import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SharedService } from 'app/main/shared.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { DeliveryEmailTemplateComponent } from './delivery-email-template/delivery-email-template.component';
var ProjectDeliveryComponent = /** @class */ (function () {
    function ProjectDeliveryComponent(route, projectsService, toastr, _formBuilder, dialog, router, httpService) {
        this.route = route;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this._formBuilder = _formBuilder;
        this.dialog = dialog;
        this.router = router;
        this.httpService = httpService;
        this.Project_Assigned_obj_compare = [];
        this.Project_Assigned_obj_id = [];
        this.completed_workflow_array = [];
        this.ProjectLanguage_data = [];
        this.target_language_select = [];
        this.show = true;
        this.file_id_data = [];
        this.file_data = [];
        this.bulk_selected_progress = false;
        this.workflow_data = [];
        this.show_deliver_button = false;
        this.show_deliver_form = false;
        this.BaseUrl_file = environment.baseUrl_file;
    }
    ProjectDeliveryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.project_id = this.route.snapshot.params['id'];
        this.email = SharedService.get('email');
        this.httpService.get('/assets/WorkFlowStatus.json').subscribe(function (data) {
            _this.workflow_data = data; // FILL THE ARRAY WITH DATA.
        }, function (err) {
            _this.toastr.warning(err.message);
        });
        this.WorkFlowByProjectId();
        this.getProjectDetail();
        this.getProjectAssignment_compare();
        this.getProjectLanguage();
        this.Form = this._formBuilder.group({
            payment_checked: ['', Validators.compose([Validators.required])],
            finished_file_naming: ['', Validators.compose([Validators.required])],
            due_date: ['', Validators.compose([Validators.required])],
            customer_notes: ['', Validators.compose([Validators.required])]
        });
    };
    ProjectDeliveryComponent.prototype.WorkFlowByProjectId = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        this.projectsService.WorkFlowByProjectId(obj)
            .subscribe(function (response) {
            if (response) {
                if (response.response.length > 0) {
                    response.response.forEach(function (element) {
                        var project_status;
                        _this.workflow_data.forEach(function (ele) {
                            if (ele.id == element.status) {
                                project_status = ele.value;
                                if (element.workflow_id == 6) {
                                    _this.delivery_workflow_id = element.id;
                                    _this.show_Delivery_status = project_status;
                                }
                            }
                        });
                        element.project_status = project_status;
                    });
                }
            }
        });
    };
    // project detail
    ProjectDeliveryComponent.prototype.getProjectDetail = function () {
        var _this = this;
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.file_data = [];
                _this.customer_email = response.data.customer.data.email_primary;
                var fullname = 'Customer';
                response.data.projectfile.data.forEach(function (element) {
                    if (element.createdby) {
                        var fullname = element.createdby.data.fullname;
                    }
                    if (element.targetlanguage) {
                        var lang_name = element.targetlanguage.data.lang_name;
                    }
                    if (element.filetype) {
                        var filetype = element.filetype.data.name;
                    }
                    element.file.data.forEach(function (elm) {
                        _this.project_file_data = _this.BaseUrl_file + elm.path;
                        elm.type = element.type;
                        elm.fullname = fullname;
                        //elm.created_at= created_at;
                        elm.lang_name = lang_name;
                        elm.filetype = filetype;
                        _this.file_data.push(elm);
                    });
                });
            }
        });
    };
    // get project assignment
    ProjectDeliveryComponent.prototype.getProjectAssignment_compare = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        var query = 'page=1&per_page=20';
        this.projectsService.getProjectAssignment(query, obj)
            .subscribe(function (response) {
            if (response) {
                _this.Project_Assigned_obj_compare = response.response.data;
                _this.Project_Assigned_obj_id = _this.Project_Assigned_obj_compare;
                if (_this.Project_Assigned_obj_compare.length > 0) {
                    _this.Project_Assigned_obj_compare.forEach(function (element) {
                        if (element.status == "5") {
                            var obj_1 = {
                                workflow_id: '',
                                workflow_name: '',
                                vendor_name: '',
                                assignment_date: ''
                            };
                            obj_1.workflow_id = element.project_workflow.workflow.id;
                            obj_1.workflow_name = element.project_workflow.workflow.name;
                            obj_1.vendor_name = element.vendor.first_name + element.vendor.last_name;
                            obj_1.assignment_date = element.project_workflow.updated_at;
                            _this.completed_workflow_array.push(obj_1);
                        }
                    });
                    _this.completed_workflow_array.sort(function (a, b) {
                        return a.workflow_id - b.workflow_id;
                    });
                }
                _this.Project_Assigned_obj_id.sort(function (a, b) {
                    return a.id - b.id;
                });
                if (_this.Project_Assigned_obj_id.length > 0) {
                    _this.Project_Assigned_obj_id.forEach(function (element) {
                        if (element.status == "5") {
                            _this.assignment_completed_id = element.id;
                        }
                    });
                }
            }
            // else{
            //   this.toastr.error("Something went wrong");
            // }
        });
    };
    // Get Project Language
    ProjectDeliveryComponent.prototype.getProjectLanguage = function () {
        var _this = this;
        this.projectsService.getProjectLanguage(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.ProjectLanguage_data = response.data;
                _this.language_pair_count = _this.ProjectLanguage_data.length;
                var target_language_obj_1 = {
                    id: Number,
                    target_lang_name: String,
                    source_lang_name: '',
                    project_lang_id: Number
                };
                _this.ProjectLanguage_data.forEach(function (element) {
                    target_language_obj_1.id = element.targetlanguage.id;
                    target_language_obj_1.source_lang_name = element.sourcelanguage.lang_name;
                    target_language_obj_1.target_lang_name = element.targetlanguage.lang_name;
                    target_language_obj_1.project_lang_id = element.id;
                    _this.target_language_select.push(target_language_obj_1);
                    target_language_obj_1 = {
                        id: '',
                        target_lang_name: '',
                        source_lang_name: '',
                        project_lang_id: ''
                    };
                });
            }
        });
    };
    ProjectDeliveryComponent.prototype.check_term_conditions = function (evt) {
        if (evt.checked && this.Form.value.payment_checked && this.Form.value.finished_file_naming &&
            this.Form.value.due_date && this.Form.value.customer_notes) {
            this.show = false;
        }
        else {
            this.show = true;
        }
    };
    ProjectDeliveryComponent.prototype.selectFile = function (evt, res) {
        if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
            this.file_id_data.push(res.id);
        }
        else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
            var index = this.file_id_data.indexOf(res.id);
            this.file_id_data.splice(index, 1);
        }
    };
    ProjectDeliveryComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        this.file_id_data = [];
        var check = false;
        this.file_data.forEach(function (element, key) {
            element["check"] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.file_id_data.push(element.id);
            }
            else {
                _this.file_id_data = [];
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
    };
    // Submit Assignment project
    ProjectDeliveryComponent.prototype.submitProject = function () {
        if (this.file_id_data == 0) {
            this.toastr.error("Please select any file");
            return;
        }
        var assignment_obj = {
            file_id: '',
            project_id: '',
            customer_email: '',
            project_assignment_id: '',
            delivery_workflow_id: ''
        };
        assignment_obj.file_id = this.file_id_data;
        assignment_obj.project_id = this.project_id;
        assignment_obj.customer_email = this.customer_email;
        assignment_obj.project_assignment_id = this.assignment_completed_id;
        assignment_obj.delivery_workflow_id = this.delivery_workflow_id;
        this.openDialog(assignment_obj);
        // this.projectsService.ProjectDelivered(assignment_obj)
        //   .subscribe((response) => {
        //       if(response) {
        //          window.location.href = '/pms/project/details/'+this.project_id;
        //         this.toastr.success("Project delivered successfully");
        //       }
        //       else{
        //         this.toastr.error("Something went wrong");
        //       }
        //   }, (error) => {
        //       this.toastr.error("Someting went wrong");
        //   });
    };
    ProjectDeliveryComponent.prototype.openDialog = function (res) {
        var _this = this;
        var data;
        data = res;
        var dailogRef = this.dialog.open(DeliveryEmailTemplateComponent, {
            width: '1220px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                window.location.href = '/pms/project/details/' + _this.project_id;
            }
        });
    };
    ProjectDeliveryComponent.prototype.showDeliverForm = function () {
        this.show_deliver_form = true;
    };
    return ProjectDeliveryComponent;
}());
export { ProjectDeliveryComponent };
