import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ElementRef } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  dataSource: any;
  id: string;
  paginate: any = {};
  total_due_today: number = 0;
  pageIndex: number = 1;
  pageSize: number = 20;
  query_projects: string = "";
  @ViewChild(MatSort)
  sort: MatSort;
  displayedColumns: string[] = ['id', 'short_url', 'long_url', 'clicks', 'created_at'];



  constructor(private projectsService: ProjectsService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    let query = '';
    this.projectsService.getReport(query)
      .subscribe((response) => {
        if (response) {
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response;
          this.total_due_today = response.total;
        }
      });
  }

  paginateData(event) {
    let pQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    this.query_projects = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
    this.query_projects += pQuery;

    this.projectsService.getReport(this.query_projects)
      .subscribe((response) => {
        if (response) {
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.sort = this.sort;
          this.paginate = response;
        }
      });
  }

}
