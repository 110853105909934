<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
    <div class="white-box">

        <div class="tHeader">

            <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="space-between center">

                <h2>
                    <mat-icon class="ml-24">group</mat-icon>Freelancer
                </h2>
                <div fxFlex class="text-right">
                    <button mat-raised-button color="accent" (click)="openDialogAllTranslator()"  class="mr-8 text-right"><i class="material-icons">group</i>Send Email To ALL</button>
                    <button mat-raised-button color="accent" [disabled]="login_enable" (click)="openDialogAddTranslator()" class="mr-8 text-right"><i class="material-icons">group</i>Send Email</button>
                </div>

            </div>
        </div>

        <div class="wraperBox">

            <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="space-between center" fxLayoutGap="10px">
                <h2 class="ml-24 mr-16"><i class="material-icons"> filter_list </i></h2>
                <mat-form-field fxFlex="20">
                    <mat-label class="font-size-12">Native Language</mat-label>
                    <mat-select [(ngModel)]="fdata.Native" [ngModelOptions]="{standalone: true}"
                        (selectionChange)="onFilter($event,'native_lang')">
                        <mat-option [value]="''">
                            Select
                        </mat-option>
                        <mat-option *ngFor="let language of languageData.data" [value]="language.id">
                            {{language.lang_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="24" class="ml-8">
                    <mat-label>Source Language</mat-label>
                    <mat-select [(ngModel)]="fdata.Source" [ngModelOptions]="{standalone: true}"
                        (selectionChange)="onFilter($event,'s_lang')">
                        <mat-option [value]="''">
                            Select
                        </mat-option>
                        <mat-option *ngFor="let language of languageData.data" [value]="language.lang_name">
                            {{language.lang_name}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>

                <mat-form-field fxFlex="24" class="ml-8">
                    <mat-label>Target Language</mat-label>
                    <mat-select [(ngModel)]="fdata.Target" [ngModelOptions]="{standalone: true}"
                        (selectionChange)="onFilter($event,'t_lang')">
                        <mat-option [value]="''">
                            Select
                        </mat-option>
                        <mat-option *ngFor="let language of languageData.data" [value]="language.lang_name">
                            {{language.lang_name}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>

                <mat-form-field fxFlex="28" class="ml-8">
                    <mat-label>Speciality Fields</mat-label>

                    <mat-select [(ngModel)]="fdata.specialization" [ngModelOptions]="{standalone: true}"
                        (selectionChange)="onFilter($event,'speciality')">

                        <mat-option [value]="''">
                            Select
                        </mat-option>
                        <mat-option *ngFor="let speciality of specialityData" [value]="speciality.name">
                            {{speciality.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <div class="search-wrapper mx-32 mx-md-0" fxFlex="20">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="mt-12">search</mat-icon>

                        <input placeholder="Search" (keyup)="applyFilterApproved($event.target.value)">
                    </div>
                </div>



            </div>
        </div>


        <div class="content-card">
            <mat-table class="products-table" #table [dataSource]="dataSource" matSort
                (matSortChange)="sortUser($event)" fusePerfectScrollbar>

                <!-- <ng-container matColumnDef="ref" >
                        <mat-header-cell *matHeaderCellDef  fxFlex="12">
                           Ref id</mat-header-cell>
                        <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                            <span>
                                {{ product.reference_id}}
                        </span>
                            
                        </mat-cell>
                    </ng-container> -->
                <ng-container matColumnDef="ref">
                    <mat-header-cell *matHeaderCellDef fxFlex="5">
                        <mat-checkbox [(ngModel)]="bulk_selected_progress" (change)="selectBulkProgress($event)">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="5">
                        <mat-checkbox [(ngModel)]="product.check" (change)="selectMailProgress($event,product)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="first_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16">First Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">

                        <div fxLayout="column">
                            <div fxFlex>
                                <a class="btn-link" routerLink="/marketing/freelancer/review/{{ product.id }}">
                                    {{product.first_name}} {{product.last_name}}
                                </a>
                            </div>

                            <div class="navigat pt-8" fxFlex>
                                <span>
                                    <button mat-button (click)="editData(product)" class="btn-link br-1">
                                        Edit
                                    </button>
                                </span>

                            </div>
                        </div>


                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="native_language">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="16">Native Language</mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
                        <span *ngIf="product.vendornativelanguage!=null">
                            {{product.vendornativelanguage.data.lang_name}}
                        </span>
                        <span *ngIf="product.vendornativelanguage==null">
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="category_level">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="8">Category Level
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                        <span *ngIf="product.category_level!=null">
                            {{product?.category_level}}
                        </span>
                        <span *ngIf="product.category_level==null">
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="language">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md fxFlex="32">Language Pair</mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-md fxFlex="32">
                        <span class="price text-truncate">
                            <!-- {{product.sourceLanguage?.data.lang_name}} - {{product.targetlanguage?.data.lang_name}} -->
                            <span *ngFor="let res of product.lang_array">
                                <p>{{res}}</p>
                            </span></span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20">Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" class="mt-16" fxHide fxShow.gt-sm fxFlex="20">


                        <span>
                            {{product.email}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="8">Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="8">
                        <span class="bdg ylbg" *ngIf="product.stage!=null">
                            {{product.stage.data.name}}
                        </span>
                        <span class="bdg ylbg" *ngIf="product.stage==null">

                        </span>
                    </mat-cell>
                </ng-container>




                <mat-header-row *matHeaderRowDef="ApprovaldisplayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let product; columns: ApprovaldisplayedColumns;" class="product fList">
                </mat-row>

            </mat-table>
            <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
                [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
                [pageSizeOptions]="[10,20,30,40]" (page)='paginateData($event)'>
            </mat-paginator>

            <div *ngIf="paginate.total==0" class="noDataFound"> No data available!!
            </div>
        </div>
    </div>
</div>