import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';

@Component({
  selector: 'app-edit-template-master',
  templateUrl: './edit-template-master.component.html',
  styleUrls: ['./edit-template-master.component.scss']
})
export class EditTemplateMasterComponent implements OnInit {

  config: any;
  project_id: string;
  Form: FormGroup;
  templateType : any;
 constructor( private dialogRef: MatDialogRef<EditTemplateMasterComponent>,
   @Inject(MAT_DIALOG_DATA) public id :any, private vendorService: VendorService,
   private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
   private _formBuilder: FormBuilder) { }

 Onclose(){
   this.dialogRef.close();
}
 ngOnInit() {
   this.project_id = this.route.snapshot.params['id'];
   this.getTemplateType();
   this.config = {
      width: '1000px',
      height: '155px',
      toolbar : [
        { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
        { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
        { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
        '/',
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 
        'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 
        'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
        { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 
        'SpecialChar', 'PageBreak', 'Iframe' ] },
        '/',
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        { name: 'about', items: [ 'About' ] }
      ]
     // toolbar: [
     //     { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
     //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }
     // ]
 };
 this.Form = this._formBuilder.group({ 

   title: ['',Validators.compose([ Validators.required])],
   subject: ['',Validators.compose([ Validators.required])],
   ckeditorContent: [''],
   status:['0'],
   email_from: [''],
   email_to: [''],
   type: ['']
});

 this.get_quote_email();
 }

 updateMessage(){
   if(this.Form.value.subject == ''){
     this.toastr.error('Subject never be empty!!');
     return;
   }

   if(  this.Form.value.ckeditorContent == ''){
     this.toastr.error('Body never be empty!!');
     return;
   }
   let data:any={
    name: '',
    body: '',
    subject: '',
    is_active: '',
    email_from: '',
    email_to: '',
    type : ''
   
   };
  
   data.name = this.Form.value.title;
   data.body = this.Form.value.ckeditorContent;
   data.subject = this.Form.value.subject;
   data.is_active = this.Form.value.status;
   data.email_from = this.Form.value.email_from;
   data.email_to = this.Form.value.email_to;
   data.type = this.Form.value.type;
   
     this.vendorService.updateEmailTemplate(this.id, data)
         .subscribe((response) => {
             if(response) {
              //  this.router.navigate(['/pms/project/details/'+this.id.project_id]);
               this.toastr.success('Template updated!!');
               this.Onclose();
             }
         });
 }
 get_quote_email(){    
   this.vendorService.getSingleEmailTemplate(this.id)
         .subscribe((response:any) => {
             if(response) {
             this.Form.patchValue({
               ckeditorContent: response.data.body,
               subject: response.data.subject,
               title: response.data.name,
               status: response.data.is_active,
               email_from: response.data.email_from,
               email_to: response.data.email_to,
               type: response.data.type
             });
             }
         });
 }

 getTemplateType() {
  let query = '';
  this.vendorService.getTemplateType(query)
    .subscribe((res: any) => {
      if (res) {
        this.templateType = res;
      }
    });
}

}
