import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-history',
  templateUrl: './project-history.component.html',
  styleUrls: ['./project-history.component.scss']
})
export class ProjectHistoryComponent implements OnInit {
  project_id:string;
  activity_response:any=[];
  activity_count:number=0;
  constructor(private route: ActivatedRoute,
    private projectsService: ProjectsService,private toastr: ToastrService) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getProjectActivity();
  }


  getProjectActivity()
  {
    this.projectsService.projectActivity(this.project_id)
    .subscribe((response:any) => {
        if(response) {
          this.activity_response=[];
          if(response.project_activity.length>0){
            this.activity_response=response.project_activity;
        
          }
         }
    });
  }

}
