import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
export interface QuotesChip {
  name: string;
  type: string;
}
@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  dataSource: any;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  fQuery: string = "";
  fchips: string = "";
  fChipsArray: any = [];
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild('filter')
  filter: ElementRef;
  paginate: any = {};
  query_quote: string = "";
  pageSize: number = 20;
  pageIndex: number = 1;
  languageData: any = [];
  filter_src_lang: string = "";
  filter_target_lang: string = "";
  filterCategory: string = "";
  filterPaymentMethod: string = "";
  filterCity: string = "";
  filterCountry: string = "";
  filterLeadSource: string = "";
  filterProjectManager: string = "";
  filterStatus: string = "";
  response_data_quoteArray: any = [];
  projectmanager: any = [];
  projectLeadsource: any = [];
  Form: FormGroup;
  filterEndDate: string = "";
  filterStartDate: string = "";
  filterSearchQuery: string = "";
  total_quotes:number=0;
  quote_status: any;
  displayedColumns: string[] = ['id', 'qEmail', 'leadSrouce', 'quoteServices', 'languagePair', 'quoteStartDate', 'quoteStatus'];
  constructor(private projectsService: ProjectsService, private toastr: ToastrService, private _formBuilder: FormBuilder) { }

  // mat-chip start
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  QuotesChips: any[] = [];

  add(event: MatChipInputEvent, evt): void {
    const input = event.input;
    const value = event.value;

    // Add our QuotesChip
    if ((value || '').trim()) {
      this.QuotesChips.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(QuotesChip: QuotesChip): void {
  
    // this.languageData.data.filter(x=>{
    //   if(x.lang_name==QuotesChip.name){
    //     this.languageData=''
    //   }
    // });
    const index = this.QuotesChips.indexOf(QuotesChip);

    if (index >= 0) {
      this.QuotesChips.splice(index, 1);
    }
  }

  // mat-chip end
  ngOnInit() {
    this.getData();
    this.getLanguage();
    this.getProjectSource();
    this.getProjectManager();
    this.Form = this._formBuilder.group({

      search_value: [''],

    });
  }

  getData() {
    let query = '?page=1&per_page=20';
    this.projectsService.getProjectQuotes(query)
      .subscribe((response) => {
        if (response) {

          response.data.forEach(element => {

            let lang1 = '';
            let lang_array = [];
            element.projectLanguage.data.forEach(elm => {
              if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                lang_array.push(lang1);
              }
            });

            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)

            }
            element.lang_array = lang_array;
            response.data.lang_array = lang_array;





          });

          //     let quote_status_new;
          //     response.data.forEach(element => {

          //       if(element.projectquote.data.length>0){

          //         this.response_data_quoteArray=element.projectquote.data;
          //         this.response_data_quoteArray.forEach(response_t => {
          //           quote_status_new=response_t.status;

          //           if(quote_status_new==1){
          //             quote_status_new="Sent to customer"

          //           }else if(quote_status_new==2){
          //             quote_status_new="Requested Changes by customer"

          //           }
          //           else if(quote_status_new==3){
          //             quote_status_new="Declined by customer"

          //           }
          //           else if(quote_status_new==4){
          //             quote_status_new="Approved by customer"

          //           }
          //           else if(quote_status_new==5){
          //             quote_status_new="Save As Draft"

          //           }

          //           response.data.quote_status_new=quote_status_new;

          //   });
          // }

          //     });


        }




        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.sort = this.sort;
        this.paginate = response.meta.pagination;
        this.total_quotes=response.meta.pagination.total;

      });
  }


  paginateData(event) {
    let pQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;

    this.query_quote = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;

    this.query_quote += pQuery;


    this.projectsService.getProjectQuotes(this.query_quote)
      .subscribe((response) => {
        if (response) {

          response.data.forEach(element => {

            let lang1 = '';
            let lang_array = [];
            element.projectLanguage.data.forEach(elm => {
              if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                lang_array.push(lang1);
              }

            });

            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)

            }
            element.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.sort = this.sort;
          this.paginate = response.meta.pagination;
          this.total_quotes=response.meta.pagination;

        }
      });
  }

  // get Source Language & Target Language 

  getLanguage() {
    this.projectsService.getAllLanguages()
      .subscribe((res: any) => {
        if (res) {

          this.languageData = res.data;
        }
      });
  }

  // get project source
  getProjectSource() {
    this.projectsService.getLeadSource()
      .subscribe((res: any) => {
        if (res) {
          this.projectLeadsource = res.data;
        }
      });
  }

  // get project manager
  getProjectManager() {
    this.projectsService.getProjectManager()
      .subscribe((res: any) => {
        if (res) {
          res.data.forEach(element => {
            if (element.user_group_id == 'Admin') {
              this.projectmanager.push(element.fullname);
            }

          });

        }
      });
  }


  onFilter(evt, type) {

    this.fQuery = '?page=1&per_page=10';
    this.fchips = '';

    // project search filter
    if ((type == "search" && evt != "" && evt !== undefined)) {
      this.filterSearchQuery = evt;
      this.fQuery += "&search_text=" + this.filterSearchQuery;

    }
    else if (type == "search" && evt == "") {
      this.filterSearchQuery = evt;
    }
    else if (this.filterSearchQuery !== "") {
      this.fQuery += "&search_text=" + this.filterSearchQuery;

    }

    // project status filter
    if ((type == "status" && evt.value != "" && evt.value !== undefined)) {
      this.filterStatus = evt.value;
      this.fQuery += "&status=" + this.filterStatus;
      this.fchips += '&' + this.filterStatus;
    }
    else if (type == "status" && evt.value == "") {
      this.filterStatus = evt.value;
    }
    else if (this.filterStatus !== "") {
      this.fQuery += "&status=" + this.filterStatus;
      this.fchips += '&' + this.filterStatus;

    }

    // project Source Language filter 
    if ((type == "s_lang" && evt.value != "" && evt.value !== undefined)) {
      this.filter_src_lang = evt.value;
      this.fQuery += "&source_language=" + this.filter_src_lang;
      this.fchips += '&' + this.filter_src_lang;

    }
    else if (type == "s_lang" && evt.value == "") {
      this.filter_src_lang = evt.value;
    }
    else if (this.filter_src_lang !== "") {
      this.fQuery += "&source_language=" + this.filter_src_lang;
      this.fchips += '&' + this.filter_src_lang;

    }

    // project Target Language filter
    if ((type == "t_lang" && evt.value != "" && evt.value !== undefined)) {
      this.filter_target_lang = evt.value;
      this.fQuery += "&target_language=" + this.filter_target_lang;

    }
    else if (type == "t_lang" && evt.value == "") {
      this.filter_target_lang = evt.value;
    }
    else if (this.filter_target_lang !== "") {
      this.fQuery += "&target_language=" + this.filter_target_lang;

    }

    // project City filter
    // if ((type == "city" && evt.value != "" && evt.value !== undefined)) {
    //    this.filterCity = evt.value;
    //    this.fQuery += "&city=" + this.filterCity;
    // }
    // else if(type == "city" && evt.value == ""){
    //   this.filterCity = evt.value;  
    // }
    // else if(this.filterCity!==""){
    //  this.fQuery += "&city=" + this.filterCity;
    // }   

    if ((type == "city" && evt.trim().toLowerCase() != "" && evt.trim().toLowerCase() !== undefined)) {
      this.filterCity = evt.trim().toLowerCase();

      this.fQuery += "&city=" + this.filterCity;

    }
    else if (type == "city" && evt.trim().toLowerCase() == "") {
      this.filterCity = evt.trim().toLowerCase();

    }
    else if (this.filterCity !== "") {
      this.fQuery += "&city=" + this.filterCity;

    }

    // project country filter

    if ((type == "country" && evt.value != "" && evt.value !== undefined)) {
      this.filterCountry = evt.value;
      this.fQuery += "&country=" + this.filterCountry;
      this.fchips += '&' + this.filterCountry;
    
    }
    else if (type == "country" && evt.value == "") {
      this.filterCountry = evt.value;
    }
    else if (this.filterCountry !== "") {
      this.fQuery += "&country=" + this.filterCountry;
      this.fchips += '&' + this.filterCountry;
      
    }

    // project Project Manager filter

    if ((type == "ProjectManager" && evt.value != "" && evt.value !== undefined)) {
      this.filterProjectManager = evt.value;
      this.fQuery += "&project_pm=" + this.filterProjectManager;
      this.fchips += '&' + this.filterProjectManager;
    }
    else if (type == "ProjectManager" && evt.value == "") {
      this.filterProjectManager = evt.value;

    }
    else if (this.filterProjectManager !== "") {
      this.fQuery += "&project_pm=" + this.filterProjectManager;
      this.fchips += '&' + this.filterProjectManager;


    }

    // project lead source filter

    if ((type == "lead_source" && evt.value != "" && evt.value !== undefined)) {
      this.filterLeadSource = evt.value;
      this.fQuery += "&source=" + this.filterLeadSource;

    }
    else if (type == "lead_source" && evt.value == "") {
      this.filterLeadSource = evt.value;
    }
    else if (this.filterLeadSource !== "") {
      this.fQuery += "&source=" + this.filterLeadSource;

    }

    // project category filter

    if ((type == "category" && evt.value != "" && evt.value !== undefined)) {
      this.filterCategory = evt.value;
      this.fQuery += "&category=" + this.filterCategory;

    }
    else if (type == "category" && evt.value == "") {
      this.filterCategory = evt.value;
    }
    else if (this.filterCategory !== "") {
      this.fQuery += "&category=" + this.filterCategory;

    }

    // Payment Method filter

    if ((type == "payment_method" && evt.value != "" && evt.value !== undefined)) {
      this.filterPaymentMethod = evt.value;
      this.fQuery += "&payment_method=" + this.filterPaymentMethod;
    }
    else if (type == "payment_method" && evt.value == "") {
      this.filterPaymentMethod = evt.value;
    }
    else if (this.filterPaymentMethod !== "") {
      this.fQuery += "&payment_method=" + this.filterPaymentMethod;
    }


    // Start date filter

    if ((type == "start_date" && evt.value != "" && evt.value !== undefined)) {

      this.filterStartDate = evt.value.format("YYYY-MM-DD");
      this.fQuery += "&start_date=" + this.filterStartDate;
    }
    else if (type == "start_date" && evt.value == "") {
      this.filterStartDate = evt.value;
    }
    else if (this.filterStartDate !== "") {
      this.fQuery += "&start_date=" + this.filterStartDate;
    }


    // End date filter

    if ((type == "end_date" && evt.value != "" && evt.value !== undefined)) {

      this.filterEndDate = evt.value.format("YYYY-MM-DD");
      this.fQuery += "&end_date=" + this.filterEndDate;
    }
    else if (type == "end_date" && evt.value == "") {
      this.filterEndDate = evt.value;
    }
    else if (this.filterEndDate !== "") {

      this.fQuery += "&end_date=" + this.filterEndDate;
    }

    // this.fChipsArray=this.fchips.split('&');
    // this.fChipsArray.shift();

    this.parseQueryString(this.fQuery);

    let query = this.fQuery;
    this.projectsService.getProjectQuotes(query)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(element => {

            let lang1 = '';
            let lang_array = [];
            element.projectLanguage.data.forEach(elm => {
              if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                lang_array.push(lang1);
              }
            });

            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)

            }
            element.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });

          this.dataSource = new MatTableDataSource(response.data);

          this.dataSource.sort = this.sort;
          this.paginate = response.meta.pagination;

        }
      });

  }

  submit() {
    this.onFilter(this.Form.value.search_value, 'search');
  }


  // query string

  parseQueryString(queryString) {
    this.fChipsArray=[];
    var params = {
      search_text: '',
      status: '',
      source_language: '',
      target_language: '',
      city: '',
      country: '',
      project_pm: '',
      source: '',
      category: '',
      payment_method: '',
      start_date: '',
      end_date: '',
    }, 
    queries, temp, i, l;

    // Split into key/value pairs
    queries = queryString.split("&");
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
    
  
      this.get_filter_value(params)
}


get_filter_value(data){
  let key = Object.keys(data);

  if (key[0] == 'search_text')
  {
    if(data.search_text!=""){
      this.fChipsArray.push(data.search_text);
    }
     
  }

  if (key[1] == 'status')
  {
        if (data.status == 1) {
          this.fChipsArray.push("Pending");
        }else if (data.status == 2) {
          this.fChipsArray.push("Under Quote");
        }
  }

  if (key[2] == 'source_language')
  {
        this.languageData.forEach(element => {
        if (data.source_language == element.id) {
          this.fChipsArray.push(element.lang_name);
        }

      });
    
  }

  if (key[3] == 'target_language')
  {
        this.languageData.forEach(element => {
        if (data.target_language == element.id) {
          this.fChipsArray.push(element.lang_name);
        }

      });
    
  }
  if (key[4] == 'city')
  {
    if(data.city!=""){
      this.fChipsArray.push(data.city);
    }
    
  }

  if (key[5] == 'country')
  {

    if (data.country !="") {
      this.fChipsArray.push(data.country);
    }
  }

  if (key[6] == 'project_pm')
  {
    if (data.project_pm !="") {
      this.fChipsArray.push(data.project_pm);
    }
    
  }

  if (key[7] == 'source')
  {
    if (data.source !="") {
      this.fChipsArray.push(data.source);
    }
    
  }

  if (key[8] == 'category')
  {
        if (data.category == 1) {
          this.fChipsArray.push("DayVIP");
        }else if (data.category == 2) {
          this.fChipsArray.push("Company");
        }
        if (data.category == 3) {
          this.fChipsArray.push("Individual");
        }else if (data.category == 4) {
          this.fChipsArray.push("New Client");
        }
        
  }

  
  if (key[9] == 'payment_method')
  {
        if (data.payment_method == 1) {
          this.fChipsArray.push("NET30");
        }else if (data.payment_method == 2) {
          this.fChipsArray.push("NET45");
        }
        if (data.payment_method == 3) {
          this.fChipsArray.push("Regular/Upfront");
        }else if (data.payment_method == 4) {
          this.fChipsArray.push("Credit Available");
        }
        
  }

  if (key[10] == 'start_date')
  {
    if (data.start_date !="") {
      this.fChipsArray.push(data.start_date);
    }
    
  }

  if (key[11] == 'end_date')
  {
    if (data.end_date !="") {
      this.fChipsArray.push(data.end_date);
    }
    
  }
}



}