<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
    <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
        <div fxFlex="30">
            <div class="white-box plainBox">
                <div class="tHeader">
                    <h2>
                        <i class="material-icons">filter_list</i>Portal users
                        Sort by
                    </h2>
                </div>
                <div class="boxBody">
                    <div class="boxBodyInn">
                        <div
                            fxFlexLayout="row wrap"
                            fxLayoutAlign="left center"
                            fxLayoutGap="10px"
                        >
                            <div fxFlex="100">
                                <mat-form-field>
                                    <mat-label
                                        >Search by ID/Name/Email</mat-label
                                    >
                                    <input
                                        matInput
                                        (keyup)="
                                            onFilter(
                                                $event.target.value,
                                                'search'
                                            )
                                        "
                                    />
                                </mat-form-field>
                            </div>
                            <!-- <div fxFlex="20">
                <button mat-stroked-button color="accent"><i class="material-icons">search</i></button>
              </div> -->
                        </div>

                        <div
                            fxFlexLayout="row wrap"
                            fxLayoutAlign="left center"
                            fxLayoutGap="10px"
                        >
                            <div fxFlex="30">
                                <div class="text-right"><b>Status is</b></div>
                            </div>
                            <div fxFlex="69">
                                <mat-form-field>
                                    <mat-label>Select Field</mat-label>
                                    <mat-select
                                        (selectionChange)="
                                            onFilter($event, 'status')
                                        "
                                    >
                                        <mat-option [value]="''">
                                            Any
                                        </mat-option>
                                        <mat-option [value]="'1'">
                                            Active
                                        </mat-option>
                                        <mat-option [value]="'0'">
                                            Inactive
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div
                            fxFlexLayout="row wrap"
                            fxLayoutAlign="left center"
                            fxLayoutGap="10px"
                        >
                            <div fxFlex="30">
                                <div class="text-right">
                                    <b>Role/Position is</b>
                                </div>
                            </div>
                            <div fxFlex="69">
                                <mat-form-field>
                                    <mat-label>Select Field</mat-label>
                                    <mat-select
                                        (selectionChange)="
                                            onFilter($event, 'role')
                                        "
                                    >
                                        <mat-option [value]="''">
                                            Any
                                        </mat-option>
                                        <mat-option
                                            *ngFor="let role of roleData"
                                            [value]="role.id"
                                        >
                                            {{ role.id }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div
                            fxFlexLayout="row wrap"
                            fxLayoutAlign="left center"
                            fxLayoutGap="10px"
                        >
                            <div fxFlex="30">
                                <div class="text-right">
                                    <b>Portal Access is</b>
                                </div>
                            </div>
                            <div fxFlex="69">
                                <mat-form-field>
                                    <mat-label>Select</mat-label>
                                    <mat-select
                                        (selectionChange)="
                                            onFilter($event, 'portal_access')
                                        "
                                    >
                                        <mat-option [value]="''">
                                            Any
                                        </mat-option>
                                        <mat-option value="1">Admin</mat-option>
                                        <mat-option value="2">Hr</mat-option>
                                        <mat-option value="3">PMS</mat-option>
                                        <mat-option value="4"
                                            >Finance</mat-option
                                        >
                                        <mat-option value="5"
                                            >Marketing</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- <div  fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                                    <div fxFlex="30">
                                  <div class="text-right"><b>User Added</b> </div>
                                </div>
                                <div fxFlex="69">
                                    <mat-form-field  >
                                        <mat-select placeholder="Select Field">
                                            <mat-option value="option1">Any time</mat-option>
                                            <mat-option value="option1">After</mat-option>
                                            <mat-option value="option2">Before</mat-option>
                                          <mat-option value="option3">Today</mat-option>
                                          <mat-option value="option4">This Month</mat-option>
                                          <mat-option value="option4">This Week</mat-option>
                                          <mat-option value="option4">This Year</mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                  </div>
                                
            
                                  </div> -->

                        <!--             
                                  <div  fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                                      <div fxFlex="30">
                                    <div class="text-right"><b>Select</b> </div>
                                  </div>
                                  <div fxFlex="69">
                                      <mat-form-field  >
                                          <mat-label>Date</mat-label>
                                          <input matInput>
                                      </mat-form-field>
                                    </div>
                              
                                    </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="68">
            <div class="white-box">
                <div class="tHeader">
                    <div fxLayout="row wrap">
                        <div fxFlex>
                            <h2>
                                Total <b>{{ total_user }}</b> Records Available
                            </h2>
                        </div>
                        <div fxFlex class="text-right">
                            <button
                                mat-raised-button
                                class="green-bg Text_Color mr-8"
                                [routerLink]="'/user-management/add-user'"
                            >
                                <mat-icon>add</mat-icon>Add New User
                            </button>
                        </div>
                    </div>
                    <div class="filterTag pt-12">
                        <mat-chip-list
                            #chipList
                            aria-label="QuotesChip selection"
                        >
                            <mat-chip
                                *ngFor="let QuotesChip of fChipsArray"
                                [selectable]="selectable"
                                [removable]="removable"
                            >
                                {{ QuotesChip }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>

                <div class="content-card" *ngIf="paginate?.total > 0">
                    <mat-table
                        #table
                        [dataSource]="dataSource"
                        matSort
                        (matSortChange)="sortUser($event)"
                    >
                        <!-- <ng-container matColumnDef="username">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20">User Name</mat-header-cell>
              <mat-cell *matCellDef="let product" fxFlex="20">
                <p class="text-truncate">{{product.user_name}}</p>
              </mat-cell>
            </ng-container> -->

                        <ng-container matColumnDef="fullname">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                fxHide
                                fxShow.gt-xs
                                fxFlex="20"
                                >Name</mat-header-cell
                            >
                            <mat-cell
                                *matCellDef="let product"
                                fxHide
                                fxShow.gt-xs
                                fxFlex="20"
                            >
                                <p class="price text-truncate">
                                    {{ product.fullname }}
                                </p>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="user_group_id">
                            <mat-header-cell
                                *matHeaderCellDef
                                fxHide
                                fxShow.gt-md
                                fxFlex="20"
                                >Role/Position</mat-header-cell
                            >
                            <mat-cell
                                *matCellDef="let product"
                                fxHide
                                fxShow.gt-md
                                fxFlex="20"
                            >
                                <p class="category text-truncate">
                                    {{ product.user_group_id }}
                                </p>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                fxHide
                                fxShow.gt-sm
                                fxFlex="40"
                                >Email</mat-header-cell
                            >
                            <mat-cell
                                *matCellDef="let product"
                                fxHide
                                fxShow.gt-sm
                                fxFlex="40"
                            >
                                <span>
                                    {{ product.email }}
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="portal_access">
                            <mat-header-cell
                                *matHeaderCellDef
                                fxHide
                                fxShow.gt-sm
                                fxFlex="12"
                                >Portal Access</mat-header-cell
                            >
                            <mat-cell
                                *matCellDef="let product"
                                fxHide
                                fxShow.gt-sm
                                fxFlex="12"
                            >
                                <span class="ml-8">
                                    {{ product.user_portal }}
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="last_login">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                fxHide
                                fxShow.gt-sm
                                fxFlex="20"
                                >Last Login
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let product"
                                fxHide
                                fxShow.gt-sm
                                fxFlex="20"
                            >
                                <span>
                                    {{ product.last_login }}
                                </span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                fxHide
                                fxShow.gt-sm
                                fxFlex="8"
                                >Status</mat-header-cell
                            >
                            <mat-cell
                                *matCellDef="let product"
                                fxHide
                                fxShow.gt-sm
                                fxFlex="8"
                            >
                                <span
                                    class="bdg rdb"
                                    *ngIf="product.status == '0'"
                                >
                                    InActive
                                </span>
                                <span
                                    class="bdg grn"
                                    *ngIf="product.status == '1'"
                                >
                                    Active
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef
                                >Actions</mat-header-cell
                            >
                            <mat-cell
                                *matCellDef="let product"
                                fxHide
                                fxShow.gt-xs
                            >
                                <button
                                    (click)="editData(product)"
                                    mat-stroked-button
                                    class="bicon"
                                >
                                    <mat-icon matTooltip="Edit">
                                        edit
                                    </mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row
                            *matHeaderRowDef="displayedColumns"
                        ></mat-header-row>

                        <mat-row
                            *matRowDef="let product; columns: displayedColumns"
                            class="product"
                        >
                        </mat-row>
                    </mat-table>
                    <mat-paginator
                        #paginator
                        *ngIf="paginate?.total != 0"
                        [length]="paginate?.total"
                        [pageIndex]="paginate?.current_page - 1"
                        [pageSize]="paginate?.per_page"
                        [pageSizeOptions]="[10, 20, 30, 40]"
                        (page)="paginateData($event)"
                    >
                    </mat-paginator>
                </div>
                <div class="noDataFound" *ngIf="paginate?.total == 0">
                    No data available!!
                </div>
            </div>
        </div>
    </div>
</div>
