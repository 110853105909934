import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { HrDashboardComponent } from "./hr-dashboard/hr-dashboard.component";
import { TranslatorComponent } from "./translator/translator.component";
import { AddTranslatorComponent } from "./translator/add-translator/add-translator.component";
import { TranslatorOrientationComponent } from "./translator/translator-orientation/translator-orientation.component";
import { TranslatorQuizComponent } from "./translator/translator-quiz/translator-quiz.component";
import { MatCardModule } from "@angular/material/card";
import {
    MatButtonModule,
    MatBadgeModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatDatepickerModule,
    MatTableModule,
    MatDividerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
} from "@angular/material";
import { MatProgressBarModule } from '@angular/material';
import { MatRadioModule } from "@angular/material/radio";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatStepperModule } from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HrGuard } from "app/_guards/hr.guard";
import { ReviewTranslatorComponent } from "./translator/review-translator/review-translator.component";
import { SharedListingComponent } from "./hr-dashboard/shared-listing/shared-listing.component";
import { ImportedTranslatorsComponent } from "./imported-translators/imported-translators.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown-angular7";
import { CKEditorModule } from "ng2-ckeditor";
import { BulkEmailTranslatorComponent } from "./translator/bulk-email-translator/bulk-email-translator.component";
import { AddVendorDocComponent } from "./translator/add-translator/add-vendor-doc/add-vendor-doc.component";
import { SubtitlerComponent } from "./subtitler/subtitler.component";
import { ClosedBlacklistedComponent } from "./closed-blacklisted/closed-blacklisted.component";
import { TrepingComponent } from './treping/treping.component';
import { AITranscriptionComponent } from "./ai-transcription/ai-transcription.component";
const routes = [
    {
        path: "hr/hr-dashboard-shared-listing",
        component: SharedListingComponent,
        canActivate: [HrGuard],
    },

    {
        path: "hr/hr-dashboard",
        component: HrDashboardComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator",
        component: TranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/review/:id",
        component: ReviewTranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/orientation",
        component: TranslatorOrientationComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/add-translator",
        component: AddTranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/:id",
        component: AddTranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/quiz",
        component: TranslatorQuizComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/imported-translator",
        component: ImportedTranslatorsComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/subtitler",
        component: SubtitlerComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/ai-transcription",
        component: AITranscriptionComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/terping",
        component: TrepingComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/closed-blacklisted",
        component: ClosedBlacklistedComponent,
        canActivate: [HrGuard],
    },
];

@NgModule({
    declarations: [
        HrDashboardComponent,
        TranslatorComponent,
        TranslatorOrientationComponent,
        AddTranslatorComponent,
        TranslatorQuizComponent,
        ReviewTranslatorComponent,
        SharedListingComponent,
        ImportedTranslatorsComponent,
        BulkEmailTranslatorComponent,
        AddVendorDocComponent,
        SubtitlerComponent,
        ClosedBlacklistedComponent,
        TrepingComponent,
        AITranscriptionComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        TranslateModule,
        MatCardModule,
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatRadioModule,
        MatFormFieldModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatDatepickerModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatBadgeModule,
        MatTooltipModule,
        CKEditorModule,
        BrowserAnimationsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatProgressBarModule
    ],
    entryComponents: [BulkEmailTranslatorComponent, AddVendorDocComponent],
    exports: [
        TranslatorComponent,
        TranslatorOrientationComponent,
        AddTranslatorComponent,
        TranslatorQuizComponent,
        ImportedTranslatorsComponent,
    ],
})
export class HrModule {}
