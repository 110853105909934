import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
var DeliveryEmailTemplateComponent = /** @class */ (function () {
    function DeliveryEmailTemplateComponent(dialogRef, delivery_data, projectsService, toastr, router, route, _formBuilder) {
        this.dialogRef = dialogRef;
        this.delivery_data = delivery_data;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this._formBuilder = _formBuilder;
    }
    DeliveryEmailTemplateComponent.prototype.Onclose = function (res) {
        this.dialogRef.close(res);
    };
    DeliveryEmailTemplateComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.config = {
            width: "1000px",
            height: '155px',
            toolbar: [
                { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'] },
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
                { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
                { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                '/',
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
                { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                '/',
                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                { name: 'colors', items: ['TextColor', 'BGColor'] },
                { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
                { name: 'about', items: ['About'] }
            ]
            // toolbar: [
            //     { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
            //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }
            // ]
        };
        this.Form = this._formBuilder.group({
            to: ['', Validators.compose([Validators.required, Validators.email])],
            cc: ['', Validators.compose([Validators.email])],
            bcc: ['', Validators.compose([Validators.email])],
            subject: ['', Validators.compose([Validators.required])],
            ckeditorContent: [''],
        });
        this.get_delivery_email();
    };
    DeliveryEmailTemplateComponent.prototype.updateMessage = function () {
        var _this = this;
        if (this.Form.value.subject == '') {
            this.toastr.error("Subject never be empty!!");
            return;
        }
        if (this.Form.value.ckeditorContent == '') {
            this.toastr.error("Body never be empty!!");
            return;
        }
        var data = {
            mail_response: {}
        };
        var mail_response = {
            to: '',
            cc: '',
            bcc: '',
            message: '',
            subject: '',
        };
        mail_response.to = this.Form.value.to;
        mail_response.cc = this.Form.value.cc;
        mail_response.bcc = this.Form.value.bcc;
        mail_response.message = this.Form.value.ckeditorContent;
        mail_response.subject = this.Form.value.subject;
        data = this.delivery_data;
        data.mail_response = mail_response;
        this.projectsService.ProjectDelivered(data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Project delivered successfully");
                _this.Onclose(true);
            }
            else {
                // this.toastr.error("Something went wrong");
                _this.Onclose(false);
            }
        }
        //  , (error) => {
        //      this.Onclose(false);
        //  }
        );
    };
    DeliveryEmailTemplateComponent.prototype.get_delivery_email = function () {
        var _this = this;
        this.projectsService.get_delivery_email(this.delivery_data)
            .subscribe(function (response) {
            if (response) {
                //   this.Form.value.ckeditorContent= response.mail_response.message;
                //  this.Form.value.subject=response.mail_response.subject;
                //  this.Form.value.to=response.mail_response.To;
                _this.Form.patchValue({
                    ckeditorContent: response.mail_response.message,
                    subject: response.mail_response.subject,
                    to: response.mail_response.To,
                });
            }
        });
    };
    return DeliveryEmailTemplateComponent;
}());
export { DeliveryEmailTemplateComponent };
