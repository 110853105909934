

import { Component, OnInit, ViewChild } from '@angular/core';

import { AppGlobals } from 'app/main/app.global';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  providers: [AppGlobals]
})
export class TransactionsComponent implements OnInit {
  quote_id:number
  constructor(private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    
    this.quote_id = this.route.snapshot.params['id'];
  }


  searchTransaction(){
    if(this.quote_id!=null){
      this.router.navigate(['/pms/transactions/search/'+this.quote_id]);
    }else{
      this.router.navigate(['/pms/transactions/search']);
    }
    
  }

  CreateTransaction(){
    if(this.quote_id!=null){
    this.router.navigate(['/pms/transactions/create/'+this.quote_id]);
    }else{
      this.router.navigate(['/pms/transactions/create']);
    }
  }


 

  


 
}
