import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectsService } from 'app/main/Services/projects.service';
import { error } from 'console';
import { environment } from 'environments/environment';
// @ts-ignore
import Resumable from "resumablejs";
@Component({
  selector: 'ai-transcription',
  templateUrl: './ai-transcription.component.html',
  styleUrls: ['./ai-transcription.component.scss']
})
export class AITranscriptionComponent implements OnInit,AfterViewInit {
  @ViewChild('imgRef') imgRef;
  @ViewChild('browseButton') browseButton;
  resumable: Resumable;
  supportResumable:boolean=true;
  maxFileAllowed:number=1;
  chunkSize = 1024  * 1024;
  fileRestrict = ['flac', 'm4a', 'mp3', 'mp4', 'mpeg', 'mpga', 'oga', 'ogg', 'wav', 'webm'];
  file_multiple_data:any=[];
  fileError: string = '';
  file_id_response:number;
  updated_data: any = {};
  fileSuccess: string;
  videoUrl:any="";
  progressBarConfig: any = {
      percentage: 0
  }

  constructor(
    private projectsService: ProjectsService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
      
  }


  ngAfterViewInit() {
      this.setQuery();
  }

  onFileUpload() { 
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      this.fileError = '';
        let filecheck = ['mp3'];
        var re = /(?:\.([^.]+))?$/;
        var files = fileUpload.files;
        var file;
        this.videoUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
          // loop through files
        for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if(file && filecheck.indexOf(ext) != -1) {
                    let size = file.size/1024/1024;
                    if (size > 20) {
                        this.fileError = 'File size should not be more than 20MB.';
                        return;
                    }
                    this.file_multiple_data.push(file);
                }else{
                  this.fileError = 'Invalid file type.';
                  return;
                }
        } 
    };
    fileUpload.click();
  }

  setQuery(){
    let query: any = {}
    let fileUploadOptions: Resumable.ConfigurationHash = {
        simultaneousUploads: 1,
        maxFiles: this.maxFileAllowed,
        fileParameterName: 'file',
        chunkSize: this.chunkSize,
        forceChunkSize: true,
        query,
        headers: {},
        fileType: this.fileRestrict,
        fileTypeErrorCallback: this.onFileTypeError.bind(this)
    };
    this.resumable = new Resumable(fileUploadOptions);
    this.supportResumable = this.resumable.support;
    if (this.supportResumable) {
        this.resumable = new Resumable(fileUploadOptions);
        this.resumable.assignBrowse(this.imgRef.nativeElement, false);
        this.resumable.assignBrowse(this.browseButton._elementRef.nativeElement, false);
        this.assignDragNDrop();
    }
    this.setEvents();
  }

  //function to add events
  setEvents() {
    this.resumable.on('fileAdded', this.onFileAdded.bind(this));
    this.resumable.on('error', this.onError.bind(this));
    this.resumable.on('fileError', this.onFileError.bind(this));
    this.resumable.on('cancel', this.onUploadCancel.bind(this));
    this.resumable.on('fileSuccess', this.onFileSuccess.bind(this));
    this.resumable.on('complete', this.onComplete.bind(this));
  }

  //on file added callback
  onFileAdded(file: any, event) {
      file['resumableObj'].opts.target = `${environment.whisperUrl}/resumable_upload`;
      if (file['resumableObj'].opts.query.docId) {
          delete file['resumableObj'].opts.query.docId;
      }
      let resumableFile: any = this.resumable.files[0];
      resumableFile.fileReader = new FileReader();
      resumableFile.fileReader.readAsDataURL(file.file);
      this.fileSuccess="";
      this.fileError="";
      this.dataResponse="";
      this.videoUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file.file));
      resumableFile.fileReader.addEventListener('load', ()=> {
          this.progressBarConfig.percentage=0;
      });
  }


  /**
   * return individual file upload progress 
   */
  parseProgress(file: any) {
      this.progressBarConfig.percentage = Math.round(Math.floor(file.progress(false) * 100) * 10) / 10;
      return true;
  }


  onError(){
  }

  uploadTheFile() {
      this.resumable.upload();
  }


  pauseFile() {
      this.resumable.pause();
  }

  cancelUpload() {
      this.videoUrl="";
      this.duration="";
      this.resumable.cancel();
  }
  onFileError(){
    this.videoUrl="";
    this.duration="";
  }

  onUploadCancel(){
    this.videoUrl="";
    this.duration="";
  }

  subtitlingIsInProgress:boolean=false;
  onFileSuccess($event,message){
    this.subtitlingIsInProgress=true;
    this.projectsService.whisperGetSubTitlesByUrl({file:message}).subscribe((response) => {
        this.subtitlingIsInProgress=false;
        this.dataResponse=response;
        this.fileSuccess ='Request successful!!';
    });
  }

  onComplete($event){
    console.log($event)
  }

  assignDragNDrop() {
        this.resumable.assignDrop(
            this.imgRef.nativeElement
        );
  }

  onFileTypeError($event){

  }

  dataResponse="";
  uploadDocument() {
        this.fileSuccess = '';
        var uploadData = new FormData();
        var ins = this.file_multiple_data.length;
        for (var x = 0; x < ins; x++) {
          uploadData.append('file', this.file_multiple_data[x]);
        }
        this.projectsService.whisperUploadFile(uploadData).subscribe((response) => {
            this.file_multiple_data=[];
            this.dataResponse=response;
            console.log('response',response)
            this.fileSuccess ='Request successful!!';
        });
  }

  duration="";
  getDuration(e) {
    this.duration = e.target.duration;
  }

  toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
  
    return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes.toFixed(2))}`;
  }
  
  padTo2Digits(num) {
    return parseInt(num.toString().padStart(2, '0'));
  }
}