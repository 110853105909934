<div class="page-layout w100 p-24 bg-white" fusePerfectScrollbar>

  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
    <div fxFlex="20">
    </div>

    <div fxFlex="78">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex>
              <h2>Report</h2>
            </div>
            <div fxFlex class="text-right">Total {{total_due_today}}<b></b> Records Available</div>
          </div>
          <div class="filterTag pt-12"></div>
        </div>
        <div class="tHeader">
          <table mat-table [dataSource]="dataSource" class="table">
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> ID</mat-header-cell>
                  <mat-cell *matCellDef="let report"  fxHide fxShow.gt-sm fxFlex="12">
                      <p class="text-truncate">{{report?.id}}</p>
                  </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="short_url">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="30"> Short Url</mat-header-cell>
                  <mat-cell *matCellDef="let report"  fxHide fxShow.gt-sm fxFlex="30">
                      <p class="text-truncate">https://daytv.me/d/{{report?.short_url}}/{{report?.secret_key}}</p>
                  </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="secret_key">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="5"></mat-header-cell>
                  <mat-cell *matCellDef="let report"  fxHide fxShow.gt-sm fxFlex="5">
                      <p class="text-truncate"></p>
                  </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="long_url">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20"> Link  </mat-header-cell>
                  <mat-cell *matCellDef="let report"  fxHide fxShow.gt-sm fxFlex="20">
                      <p class="text-truncate"><a href="{{report?.long_url}}" target="blank">Link</a> </p>
                  </mat-cell>
            </ng-container>

            <ng-container matColumnDef="clicks">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> Clicks </mat-header-cell>
                  <mat-cell *matCellDef="let report"  fxHide fxShow.gt-sm fxFlex="12">
                      <p class="text-truncate">{{report?.clicks}} </p>
                  </mat-cell>
            </ng-container>

            <ng-container matColumnDef="created_at">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16"> Created At </mat-header-cell>
                  <mat-cell *matCellDef="let report"  fxHide fxShow.gt-sm fxFlex="16">
                      <p class="text-truncate">{{report?.created_at}} </p>
                  </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
            <mat-row *matRowDef="let report; columns: displayedColumns;" class="report fList">
            </mat-row>

          </table>

          <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[20,30,40,50,60]" (page)='paginateData($event)'>
          </mat-paginator>

        </div>

      </div>

    </div>

  </div>

</div>