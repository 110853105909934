import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-release',
  templateUrl: './project-release.component.html',
  styleUrls: ['./project-release.component.scss']
})
export class ProjectReleaseComponent implements OnInit {
  form: FormGroup;
  constructor(private _formBuilder: FormBuilder, private vendorService : VendorService,
    private toastr: ToastrService, private projectsService: ProjectsService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ProjectReleaseComponent>,private router: Router,
    @Inject(MAT_DIALOG_DATA) public data :any) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      
      message   : ['',Validators.compose([Validators.required])]
      
  });
  }

  Onclose(){
    this.dialogRef.close();
}

// Submit


submit() {
 
 let ReleaseData:any={
  message:'',
  project_id:''
 }
    ReleaseData=this.form.value;
  ReleaseData.project_id=this.data.project_id;


  this.projectsService.projectRelease(ReleaseData)
  .subscribe((response) => {

      this.toastr.success("Project Release Successfully");
       
      this.router.navigate(['/pms/dashboard']);
          this.dialogRef.close(true);
      
  });
}


}
