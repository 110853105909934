import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
var ConfirmationDialogComponent = /** @class */ (function () {
    function ConfirmationDialogComponent(dialogRef, projectsService, toastr, router, data) {
        this.dialogRef = dialogRef;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.router = router;
        this.data = data;
    }
    ConfirmationDialogComponent.prototype.ngOnInit = function () {
    };
    ConfirmationDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    // onYesClick(): void {
    //   this.dialogRef.close();
    // }
    // Delete Additional Service
    ConfirmationDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        this.projectsService.deleteProject(this.data.project_id).subscribe(function (response) {
            if (response) {
                _this.toastr.success("Delete Project  Successfully!!");
                _this.dialogRef.close();
                _this.router.navigate(['/pms/dashboard']);
            }
        });
    };
    return ConfirmationDialogComponent;
}());
export { ConfirmationDialogComponent };
