import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
var SmsComponent = /** @class */ (function () {
    function SmsComponent(route, projectsService, _fuseProgressBarService, toastr, _formBuilder) {
        this.route = route;
        this.projectsService = projectsService;
        this._fuseProgressBarService = _fuseProgressBarService;
        this.toastr = toastr;
        this._formBuilder = _formBuilder;
        this.paginate = {};
        this.pageSize = 20;
        this.pageIndex = 1;
        this.mainProgressbarhistory = false;
        this.query = "";
        this.filterSearchQuery = "";
        this.fQuery = "";
        this.displayedColumns = ['id', 'from', 'to', 'body', 'created_at'];
    }
    SmsComponent.prototype.ngOnInit = function () {
        //     this.getSmsTwillo();
        //     this.Form = this._formBuilder.group({ 
        //       search_value: ['']
        //   });
        //   this.smsForm = this._formBuilder.group({ 
        //     to : ['', Validators.compose([Validators.required])],
        //     body : ['', Validators.compose([Validators.required])]
        // });
    };
    // OCR HISTORY
    SmsComponent.prototype.getSmsTwillo = function () {
        var _this = this;
        this.mainProgressbarhistory = true;
        var query = '';
        this.projectsService.getSmsTwillo(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.mainProgressbarhistory = false;
                // this.totalticket=response.meta.pagination.total;
            }
        }, function (error) {
            // this.toastr.error("Something went wrong, Please try later!");
            _this.mainProgressbarhistory = false;
        });
    };
    SmsComponent.prototype.paginateData = function (event) {
        var _this = this;
        this.mainProgressbarhistory = true;
        var pQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query += pQuery;
        if (this.Form.value.search_value !== "") {
            this.query += "&search_text=" + this.Form.value.search_value;
        }
        this.projectsService.getSmsTwillo(this.query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.mainProgressbarhistory = false;
            }
        }, function (error) {
            //  this.toastr.error("Something went wrong, Please try later!");
            _this.mainProgressbarhistory = false;
        });
    };
    SmsComponent.prototype.submit = function () {
        this.onFilter(this.Form.value.search_value, 'search');
    };
    SmsComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.mainProgressbarhistory = true;
        this.fQuery = '?page=1&per_page=10';
        // project search filter
        if ((type == "search" && evt != "" && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        else if (type == "search" && evt == "") {
            this.filterSearchQuery = evt;
        }
        else if (this.filterSearchQuery !== "") {
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        var query = this.fQuery;
        this.projectsService.getSmsTwillo(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.mainProgressbarhistory = false;
            }
        }, function (error) {
            //  this.toastr.error("Something went wrong, Please try later!");
            _this.mainProgressbarhistory = false;
        });
    };
    SmsComponent.prototype.sendSms = function () {
        var _this = this;
        if (this.smsForm.value.to == '') {
            this.toastr.error('Phone is required');
            return false;
        }
        if (this.smsForm.value.from == '') {
            this.toastr.error('Message is required');
            return false;
        }
        var data = {
            to: this.smsForm.value.to,
            body: this.smsForm.value.body
        };
        this.projectsService.sendMessage(data)
            .subscribe(function (response) {
            if (response) {
                _this.smsForm.patchValue({
                    to: '',
                    body: ''
                });
                _this.toastr.success('Message sent!!');
                _this.getSmsTwillo();
            }
        }, function (error) {
            _this.mainProgressbarhistory = false;
            _this.toastr.error(error.error.message);
        });
    };
    return SmsComponent;
}());
export { SmsComponent };
