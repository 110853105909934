import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
export interface PeriodicElement {
  id: string;
  name: string;
  cust_email:string;
  savedDate: string;
  paymentMethod: string;
  status: string;
  addressCountry: string;
  categories: string;
  leadSource:string;

}

const ELEMENT_DATA: PeriodicElement[] = [
  {id: '1832648141', name: 'Raghvandra Kumar', cust_email:'cname@domail.com',savedDate: '08/03/2019', paymentMethod: '**0896', categories:'DayVIP', status:'Active', addressCountry:'SpainMadrid',leadSource:'DT Web'},
  {id: 'D000475', name: 'R	jane smithe',cust_email:'cname@domail.com', savedDate: '06/03/2016', paymentMethod: 'NET30', categories:'DayVIP', status:'Active', addressCountry:'Delhi, Pari',leadSource:'DT Web'},
  {id: 'D000475', name: 'R	jane smithe', cust_email:'cname@domail.com', savedDate: '06/03/2016', paymentMethod: 'NET45', categories:'Company', status:'Active', addressCountry:'Smalka, Pari',leadSource:'DT Web'},
  {id: 'D000475', name: 'R	jane smithe', cust_email:'cname@domail.com', savedDate: '06/03/2016', paymentMethod: 'Upfront', categories:'Company', status:'Active', addressCountry:'Delhi, Pari',leadSource:'DT Web'},
  {id: 'D000475', name: 'R	jane smithe', cust_email:'cname@domail.com', savedDate: '06/03/2016', paymentMethod: 'Upfront', categories:'Individual', status:'Active', addressCountry:'Spain, Pari',leadSource:'DT Web'},
  {id: 'D000475', name: 'R	jane smithe', cust_email:'cname@domail.com', savedDate: '06/03/2016', paymentMethod: 'Upfront', categories:'Individual', status:'Active', addressCountry:'Paris, Arri',leadSource:'DT Web'},
  
];
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  dataSource: any;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  fQuery: string = '';
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild('filter')
  filter: ElementRef;
  paginate:any = {};
  query_quote:string = '';
  pageSize:number = 20;
  pageIndex:number = 1;
  Form: FormGroup;
  languageData: any = [];
  total_customer:number = 0;
  filter_src_lang: string = '';
  filter_target_lang: string = '';
  filterLeadSource: string = '';
  filterProjectManager: string = '';
  projectmanager: any = [];
  projectLeadsource: any = [];
  filterCity:string='';
  filterPaymentMethod:string='';
  filterCategory:string = '';
  filterCountry:string = '';
  filterEndDate:string = '';
  filterStartDate:string = '';
  filterProjectStatus:string = '';
  filterSearchQuery:string = '';
  fChipsArray: any = [];
  displayedColumns: string[] = ['id', 'name', 'cust_email', 'savedDate', 'paymentMethod', 'status', 'addressCountry', 'categories', 'leadSource', 'action'];
 
  constructor(private projectsService: ProjectsService,private toastr: ToastrService,private _formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.getData();
    this.getLanguage();
    this.getProjectSource();
    this.getProjectManager();
    this.Form = this._formBuilder.group({ 
      search_value: [''],
  });

  }

  getData() {
    let query = '?per_page=20';
    this.projectsService.getProjectCustomer(query)
    .subscribe((response) => {
        if(response) {
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.sort = this.sort;
            this.paginate = response.meta.pagination;
            this.total_customer = response.meta.pagination.total;
        }
    });
  }


  paginateData(event) {
    let pQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if(this.pageIndex < 0) {
        this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
   this.query_quote = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
   this.query_quote+=pQuery;
    this.projectsService.getProjectCustomer(this.query_quote)
    .subscribe((response) => {
    if(response) {
     
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.sort = this.sort;
      this.paginate = response.meta.pagination;
      this.total_customer = response.meta.pagination.total;
     }
  });
  }

  // get Source Language & Target Language 

  getLanguage() {
    this.projectsService.getAllLanguages()
    .subscribe((res:any) => {
        if(res) {
          this.languageData = res.data;
        }
    });
  }


  onFilter(evt,type){
    this.fQuery = '?page=1&per_page=10'
    // project search filter
    if ((type == 'search' && evt != '' && evt !== undefined)) {
      this.filterSearchQuery = evt;
      this.fQuery += '&search_text=' + this.filterSearchQuery;
     }
    else if(type == 'search' && evt == ''){
      this.filterSearchQuery = evt;
    }
    else if(this.filterSearchQuery!==''){
      this.fQuery += '&search_text=' + this.filterSearchQuery;
    }  

    // project status filter
    if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
      this.filterProjectStatus = evt.value;
      this.fQuery += '&status=' + this.filterProjectStatus;
     }
    else if(type == 'status' && evt.value == ''){
      this.filterProjectStatus = evt.value;
    }
    else if(this.filterProjectStatus!==''){
      this.fQuery += '&status=' + this.filterProjectStatus;
    }  

    // project Source Language filter 
    if ((type == 's_lang' && evt.value != '' && evt.value !== undefined)) {
    this.filter_src_lang = evt.value;
    this.fQuery += '&source_language=' + this.filter_src_lang;
    }
    else if(type == 's_lang' && evt.value == ''){
    this.filter_src_lang = evt.value;
    }
    else if(this.filter_src_lang!==''){
    this.fQuery += '&source_language=' + this.filter_src_lang;
    }  
  
  // project Target Language filter
    if ((type == 't_lang' && evt.value != '' && evt.value !== undefined)) {
    this.filter_target_lang = evt.value;
    this.fQuery += '&target_language=' + this.filter_target_lang;
    }
    else if(type == 't_lang' && evt.value == ''){
    this.filter_target_lang = evt.value; 
     }
     else if(this.filter_target_lang!==''){
    this.fQuery += '&target_language=' + this.filter_target_lang;
     }  
    if ((type == 'city' && evt.trim().toLowerCase() != '' && evt.trim().toLowerCase() !== undefined)) {
      this.filterCity = evt.trim().toLowerCase();
     
      this.fQuery += '&city=' + this.filterCity;
  }
  else if(type == 'city' && evt.trim().toLowerCase() == ''){
      this.filterCity = evt.trim().toLowerCase();
      
  }
  else if(this.filterCity!==''){
      this.fQuery += '&city=' + this.filterCity;
  }   


   // project country filter
    if ((type == 'country' && evt.value != '' && evt.value !== undefined)) {
      this.filterCountry = evt.value;
       this.fQuery += '&country=' + this.filterCountry;
    }
    else if(type == 'country' && evt.value == ''){
    this.filterCountry = evt.value; 
    }
    else if(this.filterCountry!==''){
     this.fQuery += '&country=' + this.filterCountry;
    }   

    // project Project Manager filter

    if ((type == 'ProjectManager' && evt.value != '' && evt.value !== undefined)) {
      this.filterProjectManager = evt.value;
       this.fQuery += '&project_pm=' + this.filterProjectManager;
    }
    else if(type == 'ProjectManager' && evt.value == ''){
    this.filterProjectManager = evt.value;
    }
    else if(this.filterProjectManager!==''){
     this.fQuery += '&project_pm=' + this.filterProjectManager;
    }  

// project lead source filter

  if ((type == 'lead_source' && evt.value != '' && evt.value !== undefined)) {
  this.filterLeadSource = evt.value;
  this.fQuery += '&source=' + this.filterLeadSource;
  }
  else if(type == 'lead_source' && evt.value == ''){
  this.filterLeadSource = evt.value;
  }
  else if(this.filterLeadSource!==''){
  this.fQuery += '&source=' + this.filterLeadSource;
  }  
  
// project category filter

  if ((type == 'category' && evt.value != '' && evt.value !== undefined)) {
  this.filterCategory = evt.value;
  this.fQuery += '&category=' + this.filterCategory;
  }
  else if(type == 'category' && evt.value == ''){
  this.filterCategory = evt.value;
  }
  else if(this.filterCategory!==''){
  this.fQuery += '&category=' + this.filterCategory;
  }  

  // Payment Method filter

  if ((type == 'payment_method' && evt.value != '' && evt.value !== undefined)) {
    this.filterPaymentMethod = evt.value;
    this.fQuery += '&payment_method=' + this.filterPaymentMethod;
    }
    else if(type == 'payment_method' && evt.value == ''){
    this.filterPaymentMethod = evt.value;
    }
    else if(this.filterPaymentMethod!==''){
      this.fQuery += '&payment_method=' + this.filterPaymentMethod;
      }  


      
        // Start date filter

  if ((type == 'start_date' && evt.value != '' && evt.value !== undefined)) {
   
    this.filterStartDate = evt.value.format('YYYY-MM-DD');
    this.fQuery += '&start_date=' + this.filterStartDate;
    }
    else if(type == 'start_date' && evt.value == ''){
    this.filterStartDate = evt.value;
    }
    else if(this.filterStartDate!==''){

      this.fQuery += '&start_date=' + this.filterStartDate;
      }  
  


        // End date filter

  if ((type == 'end_date' && evt.value != '' && evt.value !== undefined)) {
   
    this.filterEndDate = evt.value.format('YYYY-MM-DD');
    this.fQuery += '&end_date=' + this.filterEndDate;
    }
    else if(type == 'end_date' && evt.value == ''){
    this.filterEndDate = evt.value;
    }
    else if(this.filterEndDate!==''){

      this.fQuery += '&end_date=' + this.filterEndDate;
      }  

      this.parseQueryString(this.fQuery);
      let query = this.fQuery;
      this.projectsService.getProjectCustomer(query)
      .subscribe((response) => {
          if(response) {
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.sort = this.sort;
              this.paginate = response.meta.pagination;
          }
      });
    }


        // get project source
        getProjectSource() {
          this.projectsService.getLeadSource()
          .subscribe((res:any) => {
              if(res) {
                this.projectLeadsource=res.data;
              }
          });
        }

              // get project manager
getProjectManager() {
  this.projectsService.getProjectManager()
  .subscribe((res:any) => {
      if(res) {
        res.data.forEach(element => {
          if(element.user_group_id=='Admin'){
            this.projectmanager.push(element.fullname);
          }
         
        });
     
      }
    });
  }


  submit(){
    let data=encodeURIComponent(this.Form.value.search_value);
    this.onFilter(data,'search');
  }
  

   // query string

   parseQueryString(queryString) {

    this.fChipsArray=[];
    var params = {
      search_text: '',
      status: '',
      source_language: '',
      target_language: '',
      city: '',
      country: '',
      project_pm: '',
      source: '',
      category: '',
      speciality: '',
      start_date: '',
      end_date: '',
    }, 
    queries, temp, i, l;

    // Split into key/value pairs
    queries = queryString.split('&');
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
    
  
      this.get_filter_value(params)
}


get_filter_value(data){
  let key = Object.keys(data);

  if (key[0] == 'search_text')
  {
    if(data.search_text!=''){
      this.fChipsArray.push(decodeURIComponent(data.search_text));
    }
     
  }

  if (key[1] == 'status')
  {
        if (data.status == 1) {
          this.fChipsArray.push('Active');
        }else if (data.status == 2) {
          this.fChipsArray.push('InActive');
        }
        else if (data.status == 3) {
          this.fChipsArray.push('Potential');
        }
  }

  if (key[2] == 'source_language')
  {
        this.languageData.forEach(element => {
        if (data.source_language == element.id) {
          this.fChipsArray.push(element.lang_name);
        }

      });

     
    
  }

  if (key[3] == 'target_language')
  {
        this.languageData.forEach(element => {
        if (data.target_language == element.id) {
          this.fChipsArray.push(element.lang_name);
        }

      });
    
  }
  if (key[4] == 'city')
  {
    if(data.city!=''){
      this.fChipsArray.push(data.city);
    }
    
  }

  if (key[5] == 'country')
  {

    if (data.country !='') {
      this.fChipsArray.push(data.country);
    }
  }

  if (key[6] == 'project_pm')
  {
    if (data.project_pm !='') {
      this.fChipsArray.push(data.project_pm);
    }
    
  }

  if (key[7] == 'source')
  {
  

    
    this.projectLeadsource.forEach(element => {
      if (data.source == element.id) {
        this.fChipsArray.push(element.name);
      }

    });
  
    
  }

  if (key[8] == 'category')
  {
        if (data.category == 1) {
          this.fChipsArray.push('DayVIP');
        }else if (data.category == 2) {
          this.fChipsArray.push('Company');
        }
        if (data.category == 3) {
          this.fChipsArray.push('Individual');
        }else if (data.category == 4) {
          this.fChipsArray.push('New Client');
        }
        
  }

  
  if (key[9] == 'payment_method')
  {
        if (data.payment_method == 1) {
          this.fChipsArray.push('NET30');
        }else if (data.payment_method == 2) {
          this.fChipsArray.push('NET45');
        }
        if (data.payment_method == 3) {
          this.fChipsArray.push('Regular/Upfront');
        }else if (data.payment_method == 4) {
          this.fChipsArray.push('Credit Available');
        }
        
  }

  if (key[10] == 'start_date')
  {
    if (data.start_date !='') {
      this.fChipsArray.push(data.start_date);
    }
    
  }

  if (key[11] == 'end_date')
  {
    if (data.end_date !='') {
      this.fChipsArray.push(data.end_date);
    }
    
  }
}
}
