import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  //workflow
  private workflowSource = new BehaviorSubject('workflow default message');
  currentWorkflowMessage = this.workflowSource.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeWorkflow(message: string) {
    this.workflowSource.next(message)
  }
}
