import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
var AddAccountComponent = /** @class */ (function () {
    function AddAccountComponent(projectsService, toastr, router, route, _formBuilder) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this._formBuilder = _formBuilder;
        this.portalUsers = [];
        this.accountType = [];
        this.shipping_id = null;
        this.billing_id = null;
        this.accountAddress = [];
    }
    AddAccountComponent.prototype.ngOnInit = function () {
        this.getPortalUsers();
        this.getAccountType();
        this.Form = this._formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            company: [''],
            website: [''],
            office_phone: [''],
            account_type: ['', Validators.compose([Validators.required])],
            sic_code: [''],
            assigned_to: [''],
            billing_street: [''],
            billing_city: [''],
            billing_state: [''],
            billing_country: [''],
            billing_zip_code: [''],
            shipping_street: [''],
            shipping_city: [''],
            shipping_state: [''],
            shipping_country: [''],
            shipping_zip_code: ['']
        });
    };
    AddAccountComponent.prototype.getPortalUsers = function () {
        var _this = this;
        var Query = 'page=1&per_page=30&';
        this.projectsService.getPortalUsers(Query)
            .subscribe(function (res) {
            if (res) {
                _this.portalUsers = res.data;
            }
        });
    };
    // get Account Type
    AddAccountComponent.prototype.getAccountType = function () {
        var _this = this;
        this.projectsService.getAccountType()
            .subscribe(function (res) {
            if (res) {
                _this.accountType = res.data;
            }
        });
    };
    AddAccountComponent.prototype.submit = function () {
        var _this = this;
        this.accountAddress = [];
        var billing_address = {
            id: this.billing_id,
            type: "Billing",
            street: this.Form.value.billing_street,
            city: this.Form.value.billing_city,
            state: this.Form.value.billing_state,
            postal_code: this.Form.value.billing_zip_code,
            country: this.Form.value.billing_country
        };
        var shipping_address = {
            id: this.shipping_id,
            type: "Shipping",
            street: this.Form.value.shipping_street,
            city: this.Form.value.shipping_city,
            state: this.Form.value.shipping_state,
            postal_code: this.Form.value.shipping_zip_code,
            country: this.Form.value.shipping_country
        };
        if (this.Form.value.billing_street != '' || this.Form.value.billing_city != '' || this.Form.value.billing_state != ''
            || this.Form.value.billing_zip_code != '' || this.Form.value.billing_country != '') {
            this.accountAddress.push(billing_address);
        }
        if (this.Form.value.shipping_street != '' || this.Form.value.shipping_city != '' || this.Form.value.shipping_state != ''
            || this.Form.value.shipping_zip_code != '' || this.Form.value.shipping_country != '') {
            this.accountAddress.push(shipping_address);
        }
        var final_data = {
            account_type: this.Form.value.account_type,
            alliance_discount: '',
            name: this.Form.value.name,
            email: this.Form.value.email,
            industry_id: '',
            company: this.Form.value.company,
            company_logo: '',
            office_phone: this.Form.value.office_phone,
            website: this.Form.value.website,
            employees_count: '',
            annual_revenue: '',
            sic_code: this.Form.value.sic_code,
            rating: '',
            crm_ref_id: '',
            assigned_to: this.Form.value.assigned_to,
            created_by: '',
            account_address: this.accountAddress
        };
        this.projectsService.addAccount(final_data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Account Details added!!");
                _this.router.navigate(['/pms/account']);
            }
        });
    };
    return AddAccountComponent;
}());
export { AddAccountComponent };
