import { PipeTransform } from '@angular/core';
var FileSizePipe = /** @class */ (function () {
    function FileSizePipe() {
    }
    FileSizePipe.prototype.transform = function (size, extension) {
        if (extension === void 0) { extension = 'MB'; }
        return (size / (1024 * 1024)).toFixed(2) + extension;
    };
    return FileSizePipe;
}());
export { FileSizePipe };
