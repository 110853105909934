import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './../../../Services/user.service';
import { OnInit } from '@angular/core';
import { SharedService } from 'app/main/shared.service';
import { environment } from 'environments/environment';
var TicketDetailComponent = /** @class */ (function () {
    function TicketDetailComponent(userservice, toastr, sharedService, activeRoute, router, fb) {
        this.userservice = userservice;
        this.toastr = toastr;
        this.sharedService = sharedService;
        this.activeRoute = activeRoute;
        this.router = router;
        this.fb = fb;
        this.file_url = "";
        this.files = [];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    TicketDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activeRoute.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.getTicketDetails();
        this.Form = this.fb.group({
            status: ['', Validators.required],
            support_message: ['', Validators.required]
        });
    };
    TicketDetailComponent.prototype.getTicketDetails = function () {
        var _this = this;
        this.userservice.getallTicketbyId(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.detail = response.data;
                _this.files = [];
                response.data.ticket_attachment.data.forEach(function (element) {
                    var obj = {
                        file_name: '',
                        file_url: ''
                    };
                    obj.file_name = element.file.data.name;
                    obj.file_url = _this.BaseUrl_file + element.file.data.path;
                    _this.files.push(obj);
                });
            }
        }, function (error) {
            console.log(error);
        });
    };
    TicketDetailComponent.prototype.back = function () {
        this.router.navigate(['/tickets']);
    };
    TicketDetailComponent.prototype.Submit = function () {
        var _this = this;
        if (this.Form.value.status == '') {
            this.toastr.error("Please select status !!");
            return false;
        }
        if (this.Form.value.support_message == '') {
            this.toastr.error("Please select support message!!");
            return false;
        }
        var statusdata = this.Form.value;
        this.userservice.updateTicketStatus(this.id, statusdata)
            .subscribe(function (response) {
            if (response) {
                _this.Form.patchValue({
                    status: [''],
                    support_message: [''],
                });
                _this.getTicketDetails();
                _this.toastr.success("Status Updated Successfully");
            }
        });
    };
    return TicketDetailComponent;
}());
export { TicketDetailComponent };
