<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
    <div fxFlex="30">
      <div class="white-box plainBox">
        <div class="tHeader">
          <h2><i class="material-icons">filter_list</i> Sort by</h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="100">
                <mat-form-field  >
                  <mat-label>Search by ID/Name/Email/Phone</mat-label>
                  <input matInput (keyup)="onFilterSpeciality($event.target.value,'search')">
                </mat-form-field>
              </div>
            </div>
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Service provided is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select placeholder="Select Field">
                    <mat-option value="option1">Any</mat-option>
                    <mat-option value="option2">Translation</mat-option>
                    <mat-option value="option3">Certification</mat-option>
                    <mat-option value="option4">DTP</mat-option>
                    <mat-option value="option4">Subtitling</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Native Language is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-label>Select Field</mat-label>
                  <mat-select (selectionChange)="onFilterSpeciality($event,'native_lang')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.id">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Source Language is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-label>Select Field</mat-label>
                  <mat-select (selectionChange)="onFilterSpeciality($event,'s_lang')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Target Language is </b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-label>Select Field</mat-label>
                  <mat-select (selectionChange)="onFilterSpeciality($event,'t_lang')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="68">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">
            <h2>
              <mat-icon>how_to_reg</mat-icon>Admin Approval Pending
            </h2>
          </div>
          <div class="filterTag pt-12">
            <mat-chip-list #chipList aria-label="QuotesChip selection">
              <mat-chip *ngFor="let QuotesChip of fChipsArrayApprove" [selectable]="selectable" [removable]="removable">
                {{QuotesChip}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div class="content-card" *ngIf="paginate?.total>0">
          <mat-table class="products-table " #table [dataSource]="dataSource" matSort
            (matSortChange)="sortFreelancer($event)" matSort fusePerfectScrollbar>
            <ng-container matColumnDef="first_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="24">Name</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="24">
                <button mat-button routerLink="/approval/review-translator/{{ product.id }}" class="btn-link">
                  <span class=" text-truncate"> {{product.first_name}} {{product.last_name}} </span>
                </button>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20">Email</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                <span class="email text-truncate">
                  {{product.email}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="native_language">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="12">Native Language</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                <span *ngIf="product.vendornativelanguage!=null">
                  {{product.vendornativelanguage.data.lang_name}}
                </span>
                <span *ngIf="product.vendornativelanguage==null">
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="language">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md fxFlex="32">Language Pair</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-md fxFlex="32">
                <span class="price text-truncate">
                  <span *ngFor="let res of product.lang_array">
                    <p>{{res}}</p>
                  </span></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="stage">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs fxFlex="13">Status</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="13">
                <span class="bdg ylbg" *ngIf="product.stage!=null">
                  {{product.stage.data.name}}
                </span>
                <span class="bdg ylbg" *ngIf="product.stage==null">
                </span>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="VendordisplayedColumns; sticky:true"></mat-header-row>
            <mat-row *matRowDef="let product; columns: VendordisplayedColumns;" class="product fList">
            </mat-row>
          </mat-table>
          <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
            [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[ 10, 15, 20]"
            (page)='paginateData($event)'>
          </mat-paginator>
        </div>
        <div class="noDataFound" *ngIf="paginate?.total==0">
          No data available!!
        </div>
      </div>
    </div>
  </div>
</div>