import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'app/main/app.global';
import { Router, ActivatedRoute } from '@angular/router';
var SearchStripeTransactionComponent = /** @class */ (function () {
    function SearchStripeTransactionComponent(_formBuilder, router, projectsService, toastr, _global, route) {
        this._formBuilder = _formBuilder;
        this.router = router;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this._global = _global;
        this.route = route;
        this.show_record = false;
        this.displayedColumns = ['id', 'amount', 'status', 'cardholderName', 'creditCardNumber', 'email', 'date'];
    }
    SearchStripeTransactionComponent.prototype.ngOnInit = function () {
        this.quote_id = this.route.snapshot.params['id'];
        this.searchTransactionForm = this._formBuilder.group({
            email: ["", Validators.compose([Validators.required, Validators.email]),]
        });
    };
    SearchStripeTransactionComponent.prototype.search = function () {
        var _this = this;
        var formData = this.searchTransactionForm.value;
        this.show_record = true;
        this.projectsService.searchStripeTransaction(formData)
            .subscribe(function (response) {
            if (response) {
                //console.log(response);
                if (response.data.length > 0) {
                    _this.totaltransaction = response.data.length;
                    _this.dataSource = new MatTableDataSource(response.data);
                    _this.dataSource.paginator = _this.paginator;
                    _this.noRecord = '';
                }
                else {
                    _this.dataSource = '';
                    _this.noRecord = 'no record found';
                }
            }
        }, function (error) {
            _this.toastr.error(_this._global.errorMessage);
        });
    };
    SearchStripeTransactionComponent.prototype.searchTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/stripetransactions/search/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/stripetransactions/search']);
        }
    };
    SearchStripeTransactionComponent.prototype.CreateTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/stripetransactions/create/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/stripetransactions/create']);
        }
    };
    return SearchStripeTransactionComponent;
}());
export { SearchStripeTransactionComponent };
