import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { UserService } from 'app/main/Services/user.service';
var AddAdditionalServicesComponent = /** @class */ (function () {
    function AddAdditionalServicesComponent(fb, projectsService, toastr, route, userService) {
        this.fb = fb;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.route = route;
        this.userService = userService;
        this.show_apostille_country = false;
        this.show_second_address_field = false;
        this.show_notarization_no_of_copy = false;
        this.show_notarization_other_state = false;
        this.show_apostille_state = false;
        this.show_mailing_address = false;
        this.show_pickup_address = false;
        this.show_single_pickup_address = false;
        this.checkNumberOfCopies = 0;
        this.checkStateValue = '';
        this.locationData = [];
        this.show_second_address = false;
        this.show_mailing_copy = false;
        this.selectPickUpValue = '';
        this.project_additional_service = [];
        this.project_additional_service_mailing = [];
        this.second_address_mailing = [];
        this.check_apostille = {
            value: ''
        };
        this.check_mailing = {
            street_address: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            phone_number: '',
            no_of_copy: ''
        };
        this.check_no_mailing = {
            no_of_copy: ''
        };
        this.mailing_second_address = {
            street_address: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            phone_number: '',
            no_of_copy: ''
        };
        this.isSelectMailing = '';
        this.secondAddressValue = '';
        this.show_additional_notarization = false;
        this.show_additional_notarization_no_of_copy = false;
        this.additional_notarization_checkNumberOfCopies = 0;
        this.mailing_address = [];
        this.shipping_address = {
            street: '',
            city: '',
            state: '',
            country: '',
            postal_code: ''
        };
        this.billing_address = {
            street: '',
            city: '',
            state: '',
            country: '',
            postal_code: ''
        };
    }
    AddAdditionalServicesComponent.prototype.ngOnInit = function () {
        this.getLocation();
        this.project_id = this.route.snapshot.params['id'];
        this.getMailingAddress();
        this.Form = this.fb.group({
            apostile_country_source: '',
            apostile_from_state: '',
            service_code: ''
        });
    };
    // Mailing Service Start
    AddAdditionalServicesComponent.prototype.getLocation = function () {
        var _this = this;
        var query = '';
        this.userService.getLocation(query)
            .subscribe(function (res) {
            if (res) {
                _this.locationData = res.data;
            }
        });
    };
    // get billing or shipping address
    AddAdditionalServicesComponent.prototype.getMailingAddress = function () {
        var _this = this;
        this.projectsService.getMailingAddress(this.project_id)
            .subscribe(function (res) {
            if (res) {
                _this.mailing_address = res.account;
                if (_this.mailing_address.length > 0) {
                    _this.mailing_address.forEach(function (element) {
                        element.accountaddress.forEach(function (obj) {
                            if (obj.address.type == 'Billing') {
                                _this.billing_address = obj.address;
                            }
                            else if (obj.address.type == 'Shipping') {
                                _this.shipping_address = obj.address;
                            }
                        });
                    });
                }
            }
        });
    };
    // end
    AddAdditionalServicesComponent.prototype.selectPickUp = function (evt) {
        var _this = this;
        this.show_second_address_field = false;
        this.project_additional_service_mailing = [];
        this.check_mailing = {
            street_address: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            phone_number: '',
            no_of_copy: ''
        };
        if (evt.value == '') {
            this.show_single_pickup_address = false;
            if (this.project_additional_service_mailing.length > 0) {
                var dataRemove = this.project_additional_service_mailing.filter(function (x) { return x.service_code == 'regular_PickUp'; });
                if (dataRemove.length > 0) {
                    this.project_additional_service_mailing.pop(dataRemove);
                }
            }
            return false;
        }
        this.selectPickUpValue = evt.value;
        this.userService.getSingleLocation(evt.value)
            .subscribe(function (res) {
            if (res) {
                var complete_obj = void 0;
                if (res.data.title) {
                    complete_obj = res.data.title;
                }
                if (res.data.complete_address) {
                    complete_obj += ', ' + res.data.complete_address;
                }
                if (res.data.city) {
                    complete_obj += ', ' + res.data.city;
                }
                if (res.data.country) {
                    complete_obj += ', ' + res.data.country;
                }
                if (res.data.zip) {
                    complete_obj += ', ' + res.data.zip;
                }
                var obj = {
                    project_id: _this.project_id,
                    service_code: 'regular_PickUp',
                    quantity: 1,
                    description: '',
                    shipping_address: '',
                    shipping_city: '',
                    shipping_state: '',
                    shipping_country: '',
                    shipping_phone: '',
                    second_address: '',
                    pickup_location: complete_obj,
                    notarization_other_state: '',
                    apostile_country_source: '',
                    apostile_from_state: '',
                };
                _this.project_additional_service_mailing.push(obj);
                _this.show_single_pickup_address = true;
                _this.singlelocation = res.data;
            }
        }, function (error) {
            _this.show_single_pickup_address = false;
            // this.toastr.error(error);
        });
    };
    AddAdditionalServicesComponent.prototype.setSecondAddress = function (event) {
        this.secondAddressValue = event.value;
        if (event.source.checked) {
            this.show_second_address_field = true;
        }
        else if (!event.source.checked) {
            this.show_second_address_field = false;
        }
    };
    AddAdditionalServicesComponent.prototype.mailingSetNumberOfCopies = function (event, value) {
        var dataRemove = this.project_additional_service_mailing.filter(function (x) { return x.service_code == value; });
        if (dataRemove.length > 0) {
            this.project_additional_service_mailing.pop(dataRemove);
        }
        // this.checkNumberOfCopies=event.target.value;
        var obj = {
            project_id: this.project_id,
            service_code: 'additional_copy',
            quantity: parseInt(event.target.value),
            description: '',
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_country: '',
            shipping_phone: '',
            second_address: '',
            pickup_location: '',
            notarization_other_state: '',
            apostile_country_source: '',
            apostile_from_state: '',
        };
        this.project_additional_service_mailing.push(obj);
    };
    AddAdditionalServicesComponent.prototype.check_mailing_service = function (evt, type) {
        this.show_second_address_field = false;
        this.show_mailing_copy = false;
        this.check_mailing = {
            street_address: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            phone_number: '',
            no_of_copy: ''
        };
        this.isSelectMailing = type;
        this.project_additional_service_mailing = [];
        if (evt.source.value == "certified_Mailing_1Day" || evt.source.value == "certified_Mailing" ||
            evt.source.value == "certified_Mailing_International" || evt.source.value == "certified_Mailing_Canada") {
            this.show_mailing_address = true;
            this.show_pickup_address = false;
        }
        else if (evt.source.value == "regular_PickUp") {
            this.show_mailing_address = false;
            this.show_pickup_address = true;
            this.show_second_address = false;
        }
    };
    AddAdditionalServicesComponent.prototype.checkMailingAddress = function (evt) {
        if (evt.source.value == "Shipping") {
            if (this.mailing_address.length == 0) {
                this.toastr.error("No shipping address found !!");
                return;
            }
            if (this.shipping_address.street == null) {
                this.check_mailing.street_address = '';
            }
            else {
                this.check_mailing.street_address = this.shipping_address.street;
            }
            if (this.shipping_address.city == null) {
                this.check_mailing.city = '';
            }
            else {
                this.check_mailing.city = this.shipping_address.city;
            }
            if (this.shipping_address.state == null) {
                this.check_mailing.state = '';
            }
            else {
                this.check_mailing.state = this.shipping_address.state;
            }
            if (this.shipping_address.country == null) {
                this.check_mailing.country = '';
            }
            else {
                this.check_mailing.country = this.shipping_address.country;
            }
            if (this.shipping_address.postal_code == null) {
                this.check_mailing.zip_code = '';
            }
            else {
                this.check_mailing.zip_code = this.shipping_address.postal_code;
            }
        }
        else if (evt.source.value == "Billing") {
            if (this.mailing_address.length == 0) {
                this.toastr.error("No billing address found !!");
                return;
            }
            if (this.billing_address.street == null) {
                this.check_mailing.street_address = '';
            }
            else {
                this.check_mailing.street_address = this.billing_address.street;
            }
            if (this.billing_address.city == null) {
                this.check_mailing.city = '';
            }
            else {
                this.check_mailing.city = this.billing_address.city;
            }
            if (this.billing_address.state == null) {
                this.check_mailing.state = '';
            }
            else {
                this.check_mailing.state = this.billing_address.state;
            }
            if (this.billing_address.country == null) {
                this.check_mailing.country = '';
            }
            else {
                this.check_mailing.country = this.billing_address.country;
            }
            if (this.billing_address.postal_code == null) {
                this.check_mailing.zip_code = '';
            }
            else {
                this.check_mailing.zip_code = this.billing_address.postal_code;
            }
        }
    };
    AddAdditionalServicesComponent.prototype.send_to_second_address = function (evt) {
        if (evt.checked) {
            this.show_second_address = true;
            this.show_second_address_field = false;
            return false;
        }
        else if (!evt.checked) {
            this.show_second_address = false;
            this.show_second_address_field = false;
            this.secondAddressValue = '';
            this.mailing_second_address = {
                street_address: '',
                city: '',
                state: '',
                country: '',
                zip_code: '',
                phone_number: '',
                no_of_copy: ''
            };
            if (this.second_address_mailing.length > 0) {
                this.second_address_mailing = [];
            }
            return false;
        }
    };
    AddAdditionalServicesComponent.prototype.show_no_of_copy_mailing = function (evt) {
        if (evt.checked) {
            this.show_mailing_copy = true;
            return false;
        }
        else if (!evt.checked) {
            this.show_mailing_copy = false;
            var dataRemove = this.project_additional_service_mailing.filter(function (x) { return x.service_code == 'additional_copy'; });
            if (dataRemove.length > 0) {
                this.project_additional_service_mailing.pop(dataRemove);
            }
            return false;
        }
    };
    AddAdditionalServicesComponent.prototype.SubmitMailing = function () {
        var _this = this;
        if (this.show_mailing_copy && this.check_no_mailing.no_of_copy == '') {
            this.toastr.error("Please enter additional copy value ");
            return false;
        }
        if (this.show_second_address && this.secondAddressValue == '') {
            this.toastr.error("Please choose any mailing service for second address !! ");
            return false;
        }
        if (this.show_mailing_address == false &&
            this.show_pickup_address == false) {
            this.toastr.error("Please select any mailing option ");
            return false;
        }
        if (this.show_pickup_address && this.selectPickUpValue == '') {
            this.toastr.error("Please select any pickup option ");
            return false;
        }
        var isSuccess = false;
        if (this.isSelectMailing == "certified_Mailing_1Day" || this.isSelectMailing == "certified_Mailing" ||
            this.isSelectMailing == "certified_Mailing_International" || this.isSelectMailing == "certified_Mailing_Canada") {
            isSuccess = true;
        }
        else {
            isSuccess = false;
        }
        if (isSuccess) {
            if (this.check_mailing.street_address == '') {
                this.toastr.error("Please select street address in first address");
                return false;
            }
            if (this.check_mailing.city == '') {
                this.toastr.error("Please select city in first address");
                return false;
            }
            if (this.check_mailing.state == '') {
                this.toastr.error("Please select shipping state in first address");
                return false;
            }
            if (this.check_mailing.country == '') {
                this.toastr.error("Please select country in first address");
                return false;
            }
            if (this.check_mailing.zip_code == '') {
                this.toastr.error("Please select zip code in first address");
                return false;
            }
            if (this.check_mailing.phone_number == '') {
                this.toastr.error("Please select phone number in first address");
                return false;
            }
            var obj_1 = {
                project_id: this.project_id,
                service_code: this.isSelectMailing,
                quantity: 1,
                description: '',
                shipping_address: this.check_mailing.street_address,
                shipping_city: this.check_mailing.city,
                shipping_state: this.check_mailing.state,
                shipping_country: this.check_mailing.country,
                shipping_phone: this.check_mailing.phone_number,
                second_address: '',
                pickup_location: '',
                notarization_other_state: '',
                apostile_country_source: '',
                apostile_from_state: '',
            };
            this.project_additional_service_mailing.push(obj_1);
        }
        // second address submit
        var second_address_service_code;
        var isSuccess_second = false;
        if (this.secondAddressValue == "1") {
            second_address_service_code = "certified_Mailing_1Day";
            isSuccess_second = true;
        }
        else if (this.secondAddressValue == "2") {
            second_address_service_code = "certified_Mailing";
            isSuccess_second = true;
        }
        else if (this.secondAddressValue == "3") {
            second_address_service_code = "certified_Mailing_International";
            isSuccess_second = true;
        }
        else if (this.secondAddressValue == "4") {
            second_address_service_code = "certified_Mailing_Canada";
            isSuccess_second = true;
        }
        else {
            isSuccess_second = false;
        }
        if (isSuccess_second) {
            if (this.mailing_second_address.street_address == '') {
                this.toastr.error("Please select street address in second address");
                return false;
            }
            if (this.mailing_second_address.city == '') {
                this.toastr.error("Please select city in second address");
                return false;
            }
            if (this.mailing_second_address.state == '') {
                this.toastr.error("Please select shipping state in second address");
                return false;
            }
            if (this.mailing_second_address.country == '') {
                this.toastr.error("Please select country in second address");
                return false;
            }
            if (this.mailing_second_address.zip_code == '') {
                this.toastr.error("Please select zip code in second address");
                return false;
            }
            var obj_2 = {
                project_id: this.project_id,
                service_code: second_address_service_code,
                quantity: 1,
                description: '',
                shipping_address: this.mailing_second_address.street_address,
                shipping_city: this.mailing_second_address.city,
                shipping_state: this.mailing_second_address.state,
                shipping_country: this.mailing_second_address.country,
                shipping_phone: '',
                second_address: '',
                pickup_location: '',
                notarization_other_state: '',
                apostile_country_source: '',
                apostile_from_state: '',
            };
            this.second_address_mailing.push(obj_2);
        }
        if (this.second_address_mailing.length > 0) {
            this.second_address_mailing.forEach(function (element) {
                _this.project_additional_service_mailing.push(element);
            });
        }
        var obj = {
            project_additional_service: this.project_additional_service_mailing
        };
        this.projectsService.addAdditionalService(obj)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Add mailing successfully!!");
                window.location.href = '/pms/project/details/' + _this.project_id;
            }
        });
    };
    // Mailing Service End
    // Apostile Service start
    AddAdditionalServicesComponent.prototype.check_apostille_service = function (evt) {
        if (evt.checked) {
            this.show_apostille_country = true;
        }
        else if (!evt.checked) {
            this.show_apostille_country = false;
        }
    };
    AddAdditionalServicesComponent.prototype.select_apostille_service = function (evt) {
        if (evt.source.value == "apostille_service_Illinois") {
            this.show_apostille_state = false;
        }
        else if (evt.source.value == "apostille_service_outside_Illinois") {
            this.show_apostille_state = true;
        }
    };
    AddAdditionalServicesComponent.prototype.Submit_Apostille = function () {
        var _this = this;
        if (this.check_apostille.value == "") {
            this.toastr.error("please select Apostile service");
            return;
        }
        if (this.Form.value.apostile_country_source == '') {
            this.toastr.error("Please select Apostile country!");
            return;
        }
        if (this.Form.value.service_code == "") {
            this.toastr.error("Please select service code!");
            return;
        }
        if (this.Form.value.service_code == "apostille_service_outside_Illinois" && this.Form.value.apostile_from_state == '') {
            this.toastr.error("Please select Apostile state!");
            return;
        }
        var obj = {
            project_additional_service: [{
                    project_id: this.project_id,
                    service_code: this.Form.value.service_code,
                    quantity: 1,
                    description: '',
                    shipping_address: '',
                    shipping_city: '',
                    shipping_state: '',
                    shipping_country: '',
                    shipping_phone: '',
                    second_address: '',
                    pickup_location: '',
                    notarization_other_state: '',
                    apostile_country_source: this.Form.value.apostile_country_source,
                    apostile_from_state: this.Form.value.apostile_from_state
                }]
        };
        this.projectsService.addAdditionalService(obj)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Add Apostile successfully!!");
                window.location.href = '/pms/project/details/' + _this.project_id;
            }
        });
    };
    // Apostile Service end  
    // Notarization Service start
    AddAdditionalServicesComponent.prototype.check_notarization_coa = function (evt) {
        if (evt.checked) {
            this.show_notarization_no_of_copy = true;
        }
        else if (!evt.checked) {
            this.show_notarization_no_of_copy = false;
        }
    };
    AddAdditionalServicesComponent.prototype.select_notarization = function (evt, value) {
        if (evt.checked && value == 'notarization_coa_il_ca') {
            this.show_notarization_no_of_copy = true;
            return false;
        }
        else if (evt.checked && value == 'notarization_other_state') {
            this.show_notarization_other_state = true;
            // test
            this.show_additional_notarization = true;
            // test
            return false;
        }
        else if (!evt.checked && value == 'notarization_coa_il_ca') {
            this.show_notarization_no_of_copy = false;
            this.checkNumberOfCopies = 0;
            var dataRemove = this.project_additional_service.filter(function (x) { return x.service_code == value; });
            if (dataRemove.length > 0) {
                this.project_additional_service.pop(dataRemove);
            }
            // var dataRemove1 = this.project_additional_service.filter(x => x.service_code == 'notarization_additional');
            // if (dataRemove1.length > 0) {
            //   this.project_additional_service.pop(dataRemove1);
            // }
            return false;
        }
        else if (evt.checked && value == 'notarization_additional') {
            this.show_additional_notarization_no_of_copy = true;
            return false;
        }
        else if (!evt.checked && value == 'notarization_other_state') {
            this.show_notarization_other_state = false;
            // test
            this.show_additional_notarization = false;
            this.show_additional_notarization_no_of_copy = false;
            this.additional_notarization_checkNumberOfCopies = 0;
            this.checkStateValue = '';
            // test
            var dataRemove = this.project_additional_service.filter(function (x) { return x.service_code == value; });
            if (dataRemove.length > 0) {
                this.project_additional_service.pop(dataRemove);
            }
            // test
            var dataRemove1 = this.project_additional_service.filter(function (x) { return x.service_code == 'notarization_additional'; });
            if (dataRemove1.length > 0) {
                this.project_additional_service.pop(dataRemove1);
            }
            // test
            return false;
        }
        else if (!evt.checked && value == 'notarization_additional') {
            this.show_additional_notarization_no_of_copy = false;
            this.additional_notarization_checkNumberOfCopies = 0;
            var dataRemove = this.project_additional_service.filter(function (x) { return x.service_code == value; });
            if (dataRemove.length > 0) {
                this.project_additional_service.pop(dataRemove);
            }
            return false;
        }
        var obj = {
            project_id: this.project_id,
            service_code: '',
            quantity: value,
            description: '',
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_country: '',
            shipping_phone: '',
            second_address: '',
            pickup_location: '',
            notarization_other_state: '',
            apostile_country_source: '',
            apostile_from_state: '',
        };
        if (evt.checked && value != null) {
            var quantityObj = 0;
            if (value == 'notarization_affidavit_court' ||
                value == 'notarization_other_state') {
                quantityObj = 1;
            }
            else {
                quantityObj = this.NumberCopies;
            }
            obj.quantity = quantityObj;
            obj.service_code = value;
            this.project_additional_service.push(obj);
        }
        else {
            var dataRemove = this.project_additional_service.filter(function (x) { return x.service_code == value; });
            if (dataRemove.length > 0) {
                this.project_additional_service.pop(dataRemove);
            }
        }
    };
    AddAdditionalServicesComponent.prototype.setState = function (event, value) {
        var dataRemove = this.project_additional_service.filter(function (x) { return x.service_code == value; });
        if (dataRemove.length > 0) {
            this.project_additional_service.pop(dataRemove);
        }
        this.checkStateValue = event.target.value;
        var obj = {
            project_id: this.project_id,
            service_code: 'notarization_other_state',
            quantity: 1,
            description: '',
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_country: '',
            shipping_phone: '',
            second_address: '',
            pickup_location: '',
            notarization_other_state: event.target.value,
            apostile_country_source: '',
            apostile_from_state: '',
        };
        this.project_additional_service.push(obj);
    };
    AddAdditionalServicesComponent.prototype.setNumberOfCopies = function (event, value) {
        var dataRemove = this.project_additional_service.filter(function (x) { return x.service_code == value; });
        if (dataRemove.length > 0) {
            this.project_additional_service.pop(dataRemove);
        }
        this.checkNumberOfCopies = event.target.value;
        var obj = {
            project_id: this.project_id,
            service_code: 'notarization_coa_il_ca',
            quantity: parseInt(event.target.value),
            description: '',
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_country: '',
            shipping_phone: '',
            second_address: '',
            pickup_location: '',
            notarization_other_state: '',
            apostile_country_source: '',
            apostile_from_state: '',
        };
        this.project_additional_service.push(obj);
    };
    AddAdditionalServicesComponent.prototype.setNumberOfCopies_AdditionalNotarization = function (event, value) {
        var dataRemove = this.project_additional_service.filter(function (x) { return x.service_code == value; });
        if (dataRemove.length > 0) {
            this.project_additional_service.pop(dataRemove);
        }
        this.additional_notarization_checkNumberOfCopies = event.target.value;
        var obj = {
            project_id: this.project_id,
            service_code: 'notarization_additional',
            quantity: parseInt(event.target.value),
            description: '',
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_country: '',
            shipping_phone: '',
            second_address: '',
            pickup_location: '',
            notarization_other_state: '',
            apostile_country_source: '',
            apostile_from_state: '',
        };
        this.project_additional_service.push(obj);
    };
    AddAdditionalServicesComponent.prototype.SubmitNotarization = function () {
        var _this = this;
        if ((this.checkNumberOfCopies == 0 || this.checkNumberOfCopies == null) && this.show_notarization_no_of_copy) {
            this.toastr.error("Number of copies never be empty or zero in Notarization of COA");
            return false;
        }
        if ((this.checkNumberOfCopies < 0) && this.show_notarization_no_of_copy) {
            this.toastr.error("Number of copies never be negative in Notarization of COA");
            return false;
        }
        // test
        if ((this.additional_notarization_checkNumberOfCopies == 0 || this.additional_notarization_checkNumberOfCopies == null) && this.show_additional_notarization_no_of_copy) {
            this.toastr.error("Number of copies never be empty or zero in additional notarization");
            return false;
        }
        if ((this.additional_notarization_checkNumberOfCopies < 0) && this.show_additional_notarization_no_of_copy) {
            this.toastr.error("Number of copies never be negative in additional notarization");
            return false;
        }
        //test
        if ((this.checkStateValue == '') && this.show_notarization_other_state) {
            this.toastr.error("Notarization other state never be empty");
            return false;
        }
        if (this.project_additional_service.length == 0) {
            this.toastr.error("Please select any Notarization!!");
            return false;
        }
        var obj = {
            project_additional_service: this.project_additional_service
        };
        this.projectsService.addAdditionalService(obj)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Add notarization successfully!!");
                window.location.href = '/pms/project/details/' + _this.project_id;
            }
        });
    };
    AddAdditionalServicesComponent.prototype.check_notarization_other_state = function (evt) {
        if (evt.checked) {
            this.show_notarization_other_state = true;
        }
        else if (!evt.checked) {
            this.show_notarization_other_state = false;
        }
    };
    // Notarization Service end
    // cancel
    AddAdditionalServicesComponent.prototype.Cancel = function () {
        window.location.href = '/pms/project/details/' + this.project_id;
    };
    return AddAdditionalServicesComponent;
}());
export { AddAdditionalServicesComponent };
