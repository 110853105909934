var QuickPanelComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function QuickPanelComponent() {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true
        };
    }
    return QuickPanelComponent;
}());
export { QuickPanelComponent };
