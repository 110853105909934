<div class="dialog-content-wrapper"  style="position:relative;">

  <div class="p-16" fxLayout="row wrap" fxAlign="start center">
<div fxFlex>
  <h4 class="title dialog-title ">Release Project </h4>
</div>
<div fxFlex class="align-right">
  <button mat-icon-button (click)="dialogRef.close(false)" aria-label="Close dialog">
    <mat-icon>close</mat-icon>
</button>
</div>
</div>
<form name="form" [formGroup]="form" (ngSubmit)="submit();">
<div class="p-16" fxLayout="column" fxLayoutAlign="start">
      
        
        <mat-form-field  >
          <mat-select placeholder="Select release reason">
            <mat-option value="option2">Request not clear</mat-option>
            <mat-option value="option3"> No file found</mat-option>
            <mat-option value="option4">Unable to quote</mat-option>
            <mat-option value="option5"> No Reason</mat-option>
            <mat-option value="option6">Technical Reason</mat-option>
            <mat-option value="option7">Do not have time</mat-option>
  
          </mat-select>
        </mat-form-field>
           
              <mat-form-field  >
            <mat-label>Comment</mat-label>
              <textarea matInput formControlName="message" rows="10" style="height: 100px;"></textarea>
            <mat-error *ngIf="form.get('message').invalid && form.get('message').touched">
              <span *ngIf="form.get('message').errors.required"> Comment required!</span>
          </mat-error>
            </mat-form-field>
          
      
      
  </div>
  <div class="actWraper">
    <button mat-raised-button matTooltip="Apply" type="submit"  [disabled]="!form.valid" class="green-bg">
               <mat-label class="Text_Color">Release</mat-label>
              </button>
         </div>
</form>
  <!-- / CONTENT -->

</div>