import { FinancialDashboardComponent } from './financial-dashboard/financial-dashboard.component';
var routes = [
    {
        path: 'financial/financial-dashboard',
        component: FinancialDashboardComponent
    }
];
var FinancialModule = /** @class */ (function () {
    function FinancialModule() {
    }
    return FinancialModule;
}());
export { FinancialModule };
