import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule, MatIconModule, MatTableModule, MatProgressBarModule,
   MatButtonModule, MatRadioModule, MatSliderModule, MatBadgeModule, MatFormFieldModule, MatInputModule, 
   MatStepperModule, MatSelectModule,MatPaginatorModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from 'app/fuse-config';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { ChartsModule } from 'ng2-charts';
import {MatTabsModule} from '@angular/material/tabs';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FinancialDashboardComponent } from './financial-dashboard/financial-dashboard.component';
const routes = [
  {
      path: 'financial/financial-dashboard',
      component: FinancialDashboardComponent
   }
];

@NgModule({
  declarations: [FinancialDashboardComponent],
  imports: [
    
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatPaginatorModule,
    TranslateModule.forRoot(),
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    MatTableModule,        // App modules
    LayoutModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSliderModule, RouterModule.forChild(routes),
    MatBadgeModule,MatCheckboxModule,FormsModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule,
    ChartsModule,MatTabsModule,CdkStepperModule,MatStepperModule,MatSelectModule

  ]
})
export class FinancialModule { }
