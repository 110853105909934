import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
var UpdateAccountComponent = /** @class */ (function () {
    function UpdateAccountComponent(dialogRef, data, projectsService, toastr, router, route, _formBuilder) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this._formBuilder = _formBuilder;
        this.portalUsers = [];
        this.accountType = [];
        this.shipping_id = null;
        this.billing_id = null;
        this.accountAddress = [];
    }
    UpdateAccountComponent.prototype.ngOnInit = function () {
        this.getPortalUsers();
        this.getAccountType();
        this.Form = this._formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            email: [''],
            company: [''],
            website: [''],
            office_phone: [''],
            account_type: [''],
            sic_code: [''],
            assigned_to: [''],
            billing_street: [''],
            billing_city: [''],
            billing_state: [''],
            billing_country: [''],
            billing_zip_code: [''],
            shipping_street: [''],
            shipping_city: [''],
            shipping_state: [''],
            shipping_country: [''],
            shipping_zip_code: ['']
        });
        this.getAddress();
        this.Form.patchValue({
            name: this.data.accountDetail.name,
            email: this.data.accountDetail.email,
            company: this.data.accountDetail.company,
            website: this.data.accountDetail.website,
            office_phone: this.data.accountDetail.office_phone,
            account_type: this.data.accountDetail.account_type,
            sic_code: this.data.accountDetail.sic_code,
            assigned_to: this.data.accountDetail.assigned_to
        });
    };
    UpdateAccountComponent.prototype.Onclose = function () {
        this.dialogRef.close(true);
    };
    UpdateAccountComponent.prototype.getPortalUsers = function () {
        var _this = this;
        var Query = 'page=1&per_page=30&';
        this.projectsService.getPortalUsers(Query)
            .subscribe(function (res) {
            if (res) {
                _this.portalUsers = res.data;
            }
        });
    };
    // get Account Type
    UpdateAccountComponent.prototype.getAccountType = function () {
        var _this = this;
        this.projectsService.getAccountType()
            .subscribe(function (res) {
            if (res) {
                _this.accountType = res.data;
            }
        });
    };
    UpdateAccountComponent.prototype.getAddress = function () {
        var _this = this;
        if (this.data.accountDetail.accountaddress.data.length > 0) {
            this.data.accountDetail.accountaddress.data.forEach(function (element) {
                if (element.address.data.type == "Billing") {
                    _this.billing_id = element.address.data.id;
                    _this.Form.patchValue({
                        billing_street: element.address.data.street,
                        billing_city: element.address.data.city,
                        billing_state: element.address.data.state,
                        billing_country: element.address.data.postal_code,
                        billing_zip_code: element.address.data.country,
                    });
                }
                if (element.address.data.type == "Shipping") {
                    _this.shipping_id = element.address.data.id;
                    _this.Form.patchValue({
                        shipping_street: element.address.data.street,
                        shipping_city: element.address.data.city,
                        shipping_state: element.address.data.state,
                        shipping_country: element.address.data.postal_code,
                        shipping_zip_code: element.address.data.country,
                    });
                }
            });
        }
    };
    UpdateAccountComponent.prototype.update = function () {
        var _this = this;
        this.accountAddress = [];
        var billing_address = {
            id: this.billing_id,
            type: "Billing",
            street: this.Form.value.billing_street,
            city: this.Form.value.billing_city,
            state: this.Form.value.billing_state,
            postal_code: this.Form.value.billing_zip_code,
            country: this.Form.value.billing_country
        };
        var shipping_address = {
            id: this.shipping_id,
            type: "Shipping",
            street: this.Form.value.shipping_street,
            city: this.Form.value.shipping_city,
            state: this.Form.value.shipping_state,
            postal_code: this.Form.value.shipping_zip_code,
            country: this.Form.value.shipping_country
        };
        if (this.Form.value.billing_street != '' || this.Form.value.billing_city != '' || this.Form.value.billing_state != ''
            || this.Form.value.billing_zip_code != '' || this.Form.value.billing_country != '') {
            this.accountAddress.push(billing_address);
        }
        if (this.Form.value.shipping_street != '' || this.Form.value.shipping_city != '' || this.Form.value.shipping_state != ''
            || this.Form.value.shipping_zip_code != '' || this.Form.value.shipping_country != '') {
            this.accountAddress.push(shipping_address);
        }
        var final_data = {
            account_type: this.Form.value.account_type,
            alliance_discount: this.data.accountDetail.alliance_discount,
            name: this.Form.value.name,
            email: this.Form.value.email,
            industry_id: this.data.accountDetail.industry_id,
            company: this.Form.value.company,
            company_logo: this.data.accountDetail.company_logo,
            office_phone: this.Form.value.office_phone,
            website: this.Form.value.website,
            employees_count: this.data.accountDetail.employees_count,
            annual_revenue: this.data.accountDetail.annual_revenue,
            sic_code: this.Form.value.sic_code,
            rating: this.data.accountDetail.rating,
            crm_ref_id: this.data.accountDetail.crm_ref_id,
            assigned_to: this.Form.value.assigned_to,
            created_by: this.data.accountDetail.created_by,
            account_address: this.accountAddress
        };
        this.projectsService.updateAccountDetail(this.data.accountDetail.id, final_data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Account Details updated!!");
                _this.Onclose();
            }
        });
    };
    return UpdateAccountComponent;
}());
export { UpdateAccountComponent };
