<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
    <div class="white-box">
        <div class="tHeader">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="space-between center">
                <h2>
                    <mat-icon class="ml-24">group</mat-icon>GCLID Record
                </h2>
                <div fxFlex class="text-right">
                    <button mat-raised-button color="accent"  (click)="export()" class="mr-8 text-right"><i class="material-icons">group</i>Export</button>
                </div>
            </div>
        </div>
        <div class="content-card">
            <mat-table class="products-table" id="gclid-material-table" #table [dataSource]="dataSource" matSort fusePerfectScrollbar>
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="12">Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                        <div fxLayout="column">
                            <div fxFlex>
                                    {{product.type=='ld'?'Lead':'Opportunity'}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="payment_status">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="12">Payment Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                        <div fxLayout="column">
                            <div fxFlex>
                                    <ng-container *ngIf="product.payment_method_c">
                                        Method: {{product.payment_method_c}}
                                        <br/>
                                    </ng-container>
                                    <ng-container *ngIf="product.payment_status_c || product.approval_payment_dd_c">
                                        Status: {{product.payment_status_c}} / {{product.approval_payment_dd_c}}
                                    </ng-container>
                                   <br/> 
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="gclid">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="24">Google Click ID (GCLID)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="24">
                        <div fxLayout="column" style="word-break: break-all;">
                            <div fxFlex>
                                    {{product.gclid_c}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="msclkid">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="24">Microsoft's Click ID (MSCLKID)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="24">
                        <div fxLayout="column" style="word-break: break-all;">
                            <div fxFlex>
                                    {{product.msclkid_c}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
               <!--  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="16">Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
                        <div fxLayout="column">
                            <div fxFlex>
                                    {{product.type=="ld"?product.opportunity_name:product.name}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="currency">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="10">Currency
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="10">
                        <div fxLayout="column">
                            <div fxFlex>
                                    {{product.currencyName?product.currencyName + ':'+ product.currencySymbol:'US Dollars : $'}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date_entered">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="18">Date Entered
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="18">
                        <div fxLayout="column">
                            <div fxFlex>
                                    {{product.date_entered}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef  fxHide fxShow.gt-sm fxFlex="12">Amount
                    </mat-header-cell>
                    <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                        <div fxLayout="column">
                            <div fxFlex>
                                    {{product.type=="ld"?product.opportunity_amount:product.amount}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="ApprovaldisplayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let product; columns: ApprovaldisplayedColumns;" class="product fList">
                </mat-row>

            </mat-table>
        </div>
    </div>
</div>