<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" (click)="back()" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1">
      {{ data_response.title}} {{ data_response.first_name}} {{data_response.middle_name}} {{ data_response.last_name }}
      <span class="prfInfo">
        <!-- <span *ngIf="data_response?.is_new==0" class="pr-8">Existing Application</span> -->
        <span *ngIf="data_response?.is_new==0" class="pr-8">Translation Status</span>
        <span *ngIf="data_response?.is_new==1">New Application</span>
        <span class="prfo mr-10"
          *ngIf="data_response?.category_level != 'Closed' && data_response?.category_level != 'Blacklisted'">
          <span class="hText hYellow test" *ngIf="data_response?.stage!=null">
            {{data_response?.stage.data?.name}}
          </span>
        </span>
        <span class="prfo mr-10" *ngIf="data_response?.category_level!=null">
          <span class="pl-8 hText hRed"
            *ngIf="data_response?.category_level=='Closed' || data_response?.category_level=='Blacklisted' || data_response?.category_level=='Unavailable'">
            {{data_response?.category_level}}
          </span>
          <span class="pl-8 hText hYellow"
            *ngIf="data_response?.category_level!='Closed' && data_response?.category_level!='Blacklisted' && data_response?.category_level!='Unavailable'">
            {{data_response?.category_level}}
          </span>
        </span>
      </span>
    </span>
  </div>

  <div class="white-box">
    <form name="Form" [formGroup]="Form">
      <div class="profHeader p20 bb1">
        <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="auto">
            <div class="profImage">
              <img [src]="vendor_file_data" onerror="this.src='assets/images/avatars/profile.jpg'" width="120" /><br />
            </div>
            <h2> {{ data_response.title}} {{ data_response.first_name}} {{data_response.middle_name}}
              {{ data_response.last_name }}</h2>

            <!-- <p  class="font-16">Gender: <span class="txt-green" *ngIf="data_response?.gender==1">Male</span><span class="txt-green" *ngIf="data_response?.gender==2">Female</span></p> -->
            <p  class="font-16">Timezone: <span class="txt-green" *ngIf="data_response?.timezone!=null">{{data_response?.timezone}}</span></p>
            <!-- <p  class="font-16">Age: <span class="txt-green" >{{age}}</span></p> -->

            
            <h3>Native in: <span class="txt-green"
                *ngIf="data_response.vendornativelanguage!=null">{{data_response.vendornativelanguage.data?.lang_name}}</span>
            </h3>
            <h3>Status: <span class="txt-green" *ngIf="is_active==1">Active</span>
              <span class="txt-red" *ngIf="is_active==0">InActive</span>
            </h3>
          </div>

          <div class="item item-2" fxFlex="auto">
            <div class="invAdd">
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Address:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p> {{data_response.address1}} {{ data_response.city }} {{data_response?.state}}
                    {{ data_response?.postal_code}} {{ data_response?.country }}
                  </p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Important notes:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.important_note }} </p>
                  <p *ngIf="data_response?.important_note==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Email:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.email }} </p>
                  <p *ngIf="data_response?.email==null">NA</p>
                </div>
              </div>



              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Skype ID:</b></p>
                </div>

                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.skype_id }}</p>
                  <p *ngIf="data_response?.skype_id==null">NA</p>
                </div>
              </div>

              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Phone:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response?.phone }}</p>
                  <p *ngIf="data_response?.phone==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Alternative Email:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.email_sec }} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="boxBody">
        <div class="boxBodyInn">
          <div class="box-blank" *ngIf="service_id">
            <p><b>Role</b></p>
            <p *ngIf="service_id==12">
              Video editor
            </p>
            <p *ngIf="service_id==13">
              Copywriter
            </p>
          </div>
          <div class="box-blank" *ngIf="skills">
            <p><b>Skills</b></p>
            <p>
              {{skills}}
            </p>
          </div>

          <h4>Pricing</h4>
          <table class="tableGrey bdr1">
            <thead>
              <ng-container *ngFor="let column of displayedMarketingSkillPriceColumns">
                <th *ngIf="column.service_id==service_id">
                    {{column.label}}
                </th>
              </ng-container>
            </thead>
            <tbody>
                <tr>
                  <ng-container *ngFor="let column of displayedMarketingSkillPriceColumns">
                      <td *ngIf="column.service_id==service_id" style="text-align: center;">
                          {{table_vendor_marketing_skillprice_data[column.key]}}
                      </td>
                  </ng-container>
                </tr>
            </tbody>
          </table>

          <h4>Days Available</h4>
          <p *ngIf="day_name_test!=null || day_name_test!=''"> {{ day_name_test }}</p>
          <p *ngIf="day_name_test==null || day_name_test==''"> NA</p>
          <h4>Notes yourself</h4>
          <p *ngIf="data_response?.education!=null || data_response?.education!=''"> {{ data_response?.education }}</p>
          <p *ngIf="data_response?.education==null || data_response?.education==''"> NA</p>
          <h4>Detailed cover letter</h4>
          <p *ngIf="data_response?.cover_letter!=null || data_response?.cover_letter!=''"> {{
            data_response?.cover_letter }}</p>
          <p *ngIf="data_response?.cover_letter==null || data_response?.cover_letter==''"> NA</p>

          <h4>Resume</h4>
          <div class="box-blank" *ngIf="vendor_resume_name!=null">
          <p *ngIf="vendor_resume_name!=null"> {{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank"
              download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          </div>
          <p *ngIf="vendor_resume_name==null"> No record <a>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          <!-- <h4>Contract</h4> -->
          <!-- <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data"
              target="_blank" download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          <p *ngIf="vendor_contract_name==null"> No record
          </p> -->
          <!-- <div class="box-blank" *ngIf="vendor_contract_name!=null">
            <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data"
                target="_blank" download>
                <mat-icon>vertical_align_bottom</mat-icon>
              </a>
            </p>
          </div>
          <p *ngIf="vendor_contract_name==null"> No record</p> -->
          <!-- test -->
          <h4>Coa Files</h4>
          <!-- <p *ngIf="vendor_resume_name!=null"> {{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank"
              download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          <p *ngIf="vendor_resume_name==null"> No record <a>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p> -->
          <div class="box-blank" *ngIf="coa_array?.length>0">
            <span *ngFor="let resCoa of coa_array">
              <p>{{resCoa.name}} <a [href]="resCoa.url" target="_blank" download>
                  <mat-icon>vertical_align_bottom</mat-icon>
                </a></p>
              <p *ngIf="coa_array?.length ==0"> No record
              </p>
            </span>
          </div>

          <h4>Extra Document</h4>
          <div class="box-blank" *ngIf="vendor_exta_document_name!=null">
            <p *ngIf="vendor_exta_document_name!=null"> {{vendor_exta_document_name}} <a
                [href]="vendor_exta_document_data" target="_blank" download>
                <mat-icon>vertical_align_bottom</mat-icon>
              </a>
            </p>
          </div>
          <!-- test -->
          <h4>Important Notes</h4>
          <p *ngIf="data_response?.important_note!=null || data_response?.important_note!=''"> {{
            data_response?.important_note }}</p>
          <p *ngIf="data_response?.important_note==null || data_response?.important_note==''"> NA</p>

          <h4>Onboarding</h4>
          <p *ngIf="data_response?.on_boarding_date!=null || data_response?.on_boarding_date!=''"> {{
            data_response?.on_boarding_date | date:'yyyy-MM-dd' }}</p>
          <p *ngIf="data_response?.on_boarding_date==null || data_response?.on_boarding_date==''"> NA</p>



          <h4>HR Comments</h4>

          <div class="boxBody" *ngIf="vendorcomment_data?.length!=0">
            <div class="boxBodyInn2">
              <div class="commentDiv" *ngFor="let res of vendorcomment_data">

                <div class="quesDiv" *ngIf="res?.user?.data?.user_group_id=='Vendor'">
                  <p><b> {{ res?.user?.data?.fullname}} ({{ res?.user?.data?.user_group_id}})</b> <span class="tStamp">
                      {{ res?.created_at.date | date:'short' }}</span> </p>
                  <p>{{ res?.comment}}</p>
                </div>


                <div class="ansDiv" *ngIf="res?.user?.data.user_group_id!='Vendor'">
                  <div class="divinn">
                    <p><b> {{ res?.user?.data?.fullname}} ({{ res?.user?.data?.user_group_id}})</b> <span
                        class="tStamp">
                        {{ res?.created_at?.date | date:'short' }}</span> </p>
                    <p>{{ res?.comment}}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <h4>Personal information</h4>
          <p>Gender: 
            <span *ngIf="data_response?.gender==1">Male</span>
            <span *ngIf="data_response?.gender==2">Female</span>
          </p>
          <p >age: 
            <span >{{age}}</span>
          </p>

          <p>billing address: 
            <span >{{data_response?.address1}} {{data_response?.address2}}</span>
          </p>
          <p>total earned: 
            <span >NA</span>
          </p>
          <p>company website: 
            <span >NA</span>
          </p>




        </div>
      </div>

      <div class="boxBodyInn">

        <div class="box-blank bg-yellow">

          <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <div fxLayout="column" fxFlex="1 0 auto" fxFlex="100">
              <h4>Statics</h4>
              <mat-form-field fxFlex="100">
                <mat-label>Quiz Result Inputs:</mat-label>
                <textarea matInput rows="5" formControlName="comment_quiz"
                  [value]="data_response?.comment_quiz"></textarea>
              </mat-form-field>

              <mat-form-field fxFlex="100">
                <mat-label>Orientation Inputs:</mat-label>
                <textarea matInput rows="5" formControlName="comment_orientation"
                  [value]="data_response?.comment_orientation"></textarea>
              </mat-form-field>




              <mat-form-field>
                <mat-label>Special Comments from Marketing:</mat-label>
                <textarea matInput rows="5" formControlName="comment"></textarea>
              </mat-form-field>

              <!-- <span><span class="hText hYellow"> {{data_response?.stage.data?.name}}</span></span> -->
              <div *ngIf="data_response?.stage.data?.name!='Approved'">
                <mat-form-field fxFlex="30" class="mt-12">
                  <mat-label>Stage</mat-label>
                  <mat-select formControlName="stage_id">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option label="New Application" [value]="1">New Application</mat-option>
                    <mat-option label="Under Review" [value]="2">Under Review</mat-option>
                    <mat-option label="Quiz" [value]="3">Quiz</mat-option>
                    <mat-option label="Orientation" [value]="4">Orientation</mat-option>
                    <mat-option label="HR Approval" [value]="9">HR Approval</mat-option>
                    <mat-option label="Admin Approval Pending" [value]="7">Admin Approval Pending</mat-option>
                    <!-- <mat-option *ngFor="let stage_res of stageData" [value]="stage_res.id">
                  {{stage_res.name}}
                </mat-option> -->
                  </mat-select>
                </mat-form-field>
              </div>






            </div>


          </div>
          <p>
            <button matTooltip="Save" mat-flat-button color="accent" type="submit" (click)="submit()"
              [disabled]="Form.invalid" class="mr-8">
              <mat-label>Save Statics</mat-label>
            </button>
          </p>
        </div>
        <p class="text-right">


          <button mat-raised-button (click)="Edit()" class="mr-8"><span class="material-icons">edit</span> Edit
            User</button>

          <!-- <button (click)="Send_mail_single(data_response)" mat-raised-button class="mr-8">
            <span class="material-icons">vpn_key</span> Resend Login Credentials to this User
          </button> -->



        </p>

      </div>
      <div class="actWraper">
        <p class="tStatus text-left ml-4"> Current Stage:
          <span class="hText hYellow" *ngIf="data_response?.stage!=null"> {{data_response?.stage.data?.name}}</span>
        </p>
        <p>
          <button matTooltip="Approve" mat-raised-button
            *ngIf="data_response?.stage.data?.name!='Approved'" (click)="finalApproval(6)" class="mr-8">
            <mat-label>Approve</mat-label> <!--Bypass Admin and -->
          </button>
          <!-- <button matTooltip="Admin Approval" mat-raised-button *ngIf="data_response?.stage.data?.name!='Approved'"
            (click)="adminApprovalPending(7)" class="mr-8">
            <mat-label>Send Admin for Approval </mat-label>
          </button> -->
          <button color="warn" mat-raised-button matTooltip="Click here to Reject"
            *ngIf="data_response?.stage.data?.name!='Approved'" (click)="submitDataReject(8)">Reject</button>
        </p>
      </div>
    </form>
  </div>
</div>