import { Component, OnInit,Output,EventEmitter  } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatTableDataSource } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { StatusChangeAdditionalServiceComponent } from '../status-change-additional-service/status-change-additional-service.component';
@Component({
  selector: 'app-additional-services-notarization',
  templateUrl: './additional-services-notarization.component.html',
  styleUrls: ['./additional-services-notarization.component.scss']
})
export class AdditionalServicesNotarizationComponent implements OnInit {
  dataSource:any;
  file_data:any=[];
  project_id:string;
  project_file_data:string;
  file_id_data:any=[];
  BaseUrl_file: any;
  response_email_data:any=[];
  Form: FormGroup;
  assignment_obj:any={};
  vendor_ids : any=[];
  project_workflow_id:number;
  projectWorkFlowResponse:any=[];
  bulk_selected_progress: boolean = false;
  apostile_show:boolean=false;
  notarization_quantity:number;
  notarization_other_state:string='';
  notarization_additional:string="No";
  notarization_affidavit_court:string="No";
  show_form:boolean=false;
  additonal_service_array:any=[];
  Project_Assigned_obj_compare:any=[];
  target_language_select:any=[];
  ProjectLanguage_data:any=[];
  completed_workflow_array:any=[];
  project_assignment_response_array:any=[];
  language_pair_count:number;
  displayedColumns: string[] = ['position', 'name', 'email' , 'location', 'services'];
  email_variable_obj:any={
    mailing_options:'',
    documents_shipping_address:'',
    pickUp_location:'',
    street_address:'',
    phone_number:'',
    second_address:'',
    shipping_city:'',
    shipping_state:'',
    shipping_country:'',
    shipping_zip_code:'',
    additonal_copy:'',
    notarization_of_coa:'',
    notarization_other_state: '',
    additional_notarization:'',
    notarization_affidavit: '',
    apostille_country: '',
    apostille_state: '',
    apostille_outside: ''
  
  }

  @Output() myoutputNotarization:EventEmitter<string> = new EventEmitter();
  outputString:string;

  constructor(private route: ActivatedRoute,private _formBuilder: FormBuilder,
    private projectsService: ProjectsService,private toastr: ToastrService,public dialog: MatDialog) { 
    this.BaseUrl_file = environment.baseUrl_file; 
  }
  response:any=[];
  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getAdditionalServices();
    this.getvendorbyskill();
    this.getFileDetail();
    this.getEmailTemplate();
    this.WorkFlowByProjectId();
    this.getProjectAssignment_compare();
    this.getProjectLanguage();

    this.Form = this._formBuilder.group({ 
      email_template_id: ['',Validators.compose([Validators.required])],
      instruction:[''],
      vendor_id:[''],
      start_date: [''],
      due_date: ['',Validators.compose([Validators.required])],
  });
  }

  getAdditionalServices() {
 
    this.projectsService.getAdditionalServices(this.project_id)
    .subscribe((response) => {
        if(response) {
          this.additonal_service_array=response.project_addition_service;
          if(this.additonal_service_array.length==0){
            this.sendNotApply();
          }
         if(response.project_addition_service.length>0){
          response.project_addition_service.forEach(element => {
            if(element.additional_service.code=="notarization_coa_il_ca"){
              // add copy
               this.notarization_quantity=element.quantity;
               this.apostile_show=true;
               this.sendApply();
            }

            if(
            element.additional_service.code=="notarization_other_state"){
               this.notarization_other_state=element.notarization_other_state;
               this.apostile_show=true;
               this.sendApply();
               
            }
            if(element.additional_service.code=="notarization_additional"){
              this.notarization_additional="Yes";
              this.apostile_show=true;
              this.sendApply();
            }

            if(element.additional_service.code=="notarization_affidavit_court"){
              this.notarization_affidavit_court="Yes";
              this.apostile_show=true;
              this.sendApply();
            }
            
          });
         }
        }
    });
  }

  sendNotApply(){
  
    this.outputString="(NA)"
    this.myoutputNotarization.emit(this.outputString);
  }

  sendApply(){
    this.outputString="(Applied)"
    this.myoutputNotarization.emit(this.outputString);
  }

  getvendorbyskill() {
    let obj:any={
      skill_id:Number
    }
    obj.skill_id=6;
    this.projectsService.getvendorbyskill(obj)
    .subscribe((response)=>{
      if(response){
        this.response=response.data;
        this.dataSource = new MatTableDataSource(response.data);

      }

    }
    );

}



// Get file data

getFileDetail() {
  this.projectsService.getProjectDetail(this.project_id)
  .subscribe((response) => {
      if(response) {
        this.file_data=[];
          var fullname="Customer";
          response.data.projectfile.data.forEach(element => {
            if(element.createdby){
              fullname =element.createdby.data.fullname;
              }
              if(element.targetlanguag){
                  var lang_name=element.targetlanguage.data.lang_name;
              }

              if( element.filetype){
                  var filetype=element.filetype.data.name;
              }
                  element.file.data.forEach(elm => {
                     this.project_file_data=this.BaseUrl_file + elm.path;
                     elm.type=element.type;
                     elm.fullname= fullname;
                     //elm.created_at= created_at;
                     elm.lang_name=lang_name;
                     elm.filetype=filetype;
                      this.file_data.push(elm);
                  });
          });
         
        }
      });
    }

    getEmailTemplate() {
      this.projectsService.getEmailTemplateNotarization()
      .subscribe((response) => {
          if(response) {
      
              this.response_email_data=response.data;
          }
      });
      }

        // file section
        selectFile(evt,res){
          if (evt.checked && this.file_id_data.indexOf(res.id== -1)) {
            this.file_id_data.push(res.id);
          }
          else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
              let index = this.file_id_data.indexOf(res.id);
              this.file_id_data.splice(index, 1);
          }
       
      }
  
  
        selectBulkProgress(evt) {
          this.file_id_data=[];
          let check = false;
          this.file_data.forEach((element, key) => {
          element["check"] = evt.checked;
            if (evt.checked) {
              element.check = true;
              this.file_id_data.push(element.id);
            }
            else {
                this.file_id_data = [];  
            }
        });
        if (check) {
            this.bulk_selected_progress = true;  
        } 
      }
  
      WorkFlowByProjectId() {
        let obj:any={
          project_id:''
        };
        obj.project_id=this.project_id;
        this.projectsService.WorkFlowByProjectId(obj)
        .subscribe((response) => {
            if(response) {
        if(response.response.length>0){
     
          this.projectWorkFlowResponse=response.response;
          this.projectWorkFlowResponse.forEach(element => {
            if(element.workflow_id==8){
              this.project_workflow_id=element.id;
              this.getProjectLanguageAssignment(this.project_workflow_id);
            }
            
          });
        }
         
            }
        });
        }
      
  
      submit(){
  
        if(this.file_id_data.length==0){
          this.toastr.error("Please select any files");
          return;
        }

        if(  this.Form.value.vendor_id==null){
          this.toastr.error("Please select any vendor");
          return;

        }

        if(  this.Form.value.vendor_id.length>1){
          this.toastr.error("Please select only one vendor");
          return;
        }
        this.assignment_obj=this.Form.value;    
          var date=new Date();
        this.Form.value.start_date=date;
        this.assignment_obj.start_date=this.dateFormat(this.Form.value.start_date);
        this.assignment_obj.due_date=this.dateFormat(this.Form.value.due_date);
        this.assignment_obj.service_id=3;
        this.assignment_obj.file_id=this.file_id_data;
        this.assignment_obj.project_workflow_id=this.project_workflow_id;

   
         var  email_variable;
        email_variable={
          mailing_options:'',
          documents_shipping_address:'',
          pickUp_location:'',
          street_address:'',
          phone_number:'',
          second_address:'',
          shipping_city:'',
          shipping_state:'',
          shipping_country:'',
          shipping_zip_code:'',
          additonal_copy:'',
          notarization_of_coa:this.notarization_quantity,
          notarization_other_state: this.notarization_other_state,
          additional_notarization: this.notarization_additional,
          notarization_affidavit: this.notarization_affidavit_court,
          apostille_country: '',
          apostille_state: '',
          apostille_outside: ''
        }

        this.assignment_obj.email_variable=email_variable;
        this.assignment_obj.project_id=this.project_id;
  
        this.projectsService.ProjectAssignmentAddition_service(this.assignment_obj)
        .subscribe((response) => {
            if(response) {
              window.location.href = '/pms/project/details/'+this.project_id;
            //   this.bulk_selected_progress=false;
            //   this.toastr.success("Success!!!");
            //   this.file_id_data=[];
            //   this.Project_Assigned_obj_compare=[];
            //   this.target_language_select=[];
            //   this.ProjectLanguage_data=[];
            //   this.completed_workflow_array=[];
            //   this.show_form=false;
            //   this.Form.patchValue({ 
            //     email_template_id: [''],
            //     instruction:[''],
            //     vendor_id:[],
            //     start_date: [''],
            //     due_date: [''],
            // });
            //  this.ngOnInit();
            }
            // else{
            //   this.toastr.error("Something went wrong");
            // }
        });
      }
  
      selectFinance(evt:any,val){
  
        if (evt.checked && this.vendor_ids.indexOf(val.id== -1)) {
         
           this.vendor_ids.push(val.id);
        }
        else if (!evt.checked && this.vendor_ids.indexOf(val.id != -1)) {
            let index = this.vendor_ids.indexOf(val.id);
            this.vendor_ids.splice(index, 1);
          
        }
   
        this.Form.patchValue({
          vendor_id:this.vendor_ids
          
        });
  
      }
  
         // date format
         dateFormat(data) {
          let date = new Date(data);
          let year = date.getFullYear();
          let month: any = date.getMonth() + 1;
          let dt: any = date.getDate();
    
          if (dt < 10) {
              dt = '0' + dt;
          }
          if (month < 10) {
              month = '0' + month;
          }
    
          return year + '-' + month + '-' + dt +' 00:00:00';
      }

      show_table(){
        this.show_form=true;
      }

       // get project assignment
   getProjectAssignment_compare(){
  
    let obj:any={
      project_id: ''
    };
    obj.project_id=this.project_id;
    let query = 'page=1&per_page=20';
    this.projectsService.getProjectAssignment(query,obj)
    .subscribe((response:any) => {
        if(response) {
          this.Project_Assigned_obj_compare=response.response.data;

          if(this.Project_Assigned_obj_compare.length>0){
            this.Project_Assigned_obj_compare.forEach(element => {
              if(element.status=="5" && (element.project_workflow.workflow.id!=6)){
                let obj:any={
                  workflow_id:'',
                  workflow_name: '',
                  vendor_name:'',
                  assignment_date:''
                }
                obj.workflow_id=element.project_workflow.workflow.id;
                obj.workflow_name=element.project_workflow.workflow.name;
                obj.vendor_name=element.vendor.first_name + element.vendor.last_name;
                obj.assignment_date=element.project_workflow.updated_at;
                this.completed_workflow_array.push(obj);
              }
            });
            this.completed_workflow_array.sort(function (a, b) {
              return a.workflow_id - b.workflow_id;
            });
          }
         
        }
        // else{
        //   this.toastr.error("Something went wrong");
     
        // }
    });
  }

    // Get Project Language
    getProjectLanguage() {
      this.projectsService.getProjectLanguage(this.project_id)
      .subscribe((response) => {
          if(response) {
            this.ProjectLanguage_data=response.data;
            this.language_pair_count=this.ProjectLanguage_data.length;
            let target_language_obj:any={
              id:Number,
              sourcelang_name:String,
              targetlang_name:String,
              project_lang_id:Number
            };
            this.ProjectLanguage_data.forEach(element => {
              target_language_obj.id=element.targetlanguage.id;
              target_language_obj.sourcelang_name=element.sourcelanguage.lang_name;
              target_language_obj.targetlang_name=element.targetlanguage.lang_name;
              target_language_obj.project_lang_id=element.id;
              this.target_language_select.push(target_language_obj);
              target_language_obj={
                id:'',
                lang_name:'',
                project_lang_id:''
              };
              
            });
          }
      });
      }

       // Get Project Language Assignment
       getProjectLanguageAssignment(project_workflow_id:any) {
     
      this.projectsService.getProjectLanguageAssignment(project_workflow_id)
      .subscribe((response) => {
          if(response) {
            this.project_assignment_response_array= response.project_assignment;
           
          }
      });
      }


      openDialog(project_assignment_id) {
        let data:any={
          project_assignment_id:'',
          project_id:'',
          email_variable:{}
        }
        data.project_assignment_id=project_assignment_id;
        data.project_id=this.project_id;

        this.email_variable_obj={
          mailing_options:'',
          documents_shipping_address:'',
          pickUp_location:'',
          street_address:'',
          phone_number:'',
          second_address:'',
          shipping_city:'',
          shipping_state:'',
          shipping_country:'',
          shipping_zip_code:'',
          additonal_copy:'',
          notarization_of_coa:this.notarization_quantity,
          notarization_other_state: this.notarization_other_state,
          additional_notarization: this.notarization_additional,
          notarization_affidavit: this.notarization_affidavit_court,
          apostille_country: '',
          apostille_state: '',
          apostille_outside: ''
        }
        data.email_variable=this.email_variable_obj;
      
        const dailogRef = this.dialog.open(StatusChangeAdditionalServiceComponent, {
            width: '400px',
            data: data
          
        });

        dailogRef.afterClosed().subscribe((response) => {
          if(response) {
             this.WorkFlowByProjectId();
          }
      });
      }
}
