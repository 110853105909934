import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { UserService } from 'app/main/Services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { SharedService } from 'app/main/shared.service';

@Component({
  selector: 'app-location-master',
  templateUrl: './location-master.component.html',
  styleUrls: ['./location-master.component.scss']
})
export class LocationMasterComponent implements OnInit {
  type:string = 'Add';
  addlocationForm: any;
  paginate: any = {};
  pageIndex: number = 1;
  locationId: number;
  pageSize: number = 10;
  locationData: any = [];
  uploaded_files_array:any=[];
  file_multiple_data:any=[];
  fileError: string = '';
  query: string = '';
  dataSource: any;
  final_data: any = {};
  file_id_response:number;
  updated_data: any = {};
  fileSuccess: string;
  displayedColumns: string[] = ['id', 'title','city','state','country', 'complete_address', 'phone', 'action'];
  constructor(private userService: UserService,private projectsService: ProjectsService,
     private _formBuilder: FormBuilder, private toastr: ToastrService) { }
  ngOnInit() {
    
    this.addlocationForm = this._formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
      complete_address: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required] )],
      state: ['', Validators.compose([Validators.required])],
      zip: ['', Validators.compose([Validators.required])],
      country: ['', Validators.compose([Validators.required])],
      address_marks: [''],
      phone: ['',Validators.compose([Validators.required])],
      phone_second: [''],
      phone_client_use: [''],
      fax: [''],
      hours_of_operation: [''],
      hours_of_operation_marks: [''],
      center_email: ['', Validators.compose([Validators.required, Validators.email])],
      representatives: [''],
      notary: [''],
      status: ['0'],
      coa_kits: [''],
      notes: [''],
      file_id: [''],
    });
    this.getLocation();
  }

  submit() {
    if(this.type == 'Add') {
      this.addData();
    } else {
        this.updateData();
    }
  }
  addData(){
    if(this.file_id_response != null){
      this.addlocationForm.value.file_id = this.file_id_response;
    }
    this.final_data = this.addlocationForm.value;
    this.final_data.is_active = 1;
    this.userService.addLocation(this.final_data).subscribe(
      resposne => {
        if (resposne) {
          this.uploaded_files_array = [];
          this.fileSuccess = '';
          this.fileError = '';
          this.addlocationForm.reset();
          this.getLocation();
          this.toastr.success('Location added successfully');
        }
      }
    );
  }

  updateData() {
    this.updated_data = this.addlocationForm.value;
    this.userService.updateMasterLocation(this.locationId, this.updated_data).subscribe(
      resposne => {
        if (resposne) {
          this.uploaded_files_array = [];
          this.fileError = '';
          this.fileSuccess = '';
          this.addlocationForm.reset();
          this.toastr.success('Location updated successfully');
          this.type = 'Add';
          this.getLocation();
        }
      }
    );

  }


  getLocation() {
    let query;
    let location_pagination = localStorage.getItem('pagination');
    let locationObject = JSON.parse(location_pagination);
    if(location_pagination !== null && locationObject.table_name == 'location'){
      query = '?page=' + locationObject.page + '&per_page=' +  locationObject.per_page;
    }else{
       query = '?page=1&per_page=10';

    }
    this.userService.getLocation(query)
      .subscribe((res: any) => {
        if (res) {
          this.locationData = res.data;
          this.dataSource = new MatTableDataSource(this.locationData);
          this.paginate = res.meta.pagination;
        }
      });
  }

  paginateData(event) {
    let aQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
    var pagination = {table_name: 'location' ,page: this.pageIndex, per_page: this.pageSize};
    SharedService.set(SharedService.pagination, JSON.stringify(pagination));
    this.query += aQuery;
    this.userService.getLocation(this.query)
      .subscribe((res: any) => {
        if (res) {
          this.locationData = res.data;
          this.dataSource = new MatTableDataSource(this.locationData);
          this.paginate = res.meta.pagination;
        }
      });
  }


  editData(index) {
    this.type = 'Update';
    this.locationData.forEach((element, ind) => {
      if (index == ind) {
        this.locationId=element.id;
        this.addlocationForm.patchValue({
          title: element.title,
          complete_address: element.complete_address,
          city: element.city,
          state: element.state,
          zip: element.zip,
          country: element.country,
          address_marks: element.address_marks,
          phone: element.phone,
          phone_second: element.phone_second,
          phone_client_use: element.phone_client_use,
          fax: element.fax,
          hours_of_operation: element.hours_of_operation,
          hours_of_operation_marks: element.hours_of_operation_marks,
          center_email: element.center_email,
          representatives: element.representatives,
          notary: element.notary,
          status: element.status,
          coa_kits: element.coa_kits,
          notes: element.notes,
          file_id: element.file_id
        });
      }
    });
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }


  onFileUpload() { 
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
        let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx','zip','txt'];
        var re = /(?:\.([^.]+))?$/;
        var files = fileUpload.files;
        var file;
          // loop through files
        for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if(file && filecheck.indexOf(ext) != -1) {
            let size = file.size/1024/1024;
            if (size > 20) {
                this.fileError = 'File size should not be more than 20MB.';
                return;
            }
          this.file_multiple_data.push(file);
        }
        else {
            this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
 }
 
 this.addDocument(this.file_multiple_data);  
    };
    fileUpload.click();
  }

  addDocument(file) {
  this.uploaded_files_array = [];
    this.fileSuccess = '';
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
     }

    this.projectsService.uploadFile(uploadData)
    .subscribe((response) => {
      this.uploaded_files_array.push(response.data);
      response.data.forEach(element => {
      this.file_id_response=element.id;  
      });
        this.fileSuccess ='Uploaded successfully!!';
    });
    }
}
