import { OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ElementRef } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
var ProjectListComponent = /** @class */ (function () {
    function ProjectListComponent(projectsService, toastr, _formBuilder) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this._formBuilder = _formBuilder;
        this.paginate = {};
        this.pageSize = 20;
        this.pageIndex = 1;
        this.total_due_today = 0;
        this.query_projects = "";
        this.fQuery = "";
        this.fChipsArray = [];
        this.languageData = [];
        this.projectStatusData = [];
        this.projectLeadsource = [];
        this.projectmanager = [];
        this.filter_src_lang = "";
        this.filter_target_lang = "";
        this.filterProjectStatus = "";
        this.filterCity = "";
        this.filterCountry = "";
        this.filterLeadSource = "";
        this.filterCategory = "";
        this.filterPaymentMethod = "";
        this.filterProjectManager = "";
        this.filterEndDate = "";
        this.filterSearchQuery = "";
        this.filterStartDate = "";
        this.displayedColumns = ['id', 'custName', 'custEmail', 'projservices', 'languagePair', 'projStartDate', 'leadSrouce', 'projStatus'];
    }
    ProjectListComponent.prototype.ngOnInit = function () {
        this.getData();
        this.getLanguage();
        this.getProjectStatus();
        this.getProjectManager();
        this.getProjectSource();
        this.Form = this._formBuilder.group({
            search_value: [''],
        });
    };
    ProjectListComponent.prototype.getData = function () {
        var _this = this;
        var query = '';
        this.projectsService.getPmsProjects(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectLanguage.data.forEach(function (elm) {
                        if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        }
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.total_due_today = response.meta.pagination.total;
            }
        });
    };
    ProjectListComponent.prototype.paginateData = function (event) {
        var _this = this;
        var pQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_projects = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query_projects += pQuery;
        this.projectsService.getPmsProjects(this.query_projects)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectLanguage.data.forEach(function (elm) {
                        if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        }
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
            }
        });
    };
    // get Source Language & Target Language 
    ProjectListComponent.prototype.getLanguage = function () {
        var _this = this;
        this.projectsService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
            }
        });
    };
    // get project status
    ProjectListComponent.prototype.getProjectStatus = function () {
        var _this = this;
        this.projectsService.getProjectStatus()
            .subscribe(function (res) {
            if (res) {
                _this.projectStatusData = res.data;
            }
        });
    };
    // get project manager
    ProjectListComponent.prototype.getProjectManager = function () {
        var _this = this;
        this.projectsService.getProjectManager()
            .subscribe(function (res) {
            if (res) {
                res.data.forEach(function (element) {
                    if (element.user_group_id === 'Admin') {
                        _this.projectmanager.push(element.fullname);
                    }
                });
            }
        });
    };
    // get project source
    ProjectListComponent.prototype.getProjectSource = function () {
        var _this = this;
        this.projectsService.getLeadSource()
            .subscribe(function (res) {
            if (res) {
                _this.projectLeadsource = res.data;
            }
        });
    };
    ProjectListComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10';
        // project search filter
        if ((type == "search" && evt != "" && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        else if (type == "search" && evt == "") {
            this.filterSearchQuery = evt;
        }
        else if (this.filterSearchQuery !== "") {
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        // project status filter
        if ((type == "status" && evt.value != "" && evt.value !== undefined)) {
            this.filterProjectStatus = evt.value;
            this.fQuery += "&status=" + this.filterProjectStatus;
        }
        else if (type == "status" && evt.value == "") {
            this.filterProjectStatus = evt.value;
        }
        else if (this.filterProjectStatus !== "") {
            this.fQuery += "&status=" + this.filterProjectStatus;
        }
        // project Source Language filter 
        if ((type == "s_lang" && evt.value != "" && evt.value !== undefined)) {
            this.filter_src_lang = evt.value;
            this.fQuery += "&source_language=" + this.filter_src_lang;
        }
        else if (type == "s_lang" && evt.value == "") {
            this.filter_src_lang = evt.value;
        }
        else if (this.filter_src_lang !== "") {
            this.fQuery += "&source_language=" + this.filter_src_lang;
        }
        // project Target Language filter
        if ((type == "t_lang" && evt.value != "" && evt.value !== undefined)) {
            this.filter_target_lang = evt.value;
            this.fQuery += "&target_language=" + this.filter_target_lang;
        }
        else if (type == "t_lang" && evt.value == "") {
            this.filter_target_lang = evt.value;
        }
        else if (this.filter_target_lang !== "") {
            this.fQuery += "&target_language=" + this.filter_target_lang;
        }
        // project City filter
        // if ((type == "city" && evt.value != "" && evt.value !== undefined)) {
        //    this.filterCity = evt.value;
        //    this.fQuery += "&city=" + this.filterCity;
        // }
        // else if(type == "city" && evt.value == ""){
        //   this.filterCity = evt.value;  
        // }
        // else if(this.filterCity!==""){
        //  this.fQuery += "&city=" + this.filterCity;
        // }   
        if ((type == "city" && evt.trim().toLowerCase() != "" && evt.trim().toLowerCase() !== undefined)) {
            this.filterCity = evt.trim().toLowerCase();
            this.fQuery += "&city=" + this.filterCity;
        }
        else if (type == "city" && evt.trim().toLowerCase() == "") {
            this.filterCity = evt.trim().toLowerCase();
        }
        else if (this.filterCity !== "") {
            this.fQuery += "&city=" + this.filterCity;
        }
        // project country filter
        if ((type == "country" && evt.value != "" && evt.value !== undefined)) {
            this.filterCountry = evt.value;
            this.fQuery += "&country=" + this.filterCountry;
        }
        else if (type == "country" && evt.value == "") {
            this.filterCountry = evt.value;
        }
        else if (this.filterCountry !== "") {
            this.fQuery += "&country=" + this.filterCountry;
        }
        // project Project Manager filter
        if ((type == "ProjectManager" && evt.value != "" && evt.value !== undefined)) {
            this.filterProjectManager = evt.value;
            this.fQuery += "&project_pm=" + this.filterProjectManager;
        }
        else if (type == "ProjectManager" && evt.value == "") {
            this.filterProjectManager = evt.value;
        }
        else if (this.filterProjectManager !== "") {
            this.fQuery += "&project_pm=" + this.filterProjectManager;
        }
        // project lead source filter
        if ((type == "lead_source" && evt.value != "" && evt.value !== undefined)) {
            this.filterLeadSource = evt.value;
            this.fQuery += "&source=" + this.filterLeadSource;
        }
        else if (type == "lead_source" && evt.value == "") {
            this.filterLeadSource = evt.value;
        }
        else if (this.filterLeadSource !== "") {
            this.fQuery += "&source=" + this.filterLeadSource;
        }
        // project category filter
        if ((type == "category" && evt.value != "" && evt.value !== undefined)) {
            this.filterCategory = evt.value;
            this.fQuery += "&category=" + this.filterCategory;
        }
        else if (type == "category" && evt.value == "") {
            this.filterCategory = evt.value;
        }
        else if (this.filterCategory !== "") {
            this.fQuery += "&category=" + this.filterCategory;
        }
        // Payment Method filter
        if ((type == "payment_method" && evt.value != "" && evt.value !== undefined)) {
            this.filterPaymentMethod = evt.value;
            this.fQuery += "&payment_method=" + this.filterPaymentMethod;
        }
        else if (type == "payment_method" && evt.value == "") {
            this.filterPaymentMethod = evt.value;
        }
        else if (this.filterPaymentMethod !== "") {
            this.fQuery += "&payment_method=" + this.filterPaymentMethod;
        }
        // Start date filter
        if ((type == "start_date" && evt.value != "" && evt.value !== undefined)) {
            this.filterStartDate = evt.value.format("YYYY-MM-DD");
            this.fQuery += "&start_date=" + this.filterStartDate;
        }
        else if (type == "start_date" && evt.value == "") {
            this.filterStartDate = evt.value;
        }
        else if (this.filterStartDate !== "") {
            this.fQuery += "&start_date=" + this.filterStartDate;
        }
        // End date filter
        if ((type == "end_date" && evt.value != "" && evt.value !== undefined)) {
            this.filterEndDate = evt.value.format("YYYY-MM-DD");
            this.fQuery += "&end_date=" + this.filterEndDate;
        }
        else if (type == "end_date" && evt.value == "") {
            this.filterEndDate = evt.value;
        }
        else if (this.filterEndDate !== "") {
            this.fQuery += "&end_date=" + this.filterEndDate;
        }
        this.parseQueryString(this.fQuery);
        var query = this.fQuery;
        this.projectsService.getPmsProjects(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectLanguage.data.forEach(function (elm) {
                        if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        }
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
            }
        });
    };
    ProjectListComponent.prototype.submit = function () {
        this.onFilter(this.Form.value.search_value, 'search');
    };
    // query string
    ProjectListComponent.prototype.parseQueryString = function (queryString) {
        this.fChipsArray = [];
        var params = {
            search_text: '',
            status: '',
            source_language: '',
            target_language: '',
            city: '',
            country: '',
            project_pm: '',
            source: '',
            category: '',
            payment_method: '',
            start_date: '',
            end_date: '',
        }, queries, temp, i, l;
        // Split into key/value pairs
        queries = queryString.split("&");
        // Convert the array of strings into an object
        for (i = 0, l = queries.length; i < l; i++) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        this.get_filter_value(params);
    };
    ProjectListComponent.prototype.get_filter_value = function (data) {
        var _this = this;
        var key = Object.keys(data);
        if (key[0] == 'search_text') {
            if (data.search_text != "") {
                this.fChipsArray.push(data.search_text);
            }
        }
        if (key[1] == 'status') {
            this.projectStatusData.forEach(function (element) {
                if (data.status == element.id) {
                    _this.fChipsArray.push(element.pm_status);
                }
            });
        }
        if (key[2] == 'source_language') {
            this.languageData.forEach(function (element) {
                if (data.source_language == element.id) {
                    _this.fChipsArray.push(element.lang_name);
                }
            });
        }
        if (key[3] == 'target_language') {
            this.languageData.forEach(function (element) {
                if (data.target_language == element.id) {
                    _this.fChipsArray.push(element.lang_name);
                }
            });
        }
        if (key[4] == 'city') {
            if (data.city != "") {
                this.fChipsArray.push(data.city);
            }
        }
        if (key[5] == 'country') {
            if (data.country != "") {
                this.fChipsArray.push(data.country);
            }
        }
        if (key[6] == 'project_pm') {
            if (data.project_pm != "") {
                this.fChipsArray.push(data.project_pm);
            }
        }
        if (key[7] == 'source') {
            if (data.source != "") {
                this.fChipsArray.push(data.source);
            }
        }
        if (key[8] == 'category') {
            if (data.category == 1) {
                this.fChipsArray.push("DayVIP");
            }
            else if (data.category == 2) {
                this.fChipsArray.push("Company");
            }
            if (data.category == 3) {
                this.fChipsArray.push("Individual");
            }
            else if (data.category == 4) {
                this.fChipsArray.push("New Client");
            }
        }
        if (key[9] == 'payment_method') {
            if (data.payment_method == 1) {
                this.fChipsArray.push("NET30");
            }
            else if (data.payment_method == 2) {
                this.fChipsArray.push("NET45");
            }
            if (data.payment_method == 3) {
                this.fChipsArray.push("Regular/Upfront");
            }
            else if (data.payment_method == 4) {
                this.fChipsArray.push("Credit Available");
            }
        }
        if (key[10] == 'start_date') {
            if (data.start_date != "") {
                this.fChipsArray.push(data.start_date);
            }
        }
        if (key[11] == 'end_date') {
            if (data.end_date != "") {
                this.fChipsArray.push(data.end_date);
            }
        }
    };
    return ProjectListComponent;
}());
export { ProjectListComponent };
;
