<div  >

 <!-- <div *ngIf="additonal_service_array.length==0">
  Mailing Options <span class="aText">(NA)</span>
</div>
<div  *ngIf="additonal_service_array.length>0">
    Mailing Options <span class="aText">(Applied)</span>
  </div> -->
<div class="mopt p-20 " *ngIf="additonal_service_array.length==0 || !mailing_show">
  <p >Not Applied</p>
  </div>
<form name="Form"  [formGroup]="Form" *ngIf="additonal_service_array.length>0 && mailing_show"> 
<div class="mopt p-20">

  
<div *ngIf="show_address">

    <div *ngFor="let res of additonal_service_array">

        <div *ngIf="res.additional_service.code == 'certified_Mailing' || 
              res.additional_service.code == 'certified_Mailing_1Day' ||
               res.additional_service.code == 'certified_Mailing_International' ||
               res.additional_service.code == 'certified_Mailing_Canada' ">
  
         <p *ngIf="res?.additional_service?.title"><b> Mailing Options : </b> {{res?.additional_service?.title}}</p>
          <p *ngIf="res?.shipping_address"><b>Street Address:</b> {{res?.shipping_address}}</p>
          <p *ngIf="res?.shipping_city"><b> City : </b> {{res?.shipping_city}}</p>
          <p *ngIf="res?.shipping_state"><b> State : </b> {{res?.shipping_state}}</p>
          <p *ngIf="res?.shipping_country"> <b> Country : </b>{{res?.shipping_country}}</p>
          <p *ngIf="res?.shipping_phone"><b>Phone Number:</b> {{res?.shipping_phone}} </p>
        </div>
  
        <div *ngIf="res.additional_service.code == 'additional_copy' ">
          <p *ngIf="res.additional_service.title"><b> Mailing Options : </b> {{res.additional_service.title}}</p>
          <p *ngIf="res?.quantity"> <b> Additional copy required:</b> {{res?.quantity}} Set</p>
        </div>
  
        <div *ngIf="res.additional_service.code == 'regular_PickUp' ">
            <p *ngIf="res.additional_service.title"><b> Mailing Options : </b> {{res.additional_service.title}}</p>
            <p *ngIf="res?.pickup_location"><b > PickUp Location : </b> {{res?.pickup_location}}</p>
          </div>
  
  
      </div>

     <!-- completed data start -->

     <div class="tHeader" *ngIf="project_assignment_response_array.length>0">
      <h2>Additional Services</h2>
    </div>
    <div class="white-box " *ngIf="project_assignment_response_array.length>0">
      <div class="assignRow" fxFlexLayout="row" fxLayoutGap="10px" *ngFor="let language_res of target_language_select ; let i=index">
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxFlex="8">
            <p><span class="hText hGreen"><b>{{i+1}}/{{target_language_select.length}}</b></span></p>
          </div>
          <div fxFlex="15">
            <p class="dLabel">Language Pair</p>
            <p class="dValue">
              {{language_res.sourcelang_name}} - {{language_res.targetlang_name}}
            </p>
          </div>
          <div fxFlex="50">
            <p class="dLabel">Assignee</p>
            <div fxFlexLayout="row" *ngFor="let res of project_assignment_response_array">
              <div fxFlex >
                <div fxFlexLayout="row">
                  <p class="mt-4 mb-0"><b>{{res?.verndor?.first_name}}</b></p>
                </div>
              </div>

              
            <div fxFlex="20" class="text-center ">
              <p class="dValue" *ngIf="res.status==1">
                <span class="hText hGreen">
                  Assigned
                 </span>
              </p>
              <p class="dValue" *ngIf="res.status==5">
                <span class="hText hYellow">
                  Completed
                 </span>
              </p>
              <p class="dValue" *ngIf="res.status==7">
                <span class="hText hRed">
                  Canceled
                 </span>
              </p>

              <p class="dValue" *ngIf="res?.final_comment">
                <span >
                  {{res?.final_comment}}
                 </span>
              </p>
            </div>
            <div  fxFlex="20" class="mb-4">
              <p class="dValue" >
                <span class="hText ">
                  <button mat-raised-button (click)="openDialog(res.id)">Change Status</button>
                 </span>
              </p>
            </div>
            </div>
           

          </div>
         
        

      
        </div>
        <div class="processList">
          <div fxLayout="row">
            <div fxFlex *ngFor="let completed_workflow_res of completed_workflow_array">
              <p class="mt-4 mb-0"><b>{{completed_workflow_res.workflow_name}}</b></p>
              <p class="mt-0">{{completed_workflow_res.vendor_name}} <span class="font11 dBlock">
                  {{completed_workflow_res.assignment_date}} </span></p>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- completed data end -->
    <div class="actWraper1" *ngIf="show_address && project_assignment_response_array.length==0 && project_workflow_id">
      <p><button mat-raised-button color="accent" type="button" (click)="show_table()">Assign</button></p>
    
    </div>

  </div>
  <h4 class="mt-0 " *ngIf="show_form" >Available Mailing Rosources </h4>
  
  <table mat-table [dataSource]="dataSource" class="table bdr1" *ngIf="show_form">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"> <mat-checkbox [(ngModel)]="element.check" [ngModelOptions]="{standalone: true}"  (change)="selectFinance($event,element)"></mat-checkbox> </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> 
            {{element.first_name}} {{element.middle_name}} {{element.last_name}}
        </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>  Location</th>
      <td mat-cell *matCellDef="let element"> {{element.address1}} </td>
    </ng-container>
    <ng-container matColumnDef="services">
      <th mat-header-cell *matHeaderCellDef> Services</th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div *ngIf="response.length==0 && show_form" class="noDataFound"> No data available!!
</div>


  <h3 *ngIf="show_form"><b>Share Files</b></h3>    
    <table class="table table-bordered" *ngIf="file_data.length>0 && show_form" >
      <thead>
        <tr>
          <th scope="col"><mat-checkbox [(ngModel)]="bulk_selected_progress" [ngModelOptions]="{standalone: true}" (change)="selectBulkProgress($event)"></mat-checkbox></th>
          <th scope="col">Name</th>
          <th scope="col">category</th>
          <th scope="col">Language</th>
          <th scope="col">Last Modified</th>
          <th scope="col">Added By</th>
          <th scope="col">Shared With</th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let file_res of file_data">
          <th scope="row"><mat-checkbox  [(ngModel)]="file_res.check" [ngModelOptions]="{standalone: true}" (change)="selectFile($event,file_res)"></mat-checkbox></th>
          <td>                
                <a [href]="project_file_data" target="_blank" download>{{file_res.name}}</a>
          </td>
          <td>{{file_res.filetype}}</td>
          <td>{{file_res.type}}</td>
          <td>{{file_res.created_at.date | date: 'yyyy-MM-dd'}}  {{file_res.created_at.date | date:"HH:mm"}}</td>
          <td>{{file_res.fullname}}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="file_data.length == 0 && show_form" class="noDataFound"> No data available!!
    </div>

    <div class="actWraper" *ngIf="show_form"> 
    <div fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
        <div fxFlex>
            <mat-form-field    >
              <mat-label>Email Template</mat-label>
              <mat-select formControlName="email_template_id">
                  <mat-option [value]="''">
                      Select
                  </mat-option>
                <mat-option *ngFor="let res of response_email_data" [value]="res.id">
                    {{res.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="Form.get('email_template_id').hasError('required')">
                Email Template is required!
            </mat-error>
            </mat-form-field>
            </div>
          <div fxFlex>
              <mat-form-field  >
                  <mat-label>Due Date*</mat-label>
                <input matInput  (click)="dp2.open()"  [matDatepicker]="dp2" formControlName="due_date">
                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                <mat-datepicker  #dp2 disabled="false"></mat-datepicker>
                <mat-error *ngIf="Form.get('due_date').hasError('required')">
                  Due Date is required!
              </mat-error>
            </mat-form-field>
        </div>
      </div>


      <div fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
            <div fxFlex>
              <mat-form-field  >
                <mat-label>Message</mat-label>
                <textarea matInput placeholder="Add Message"  formControlName="instruction"> </textarea>
            </mat-form-field>
          </div>
        </div>

      </div>



</div>
<div class="actWraper" *ngIf="show_form">
  <p><button mat-raised-button color="accent" type="button" (click)="submit()" [disabled]="Form.invalid">Assign</button></p>

</div>
</form>
<!-- </mat-tab>
</mat-tab-group> -->



</div>
