<div *ngIf="workflow_check">
  <div class="white-box">
    <div class="tHeader pb-0 pt-0">
      <div class="container" fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
        <div fxFlex="30">
          <h2> <i class="material-icons">settings</i> Process to be used in project</h2>
        </div>
        <div fxFlex="68" fxFlexAlign="end">
          <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <form name="workFlowForm" [formGroup]="workFlowForm">
              <div fxFlex>
                <mat-form-field>
                  <mat-select placeholder="Select Process" formControlName="workflow_id">
                    <mat-option [value]="''">
                      Select Process
                    </mat-option>
                    <mat-option *ngFor="let res of response_workflow" [value]="res.id">
                      {{res.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="workFlowForm.get('workflow_id').hasError('required')">
                    workflow is required!
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex class="mt-12 ml-8">
                <button mat-stroked-button (click)="submitWorkFlow()" [disabled]="workFlowForm.invalid"><i
                    class="material-icons">add_circle</i> Add</button>
              </div>
            </form>
            <form name="FormAdditionalService" [formGroup]="FormAdditionalService">
              <div fxFlex>
                <mat-form-field>
                  <mat-select placeholder="Additional Services" formControlName="workflow_id">
                    <mat-option [value]="''">
                      Select Process
                    </mat-option>
                    <mat-option *ngFor="let res of response_workflow_additional_services" [value]="res.id">
                      {{res.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex class="mt-12 ml-8">
                <button mat-stroked-button (click)="submitAdditionalService()"
                  [disabled]="FormAdditionalService.invalid"><i class="material-icons">add_circle</i> Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="boxBody" id="top">
      <div class="boxBodyInn">
        <div class="scrolHorizontal">
          `<div fxLayout="row" fxLayoutGap="20px" fxLaoutAlign="left center">
            <div *ngFor="let res of projectWorkFlowResponse ">
              <div class="boxPrc  {{ 'workflow_' + res.project_status }}" fxFlex="225px">
                <div class="bxHead">
                  <div fxFlexLayout="row" fxFlexAlign="left center">
                    <div fxFlex="70">
                      <h4>{{res.workflow.name}}</h4>
                    </div>
                    <div class="text-right" fxFlex *ngIf="(res.project_status!='completed')">
                      <button mat-button class="btn-link" [matMenuTriggerFor]="menu"><i class="material-icons f25">
                          more_vert
                        </i></button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="deleteWorkFlow(res)">Remove</button>
                        <button mat-menu-item>Change Process</button>
                      </mat-menu>
                    </div>
                  </div>
                  <p class="mb-8">Due On : <b></b></p>
                </div>
                <div class="boxPrcIn">
                  <p *ngIf="language_pair_count>0"><span class="hText hWhite"> {{language_pair_count}} Pairs</span></p>
                </div>
                <div class="bxFtr" *ngIf="(res.project_status!='completed')">
                  <button mat-stroked-button color="primay" (click)="assignProcess(res)">Assign</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="white-box" *ngIf="Project_Assigned_obj?.length>0">
    <div class="tHeader">
      <h2>Project Assigned Information/Status:</h2>
    </div>
    <div class="boxBody">
      <table mat-table [dataSource]="dataSource2" class="table">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="pl-28"> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}}
            <div class="navigat">
              <a class="pr-8" *ngIf="element.project_status == 'Assigned'"
                (click)="cancelAssignedProject(element.id)">Cancel</a>
              <!-- | 
              <a  class="pl-8">Reassign</a>
              -->
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="processType">
          <th mat-header-cell *matHeaderCellDef> Process Type </th>
          <td mat-cell *matCellDef="let element"> {{element?.project_workflow?.workflow?.name}} </td>
        </ng-container>
        <ng-container matColumnDef="languages">
          <th mat-header-cell *matHeaderCellDef> Languages </th>
          <td mat-cell *matCellDef="let element">
            <span *ngFor="let res of element.lang_array">
              <p>{{res}}</p>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="freelancer">
          <th mat-header-cell *matHeaderCellDef> Freelancer </th>
          <td mat-cell *matCellDef="let element"> {{element.vendor.first_name}} {{element.vendor.middle_name}}
            {{element.vendor.last_name}} </td>
        </ng-container>
        <ng-container matColumnDef="plannedStartDate">
          <th mat-header-cell *matHeaderCellDef> Planned Start Date
          </th>
          <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
        </ng-container>
        <ng-container matColumnDef="plannedDeadline">
          <th mat-header-cell *matHeaderCellDef> Planned Deadline</th>
          <td mat-cell *matCellDef="let element"> {{element.due_date}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span class="bdg blank"> {{element.project_status}}
            </span> </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="fList"></tr>
      </table>
      <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
        [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[5,10,20,30,40]"
        (page)='paginateData($event)'>
      </mat-paginator>
    </div>
  </div>

  <div id="top1">
    <div class="white-box" *ngIf="show_translator">
      <div class="tHeader">
        <h2>Translation Assignment:</h2>
      </div>
      <div class="box-blank bg-green mt-0">
        <p>Please select min 3 Translator/Vendor</p>
        <p>It is in case one is not available, it will be sent automatically to the 2nd and then the 3rd, after by
          default 30 minutes.</p>
      </div>
      <div class="boxBody">
        <div class="boxBodyInn">
          <div class="assignRow assignRow1" fxFlexLayout="row" fxLayoutGap="10px"
            *ngFor="let language_res of target_language_select ; let i=index">
            <div fxLayout="row" fxLayoutGap="10px">
              <div fxFlex="8">
                <p><span class="hText hGreen"><b>{{i+1}}/{{target_language_select.length}}</b></span></p>
              </div>
              <div fxFlex="15">
                <p class="dLabel">Language Pair</p>
                <p class="dValue">
                  {{language_res.source_language_name}} - {{language_res.lang_name}}
                </p>
                <!-- <p class="dValue" >
                    {{source_language_value}} -  {{language_res.lang_name}}
                  </p> -->
              </div>
              <div fxFlex="50">
                <p class="dLabel">Assigned Information</p>
                <div fxFlexLayout="row">
                  <div fxFlex *ngFor="let res of vendor_array_match">
                    <div *ngIf="res.project_language_id ==language_res.project_lang_id ">
                      <p class="mt-4 mb-0"><b>{{res.amount}}</b></p>
                      <p class="mt-0">{{res.vendor.first_name}} {{res.vendor.last_name}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex="15" class="text-center" *ngIf="show_assign_button">
                <p><button mat-stroked-button (click)="getLanguageData(language_res)"> Assign </button></p>
              </div>
              <div fxFlex="20" class="text-center mt-8">
                <p class="dValue" *ngIf="show_workflow_status=='completed'">
                  <span class="hText hGreen">
                    Completed
                  </span>
                </p>
                <p class="dValue" *ngIf="show_workflow_status=='in_progress'">
                  <span class="hText hYellow">
                    In-Progress
                  </span>
                </p>
                <p class="dValue" *ngIf="show_workflow_status=='pending'">
                  <span class="hText hRed">
                    Pending
                  </span>
                </p>
              </div>
            </div>
            <div class="processList">
              <div fxLayout="row">
                <div fxFlex *ngFor="let completed_workflow_res of completed_workflow_array">
                  <p class="mt-4 mb-0"><b>{{completed_workflow_res.workflow_name}}</b></p>
                  <p class="mt-0">{{completed_workflow_res.vendor_name}} <span class="font11 dBlock">
                      {{completed_workflow_res.assignment_date}} </span></p>
                </div>
              </div>
             <div class="text-right" *ngIf="noData">
              <button mat-flat-button color="primary" class="mr-16 " type="button" (click)="openDialogAddTranslator()" >Add Translator</button>
             </div> 
            </div>
          </div>

          <!-- <div class="assignRow" fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px" *ngIf="false">
                    <div fxFlex="8">
                        <p><span class="hText hYellow"><b>1/3</b></span></p>
                    </div>
                    <div fxFlex="20">
                    <p class="dLabel">Source Language</p>
                    <p class="dValue">
                        <mat-form-field  >
                          <mat-select [(ngModel)]="data.source_lang_id" [ngModelOptions]="{standalone: true}" (selectionChange)="getSourceName($event)">
                            <mat-option [value]="''">
                                Select
                            </mat-option>
                          <mat-option [value]="source_language_id_value" >
                            {{source_language_value}}
                          </mat-option>
                        </mat-select>
                          </mat-form-field>
                      </p>
                    </div>
                        <div fxFlex="20">
                        <p class="dLabel">Target Language</p>
                        <p class="dValue">
                            <mat-form-field  >
                              <mat-select [(ngModel)]="data.target_lang_id" [ngModelOptions]="{standalone: true}" (selectionChange)="getTargetName($event)">
                                <mat-option [value]="''">
                                    Select
                                </mat-option>
                              <mat-option *ngFor="let language_res of target_language_select" [value]="language_res.targetlanguage_id">
                              {{language_res.lang_name}}
                            </mat-option>
                            </mat-select>
                              </mat-form-field>
                          </p>
                        </div>
                        <div fxFlex="50" class="text-center" *ngIf="show_search">
                          <p><button mat-raised-button color="accent" (click)="getLanguageData(data.source_lang_id,data.target_lang_id)"> <i class="material-icons">search</i> Search available {{source_lang_name}} > {{target_lang_name}} Translator </button></p>
                        </div>
                </div> -->
          <div id="show_available_translator">
            <div class="box-blank " *ngIf="show_table">
              <h4>Available <span class="txt-blue"> {{source_lang_name}} > {{target_lang_name}} </span> Vendor </h4>
              <div fxFlexLayout="row" fxLayoutGap="10px" fxFlexAlign="left center">
                <div fxFlex>
                  <mat-form-field>
                    <mat-label>Translator Type</mat-label>
                    <mat-select placeholder="Type" [(ngModel)]="translatorType"
                      (selectionChange)="onFilter($event,'type')">
                      <mat-option [value]="''">
                        Select
                      </mat-option>
                      <mat-option *ngFor="let res of arrType" [value]="res.value">
                        {{res.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-form-field>
                    <mat-label>Native Language</mat-label>
                    <mat-select [(ngModel)]="native_language" (selectionChange)="onFilter($event,'native_language')">
                      <mat-option [value]="''">
                        Select
                      </mat-option>
                      <mat-option *ngFor="let language of languageData" [value]="language.id">
                        {{language.lang_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-form-field>
                    <mat-label>Specialization</mat-label>
                    <mat-select [(ngModel)]="specialization" (selectionChange)="onFilter($event,'speciality')">
                      <mat-option [value]="''">
                        Select
                      </mat-option>
                      <mat-option *ngFor="let speciality of specialityData" [value]="speciality.id">
                        {{speciality.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-form-field>
                    <mat-label>Search Translator</mat-label>
                    <input matInput placeholder="Favorite translator" (keyup)="onFilter($event.target,'search')">
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="tablescrolldata" *ngIf="show_table">
            <table mat-table [dataSource]="dataSource_translator" class="table bdr1">
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox [(ngModel)]="element.check" (change)="selectFinance($event,element)"></mat-checkbox>

                </td>
              </ng-container>
              <ng-container matColumnDef="preference">
                <th mat-header-cell *matHeaderCellDef> Prefered </th>
                <td mat-cell *matCellDef="let element; let i = index;">

                  <input type="radio" [(ngModel)]="element.preference" (change)="radioChangeHandler($event,element)"
                    name="pref_{{i}}">
                  <mat-icon *ngIf="element?.important_note" color="warn" class="s-18 mr-8 ml-4"
                    matTooltipPosition="left" matTooltip="{{element?.important_note}}">priority_high</mat-icon>

                </td>

              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">

                  <a routerLink="/pms/freelancer/details/{{element.id}}" title="View details">
                    {{element.first_name}} {{element.middle_name}} {{element.last_name}}
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="tztype">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element?.type}} </td>
              </ng-container>
              <ng-container matColumnDef="native_language">
                <th mat-header-cell *matHeaderCellDef> Native Language
                </th>
                <td mat-cell *matCellDef="let element"> {{element?.native_language?.lang_name}} </td>
              </ng-container>
              <ng-container matColumnDef="category_level">
                <th mat-header-cell *matHeaderCellDef> Category Level
                </th>
                <td mat-cell *matCellDef="let element"> {{element?.category_level}} </td>
              </ng-container>
              <ng-container matColumnDef="currentJobs">
                <th mat-header-cell *matHeaderCellDef> Current Jobs</th>
                <td mat-cell *matCellDef="let element"> {{element?.current_jobs?.length}} </td>
              </ng-container>
              <ng-container matColumnDef="completedJobs">
                <th mat-header-cell *matHeaderCellDef> Completed Jobs</th>
                <td mat-cell *matCellDef="let element"> {{element?.completed_jobs?.length}} </td>
              </ng-container>
              <ng-container matColumnDef="bestRates">
                <th mat-header-cell *matHeaderCellDef>Best Rates USD</th>
                <td mat-cell *matCellDef="let element">
                  <p *ngFor="let res of element.vendor_translation_langs">
                    ${{res.translation_rate}} USD</p>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <div *ngIf="vendor_ids.length>0">
            <h2 class="m-20">Financial Analysis</h2>
            <p class="m-20">Translation Services</p>
            <div class="assignRow assignRow2 pl-16" *ngFor="let res of vendor">
              <h4 class="mb-0">
                {{res.first_name}} (@ {{res.vendor_translation_langs[0].translation_rate}} USD )
              </h4>
              <p class="mt-0">Quoted Rate: <b>{{quantity_rate}}</b> </p>
              <div class="text-center" fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
                <div fxFlex>
                  <p class="dLabel">Volume (source word)</p>
                  <!-- <p class="dValue">{{quantity_approve}}</p> -->
                  <p class="dValue">{{DetailMainResponse?.sprj1_t9n_wordcount_c?.value}}</p>
                </div>
                <div fxFlex>
                  <!-- <p class="dLabel" *ngIf="Opp_Amount">Opp Amount</p> -->
                  <p class="dLabel" *ngIf="opportunityAmount">Opp Amount</p>
                  <!-- <p class="dValue">${{Opp_Amount}} USD</p> -->
                  <p class="dValue">${{opportunityAmount}} USD</p>
                </div>
                <div fxFlex>
                  <p class="dLabel">
                    Frelancer Cost
                  </p>
                  <!-- <p class="dValue" *ngIf="res.vendor_translation_langs[0].translation_rate * quantity_approve">${{ roundToTwo(res.vendor_translation_langs[0].translation_rate * quantity_approve)}} USD</p> -->
                  <p class="dValue" >${{ roundToTwo(res.vendor_translation_langs[0].translation_rate * DetailMainResponse?.sprj1_t9n_wordcount_c?.value)}} USD</p>
                </div>
                <div fxFlex>
                  <p class="dLabel">
                    Profit Margin %
                  </p>
                  <!-- <p class="dValue" *ngIf="profit_margin">
                    <span class="hText hYellow">
                      {{ roundToTwo((Opp_Amount- res.vendor_translation_langs[0].translation_rate * quantity_approve)/Opp_Amount*100) }}
                    </span>
                  </p> -->
                  <p class="dValue" >
                    <span class="hText hYellow">
                      {{ roundToTwo((opportunityAmount- res.vendor_translation_langs[0].translation_rate * DetailMainResponse?.sprj1_t9n_wordcount_c?.value)/opportunityAmount*100) }}
                    </span>
                  </p>
                </div>
                <div fxFlex>
                  <p class="dLabel">
                    Revenue 2222
                  </p>
                  <!-- <p class="dValue"
                    *ngIf="Opp_Amount- res.vendor_translation_langs[0].translation_rate * quantity_approve">
                    ${{roundToTwo(Opp_Amount- res.vendor_translation_langs[0].translation_rate * quantity_approve)}} USD
                  </p> -->

                  <p class="dValue">
                    {{roundToTwoWithName(opportunityAmount- res.vendor_translation_langs[0].translation_rate * DetailMainResponse?.sprj1_t9n_wordcount_c?.value)}}
                  </p>
                </div>
              </div>
            </div>

            <!-- file section start-->
            <h3 class="m-20"><b>Share Files</b></h3>
            <div class="boxBody">
              <table class="table table-bordered" *ngIf="file_data.length>0">
                <thead>
                  <tr>
                    <th scope="col">
                      <mat-checkbox [(ngModel)]="bulk_selected_progress" [ngModelOptions]="{standalone: true}"
                        (change)="selectBulkProgress($event)"></mat-checkbox>
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">category</th>
                    <th scope="col">Language</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col">Added By</th>
                    <th scope="col">Shared With</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let file_res of file_data">
                    <th scope="row">
                      <mat-checkbox [(ngModel)]="file_res.check" [ngModelOptions]="{standalone: true}"
                        (change)="selectFile($event,file_res)"></mat-checkbox>
                    </th>
                    <td>
                      <a [href]="project_file_data" target="_blank" download>{{file_res.name}}</a>
                    </td>
                    <td>{{file_res.filetype}}</td>
                    <td>{{file_res.type}}</td>
                    <td>{{file_res.created_at.date | date: 'yyyy-MM-dd'}} {{file_res.created_at.date | date:"HH:mm"}}
                    </td>
                    <td>{{file_res.fullname}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div class="noDataFound" *ngIf="file_data.length==0">
                No data available!!
              </div>
            </div>
            <!-- file section  end-->
            <form name="Form" [formGroup]="Form">
              <div class="box-blank">
                <h4>Set Assignment Duration: </h4>
                <div class="box-blank bg-green">
                  <p>Recomended duration is <b>30 minutes</b> </p>
                </div>
                <p><b>Set customer duration</b></p>
                <div fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex="50">
                    <mat-form-field>
                      <mat-label>Duration</mat-label>
                      <mat-select formControlName="duration" (selectionChange)="getDuration($event)">
                        <mat-option value="5">5 minutes</mat-option>
                        <mat-option value="10">10 minutes</mat-option>
                        <mat-option value="15">15 minutes</mat-option>
                        <mat-option value="30">30 minutes</mat-option>
                        <mat-option value="45">45 minutes</mat-option>
                        <mat-option value="60">1 Hour</mat-option>
                        <mat-option value="90">1 Hour 30 Minutes</mat-option>
                        <mat-option value="120">2 Hours</mat-option>
                        <mat-option value="180">3 Hours</mat-option>
                        <mat-option value="240">4 Hours</mat-option>
                        <mat-option value="300">5 Hours</mat-option>
                        <mat-option value="480">8 Hours</mat-option>
                        <mat-option value="1440">24 Hours</mat-option>
                        <mat-option value="2880">48 Hours</mat-option>
                        <mat-option value="4320">72 Hours</mat-option>
                      </mat-select>
                      <mat-error *ngIf="Form.get('duration').hasError('required')">
                        Duration is required!
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="50" *ngIf="customer_duration">
                    <mat-form-field>
                      <mat-label>Reason type for more than 30 minutes</mat-label>
                      <mat-select formControlName="duration_comment">
                        <mat-option [value]="''">
                          Select
                        </mat-option>
                        <mat-option value="Rare Language">Rare Language</mat-option>
                        <mat-option value="No Translator Available">No Translator Available</mat-option>
                        <mat-option value="No Idea">No Idea</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="cmntBox mt-16" *ngIf="customer_duration">
                  <mat-form-field>
                    <mat-label>Justification for more than 30 Minutes</mat-label>
                    <textarea matInput placeholder="Add Justification"> </textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="box-blank">
                <h4> Set Assignment Updates: </h4>
                <div class="box-blank bg-green">
                  <p> Based on dedlines <b>3 Updates</b> Required by Every<b> 4 Hours</b> </p>
                </div>
                <p><b>Set customer updated duration</b></p>
                <div fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex="50">
                    <mat-form-field>
                      <mat-label>Every</mat-label>
                      <mat-select formControlName="update_status_interval"
                        (selectionChange)="getAssignmentUpdate($event)">
                        <mat-option value="60">1 Hour</mat-option>
                        <mat-option value="120">2 Hours</mat-option>
                        <mat-option value="180">3 Hours</mat-option>
                        <mat-option value="240">4 Hours</mat-option>
                        <mat-option value="300">5 Hours</mat-option>
                        <mat-option value="360">6 Hours</mat-option>
                      </mat-select>
                      <mat-error *ngIf="Form.get('update_status_interval').hasError('required')">
                        Update Status Interval is required!
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="50">
                    <mat-form-field *ngIf="assignment_update">
                      <mat-label>Reason type for more than 4 hours</mat-label>
                      <mat-select formControlName="update_status_note">
                        <mat-option [value]="''">
                          Select
                        </mat-option>
                        <mat-option value="Rare Language">Rare Language</mat-option>
                        <mat-option value="No Translator Available">No Translator Available</mat-option>
                        <mat-option value="No Idea">No Idea</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="cmntBox mt-16" *ngIf="assignment_update">
                  <mat-form-field>
                    <mat-label>Justification for more than 4 hours</mat-label>
                    <textarea matInput placeholder="Add Justification" formControlName="instruction"> </textarea>
                  </mat-form-field>
                </div>
                <div fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex>
                    <mat-form-field>
                      <mat-label>Email Template</mat-label>
                      <mat-select formControlName="email_template_id">
                        <mat-option [value]="''">
                          Select
                        </mat-option>
                        <mat-option *ngFor="let res of response_email_data" [value]="res.id">
                          {{res.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="Form.get('email_template_id').hasError('required')">
                        Email Template is required!
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex>
                    <mat-form-field>
                      <mat-label>Due Date*</mat-label>
                      <input matInput [min]="today" (click)="dp1.open()" [matDatepicker]="dp1"
                        formControlName="due_date">
                      <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                      <mat-datepicker (dateChange)="checkStart()" #dp1></mat-datepicker>
                      <mat-error>Due Date is required!</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="actWraper mmin20" *ngIf="vendor_ids.length>0">
                <div fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px;" *ngIf="vendor_ids.length>0">
                  <div fxFlex class="text-left">
                    <button mat-stroked-button color="warn" type="button">Cancel</button>
                  </div>
                  <div fxFlex>
                    <!-- <button mat-flat-button color="primary" class="mr-16" type="button">Save Draft</button> -->
                    <button mat-flat-button color="primary" class="mr-16" type="button" (click)="openDialog()"  [disabled]="Form.invalid">Next</button>
                    <!-- <button mat-raised-button color="accent" type="button" (click)="submit()"
                      [disabled]="Form.invalid">Submit</button> --> 
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="top2">
  <!--project process proofreading start -->
  <div *ngIf="show_Proofreading">
    <app-project-proofreading></app-project-proofreading>
  </div>
  <!--project process proofreading End -->
  <!--project process QA start -->
  <div *ngIf="show_Qa">
    <app-project-qa></app-project-qa>
  </div>
  <!--project process QA end -->
  <!--project process editing start -->
  <div *ngIf="show_Editing">
    <app-project-editing></app-project-editing>
  </div>
  <!--project process editing end -->

  <!--project process dtp start -->
  <div *ngIf="show_Dtp">
    <app-project-dtp></app-project-dtp>
  </div>
  <!--project process dtp end -->

  <!--project process delivery start -->
  <div *ngIf="show_Delivery">
    <app-project-delivery></app-project-delivery>
  </div>
  <!--project process delivery end -->
</div>
<!-- <div *ngIf="true">
         
              <app-completed-workflow-detail></app-completed-workflow-detail>
            
      </div> -->
<div id="top3">
  <div *ngIf="true">
    <app-additional-services></app-additional-services>
  </div>
</div>