import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(public snackBar: MatSnackBar, private router: Router,
    private toasterService: ToastrService,
    public http: HttpClient, private _fuseProgressBarService: FuseProgressBarService) {
  }

  static TEAMEM_ID: string = 'teamID';
  static LOGIN: string = 'login';
  static TOKEN: string = 'token';
  static ID: string = 'id';
  static USER_NAME: string = 'username';
  static EMAIL: any = 'email';
  static PROFILE_PIC: string = 'profile_pic';
  static user_group_id: string = 'user_group_id';
  static SELECTED_TEAM: string = 'selectedTeam';
  static VIEW_CONTACT: string = 'viewcontact';
  static NAVIGATION: string = 'navigation';
  static NAME: string = 'name';
  static REMEMBER: string = 'remember';
  static QUOTE_ID: any = 'quote_id';
  static Project_id: any = 'Project_id';
  // pagination
  static table_name: string = 'table_name';
  static page: string = 'page';
  static per_page: string = 'per_page';
  static pagination: string = 'pagination';
  
  static hrNativeFilter: string = 'hrNativeFilter';
  static hrCategoryFilter: string = 'hrCategoryFilter';
  static hrSourceFilter: string = 'hrSourceFilter';
  static hrTargetFilter: string = 'hrTargetFilter';
  

  static newRequestFilter: string = 'newRequestFilter';
  static inProgressFilter: string = 'inProgressFilter';
  static nonResponsiveFilter: string = 'nonResponsiveFilter';
  static needApprovalFilter: string = 'needApprovalFilter';
  static pendingAdminApprovalFilter: string = 'pendingAdminApprovalFilter';
  static archiveFilter: string = 'archiveFilter';

  static newRequestPagination: string = 'newRequestPagination';
  static inProgressPagination: string = 'inProgressPagination';
  static nonResponsivePagination: string = 'nonResponsivePagination';
  static closedPagination: string = 'closedPagination';
  static blacklistedPagination: string = 'blacklistedPagination';
  static needApprovalPagination: string = 'needApprovalPagination';
  static pendingAdminApprovalPagination: string = 'pendingAdminApprovalPagination';
  static archivePagination: string = 'archivePagination';

  static t_native_filter: string = 't_native_filter';
  static t_specialization: string = 't_specialization';
  static t_source_lang: string = 't_source_lang';
  static t_target_lang: string = 't_target_lang';
  static Filter: string = 'filter';

  static importedFilter: string = 'importedFilter';
  static imported_native_filter: string = 'imported_native_filter';
  static imported_category: string = 'imported_category';
  static vednor_skill: string = 'vednor_skill';
  static vednor_search: string = 'vednor_search';
  static imported_source_lang: string = 'imported_source_lang';
  static imported_target_lang: string = 'imported_target_lang';
  static importedPagination: string = 'importedPagination';

  static pms_freelancer_Filter: string = 'pms_freelancer_Filter';
  static pms_freelancer_native_filter: string = 'pms_freelancer_native_filter';
  static pms_freelancer_specialization_filter: string = 'pms_freelancer_specialization_filter';
  static pms_freelancer_source_lang_filter: string = 'pms_freelancer_source_lang_filter';
  static pms_freelancer_target_lang_filter: string = 'pms_freelancer_target_lang_filter';
  static pms_freelancer_service_filter: string = 'pms_freelancer_service_filter';
  static pms_freelancer_search_filter: string = 'pms_freelancer_search_filter';
  static pms_freelancer_days_available_filter: string = 'pms_freelancer_days_available_filter';
  static pms_freelancer_gender_filter: string = 'pms_freelancer_gender_filter';
  static pms_freelancer_tools_filter: string = 'pms_freelancer_tools_filter';
  static pms_freelancer_timezone_filter: string = 'pms_freelancer_timezone_filter';
  static pms_freelancer_age_filter: string = 'pms_freelancer_age_filter';
  static pms_freelancer_category_level_filter: string = 'pms_freelancer_category_level_filter';
  static pms_freelancer_avt_status_level_filter: string = 'pms_freelancer_avt_status_level_filter';
  static pms_freelancer_country_filter: string = 'pms_freelancer_country_filter';
  static pms_freelancer_city_filter: string = 'pms_freelancer_city_filter';
  static pms_freelancer_rate_filter: string = 'pms_freelancer_rate_filter';
  static pms_freelancer_startdate_filter: string = 'pms_freelancer_startdate_filter';
  static pms_freelancer_enddate_filter: string = 'pms_freelancer_enddate_filter';
  static pms_freelancer_pagination: string = 'pms_freelancer_pagination';
  static pms_freelancer_avt_service_filter: string = 'pms_freelancer_avt_service_filter';

  static workflowAssignProcessObj: string = 'workflowAssignProcessObj';

  static get(key: string) {
    return localStorage.getItem(key);
  }

  static clearStorage() {
    localStorage.clear();
  }

  static set(key: string, value) {
    localStorage.setItem(key, value);
  }

  static getHeader() {
    if (localStorage.getItem('token') != null) {
      return new HttpHeaders()

        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Methods', "GET, POST, DELETE, PUT,PATCH");
    }
    return null;
  }

  static getPutHeader() {
    return new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    ).set('Content-Type', 'application/json');
  }

  static getFileHeader() {
    if (localStorage.getItem('token') != null) {
      return new HttpHeaders()
        .set('Authorization', localStorage.getItem('token'));
    }
    return null;
  }

  handleError(err) {
    this._fuseProgressBarService.show();
    let message = 'Something went wrong. Please try later!!';
    this.snackBar.open(err, null, {
      duration: 3000,
      panelClass: 'msg-danger'
    });
    this._fuseProgressBarService.hide();
  }

  getApi(url: string) {
    this._fuseProgressBarService.show();
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers }).pipe(
      map(response => {
        
        this._fuseProgressBarService.hide();
        return response;
      }
      ));

  }

  postApi(url: string, data: any) {
    this._fuseProgressBarService.show();
    let headers = SharedService.getPutHeader();
    return this.http.post(url, data, { headers }).pipe(
      map(response => {
        this._fuseProgressBarService.hide();
        return response;
      }
      ));
  }


  patchApi(url: string, data: any) {
    this._fuseProgressBarService.show();
    let headers = SharedService.getPutHeader();
    return this.http.patch(url, data, { headers }).pipe(
      map(response => {
        this._fuseProgressBarService.hide();
        return response;
      }
      ));
  }

  deleteApi(url: string) {
    this._fuseProgressBarService.show();
    let headers = SharedService.getPutHeader();
    return this.http.delete(url, { headers }).pipe(
      map(response => {
        this._fuseProgressBarService.hide();
        return response;
      }
      ));

  }

  postFileApi(url: string, data: any) {
    this._fuseProgressBarService.show();
    let headers = SharedService.getFileHeader();
    return this.http.post(url, data, { headers }).pipe(
      map(response => {
        this._fuseProgressBarService.hide();
        return response;
      }
      ));
  }


  postFileWhisperApi(url: string, data: any) {
    this._fuseProgressBarService.show();
    return this.http.post(url, data,{responseType: 'text'}).pipe(
      map(response => {
        this._fuseProgressBarService.hide();
                return response;
      }
      ));
  }

  public toastr(data) {
      this.toasterService.error(data.message);
  }

  public show() {
    this._fuseProgressBarService.show();
  }
  public hide() {
    this._fuseProgressBarService.hide();
  }

}