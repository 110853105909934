import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { UserService } from 'app/main/Services/user.service';
import { ToastrService } from 'ngx-toastr';
var WorkflowMasterComponent = /** @class */ (function () {
    //dataSource = ELEMENT_DATA;
    function WorkflowMasterComponent(userService, toastr) {
        this.userService = userService;
        this.toastr = toastr;
        this.workflowData = [];
        this.paginate = {};
        this.pageIndex = 1;
        this.query = '';
        this.pageSize = 10;
        this.displayedColumns = ['id', 'name', 'description', 'action'];
    }
    WorkflowMasterComponent.prototype.ngOnInit = function () {
        this.getWorkflow();
    };
    WorkflowMasterComponent.prototype.getWorkflow = function () {
        var _this = this;
        var query = '?page=1&per_page=5';
        this.userService.getWorkflow(query)
            .subscribe(function (res) {
            if (res) {
                _this.workflowData = res.data;
                _this.dataSource = new MatTableDataSource(_this.workflowData);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    WorkflowMasterComponent.prototype.paginateData = function (event) {
        var _this = this;
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.userService.getWorkflow(this.query)
            .subscribe(function (res) {
            if (res) {
                _this.workflowData = res.data;
                _this.dataSource = new MatTableDataSource(_this.workflowData);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    return WorkflowMasterComponent;
}());
export { WorkflowMasterComponent };
