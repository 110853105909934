import { OnInit } from '@angular/core';
import { VendorService } from 'app/main/Services/vendor.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { EditTemplateMasterComponent } from './edit-template-master/edit-template-master.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
var TemplateMasterComponent = /** @class */ (function () {
    function TemplateMasterComponent(vendorService, router, toastr, dialog, _formBuilder) {
        this.vendorService = vendorService;
        this.router = router;
        this.toastr = toastr;
        this.dialog = dialog;
        this._formBuilder = _formBuilder;
        this.displayedColumns = ['position', 'title', 'desc', 'email_from', 'email_to', 'status', 'action'];
        this.paginate = {};
        this.pageSize = 10;
        this.pageIndex = 1;
        this.filterStatus = '';
        this.filterSearchQuery = '';
        this.templateType = [];
    }
    TemplateMasterComponent.prototype.ngOnInit = function () {
        var query = '';
        this.getAllTemplate(query);
        this.getTemplateType();
        this.config = {
            width: '100%',
            height: '155px',
            toolbar: [
                { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'] },
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
                { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
                { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                '/',
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
                { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                '/',
                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                { name: 'colors', items: ['TextColor', 'BGColor'] },
                { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
                { name: 'about', items: ['About'] }
            ]
            // toolbar: [
            //     { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
            //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }
            // ]
        };
        this.Form = this._formBuilder.group({
            title: ['', Validators.compose([Validators.required])],
            subject: ['', Validators.compose([Validators.required])],
            ckeditorContent: [''],
            status: [0],
            email_from: [''],
            email_to: [''],
            type: ['']
        });
    };
    TemplateMasterComponent.prototype.getAllTemplate = function (query) {
        var _this = this;
        var template_pagination = localStorage.getItem('pagination');
        var templateObject = JSON.parse(template_pagination);
        if (template_pagination !== null && templateObject.table_name == 'template') {
            query = '?page=' + templateObject.page + '&per_page=' + templateObject.per_page;
        }
        else {
            query = '?page=1&per_page=10';
        }
        this.vendorService.getAllTemplate(query)
            .subscribe(function (res) {
            if (res) {
                _this.dataSource = new MatTableDataSource(res.data);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    TemplateMasterComponent.prototype.paginateData = function (event) {
        var _this = this;
        var pQuery = '';
        var query_projects = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        query_projects = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        if ((this.filterStatus != '' && this.filterStatus !== undefined)) {
            query_projects += '&is_active=' + this.filterStatus;
        }
        else if (this.filterStatus == '') {
            this.filterStatus = '';
        }
        else if (this.filterStatus !== '') {
            query_projects += '&is_active=' + this.filterStatus;
        }
        if ((this.filterSearchQuery != '' && this.filterSearchQuery !== undefined)) {
            query_projects += '&search_text=' + this.filterSearchQuery;
        }
        else if (this.filterSearchQuery == '') {
            this.filterSearchQuery = '';
        }
        else if (this.filterSearchQuery !== '') {
            query_projects += '&search_text=' + this.filterSearchQuery;
        }
        var templateObject = { table_name: 'template', page: this.pageIndex, per_page: this.pageSize };
        SharedService.set(SharedService.pagination, JSON.stringify(templateObject));
        pQuery += query_projects;
        this.vendorService.getAllTemplate(pQuery)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    TemplateMasterComponent.prototype.openDialog = function (res) {
        var _this = this;
        var data;
        data = res;
        var dailogRef = this.dialog.open(EditTemplateMasterComponent, {
            width: '1050px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            var query_projects = '?page=' + _this.pageIndex + '&per_page=' + _this.pageSize;
            _this.getAllTemplate(query_projects);
        });
    };
    TemplateMasterComponent.prototype.submit = function () {
        var _this = this;
        if (this.Form.value.ckeditorContent == '') {
            this.toastr.error('Template body never be empty!!');
            return;
        }
        var data = {
            name: '',
            subject: '',
            body: '',
            is_active: '',
            email_from: '',
            email_to: '',
            type: ''
        };
        data.name = this.Form.value.title;
        data.subject = this.Form.value.subject;
        data.body = this.Form.value.ckeditorContent;
        data.is_active = this.Form.value.status;
        data.email_from = this.Form.value.email_from;
        data.email_to = this.Form.value.email_to;
        data.type = this.Form.value.type;
        this.vendorService.addTemplateMaster(data).subscribe(function (resposne) {
            if (resposne) {
                var query = '';
                _this.Form.patchValue({
                    title: '',
                    subject: '',
                    status: 0,
                    ckeditorContent: ''
                });
                _this.getAllTemplate(query);
                _this.toastr.success('Template Master added successfully');
            }
        });
    };
    TemplateMasterComponent.prototype.back = function () {
        window.history.back();
    };
    TemplateMasterComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        var tQuery = '?page=1&per_page=10';
        //  search filter
        if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
            this.filterStatus = evt.value;
            tQuery += '&is_active=' + this.filterStatus;
        }
        else if (type == 'status' && evt.value == '') {
            this.filterStatus = evt.value;
        }
        else if (this.filterStatus !== '') {
            tQuery += '&is_active=' + this.filterStatus;
        }
        if ((type == 'search' && evt != '' && evt !== undefined)) {
            this.filterSearchQuery = evt;
            tQuery += '&search_text=' + this.filterSearchQuery;
        }
        else if (type == 'search' && evt == '') {
            this.filterSearchQuery = evt;
        }
        else if (this.filterSearchQuery !== '') {
            tQuery += '&search_text=' + this.filterSearchQuery;
        }
        var query = tQuery;
        this.vendorService.getAllTemplate(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    TemplateMasterComponent.prototype.getTemplateType = function () {
        var _this = this;
        var query = '';
        this.vendorService.getTemplateType(query)
            .subscribe(function (res) {
            if (res) {
                _this.templateType = res;
            }
        });
    };
    return TemplateMasterComponent;
}());
export { TemplateMasterComponent };
