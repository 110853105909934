<div id="portal-selector" class="page-layout simple fullwidth">

  <div class="header" fxLayout="row" fxLayoutAlign="start start">

      <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>

          <div class="h1">Please Select Master Database!</div>
          <div class="h3">
              Please select below master table to add or edit the data.
          </div>

      </div>

  </div>

<div class="container" fxLayout="row wrap">
<div fxFlex>

    <div class="card">
        <div class="card-body">
                <button class="btnPlain w100" [routerLink]="'/masterdata/language-master'">
            <div class="cinn">
     
            <div class="titleWRaper">
                <h2>Language Master<i class="iconArrow material-icons"> arrow_forward </i> </h2>

            </div>

            <hr />

            <div class="info">
                <p>Manage Languages and rates.</p>
            </div>
        </div>
    </button>

       

        </div>
    </div>
</div>

<div fxFlex>
    <div class="card">
        <div class="card-body">
                <button class="btnPlain" [routerLink]="'/masterdata/location-master'">
                <div class="cinn">
      
            <div class="titleWRaper">
                <h2>Location Master <i class="iconArrow material-icons"> arrow_forward </i></h2>
            </div>
            <hr />
            <div class="info" fxLayout="column">
                <p>Manage office Location address and images.</p>
            </div> </div>

            </button>
           

        </div>
    </div>

</div>



<div fxFlex>
    <div class="card">
      <div class="card-body">
              <button class="btnPlain"  [routerLink]="'/masterdata/template-master'">
              <div class="cinn">
             
          <div class="titleWRaper">
              <h2>Template Master <i class="iconArrow material-icons"> arrow_forward </i></h2>
          </div>
          <hr />
          <div class="info" fxLayout="column">
              <p>Add or edits templates that applies to specific services.</p>
          </div>
              </div>
  </button>
      </div>
  </div>
  
  </div>



</div>


<div class="container" fxLayout="row wrap">

    <div fxFlex *ngIf="false">
        <div class="card">
          <div class="card-body">
                  <button class="btnPlain"  [routerLink]="'/masterdata/workflow-master'">
                  <div class="cinn">
              
              <div class="titleWRaper">
                  <h2>Wrokflow Master <i class="iconArrow material-icons"> arrow_forward </i></h2>
              </div>
              <hr />
              <div class="info" fxLayout="column">
                  <p>Add or edits workflow that applies to specific services.</p>
              </div>
                  </div>
      </button>
          </div>
      </div>
      
      </div>
      
      
      <div fxFlex  *ngIf="false">
        <div class="card">
          <div class="card-body">
                  <button class="btnPlain" [routerLink]="'/masterdata/services-master'">
                  <div class="cinn">
       
              <div class="titleWRaper">
                  <h2>Services Master<i class="iconArrow material-icons"> arrow_forward </i></h2>
              </div>
              <hr />
              <div class="info" fxLayout="column">
                  <p>Manage types of services that we provide </p>
              </div>
         
          </div>
      </button>
      
          </div>
      </div>
      
      </div>
      
        <div fxFlex  *ngIf="false">
            <div class="card">
              <div class="card-body">
                      <button class="btnPlain"  [routerLink]="'/masterdata/additionalservices-master'">
                      <div class="cinn">
                               <div class="titleWRaper">
                      <h2>Additional Services Master <i class="iconArrow material-icons"> arrow_forward </i></h2>
                  </div>
                  <hr />
                  <div class="info" fxLayout="column">
                      <p>Add or edits additional services that applies to specific services.</p>
                  </div>
                      </div>
          </button>
              </div>
          </div>
          
          </div>

</div>



  

</div>