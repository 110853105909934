<div class="white-box">
    <div class="tHeader">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxFlex>
          <h2>Project Delivery</h2>
        </div>
        <div fxFlex class="text-right">
        </div>
      </div>
    </div>
    <div class="boxBody">
      <div class="boxBodyInn">
        <div class="assignRow"  *ngFor="let language_res of target_language_select ; let i=index">
          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="8">
              <p><span class="hText hWhite"><b>{{i+1}}/{{target_language_select.length}}</b></span></p>
            </div>
  
            <div fxFlex="15">
              <p class="dLabel">Language Pair</p>
              <p class="dValue">
                  {{language_res.source_lang_name}} -  {{language_res.target_lang_name}}</p> </div> 
                  
              <div fxFlex class="text-right" *ngIf="show_Delivery_status!='completed'">
                    <p><button mat-stroked-button (click)="showDeliverForm()"> Deliver Project</button></p>
              </div>
              <div fxFlex="20" class="text-center mt-8">
                        
                <p class="dValue" *ngIf="show_Delivery_status=='completed'">
                  <span class="hText hGreen">
                     Completed
                   </span>
                </p>
                <p class="dValue" *ngIf="show_Delivery_status=='in_progress'">
                  <span class="hText hYellow">
                    In-Progress
                   </span>
                </p>
                <p class="dValue" *ngIf="show_Delivery_status=='pending'">
                  <span class="hText hRed">
                   Pending
                   </span>
                </p>
        </div>
          </div>
  
          <div class="processList">
              <div fxLayout="row">
                <div fxFlex *ngFor="let completed_workflow_res of completed_workflow_array">
                  <p class="mt-4 mb-0"><b>{{completed_workflow_res.workflow_name}}</b></p>
                  <p class="mt-0">{{completed_workflow_res.vendor_name}} <span class="font11 dBlock"> {{completed_workflow_res.assignment_date}} </span></p>
                </div>
              </div>
            </div>
        </div>
  
        <form name="Form" [formGroup]="Form" *ngIf="show_deliver_form">
        <div class="box-blank p20">
          <h4 class="mt-0">Please make sure the following before delivery </h4>
  
          <p><mat-checkbox (change)="check_term_conditions($event)" formControlName="payment_checked">Payment Checked.</mat-checkbox></p>

          <p><mat-checkbox (change)="check_term_conditions($event)" formControlName="finished_file_naming">Finished File naming convension checked.</mat-checkbox></p>   
          
          <p><mat-checkbox (change)="check_term_conditions($event)" formControlName="due_date">Due date checked.</mat-checkbox></p> 

          <p><mat-checkbox (change)="check_term_conditions($event)" formControlName="customer_notes"> Customer Notes checked.</mat-checkbox></p>  


          <h3><b>Share Files</b></h3>
          <div class="boxBody">
      
              <table class="table table-bordered" *ngIf="file_data.length>0">
                <thead>
                  <tr>
                    <th scope="col"><mat-checkbox [(ngModel)]="bulk_selected_progress" [ngModelOptions]="{standalone: true}" (change)="selectBulkProgress($event)"></mat-checkbox></th>
                    <th scope="col">Name</th>
                    <th scope="col">category</th>
                    <th scope="col">Language</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col">Added By</th>
                    <th scope="col">Shared With</th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let file_res of file_data">
                    <th scope="row"><mat-checkbox  [(ngModel)]="file_res.check" [ngModelOptions]="{standalone: true}" (change)="selectFile($event,file_res)"></mat-checkbox></th>
                    <td>                
                          <a [href]="project_file_data" target="_blank" download>{{file_res.name}}</a>
                    </td>
                    <td>{{file_res.filetype}}</td>
                    <td>{{file_res.type}}</td>
                    <td>{{file_res?.created_at?.date | date: 'yyyy-MM-dd'}}  {{file_res?.created_at?.date | date:"HH:mm"}}</td>
                    <td>{{file_res.fullname}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
        </div>

            <p>
                <button mat-raised-button color="accent"  [disabled]="show" (click)="submitProject()">Submit Project</button>
              </p>
        </div>
        </form>
      </div>
    </div>
  </div>