<div class="white-box mt-12">
  <div class="tHeader">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxFlex>
        <h2>CRM Email Logs</h2>
      </div>
    </div>
  </div>
  <div class="boxBody">
    <div class="boxInn" >
      <table mat-table [dataSource]="dataSource" class="table" *ngIf="allData.length > 0">      
        <ng-container matColumnDef="subject">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="70"> Subject</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="70">
            <p class="text-truncate" (click)="openDialogEmail(product?.link_value)" style="cursor: pointer;">
              <a>{{product?.link_value?.name?.value}}</a>
            </p>
          </mat-cell> 
        </ng-container>
             
        <ng-container matColumnDef="sent_on">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16">Sent on</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
            <p class="text-truncate">{{product?.link_value?.date_sent?.value  | date:'yyyy-MM-dd' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16">Status</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
            <p class="text-truncate">{{product?.link_value?.status?.value }}</p>
          </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
        <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList"></mat-row>
      </table>
      <div *ngIf="allData.length == 0" class="noDataFound"> CRM email not found!!
      </div>
    </div>
  </div>
</div>