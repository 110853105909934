import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators } from '@angular/forms';
@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  type: string = 'Add';
  company_id: number;
  customer_id: number = null;
  sourceResponse: any = [];
  Form: any;
  ip_address: any;
  browser: any;
  operating_system: any;
  id: string;
  search_response: any = [];
  singleCustomer: any;
  customeraddress_id: number = null;
  showContact = false;
  Topsearch: any = {
    data: ''
  }
  account_id_value: any = null;
  filterSearchQuery: string = '';
  constructor(private projectsService: ProjectsService, private route: ActivatedRoute,
    private toastr: ToastrService, private fb: FormBuilder, private router: Router) { }
  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getProjectSingleCustomer();
      this.getLeadSource();
      this.getIpAddress();
      this.Form = this.fb.group({
        contract_type: ['Individual'],
        source_id: [''],
        payment_method: ['', Validators.compose([Validators.required])],
        title: [''],
        first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        email_primary: ['', [Validators.required, Validators.email]],
        company: ['', [Validators.required]],
        company_website: [''],
        ip_address: [''],
        browser: [''],
        os: [''],
        note: [''],
        address_type: ['1'],
        address1: [''],
        city: [''],
        country: [''],
        state: ['' ],
        zip_code: [''],
        phone: ['', Validators.compose([Validators.required])],
        account_id: [''],
        news_letter:[''],
        day_vip:['']
      });
    } else {

      this.getLeadSource();
      this.getIpAddress();
      this.Form = this.fb.group({
        contract_type: ['Individual'],
        source_id: [''],
        payment_method: ['', Validators.compose([Validators.required])],
        title: [''],
        first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        email_primary: ['', [Validators.required, Validators.email]],
        company: ['', [Validators.required]],
        company_website: [''],
        ip_address: [''],
        browser: [''],
        os: [''],
        note: [''],
        address_type: ['1'],
        address1: [''],
        city: [''],
        country: [''],
        state: [''],
        zip_code: [''],
        phone: ['', Validators.compose([Validators.required])],
        account_id: [''],
        news_letter:[''],
        day_vip:['']
      });

    }

  }

  getLeadSource() {
    this.projectsService.getLeadSource()
      .subscribe((res: any) => {
        if (res) {
          this.sourceResponse = res.data;
        }
      });
  }

  getIpAddress() {
    this.projectsService.getIpAddress()
      .subscribe((res: any) => {
        if (res) {
          this.ip_address = res.ip_address;
          this.browser = res.name;
          this.operating_system = res.platform;
        }
      });
  }

  Submit() {
    if (this.type == 'Add') {
      this.addData();
    } else {
      this.updateData();
    }
  }

  addData() {
    this.Form.value.ip_address = this.ip_address;
    this.Form.value.browser = this.browser;
    this.Form.value.os = this.operating_system;
    let obj =
    {
      contract_type: this.Form.value.contract_type,
      source_id: this.Form.value.source_id,
      account_id: this.account_id_value,
      payment_method: this.Form.value.payment_method,
      title: this.Form.value.title,
      first_name: this.Form.value.first_name,
      last_name: this.Form.value.last_name,
      phone: this.Form.value.phone,
      email_primary: this.Form.value.email_primary,
      company: {
        name: this.Form.value.company,
        website: this.Form.value.company_website
      },
      // company: this.Form.value.company,
      ip_address: this.Form.value.ip_address,
      browser: this.Form.value.browser,
      os: this.Form.value.os,
      note: this.Form.value.note,
      news_letter:this.Form.value.news_letter,
      day_vip:this.Form.value.day_vip,
      customer_address: {
        address_type: this.Form.value.address_type,
        address1: this.Form.value.address1,
        city: this.Form.value.city,
        country: this.Form.value.country,
        zip_code: this.Form.value.zip_code,
        state: this.Form.value.state
      }
    }

    this.projectsService.addCustomer(obj).subscribe(
      resposne => {
        if (resposne) {
          this.toastr.success('Add Customer Successfully!!');
          this.router.navigate(['/pms/customer']);
         
        }
      }
    );
  }

  updateData() {
    this.Form.value.ip_address = this.ip_address;
    this.Form.value.browser = this.browser;
    this.Form.value.os = this.operating_system;
    let obj: any =
    {
      id: this.singleCustomer.id,
      uuid: this.singleCustomer.uuid,
      reference_id: this.singleCustomer.reference_id,
      contract_type: this.Form.value.contract_type,
      source_id: this.Form.value.source_id,
      payment_method: this.Form.value.payment_method,
      title: this.Form.value.title,
      account_id: this.account_id_value,
      first_name: this.Form.value.first_name,
      last_name: this.Form.value.last_name,
      phone: this.Form.value.phone,
      email_primary: this.Form.value.email_primary,
      account_type: this.Form.value.account_type,
      note: this.Form.value.note,
      company_id: this.company_id,
      // company: this.Form.value.company,
      company: {
        id: this.company_id,
        name: this.Form.value.company,
        website: this.Form.value.company_website
      },
      ip_address: this.Form.value.ip_address,
      browser: this.Form.value.browser,
      os: this.Form.value.os,
      news_letter:this.Form.value.news_letter,
      day_vip:this.Form.value.day_vip,

      customeraddress: {
        id: this.customeraddress_id,
        customer_id: this.singleCustomer.id,
        address_type: this.Form.value.address_type,
        address1: this.Form.value.address1,
        address2: null,
        city: this.Form.value.city,
        state: this.Form.value.state,
        country: this.Form.value.country,
        zip_code: this.Form.value.zip_code,
        suite: null
      }
    }
    this.projectsService.updateCustomer(this.id, obj).subscribe(
      resposne => {
        if (resposne) {

          this.toastr.success('Customer updated successfully');
           this.router.navigate(['/pms/customer/details/'+this.id]);
          
        }
      }
    );

  }

  goToAccountDetail(){
 
    if(this.account_id_value!=null){
      this.router.navigate(['/pms/account/details/'+this.account_id_value]);
    }else{
      this.toastr.error('please select any account first!!');
      return ;
    }
    

  }

  Cancel() {
    this.router.navigate(['/pms/customer']);
  }

  getProjectSingleCustomer() {
    this.projectsService.getProjectSingleCustomer(this.id)
      .subscribe((response) => {
        if (response) {
          this.type = 'Update';
          this.singleCustomer = response.data;
          if (response.data.company) {
            this.company_id = response.data.company.data.id;
          }

          // this.response=response.data;
          if (response.data.customeraddress) {
            if(response.data.customeraddress.data.length>0){
              this.customeraddress_id = response.data.customeraddress.data[0].id;
              this.customer_id = response.data.customeraddress.data[0].customer_id;
            }


            if (response.data.company) {
              this.Form.patchValue({
                company: response.data.company.data.name,
                company_website: response.data.company.data.website
              });
            } else {
              this.Form.patchValue({
                company: '',
                company_website: ''
              });
            }
            this.Form.patchValue({
              contract_type: response.data.contract_type,
              source_id: response.data.source_id,
              payment_method: response.data.payment_method,
              title: response.data.title,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              email_primary: response.data.email_primary,
              ip_address: response.data.ip_address,
              browser: response.data.browser,
              os: response.data.os,
              note: response.data.note,
              phone: response.data.phone,
              day_vip:response.data.day_vip,
              news_letter:response.data.news_letter,


            });

            if(response.data.customeraddress.data.length>0){
              this.Form.patchValue({
                address_type: response.data.customeraddress.data[0].address_type,
                address1: response.data.customeraddress.data[0].address1,
                city: response.data.customeraddress.data[0].city,
                country: response.data.customeraddress.data[0].country,
                zip_code: response.data.customeraddress.data[0].zip_code,
                state: response.data.customeraddress.data[0].state
              });
            }
            if (response.data.account_id != null) {
              this.account_id_value = response.data.account_id;
              if (response.data.accountdetail != null) {
                this.Form.patchValue({ account_id: response.data.accountdetail.data.name });
              }
              
              
            }
          } else {

            this.Form.patchValue({
              contract_type: response.data.contract_type,
              source_id: response.data.source_id,
              payment_method: response.data.payment_method,
              title: response.data.title,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              email_primary: response.data.email_primary,
              ip_address: response.data.ip_address,
              browser: response.data.browser,
              os: response.data.os,
              note: response.data.note,
              phone: response.data.phone

            });

            if (response.data.account_id != null) {
              this.account_id_value = response.data.account_id;
              if (response.data.accountdetail != null) {
                this.Form.patchValue({ account_id: response.data.accountdetail.data.name });
              }
              
            }
          }


        }

      }
      );

  }

  onFilter(evt, type) {
    if (evt == '') {
      this.Form.account_id = '';
      this.search_response = [];
      return;
    }
    if (evt.length > 2) {
      let fQuery = '';
      // project search filter
      if ((type == 'search' && evt != '' && evt !== undefined)) {
        this.filterSearchQuery = evt;
        fQuery += '?search_text=' + this.filterSearchQuery;
      }
      else if (type == 'search' && evt == '') {
        this.filterSearchQuery = evt;
      }
      else if (this.filterSearchQuery !== '') {
        fQuery += '?search_text=' + this.filterSearchQuery;
      }
      let query = fQuery;
      this.getAccountSearch(query);
    } else {
      return;
    }
  }

  getAccountSearch(query: any) {
    this.projectsService.getAccountSearch(query)
      .subscribe((response: any) => {
        this.search_response = [];
        if (response.data.length == 0) {
          this.toastr.error('No data available!!');
          return;
        } else {
          this.search_response = response.data;
        }
      });

  }

  onSelectSearch(res: any) {
    this.Form.patchValue({
      account_id: res.option.value.name
    })
    this.account_id_value = res.option.value.id;
  }

  customerType(evt: any) {
    if(evt.value=='1'){
      this.showContact = true ;
    }else{
      this.showContact = false ;
    }

  }
}
