import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsComponent } from './transactions/transactions.component';
import { VaultComponent } from './vault/vault.component';
import { CustomerComponent } from './customer/customer.component';
import { MatCheckboxModule, MatIconModule, MatTableModule, MatProgressBarModule,
  MatButtonModule, MatRadioModule, MatSliderModule, MatBadgeModule, MatFormFieldModule, MatInputModule,
  MatStepperModule, MatSelectModule,MatPaginatorModule,MatTooltipModule, MatMenuModule,MatDatepickerModule, MatTabsModule, MatExpansionModule, MatChipsModule } from '@angular/material';
import { MatToolbarModule } from "@angular/material/toolbar";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from 'app/fuse-config';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { ChartsModule } from 'ng2-charts';
import { CdkStepperModule } from '@angular/cdk/stepper';

import {DragDropModule} from '@angular/cdk/drag-drop';

import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {A11yModule} from '@angular/cdk/a11y';

import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { SearchTransactionComponent } from './transactions/search-transaction/search-transaction.component';
import { CreateTransactionComponent } from './transactions/create-transaction/create-transaction.component';
import { MatCardModule } from '@angular/material';
import { AccountComponent } from './account/account.component';
import { AccountDetailsComponent } from './account/account-details/account-details.component';
import { UpdateAccountComponent } from './account/account-details/update-account/update-account.component';
import { AddAccountComponent } from './account/add-account/add-account.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'environments/environment';
import { StripeTransactionsComponent } from './stripe-transactions/stripe-transactions.component';
import { SearchStripeTransactionComponent } from './stripe-transactions/search-stripe-transaction/search-stripe-transaction.component';
import { CreateStripeTransactionComponent } from './stripe-transactions/create-stripe-transaction/create-stripe-transaction.component';

const routes = [
  {
      path     : 'pms/transactions',
      component: TransactionsComponent
  },
  {
      path     : 'pms/stripetransactions',
      component: StripeTransactionsComponent
  },
  {
    path     : 'pms/transactions/search/:id',
    component: SearchTransactionComponent
},
{
  path     : 'pms/transactions/search',
  component: SearchTransactionComponent
},
  {
    path     : 'pms/stripetransactions/search/:id',
    component: SearchStripeTransactionComponent
},
{
  path     : 'pms/stripetransactions/search',
  component: SearchStripeTransactionComponent
},
{
  path     : 'pms/transactions/create',
  component: CreateTransactionComponent
},
{
  path     : 'pms/transactions/create/:id',
  component: CreateTransactionComponent
},
{
  path     : 'pms/stripetransactions/create',
  component: CreateStripeTransactionComponent
},
{
  path     : 'pms/stripetransactions/create/:id',
  component: CreateStripeTransactionComponent
},
{
    path     : 'pms/vault',
    component: VaultComponent
},
{
  path     : 'pms/customer',
  component: CustomerComponent
},
{
  path     : 'pms/customer/details/:id',
  component: CustomerDetailsComponent
},
{
  path     : 'pms/customer/addcustomer',
  component: AddCustomerComponent
},
{
  path     : 'pms/customer/editCustomer/:id',
  component: AddCustomerComponent
},
{
  path     : 'pms/account',
  component: AccountComponent
},
{
  path     : 'pms/account/details/:id',
  component: AccountDetailsComponent
},
{
  path     : 'pms/account/add-account',
  component: AddAccountComponent
}
];

@NgModule({
  declarations: [TransactionsComponent, VaultComponent, CustomerComponent, 
    CustomerDetailsComponent, AddCustomerComponent, SearchTransactionComponent,
     CreateTransactionComponent, AccountComponent, AccountDetailsComponent, UpdateAccountComponent,
      AddAccountComponent,
      StripeTransactionsComponent,
      SearchStripeTransactionComponent,
      CreateStripeTransactionComponent],
  imports: [
    NgxStripeModule.forRoot(environment.PublishKey),
    CommonModule, BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    TranslateModule.forRoot(),
    ScrollingModule,CdkTableModule,A11yModule,CdkTreeModule,
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    MatTableModule,        // App modules
    LayoutModule,
    MatProgressBarModule,
    MatCardModule,
    MatRadioModule,
    MatSliderModule, RouterModule.forChild(routes),
    MatBadgeModule,MatCheckboxModule,FormsModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule,
    ChartsModule,MatTabsModule,CdkStepperModule,MatStepperModule,MatSelectModule,
    MatExpansionModule,DragDropModule,MatMenuModule,MatChipsModule
  ],
  entryComponents: [
    CreateTransactionComponent,
    CreateStripeTransactionComponent,
    UpdateAccountComponent
],
})

export class CustomerModule { }
