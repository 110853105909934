<div class="mopt p-20"  *ngIf="!apostile_show ">
  <p>Not Applied</p>
  </div>
  <div class="mopt p-20" *ngIf="apostile_show && additonal_service_array.length>0">
      
    <p >
      Notarization of COA in CA or IL: <b *ngIf="notarization_quantity>0">{{notarization_quantity}} Copies</b>
      <b *ngIf="notarization_quantity==0">NA</b>
    </p>

    <p *ngIf="notarization_other_state"> Notarization in any other state: <b>{{notarization_other_state}}</b> </p>
    <p *ngIf="notarization_other_state==''"> Notarization in any other state: <b>NA</b> </p>

    <p> Additional Notarization: <b *ngIf="notarization_additional_quantity>0">{{notarization_additional_quantity}} Copies</b>
      <b *ngIf="notarization_additional_quantity==0">NA</b> </p>

    <p> Notarization Affidavit for court: <b>{{notarization_affidavit_court}}</b> </p>

    <div fxLayoutAlign="space-between end" >
      <div></div>
      <div>
        <button mat-raised-button color="primary" (click)="Edit()"  class="mr-8">Edit</button>
      </div>
     </div>
    </div>