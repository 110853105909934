import { OnInit, ElementRef } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SendWhatsappMessageComponent } from './popup/send-whatsapp-message/send-whatsapp-message.component';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';
import { DataService } from 'app/main/Services/data.service';
import { ActivatedRoute } from '@angular/router';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ToastrService } from 'ngx-toastr';
var WhatsappComponent = /** @class */ (function () {
    function WhatsappComponent(projectsService, toastr, dialog, route, formBuilder, data) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.dialog = dialog;
        this.route = route;
        this.formBuilder = formBuilder;
        this.data = data;
        this.heroes = [];
        this.searchText = '';
        this.BaseNumber = '';
        this.hasUserMessage = true;
        this.BaseNumber = environment.BaseNumber;
    }
    WhatsappComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.currentMessage.subscribe(function (message) { return _this.message = message; });
        this.id = this.route.snapshot.params['id'];
        if (this.id) {
            this.fromNumber = this.id;
        }
        this.getWhatsapps();
        this.heroForm = this.formBuilder.group({
            body: this.formBuilder.control('', [Validators.required]),
        });
        this.setIntervalId = setInterval(function () {
            _this.pageRefresh();
        }, 20000);
    };
    WhatsappComponent.prototype.newMessage = function () {
        this.data.changeMessage("whatsapp");
    };
    WhatsappComponent.prototype.getWhatsapps = function () {
        var _this = this;
        this.projectsService.getWhatsapps()
            .subscribe(function (heroes) {
            _this.heroes = [];
            heroes.data.forEach(function (element) {
                _this.heroes.push(element);
            });
            if (_this.fromNumber === undefined) {
                _this.getHero(_this.heroes[0].recipient);
            }
            else {
                _this.getHero(_this.fromNumber);
            }
        });
    };
    WhatsappComponent.prototype.getHero = function (id) {
        var _this = this;
        this.fromNumber = id;
        this.hasUserMessage = true;
        this.projectsService.getWhatsappHero(id)
            .subscribe(function (response) {
            var hero = response.wtsp_conversations;
            var herolength = Object.keys(hero).length;
            if (hero.findIndex(function (data) { return !data.sent; }) === -1) {
                _this.hasUserMessage = false;
            }
            for (var i = 0; i < herolength; ++i) {
                if (hero[i]['attachment'] != null) {
                    var attachvar = hero[i]['attachment'];
                    var obj = JSON.parse(attachvar);
                    hero[i]['attachment'] = obj;
                }
            }
            _this.hero = hero;
            var scrollerHeight = _this.myScrollContainer.nativeElement.offsetHeight;
            _this.chatPS.directiveRef.scrollToTop(scrollerHeight * 100, 100);
        });
    };
    WhatsappComponent.prototype.sendWhatsappto = function () {
        var _this = this;
        if (this.heroForm.value.body.length > 250) {
            this.toastr.error('Maximum 250 characters');
            return false;
        }
        if (this.heroForm.valid) {
            var obj = {
                recipient: this.fromNumber,
                body: this.heroForm.value.body
            };
            this.projectsService.sendWhatsappto(obj)
                .subscribe(function (response) {
                _this.heroForm.reset({});
                _this.getWhatsapps();
            });
        }
    };
    // popup
    WhatsappComponent.prototype.sendMessagePopup = function () {
        var _this = this;
        var dailogRef = this.dialog.open(SendWhatsappMessageComponent, {
            width: '500px'
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getWhatsapps();
            }
        });
    };
    WhatsappComponent.prototype.pageRefresh = function () {
        this.getWhatsapps();
        this.newMessage();
    };
    WhatsappComponent.prototype.markAsRead = function () {
        var _this = this;
        var obj = {
            recipient: this.fromNumber
        };
        this.projectsService.markAsReadWhatsapp(obj)
            .subscribe(function (response) {
            _this.getWhatsapps();
            _this.newMessage();
        });
    };
    return WhatsappComponent;
}());
export { WhatsappComponent };
