import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { UserService } from 'app/main/Services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { SharedService } from 'app/main/shared.service';
var LocationMasterComponent = /** @class */ (function () {
    function LocationMasterComponent(userService, projectsService, _formBuilder, toastr) {
        this.userService = userService;
        this.projectsService = projectsService;
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.type = 'Add';
        this.paginate = {};
        this.pageIndex = 1;
        this.pageSize = 10;
        this.locationData = [];
        this.uploaded_files_array = [];
        this.file_multiple_data = [];
        this.fileError = '';
        this.query = '';
        this.final_data = {};
        this.updated_data = {};
        this.displayedColumns = ['id', 'title', 'city', 'state', 'country', 'complete_address', 'phone', 'action'];
    }
    LocationMasterComponent.prototype.ngOnInit = function () {
        this.addlocationForm = this._formBuilder.group({
            title: ['', Validators.compose([Validators.required])],
            complete_address: ['', Validators.compose([Validators.required])],
            city: ['', Validators.compose([Validators.required])],
            state: ['', Validators.compose([Validators.required])],
            zip: ['', Validators.compose([Validators.required])],
            country: ['', Validators.compose([Validators.required])],
            address_marks: [''],
            phone: ['', Validators.compose([Validators.required])],
            phone_second: [''],
            phone_client_use: [''],
            fax: [''],
            hours_of_operation: [''],
            hours_of_operation_marks: [''],
            center_email: ['', Validators.compose([Validators.required, Validators.email])],
            representatives: [''],
            notary: [''],
            status: ['0'],
            coa_kits: [''],
            notes: [''],
            file_id: [''],
        });
        this.getLocation();
    };
    LocationMasterComponent.prototype.submit = function () {
        if (this.type == 'Add') {
            this.addData();
        }
        else {
            this.updateData();
        }
    };
    LocationMasterComponent.prototype.addData = function () {
        var _this = this;
        if (this.file_id_response != null) {
            this.addlocationForm.value.file_id = this.file_id_response;
        }
        this.final_data = this.addlocationForm.value;
        this.final_data.is_active = 1;
        this.userService.addLocation(this.final_data).subscribe(function (resposne) {
            if (resposne) {
                _this.uploaded_files_array = [];
                _this.fileSuccess = '';
                _this.fileError = '';
                _this.addlocationForm.reset();
                _this.getLocation();
                _this.toastr.success('Location added successfully');
            }
        });
    };
    LocationMasterComponent.prototype.updateData = function () {
        var _this = this;
        this.updated_data = this.addlocationForm.value;
        this.userService.updateMasterLocation(this.locationId, this.updated_data).subscribe(function (resposne) {
            if (resposne) {
                _this.uploaded_files_array = [];
                _this.fileError = '';
                _this.fileSuccess = '';
                _this.addlocationForm.reset();
                _this.toastr.success('Location updated successfully');
                _this.type = 'Add';
                _this.getLocation();
            }
        });
    };
    LocationMasterComponent.prototype.getLocation = function () {
        var _this = this;
        var query;
        var location_pagination = localStorage.getItem('pagination');
        var locationObject = JSON.parse(location_pagination);
        if (location_pagination !== null && locationObject.table_name == 'location') {
            query = '?page=' + locationObject.page + '&per_page=' + locationObject.per_page;
        }
        else {
            query = '?page=1&per_page=10';
        }
        this.userService.getLocation(query)
            .subscribe(function (res) {
            if (res) {
                _this.locationData = res.data;
                _this.dataSource = new MatTableDataSource(_this.locationData);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    LocationMasterComponent.prototype.paginateData = function (event) {
        var _this = this;
        var aQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        var pagination = { table_name: 'location', page: this.pageIndex, per_page: this.pageSize };
        SharedService.set(SharedService.pagination, JSON.stringify(pagination));
        this.query += aQuery;
        this.userService.getLocation(this.query)
            .subscribe(function (res) {
            if (res) {
                _this.locationData = res.data;
                _this.dataSource = new MatTableDataSource(_this.locationData);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    LocationMasterComponent.prototype.editData = function (index) {
        var _this = this;
        this.type = 'Update';
        this.locationData.forEach(function (element, ind) {
            if (index == ind) {
                _this.locationId = element.id;
                _this.addlocationForm.patchValue({
                    title: element.title,
                    complete_address: element.complete_address,
                    city: element.city,
                    state: element.state,
                    zip: element.zip,
                    country: element.country,
                    address_marks: element.address_marks,
                    phone: element.phone,
                    phone_second: element.phone_second,
                    phone_client_use: element.phone_client_use,
                    fax: element.fax,
                    hours_of_operation: element.hours_of_operation,
                    hours_of_operation_marks: element.hours_of_operation_marks,
                    center_email: element.center_email,
                    representatives: element.representatives,
                    notary: element.notary,
                    status: element.status,
                    coa_kits: element.coa_kits,
                    notes: element.notes,
                    file_id: element.file_id
                });
            }
        });
        var top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }
    };
    LocationMasterComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    LocationMasterComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.uploaded_files_array = [];
        this.fileSuccess = '';
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.projectsService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files_array.push(response.data);
            response.data.forEach(function (element) {
                _this.file_id_response = element.id;
            });
            _this.fileSuccess = 'Uploaded successfully!!';
        });
    };
    return LocationMasterComponent;
}());
export { LocationMasterComponent };
