import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { SharedService } from 'app/main/shared.service';

@Component({
  selector: 'app-add-file-management',
  templateUrl: './add-file-management.component.html',
  styleUrls: ['./add-file-management.component.scss']
})
export class AddFileManagementComponent implements OnInit {
  mainprogressbar: boolean = false;
  color: 'Primary - red';
  target_language_select: any = [];
  File_Type_data: any = [];
  project_id: string;
  form: FormGroup;
  ProjectLanguage_data: any = [];
  fileError: string = '';
  file_multiple_data: any = [];
  file_id_array: any = [];
  uploaded_files: any = [];
  uploaded_files_array: any = [];
  fileSuccess: string;
  constructor(private _formBuilder: FormBuilder, private vendorService: VendorService,
    private toastr: ToastrService, private projectsService: ProjectsService,
    public snackBar: MatSnackBar, private sharedService: SharedService,

    public dialogRef: MatDialogRef<AddFileManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  Onclose() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getFileType();
    this.getProjectLanguage();
    this.form = this._formBuilder.group({
      file_type_id: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])]
    });

  }


  // Get File Type

  getFileType() {
    this.projectsService.getFileType()
      .subscribe((response) => {
        if (response) {

          this.File_Type_data = response.data;
        }
      });
  }


  // Get Project Language

  getProjectLanguage() {

    this.projectsService.getProjectLanguage(this.data.project_id)
      .subscribe((response) => {
        if (response) {

          this.ProjectLanguage_data = response.data;

          let target_language_obj: any = {
            id: Number,
            lang_name: String
          };

          this.ProjectLanguage_data.forEach(element => {

            target_language_obj.id = element.targetlanguage.id;
            target_language_obj.lang_name = element.targetlanguage.lang_name;

            this.target_language_select.push(target_language_obj);

            target_language_obj = {
              id: '',
              lang_name: ''
            };

          });



        }
      });
  }

  // start multiple file upload

  onFileUpload() {
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = "File size should not be more than 20MB.";
            return;
          }
          this.file_multiple_data.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addDocument(this.file_multiple_data);
    };

    fileUpload.click();
  }

  // addDocument
  addDocument(file) {

    this.fileSuccess = '';
    this.mainprogressbar = true;
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append("file[]", file[x]);
    }
    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploaded_files = response.data;
        this.uploaded_files_array.push(this.uploaded_files);
        response.data.forEach(element => {
          this.file_id_array.push(element.id);
        });
        this.fileSuccess = 'Uploaded successfully!!';
        this.mainprogressbar = false;
      });
  }
  // end multiple file upload

  // Submit

  submit() {

    let filedata = this.form.value;
    filedata.file_id = this.file_id_array;
    filedata.project_id = this.data.project_id;

    this.projectsService.projectFileSave(filedata)
      .subscribe((response) => {
        this.toastr.success("Data added Successfully");

        this.dialogRef.close(true);

      });
  }

}
