import { HrDashboardComponent } from "./hr-dashboard/hr-dashboard.component";
import { TranslatorComponent } from "./translator/translator.component";
import { AddTranslatorComponent } from "./translator/add-translator/add-translator.component";
import { TranslatorOrientationComponent } from "./translator/translator-orientation/translator-orientation.component";
import { TranslatorQuizComponent } from "./translator/translator-quiz/translator-quiz.component";
import { HrGuard } from "app/_guards/hr.guard";
import { ReviewTranslatorComponent } from "./translator/review-translator/review-translator.component";
import { SharedListingComponent } from "./hr-dashboard/shared-listing/shared-listing.component";
import { ImportedTranslatorsComponent } from "./imported-translators/imported-translators.component";
import { SubtitlerComponent } from "./subtitler/subtitler.component";
import { ClosedBlacklistedComponent } from "./closed-blacklisted/closed-blacklisted.component";
import { TrepingComponent } from './treping/treping.component';
import { AITranscriptionComponent } from "./ai-transcription/ai-transcription.component";
var routes = [
    {
        path: "hr/hr-dashboard-shared-listing",
        component: SharedListingComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/hr-dashboard",
        component: HrDashboardComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator",
        component: TranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/review/:id",
        component: ReviewTranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/orientation",
        component: TranslatorOrientationComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/add-translator",
        component: AddTranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/:id",
        component: AddTranslatorComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/translator/quiz",
        component: TranslatorQuizComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/imported-translator",
        component: ImportedTranslatorsComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/subtitler",
        component: SubtitlerComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/ai-transcription",
        component: AITranscriptionComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/terping",
        component: TrepingComponent,
        canActivate: [HrGuard],
    },
    {
        path: "hr/closed-blacklisted",
        component: ClosedBlacklistedComponent,
        canActivate: [HrGuard],
    },
];
var HrModule = /** @class */ (function () {
    function HrModule() {
    }
    return HrModule;
}());
export { HrModule };
