import { endOfDay } from 'date-fns';
var CalendarEventModel = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param data
     */
    function CalendarEventModel(data) {
        data = data || {};
        this.start = new Date('2020-01-27 00:00:00');
        this.end = new Date(data.end) || endOfDay(new Date());
        this.title = data.title || '';
        this.color = {
            primary: data.color && data.color.primary || '#1e90ff',
            secondary: data.color && data.color.secondary || '#D1E8FF'
        };
        this.draggable = data.draggable || true;
        this.resizable = {
            beforeStart: data.resizable && data.resizable.beforeStart || true,
            afterEnd: data.resizable && data.resizable.afterEnd || true
        };
        this.actions = data.actions || [];
        this.allDay = data.allDay || false;
        this.cssClass = data.cssClass || '';
        this.meta = {
            location: data.meta && data.meta.location || '',
            notes: data.meta && data.meta.notes || ''
        };
    }
    return CalendarEventModel;
}());
export { CalendarEventModel };
