<div class="white-box">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Mailing Options" >
          <ng-template mat-tab-label>
              Mailing Options <span class="aText">{{getValueMailing}}</span>
              </ng-template>
        <app-additional-services-mailing-options (myoutputMailing)="getDataMailing($event)"></app-additional-services-mailing-options>
      </mat-tab>
          <mat-tab label="Notarization" >
              <ng-template mat-tab-label>
                  Notarization <span class="aText">{{getValueNotarization}}</span>
                  </ng-template>
            <app-additional-services-notarization (myoutputNotarization)="getDataNotarization($event)"></app-additional-services-notarization>
          </mat-tab>
     
    
      <mat-tab label=" Apostile"  >
          <ng-template mat-tab-label>
               Apostile <span class="aText">{{getValueApostile}}</span>
              </ng-template>
        <app-additional-services-apostile (myoutputApostile)="getDataApostile($event)"></app-additional-services-apostile>
      </mat-tab>
    </mat-tab-group>
  </div>