<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
  <form fxLayout="column" [formGroup]="Form" name="Form">
    <div class="white-box">
      <div class="tHeader">
        <h2>Add Lead</h2>
      </div>
      <div class="boxBodyInn">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-form-field   fxFlex="20">
            <mat-label>Lead Source*</mat-label>
            <mat-select  formControlName="source_id" >
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let res of projectLeadsource" [value]="res.id">
                {{res.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('source_id').hasError('required')">
              Lead Source is required!
            </mat-error>
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>Request Type*</mat-label>
            <mat-select formControlName="request_type">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option label="New" [value]="1">New</mat-option>
              <mat-option label="Sample" [value]="2">Sample</mat-option>
              <mat-option label="Free" [value]="3">Free</mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('request_type').hasError('required')">
              Request Type is required!
            </mat-error>
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>Customer Type*</mat-label>
            <mat-select  [(ngModel)]="data.customerType" [ngModelOptions]="{standalone: true}" (selectionChange)="CustomerType($event)">
              <mat-option value="New">New</mat-option>
              <mat-option value="Existing">Existing</mat-option>
            </mat-select>
          </mat-form-field>
    
          <!-- test mat-autocomplete start -->
          <mat-form-field class="example-full-width"   >
            <input fxflex="" matInput (keyup)="onFilter($event.target.value,'search')"
            [(ngModel)]="data.selectCustomer" placeholder="Search customer" [ngModelOptions]="{standalone: true}"
            class="ng-pristine ng-valid ng-touched" [matAutocomplete]="auto" [disabled]="customerList">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchCustomer($event)">
            <mat-option *ngFor="let res of allCustomer" [value]="res.uuid">
              <ul class="list_items">
                <li>
                  {{res.first_name}} {{res.last_name}}
                </li>
                <li>
                  {{res.first_name}} {{res.email_primary}}
                </li>
            </ul>

            </mat-option>
        </mat-autocomplete>
          </mat-form-field>

          <mat-form-field  fxFlex="20">
            <mat-label>Payment Type*</mat-label>
            <mat-select formControlName="payment_method">
              <mat-option [value]="''"> Select</mat-option>
              <mat-option [value]="1">NET30</mat-option>
              <mat-option [value]="2">NET45</mat-option>
              <mat-option [value]="3">Upfront</mat-option>
              <mat-option [value]="4">Credit Available</mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('payment_method').hasError('required')">
              Payment Type is required!
            </mat-error>
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>Discount Type</mat-label>
            <mat-select placeholder="Select" (selectionChange)="discountType($event)">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let res of DiscountType" [value]="res.code">
                {{res.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>Project Discount Code</mat-label>
            <input matInput formControlName="promo_code" readonly>
          </mat-form-field>

      
            <mat-form-field  fxFlex="20">
              <mat-label>Website Source</mat-label>
              <mat-select formControlName="website_source">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option  value="dt">DT</mat-option>
                <mat-option  value="wi">WI</mat-option>
                <mat-option  value="yst">YST</mat-option>
                <mat-option  value="dtcn">DT-CN</mat-option>
                <mat-option  value="accountdt">Account-DT</mat-option>
                <mat-option  value="accountdtmanual">Account-DT-Manual</mat-option>
              </mat-select>
            
            </mat-form-field>
    

        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="mb-8">
            <mat-radio-group aria-label="Select an option " formControlName="certification_required">
              <mat-radio-button [value]="true">Certified</mat-radio-button>
              <mat-radio-button [value]="false">Non Certified</mat-radio-button>
            </mat-radio-group>
          </div>

      
  
        </div>
    
        <div >
          <h4>Contact Info:</h4>
          <div class="mb-8">
            <mat-radio-group aria-label="Select an option " formControlName="address_type">
              <mat-radio-button [value]="1">Individual</mat-radio-button>
              <mat-radio-button [value]="2">Corporate</mat-radio-button>
            </mat-radio-group>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field   fxFlex="15">
              <mat-label>Select Title</mat-label>
              <mat-select formControlName="title">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option label="Mr."  value="Mr.">Mr.</mat-option>
                <mat-option label="Ms."  value="Ms.">Ms.</mat-option>
                <mat-option label="Mrs." value="Mrs.">Mrs.</mat-option>
                <mat-option label="Mx."  value="Mx.">Mx.</mat-option>
                <mat-option label="Dr."  value="Dr.">Dr.</mat-option>
                <mat-option label="Prof." value="Prof.">Prof.</mat-option>
                <mat-option label="Sr."   value="Sr.">Sr.</mat-option>
                <mat-option label="Sra." value="Sra.">Sra.</mat-option>
              </mat-select>
            </mat-form-field>

      
            <mat-form-field >
              <mat-label>First Name*</mat-label>
              <input matInput formControlName="first_name"  [readonly]="existing_check">
              <mat-error *ngIf="Form.get('first_name').hasError('required')">
                First Name is required!
              </mat-error>
            </mat-form-field>
        

          
            <mat-form-field  >
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="last_name">
            </mat-form-field>
     
                <mat-form-field fxFlex="32">
                  <mat-label>Email Address*</mat-label>
                  <input matInput formControlName="email_address" [readonly]="existing_check">
                  <mat-error *ngIf="Form.get('email_address').hasError('required')">
                    Email Address is required!
                  </mat-error>
                  <mat-error *ngIf="Form.get('email_address').hasError('email')">
                    Please enter a valid email address
                  </mat-error>
                </mat-form-field>

          </div>


          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            <!-- <div class="w30">
              <mat-form-field  >
                <mat-label>Email Address*</mat-label>
                <input matInput formControlName="email_address">
                <mat-error *ngIf="Form.get('email_address').hasError('required')">
                  Email Address is required!
                </mat-error>
                <mat-error *ngIf="Form.get('email_address').hasError('email')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div> -->

            <div class="w30">
              <mat-form-field  >
                <mat-label>Company</mat-label>
                <input matInput formControlName="company_name">
              </mat-form-field>
            </div>

          

            <div class="w30">
              <mat-form-field  >
                <mat-label>Phone</mat-label>
                <input matInput formControlName="phone_number" type="number">
              </mat-form-field>
            </div>
          </div>


          <h4>Address</h4>
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div fxFlex="95">
              <mat-form-field  >
                <mat-label>Address</mat-label>
                <textarea matInput placeholder="Address" formControlName="address"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

            <div fxFlex="15">
                  <mat-form-field  >
                    <mat-label>State</mat-label>
                    <input matInput formControlName="state">
                  </mat-form-field>
              </div>

            <div >
              <mat-form-field  >
                <mat-label>City</mat-label>
                <input matInput formControlName="city">
              </mat-form-field>
            </div>

            <div >
              <mat-form-field  >
                <mat-label>Country</mat-label>
                <input matInput formControlName="country">
              </mat-form-field>
            </div>

            <div class="w30">
              <mat-form-field  >
                <mat-label>Zip</mat-label>
                <input matInput formControlName="zip_code" type="number">
              </mat-form-field>
            </div>

          </div>

          <div class="actWraper" *ngIf='existing_check'>
            <button mat-flat-button color="accent" class="mr-16" (click)="updateData()" >Update</button>
          </div>


        </div>
        <h4>Lead Information:</h4>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-form-field fxFlex="16">
            <mat-label>Service*</mat-label>
            <mat-select formControlName="service_type">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let service of serviceResponse" [value]="service.title">
                {{service.title}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('service_type').hasError('required')">
              Service is required!
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field   fxFlex="20" >
            <mat-label>Source Language*</mat-label>
            <mat-select multiple placeholder="Select Field" formControlName="source_language">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                {{language.lang_name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('source_language').hasError('required')">
              Source Language is required!
            </mat-error>
          </mat-form-field> -->
          <div class="item item-1" fxFlex="28">
            <p class="dLabel">Source Language*</p>
       
        <ng-multiselect-dropdown-angular7
           
            [data]="dropdownList"
            formControlName="source_language"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="onItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
        >
      </ng-multiselect-dropdown-angular7>
 
      </div>
       
      <div class="item item-1" fxFlex="28">
        <p class="dLabel">Target Language*</p>
        <ng-multiselect-dropdown-angular7
              [data]="dropdownList"
              formControlName="target_language"
              [settings]="dropdownSettings"
              (onSelect)="onItemSelect($event)"
              (onDeSelect)="onItemDeSelect($event)"
              (onSelectAll)="onSelectAll($event)"
              (onDeSelectAll)="onDeSelectAll($event)"
          >
        </ng-multiselect-dropdown-angular7>
        
      </div>
          <!-- <mat-form-field   fxFlex="20">
            <mat-label>Target Language*</mat-label>
            <mat-select multiple placeholder="Select Field" formControlName="target_language">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                {{language.lang_name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('target_language').hasError('required')">
              Target Language is required!
            </mat-error>
          </mat-form-field> -->


          <div class="item item-1" fxFlex="12">
            <p class="dLabel">Due Date*</p>
      
              <mat-form-field  >
                <input matInput [min]="today" (click)="dp1.open()" [matDatepicker]="dp1" formControlName="due_date">
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1></mat-datepicker>
                <mat-error *ngIf="Form.get('due_date').hasError('required')">
                  Due Date is required!
                </mat-error>
              </mat-form-field>
      
          </div>


          <mat-form-field   fxFlex="16">
            <mat-label>Speciality*</mat-label>
            <mat-select formControlName="specilization_id">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let speciality of specialityResponse" [value]="speciality.id">
                {{speciality.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('specilization_id').hasError('required')">
              Specilization is required!
            </mat-error>
          </mat-form-field>


          <div fxFlex="92">

            <div class="fileUploader p20">

              <button mat-raised-button (click)="onFileUpload()">Upload Files</button>
              <input type="file" id="docUpload" name="docUpload" style="display:none;" multiple />
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
                
                <div class="box-blank" *ngIf="uploaded_files_array.length>0">
                    <p *ngFor="let res of uploaded_files_array">
                      <span *ngFor="let res1 of res">
                        <p>{{res1.name}}</p>
                      </span>
      
                    </p>
                    <p style="color: green;">{{fileSuccess}}</p>
                  </div>
              </div>

            </div>
         

          </div>

          <div fxFlex="92">
            <mat-form-field  >
              <mat-label>Additional Notes/Project Description: </mat-label>
              <textarea matInput placeholder="Additional Notes/Project Description:" formControlName="note"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="actWraper">
        <button mat-flat-button color="accent" class="mr-16" (click)="submit()" [disabled]="Form.invalid">Save</button>
        <button mat-stroked-button color="warn" (click)="Cancel()">Cancel</button>
      </div>
    </div>
  </form>
</div>