import { WelcomeComponent } from './welcome.component';
var routes = [
    {
        path: 'welcome',
        component: WelcomeComponent // authguard 
    }
];
var WelcomeModule = /** @class */ (function () {
    function WelcomeModule() {
    }
    return WelcomeModule;
}());
export { WelcomeModule };
