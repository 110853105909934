import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { VendorService } from 'app/main/Services/vendor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EmailTemplateViewComponent } from '../email-template-view/email-template-view.component';
import { AddTempTranslatorComponent } from '../add-temp-translator/add-temp-translator.component';
import { DataService } from 'app/main/Services/data.service';

@Component({
  selector: 'app-project-qa',
  templateUrl: './project-qa.component.html',
  styleUrls: ['./project-qa.component.scss']
})
export class ProjectQaComponent implements OnInit {
  file_data: any = [];
  noData: boolean = false;
  project_file_data: string;
  project_id: string;
  BaseUrl_file: any;
  Project_Assigned_obj: any = [];
  Project_Assigned_obj_compare: any = [];
  project_type: number;
  opportunityAmount: any = '';
  language_pair_count: number;
  file_d: string;
  email_temp_id: any;
  DetailMainResponse: any = '';
  specialization_id: number;
  ProjectLanguage_data: any = [];
  target_language_select: any = [];
  source_language_value: string;
  source_language_id_value: number;
  vendor_ids: any = [];
  vendor_first_name: any;
  vendor: any = [];
  lang_pair: any = [];
  vendor_array: any = [];
  vendor_array_match: any = [];
  project_workflow_id: number = 3;
  // vendor_translator_number : any;
  lang1 = '';
  quote_approve: any = [];
  quoteTotal: number;
  target_language_value: string;
  languageData: any = [];
  dataSource_translator: any;
  dataSource2: any;
  source_lang_name: any;
  target_lang_name: any;
  show_search: boolean = false;
  workflow_check: boolean = false;
  show_table: boolean = false;
  show_translator: boolean = false;
  specialityData: any = [];
  response_email_data: any = [];
  response_workflow: any = [];
  assignment_data: any = {};
  languageDataArray: any = [];
  Form: FormGroup;
  workFlowForm: FormGroup;
  project_language_id: number;
  assignment_obj: any = {};
  projectWorkFlowResponse: any = [];
  paginate: any = {};
  pageSize: number = 10;
  pageIndex: number = 1;
  compare_target_lang: string;
  quantity_approve: number;
  quantity_rate: number;
  profit_margin: number;
  freelancer_cost_approve: number;
  Opp_Amount: number;
  arrType: any = [];
  workflow_data: any = [];
  ProjectAssignedStatus: any = [];
  vendor_name: string;
  show_assign_button: boolean = true;
  show_workflow_status: string;
  // assign_show:boolean=true;
  completed_workflow_array: any = [];
  file_id_data: any = [];
  data: any =
    {
      source_lang_id: '',
      project_language_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },

      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
    };

  data_approved: any =
    {
      source_lang_id: '',
      service_id: '',
      project_language_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },

      targetlanguage: {
        data: {
          lang_name: ''
        }
      },

      target_lang_id: '',
      quantity: '',
      pricing_method: '',
      rate: '',
      sub_total: ''
    };
  customer_duration: boolean = false;
  assignment_update: boolean = false;
  bulk_selected_progress: boolean = false;
  // filter
  translatorType: string;
  native_language: string;
  specialization: string;
  filterSearchQuery: string = "";
  searchTerm: string;
  filterSearchObj: any = {
    specilization_id: Number,
    source_language_id: Number,
    target_language_id: Number
  };
  // filter end
  language_resObj:any;
  today: Date = new Date();
  filterTypeQuery: string = "";
  filterNativeLanguageQuery: string = "";
  filterSpecializationQuery: string = "";
  fQuery: string = "";
  displayedColumns2: string[] = ['id', 'processType', 'languages', 'freelancer', 'plannedStartDate', 'plannedDeadline', 'status'];
  displayedColumns: string[] = ['position', 'preference', 'name', 'tztype', 'native_language','category_level', 'currentJobs', 'completedJobs', 'bestRates'];
  constructor(private dialog: MatDialog, private route: ActivatedRoute, private projectsService: ProjectsService, private httpService: HttpClient,
    private toastr: ToastrService,  private dataService: DataService, private vendorService: VendorService, private _formBuilder: FormBuilder, private router: Router, ) {
    this.BaseUrl_file = environment.baseUrl_file;
    this.translatorType = '';
    this.native_language = '';
    this.specialization = '';
    this.searchTerm = '';
  }


  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];

    this.httpService.get('/assets/TranslatorType.json').subscribe(
      data => {
        this.arrType = data as string[];	 // FILL THE ARRAY WITH DATA.

      },
      (err: HttpErrorResponse) => {
        this.toastr.warning(err.message);
      }
    );

    this.httpService.get('/assets/ProjectAssignedStatus.json').subscribe(
      data => {
        this.ProjectAssignedStatus = data as string[];	 // FILL THE ARRAY WITH DATA.

      },
      (err: HttpErrorResponse) => {
        this.toastr.warning(err.message);
      }
    );

    this.httpService.get('/assets/WorkFlowStatus.json').subscribe(
      data => {
        this.workflow_data = data as string[];	 // FILL THE ARRAY WITH DATA.

      },
      (err: HttpErrorResponse) => {
        this.toastr.warning(err.message);
      }
    );

    this.getLanguage();
    this.getSpeciality();
    this.getEmailTemplate();
    this.getWorkflow();
    this.getProjectDetail();
    this.getProjectAssignment_compare();
    this.getProjectLanguage();
    this.WorkFlowByProjectId();
    this.getProjectAssignment();


    this.Form = this._formBuilder.group({
      duration: ['30', Validators.compose([Validators.required])],
      duration_unit: ['1'],
      duration_comment: [''],
      email_template_id: ['', Validators.compose([Validators.required])],
      update_status_interval: ['240', Validators.compose([Validators.required])],
      update_status_note: [''],
      instruction: [''],
      vendor_id: [],
      due_date: ['', Validators.compose([Validators.required])],
    });

    this.workFlowForm = this._formBuilder.group({
      workflow_id: ['', Validators.compose([Validators.required])]
    });

  }



  getProjectDetail() {
    this.projectsService.getProjectDetail(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.project_type = response.data.service_id;
          this.specialization_id = response.data.specilization_id;
          this.opportunityAmount = response.data.project_amount;
          this.getDetailMain(response.data.crm_opp_id);
          this.DownloadZip();
          response.data.projectLanguage.data.forEach(element => {
            this.languageDataArray.push(element);
          });
          response.data.projectquote.data.forEach(res => {

            if (res.status == 4) {
              this.workflow_check = true;
              this.quoteTotal = res.subTotal;
              res.projectquoteservice.data.forEach(element => {

                this.data_approved = {
                  source_lang_id: '',
                  service_id: '',
                  project_language_id: '',
                  sourcelanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  targetlanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  target_lang_id: '',
                  quantity: '',
                  pricing_method: '',
                  rate: '',
                  sub_total: '',
                };

                this.data_approved.quantity = element.quantity;
                this.data_approved.pricing_method = element.pricing_method;

                this.data_approved.rate = element.rate;
                this.data_approved.sub_total = element.subTotal;
                element.projectlanguages.data.forEach(elem => {

                  this.data_approved.sourcelanguage.data.lang_name = elem.sourcelanguage.data.lang_name;
                  this.data_approved.targetlanguage.data.lang_name = elem.targetlanguage.data.lang_name;
                  if (res.status == 4) {
                    this.compare_target_lang = this.data.targetlanguage.data.lang_name;
                  }
                });

                this.quote_approve.push(this.data_approved);

              });
            }
          });
        }
      });
  }



  DownloadZip() {
    let data = {
      project_id: '',
      type: 1
    }
    data.project_id = this.project_id;
    data.type = this.project_type;

    this.projectsService.downloadZip(data).subscribe(
      resposne => {
        if (resposne) {
          this.file_d = resposne.url;
        }
      }
    );
  }

  // Get Project Language
  getProjectLanguage() {
    this.projectsService.getProjectLanguage(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.ProjectLanguage_data = response.data;
          this.language_pair_count = this.ProjectLanguage_data.length;
          let target_language_obj: any = {
            targetlanguage_id: Number,
            lang_name: String,
            project_lang_id: Number,
            sourcelanguage_id: Number,
            source_language_name: String
          };
          this.ProjectLanguage_data.forEach(element => {
            target_language_obj.targetlanguage_id = element.targetlanguage.id;
            target_language_obj.lang_name = element.targetlanguage.lang_name;
            target_language_obj.source_language_name = element.sourcelanguage.lang_name;
            target_language_obj.sourcelanguage_id = element.sourcelanguage.id;
            target_language_obj.project_lang_id = element.id;
            this.target_language_select.push(target_language_obj);
            target_language_obj = {
              targetlanguage_id: '',
              lang_name: '',
              project_lang_id: '',
              source_language_name: '',
              sourcelanguage_id: ''
            };

          });


          if (response.data) {

            response.data.forEach(element => {
              this.data.project_language_id = element.id;

              if (this.Project_Assigned_obj_compare) {
                this.Project_Assigned_obj_compare.forEach(ele => {
                  // if(element.id==ele.project_language_id && ele.status!='3' && ele.project_workflow.workflow_id==3){
                  //   this.vendor_array_match.push(ele);
                  // }

                  if (element.id == ele.project_language_id && ele.status == '5' && ele.project_workflow.workflow_id == 3) {
                    this.vendor_array_match.push(ele);
                  }
                  else if (element.id == ele.project_language_id && ele.status != '3' && ele.status != '7' && ele.project_workflow.workflow_id == 3) {
                    this.vendor_array_match.push(ele);
                  }
                });
              }


              // this.lang1 += element.targetlanguage.lang_name+ ', ' ;
              // this.source_language_value= element.sourcelanguage.lang_name;
              // this.source_lang_name=this.source_language_value;
              // this.source_language_id_value= element.source_language_id;
              // this.data.sourcelanguage.data.lang_name=this.source_language_value;
              // this.data.source_lang_id=this.source_language_id_value;
            });
            // this.target_language_value=this.lang1.slice(0, -2);
          }
        }
      });
  }


  // get Language
  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res) => {
        if (res) {
          this.languageData = res;

        }
      });
  }

  // select source Name
  getSourceName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.data.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  // selcect target language
  getTargetName(evt, res: any) {

    if (evt.value == "") {
      this.show_search = false;
      this.show_table = false;
      return
    }

    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.data.targetlanguage.data.lang_name = element.lang_name;
        this.target_lang_name = element.lang_name;
        this.show_search = true;
        this.show_table = false;
        this.vendor_ids = [];
      }
    });
    this.languageDataArray.forEach(elem => {
      if (elem.targetlanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
        this.data.project_language_id = elem.id;
        this.project_language_id = elem.id;
      }
    });

    this.quote_approve.forEach(elem => {
      if (elem.targetlanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
        let total;
        this.quantity_approve = parseFloat(elem.quantity);
        this.quantity_rate = parseFloat(elem.rate);
        total = this.quantity_approve * this.quantity_rate;
        this.profit_margin = elem.sub_total - this.quantity_rate;
        this.Opp_Amount = this.roundToTwo(total);
      }
    });

  }

  // Get Speciality 

  getSpeciality() {

    this.vendorService.getSpeciality()
      .subscribe((res) => {
        if (res) {
          this.specialityData = res;
        }
      });
  }

  // GetTranslator Languages

  getLanguageData(language_res: any) {
    this.language_resObj = language_res;
    this.project_language_id = language_res.project_lang_id;
    this.target_lang_name = language_res.lang_name;
    this.source_lang_name = language_res.source_language_name;
    this.quote_approve.forEach(elem => {
      if (elem.targetlanguage.data.lang_name == this.target_lang_name) {
        let total;
        this.quantity_approve = parseFloat(elem.quantity);
        this.quantity_rate = parseFloat(elem.rate);
        total = this.quantity_approve * this.quantity_rate;
        this.profit_margin = elem.sub_total - this.quantity_rate;
        this.Opp_Amount = this.roundToTwo(total);
      }
    });

    let obj: any = {
      specilization_id: Number,
      source_language_id: Number,
      target_language_id: Number
    };


    obj.specilization_id = this.specialization_id;
    obj.source_language_id = language_res.sourcelanguage_id;
    obj.target_language_id = language_res.targetlanguage_id;
    obj.skill_id = 4;
    this.filterSearchObj = obj;
    this.projectsService.ProjectTranslatorAssignment(obj, '')
      .subscribe((response) => {
        if (response) {
          this.translatorType = '';
          this.native_language = '';
          this.specialization = '';
          if (response.data.length > 0) {
            let show_available_translator = document.getElementById('show_available_translator');
            if (show_available_translator != null) {
              show_available_translator.scrollIntoView();
              show_available_translator = null;
            }
            // this.toastr.success("Data available!!");
            response.data.forEach(element => {
              element.preference = false;
            });
            this.dataSource_translator = new MatTableDataSource(response.data);
            this.vendor = [];
            this.vendor_ids = [];
            this.show_table = true;
            this.noData = false;
          }
          else {
            this.show_table = false;
            this.toastr.error("No record available");
            this.noData = true;
          }

        }
      });

  }


  // selectFinance(evt:any,val){

  //   if (evt.checked && this.vendor_ids.indexOf(val.id== -1)) {
  //     this.vendor_first_name=val.first_name;

  //      this.vendor.push(val);
  //      this.vendor_ids.push(val.id);
  //   }
  //   else if (!evt.checked && this.vendor_ids.indexOf(val.id != -1)) {
  //       let index = this.vendor_ids.indexOf(val.id);
  //       this.vendor_ids.splice(index, 1);
  //       this.vendor.splice(index, 1);
  //   }
  //   this.getFileDetail();
  //   this.Form.patchValue({
  //     vendor_id:this.vendor_ids

  //   });

  // }

  selectFinance(evt: any, val) {
    let obj = {
      id: '',
      preference: 0
    }
    var index = this.vendor_ids.findIndex(item => item.id == val.id);
    if (evt.checked && index == -1) {
      this.vendor_first_name = val.first_name;
      this.vendor.push(val);
      console.log(this.vendor);
      obj.id = val.id;
      this.vendor_ids.push(obj);
    }
    else if (!evt.checked && index != -1) {
      let indexVendor = this.vendor.indexOf(val.id);
      this.vendor_ids.splice(index, 1);
      this.vendor.splice(indexVendor, 1);
      this.dataSource_translator.data.forEach(element => {
        if (element.id == val.id) {
          element.preference = false;
        }

      });
    }

    this.getFileDetail();
    this.Form.patchValue({
      vendor_id: this.vendor_ids

    });

  }

  radioChangeHandler(event: any, val: any) {

    if (event.target.checked) {
      this.vendor_ids.map(element => {
        return element.preference = 0;
      });
      this.dataSource_translator.data.forEach((rec) => {
        if (rec.id != val.id) {
          rec.preference = false;
        }
      })
      this.vendor_ids.forEach(element => {
        if (element.id == val.id) {
          element.preference = 1;
        }

      });
    }
  }

  getEmailTemplate() {
    this.projectsService.getEmailTemplateQA()
      .subscribe((response) => {
        if (response) {

          this.response_email_data = response.data;
        }
      });
  }

  getWorkflow() {

    this.projectsService.getWorkFlow()
      .subscribe((response) => {
        if (response) {
          this.response_workflow = response.data;
        }
      });
  }

  // Submit Assignment project
  submit() {

    if (this.file_id_data.length == 0) {
      this.toastr.error("Please select any files");
      return;
    }

    this.assignment_obj = this.Form.value;
    this.assignment_obj.due_date = this.dateFormat(this.Form.value.due_date);
    this.assignment_obj.project_language_id = this.project_language_id;
    this.assignment_obj.service_id = 1;
    this.assignment_obj.start_date = '';
    this.assignment_obj.completed_percentage = 0;
    this.projectWorkFlowResponse.forEach(element => {
      if (element.workflow_id == 3) {
        this.project_workflow_id = element.id;
      }
    });
    this.assignment_obj.project_workflow_id = this.project_workflow_id;
    //this.assignment_obj.quantity_words = this.quantity_approve;
    this.assignment_obj.quantity_words = this.DetailMainResponse.sprj1_t9n_wordcount_c.value;
    this.assignment_obj.file_id = this.file_id_data;

    this.projectsService.ProjectAssignment(this.assignment_obj)
      .subscribe((response) => {
        if (response) {
          this.show_table = false;
          this.vendor_ids = [];
          this.data.target_lang_id = '';
          this.show_search = false;
          this.vendor = [];
          this.Form = this._formBuilder.group({
            duration: ['30', Validators.compose([Validators.required])],
            duration_unit: ['1'],
            duration_comment: [''],
            email_template_id: ['', Validators.compose([Validators.required])],
            update_status_interval: ['240', Validators.compose([Validators.required])],
            update_status_note: [''],
            instruction: [''],
            vendor_id: [],
            due_date: ['', Validators.compose([Validators.required])],
          });
          this.toastr.success(response.message);
          let top = document.getElementById('top');
          if (top !== null) {
            top.scrollIntoView();
            top = null;
          }
          this.target_language_select = [];

          // this.getProjectAssignment();
          // this.getProjectAssignment_compare();
          // this.WorkFlowByProjectId();
          // this.getProjectLanguage();

          window.location.href = '/pms/project/details/' + this.project_id;
        }
        // else {
        //   this.toastr.error("Something went wrong");
        // }
      });


  }


  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return year + '-' + month + '-' + dt + ' 00:00:00';
  }

  // get project assignment
  getProjectAssignment() {

    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;
    let query = 'page=1&per_page=5';
    this.projectsService.getProjectAssignment(query, obj)
      .subscribe((response: any) => {
        if (response) {
          response.response.data.forEach(element => {

            let project_status = '';
            this.ProjectAssignedStatus.forEach(ele => {
              if (ele.id == element.status) {
                project_status = ele.value;
              }
            });
            element.project_status = project_status;
            this.vendor_name = element.vendor.first_name + ' ' + element.vendor.last_name + '';

            let lang1 = '';
            let lang_array = [];
            element.project_language.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
              lang_array.push(lang1);
            });

            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)

            }
            element.lang_array = lang_array;
            this.lang_pair = lang_array;

            this.vendor_array.push(element);

            response.response.lang_array = lang_array;
          });

          this.Project_Assigned_obj = response.response.data;


          this.dataSource2 = new MatTableDataSource(response.response.data);
          this.paginate = response.response;

        }
        // else {
        //   this.toastr.error("Something went wrong");

        // }
      });
  }


  // get project assignment
  getProjectAssignment_compare() {

    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;
    let query = 'page=1&per_page=20';
    this.projectsService.getProjectAssignment(query, obj)
      .subscribe((response: any) => {
        if (response) {
          this.Project_Assigned_obj_compare = response.response.data;
          if (this.Project_Assigned_obj_compare.length > 0) {
            this.Project_Assigned_obj_compare.forEach(element => {
              if (element.status == "5" && (element.project_workflow.workflow.id == 1 || element.project_workflow.workflow.id == 2 || element.project_workflow.workflow.id == 3)) {
                let obj: any = {
                  workflow_id: '',
                  workflow_name: '',
                  vendor_name: '',
                  assignment_date: ''
                }
                obj.workflow_id = element.project_workflow.workflow.id;
                obj.workflow_name = element.project_workflow.workflow.name;
                obj.vendor_name = element.vendor.first_name + element.vendor.last_name;
                obj.assignment_date = element.project_workflow.updated_at;
                this.completed_workflow_array.push(obj);
              }
            });
            this.completed_workflow_array.sort(function (a, b) {
              return a.workflow_id - b.workflow_id;
            });
          }

        }
        // else {
        //   this.toastr.error("Something went wrong");

        // }
      });
  }

  paginateData(event) {


    let Query = '';

    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;

    Query = 'page=' + this.pageIndex + '&per_page=' + this.pageSize;

    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;

    this.projectsService.getProjectAssignment(Query, obj)
      .subscribe((response) => {
        if (response) {
          response.response.forEach(element => {
            let project_status = '';
            this.ProjectAssignedStatus.forEach(ele => {
              if (ele.id == element.status) {
                project_status = ele.value;
              }

            });
            // response.response.data.project_status=project_status;
            element.project_status = project_status;

            let lang1 = '';
            let lang_array = [];
            element.project_language.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
              lang_array.push(lang1);
            });

            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)

            }
            element.lang_array = lang_array;
            response.response.lang_array = lang_array;
          });

          this.Project_Assigned_obj = response.response.data;

          this.dataSource2 = new MatTableDataSource(response.response.data);
          this.paginate = response.response;

        }
        // else {
        //   this.toastr.error("Something went wrong");

        // }
      });
  }

  roundToTwo(num) {

    return Math.round(num * 100) / 100;
  }

  roundToTwoWithName(num) {
    let checknumber = Math.round(num * 100) / 100;
    if(checknumber < 0 ){
      return '-$'+(-checknumber)+' USD';
    }else{
      return '$'+checknumber+' USD';
    }
    
  }


  // Submit WorkFlow
  submitWorkFlow() {

    var index = this.projectWorkFlowResponse.findIndex(item => item.workflow_id == this.workFlowForm.value.workflow_id);
    if (index != -1) {
      this.toastr.error("Process Already Exist!!");
      return;
    }

    let object
    object = this.workFlowForm.value;
    object.project_id = this.project_id;
    object.status = 1;
    this.projectsService.projectWorkFlow(object)
      .subscribe((response) => {
        if (response) {
          this.WorkFlowByProjectId();
        }
      });
  }

  WorkFlowByProjectId() {

    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;
    this.projectsService.WorkFlowByProjectId(obj)
      .subscribe((response) => {
        if (response) {
          if (response.response.length > 0) {
            response.response.forEach(element => {

              let project_status;

              this.workflow_data.forEach(ele => {
                if (ele.id == element.status) {
                  project_status = ele.value;
                }
                if (element.status == 3 && element.workflow_id == 3) {
                  this.show_assign_button = false;
                }
                if (element.workflow_id == 3) {
                  this.show_workflow_status = project_status;
                }
              });
              element.project_status = project_status;
            });
            this.projectWorkFlowResponse = response.response;
          }

        }
      });
  }

  // file section start

  selectFile(evt, res) {

    if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
      this.file_id_data.push(res.id);
    }
    else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
      let index = this.file_id_data.indexOf(res.id);
      this.file_id_data.splice(index, 1);
    }

  }

  selectBulkProgress(evt) {
    this.file_id_data = [];
    let check = false;
    this.file_data.forEach((element, key) => {
      element["check"] = evt.checked;
      if (evt.checked) {
        element.check = true;
        this.file_id_data.push(element.id);
      }
      else {
        this.file_id_data = [];
      }
    });
    if (check) {
      this.bulk_selected_progress = true;
    }
  }


  // Get file data

  getFileDetail() {
    this.projectsService.getProjectDetail(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.file_data = [];

          var fullname = "Customer";
          response.data.projectfile.data.forEach(element => {
            if (element.createdby) {
              fullname = element.createdby.data.fullname;
            }
            if (element.targetlanguage) {
              var lang_name = element.targetlanguage.data.lang_name;
            }

            if (element.filetype) {
              var filetype = element.filetype.data.name;
            }

            element.file.data.forEach(elm => {
              this.project_file_data = this.BaseUrl_file + elm.path;
              elm.type = element.type;
              elm.fullname = fullname;
              //elm.created_at= created_at;
              elm.lang_name = lang_name;
              elm.filetype = filetype;
              this.file_data.push(elm);
            });
          });

        }
      });
  }
  // file section end


  getDuration(evt) {
    let duration_value = parseInt(evt.value);
    if (duration_value > 30) {
      this.customer_duration = true;
    } else {
      this.customer_duration = false;
    }

  }

  getAssignmentUpdate(evt) {
    let duration_value = parseInt(evt.value);
    if (duration_value > 240) {
      this.assignment_update = true;
    } else {
      this.assignment_update = false;
    }
  }

  /**
* Filter courses by category
*/
  onFilter(evt: any, type): void {

    this.fQuery = '?page=1&per_page=100';
    //  translator type filter
    if ((type == "type" && evt.value != "" && evt.value !== undefined)) {
      this.filterTypeQuery = evt.value;
      this.fQuery += "&translation_type=" + this.filterTypeQuery;
    }
    else if (type == "type" && evt.value == "") {
      this.filterTypeQuery = evt.value;
    }
    else if (this.filterTypeQuery !== "") {
      this.fQuery += "&translation_type=" + this.filterTypeQuery;
    }

    //  native language filter
    if ((type == "native_language" && evt.value != "" && evt.value !== undefined)) {
      this.filterNativeLanguageQuery = evt.value;
      this.fQuery += "&native_language=" + this.filterNativeLanguageQuery;
    }
    else if (type == "native_language" && evt.value == "") {
      this.filterNativeLanguageQuery = evt.value;
    }
    else if (this.filterNativeLanguageQuery !== "") {
      this.fQuery += "&native_language=" + this.filterNativeLanguageQuery;
    }
    //  specialization filter
    if ((type == "speciality" && evt.value != "" && evt.value !== undefined)) {
      this.filterSpecializationQuery = evt.value;
      this.fQuery += "&specialization=" + this.filterSpecializationQuery;
    }
    else if (type == "speciality" && evt.value == "") {
      this.filterSpecializationQuery = evt.value;
    }
    else if (this.filterSpecializationQuery !== "") {
      this.fQuery += "&specialization=" + this.filterSpecializationQuery;
    }

    // project search filter
    if ((type == "search" && evt.value != "" && evt.value !== undefined)) {
      this.filterSearchQuery = evt.value;
      this.fQuery += "&search_text=" + this.filterSearchQuery;
    }
    else if (type == "search" && evt.value == "") {
      this.filterSearchQuery = evt.value;
    }
    else if (this.filterSearchQuery !== "") {
      this.fQuery += "&search_text=" + this.filterSearchQuery;
    }
    this.projectsService.ProjectTranslatorAssignment(this.filterSearchObj, this.fQuery)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(element => {
            element.preference = false;
          });

          this.dataSource_translator = new MatTableDataSource(response.data);


        }
      });

  }


  getDetailMain(crmid:any){
    this.projectsService.getDetailMain(crmid)
    .subscribe((response:any) => {
      if (response) {
        this.DetailMainResponse = response.data;
      };
    });
  }


  openDialog(){
    if (this.file_id_data.length == 0) {
      this.toastr.error("Please select any files");
      return;
    }
    this.assignment_obj = this.Form.value;
    this.assignment_obj.due_date = this.dateFormat(this.Form.value.due_date);
    this.assignment_obj.project_language_id = this.project_language_id;
    this.assignment_obj.service_id = 1;
    this.assignment_obj.start_date = '';
    this.assignment_obj.completed_percentage = 0;
    this.projectWorkFlowResponse.forEach(element => {
      if (element.workflow_id == 3) {
        this.project_workflow_id = element.id;
      }
    });
    this.assignment_obj.project_workflow_id = this.project_workflow_id;
    //this.assignment_obj.quantity_words = this.quantity_approve;
    this.assignment_obj.quantity_words = this.DetailMainResponse.sprj1_t9n_wordcount_c.value;
    this.assignment_obj.file_id = this.file_id_data;
    this.email_temp_id = this.assignment_obj.email_template_id;
    this.projectsService.getEmailTemp(this.email_temp_id)
    .subscribe((response:any) => {
      if (response) {
        // console.log(response.data);
        // console.log(this.project_id);
        this.assignment_obj.emailTemp = response.data;
        this.assignment_obj.project_id = this.project_id;
        const dailogRef = this.dialog.open(EmailTemplateViewComponent, {
          width: '850px',
          data: this.assignment_obj
      });
      };
    });
  }


  openDialogAddTranslator() {
    const dailogRef = this.dialog.open(AddTempTranslatorComponent, {
      width: '1200px',
      height: '600px',
    });

    dailogRef.afterClosed().subscribe((response) => {
      if(response)
      {
        this.getLanguageData(this.language_resObj);
        this.dataService.changeWorkflow("qa");
      }
      
  });
  }

}

