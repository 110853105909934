<div class="page-layout w75 p-24 bg-white" >
  <div class="white-box">
    <div  class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
        <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" [routerLink]="'/pms/account'">
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <span class="h1"> <p > Add Account</p></span>
      <!-- <h4 class="m0 title dialog-title text-uppercase">Add Account</h4> -->
    </div>
<form  [formGroup]="Form" >
<div  class="p-16 pt-4 m-0" >
    
    <div fxLayout="column" fxLayoutAlign="start" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="10px">
            <mat-form-field   fxFlex="24">
                <mat-label>Name *</mat-label>
                <input matInput formControlName="name">
                <mat-error *ngIf="Form.get('name').hasError('required')">
                  Name is required!
                </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="28">
                <mat-label>Email *</mat-label>
                <input matInput formControlName="email" >
                <mat-error *ngIf="Form.get('email').hasError('required')">
                  Email is required!
              </mat-error>
              <mat-error
              *ngIf="Form.get('email').hasError('email')">
              Enter Valid Email
              </mat-error>
            </mat-form-field>
         <mat-form-field   fxFlex="20">
              <mat-label>Company</mat-label>
              <input matInput formControlName="company">
          </mat-form-field> 
          <mat-form-field   fxFlex="24">
            <mat-label>Website</mat-label>
            <input matInput formControlName="website">
        </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
            <mat-form-field   fxFlex="24">
                <mat-label>Office Phone</mat-label>
                <input matInput formControlName="office_phone">
            </mat-form-field>
            <mat-form-field   fxFlex="24">
                <mat-label>Account Type *</mat-label>
                <mat-select formControlName="account_type">
                        <mat-option value="">
                          Select
                        </mat-option>
                        <mat-option *ngFor="let res of accountType" [value]="res?.slug">{{res?.name}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="Form.get('account_type').hasError('required')">
                        Account Type is required!
                 </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="24">
                <mat-label>SIC code</mat-label>
                <input matInput formControlName="sic_code">
            </mat-form-field>
            <mat-form-field   fxFlex="24">
                <mat-label>Assigned to</mat-label>
                <mat-select formControlName="assigned_to">
                        <mat-option value="">
                          Select
                        </mat-option>
                        <mat-option *ngFor="let res of portalUsers" [value]="res?.user_name">{{res?.user_name}}</mat-option>
            
                      </mat-select>
            </mat-form-field>
        </div>

        <!-- Billing address start -->
        <p ><b>Billing Address</b></p>
        <div class="newAdd" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" >
          <mat-form-field   fxFlex="90">
            <mat-label>Street Address</mat-label>
            <input matInput formControlName="billing_street">
          </mat-form-field>

          <mat-form-field  >
            <mat-label>City</mat-label>
            <input matInput formControlName="billing_city">
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>State</mat-label>
            <input matInput formControlName="billing_state">
          </mat-form-field>
          <mat-form-field   fxFlex="20">
            <mat-label>Country</mat-label>
            <input matInput formControlName="billing_country">
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>Zip Code</mat-label>
            <input matInput formControlName="billing_zip_code">
          </mat-form-field>

        </div>
        <!-- Billing address end -->

         <!-- Shipping address start -->
         <p ><b>Shipping Address</b></p>
         <div class="newAdd" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" >
           <mat-form-field   fxFlex="90">
             <mat-label>Street Address</mat-label>
             <input matInput formControlName="shipping_street">
           </mat-form-field>
 
           <mat-form-field  >
             <mat-label>City </mat-label>
             <input matInput formControlName="shipping_city">
           </mat-form-field>
 
           <mat-form-field   fxFlex="20">
             <mat-label>State</mat-label>
             <input matInput formControlName="shipping_state">
           </mat-form-field>
           <mat-form-field   fxFlex="20">
             <mat-label>Country *</mat-label>
             <input matInput formControlName="shipping_country">
           </mat-form-field>
 
           <mat-form-field   fxFlex="20">
             <mat-label>Zip Code</mat-label>
             <input matInput formControlName="shipping_zip_code">
           </mat-form-field>
 
         </div>
         <!-- Shipping address end -->
  
      
      <div fxLayout="column" class="mt-8"  fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxFlex="100" >
        <button mat-raised-button color="accent" (click)="submit()" [disabled]="Form.invalid">Submit</button>
  
      </div>
    </div>

  </div>
</form>
</div>
</div>