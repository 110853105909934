import { Injectable } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';

import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class VendorService {
  BaseUrl: any;

  constructor(public http: HttpClient, public sharedService: SharedService) {
    this.BaseUrl = environment.APIEndpoint;
  }


  add(data: any,type='translator'): Observable<any> {
      if(typeof data=='object'){
        data["vendor_type"]=type;
      }
      let url = this.BaseUrl + '/vendors/addtranslator';
      return this.sharedService.postApi(url, data);
  }

  addComment(data: any): Observable<any> {
    let url = this.BaseUrl + '/addvendorcomment';
    return this.sharedService.postApi(url, data);
  }


  getAll(type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + `?include=vendorspecialities,vendortranslationlangs,vendornativelanguage&vendor_type=${type}`;
    return this.sharedService.getApi(url);

  }

  getTranslatorStatus(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendors_user_detail/' + id;
    return this.sharedService.getApi(url);
  }
  getAdminApprovalPending(query,type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendortranslationlangs,vendornativelanguage&stage=7&vendor_type=${type}`;
    return this.sharedService.getApi(url);

  }

  // getAllApprove(query): Observable<any> {
  //   let url = this.BaseUrl + '/vendors' + query + '&include=vendorspecialities,vendortranslationlangs,vendornativelanguage&stage=5';
  //   return this.sharedService.getApi(url);

  // }


  getAllTranslator(query,type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendorSubtitler,vendornativelanguage&stage=6&vendor_type=${type}`;
    return this.sharedService.getApi(url);

  }

  getAllTranslators(type='translator'): Observable<any> {
    let query = '?page=1&per_page=1000';
    let url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendorSubtitler,vendornativelanguage&stage=6&vendor_type=${type}`;
    return this.sharedService.getApi(url);

  }


  getTranslatorProgress(query,type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&stage=2,3,4&vendor_type=${type}`;
    return this.sharedService.getApi(url);
  }

  getHrDashboardListing(query,stage_id:any,type='translator'): Observable<any> {
    let url = '';
    if(stage_id != 0){
      url = this.BaseUrl + '/vendors' + query + `&include=vendorskill,vendorspecialities,vendorSubtitler,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=${type}&stage=` + stage_id;
    }
    else if(stage_id == 0){
      url = this.BaseUrl + '/vendors' + query + `&include=vendorskill,vendorspecialities,vendorSubtitler,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=${type}`;
    }

    return this.sharedService.getApi(url);
  }


  getHrDashboardSubtitlerListing(query,stage_id:any,type='translator'): Observable<any> {
    let url = '';
    if(stage_id != 0){
      url = this.BaseUrl + '/vendors' + query + `&skill=8&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=${type}&stage=` + stage_id;
    }
    else if(stage_id == 0){
      url = this.BaseUrl + '/vendors' + query + `&skill=8&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=${type}`;
    }

    return this.sharedService.getApi(url);
  }

  getHrDashboardTrepingListing(query,stage_id:any,type='translator'): Observable<any> {
    let url = '';
    if(stage_id != 0){
      url = this.BaseUrl + '/vendors' + query + `&skill=78&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill,vendorTreping,vendorMarketingSkillPrice&vendor_type=${type}&stage=` + stage_id;
    }
    else if(stage_id == 0){
      url = this.BaseUrl + '/vendors' + query + `&skill=78&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill,vendorTreping,vendorMarketingSkillPrice&vendor_type=${type}`;
    }

    return this.sharedService.getApi(url);
  }

  getHrDashboardClosedBlacklistedListing(query,stage_id:any,type='translator'): Observable<any> {
    let url = '';
    if(stage_id != 0){
      url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=${type}&stage=` + stage_id;
    }
    else if(stage_id == 0){
      url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=${type}`;
    }

    return this.sharedService.getApi(url);
  }



  getNeedApproval(query,type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&stage=9&vendor_type=${type}`;
    return this.sharedService.getApi(url);

  }


  filterSpeciality(query,type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + `?include=vendorspecialities,vendortranslationlangs,vendoravailabilities&vendor_type=${type}` + query;
    return this.sharedService.getApi(url);

  }

  filterSpecialityProgress(query,type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + `?include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=${type}` + query;
    return this.sharedService.getApi(url);

  }


  filterSpecialityApprove(query,type='translator'): Observable<any> {
    let url = this.BaseUrl + '/vendors' + query + `&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=${type}`;
    return this.sharedService.getApi(url);
  }


  get(id: any,type='translator'): Observable<any> {
    // tslint:disable-next-line: max-line-length
    let url = this.BaseUrl + '/vendors/' + id + `?include=vendorSubtitler,vendorspecialities,vendortranslationlangs,vendoravailabilities,vendorfiles,vendornativelanguage,vendorprofileimage,vendorresume,vendorcontract,vendorcomment,vendorskill,vendorcoafiles,extradocument,vendorTreping,vendorMarketingSkillPrice&vendor_type=${type}`;
    return this.sharedService.getApi(url);
  }

  update(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/' + id;
    return this.sharedService.patchApi(url, data);
  }

  updateLanguage(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/translatorlang/' + id;
    return this.sharedService.patchApi(url, data);
  }

  updateSubtitlerLanguage(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/subtitlerlang/' + id;
    return this.sharedService.patchApi(url, data);
  }

  updateTrepingLanguage(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/trepinglang/' + id;
    return this.sharedService.patchApi(url, data);
  }


  addLanguage_edit(data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/addtranslatorlang';
    return this.sharedService.postApi(url, data);
  }

  addSubtitlerLanguage_edit(data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/addSubtitlerlang';
    return this.sharedService.postApi(url, data);
  }

  addTrepingLanguage_edit(data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/addTrepinglang';
    return this.sharedService.postApi(url, data);
  }

  updateTranslator(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/translator/' + id;
    return this.sharedService.patchApi(url, data);
  }

  finalTranslatorApprove(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors_approve/' + id;
    return this.sharedService.patchApi(url, data);
  }


  getSpeciality() {
    let url = this.BaseUrl + '/speciality';
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers });
  }

  uploadFile(data: FormData): Observable<any> {
    let url = this.BaseUrl + '/vendors/uploadfile';
    return this.sharedService.postFileApi(url, data);
  }

  VendorsCoaFile(id:any, data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors_coa_file/' + id;
    return this.sharedService.postFileApi(url, data);
  }



  getLanguages() {
    let url = this.BaseUrl + '/language?per_page=500';
    return this.sharedService.getApi(url);
  }

  // get language by id
  getLanguageById(id:any) {
    let url = this.BaseUrl + '/language/' + id;
    return this.sharedService.getApi(url);
  }

  // create quote api
  getAllLanguages() {
    let url = this.BaseUrl + '/all_language?per_page=500';
    return this.sharedService.getApi(url);
  }

  getAllTimezone(){
    let url = this.BaseUrl + '/timezones?per_page=500';
    return this.sharedService.getApi(url);
  }

  getSkillService() {
    let url = this.BaseUrl + '/skill_service/9';
    return this.sharedService.getApi(url);
  }


  getSkillServiceById(id) {
    let url = this.BaseUrl + `/skill_service/${id}`;
    return this.sharedService.getApi(url);
  }

  getTrepingSkillService() {
    let url = this.BaseUrl + '/skill_service/11';
    return this.sharedService.getApi(url);
  }


  // get all county api
  getAllCounty() {
    let url = this.BaseUrl + '/country_list';
    return this.sharedService.getApi(url);
  }


  sendProgressMail(ids: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/sendlogindetail';
    return this.sharedService.postApi(url, ids);
  }


  getStage() {
    let url = this.BaseUrl + '/stage' + '?exclude=1,2,3,4,9,7';
    return this.sharedService.getApi(url);
  }


  getService() {
    let url = this.BaseUrl + '/skill';
    return this.sharedService.getApi(url);
  }

  getUserCount() {
    let url = this.BaseUrl + '/user/count';
    return this.sharedService.getApi(url);
  }

  getAllTemplate(query) {
    let url = this.BaseUrl + '/email'+ query;
    return this.sharedService.getApi(url);
  }

  getTemplateType(query) {
    let url = this.BaseUrl + '/get_all_email_type' + query;
    return this.sharedService.getApi(url);
  }
  

  getSingleEmailTemplate(id: any): Observable<any> {
    let url = this.BaseUrl + '/emails/' + id ;
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers });
  }

  updateEmailTemplate(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/email/' + id;
    return this.sharedService.patchApi(url, data);
  }

  removeExtradocument (data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/removedExtrafile';
    return this.sharedService.postApi(url, data);
  }

  addTemplateMaster(data: any): Observable<any> {
    let url = this.BaseUrl + '/email';
    return this.sharedService.postApi(url, data);
  }

  getVendorListingCount(vendor_type='translator'): Observable<any> {
    let url = this.BaseUrl + `/vendors_listing_count?vendor_type=${vendor_type}`;
    return this.sharedService.getApi(url);
  }

  deleteCoaFile(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_coa_file/' + id;
    return this.sharedService.deleteApi(url);
  }

  deleteVendorLanguage(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_language/' + id;
    return this.sharedService.deleteApi(url);
  }

  deleteVendorSubtitlerLanguage(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_subtitler/' + id;
    return this.sharedService.deleteApi(url);
  }

  deleteVendorTrepingLanguage(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_treping/' + id;
    return this.sharedService.deleteApi(url);
  }

  getVendorDoc(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_docs/' + id;
    return this.sharedService.getApi(url);
  }

  vendorDocSave(data: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_docs';
    return this.sharedService.postApi(url, data);
  }

  downloadVendorZip(data: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_docs_zip';
    return this.sharedService.postApi(url, data);
  }

  
  deleteVendorDoc(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendor_docs/' + id;
    return this.sharedService.deleteApi(url);
  }
  
}