import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import {  Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {
  projectLeadsource: any = [];
  allCustomer: any = [];
  languageData: any = [];
  DiscountType: any = [];
  classValue:string='';
  customerList:boolean=true;
  emaiExist:boolean=false;
  Form: any;
  today: Date = new Date();
  minDate = this.today;
  file_multiple_data: any = [];
  fileError: string = '';
  specialityResponse: any = [];
  serviceResponse: any = [];
  fileSuccess: string;
  uploaded_files: any = [];
  uploaded_files_array: any = [];
  file_id_array: any = [];
  ip_address: any;
  browser: any;
  operating_system: any;
  project_id: string;
  existing_check : boolean = false;
  singleCustomer : any;
  company_id: number = null;
  customeraddress_id: number = null;
  customer_id: number = null;
  data:any =
  {
    selectCustomer: '',
    customerType: 'New'
  }
  filterSearchQuery:string='';
  fQuery: string = '';
  dropdownSettings = {};
  dropdownList = [];
  constructor(private projectsService: ProjectsService, private toastr: ToastrService, private router: Router,
    private fb: FormBuilder, private vendorService: VendorService) { }

  ngOnInit() {
    this.getProjectSource();
    this.getLanguage();
    this.getIpAddress();
    this.getSpeciality();
    this.getDiscountType();
    this.getService();
    this.Form = this.fb.group({
      source_id: [5, Validators.compose([Validators.required])],
      request_type: [1, Validators.compose([Validators.required])],
      source_language: [null, Validators.compose([Validators.required])],
      target_language: [null, Validators.compose([Validators.required])],
      due_date: ['', Validators.compose([Validators.required])],
      first_name: ['', Validators.compose([Validators.required])],
      last_name: [''],
      email_address: ['', Validators.compose([Validators.required, Validators.email])],
      title: [''],
      phone_number: [''],
      company_name: [''],
      website_source: [''],
      city: [''],
      zip_code: [''],
      payment_method: [3, Validators.compose([Validators.required])],
      country: [''],
      state:[''],
      promo_code: [''],
      address_type: [1],
      address: [''],
      note: [''],
      service_type: ['Translation', Validators.compose([Validators.required])],
      specilization_id: ['', Validators.compose([Validators.required])],
      ip_address: [''],
      browser: [''],
      operating_system: [''],
      files_path: [''],
      file_ids: [''],
      certification_required: [true]

    });

    this.dropdownSettings = {
      singleSelection: false,
      textField: 'lang_name',
      allowSearchFilter: true
    };
  }

  // get project source
  getProjectSource() {
    this.projectsService.getLeadSource()
      .subscribe((res: any) => {
        if (res) {
          this.projectLeadsource = res.data;
        }
      });
  }

  // get Source Language & Target Language 

  getLanguage() {
    this.projectsService.getAllLanguages()
      .subscribe((res: any) => {
        if (res) {

          this.languageData = res.data;
          this.dropdownList =  res.data;
        }
      });
  }

  getIpAddress() {
    this.projectsService.getIpAddress()
      .subscribe((res: any) => {
        if (res) {
          this.ip_address = res.ip_address;
          this.browser = res.name;
          this.operating_system = res.platform;
        }
      });
  }


  getDiscountType() {

    this.projectsService.getDiscountType()
      .subscribe((res: any) => {
        if (res) {
          this.DiscountType = res.data;
        }
      });
  }

  discountType(evt: any) {

    this.Form.patchValue({
      promo_code: evt.value
    });

  }

  // start multiple file upload

  onFileUpload() {
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip','txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = 'File size should not be more than 20MB.';
            return;
          }
          this.file_multiple_data.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addDocument(this.file_multiple_data);

    };

    fileUpload.click();
  }

  // addDocument
  addDocument(file) {
    this.fileSuccess = '';
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
    }
    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploaded_files = response.data;
        this.uploaded_files_array.push(this.uploaded_files);
        response.data.forEach(element => {
          this.file_id_array.push(element.id);
        });
        this.fileSuccess = 'Uploaded successfully!!';
      });
  }
  // end multiple file upload


  // Get Speciality 

  getSpeciality() {
    
    this.vendorService.getSpeciality()
      .subscribe((res: any) => {
        if (res) {
          this.specialityResponse = res;
        }
      });
  }

  getService() {
    this.projectsService.getService()
      .subscribe((res: any) => {
        if (res) {
          this.serviceResponse = res.data;
        }
      });
  }

  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return year + '-' + month + '-' + dt + ' 00:00:00';
  }


  onFilter(evt,type){
    if(evt==''){
        this.data.selectCustomer='';
        this.allCustomer=[];
        return;
    }

    if(evt.length>2){
        this.fQuery = '';    
        // project search filter
        if ((type == 'search' && evt != '' && evt !== undefined)) {
         this.filterSearchQuery = evt;
         this.fQuery += '?search_text=' + this.filterSearchQuery;
        }
       else if(type == 'search' && evt == ''){
         this.filterSearchQuery = evt;
       }
       else if(this.filterSearchQuery!==''){
         this.fQuery += '?search_text=' + this.filterSearchQuery;
       }  
       let query = this.fQuery;


        this.getcustomer(query);

  
    }else{
        return;
    }
}

  //get customer
  getcustomer(query) {
    this.projectsService.getcustomerForLead(query)
      .subscribe((res: any) => {
        if (res) {
         
          this.allCustomer = res.data;
        }
      });
  }

  submit() {
    let obj ={
      source_id: '',
      request_type: '',
      source_language: null,
      target_language: null,
      due_date: '',
      first_name: '',
      last_name: '',
      email_address: '',
      title: '',
      phone_number: '',
      company_name: '',
      website_source: '',
      city: '',
      zip_code: '',
      payment_method: 3,
      country: '',
      state:'',
      promo_code: '',
      address_type: 1,
      address: '',
      note: '',
      service_type: 'Translation',
      specilization_id: '',
      ip_address: '',
      browser: '',
      operating_system: '',
      files_path: '',
      file_ids: '',
      certification_required: true
    };

    if (this.file_id_array.length == 0) {
      this.toastr.error('Please upload file!!');
      return;
    }
 
    if (this.Form.value.source_language == null || this.Form.value.source_language.length == 0) {
      this.toastr.error('Please select source language!! ');
      return;
    }

    if (this.Form.value.target_language == null || this.Form.value.target_language.length == 0) {
      this.toastr.error('Please select target language!! ');
      return;
    }
    this.Form.value.file_ids = this.file_id_array;
    obj.file_ids = this.Form.value.file_ids;
    obj.ip_address = this.ip_address;
    obj.browser = this.browser;
    obj.operating_system = this.operating_system;
    obj.due_date = this.dateFormat(this.Form.value.due_date);
    obj.source_language = this.getLanguageName(this.Form.value.source_language);
    obj.target_language = this.getLanguageName(this.Form.value.target_language);

    obj.source_id = this.Form.value.source_id;
    obj.request_type = this.Form.value.request_type;
    obj.first_name = this.Form.value.first_name;
    obj.last_name = this.Form.value.last_name;
    obj.email_address = this.Form.value.email_address;
    obj.title = this.Form.value.title;
    obj.phone_number = this.Form.value.phone_number;
    obj.company_name = this.Form.value.company_name;
    obj.website_source = this.Form.value.website_source;
    obj.city = this.Form.value.city;
    obj.country = this.Form.value.country;
    obj.zip_code = this.Form.value.zip_code;
    obj.payment_method = this.Form.value.payment_method;
    obj.state = this.Form.value.state;
    obj.promo_code = this.Form.value.promo_code;
    obj.address_type = this.Form.value.address_type;
    obj.address = this.Form.value.address;
    obj.note = this.Form.value.note;
    obj.service_type = this.Form.value.service_type;
    obj.specilization_id = this.Form.value.specilization_id;
    obj.certification_required = this.Form.value.certification_required;  
    if (obj.target_language == obj.source_language) {
      this.toastr.error('Source language & Target language must be diffrent!!');
      return;
    }
  
    this.projectsService.addLead(obj).subscribe(
      resposne => {
        if (resposne) {
          this.toastr.success('Add Lead Successfully!!');
          this.router.navigate(['/pms/dashboard']);
        }
      }
    );
  }

  Cancel() {

    this.router.navigate(['/pms/dashboard']);
  }

  CustomerType(evt){
    if(evt.value=='Existing'){
      this.customerList=false;
      this.existing_check = true;
      this.classValue='div_disabled';
    }
    else{
      this.customerList=true;
      this.existing_check = false;
      this.classValue='';
      this.data.selectCustomer='';
      this.Form.patchValue({
            first_name: '',
            last_name: '',
            email_address: '',
            title: '',
            phone_number: '',
            company_name: '',
            city: '',
            country:'',
            zip_code:'',
            address:'',
            state:'',
            website_source :''
          });
    }

  }

  searchCustomer(evt:any) {
    
    if(evt.option.value==''){
      this.Form.patchValue({
        first_name: '',
        last_name: '',
        email_address: '',
        title: '',
        phone_number: '',
        company_name: '',
        city: '',
        country:'',
        zip_code:'',
        address:'',
        state:''
      });
      this.toastr.error('Please select any customer!!');
      return;
    }
    this.project_id = evt.option.value;

    this.getProjectSingleCustomer();
  }
 

  getProjectSingleCustomer() {

    this.projectsService.getProjectSingleCustomer(this.project_id)
      .subscribe((response:any) => {
        if (response) {
          this.singleCustomer = response.data;
          this.classValue = 'div_disabled';
          this.existing_check = true;
          if (response.data.company) {
            this.company_id = response.data.company.data.id;
          }

          if(response.data.last_name == null){
            this.data.selectCustomer = response.data.first_name;
          }else{
            this.data.selectCustomer = response.data.first_name + ' ' + response.data.last_name;
          }
          
          if(response.data.customeraddress!=null){    
            if(response.data.customeraddress.data.length>0){
              this.customeraddress_id = response.data.customeraddress.data[0].id;
              this.customer_id = response.data.customeraddress.data[0].customer_id;
            }
            if(response.data.company!=null){
              if(response.data.customeraddress.data.length>0){
                this.Form.patchValue({
                  city: response.data.customeraddress.data[0].city,
                  country:response.data.customeraddress.data[0].country,
                  zip_code:response.data.customeraddress.data[0].zip_code,
                  address:response.data.customeraddress.data[0].address1,
                  state:response.data.customeraddress.data[0].state
                });
              }
              this.Form.patchValue({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email_address: response.data.email_primary,
                title: response.data.title,
                phone_number: response.data.phone,
                website : response.data.company.data.website,
                company_name: response.data.company.data.name
              });
            }else{
              
                if(response.data.customeraddress.data.length>0){
                  this.Form.patchValue({
                    city: response.data.customeraddress.data[0].city,
                    country:response.data.customeraddress.data[0].country,
                    zip_code:response.data.customeraddress.data[0].zip_code,
                    address:response.data.customeraddress.data[0].address1,
                    state:response.data.customeraddress.data[0].state
                  });
                }
              this.Form.patchValue({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email_address: response.data.email_primary,
                title: response.data.title,
                phone_number: response.data.phone,
                website : '',
                company_name: '',
              });
            }
          
          }else{

            if(response.data.company!=null){
              this.Form.patchValue({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email_address: response.data.email_primary,
                title: response.data.title,
                phone_number: response.data.phone,
                website : response.data.company.data.website,
                company_name: response.data.company.data.name,
                city: '',
                country:'',
                zip_code:'',
                address:'',
                state:''
              });
            }else{
              this.Form.patchValue({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email_address: response.data.email_primary,
                title: response.data.title,
                website : response.data.website,
                phone_number: response.data.phone,
                company_name: '',
                city: '',
                country:'',
                zip_code:'',
                address:'',
                state:''
              });
            }
          }
        }

      }
      );

  }


  getLanguageName(res: any) {
 
    let lang = '';
    if(res.length>0){
      res.forEach(element => {
            lang += element.lang_name + ', ';
        });
    }
    lang = lang.slice(0, -2);
    return lang;
  }

  updateData() {
    let obj : any =
    {
      uuid: this.singleCustomer.uuid,
      title: this.Form.value.title,
      first_name: this.Form.value.first_name,
      last_name: this.Form.value.last_name,
      phone: this.Form.value.phone_number,
      email_primary: this.Form.value.email_address,
      company_id: this.company_id,
      company: {
        id: this.company_id,
        name: this.Form.value.company_name,
        website: ''
      },

      customeraddress: {
        id: this.customeraddress_id,
        customer_id: this.singleCustomer.id,
        address_type: this.Form.value.address_type,
        address1: this.Form.value.address,
        address2: null,
        city: this.Form.value.city,
        state: this.Form.value.state,
        country: this.Form.value.country,
        zip_code: this.Form.value.zip_code,
        suite: null
      }
    }
    this.projectsService.updateCustomer(this.singleCustomer.uuid, obj).subscribe(
      resposne => {
        if (resposne) {
          this.toastr.success('Customer updated successfully');
        }
      }
    );

  }


  onItemSelect(item: any) {
  
  }
  onSelectAll(items: any) {

  }

  onItemDeSelect(items: any) {
  
  }

  onDeSelectAll(items: any) {
  }


}
