/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./whatsapp-attachment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./whatsapp-attachment.component";
var styles_WhatsappAttachmentComponent = [i0.styles];
var RenderType_WhatsappAttachmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WhatsappAttachmentComponent, data: {} });
export { RenderType_WhatsappAttachmentComponent as RenderType_WhatsappAttachmentComponent };
function View_WhatsappAttachmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "img_test"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.mediaurl, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.mediaurl, ""); _ck(_v, 1, 0, currVal_1); }); }
function View_WhatsappAttachmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "pdf-media"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "s-60 mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["picture_as_pdf"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.mediaurl, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_WhatsappAttachmentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["videoPlayer", 1]], null, 2, "video", [["controls", ""], ["width", "200"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Browser not supported "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.mediaurl, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_WhatsappAttachmentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "audio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "audio", [["controls", ""], ["width", "200"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "audio/mpeg"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Browser not supported "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.mediaurl, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_WhatsappAttachmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WhatsappAttachmentComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WhatsappAttachmentComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WhatsappAttachmentComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WhatsappAttachmentComponent_5)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.mediacontentType == "image/jpeg"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.mediacontentType == "application/pdf"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.mediacontentType == "video/mp4"); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_v.context.$implicit.mediacontentType == "audio/mpeg") || (_v.context.$implicit.mediacontentType == "audio/ogg")); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_WhatsappAttachmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "attachmentwrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WhatsappAttachmentComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.peopleArray; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WhatsappAttachmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-whatsapp-attachment", [], null, null, null, View_WhatsappAttachmentComponent_0, RenderType_WhatsappAttachmentComponent)), i1.ɵdid(1, 114688, null, 0, i5.WhatsappAttachmentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WhatsappAttachmentComponentNgFactory = i1.ɵccf("app-whatsapp-attachment", i5.WhatsappAttachmentComponent, View_WhatsappAttachmentComponent_Host_0, { childAttachment: "childAttachment" }, {}, []);
export { WhatsappAttachmentComponentNgFactory as WhatsappAttachmentComponentNgFactory };
