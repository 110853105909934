<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
    <div class="white-box">
      <div class="tHeader">
        <h2>How can we help you?</h2>
        </div>
  <div class="boxBodyInn">

    


      <mat-tab-group>
          <mat-tab label="Quote">  
      
            <div class="p20">

              <h2>HISTORY, PURPOSE AND USAGE </h2>
              <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. 
                </p>
                <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
             
             <h3>  Frequently asked questions</h3> 
             
             
                <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h4>
                        How to insert an image into an e-mail notification template?
                      </h4>
                    </mat-expansion-panel-header>
                  
                    <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                  </mat-expansion-panel>
            
                  <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <h4>
                      How to change my password?
                    </h4>
                  </mat-expansion-panel-header>
                
                  <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                </mat-expansion-panel>


                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h4>
                        How to set up my email?
                      </h4>
                    </mat-expansion-panel-header>
                  
                    <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                  </mat-expansion-panel>

                  <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <h4>
                          What browsers do you recommend?
                        </h4>
                      </mat-expansion-panel-header>
                    
                      <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <h4>
                            How to create credit notes?
                          </h4>
                        </mat-expansion-panel-header>
                      
                        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                      </mat-expansion-panel>


                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <h4>
                              How to set up Filtered Records widget?
                            </h4>
                          </mat-expansion-panel-header>
                        
                          <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                        </mat-expansion-panel>




                </mat-accordion>
                    
                                  
                         
                 
      
              </div>
      
            
            
      
      
      
            
            </mat-tab>
          <mat-tab label="Assignment"> 
      
            <div class="p20">
            
                <h2>HISTORY, PURPOSE AND USAGE </h2>
              <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. 
                </p>
                <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
             
             <h3>  Frequently asked questions</h3> 

              <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h4>
                        How to insert an image into an e-mail notification template?
                      </h4>
                    </mat-expansion-panel-header>
                  
                    <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                  </mat-expansion-panel>
            
                  <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <h4>
                      How to change my password?
                    </h4>
                  </mat-expansion-panel-header>
                
                  <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                </mat-expansion-panel>


                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h4>
                        How to set up my email?
                      </h4>
                    </mat-expansion-panel-header>
                  
                    <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                  </mat-expansion-panel>

                  <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <h4>
                          What browsers do you recommend?
                        </h4>
                      </mat-expansion-panel-header>
                    
                      <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <h4>
                            How to create credit notes?
                          </h4>
                        </mat-expansion-panel-header>
                      
                        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                      </mat-expansion-panel>


                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <h4>
                              How to set up Filtered Records widget?
                            </h4>
                          </mat-expansion-panel-header>
                        
                          <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                        </mat-expansion-panel>




                </mat-accordion>
                </div>
      
      
      
      
      
      
          
          
          </mat-tab>

          <mat-tab label="Payment"> 
      
              <div class="p20">
              
                  <h2>HISTORY, PURPOSE AND USAGE </h2>
                  <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. 
                    </p>
                    <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                 
                 <h3>  Frequently asked questions</h3> 
                <mat-accordion>
                  <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <h4>
                          How to insert an image into an e-mail notification template?
                        </h4>
                      </mat-expansion-panel-header>
                    
                      <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                    </mat-expansion-panel>
              
                    <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h4>
                        How to change my password?
                      </h4>
                    </mat-expansion-panel-header>
                  
                    <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                  </mat-expansion-panel>


                  <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <h4>
                          How to set up my email?
                        </h4>
                      </mat-expansion-panel-header>
                    
                      <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <h4>
                            What browsers do you recommend?
                          </h4>
                        </mat-expansion-panel-header>
                      
                        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                      </mat-expansion-panel>

                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <h4>
                              How to create credit notes?
                            </h4>
                          </mat-expansion-panel-header>
                        
                          <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                        </mat-expansion-panel>


                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <h4>
                                How to set up Filtered Records widget?
                              </h4>
                            </mat-expansion-panel-header>
                          
                            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
  
                          </mat-expansion-panel>




                  </mat-accordion>
                  </div>
        
        
        
        
        
        
            
            
            </mat-tab>

            <mat-tab label="Approval"> 
      
                <div class="p20">
                
                    <h2>HISTORY, PURPOSE AND USAGE </h2>
                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. 
                      </p>
                      <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                   
                   <h3>  Frequently asked questions</h3> 
                  <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <h4>
                            How to insert an image into an e-mail notification template?
                          </h4>
                        </mat-expansion-panel-header>
                      
                        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                      </mat-expansion-panel>
                
                      <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <h4>
                          How to change my password?
                        </h4>
                      </mat-expansion-panel-header>
                    
                      <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                    </mat-expansion-panel>


                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <h4>
                            How to set up my email?
                          </h4>
                        </mat-expansion-panel-header>
                      
                        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                      </mat-expansion-panel>

                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <h4>
                              What browsers do you recommend?
                            </h4>
                          </mat-expansion-panel-header>
                        
                          <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <h4>
                                How to create credit notes?
                              </h4>
                            </mat-expansion-panel-header>
                          
                            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
  
                          </mat-expansion-panel>


                          <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <h4>
                                  How to set up Filtered Records widget?
                                </h4>
                              </mat-expansion-panel-header>
                            
                              <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
    
                            </mat-expansion-panel>




                    </mat-accordion>
                    
                    </div>
          
              
              </mat-tab>

              <mat-tab label="Invoice">
      
                  <div class="p20">
                      <h2>HISTORY, PURPOSE AND USAGE </h2>
                      <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. 
                        </p>
                        <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                     
                     <h3>  Frequently asked questions</h3> 
                      
                    <mat-accordion>
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <h4>
                              How to insert an image into an e-mail notification template?
                            </h4>
                          </mat-expansion-panel-header>
                        
                          <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                        </mat-expansion-panel>
                  
                        <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <h4>
                            How to change my password?
                          </h4>
                        </mat-expansion-panel-header>
                      
                        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                      </mat-expansion-panel>


                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <h4>
                              How to set up my email?
                            </h4>
                          </mat-expansion-panel-header>
                        
                          <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <h4>
                                What browsers do you recommend?
                              </h4>
                            </mat-expansion-panel-header>
                          
                            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
  
                          </mat-expansion-panel>

                          <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <h4>
                                  How to create credit notes?
                                </h4>
                              </mat-expansion-panel-header>
                            
                              <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
    
                            </mat-expansion-panel>


                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <h4>
                                    How to set up Filtered Records widget?
                                  </h4>
                                </mat-expansion-panel-header>
                              
                                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
      
                              </mat-expansion-panel>




                      </mat-accordion>
                      
                      </div>
            
                
                </mat-tab>

                <mat-tab label="Refund"> 
      
                    <div class="p20">
                      
                        <h2>HISTORY, PURPOSE AND USAGE </h2>
                        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. 
                          </p>
                          <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                       
                       <h3>  Frequently asked questions</h3> 
                        <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <h4>
                                How to insert an image into an e-mail notification template?
                              </h4>
                            </mat-expansion-panel-header>
                          
                            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                          </mat-expansion-panel>
                    
                          <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <h4>
                              How to change my password?
                            </h4>
                          </mat-expansion-panel-header>
                        
                          <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                        </mat-expansion-panel>


                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <h4>
                                How to set up my email?
                              </h4>
                            </mat-expansion-panel-header>
                          
                            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
  
                          </mat-expansion-panel>

                          <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <h4>
                                  What browsers do you recommend?
                                </h4>
                              </mat-expansion-panel-header>
                            
                              <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
    
                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <h4>
                                    How to create credit notes?
                                  </h4>
                                </mat-expansion-panel-header>
                              
                                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
      
                              </mat-expansion-panel>


                              <mat-expansion-panel>
                                  <mat-expansion-panel-header>
                                    <h4>
                                      How to set up Filtered Records widget?
                                    </h4>
                                  </mat-expansion-panel-header>
                                
                                  <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
        
                                </mat-expansion-panel>




                        </mat-accordion>
                        </div>
              
                  
                  </mat-tab>
          
        </mat-tab-group>




  




      </div>

      <div class="actWraper">
          <p>Couldn't find the Help you need?</p>

        <button mat-flat-button color="accent" class="mr-16" routerLink="/pms/support">Contact Support</button>

        <button mat-stroked-button color="accent" routerLink="/pms/kb">Learning Center</button>



      </div>


</div>





</div>