import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'menu',
        title    : 'Menu',
        translate: 'Menu',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'Dashboard',
                type     : 'item',
                icon     : 'home',
                url      : '/admin-dashboard',
                
            }, 
            //  {
            //     id       : 'pmdashboard',
            //     title    : 'Dashboard',
            //     translate: 'Dashboard',
            //     type     : 'item',
            //     icon     : 'home',
            //     url      : '/pms/dashboard',
                
            // },
            // {
            //     id       : 'pmprojects',
            //     title    : 'Projects',
            //     translate: 'Projects',
            //     type     : 'item',
            //     icon     : 'desktop_windows',
            //     url      : '/pms/projects',
                
            // } ,
            // {
            //     id       : 'quotes',
            //     title    : 'Quotes',
            //     translate: 'Quotes',
            //     type     : 'item',
            //     icon     : 'file_copy',
            //     url      : 'pms/quotes',
            // },

            {
                id       : 'user_management',
                title    : 'Portal Users',
                translate: 'Portal Users',
                type     : 'item',
                icon     : 'group',
                url      : '/user-management',
                
            },
            {
                id       : 'approval',
                title    : 'Approval',
                translate: 'Approval',
                type     : 'item',
                icon     : 'how_to_reg',
                url      : '/approval',
            },
            {
                id       : 'tickets',
                title    : 'Tickets',
                translate: 'Tickets',
                type     : 'item',
                icon     : 'web_asset',
                url      : '/tickets',
            }
            ,
            {
                id       : 'hrDashboard',
                title    : 'Dashboard',
                translate: 'Dashboard',
                type     : 'item',
                icon     : 'home',
                url      : '/hr/hr-dashboard',
            }
            ,
            {
                id       : 'imported',
                title    : 'Imported',
                translate: 'Imported',
                type     : 'item',
                icon     : 'import_export',
                url      : '/hr/imported-translator',
            },
            {
                id       : 'translator',
                title    : 'Translators',
                translate: 'Translators',
                type     : 'item',
                icon     : 'how_to_reg',
                url      : '/hr/translator',
            },
            {
                id       : 'orientations',
                title    : 'Orientation',
                translate: 'Orientation',
                type     : 'item',
                icon     : 'how_to_reg',
                url      : '/hr/orientation',
            },
            {
                id       : 'marketingDashboard',
                title    : 'Dashboard',
                translate: 'Dashboard',
                type     : 'item',
                icon     : 'home',
                url      : '/marketing/marketing-dashboard',
            },
            {
                id       : 'maketing',
                title    : 'Freelancer',
                translate: 'Freelancers',
                type     : 'item',
                icon     : 'how_to_reg',
                url      : '/marketing/freelancer',
            },
            {
                id       : 'maketingGCLID',
                title    : 'GCLID Records',
                translate: 'GCLID Records',
                type     : 'item',
                icon     : 'how_to_reg',
                url      : '/marketing/gclid-records',
            },
            /* {
                id       : 'marketingorientations',
                title    : 'Orientation',
                translate: 'Orientation',
                type     : 'item',
                icon     : 'how_to_reg',
                url      : '/marketing/orientation',
            }, */
            {
                id       : 'masterData',
                title    : 'Master Data',
                translate: 'Master Data',
                type     : 'item',
                icon     : 'view_list',
                url      : '/masterdata',
            },
            {
                id       : 'reports',
                title    : 'Reports',
                translate: 'Reports',
                type     : 'item',
                icon     : 'bar_chart',
                url      : '/reports',
            },
            {
                id       : 'subtitler',
                title    : 'AVT',
                translate: 'AVT',
                type     : 'item',
                icon     : 'import_export',
                url      : '/hr/subtitler',   
            },
            {
                id       : 'treping',
                title    : 'Terping',
                translate: 'Terping',
                type     : 'item',
                icon     : 'import_export',
                url      : '/hr/terping',   
            },
            // {
            //     id       : 'closedblacklisted',
            //     title    : 'Closed/Blacklisted',
            //     translate: 'Closed/Blacklisted',
            //     type     : 'item',
            //     icon     : 'block',
            //     url      : '/hr/closed-blacklisted',   
            // },
            // {
            //     id       : 'customer',
            //     title    : 'Customer',
            //     translate: 'Customer',
            //     type     : 'item',
            //     icon     : 'account_box',
            //     url      : '/pms/customer',
            // },
            // {
            //     id       : 'account',
            //     title    : 'Account',
            //     translate: 'Account',
            //     type     : 'item',
            //     icon     : 'contacts',
            //     url      : '/pms/account',
            // },

            // {
            //     id       : 'calendar',
            //     title    : 'Calendar',
            //     translate: 'Calendar',
            //     type     : 'item',
            //     icon     : 'calendar_today',
            //     url      : 'pms/calendar',
            // },
            {
                id       : 'freelancer',
                title    : 'Freelancer',
                translate: 'Freelancer',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : 'pms/freelancer',
            },
            {
                id       : 'pmblacklisted',
                title    : 'Closed/Blacklisted',
                translate: 'Closed/Blacklisted',
                type     : 'item',
                icon     : 'block',
                url      : '/pms/closed-blacklisted',   
            },
            {
                id       : 'report',
                title    : 'Report',
                translate: 'Report',
                type     : 'item',
                icon     : 'report',
                url      : 'pms/report',
            },
            {
                id       : 'switchPortal',
                title    : 'Switch Portal',
                translate: 'Switch Portal',
                type     : 'item',
                icon     : 'switch_camera',
                
                function () {
                  
                    window.location.href = '/welcome';
                    
                }
            },

        ]
    }
];
