import { OnInit } from '@angular/core';
var AdditionalServicesComponent = /** @class */ (function () {
    // check_Mailing:boolean=true;
    // check_Notarization:boolean=true;
    // check_Apostile:boolean=true;
    function AdditionalServicesComponent() {
        this.getValueMailing = '';
        this.getValueNotarization = '';
        this.getValueApostile = '';
    }
    AdditionalServicesComponent.prototype.ngOnInit = function () {
    };
    AdditionalServicesComponent.prototype.getDataMailing = function (value) {
        this.getValueMailing = value;
    };
    AdditionalServicesComponent.prototype.getDataNotarization = function (value) {
        this.getValueNotarization = value;
        //  if(value=='"(Applied)"'){
        //   this.check_Notarization=true;
        // }else {
        //   this.check_Notarization=false;
        // }
    };
    AdditionalServicesComponent.prototype.getDataApostile = function (value) {
        this.getValueApostile = value;
        //  if(value=='"(Applied)"'){
        //   this.check_Apostile=true;
        // }else {
        //   this.check_Apostile=false;
        // }
    };
    return AdditionalServicesComponent;
}());
export { AdditionalServicesComponent };
