import { Component, OnInit } from '@angular/core';
export interface servData {
  position: number;
  servTitle: string;
  servDesc: string;
}
const ELEMENT_DATA: servData[] = [
  {position: 1, servTitle: 'Translation Services', servDesc: 'Document Translation'},
  {position: 2, servTitle: 'OVer the phone interpretation', servDesc: 'Over the Phone Interpretation'},

];
@Component({
  selector: 'app-services-master',
  templateUrl: './services-master.component.html',
  styleUrls: ['./services-master.component.scss']
})
export class ServicesMasterComponent implements OnInit {
  displayedColumns: string[] = ['position', 'servTitle', 'servDesc', 'action'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit() {
  }

}
