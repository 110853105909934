import { Injectable } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  BaseUrl: any;

  constructor(public http: HttpClient, public sharedService: SharedService) {
    this.BaseUrl = environment.APIEndpoint;
  }

  add(data: any): Observable<any> {
    let url = this.BaseUrl + '/user/register';
    return this.sharedService.postApi(url, data);
  }


  // get(query): Observable<any> {
  //   let url = this.BaseUrl + '/user?' + query + 'user_role=Admin,Finance-Manager,Hr-Admin,Operation-Manager,Project-Manager';
  //   return this.sharedService.getApi(url);
  // }
  getAll(query): Observable<any> {
    let url = this.BaseUrl + '/user?'+ query;
    return this.sharedService.getApi(url);
  }


  getAdminProfile(): Observable<any> {
    let url = this.BaseUrl + '/me?';
    return this.sharedService.getApi(url);
  }

  getUser(id: any): Observable<any> {
    let url = this.BaseUrl + '/user/' + id;
    return this.sharedService.getApi(url);
  }


  getRole() {
    let url = this.BaseUrl + '/userGroup?exclude=Vendor14';
    return this.sharedService.getApi(url);
  }


  update(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/user/' + id;
    return this.sharedService.patchApi(url, data);
  }

  updateProfileImage(data: any): Observable<any> {
    let url = this.BaseUrl + '/user/profile_image';
    return this.sharedService.postApi(url, data);
  }

  uploadFile(data: FormData): Observable<any> {
    let url = this.BaseUrl + '/vendors/uploadfile';
    return this.sharedService.postFileApi(url, data);
  }

  getLanguages(query) {
    let url = this.BaseUrl + '/language?per_page=500' + query;
    return this.sharedService.getApi(url);
  }

  getLanguagesListing(query) {
    let url = this.BaseUrl + '/languages' + query;
    return this.sharedService.getApi(url);
  }

  addLangugae(data: any): Observable<any> {
    let url = this.BaseUrl + '/language';
    return this.sharedService.postApi(url, data);
  }


  updateMasterLanguage(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/language/' + id;
    return this.sharedService.patchApi(url, data);
  }


  getWorkflow(query) {
    let url = this.BaseUrl + '/worflow' + query;
    return this.sharedService.getApi(url);
  }

  addWorkflow(data: any): Observable<any> {
    let url = this.BaseUrl + '/worflow';
    return this.sharedService.postApi(url, data);
  }

  getLocation(query) {
    let url = this.BaseUrl + '/location' + query;
    return this.sharedService.getApi(url);
  }

  getSingleLocation(id: any): Observable<any> {
    let url = this.BaseUrl + '/location/' + id;
    return this.sharedService.getApi(url);
  }
  addLocation(query) {
    let url = this.BaseUrl + '/location';
    return this.sharedService.postApi(url, query);
  }

  updateMasterLocation(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/location/' + id;
    return this.sharedService.patchApi(url, data);
  }

  // Tickets

  getallTickets(query:any): Observable<any> {
    let url = this.BaseUrl + '/all_ticket' + query;
    return this.sharedService.getApi(url);
  }
  getallTicketbyId(id:any): Observable<any> {
    let url = this.BaseUrl + '/ticket/' + id;
    return this.sharedService.getApi(url);
  }
 
  updateTicketStatus(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/ticket/' + id;
    return this.sharedService.patchApi(url, data);
  }

}
