import { OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(projectsService, toastr) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.notification_count = 0;
        this.notification_response = [];
        this.paginate = {};
        this.pageIndex = 1;
        this.pageSize = 5;
        this.query = "";
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        this.getAllNotifications();
    };
    NotificationsComponent.prototype.getAllNotifications = function () {
        var _this = this;
        var Query = '';
        this.projectsService.allNotification(Query)
            .subscribe(function (response) {
            _this.notification_response = [];
            response.data.forEach(function (element) {
                if (element.type == "App\\Notifications\\ProjectCreatedNotification") {
                    if (element.data.reference) {
                        var message = " New " + element.data.service_type + " Request for Language " + element.data.source_language + " - " + element.data.target_language + " Received";
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\\Notifications\\ProjectQuoteCreatedNotification") {
                    if (element.data.due_date) {
                        var lang_1 = '';
                        element.data.language_pair.forEach(function (res) {
                            lang_1 += res + ', ';
                        });
                        lang_1 = lang_1.slice(0, -2);
                        var message = lang_1 + ' Translation request due on ' + element.data.due_date;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\\Notifications\\AssignmentDeclinedNotification") {
                    if (element.data.reference_number) {
                        var message = element.data.reference_number + " assignment was declined by " + element.data.freelance_name;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\Notifications\AssignmentAcceptNotification") {
                    if (element.data.reference_number) {
                        var message = element.data.freelance_name + " accepted " + element.data.reference_number + " assignment due on " + element.data.reference_number;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\\Notifications\\ProjectStatusUpdateNotification") {
                    if (element.data.content) {
                        _this.notification_response.push(element.data.content);
                    }
                }
            });
            _this.paginate = response.meta.pagination;
            _this.notification_count = response.meta.pagination.total;
        }
        //   , (error) => {
        //       this.toastr.error(error);
        //   }
        );
    };
    NotificationsComponent.prototype.paginateData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query += uQuery;
        this.projectsService.allNotification(this.query)
            .subscribe(function (response) {
            _this.notification_response = [];
            response.data.forEach(function (element) {
                if (element.type == "App\\Notifications\\ProjectCreatedNotification") {
                    if (element.data.reference) {
                        var message = " New " + element.data.service_type + " Request for Language " + element.data.source_language + " - " + element.data.target_language + " Received";
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\\Notifications\\ProjectQuoteCreatedNotification") {
                    if (element.data.due_date) {
                        var lang_2 = '';
                        element.data.language_pair.forEach(function (res) {
                            lang_2 += res + ', ';
                        });
                        lang_2 = lang_2.slice(0, -2);
                        var message = lang_2 + ' Translation request due on ' + element.data.due_date;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\\Notifications\\AssignmentDeclinedNotification") {
                    if (element.data.reference_number) {
                        var message = element.data.reference_number + " assignment was declined by " + element.data.freelance_name;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\Notifications\AssignmentAcceptNotification") {
                    if (element.data.reference_number) {
                        var message = element.data.freelance_name + " accepted " + element.data.reference_number + " assignment due on " + element.data.reference_number;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == "App\\Notifications\\ProjectStatusUpdateNotification") {
                    if (element.data.content) {
                        _this.notification_response.push(element.data.content);
                    }
                }
            });
            _this.paginate = response.meta.pagination;
            _this.notification_count = response.meta.pagination.total;
        }
        //   , (error) => {
        //       this.toastr.error(error);
        //   }
        );
    };
    return NotificationsComponent;
}());
export { NotificationsComponent };
