/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./translator-quiz.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./translator-quiz.component";
var styles_TranslatorQuizComponent = [i0.styles];
var RenderType_TranslatorQuizComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TranslatorQuizComponent, data: {} });
export { RenderType_TranslatorQuizComponent as RenderType_TranslatorQuizComponent };
export function View_TranslatorQuizComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" translator-quiz works!\n"]))], null, null); }
export function View_TranslatorQuizComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-translator-quiz", [], null, null, null, View_TranslatorQuizComponent_0, RenderType_TranslatorQuizComponent)), i1.ɵdid(1, 114688, null, 0, i2.TranslatorQuizComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TranslatorQuizComponentNgFactory = i1.ɵccf("app-translator-quiz", i2.TranslatorQuizComponent, View_TranslatorQuizComponent_Host_0, {}, {}, []);
export { TranslatorQuizComponentNgFactory as TranslatorQuizComponentNgFactory };
