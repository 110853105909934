import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { HrModule } from "app/main/hr/hr.module";
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { WelcomeModule } from 'app/main/welcome/welcome.module';
import { AdminModule } from 'app/main/admin/admin.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { ComingSoonComponent } from './main/coming-soon/coming-soon.component';
import { AdminGuard } from './_guards/admin.guard';
import { HrGuard } from './_guards/hr.guard';
import { PmsModule } from './main/pms/pms.module';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FinancialModule } from './main/financial/financial.module';
import { CustomerModule } from './main/pms/customer/customer.module';

import { CalendarService } from 'app/main/pms/calendar/calendar.service';
import { EmailLogPreviewComponent } from './main/pms/project-details/project-email-log/email-log-preview/email-log-preview.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { LoginGuard } from './_guards/login.guard';
import { MarketingGuard } from './_guards/marketing.guard';
import { MarketingModule } from './main/marketing/marketing.module';
const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
const appRoutes: Routes = [
    {
        path: "",
        redirectTo: "auth/login",
        pathMatch: "full"
    },

    {
        path: 'coming-soon',
        component: ComingSoonComponent
    },
    {
        path: "admin",
        loadChildren: "./main/admin/admin.module#AdminModule",
        canActivate: [AdminGuard]
    } ,
    {
        path: "hr",
        loadChildren: "./main/hr/hr.module#HrModule",
        canActivate: [HrGuard]
    },
    {
        path: "marketing",
        loadChildren: "./main/marketing/marketing.module#MarketingModule",
        canActivate: [MarketingGuard]
    }
    
    ,
    {
        path        : '',
        loadChildren: './main/pages/pages.module#PagesModule'
    },
    {
        path      : '**',
        redirectTo: 'auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        ComingSoonComponent,
        
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        ChartsModule,
        NgxChartsModule,
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule, CdkStepperModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
      
        LayoutModule,
        WelcomeModule,
        AdminModule,FinancialModule,
        FuseWidgetModule,
        HrModule,PmsModule,CustomerModule,MarketingModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            maxOpened: 1,
            autoDismiss: true,
            enableHtml: true,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right'
          }),
          SocketIoModule.forRoot(config)
    ],
    bootstrap   : [
        AppComponent
    ],
    entryComponents: [EmailLogPreviewComponent],
    providers      : [
        CalendarService, LoginGuard,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
})
export class AppModule
{
}
