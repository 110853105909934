import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private projectsService: ProjectsService,private toastr: ToastrService,private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  // onYesClick(): void {
  //   this.dialogRef.close();
  // }


  // Delete Additional Service

  onYesClick() {

    this.projectsService.deleteProject(this.data.project_id).subscribe(
      (response) => {
       
        if(response){
           this.toastr.success("Delete Project  Successfully!!");
           this.dialogRef.close();
           this.router.navigate(['/pms/dashboard']);
        }
    
      }
    );
  }
}
