<div class="page-layout w100 p-24 bg-white" fusePerfectScrollbar>

  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">

    <div fxFlex="20">

      <div class="white-box plainBox">
        <div class="tHeader">
          <h2><i class="material-icons">filter_list</i> Apply Filter</h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <form name="Form" [formGroup]="Form">
              <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                <div fxFlex="79">
                  <div><b> Search by </b> </div>
                  <mat-form-field  >
                    <mat-label>ID/Name/Email/Phone</mat-label>
                    <input matInput formControlName="search_value">
                  </mat-form-field>
                </div>
                <div fxFlex="20">
                  <button mat-stroked-button color="accent" class="bicon mt-12" (click)="submit()"
                    [disabled]="Form.invalid"><i class="material-icons">search</i></button>
                </div>
              </div>
            </form>
            <div><b>Source Language is</b> </div>
            <mat-form-field  >
              <mat-select (selectionChange)="onFilter($event,'s_lang')" placeholder="Select Field">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let language of languageData" [value]="language.id">
                  {{language.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div><b>Target Language is </b> </div>
            <mat-form-field  >
              <mat-select (selectionChange)="onFilter($event,'t_lang')" placeholder="Select Field">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let language of languageData" [value]="language.id">
                  {{language.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div><b>Status is</b> </div>
            <mat-form-field  >
              <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'status')">
                <mat-option value="">Any</mat-option>
                <mat-option value="1">Pending</mat-option>
                <mat-option value="2">Under Quote</mat-option>
              </mat-select>
            </mat-form-field>

            <div><b>Category is</b> </div>
            <mat-form-field  >
              <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'category')">
                <mat-option value="">Any</mat-option>
                <mat-option value="1">DayVIP</mat-option>
                <mat-option value="2">Company</mat-option>
                <mat-option value="3">Individual</mat-option>
                <mat-option value="4">New Client</mat-option>
              </mat-select>
            </mat-form-field>


            <div><b>Payment Method is</b> </div>
            <mat-form-field  >
              <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'payment_method')">
                <mat-option value="">Any</mat-option>
                <mat-option value="1">NET30</mat-option>
                <mat-option value="2">NET45</mat-option>
                <mat-option value="3">Regular/Upfront</mat-option>
                <mat-option value="4">Credit Available</mat-option>
              </mat-select>
            </mat-form-field>

            <div><b>Location</b> </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <mat-form-field  >
                  <input matInput (keyup)="onFilter($event.target.value,'city')">
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field  >
                  <mat-select placeholder="Country" (selectionChange)="onFilter($event,'country')">
                    <mat-option value="">All</mat-option>
                    <mat-option value="USA">USA</mat-option>
                    <mat-option value="China">China</mat-option>
                    <mat-option value="Germany">Germany</mat-option>
                    <mat-option value="France">France</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div><b>Request time by</b> </div>
            <mat-form-field  >
              <input matInput (click)="dp2.open()" [matDatepicker]="dp2" (dateChange)="onFilter($event,'start_date')">
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2 disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field  >
              <input matInput (click)="dp1.open()" [matDatepicker]="dp1" (dateChange)="onFilter($event,'end_date')">
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1 disabled="false"></mat-datepicker>
            </mat-form-field>


            <div><b>Locked by Project Manager</b> </div>
            <mat-form-field  >
              <mat-select (selectionChange)="onFilter($event,'ProjectManager')" placeholder="Select Field">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let res of projectmanager" [value]="res">
                  {{res}}

                </mat-option>
              </mat-select>
            </mat-form-field>

            <div><b>Lead Source</b> </div>
            <mat-form-field  >
              <mat-select (selectionChange)="onFilter($event,'lead_source')" placeholder="Select Field">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let res of projectLeadsource" [value]="res.name">
                  {{res.name}}

                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>



    <div fxFlex="78">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex>
              <h2>Quote request in Queue</h2>
            </div>
            <div fxFlex class="text-right">
              Total <b>{{total_quotes}}</b> Records Available


              <button mat-stroked-button routerLink="/pms/addlead">Add Project</button>
            </div>

          </div>
          <div class="filterTag pt-12">
            <mat-chip-list #chipList aria-label="QuotesChip selection">
              <mat-chip *ngFor="let QuotesChip of fChipsArray" [selectable]="selectable" [removable]="removable">
                {{QuotesChip}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>


        <div class="boxBody">
          <div class="boxInn">
            <table mat-table [dataSource]="dataSource" class="table">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> ID</mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                  <p class="text-truncate"><a
                      routerLink="/pms/project/details/{{ product.uuid }}">{{product?.reference}}</a></p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="qEmail">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16"> Email
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
                  <p class="text-truncate">{{product?.customer?.data?.email_primary}}</p>

                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="leadSrouce">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8"> Lead Source
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                  <p class="text-truncate">{{product?.customer?.data?.source?.data?.name}}</p>

                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="quoteServices">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8"> Services
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                  <p class="text-truncate">{{product?.service?.data?.title}}</p>

                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="languagePair">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20"> Language Pair
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                  <span class="price text-truncate">
                    <span *ngFor="let res of product.lang_array">
                      <p>{{res}}</p>
                    </span>
                  </span>
                </mat-cell>
              </ng-container>



              <ng-container matColumnDef="quoteStartDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16"> Start Date
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
                  <p class="text-truncate">{{product?.created_at?.date | date: 'yyyy-MM-dd'}}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="quoteStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> Status
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                  <p class="text-truncate">{{product?.projectstage?.data?.pm_status}}</p>
                </mat-cell>
              </ng-container>


              <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

              <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
              </mat-row>
            </table>

            <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
              [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
              [pageSizeOptions]="[20,30,40,50,60]" (page)='paginateData($event)'>
            </mat-paginator>
          </div>
        </div>
      </div>

    </div>
  </div>



</div>