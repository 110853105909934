import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { navigation } from 'app/navigation/navigation';
import { SharedService } from 'app/main/shared.service';
import * as moment from 'moment';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DataService } from 'app/main/Services/data.service';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    fQuery: string = '';
    url_string_length:number;
    notArchive: string = '';//Unavailable,Closed
    selectedLanguage: any;
    userStatusOptions: any[];
    search_response:any=[];
    notification_count:number=0;
    notification_response:any=[];
    profile_pic:string;
    user_name:string;
    today_date:Date;
    current_date:any;
    url_string:string;
    route: string;
    welcome:string='/welcome';
    filterSearchQuery:string='';
    whatsappTotalCount :number = 0;
    smsTotalCount :number = 0;
    // pms
    Topsearch:any={
        data:''
    }

    // Hr
    topSearchHr:any={
        data : ''
    }
    search_response_hr:any=[];
    hrQuery: string = '';
    filterHrSearchQuery:string='';
    // marketing
    topSearchMarketing:any={
        data : ''
    }
    search_response_marketing:any=[];
    marketingQuery: string = '';
    filterMarketingSearchQuery:string='';
    message:string;
    // user_group_name:string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private router: Router,
        private projectsService: ProjectsService,
        private toastr: ToastrService,
        location: Location,
        private data: DataService
    )
    {

        router.events.subscribe(val => {
            if (location.path() != '') {
              this.route = location.path();
            } 
          });   
     
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon' : 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon' : 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon' : 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'tr',
                title: 'Turkish',
                flag : 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.data.currentMessage.subscribe((response) => {
             this.message = response;
            if(this.message === 'default message')
            {
            
            this.whatsappNotification();
            this.smsNotification();
           }
            else if(this.message === 'whatsapp')
            {
                this.whatsappNotification();
            }else if(this.message === 'sms')
            {
                this.smsNotification();
            }
        });
      
       
        const url= this.router.url;
        let x = url.split('/');
        this.url_string=x[1];
        this.today_date= new Date();
        this.getUnreadNotifications();
        // this.whatsappNotification();
        // this.smsNotification();
        this.current_date= moment(this.today_date.toISOString(), 'YYYY-MM-DD HH:mm:ss.SSSS');
          this.profile_pic=SharedService.get('profile_pic');
         this.user_name=SharedService.get('username');
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...

    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

   
    logout() {
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('login');
        // localStorage.removeItem('token');
        localStorage.clear();
        this.clearLocalStorage();
        this.router.navigate(['/auth/login']);
   
    }

    clearLocalStorage(){
        localStorage.removeItem('pms_freelancer_Filter');
        localStorage.removeItem('pms_freelancer_native_filter');
        localStorage.removeItem('pms_freelancer_specialization_filter');
        localStorage.removeItem('pms_freelancer_source_lang_filter');
        localStorage.removeItem('pms_freelancer_target_lang_filter');
        localStorage.removeItem('pms_freelancer_service_filter');
        localStorage.removeItem('pms_freelancer_search_filter');
        localStorage.removeItem('pms_freelancer_days_available_filter');
        localStorage.removeItem('pms_freelancer_category_level_filter');
        localStorage.removeItem('pms_freelancer_country_filter');
        localStorage.removeItem('pms_freelancer_city_filter');
        localStorage.removeItem('pms_freelancer_startdate_filter');
        localStorage.removeItem('pms_freelancer_enddate_filter');
        localStorage.removeItem('pms_freelancer_pagination');
    }

    MyProfile(){
        window.location.href='/admin-profile';
  
    }

    // PMS top search start

    onFilterPms(evt,type){
        if(evt==''){
            this.Topsearch.data='';
            this.search_response=[];
            return;
        }
        if(evt.length>3){
            this.fQuery = '';    
            // project search filter
            if ((type == 'search' && evt != '' && evt !== undefined)) {
             this.filterSearchQuery = evt;
             this.fQuery += '&per_page=100&&stage=6&search_text=' + this.filterSearchQuery;
            }
           else if(type == 'search' && evt == ''){
             this.filterSearchQuery = evt;
           }
           else if(this.filterSearchQuery!==''){
                this.fQuery += '&per_page=100&&stage=6&search_text=' + this.filterSearchQuery;
           }  
           let query = this.fQuery;
           //this.getTopPmsSearch(query);
           this.getTopPmsSearchFreelancer(query);
        }else{
            return;
        }
    }


    getTopPmsSearchFreelancer(query:any){
        this.projectsService.getTopHrSearch(query)
            .subscribe((response:any) => {
              this.search_response=[];
                if(response.data.length==0){
                    this.toastr.error('No data available!!');
                    return;
                }else{
                  this.search_response = response.data;
                }
          });
    }


    getTopPmsSearch(query:any){
        this.projectsService.getTopPmsSearch(query)
            .subscribe((response:any) => {
              this.search_response=[];
                if(response.data.length==0){
                    this.toastr.error('No data available!!');
                    return;
                }else{
                  this.search_response=response.data;
                }
          }, (error) => {
              this.toastr.error(error);
          });
    }

    onSelectSearch(res:any,type){
        if(type === 'pms'){
            this.Topsearch.data='';
            if(res.option.value!=null){
                //this.router.navigate(['/pms/project/details/'+ res.option.value]);
                window.location.href = '/pms/freelancer/details/'+ res.option.value;
            }
            else{
                this.toastr.error('Project Id is invalid');
                return;
            }
        }else if(type === 'hr')
        {
            this.topSearchHr.data='';
            if(res.option.value!=null){
            // this.router.navigate(['/hr/translator/review/' + res.option.value]);
                window.location.href = '/hr/translator/review/' + res.option.value;
            }else{
                this.toastr.error('Translator is invalid');
                return;
            }
        }else if(type === 'marketing'){
            this.topSearchMarketing.data='';
            if(res.option.value!=null){
                window.location.href = '/marketing/freelancer/review/' + res.option.value;
            }else{
                this.toastr.error('Freelancer is invalid');
                return;
            }
        }
}

    // PMS top search end

      // Hr top search start

      onFilterTranslator(evt,type){
        if(evt==''){
            this.topSearchHr.data='';
            this.search_response_hr=[];
            return;
        }
        if(evt.length>3){
            this.hrQuery = '';    
            // project search filter
            if ((type == 'search' && evt != '' && evt !== undefined)) {
             this.filterHrSearchQuery = evt;
             this.hrQuery += '&per_page=100&search_text=' + this.filterHrSearchQuery;
             //+ '&not_archive=' + this.notArchive;
            }
           else if(type == 'search' && evt == ''){
             this.filterHrSearchQuery = evt;
           }
           else if(this.filterHrSearchQuery!==''){
             this.hrQuery += '&per_page=100&search_text=' + this.filterHrSearchQuery;
             //+ '&not_archive=' + this.notArchive;
           }  
           let query = this.hrQuery;
            this.getTopHrSearch(query);
        }else{
            return;
        }
    }

    onFilterMarketingTranslator(evt,type){
        if(evt==''){
            this.topSearchMarketing.data='';
            this.search_response_marketing=[];
            return;
        }
        if(evt.length>3){
            this.marketingQuery = '';    
            // project search filter
            if ((type == 'search' && evt != '' && evt !== undefined)) {
             this.filterMarketingSearchQuery = evt;
             this.marketingQuery += '&per_page=100&search_text=' + this.filterMarketingSearchQuery;
             //+ '&not_archive=' + this.notArchive;
            }
           else if(type == 'search' && evt == ''){
             this.filterMarketingSearchQuery = evt;
           }
           else if(this.filterMarketingSearchQuery!==''){
             this.marketingQuery += '&per_page=100&search_text=' + this.filterMarketingSearchQuery;
             //+ '&not_archive=' + this.notArchive;
           }  
           let query = this.marketingQuery;
            this.getTopMarketingSearch(query);
        }else{
            return;
        }
    }

    getTopHrSearch(query:any){
        this.projectsService.getTopHrSearch(query)
            .subscribe((response:any) => {
              this.search_response_hr=[];
                if(response.data.length==0){
                    this.toastr.error('No data available!!');
                    return;
                }else{
                  this.search_response_hr = response.data;
                }
          });
    }

    getTopMarketingSearch(query:any){
        this.projectsService.getTopHrSearch(query,'marketing')
            .subscribe((response:any) => {
              this.search_response_marketing=[];
                if(response.data.length==0){
                    this.toastr.error('No data available!!');
                    return;
                }else{
                  this.search_response_marketing = response.data;
                }
          });
    }

    // Hr top search end


    getUnreadNotifications(){
        this.projectsService.unreadNotification()
            .subscribe((response:any) => {

                response.data.forEach(element => {
                   
                    if(element.type=='App\\Notifications\\ProjectCreatedNotification'){
                        if(element.data.reference){
                            this.notification_count=this.notification_count + 1;
                            let message = ' New ' + element.data.service_type + 'Request for Language ' + 
                            element.data.source_language +' - ' + element.data.target_language + ' Received';
                            this.notification_response.push(message);
                        }
                    }

                    if(element.type=='App\\Notifications\\ProjectQuoteCreatedNotification'){
                        if(element.data.due_date){
                            this.notification_count=this.notification_count+1;
                            let lang = '';
                                element.data.language_pair.forEach(res => {
                                    lang += res + ', ' ;
                                });
                                lang = lang.slice(0, -2);
                            let message=lang + ' Translation request due on ' + this.dateFormat(element.data.due_date);
                           
                            this.notification_response.push(message);
                        }
                    }

                    if(element.type == 'App\\Notifications\\AssignmentDeclinedNotification'){
                        if(element.data.reference_number){
                            this.notification_count=this.notification_count+1;
                            let message= element.data.reference_number+ ' assignment was declined by '+ element.data.freelance_name;
                            this.notification_response.push(message);
                        }
                    }

                    if(element.type == 'App\\Notifications\\AssignmentAcceptNotification'){
                        if(element.data.reference_number){
                            this.notification_count=this.notification_count+1;
                            let message= element.data.freelance_name+ ' accepted '+ element.data.reference_number +' assignment due on '+ element.data.reference_number;
                            this.notification_response.push(message);
                        }
                    }

                    if(element.type=='App\\Notifications\\ProjectStatusUpdateNotification'){
                        if(element.data.content){
                            this.notification_count=this.notification_count+1;
                            this.notification_response.push(element.data.content);
                        }
                    }
                });
          });
    }



notification_read(){
     let query='';
    this.projectsService.notificationRead(query)
    .subscribe((response) => {
      if (response) {
        this.toastr.success('Notification read successfully!!');
        this.notification_count=0;
        this.getUnreadNotifications();
      }
    });
}

// date format
dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + '-' + month + '-' + dt;
  }


whatsappNotification(): void {
    this.projectsService.getWhatsappCount()
      .subscribe(response => {
        this.whatsappTotalCount = response.count;
      });
  }

  smsNotification(): void {
    this.projectsService.getSmsCount().subscribe((response) => {
        this.smsTotalCount = response.count;
    });
}
}
