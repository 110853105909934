import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendWhatsappMessageComponent } from './popup/send-whatsapp-message/send-whatsapp-message.component';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';
import { DataService } from 'app/main/Services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  heroes=[];
  fromNumber;
  searchText = '';
  BaseNumber: String = '';
  hero;
  public heroForm: FormGroup;
  message:string;
  id: number;
  setIntervalId:any;

  constructor(private projectsService: ProjectsService, private toastr: ToastrService,
  private dialog: MatDialog, private route: ActivatedRoute,
  public formBuilder: FormBuilder, private data: DataService) {
      this.BaseNumber = environment.BaseNumber;
     }

  ngOnInit() {
    this.data.currentMessage.subscribe(message => this.message = message);
    this.id = this.route.snapshot.params['id'];
    if(this.id)
    {
      this.fromNumber = this.id;
    }
    this.getWhatsapps();
    this.heroForm = this.formBuilder.group({
      body: this.formBuilder.control('', [Validators.required]),
    });

    this.setIntervalId = setInterval(() => {
      this.pageRefresh();
    }, 20000)
  }

  newMessage() {
    this.data.changeMessage("whatsapp");
  }

  getWhatsapps(): void {
    this.projectsService.getWhatsapps()
      .subscribe(heroes => {
        this.heroes =[];
        heroes.data.forEach(element => {
            this.heroes.push(element); 
        });
      
        if(this.fromNumber === undefined )
        {
          this.getHero(this.heroes[0].recipient);
        }else{
          this.getHero(this.fromNumber);
        }
        
      });
  }

  hasUserMessage:boolean=true;
  getHero(id) {
    this.fromNumber = id;
    this.hasUserMessage=true;
    this.projectsService.getWhatsappHero(id)
      .subscribe(
        response => {
          let hero = response.wtsp_conversations;
          var herolength= Object.keys(hero).length;
          if(hero.findIndex(data=>!data.sent)===-1){
              this.hasUserMessage=false;
          }
          for (var i = 0; i < herolength; ++i) {
          if(hero[i]['attachment']!=null){
          var attachvar= hero[i]['attachment'];
          var obj = JSON.parse(attachvar);
          hero[i]['attachment'] = obj;  
          }
          }
   
        this.hero = hero;
        let scrollerHeight = this.myScrollContainer.nativeElement.offsetHeight;
        this.chatPS.directiveRef.scrollToTop(scrollerHeight * 100, 100); 
      }
        );
  }


  sendWhatsappto() {
    if(this.heroForm.value.body.length > 250){
      this.toastr.error('Maximum 250 characters');
      return false;
    }
    if (this.heroForm.valid) {
     let obj ={
      recipient: this.fromNumber,
       body: this.heroForm.value.body
     }
    this.projectsService.sendWhatsappto(obj)
      .subscribe(
        (response: any) => {
          this.heroForm.reset({});
           this.getWhatsapps();
        }
      );
    }
  }

  // popup
  sendMessagePopup() {
    const dailogRef = this.dialog.open(SendWhatsappMessageComponent, {
      width: '500px'

    })
    dailogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.getWhatsapps();
      }
    });
  }

  pageRefresh() {
        this.getWhatsapps();
        this.newMessage();
     }

    markAsRead() {
      let obj ={
       recipient: this.fromNumber
      }
     this.projectsService.markAsReadWhatsapp(obj)
       .subscribe(
         (response: any) => {
           this.getWhatsapps();
           this.newMessage();
         }
       );
     }
 
}
