import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  dataSource: any;
  total_records: number = 0;
  paginate: any = {};
  pageIndex: number = 1;
  pageSize: number = 20;
  query: string = '';
  filterName: string = '';
  filterPhone: string = '';
  filterEmail: string = '';
  filterAddress: string = '';
  filterCity: string = '';
  filterState: string = '';
  filterPostal: string = '';
  filterCountry: string = '';
  filterAccountType: string = '';
  filterIndustry: string = '';
  filterAssignedTo: string = '';
  filterCreatedBy: string = '';
  portalUsers: any = [];
  fQuery: string = '';
  Form: FormGroup;
  accountType: any = [];
  industryData: any = [];
  displayedColumns: string[] = ['name', 'email', 'contact', 'type', 'Created'];
  constructor(private projectsService: ProjectsService,
    private toastr: ToastrService, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getPortalUsers();
    this.getAccountType();
    this.getIndustry();
    this.getAccountDetail();
    this.Form = this._formBuilder.group({
      name: [''],
      phone: [''],
      email: [''],
      address: [''],
      city: [''],
      state: [''],
      postal_code: [''],
      country: [''],
      account_type: [''],
      industry: [''],
      created_by: [''],
      assigned_to: [''],
    });
  }

  getAccountDetail() {
    let Query = '?per_page=20'
    this.projectsService.getAccountListing(Query)
      .subscribe((res: any) => {
        if (res) {
          this.dataSource = new MatTableDataSource(res.data);
          this.paginate = res.meta.pagination;
          this.total_records = res.meta.pagination.total;
        }
      });
  }

  paginateData(event) {
    let Query = ''
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
    this.query += Query;
    this.projectsService.getAccountListing(this.query)
      .subscribe((res: any) => {
        if (res) {
          this.dataSource = new MatTableDataSource(res.data);
          this.paginate = res.meta.pagination;
          this.total_records = res.meta.pagination.total;
        }
      });
  }

  onFilter(evt) {
    this.fQuery = '';
    // Name
    if ((evt.name != '' && evt.name !== undefined)) {
      this.filterName = evt.name;

      this.fQuery += '&name=' + this.filterName;
    }
    else if (evt.name == '') {
      this.filterName = evt.name;

    }
    else if (this.filterName !== '') {
      this.fQuery += '&name=' + this.filterName;
    }
    // Phone
    if ((evt.phone != '' && evt.phone !== undefined)) {
      this.filterPhone = evt.phone;

      this.fQuery += '&phone=' + this.filterPhone;
    }
    else if (evt.phone == '') {
      this.filterPhone = evt.phone;

    }
    else if (this.filterPhone !== '') {
      this.fQuery += '&phone=' + this.filterPhone;
    }

    // email
    if ((evt.email != '' && evt.email !== undefined)) {
      this.filterEmail = evt.email;

      this.fQuery += '&email=' + this.filterEmail;
    }
    else if (evt.email == '') {
      this.filterEmail = evt.email;

    }
    else if (this.filterEmail !== '') {
      this.fQuery += '&email=' + this.filterEmail;
    }

    // Address
    if ((evt.address != '' && evt.address !== undefined)) {
      this.filterAddress = evt.address;

      this.fQuery += '&address=' + this.filterAddress;
    }
    else if (evt.address == '') {
      this.filterAddress = evt.address;

    }
    else if (this.filterAddress !== '') {
      this.fQuery += '&address=' + this.filterAddress;
    }

    // City
    if ((evt.city != '' && evt.city !== undefined)) {
      this.filterCity = evt.city;

      this.fQuery += '&city=' + this.filterCity;
    }
    else if (evt.city == '') {
      this.filterCity = evt.city;

    }
    else if (this.filterCity !== '') {
      this.fQuery += '&city=' + this.filterCity;
    }

    // State
    if ((evt.state != '' && evt.state !== undefined)) {
      this.filterState = evt.state;

      this.fQuery += '&state=' + this.filterState;
    }
    else if (evt.state == '') {
      this.filterState = evt.state;

    }
    else if (this.filterState !== '') {
      this.fQuery += '&state=' + this.filterState;
    }

    // postal code
    if ((evt.postal_code != '' && evt.postal_code !== undefined)) {
      this.filterPostal = evt.postal_code;

      this.fQuery += '&postal_code=' + this.filterPostal;
    }
    else if (evt.postal_code == '') {
      this.filterPostal = evt.postal_code;

    }
    else if (this.filterPostal !== '') {
      this.fQuery += '&postal_code=' + this.filterPostal;
    }

    // country
    if ((evt.country != '' && evt.country !== undefined)) {
      this.filterCountry = evt.country;

      this.fQuery += '&country=' + this.filterCountry;
    }
    else if (evt.country == '') {
      this.filterPostal = evt.country;

    }
    else if (this.filterCountry !== '') {
      this.fQuery += '&country=' + this.filterCountry;
    }

    // account type
    if ((evt.account_type != '' && evt.account_type !== undefined)) {
      this.filterAccountType = evt.account_type;
      this.fQuery += '&account_type=' + this.filterAccountType;
    }
    else if (evt.account_type == '') {
      this.filterAccountType = evt.account_type;
    }
    else if (this.filterAccountType !== '') {
      this.fQuery += '&account_type=' + this.filterAccountType;
    }

    // industry
    if ((evt.industry != '' && evt.industry !== undefined)) {
      this.filterIndustry = evt.industry;
      this.fQuery += '&industry_id=' + this.filterIndustry;
    }
    else if (evt.industry == '') {
      this.filterIndustry = evt.industry;
    }
    else if (this.filterIndustry !== '') {
      this.fQuery += '&industry_id=' + this.filterIndustry;
    }

    // created by
    if ((evt.created_by != '' && evt.created_by !== undefined)) {
      this.filterCreatedBy = evt.created_by;
      this.fQuery += '&created_by=' + this.filterCreatedBy;
    }
    else if (evt.created_by == '') {
      this.filterCreatedBy = evt.created_by;
    }
    else if (this.filterCreatedBy !== '') {
      this.fQuery += '&created_by=' + this.filterCreatedBy;
    }


    // assigned to
    if ((evt.assigned_to != '' && evt.assigned_to !== undefined)) {
      this.filterAssignedTo = evt.assigned_to;
      this.fQuery += '&assigned_to=' + this.filterAssignedTo;
    }
    else if (evt.assigned_to == '') {
      this.filterAssignedTo = evt.assigned_to;
    }
    else if (this.filterAssignedTo !== '') {
      this.fQuery += '&assigned_to=' + this.filterAssignedTo;
    }

    let Query = '?per_page=20'
    Query += this.fQuery;

    this.projectsService.getAccountListing(Query)
      .subscribe((res: any) => {
        if (res) {

          this.dataSource = new MatTableDataSource(res.data);
          this.paginate = res.meta.pagination;
          this.total_records = res.meta.pagination.total;
        }
      });
  }

  submit() {
    this.Form.value.email=encodeURIComponent(this.Form.value.email);
    this.onFilter(this.Form.value);
  }

  // get protal users
  getPortalUsers() {
    let Query = 'page=1&per_page=30&'
    this.projectsService.getPortalUsers(Query)
      .subscribe((res: any) => {
        if (res) {
          this.portalUsers = res.data;
        }
      });
  }


  // get Account Type
  getAccountType() {

    this.projectsService.getAccountType()
      .subscribe((res: any) => {
        if (res) {
          this.accountType = res.data;
        }
      });
  }

  // get Industry
  getIndustry() {
    this.projectsService.getIndustry()
      .subscribe((res: any) => {
        if (res) {
          this.industryData = res.data;
        }
      });
  }
}
