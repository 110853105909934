<div class="page-layout w100 p-24 bg-white" fusePerfectScrollbar>
  <div class="container" fxLayout="row wrap" fxLayoutGap="8px">
    <div fxFlex="24">
      <div class="white-box plainBox">
        <div class="tHeader">
          <div fxFlex="50">
            <h2 class="mt-1"><i class="material-icons">filter_list</i> Apply Filter</h2>
          </div>
          <div fxFlex="50" class="text-right">
            <button mat-raised-button color="accent" (click)="clearLocalStorage()" class="mr-8 text-right">Clear</button>
          </div>        
        </div>

        <div class="boxBody">
          <div class="boxBodyInn">
            <!-- <form name="Form" [formGroup]="Form"> -->
              <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                <div fxFlex="79">
                  <mat-form-field  >
                    <mat-label>Search by ID/Name/Email/Phone</mat-label>
                    <input [(ngModel)]="fdata.Search" [ngModelOptions]="{standalone: true}"  matInput (keyup)="onFilter($event.target.value,'search')">
                  </mat-form-field>
                </div>
                <!-- <div fxFlex="20">
                  <button mat-stroked-button color="primary" (click)="submit()" [disabled]="Form.invalid"><i
                      class="material-icons">search</i></button>
                </div> -->
              </div>
            <!-- </form> -->

            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Service provided is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Service" [ngModelOptions]="{standalone: true}" (selectionChange)="onFilter($event,'service')" placeholder="Select Field">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let res of servicesValue" [value]="res.id">
                      {{res.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->

            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Service provided is</b> </div>
              </div> 
              <div fxFlex="69" class="multi_service_filed">
                  <mat-label>Select Field </mat-label>
                  <mat-select [formControl]="sToppings"  (selectionChange)="onFilter($event,'multi_service')" multiple>
                    <mat-select-trigger style="background-color: none;">
                      {{sToppings.value ? sToppings.value[0] : ''}}
                      <span *ngIf="sToppings.value?.length > 1" class="example-additional-selection">
                        (+{{sToppings.value.length - 1}} {{sToppings.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let stopping of serviceList" [value]="stopping">{{stopping}}</mat-option>
                  </mat-select>
              </div>
            </div> -->

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Service provided is</b> </div>
              </div>
              <div fxFlex="69" style="border-bottom: 1px solid #959393;">
                  <mat-select placeholder="Select Field" [formControl]="sToppings"  (selectionChange)="onFilter($event,'multi_service')" multiple>
                <mat-option *ngFor="let stopping of serviceList" [value]="stopping">{{stopping}}</mat-option>
                </mat-select>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Gender</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Gender" [ngModelOptions]="{standalone: true}" placeholder="Select Field" (selectionChange)="onFilter($event,'gender')">
                    <mat-option value="">Select</mat-option>
                    <mat-option value="1">Male</mat-option>
                    <mat-option value="2">Female</mat-option>   
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Tools</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Tools" [ngModelOptions]="{standalone: true}" placeholder="Select Field" (selectionChange)="onFilter($event,'tools')">
                    <mat-option value="">Select</mat-option>
                    <mat-option value="WordFast">WordFast</mat-option>
                    <mat-option value="Trados">Trados</mat-option>
                    <mat-option value="MemoQ">MemoQ</mat-option>
                    <mat-option value="Phraseapp">Phraseapp</mat-option>
                    <mat-option value="Transifex">Transifex</mat-option>
                    <mat-option value="SmartCat">SmartCat</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Timezone</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Timezone" [ngModelOptions]="{standalone: true}" (selectionChange)="onFilter($event,'timezone')" placeholder="Select Field">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let timeData of timezoneData.data" [value]="timeData.zone">{{timeData.country_name}} {{timeData.zone}}  ( {{timeData.gmt_offset}} ) </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Age</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Age" [ngModelOptions]="{standalone: true}" (selectionChange)="onFilter($event,'age')" placeholder="Select Field">
                    <mat-option [value]="''">Select</mat-option>
                    <mat-option *ngFor="let aData of ageData" [value]="aData">{{aData}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>AVT Service</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Avtservice" [ngModelOptions]="{standalone: true}" (selectionChange)="onFilter($event,'avt_service')" placeholder="Select Field">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let sData of skillData.data" [value]="sData.id">{{sData.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Speciality</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.specialization" [ngModelOptions]="{standalone: true}" (selectionChange)="onFilter($event,'speciality')" placeholder="Select Field">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let speciality of specialityData" [value]="speciality.name">
                      {{speciality.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Native Language is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select  [(ngModel)]="fdata.Native" [ngModelOptions]="{standalone: true}"
                  (selectionChange)="onFilter($event,'native_lang')" placeholder="Select Field">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.id">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Source Language is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Source" [ngModelOptions]="{standalone: true}" (selectionChange)="onFilter($event,'s_lang')" placeholder="Select Field">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Target Language is </b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Target" [ngModelOptions]="{standalone: true}" (selectionChange)="onFilter($event,'t_lang')" placeholder="Select Field">
                    <mat-option [value]="''">Select</mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">{{language.lang_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Days Available</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Dayavailabe" [ngModelOptions]="{standalone: true}" placeholder="Select Field" (selectionChange)="onFilter($event,'days_available')">
                    <!-- <mat-option value="">Any Day</mat-option>
                              <mat-option value="">All Day</mat-option>
                              <mat-option value="">Weekend Only</mat-option>
                              <mat-option value="">Part Time</mat-option> -->
                    <mat-option value="">Full Time</mat-option>
                    <mat-option value="0">Sunday</mat-option>
                    <mat-option value="1">Monday</mat-option>
                    <mat-option value="2">Tuesday</mat-option>
                    <mat-option value="3">Wednesday</mat-option>
                    <mat-option value="4">Thursday</mat-option>
                    <mat-option value="5">Friday</mat-option>
                    <mat-option value="6">Saturday</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>



            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Category is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'category_type')">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let res of arrType" [value]="res.value">
                      {{res.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
<!-- 
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Category Level is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Categorylevel" [ngModelOptions]="{standalone: true}" placeholder="Select Field" (selectionChange)="onFilter($event,'category_level')">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option value="Leader">Leader</mat-option>
                    <mat-option value="Probation">Probation</mat-option>
                    <mat-option value="Junior"> Junior</mat-option>
                    <mat-option value="Closed">Closed</mat-option>
                    <mat-option value="Blacklisted"> Blacklisted</mat-option>
                    <mat-option value="Unavailable">Unavailable</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Category Level is</b> </div>
              </div>
              <div fxFlex="69" style="border-bottom: 1px solid #959393;">
              <mat-select placeholder="Select Field" [formControl]="toppings"  (selectionChange)="onFilter($event,'multi_category_level')" multiple>
                <mat-option *ngFor="let filters of toppingList" [value]="filters">{{filters}}</mat-option>
              </mat-select>
            </div>
          </div>
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>AVT Status is</b> </div>
              </div>
              <div fxFlex="69" style="border-bottom: 1px solid #959393;">
              <mat-select placeholder="Select Field" [formControl]="toppings"  (selectionChange)="onFilter($event,'avt_status_level')" multiple>
                <mat-option *ngFor="let filters of toppingList" [value]="filters">{{filters}}</mat-option>
              </mat-select>
            </div>
          </div>

            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Category Level is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field appearance="fill">
                  <mat-label>Select Field </mat-label>
                  <mat-select [formControl]="toppings"  (selectionChange)="onFilter($event,'multi_category_level')" multiple>
                    <mat-select-trigger>
                      {{toppings.value ? toppings.value[0] : ''}}
                      <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                        (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->

            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Status is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select placeholder="Select Field">
                    <mat-option value="option1">Any</mat-option>
                    <mat-option value="option2">Unavailable</mat-option>
                    <mat-option value="option3">Closed</mat-option>
                    <mat-option value="option4">XEED</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->

            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Payment Method is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select placeholder="Select Field">
                    <mat-option value="option1">Any</mat-option>
                    <mat-option value="option1">Regular/Upfront</mat-option>
                    <mat-option value="option2">NET15</mat-option>
                    <mat-option value="option3">NET45</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Location</b> </div>
              </div>
              <div fxFlex="30">
                <mat-form-field  >
                  <input [(ngModel)]="fdata.City" [ngModelOptions]="{standalone: true}" matInput (keyup)="onFilter($event.target.value,'city')">
                </mat-form-field>
              </div>
              <div fxFlex="39">
                <!-- <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Country" [ngModelOptions]="{standalone: true}" placeholder="Country" (selectionChange)="onFilter($event,'country')">
                    <mat-option value="">All</mat-option>
                    <mat-option value="USA">USA</mat-option>
                    <mat-option value="China">China</mat-option>
                    <mat-option value="Germany">Germany</mat-option>
                    <mat-option value="France">France</mat-option>
                  </mat-select>
                </mat-form-field> -->
                <mat-form-field  >
                  <mat-select [(ngModel)]="fdata.Country" [ngModelOptions]="{standalone: true}" placeholder="Country" (selectionChange)="onFilter($event,'country')">
                    <mat-option value="">All</mat-option>
                    <mat-option *ngFor="let country of countryData" [value]="country.name">{{country.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Customer Added</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <input [(ngModel)]="fdata.Startdate" [ngModelOptions]="{standalone: true}"  matInput (click)="dp2.open()" [matDatepicker]="dp2"
                    (dateChange)="onFilter($event,'start_date')">
                  <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                  <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Rate per word</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <input [(ngModel)]="fdata.Rate" [ngModelOptions]="{standalone: true}" matInput
                    (keyup)="onFilter($event.target.value,'rate')">
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Select</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <input [(ngModel)]="fdata.Enddate" [ngModelOptions]="{standalone: true}"  matInput (click)="dp1.open()" [matDatepicker]="dp1" (dateChange)="onFilter($event,'end_date')">
                  <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                  <mat-datepicker #dp1 disabled="false"></mat-datepicker>
               </mat-form-field>
              </div>
            </div>

            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"></div>
              </div>
              <div fxFlex="69" class="text-right">
                <button mat-raised-button color="accent" (click)="clearLocalStorage()" class="mr-8 text-right">Clear</button>
              </div>
            </div> -->

            <!-- <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Freelancer Source</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field  >
                  <mat-select placeholder="Select Field">
                    <mat-option value="option1">Any</mat-option>
                    <mat-option value="option1">DT Database</mat-option>
                    <mat-option value="option2">Proz.com</mat-option>
                    <mat-option value="option3">TranslatorsCafe.com</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="72">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap">
            <div fxFlex>
              <h2>Browse Freelancer: <span class="txt-blue">Translator</span></h2>
            </div>
             
            <div fxFlex class="text-right">
              <!-- <button mat-raised-button color="accent" class="mr-8 text-right" 
                      (click)="openDialogAllTranslator()"><i class="material-icons">
                          group
                      </i>Send Email To All</button> -->

              <button mat-raised-button color="accent" [disabled]="login_enable" class="mr-8 text-right" 
                      (click)="openDialogAddTranslator()"><i class="material-icons">
                          group
                      </i>Send Email</button>
              Total <b>{{total_freelancer}}</b> Records Available
            </div>
          </div>
          <div class="filterTag pt-12">
            
            <mat-chip-list #chipList aria-label="QuotesChip selection">
              <mat-chip *ngFor="let QuotesChip of fChipsArray" [selectable]="selectable" [removable]="removable">
                {{QuotesChip}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
  
        <div class="boxBody">
          <div class="boxInn">
          <div *ngIf="paginate?.total != 0">
            <table mat-table [dataSource]="dataSource"  class="table" (matSortChange)="sortUser($event)">
              <ng-container matColumnDef="ref" >
                <mat-header-cell *matHeaderCellDef fxFlex="5">
                    <mat-checkbox [(ngModel)]="bulk_selected_progress" 
                    (change)="selectBulkProgress($event)"></mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="5">
                    <mat-checkbox [(ngModel)]="product.check"
                    (change)="selectMailProgress($event,product)" >
                </mat-checkbox>
                </mat-cell>
            </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20"> Name
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                  <div fxLayout="column">
                    <div fxFlex>
                      <button mat-button class="btn-link" routerLink="/pms/freelancer/details/{{product.id}}">
                        <p class="text-truncate">{{product.first_name}} {{product.last_name}}</p>
                      </button>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="frlcrEmail">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="22"> Email
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="22">
                  <p class="text-truncate">{{product.email}}</p>
                </mat-cell>
              </ng-container>

              <!-- Weight Column -->
              <!-- <ng-container matColumnDef="frlcrCategory">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8"> Category
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                  <p class="text-truncate">{{product.type}}</p>
                </mat-cell>
              </ng-container> -->


              <!-- Symbol Column -->
              <ng-container matColumnDef="native_language">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="15"> Native Language
                </mat-header-cell>
                <mat-cell class="native-cell-truncate" *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="15">
                  <span *ngIf="product.vendornativelanguage!=null" class="text-truncate">
                    {{product.vendornativelanguage.data.lang_name}}
                  </span>
                  <span *ngIf="product.vendornativelanguage==null">
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Symbol Column -->

              <!-- <ng-container matColumnDef="frlcrLocation">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="4"> Location
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="4">
                </mat-cell>
              </ng-container> -->
              <!-- Symbol Column -->
<!-- 
              <ng-container matColumnDef="frlcrRate">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="4"> Rate
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="4">
                </mat-cell>
              </ng-container> -->
              <!-- Symbol Column -->
              <ng-container matColumnDef="language">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="25"> Language Pair
                </mat-header-cell>
                <mat-cell class="language-cell-truncate" *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="25">
                  <span class="price text-truncate">
                    <span *ngFor="let res of product.lang_array">
                      <p class="text-truncate">{{res}}</p>
                    </span>
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="category_level">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> Category Level
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                  <span class="text-truncate">
                    {{product.category_level}}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Symbol Column -->
              <!-- <ng-container matColumnDef="frlcrDaysAvailable">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="4"> Days Available
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="4">
                </mat-cell>
              </ng-container> -->
              <!-- Symbol Column -->
              <!-- <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="12">Status
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="12">
                  <span class="bdg ylbg" *ngIf="product.stage!=null">
                    {{product.stage.data.name}}
                  </span>
                  <span class="bdg ylbg" *ngIf="product.stage==null">
                  </span>
                </mat-cell>
              </ng-container> -->
              <!-- Symbol Column -->

              <mat-header-row *matHeaderRowDef="ApprovaldisplayedColumns; sticky:true"></mat-header-row>
              <mat-row *matRowDef="let product; columns: ApprovaldisplayedColumns;" class="product fList">
              </mat-row>
            </table>
            <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
              [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
              [pageSizeOptions]="[20,30,40,50,60]" (page)='paginateData($event)'>
            </mat-paginator>
          </div>
          <div *ngIf="paginate?.total==0" class="noDataFound">
            <p> No Data available!!</p>
           </div>
          
        </div>
        </div>
      </div>
    </div>
  </div>
</div>