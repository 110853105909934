<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <button
            color="accent"
            (click)="back()"
            matTooltip="Back"
            mat-icon-button
            class="mr-12 mat-back"
        >
            <mat-icon>keyboard_backspace</mat-icon>
        </button>

        <span class="logo-text h1 ">
            Edit Freelancer Approval
        </span>
    </div>

    <div class="content">
        <div class="mat-card mat-employee-form mb-24">
            <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
                <div
                    class="pr-24 pl-24"
                    fxLayout="column"
                    fxFlex="1 0 auto"
                    fxFlex="100"
                >
                    <form
                        class="mt-16"
                        fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="1 0 auto"
                        name="reviewForm"
                        [formGroup]="reviewForm"
                    >
                        <h2>Personal Information</h2>
                        <div
                            fxLayout="column"
                            fxLayout.gt-sm="row"
                            fxLayoutAlign="start"
                            fxLayoutGap="10px"
                        >
                            <mat-form-field fxFlex="80">
                                <mat-label class="font-size-12"
                                    >Full Name</mat-label
                                >
                                <input matInput />
                            </mat-form-field>
                            <mat-form-field fxFlex="80">
                                <mat-label class="font-size-12"
                                    >Email Address</mat-label
                                >
                                <input matInput />
                            </mat-form-field>
                        </div>
                        <div
                            fxLayout="column"
                            fxLayout.gt-sm="row"
                            fxLayoutAlign="start"
                            fxLayoutGap="10px"
                        >
                            <mat-form-field fxFlex="50">
                                <mat-label class="font-size-12"
                                    >Language Pair</mat-label
                                >
                                <input matInput />
                            </mat-form-field>
                        </div>

                        <h2>Specialization</h2>

                        <mat-radio-group
                            class="mt-12"
                            fxFlex="100"
                            fxFlex.gt-sm="25"
                        >
                            <mat-radio-button value="1" class="pr-20"
                                >Admin</mat-radio-button
                            >
                            <mat-radio-button value="2" class="pr-20"
                                >Finance</mat-radio-button
                            >
                            <mat-radio-button value="3" class="pr-20"
                                >Medical</mat-radio-button
                            >
                        </mat-radio-group>

                        <mat-divider class="mt-12"></mat-divider>
                        <div
                            fxLayout="row"
                            fxLayoutAlign.gt-sm="right"
                            fxLayoutAlign="center center"
                            class="mb-8 mt-8 ml-auto"
                        >
                            <button
                                matTooltip="Create User"
                                mat-raised-button
                                type="button"
                                class="green-bg"
                            >
                                <mat-label class="Text_Color"
                                    >Update Approval</mat-label
                                >
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
