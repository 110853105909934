import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { Router, } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { SharedService } from 'app/main/shared.service';
import { BulkEmailComponent } from './bulk-email/bulk-email.component';
import { FormControl } from '@angular/forms';
var FreelancerComponent = /** @class */ (function () {
    function FreelancerComponent(_fuseNavigationService, projectsService, vendorService, httpService, toastr, router, dialog, _formBuilder) {
        this._fuseNavigationService = _fuseNavigationService;
        this.projectsService = projectsService;
        this.vendorService = vendorService;
        this.httpService = httpService;
        this.toastr = toastr;
        this.router = router;
        this.dialog = dialog;
        this._formBuilder = _formBuilder;
        this.fChipsArray = [];
        this.mChipsArray = [];
        this.paginate = {};
        this.pageIndex = 1;
        this.query_approve = '';
        this.filterSpecialization = '';
        this.filter_src_lang = '';
        this.filter_days_available = '';
        this.filter_gender = '';
        this.filter_tools = '';
        this.filter_timezone = '';
        this.filter_age = '';
        this.filter_category_type = '';
        this.filter_category_level = '';
        this.filter_avt_status_level = '';
        this.notArchive = 'Unavailable,Blacklisted,Closed';
        this.filterCountry = '';
        this.filterSearchQuery = '';
        this.ids_progress = [];
        this.all_freelancer_id = [];
        this.bulk_selected_progress = false;
        this.send_email_progress_data = {};
        this.login_enable = true;
        this.filterService = '';
        this.filterAvtService = '';
        this.emailTemplateData = '';
        this.total_freelancer = 0;
        this.filter_target_lang = '';
        this.fQuery = '';
        this.specialityData = [];
        this.skillData = [];
        this.genderData = [];
        this.ageData = [];
        this.toolsData = [];
        this.timezoneData = [];
        this.servicesValue = [];
        this.query_user_sort = '';
        this.languageData = [];
        this.countryData = [];
        this.pageSize = 20;
        this.filterEndDate = '';
        this.filterStartDate = '';
        this.filterCity = '';
        this.filterRate = '';
        this.filter_native_lang = '';
        this.arrType = [];
        this.ApprovaldisplayedColumns = ['ref', 'first_name', 'frlcrEmail', 'native_language', 'language', 'category_level'];
        this.toppings = new FormControl();
        this.sToppings = new FormControl();
        // toppingList: string[] = ['Leader', 'Probation', 'Junior', 'Blacklisted', 'Unavailable','Temp','Closed'];
        this.toppingList = ['Leader', 'Probation', 'Junior', 'Unavailable', 'Temp'];
        this.serviceList = [];
        // Form:any;
        //start
        this.fdata = {
            specialization: '',
            Avtservice: '',
            Source: '',
            Target: '',
            Native: '',
            Service: '',
            Search: '',
            Dayavailabe: '',
            Gender: '',
            Tools: '',
            Timezone: '',
            Age: '',
            Categorylevel: '',
            AvtStatuslevel: '',
            Country: '',
            City: '',
            Rate: '',
            Startdate: '',
            Enddate: ''
        };
    }
    FreelancerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSkillService();
        this.getTimezoneService();
        //this.getGenderService();
        this.getLanguage();
        this.getAllCounty();
        this.getSpeciality();
        this.getData();
        this.getService();
        this.getAllVendorIds();
        this.getFreelancerEmailTemplate();
        this.getAgeService();
        this.httpService.get('/assets/TranslatorType.json').subscribe(function (data) {
            _this.arrType = data; // FILL THE ARRAY WITH DATA.
        }, function (err) {
            _this.toastr.warning(err.message);
        });
    };
    FreelancerComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
            }
        });
    };
    FreelancerComponent.prototype.getAllCounty = function () {
        var _this = this;
        this.vendorService.getAllCounty()
            .subscribe(function (res) {
            if (res) {
                _this.countryData = res;
            }
        });
    };
    FreelancerComponent.prototype.getAllVendorIds = function () {
        var _this = this;
        this.vendorService.getAllTranslators()
            .subscribe(function (res) {
            res.data.forEach(function (elem) {
                _this.all_freelancer_id.push(elem.id);
            });
        });
    };
    FreelancerComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                res.forEach(function (element) {
                    element.name = element.name.charAt(0).toUpperCase() + element.name.slice(1);
                    ;
                });
                _this.specialityData = res;
            }
        });
    };
    FreelancerComponent.prototype.getSkillService = function () {
        var _this = this;
        this.vendorService.getSkillService()
            .subscribe(function (res) {
            if (res) {
                _this.skillData = res;
            }
        });
    };
    FreelancerComponent.prototype.getAgeService = function () {
        var lowEnd = 1;
        var highEnd = 100;
        for (var i = lowEnd; i <= highEnd; i++) {
            this.ageData.push(i);
        }
    };
    // getGenderService(){
    //   let text = '{"data":['+'{"id":"1","gen":"Male" },' + '{"id":"2","gen":"Female" }]}';
    //   this.genderData.push(text);
    //   console.log(this.genderData);
    // }
    FreelancerComponent.prototype.getTimezoneService = function () {
        var _this = this;
        this.vendorService.getAllTimezone()
            .subscribe(function (res) {
            if (res) {
                _this.timezoneData = res;
            }
        });
    };
    FreelancerComponent.prototype.getService = function () {
        var _this = this;
        this.vendorService.getService()
            .subscribe(function (res) {
            if (res) {
                _this.servicesValue = res.data;
                res.data.forEach(function (elem) {
                    _this.serviceList.push(elem.name);
                });
            }
        });
    };
    FreelancerComponent.prototype.getData = function () {
        var _this = this;
        var query = '';
        // start
        var pms_freelancer_search_filter = localStorage.getItem('pms_freelancer_search_filter');
        var search_filter = JSON.parse(pms_freelancer_search_filter);
        if (search_filter !== '' || search_filter !== null) {
            this.fdata.Search = search_filter;
        }
        var pms_freelancer_native_filter = localStorage.getItem('pms_freelancer_native_filter');
        var nativeFilter = JSON.parse(pms_freelancer_native_filter);
        if (nativeFilter !== '' || nativeFilter !== null) {
            this.fdata.Native = nativeFilter;
        }
        var pms_freelancer_source_lang_filter = localStorage.getItem('pms_freelancer_source_lang_filter');
        var source_langFilter = JSON.parse(pms_freelancer_source_lang_filter);
        if (source_langFilter !== '' || source_langFilter !== null) {
            this.fdata.Source = source_langFilter;
        }
        var pms_freelancer_target_lang_filter = localStorage.getItem('pms_freelancer_target_lang_filter');
        var target_langFilter = JSON.parse(pms_freelancer_target_lang_filter);
        if (target_langFilter !== '' || target_langFilter !== null) {
            this.fdata.Target = target_langFilter;
        }
        var pms_freelancer_specialization_filter = localStorage.getItem('pms_freelancer_specialization_filter');
        var specializationFilter = JSON.parse(pms_freelancer_specialization_filter);
        if (specializationFilter !== '' || specializationFilter !== null) {
            this.fdata.specialization = specializationFilter;
        }
        var pms_freelancer_service_filter = localStorage.getItem('pms_freelancer_service_filter');
        var serviceFilter = JSON.parse(pms_freelancer_service_filter);
        if (serviceFilter !== '' || serviceFilter !== null) {
            this.fdata.Service = serviceFilter;
        }
        var pms_freelancer_days_available_filter = localStorage.getItem('pms_freelancer_days_available_filter');
        var days_available_filter = JSON.parse(pms_freelancer_days_available_filter);
        if (days_available_filter !== '' || days_available_filter !== null) {
            this.fdata.Dayavailabe = days_available_filter;
        }
        var pms_freelancer_gender_filter = localStorage.getItem('pms_freelancer_gender_filter');
        var gender_filter = JSON.parse(pms_freelancer_gender_filter);
        if (gender_filter !== '' || gender_filter !== null) {
            this.fdata.Gender = gender_filter;
        }
        var pms_freelancer_tools_filter = localStorage.getItem('pms_freelancer_tools_filter');
        var tools_filter = JSON.parse(pms_freelancer_tools_filter);
        if (tools_filter !== '' || tools_filter !== null) {
            this.fdata.Tools = tools_filter;
        }
        var pms_freelancer_timezone_filter = localStorage.getItem('pms_freelancer_timezone_filter');
        var timezone_filter = JSON.parse(pms_freelancer_timezone_filter);
        if (timezone_filter !== '' || timezone_filter !== null) {
            this.fdata.Timezone = timezone_filter;
        }
        var pms_freelancer_age_filter = localStorage.getItem('pms_freelancer_age_filter');
        var age_filter = JSON.parse(pms_freelancer_age_filter);
        if (age_filter !== '' || age_filter !== null) {
            this.fdata.Age = age_filter;
        }
        var pms_freelancer_category_level_filter = localStorage.getItem('pms_freelancer_category_level_filter');
        var category_level_filter = JSON.parse(pms_freelancer_category_level_filter);
        if (category_level_filter !== '' || category_level_filter !== null) {
            this.fdata.Categorylevel = category_level_filter;
        }
        var pms_freelancer_country_filter = localStorage.getItem('pms_freelancer_country_filter');
        var country_filter = JSON.parse(pms_freelancer_country_filter);
        if (country_filter !== '' || country_filter !== null) {
            this.fdata.Country = country_filter;
        }
        var pms_freelancer_city_filter = localStorage.getItem('pms_freelancer_city_filter');
        var City_filter = JSON.parse(pms_freelancer_city_filter);
        if (City_filter !== '' || City_filter !== null) {
            this.fdata.City = City_filter;
        }
        var pms_freelancer_rate_filter = localStorage.getItem('pms_freelancer_rate_filter');
        var Rate_filter = JSON.parse(pms_freelancer_rate_filter);
        if (Rate_filter !== '' || Rate_filter !== null) {
            this.fdata.Rate = Rate_filter;
        }
        var pms_freelancer_startdate_filter = localStorage.getItem('pms_freelancer_startdate_filter');
        var Startdate_filter = JSON.parse(pms_freelancer_startdate_filter);
        if (Startdate_filter !== '' || Startdate_filter !== null) {
            this.fdata.Startdate = Startdate_filter;
        }
        var pms_freelancer_enddate_filter = localStorage.getItem('pms_freelancer_enddate_filter');
        var Enddate_filter = JSON.parse(pms_freelancer_enddate_filter);
        if (Enddate_filter !== '' || Enddate_filter !== null) {
            this.fdata.Enddate = Enddate_filter;
        }
        var pms_freelancer_avt_service_filter = localStorage.getItem('pms_freelancer_avt_service_filter');
        var AVT_filter = JSON.parse(pms_freelancer_avt_service_filter);
        if (AVT_filter !== '' || AVT_filter !== null) {
            this.fdata.Avtservice = AVT_filter;
        }
        var pms_freelancer_Filter = localStorage.getItem('pms_freelancer_Filter');
        var filterObject = JSON.parse(pms_freelancer_Filter);
        var pmsfreelancerPagination = localStorage.getItem('pms_freelancer_pagination');
        var pmsfreelancerObject = JSON.parse(pmsfreelancerPagination);
        if (pms_freelancer_Filter != null && filterObject.filter) {
            query = filterObject.filter;
        }
        else if (pmsfreelancerPagination !== null && pmsfreelancerObject.table_name == 'pmsfreelancePagination') {
            query = '?page=' + pmsfreelancerObject.page + '&per_page=' + pmsfreelancerObject.per_page + '&not_archive=' + this.notArchive;
        }
        else {
            query = '?page=1&per_page=10&not_archive=' + this.notArchive;
        }
        // end
        this.vendorService.getAllTranslator(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource1 = response;
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -2);
                });
                response.data.forEach(function (elem) {
                    if (elem.vendortranslationlangs.data.length > 0) {
                        var lang_1 = '';
                        var lang_array_1 = [];
                        var translation_rate_value1_1 = '';
                        elem.vendortranslationlangs.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_1 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_1 += res.translation_rate;
                            }
                            lang_1 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_1.push(lang_1);
                        });
                        elem.check = false;
                        if (lang_array_1.length > 0) {
                            var test = void 0;
                            test = lang_array_1[lang_array_1.length - 1];
                            lang_array_1[lang_array_1.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_1;
                        response.data.lang_array = lang_array_1;
                    }
                    else if (elem.vendorSubtitler.data.length > 0) {
                        var lang_2 = '';
                        var lang_array_2 = [];
                        var translation_rate_value1_2 = '';
                        elem.vendorSubtitler.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_2 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_2 += res.translation_rate;
                            }
                            lang_2 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_2.push(lang_2);
                        });
                        elem.check = false;
                        if (lang_array_2.length > 0) {
                            var test = void 0;
                            test = lang_array_2[lang_array_2.length - 1];
                            lang_array_2[lang_array_2.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_2;
                        response.data.lang_array = lang_array_2;
                    }
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.total_freelancer = response.meta.pagination.total;
            }
        });
    };
    //sorting
    FreelancerComponent.prototype.sortUser = function (evt) {
        var _this = this;
        if (evt.direction == '') {
            evt.direction = 'asc';
        }
        this.query_user_sort = '?page=' +
            this.pageIndex + '&per_page=' + this.pageSize + '&sort=' + evt.active + '&order=' + evt.direction;
        this.vendorService.getAllTranslator(this.query_user_sort)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -2);
                });
                response.data.forEach(function (elem) {
                    if (elem.vendortranslationlangs.data.length > 0) {
                        var lang_3 = '';
                        var lang_array_3 = [];
                        var translation_rate_value1_3 = '';
                        elem.vendortranslationlangs.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_3 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_3 += res.translation_rate;
                            }
                            lang_3 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_3.push(lang_3);
                        });
                        elem.check = false;
                        if (lang_array_3.length > 0) {
                            var test = void 0;
                            test = lang_array_3[lang_array_3.length - 1];
                            lang_array_3[lang_array_3.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_3;
                        response.data.lang_array = lang_array_3;
                    }
                    else if (elem.vendorSubtitler.data.length > 0) {
                        var lang_4 = '';
                        var lang_array_4 = [];
                        var translation_rate_value1_4 = '';
                        elem.vendorSubtitler.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_4 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_4 += res.translation_rate;
                            }
                            lang_4 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_4.push(lang_4);
                        });
                        elem.check = false;
                        if (lang_array_4.length > 0) {
                            var test = void 0;
                            test = lang_array_4[lang_array_4.length - 1];
                            lang_array_4[lang_array_4.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_4;
                        response.data.lang_array = lang_array_4;
                    }
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    FreelancerComponent.prototype.paginateData = function (event) {
        var _this = this;
        var aQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        //start
        this.query_approve = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        var pms_freelancer_pagination = { table_name: 'pmsfreelancePagination', page: this.pageIndex, per_page: this.pageSize };
        SharedService.set(SharedService.pms_freelancer_pagination, JSON.stringify(pms_freelancer_pagination));
        //end
        if (this.filterSpecialization !== '') {
            aQuery += '&specialization=' + this.filterSpecialization;
            var Specialization = this.filterSpecialization;
            SharedService.set(SharedService.pms_freelancer_specialization_filter, JSON.stringify(Specialization));
        }
        else if (this.fdata.specialization) {
            aQuery += '&specialization=' + this.fdata.specialization;
        }
        if (this.filter_src_lang !== '') {
            aQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.pms_freelancer_source_lang_filter, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source) {
            aQuery += '&source_lang=' + this.fdata.Source;
        }
        if (this.filter_target_lang !== '') {
            aQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.pms_freelancer_target_lang_filter, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target) {
            aQuery += '&target_lang=' + this.fdata.Target;
        }
        if (this.filter_native_lang !== null && this.filter_native_lang !== '') {
            aQuery += '&native_lang=' + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.pms_freelancer_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.fdata.Native) {
            aQuery += '&native_lang=' + this.fdata.Native;
        }
        if (this.filterSearchQuery !== '') {
            aQuery += '&search_query=' + this.filterSearchQuery;
            var filterSearchQuery = this.filterSearchQuery;
            SharedService.set(SharedService.pms_freelancer_search_filter, JSON.stringify(filterSearchQuery));
        }
        else if (this.fdata.Search) {
            aQuery += '&search_query=' + this.fdata.Search;
        }
        if (this.filterService !== null && this.filterService !== '') {
            aQuery += '&multi_service=' + this.filterService;
            var filterService = this.filterService;
            SharedService.set(SharedService.pms_freelancer_service_filter, JSON.stringify(filterService));
        }
        else if (this.fdata.Service) {
            aQuery += '&multi_service=' + this.fdata.Service;
        }
        if (this.filterAvtService !== null && this.filterAvtService !== '') {
            aQuery += '&avt_service=' + this.filterAvtService;
            var filterAvtService = this.filterAvtService;
            SharedService.set(SharedService.pms_freelancer_avt_service_filter, JSON.stringify(filterAvtService));
        }
        else if (this.fdata.Avtservice) {
            aQuery += '&avt_service=' + this.fdata.Avtservice;
        }
        if (this.filter_days_available !== null && this.filter_days_available !== '') {
            aQuery += '&days_available=' + this.filter_days_available;
            var filter_days_available = this.filter_days_available;
            SharedService.set(SharedService.pms_freelancer_days_available_filter, JSON.stringify(filter_days_available));
        }
        else if (this.fdata.Dayavailabe) {
            aQuery += '&days_available=' + this.fdata.Dayavailabe;
        }
        if (this.filter_gender !== null && this.filter_gender !== '') {
            aQuery += '&gender=' + this.filter_gender;
            var filter_gender = this.filter_gender;
            SharedService.set(SharedService.pms_freelancer_gender_filter, JSON.stringify(filter_gender));
        }
        else if (this.fdata.Gender) {
            aQuery += '&gender=' + this.fdata.Gender;
        }
        if (this.filter_tools !== null && this.filter_tools !== '') {
            aQuery += '&tools=' + this.filter_tools;
            var filter_tools = this.filter_tools;
            SharedService.set(SharedService.pms_freelancer_gender_filter, JSON.stringify(filter_tools));
        }
        else if (this.fdata.Tools) {
            aQuery += '&tools=' + this.fdata.Tools;
        }
        if (this.filter_timezone !== null && this.filter_timezone !== '') {
            aQuery += '&timezone=' + this.filter_timezone;
            var filter_timezone = this.filter_timezone;
            SharedService.set(SharedService.pms_freelancer_gender_filter, JSON.stringify(filter_timezone));
        }
        else if (this.fdata.Timezone) {
            aQuery += '&timezone=' + this.fdata.Timezone;
        }
        if (this.filter_age !== null && this.filter_age !== '') {
            aQuery += '&age=' + this.filter_age;
            var filter_age = this.filter_age;
            SharedService.set(SharedService.pms_freelancer_age_filter, JSON.stringify(filter_age));
        }
        else if (this.fdata.Age) {
            aQuery += '&age=' + this.fdata.Age;
        }
        if (this.filter_category_level !== null && this.filter_category_level !== '') {
            aQuery += '&multi_category_level=' + this.filter_category_level;
            var filter_category_level = this.filter_category_level;
            SharedService.set(SharedService.pms_freelancer_category_level_filter, JSON.stringify(filter_category_level));
        }
        else if (this.fdata.Categorylevel) {
            aQuery += '&multi_category_level=' + this.fdata.Categorylevel;
        }
        if (this.filter_avt_status_level !== null && this.filter_avt_status_level !== '') {
            aQuery += '&avt_status_level=' + this.filter_avt_status_level;
            var filter_avt_status_level = this.filter_avt_status_level;
            SharedService.set(SharedService.pms_freelancer_category_level_filter, JSON.stringify(filter_avt_status_level));
        }
        else if (this.fdata.AvtStatuslevel) {
            aQuery += '&avt_status_level=' + this.fdata.AvtStatuslevel;
        }
        if (this.filterCity !== '') {
            aQuery += '&city=' + this.filterCity;
            var filterCity = this.filterCity;
            SharedService.set(SharedService.pms_freelancer_city_filter, JSON.stringify(filterCity));
        }
        else if (this.fdata.Search) {
            aQuery += '&City=' + this.fdata.City;
        }
        if (this.filterRate !== '') {
            aQuery += '&rate_per_word=' + this.filterRate;
            var filterRate = this.filterRate;
            SharedService.set(SharedService.pms_freelancer_rate_filter, JSON.stringify(filterRate));
        }
        else if (this.fdata.Search) {
            aQuery += '&rate_per_word=' + this.fdata.Rate;
        }
        if (this.filterCountry !== null && this.filterCountry !== '') {
            aQuery += '&country=' + this.filterCountry;
            var filterCountry = this.filterCountry;
            SharedService.set(SharedService.pms_freelancer_country_filter, JSON.stringify(filterCountry));
        }
        else if (this.fdata.Search) {
            aQuery += '&country=' + this.fdata.Country;
        }
        if (this.filterStartDate !== null && this.filterStartDate !== '') {
            aQuery += '&start_date=' + this.filterStartDate;
            var filterStartDate = this.filterStartDate;
            SharedService.set(SharedService.pms_freelancer_startdate_filter, JSON.stringify(filterStartDate));
        }
        else if (this.fdata.Dayavailabe) {
            aQuery += '&start_date=' + this.fdata.Startdate;
        }
        if (this.filterEndDate !== null && this.filterEndDate !== '') {
            aQuery += '&end_date=' + this.filterEndDate;
            var filterEndDate = this.filterEndDate;
            SharedService.set(SharedService.pms_freelancer_enddate_filter, JSON.stringify(filterEndDate));
        }
        else if (this.fdata.Search) {
            aQuery += '&end_date=' + this.fdata.Enddate;
        }
        this.query_approve += aQuery + '&not_archive=' + this.notArchive;
        //start
        var pms_freelancer_Filter = { table_name: 'pms_freelancer_Filter', filter: this.query_approve };
        SharedService.set(SharedService.pms_freelancer_Filter, JSON.stringify(pms_freelancer_Filter));
        // end    
        this.vendorService.getAllTranslator(this.query_approve)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -2);
                });
                response.data.forEach(function (elem) {
                    if (elem.vendortranslationlangs.data.length > 0) {
                        var lang_5 = '';
                        var lang_array_5 = [];
                        var translation_rate_value1_5 = '';
                        elem.vendortranslationlangs.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_5 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_5 += res.translation_rate;
                            }
                            lang_5 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_5.push(lang_5);
                        });
                        elem.check = false;
                        if (lang_array_5.length > 0) {
                            var test = void 0;
                            test = lang_array_5[lang_array_5.length - 1];
                            lang_array_5[lang_array_5.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_5;
                        response.data.lang_array = lang_array_5;
                    }
                    else if (elem.vendorSubtitler.data.length > 0) {
                        var lang_6 = '';
                        var lang_array_6 = [];
                        var translation_rate_value1_6 = '';
                        elem.vendorSubtitler.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_6 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_6 += res.translation_rate;
                            }
                            lang_6 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_6.push(lang_6);
                        });
                        elem.check = false;
                        if (lang_array_6.length > 0) {
                            var test = void 0;
                            test = lang_array_6[lang_array_6.length - 1];
                            lang_array_6[lang_array_6.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_6;
                        response.data.lang_array = lang_array_6;
                    }
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.total_freelancer = response.meta.pagination.total;
            }
        });
    };
    FreelancerComponent.prototype.editData = function (value) {
        this.router.navigate(['/hr/translator/' + value.id]);
    };
    FreelancerComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10';
        if ((type == 'search' && evt != '' && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += '&search_query=' + this.filterSearchQuery;
            var filterSearchQuery = this.filterSearchQuery;
            SharedService.set(SharedService.pms_freelancer_search_filter, JSON.stringify(filterSearchQuery));
        }
        else if (type == 'search' && evt == '') {
            this.filterSearchQuery = evt;
            var filterSearchQuery = this.filterSearchQuery;
            SharedService.set(SharedService.pms_freelancer_search_filter, JSON.stringify(filterSearchQuery));
        }
        else if (this.filterSearchQuery !== '' && this.filterSearchQuery !== null) {
            this.fQuery += '&search_query=' + this.filterSearchQuery;
            var filterSearchQuery = this.filterSearchQuery;
            SharedService.set(SharedService.pms_freelancer_search_filter, JSON.stringify(filterSearchQuery));
        }
        else if (this.fdata.Search != '' && this.fdata.Search != null) {
            this.fQuery += '&search_query=' + this.fdata.Search;
        }
        if ((type == 'city' && evt != '' && evt !== undefined)) {
            this.filterCity = evt;
            this.fQuery += '&city=' + this.filterCity;
            var filterCity = this.filterCity;
            SharedService.set(SharedService.pms_freelancer_city_filter, JSON.stringify(filterCity));
        }
        else if (type == 'city' && evt == '') {
            this.filterCity = evt;
            var filterCity = this.filterCity;
            SharedService.set(SharedService.pms_freelancer_city_filter, JSON.stringify(filterCity));
        }
        else if (this.filterCity != '' && this.filterCity != null) {
            this.fQuery += '&City=' + this.filterCity;
            var filterCity = this.filterCity;
            SharedService.set(SharedService.pms_freelancer_city_filter, JSON.stringify(filterCity));
        }
        else if (this.fdata.City != '' && this.fdata.City != null) {
            this.fQuery += '&City=' + this.fdata.City;
        }
        if ((type == 'rate' && evt != '' && evt !== undefined)) {
            this.filterRate = evt;
            this.fQuery += '&rate_per_word=' + this.filterRate;
            var filterRate = this.filterRate;
            SharedService.set(SharedService.pms_freelancer_rate_filter, JSON.stringify(filterRate));
        }
        else if (type == 'rate' && evt == '') {
            this.filterRate = evt;
            var filterRate = this.filterRate;
            SharedService.set(SharedService.pms_freelancer_rate_filter, JSON.stringify(filterRate));
        }
        else if (this.filterRate != '' && this.filterRate != null) {
            this.fQuery += '&rate_per_word=' + this.filterRate;
            var filterRate = this.filterRate;
            SharedService.set(SharedService.pms_freelancer_rate_filter, JSON.stringify(filterRate));
        }
        else if (this.fdata.Rate != '' && this.fdata.Rate != null) {
            this.fQuery += '&rate_per_word=' + this.fdata.Rate;
        }
        if ((type == 'speciality' && evt.value != '' && evt.value !== undefined)) {
            this.filterSpecialization = evt.value;
            this.fQuery += '&specialization=' + this.filterSpecialization;
            // start
            var filterSpecialization = this.filterSpecialization;
            SharedService.set(SharedService.pms_freelancer_specialization_filter, JSON.stringify(filterSpecialization));
            // end     
        }
        else if (type == 'speciality' && evt.value == '') {
            this.filterSpecialization = evt.value;
            // start
            var filterSpecialization = this.filterSpecialization;
            SharedService.set(SharedService.pms_freelancer_specialization_filter, JSON.stringify(filterSpecialization));
            // end    
        }
        else if (this.filterSpecialization !== '' && this.filterSpecialization !== null) {
            this.fQuery += '&specialization=' + this.filterSpecialization;
            // start
            var filterSpecialization = this.filterSpecialization;
            SharedService.set(SharedService.pms_freelancer_specialization_filter, JSON.stringify(filterSpecialization));
            // end
        }
        else if (this.fdata.specialization != '' && this.fdata.specialization != null) {
            this.fQuery += '&specialization=' + this.fdata.specialization;
        }
        if ((type == 'native_lang' && evt.value != '' && evt.value !== undefined)) {
            this.filter_native_lang = evt.value;
            this.fQuery += '&native_lang=' + this.filter_native_lang;
            // start
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.pms_freelancer_native_filter, JSON.stringify(filter_native_lang));
            // end
        }
        else if (type == 'native_lang' && evt.value == '') {
            this.filter_native_lang = evt.value;
            // start
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.pms_freelancer_native_filter, JSON.stringify(filter_native_lang));
            // end
        }
        else if (this.filter_native_lang != null && this.filter_native_lang != '') {
            this.fQuery += '&native_lang=' + this.filter_native_lang;
            // start
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.pms_freelancer_native_filter, JSON.stringify(filter_native_lang));
            // end
        }
        else if (this.fdata.Native != '' && this.fdata.Native != null) {
            this.fQuery += '&native_lang=' + this.fdata.Native;
        }
        if ((type == 's_lang' && evt.value != '' && evt.value !== undefined)) {
            this.filter_src_lang = evt.value;
            this.fQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.pms_freelancer_source_lang_filter, JSON.stringify(filter_src_lang));
        }
        else if (type == 's_lang' && evt.value == '') {
            this.filter_src_lang = evt.value;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.pms_freelancer_source_lang_filter, JSON.stringify(filter_src_lang));
        }
        else if (this.filter_src_lang != '' && this.filter_src_lang != null) {
            this.fQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.pms_freelancer_source_lang_filter, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source != '' && this.fdata.Source != null) {
            this.fQuery += '&source_lang=' + this.fdata.Source;
        }
        if ((type == 't_lang' && evt.value != '' && evt.value !== undefined)) {
            this.filter_target_lang = evt.value;
            this.fQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.pms_freelancer_target_lang_filter, JSON.stringify(filter_target_lang));
        }
        else if (type == 't_lang' && evt.value == '') {
            this.filter_target_lang = evt.value;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.pms_freelancer_target_lang_filter, JSON.stringify(filter_target_lang));
        }
        else if (this.filter_target_lang != '' && this.filter_target_lang != null) {
            this.fQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.pms_freelancer_target_lang_filter, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target != '' && this.fdata.Target != null) {
            this.fQuery += '&target_lang=' + this.fdata.Target;
        }
        if ((type == 'days_available' && evt.value != '' && evt.value !== undefined)) {
            this.filter_days_available = evt.value;
            this.fQuery += '&days_available=' + this.filter_days_available;
            var filter_days_available = this.filter_days_available;
            SharedService.set(SharedService.pms_freelancer_days_available_filter, JSON.stringify(filter_days_available));
        }
        else if (type == 'days_available' && evt.value == '') {
            this.filter_days_available = evt.value;
            var filter_days_available = this.filter_days_available;
            SharedService.set(SharedService.pms_freelancer_days_available_filter, JSON.stringify(filter_days_available));
        }
        else if (this.filter_days_available != '' && this.filter_days_available != null) {
            this.fQuery += '&days_available=' + this.filter_days_available;
            var filter_days_available = this.filter_days_available;
            SharedService.set(SharedService.pms_freelancer_days_available_filter, JSON.stringify(filter_days_available));
        }
        else if (this.fdata.Dayavailabe != '' && this.fdata.Dayavailabe != null) {
            this.fQuery += '&days_available=' + this.fdata.Dayavailabe;
        }
        if ((type == 'gender' && evt.value != '' && evt.value !== undefined)) {
            this.filter_gender = evt.value;
            this.fQuery += '&gender=' + this.filter_gender;
            var filter_gender = this.filter_gender;
            SharedService.set(SharedService.pms_freelancer_gender_filter, JSON.stringify(filter_gender));
        }
        else if (type == 'gender' && evt.value == '') {
            this.filter_gender = evt.value;
            var filter_gender = this.filter_gender;
            SharedService.set(SharedService.pms_freelancer_gender_filter, JSON.stringify(filter_gender));
        }
        else if (this.filter_gender != '' && this.filter_gender != null) {
            this.fQuery += '&gender=' + this.filter_gender;
            var filter_gender = this.filter_gender;
            SharedService.set(SharedService.pms_freelancer_gender_filter, JSON.stringify(filter_gender));
        }
        else if (this.fdata.Gender != '' && this.fdata.Gender != null) {
            this.fQuery += '&gender=' + this.fdata.Gender;
        }
        if ((type == 'tools' && evt.value != '' && evt.value !== undefined)) {
            this.filter_tools = evt.value;
            this.fQuery += '&tools=' + this.filter_tools;
            var filter_tools = this.filter_tools;
            SharedService.set(SharedService.pms_freelancer_tools_filter, JSON.stringify(filter_tools));
        }
        else if (type == 'tools' && evt.value == '') {
            this.filter_tools = evt.value;
            var filter_tools = this.filter_tools;
            SharedService.set(SharedService.pms_freelancer_tools_filter, JSON.stringify(filter_tools));
        }
        else if (this.filter_tools != '' && this.filter_tools != null) {
            this.fQuery += '&tools=' + this.filter_tools;
            var filter_tools = this.filter_tools;
            SharedService.set(SharedService.pms_freelancer_tools_filter, JSON.stringify(filter_tools));
        }
        else if (this.fdata.Tools != '' && this.fdata.Tools != null) {
            this.fQuery += '&tools=' + this.fdata.Tools;
        }
        if ((type == 'timezone' && evt.value != '' && evt.value !== undefined)) {
            this.filter_timezone = evt.value;
            this.fQuery += '&timezone=' + this.filter_timezone;
            var filter_timezone = this.filter_timezone;
            SharedService.set(SharedService.pms_freelancer_timezone_filter, JSON.stringify(filter_timezone));
        }
        else if (type == 'timezone' && evt.value == '') {
            this.filter_timezone = evt.value;
            var filter_timezone = this.filter_timezone;
            SharedService.set(SharedService.pms_freelancer_timezone_filter, JSON.stringify(filter_timezone));
        }
        else if (this.filter_timezone != '' && this.filter_timezone != null) {
            this.fQuery += '&timezone=' + this.filter_timezone;
            var filter_timezone = this.filter_timezone;
            SharedService.set(SharedService.pms_freelancer_timezone_filter, JSON.stringify(filter_timezone));
        }
        else if (this.fdata.Timezone != '' && this.fdata.Timezone != null) {
            this.fQuery += '&timezone=' + this.fdata.Timezone;
        }
        if ((type == 'age' && evt.value != '' && evt.value !== undefined)) {
            this.filter_age = evt.value;
            this.fQuery += '&age=' + this.filter_age;
            var filter_age = this.filter_age;
            SharedService.set(SharedService.pms_freelancer_age_filter, JSON.stringify(filter_age));
        }
        else if (type == 'age' && evt.value == '') {
            this.filter_age = evt.value;
            var filter_age = this.filter_age;
            SharedService.set(SharedService.pms_freelancer_age_filter, JSON.stringify(filter_age));
        }
        else if (this.filter_age != '' && this.filter_age != null) {
            this.fQuery += '&age=' + this.filter_age;
            var filter_age = this.filter_age;
            SharedService.set(SharedService.pms_freelancer_age_filter, JSON.stringify(filter_age));
        }
        else if (this.fdata.Age != '' && this.fdata.Age != null) {
            this.fQuery += '&age=' + this.fdata.Age;
        }
        if ((type == 'category_type' && evt.value != '' && evt.value !== undefined)) {
            this.filter_category_type = evt.value;
            this.fQuery += '&category_type=' + this.filter_category_type;
        }
        else if (type == 'category_type' && evt.value == '') {
            this.filter_category_type = evt.value;
        }
        else if (this.filter_category_type !== '') {
            this.fQuery += '&category_type=' + this.filter_category_type;
        }
        if ((type == 'multi_category_level' && evt.value != '' && evt.value !== undefined)) {
            this.filter_category_level = evt.value;
            this.fQuery += '&multi_category_level=' + this.filter_category_level;
            var filter_category_level = this.filter_category_level;
            SharedService.set(SharedService.pms_freelancer_category_level_filter, JSON.stringify(filter_category_level));
        }
        else if (type == 'multi_category_level' && evt.value == '') {
            this.filter_category_level = evt.value;
            var filter_category_level = this.filter_category_level;
            SharedService.set(SharedService.pms_freelancer_category_level_filter, JSON.stringify(filter_category_level));
        }
        else if (this.filter_category_level != '' && this.filter_category_level != null) {
            this.fQuery += '&multi_category_level=' + this.filter_category_level;
            var filter_category_level = this.filter_category_level;
            SharedService.set(SharedService.pms_freelancer_category_level_filter, JSON.stringify(filter_category_level));
        }
        else if (this.fdata.Categorylevel != '' && this.fdata.Categorylevel != null) {
            this.fQuery += '&multi_category_level=' + this.fdata.Categorylevel;
        }
        if ((type == 'avt_status_level' && evt.value != '' && evt.value !== undefined)) {
            this.filter_avt_status_level = evt.value;
            this.fQuery += '&avt_status_level=' + this.filter_avt_status_level;
            var filter_avt_status_level = this.filter_avt_status_level;
            SharedService.set(SharedService.pms_freelancer_avt_status_level_filter, JSON.stringify(filter_avt_status_level));
        }
        else if (type == 'avt_status_level' && evt.value == '') {
            this.filter_avt_status_level = evt.value;
            var filter_avt_status_level = this.filter_avt_status_level;
            SharedService.set(SharedService.pms_freelancer_avt_status_level_filter, JSON.stringify(filter_avt_status_level));
        }
        else if (this.filter_avt_status_level != '' && this.filter_avt_status_level != null) {
            this.fQuery += '&avt_status_level=' + this.filter_avt_status_level;
            var filter_avt_status_level = this.filter_avt_status_level;
            SharedService.set(SharedService.pms_freelancer_avt_status_level_filter, JSON.stringify(filter_avt_status_level));
        }
        else if (this.fdata.AvtStatuslevel != '' && this.fdata.AvtStatuslevel != null) {
            this.fQuery += '&avt_status_level=' + this.fdata.AvtStatuslevel;
        }
        if ((type == 'country' && evt.value != '' && evt.value !== undefined)) {
            this.filterCountry = evt.value;
            this.fQuery += '&country=' + this.filterCountry;
            var filterCountry = this.filterCountry;
            SharedService.set(SharedService.pms_freelancer_country_filter, JSON.stringify(filterCountry));
        }
        else if (type == 'country' && evt.value == '') {
            this.filterCountry = evt.value;
            var filterCountry = this.filterCountry;
            SharedService.set(SharedService.pms_freelancer_country_filter, JSON.stringify(filterCountry));
        }
        else if (this.filterCountry != '' && this.filterCountry != null) {
            this.fQuery += '&country=' + this.filterCountry;
            var filterCountry = this.filterCountry;
            SharedService.set(SharedService.pms_freelancer_country_filter, JSON.stringify(filterCountry));
        }
        else if (this.fdata.Country != '' && this.fdata.Country != null) {
            this.fQuery += '&country=' + this.fdata.Country;
        }
        // project service filter
        if ((type == 'multi_service' && evt.value != '' && evt.value !== undefined)) {
            this.filterService = evt.value;
            this.fQuery += '&multi_service=' + this.filterService;
            var filterService = this.filterService;
            SharedService.set(SharedService.pms_freelancer_service_filter, JSON.stringify(filterService));
        }
        else if (type == 'multi_service' && evt.value == '') {
            this.filterService = evt.value;
            var filterService = this.filterService;
            SharedService.set(SharedService.pms_freelancer_service_filter, JSON.stringify(filterService));
        }
        else if (this.filterService != '' && this.filterService != null) {
            this.fQuery += '&multi_service=' + this.filterService;
            var filterService = this.filterService;
            SharedService.set(SharedService.pms_freelancer_service_filter, JSON.stringify(filterService));
        }
        else if (this.fdata.Service != '' && this.fdata.Service != null) {
            this.fQuery += '&multi_service=' + this.fdata.Service;
        }
        // project service filter
        if ((type == 'avt_service' && evt.value != '' && evt.value !== undefined)) {
            this.filterAvtService = evt.value;
            this.fQuery += '&avt_service=' + this.filterAvtService;
            var filterAvtService = this.filterAvtService;
            SharedService.set(SharedService.pms_freelancer_avt_service_filter, JSON.stringify(filterAvtService));
        }
        else if (type == 'avt_service' && evt.value == '') {
            this.filterAvtService = evt.value;
            var filterAvtService = this.filterAvtService;
            SharedService.set(SharedService.pms_freelancer_avt_service_filter, JSON.stringify(filterAvtService));
        }
        else if (this.filterAvtService != '' && this.filterAvtService != null) {
            this.fQuery += '&avt_service=' + this.filterAvtService;
            var filterAvtService = this.filterAvtService;
            SharedService.set(SharedService.pms_freelancer_avt_service_filter, JSON.stringify(filterAvtService));
        }
        else if (this.fdata.Avtservice != '' && this.fdata.Avtservice != null) {
            this.fQuery += '&avt_service=' + this.fdata.Avtservice;
        }
        if ((type == 'start_date' && evt.value != null && evt.value !== undefined)) {
            if (evt.value == null) {
                this.fQuery += '&start_date=' + '';
            }
            else {
                this.filterStartDate = evt.value.format('YYYY-MM-DD');
                this.fQuery += '&start_date=' + this.filterStartDate;
                var filterStartDate = this.filterStartDate;
                SharedService.set(SharedService.pms_freelancer_startdate_filter, JSON.stringify(filterStartDate));
            }
        }
        else if (type == 'start_date' && evt.value == null) {
            this.filterStartDate = evt.value;
            var filterStartDate = this.filterStartDate;
            SharedService.set(SharedService.pms_freelancer_startdate_filter, JSON.stringify(filterStartDate));
        }
        else if (this.filterStartDate != null && this.filterStartDate != '') {
            if (this.filterStartDate == null) {
                this.fQuery += '&start_date=' + '';
            }
            else {
                this.fQuery += '&start_date=' + this.filterStartDate;
                var filterStartDate = this.filterStartDate;
                SharedService.set(SharedService.pms_freelancer_startdate_filter, JSON.stringify(filterStartDate));
            }
        }
        else if (this.fdata.Startdate != null && this.fdata.Startdate != '') {
            this.fQuery += '&start_date=' + this.fdata.Startdate;
        }
        if ((type == 'end_date' && evt.value != null && evt.value !== undefined)) {
            if (evt.value == null) {
                this.fQuery += '&end_date=' + '';
            }
            else {
                this.filterEndDate = evt.value.format('YYYY-MM-DD');
                this.fQuery += '&end_date=' + this.filterEndDate;
                var filterEndDate = this.filterEndDate;
                SharedService.set(SharedService.pms_freelancer_enddate_filter, JSON.stringify(filterEndDate));
            }
        }
        else if (type == 'end_date' && evt.value == null) {
            this.filterEndDate = evt.value;
            var filterEndDate = this.filterEndDate;
            SharedService.set(SharedService.pms_freelancer_enddate_filter, JSON.stringify(filterEndDate));
        }
        else if (this.filterEndDate != null && this.filterEndDate != '') {
            if (this.filterEndDate == null) {
                this.fQuery += '&end_date=' + '';
            }
            else {
                this.fQuery += '&end_date=' + this.filterEndDate;
                var filterEndDate = this.filterEndDate;
                SharedService.set(SharedService.pms_freelancer_enddate_filter, JSON.stringify(filterEndDate));
            }
        }
        else if (this.fdata.Enddate != null && this.fdata.Enddate != '') {
            this.fQuery += '&end_date=' + this.fdata.Enddate;
        }
        this.parseQueryString(this.fQuery);
        // start
        var pms_freelancer_Filter = { table_name: 'pms_freelancer_Filter', filter: this.fQuery };
        SharedService.set(SharedService.pms_freelancer_Filter, JSON.stringify(pms_freelancer_Filter));
        // end
        this.fQuery += '&not_archive=' + this.notArchive;
        var query = this.fQuery;
        this.vendorService.getAllTranslator(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    if (elem.vendortranslationlangs.data.length > 0) {
                        var lang_7 = '';
                        var lang_array_7 = [];
                        var translation_rate_value1_7 = '';
                        elem.vendortranslationlangs.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_7 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_7 += res.translation_rate;
                            }
                            lang_7 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_7.push(lang_7);
                        });
                        elem.check = false;
                        if (lang_array_7.length > 0) {
                            var test = void 0;
                            test = lang_array_7[lang_array_7.length - 1];
                            lang_array_7[lang_array_7.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_7;
                        response.data.lang_array = lang_array_7;
                    }
                    else if (elem.vendorSubtitler.data.length > 0) {
                        var lang_8 = '';
                        var lang_array_8 = [];
                        var translation_rate_value1_8 = '';
                        elem.vendorSubtitler.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_8 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_8 += res.translation_rate;
                            }
                            lang_8 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_8.push(lang_8);
                        });
                        elem.check = false;
                        if (lang_array_8.length > 0) {
                            var test = void 0;
                            test = lang_array_8[lang_array_8.length - 1];
                            lang_array_8[lang_array_8.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_8;
                        response.data.lang_array = lang_array_8;
                    }
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    FreelancerComponent.prototype.parseQueryString = function (queryString) {
        this.fChipsArray = [];
        var params = {
            search_query: '',
            service: '',
            source_lang: '',
            target_lang: '',
            city: '',
            rate: '',
            country: '',
            project_pm: '',
            source: '',
            category: '',
            specialization: '',
            start_date: '',
            end_date: '',
            native_lang: '',
        }, queries, temp, i, l;
        // Split into key/value pairs
        queries = queryString.split('&');
        // Convert the array of strings into an object
        for (i = 0, l = queries.length; i < l; i++) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        this.get_filter_value(params);
    };
    FreelancerComponent.prototype.get_filter_value = function (data) {
        var _this = this;
        var key = Object.keys(data);
        if (key[0] == 'search_query') {
            if (data.search_query != '') {
                this.fChipsArray.push(data.search_query);
            }
        }
        if (key[1] == 'service') {
            this.servicesValue.forEach(function (element) {
                if (data.service == element.id) {
                    _this.fChipsArray.push(element.name);
                }
            });
        }
        if (key[2] == 'source_lang') {
            if (data.source_lang != '') {
                this.fChipsArray.push(data.source_lang);
            }
        }
        if (key[3] == 'target_lang') {
            if (data.target_lang != '') {
                this.fChipsArray.push(data.target_lang);
            }
        }
        if (key[4] == 'city') {
            if (data.city != '') {
                this.fChipsArray.push(data.city);
            }
        }
        if (key[5] == 'country') {
            if (data.country != '') {
                this.fChipsArray.push(data.country);
            }
        }
        if (key[6] == 'project_pm') {
            if (data.project_pm != '') {
                this.fChipsArray.push(data.project_pm);
            }
        }
        if (key[7] == 'source') {
            if (data.source != '') {
                this.fChipsArray.push(data.source);
            }
        }
        if (key[8] == 'category_type') {
            if (data.category_type == 1) {
                this.fChipsArray.push('DayVIP');
            }
            else if (data.category_type == 2) {
                this.fChipsArray.push('Company');
            }
            if (data.category_type == 3) {
                this.fChipsArray.push('Individual');
            }
            else if (data.category_type == 4) {
                this.fChipsArray.push('New Client');
            }
        }
        if (key[9] == 'specialization') {
            if (data.specialization != '') {
                this.fChipsArray.push(data.specialization);
            }
        }
        if (key[10] == 'start_date') {
            if (data.start_date != '') {
                this.fChipsArray.push(data.start_date);
            }
        }
        if (key[11] == 'end_date') {
            if (data.end_date != '') {
                this.fChipsArray.push(data.end_date);
            }
        }
        if (key[12] == 'native_lang') {
            this.languageData.forEach(function (element) {
                if (data.native_lang == element.id) {
                    _this.fChipsArray.push(element.lang_name);
                }
            });
        }
        if (key[15] == 'multi_category_level') {
            if (data.multi_category_level != '') {
                var catArray = data.multi_category_level.split(",");
                catArray.forEach(function (element) {
                    _this.fChipsArray.push(element);
                });
                //this.fChipsArray.push(data.multi_category_level);
            }
        }
        // if (key[16] == 'multi_service') {
        //   if (data.multi_service != '') {
        //       this.fChipsArray.push(data.multi_service);
        //   }
        // }
        key.forEach(function (elem) {
            if (elem == 'multi_service') {
                if (data.multi_service != '') {
                    var mArray = data.multi_service.split(",");
                    mArray.forEach(function (element) {
                        _this.fChipsArray.push(element);
                    });
                    //this.fChipsArray.push(data.multi_service);
                }
            }
        });
        key.forEach(function (ele) {
            if (ele == 'avt_service') {
                _this.skillData.data.forEach(function (element) {
                    if (data.avt_service == element.id) {
                        console.log(element.name);
                        _this.fChipsArray.push(element.name);
                    }
                });
            }
        });
        key.forEach(function (ele) {
            if (ele == 'age') {
                _this.ageData.forEach(function (element) {
                    if (data.age == element) {
                        //console.log(element);
                        _this.fChipsArray.push(element);
                    }
                });
            }
        });
        key.forEach(function (ele) {
            if (ele == 'gender') {
                if (data.gender != '') {
                    if (data.gender == 1) {
                        _this.fChipsArray.push('Male');
                    }
                    if (data.gender == 2) {
                        _this.fChipsArray.push('Female');
                    }
                }
            }
        });
        key.forEach(function (ele) {
            if (ele == 'tools') {
                if (data.tools != '') {
                    _this.fChipsArray.push(data.tools);
                }
            }
        });
        key.forEach(function (ele) {
            if (ele == 'timezone') {
                if (data.timezone != '') {
                    _this.fChipsArray.push(data.timezone);
                }
            }
        });
        key.forEach(function (ele) {
            // debugger;
            if (ele == 'rate') {
                if (data.rate != '') {
                    _this.fChipsArray.push(data.rate);
                }
            }
        });
        // key.forEach(ele =>{
        //   if(ele == 'age'){
        //     if (data.age != '') {
        //       this.fChipsArray.push(data.age);
        //     }
        //   }
        // });
    };
    FreelancerComponent.prototype.selectMailProgress = function (evt, val) {
        if (evt.checked && this.ids_progress.indexOf(val.id == -1)) {
            this.ids_progress.push(val.id);
            this.login_enable = false;
        }
        else if (!evt.checked &&
            this.ids_progress.indexOf(val.id != -1)) {
            var index = this.ids_progress.indexOf(val.id);
            this.ids_progress.splice(index, 1);
        }
        if (this.ids_progress.length == 0) {
            this.bulk_selected_progress = false;
            this.login_enable = true;
        }
        this.send_email_progress_data.freelacner_id = this.ids_progress;
    };
    FreelancerComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        var check = false;
        this.dataSource1.data.forEach(function (element, key) {
            element['check'] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.login_enable = false;
                _this.ids_progress.push(element.id);
            }
            else {
                element.check = false;
                _this.ids_progress = [];
                _this.login_enable = true;
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
        this.send_email_progress_data.freelacner_id = this.ids_progress;
    };
    FreelancerComponent.prototype.getFreelancerEmailTemplate = function () {
        var _this = this;
        this.projectsService.getFreelancerEmailTemplate()
            .subscribe(function (response) {
            if (response) {
                _this.emailTemplateData = response.data;
            }
            ;
        });
    };
    FreelancerComponent.prototype.openDialogAddTranslator = function () {
        var _this = this;
        var dailogRef = this.dialog.open(BulkEmailComponent, {
            width: '1000px',
            height: '600px',
            data: { templateData: this.emailTemplateData, freelacner: this.send_email_progress_data.freelacner_id }
        });
        dailogRef.afterClosed().subscribe(function (response) {
            _this.dataSource1.data.forEach(function (element, key) {
                element['check'] = false;
            });
            _this.bulk_selected_progress = false;
        });
    };
    FreelancerComponent.prototype.openDialogAllTranslator = function () {
        this.send_email_progress_data.freelacner_id = this.all_freelancer_id;
        var dailogRef = this.dialog.open(BulkEmailComponent, {
            width: '1000px',
            height: '600px',
            data: { templateData: this.emailTemplateData, freelacner: this.send_email_progress_data.freelacner_id }
        });
    };
    FreelancerComponent.prototype.clearLocalStorage = function () {
        var _this = this;
        localStorage.removeItem('pms_freelancer_Filter');
        localStorage.removeItem('pms_freelancer_native_filter');
        localStorage.removeItem('pms_freelancer_specialization_filter');
        localStorage.removeItem('pms_freelancer_source_lang_filter');
        localStorage.removeItem('pms_freelancer_target_lang_filter');
        localStorage.removeItem('pms_freelancer_service_filter');
        localStorage.removeItem('pms_freelancer_search_filter');
        localStorage.removeItem('pms_freelancer_days_available_filter');
        localStorage.removeItem('pms_freelancer_gender_filter');
        localStorage.removeItem('pms_freelancer_timezone_filter');
        localStorage.removeItem('pms_freelancer_tools_filter');
        localStorage.removeItem('pms_freelancer_age_filter');
        localStorage.removeItem('pms_freelancer_category_level_filter');
        localStorage.removeItem('pms_freelancer_country_filter');
        localStorage.removeItem('pms_freelancer_city_filter');
        localStorage.removeItem('pms_freelancer_rate_filter');
        localStorage.removeItem('pms_freelancer_startdate_filter');
        localStorage.removeItem('pms_freelancer_enddate_filter');
        localStorage.removeItem('pms_freelancer_pagination');
        localStorage.removeItem('pms_freelancer_avt_service');
        localStorage.removeItem('pms_freelancer_avt_service_filter');
        localStorage.removeItem('multi_service');
        localStorage.removeItem('avt_service');
        this.toppings.patchValue([]);
        this.sToppings.patchValue([]);
        this.fChipsArray = [];
        this.getData();
        var currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(function () {
            _this.router.navigate([currentUrl]);
        });
    };
    return FreelancerComponent;
}());
export { FreelancerComponent };
