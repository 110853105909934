import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import {MatGridListModule} from '@angular/material/grid-list';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { WelcomeComponent } from './welcome.component';

const routes = [
    {
        path     : 'welcome',
        component: WelcomeComponent       // authguard 
  
    }
];

@NgModule({
    declarations: [
        WelcomeComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatIconModule,MatButtonModule,MatCheckboxModule,MatFormFieldModule,MatInputModule,
        TranslateModule,
        MatGridListModule,
        FuseSharedModule
    ],
    exports     : [
        WelcomeComponent
    ]
})

export class WelcomeModule
{
    
}
