import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-crm-email-log-preview',
  templateUrl: './crm-email-log-preview.component.html',
  styleUrls: ['./crm-email-log-preview.component.scss']
})
export class CrmEmailLogPreviewComponent implements OnInit {

  id:any;
  response_email:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data :any,private toastr: ToastrService,
  private dialogRef: MatDialogRef<CrmEmailLogPreviewComponent>,private projectsService: ProjectsService) { }

  ngOnInit() {
    if(this.data) {
      this.id=this.data;
      this.response_email=this.data;
    }
  }

  Onclose(){
    this.dialogRef.close();
}


}
