import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/main/Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { SharedService } from 'app/main/shared.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
var AddUserComponent = /** @class */ (function () {
    function AddUserComponent(_formBuilder, router, route, userService, FuseProgressBarService, toastr, SharedService) {
        this._formBuilder = _formBuilder;
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.FuseProgressBarService = FuseProgressBarService;
        this.toastr = toastr;
        this.SharedService = SharedService;
        this.type = 'Add';
        this.selected_portal_access = [];
        this.portal_access_update = [];
        this.data = {};
        this.fileError = '';
        this.profile_file_id = 0;
        this.file_multiple_data = [];
        this.uploaded_files = [];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    AddUserComponent.prototype.ngOnInit = function () {
        //  this.id= this.route.snapshot.params['id'];
        this.id = this.route.snapshot.params['id'];
        if (this.id) {
            this.getUserData();
            this.userForm = this._formBuilder.group({
                fullname: ['', [Validators.required, Validators.minLength(4)]],
                email: ['', [Validators.required, Validators.email]],
                password: [''],
                username: ['', [Validators.required, Validators.minLength(3)]],
                user_group_id: ['Admin'],
                status: ['1'],
                send_detail: [],
            });
        }
        else {
            this.userForm = this._formBuilder.group({
                fullname: ['', [Validators.required, Validators.minLength(4)]],
                email: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required, Validators.minLength(6)]],
                username: ['', [Validators.required, Validators.minLength(3)]],
                user_group_id: ['Admin'],
                status: ['1'],
                send_detail: [],
            });
        }
    };
    Object.defineProperty(AddUserComponent.prototype, "fullname", {
        get: function () {
            return this.userForm.get('fullname');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddUserComponent.prototype, "f", {
        get: function () {
            return this.userForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddUserComponent.prototype.getUserData = function () {
        var _this = this;
        this.userService.getUser(this.id).subscribe(function (response) {
            if (response) {
                _this.type = 'Update';
                response.data.user_portal.forEach(function (element) {
                    _this.selected_portal_access.push(element.id);
                });
                if (response.data.profile_file_id != null) {
                    _this.profile_file_id = response.data.profile_file_id.id;
                    _this.vendor_file_data =
                        _this.BaseUrl_file +
                            response.data.profile_file_id.path;
                }
                _this.userForm.patchValue({
                    fullname: response.data.fullname,
                    email: response.data.email,
                    username: response.data.user_name,
                    user_group_id: response.data.user_group_id,
                    status: response.data.status.toString(),
                    send_detail: _this.userForm.value.send_detail,
                });
            }
        });
    };
    AddUserComponent.prototype.check_portal_access = function (evt) {
        if (evt.checked &&
            this.selected_portal_access.indexOf(parseInt(evt.source.value) == -1)) {
            this.selected_portal_access.push(parseInt(evt.source.value));
        }
        else if (!evt.checked &&
            this.selected_portal_access.indexOf(parseInt(evt.source.value) != -1)) {
            var index = this.selected_portal_access.indexOf(parseInt(evt.source.value));
            this.selected_portal_access.splice(index, 1);
        }
    };
    AddUserComponent.prototype.submit = function () {
        if (this.type == 'Add') {
            this.addData();
        }
        else {
            this.updateData();
        }
    };
    AddUserComponent.prototype.addData = function () {
        var _this = this;
        if (this.userForm.value.user_group_id === '') {
            this.toastr.error('The User group field is required');
            return false;
        }
        if (this.userForm.value.status === '') {
            this.toastr.error('The status field is required.');
            return false;
        }
        if (this.selected_portal_access.length === 0) {
            this.toastr.error('Please select any portal access');
            return false;
        }
        this.data = this.userForm.value;
        if (this.profile_file_id !== 0) {
            this.data.profile_file_id = this.profile_file_id;
        }
        this.data.portal_access = this.selected_portal_access;
        this.userService.add(this.data).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success('New user added successfully');
                _this.router.navigate(['user-management']);
            }
        });
    };
    AddUserComponent.prototype.updateData = function () {
        var _this = this;
        this.data = this.userForm.value;
        if (this.profile_file_id != 0) {
            this.data.profile_file_id = this.profile_file_id;
        }
        this.data.portal_access = this.selected_portal_access;
        this.userService.update(this.id, this.data).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success('User updated successfully');
                _this.router.navigate(['user-management']);
            }
        });
    };
    AddUserComponent.prototype.generate_password = function (evt) {
        if (evt.checked) {
            var rString = this.randomString(8, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$%^@');
            this.userForm.patchValue({
                password: rString,
            });
        }
        else {
            this.userForm.patchValue({
                password: '',
            });
        }
    };
    AddUserComponent.prototype.randomString = function (length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    };
    AddUserComponent.prototype.check_send_detail = function (evt) {
        if (evt.checked) {
            this.userForm.patchValue({
                send_detail: 1,
            });
        }
        else {
            this.userForm.patchValue({
                send_detail: 0,
            });
        }
    };
    // start multiple file upload
    AddUserComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = [
                'jpeg',
                'jpg',
                'png',
                'gif'
            ];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError =
                            'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError =
                        'Only accepts jpg, jpeg, png, gif format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    // addDocument
    AddUserComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.userService.uploadFile(uploadData).subscribe(function (response) {
            _this.uploaded_files = response.data;
            response.data.forEach(function (element) {
                _this.profile_file_id = element.id;
                _this.vendor_file_data =
                    _this.BaseUrl_file + '/uploads/' + element.name;
            });
            _this.fileSuccess = 'Uploaded successfully!!';
        });
    };
    // end multiple file upload
    AddUserComponent.prototype.back = function () {
        this.router.navigate(['user-management']);
    };
    return AddUserComponent;
}());
export { AddUserComponent };
