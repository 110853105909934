import { OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var AdditionalInformationApostileComponent = /** @class */ (function () {
    function AdditionalInformationApostileComponent(route, projectsService, toastr) {
        this.route = route;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.apostile_show = false;
        this.additonal_service_array = [];
        this.apostile_state = false;
        this.myoutputApostile = new EventEmitter();
        this.outputString = "(NA)";
    }
    AdditionalInformationApostileComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getAdditionalServices();
    };
    AdditionalInformationApostileComponent.prototype.getAdditionalServices = function () {
        var _this = this;
        this.projectsService.getAdditionalServices(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.additonal_service_array = response.project_addition_service;
                if (_this.additonal_service_array.length == 0) {
                    _this.sendNotApply();
                }
                if (response.project_addition_service.length > 0) {
                    response.project_addition_service.forEach(function (element) {
                        if (element.additional_service.code == " Apostile_service_Illinois") {
                            _this.apostile_show = true;
                            _this.shipping_country = element.apostile_country_source;
                            _this.apostile_state = true;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == " Apostile_service_outside_Illinois") {
                            _this.apostile_show = true;
                            _this.apostile_state = false;
                            _this.shipping_country = element.apostile_country_source;
                            _this.shipping_state = element.apostile_from_state;
                            _this.sendApply();
                        }
                    });
                }
            }
        });
    };
    AdditionalInformationApostileComponent.prototype.sendNotApply = function () {
        this.outputString = "(NA)";
        var data = {
            title: this.outputString,
            click_value: 0
        };
        this.myoutputApostile.emit(data);
    };
    AdditionalInformationApostileComponent.prototype.sendApply = function () {
        this.outputString = "(Applied)";
        var data = {
            title: this.outputString,
            click_value: 0
        };
        this.myoutputApostile.emit(data);
    };
    AdditionalInformationApostileComponent.prototype.Edit = function () {
        this.outputString = "(Applied)";
        var data = {
            title: this.outputString,
            click_value: 1
        };
        this.myoutputApostile.emit(data);
    };
    return AdditionalInformationApostileComponent;
}());
export { AdditionalInformationApostileComponent };
