import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute,ParamMap } from '@angular/router';
import { UserService } from './../../../Services/user.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'app/main/shared.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit {
id:string;
Form:any;
BaseUrl_file:any;
file_url:string="";
detail:any;
file_name:string;
files:any=[];
  constructor(private userservice:UserService,private toastr:ToastrService, private sharedService: SharedService,
    private activeRoute: ActivatedRoute, private router:Router,private fb:FormBuilder) {
      this.BaseUrl_file = environment.baseUrl_file;
     }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
       this.id = params.get('id');
    
    
      });
      this.getTicketDetails();
      this.Form=this.fb.group({
        status   : ['',Validators.required],
        support_message : ['',Validators.required]
       
      });
  }

  
  getTicketDetails()
  {
     this.userservice.getallTicketbyId(this.id)
    .subscribe((response:any) => {
        if(response) {
        this.detail=response.data;
        this.files=[];
        response.data.ticket_attachment.data.forEach(element => {
          let obj={
            file_name:'',
            file_url:''
          }
          obj.file_name=element.file.data.name;
          obj.file_url=this.BaseUrl_file+element.file.data.path;
         
          this.files.push(obj);
        });
     
         }
    }, (error) => {
      
      console.log(error);
    });
  }


  back(){
    this.router.navigate(['/tickets']);
      }


  Submit()
  {
    if(this.Form.value.status==''){
      this.toastr.error("Please select status !!");
      return false;
    }
    if(this.Form.value.support_message==''){
      this.toastr.error("Please select support message!!");
      return false;
    }
    let statusdata = this.Form.value;    
      this.userservice.updateTicketStatus(this.id,statusdata)
      .subscribe((response) => {
        if(response){
          this.Form.patchValue(
            {
            status   : [''],
            support_message : [''],
             
             }
            );
            this.getTicketDetails();
            this.toastr.success("Status Updated Successfully");
        }
        
       
      });
  }

}
