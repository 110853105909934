<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>

  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
      Business Reports
    </span>
  </div>

  <div class="white-box">
    <div class="boxBodyInn">

      <div class="box-blank">
        <h4>Date Range</h4>


        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Mailing Options">
            <ng-template mat-tab-label>This Year</ng-template>



            <div class="p20">


              <p class="mb-0"><b>Start Date</b></p>
              <div fxLayout="row wrap" fxLayoutGap="15px">

                <div fxFlex="25">
                  <mat-form-field  >
                    <input matInput>
                   
                  </mat-form-field>

                </div>

                <div fxFlex="10">
                  <mat-form-field  >
                    <mat-select placeholder="HRS">
                      <mat-option value="option1">00</mat-option>
                      <mat-option value="option2">01</mat-option>
                      <mat-option value="option3">02</mat-option>
                      <mat-option value="option4">05</mat-option>
                      <mat-option value="option4">06</mat-option>
                      <mat-option value="option4">07</mat-option>
                      <mat-option value="option4">08</mat-option>
                      <mat-option value="option4">09</mat-option>
                      <mat-option value="option4">10</mat-option>
                      <mat-option value="option4">24</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>


                <div fxFlex="10">
                  <mat-form-field  >
                    <mat-select placeholder="MIN">
                      <mat-option value="option1">00</mat-option>
                      <mat-option value="option2">01</mat-option>
                      <mat-option value="option3">02</mat-option>
                      <mat-option value="option4">05</mat-option>
                      <mat-option value="option4">06</mat-option>
                      <mat-option value="option4">07</mat-option>
                      <mat-option value="option4">08</mat-option>
                      <mat-option value="option4">09</mat-option>
                      <mat-option value="option4">10</mat-option>
                      <mat-option value="option4">59</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>


              </div>



              <p class="mb-0"><b>End Date</b></p>
              <div fxLayout="row wrap" fxLayoutGap="15px">

                <div fxFlex="25">
                  <mat-form-field  >
                    <input matInput>

                  </mat-form-field>

                </div>

                <div fxFlex="10">
                  <mat-form-field  >
                    <mat-select placeholder="HRS">
                      <mat-option value="option1">00</mat-option>
                      <mat-option value="option2">01</mat-option>
                      <mat-option value="option3">02</mat-option>
                      <mat-option value="option4">05</mat-option>
                      <mat-option value="option4">06</mat-option>
                      <mat-option value="option4">07</mat-option>
                      <mat-option value="option4">08</mat-option>
                      <mat-option value="option4">09</mat-option>
                      <mat-option value="option4">10</mat-option>
                      <mat-option value="option4">24</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>


                <div fxFlex="10">
                  <mat-form-field  >
                    <mat-select placeholder="MIN">
                      <mat-option value="option1">00</mat-option>
                      <mat-option value="option2">01</mat-option>
                      <mat-option value="option3">02</mat-option>
                      <mat-option value="option4">05</mat-option>
                      <mat-option value="option4">06</mat-option>
                      <mat-option value="option4">07</mat-option>
                      <mat-option value="option4">08</mat-option>
                      <mat-option value="option4">09</mat-option>
                      <mat-option value="option4">10</mat-option>
                      <mat-option value="option4">59</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>


              </div>



            </div>







          </mat-tab>


          <mat-tab label="Mailing Options">
            <ng-template mat-tab-label>This Month </ng-template>

            Inputs

          </mat-tab>

          <mat-tab label="Mailing Options">
            <ng-template mat-tab-label>Last Month</ng-template>

            Inputs
          </mat-tab>


          <mat-tab label="Mailing Options">
            <ng-template mat-tab-label>Past 30 Days</ng-template>

            Inputs
          </mat-tab>


          <mat-tab label="Mailing Options">
            <ng-template mat-tab-label>Today</ng-template>

            Inputs
          </mat-tab>



          <mat-tab label="Mailing Options">
            <ng-template mat-tab-label>Custom</ng-template>

            Inputs
          </mat-tab>








        </mat-tab-group>



      </div>



      <div class="box-blank">


        <div class="rptInputs" fxLayout="row wrap" fxLayoutGap="5px">

          <div fxFlex="19">
            <h4>Services</h4>
            <p>
              <mat-checkbox>Regular Translations</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Certified Translations</mat-checkbox>
            </p>
            <p>
              <mat-checkbox disabled="disabled">Subtitling</mat-checkbox>
            </p>
            <p>
              <mat-checkbox disabled="disabled"> Localization</mat-checkbox>
            </p>
            <p>
              <mat-checkbox disabled="disabled">Interpretation</mat-checkbox>
            </p>
          </div>
          <div fxFlex="19">
            <h4>Quote</h4>
            <p>
              <mat-checkbox>Requested</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>In-Proucess</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Rejected</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Approved</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Under Discussion</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Submitted within 10 min</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Submitted more than 10 min</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Canceled</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Re-quoted</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Rejection Converted</mat-checkbox>
            </p>
          </div>

          <div fxFlex="19">
            <h4>Lead Source</h4>
            <p>
              <mat-checkbox>DT Web form</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>DT Formsite form</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>DT APP</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Social Media</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Phone</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Email</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Fax</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Office visit</mat-checkbox>
            </p>
          </div>


          <div fxFlex="19">
            <h4>Project</h4>
            <p>
              <mat-checkbox>In-Process</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>UnAssigned</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Assigned</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Completed</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Delivered</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Un Delivered</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Canceled</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Under Edits</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Delayed</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Due date </mat-checkbox>
            </p>
          </div>

          <div fxFlex="19">
            <h4>Customer</h4>
            <p>
              <mat-checkbox>DayVIP</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Individual</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Corporate</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Blacklisted</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>SPAM</mat-checkbox>
            </p>

          </div>

        </div>


        <div class="rptInputs" fxLayout="row wrap" fxLayoutGap="15px">

          <div fxFlex="20">
            <h4>Freelancer</h4>
            <p>
              <mat-checkbox>Rookie 0</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Language Leader</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Expert</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Internal</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>External</mat-checkbox>
            </p>
          </div>

          <div fxFlex="20">
            <h4>Resources Involved</h4>
            <p><b>Project manager</b> </p>
            <mat-form-field  >
              <mat-select placeholder="Select Field" multiple>
                <mat-option value="option1">Natalia Robles</mat-option>
                <mat-option value="option2">M Maceda</mat-option>
                <mat-option value="option3">Maria Ferro</mat-option>
                <mat-option value="option4">Mark Stephen Parrish</mat-option>
                <mat-option value="option4">Karina Tesoro</mat-option>
                <mat-option value="option4">Marianna Khalatyan</mat-option>
              </mat-select>
            </mat-form-field>

            <p><b>Others</b> </p>
            <mat-form-field  >
              <mat-select placeholder="Select Field" multiple>
                <mat-option value="option1">Natalia Robles</mat-option>
                <mat-option value="option2">M Maceda</mat-option>
                <mat-option value="option3">Maria Ferro</mat-option>
                <mat-option value="option4">Mark Stephen Parrish</mat-option>
                <mat-option value="option4">Karina Tesoro</mat-option>
                <mat-option value="option4">Marianna Khalatyan</mat-option>
              </mat-select>
            </mat-form-field>




          </div>
          <div fxFlex="20">
            <h4>Languages</h4>
            <p><b>Source Language is</b> </p>
            <mat-form-field  >
              <mat-select placeholder="Select Field">
                <mat-option value="option1">Any</mat-option>
                <mat-option value="option1">Afar</mat-option>
                <mat-option value="option2">Hindi</mat-option>
                <mat-option value="option3">English</mat-option>
                <mat-option value="option4">Spanish</mat-option>
                <mat-option value="option4">French</mat-option>
                <mat-option value="option4">German</mat-option>
              </mat-select>
            </mat-form-field>

            <p><b>Target Language is</b> </p>

            <mat-form-field  >
              <mat-select placeholder="Select Field">
                <mat-option value="option1">Any</mat-option>
                <mat-option value="option1">Afar</mat-option>
                <mat-option value="option2">Hindi</mat-option>
                <mat-option value="option3">English</mat-option>
                <mat-option value="option4">Spanish</mat-option>
                <mat-option value="option4">French</mat-option>
                <mat-option value="option4">German</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="20">
            <h4>Financial</h4>
            <p>
              <mat-checkbox>NET15</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>NET30</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>NET45</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Credit Available</mat-checkbox>
            </p>
            <p>
              <mat-checkbox>Vault customer</mat-checkbox>
            </p>
          </div>



        </div>
        <div class="rptInputs" fxLayout="row wrap" fxLayoutGap="15px">
          <div fxFlex="30">
            <h4>Additional Services</h4>
            <p><b> Mailing Options</b> </p>

            <mat-checkbox> Electronic version only</mat-checkbox>

            <mat-checkbox>Regular mailing (2-3 business days)</mat-checkbox>

            <mat-checkbox>Overnight mailing (1 business day)</mat-checkbox>

            <mat-checkbox>International mailing (3-5 business days)</mat-checkbox>

            <mat-checkbox>Canada mailing (1-3 business days)</mat-checkbox>


            <p><b>Expedited Office pickup Location</b> </p>

            <mat-form-field  >
              <mat-select placeholder="Select Field">
                <mat-option value="option1">New York, NY</mat-option>
                <mat-option value="option1">Chicago, IL</mat-option>
                <mat-option value="option2">Tampa, FL</mat-option>
                <mat-option value="option3">Washington, DC</mat-option>
                <mat-option value="option4">Houston, TX</mat-option>
                <mat-option value="option4">Los Angeles, CA</mat-option>
                <mat-option value="option4">Boston, MA</mat-option>
              </mat-select>
            </mat-form-field>

            <p><b>Notarization </b> </p>

            <mat-checkbox> Notarization of COA in CA or IL </mat-checkbox>

            <p><b> Notarization in any other state</b> </p>

            <mat-form-field  >
              <mat-select placeholder="Select Field">
                <mat-option value="option1">New York, NY</mat-option>
                <mat-option value="option1">Chicago, IL</mat-option>
                <mat-option value="option2">Tampa, FL</mat-option>
                <mat-option value="option3">Washington, DC</mat-option>
                <mat-option value="option4">Houston, TX</mat-option>
                <mat-option value="option4">Los Angeles, CA</mat-option>
                <mat-option value="option4">Boston, MA</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox> Additional Notarization</mat-checkbox>

            <mat-checkbox> Notarization Affidavit for court</mat-checkbox>


          </div>

          <div fxFlex="30">
            <h4>Locations</h4>

            <p><b>City is</b> </p>
            <mat-form-field  >
              <mat-select placeholder="Select Field">
                <mat-option value="option1">New York, NY</mat-option>
                <mat-option value="option1">Chicago, IL</mat-option>
                <mat-option value="option2">Tampa, FL</mat-option>
                <mat-option value="option3">Washington, DC</mat-option>
                <mat-option value="option4">Houston, TX</mat-option>
                <mat-option value="option4">Los Angeles, CA</mat-option>
                <mat-option value="option4">Boston, MA</mat-option>
              </mat-select>
            </mat-form-field>

            <p><b>State is</b> </p>

            <mat-form-field  >
              <mat-select placeholder="Select Field">
                <mat-option value="option1">New York, NY</mat-option>
                <mat-option value="option1">Chicago, IL</mat-option>
                <mat-option value="option2">Tampa, FL</mat-option>
                <mat-option value="option3">Washington, DC</mat-option>
                <mat-option value="option4">Houston, TX</mat-option>
                <mat-option value="option4">Los Angeles, CA</mat-option>
                <mat-option value="option4">Boston, MA</mat-option>
              </mat-select>
            </mat-form-field>

            <p><b>Country is</b> </p>

            <mat-form-field  >
              <mat-select placeholder="Select Field">
                <mat-option value="option1">New York, NY</mat-option>
                <mat-option value="option1">Chicago, IL</mat-option>
                <mat-option value="option2">Tampa, FL</mat-option>
                <mat-option value="option3">Washington, DC</mat-option>
                <mat-option value="option4">Houston, TX</mat-option>
                <mat-option value="option4">Los Angeles, CA</mat-option>
                <mat-option value="option4">Boston, MA</mat-option>
              </mat-select>
            </mat-form-field>

          </div>



          <div fxFlex="30">
            <h4>Log</h4>
            <mat-checkbox>Work time logged</mat-checkbox>
             <mat-checkbox>Work time logged</mat-checkbox>
          </div>

        </div>

      </div>


    </div>




    <div class="actWraper">

      <button matTooltip="Approve" mat-raised-button class="green-bg mr-8">
        <mat-label class="Text_Color">Search</mat-label>
      </button>



    </div>

  </div>



  <div class="white-box">


    <div class="tHeader">
      <div fxLayout="row wrap">
        <div fxFlex="60">
          <h4>Report</h4>
        </div>
        <div fxFlex="40" class="text-right">
          <button matTooltip="Export" mat-stroked-button>
            Export CSV
          </button>

          <button matTooltip="Export" mat-stroked-button>
            <i class="material-icons"> print </i> Print
          </button>

          <button matTooltip="Export" mat-stroked-button>
            <i class="material-icons"> picture_as_pdf </i> PDF </button>

        </div>
      </div>

      <p class="mb-0 mt-0">
        Generated by DayTMS on 2019-09-08 15:37 Eastern Daylight Time
      </p>




    </div>


    <div class="sData p20">

      <table class="table table-bordered">
        <tr>
          <th>SERVICES</th>

          <th>QUOTE</th>

          <th>LEAD SOURCE</th>

          <th>DATE FROM</th>

          <th>DATE TO</th>

          <th>RESULTS</th>

        </tr>

        <tr>
          <td>Regular Translations</td>

          <td>Rejected</td>

          <td>DT Web form</td>

          <td>06/08/2019, 12:45 EST</td>

          <td>16/08/2019, 12:45 EST</td>

          <td><span class="bdg ylbg">524 Records</span> <a class="ml-8 bdg blank" href="#">Vew List</a></td>

        </tr>



      </table>



      <table class="table table-bordered mt-16">
        <tr>
          <th>ID</th>

          <th>Lead Srouce</th>

          <th>Name</th>

          <th>Email</th>

          <th>Language Pair</th>

          <th>Value</th>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>


        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>


        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>
 
          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>

        <tr>
          <td> <a href="#"> Bondarev-08931972</a></td>

          <td>DayVIP</td>

          <td>Mr. Budaya</td>

          <td>mbudaya@derticder.com </td>

          <td>English < Hindi</td> <td><span class="bdg blank"> $455.00 </span></td>

        </tr>



      </table>




    </div>





  </div>








</div>