import { OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { EmailLogPreviewComponent } from './email-log-preview/email-log-preview.component';
var ProjectEmailLogComponent = /** @class */ (function () {
    function ProjectEmailLogComponent(projectsService, toastr, route, dialog) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.route = route;
        this.dialog = dialog;
        this.emailLogResponse = [];
        this.paginate = {};
        this.pageSize = 10;
        this.query_projects = "";
        this.pageIndex = 1;
        this.total = 0;
        this.displayedColumns = ['subject', 'to', 'sent_on', 'status'];
    }
    ProjectEmailLogComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getEmailLogs();
    };
    ProjectEmailLogComponent.prototype.getEmailLogs = function () {
        var _this = this;
        this.projectsService.getProjectEmailLog(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.emailLogResponse = response.data;
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.total = response.meta.pagination.total;
            }
        });
    };
    ProjectEmailLogComponent.prototype.paginateData = function (event) {
        var _this = this;
        var pQuery = this.project_id;
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_projects = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        pQuery += this.query_projects;
        this.projectsService.getProjectEmailLog(pQuery)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.total = response.meta.pagination.total;
            }
        });
    };
    // popup
    ProjectEmailLogComponent.prototype.openDialogEmail = function (id) {
        var dailogRef = this.dialog.open(EmailLogPreviewComponent, {
            width: '1700px',
            data: id
        });
    };
    return ProjectEmailLogComponent;
}());
export { ProjectEmailLogComponent };
