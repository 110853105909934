import { Component, OnInit ,ViewChild} from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { VendorService } from 'app/main/Services/vendor.service'; 
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  dataSource: any; 
  filter_native_lang: number = null;
  fChipsArrayApprove: any = [];
  VendordisplayedColumns = [ 'first_name', 'email','native_language', 'language', 'stage'];
  filterSpecialization: string = '';
  filter_src_lang: string = '';
  filter_target_lang: string = '';
  fQuery: string = '';
  specialityData: any = [];
  languageData: any =[];
  pageSize:number = 10;
  paginate:any = {};
  query_approve:string ='';
  query_freelancer_sort:string ='';
  pageIndex:number=1;
  filterSearchQuery:string='';
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private vendorService : VendorService, private toastr: ToastrService) {}
  ngOnInit() {
    this.getLanguage();
    this.getSpeciality();
    this.getDataVendor();
  }

  getSpeciality() {
    this.vendorService.getSpeciality()
    .subscribe((res) => {
        if(res) {
          this.specialityData = res;
        }
    });
  }

  getLanguage() {
    this.vendorService.getAllLanguages()
    .subscribe((res:any) => {
        if(res) {
          this.languageData = res.data;
        }
    });
  }

  getDataVendor() {
    let query = '?page=1&per_page=10';
   this.vendorService.getAdminApprovalPending(query,'marketing')
   .subscribe((response) => {
       if(response) {
        response.data.forEach(elem => {
          let specs = '';
          elem.vendorspecialities.data.forEach(res => {
            if(res.specialities != null){
              specs += res.specialities.data.name + ', ';
            }
          });
          elem.specs = specs.slice(0, -2);
        });
        response.data.forEach(elem => {
          let lang = '';
          let lang_array = [];
          let translation_rate_value ='';
          elem.vendortranslationlangs.data.forEach(res => {
            if(res.translation_rate != null){
              translation_rate_value += res.translation_rate;
          }
        if(res.bi_direction == 2){
          res.bi_direction = '<>';
      }else{
        res.bi_direction = '>';
      }
      lang = res.sourcelanguage.data.lang_name+res.bi_direction+ res.targetlanguage.data.lang_name+ '  Rate:$'+translation_rate_value+'/word'+' Speed:'+res.speed+' words'+',' ;
      lang_array.push(lang);
          });

          if (lang_array.length > 0){
            let test;
            test = lang_array[lang_array.length - 1];
            lang_array[lang_array.length - 1] = test.slice(0, -1);
        }
        elem.lang_array = lang_array;
        response.data.lang_array = lang_array;
        });
        this.dataSource = new MatTableDataSource(response.data);
        this.paginate = response.meta.pagination;
       }
   });
 }

 paginateData(event) {
  let aQuery = '';
  this.pageIndex = event.pageIndex + 1;
  if(this.pageIndex < 0) {
      this.pageIndex = 1;
  }
  this.pageSize = event.pageSize;
  this.query_approve = '?page=' +this.pageIndex + '&per_page=' + this.pageSize;
  if(this.filterSpecialization !=='' ){
    aQuery += '&specialization=' + this.filterSpecialization;
  }
  if(this.filter_src_lang !=='' ){
    aQuery += '&source_lang=' + this.filter_src_lang;
  }
  if(this.filter_target_lang !== ''){
    aQuery += '&target_lang=' + this.filter_target_lang;
  }


  if(this.filter_native_lang!==null){
    aQuery += '&native_lang=' + this.filter_native_lang;
  }
  else{
    let filter_native_lang='';
    aQuery += '&native_lang=' + filter_native_lang;
  }
  this.query_approve+=aQuery;
    this.vendorService.getAdminApprovalPending(this.query_approve)
    .subscribe((response) => {
    if(response) {
      response.data.forEach(elem => {
        let specs = '';
        elem.vendorspecialities.data.forEach(res => {
          if(res.specialities!=null){
            specs += res.specialities.data.name+', ';
          }  
        });
        elem.specs = specs.slice(0, -1);
      });
      response.data.forEach(elem => {
        let lang = '';
        let lang_array = [];
        let translation_rate_value ='';
        elem.vendortranslationlangs.data.forEach(res => {
          if(res.translation_rate != null){
            translation_rate_value+=res.translation_rate;
        }
      if(res.bi_direction == 2){
        res.bi_direction = '<>';
    }else{
      res.bi_direction = '>';
    }
    lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name 
    + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',' ;
    lang_array.push(lang);
        });
        if(lang_array.length>0){
          let test;
          test = lang_array[lang_array.length - 1];
          lang_array[lang_array.length - 1] = test.slice(0, -1);
      }
      elem.lang_array = lang_array;
      response.data.lang_array = lang_array;
      });
      this.dataSource = new MatTableDataSource(response.data);
      this.paginate = response.meta.pagination;
    }
  });
}

 applyFilterVendor(filterValue: string) {
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

onFilterSpeciality(evt,type){

  this.fQuery = '?page=1&per_page=10';
       // project search filter
       if ((type == 'search' && evt != '' && evt !== undefined)) {
        this.filterSearchQuery = evt;
        this.fQuery += '&search_text=' + this.filterSearchQuery;
       }
      else if(type == 'search' && evt == ''){
        this.filterSearchQuery = evt;
      }
      else if(this.filterSearchQuery!==''){
        this.fQuery += '&search_text=' + this.filterSearchQuery;
      }

      if ((type == 'speciality' && evt.value != '' && evt.value !== undefined)) {
          this.filterSpecialization = evt.value;
        
          this.fQuery += '&specialization=' + this.filterSpecialization;
      }
      else if(type == 'speciality' && evt.value == ''){
          this.filterSpecialization = evt.value;
          
      }
      else if(this.filterSpecialization!==''){
          this.fQuery += '&specialization=' + this.filterSpecialization;
      }   

      if ((type == 's_lang' && evt.value != '' && evt.value !== undefined)) {
        this.filter_src_lang = evt.value;
        this.fQuery += '&source_lang=' + this.filter_src_lang;
      }
      else if(type == 's_lang' && evt.value == ''){
        this.filter_src_lang = evt.value;
      }
      else if(this.filter_src_lang!==''){
        this.fQuery += '&source_lang=' + this.filter_src_lang;
      }  
      if ((type == 't_lang' && evt.value != '' && evt.value !== undefined)) {
        this.filter_target_lang = evt.value;
        this.fQuery += '&target_lang=' + this.filter_target_lang;
      }
      else if(type == 't_lang' && evt.value == ''){
        this.filter_target_lang = evt.value;
      }
      else if(this.filter_target_lang!==''){
        this.fQuery += '&target_lang=' + this.filter_target_lang;
      }  
      if ((type == 'native_lang' && evt.value != null && evt.value !== undefined)) {
        this.filter_native_lang = evt.value;
        this.fQuery += '&native_lang=' + this.filter_native_lang;
      }
      else if(type == 'native_lang' && evt.value == null)
      {
        let filter_native_lang='';
        this.fQuery += '&native_lang=' + filter_native_lang;
      }
      else if(this.filter_native_lang !=null){
        this.fQuery += '&native_lang=' + this.filter_native_lang;
      } 
    let query = this.fQuery;
    this.parseQueryStringApprove(this.fQuery);
    this.vendorService.getAdminApprovalPending(query)
    .subscribe((response) => {
        if(response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if(res.specialities!=null){
                specs += res.specialities.data.name+', ';
              }
            });
            elem.specs = specs.slice(0, -1);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array=[];
            let translation_rate_value ='';
            elem.vendortranslationlangs.data.forEach(res => {
              if(res.translation_rate!=null){
                translation_rate_value+=res.translation_rate;
            }
      
          if(res.bi_direction==2){
            res.bi_direction='<>';
        }else{
          res.bi_direction='>';
        }
          
        lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name 
        + '  Rate:$'+ translation_rate_value + '/word'+' Speed:' + res.speed + ' words' + ',' ;
        lang_array.push(lang);
            });
            if(lang_array.length > 0){
              let test;
              test = lang_array[lang_array.length -1 ];
              lang_array[lang_array.length - 1] = test.slice(0, -1);
          }
          elem.lang_array = lang_array;
          response.data.lang_array = lang_array;
          });
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
        }
    });

  }

  parseQueryStringApprove(queryString) {
    this.fChipsArrayApprove = [];
    var params = {
      native_lang: '',
      source_lang: '',
      target_lang: ''
    }, 
    queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.split('&');
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
      this.get_filter_valueApprove(params)
}

get_filter_valueApprove(data: any){
  const key = Object.keys(data);
      if (key[0] === 'native_lang')
  {
    if (data.native_lang !='') {
      this.languageData.forEach(element => {
        if(element.id === data.native_lang){
          this.fChipsArrayApprove.push(element.lang_name);
        }
      });
    }
  }

  if (key[1] === 'source_lang')
  {
    if (data.source_lang !== '') {
      this.fChipsArrayApprove.push(data.source_lang);
    }
  }
  if (key[2] === 'target_lang')
  {
    if (data.target_lang !== '') {
      this.fChipsArrayApprove.push(data.target_lang);
    }
  }
}


  sortFreelancer(evt){
    if(evt.direction === ''){
      evt.direction = 'asc';
    }
    this.query_freelancer_sort = '?page=' + this.pageIndex + 
    '&per_page=' + this.pageSize + '&sort=' + evt.active + '&order=' + evt.direction;
     this.vendorService.getAdminApprovalPending(this.query_freelancer_sort)
     .subscribe((response) => {
         if(response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if(res.specialities != null){
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -2);
          });
          response.data.forEach(elem => {
            let lang = '';
            const lang_array = [];
            let translation_rate_value = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if(res.translation_rate != null){
                translation_rate_value += res.translation_rate;
            }
          if(res.bi_direction === 2){
            res.bi_direction = '<>';
        }else{
          res.bi_direction = '>';
        }
          
        lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name
         + '  Rate:$'+translation_rate_value + '/word'+' Speed:' + res.speed + ' words' + ',' ;
        lang_array.push(lang);
            });
            if(lang_array.length > 0){
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1 );   
          }
          elem.lang_array = lang_array;
          response.data.lang_array = lang_array;
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
         }
     });
  }
}
