import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-language-change',
  templateUrl: './language-change.component.html',
  styleUrls: ['./language-change.component.scss']
})
export class LanguageChangeComponent implements OnInit {
  Form:any;
  languageData: any = [];
  source_language_name: any = [];
  target_language_name: any = [];
  dropdownSettings = {};
  dropdownList = [];
  constructor( private _formBuilder: FormBuilder,
    private vendorService : VendorService,  private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public id :any,
    private projectsService: ProjectsService,
    private dialogRef: MatDialogRef<LanguageChangeComponent>) { }

  ngOnInit() {
  
    this.getLanguage();
    this.getUniqueProjectLanguage();
    this.Form = this._formBuilder.group({
      source_language : ['',Validators.compose([Validators.required])],
      target_language:  ['',Validators.compose([Validators.required])]
  });

  this.dropdownSettings = {
    singleSelection: false,
    textField: 'lang_name',
    allowSearchFilter: true
  };
  }

  Onclose(){
    this.dialogRef.close(true);
}


 // get Source Language & Target Language 

 getLanguage() {
  this.projectsService.getAllLanguages()
    .subscribe((res: any) => {
      if (res) {

        this.languageData = res.data;
        this.dropdownList =  res.data;
      }
    });
}

// get unique project language
getUniqueProjectLanguage() {
  let data:any={
    project_id: this.id
  }

  this.projectsService.getUniqueProjectLanguage(data)
    .subscribe((response: any) => {
      if (response) {
        this.source_language_name  = [];
        this.source_language_name = [];
        // this.source_language_name = this.getLanguageName(response.source_language_name);
        // this.target_language_name = this.getLanguageName(response.target_language_name);
        if(response.source_language_name.length > 0){
          response.source_language_name.forEach(element => {
            this.source_language_name.push(element);
          });
        }
        if(response.target_language_name.length > 0){
          response.target_language_name.forEach(element => {
            this.target_language_name.push(element);
          });
        }
      
        this.Form.patchValue({
          source_language: this.source_language_name,
          target_language: this.target_language_name
        });
      }
    });
}

  submit(){
    let data: any={
      project_id:'',
      source_language:'',
      target_language:'',
      words:null
    }
    data.project_id= this.id;
    data.source_language = this.getLanguageName(this.Form.value.source_language);
    data.target_language = this.getLanguageName(this.Form.value.target_language);
    this.projectsService.updateProjectLanguage(data)
    .subscribe((response) => {
        if(response) {
          this.toastr.success("Project Language change successfully!");
         this.Onclose();

        }
    });
  }


  getLanguageName(res: any) {
    let lang = '';
    if(res.length>0){
      res.forEach(element => {
            lang += element.lang_name + ', ';
        });
    }
    lang = lang.slice(0, -2);
    return lang;
  }

  onItemSelect(item: any) {
  
  }
  onSelectAll(items: any) {

  }

  onItemDeSelect(items: any) {
  
  }

  onDeSelectAll(items: any) {
  }

}
