import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef } from '@angular/material';
var LanguageChangeComponent = /** @class */ (function () {
    function LanguageChangeComponent(_formBuilder, vendorService, toastr, id, projectsService, dialogRef) {
        this._formBuilder = _formBuilder;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.id = id;
        this.projectsService = projectsService;
        this.dialogRef = dialogRef;
        this.languageData = [];
        this.source_language_name = [];
        this.target_language_name = [];
        this.dropdownSettings = {};
        this.dropdownList = [];
    }
    LanguageChangeComponent.prototype.ngOnInit = function () {
        this.getLanguage();
        this.getUniqueProjectLanguage();
        this.Form = this._formBuilder.group({
            source_language: ['', Validators.compose([Validators.required])],
            target_language: ['', Validators.compose([Validators.required])]
        });
        this.dropdownSettings = {
            singleSelection: false,
            textField: 'lang_name',
            allowSearchFilter: true
        };
    };
    LanguageChangeComponent.prototype.Onclose = function () {
        this.dialogRef.close(true);
    };
    // get Source Language & Target Language 
    LanguageChangeComponent.prototype.getLanguage = function () {
        var _this = this;
        this.projectsService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
                _this.dropdownList = res.data;
            }
        });
    };
    // get unique project language
    LanguageChangeComponent.prototype.getUniqueProjectLanguage = function () {
        var _this = this;
        var data = {
            project_id: this.id
        };
        this.projectsService.getUniqueProjectLanguage(data)
            .subscribe(function (response) {
            if (response) {
                _this.source_language_name = [];
                _this.source_language_name = [];
                // this.source_language_name = this.getLanguageName(response.source_language_name);
                // this.target_language_name = this.getLanguageName(response.target_language_name);
                if (response.source_language_name.length > 0) {
                    response.source_language_name.forEach(function (element) {
                        _this.source_language_name.push(element);
                    });
                }
                if (response.target_language_name.length > 0) {
                    response.target_language_name.forEach(function (element) {
                        _this.target_language_name.push(element);
                    });
                }
                _this.Form.patchValue({
                    source_language: _this.source_language_name,
                    target_language: _this.target_language_name
                });
            }
        });
    };
    LanguageChangeComponent.prototype.submit = function () {
        var _this = this;
        var data = {
            project_id: '',
            source_language: '',
            target_language: '',
            words: null
        };
        data.project_id = this.id;
        data.source_language = this.getLanguageName(this.Form.value.source_language);
        data.target_language = this.getLanguageName(this.Form.value.target_language);
        this.projectsService.updateProjectLanguage(data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Project Language change successfully!");
                _this.Onclose();
            }
        });
    };
    LanguageChangeComponent.prototype.getLanguageName = function (res) {
        var lang = '';
        if (res.length > 0) {
            res.forEach(function (element) {
                lang += element.lang_name + ', ';
            });
        }
        lang = lang.slice(0, -2);
        return lang;
    };
    LanguageChangeComponent.prototype.onItemSelect = function (item) {
    };
    LanguageChangeComponent.prototype.onSelectAll = function (items) {
    };
    LanguageChangeComponent.prototype.onItemDeSelect = function (items) {
    };
    LanguageChangeComponent.prototype.onDeSelectAll = function (items) {
    };
    return LanguageChangeComponent;
}());
export { LanguageChangeComponent };
