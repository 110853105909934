<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>

  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
      Workflow Master
    </span>
  </div>

  <div class="white-box">

    <form name="addLocatinMasterForm">
        <div class="boxBodyInn">
      <div class="profHeader" fxLayout="row wrap">


        <div fxFlex="50">
          <p class="dLabel">Workflow Title</p>
          <p class="dValue">
            <mat-form-field  >
              <input matInput>
            </mat-form-field>
          </p>
        </div>

        
        </div>

      <div fxLayout="row wrap" fxLayoutGap="20px">


          <div fxFlex="100">
              <p class="dLabel">Workflow Notes</p>
              <p class="dValue">
                  <mat-form-field  >
                      <textarea matInput> </textarea>
                      </mat-form-field>
              </p>
            </div>
        </div>

          </div>

    </form>
    <div class="actWraper">

      <button matTooltip="Approve" mat-raised-button class="green-bg mr-8">
        <mat-label class="Text_Color">Submit</mat-label>
      </button>



    </div>









  </div>



  <div class="white-box" *ngIf="paginate?.total>0">
    <div class="tHeader">
      <h4>Services</h4>
    </div>
    <table mat-table [dataSource]="dataSource">

      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Workflow Title </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Workflow Description </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
     
      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> <button mat-stroked-button class="bicon" title="Edit"><i
              class="material-icons">
              edit
            </i></button> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    </table> 
    <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[5,10,20,30,40]" (page)='paginateData($event)'>
      </mat-paginator>
     
  </div>
  <div *ngIf="paginate?.total==0" class="noDataFound">
    No Data available!!
</div>
</div>