import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var StatusChangeAdditionalServiceComponent = /** @class */ (function () {
    function StatusChangeAdditionalServiceComponent(_formBuilder, data, dialogRef, toastr, projectsService) {
        this._formBuilder = _formBuilder;
        this.data = data;
        this.dialogRef = dialogRef;
        this.toastr = toastr;
        this.projectsService = projectsService;
    }
    StatusChangeAdditionalServiceComponent.prototype.ngOnInit = function () {
        if (this.data) {
            this.project_assignment_id = this.data.project_assignment_id;
        }
        this.Form = this._formBuilder.group({
            status: ['', Validators.compose([Validators.required])],
            final_comment: ['', Validators.compose([Validators.required])]
        });
    };
    StatusChangeAdditionalServiceComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    StatusChangeAdditionalServiceComponent.prototype.submit = function () {
        var _this = this;
        var assignment_obj;
        assignment_obj = this.data;
        assignment_obj.final_comment = this.Form.value.final_comment;
        assignment_obj.status = this.Form.value.status;
        this.projectsService.changeStatusAdditionalService(assignment_obj)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Status Updated!!");
                _this.dialogRef.close(true);
                //  window.location.href = '/pms/project/details/'+this.data.project_id;
            }
        });
    };
    return StatusChangeAdditionalServiceComponent;
}());
export { StatusChangeAdditionalServiceComponent };
