import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
var EditTemplateMasterComponent = /** @class */ (function () {
    function EditTemplateMasterComponent(dialogRef, id, vendorService, toastr, router, route, _formBuilder) {
        this.dialogRef = dialogRef;
        this.id = id;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this._formBuilder = _formBuilder;
    }
    EditTemplateMasterComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    EditTemplateMasterComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getTemplateType();
        this.config = {
            width: '1000px',
            height: '155px',
            toolbar: [
                { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'] },
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
                { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
                { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                '/',
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
                { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-',
                        'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft',
                        'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley',
                        'SpecialChar', 'PageBreak', 'Iframe'] },
                '/',
                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                { name: 'colors', items: ['TextColor', 'BGColor'] },
                { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
                { name: 'about', items: ['About'] }
            ]
            // toolbar: [
            //     { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
            //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }
            // ]
        };
        this.Form = this._formBuilder.group({
            title: ['', Validators.compose([Validators.required])],
            subject: ['', Validators.compose([Validators.required])],
            ckeditorContent: [''],
            status: ['0'],
            email_from: [''],
            email_to: [''],
            type: ['']
        });
        this.get_quote_email();
    };
    EditTemplateMasterComponent.prototype.updateMessage = function () {
        var _this = this;
        if (this.Form.value.subject == '') {
            this.toastr.error('Subject never be empty!!');
            return;
        }
        if (this.Form.value.ckeditorContent == '') {
            this.toastr.error('Body never be empty!!');
            return;
        }
        var data = {
            name: '',
            body: '',
            subject: '',
            is_active: '',
            email_from: '',
            email_to: '',
            type: ''
        };
        data.name = this.Form.value.title;
        data.body = this.Form.value.ckeditorContent;
        data.subject = this.Form.value.subject;
        data.is_active = this.Form.value.status;
        data.email_from = this.Form.value.email_from;
        data.email_to = this.Form.value.email_to;
        data.type = this.Form.value.type;
        this.vendorService.updateEmailTemplate(this.id, data)
            .subscribe(function (response) {
            if (response) {
                //  this.router.navigate(['/pms/project/details/'+this.id.project_id]);
                _this.toastr.success('Template updated!!');
                _this.Onclose();
            }
        });
    };
    EditTemplateMasterComponent.prototype.get_quote_email = function () {
        var _this = this;
        this.vendorService.getSingleEmailTemplate(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.Form.patchValue({
                    ckeditorContent: response.data.body,
                    subject: response.data.subject,
                    title: response.data.name,
                    status: response.data.is_active,
                    email_from: response.data.email_from,
                    email_to: response.data.email_to,
                    type: response.data.type
                });
            }
        });
    };
    EditTemplateMasterComponent.prototype.getTemplateType = function () {
        var _this = this;
        var query = '';
        this.vendorService.getTemplateType(query)
            .subscribe(function (res) {
            if (res) {
                _this.templateType = res;
            }
        });
    };
    return EditTemplateMasterComponent;
}());
export { EditTemplateMasterComponent };
