<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1">
      Additional Services Master
    </span>
  </div>

  <div class="white-box">
    <form name="addLocatinMasterForm">
        <div class="boxBodyInn">
      <div class="profHeader" fxLayout="row wrap">
        <div fxFlex="50">
          <p class="dLabel">Additional Services Title</p>
          <p class="dValue">
            <mat-form-field  >
              <input matInput>
            </mat-form-field>
          </p>
        </div>
        </div>
      <div fxLayout="row wrap" fxLayoutGap="20px">
          <div fxFlex="100">
              <p class="dLabel">Notes</p>
              <p class="dValue">
                  <mat-form-field  >
                      <textarea matInput> </textarea>
                      </mat-form-field>
              </p>
            </div>
        </div>
          </div>
    </form>
    <div class="actWraper">
      <button matTooltip="Approve" mat-raised-button class="green-bg mr-8">
        <mat-label class="Text_Color">Submit</mat-label>
      </button>
    </div>
  </div>

  <div class="white-box">
    <div class="tHeader">
      <h4>Services</h4>
    </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>
      <ng-container matColumnDef="addServTitle">
        <th mat-header-cell *matHeaderCellDef> Service Title </th>
        <td mat-cell *matCellDef="let element"> {{element.addServTitle}} </td>
      </ng-container>
      <ng-container matColumnDef="addServDesc">
        <th mat-header-cell *matHeaderCellDef> Service Description </th>
        <td mat-cell *matCellDef="let element"> {{element.addServDesc}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> <button mat-stroked-button class="bicon" title="Edit"><i
              class="material-icons">
              edit
            </i></button> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>