import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort } from '@angular/material';
import { UserService } from 'app/main/Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
var UserComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    function UserComponent(router, userService, toastr) {
        this.router = router;
        this.userService = userService;
        this.toastr = toastr;
        this.paginate = {};
        this.pageSize = 10;
        this.pageIndex = 1;
        this.roleData = [];
        this.searchQuery = '';
        this.filterStatus = '';
        this.filterRole = '';
        this.filterPortal_Access = '';
        this.fQuery = '';
        this.query_user = '';
        this.query_user_sort = '';
        this.fChipsArray = [];
        this.displayedColumns = ['fullname', 'user_group_id', 'email', 'portal_access', 'last_login', 'status', 'actions'];
        this.filterSearchQuery = '';
    }
    UserComponent.prototype.ngOnInit = function () {
        this.getRole();
        this.getData();
    };
    UserComponent.prototype.getData = function () {
        var _this = this;
        var query = 'page=1&per_page=10';
        this.userService.getAll(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.total_user = response.meta.pagination.total;
            }
        });
    };
    UserComponent.prototype.paginateData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_user = 'page=' + this.pageIndex + '&per_page=' + this.pageSize;
        if (this.filterStatus !== '') {
            uQuery += '&status=' + this.filterStatus;
        }
        if (this.filterRole !== '') {
            uQuery += '&role=' + this.filterRole;
        }
        this.query_user += uQuery;
        this.userService.getAll(this.query_user)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.total_user = response.meta.pagination.total;
            }
        });
    };
    UserComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '';
        // project search filter
        if ((type == 'search' && evt != '' && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += '&search_text=' + this.filterSearchQuery;
        }
        else if (type == 'search' && evt == '') {
            this.filterSearchQuery = evt;
        }
        else if (this.filterSearchQuery !== '') {
            this.fQuery += '&search_text=' + this.filterSearchQuery;
        }
        if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
            this.filterStatus = evt.value;
            this.fQuery += '&status=' + this.filterStatus;
        }
        else if (type == 'status' && evt.value == '') {
            this.filterStatus = evt.value;
        }
        else if (this.filterStatus !== '') {
            this.fQuery += '&status=' + this.filterStatus;
        }
        if ((type == 'role' && evt.value != '' && evt.value !== undefined)) {
            this.filterRole = evt.value;
            this.fQuery += '&role=' + this.filterRole;
        }
        else if (type == 'role' && evt.value == '') {
            this.filterRole = evt.value;
        }
        else if (this.filterRole !== '') {
            this.fQuery += '&role=' + this.filterRole;
        }
        if ((type == 'portal_access' && evt.value != '' && evt.value !== undefined)) {
            this.filterPortal_Access = evt.value;
            this.fQuery += '&portal_access=' + this.filterPortal_Access;
        }
        else if (type == 'portal_access' && evt.value == '') {
            this.filterPortal_Access = evt.value;
        }
        else if (this.filterPortal_Access !== '') {
            this.fQuery += '&portal_access=' + this.filterPortal_Access;
        }
        this.parseQueryString(this.fQuery);
        var query = this.fQuery;
        this.userService.getAll(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.total_user = response.meta.pagination.total;
            }
        });
    };
    UserComponent.prototype.parseQueryString = function (queryString) {
        this.fChipsArray = [];
        var params = {
            status: '',
            role: '',
            portal_access: ''
        }, queries, temp, i, l;
        // Split into key/value pairs
        queries = queryString.split('&');
        // Convert the array of strings into an object
        for (i = 0, l = queries.length; i < l; i++) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        this.get_filter_value(params);
    };
    UserComponent.prototype.get_filter_value = function (data) {
        var key = Object.keys(data);
        if (key[0] == 'status') {
            if (data.status != '') {
                if (data.status == 1) {
                    this.fChipsArray.push('Active');
                }
                else {
                    this.fChipsArray.push('Inactive');
                }
            }
        }
        if (key[1] == 'role') {
            if (data.role != '') {
                this.fChipsArray.push(data.role);
            }
        }
        if (key[2] == 'portal_access') {
            if (data.portal_access != '') {
                if (data.portal_access == 1) {
                    this.fChipsArray.push('Admin');
                }
                else if (data.portal_access == 2) {
                    this.fChipsArray.push('Hr');
                }
                else if (data.portal_access == 3) {
                    this.fChipsArray.push('PMS');
                }
                else if (data.portal_access == 4) {
                    this.fChipsArray.push('Finance');
                }
            }
        }
    };
    UserComponent.prototype.getRole = function () {
        var _this = this;
        this.userService.getRole()
            .subscribe(function (res) {
            if (res) {
                _this.roleData = res.data;
            }
        });
    };
    UserComponent.prototype.editData = function (value) {
        this.router.navigate(['/user-management/' + value.id]);
    };
    UserComponent.prototype.sortUser = function (evt) {
        var _this = this;
        if (evt.direction == '') {
            evt.direction = 'asc';
        }
        this.query_user_sort = 'page=' + this.pageIndex + '&per_page='
            + this.pageSize + '&sort=' + evt.active + '&order=' + evt.direction;
        this.userService.getAll(this.query_user_sort)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    return UserComponent;
}());
export { UserComponent };
