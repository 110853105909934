import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { UserComponent } from './user/user.component';
import { ReportsComponent } from './reports/reports.component';
import { ApprovalComponent } from './approval/approval.component';
import { MatCardModule } from "@angular/material/card";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatDatepickerModule,
    MatTableModule,
    MatDividerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule
} from "@angular/material";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AddUserComponent } from './user/add-user/add-user.component';
import { ReviewTranslatorComponent } from './user/review-translator/review-translator.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { EditReviewTranslatorComponent } from './user/edit-review-translator/edit-review-translator.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AdminGuard } from 'app/_guards/admin.guard';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { LanguageMasterComponent } from './masterdata/language-master/language-master.component';
import { LocationMasterComponent } from './masterdata/location-master/location-master.component';
import { AdditionalServicesMasterComponent } from './masterdata/additional-services-master/additional-services-master.component';
import { ServicesMasterComponent } from './masterdata/services-master/services-master.component';
import { WorkflowMasterComponent } from './masterdata/workflow-master/workflow-master.component';
import { TemplateMasterComponent } from './masterdata/template-master/template-master.component';
import { EditTemplateMasterComponent } from './masterdata/template-master/edit-template-master/edit-template-master.component';
import { MatToolbarModule } from "@angular/material/toolbar";
import { CKEditorModule } from 'ng2-ckeditor';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketDetailComponent } from './tickets/ticket-detail/ticket-detail.component';
const routes = [
    {
        path     : 'user-management',
        component: UserComponent,
        canActivate: [AdminGuard] 
    },
    {
        path     : 'user-management/add-user',
        component: AddUserComponent,
        canActivate: [AdminGuard]
    },

    {
        path: "user-management/:id",
        component: AddUserComponent,
        canActivate: [AdminGuard]
    },

    {
        path     : 'approval/review-translator/:id',
        component: ReviewTranslatorComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'approval/edit-review-translator',
        component: EditReviewTranslatorComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'reports',
        component: ReportsComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'approval',
        component: ApprovalComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'admin-dashboard',
        component: AdminDashboardComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'admin-profile',
        component: AdminProfileComponent
    }
    ,
    {
        path     : 'masterdata',
        component: MasterdataComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'masterdata/language-master',
        component: LanguageMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'masterdata/location-master',
        component: LocationMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'masterdata/additionalservices-master',
        component: AdditionalServicesMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'masterdata/services-master',
        component: ServicesMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'masterdata/template-master',
        component: TemplateMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'masterdata/workflow-master',
        component: WorkflowMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'tickets',
        component: TicketsComponent,
        canActivate: [AdminGuard]
    },
    {
        path     : 'tickets/:id',
        component: TicketDetailComponent,
        canActivate: [AdminGuard]
    }
];

@NgModule({
    declarations: [
        UserComponent,
        ReportsComponent,
        ApprovalComponent,
        AddUserComponent,
        ReviewTranslatorComponent,
        AdminDashboardComponent,
        EditReviewTranslatorComponent,
        AdminProfileComponent,
        MasterdataComponent,
        LanguageMasterComponent,
        LocationMasterComponent,
        AdditionalServicesMasterComponent,
        ServicesMasterComponent,
        WorkflowMasterComponent,
        TemplateMasterComponent,
        EditTemplateMasterComponent,
        TicketsComponent,
        TicketDetailComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        TranslateModule,
        CKEditorModule,
        MatGridListModule,
        MatToolbarModule,
        MatCardModule,
        FuseSharedModule,
        MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatRadioModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatDatepickerModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    DragDropModule
    ],
    exports     : [
        UserComponent,
        ReportsComponent,
        ApprovalComponent,
        DragDropModule
    ],
    entryComponents: [
        EditTemplateMasterComponent
    ],
})
export class AdminModule
{
    
}
