import { OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { SharedService } from 'app/main/shared.service';
var WelcomeComponent = /** @class */ (function () {
    /**
     * Constructor
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    function WelcomeComponent(_fuseConfigService, _fuseTranslationLoaderService) {
        this._fuseConfigService = _fuseConfigService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.admin = false;
        this.hr = false;
        this.pms = false;
        this.finance = false;
        this.marketing = false;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: false
                },
                footer: {
                    hidden: false
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }
    WelcomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var portal_ids = currentUser.data.portal_ids;
        var user_group_id = currentUser.data.user_group_id;
        this.user_name = SharedService.get('username');
        if (user_group_id == "Admin") {
            portal_ids.forEach(function (res) {
                if (res == 1) {
                    _this.admin = true;
                }
                if (res == 2) {
                    _this.hr = true;
                }
                if (res == 3) {
                    _this.pms = true;
                }
                if (res == 4) {
                    _this.finance = true;
                }
                if (res == 5) {
                    _this.marketing = true;
                }
            });
        }
        else {
            portal_ids.forEach(function (res) {
                // On Welcome Screen: Allow access based on portal_access change start
                // if(res==1 ){
                //     this.admin=true;
                // }
                // On Welcome Screen: Allow access based on portal_access change end
                if (res == 2) {
                    _this.hr = true;
                }
                if (res == 3) {
                    _this.pms = true;
                }
                if (res == 4) {
                    _this.finance = true;
                }
                if (res == 5) {
                    _this.marketing = true;
                }
            });
        }
    };
    WelcomeComponent.prototype.admin_select = function () {
        window.location.href = '/admin-dashboard';
    };
    WelcomeComponent.prototype.hr_select = function () {
        window.location.href = '/hr/hr-dashboard';
    };
    WelcomeComponent.prototype.marketing_select = function () {
        window.location.href = '/marketing/marketing-dashboard';
    };
    WelcomeComponent.prototype.pms_select = function () {
        //window.location.href = '/pms/dashboard';
        window.location.href = '/pms/freelancer';
    };
    return WelcomeComponent;
}());
export { WelcomeComponent };
