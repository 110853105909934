import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef } from '@angular/material';
var UpdateServiceTypeComponent = /** @class */ (function () {
    function UpdateServiceTypeComponent(_formBuilder, toastr, data, projectsService, dialogRef) {
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.data = data;
        this.projectsService = projectsService;
        this.dialogRef = dialogRef;
        this.specialityData = [];
        this.today = new Date();
        this.minDate = this.today;
    }
    UpdateServiceTypeComponent.prototype.ngOnInit = function () {
        this.Form = this._formBuilder.group({
            translation_type: ['', Validators.compose([Validators.required])]
        });
        this.Form.patchValue({
            translation_type: this.data.translation_type
        });
    };
    UpdateServiceTypeComponent.prototype.Onclose = function () {
        this.dialogRef.close(true);
    };
    UpdateServiceTypeComponent.prototype.submit = function () {
        var _this = this;
        var final_data = {
            translation_type: this.Form.value.translation_type
        };
        this.projectsService.updateServiceType(this.data.project_id, final_data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Service type update successfully!");
                _this.Onclose();
            }
        });
    };
    // date format
    UpdateServiceTypeComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt + ' 00:00:00';
    };
    return UpdateServiceTypeComponent;
}());
export { UpdateServiceTypeComponent };
