import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UserService } from 'app/main/Services/user.service';
import { Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'app/main/shared.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
var AdminProfileComponent = /** @class */ (function () {
    function AdminProfileComponent(_formBuilder, userService, router, toastr) {
        this._formBuilder = _formBuilder;
        this.userService = userService;
        this.router = router;
        this.toastr = toastr;
        this.profile_file_id = 0;
        this.fileError = '';
        this.file_multiple_data = [];
        this.uploaded_files = [];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    AdminProfileComponent.prototype.ngOnInit = function () {
        this.id = SharedService.get('id');
        this.getUserData();
        this.profileForm = this._formBuilder.group({
            fullname: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            status: [],
        });
        this.changeForm = this._formBuilder.group({
            old_password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
            new_password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
            confirm_password: [
                '',
                [
                    confirmPasswordValidator,
                    Validators.required,
                    Validators.minLength(6)
                ]
            ],
        });
    };
    AdminProfileComponent.prototype.updateForm = function () {
        var _this = this;
        var data = {
            file_id: ''
        };
        if (this.profile_file_id !== 0) {
            data.file_id = this.profile_file_id;
        }
        this.userService.updateProfileImage(data).subscribe(function (resposne) {
            if (resposne) {
                SharedService.set(SharedService.PROFILE_PIC, _this.vendor_file_data);
                SharedService.set(SharedService.USER_NAME, _this.profileForm.value.fullname);
                _this.toastr.success(resposne.response);
                window.location.href = '/admin-profile';
            }
        });
    };
    AdminProfileComponent.prototype.updatePassword = function () {
        var _this = this;
        if (this.changeForm.value.confirm_password != '' && this.changeForm.value.new_password != '' && this.changeForm.value.old_password != '') {
            this.dataPassword = this.changeForm.value;
            this.dataPassword.email = this.profileForm.value.email;
            this.dataPassword.fullname = this.profileForm.value.fullname;
            this.dataPassword.status = this.profileForm.value.status;
            this.userService.update(this.id, this.dataPassword).subscribe(function (resposne) {
                if (resposne) {
                    _this.toastr.success('Password updated successfully');
                    window.location.href = '/admin-profile';
                }
            });
        }
        else {
            this.toastr.error('please select all password fields');
        }
    };
    AdminProfileComponent.prototype.getUserData = function () {
        var _this = this;
        this.userService.getUser(this.id)
            .subscribe(function (response) {
            if (response) {
                if (response.data.profile_file_id != null) {
                    _this.profile_file_id = response.data.profile_file_id.id;
                    _this.vendor_file_data = _this.BaseUrl_file + response.data.profile_file_id.path;
                }
                if (response.data.user_portal.length > 0) {
                    var portal_access_value_1 = '';
                    response.data.user_portal.forEach(function (elem) {
                        portal_access_value_1 += elem.access_title + ', ';
                    });
                    response.data.portal_access_value = portal_access_value_1.slice(0, -2);
                }
                _this.userDetail = response.data;
                _this.profileForm.patchValue({
                    fullname: response.data.fullname,
                    email: response.data.email,
                    status: response.data.status.toString(),
                });
            }
        });
    };
    // start multiple file upload
    AdminProfileComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    // addDocument
    AdminProfileComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.userService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files = response.data;
            response.data.forEach(function (element) {
                _this.profile_file_id = element.id;
                _this.vendor_file_data = _this.BaseUrl_file + '/uploads/' + element.name;
            });
            _this.fileSuccess = 'Uploaded successfully!!';
        });
    };
    return AdminProfileComponent;
}());
export { AdminProfileComponent };
export var confirmPasswordValidator = function (control) {
    if (!control.parent || !control) {
        return null;
    }
    var password = control.parent.get('new_password');
    var passwordConfirm = control.parent.get('confirm_password');
    if (!password || !passwordConfirm) {
        return null;
    }
    if (passwordConfirm.value === '') {
        return null;
    }
    if (password.value === passwordConfirm.value) {
        return null;
    }
    return { passwordsNotMatching: true };
};
