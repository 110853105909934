<!-- <div class="inputDisplay">
  <mat-form-field>
    <mat-label>Enter mobile number with country code e.g +1234567890</mat-label>
    <input matInput>
  </mat-form-field>
</div> -->
<div class="dialog-content-wrapper"  style="position:relative;">
  <div class="tHeader">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <div fxFlex>
        <h4 class="m0 title dialog-title text-uppercase">New Message</h4>
      </div>
      <div fxFlex class="align-right">
          <button mat-icon-button (click)="Onclose()"  matTooltip="Close" aria-label="Close dialog">
                 <mat-icon>close</mat-icon>
          </button>
        </div>
  </div>
  </div>
    
    
    <div mat-dialog-content class="p-16 pt-4 m-0" fusePerfectScrollbar>
      <form class="mat-employee-form" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
         name="Form" [formGroup]="Form">
        <div fxLayout="row" class="mt-8" fxLayoutAlign="start" >
          <mat-form-field fxFlex="100">
            <mat-label>Enter mobile number with country code e.g +1234567890</mat-label>
            <input matInput formControlName="recipient">
            <mat-error *ngIf="Form.get('recipient').hasError('required')">
              Mobile number is required!
            </mat-error>
            <mat-error *ngIf="Form.get('recipient').hasError('pattern')">
              Enter mobile number with country code e.g +1234567890
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row " fxLayoutAlign="start" >
          <div fxFlex="100">
              <mat-form-field >
                <mat-label>Type a message</mat-label>
                <textarea matInput formControlName="body"> </textarea> 
                <mat-error *ngIf="Form.get('body').hasError('required')">
                  message is required!
                </mat-error>
              </mat-form-field>
          </div>
        </div>
      <div fxLayoutAlign="space-between end" >
            <button mat-raised-button color="primary" (click)="submit()" [disabled]="Form.invalid">Submit</button>
         </div>
        </form>
    </div>    
    </div>