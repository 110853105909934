import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {
  Form:any;
  constructor(private _formBuilder: FormBuilder, private toastr: ToastrService,
    private projectsService: ProjectsService, 
    public dialogRef: MatDialogRef<SendMessageComponent>) { }

  ngOnInit() {
    this.Form = this._formBuilder.group({
      recipient   : ['', Validators.compose([Validators.required, Validators.pattern("^\\+(?:[0-9] ?){10,14}[0-9]$")])],
      body : ['', Validators.compose([Validators.required])]
      
  });
  }

  Onclose(){
    this.dialogRef.close(true);
}


submit() {
  const recipientFormat = this.Form.value.recipient.replace("+", "");
  if (this.Form.valid) {
   let obj ={
    recipient: recipientFormat,
     body: this.Form.value.body
   }

  this.projectsService.sendSmsto(obj)
    .subscribe(
      (response: any) => {
        this.toastr.success("Message sent Successfully");
            this.dialogRef.close(true);
      }
    );
  }
}
}
