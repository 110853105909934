import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var HrGuard = /** @class */ (function () {
    function HrGuard(router) {
        this.router = router;
    }
    HrGuard.prototype.canActivate = function (route, state) {
        var roleData = SharedService.get(SharedService.user_group_id);
        if (SharedService.get(SharedService.LOGIN) == 'true' && (roleData == 'Hr-Admin' || roleData == 'Admin' || roleData == 'Project-Manager')) {
            return true;
        }
        this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    HrGuard.ngInjectableDef = i0.defineInjectable({ factory: function HrGuard_Factory() { return new HrGuard(i0.inject(i1.Router)); }, token: HrGuard, providedIn: "root" });
    return HrGuard;
}());
export { HrGuard };
