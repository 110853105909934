<div *ngFor="let message of messageList">
{{message}}
</div>

<div>
  <input [(ngModel)]="newMessage" (keyup)="$event.keyCode == 13 && sendMessage()" />
  <button (click)="sendMessage()">Send Message</button> 
</div>


<!-- <div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
    <div class="white-box">
      <div class="tHeader">
        <h2>Knowledge Base</h2>
        </div>
  <div class="boxBodyInn">

<h2>DayTMS Release Notes</h2>

<p><b>Current Version:</b> <span class="bdg blank">V1.0</span></p>
    
<p>Please keep in mind that the following listed major releases of DayTMS Platform are supported. </p>





      </div>

      <div class="actWraper">
          <p>Couldn't Find The Help You Need?</p>

        <button mat-flat-button color="accent" class="mr-16" routerLink="/pms/support">Contact Support</button>

      </div>


</div>





</div> -->