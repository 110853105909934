import { Component, ViewChild, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/main/Services/user.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  dataSource: any;
  paginate: any = {};
  fChipsArray: any = [];
  fChipsArrayApprove: any = [];
  paginate1: any = {};
  pageSize: number = 10;
  searchQuery: string = '';
  filterStatus: string = '';
  query_freelancer_sort: string = '';
  filterRole: string = '';
  specialityData: any = [];
  languageData: any = [];
  userCount: any;
  roleData: any = [];
  fQuery: string = '';
  filterlang_pair: string = '';
  filterSpecialization: string = '';
  filterPortal_Access:string='';
  filter_src_lang: string = '';
  filter_target_lang: string = '';
  query_approve: string = '';
  query_user_sort: string = '';
  query_user: string = '';
  filter_native_lang: number = null;
  fQuery1: string = '';
  pageIndex: number = 1;
  pageSize_approval: number = 6;
  pageIndex_approval: number = 1;
  total_user: number;
  total_vendor: number;
  filterSearchQuery:string='';
  filterSearchQueryApproval:string='';
  displayedColumns = ['fullname', 'user_group_id', 'email', 'portal_access', 'last_login', 'status', 'actions'];
  dataSource1: any;
  VendordisplayedColumns = ['first_name', 'email', 'native_language', 'language', 'stage'];
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatSort)
  sort2: MatSort;
  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;
  @ViewChild('paginator2', { read: MatPaginator }) paginator2: MatPaginator;
  constructor(private router: Router,
    private userService: UserService,
    private vendorService: VendorService,
    private toastr: ToastrService, private _fuseNavigationService: FuseNavigationService, ) {
  }

  ngOnInit(): void {
    this.getLanguage();
    this.getUserCount();
    this.getSpeciality();
    this.getRole();
    this.getDataUser();
    this.getDataVendor();
  }

  getRole() {
    this.userService.getRole()
      .subscribe((res: any) => {
        if (res) {
          this.roleData = res.data;
        }
      }
      );
  }

  getDataUser() {
    let query = 'page=1&per_page=6';
    this.userService.getAll(query)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let user_portal = '';
            elem.user_portal.forEach(res => {
              user_portal += res.access_title + ', ';
            });
            elem.user_portal = user_portal.slice(0, -2);
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
          this.total_user = response.meta.pagination.total;
        }
      }
      );
  }

  getDataVendor() {
    let query = '?page=1&per_page=6';
    this.vendorService.getAdminApprovalPending(query)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -1);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if (res.translation_rate != null) {
                translation_rate_value += res.translation_rate;
              }
              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }
              lang = res.sourcelanguage.data.lang_name + res.bi_direction +
               res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)
            }
            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource1 = new MatTableDataSource(response.data);
          this.paginate1 = response.meta.pagination;
          this.total_vendor = response.meta.pagination.total;
        }
      }
      );
  }


  userMangement() {
    this.router.navigate(['user-management']);
  }

  Approval() {
    this.router.navigate(['/approval']);
  }


  applyFilterVendor(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  onFilter(evt, type) {
    this.fQuery = '';
          // project search filter
          if ((type == 'search' && evt != '' && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += '&search_text=' + this.filterSearchQuery;
           }
          else if(type == 'search' && evt == ''){
            this.filterSearchQuery = evt;
          }
          else if(this.filterSearchQuery!==''){
            this.fQuery += '&search_text=' + this.filterSearchQuery;
          }
    if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
      this.filterStatus = evt.value;
      this.fQuery += '&status=' + this.filterStatus;
    }
    else if (type == 'status' && evt.value == '') {
      this.filterStatus = evt.value;
    }
    else if (this.filterStatus !== '') {
      this.fQuery += '&status=' + this.filterStatus;
    }

    if ((type == 'role' && evt.value != '' && evt.value !== undefined)) {
      this.filterRole = evt.value;
      this.fQuery += '&role=' + this.filterRole;
    }
    else if (type == 'role' && evt.value == '') {
      this.filterRole = evt.value;
    }
    else if (this.filterRole !== '') {
      this.fQuery += '&role=' + this.filterRole;
    }

    if ((type == 'portal_access' && evt.value != '' && evt.value !== undefined)) {
      this.filterPortal_Access = evt.value;

      this.fQuery += '&portal_access=' + this.filterPortal_Access;
  }
  else if(type == 'portal_access' && evt.value == ''){
      this.filterPortal_Access = evt.value;
      
  }
  else if(this.filterPortal_Access!==''){
      this.fQuery += '&portal_access=' + this.filterPortal_Access;
  }
    const query = this.fQuery;
    this.parseQueryString(this.fQuery);
    this.userService.getAll(query)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let user_portal = '';
            elem.user_portal.forEach(res => {
              user_portal += res.access_title + ', ';
            });
            elem.user_portal = user_portal.slice(0, -2);
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
          this.total_user = response.meta.pagination.total;
        }
      }
      );
  }

  parseQueryString(queryString) {
    this.fChipsArray = [];
    let params = {
      status: '',
      role: '',
      portal_access:''
    },
      queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.split('&');
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
    this.get_filter_value(params)
  }

  get_filter_value(data) {
    let key = Object.keys(data);
    if (key[0] == 'status') {
      if (data.status != '') {
        if (data.status == 1) {
          this.fChipsArray.push('Active');
        } else {
          this.fChipsArray.push('Inactive');
        }
      }
    }

    if (key[1] == 'role') {
      if (data.role != '') {
        this.fChipsArray.push(data.role);
      }
    }

    if (key[2] == 'portal_access')
    {
      
        if(data.portal_access!=''){
            if (data.portal_access == 1) {
                this.fChipsArray.push('Admin');
              }else if (data.portal_access == 2) {
                this.fChipsArray.push('Hr');
              }else if (data.portal_access == 3) {
                this.fChipsArray.push('PMS');
              }else if (data.portal_access == 4) {
                this.fChipsArray.push('Finance');
              }
          }
   
    }
  }

  onFilterSpeciality(evt, type) {
    this.fQuery1 = '?page=1&per_page=10';

       // project search filter
       if ((type == 'search' && evt != '' && evt !== undefined)) {
        this.filterSearchQueryApproval = evt;
        this.fQuery1 += '&search_text=' + this.filterSearchQueryApproval;
       }
      else if(type == 'search' && evt == ''){
        this.filterSearchQueryApproval = evt;
      }
      else if(this.filterSearchQueryApproval!==''){
        this.fQuery1 += '&search_text=' + this.filterSearchQueryApproval;
      }

    if ((type == 'speciality' && evt.value != '' && evt.value !== undefined)) {
      this.filterSpecialization = evt.value;
      this.fQuery1 += '&specialization=' + this.filterSpecialization;
    }
    else if (type == 'speciality' && evt.value == '') {
      this.filterSpecialization = evt.value;
    }
    else if (this.filterSpecialization !== '') {
      this.fQuery1 += '&specialization=' + this.filterSpecialization;
    }
    if ((type == 's_lang' && evt.value != '' && evt.value !== undefined)) {
      this.filter_src_lang = evt.value;
      this.fQuery1 += '&source_lang=' + this.filter_src_lang;
    }
    else if (type == 's_lang' && evt.value == '') {
      this.filter_src_lang = evt.value;
    }
    else if (this.filter_src_lang !== '') {
      this.fQuery1 += '&source_lang=' + this.filter_src_lang;
    }
    if ((type == 't_lang' && evt.value != '' && evt.value !== undefined)) {
      this.filter_target_lang = evt.value;
      this.fQuery1 += '&target_lang=' + this.filter_target_lang;
    }
    else if (type == 't_lang' && evt.value == '') {
      this.filter_target_lang = evt.value;
    }
    else if (this.filter_target_lang !== '') {
      this.fQuery1 += '&target_lang=' + this.filter_target_lang;
    }
    if ((type == 'native_lang' && evt.value != null && evt.value !== undefined)) {
      this.filter_native_lang = evt.value;
      this.fQuery1 += '&native_lang=' + this.filter_native_lang;
    }
    else if (type == 'native_lang' && evt.value == null) {
      evt.value = '';
      this.filter_native_lang = evt.value;
    }
    else if (this.filter_native_lang != null) {
      this.fQuery1 += '&native_lang=' + this.filter_native_lang;
    }
    let query = this.fQuery1;
    this.parseQueryStringApprove(this.fQuery1);
    this.vendorService.getAdminApprovalPending(query)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -1);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if (res.translation_rate != null) {
                translation_rate_value += res.translation_rate;
              }
              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }
              lang = res.sourcelanguage.data.lang_name + res.bi_direction +
               res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)
            }
            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource1 = new MatTableDataSource(response.data);
          this.paginate1 = response.meta.pagination;
          this.total_vendor = response.meta.pagination.total;
        }
      });
  }

  parseQueryStringApprove(queryString) {
    this.fChipsArrayApprove = [];
    var params = {
      native_lang: '',
      source_lang: '',
      target_lang: ''
    },
      queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.split('&');
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
    this.get_filter_valueApprove(params)
  }

  get_filter_valueApprove(data) {
    let key = Object.keys(data);
    if (key[0] == 'native_lang') {
      if (data.native_lang != '') {
        this.languageData.forEach(element => {
          if (element.id == data.native_lang) {
            this.fChipsArrayApprove.push(element.lang_name);
          }
        });
      }
    }
    if (key[1] == 'source_lang') {
      if (data.source_lang != '') {
        this.fChipsArrayApprove.push(data.source_lang);
      }
    }
    if (key[2] == 'target_lang') {
      if (data.target_lang != '') {
        this.fChipsArrayApprove.push(data.target_lang);
      }
    }
  }
  paginateData_approve(event) {
    let aQuery = ''
    this.pageIndex_approval = event.pageIndex + 1;
    if (this.pageIndex_approval < 0) {
      this.pageIndex_approval = 1;
    }
    this.pageSize_approval = event.pageSize;
    this.query_approve = '?page=' + this.pageIndex_approval + '&per_page=' + this.pageSize_approval;
    if (this.filterSpecialization !== '') {
      aQuery += '&specialization=' + this.filterSpecialization;
    }
    if (this.filter_src_lang !== '') {
      aQuery += '&source_lang=' + this.filter_src_lang;
    }
    if (this.filter_target_lang !== '') {
      aQuery += '&target_lang=' + this.filter_target_lang;
    }
    if (this.filter_native_lang !== null) {
      aQuery += '&native_lang=' + this.filter_native_lang;
    }
    else {
      let filter_native_lang = '';
      aQuery += '&native_lang=' + filter_native_lang;
    }
    this.query_approve += aQuery;
    this.vendorService.getAdminApprovalPending(this.query_approve)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -1);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if (res.translation_rate != null) {
                translation_rate_value += res.translation_rate;
              }
              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }
              lang = res.sourcelanguage.data.lang_name + 
              res.bi_direction + res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)
            }
            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource1 = new MatTableDataSource(response.data);
          this.paginate1 = response.meta.pagination;
          this.total_vendor = response.meta.pagination.total;
        }
      });
  }

  getSpeciality() {
    this.vendorService.getSpeciality()
      .subscribe((res) => {
        if (res) {
          this.specialityData = res;
        }
      });
  }

  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res: any) => {
        if (res) {
          this.languageData = res.data;
        }
      });
  }

  getUserCount() {
    this.vendorService.getUserCount()
      .subscribe((res) => {
        if (res) {
          this.userCount = res;
        }
      });
  }

  paginateData(event) {
    let uQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    this.query_user = 'page=' + this.pageIndex + '&per_page=' + this.pageSize;
    if (this.filterStatus !== '') {
      uQuery += '&status=' + this.filterStatus;
    }

    if (this.filterRole !== '') {
      uQuery += '&role=' + this.filterRole;
    }
    this.query_user += uQuery;
    this.userService.getAll(this.query_user)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let user_portal = '';
            elem.user_portal.forEach(res => {
              user_portal += res.access_title + ', ';
            });
            elem.user_portal = user_portal.slice(0, -2);
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
          this.total_user = response.meta.pagination.total;
        }
      });
  }

  // user sorting
  sortUser(evt) {
    if (evt.direction == '') {
      evt.direction = 'asc';
    }
    this.query_user_sort = 'page=' + this.pageIndex + '&per_page=' + this.pageSize + '&sort=' + evt.active + '&order=' + evt.direction;
    this.userService.getAll(this.query_user_sort)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let user_portal = '';
            elem.user_portal.forEach(res => {
              user_portal += res.access_title + ', ';
            });
            elem.user_portal = user_portal.slice(0, -2);
          });
          this.dataSource = new MatTableDataSource(response.data);
          //   this.dataSource.sort=this.sort;
          this.paginate = response.meta.pagination;
        }
      });
  }


  // freelancer sorting
  sortFreelancer(evt) {
    if (evt.direction == '') {
      evt.direction = 'asc';
    }

    this.query_freelancer_sort = '?page=' + this.pageIndex_approval + '&per_page=' + this.pageSize_approval + '&sort=' + evt.active + '&order=' + evt.direction;
    this.vendorService.getAdminApprovalPending(this.query_freelancer_sort)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -1);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if (res.translation_rate != null) {
                translation_rate_value += res.translation_rate;
              }
              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }
              lang = res.sourcelanguage.data.lang_name + res.bi_direction +
               res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)
            }
            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource1 = new MatTableDataSource(response.data);
          this.paginate1 = response.meta.pagination;
          this.total_vendor = response.meta.pagination.total;
        }
      });
  }

  editData(value) {
    this.router.navigate(['/user-management/' + value.id]);
  }
}