import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-additional-information-notarization',
  templateUrl: './additional-information-notarization.component.html',
  styleUrls: ['./additional-information-notarization.component.scss']
})
export class AdditionalInformationNotarizationComponent implements OnInit {
  apostile_show: boolean = false;
  notarization_quantity: number=0;
  notarization_other_state: string = '';
  notarization_additional_quantity: number = 0;
  notarization_affidavit_court: string = "No";
  project_id: string;
  additonal_service_array: any = [];
  @Output() myoutputNotarization: EventEmitter<any> = new EventEmitter();
  outputString: string="(NA)";
  constructor(private projectsService: ProjectsService,
    private toastr: ToastrService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getAdditionalServices();
  }

  getAdditionalServices() {
    this.projectsService.getAdditionalServices(this.project_id)
      .subscribe((response: any) => {
        if (response) {
          this.additonal_service_array = response.project_addition_service;
          if (this.additonal_service_array.length == 0) {
            this.sendNotApply();
          }
          if (response.project_addition_service.length > 0) {
            response.project_addition_service.forEach(element => {
              if (element.additional_service.code == "notarization_coa_il_ca") {
                // add copy
                this.notarization_quantity = element.quantity;
                this.apostile_show = true;
                this.sendApply();
              }
              if (
                element.additional_service.code == "notarization_other_state") {
                this.notarization_other_state = element.notarization_other_state;
                this.apostile_show = true;
                this.sendApply();

              }
              if (element.additional_service.code == "notarization_additional") {
                this.notarization_additional_quantity =  element.quantity;
                this.apostile_show = true;
                this.sendApply();
              }

              if (element.additional_service.code == "notarization_affidavit_court") {
                this.notarization_affidavit_court = "Yes";
                this.apostile_show = true;
                this.sendApply();
              }
            });
          }
        }
      });
  }

  sendNotApply() {
    this.outputString = "(NA)";
    let data: any = {
      title: this.outputString,
      click_value: 0
    }
    this.myoutputNotarization.emit(data);
  }

  sendApply() {
    this.outputString = "(Applied)";
    let data: any = {
      title: this.outputString,
      click_value: 0

    }
    this.myoutputNotarization.emit(data);
  }


  Edit() {
    this.outputString = "(Applied)";
    let data: any = {
      title: this.outputString,
      click_value: 1

    }
    this.myoutputNotarization.emit(data);
  }

}
