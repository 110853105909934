import { Component, OnInit, ViewChild } from '@angular/core';
import { AppGlobals } from 'app/main/app.global';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stripe-transactions',
  templateUrl: './stripe-transactions.component.html',
  styleUrls: ['./stripe-transactions.component.scss']
})
export class StripeTransactionsComponent implements OnInit {
quote_id:number
  constructor(private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    
    this.quote_id = this.route.snapshot.params['id'];
  }


  searchStripeTransaction(){
    if(this.quote_id!=null){
      this.router.navigate(['/pms/stripetransactions/search/'+this.quote_id]);
    }else{
      this.router.navigate(['/pms/stripetransactions/search']);
    }
    
  }

  CreateStripeTransaction(){
    if(this.quote_id!=null){
    this.router.navigate(['/pms/stripetransactions/create/'+this.quote_id]);
    }else{
      this.router.navigate(['/pms/stripetransactions/create']);
    }
  }

}
