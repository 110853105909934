import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedService } from '../main/shared.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        public sharedService: SharedService,
        private router: Router, private route: ActivatedRoute
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
        this.sharedService.show();
        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    this.sharedService.hide();
                }
            }),
            catchError(err => {
              this.sharedService.hide();
              if (err.status === 401) {
                    const message = {
                        title: 'Error',
                        message: err.error.message,
                        status: 'danger'
                    };
                    this.sharedService.toastr(message);
                    this.route.queryParams.subscribe(params => {
                        if (params.returnUrl) {
                            this.router.navigate([params.returnUrl]);
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });
                  
                    localStorage.clear();
                } else if (err.status === 422) {
                    const message = {
                        title: 'Error',
                        message: err.error,
                        status: 'danger',
                        duration: 7000
                    };
                    this.sharedService.toastr(message);
                }  else if (err.status === 403) {
                    const message = {
                        title: 'Error',
                        message: err.error,
                        status: 'danger',
                        duration: 7000
                    };
                    this.sharedService.toastr(message);
                } else if (err.status === 404) {
                    const message = {
                        title: 'Error',
                        message: 'URL is not valid. Please open correct url.',
                        status: 'danger',
                        duration: 7000
                    };
                    this.sharedService.toastr(message);
                }  else if (err.status === 405) {
                    this.sharedService.toastr(err.error);
                }  else if (err.status === 410) {
                    this.router.navigate(['not-found']);
                }  else if (err.status === 466) {
                    const message = {
                        title: 'Error',
                        message: 'Something went wrong',
                        status: 'danger',
                        duration: 7000
                    };
                    this.sharedService.toastr(message);
                } else if (err.status === 400) {
                    // const message = {
                    //     message: err.error,
                    //     status: 'danger'
                    // };
                    this.sharedService.toastr(err.error);
                } else if (err.status == 0) {
                     const message = {
                        message: err.statusText,
                        status: 'danger'
                    };
                    
                    this.sharedService.toastr(message);
                 }else  if (err.status == 500){
                    
                    this.sharedService.toastr(err.error);
                 }



              return throwError(err);
            }));
    }
}
