import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
var StripeTransactionsComponent = /** @class */ (function () {
    function StripeTransactionsComponent(router, route) {
        this.router = router;
        this.route = route;
    }
    StripeTransactionsComponent.prototype.ngOnInit = function () {
        this.quote_id = this.route.snapshot.params['id'];
    };
    StripeTransactionsComponent.prototype.searchStripeTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/stripetransactions/search/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/stripetransactions/search']);
        }
    };
    StripeTransactionsComponent.prototype.CreateStripeTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/stripetransactions/create/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/stripetransactions/create']);
        }
    };
    return StripeTransactionsComponent;
}());
export { StripeTransactionsComponent };
