<div class="page-layout w100 p-24 bg-white" fusePerfectScrollbar>
  <div class="white-box">
    <div class="tHeader">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2>Search Stripe Transaction</h2>
        <div class="text-right">
          <button mat-button color="accent" (click)="CreateTransaction()"><span
              class="material-icons">queue</span>Create Stripe Payment
          </button>
        </div>
      </div>
    </div>
    <div class="boxBody">
      <div class="tabWraper">
        <div class="p20">
          <form name="searchTransactionForm" [formGroup]="searchTransactionForm" (ngSubmit)="search();">
            <div class="box-blank p20" fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="20"></div>
              <div fxFlex="40">
                <mat-form-field>
                  <mat-label>Enter email address</mat-label>
                  <input matInput formControlName="email" placeholder="Enter email address">
                  <mat-error *ngIf="searchTransactionForm.get('email').hasError('required')">
                    email is required!
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex>
                <button type="submit" [disabled]="(!searchTransactionForm.valid)" mat-raised-button
                  color="accent">Search <mat-icon matSuffix>search</mat-icon></button>
              </div>
            </div>
          </form>

          <div class="boxBody">
            <div class="boxInn" *ngIf="show_record">

              <table mat-table [dataSource]="dataSource" class="table">
                <!-- Name Column -->
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="24"> Id
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="24">
                    <div fxLayout="column">
                      <p class="text-truncate">
                        {{product?.id}}
                      </p>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8"> Amount
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                    <p class="text-truncate">{{product?.amount}}</p>
                  </mat-cell>
                </ng-container>


                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="15"> Status
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="15">
                    <p class="text-truncate">{{product.status}}</p>
                  </mat-cell>
                </ng-container>


                <ng-container matColumnDef="cardholderName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8"> Customer
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                    <span>
                      {{product.cardholder_name}}
                    </span>
                  </mat-cell>
                </ng-container>



                <ng-container matColumnDef="creditCardNumber">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20"> Card Number
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                    <!-- <img [src]="product?.image_url" width="30"> -->
                    {{product?.card_brand}} ******** {{product?.last4}}
                  </mat-cell>
                </ng-container>

                <!-- Symbol Column -->

                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="24"> Email
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="24">
                    <p class="text-truncate">{{product?.email}}</p>
                  </mat-cell>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                    <p class="text-truncate">{{product?.date}}</p>
                  </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
                </mat-row>
              </table>
              <div *ngIf="noRecord" class="noDataFound">
                <p> No Data available!!</p>
              </div>
              <mat-paginator [length]="totaltransaction" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>

              <!-- <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[20,30,40,50,60]" (page)='paginateData($event)'>
                          </mat-paginator> -->
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>