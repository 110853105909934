<mat-toolbar class="p-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center" >
      <span class="title dialog-title text-uppercase">Email Preview</span>
      <button mat-icon-button (click)="Onclose(false)" matTooltip="Close" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form name="Form"  [formGroup]="Form">
  <div mat-dialog-content class="p-16 pt-4 m-0" >
    <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
            <mat-form-field   fxFlex="50">
                <mat-label>To</mat-label>
                <input matInput formControlName="to" readonly>
                <mat-error *ngIf="Form.get('to').hasError('required')">
                    To is required!
                  </mat-error>
                  <mat-error *ngIf="Form.get('to').hasError('email')">
                    Please enter a valid email address
                  </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="50">
                <mat-label>Cc</mat-label>
                <input matInput formControlName="cc"  >
              
                  <mat-error *ngIf="Form.get('cc').hasError('email')">
                    Please enter a valid email address
                  </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
            <mat-form-field   fxFlex="50">
                <mat-label>Bcc</mat-label>
                <input matInput formControlName="bcc"  >
              
                  <mat-error *ngIf="Form.get('bcc').hasError('email')">
                    Please enter a valid email address
                  </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="50">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject" >
                <mat-error *ngIf="Form.get('subject').hasError('required')">
                    Subject is required!
                  </mat-error>
                 
            </mat-form-field>
        </div>
      <div fxLayout="row" class="mt-4" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <ckeditor formControlName="ckeditorContent" [config]="config" [readonly]="false"
         debounce="500">
        </ckeditor>
      </div>
      
      <div class="actWraper" fxLayout="column"  fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
          <button mat-raised-button color="primary" (click)="Onclose(false)" >Cancel</button>
        <div></div>
        <button mat-raised-button color="primary" (click)="updateMessage()" [disabled]="Form.invalid">Submit Project</button>
  
      </div>
    </div>
  </div>
  </form>