import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { VendorService } from 'app/main/Services/vendor.service';
import { AddFileManagementComponent } from './add-file-management/add-file-management.component';
import { ToolsComponent } from '../../tools/tools.component';
import { SharedService } from 'app/main/shared.service';
var FileManagementComponent = /** @class */ (function () {
    // mainprogressbar: boolean;
    function FileManagementComponent(route, projectsService, vendorService, dialog, toastr) {
        this.route = route;
        this.projectsService = projectsService;
        this.vendorService = vendorService;
        this.dialog = dialog;
        this.toastr = toastr;
        this.file_data = [];
        this.file_table_data = [];
        this.file_id_data = [];
        this.fileError = '';
        this.bulk_selected_progress = false;
        this.file_multiple_data = [];
        this.uploaded_files = [];
        this.file_data_save = {};
        this.file_id_array = [];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    FileManagementComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getProjectDetail();
    };
    FileManagementComponent.prototype.getProjectDetail = function () {
        var _this = this;
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.file_data = [];
                var fullname;
                var id;
                _this.project_type = response.data.service_id;
                response.data.projectfile.data.forEach(function (element) {
                    id = element.id;
                    if (element.createdby) {
                        fullname = element.createdby.data.fullname;
                    }
                    else {
                        fullname = 'Customer';
                    }
                    if (element.targetlanguage) {
                        var lang_name = element.targetlanguage.data.lang_name;
                    }
                    if (element.filetype) {
                        var filetype = element.filetype.data.name;
                    }
                    element.file.data.forEach(function (elm) {
                        //  this.project_file_data=this.BaseUrl_file + elm.path;
                        var file_url;
                        if (elm.path.includes("http")) {
                            _this.project_file_data = elm.path;
                        }
                        else {
                            _this.project_file_data = _this.BaseUrl_file + elm.path;
                        }
                        elm.file_url = _this.project_file_data;
                        elm.type = element.type;
                        elm.fullname = fullname;
                        //elm.created_at= created_at;
                        elm.lang_name = lang_name;
                        elm.filetype = filetype;
                        elm.id = id;
                        _this.file_data.push(elm);
                    });
                });
            }
        });
    };
    FileManagementComponent.prototype.DownloadZip = function () {
        var _this = this;
        var data = {
            project_id: '',
            file_ids: []
        };
        data.project_id = this.project_id;
        data.file_ids = this.file_id_data;
        this.projectsService.downloadZip(data).subscribe(function (resposne) {
            if (resposne) {
                _this.file_d = resposne.url;
            }
        });
    };
    FileManagementComponent.prototype.selectFile = function (evt, res) {
        if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
            this.file_id_data.push(res.id);
        }
        else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
            var index = this.file_id_data.indexOf(res.id);
            this.file_id_data.splice(index, 1);
        }
        this.DownloadZip();
    };
    FileManagementComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        this.file_id_data = [];
        var check = false;
        this.file_data.forEach(function (element, key) {
            element["check"] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.file_id_data.push(element.id);
            }
            else {
                _this.file_id_data = [];
            }
        });
        this.DownloadZip();
        if (check) {
            this.bulk_selected_progress = true;
        }
    };
    FileManagementComponent.prototype.UncheckedFile = function () {
        var _this = this;
        this.bulk_selected_progress = false;
        this.file_data.forEach(function (element, key) {
            element["check"] = false;
            var index = _this.file_id_data.indexOf(element.id);
            _this.file_id_data.splice(index, 1);
        });
    };
    // pop-up 
    FileManagementComponent.prototype.onCreate = function () {
        var _this = this;
        var dailogRef = this.dialog.open(AddFileManagementComponent, {
            width: '70%',
            data: { project_id: this.project_id }
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getProjectDetail();
            }
        });
    };
    FileManagementComponent.prototype.ocrTools = function () {
        var _this = this;
        SharedService.set(SharedService.Project_id, this.project_id);
        var dialogRef = this.dialog.open(ToolsComponent, {
            width: '70%',
            height: '80%',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.getProjectDetail();
            // window.location.href = '/pms/project/details/'+this.project_id;
            // DO SOMETHING
        });
    };
    // Remove Project File
    FileManagementComponent.prototype.removeProjectFile = function (res) {
        var _this = this;
        this.projectsService.deleteProjectFile(res.id).subscribe(function (response) {
            if (response) {
                _this.toastr.success(" Delete Project File  Successfully!!");
                _this.getProjectDetail();
            }
        });
    };
    return FileManagementComponent;
}());
export { FileManagementComponent };
