import { Component, OnInit } from '@angular/core';
import { ConditionalExpr } from '@angular/compiler';

@Component({
  selector: 'app-additional-services',
  templateUrl: './additional-services.component.html',
  styleUrls: ['./additional-services.component.scss']
})
export class AdditionalServicesComponent implements OnInit {
  getValueMailing:string='';
  getValueNotarization:string='';
  getValueApostile:string='';

  // check_Mailing:boolean=true;
  // check_Notarization:boolean=true;
  // check_Apostile:boolean=true;
  constructor() { }

  ngOnInit() {
  }

  getDataMailing(value){
    this.getValueMailing=value;
  }

  getDataNotarization(value){
    this.getValueNotarization=value;
    //  if(value=='"(Applied)"'){
    //   this.check_Notarization=true;
    // }else {
    //   this.check_Notarization=false;
    // }


  }

  getDataApostile(value){
  
  
    this.getValueApostile=value;
 
    //  if(value=='"(Applied)"'){
    //   this.check_Apostile=true;
    // }else {
    //   this.check_Apostile=false;
    // }

  }
}
