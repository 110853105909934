import { TransactionsComponent } from './transactions/transactions.component';
import { VaultComponent } from './vault/vault.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { SearchTransactionComponent } from './transactions/search-transaction/search-transaction.component';
import { CreateTransactionComponent } from './transactions/create-transaction/create-transaction.component';
import { AccountComponent } from './account/account.component';
import { AccountDetailsComponent } from './account/account-details/account-details.component';
import { AddAccountComponent } from './account/add-account/add-account.component';
import { StripeTransactionsComponent } from './stripe-transactions/stripe-transactions.component';
import { SearchStripeTransactionComponent } from './stripe-transactions/search-stripe-transaction/search-stripe-transaction.component';
import { CreateStripeTransactionComponent } from './stripe-transactions/create-stripe-transaction/create-stripe-transaction.component';
var routes = [
    {
        path: 'pms/transactions',
        component: TransactionsComponent
    },
    {
        path: 'pms/stripetransactions',
        component: StripeTransactionsComponent
    },
    {
        path: 'pms/transactions/search/:id',
        component: SearchTransactionComponent
    },
    {
        path: 'pms/transactions/search',
        component: SearchTransactionComponent
    },
    {
        path: 'pms/stripetransactions/search/:id',
        component: SearchStripeTransactionComponent
    },
    {
        path: 'pms/stripetransactions/search',
        component: SearchStripeTransactionComponent
    },
    {
        path: 'pms/transactions/create',
        component: CreateTransactionComponent
    },
    {
        path: 'pms/transactions/create/:id',
        component: CreateTransactionComponent
    },
    {
        path: 'pms/stripetransactions/create',
        component: CreateStripeTransactionComponent
    },
    {
        path: 'pms/stripetransactions/create/:id',
        component: CreateStripeTransactionComponent
    },
    {
        path: 'pms/vault',
        component: VaultComponent
    },
    {
        path: 'pms/customer',
        component: CustomerComponent
    },
    {
        path: 'pms/customer/details/:id',
        component: CustomerDetailsComponent
    },
    {
        path: 'pms/customer/addcustomer',
        component: AddCustomerComponent
    },
    {
        path: 'pms/customer/editCustomer/:id',
        component: AddCustomerComponent
    },
    {
        path: 'pms/account',
        component: AccountComponent
    },
    {
        path: 'pms/account/details/:id',
        component: AccountDetailsComponent
    },
    {
        path: 'pms/account/add-account',
        component: AddAccountComponent
    }
];
var CustomerModule = /** @class */ (function () {
    function CustomerModule() {
    }
    return CustomerModule;
}());
export { CustomerModule };
