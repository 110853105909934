/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i3 from "../../../../@fuse/services/config.service";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/router";
import * as i6 from "../sms-conversation/sms-conversation/sms-conversation.component.ngfactory";
import * as i7 from "../sms-conversation/sms-conversation/sms-conversation.component";
import * as i8 from "../../Services/projects.service";
import * as i9 from "../../Services/data.service";
import * as i10 from "@angular/forms";
import * as i11 from "@angular/material/dialog";
import * as i12 from "./sms.component";
import * as i13 from "../../../../@fuse/components/progress-bar/progress-bar.service";
import * as i14 from "ngx-toastr";
var styles_SmsComponent = [i0.styles];
var RenderType_SmsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmsComponent, data: {} });
export { RenderType_SmsComponent as RenderType_SmsComponent };
export function View_SmsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "page-layout w85 p-24 bg-white"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.FusePerfectScrollbarDirective, [i1.ElementRef, i3.FuseConfigService, i4.Platform, i5.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-sms-conversation", [], null, null, null, i6.View_SmsConversationComponent_0, i6.RenderType_SmsConversationComponent)), i1.ɵdid(3, 114688, null, 0, i7.SmsConversationComponent, [i8.ProjectsService, i9.DataService, i5.ActivatedRoute, i10.FormBuilder, i11.MatDialog], null, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_SmsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sms", [], null, null, null, View_SmsComponent_0, RenderType_SmsComponent)), i1.ɵdid(1, 114688, null, 0, i12.SmsComponent, [i5.ActivatedRoute, i8.ProjectsService, i13.FuseProgressBarService, i14.ToastrService, i10.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmsComponentNgFactory = i1.ɵccf("app-sms", i12.SmsComponent, View_SmsComponent_Host_0, {}, {}, []);
export { SmsComponentNgFactory as SmsComponentNgFactory };
