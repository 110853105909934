import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'app/main/shared.service';
import { environment } from 'environments/environment';
import { MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material';
import { VendorService } from 'app/main/Services/vendor.service';
import { ProjectReleaseComponent } from '../project-details/project-release/project-release.component';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { SpecialityChangeComponent } from '../project-details/speciality-change/speciality-change.component';
import { LanguageChangeComponent } from '../project-details/language-change/language-change.component';
import { DeadlineChangeComponent } from '../project-details/deadline-change/deadline-change.component';
import { UpdateServiceTypeComponent } from '../project-details/update-service-type/update-service-type.component';
var CreateQuoteComponent = /** @class */ (function () {
    function CreateQuoteComponent(route, projectsService, _formBuilder, vendorService, toastr, router, dialog) {
        this.route = route;
        this.projectsService = projectsService;
        this._formBuilder = _formBuilder;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.router = router;
        this.dialog = dialog;
        this.index_array = null;
        this.response_data_quoteArray = [];
        // mainprogressbar: boolean;
        this.showAddAdditionService = false;
        this.languageData = [];
        this.DiscoutPromotion = [];
        this.is_draft = false;
        this.version = '';
        this.response_email_data = [];
        this.table_data = [];
        this.test = false;
        this.Dublicate = false;
        this.Finance_update = {};
        this.project_pm_data = {};
        this.comment_data_update = {};
        this.NewAdditionalServicesTotal = 0;
        this.quote_id = '';
        this.target_language_select = [];
        this.source_language_select = [];
        this.file_multiple_data = [];
        this.project_quote_additional_service = [];
        this.file_prepration_data = [];
        this.data_zip_file_prepration = {};
        this.count = 0;
        this.count_test = 0;
        this.fileError = '';
        this.file_id_array = [];
        this.language = [];
        this.languageDataArray = [];
        this.uploaded_files = [];
        this.file_data_save = {};
        this.ProjectLanguage_data = [];
        this.ProjectLanguage_data_translator = [];
        this.lang1 = '';
        this.total_temp = 0;
        this.target_language_show = false;
        // Additional service 
        this.additonal_service_array = [];
        this.getValueMailing = '(NA)';
        this.getValueNotarization = '(NA)';
        this.getValueApostile = '(NA)';
        this.displayedColumns_translator = ['ref', 'name', 'specialization', 'overallEvaluation', 'completedJobs', 'BestRateUSD'];
        this.language_source_target = '';
        this.dataTypePromotion = {
            value: ''
        };
        this.data = {
            source_lang_id: '',
            service_id: '',
            project_language_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            quantity: '',
            pricing_method: 'Words',
            rate: '',
            sub_total: ''
        };
        this.today = new Date();
        this.minDate = this.today;
        this.isLinear = true;
        this.first = false;
        this.second = false;
        this.third = false;
        this.source_id = [];
        this.target_id = [];
        this.unique_source_language = [];
        this.unique_target_language = [];
        this.check_discount = true;
        this.source_language_array = [];
        this.target_language_array = [];
        this.priceRecomendations = {
            rate: '',
            rate_tier2: '',
            rate_tier3: '',
            rate_tier4: '',
            rate_tier5: '',
        };
        this.BaseUrl_file = environment.baseUrl_file;
        this.DueTime_env = environment.Due_time;
    }
    CreateQuoteComponent.prototype.ngOnInit = function () {
        this.user_id = SharedService.get('id');
        this.username = SharedService.get('username');
        this.user_group_id = SharedService.get('user_group_id');
        this.getLanguage();
        this.project_id = this.route.snapshot.params['id'];
        this.getUniqueProjectLanguage();
        this.getAdditionalServices();
        this.getDiscountTypePromotion();
        this.UpdateProjectPm();
        this.getProjectDetail();
        this.getEmailTemplate();
        this.getProjectLanguageTranslator();
        this.Form = this._formBuilder.group({
            total: ['', Validators.compose([Validators.required])],
            discount_amount: [''],
            quote_subtotal: [''],
            discount: [''],
            email_template_id: ['', Validators.compose([Validators.required])],
            comment: [''],
            delivery_date: ['', Validators.compose([Validators.required])],
        });
        this.dataSource_translator = [
            { 'name': 'Tanika Evans', 'specialization': 'Medical', 'overallEvaluation': '4 Star', 'completedJobs': '42', 'BestRateUSD': '0.7 USD' },
            { 'name': 'Boskfie sebe', 'specialization': 'Medical', 'overallEvaluation': '3 Star', 'completedJobs': '16', 'BestRateUSD': '0.67 USD' },
            { 'name': 'Rakgyioi selo', 'specialization': 'Medical', 'overallEvaluation': '5 Star', 'completedJobs': '6', 'BestRateUSD': '0.48 USD' },
            { 'name': 'Heloer bera', 'specialization': 'Medical', 'overallEvaluation': '1 Star', 'completedJobs': '23', 'BestRateUSD': '0.5 USD' },
            { 'name': 'Tanika Evans', 'specialization': 'Medical', 'overallEvaluation': '4 Star', 'completedJobs': '33', 'BestRateUSD': '0.7 USD' }
        ];
        this.dataSource_translator = new MatTableDataSource(this.dataSource_translator);
        this.dataSource_translator.paginator = this.paginator;
    };
    CreateQuoteComponent.prototype.getImportantNotes = function () {
        var _this = this;
        this.projectsService.getImportantNotes(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.important_messages_response = response.data;
            }
        });
    };
    CreateQuoteComponent.prototype.getProjectDetail = function () {
        var _this = this;
        this.commentForm = this._formBuilder.group({
            message: ['', Validators.compose([Validators.required])],
            message_type: ['0', Validators.compose([Validators.required])],
        });
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.response_data = response.data;
                _this.version = _this.response_data.version;
                _this.DiscoutPromotion.forEach(function (dis_res) {
                    if (_this.response_data.promo_code == dis_res.coupon) {
                        _this.discount_value = dis_res.value;
                    }
                });
                var dt2 = new Date();
                var datetime = moment(response.data.created_at.date);
                var current = moment(dt2.toISOString(), 'YYYY-MM-DD HH:mm:ss.SSSS');
                var minutes = current.diff(datetime, 'minutes');
                if (minutes > 60) {
                    var diff;
                    _this.TimeElapsed = _this.timeConvert(minutes);
                    diff = _this.DueTime_env - minutes;
                    if (diff < 0) {
                        _this.checkdueTime = false;
                        _this.DueTime = _this.timeConvert(diff);
                        _this.DueTime = '-' + _this.DueTime;
                    }
                    else {
                        _this.checkdueTime = true;
                        _this.DueTime = _this.timeConvert(diff);
                    }
                }
                else {
                    _this.TimeElapsed = minutes + ' Mintue(s)';
                    _this.DueTime = _this.DueTime_env - minutes;
                    if (_this.DueTime < 0) {
                        _this.checkdueTime = false;
                        _this.DueTime = _this.DueTime_env - minutes + ' Mintue(s)';
                    }
                    else {
                        _this.checkdueTime = true;
                        _this.DueTime = _this.DueTime_env - minutes + ' Mintue(s)';
                    }
                }
                _this.is_locked = _this.response_data.is_locked;
                _this.locked_by = _this.response_data.locked_by;
                _this.locked_by_value = _this.locked_by;
                if (_this.user_id != _this.locked_by) {
                    _this.projectLock();
                }
                _this.specialization_id = response.data.specilization_id;
                _this.project_type = response.data.service_id;
                if (response.data.projectmanager != null) {
                    _this.project_manager_id = response.data.projectmanager.data.id;
                }
                _this.DownloadZip_file_prepration();
                response.data.projectLanguage.data.forEach(function (element) {
                    _this.priceRecomendations.rate = parseFloat(element.targetlanguage.data.rate);
                    _this.priceRecomendations.rate_tier2 = parseFloat(element.targetlanguage.data.rate_tier2);
                    _this.priceRecomendations.rate_tier3 = parseFloat(element.targetlanguage.data.rate_tier3);
                    _this.priceRecomendations.rate_tier4 = parseFloat(element.targetlanguage.data.rate_tier4);
                    _this.priceRecomendations.rate_tier5 = parseFloat(element.targetlanguage.data.rate_tier5);
                    _this.languageDataArray.push(element);
                });
                response.data.projectfile.data.forEach(function (element) {
                    if (element.type == 2) {
                        element.file.data.forEach(function (elm) {
                            _this.file_prepration_url = _this.BaseUrl_file + elm.path;
                            _this.file_prepration_data.push(elm);
                        });
                    }
                });
                if (_this.response_data.customer != null && _this.response_data.deadline_date != null) {
                    var dt1 = new Date(_this.response_data.customer.data.created_at.date);
                    var dt2_1 = new Date(_this.response_data.deadline_date.date);
                    _this.estimate_hours = _this.diff_hours(dt2_1, dt1);
                }
                if (response.data.projectquote.data.length > 0) {
                    response.data.projectquote.data.forEach(function (first_ele) {
                        if (first_ele.status == 5) {
                            _this.is_draft = true;
                        }
                        else {
                            _this.is_draft = false;
                        }
                    });
                    response.data.projectquote.data.forEach(function (ele) {
                        // if (this.is_draft !== true) {
                        //   this.language = [];
                        // }
                        if (ele.status != 5) {
                            _this.language = [];
                        }
                        _this.dataTypePromotion.value = parseInt(ele.discount);
                        ele.projectquoteservice.data.forEach(function (element) {
                            element.language = [];
                            _this.data = {
                                source_lang_id: '',
                                service_id: '',
                                project_language_id: '',
                                sourcelanguage: {
                                    data: {
                                        lang_name: ''
                                    }
                                },
                                targetlanguage: {
                                    data: {
                                        lang_name: ''
                                    }
                                },
                                target_lang_id: '',
                                quantity: '',
                                pricing_method: '',
                                rate: '',
                                sub_total: '',
                            };
                            _this.data.quantity = element.quantity;
                            _this.data.pricing_method = element.pricing_method;
                            _this.data.rate = element.rate;
                            _this.data.sub_total = element.subTotal;
                            _this.data.project_language_id = element.project_language_id;
                            element.projectlanguages.data.forEach(function (element) {
                                _this.data.sourcelanguage.data.lang_name = element.sourcelanguage.data.lang_name;
                                _this.data.targetlanguage.data.lang_name = element.targetlanguage.data.lang_name;
                                _this.data.source_lang_id = element.sourcelanguage.data.id;
                                _this.data.target_lang_id = element.targetlanguage.data.id;
                                _this.data.service_id = _this.project_type;
                            });
                            if (_this.is_draft == true) {
                                if (ele.status == 5) {
                                    _this.data.id = element.id;
                                    _this.data.quote_id = element.quote_id;
                                    _this.quote_id = element.quote_id;
                                    _this.language.push(_this.data);
                                    _this.Form.patchValue({
                                        total: ele.total,
                                        quote_subtotal: ele.quote_subtotal,
                                        discount: ele.discount,
                                        discount_amount: ele.discount_amount,
                                        email_template_id: ele.email_template_id
                                    });
                                }
                            }
                            else {
                                _this.language.push(_this.data);
                                _this.Form.patchValue({
                                    total: ele.total,
                                    quote_subtotal: ele.quote_subtotal,
                                    discount: ele.discount,
                                    discount_amount: ele.discount_amount
                                });
                            }
                        });
                    });
                    _this.dataSource = new MatTableDataSource(_this.language);
                    _this.count_test = _this.language.length;
                    _this.response_data_quoteArray = response.data.projectquote.data;
                    _this.response_data_quoteArray.forEach(function (res) {
                        var is_active_class = '';
                        if (res.is_active == 0) {
                            is_active_class = 'quote_inactive';
                        }
                        else if (res.is_active == 1) {
                            is_active_class = 'quote_active';
                        }
                        res.is_active_class = is_active_class;
                        _this.quote_status = res.status;
                        if (_this.quote_status == 2 || _this.quote_status == 3) {
                            _this.first = true;
                        }
                        else if (_this.quote_status == 4) {
                            _this.first = true;
                            _this.second = true;
                        }
                        if (_this.quote_status == 1 || _this.quote_status == 2 ||
                            _this.quote_status == 3 || _this.quote_status == 5) {
                            _this.data =
                                {
                                    source_lang_id: _this.source_language_id_value,
                                    service_id: '',
                                    project_language_id: '',
                                    sourcelanguage: {
                                        data: {
                                            lang_name: _this.source_language_value
                                        }
                                    },
                                    targetlanguage: {
                                        data: {
                                            lang_name: ''
                                        }
                                    },
                                    target_lang_id: '',
                                    quantity: '',
                                    pricing_method: 'Words',
                                    rate: '',
                                    sub_total: ''
                                };
                        }
                    });
                }
            }
        });
    };
    CreateQuoteComponent.prototype.diff_hours = function (dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    };
    CreateQuoteComponent.prototype.getEmailTemplate = function () {
        var _this = this;
        this.projectsService.getEmailTemplate()
            .subscribe(function (response) {
            if (response) {
                if (response.data.length > 0) {
                    response.data.forEach(function (element) {
                        if (element.is_active == 1) {
                            _this.response_email_data.push(element);
                        }
                    });
                }
            }
        });
    };
    /*
    * Function name Submit
    * Description: Save quote and save draft quote here
    * Param: type (draft or not)
    * save_status: 1  draft, 2 update draft quote
    */
    CreateQuoteComponent.prototype.submit = function (type) {
        var _this = this;
        this.Finance_update = {};
        this.Finance_update = this.Form.value;
        this.Finance_update.delivery_date = this.dateFormat(this.Form.value.delivery_date);
        if (type == 'draft') {
            this.Finance_update.save_status = 1;
        }
        else {
            this.Finance_update.save_status = 2;
        }
        this.Finance_update.project_id = this.project_id;
        this.Finance_update.discount_title = 'discount';
        this.Finance_update.project_quote_service = this.language;
        this.Finance_update.project_quote_additional_service = this.project_quote_additional_service;
        //Send draft to update query here
        // if (type == 'draft') {
        if (type == 'draft' && this.is_draft == true) {
            this.Finance_update.save_status = 1;
            this.Finance_update.status = 5;
            this.Finance_update.is_active = true;
            this.Finance_update.project_manager_id = this.project_manager_id;
            this.projectsService.sendDraftProjectQuote(this.quote_id, this.Finance_update)
                .subscribe(function (response) {
                if (response) {
                    _this.router.navigate(['/pms/project/details/' + _this.project_id]);
                    _this.toastr.success('Quote save as draft again');
                }
            }, function (error) {
                _this.toastr.error(error.error.message);
            });
            //Save draft OR send New quote here
        }
        else if (type == 'draft' && this.is_draft == false) {
            var data = {
                mail_response: {
                    to: '',
                    cc: '',
                    bcc: '',
                    message: '',
                    subject: ''
                }
            };
            data = this.Finance_update;
            this.projectsService.sendProjectQuote(data)
                .subscribe(function (response) {
                if (response) {
                    _this.router.navigate(['/pms/project/details/' + _this.project_id]);
                    _this.toastr.success('Quote save as draft');
                }
            }, function (error) {
                _this.toastr.error(error.message);
            });
        }
        // create quote
        if (type == 'not_draft') {
            this.openDialogEmailPreview(this.Finance_update);
        }
    };
    // date format
    CreateQuoteComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt + ' 00:00:00';
    };
    CreateQuoteComponent.prototype.UpdateProjectPm = function () {
        var _this = this;
        this.project_pm_data.project_id = this.project_id;
        this.projectsService.UpdateProjectPm(this.project_pm_data)
            .subscribe(function (response) {
            if (response) {
            }
        }, function (error) {
            _this.toastr.error(error.message);
        });
    };
    CreateQuoteComponent.prototype.submitComment = function () {
        var _this = this;
        this.comment_data_update.message_type = this.commentForm.value.message_type;
        this.comment_data_update.message = this.commentForm.value.message;
        this.comment_data_update.from_user_id = this.user_id;
        this.projectsService.updateComment(this.project_id, this.comment_data_update)
            .subscribe(function (response) {
            if (response) {
                if (response.data.mail_response) {
                    _this.toastr.success(response.data.mail_response);
                }
                else {
                    _this.toastr.success('Updated');
                }
                _this.getProjectDetail();
                _this.getEmailTemplate();
            }
        });
    };
    CreateQuoteComponent.prototype.Total_cost_new = function () {
        var _this = this;
        if (this.data.quantity < 0) {
            this.toastr.error('Quantity must be positive');
            return;
        }
        if (this.data.quantity % 1 != 0) {
            this.toastr.error('Quantity never be floating number');
            return;
        }
        if (this.data.rate < 0) {
            this.toastr.error('Rate must be positive');
            return;
        }
        // rate according to quantity start
        var recomended_rate;
        this.languageDataArray.forEach(function (elem) {
            if (_this.data.quantity > environment.rate && _this.data.quantity <= environment.rate_tier2) {
                _this.target_language_rate = parseFloat(elem.targetlanguage.data.rate);
            }
            else if (_this.data.quantity > environment.rate_tier2 && _this.data.quantity <= environment.rate_tier3) {
                _this.target_language_rate = parseFloat(elem.targetlanguage.data.rate_tier2);
            }
            else if (_this.data.quantity > environment.rate_tier3 && _this.data.quantity <= environment.rate_tier4) {
                _this.target_language_rate = parseFloat(elem.targetlanguage.data.rate_tier3);
            }
            else if (_this.data.quantity > environment.rate_tier4 && _this.data.quantity <= environment.rate_tier5) {
                _this.target_language_rate = parseFloat(elem.targetlanguage.data.rate_tier4);
            }
            else if (_this.data.quantity > environment.rate_tier5) {
                _this.target_language_rate = parseFloat(elem.targetlanguage.data.rate_tier5);
            }
            _this.target_language_margin = elem.targetlanguage.data.margin;
            recomended_rate = _this.target_language_rate + ((_this.target_language_rate * _this.target_language_margin) / 100);
            _this.target_language_recomended_rate = _this.roundToTwo(recomended_rate);
        });
        // rate according to quantity end
        if (this.data.quantity != '' && this.data.rate != '') {
            this.data.sub_total = (this.data.quantity * this.data.rate).toFixed(2);
        }
        else {
            this.data.sub_total = '';
        }
    };
    CreateQuoteComponent.prototype.Discount_total = function () {
        var total;
        var discount_amt;
        var quote_subtotal = 0;
        this.target_language_show = false;
        this.language = this.dataSource.data;
        this.language.forEach(function (element) {
            quote_subtotal += parseFloat(element.sub_total);
        });
        this.NewAdditionalServicesTotal = this.roundToTwo(this.NewAdditionalServicesTotal);
        quote_subtotal = this.roundToTwo(quote_subtotal + this.NewAdditionalServicesTotal);
        discount_amt = quote_subtotal * (this.Form.value.discount / 100);
        discount_amt = discount_amt.toFixed(2);
        total = quote_subtotal - discount_amt;
        total = total.toFixed(2);
        this.Form.patchValue({
            total: total,
            quote_subtotal: quote_subtotal,
            discount_amount: discount_amt
        });
    };
    CreateQuoteComponent.prototype.Discount_total_old = function () {
        var final_value;
        var discount_amt;
        this.target_language_show = false;
        this.language = this.dataSource.data;
        var subtotal_test = 0;
        this.language.forEach(function (element) {
            subtotal_test += parseFloat(element.sub_total);
        });
        subtotal_test = this.roundToTwo(subtotal_test);
        discount_amt = subtotal_test * (this.Form.value.discount / 100);
        discount_amt = discount_amt.toFixed(2);
        final_value = subtotal_test - discount_amt;
        final_value = this.roundToTwo(final_value);
        this.NewAdditionalServicesTotal = this.roundToTwo(this.NewAdditionalServicesTotal);
        final_value = this.roundToTwo(final_value + this.NewAdditionalServicesTotal);
        subtotal_test = this.roundToTwo(subtotal_test + this.NewAdditionalServicesTotal);
        this.Form.patchValue({
            total: final_value,
            quote_subtotal: subtotal_test,
            discount_amount: discount_amt
        });
    };
    CreateQuoteComponent.prototype.DownloadZip_file_prepration = function () {
        var _this = this;
        this.data_zip_file_prepration.project_id = this.project_id;
        this.data_zip_file_prepration.type = 2;
        this.projectsService.downloadZip(this.data_zip_file_prepration).subscribe(function (response) {
            if (response) {
                _this.file_prepration_download = response.url;
            }
        });
    };
    CreateQuoteComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
            }
        });
    };
    CreateQuoteComponent.prototype.getSourceName = function (evt) {
        var _this = this;
        this.languageData.forEach(function (element) {
            if (element.id == evt.value) {
                _this.data.sourcelanguage.data.lang_name = element.lang_name;
            }
        });
    };
    CreateQuoteComponent.prototype.PricingType = function (evt) {
        this.data.pricing_method = evt.value;
    };
    CreateQuoteComponent.prototype.getTargetName = function (evt) {
        var _this = this;
        if (evt.value == '') {
            this.target_language_show = false;
        }
        else {
            this.target_language_show = true;
        }
        this.languageData.forEach(function (element) {
            if (element.id == evt.value) {
                var recomended_rate = void 0;
                _this.data.targetlanguage.data.lang_name = element.lang_name;
                _this.target_language_name = element.lang_name;
                _this.target_language_margin = element.margin;
                _this.target_language_rate = parseFloat(element.rate);
                recomended_rate = _this.target_language_rate + ((_this.target_language_rate * _this.target_language_margin) / 100);
                _this.target_language_recomended_rate = _this.roundToTwo(recomended_rate);
            }
        });
        this.languageDataArray.forEach(function (elem) {
            if ((elem.targetlanguage.data.lang_name == _this.data.targetlanguage.data.lang_name)
                && (elem.sourcelanguage.data.lang_name == _this.data.sourcelanguage.data.lang_name)) {
                _this.data.project_language_id = elem.id;
            }
        });
    };
    CreateQuoteComponent.prototype.addLanguageTable = function () {
        var _this = this;
        if (this.data.quantity % 1 != 0) {
            this.toastr.error('Quantity never be floating number');
            return;
        }
        this.Dublicate = false;
        this.language.forEach(function (element) {
            if ((_this.data.targetlanguage.data.lang_name == element.targetlanguage.data.lang_name)
                && (_this.data.sourcelanguage.data.lang_name == element.sourcelanguage.data.lang_name)) {
                _this.Dublicate = true;
            }
        });
        if (this.index_array != null) {
            this.Dublicate = false;
            if (this.data.sourcelanguage.data.lang_name == '') {
                this.toastr.error('please select source language');
                return;
            }
            if (this.data.targetlanguage.data.lang_name == '') {
                this.toastr.error('please select target language');
                return;
            }
            if (this.data.pricing_method == '') {
                this.toastr.error('please select pricing method');
                return;
            }
            if (this.data.quantity == '' || this.data.quantity == null) {
                this.toastr.error('please select Quantity');
                return;
            }
            else if (this.data.quantity < 0) {
                this.toastr.error('Quantity must be positive');
                return;
            }
            if (this.data.rate == '' || this.data.rate == null) {
                this.toastr.error('please select rate');
                return;
            }
            else if (this.data.rate < 0) {
                this.toastr.error('Rate must be positive');
                return;
            }
            if (this.data.sub_total == '') {
                this.toastr.error('please select sub_total');
                return;
            }
            this.count_test = this.count_test + 1;
            this.language.forEach(function (element, ind) {
                if (_this.index_array != ind) {
                    if ((_this.data.targetlanguage.data.lang_name == element.targetlanguage.data.lang_name)
                        && (_this.data.sourcelanguage.data.lang_name == element.sourcelanguage.data.lang_name)) {
                        _this.Dublicate = true;
                    }
                }
            });
            if (this.Dublicate) {
                this.toastr.error('Please select unique language pair!!');
                return;
            }
            this.dataSource.data[this.index_array] = this.data;
            this.dataSource = new MatTableDataSource(this.dataSource.data);
            this.data.service_id = this.project_type;
            if (this.Form.value.discount) {
                // this.count_test = this.count_test + 1;
                this.Discount_total();
            }
            else {
                this.language = this.dataSource.data;
                // this.count_test = this.count_test + 1;
                var subtotal_test_1 = 0;
                this.language.forEach(function (element) {
                    subtotal_test_1 += parseFloat(element.sub_total);
                });
                var total = void 0;
                total = this.roundToTwo(subtotal_test_1 + this.NewAdditionalServicesTotal);
                this.Form.patchValue({
                    total: total,
                    quote_subtotal: total
                });
            }
            this.index_array = null;
            this.data = {
                source_lang_id: this.data.source_lang_id,
                service_id: '',
                project_language_id: this.data.project_language_id,
                sourcelanguage: {
                    data: {
                        lang_name: this.data.sourcelanguage.data.lang_name
                    }
                },
                targetlanguage: {
                    data: {
                        lang_name: this.data.targetlanguage.data.lang_name
                    }
                },
                target_lang_id: this.data.target_lang_id,
                quantity: this.data.quantity,
                pricing_method: 'Words',
                rate: this.data.rate,
                sub_total: this.data.sub_total,
            };
        }
        else {
            if (this.Dublicate == false) {
                if (this.data.sourcelanguage.data.lang_name == '') {
                    this.toastr.error('please select source language');
                    return;
                }
                if (this.data.targetlanguage.data.lang_name == '') {
                    this.toastr.error('please select target language');
                    return;
                }
                if (this.data.pricing_method == '') {
                    this.toastr.error('please select pricing method');
                    return;
                }
                if (this.data.quantity == '' || this.data.quantity == null) {
                    this.toastr.error('please select Quantity');
                    return;
                }
                else if (this.data.quantity < 0) {
                    this.toastr.error('Quantity must be positive');
                    return;
                }
                if (this.data.rate == '' || this.data.rate == null) {
                    this.toastr.error('please select rate');
                    return;
                }
                else if (this.data.rate < 0) {
                    this.toastr.error('Rate must be positive');
                    return;
                }
                if (this.data.sub_total == '') {
                    this.toastr.error('please select sub_total');
                    return;
                }
                this.count_test = this.count_test + 1;
                this.dataSource = [];
                this.data.service_id = this.project_type;
                this.language.push(this.data);
                var subtotal_test_2 = 0;
                this.language.forEach(function (element) {
                    subtotal_test_2 += parseFloat(element.sub_total);
                });
                var total = void 0;
                total = this.roundToTwo(subtotal_test_2 + this.NewAdditionalServicesTotal);
                this.Form.patchValue({
                    total: total,
                    quote_subtotal: total
                });
                this.dataSource = new MatTableDataSource(this.language);
                this.data = {
                    source_lang_id: '',
                    service_id: '',
                    project_language_id: this.project_language_id_select,
                    sourcelanguage: {
                        data: {
                            lang_name: this.source_language_value
                        }
                    },
                    targetlanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    target_lang_id: '',
                    quantity: '',
                    pricing_method: 'Words',
                    rate: '',
                    sub_total: '',
                };
            }
            else {
                this.toastr.error('Please select unique language pair!!');
                return;
            }
        }
    };
    CreateQuoteComponent.prototype.showAddAdditionalServices = function () {
        this.showAddAdditionService = true;
    };
    CreateQuoteComponent.prototype.showAddAdditionalServices_click = function () {
        this.index_id = 0;
        this.showAddAdditionService = true;
    };
    //get child component data start
    CreateQuoteComponent.prototype.getDataMailing = function (value) {
        this.index_id = 0;
        if (value.title == '(Applied)') {
            this.getValueMailing = value.title;
        }
        else {
            this.getValueMailing = '(NA)';
        }
        if (value.click_value == 1) {
            this.showAddAdditionalServices();
            var top_additional_service = document.getElementById('top_additional_service');
            if (top_additional_service != null) {
                top_additional_service.scrollIntoView();
                top_additional_service = null;
            }
        }
    };
    CreateQuoteComponent.prototype.getDataNotarization = function (value) {
        // this.getValueNotarization = value;
        this.index_id = 1;
        if (value.title == '(Applied)') {
            this.getValueNotarization = value.title;
        }
        else {
            this.getValueNotarization = '(NA)';
        }
        if (value.click_value == 1) {
            this.showAddAdditionalServices();
            var top_additional_service = document.getElementById('top_additional_service');
            if (top_additional_service != null) {
                top_additional_service.scrollIntoView();
                top_additional_service = null;
            }
        }
    };
    CreateQuoteComponent.prototype.getDataApostile = function (value) {
        this.index_id = 2;
        // this.getValueApostile = value;
        if (value.title == '(Applied)') {
            this.getValueApostile = value.title;
        }
        else {
            this.getValueApostile = '(NA)';
        }
        if (value.click_value == 1) {
            this.showAddAdditionalServices();
            var top_additional_service = document.getElementById('top_additional_service');
            if (top_additional_service != null) {
                top_additional_service.scrollIntoView();
                top_additional_service = null;
            }
        }
    };
    // edit finance table
    CreateQuoteComponent.prototype.editData = function (value, index) {
        if (this.count_test <= this.count) {
            this.count_test = this.count_test - 1;
        }
        this.data.source_lang_id = value.source_lang_id;
        this.data.target_lang_id = value.target_lang_id;
        this.data.project_language_id = value.project_language_id;
        this.data.targetlanguage.data.lang_name = value.targetlanguage.data.lang_name;
        this.data.sourcelanguage.data.lang_name = value.sourcelanguage.data.lang_name;
        this.data.rate = value.rate;
        this.data.pricing_method = value.pricing_method;
        this.data.quantity = value.quantity;
        this.data.sub_total = value.sub_total;
        this.index_array = index;
    };
    CreateQuoteComponent.prototype.roundToTwo = function (num) {
        return Math.round(num * 100) / 100;
    };
    //multiple file upload
    CreateQuoteComponent.prototype.onFileUpload = function () {
        var _this = this;
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    CreateQuoteComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files = response.data;
            response.data.forEach(function (element) {
                _this.file_id_array.push(element.id);
            });
            _this.fileSuccess = 'Uploaded successfully!!';
        });
    };
    //Project File prepration Save
    CreateQuoteComponent.prototype.ProjectFileSave = function () {
        var _this = this;
        this.file_data_save.project_id = this.project_id;
        this.file_data_save.file_id = this.file_id_array;
        this.file_data_save.type = 2;
        this.projectsService.projectFileSave(this.file_data_save).subscribe(function (response) {
            if (response) {
                _this.getProjectDetail();
            }
        });
    };
    CreateQuoteComponent.prototype.getUniqueProjectLanguage = function () {
        var _this = this;
        var data = {
            project_id: this.project_id
        };
        this.count = 0;
        this.source_language_array = [];
        this.target_language_array = [];
        this.target_language_value = '';
        this.source_language_value = '';
        this.source_language_select = [];
        this.target_language_select = [];
        this.projectsService.getUniqueProjectLanguage(data)
            .subscribe(function (response) {
            if (response) {
                _this.source_language_array = response.source_language_name;
                _this.target_language_array = response.target_language_name;
                _this.count += _this.source_language_array.length;
                _this.count *= _this.target_language_array.length;
                _this.source_language_value = _this.getLanguageName(_this.source_language_array);
                _this.target_language_value = _this.getLanguageName(_this.target_language_array);
                _this.source_language_select = _this.source_language_array;
                _this.target_language_select = _this.target_language_array;
            }
        }, function (error) {
            _this.toastr.error('Language pair not found!');
        });
    };
    CreateQuoteComponent.prototype.getLanguageName = function (res) {
        var lang = '';
        if (res.length > 0) {
            res.forEach(function (element) {
                lang += element.lang_name + ', ';
            });
        }
        lang = lang.slice(0, -2);
        return lang;
    };
    CreateQuoteComponent.prototype.getLanguageArray = function (uniqueLanguage) {
        var lang_array = [];
        var obj = {
            id: Number,
            lang_name: String
        };
        this.languageData.forEach(function (target_element) {
            uniqueLanguage.forEach(function (elm) {
                if (elm == target_element.lang_name) {
                    obj.id = target_element.id;
                    obj.lang_name = target_element.lang_name;
                    lang_array.push(obj);
                    obj = {
                        id: '',
                        lang_name: ''
                    };
                }
            });
        });
        return lang_array;
    };
    // Get Project Language for translator table
    CreateQuoteComponent.prototype.getProjectLanguageTranslator = function () {
        var _this = this;
        this.projectsService.ProjectTranslator(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.ProjectLanguage_data_translator = response.data;
                var obj_1 = {
                    specilization_id: Number,
                    source_language_id: Number,
                    target_language_id: Number
                };
                _this.ProjectLanguage_data_translator.forEach(function (element) {
                    obj_1.specilization_id = _this.specialization_id;
                    obj_1.source_language_id = element.source_language_id;
                    obj_1.target_language_id = element.target_language_id;
                    _this.projectsService.ProjectTranslator(obj_1)
                        .subscribe(function (response) {
                        if (response) {
                        }
                    }, function (error) {
                        _this.toastr.error(error.message);
                    });
                });
            }
        });
    };
    CreateQuoteComponent.prototype.Cancel = function () {
        this.router.navigate(['/pms/project/details/' + this.project_id]);
    };
    CreateQuoteComponent.prototype.projectLock = function () {
        var data = {
            project_id: '',
            is_locked: false
        };
        data.project_id = this.project_id;
        data.is_locked = true;
        this.projectsService.projectLock(data)
            .subscribe(function (response) {
            if (response) {
            }
        });
    };
    // pop-up 
    CreateQuoteComponent.prototype.projectRelease = function () {
        var dailogRef = this.dialog.open(ProjectReleaseComponent, {
            width: '500px',
            data: { project_id: this.project_id }
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
            }
        });
    };
    /// Discount Type
    CreateQuoteComponent.prototype.DiscountType = function (evt) {
        if (evt.value == 0) {
            this.check_discount = false;
            this.Form.patchValue({
                discount: evt.value
            });
            this.Discount_total();
        }
        if (evt.value == undefined) {
            this.check_discount = true;
            this.Form.patchValue({
                discount: 0
            });
            this.Discount_total();
        }
        if (evt.value != undefined && evt.value != 0) {
            this.check_discount = true;
            this.Form.patchValue({
                discount: evt.value
            });
            this.Discount_total();
        }
    };
    CreateQuoteComponent.prototype.DiscountType_input = function () {
        if (this.Form.value.discount < 0) {
            this.toastr.error('Discount never be negative');
            return;
        }
        if (this.Form.value.discount == 0) {
            this.Form.patchValue({
                discount: 0
            });
            this.Discount_total();
        }
        else {
            this.Form.patchValue({
                discount: this.Form.value.discount
            });
            this.Discount_total();
        }
    };
    // Discount type promotion
    CreateQuoteComponent.prototype.getDiscountTypePromotion = function () {
        var _this = this;
        this.projectsService.getDiscountTypePromotion()
            .subscribe(function (res) {
            if (res) {
                _this.DiscoutPromotion = res.data;
            }
        }, function (error) {
            _this.toastr.error('Something went wrong, Please try later!');
        });
    };
    // calulate time from minute
    CreateQuoteComponent.prototype.timeConvert = function (n) {
        if (n < 0) {
            n = -1 * n;
        }
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var days = (hours / 24);
        var rdays = Math.floor(days);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ' hour(s) and ' + rminutes + ' minute(s)';
    };
    // tslint:disable-next-line: typedef
    CreateQuoteComponent.prototype.openDialogEmailPreview = function (res) {
        var data;
        data = res;
        var dailogRef = this.dialog.open(EmailPreviewComponent, {
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
        });
    };
    // get Additional Services
    CreateQuoteComponent.prototype.getAdditionalServices = function () {
        var _this = this;
        this.projectsService.getAdditionalServices(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.additonal_service_array = response.project_addition_service;
                _this.NewAdditionalServicesTotal = 0;
                if (_this.additonal_service_array.length > 0) {
                    _this.project_quote_additional_service = [];
                    _this.additonal_service_array.forEach(function (element) {
                        var obj = {
                            service_id: element.additional_service.service_id,
                            additional_service_id: element.additional_service_id,
                            title: element.additional_service.title,
                            quantity: element.quantity,
                            rate: element.price,
                            sub_total: element.sub_total,
                            additional_info: null
                        };
                        _this.NewAdditionalServicesTotal += parseFloat(element.sub_total);
                        _this.project_quote_additional_service.push(obj);
                    });
                    _this.NewAdditionalServicesTotal = _this.roundToTwo(_this.NewAdditionalServicesTotal);
                }
            }
        });
    };
    // Delete Additional Service
    CreateQuoteComponent.prototype.removeAdditionalService = function (res) {
        var _this = this;
        this.projectsService.deleteProjectAdditionalService(res.id).subscribe(function (response) {
            if (response) {
                _this.toastr.success(' Delete Project Additional Service  Successfully!!');
                var quote_subtotal = _this.roundToTwo(_this.Form.value.quote_subtotal - res.sub_total);
                var discount_amt = 0;
                if (_this.Form.value.discount) {
                    discount_amt = quote_subtotal * (_this.Form.value.discount / 100);
                    discount_amt = _this.roundToTwo(discount_amt);
                }
                var final__total = quote_subtotal - discount_amt;
                final__total = _this.roundToTwo(final__total);
                _this.Form.patchValue({
                    total: final__total,
                    quote_subtotal: quote_subtotal,
                    email_template_id: ['', Validators.compose([Validators.required])],
                    delivery_date: ['', Validators.compose([Validators.required])],
                    discount_amount: discount_amt
                });
                _this.getAdditionalServices();
            }
        });
    };
    // popup speciality
    CreateQuoteComponent.prototype.openDialog = function () {
        var _this = this;
        var dailogRef = this.dialog.open(SpecialityChangeComponent, {
            width: '400px',
            data: this.project_id
        });
        dailogRef.afterClosed().subscribe(function (response) {
            _this.getProjectDetail();
        });
    };
    // popup Change Language
    CreateQuoteComponent.prototype.openDialogLanguage = function () {
        var _this = this;
        var dailogRef = this.dialog.open(LanguageChangeComponent, {
            width: '450px',
            data: this.project_id
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getProjectDetail();
                _this.getUniqueProjectLanguage();
            }
        });
    };
    // popup Change Deadline
    CreateQuoteComponent.prototype.openDialogDeadline = function () {
        var _this = this;
        var data = {
            project_id: this.project_id,
            due_date: ''
        };
        if (this.response_data.due_date.date) {
            data.due_date = this.response_data.due_date.date;
        }
        var dailogRef = this.dialog.open(DeadlineChangeComponent, {
            width: '350px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getProjectDetail();
            }
        });
    };
    // popup Update service type
    CreateQuoteComponent.prototype.openDialogServiceType = function () {
        var _this = this;
        var data = {
            project_id: this.project_id,
            translation_type: ''
        };
        if (this.response_data.translation_type) {
            data.translation_type = this.response_data.translation_type;
        }
        var dailogRef = this.dialog.open(UpdateServiceTypeComponent, {
            width: '350px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getProjectDetail();
            }
        });
    };
    return CreateQuoteComponent;
}());
export { CreateQuoteComponent };
