import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-update-service-type',
  templateUrl: './update-service-type.component.html',
  styleUrls: ['./update-service-type.component.scss']
})
export class UpdateServiceTypeComponent implements OnInit {

  Form:any;
  specialityData: any = [];
  today: Date = new Date();
  minDate = this.today;
  constructor( private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectsService: ProjectsService,
    private dialogRef: MatDialogRef<UpdateServiceTypeComponent>) { }

  ngOnInit() {
    
    this.Form = this._formBuilder.group({
      translation_type : ['',Validators.compose([Validators.required])]
  });

  this.Form.patchValue({
    translation_type: this.data.translation_type
});

  }

  Onclose(){
    this.dialogRef.close(true);
}




  submit(){

  
   let final_data= {
      translation_type: this.Form.value.translation_type
    }

    this.projectsService.updateServiceType(this.data.project_id , final_data)
    .subscribe((response) => {
        if(response) {
          this.toastr.success("Service type update successfully!");
         this.Onclose();

        }
    });
  }


    
  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + '-' + month + '-' + dt + ' 00:00:00';
  }

}
