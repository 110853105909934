import { Component, OnInit } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from 'app/main/shared.service';
import { Router, ActivatedRoute, ChildActivationStart, ChildActivationEnd } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material';
import { MatTableDataSource } from "@angular/material";
import { ProjectReleaseComponent } from './project-release/project-release.component';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { SpecialityChangeComponent } from './speciality-change/speciality-change.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { distinct } from 'rxjs/operators';
import { VendorService } from 'app/main/Services/vendor.service';
import { LanguageChangeComponent } from './language-change/language-change.component';
import { TransactionsComponent } from '../customer/transactions/transactions.component';
import { CreateTransactionComponent } from '../customer/transactions/create-transaction/create-transaction.component';
import { DeadlineChangeComponent } from './deadline-change/deadline-change.component';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { UpdateServiceTypeComponent } from './update-service-type/update-service-type.component';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  public index_id: number;
  linear: boolean = true;
  dataSource: any;
  project_id: string;
  showAddAdditionService: boolean = false;
  is_locked: string;
  TimeElapsed: any;
  locked_by_value: any;
  additonal_service_array: any = [];
  locked_by: string;
  show: boolean = false;
  user_id: string;
  file_id_data: any = [];
  username: string;
  crm_file_data: any = [];
  user_group_id: string;
  NewAdditionalServicesTotal: number = 0;
  Form: FormGroup;
  version: any = '';
  commentForm: FormGroup;
  project_type: number;
  comment_data_update: any = {};
  rush_project_data: any = {};
  RequestQueue_response: any;
  important_messages_response: any = [];
  locked_message: string = '';
  is_active_class: string = '';
  languageData: any = [];
  // check_Mailing:boolean=true;
  // check_Notarization:boolean=true;
  // check_Apostile:boolean=true;
  check_class: number;
  // project_locked_record: any =[];
  quote_approve: any = [];
  // all_locked_projects:string;
  quote_status: any;
  specialization_id: number;
  DiscoutPromotion: any = [];
  MarkAsStatus: any = [];
  AddonResponse: any = '';
  AutoQuoteInfoResponse: any = '';
  CrmAccountInfoResponse: any = '';
  DetailMainResponse: any = '';
  response_data_quoteArray: any = [];
  activeInternal:boolean = false;
  data: any =
    {
      source_lang_id: '',
      service_id: '',
      project_language_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },

      targetlanguage: {
        data: {
          lang_name: ''
        }
      },

      target_lang_id: '',
      quantity: '',
      pricing_method: '',
      rate: '',
      sub_total: ''
    };

  BaseUrl_file: any;
  CrmUrl: any;
  CrmAccountUrl: any;
  project_manager_id: string;
  total_quote_amount: number;
  source_language_value: string;
  source_language_id_value: number;
  target_language_value: string;
  project_lang_data: any = [];
  table_data: any = [];
  displayedColumns = ['langPair', 'translationRate', 'proofindRate', 'sub_total', 'actions'];
  createdTime: any;
  now: Date;
  elapsedTime: any;
  DueTime: any;
  DueTime_env: any;
  totalmin: number;
  totoalminuts: any;
  diffTime: number;
  elapsedMinuts: any;
  elapsedHours: any;
  totaltimeElepsed: any;
  checkdueTime: boolean;
  compare_target_lang: string;
  project_stage_id: number;
  discount_value: number;

  getValueMailing: string = "(NA)";
  getValueNotarization: string = "(NA)";
  getValueApostile: string = "(NA)";
  project_quote_additional_service: any = [];
  source_id: any = [];
  target_id: any = [];
  unique_source_language: any = [];
  unique_target_language: any = [];

  source_language_array: any = [];
  target_language_array: any = [];
  crmStatusObject: any = {
    lead_status: '',
    opp_stage: ''
  }
  checkCrmStatus: boolean = true;
  crmdoc_data: any = [];
  constructor(private _fuseNavigationService: FuseNavigationService,
    private route: ActivatedRoute, private toastr: ToastrService,
    private projectsService: ProjectsService, private _formBuilder: FormBuilder, private router: Router,
    private vendorService: VendorService,
    private dialog: MatDialog,
    private _location: Location
  ) {
    this.BaseUrl_file = environment.baseUrl_file;


    this.DueTime_env = environment.Due_time;
  }

  ngOnInit() {
    this.user_id = SharedService.get('id');
    this.username = SharedService.get('username');
    this.user_group_id = SharedService.get('user_group_id');
    this.getLanguage();
    this.project_id = this.route.snapshot.params['id'];
    this.getAdditionalServices();
    this.getDiscountTypePromotion();
    this.getMarkAsStatus();
    this.getProjectDetail();
    // this.getUniqueProjectLanguage();
    this.getImportantNotes();

    this.Form = this._formBuilder.group({
      total: ['', Validators.compose([Validators.required])],
      discount_amount: [''],
      discount: ['']
    });

    // this.router.events.subscribe(e=>{
    //   if (e instanceof ChildActivationStart) {
    //     console.log(e.snapshot.children[0].params.id)
    //     window.location.href = '/pms/project/details/'+e.snapshot.children[0].params.id;

    //   } 
    // })

  }

  // goToCreateQuote(res:any){
  //   window.location.href = '/pms/project/'+res+'/create-quote/';
  // }

  getImportantNotes() {
    this.projectsService.getImportantNotes(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.important_messages_response = response.data;
        }
      });
  }

  getProjectDetail() {
    this.commentForm = this._formBuilder.group({
      message: ['', Validators.compose([Validators.required])],
      message_type: ['0', Validators.compose([Validators.required])],
      is_important: [false]
    });

    this.projectsService.getProjectDetail(this.project_id)
      .subscribe((response: any) => {
        if (response) {
          this.getUniqueProjectLanguage();
          if (response.data.crm_opp_id) {
            this.getCrmdocuments(response.data.crm_opp_id);
            this.getAddonInfo(response.data.crm_opp_id);
            this.getAutoQuoteInfo(response.data.crm_opp_id);
            this.getCrmAccountInfo(response.data.crm_opp_id);
            this.getDetailMain(response.data.crm_opp_id);
          }

          if (response.data.crm_opp_id && response.data.crm_type) {
            let crm_type;
            if (response.data.crm_type == 'Opportunity') {
              crm_type = 'Opportunities';
              this.CrmUrl = environment.crmUrl + '?module=' + crm_type + '&action=DetailView' + '&record=' + response.data.crm_opp_id;
            }
            if (response.data.opp_stage == null) {
              this.crmStatusObject.opp_stage = '';
            }

            if (this.crmStatusObject.opp_stage != null) {
              this.crmStatusObject.opp_stage = response.data.opp_stage; // test
              this.checkCrmStatus = false;
            }
          }
          else if (response.data.crm_lead_id && response.data.crm_type) {
            let crm_type;
            if (response.data.crm_type == 'lead') {
              crm_type = 'Leads';
              this.CrmUrl = environment.crmUrl + '?module=' + crm_type + '&action=DetailView' + '&record=' + response.data.crm_lead_id;
            }
            if (response.data.lead_status == null) {
              this.crmStatusObject.lead_status = '';
            }
            if (this.crmStatusObject.lead_status != null) {
              this.crmStatusObject.lead_status = response.data.lead_status; // test
              this.checkCrmStatus = false;
            }
          }
          this.DiscoutPromotion.forEach(dis_res => {
            if (response.data.promo_code == dis_res.coupon) {
              this.discount_value = dis_res.value;
            }
          });

          let dt2 = new Date();
          var datetime = moment(response.data.created_at.date);
          var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
          var minutes = current.diff(datetime, 'minutes');
          this.check_class = minutes;

          if (minutes > 60) {
            var diff;
            this.TimeElapsed = this.timeConvert(minutes);
            diff = this.DueTime_env - minutes;
            if (diff < 0) {
              this.checkdueTime = false;
              this.DueTime = this.timeConvert(diff);
              this.DueTime = '-' + this.DueTime;
            }
            else {
              this.checkdueTime = true;
              this.DueTime = this.timeConvert(diff);
            }
          }
          else {
            this.TimeElapsed = minutes + ' Mintue(s)';
            this.DueTime = this.DueTime_env - minutes;
            if (this.DueTime < 0) {
              this.checkdueTime = false;
              this.DueTime = this.DueTime_env - minutes + ' Mintue(s)';
            }
            else {
              this.checkdueTime = true;
              this.DueTime = this.DueTime_env - minutes + ' Mintue(s)';
            }
          }

          this.RequestQueue_response = response.data;
          if(response.data.rush_project == 0){
            this.activeInternal = false;
          }else{
            this.activeInternal = true;
          }
          
          this.project_stage_id = this.RequestQueue_response.stage_id;
          this.is_locked = this.RequestQueue_response.is_locked;
          this.locked_by = this.RequestQueue_response.locked_by;
          this.locked_by_value = this.locked_by;
          if (this.user_id == this.locked_by) {
            this.show = true;
          }
          else if (this.user_id != this.locked_by) {
            if (this.locked_by == null) {
              this.show = true;
            } else {
              this.show = false;
              this.projectLock();
            }

          }

          this.version = this.RequestQueue_response.version
          this.specialization_id = response.data.specilization_id;
          this.project_type = response.data.service_id;
          if (response.data.projectmanager != null) {
            this.project_manager_id = response.data.projectmanager.data.id;

          }
          if (response.data.projectquote.data.length > 0) {
            response.data.projectquote.data.forEach(ele => {
              ele.projectquoteservice.data.forEach(element => {
                element.language = [];
                this.data = {
                  source_lang_id: '',
                  service_id: '',
                  project_language_id: '',
                  sourcelanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  targetlanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  target_lang_id: '',
                  quantity: '',
                  pricing_method: '',
                  rate: '',
                  sub_total: '',
                };

                this.data.quantity = element.quantity;
                this.data.pricing_method = element.pricing_method;
                this.data.rate = element.rate;
                this.data.sub_total = element.subTotal;
                element.projectlanguages.data.forEach(elem => {
                  this.data.sourcelanguage.data.lang_name = elem.sourcelanguage.data.lang_name;
                  this.data.targetlanguage.data.lang_name = elem.targetlanguage.data.lang_name;
                  if (this.quote_status == 4) {
                    this.compare_target_lang = this.data.targetlanguage.data.lang_name;
                  }

                });

                element.language.push(this.data);

              });
            });

            this.response_data_quoteArray = response.data.projectquote.data;
            this.response_data_quoteArray.forEach(res => {

              let is_active_class = '';
              if (res.is_active == 0) {
                is_active_class = 'quote_inactive';
              }
              else if (res.is_active == 1) {
                is_active_class = 'quote_active';
              }
              res.is_active_class = is_active_class;

              this.quote_status = res.status;

              if (this.quote_status == 2 || this.quote_status == 3) {
                this.data =
                {
                  source_lang_id: '',
                  service_id: '',
                  project_language_id: '',
                  sourcelanguage: {
                    data: {
                      lang_name: ''
                    }
                  },

                  targetlanguage: {
                    data: {
                      lang_name: ''
                    }
                  },

                  target_lang_id: '',
                  quantity: '',
                  pricing_method: 'Words',
                  rate: '',
                  sub_total: ''

                };

              }

            });


          }


        }
      });
  }

  showAddAdditionalServices() {
    this.showAddAdditionService = true;
  }

  showAddAdditionalServices_click() {
    this.index_id = 0;
    this.showAddAdditionService = true;
  }


  //get child component data start
  getDataMailing(value: any) {
    this.index_id = 0;
    if (value.title == "(Applied)") {
      this.getValueMailing = value.title;
    } else {
      this.getValueMailing = "(NA)";
    }

    if (value.click_value == 1) {
      this.showAddAdditionalServices();
      let top_additional_service = document.getElementById('top_additional_service');
      if (top_additional_service != null) {
        top_additional_service.scrollIntoView();
        top_additional_service = null;
      }
    }
  }

  getDataNotarization(value: any) {
    // this.getValueNotarization = value;
    this.index_id = 1;
    if (value.title == "(Applied)") {
      this.getValueNotarization = value.title;
    } else {
      this.getValueNotarization = "(NA)";
    }


    if (value.click_value == 1) {
      this.showAddAdditionalServices();
      let top_additional_service = document.getElementById('top_additional_service');
      if (top_additional_service != null) {
        top_additional_service.scrollIntoView();
        top_additional_service = null;
      }

    }
  }

  getDataApostile(value) {
    this.index_id = 2;
    // this.getValueApostile = value;
    if (value.title == "(Applied)") {
      this.getValueApostile = value.title;
    } else {
      this.getValueApostile = "(NA)";
    }

    if (value.click_value == 1) {
      this.showAddAdditionalServices();
      let top_additional_service = document.getElementById('top_additional_service');
      if (top_additional_service != null) {
        top_additional_service.scrollIntoView();
        top_additional_service = null;
      }

    }
  }
  //get child component data end

  submitComment() {

    this.comment_data_update.message_type = this.commentForm.value.message_type;
    this.comment_data_update.message = this.commentForm.value.message;
    this.comment_data_update.from_user_id = this.user_id;
    this.comment_data_update.is_important = this.commentForm.value.is_important;
    this.projectsService.updateComment(this.project_id, this.comment_data_update)
      .subscribe((response) => {
        if (response) {

          if (response.data.mail_response) {
            this.toastr.success(response.data.mail_response);
          }
          else {
            this.toastr.success("Updated");
          }
          this.getImportantNotes();
          this.getProjectDetail();

        }
      });
  }

  projectLock() {
    let data: any = {
      project_id: '',
      is_locked: false
    }
    data.project_id = this.project_id;
    data.is_locked = true;
    this.projectsService.projectLock(data)
      .subscribe((response) => {
        if (response) {
          if (this.locked_by != this.user_id) {
            if (this.locked_by_value) {
              this.locked_message = response.data;
            }
          }
        }
      });
  }



  Total_cost() {
    if (this.Form.value.words != '' && this.Form.value.rate != '') {
      let total = 0
      total = this.Form.value.words * this.Form.value.rate;

      this.Form.patchValue({
        total_cost: total
      });
    }
    else {
      this.Form.patchValue({
        total_cost: ''
      });
    }
  }


  // get unique project language
  getUniqueProjectLanguage() {

    let data: any = {
      project_id: this.project_id
    }

    this.source_language_array = [];
    this.target_language_array = [];
    this.target_language_value = '';
    this.source_language_value = '';
    this.projectsService.getUniqueProjectLanguage(data)
      .subscribe((response: any) => {
        if (response) {
          this.project_lang_data = response.source_language_name;
          this.source_language_array = response.source_language_name;
          this.target_language_array = response.target_language_name;
          this.source_language_value = this.getLanguageName(this.source_language_array);
          this.target_language_value = this.getLanguageName(this.target_language_array);
        }
      });
  }

  getLanguageName(res: any) {
    let lang = '';
    if (res.length > 0) {
      res.forEach(element => {
        lang += element.lang_name + ', ';
      });
    }
    lang = lang.slice(0, -2);
    return lang;
  }

  // Get Language
  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res: any) => {
        if (res) {
          this.languageData = res.data;
        }
      });
  }


  // pop-up 

  projectRelease() {
    const dailogRef = this.dialog.open(ProjectReleaseComponent, {
      width: '500px',
      data: { project_id: this.project_id }

    })
    dailogRef.afterClosed().subscribe((response) => {
      if (response) {
        // this.getProjectDetail();
      }
    });
  }


  // calulate time from minute

  timeConvert(n) {
    if (n < 0) {
      n = -1 * n;
    }
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var days = (hours / 24);
    var rdays = Math.floor(days);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s)";
  }

  // Discount type promotion
  getDiscountTypePromotion() {
    this.projectsService.getDiscountTypePromotion()
      .subscribe((res) => {
        if (res) {
          this.DiscoutPromotion = res.data;
        }
      });
  }


  // Get Mark as status
  getMarkAsStatus() {
    this.projectsService.getMarkAsStatus()
      .subscribe((res: any) => {
        if (res) {
          this.MarkAsStatus = res.data;
        }
      }, (error) => {
        this.toastr.error("Something went wrong, Please try later!");
      });
  }

  // update mark as status
  updateMarkAs(res: any) {
    let obj = {
      status_id: ''
    }
    obj.status_id = res;
    this.projectsService.updateMarkAsStatus(this.project_id, obj)
      .subscribe((response) => {
        if (response) {
          this.toastr.success('Status update successfully');
          this.getProjectDetail();
        }
        //  else {
        //   this.toastr.error('Something went wrong!!');
        // }
      });
  }

  // popup speciality
  openDialog() {
    let data = {
      project_id: this.project_id,
      specilization_id: this.RequestQueue_response.specilization_id

    }

    const dailogRef = this.dialog.open(SpecialityChangeComponent, {
      width: '400px',
      data: data
    });
    dailogRef.afterClosed().subscribe((response) => {
      this.getProjectDetail();
    });
  }

  // Delete project
  openDialogDeleteProject(): void {
    let obj = {
      project_id: this.project_id,
      message: 'Do you confirm the deletion of this project?'
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      height: '30%',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // DO SOMETHING
      }
    });
  }

  // Transaction start

  Transaction(id: any): void {
    SharedService.set(SharedService.QUOTE_ID, id);
    // let obj = {
    //   quote_id: id,
    //   message: 'Do you confirm the deletion of this project?'
    // }
    const dialogRef = this.dialog.open(CreateTransactionComponent, {

      // data: obj
    });

    dialogRef.afterClosed().subscribe(result => {

      // this.getProjectDetail();
      window.location.href = '/pms/project/details/' + this.project_id;

      // DO SOMETHING

    });
  }

  // Transaction end



  // get Additional Services

  getAdditionalServices() {
    this.projectsService.getAdditionalServices(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.additonal_service_array = response.project_addition_service;
          this.NewAdditionalServicesTotal = 0;
          if (this.additonal_service_array.length > 0) {
            this.project_quote_additional_service = [];
            this.additonal_service_array.forEach(element => {
              let obj: any =
              {
                service_id: element.additional_service.service_id,
                additional_service_id: element.additional_service_id,
                title: element.additional_service.title,
                quantity: element.quantity,
                rate: element.price,
                sub_total: element.sub_total,
                additional_info: null
              }
              this.NewAdditionalServicesTotal += parseFloat(element.sub_total);
              this.project_quote_additional_service.push(obj);
            });
            this.NewAdditionalServicesTotal = this.roundToTwo(this.NewAdditionalServicesTotal);
          }
        }
      });
  }


  roundToTwo(num) {
    return Math.round(num * 100) / 100;
  }

  // Donot follow up
  doNotFollowUp() {
    let obj = {
      project_id: ''
    }
    obj.project_id = this.project_id;
    this.projectsService.updateLeadEnquiryDate(obj)
      .subscribe((response: any) => {
        if (response) {
          this.toastr.success(response.data);
        }
      }, (error) => {
        this.toastr.error(error.error.data);
      });
  }


  // popup Change Language
  openDialogLanguage() {
    const dailogRef = this.dialog.open(LanguageChangeComponent, {
      width: '700px',
      data: this.project_id
    });
    dailogRef.afterClosed().subscribe((response) => {

      if (response) {

        this.getUniqueProjectLanguage();
      }

    });
  }

  // popup Change Deadline
  openDialogDeadline() {
    let data = {
      project_id: this.project_id,
      due_date: ''

    }
    if (this.RequestQueue_response.due_date != null) {
      data.due_date = this.RequestQueue_response.due_date.date;
    }

    const dailogRef = this.dialog.open(DeadlineChangeComponent, {
      width: '350px',
      data: data
    });
    dailogRef.afterClosed().subscribe((response) => {

      if (response) {

        this.getProjectDetail();
      }

    });
  }



  crmStatusChange(evt) {

    if (evt.value == '') {
      this.checkCrmStatus = true;
    } else {
      this.checkCrmStatus = false;
    }
  }


  // Update Lead status or opportuity stage
  updateCrmStatus() {

    let projectCrmData: any = {
      project_id: '',
      crm_id: '',
      status: ''
    }
    let message;
    if (this.RequestQueue_response.crm_lead_id) {
      projectCrmData.project_id = this.project_id;
      projectCrmData.crm_id = this.RequestQueue_response.crm_lead_id;
      projectCrmData.status = this.crmStatusObject.lead_status;
      message = 'Crm status updated successfully';
    } else if (this.RequestQueue_response.crm_opp_id) {
      projectCrmData.project_id = this.project_id;
      projectCrmData.crm_id = this.RequestQueue_response.crm_opp_id;
      projectCrmData.status = this.crmStatusObject.opp_stage;
      message = 'Crm stage updated successfully';
    }

    this.projectsService.updateProjectCrmStatus(projectCrmData)
      .subscribe((response) => {
        if (response) {
          window.location.href = '/pms/project/details/' + this.project_id;
          this.toastr.success(message);
        }
      });
  }


  // popup Update service type
  openDialogServiceType() {
    let data = {
      project_id: this.project_id,
      translation_type: ''
    }

    if (this.RequestQueue_response.translation_type) {
      data.translation_type = this.RequestQueue_response.translation_type;
    }

    const dailogRef = this.dialog.open(UpdateServiceTypeComponent, {
      width: '350px',
      data: data
    });
    dailogRef.afterClosed().subscribe((response) => {

      if (response) {

        this.getProjectDetail();
      }

    });
  }

  back() {
    this._location.back();
  }

  getCrmdocuments(crmid: any) {
    this.projectsService.getCrmdocuments(crmid)
      .subscribe((response: any) => {
        if (response) {
          if (response.data.length > 0) {
            this.crmdoc_data = response.data;
            response.data.forEach(element => {
              let obj: any =
              {
                file_name: element.download_link_without_login_c.value,
                file_path: element.filename.value
              }
              this.crm_file_data.push(obj);
            });

          } else {
            this.crmdoc_data = '';
          }
        } else {
          this.crmdoc_data = '';
        }
      });
  }

  getDetailMain(crmid: any) {
    this.projectsService.getDetailMain(crmid)
      .subscribe((response: any) => {
        if (response) {
          this.DetailMainResponse = response.data;
        };
      });
  }



  getAutoQuoteInfo(crmid: any) {
    this.projectsService.getAutoQuoteInfo(crmid)
      .subscribe((response: any) => {
        if (response) {
          this.AutoQuoteInfoResponse = response.data;
        };
      });
  }


  getCrmAccountInfo(crmid: any) {
    this.projectsService.getCrmAccountInfo(crmid)
      .subscribe((response: any) => {
        if (response) {
          this.CrmAccountInfoResponse = response.data;
          this.CrmAccountUrl = environment.crmUrl + '?module=Accounts&action=DetailView&record=' + response.data.id.value;
        };
      });
  }

  getAddonInfo(crmid: any) {
    this.projectsService.getAddonInfo(crmid)
      .subscribe((response: any) => {
        if (response) {
          this.AddonResponse = response.data;
        };
      });
  }


  convertIntoFloat(n: any) {
    let x = parseInt(n);
    let res = x.toFixed(2);
    return res;
  }

  // selectFile(evt, res) {
  //   debugger;
  //   console.log(res[0].id.value);
  //   if (evt.checked && this.crm_file_data.indexOf(res[0].id.value == -1)) {
  //     let obj: any =
  //     {
  //       file_name: res[0].download_link_without_login_c.value,
  //       file_path: res[0].filename.value
  //     }
  //     this.crm_file_data.push(obj);
  //   }
  //   console.log(this.crm_file_data);
  //   // else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
  //   //     let index = this.file_id_data.indexOf(res.id);
  //   //     this.file_id_data.splice(index, 1);
  //   // }

  // }


  // selectBulkProgress(evt) {
  //   debugger;
  //   this.file_id_data = [];
  //   let check = false;
  //   this.crm_file_data.forEach((element) => {
  //     console.log(element);
  //     element["check"] = evt.checked;
  //     if (evt.checked) {
  //       element.check = true;
  //       this.file_id_data.push(element);
  //     }
  //     else {
  //       this.file_id_data = [];
  //     }
  //   });

  //   console.log(this.file_id_data);
  //   // this.DownloadZip();
  //   // if (check) {
  //   //   this.bulk_selected_progress = true;
  //   // }

  // }

  UncheckedFile() {
    // this.bulk_selected_progress = false;
    // this.file_data.forEach((element, key) => {
    //   element["check"] = false;
    //   let index = this.file_id_data.indexOf(element.id);
    //   this.file_id_data.splice(index, 1);
    // });
  }

  markAsRushProject(event){
    this.rush_project_data.project_id = this.project_id;
    this.rush_project_data.rush_project = event.checked;
    this.projectsService.markAsRushProject(this.rush_project_data)
      .subscribe((response) => {
        if (response) {
          this.toastr.success(response.message);
        }
      });
   

  }

}