import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef } from '@angular/material';
var SpecialityChangeComponent = /** @class */ (function () {
    function SpecialityChangeComponent(_formBuilder, vendorService, toastr, data, projectsService, dialogRef) {
        this._formBuilder = _formBuilder;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.data = data;
        this.projectsService = projectsService;
        this.dialogRef = dialogRef;
        this.specialityData = [];
    }
    SpecialityChangeComponent.prototype.ngOnInit = function () {
        this.getSpeciality();
        this.Form = this._formBuilder.group({
            specilization_id: ['', Validators.compose([Validators.required])]
        });
        if (this.data.specilization_id != null) {
            this.Form.patchValue({
                specilization_id: this.data.specilization_id
            });
        }
    };
    SpecialityChangeComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    SpecialityChangeComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                _this.specialityData = res;
            }
        });
    };
    SpecialityChangeComponent.prototype.submit = function () {
        var _this = this;
        this.projectsService.updateSpeciality(this.data.project_id, this.Form.value)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Speciality change successfully!");
                _this.Onclose();
            }
        });
    };
    return SpecialityChangeComponent;
}());
export { SpecialityChangeComponent };
