<div mat-dialog-content class="p-16 pt-4 m-0" >
        <div fxLayout="column" fxLayoutAlign="start">

          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">  
             <mat-label>From:</mat-label>
                <p>{{response_email?.from}}</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
             <mat-label>To:</mat-label>
             <p>{{response_email?.to}}</p>
        </div>
    

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">  
                <mat-label>Subject:</mat-label>
                <p>{{response_email?.subject}}</p>
        </div>

      
                      
        <div [innerHTML]="response_email?.body | keepHtml"></div>
       
      

     
   </div> 
      
    
  </div>
  <div class="actWraper" fxLayout="column"  fxLayout.gt-sm="row" >
        <button mat-raised-button color="primary" (click)="Onclose()" >Cancel</button>
    </div>