import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var EmailLogPreviewComponent = /** @class */ (function () {
    function EmailLogPreviewComponent(data, toastr, dialogRef, projectsService) {
        this.data = data;
        this.toastr = toastr;
        this.dialogRef = dialogRef;
        this.projectsService = projectsService;
    }
    EmailLogPreviewComponent.prototype.ngOnInit = function () {
        if (this.data) {
            this.id = this.data;
            this.getsingleEmailLogs();
        }
    };
    EmailLogPreviewComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    EmailLogPreviewComponent.prototype.getsingleEmailLogs = function () {
        var _this = this;
        this.projectsService.getProjectEmailById(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.response_email = response.data;
                // this.paginate = response.meta.pagination;
                // this.total=response.meta.pagination.total;
            }
        });
    };
    return EmailLogPreviewComponent;
}());
export { EmailLogPreviewComponent };
