<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon routerLink="/masterdata">keyboard_backspace</mat-icon> 
    
    </button>
    <span class="title-text h1">
      Template Master
    </span>
  </div>

  <div class="white-box">
    <form name="Form" [formGroup]="Form">
      <div class="boxBodyInn">
        <div class="profHeader" fxLayout="row wrap" fxLayoutGap="10px">
          <div fxFlex="28">
            <p class="dValue">
              <mat-form-field>
                <mat-label>Template Title*</mat-label>
                <input matInput formControlName="title">
                <mat-error *ngIf="Form.get('title').hasError('required')">
                  Template Title is required!
                </mat-error>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="28">
            <p class="dValue">
              <mat-form-field>
                <mat-label>Subject*</mat-label>
                <input matInput formControlName="subject">
                <mat-error *ngIf="Form.get('subject').hasError('required')">
                  Subject is required!
                </mat-error>
              </mat-form-field>
            </p>
          </div>
          <div fxFlex="28">
              <p class="dValue mt-12">
             <mat-radio-group aria-label="Select an option" formControlName="status" >
              <mat-radio-button [value]="1">Active</mat-radio-button>
              <mat-radio-button [value]="0">In Active</mat-radio-button>
            </mat-radio-group>
            </p>
            </div>
        </div>
        <div class="profHeader" fxLayout="row wrap" fxLayoutGap="10px">
          <div fxFlex="28">
            <p class="dValue">
              <mat-form-field>
                <mat-label>From</mat-label>
                <input matInput formControlName="email_from">
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="28">
            <p class="dValue">
              <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput formControlName="email_to">
              </mat-form-field>
            </p>
          </div>
          <div fxFlex="28">
          <mat-form-field   fxFlex="100">
            <mat-label>Type</mat-label>
            <mat-select  formControlName="type" disableOptionCentering class="myPanelClass">         
              <mat-option [value]="''">
                      Select
                  </mat-option>
          <mat-option *ngFor="let res of templateType" [value]="res.type">
              {{res.type}}
          </mat-option>
      </mat-select>
      <mat-error *ngIf="Form.get('type').hasError('required')">
          Type is required!
      </mat-error>
        </mat-form-field>
        </div>
        
        </div>

        <div class="editorContainer">
            <h4>Email Content</h4>
<ckeditor formControlName="ckeditorContent" [config]="config" [readonly]="false" debounce="500">

</ckeditor>
        </div>
      </div>

      <div class="actWraper">
        <button matTooltip="Approve" mat-raised-button class="green-bg mr-8" (click)="submit()"
          [disabled]="Form.invalid">
          <mat-label class="Text_Color">Submit</mat-label>
        </button>
      </div>
    </form>
  </div>

  <div class="white-box" >
   
    <div class="tHeader">
      <h4>Email Templates</h4>
    </div>
     <!-- test filter-->
     <div class="boxBodyInn">
      <div class="mt-12 mb-12" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxLayoutGap="10px">
          <h2 class="ml mr-16"><i class="material-icons"> filter_list </i></h2>
          <mat-form-field   fxFlex="15">
              <mat-label class="font-size-12">Status</mat-label>
              <mat-select (selectionChange)="onFilter($event,'status')">
                <mat-option [value]="''">
                  Any
                </mat-option>
                <mat-option [value]="'1'">
                  Active
                </mat-option>
                <mat-option [value]="'0'">
                  Inactive
                </mat-option>
              </mat-select>
          </mat-form-field>
          <div class="search-wrapper mx-32 mx-md-0" fxFlex="15">
              <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon class="mt-12">search</mat-icon>
                  <input placeholder="Search" (keyup)="onFilter($event.target.value,'search')">
              </div>
          </div>
      </div>
    </div>
    <!-- test filter -->
    <div *ngIf="paginate?.total>0">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="8">ID
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm fxFlex="8">
               <p>{{element.id}}</p>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="20">Service Title
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm fxFlex="20">
               <p>{{element.name}}</p>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="desc">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="20">Service Subject
            </mat-header-cell>1
            <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm fxFlex="20">
               <p>{{element.subject}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email_from">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="20">From
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm fxFlex="20">
               <p>{{element?.email_from}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email_to">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="20">To
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm fxFlex="20">
               <p>{{element?.email_to}}</p>
            </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="20">Service Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm fxFlex="20">
                <p *ngIf="element.is_active==0">In active</p> 
                <p *ngIf="element.is_active==1">Active</p>
            </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <!-- <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button class="bicon" title="Edit" (click)="openDialog(element.id)">
              <i class="material-icons">
                edit
              </i></button> </td> -->
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="4">Action
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm fxFlex="4">
                    <button mat-stroked-button class="bicon" title="Edit" (click)="openDialog(element.id)">
                        <i class="material-icons">
                          edit
                        </i></button> 
                </mat-cell>
        </ng-container>
  
        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </table>
      <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
        [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[10,20,30,40,50,60]"
        (page)='paginateData($event)'>
      </mat-paginator>
    </div>
 
    <div *ngIf="paginate?.total==0" class="noDataFound">
      No Data available!!
  </div>

  </div>
</div>