import { OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
var ELEMENT_DATA = [
    { position: 1, id: 'Bondarev-08201931972', leadSource: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Paid', projValue: '$455.00 USD' },
    { position: 2, id: 'Bondarev-08201931972', leadSource: 'DayVIP', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'In-Process', projValue: '$455.00 USD' },
    { position: 3, id: 'Bondarev-08201931972', leadSource: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Lost', projValue: '$455.00 USD' },
    { position: 4, id: 'Bondarev-08201931972', leadSource: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Under Quotation', projValue: '$455.00 USD' },
    { position: 5, id: 'Bondarev-08201931972', leadSource: 'YST Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Approved', projValue: '$455.00 USD' },
    { position: 6, id: 'Bondarev-08201931972', leadSource: 'DT Website', projservices: 'Translation, Notarization', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'New Request', projValue: '$455.00 USD' },
    { position: 7, id: 'Bondarev-08201931972', leadSource: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Paid', projValue: '$455.00 USD' },
    { position: 8, id: 'Bondarev-08201931972', leadSource: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Paid', projValue: '$455.00 USD' },
];
var CustomerDetailsComponent = /** @class */ (function () {
    function CustomerDetailsComponent(projectsService, router, route, toastr, _location) {
        this.projectsService = projectsService;
        this.router = router;
        this.route = route;
        this.toastr = toastr;
        this._location = _location;
        this.customer_response = [];
        this.pageIndex = 1;
        this.pageSize = 5;
        this.total = 0;
        this.query = '';
        this.paginate = {};
        this.first_created_project = '';
        this.first_created_quote = '';
        this.last_created_project = '';
        this.last_created_quote = '';
        this.displayedColumns = ['id', 'leadSource', 'projservices', 'languagePair', 'projStartDate', 'projEndDate', 'projStatus', 'projValue'];
    }
    CustomerDetailsComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.params['id'];
        this.getProjectSingleCustomer();
        this.getFirst_Quote_Project();
        this.getCustomerProject();
    };
    CustomerDetailsComponent.prototype.getProjectSingleCustomer = function () {
        var _this = this;
        this.projectsService.getProjectSingleCustomer(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.response = response.data;
                if (_this.response.customeraddress.data.length > 0) {
                    _this.response.customeraddress.data.forEach(function (element) {
                        if (element.address_type == '1') {
                            _this.billing_address = element;
                        }
                        else if (element.address_type == '2') {
                            _this.mailing_address = element;
                        }
                    });
                }
            }
        });
    };
    CustomerDetailsComponent.prototype.getCustomerProject = function () {
        var _this = this;
        var query = '';
        this.projectsService.getCustomerProjects(this.id, query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectLanguage.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = response.data;
                _this.customer_response = response.data;
                _this.paginate = response.meta.pagination;
                _this.total = response.meta.pagination.total;
            }
        });
    };
    CustomerDetailsComponent.prototype.paginateData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query += uQuery;
        this.projectsService.getCustomerProjects(this.id, this.query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectLanguage.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = response.data;
                _this.customer_response = response.data;
                _this.paginate = response.meta.pagination;
                _this.total = response.meta.pagination.total;
            }
        });
    };
    CustomerDetailsComponent.prototype.getFirst_Quote_Project = function () {
        var _this = this;
        var obj = {
            customer_uuid: ''
        };
        obj.customer_uuid = this.id;
        this.projectsService.getFirst_Quote_Project(obj)
            .subscribe(function (response) {
            if (response) {
                if (response) {
                    _this.first_created_project = response.first_created_project;
                    _this.first_created_quote = response.first_created_project;
                    _this.last_created_project = response.first_created_project;
                    _this.last_created_quote = response.first_created_project;
                }
            }
        });
    };
    CustomerDetailsComponent.prototype.editData = function (value) {
        this.router.navigate(['/pms/customer/editCustomer/' + value.uuid]);
    };
    // Delete customer
    // Remove Project File
    CustomerDetailsComponent.prototype.deleteCustomer = function () {
        var _this = this;
        this.projectsService.deleteCustomer(this.id).subscribe(function (response) {
            if (response) {
                _this.toastr.success(' Delete Customer Successfully!!');
                _this.router.navigate(['/pms/customer']);
            }
        });
    };
    CustomerDetailsComponent.prototype.back = function () {
        this._location.back();
    };
    return CustomerDetailsComponent;
}());
export { CustomerDetailsComponent };
