<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" (click)="back()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>


    <span class="title-text h1">
      
      <!-- <p *ngIf="response?.uuid!=null"> {{response?.uuid}}</p> -->
      
      <p *ngIf="response?.reference_id!=null"> {{response?.reference_id}}</p>
      <p *ngIf="response?.reference_id==null"> N/A</p>
      <span class="prfInfo">
       <b>Added:</b>  {{response?.created_at?.date | date: 'yyyy-MM-dd'}}
        <!-- <span class="prfo"><b>Location:</b>
          <span> {{response?.city}} {{response?.country}} </span>
        </span> -->
      </span>
    </span>
  </div>





  <div class="white-box">
   
    <div class="tHeader">
        <div fxLayout="row wrap">
          <div fxFlex>
              <h2>{{response?.title}} {{response?.first_name}} {{response?.last_name}} </h2>
      </div>
      <div fxFlex class="text-right">
<button mat-stroked-button  (click)="editData(response)" 
>Edit</button>
<button mat-stroked-button (click)="deleteCustomer()">Move to Trash</button>
        </div>

      </div>

          </div>


    <div class="boxBody">


      <div class="boxBodyInn custInfo">

        <div class="container" fxLayout fxLayoutAlign="left" fxLayoutGap="10px">

          <div class="item item-1" fxFlex>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Name: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b> {{response?.title}} {{response?.first_name}} {{response?.last_name}}</b></p>
              </div>

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Email: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.email_primary}}</b></p>
              </div>

            </div>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Company: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.company?.data?.name}}</b></p>
              </div>

            </div>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Phone: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b *ngIf="response?.phone">{{response?.phone}}</b > <b *ngIf="response?.phone_secondary">,</b> <b>{{response?.phone_secondary }}</b></p>
              </div>

            </div>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Company Website: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.company?.data?.website}}</b></p>
              </div>

            </div>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Billing Address: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>
                  <b *ngIf="billing_address?.address1">{{billing_address?.address1}} </b>
                  <b *ngIf="billing_address?.city">{{billing_address?.city}} </b>
                  <b *ngIf="billing_address?.state">{{billing_address?.state}} </b>
                  <b *ngIf="billing_address?.country">{{billing_address?.country}} </b>
                  <b *ngIf="billing_address?.zip_code">{{billing_address?.zip_code}} </b></p>
              </div>

            </div>

              <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

                  <div class="text-right" fxFlex="30">
                    <p>Mailing Address: </p>
                  </div>
                  <div class="item item-1" fxFlex>
                    <p>
                      <b *ngIf="mailing_address?.address1">{{mailing_address?.address1}} </b>
                      <b *ngIf="mailing_address?.city">{{mailing_address?.city}} </b>
                      <b *ngIf="mailing_address?.state">{{mailing_address?.state}} </b>
                      <b *ngIf="mailing_address?.country">{{mailing_address?.country}} </b>
                      <b *ngIf="mailing_address?.zip_code">{{mailing_address?.zip_code}} </b></p>
                  </div>
    
                </div>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

                <div class="text-right" fxFlex="30">
                  <p>First Quote Date (Entered Automatically): </p>
                </div>
                <div class="item item-1" fxFlex>
                  <p><b>{{first_created_quote | date:'yyyy-MM-dd'}}</b></p>
                </div>
  
              </div>

              <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

                  <div class="text-right" fxFlex="30">
                    <p>Last Quote Date (Entered Automatically): </p>
                  </div>
                  <div class="item item-1" fxFlex>
                    <p><b>{{last_created_quote | date:'yyyy-MM-dd'}}</b></p>
                  </div>
    
                </div>


               






          </div>

          <div class="item item-2" fxFlex>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Account Type: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.accountdetail?.data?.account_type}}</b></p>
              </div>
              <!-- <div class="item item-1" fxFlex>
                <p><b>Corporate </b></p>
              </div> -->

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Newsletter: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.news_letter}}</b></p>
              </div>

            </div>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>DayVIP: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.day_vip}}</b></p>
              </div>

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Payment Method: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="response?.payment_method==1"><b>NET30</b></p>
                <p *ngIf="response?.payment_method==2"><b>NET45</b></p>
                <p *ngIf="response?.payment_method==3"><b>Regular/Upfront</b></p>
                <p *ngIf="response?.payment_method==4"><b>Credit Available</b></p>
              </div>
              <!-- <div class="item item-1" fxFlex>
                <p><b>CC(***5560)</b></p>
              </div> -->
<!-- 
              <mat-option value="">Any</mat-option>
              <mat-option value="1">NET30</mat-option>
              <mat-option value="2">NET45</mat-option>
              <mat-option value="3">Regular/Upfront</mat-option>
              <mat-option value="4">Credit Available</mat-option> -->

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Credit Available: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.credit_available}}</b></p>
              </div>
               <!-- <div class="item item-1" fxFlex>
                <p><b>$5453.00 USD</b></p>
              </div> -->
              
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Contract Type: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.contract_type}}</b> </p>
              </div>
              <!-- <div class="item item-1" fxFlex>
                <p><b>NET 30 </b> </p>
              </div> -->
              
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

                <div class="text-right" fxFlex="30">
                  <p>First Project Date (Entered Automatically) </p>
                </div>
                <div class="item item-1" fxFlex>
                  <p><b>{{first_created_project | date:'yyyy-MM-dd'}} </b></p>
                </div>
  
              </div>


              <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

                  <div class="text-right" fxFlex="30">
                    <p>Last Project Date (Entered Automatically): </p>
                  </div>
                  <div class="item item-1" fxFlex>
                    <p><b>{{last_created_project | date:'yyyy-MM-dd'}} </b></p>
                  </div>
    
                </div>

          </div>



        </div>



      </div>
    </div>
  </div>


  <div class="white-box">
      <div class="tHeader">
          <div fxLayout="row wrap">
            <div fxFlex>
          <h2>Projects</h2>
        </div>
        <div fxFlex class="text-right">
Total <b>{{total}}</b> Records Available

<!-- <button mat-stroked-button>Add Quote</button> -->

<button mat-stroked-button  routerLink="/pms/addlead">Add Project</button>
          </div>

        </div>

            </div>
  
  
      <div class="boxBody">
        <div class="boxInn" *ngIf="customer_response.length>0">
  
            <table mat-table [dataSource]="dataSource" class="table">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef> No. </th>
                  <td mat-cell *matCellDef="let element"> {{element.number}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> ID </th>
                  <td mat-cell *matCellDef="let element"> 
                    
                   <!-- {{element?.reference}}  -->
                   <span >
                    <a routerLink="/pms/project/details/{{element.uuid }}"> {{element?.reference}} </a>
                  </span>
                  
                  </td>
                </ng-container>

                
                <!-- Weight Column -->
                <ng-container matColumnDef="leadSource">
                    <th mat-header-cell *matHeaderCellDef> Lead Source </th>
                    <td mat-cell *matCellDef="let element"> {{element?.customer?.data?.source?.data.name}} </td>
                  </ng-container>

                    <!-- Weight Column -->
                <ng-container matColumnDef="projservices">
                    <th mat-header-cell *matHeaderCellDef> Services</th>
                    <td mat-cell *matCellDef="let element"> {{element?.service?.data?.title}} </td>
                  </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="languagePair">
                  <th mat-header-cell *matHeaderCellDef> Language Pair </th>
                  <td mat-cell *matCellDef="let element">
                  

                     <span class="price text-truncate">
                        <!-- {{product.sourceLanguage?.data.lang_name}} - {{product.targetlanguage?.data.lang_name}} -->
                        <span *ngFor="let res of element.lang_array">
                        <p>{{res}}</p>
                        </span></span>
                    </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="projStartDate">
                  <th mat-header-cell *matHeaderCellDef> Start Date </th>
                  <td mat-cell *matCellDef="let element"> {{element?.created_at?.date | date:'yyyy-MM-dd'}} </td>
                </ng-container>
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="projEndDate">
                      <th mat-header-cell *matHeaderCellDef> End Date </th>
                      <td mat-cell *matCellDef="let element"> {{element?.due_date?.date | date:'yyyy-MM-dd'}} </td>
                    </ng-container>

                      <!-- Symbol Column -->
                <ng-container matColumnDef="projStatus">
                    <th mat-header-cell *matHeaderCellDef> Current Status </th>
                    <td mat-cell *matCellDef="let element"> 
                      {{element?.projectstage?.data?.pm_status}} 
                    </td>
                  </ng-container>


                    <!-- Symbol Column -->
                <ng-container matColumnDef="projValue">
                    <th mat-header-cell *matHeaderCellDef> Value </th>
                    <td mat-cell *matCellDef="let element"> 
                      <!-- <span class="bdg blank">  {{element.projValue}} </span> -->
                    </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>



              <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[ 5,10, 15, 20]" (page)='paginateData($event)'>
    
                </mat-paginator>
  
  
  
        </div>
        <!-- <div class="boxInn" *ngIf="customer_response.length==0">
          <div class="mopt p-20 ">
            <p >No data available!!</p>
          </div>
        </div> -->
        <div class="noDataFound" *ngIf="customer_response.length==0">
          No data available!!
        </div>
      </div>
    </div>


    <div class="white-box" *ngIf="false">
        <div class="tHeader">
          <h2> COMMENTS / COMMUNICATION / SPECIAL INSTRUCTIONS</h2>
        </div>
        <div class="boxBody">
    
          <div class="boxBodyInn">
            <div class="commentDiv">
              <div class="quesDiv">
                <p><b>Dr. Winston Choi:</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                <p>I have some question regarding this project. Can we put somme comment here and you reply. I really need
                  to clarify something.</p>
              </div>
              <div class="ansDiv">
                <div class="divinn">
                  <p><b>Ms Basama (DayPM):</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                  <p>Yes sure please share your question, I will glad to answer all of your questions.</p>
                </div>
              </div>
    
              <div class="quesDiv">
                <p><b>Dr. Winston Choi:</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                <p>Thats really great, I am getting reply promptly.</p>
              </div>
              <div class="ansDiv">
                <div class="divinn">
                  <p><b>Ms Seltiper (DayPM):</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                  <p>You are very welcome!</p>
                </div>
              </div>
    
            </div>
          </div>
        </div>
      </div>


</div>