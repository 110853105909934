import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
export interface CustProjList {
  position: number;
  id: string;
  projservices:string;
  languagePair: string;
  projStartDate: string;
  projEndDate: string;
  projStatus: string;
  projValue: string;
  leadSrouce:string;
}

const ELEMENT_DATA: CustProjList[] = [
  {position: 1, id: 'Bondarev-08201931972',leadSrouce:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Paid', projValue:'$455.00 USD'},
  {position: 2, id: 'Bondarev-08201931972',leadSrouce:'DayVIP', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'In-Process', projValue:'$455.00 USD'},
  {position: 3, id: 'Bondarev-08201931972',leadSrouce:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Lost', projValue:'$455.00 USD'},
  {position: 4, id: 'Bondarev-08201931972',leadSrouce:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Under Quotation', projValue:'$455.00 USD'},
  {position: 5, id: 'Bondarev-08201931972',leadSrouce:'YST Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Approved', projValue:'$455.00 USD'},
  {position: 6, id: 'Bondarev-08201931972',leadSrouce:'DT Website', projservices:'Translation, Notarization', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'New Request', projValue:'$455.00 USD'},
  {position: 7, id: 'Bondarev-08201931972',leadSrouce:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Paid', projValue:'$455.00 USD'},
  {position: 8, id: 'Bondarev-08201931972',leadSrouce:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Paid', projValue:'$455.00 USD'},

];
@Component({
  selector: 'app-freelancer-details',
  templateUrl: './freelancer-details.component.html',
  styleUrls: ['./freelancer-details.component.scss']
})
export class FreelancerDetailsComponent implements OnInit {
  profileData:any={};
  BaseUrl_file: any;
  specialities_test:string;
  selected_tools : string;
  day_name_test:string='';
  skill_name:string='';
  fileError: string = '';
  fileSuccess: string; 
  file_id:number;
  vendor_file_data:string;
  vendor_contract_data:string;
  vendor_resume_data:string;
  vendor_contract_name:string;
  vendor_resume_name:string;
  vendor_exta_document_name: string;
  vendor_exta_document_data: string;
  id:any;
  pageIndex: number = 1;
  pageSize: number = 5;
  total_record: number = 0;
  paginate: any = {};
  data:any = {

  tool_licence: '',
  vendoravailabilities: {
    data:[]
  },
  vendorspecialities : {
    data:[]
  },
  country: '',
  first_name: '',
  last_name: '',
  reference_id: '',
  email: '',
  skype_id: '',
  address1: '',
  city: '',
  state: '',
  postal_code: '',
  payment_method: '',
  translation_tools: '',
  education: '',
  cover_letter: '',
  vendornativelanguage: {}

  }
  ProjectAssignedStatus: any= [];
  responseData:any=[];
  dataSource:any;
  age: any = '';
  constructor(private projectsService: ProjectsService,private route: ActivatedRoute,
    private httpService: HttpClient,private datePipe: DatePipe) { 
    this.BaseUrl_file = environment.baseUrl_file;
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getFreelancer();
    this.getFreelancerProjectHistory();

    this.httpService.get('/assets/ProjectAssignedStatus.json').subscribe(
      data => {
        this.ProjectAssignedStatus = data as string [];	 // FILL THE ARRAY WITH DATA.
  
      },
      (err: HttpErrorResponse) => {
        //console.log(err.message);
      }
    );
  }

  displayedColumns: string[] = ['id', 'leadSrouce', 'projservices', 'languagePair', 'projStartDate', 'projEndDate', 'projStatus'];
  // dataSource = ELEMENT_DATA;

  getFreelancer(){
    this.projectsService.getFreelancer(this.id)
    .subscribe((response:any) => {
        if(response) {
        this.profileData = response.data;
          //console.log(response.data.vendorSubtitler.data);
          if(response.data.vendorSubtitler.data.length > 0){
            response.data.vendorSubtitler.data = response.data.vendorSubtitler.data.sort((a,b) => a.skillservice.data.name.localeCompare(b.skillservice.data.name));
          }
       // console.log(response.data.vendorSubtitler.data);
       if(response.data.dob !='' && response.data.dob !=null){
          let bdate = new Date(response.data.dob);
          let ntimeDiff = Math.abs(Date.now() - bdate.getTime() );
          this.age = Math.floor((ntimeDiff / (1000 * 3600 * 24)) / 365);
        }
        if(response.data.tool_names){
          this.selected_tools = response.data.tool_names.replace(/,/g, ', ');
        }
        
          if(response.data.tool_licence == 0){
            this.profileData.tool_licence = 'No' ;
          }
          else{
            this.profileData.tool_licence = 'Yes';
          }

        let specialities = '';
        response.data.vendorspecialities.data.forEach(elem => {
          specialities += elem.specialities.data.name + ', ';
         this.specialities_test=specialities;
         this.specialities_test = this.specialities_test.slice(0, -2);
         });
         let day = '';
         response.data.vendoravailabilities.data.forEach(elem => {
         day += elem.day_name+', ';
         this.day_name_test=day;
         this.day_name_test = this.day_name_test.slice(0, -2);
         });
         if(response.data.vendorprofileimage.data.length>0){
          response.data.vendorprofileimage.data.forEach(element => {
            this.vendor_file_data=this.BaseUrl_file + element.path;
          });
         }

        response.data.vendorcontract.data.forEach(element => {
          this.vendor_contract_data = this.BaseUrl_file + element.path;
          let x = element.path.split('/');
          this.vendor_contract_name = x[2];
        });

        response.data.vendorresume.data.forEach(element => {
          this.vendor_resume_data=this.BaseUrl_file + element.path;
          let x = element.path.split('/');
          this.vendor_resume_name = x[2];
        });

        // response.data.extradocument.data.forEach(element => {
        //   this.vendor_exta_document_data=this.BaseUrl_file + element.path;
        //   let x = element.path.split('/');
        //   this.vendor_exta_document_name = x[2];
        // });

        if (response.data.extradocument != null) {
            this.vendor_exta_document_data = this.BaseUrl_file + response.data.extradocument.data.path;
            this.vendor_exta_document_name = response.data.extradocument.data.name;
        }

        if(response.data.vendorskill.data.length>0){
          let skill = '';
          response.data.vendorskill.data.forEach(elem => {
            skill += elem.skill.data.name + ', ';
          });
          this.skill_name = skill.slice(0, -2);
        }
        response.data.vendortranslationlangs.data.forEach(elem => {
          if(elem.bi_direction==2){
            elem.bi_direction='<>';
        }else{
          elem.bi_direction='>';
        }
          elem.lang_data = elem.sourcelanguage.data.lang_name+elem.bi_direction+ elem.targetlanguage.data.lang_name;

        });

        let subtitlerslang = '';
          response.data.vendorSubtitler.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.subtiterslang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
            elem.subtitle_rate = elem.subtitle_rate;
          });

        let treplang = '';
          response.data.vendorTreping.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            } 
            elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
          });


        this.data=response.data;
        }
    });
  }

  getFreelancerProjectHistory(){
    let query='';
    this.projectsService.getFreelancerProjectHistory(this.id,query)
    .subscribe((response:any) => {
        if(response) {
          //console.log(response);
          response.data.data.forEach(element => {
            let project_status='';
            this.ProjectAssignedStatus.forEach(ele => {
              if(ele.id==element.status){
                project_status=ele.value;
              }
            });
            element.project_status = project_status;
            let lang1 = '';
            let ref = '';
            let lead_source = '';
            let lang_array = [];
            let due_date = '';
            let start_date='';
            let uuid='';
            if(element.projectlanguage.length > 0){
              element.projectlanguage.forEach(elm => {
                lang1 = elm.sourcelanguage.lang_name + ' - ' + elm.targetlanguage.lang_name + ',' ;
                lang_array.push(lang1);
                
              
                if(elm.project ){
                  if(elm.project.reference!=null){
                    ref = elm.project.reference;
                  }
                  if(elm.project.customer.source.name){
                    lead_source = elm.project.customer.source.name;
                  }
              
                  due_date = elm.project.due_date;
                  start_date = elm.project.start_date;
                  uuid = elm.project.uuid;
                }
            });
            }
           
            if(lang_array.length > 0){
                let test;
                test = lang_array[lang_array.length - 1];
                lang_array[lang_array.length - 1] = test.slice(0, -1);
            }
            element.lang_array = lang_array;
            element.lang_array = lang_array;
            element.ref = ref;
            element.lead_source = lead_source;
            element.uuid = uuid;
            if(due_date != null){
              element.due_date = due_date;
            }
            if(start_date != null){
              element.start_date = start_date;
            }
          });
          this.responseData=response.data.data;
          this.dataSource  = new MatTableDataSource(response.data.data);
          this.paginate = response.data;
          this.total_record = response.data.total;
        }
       });
  }

  paginateData(event) {
    let uQuery = '';
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
        this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    let query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
    query += uQuery;
    this.projectsService.getFreelancerProjectHistory(this.id,query)
    .subscribe((response:any) => {
        if(response) {
          response.data.data.forEach(element => {
            let project_status='';
            this.ProjectAssignedStatus.forEach(ele => {
              if(ele.id == element.status){
                project_status = ele.value;
              }
            });
            element.project_status = project_status;
            let lang1 = '';
            let ref = '';
            let lead_source = '';
            let lang_array = [];
            let due_date = '';
            let start_date = '';
            let uuid = '';
            element.projectlanguage.forEach(elm => {
                lang1 = elm.sourcelanguage.lang_name + ' - ' + elm.targetlanguage.lang_name + ',' ;
                lang_array.push(lang1);
                ref = elm.project.reference;
                lead_source = elm.project.customer.source.name;
                due_date = elm.project.due_date;
                start_date = elm.project.start_date;
                uuid = elm.project.uuid;
            });
  
            if(lang_array.length > 0){
                let test;
                test = lang_array[lang_array.length-1];
                lang_array[lang_array.length - 1] = test.slice(0,-1)
            }
            element.lang_array = lang_array;
            element.lang_array = lang_array;
            element.ref = ref;
            element.lead_source = lead_source;
            element.uuid = uuid;
            if(due_date != null){
              element.due_date = due_date;
            }
            if(start_date!=null){
              element.start_date=start_date;
            }
          });
          this.responseData=response.data.data;
          this.dataSource  = new MatTableDataSource(response.data.data);
          this.paginate = response.data;
          this.total_record = response.data.total;
        }
       });
}
}
