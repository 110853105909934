import { ModuleWithProviders } from '@angular/core';
import { FUSE_CONFIG } from '@fuse/services/config.service';
var FuseModule = /** @class */ (function () {
    function FuseModule(parentModule) {
        if (parentModule) {
            throw new Error('FuseModule is already loaded. Import it in the AppModule only!');
        }
    }
    FuseModule.forRoot = function (config) {
        return {
            ngModule: FuseModule,
            providers: [
                {
                    provide: FUSE_CONFIG,
                    useValue: config
                }
            ]
        };
    };
    return FuseModule;
}());
export { FuseModule };
