<div class="page-layout w100 p-24 bg-white" fusePerfectScrollbar>
  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
      <div fxFlex="20">
          <div class="white-box plainBox">
              <div class="tHeader">
                <h2><i class="material-icons">filter_list</i> Apply Filter</h2>
              </div>
              <div class="boxBody">
                <div class="boxBodyInn">
                 
                
                  <mat-form-field   >
                      <mat-label>Department</mat-label>
                      <mat-select (selectionChange)="onFilter($event,'department')">
                          <mat-option label="New" [value]="''">Select Department</mat-option>
                          <mat-option label="New" [value]="'1'">Admin</mat-option>
                          <mat-option label="Sample" [value]="'2'">HR</mat-option>
                          <mat-option label="Sample" [value]="'3'">PM</mat-option>
                          <mat-option label="Sample" [value]="'4'">Management</mat-option>
                          <mat-option label="Sample" [value]="'5'">IT</mat-option>
                       </mat-select>
                    </mat-form-field>
      
                  
                  <mat-form-field   >
                      <mat-label>Issue Type</mat-label>
                      <mat-select (selectionChange)="onFilter($event,'issue_type')">
                          <mat-option label="New" [value]="''">Select Issue</mat-option>
                          <mat-option label="New" [value]="'Quote Creation'">Quote Creation</mat-option>
                          <mat-option label="Sample" [value]="'Project Assignmment'">Project Assignmment</mat-option>
                          <mat-option label="Sample" [value]="'User Login'">User Login</mat-option>
                          <mat-option label="Sample" [value]="'Payments'">Payments</mat-option>
                          <mat-option label="Sample" [value]="'Approval'">Approval</mat-option>
                          <mat-option label="Sample" [value]="'Promotion'">Promotion</mat-option>
                          <mat-option label="Sample" [value]="'Subscription'">Subscription</mat-option>
                       </mat-select>
                    </mat-form-field>
      
              
                  <mat-form-field  >
                    <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'status')">
                      <mat-option [value]="''">select Status</mat-option>
                      <mat-option [value]="'1'">Pending</mat-option>
                      <mat-option [value]="'2'">In Progress</mat-option>
                      <mat-option [value]="'3'">Resolved</mat-option>
                      <mat-option [value]="'4'">Cancel</mat-option>
                    </mat-select>
                  </mat-form-field>
      
                   
                   
                </div>
              </div>
            </div>
      
      </div>
      <div fxFlex="78">      
<div class="white-box">
    <div class="tHeader">
        <h2>Ticket History:</h2>
        </div>
    <div class="boxBodyInn">
    <!-- <p> Your Tickets Dashboard / General Tickets History</p> -->
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="paginate.total>0" >
    <!--list Ticket History-->
    
    <table mat-table [dataSource]="dataSource" class="table" >
        <!-- id Column -->
       <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef fxFlex="20"> ID</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxFlex="20">
                    <p class="text-truncate"><a routerLink="/tickets/{{product.id}}">{{product.second_id}}</a></p>
                </mat-cell>
          </ng-container>
       
    
                <!-- subject Column -->
        <ng-container matColumnDef="subject">
          <mat-header-cell *matHeaderCellDef  fxFlex="16"> Subject</mat-header-cell>
            <mat-cell *matCellDef="let product"  fxFlex="20">
              <p class="text-truncate">
               {{product.subject}}
                
            </mat-cell>
        </ng-container>
    
          <!-- issue Column -->
          <ng-container matColumnDef="issue_type">
            <mat-header-cell *matHeaderCellDef  fxFlex="16"> Issue Type</mat-header-cell>
              <mat-cell *matCellDef="let product"   fxFlex="20">
                <p class="text-truncate">{{product.issue_type}}</p>
                  
              </mat-cell>
          </ng-container>
    
          <!-- department Column -->
          <ng-container matColumnDef="portal_id">
              <mat-header-cell *matHeaderCellDef fxFlex="16">Department</mat-header-cell>
                <mat-cell *matCellDef="let product"   fxFlex="20">
                    <p *ngIf="product?.portal_id==1">Admin</p>
                    <p *ngIf="product?.portal_id==2">HR</p>
                    <p *ngIf="product?.portal_id==3">PM</p>
                    <p *ngIf="product?.portal_id==4">Management</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
              <mat-header-cell *matHeaderCellDef fxFlex="16">Created At</mat-header-cell>
                <mat-cell *matCellDef="let product"   fxFlex="20">
                  <p class="text-truncate">{{product.created_at.date | date:'yyyy-MM-dd'}}</p> 
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="updated_at">
              <mat-header-cell *matHeaderCellDef fxFlex="16">Updated At</mat-header-cell>
                <mat-cell *matCellDef="let product"   fxFlex="20">
                  <p class="text-truncate">{{product?.updated_at?.date | date:'yyyy-MM-dd' }}</p>
                </mat-cell>
            </ng-container>
             <!-- status Column -->
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef fxFlex="16">Status</mat-header-cell>
                  <mat-cell *matCellDef="let product"   fxFlex="20">
                      <p class="bdg ylbg" *ngIf="product?.status==1">Pending</p>
                      <p class="bdg ylbg" *ngIf="product?.status==2">In-Progress</p>
                      <p  class="bdg ylbg" *ngIf="product?.status==3">Resolved</p>
                      <p class="bdg ylbg" *ngIf="product?.status==4">Invalid</p>  
                  </mat-cell>
              </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
          <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
          </mat-row>
      </table>
      <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[5,10,20,30,40,50,60]" (page)='paginateData($event)'>
        </mat-paginator>
       
      </div>
      <div *ngIf="paginate.total==0" class="noDataFound"> No data available!!
      </div>
    </div>
    </div>
  </div>
  </div>