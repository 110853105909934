import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { environment } from 'environments/environment';
import { AddVendorDocComponent } from './add-vendor-doc/add-vendor-doc.component';
var AddTranslatorComponent = /** @class */ (function () {
    function AddTranslatorComponent(fb, router, vendorService, route, toastr, dialog, location) {
        this.fb = fb;
        this.router = router;
        this.vendorService = vendorService;
        this.route = route;
        this.toastr = toastr;
        this.dialog = dialog;
        this.location = location;
        this.file_data = [];
        this.type = 'Add';
        this.file_id_data = [];
        this.table_data = [];
        this.table_subtitler_data = [];
        this.table_treping_data = [];
        this.final_table_data = [];
        this.lang_name_update = [];
        this.today = new Date();
        this.bulk_selected_progress = false;
        this.minDate = this.today;
        this.bulk_selected = false;
        this.displayedColumns = ['langPair', 'translationRate', 'proofindRate', 'dtpRate', 'qaRate', 'speed', 'accredited', 'actions'];
        this.displayedSubtitlerColumns = ['langPair', 'service', 'subtitlerRate', 'perWord', 'speed', 'actions'];
        this.displayedTrepingColumns = ['trepingLangPair', 'service', 'qualification', 'perHourRate', 'perMinutesRate', 'flexibleRate', 'actions'];
        this.languageData = [];
        this.timezoneData = [];
        this.skillData = [];
        this.skillTrepingData = [];
        this.speciality_new_ids = [];
        this.speciality_new_data = [];
        this.specialityData = [];
        this.language = [];
        this.Subtitlerlanguage = [];
        this.Trepinglanguage = [];
        this.fileError = '';
        this.file_multiple_data = [];
        this.file_id_array = [];
        this.extra_file_id = [];
        this.contract_file_id = [];
        this.uploaded_files_array = [];
        this.uploaded_extra_files_array = [];
        this.uploaded_contract_files_array = [];
        this.selectedItems = [];
        // Coa start
        this.file_multiple_data1 = [];
        this.file_multiple_data2 = [];
        this.file_multiple_data3 = [];
        this.fileError1 = '';
        this.uploaded_coa_array = [];
        this.coa_id_array = [];
        this.per_word_cat_array = [68, 69, 70, 71, 72];
        this.show_per_word = false;
        this.certificates = [{ id: 'ATA', name: 'ATA' }, { id: 'CTTIC', name: 'CTTIC' }, { id: 'NAATI', name: 'NAATI' }, { id: 'ATIA', name: 'ATIA' }, { id: 'ATIO', name: 'ATIO' }, { id: 'NAJIT', name: 'NAJIT' }, { id: 'ATC', name: 'ATC' }, { id: 'EUATC', name: 'EUATC' }, { id: 'ATN', name: 'ATN' }, { id: 'SWORN', name: 'SWORN' }];
        //Coa end
        this.data = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            translation_rate: '',
            proofreading_rate: '',
            dtp_rate: '',
            qa_rate: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
        };
        this.dataSubtitler = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            skill_service_id: '',
            qualification: '',
            subtitle_rate: '',
            per_word: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
        };
        this.dataTreping = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            skill_service_id: '',
            qualification: '',
            rate_hour: '',
            rate_min: '',
            speed: '',
            flexible_rate: false,
            bi_direction: false,
            bi_direction_test: ''
        };
        this.coa_array = [];
        this.selected_tools = [];
        this.selected_days = [];
        this.final_data = {};
        this.removed_extra_document_data = {};
        this.checked = true;
        this.selected_skills = [];
        this.dropdownSettings = {};
        this.dropdownList = [];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    AddTranslatorComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.params['id'];
        if (this.id) {
            this.addTranslatorForm = this.fb.group({
                password: [''],
                confirm_password: [''],
                first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                email: ['', [Validators.required, Validators.email]],
                email_sec: ['', [Validators.email]],
                middle_name: [''],
                gender: [''],
                timezone: [''],
                skype_id: [''],
                phone: [''],
                phone_sec: [''],
                address1: [''],
                city: [''],
                state: [''],
                postal_code: [''],
                country: [''],
                payment_method: ['PayPal'],
                other_payment_method: [''],
                translation_tools: ['1'],
                native_language_id: [''],
                education: [''],
                voice_sample: [''],
                cover_letter: [''],
                payment_method_id: ['', [Validators.email]],
                tool_licence: ['1'],
                boostlingo_affiliation: ['0'],
                title: [''],
                specialities: [''],
                important_note: [''],
                pr_scores: [''],
                info: [''],
                subtitles_program_software: [''],
                category_level: [''],
                avt_status: [''],
                treping_status: [''],
                is_active: ['1'],
                on_boarding_date: [''],
                dob: [''],
                sms_notification: ['', [Validators.required]],
                wtsp_notification: ['', [Validators.required]],
                vo_sample: [''],
                voiceover_category: [''],
                voiceover_notes: [''],
                untimed_vo_recording: [''],
                timed_vo_recording: [''],
                minimum_time: [''],
                travel_cost: [''],
                distance_willing_to_travel: [''],
                cancellation_cost: [''],
                send_email_status: ['']
            });
            this.getTranslatorData();
            this.getLanguage();
            this.getTimezone();
            this.getSkillService();
            this.getTrepingSkillService();
            this.getSpeciality();
            this.getTranslatorStatus();
            this.getVendorDoc();
        }
        else {
            this.getSkillService();
            this.getTrepingSkillService();
            this.getLanguage();
            this.getTimezone();
            this.getSpeciality();
            // this.country_code=Dial.response;
            this.addTranslatorForm = this.fb.group({
                password: ['', [Validators.required, Validators.minLength(6)]],
                confirm_password: [
                    '',
                    [
                        confirmPasswordValidator,
                        Validators.required,
                        Validators.minLength(6)
                    ]
                ],
                first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                email: ['', [Validators.required, Validators.email]],
                email_sec: ['', [Validators.email]],
                middle_name: [''],
                gender: [''],
                timezone: [''],
                skype_id: [''],
                phone: [''],
                phone_sec: [''],
                address1: [''],
                city: [''],
                state: [''],
                postal_code: [''],
                country: [''],
                payment_method_id: ['', [Validators.email]],
                payment_method: ['PayPal'],
                translation_tools: ['1'],
                native_language_id: [''],
                education: [''],
                voice_sample: [''],
                cover_letter: [''],
                tool_licence: ['1'],
                boostlingo_affiliation: ['0'],
                // title: ['', Validators.compose([Validators.required])],
                title: [''],
                specialities: [''],
                important_note: [''],
                pr_scores: [''],
                info: [''],
                subtitles_program_software: [''],
                category_level: [''],
                avt_status: [''],
                treping_status: [''],
                is_active: ['1'],
                on_boarding_date: [''],
                dob: [''],
                sms_notification: [''],
                wtsp_notification: [''],
                vo_sample: [''],
                voiceover_category: [''],
                voiceover_notes: [''],
                untimed_vo_recording: [''],
                timed_vo_recording: [''],
                minimum_time: [''],
                travel_cost: [''],
                distance_willing_to_travel: [''],
                cancellation_cost: [''],
                send_email_status: ['1']
            });
        }
        this.dropdownSettings = {
            singleSelection: false,
            textId: 'id',
            textField: 'name',
            allowSearchFilter: true
        };
    };
    Object.defineProperty(AddTranslatorComponent.prototype, "f", {
        get: function () {
            return this.addTranslatorForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddTranslatorComponent.prototype.submit = function () {
        if (this.type == 'Add') {
            this.addData();
        }
        else {
            this.updateData();
        }
    };
    AddTranslatorComponent.prototype.getTranslatorData = function () {
        var _this = this;
        this.coa_array = [];
        this.table_data = [];
        this.table_subtitler_data = [];
        this.table_treping_data = [];
        this.speciality_new_ids = [];
        this.coa_id_array = [];
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                //debugger;
                _this.dataSource = [];
                _this.dataSubtitlerSource = [];
                _this.dataTrepingSource = [];
                _this.getSpeciality();
                _this.type = 'Update';
                _this.stage_id = response.data.stage_id;
                _this.reference_id = response.data.reference_id;
                _this.sms_notification = response.data.sms_notification;
                _this.wtsp_notification = response.data.wtsp_notification;
                _this.vo_sample = response.data.vo_sample;
                _this.voiceover_category = response.data.voiceover_category;
                _this.voiceover_notes = response.data.voiceover_notes;
                //this.untimed_vo_recording = response.data.untimed_vo_recording !=null ? response.data.untimed_vo_recording.toString() : response.data.untimed_vo_recording;
                //this.timed_vo_recording = response.data.timed_vo_recording !=null ? response.data.timed_vo_recording.toString() : response.data.timed_vo_recording.toString();
                if (response.data.tool_names) {
                    _this.selected_tools = response.data.tool_names.split(',');
                }
                response.data.vendorskill.data.forEach(function (element) {
                    _this.selected_skills.push(element.skill_id);
                });
                response.data.vendortranslationlangs.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    _this.table_data.push(element);
                });
                _this.dataSource = new MatTableDataSource(_this.table_data);
                response.data.vendorSubtitler.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    if (element.skillservice.data.name != '') {
                        element.skill_service_name = element.skillservice.data.name;
                    }
                    _this.table_subtitler_data.push(element);
                });
                _this.dataSubtitlerSource = new MatTableDataSource(_this.table_subtitler_data);
                response.data.vendorTreping.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    if (element.trepingSkillservice.data.name != '') {
                        element.skill_service_name = element.trepingSkillservice.data.name;
                    }
                    _this.table_treping_data.push(element);
                });
                _this.dataTrepingSource = new MatTableDataSource(_this.table_treping_data);
                response.data.vendoravailabilities.data.forEach(function (element) {
                    _this.selected_days.push(element.day_name);
                });
                response.data.vendorspecialities.data.forEach(function (element) {
                    _this.speciality_new_ids.push(element.specialities.data);
                });
                if (response.data.vendorresume.data.length != 0) {
                    response.data.vendorresume.data.forEach(function (element) {
                        _this.vendor_resume_data = _this.BaseUrl_file + element.path;
                        var resume_name = element.path.split('/');
                        _this.vendor_resume_name = resume_name[2];
                    });
                }
                if (response.data.extradocument != null) {
                    _this.vendor_extra_document_data = _this.BaseUrl_file + response.data.extradocument.data.path;
                    _this.vendor_extra_document_name = response.data.extradocument.data.name;
                    _this.vendor_extra_document_id = response.data.extradocument.data.id;
                }
                if (response.data.vendorcontract.data.length != 0) {
                    response.data.vendorcontract.data.forEach(function (element) {
                        _this.vendor_contract_document_data = _this.BaseUrl_file + element.path;
                        _this.vendor_contract_document_name = element.name;
                    });
                }
                if (response.data.vendorcoafiles.data.length != 0) {
                    response.data.vendorcoafiles.data.forEach(function (element) {
                        _this.coa_url = _this.BaseUrl_file + element.coafile.data.path;
                        var coa_name = element.coafile.data.path.split('/');
                        _this.coa_name = coa_name[2];
                        var coa_obj = {
                            id: element.id,
                            url: _this.coa_url,
                            name: _this.coa_name,
                            coa_file_id: element.coa_file_id
                        };
                        // this.coa_id_array()
                        _this.coa_id_array.push(element.coa_file_id);
                        _this.coa_array.push(coa_obj);
                    });
                }
                _this.addTranslatorForm.patchValue({
                    address1: response.data.address1,
                    email: response.data.email,
                    email_sec: response.data.email_sec,
                    gender: response.data.gender,
                    timezone: response.data.timezone,
                    city: response.data.city,
                    country: response.data.country,
                    cover_letter: response.data.cover_letter,
                    education: response.data.education,
                    voice_sample: response.data.voice_sample,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    middle_name: response.data.middle_name,
                    native_language_id: response.data.native_language_id,
                    payment_method: response.data.payment_method,
                    other_payment_method: response.data.other_payment_method,
                    payment_method_id: response.data.payment_method_id,
                    phone: response.data.phone,
                    phone_sec: response.data.phone_sec,
                    postal_code: response.data.postal_code,
                    skype_id: response.data.skype_id,
                    state: response.data.state,
                    title: response.data.title,
                    specialities: _this.speciality_new_ids,
                    important_note: response.data.important_note,
                    pr_scores: response.data.pr_scores,
                    info: response.data.info,
                    subtitles_program_software: response.data.subtitles_program_software,
                    category_level: response.data.category_level,
                    avt_status: response.data.avt_status,
                    treping_status: response.data.treping_status,
                    on_boarding_date: response.data.on_boarding_date,
                    dob: response.data.dob,
                    sms_notification: response.data.sms_notification,
                    wtsp_notification: response.data.wtsp_notification,
                    vo_sample: response.data.vo_sample,
                    voiceover_category: response.data.voiceover_category,
                    voiceover_notes: response.data.voiceover_notes,
                    untimed_vo_recording: response.data.untimed_vo_recording,
                    timed_vo_recording: response.data.timed_vo_recording,
                    minimum_time: response.data.minimum_time,
                    travel_cost: response.data.travel_cost,
                    distance_willing_to_travel: response.data.distance_willing_to_travel,
                    cancellation_cost: response.data.cancellation_cost,
                });
                //console.log(this.addTranslatorForm);
                if (response.data.translation_tools != null) {
                    _this.addTranslatorForm.patchValue({
                        translation_tools: response.data.translation_tools.toString(),
                    });
                }
                if (response.data.tool_licence != null) {
                    _this.addTranslatorForm.patchValue({
                        tool_licence: response.data.tool_licence.toString(),
                    });
                }
                if (response.data.boostlingo_affiliation != null) {
                    _this.addTranslatorForm.patchValue({
                        boostlingo_affiliation: response.data.boostlingo_affiliation.toString(),
                    });
                }
                if (response.data.send_email_status != null) {
                    _this.addTranslatorForm.patchValue({
                        send_email_status: response.data.send_email_status.toString(),
                    });
                }
            }
        });
    };
    AddTranslatorComponent.prototype.edit_getTranslatorData = function () {
        var _this = this;
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = [];
                _this.type = 'Update';
                response.data.vendortranslationlangs.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    _this.table_data.push(element);
                });
                _this.dataSource = new MatTableDataSource(_this.table_data);
            }
        });
    };
    AddTranslatorComponent.prototype.edit_getSubtitlerData = function () {
        var _this = this;
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.dataSubtitlerSource = [];
                _this.type = 'Update';
                response.data.vendorSubtitler.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    if (element.skillservice.data.name != '') {
                        element.skill_service_name = element.skillservice.data.name;
                    }
                    _this.table_subtitler_data.push(element);
                });
                _this.dataSubtitlerSource = new MatTableDataSource(_this.table_subtitler_data);
            }
        });
    };
    AddTranslatorComponent.prototype.edit_getTrepingData = function () {
        var _this = this;
        // debugger;
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.dataTrepingSource = [];
                _this.type = 'Update';
                response.data.vendorTreping.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    if (element.trepingSkillservice.data.name != '') {
                        element.skill_service_name = element.trepingSkillservice.data.name;
                    }
                    _this.table_treping_data.push(element);
                });
                _this.dataTrepingSource = new MatTableDataSource(_this.table_treping_data);
            }
        });
    };
    AddTranslatorComponent.prototype.editData = function (value, index) {
        this.addIndex = index;
        if (this.type == 'Add') {
            this.data.source_lang_id = value.source_lang_id;
            this.data.sourcelanguage = value.sourcelanguage;
            this.data.target_lang_id = value.target_lang_id;
            this.data.targetlanguage = value.targetlanguage;
            this.data.translation_rate = value.translation_rate;
            this.data.proofreading_rate = value.proofreading_rate;
            this.data.dtp_rate = value.dtp_rate;
            this.data.qa_rate = value.qa_rate;
            this.data.speed = value.speed;
            this.lang_combination_id = value.id;
            if ((typeof value.bi_direction == 'string' && value.bi_direction == '>') || (typeof value.bi_direction == 'boolean' && !value.bi_direction)) {
                this.data.bi_direction = false;
            }
            else {
                this.data.bi_direction = true;
            }
            this.data.certificates = [];
            if (value.sworn == '1') {
                this.data.certificates.push('SWORN');
            }
            if (value.ata_certified == '1') {
                this.data.certificates.push('ATA');
            }
            if (value.cttic == '1') {
                this.data.certificates.push('CTTIC');
            }
            if (value.naati == '1') {
                this.data.certificates.push('NAATI');
            }
            if (value.atia == '1') {
                this.data.certificates.push('ATIA');
            }
            if (value.atio == '1') {
                this.data.certificates.push('ATIO');
            }
            if (value.najit == '1') {
                this.data.certificates.push('NAJIT');
            }
            if (value.atc == '1') {
                this.data.certificates.push('ATC');
            }
            if (value.euatc == '1') {
                this.data.certificates.push('EUATC');
            }
            if (value.atn == '1') {
                this.data.certificates.push('ATN');
            }
        }
        else {
            this.data.source_lang_id = value.sourcelanguage.data.id;
            this.data.target_lang_id = value.targetlanguage.data.id;
            this.data.translation_rate = value.translation_rate;
            this.data.proofreading_rate = value.proofreading_rate;
            this.data.dtp_rate = value.dtp_rate;
            this.data.qa_rate = value.qa_rate;
            this.data.speed = value.speed;
            this.lang_combination_id = value.id;
            if ((typeof value.bi_direction == 'string' && value.bi_direction == '>') || (typeof value.bi_direction == 'boolean' && !value.bi_direction)) {
                this.data.bi_direction = false;
            }
            else {
                this.data.bi_direction = true;
            }
            this.data.certificates = [];
            if (value.sworn == '1') {
                this.data.certificates.push('SWORN');
            }
            if (value.ata_certified == '1') {
                this.data.certificates.push('ATA');
            }
            if (value.cttic == '1') {
                this.data.certificates.push('CTTIC');
            }
            if (value.naati == '1') {
                this.data.certificates.push('NAATI');
            }
            if (value.atia == '1') {
                this.data.certificates.push('ATIA');
            }
            if (value.atio == '1') {
                this.data.certificates.push('ATIO');
            }
            if (value.najit == '1') {
                this.data.certificates.push('NAJIT');
            }
            if (value.atc == '1') {
                this.data.certificates.push('ATC');
            }
            if (value.euatc == '1') {
                this.data.certificates.push('EUATC');
            }
            if (value.atn == '1') {
                this.data.certificates.push('ATN');
            }
        }
    };
    AddTranslatorComponent.prototype.editSubtitlerData = function (value, index) {
        var _this = this;
        //debugger;
        this.addIndex = index;
        if (this.type == 'Add') {
            var per_word_price_1 = null;
            this.per_word_cat_array.forEach(function (ele) {
                if (ele == value.skill_service_id) {
                    //console.log(value.skill_service_id);
                    _this.show_per_word = true;
                    per_word_price_1 = value.per_word;
                }
            });
            this.dataSubtitler.source_lang_id = value.source_lang_id;
            this.dataSubtitler.sourcelanguage = value.sourcelanguage;
            this.dataSubtitler.target_lang_id = value.target_lang_id;
            this.dataSubtitler.targetlanguage = value.targetlanguage;
            this.dataSubtitler.skill_service_id = value.skill_service_id;
            this.dataSubtitler.qualification = value.qualification;
            this.dataSubtitler.subtitle_rate = value.subtitle_rate;
            this.dataSubtitler.per_word = per_word_price_1;
            this.dataSubtitler.speed = value.speed;
            this.subtitler_lang_combination_id = value.id;
            if (value.bi_direction == '>') {
                this.dataSubtitler.bi_direction = false;
            }
            else {
                this.dataSubtitler.bi_direction = true;
            }
        }
        else {
            var per_word_price_2 = null;
            this.per_word_cat_array.forEach(function (ele) {
                if (ele == value.skill_service_id) {
                    // console.log(value.skill_service_id);
                    _this.show_per_word = true;
                    per_word_price_2 = value.per_word;
                }
            });
            this.dataSubtitler.source_lang_id = value.sourcelanguage.data.id;
            this.dataSubtitler.target_lang_id = value.targetlanguage.data.id;
            this.dataSubtitler.skill_service_id = value.skill_service_id;
            this.dataSubtitler.qualification = value.qualificationl;
            this.dataSubtitler.subtitle_rate = value.subtitle_rate;
            this.dataSubtitler.per_word = per_word_price_2;
            this.dataSubtitler.speed = value.speed;
            this.subtitler_lang_combination_id = value.id;
            if (value.bi_direction == '>') {
                this.dataSubtitler.bi_direction = false;
            }
            else {
                this.dataSubtitler.bi_direction = true;
            }
        }
    };
    AddTranslatorComponent.prototype.editTrepingData = function (value, index) {
        this.addIndex = index;
        if (this.type == 'Add') {
            this.dataTreping.source_lang_id = value.source_lang_id;
            this.dataTreping.sourcelanguage = value.sourcelanguage;
            this.dataTreping.target_lang_id = value.target_lang_id;
            this.dataTreping.targetlanguage = value.targetlanguage;
            this.dataTreping.skill_service_id = value.skill_service_id;
            this.dataTreping.qualification = value.qualification;
            this.dataTreping.rate_hour = value.rate_hour;
            this.dataTreping.rate_min = value.rate_min;
            this.dataTreping.speed = value.speed;
            this.dataTreping.flexible_rate = value.flexible_rate;
            this.treping_lang_combination_id = value.id;
            if (value.bi_direction == '>') {
                this.dataTreping.bi_direction = false;
            }
            else {
                this.dataTreping.bi_direction = true;
            }
            if (value.flexible_rate == "1") {
                this.dataTreping.flexible_rate = true;
            }
            else {
                this.dataTreping.flexible_rate = false;
            }
        }
        else {
            this.dataTreping.source_lang_id = value.sourcelanguage.data.id;
            this.dataTreping.target_lang_id = value.targetlanguage.data.id;
            this.dataTreping.skill_service_id = value.skill_service_id;
            this.dataTreping.qualification = value.qualification;
            this.dataTreping.rate_hour = value.rate_hour;
            this.dataTreping.rate_min = value.rate_min;
            this.dataTreping.speed = value.speed;
            this.dataTreping.flexible_rate = value.flexible_rate;
            this.treping_lang_combination_id = value.id;
            if (value.bi_direction == '>') {
                this.dataTreping.bi_direction = false;
            }
            else {
                this.dataTreping.bi_direction = true;
            }
            if (value.flexible_rate == "1") {
                this.dataTreping.flexible_rate = true;
            }
            else {
                this.dataTreping.flexible_rate = false;
            }
        }
    };
    AddTranslatorComponent.prototype.updateData = function () {
        var _this = this;
        this.final_data = {};
        //debugger
        this.final_data = this.addTranslatorForm.value;
        //this.final_data.extra_document = this.extra_file_id;
        this.final_data.sms_notification = this.sms_notification;
        this.final_data.wtsp_notification = this.wtsp_notification;
        this.final_data.translation_tools = this.addTranslatorForm.value.translation_tools;
        this.final_data.on_boarding_date = this.dateFormat(this.addTranslatorForm.value.on_boarding_date);
        this.final_data.dob = this.dateFormat(this.addTranslatorForm.value.dob);
        this.final_data.specialities = this.getSpecialityId(this.addTranslatorForm.value.specialities);
        if (this.selected_tools.length > 0) {
            this.final_data.tool_names = this.selected_tools.toString();
        }
        else {
            this.final_data.tool_names = this.selected_tools;
        }
        this.final_data.vendor_skill = this.selected_skills;
        if (this.selected_days.length > 0) {
            this.final_data.availability = this.selected_days.toString();
        }
        else {
            this.final_data.availability = this.selected_days;
        }
        this.final_data.vendor_skill = this.selected_skills;
        if (this.file_id_array.length > 0) {
            this.final_data.resume_id = this.file_id_array;
        }
        if (this.contract_file_id.length > 0) {
            this.final_data.contract_file_id = this.contract_file_id;
        }
        if (this.extra_file_id.length > 0) {
            this.final_data.extra_document = this.extra_file_id;
        }
        if (this.coa_id_array.length > 0) {
            this.final_data.coa_files = this.coa_id_array;
        }
        else {
            this.final_data.coa_files = [];
        }
        this.final_data.file_id = [];
        this.final_data.stage_id = this.stage_id;
        //console.log(this.final_data);
        this.vendorService.updateTranslator(this.id, this.final_data).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success('Translator updated successfully');
                // this.location.back();
                _this.router.navigate(['/hr/hr-dashboard']);
            }
        });
    };
    // date format
    AddTranslatorComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt;
    };
    AddTranslatorComponent.prototype.getSourceName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.data.sourcelanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTranslatorComponent.prototype.getSubtitlerSourceName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.dataSubtitler.sourcelanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTranslatorComponent.prototype.getTrepingSourceName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.dataTreping.sourcelanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTranslatorComponent.prototype.getTargetName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.data.targetlanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTranslatorComponent.prototype.getSubtitlerTargetName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.dataSubtitler.targetlanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTranslatorComponent.prototype.getTrepingTargetName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.dataTreping.targetlanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTranslatorComponent.prototype.getTrepingSkillName = function (evt) {
        // debugger;
        // if(this.per_word_cat_array.indexOf(evt.value) !== -1){
        //     this.show_per_word = true;
        // }else{
        //     this.show_per_word = false;
        // }
        var _this = this;
        this.skillTrepingData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.dataTreping.skill_service_name = element.name;
                _this.dataTreping.skill_service_id = element.id;
            }
        });
        //console.log(this.skillTrepingData);
    };
    AddTranslatorComponent.prototype.getSubtitlerSkillName = function (evt) {
        var _this = this;
        if (this.per_word_cat_array.indexOf(evt.value) !== -1) {
            this.show_per_word = true;
        }
        else {
            this.show_per_word = false;
        }
        this.skillData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.dataSubtitler.skill_service_name = element.name;
                _this.dataSubtitler.skill_service_id = element.id;
            }
        });
    };
    AddTranslatorComponent.prototype.addLanguageTable = function () {
        var bi_direction;
        var sworn;
        var ata_certified;
        var cttic;
        var naati;
        var atia;
        var atio;
        var najit;
        var atc;
        var euatc;
        var atn;
        this.data.certificates = Array.isArray(this.data.certificates) ? this.data.certificates : [];
        this.dataSource = [];
        if (this.data.bi_direction == false) {
            bi_direction = '>';
            this.data.bi_direction = bi_direction;
        }
        else {
            bi_direction = '<>';
            this.data.bi_direction = bi_direction;
        }
        if (this.data.certificates.includes('SWORN')) {
            sworn = '1';
            this.data.sworn = sworn;
        }
        else {
            sworn = '0';
            this.data.sworn = sworn;
        }
        if (this.data.certificates.includes('ATA')) {
            ata_certified = '1';
            this.data.ata_certified = ata_certified;
        }
        else {
            ata_certified = '0';
            this.data.ata_certified = ata_certified;
        }
        if (this.data.certificates.includes('CTTIC')) {
            cttic = '1';
            this.data.cttic = cttic;
        }
        else {
            cttic = '0';
            this.data.cttic = cttic;
        }
        if (this.data.certificates.includes('NAATI')) {
            naati = '1';
            this.data.naati = naati;
        }
        else {
            naati = '0';
            this.data.naati = naati;
        }
        if (this.data.certificates.includes('ATIA')) {
            atia = '1';
            this.data.atia = atia;
        }
        else {
            atia = '0';
            this.data.atia = atia;
        }
        if (this.data.certificates.includes('ATIO')) {
            atio = '1';
            this.data.atio = atio;
        }
        else {
            atio = '0';
            this.data.atio = atio;
        }
        if (this.data.certificates.includes('NAJIT')) {
            najit = '1';
            this.data.najit = najit;
        }
        else {
            najit = '0';
            this.data.najit = najit;
        }
        if (this.data.certificates.includes('ATC')) {
            atc = '1';
            this.data.atc = atc;
        }
        else {
            atc = '0';
            this.data.atc = atc;
        }
        if (this.data.certificates.includes('EUATC')) {
            euatc = '1';
            this.data.euatc = euatc;
        }
        else {
            euatc = '0';
            this.data.euatc = euatc;
        }
        if (this.data.certificates.includes('ATN')) {
            atn = '1';
            this.data.atn = atn;
        }
        else {
            atn = '0';
            this.data.atn = atn;
        }
        if (this.data.sourcelanguage.data.lang_name == '') {
            this.toastr.error('please select source language');
            return false;
        }
        if (this.data.targetlanguage.data.lang_name != '' && this.data.sourcelanguage.data.lang_name != '') {
            if (this.data.sourcelanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
                this.toastr.error('please select unique language pair !!');
                return false;
            }
        }
        if (this.data.targetlanguage.data.lang_name == '') {
            this.toastr.error('please select target language');
            return false;
        }
        if (this.data.translation_rate == '') {
            this.toastr.error('please select translation rate');
            return false;
        }
        if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
            this.toastr.error('Translation rate never be negative or zero');
            return false;
        }
        if (this.data.proofreading_rate == '') {
            this.toastr.error('please select proofreading rate');
            return false;
        }
        if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
            this.toastr.error('Proofreading rate never be negative or zero');
            return false;
        }
        // if (this.data.dtp_rate == '') {
        //   this.toastr.error('please select Dtp rate');
        //   return false;
        // }
        // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
        //   this.toastr.error('Dtp rate never be negative or zero');
        //   return false;
        // }
        // if (this.data.qa_rate == '') {
        //   this.toastr.error('please select Dtp rate');
        //   return false;
        // }
        // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
        //   this.toastr.error('Qa rate never be negative or zero');
        //   return false;
        // }
        if (this.data.speed == '') {
            this.toastr.error('please select speed');
            return false;
        }
        if (this.language.length > 0 && this.language[this.addIndex] !== undefined) {
            this.language[this.addIndex].source_lang_id = this.data.source_lang_id;
            this.language[this.addIndex].sourcelanguage = this.data.sourcelanguage;
            this.language[this.addIndex].target_lang_id = this.data.target_lang_id;
            this.language[this.addIndex].targetlanguage = this.data.targetlanguage;
            this.language[this.addIndex].translation_rate = this.data.translation_rate;
            this.language[this.addIndex].proofreading_rate = this.data.proofreading_rate;
            this.language[this.addIndex].dtp_rate = this.data.dtp_rate;
            this.language[this.addIndex].qa_rate = this.data.qa_rate;
            this.language[this.addIndex].speed = this.data.speed;
            this.language[this.addIndex].lang_combination_id = this.lang_combination_id;
            this.language[this.addIndex].bi_direction = bi_direction;
            this.language[this.addIndex].sworn = sworn;
            this.language[this.addIndex].ata_certified = ata_certified;
            this.language[this.addIndex].cttic = cttic;
            this.language[this.addIndex].naati = naati;
            this.language[this.addIndex].atia = atia;
            this.language[this.addIndex].atio = atio;
            this.language[this.addIndex].najit = najit;
            this.language[this.addIndex].atc = atc;
            this.language[this.addIndex].euatc = euatc;
            this.language[this.addIndex].atn = atn;
            if (this.data.bi_direction == '>') {
                this.data.bi_direction = false;
            }
            else {
                this.data.bi_direction = true;
            }
        }
        else {
            this.language.push(this.data);
        }
        this.dataSource = new MatTableDataSource(this.language);
        this.data = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            translation_rate: '',
            dtp_rate: '',
            proofreading_rate: '',
            qa_rate: '',
            speed: '',
            bi_direction: false,
            sworn: false,
            ata_certified: false,
            certificates: [],
            bi_direction_test: ''
        };
        this.addIndex = null;
    };
    AddTranslatorComponent.prototype.addSubtitlerLanguageTable = function () {
        //debugger;
        var bi_direction;
        this.dataSubtitlerSource = [];
        if (this.dataSubtitler.bi_direction == false) {
            bi_direction = '>';
            this.dataSubtitler.bi_direction = bi_direction;
        }
        else {
            bi_direction = '<>';
            this.dataSubtitler.bi_direction = bi_direction;
        }
        if (this.dataSubtitler.sourcelanguage.data.lang_name == '') {
            this.toastr.error('please select avt source language');
            return false;
        }
        // if (this.dataSubtitler.targetlanguage.data.lang_name != '' && this.dataSubtitler.sourcelanguage.data.lang_name != '') {
        //   if (this.dataSubtitler.sourcelanguage.data.lang_name == this.dataSubtitler.targetlanguage.data.lang_name) {
        //     this.toastr.error('please select avt unique language pair !!');
        //     return false;
        //   }
        // }
        if (this.dataSubtitler.targetlanguage.data.lang_name == '') {
            this.toastr.error('please select avt target language');
            return false;
        }
        if (this.dataSubtitler.skill_service_id == '') {
            this.toastr.error('please select avt service');
            return false;
        }
        // if (this.dataSubtitler.subtitle_rate == '') {
        //   this.toastr.error('please select avt rate');
        //   return false;
        // }
        // if (this.dataSubtitler.subtitle_rate < 0 || this.dataSubtitler.subtitle_rate == 0) {
        //   this.toastr.error('Avt rate never be negative or zero');
        //   return false;
        // }
        // if (this.dataSubtitler.per_word == '') {
        //   this.toastr.error('please select avt rate per word');
        //   return false;
        // }
        // if (this.dataSubtitler.per_word < 0 || this.dataSubtitler.per_word == 0 || this.dataSubtitler.per_word != null) {
        //   this.toastr.error('Avt rate per word never be negative or zero');
        //   return false;
        // }
        if (this.dataSubtitler.speed == '') {
            this.toastr.error('please select avt speed');
            return false;
        }
        if (this.Subtitlerlanguage.length > 0 && this.Subtitlerlanguage[this.addIndex] !== undefined) {
            this.Subtitlerlanguage[this.addIndex].source_lang_id = this.dataSubtitler.source_lang_id;
            this.Subtitlerlanguage[this.addIndex].sourcelanguage = this.dataSubtitler.sourcelanguage;
            this.Subtitlerlanguage[this.addIndex].target_lang_id = this.dataSubtitler.target_lang_id;
            this.Subtitlerlanguage[this.addIndex].targetlanguage = this.dataSubtitler.targetlanguage;
            this.Subtitlerlanguage[this.addIndex].skill_service_id = this.dataSubtitler.skill_service_id;
            this.Subtitlerlanguage[this.addIndex].qualification = this.dataSubtitler.qualification;
            this.Subtitlerlanguage[this.addIndex].skill_service_name = this.dataSubtitler.skill_service_name;
            this.Subtitlerlanguage[this.addIndex].subtitle_rate = this.dataSubtitler.subtitle_rate;
            this.Subtitlerlanguage[this.addIndex].per_word = this.dataSubtitler.per_word;
            this.Subtitlerlanguage[this.addIndex].speed = this.dataSubtitler.speed;
            this.Subtitlerlanguage[this.addIndex].subtitler_lang_combination_id = this.subtitler_lang_combination_id;
            this.Subtitlerlanguage[this.addIndex].bi_direction = bi_direction;
            if (this.dataSubtitler.bi_direction == '>') {
                this.dataSubtitler.bi_direction = false;
            }
            else {
                this.dataSubtitler.bi_direction = true;
            }
        }
        else {
            this.Subtitlerlanguage.push(this.dataSubtitler);
        }
        this.dataSubtitlerSource = new MatTableDataSource(this.Subtitlerlanguage);
        this.dataSubtitler = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            skill_service_id: '',
            qualification: '',
            subtitle_rate: '',
            per_word: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
        };
        this.addIndex = null;
    };
    AddTranslatorComponent.prototype.addTrepingLanguageTable = function () {
        //debugger;
        var bi_direction;
        this.dataTrepingSource = [];
        if (this.dataTreping.bi_direction == false) {
            bi_direction = '>';
            this.dataTreping.bi_direction = bi_direction;
        }
        else {
            bi_direction = '<>';
            this.dataTreping.bi_direction = bi_direction;
        }
        if (this.dataTreping.sourcelanguage.data.lang_name == '') {
            this.toastr.error('please select treping source language');
            return false;
        }
        // if (this.dataSubtitler.targetlanguage.data.lang_name != '' && this.dataSubtitler.sourcelanguage.data.lang_name != '') {
        //   if (this.dataSubtitler.sourcelanguage.data.lang_name == this.dataSubtitler.targetlanguage.data.lang_name) {
        //     this.toastr.error('please select avt unique language pair !!');
        //     return false;
        //   }
        // }
        if (this.dataTreping.targetlanguage.data.lang_name == '') {
            this.toastr.error('please select treping target language');
            return false;
        }
        if (this.dataTreping.skill_service_id == '') {
            this.toastr.error('please select treping service');
            return false;
        }
        /* if (this.dataTreping.speed == '') {
          this.toastr.error('please select treping speed');
          return false;
        } */
        if (this.Trepinglanguage.length > 0 && this.Trepinglanguage[this.addIndex] !== undefined) {
            this.Trepinglanguage[this.addIndex].source_lang_id = this.dataTreping.source_lang_id;
            this.Trepinglanguage[this.addIndex].sourcelanguage = this.dataTreping.sourcelanguage;
            this.Trepinglanguage[this.addIndex].target_lang_id = this.dataTreping.target_lang_id;
            this.Trepinglanguage[this.addIndex].targetlanguage = this.dataTreping.targetlanguage;
            this.Trepinglanguage[this.addIndex].skill_service_id = this.dataTreping.skill_service_id;
            this.Trepinglanguage[this.addIndex].qualification = this.dataTreping.qualification;
            this.Trepinglanguage[this.addIndex].skill_service_name = this.dataTreping.skill_service_name;
            this.Trepinglanguage[this.addIndex].rate_hour = this.dataTreping.rate_hour;
            this.Trepinglanguage[this.addIndex].rate_min = this.dataTreping.rate_min;
            this.Trepinglanguage[this.addIndex].speed = this.dataTreping.speed;
            this.Trepinglanguage[this.addIndex].flexible_rate = this.dataTreping.flexible_rate;
            this.Trepinglanguage[this.addIndex].treping_lang_combination_id = this.treping_lang_combination_id;
            this.Trepinglanguage[this.addIndex].bi_direction = bi_direction;
            if (this.dataTreping.bi_direction == '>') {
                this.dataTreping.bi_direction = false;
            }
            else {
                this.dataTreping.bi_direction = true;
            }
        }
        else {
            this.Trepinglanguage.push(this.dataTreping);
        }
        console.log(this.Trepinglanguage);
        this.dataTrepingSource = new MatTableDataSource(this.Trepinglanguage);
        this.dataTreping = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            skill_service_id: '',
            qualification: '',
            subtitle_rate: '',
            per_word: '',
            /* speed: '', */
            bi_direction: false,
            bi_direction_test: ''
        };
        this.addIndex = null;
    };
    AddTranslatorComponent.prototype.addLanguageTableApi = function () {
        var _this = this;
        var bi_direction;
        var sworn;
        var ata_certified;
        var cttic;
        var naati;
        var atia;
        var atio;
        var najit;
        var atc;
        var euatc;
        var atn;
        this.dataSource = [];
        this.data.certificates = Array.isArray(this.data.certificates) ? this.data.certificates : [];
        if (typeof this.data.bi_direction == 'string' && this.data.bi_direction == '>') {
            this.data.bi_direction = false;
        }
        if (this.data.bi_direction == false) {
            bi_direction = '>';
        }
        else {
            bi_direction = '<>';
        }
        if (this.data.certificates.includes('SWORN')) {
            sworn = '1';
            this.data.sworn = sworn;
        }
        else {
            sworn = '0';
            this.data.sworn = sworn;
        }
        if (this.data.certificates.includes('ATA')) {
            ata_certified = '1';
            this.data.ata_certified = ata_certified;
        }
        else {
            ata_certified = '0';
            this.data.ata_certified = ata_certified;
        }
        if (this.data.certificates.includes('CTTIC')) {
            cttic = '1';
            this.data.cttic = cttic;
        }
        else {
            cttic = '0';
            this.data.cttic = cttic;
        }
        if (this.data.certificates.includes('NAATI')) {
            naati = '1';
            this.data.naati = naati;
        }
        else {
            naati = '0';
            this.data.naati = naati;
        }
        if (this.data.certificates.includes('ATIA')) {
            atia = '1';
            this.data.atia = atia;
        }
        else {
            atia = '0';
            this.data.atia = atia;
        }
        if (this.data.certificates.includes('ATIO')) {
            atio = '1';
            this.data.atio = atio;
        }
        else {
            atio = '0';
            this.data.atio = atio;
        }
        if (this.data.certificates.includes('NAJIT')) {
            najit = '1';
            this.data.najit = najit;
        }
        else {
            najit = '0';
            this.data.najit = najit;
        }
        if (this.data.certificates.includes('ATC')) {
            atc = '1';
            this.data.atc = atc;
        }
        else {
            atc = '0';
            this.data.atc = atc;
        }
        if (this.data.certificates.includes('EUATC')) {
            euatc = '1';
            this.data.euatc = euatc;
        }
        else {
            euatc = '0';
            this.data.euatc = euatc;
        }
        if (this.data.certificates.includes('ATN')) {
            atn = '1';
            this.data.atn = atn;
        }
        else {
            atn = '0';
            this.data.atn = atn;
        }
        //debugger;
        if (this.lang_combination_id) {
            this.table_data = [];
            if (this.data) {
                this.vendorService.updateLanguage(this.lang_combination_id, this.data)
                    .subscribe(function (response) {
                    if (response) {
                        _this.lang_combination_id = null;
                        _this.edit_getTranslatorData();
                        _this.data = {
                            source_lang_id: '',
                            sourcelanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            targetlanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            target_lang_id: '',
                            target_lang_name: '',
                            translation_rate: '',
                            proofreading_rate: '',
                            qa_rate: '',
                            dtp_rate: '',
                            speed: '',
                            certificates: [],
                            bi_direction: false,
                            bi_direction_test: ''
                        };
                    }
                }, function (error) {
                    if (error) {
                        _this.toastr.error('Please first select all fields.');
                    }
                });
            }
        }
        else {
            this.table_data = [];
            if (this.data.sourcelanguage.data.lang_name == '') {
                this.toastr.error('please select source language');
                return false;
            }
            if (this.data.targetlanguage.data.lang_name == '') {
                this.toastr.error('please select target language');
                return false;
            }
            if (this.data.translation_rate == '') {
                this.toastr.error('please select translation rate');
                return false;
            }
            if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
                this.toastr.error('Translation rate never be negative or zero');
                return false;
            }
            if (this.data.proofreading_rate == '') {
                this.toastr.error('please select proofreading rate');
                return false;
            }
            if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
                this.toastr.error('Proofreading rate never be negative or zero');
                return false;
            }
            // if (this.data.dtp_rate == '') {
            //   this.toastr.error('please select Dtp rate');
            //   return false;
            // }
            // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
            //   this.toastr.error('Dtp rate never be negative or zero');
            //   return false;
            // }
            // if (this.data.qa_rate == '') {
            //   this.toastr.error('please select Dtp rate');
            //   return false;
            // }
            // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
            //   this.toastr.error('Qa rate never be negative or zero');
            //   return false;
            // }
            if (this.data.speed == '') {
                this.toastr.error('please select speed');
                return false;
            }
            if (this.data.speed < 0 || this.data.speed == 0) {
                this.toastr.error('Speed never be negative or zero');
                return false;
            }
            this.data.vendor_id = this.id;
            this.data.table_data = this.table_data;
            this.vendorService.addLanguage_edit(this.data)
                .subscribe(function (response) {
                if (response) {
                    _this.edit_getTranslatorData();
                    _this.data = {
                        source_lang_id: '',
                        sourcelanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        targetlanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        certificates: [],
                        target_lang_id: '',
                        target_lang_name: '',
                        translation_rate: '',
                        proofreading_rate: '',
                        dtp_rate: '',
                        qa_rate: '',
                        speed: '',
                        bi_direction: false,
                        bi_direction_test: ''
                    };
                }
            });
        }
    };
    AddTranslatorComponent.prototype.addSubtitlerLanguageTableApi = function () {
        var _this = this;
        //debugger;
        if (this.subtitler_lang_combination_id) {
            this.table_subtitler_data = [];
            if (this.dataSubtitler) {
                this.vendorService.updateSubtitlerLanguage(this.subtitler_lang_combination_id, this.dataSubtitler)
                    .subscribe(function (response) {
                    if (response) {
                        _this.subtitler_lang_combination_id = null;
                        _this.edit_getSubtitlerData();
                        _this.dataSubtitler = {
                            source_lang_id: '',
                            sourcelanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            targetlanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            target_lang_id: '',
                            target_lang_name: '',
                            skill_service_id: '',
                            qualification: '',
                            subtitle_rate: '',
                            per_word: '',
                            speed: '',
                            bi_direction: false,
                            bi_direction_test: ''
                        };
                    }
                }, function (error) {
                    if (error) {
                        _this.toastr.error('Please first select all fields.');
                    }
                });
            }
        }
        else {
            //debugger;
            this.table_subtitler_data = [];
            if (this.dataSubtitler.sourcelanguage.data.lang_name == '') {
                this.toastr.error('please select avt source language');
                return false;
            }
            if (this.dataSubtitler.targetlanguage.data.lang_name == '') {
                this.toastr.error('please select avt target language');
                return false;
            }
            if (this.dataSubtitler.skill_service_id == '') {
                this.toastr.error('please select avt service');
                return false;
            }
            // if (this.dataSubtitler.subtitle_rate == '') {
            //   this.toastr.error('please select avt rate');
            //   return false;
            // }
            // if (this.dataSubtitler.subtitle_rate < 0 || this.dataSubtitler.subtitle_rate == 0) {
            //   this.toastr.error('Avt rate never be negative or zero');
            //   return false;
            // }
            // if (this.dataSubtitler.per_word == '') {
            //   this.toastr.error('please select avt rate per word');
            //   return false;
            // }
            // if (this.dataSubtitler.per_word < 0 || this.dataSubtitler.per_word == 0) {
            //   this.toastr.error('Avt rate per word never be negative or zero');
            //   return false;
            // }
            if (this.dataSubtitler.speed == '') {
                this.toastr.error('please select avt speed');
                return false;
            }
            if (this.dataSubtitler.speed < 0 || this.dataSubtitler.speed == 0) {
                this.toastr.error('Speed never be negative or zero');
                return false;
            }
            this.dataSubtitler.vendor_id = this.id;
            this.dataSubtitler.table_subtitler_data = this.table_subtitler_data;
            this.vendorService.addSubtitlerLanguage_edit(this.dataSubtitler)
                .subscribe(function (response) {
                if (response) {
                    _this.edit_getSubtitlerData();
                    _this.dataSubtitler = {
                        source_lang_id: '',
                        sourcelanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        targetlanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        target_lang_id: '',
                        target_lang_name: '',
                        skill_service_id: '',
                        qualification: '',
                        subtitle_rate: '',
                        per_word: '',
                        speed: '',
                        bi_direction: false,
                        bi_direction_test: ''
                    };
                }
            });
        }
    };
    AddTranslatorComponent.prototype.addTrepingLanguageTableApi = function () {
        var _this = this;
        //debugger;
        if (this.treping_lang_combination_id) {
            this.table_treping_data = [];
            if (this.dataTreping) {
                this.vendorService.updateTrepingLanguage(this.treping_lang_combination_id, this.dataTreping)
                    .subscribe(function (response) {
                    if (response) {
                        _this.treping_lang_combination_id = null;
                        _this.edit_getTrepingData();
                        //this.getTranslatorData();
                        _this.dataTreping = {
                            source_lang_id: '',
                            sourcelanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            targetlanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            target_lang_id: '',
                            target_lang_name: '',
                            skill_service_id: '',
                            qualification: '',
                            rate_hour: '',
                            rate_min: '',
                            speed: '',
                            bi_direction: false,
                            flexible_rate: false,
                            bi_direction_test: ''
                        };
                    }
                }, function (error) {
                    if (error) {
                        _this.toastr.error('Please first select all fields.');
                    }
                });
            }
        }
        else {
            //debugger;
            this.table_treping_data = [];
            if (this.dataTreping.sourcelanguage.data.lang_name == '') {
                this.toastr.error('please select treping source language');
                return false;
            }
            if (this.dataTreping.targetlanguage.data.lang_name == '') {
                this.toastr.error('please select treping target language');
                return false;
            }
            if (this.dataTreping.skill_service_id == '') {
                this.toastr.error('please select treping service');
                return false;
            }
            if (this.dataTreping.rate_hour == '' && this.dataTreping.rate_min == '') {
                this.toastr.error('please select atlest one rate either hour rate or minute rate');
                return false;
            }
            /* if (this.dataTreping.speed == '') {
              this.toastr.error('please select treping speed');
              return false;
            }
      
            if (this.dataTreping.speed < 0 || this.dataTreping.speed == 0) {
              this.toastr.error('Speed never be negative or zero');
              return false;
            } */
            this.dataTreping.vendor_id = this.id;
            this.dataTreping.table_subtitler_data = this.table_subtitler_data;
            this.vendorService.addTrepingLanguage_edit(this.dataTreping)
                .subscribe(function (response) {
                if (response) {
                    _this.edit_getTrepingData();
                    _this.dataTreping = {
                        source_lang_id: '',
                        sourcelanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        targetlanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        target_lang_id: '',
                        target_lang_name: '',
                        skill_service_id: '',
                        qualification: '',
                        rate_hour: '',
                        rate_min: '',
                        speed: '',
                        bi_direction: false,
                        flexible_rate: false,
                        bi_direction_test: ''
                    };
                }
            });
        }
    };
    AddTranslatorComponent.prototype.deleteSubtitlerData = function (obj, index) {
        var _this = this;
        if (this.id) {
            this.vendorService.deleteVendorSubtitlerLanguage(obj.id)
                .subscribe(function (response) {
                _this.toastr.success("delete successfully");
                _this.getTranslatorData();
                _this.data = {
                    source_lang_id: '',
                    sourcelanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    targetlanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    certificates: [],
                    target_lang_id: '',
                    target_lang_name: '',
                    skill_service_id: '',
                    qualification: '',
                    subtitle_rate: '',
                    per_word: '',
                    speed: '',
                    bi_direction: false,
                    bi_direction_test: ''
                };
            });
        }
        else {
            this.Subtitlerlanguage.splice(index, 1);
            this.dataSubtitlerSource = new MatTableDataSource(this.Subtitlerlanguage);
        }
    };
    AddTranslatorComponent.prototype.deleteTrepingData = function (obj, index) {
        var _this = this;
        if (this.id) {
            this.vendorService.deleteVendorTrepingLanguage(obj.id)
                .subscribe(function (response) {
                _this.toastr.success("delete successfully");
                _this.getTranslatorData();
                _this.dataTreping = {
                    source_lang_id: '',
                    sourcelanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    targetlanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    target_lang_id: '',
                    target_lang_name: '',
                    skill_service_id: '',
                    qualification: '',
                    rate_hour: '',
                    rate_min: '',
                    speed: '',
                    bi_direction: false,
                    flexible_rate: false,
                    bi_direction_test: ''
                };
            });
        }
        else {
            this.Trepinglanguage.splice(index, 1);
            this.dataSubtitlerSource = new MatTableDataSource(this.Trepinglanguage);
        }
    };
    AddTranslatorComponent.prototype.deleteData = function (obj, index) {
        var _this = this;
        if (this.id) {
            this.vendorService.deleteVendorLanguage(obj.id)
                .subscribe(function (response) {
                _this.toastr.success("delete successfully");
                _this.getTranslatorData();
                _this.data = {
                    source_lang_id: '',
                    sourcelanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    targetlanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    target_lang_id: '',
                    target_lang_name: '',
                    translation_rate: '',
                    proofreading_rate: '',
                    qa_rate: '',
                    dtp_rate: '',
                    speed: '',
                    bi_direction: false,
                    sworn: false,
                    ata_certified: false,
                    cttic: false,
                    naati: false,
                    atia: false,
                    atio: false,
                    najit: false,
                    atc: false,
                    euatc: false,
                    atn: false,
                    certificates: [],
                    bi_direction_test: ''
                };
            });
        }
        else {
            this.language.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.language);
        }
    };
    AddTranslatorComponent.prototype.tools = function (evt) {
        if (evt.checked && this.selected_tools.indexOf(evt.source.value == -1)) {
            this.selected_tools.push(evt.source.value);
        }
        else if (!evt.checked && this.selected_tools.indexOf(evt.source.value != -1)) {
            var index = this.selected_tools.indexOf(evt.source.value);
            this.selected_tools.splice(index, 1);
        }
    };
    AddTranslatorComponent.prototype.Available_days = function (evt) {
        if (evt.checked && this.selected_days.indexOf(evt.source.value == -1)) {
            this.selected_days.push(evt.source.value);
        }
        else if (!evt.checked && this.selected_days.indexOf(evt.source.value != -1)) {
            var index = this.selected_days.indexOf(evt.source.value);
            this.selected_days.splice(index, 1);
        }
    };
    AddTranslatorComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res;
            }
        });
    };
    AddTranslatorComponent.prototype.getTimezone = function () {
        var _this = this;
        this.vendorService.getAllTimezone()
            .subscribe(function (res) {
            if (res) {
                _this.timezoneData = res;
            }
        });
    };
    AddTranslatorComponent.prototype.getSkillService = function () {
        var _this = this;
        this.vendorService.getSkillService()
            .subscribe(function (res) {
            if (res) {
                _this.skillData = res;
            }
        });
    };
    AddTranslatorComponent.prototype.getTrepingSkillService = function () {
        var _this = this;
        this.vendorService.getTrepingSkillService()
            .subscribe(function (res) {
            if (res) {
                _this.skillTrepingData = res;
            }
        });
    };
    AddTranslatorComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                _this.specialityData = res;
                _this.dropdownList = res;
            }
        });
    };
    AddTranslatorComponent.prototype.getTranslatorStatus = function () {
        var _this = this;
        this.vendorService.getTranslatorStatus(this.id)
            .subscribe(function (res) {
            if (res) {
                _this.addTranslatorForm.patchValue({
                    is_active: res.user_detail.status.toString()
                });
            }
        });
    };
    AddTranslatorComponent.prototype.getVendorDoc = function () {
        var _this = this;
        this.vendorService.getVendorDoc(this.id)
            .subscribe(function (res) {
            if (res) {
                _this.file_data = res.vendor_docs;
            }
        });
    };
    AddTranslatorComponent.prototype.addData = function () {
        var _this = this;
        this.final_data = {};
        this.final_data = this.addTranslatorForm.value;
        this.final_data.extra_document = this.extra_file_id;
        this.final_data.contract_file_id = this.contract_file_id;
        this.final_data.sms_notification = this.sms_notification;
        this.final_data.wtsp_notification = this.wtsp_notification;
        // this.final_data.vo_sample = this.vo_sample;
        // this.final_data.voiceover_category = this.voiceover_category;
        // this.final_data.voiceover_notes = this.voiceover_notes;
        // this.final_data.untimed_vo_recording = this.untimed_vo_recording;
        // this.final_data.timed_vo_recording = this.timed_vo_recording;
        this.final_data.specialities = this.getSpecialityId(this.addTranslatorForm.value.specialities);
        this.final_data.availability = this.selected_days.toString();
        this.final_data.vendor_skill = this.selected_skills;
        if (this.selected_tools.length > 0) {
            this.final_data.tool_names = this.selected_tools.toString();
        }
        else {
            this.final_data.tool_names = this.selected_tools;
        }
        this.final_data.vendor_skill = this.selected_skills;
        if (this.selected_days.length > 0) {
            this.final_data.availability = this.selected_days.toString();
        }
        else {
            this.final_data.availability = this.selected_days;
        }
        if (this.file_id_array.length > 0) {
            this.final_data.resume_id = this.file_id_array;
        }
        if (this.coa_id_array.length > 0) {
            this.final_data.coa_files = this.coa_id_array;
        }
        else {
            this.final_data.coa_files = [];
        }
        this.final_data.file_id = [];
        this.final_data.language = this.language.map(function (lang) {
            lang.bi_direction = (lang.bi_direction == false || lang.bi_direction == '>') ? false : true;
            return lang;
        });
        ;
        this.final_data.vendor_subtitler = this.Subtitlerlanguage;
        this.final_data.vendor_treping = this.Trepinglanguage;
        //console.log('this.final_data');
        //console.log(this.final_data);
        this.vendorService.add(this.final_data).subscribe(function (resposne) {
            if (resposne) {
                if (resposne.status == 409) {
                    _this.toastr.error(resposne.message);
                }
                else {
                    _this.toastr.success(resposne.message);
                    _this.router.navigate(['hr/translator']);
                }
            }
        });
    };
    AddTranslatorComponent.prototype.getSpecialityId = function (res) {
        if (res.constructor === String) {
            return res;
        }
        else if (res.length > 0 && res.constructor === Array) {
            var id_1 = '';
            res.forEach(function (element) {
                id_1 += element.id + ', ';
            });
            id_1 = id_1.slice(0, -2);
            return id_1;
        }
    };
    AddTranslatorComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext.toLowerCase()) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return false;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    AddTranslatorComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        this.uploaded_files_array = [];
        this.file_id_array = [];
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files_array.push(response.data);
            response.data.forEach(function (element) {
                _this.file_id_array.push(element.id);
            });
            _this.fileSuccess = 'Uploaded successfully!!';
        });
    };
    AddTranslatorComponent.prototype.onExtraFileUpload = function () {
        var _this = this;
        this.file_multiple_data2 = [];
        var fileUpload = document.getElementById('extraDocUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext.toLowerCase()) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return false;
                    }
                    _this.file_multiple_data2.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addExtraDocument(_this.file_multiple_data2);
        };
        fileUpload.click();
    };
    AddTranslatorComponent.prototype.addExtraDocument = function (file) {
        var _this = this;
        this.extraFileSuccess = '';
        this.uploaded_extra_files_array = [];
        this.extra_file_id = [];
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_extra_files_array.push(response.data);
            response.data.forEach(function (element) {
                _this.extra_file_id.push(element.id);
                //this.extra_file_id = element.id;
            });
            _this.extraFileSuccess = 'Uploaded successfully!!';
        });
    };
    AddTranslatorComponent.prototype.onContractFileUpload = function () {
        var _this = this;
        this.file_multiple_data3 = [];
        var fileUploads = document.getElementById('contractUpload');
        fileUploads.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUploads.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext.toLowerCase()) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return false;
                    }
                    _this.file_multiple_data3.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addContractDocument(_this.file_multiple_data3);
        };
        fileUploads.click();
    };
    AddTranslatorComponent.prototype.addContractDocument = function (file) {
        var _this = this;
        this.contractFileSuccess = '';
        this.uploaded_contract_files_array = [];
        this.contract_file_id = [];
        var uploadContractData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadContractData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadContractData)
            .subscribe(function (response) {
            _this.uploaded_contract_files_array.push(response.data);
            response.data.forEach(function (element) {
                _this.contract_file_id.push(element.id);
                //this.contract_file_id = element.id;
            });
            _this.contractFileSuccess = 'Uploaded successfully!!';
        });
    };
    // COA FILE UPLOAD
    AddTranslatorComponent.prototype.onCoaUpload = function () {
        var _this = this;
        this.file_multiple_data1 = [];
        var fileUpload = document.getElementById('coaUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext.toLowerCase()) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError1 = 'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data1.push(file);
                }
                else {
                    _this.fileError1 = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addCoa(_this.file_multiple_data1);
        };
        fileUpload.click();
    };
    // addCoa
    AddTranslatorComponent.prototype.addCoa = function (file) {
        var _this = this;
        this.fileSuccess1 = '';
        // this.uploaded_coa_array = [];
        // this.coa_id_array = [];
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            response.data.forEach(function (element) {
                if (_this.id) {
                    _this.coa_array.push(element);
                }
                else {
                    _this.uploaded_coa_array.push(element);
                }
                _this.coa_id_array.push(element.id);
            });
            if (_this.id) {
                _this.vendorCoaFile(_this.coa_id_array);
            }
            _this.fileSuccess1 = 'Uploaded successfully!!';
        });
    };
    AddTranslatorComponent.prototype.vendorCoaFile = function (coaFileId) {
        var _this = this;
        var obj = {
            coa_files: coaFileId
        };
        this.vendorService.VendorsCoaFile(this.id, obj)
            .subscribe(function (response) {
            _this.getTranslatorData();
        });
    };
    AddTranslatorComponent.prototype.deleteCoa = function (id) {
        var coa_array_index = this.uploaded_coa_array.findIndex(function (x) { return x.id === id; });
        if (coa_array_index !== -1) {
            this.uploaded_coa_array.splice(coa_array_index, 1);
        }
        var coa_id_index = this.coa_id_array.findIndex(function (x) { return x === id; });
        if (coa_id_index !== -1) {
            this.coa_id_array.splice(coa_id_index, 1);
        }
    };
    AddTranslatorComponent.prototype.deleteExtra = function (id) {
        var _this = this;
        this.removed_extra_document_data = {};
        this.removed_extra_document_data.vendor_id = this.id;
        this.removed_extra_document_data.extra_document = id;
        //console.log(this.removed_extra_document_data);
        this.vendorService.removeExtradocument(this.removed_extra_document_data).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success(resposne.message);
                //window.location.reload();
                _this.getTranslatorData();
                _this.getLanguage();
                _this.getTimezone();
                _this.getSkillService();
                _this.getTrepingSkillService();
                _this.getSpeciality();
                _this.getTranslatorStatus();
                _this.getVendorDoc();
                //window.location.href='/hr/translator/'+id;
                _this.uploaded_extra_files_array = [];
                _this.vendor_extra_document_name = null;
                _this.extraFileSuccess = '';
                _this.file_multiple_data2 = [];
                _this.extra_file_id = [];
            }
        });
    };
    AddTranslatorComponent.prototype.deleteCoaEdit = function (obj) {
        var _this = this;
        this.vendorService.deleteCoaFile(obj.id)
            .subscribe(function (response) {
            _this.toastr.success("delete successfully");
            _this.deleteCoaEditArray(obj);
        });
    };
    AddTranslatorComponent.prototype.deleteExtraEdit = function (obj) {
        //debugger;
        //console.log(obj);
        this.uploaded_extra_files_array = [];
        this.vendor_extra_document_name = null;
        this.extraFileSuccess = '';
        this.file_multiple_data2 = [];
        this.extra_file_id = [];
    };
    AddTranslatorComponent.prototype.deleteCoaEditArray = function (obj) {
        var coa_array_index = this.coa_array.findIndex(function (x) { return x.id === obj.id; });
        if (coa_array_index !== -1) {
            this.coa_array.splice(coa_array_index, 1);
        }
        var coa_id_index = this.coa_id_array.findIndex(function (x) { return x === obj.coa_file_id; });
        if (coa_id_index !== -1) {
            this.coa_id_array.splice(coa_id_index, 1);
        }
    };
    // COA END
    AddTranslatorComponent.prototype.back = function () {
        this.location.back();
    };
    AddTranslatorComponent.prototype.Reset = function () {
        this.addTranslatorForm.reset();
        this.location.back();
    };
    AddTranslatorComponent.prototype.onCreate = function () {
        var _this = this;
        var dailogRef = this.dialog.open(AddVendorDocComponent, {
            width: '70%',
            data: { vendor_id: this.id, reference_id: this.reference_id }
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getVendorDoc();
            }
        });
    };
    AddTranslatorComponent.prototype.Skills = function (evt) {
        if (evt.checked && this.selected_skills.indexOf(evt.source.value == -1)) {
            this.selected_skills.push(evt.source.value);
        }
        else if (!evt.checked && this.selected_skills.indexOf(evt.source.value != -1)) {
            var index = this.selected_skills.indexOf(evt.source.value);
            this.selected_skills.splice(index, 1);
        }
    };
    AddTranslatorComponent.prototype.smsNotification = function (evt) {
        if (evt.checked) {
            this.sms_notification = 1;
        }
        else {
            this.sms_notification = 0;
        }
    };
    AddTranslatorComponent.prototype.wtspNotification = function (evt) {
        if (evt.checked) {
            this.wtsp_notification = 1;
        }
        else {
            this.wtsp_notification = 0;
        }
    };
    AddTranslatorComponent.prototype.onItemSelect = function (item) {
    };
    AddTranslatorComponent.prototype.onSelectAll = function (items) {
    };
    AddTranslatorComponent.prototype.onItemDeSelect = function (items) {
    };
    AddTranslatorComponent.prototype.onDeSelectAll = function (items) {
    };
    AddTranslatorComponent.prototype.selectFile = function (evt, res) {
        //debugger;
        //console.log(res);
        if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
            this.file_id_data.push(res.uuid);
        }
        else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
            var index = this.file_id_data.indexOf(res.uuid);
            this.file_id_data.splice(index, 1);
        }
        //console.log(this.file_id_data);
        //this.DownloadZip();
    };
    // Remove Project File
    AddTranslatorComponent.prototype.removeVendorDocFile = function (res) {
        var _this = this;
        this.vendorService.deleteVendorDoc(res.uuid).subscribe(function (response) {
            if (response) {
                _this.toastr.success(" Delete Vendor Doc File  Successfully!!");
                _this.getVendorDoc();
            }
        });
    };
    return AddTranslatorComponent;
}());
export { AddTranslatorComponent };
export var confirmPasswordValidator = function (control) {
    if (!control.parent || !control) {
        return null;
    }
    var password = control.parent.get('password');
    var passwordConfirm = control.parent.get('confirm_password');
    if (!password || !passwordConfirm) {
        return null;
    }
    if (passwordConfirm.value === '') {
        return null;
    }
    if (password.value === passwordConfirm.value) {
        return null;
    }
    return { passwordsNotMatching: true };
};
