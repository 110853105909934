import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { MarketingDashboardComponent } from "./marketing-dashboard/marketing-dashboard.component";
import { TranslatorComponent } from "./translator/translator.component";
import { AddTranslatorComponent } from "./translator/add-translator/add-translator.component";
import { MatCardModule } from "@angular/material/card";
import {
    MatButtonModule,
    MatBadgeModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatDatepickerModule,
    MatTableModule,
    MatDividerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
} from "@angular/material";
import { MatProgressBarModule } from '@angular/material';
import { MatRadioModule } from "@angular/material/radio";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatStepperModule } from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MarketingGuard } from "app/_guards/marketing.guard";
import { ReviewTranslatorComponent } from "./translator/review-translator/review-translator.component";
import { SharedListingComponent } from "./marketing-dashboard/shared-listing/shared-listing.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown-angular7";
import { CKEditorModule } from "ng2-ckeditor";
import { BulkEmailTranslatorComponent } from "./translator/bulk-email-translator/bulk-email-translator.component";
import { AddVendorDocComponent } from "./translator/add-translator/add-vendor-doc/add-vendor-doc.component";
import { ClosedBlacklistedComponent } from "./closed-blacklisted/closed-blacklisted.component";
import { GclidComponent } from "./gclid/gclid.component";
const routes = [
    {
        path: "marketing/marketing-dashboard-shared-listing",
        component: SharedListingComponent,
        canActivate: [MarketingGuard],
    },

    {
        path: "marketing/marketing-dashboard",
        component: MarketingDashboardComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer",
        component: TranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/gclid-records",
        component: GclidComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer/review/:id",
        component: ReviewTranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer/add-freelancer",
        component: AddTranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer/:id",
        component: AddTranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/closed-blacklisted",
        component: ClosedBlacklistedComponent,
        canActivate: [MarketingGuard],
    },
];

@NgModule({
    declarations: [
        MarketingDashboardComponent,
        TranslatorComponent,
        AddTranslatorComponent,
        ReviewTranslatorComponent,
        SharedListingComponent,
        BulkEmailTranslatorComponent,
        AddVendorDocComponent,
        ClosedBlacklistedComponent,
        GclidComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        TranslateModule,
        MatCardModule,
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatRadioModule,
        MatFormFieldModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatDatepickerModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatBadgeModule,
        MatTooltipModule,
        CKEditorModule,
        BrowserAnimationsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatProgressBarModule
    ],
    entryComponents: [BulkEmailTranslatorComponent, AddVendorDocComponent],
    exports: [
        TranslatorComponent,
        AddTranslatorComponent
    ],
})
export class MarketingModule {}
