import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { UpdateAccountComponent } from './update-account/update-account.component';
var AccountDetailsComponent = /** @class */ (function () {
    function AccountDetailsComponent(route, dialog, projectsService, toastr) {
        this.route = route;
        this.dialog = dialog;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.project_detail = [];
        this.total_records = 0;
        this.total_customer = 0;
        this.displayedColumns = ['id', 'customerName', 'projservices', 'languagePair', 'projStartDate', 'projEndDate', 'projStatus'];
        this.displayedColumns_customer = ['id', 'name', 'cust_email', 'savedDate', 'paymentMethod', 'status', 'categories', 'leadSource'];
    }
    AccountDetailsComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getProjects();
    };
    AccountDetailsComponent.prototype.getProjects = function () {
        var _this = this;
        this.projectsService.getAccountProjects(this.project_id)
            .subscribe(function (res) {
            if (res) {
                _this.response = res.data;
                _this.dataSource_customer = new MatTableDataSource(res.data.accountcustomer.data);
                _this.total_customer = res.data.accountcustomer.data.length;
                res.data.accountproject.data.forEach(function (element) {
                    if (element.project) {
                        var lang1_1 = '';
                        var lang_array_1 = [];
                        element.project.data.projectLanguage.data.forEach(function (elm) {
                            lang1_1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array_1.push(lang1_1);
                        });
                        if (lang_array_1.length > 0) {
                            var test = void 0;
                            test = lang_array_1[lang_array_1.length - 1];
                            lang_array_1[lang_array_1.length - 1] = test.slice(0, -1);
                        }
                        element.project.lang_array = lang_array_1;
                        _this.project_detail.push(element.project);
                    }
                });
                _this.dataSource = new MatTableDataSource(_this.project_detail);
                _this.total_records = _this.project_detail.length;
            }
        });
    };
    // popup update account
    AccountDetailsComponent.prototype.openDialogAccount = function () {
        var _this = this;
        var data = {
            accountDetail: this.response
        };
        var dailogRef = this.dialog.open(UpdateAccountComponent, {
            width: '75%',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getProjects();
            }
        });
    };
    return AccountDetailsComponent;
}());
export { AccountDetailsComponent };
