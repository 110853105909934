import { OnInit, EventEmitter } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
var AdditionalInformationMailingComponent = /** @class */ (function () {
    function AdditionalInformationMailingComponent(projectsService, route, toastr) {
        this.projectsService = projectsService;
        this.route = route;
        this.toastr = toastr;
        this.additonal_service_array = [];
        this.show_address = true;
        this.mailing_show = false;
        this.myoutputMailing = new EventEmitter();
        this.outputString = "(NA)";
    }
    AdditionalInformationMailingComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getAdditionalServices();
    };
    AdditionalInformationMailingComponent.prototype.getAdditionalServices = function () {
        var _this = this;
        this.projectsService.getAdditionalServices(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.additonal_service_array = response.project_addition_service;
                if (_this.additonal_service_array.length == 0) {
                    _this.mailing_show = false;
                    _this.sendNotApply();
                }
                if (response.project_addition_service.length > 0) {
                    response.project_addition_service.forEach(function (element) {
                        if (element.additional_service.code == "e_preference") {
                            _this.mailing_show = true;
                            _this.show_address = false;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == "certified_Mailing" ||
                            element.additional_service.code == "certified_Mailing_1Day" ||
                            element.additional_service.code == "certified_Mailing_International" ||
                            element.additional_service.code == "certified_Mailing_Canada") {
                            _this.show_address = true;
                            _this.mailing_show = true;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == "additional_copy") {
                            _this.show_address = true;
                            _this.mailing_show = true;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == "regular_PickUp") {
                            _this.mailing_show = true;
                            _this.show_address = true;
                            _this.sendApply();
                        }
                    });
                }
            }
        });
    };
    AdditionalInformationMailingComponent.prototype.sendNotApply = function () {
        this.outputString = "(NA)";
        var data = {
            title: this.outputString,
            click_value: 0
        };
        this.myoutputMailing.emit(data);
    };
    AdditionalInformationMailingComponent.prototype.sendApply = function () {
        this.outputString = "(Applied)";
        var data = {
            title: this.outputString,
            click_value: 0
        };
        this.myoutputMailing.emit(data);
    };
    AdditionalInformationMailingComponent.prototype.Edit = function () {
        this.outputString = "(Applied)";
        var data = {
            title: this.outputString,
            click_value: 1
        };
        this.myoutputMailing.emit(data);
    };
    return AdditionalInformationMailingComponent;
}());
export { AdditionalInformationMailingComponent };
