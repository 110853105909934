<div class="adddServices">
  <h3>Add Additional Services</h3>
  <mat-form-field  >
    <mat-label>Transcription of names, if applicable</mat-label>
    <input matInput>
  </mat-form-field>


  <mat-tab-group animationDuration="0ms"  [selectedIndex]="indexID">
    <mat-tab label="Mailing Options " >
      <ng-template mat-tab-label>
        Mailing
      </ng-template>
      <div class="p20">
        <mat-radio-group class="blockLine" aria-label="Select an option">
          <mat-radio-button value="certified_Mailing_1Day"
            (change)="check_mailing_service($event,'certified_Mailing_1Day')">Overnight (1 business day): <b>$40.00
              USD</b></mat-radio-button>
          <mat-radio-button value="certified_Mailing" (change)="check_mailing_service($event,'certified_Mailing')">
            Regular mailing (2-3 business
            days):<b>$30.00 USD</b> </mat-radio-button>
          <mat-radio-button value="certified_Mailing_International"
            (change)="check_mailing_service($event,'certified_Mailing_International')"> International mailing (3-5
            business
            days): <b>$60.00 USD</b></mat-radio-button>
          <mat-radio-button value="certified_Mailing_Canada"
            (change)="check_mailing_service($event,'certified_Mailing_Canada')"> Canada mailing (1-3 business days):
            <b>$45.00 USD</b></mat-radio-button>
          <mat-radio-button value="regular_PickUp" (change)="check_mailing_service($event,'regular_PickUp')"> Expedited
            Office pick up: <b>$14.99
              USD</b></mat-radio-button>
        </mat-radio-group>


        <p *ngIf="show_mailing_address"><b>Please let us know where we should ship your documents to.</b></p>

        <div fxLayout="column" *ngIf="show_mailing_address">

          <!-- <mat-radio-group class="blockLine" aria-label="Select an option">
            <mat-radio-button value="1">
              <b>Ship here:</b> 202, Wert St, North Avenue, 34567,

              <b> Phone:</b> +689-458792-3564
            </mat-radio-button>
            <mat-radio-button value="2"><b>Ship to new Address</b></mat-radio-button>
          </mat-radio-group> -->
         
              <mat-radio-group class="blockLine" aria-label="Select an option" >
                  <mat-radio-button (change)="checkMailingAddress($event)" value="Shipping" *ngIf="mailing_address.length>0 && shipping_address.type=='Shipping'" >
                    <b>Shipping here:</b>{{shipping_address?.street}} {{shipping_address?.city}} {{shipping_address?.state}} {{shipping_address?.postal_code}}
                  </mat-radio-button>
                  <mat-radio-button (change)="checkMailingAddress($event)" value="Billing"  *ngIf="mailing_address.length>0 && billing_address.type=='Billing'">
                      <b>Billing here:</b> {{billing_address?.street}} {{billing_address?.city}} {{billing_address?.state}} {{billing_address?.postal_code}}
                  </mat-radio-button>
                </mat-radio-group>
          
     

          <div class="newAdd" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field   fxFlex="90">
              <mat-label>Street Address *</mat-label>
              <input matInput [(ngModel)]="check_mailing.street_address" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field  >
              <mat-label>City *</mat-label>
              <input matInput [(ngModel)]="check_mailing.city" [ngModelOptions]="{standalone: true}">
            </mat-form-field>


            <mat-form-field   fxFlex="20">
              <mat-label>State*</mat-label>
              <input matInput [(ngModel)]="check_mailing.state" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field   fxFlex="20">
              <mat-label>Country *</mat-label>
              <input matInput [(ngModel)]="check_mailing.country" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field   fxFlex="20">
              <mat-label>Zip Code*</mat-label>
              <input matInput [(ngModel)]="check_mailing.zip_code" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field   fxFlex="50">
              <mat-label>Phone Number *</mat-label>
              <input matInput [(ngModel)]="check_mailing.phone_number" type="number"
                [ngModelOptions]="{standalone: true}">
            </mat-form-field>

          </div>


        </div>

        <div class="acr" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px"
          *ngIf="show_mailing_address">
          <mat-checkbox (change)="show_no_of_copy_mailing($event)">Additional copy required: $10.00 USD per set
          </mat-checkbox>

          <mat-form-field   fxFlex="20" *ngIf="show_mailing_copy">
            <mat-label>No. of copies *</mat-label>
            <input matInput [(ngModel)]="check_no_mailing.no_of_copy" [ngModelOptions]="{standalone: true}"
              type="number" (change)="mailingSetNumberOfCopies($event,'additional_copy')">
          </mat-form-field>


        </div>

        <mat-checkbox *ngIf="show_mailing_address" (change)="send_to_second_address($event)">Send additional copy to
          different address </mat-checkbox>
        <div>
          <p *ngIf="show_second_address"> <b>Choose the mailing option for second address</b></p>

          <mat-radio-group class="blockLine" aria-label="Select an option" *ngIf="show_second_address">
            <mat-radio-button value="1" (change)="setSecondAddress($event)">Overnight (1 business day): <b>$40.00
                USD</b></mat-radio-button>
            <mat-radio-button value="2" (change)="setSecondAddress($event)">Regular mailing (2-3 business
              days):<b>$30.00 USD</b> </mat-radio-button>
            <mat-radio-button value="3" (change)="setSecondAddress($event)"> International mailing (3-5 business days):
              <b>$60.00 USD</b></mat-radio-button>
            <mat-radio-button value="4" (change)="setSecondAddress($event)"> Canada mailing (1-3 business days):
              <b>$45.00 USD</b></mat-radio-button>
          </mat-radio-group>

          <p *ngIf="show_second_address_field"><b>Enter Second address for additional copy mailing</b></p>

          <div fxLayout="row wrap" class="secAddrs" fxLayoutGap="10px" *ngIf="show_second_address_field">



            <mat-form-field   fxFlex="90">
              <mat-label>Street Address *</mat-label>
              <input matInput [(ngModel)]="mailing_second_address.street_address" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field  >
              <mat-label>City *</mat-label>
              <input matInput [(ngModel)]="mailing_second_address.city" [ngModelOptions]="{standalone: true}">
            </mat-form-field>


            <mat-form-field   fxFlex="20">
              <mat-label>State*</mat-label>
              <input matInput [(ngModel)]="mailing_second_address.state" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field   fxFlex="20">
              <mat-label>Country *</mat-label>
              <input matInput [(ngModel)]="mailing_second_address.country" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field   fxFlex="20">
              <mat-label>Zip Code*</mat-label>
              <input matInput [(ngModel)]="mailing_second_address.zip_code" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

          </div>
        </div>
        <p *ngIf="show_pickup_address"><b>Choose Pickup Location</b></p>

        <div fxLayout="row wrap" class="pickLocation" *ngIf="show_pickup_address">

          <mat-form-field   fxFlex="50">
            <mat-label>Choose Pickup Location *</mat-label>
            <mat-select (selectionChange)="selectPickUp($event,'regular_PickUp')">
              <mat-option [value]="''">
                Any
              </mat-option>
              <mat-option *ngFor="let res of locationData" [value]="res.id">
                {{res.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="box" fxFlex="90" *ngIf="show_single_pickup_address">

            <p><b>{{singlelocation?.title}}, {{singlelocation?.country}}</b></p>
            <p>
              {{singlelocation?.complete_address}}, {{singlelocation?.city}}, {{singlelocation?.state}}
              {{singlelocation?.zip}}
            </p>
            <p class="mt-16"><b>Phone:</b> {{singlelocation?.phone}}</p>

          </div>

        </div>


        <p>
          <button mat-raised-button color="accent" (click)="SubmitMailing()">Save</button>
          <button class="ml-8" mat-raised-button color="warn" (click)="Cancel()">Cancel</button>
        </p>
      </div>
    </mat-tab>

    <mat-tab label="Notarization" >
      <ng-template mat-tab-label>
        Notarization
      </ng-template>
      <div class="p20">

        <div class="acr" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-checkbox value="notarization_coa_il_ca" (change)="select_notarization($event,'notarization_coa_il_ca')">
            Notarization of COA in CA or IL:<b> $19.99 USD</b> </mat-checkbox>

          <mat-form-field   fxFlex="20" *ngIf="show_notarization_no_of_copy">
            <mat-label>No. of copies *</mat-label>
            <input matInput [value]="checkNumberOfCopies" type="number"
              (change)="setNumberOfCopies($event,'notarization_coa_il_ca')">
          </mat-form-field>


        </div>

        <p>
          <mat-checkbox value="notarization_other_state"
            (change)="select_notarization($event,'notarization_other_state')">Notarization in any other state: <b>$75.00
              USD </b></mat-checkbox>
        </p>
        <p>
          <mat-form-field   fxFlex="50" *ngIf="show_notarization_other_state">
            <mat-label>Indicate State and Country for notarization *</mat-label>
            <input matInput [value]='checkStateValue' (change)="setState($event,'notarization_other_state')">
          </mat-form-field>


        </p>


        <div class="acr" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px"
          *ngIf="show_additional_notarization">
          <mat-checkbox value="notarization_additional"
            (change)="select_notarization($event,'notarization_additional')">Additional Notarization: <b>$14.99 USD</b>
          </mat-checkbox>

          <mat-form-field   fxFlex="20" *ngIf="show_additional_notarization_no_of_copy">
            <mat-label>No. of copies *</mat-label>
            <input matInput [value]="additional_notarization_checkNumberOfCopies" type="number"
              (change)="setNumberOfCopies_AdditionalNotarization($event,'notarization_additional')">
          </mat-form-field>
        </div>


        <p>
          <mat-checkbox value="notarization_affidavit_court"
            (change)="select_notarization($event,'notarization_affidavit_court')">Notarization Affidavit for court:
            <b>$34.99 USD</b> </mat-checkbox>
        </p>
        <p><button mat-raised-button color="accent" (click)="SubmitNotarization()">Save</button>
          <button class="ml-8" mat-raised-button color="warn" (click)="Cancel()">Cancel</button>
        </p>
      </div>

    </mat-tab>
    <mat-tab label="Apostile " >
      <ng-template mat-tab-label>
        Apostile
      </ng-template>
      <form fxLayout="column" [formGroup]="Form" name="Form">
        <div class="p20" fxLayout="column">


          <p>
            <mat-checkbox [(ngModel)]="check_apostille.value" [ngModelOptions]="{standalone: true}"
              (change)="check_apostille_service($event)">Apostile service required (translation only)</mat-checkbox>
          </p>
          <div *ngIf="show_apostille_country">
            <mat-form-field  >
              <mat-label> Type country name for which Apostile service is required*</mat-label>
              <input matInput formControlName="apostile_country_source">
            </mat-form-field>
          </div>

          <div *ngIf="show_apostille_country"><b> Choose the state for which Apostile service is required *</b>
          </div>
          <mat-radio-group class="blockLine" aria-label="Select an option" formControlName="service_code"
            *ngIf="show_apostille_country">
            <mat-radio-button value="apostille_service_Illinois" (change)="select_apostille_service($event)">Apostile
              service from Illinois -<b> $105</b> </mat-radio-button>
            <mat-radio-button value="apostille_service_outside_Illinois" (change)="select_apostille_service($event)">
              Apostile  service outside Illinois -<b> $205 </b> </mat-radio-button>
          </mat-radio-group>
          <mat-form-field   fxFlex="30" *ngIf="show_apostille_state">
            <mat-label>Select State</mat-label>
            <input matInput formControlName="apostile_from_state">
          </mat-form-field>
          <p><button mat-raised-button color="accent" (click)="Submit_Apostille()">Save</button>
            <button class="ml-8" mat-raised-button color="warn" (click)="Cancel()">Cancel</button></p>

        </div>
      </form>

    </mat-tab>
  </mat-tab-group>
</div>