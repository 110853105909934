import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot ,Router} from '@angular/router';
import { SharedService } from 'app/main/shared.service';
@Injectable({
  providedIn: 'root'
})
export class HrGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot){
    let roleData = SharedService.get(SharedService.user_group_id);
    if (SharedService.get(SharedService.LOGIN) == 'true' && (roleData == 'Hr-Admin' || roleData == 'Admin' || roleData == 'Project-Manager')) {
      return true;
    }
    this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
