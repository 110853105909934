import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financial-dashboard',
  templateUrl: './financial-dashboard.component.html',
  styleUrls: ['./financial-dashboard.component.scss']
})
export class FinancialDashboardComponent implements OnInit {
  
  public pieChartLabels:string[] = ["Less then 50%", "50% - 64.99%", "65% - 74.99%", "75% - 99.99%", '100% and up'];
  public pieChartData:number[] = [21, 39, 18, 15, 20];
  public pieChartType:string = 'bar';
  public pieChartOptions:any = {'backgroundColor': [
            "#FF6384",
            "#4BC0C0",
            "#FFCE56",
            "#E7E9ED",
            "#36A2EB"
            ], 'responsive' : false}

            // events on slice click
  public chartClicked(e:any):void {
 
  }
 
 // event on pie chart slice hover
  public chartHovered(e:any):void {
  
  }
  constructor() { }

  ngOnInit() {
  }

}
