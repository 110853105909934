import { MatDialogRef } from '@angular/material';
var FuseConfirmDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    function FuseConfirmDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    return FuseConfirmDialogComponent;
}());
export { FuseConfirmDialogComponent };
