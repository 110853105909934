import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { CrmService } from 'app/main/Services/crm.service';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TableUtil } from 'app/utils/tableUtil';
var GclidComponent = /** @class */ (function () {
    function GclidComponent(_fuseNavigationService, crmService, projectsService, toastr, router, dialog) {
        this._fuseNavigationService = _fuseNavigationService;
        this.crmService = crmService;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.router = router;
        this.dialog = dialog;
        this.paginate = {};
        this.pageIndex = 1;
        this.query_approve = '';
        this.filterSpecialization = '';
        this.notArchive = 'Unavailable,Blacklisted,Closed';
        this.filter_src_lang = '';
        this.filter_target_lang = '';
        this.fQuery = '';
        this.ids_progress = [];
        this.all_freelancer_id = [];
        this.bulk_selected_progress = false;
        this.send_email_progress_data = {};
        this.emailTemplateData = '';
        this.login_enable = true;
        this.specialityData = [];
        this.query_user_sort = '';
        this.languageData = [];
        this.pageSize = 10;
        this.filter_native_lang = null;
        this.fdata = {
            specialization: '',
            Source: '',
            Target: '',
            Native: ''
        };
        this.ApprovaldisplayedColumns = ['type', 'gclid', 'msclkid', 'currency', 'amount', 'payment_status', 'date_entered'];
    }
    GclidComponent.prototype.ngOnInit = function () {
        this.getGclids();
    };
    GclidComponent.prototype.applyFilterApproved = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    GclidComponent.prototype.getGclids = function () {
        var _this = this;
        var query = '';
        var gclidFilter = localStorage.getItem('filter');
        var filterObject = JSON.parse(gclidFilter);
        var gclidPagination = localStorage.getItem('pagination');
        var gclidObject = JSON.parse(gclidPagination);
        if (gclidFilter != null && filterObject.filter) {
            query = filterObject.filter;
        }
        else if (gclidPagination !== null && gclidObject.table_name == 'gclidPagination') {
            query = '?page=' + gclidObject.page + '&per_page=' + gclidObject.per_page;
        }
        else {
            query = '?page=1&per_page=200&test=1&not_archive=' + this.notArchive;
        }
        this.crmService.getAllGclid(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource1 = response;
                _this.dataSource = new MatTableDataSource(response);
                //this.paginate = response.meta.pagination;
            }
        });
    };
    GclidComponent.prototype.export = function () {
        var data = [['Type', 'Google Click ID (GCLID)', 'Microsofts Click ID (MSCLKID)', 'Currency', 'Amount', 'Payment Status', 'Date Entered']];
        this.dataSource1.forEach(function (record) {
            var paymentDetail = "Method: " + record.payment_method_c + "\n          Status: " + record.payment_status_c + " / " + record.approval_payment_dd_c;
            data.push([(record.type == 'ld' ? 'Lead' : 'Opportunity'), record.gclid_c, record.msclkid_c, (record.currencyName ? record.currencyName + ':' + record.currencySymbol : 'US Dollars : $'), (record.type == "ld" ? record.opportunity_amount : record.amount), paymentDetail, record.date_entered]);
        });
        TableUtil.exportArrayToExcel(data);
    };
    return GclidComponent;
}());
export { GclidComponent };
