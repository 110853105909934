<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar id="top">
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon routerLink="/masterdata">keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1">
      Location Master
    </span>
  </div>
  <div class="white-box">
    <form name="addlocationForm" [formGroup]="addlocationForm">
      <div class="boxBodyInn">
        <div class="profHeader" fxLayout="row wrap">
          <div fxFlex="50">
            <p class="dLabel">Location Title*</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="title">
                <mat-error *ngIf="addlocationForm.get('title').invalid && addlocationForm.get('title').touched">
                  <span *ngIf="addlocationForm.get('title').errors.required">Location Title is required!!</span>
                </mat-error>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="100">
            <p class="dLabel">Complete Address*</p>
            <p class="dValue">
              <mat-form-field  >
                <textarea matInput formControlName="complete_address"> </textarea>
                <mat-error
                  *ngIf="addlocationForm.get('complete_address').invalid && addlocationForm.get('complete_address').touched">
                  <span *ngIf="addlocationForm.get('complete_address').errors.required">Complete Address is
                    required!!</span>
                </mat-error>
              </mat-form-field>
            </p>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div fxFlex="20">
            <p class="dLabel">City*</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="city">
                <mat-error *ngIf="addlocationForm.get('city').invalid && addlocationForm.get('city').touched">
                  <span *ngIf="addlocationForm.get('city').errors.required">City is required!!</span>
                </mat-error>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">State*</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="state">
                <mat-error *ngIf="addlocationForm.get('state').invalid && addlocationForm.get('state').touched">
                  <span *ngIf="addlocationForm.get('state').errors.required">State is required!!</span>
                </mat-error>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Zip*</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput  formControlName="zip">
                <mat-error *ngIf="addlocationForm.get('zip').invalid && addlocationForm.get('zip').touched">
                  <span *ngIf="addlocationForm.get('zip').errors.required">Zip is required!!</span>
                </mat-error>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Country*</p>
            <p class="dValue">
              <mat-form-field  >
                <mat-select formControlName="country">
                  <mat-option value="India">
                    India
                  </mat-option>
                  <mat-option value="USA">
                    USA
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addlocationForm.get('country').invalid && addlocationForm.get('country').touched">
                  <span *ngIf="addlocationForm.get('country').errors.required">Country is required!!</span>
                </mat-error>
              </mat-form-field>
            </p>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div fxFlex="20">
            <p class="dLabel">Address Remarks</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="address_marks">
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Phone 1 (Internal Use)*</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="phone" >
                <mat-error *ngIf="addlocationForm.get('phone').invalid && addlocationForm.get('phone').touched">
                  <span *ngIf="addlocationForm.get('phone').errors.required">Phone 1 is required!!</span>
                </mat-error>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Phone 2 (Internal Use)</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput  formControlName="phone_second">
               
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Phone (Client Use)</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="phone_client_use" >
              </mat-form-field>
            </p>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div fxFlex="20">
            <p class="dLabel">Fax</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="fax" >
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Hours of Operation</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="hours_of_operation" >
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Hours of Operation Remarks</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="hours_of_operation_marks" >
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Center E-mail (internal)*</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput type="email" formControlName="center_email">
                <mat-error *ngIf="addlocationForm.get('center_email').hasError('required')">
                  Center E-mail is required
                </mat-error>
                <mat-error *ngIf="!addlocationForm.get('center_email').hasError('required') &&
                                addlocationForm.get('center_email').hasError('email')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </p>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div fxFlex="20">
            <p class="dLabel">Representatives</p>
            <p class="dValue">
              <mat-form-field  >
                <input matInput formControlName="representatives">
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Notary</p>
            <p class="dValue">
              <mat-form-field  >
                <mat-select formControlName="notary">
                  <mat-option value="1">
                    Available
                  </mat-option>
                  <mat-option value="0">
                    Not Available
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">Status</p>
            <p class="dValue">
              <mat-form-field  >
                <mat-select formControlName="status">
                  <mat-option [value]="'1'">
                    Active
                  </mat-option>
                  <mat-option [value]="'0'">
                    Inactive
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>

          <div fxFlex="20">
            <p class="dLabel">COA Kits</p>
            <p class="dValue">
              <mat-form-field  >
                <mat-select formControlName="coa_kits">
                  <mat-option value="1">
                    Available
                  </mat-option>
                  <mat-option value="0">
                    Not Available
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div fxFlex="100">
            <p class="dLabel">Notes</p>
            <p class="dValue">
              <mat-form-field  >
                <textarea matInput formControlName="notes">
                        </textarea>
              </mat-form-field>
            </p>
          </div>
        </div>

        <p class="dLabel">Location Images</p>
        <p class="dValue">
          <button mat-raised-button (click)="onFileUpload()">Choose File</button>
          <input type="file" id="docUpload" name="docUpload" style="display:none;" />
        </p>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
          <span style="color: green;">{{fileSuccess}}</span>
        </div>
        <div class="box-blank" *ngIf="uploaded_files_array.length>0 ">
          <div *ngFor="let res of uploaded_files_array">
            <p *ngFor="let res1 of res">
              {{res1.name}}
            </p>
          </div>
        </div>
      </div>
    </form>
    <div class="actWraper">
      <button  mat-raised-button class="green-bg mr-8" [disabled]="addlocationForm.invalid"
        (click)="submit()">
        <mat-label class="Text_Color">{{type}}</mat-label>
      </button>
    </div>
  </div>
  <div class="white-box" *ngIf="paginate?.total>0">
    <div class="tHeader">
      <h4>Locations</h4>
    </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Location Title </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
      </ng-container>

      <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef> Location City </th>
          <td mat-cell *matCellDef="let element"> {{element.city}} </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef> Location State </th>
            <td mat-cell *matCellDef="let element"> {{element.state}} </td>
          </ng-container>

          <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef> Location Country </th>
              <td mat-cell *matCellDef="let element"> {{element.country}} </td>
            </ng-container>
      <ng-container matColumnDef="complete_address">
        <th mat-header-cell *matHeaderCellDef> Location Address </th>
        <td mat-cell *matCellDef="let element"> {{element.complete_address}} </td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element; let i=index"> <button mat-stroked-button class="bicon" title="Edit"
            (click)="editData(i)"><i class="material-icons">
              edit
            </i></button> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
      [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[10,20,30,40]"
      (page)='paginateData($event)'>
    </mat-paginator>
    
  </div>
  <div *ngIf="paginate?.total==0" class="noDataFound">
    No Data available!!
  </div>
</div>