<div class="page-layout w100 p-24 bg-white" fusePerfectScrollbar>
  <div class="container" fxLayout="row wrap" fxLayoutGap="8px">
    <!-- <div fxFlex="24">
      <div class="white-box plainBox">
        <div class="tHeader">
          <div fxFlex="50">
            <h2 class="mt-1"><i class="material-icons">filter_list</i> Apply Filter</h2>
          </div>
          <div fxFlex="50" class="text-right">
            <button mat-raised-button color="accent" (click)="clearLocalStorage()"
              class="mr-8 text-right">Clear</button>
          </div>
        </div>  <div class="boxBody">
          <div class="boxBodyInn">
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="79">
                <mat-form-field>
                  <mat-label>Search by ID/Name/Email/Phone</mat-label>
                  <input [(ngModel)]="fdata.Search" [ngModelOptions]="{standalone: true}" matInput
                    (keyup)="onFilter($event.target.value,'search')">
                </mat-form-field>
              </div>
            </div>
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Category Level is</b> </div>
              </div>
              <div fxFlex="69" style="border-bottom: 1px solid #959393;">
                <mat-select placeholder="Select Field" [formControl]="toppings"
                  (selectionChange)="onFilter($event,'multi_category_level')" multiple>
                  <mat-option *ngFor="let filters of toppingList" [value]="filters">{{filters}}</mat-option>
                </mat-select>
              </div>
            </div>

          </div>
        </div> 
      </div>
    </div> -->

    <div fxFlex="100">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap">
            <div fxFlex>
              <h2>Closed and Blacklisted Translator</h2>
            </div>

            <div fxFlex class="text-right">
              <!-- <button mat-raised-button color="accent" [disabled]="login_enable" class="mr-8 text-right"
                (click)="openDialogAddTranslator()"><i class="material-icons">
                  group
                </i>Send Email</button> -->
              Total <b>{{total_freelancer}}</b> Records Available
            </div>
          </div>
          <div class="filterTag pt-12">

            <mat-chip-list #chipList aria-label="QuotesChip selection">
              <mat-chip *ngFor="let QuotesChip of fChipsArray" [selectable]="selectable" [removable]="removable">
                {{QuotesChip}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>

        <div class="boxBody">
          <div class="boxInn">
            <div *ngIf="paginate?.total != 0">
              <table mat-table [dataSource]="dataSource" class="table" (matSortChange)="sortUser($event)">
                <ng-container matColumnDef="ref">
                  <mat-header-cell *matHeaderCellDef fxFlex="5">
                    <mat-checkbox [(ngModel)]="bulk_selected_progress" (change)="selectBulkProgress($event)">
                    </mat-checkbox>
                  </mat-header-cell>
                  <!-- <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="5">
                    <mat-checkbox [(ngModel)]="product.check" (change)="selectMailProgress($event,product)">
                    </mat-checkbox>
                  </mat-cell> -->
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="first_name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20"> Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                    <div fxLayout="column">
                      <div fxFlex>
                        <button mat-button class="btn-link" routerLink="/pms/freelancer/details/{{product.id}}">
                          <p class="text-truncate">{{product.first_name}} {{product.last_name}}</p>
                        </button>
                      </div>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="frlcrEmail">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="25"> Email
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="25">
                    <p class="text-truncate">{{product.email}}</p>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="native_language">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="15"> Native Language
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="15">
                    <span *ngIf="product.vendornativelanguage!=null" class="text-truncate">
                      {{product.vendornativelanguage.data.lang_name}}
                    </span>
                    <span *ngIf="product.vendornativelanguage==null">
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="language">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20"> Language Pair
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                    <span class="price text-truncate">
                      <span *ngFor="let res of product.lang_array">
                        <p class="text-truncate">{{res}}</p>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="category_level">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> Category Level
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                    <span class="text-truncate">
                      {{product.category_level}}
                    </span>
                  </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="ApprovaldisplayedColumns; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let product; columns: ApprovaldisplayedColumns;" class="product fList">
                </mat-row>
              </table>
              <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
                [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
                [pageSizeOptions]="[20,30,40,50,60]" (page)='paginateData($event)'>
              </mat-paginator>
            </div>
            <div *ngIf="paginate?.total==0" class="noDataFound">
              <p> No Data available!!</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>