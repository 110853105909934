<div class="page-layout profilePage p-20 blank bg-white">
  <div class="white-box">
    <div class="winn">
      <div class="wraperBox">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="space-between center" fxLayoutGap="10px">
          <h2 class="ml-24 mr-16"><i class="material-icons"> filter_list </i></h2>
          <mat-form-field fxFlex="15">
            <mat-label class="font-size-12">Category Level</mat-label>
            <mat-select [(ngModel)]="fdata.category" [ngModelOptions]="{standalone: true}"
              placeholder="Select category level" (selectionChange)="onFilter($event,'categoryLevel')">
              <mat-option value="">Any</mat-option>
              <mat-option value="Closed">Closed</mat-option>
              <mat-option value="Blacklisted"> Blacklisted</mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field fxFlex="15" class="ml-8">
            <mat-label class="font-size-12">Native Language</mat-label>
            <mat-select [(ngModel)]="fdata.Native" [ngModelOptions]="{standalone: true}"
              (selectionChange)="onFilter($event,'native_lang')">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let language of languageResponse" [value]="language.id">
                {{language.lang_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="15" class="ml-8">
            <mat-label>Source Language</mat-label>
            <mat-select [(ngModel)]="fdata.Source" [ngModelOptions]="{standalone: true}"
              (selectionChange)="onFilter($event,'s_lang')">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let language of languageResponse" [value]="language.lang_name">
                {{language.lang_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="15" class="ml-8">
            <mat-label>Target Language</mat-label>
            <mat-select [(ngModel)]="fdata.Target" [ngModelOptions]="{standalone: true}"
              (selectionChange)="onFilter($event,'t_lang')">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let language of languageResponse" [value]="language.lang_name">
                {{language.lang_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field   fxFlex="15" class="ml-8">
                      <mat-label>Speciality Fields</mat-label>
                      <mat-select (selectionChange)="onFilter($event,'speciality')">
                          <mat-option [value]="''">
                              Select
                          </mat-option>
                          <mat-option *ngFor="let speciality of specialityResponse" [value]="speciality.name">
                              {{speciality.name}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field> -->
          <!-- <button mat-raised-button color="accent" [disabled]="login_enable" class="mr-8" *ngIf="StageID==6"
                      (click)="Send_mail()"><i class="material-icons">
                          group
                      </i>Send Login</button> -->
          <div class="search-wrapper mx-32 mx-md-0" fxFlex="15">
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="mt-12">search</mat-icon>
              <input placeholder="Search" (keyup)="applyFilterProgress($event.target.value)">
            </div>
          </div>
        </div>
      </div>
      <div class="content-card" *ngIf="paginate1.total>0">
        <mat-table class="products-table" #table [dataSource]="dataSource1" matSort fusePerfectScrollbar>
          <h6 class="ml-4">Showing</h6>
          <ng-container matColumnDef="ref">
            <mat-header-cell *matHeaderCellDef fxFlex="5">
              <mat-checkbox [(ngModel)]="bulk_selected_progress" *ngIf="StageID==10"
                (change)="selectBulkProgress($event)"></mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="5">
              <mat-checkbox [(ngModel)]="product.check" (change)="selectMailProgress($event,product)"
                *ngIf="StageID==10">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="first_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20">Name
            </mat-header-cell>
            <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
              <div fxLayout="column" class="pt-16">
                <div fxFlex>
                  <a class="btn-link" routerLink="/hr/translator/review/{{ product.id }}">{{product.first_name}}
                    {{product.last_name}}</a>
                </div>
                <div class="navigat pt-8" fxFlex>
                  <span class="pr-8">
                    <button mat-button (click)="editData(product)" class="btn-link br-1">
                      Edit
                    </button>
                  </span>
                  <!-- <span class="bdrl1" *ngIf="StageID==6">
                                  <button mat-button (click)="Send_mail_single(product)" class="btn-link">
                                      Send Login 
                                  </button></span> -->
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="category_level">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="8">Category Level
            </mat-header-cell>
            <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
              <span *ngIf="product.category_level!=null">
                {{product?.category_level}}
              </span>
              <span *ngIf="product.category_level==null">
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="native_language">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="8">Native Language
            </mat-header-cell>
            <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
              <span *ngIf="product.vendornativelanguage!=null">
                {{product.vendornativelanguage.data.lang_name}}
              </span>
              <span *ngIf="product.vendornativelanguage==null">
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md fxFlex="24">Language Pair
            </mat-header-cell>
            <mat-cell *matCellDef="let product" fxHide fxShow.gt-md fxFlex="24">
              <span class="price text-truncate">
                <span *ngFor="let res of product.lang_array">
                  <p>{{res}}</p>
                </span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs fxFlex="20">Email</mat-header-cell>
            <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="20">
              <span>
                {{product?.email}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="stage">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="8">
              Status</mat-header-cell>
            <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="8">
              <span class="bdg ylbg" *ngIf="product.stage!=null">
                {{product.stage.data.name}}
              </span>
              <span class="bdg ylbg" *ngIf="product.stage==null">
              </span>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
          <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
          </mat-row>
        </mat-table>
        <mat-paginator *ngIf="paginate1?.total != 0" [length]="paginate1?.total"
          [pageIndex]="paginate1?.current_page - 1" [pageSize]="paginate1?.per_page" [pageSizeOptions]="[10,20,30,40]"
          (page)='paginateData($event)'>
        </mat-paginator>
      </div>
    </div>
  </div>
  <div *ngIf="paginate1.total==0" class="noDataFound"> No data available!!
  </div>
</div>