import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { environment } from 'environments/environment';
import { MatDialogRef } from '@angular/material';
var AddTempTranslatorComponent = /** @class */ (function () {
    function AddTempTranslatorComponent(fb, dialogRef, projectsService, router, vendorService, route, toastr, location) {
        this.fb = fb;
        this.dialogRef = dialogRef;
        this.projectsService = projectsService;
        this.router = router;
        this.vendorService = vendorService;
        this.route = route;
        this.toastr = toastr;
        this.location = location;
        this.type = 'Add';
        this.table_data = [];
        this.final_table_data = [];
        this.lang_name_update = [];
        this.today = new Date();
        this.minDate = this.today;
        this.bulk_selected = false;
        this.displayedColumns = ['langPair', 'translationRate', 'proofindRate', 'dtpRate', 'qaRate', 'speed', 'actions'];
        this.languageData = [];
        this.speciality_new_ids = [];
        this.speciality_new_data = [];
        this.specialityData = [];
        this.language = [];
        this.fileError = '';
        this.file_multiple_data = [];
        this.file_id_array = [];
        this.uploaded_files_array = [];
        this.selectedItems = [];
        // Coa start
        this.file_multiple_data1 = [];
        this.fileError1 = '';
        this.uploaded_coa_array = [];
        this.coa_id_array = [];
        //Coa end
        this.data = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            translation_rate: '',
            proofreading_rate: '',
            dtp_rate: '',
            qa_rate: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
        };
        this.coa_array = [];
        this.selected_tools = [];
        this.selected_days = [];
        this.final_data = {};
        this.checked = true;
        this.selected_skills = [];
        this.dropdownSettings = {};
        this.dropdownList = [];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    AddTempTranslatorComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.params['id'];
        if (this.id) {
            this.addTranslatorForm = this.fb.group({
                first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                email: ['', [Validators.required, Validators.email]],
            });
            this.getTranslatorData();
            this.getLanguage();
            this.getSpeciality();
            this.getTranslatorStatus();
        }
        else {
            this.getLanguage();
            this.getSpeciality();
            // this.country_code=Dial.response;
            this.addTranslatorForm = this.fb.group({
                first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
                email: ['', [Validators.required, Validators.email]],
            });
        }
        this.dropdownSettings = {
            singleSelection: false,
            textId: 'id',
            textField: 'name',
            allowSearchFilter: true
        };
    };
    Object.defineProperty(AddTempTranslatorComponent.prototype, "f", {
        get: function () {
            return this.addTranslatorForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddTempTranslatorComponent.prototype.submit = function () {
        if (this.type == 'Add') {
            this.addData();
        }
        else {
            this.updateData();
        }
    };
    AddTempTranslatorComponent.prototype.getTranslatorData = function () {
        var _this = this;
        this.coa_array = [];
        this.table_data = [];
        this.speciality_new_ids = [];
        this.coa_id_array = [];
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = [];
                _this.getSpeciality();
                _this.type = 'Update';
                _this.stage_id = response.data.stage_id;
                if (response.data.tool_names) {
                    _this.selected_tools = response.data.tool_names.split(',');
                }
                response.data.vendorskill.data.forEach(function (element) {
                    _this.selected_skills.push(element.skill_id);
                });
                response.data.vendortranslationlangs.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    _this.table_data.push(element);
                });
                _this.dataSource = new MatTableDataSource(_this.table_data);
                response.data.vendoravailabilities.data.forEach(function (element) {
                    _this.selected_days.push(element.day_name);
                });
                response.data.vendorspecialities.data.forEach(function (element) {
                    _this.speciality_new_ids.push(element.specialities.data);
                });
                if (response.data.vendorresume.data.length != 0) {
                    response.data.vendorresume.data.forEach(function (element) {
                        _this.vendor_resume_data = _this.BaseUrl_file + element.path;
                        var resume_name = element.path.split('/');
                        _this.vendor_resume_name = resume_name[2];
                    });
                }
                if (response.data.vendorcoafiles.data.length != 0) {
                    response.data.vendorcoafiles.data.forEach(function (element) {
                        _this.coa_url = _this.BaseUrl_file + element.coafile.data.path;
                        var coa_name = element.coafile.data.path.split('/');
                        _this.coa_name = coa_name[2];
                        var coa_obj = {
                            id: element.id,
                            url: _this.coa_url,
                            name: _this.coa_name,
                            coa_file_id: element.coa_file_id
                        };
                        // this.coa_id_array()
                        _this.coa_id_array.push(element.coa_file_id);
                        _this.coa_array.push(coa_obj);
                    });
                }
                _this.addTranslatorForm.patchValue({
                    address1: response.data.address1,
                    email: response.data.email,
                    email_sec: response.data.email_sec,
                    city: response.data.city,
                    country: response.data.country,
                    cover_letter: response.data.cover_letter,
                    education: response.data.education,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    middle_name: response.data.middle_name,
                    native_language_id: response.data.native_language_id,
                    payment_method: response.data.payment_method,
                    payment_method_id: response.data.payment_method_id,
                    phone: response.data.phone,
                    phone_sec: response.data.phone_sec,
                    postal_code: response.data.postal_code,
                    skype_id: response.data.skype_id,
                    state: response.data.state,
                    title: response.data.title,
                    specialities: _this.speciality_new_ids,
                    important_note: response.data.important_note,
                    category_level: response.data.category_level,
                    on_boarding_date: response.data.on_boarding_date
                });
                if (response.data.translation_tools != null) {
                    _this.addTranslatorForm.patchValue({
                        translation_tools: response.data.translation_tools.toString(),
                    });
                }
                if (response.data.tool_licence != null) {
                    _this.addTranslatorForm.patchValue({
                        tool_licence: response.data.tool_licence.toString(),
                    });
                }
            }
        });
    };
    AddTempTranslatorComponent.prototype.edit_getTranslatorData = function () {
        var _this = this;
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = [];
                _this.type = 'Update';
                response.data.vendortranslationlangs.data.forEach(function (element) {
                    if (element.bi_direction == 2) {
                        element.bi_direction = '<>';
                    }
                    else if (element.bi_direction == 1 || element.bi_direction == 0) {
                        element.bi_direction = '>';
                    }
                    _this.table_data.push(element);
                });
                _this.dataSource = new MatTableDataSource(_this.table_data);
            }
        });
    };
    AddTempTranslatorComponent.prototype.editData = function (value) {
        this.data.source_lang_id = value.sourcelanguage.data.id;
        this.data.target_lang_id = value.targetlanguage.data.id;
        this.data.translation_rate = value.translation_rate;
        this.data.proofreading_rate = value.proofreading_rate;
        this.data.dtp_rate = value.dtp_rate;
        this.data.qa_rate = value.qa_rate;
        this.data.speed = value.speed;
        this.lang_combination_id = value.id;
        if (value.bi_direction == '>') {
            this.data.bi_direction = false;
        }
        else {
            this.data.bi_direction = true;
        }
    };
    AddTempTranslatorComponent.prototype.updateData = function () {
        var _this = this;
        this.final_data = {};
        this.final_data = this.addTranslatorForm.value;
        this.final_data.translation_tools = this.addTranslatorForm.value.translation_tools;
        this.final_data.on_boarding_date = this.dateFormat(this.addTranslatorForm.value.on_boarding_date);
        this.final_data.specialities = this.getSpecialityId(this.addTranslatorForm.value.specialities);
        if (this.selected_tools.length > 0) {
            this.final_data.tool_names = this.selected_tools.toString();
        }
        else {
            this.final_data.tool_names = this.selected_tools;
        }
        this.final_data.vendor_skill = this.selected_skills;
        if (this.selected_days.length > 0) {
            this.final_data.availability = this.selected_days.toString();
        }
        else {
            this.final_data.availability = this.selected_days;
        }
        this.final_data.vendor_skill = this.selected_skills;
        if (this.file_id_array.length > 0) {
            this.final_data.resume_id = this.file_id_array;
        }
        if (this.coa_id_array.length > 0) {
            this.final_data.coa_files = this.coa_id_array;
        }
        else {
            this.final_data.coa_files = [];
        }
        this.final_data.file_id = [];
        this.final_data.stage_id = this.stage_id;
        this.vendorService.updateTranslator(this.id, this.final_data).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success('Translator updated successfully');
                _this.location.back();
            }
        });
    };
    // date format
    AddTempTranslatorComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt;
    };
    AddTempTranslatorComponent.prototype.getSourceName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.data.sourcelanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTempTranslatorComponent.prototype.getTargetName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.data.targetlanguage.data.lang_name = element.lang_name;
            }
        });
    };
    AddTempTranslatorComponent.prototype.addLanguageTable = function () {
        var bi_direction;
        this.dataSource = [];
        if (this.data.bi_direction == false) {
            bi_direction = '>';
            this.data.bi_direction = bi_direction;
        }
        else {
            bi_direction = '<>';
            this.data.bi_direction = bi_direction;
        }
        if (this.data.sourcelanguage.data.lang_name == '') {
            this.toastr.error('please select source language');
            return false;
        }
        if (this.data.targetlanguage.data.lang_name != '' && this.data.sourcelanguage.data.lang_name != '') {
            if (this.data.sourcelanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
                this.toastr.error('please select unique language pair !!');
                return false;
            }
        }
        if (this.data.targetlanguage.data.lang_name == '') {
            this.toastr.error('please select target language');
            return false;
        }
        if (this.data.translation_rate == '') {
            this.toastr.error('please select translation rate');
            return false;
        }
        if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
            this.toastr.error('Translation rate never be negative or zero');
            return false;
        }
        if (this.data.proofreading_rate == '') {
            this.toastr.error('please select proofreading rate');
            return false;
        }
        if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
            this.toastr.error('Proofreading rate never be negative or zero');
            return false;
        }
        // if (this.data.dtp_rate == '') {
        //   this.toastr.error('please select Dtp rate');
        //   return false;
        // }
        // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
        //   this.toastr.error('Dtp rate never be negative or zero');
        //   return false;
        // }
        // if (this.data.qa_rate == '') {
        //   this.toastr.error('please select Dtp rate');
        //   return false;
        // }
        // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
        //   this.toastr.error('Qa rate never be negative or zero');
        //   return false;
        // }
        if (this.data.speed == '') {
            this.toastr.error('please select speed');
            return false;
        }
        this.language.push(this.data);
        this.dataSource = new MatTableDataSource(this.language);
        this.data = {
            source_lang_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            target_lang_name: '',
            translation_rate: '',
            dtp_rate: '',
            proofreading_rate: '',
            qa_rate: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
        };
    };
    AddTempTranslatorComponent.prototype.addLanguageTableApi = function () {
        var _this = this;
        if (this.lang_combination_id) {
            this.table_data = [];
            if (this.data) {
                this.vendorService.updateLanguage(this.lang_combination_id, this.data)
                    .subscribe(function (response) {
                    if (response) {
                        _this.lang_combination_id = null;
                        _this.edit_getTranslatorData();
                        _this.data = {
                            source_lang_id: '',
                            sourcelanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            targetlanguage: {
                                data: {
                                    lang_name: ''
                                }
                            },
                            target_lang_id: '',
                            target_lang_name: '',
                            translation_rate: '',
                            proofreading_rate: '',
                            qa_rate: '',
                            dtp_rate: '',
                            speed: '',
                            bi_direction: false,
                            bi_direction_test: ''
                        };
                    }
                }, function (error) {
                    if (error) {
                        _this.toastr.error('Please first select all fields.');
                    }
                });
            }
        }
        else {
            this.table_data = [];
            if (this.data.sourcelanguage.data.lang_name == '') {
                this.toastr.error('please select source language');
                return false;
            }
            if (this.data.targetlanguage.data.lang_name == '') {
                this.toastr.error('please select target language');
                return false;
            }
            if (this.data.translation_rate == '') {
                this.toastr.error('please select translation rate');
                return false;
            }
            if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
                this.toastr.error('Translation rate never be negative or zero');
                return false;
            }
            if (this.data.proofreading_rate == '') {
                this.toastr.error('please select proofreading rate');
                return false;
            }
            if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
                this.toastr.error('Proofreading rate never be negative or zero');
                return false;
            }
            // if (this.data.dtp_rate == '') {
            //   this.toastr.error('please select Dtp rate');
            //   return false;
            // }
            // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
            //   this.toastr.error('Dtp rate never be negative or zero');
            //   return false;
            // }
            // if (this.data.qa_rate == '') {
            //   this.toastr.error('please select Dtp rate');
            //   return false;
            // }
            // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
            //   this.toastr.error('Qa rate never be negative or zero');
            //   return false;
            // }
            if (this.data.speed == '') {
                this.toastr.error('please select speed');
                return false;
            }
            if (this.data.speed < 0 || this.data.speed == 0) {
                this.toastr.error('Speed never be negative or zero');
                return false;
            }
            this.data.vendor_id = this.id;
            this.data.table_data = this.table_data;
            this.vendorService.addLanguage_edit(this.data)
                .subscribe(function (response) {
                if (response) {
                    _this.edit_getTranslatorData();
                    _this.data = {
                        source_lang_id: '',
                        sourcelanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        targetlanguage: {
                            data: {
                                lang_name: ''
                            }
                        },
                        target_lang_id: '',
                        target_lang_name: '',
                        translation_rate: '',
                        proofreading_rate: '',
                        dtp_rate: '',
                        qa_rate: '',
                        speed: '',
                        bi_direction: false,
                        bi_direction_test: ''
                    };
                }
            });
        }
    };
    AddTempTranslatorComponent.prototype.deleteData = function (obj, index) {
        var _this = this;
        if (this.id) {
            this.vendorService.deleteVendorLanguage(obj.id)
                .subscribe(function (response) {
                _this.toastr.success("delete successfully");
                _this.getTranslatorData();
                _this.data = {
                    source_lang_id: '',
                    sourcelanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    targetlanguage: {
                        data: {
                            lang_name: ''
                        }
                    },
                    target_lang_id: '',
                    target_lang_name: '',
                    translation_rate: '',
                    proofreading_rate: '',
                    qa_rate: '',
                    dtp_rate: '',
                    speed: '',
                    bi_direction: false,
                    bi_direction_test: ''
                };
            });
        }
        else {
            this.language.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.language);
        }
    };
    AddTempTranslatorComponent.prototype.tools = function (evt) {
        if (evt.checked && this.selected_tools.indexOf(evt.source.value == -1)) {
            this.selected_tools.push(evt.source.value);
        }
        else if (!evt.checked && this.selected_tools.indexOf(evt.source.value != -1)) {
            var index = this.selected_tools.indexOf(evt.source.value);
            this.selected_tools.splice(index, 1);
        }
    };
    AddTempTranslatorComponent.prototype.Available_days = function (evt) {
        if (evt.checked && this.selected_days.indexOf(evt.source.value == -1)) {
            this.selected_days.push(evt.source.value);
        }
        else if (!evt.checked && this.selected_days.indexOf(evt.source.value != -1)) {
            var index = this.selected_days.indexOf(evt.source.value);
            this.selected_days.splice(index, 1);
        }
    };
    AddTempTranslatorComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res;
            }
        });
    };
    AddTempTranslatorComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                _this.specialityData = res;
                _this.dropdownList = res;
            }
        });
    };
    AddTempTranslatorComponent.prototype.getTranslatorStatus = function () {
        var _this = this;
        this.vendorService.getTranslatorStatus(this.id)
            .subscribe(function (res) {
            if (res) {
                _this.addTranslatorForm.patchValue({
                    is_active: res.user_detail.status.toString()
                });
            }
        });
    };
    AddTempTranslatorComponent.prototype.addData = function () {
        var _this = this;
        this.final_data = {};
        this.final_data = this.addTranslatorForm.value;
        this.final_data.language = this.language;
        if (this.language.length == 0) {
            this.toastr.error("Please add language pair!!");
            return;
        }
        this.projectsService.add(this.final_data).subscribe(function (resposne) {
            if (resposne) {
                if (resposne.status == 409) {
                    _this.toastr.error(resposne.message);
                }
                else {
                    _this.toastr.success(resposne.message);
                    _this.Onclose();
                }
            }
        });
    };
    AddTempTranslatorComponent.prototype.Onclose = function () {
        this.dialogRef.close(true);
    };
    AddTempTranslatorComponent.prototype.getSpecialityId = function (res) {
        if (res.constructor === String) {
            return res;
        }
        else if (res.length > 0 && res.constructor === Array) {
            var id_1 = '';
            res.forEach(function (element) {
                id_1 += element.id + ', ';
            });
            id_1 = id_1.slice(0, -2);
            return id_1;
        }
    };
    AddTempTranslatorComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return false;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    AddTempTranslatorComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        this.uploaded_files_array = [];
        this.file_id_array = [];
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files_array.push(response.data);
            response.data.forEach(function (element) {
                _this.file_id_array.push(element.id);
            });
            _this.fileSuccess = 'Uploaded successfully!!';
        });
    };
    // COA FILE UPLOAD
    AddTempTranslatorComponent.prototype.onCoaUpload = function () {
        var _this = this;
        this.file_multiple_data1 = [];
        var fileUpload = document.getElementById('coaUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError1 = 'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data1.push(file);
                }
                else {
                    _this.fileError1 = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addCoa(_this.file_multiple_data1);
        };
        fileUpload.click();
    };
    // addCoa
    AddTempTranslatorComponent.prototype.addCoa = function (file) {
        var _this = this;
        this.fileSuccess1 = '';
        // this.uploaded_coa_array = [];
        // this.coa_id_array = [];
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            response.data.forEach(function (element) {
                if (_this.id) {
                    _this.coa_array.push(element);
                }
                else {
                    _this.uploaded_coa_array.push(element);
                }
                _this.coa_id_array.push(element.id);
            });
            if (_this.id) {
                _this.vendorCoaFile(_this.coa_id_array);
            }
            _this.fileSuccess1 = 'Uploaded successfully!!';
        });
    };
    AddTempTranslatorComponent.prototype.vendorCoaFile = function (coaFileId) {
        var _this = this;
        var obj = {
            coa_files: coaFileId
        };
        this.vendorService.VendorsCoaFile(this.id, obj)
            .subscribe(function (response) {
            _this.getTranslatorData();
        });
    };
    AddTempTranslatorComponent.prototype.deleteCoa = function (id) {
        var coa_array_index = this.uploaded_coa_array.findIndex(function (x) { return x.id === id; });
        if (coa_array_index !== -1) {
            this.uploaded_coa_array.splice(coa_array_index, 1);
        }
        var coa_id_index = this.coa_id_array.findIndex(function (x) { return x === id; });
        if (coa_id_index !== -1) {
            this.coa_id_array.splice(coa_id_index, 1);
        }
    };
    AddTempTranslatorComponent.prototype.deleteCoaEdit = function (obj) {
        var _this = this;
        this.vendorService.deleteCoaFile(obj.id)
            .subscribe(function (response) {
            _this.toastr.success("delete successfully");
            _this.deleteCoaEditArray(obj);
        });
    };
    AddTempTranslatorComponent.prototype.deleteCoaEditArray = function (obj) {
        var coa_array_index = this.coa_array.findIndex(function (x) { return x.id === obj.id; });
        if (coa_array_index !== -1) {
            this.coa_array.splice(coa_array_index, 1);
        }
        var coa_id_index = this.coa_id_array.findIndex(function (x) { return x === obj.coa_file_id; });
        if (coa_id_index !== -1) {
            this.coa_id_array.splice(coa_id_index, 1);
        }
    };
    // COA END
    AddTempTranslatorComponent.prototype.back = function () {
        this.location.back();
    };
    AddTempTranslatorComponent.prototype.Reset = function () {
        this.addTranslatorForm.reset();
        this.location.back();
    };
    AddTempTranslatorComponent.prototype.Skills = function (evt) {
        if (evt.checked && this.selected_skills.indexOf(evt.source.value == -1)) {
            this.selected_skills.push(evt.source.value);
        }
        else if (!evt.checked && this.selected_skills.indexOf(evt.source.value != -1)) {
            var index = this.selected_skills.indexOf(evt.source.value);
            this.selected_skills.splice(index, 1);
        }
    };
    AddTempTranslatorComponent.prototype.onItemSelect = function (item) {
    };
    AddTempTranslatorComponent.prototype.onSelectAll = function (items) {
    };
    AddTempTranslatorComponent.prototype.onItemDeSelect = function (items) {
    };
    AddTempTranslatorComponent.prototype.onDeSelectAll = function (items) {
    };
    return AddTempTranslatorComponent;
}());
export { AddTempTranslatorComponent };
export var confirmPasswordValidator = function (control) {
    if (!control.parent || !control) {
        return null;
    }
    var password = control.parent.get('password');
    var passwordConfirm = control.parent.get('confirm_password');
    if (!password || !passwordConfirm) {
        return null;
    }
    if (passwordConfirm.value === '') {
        return null;
    }
    if (password.value === passwordConfirm.value) {
        return null;
    }
    return { passwordsNotMatching: true };
};
