import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from 'app/main/shared.service';
import { Location } from '@angular/common';
var ReviewTranslatorComponent = /** @class */ (function () {
    function ReviewTranslatorComponent(router, route, vendorService, toastr, _fuseNavigationService, _formBuilder, _location) {
        this.router = router;
        this.route = route;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this._fuseNavigationService = _fuseNavigationService;
        this._formBuilder = _formBuilder;
        this._location = _location;
        this.file_data = [];
        this.bulk_selected_progress = false;
        this.file_id_data = [];
        this.data_update = {};
        this.comment_data_update = {};
        this.comment_data_approve = {};
        this.comment_data_reject = {};
        this.special_comment_data = {};
        this.stageData = [];
        this.age = '';
        this.coa_array = [];
        this.data_response = {
            title: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            is_new: '',
            stage: '',
            address1: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
            email: '',
            skype_id: '',
            phone: '',
            translation_tools: '',
            tool_licence: '',
            payment_method: '',
            comment_orientation: '',
            cover_letter: '',
            education: '',
            comment_quiz: '',
            sms_notification: '',
            wtsp_notification: '',
            vendornativelanguage: {
                data: []
            },
            vendortranslationlangs: {
                data: []
            },
            vendorSubtitler: {
                data: []
            },
            vendoravailabilities: {
                data: []
            },
            vendorspecialities: {
                data: []
            },
        };
        this.BaseUrl_file = environment.baseUrl_file;
    }
    ReviewTranslatorComponent.prototype.ngOnInit = function () {
        this.id = parseInt(this.route.snapshot.params['id']);
        this.user_id = SharedService.get('id');
        // this.getStage();
        this.getApproval();
        this.getTranslatorStatus();
        this.getVendorDoc();
        this.Form = this._formBuilder.group({
            comment: [''],
            stage_id: [''],
            comment_quiz: [''],
            comment_orientation: ['']
        });
    };
    ReviewTranslatorComponent.prototype.getTranslatorStatus = function () {
        var _this = this;
        this.vendorService.getTranslatorStatus(this.id)
            .subscribe(function (res) {
            if (res) {
                _this.is_active = res.user_detail.status;
            }
        });
    };
    ReviewTranslatorComponent.prototype.submit = function () {
        var _this = this;
        if (this.Form.value.comment == '') {
            this.comment_data_update.stage_id = this.Form.value.stage_id;
            this.comment_data_update.comment_quiz = this.Form.value.comment_quiz;
            this.comment_data_update.comment_orientation = this.Form.value.comment_orientation;
            this.vendorService.update(this.id, this.comment_data_update)
                .subscribe(function (response) {
                if (response) {
                    _this.data_update = response;
                    _this.getApproval();
                    _this.toastr.success('Updated');
                }
            });
        }
        else {
            if (this.Form.value.comment == null) {
                this.toastr.error('Special Comment required!!');
                return;
            }
            this.special_comment_data.comment = this.Form.value.comment;
            this.special_comment_data.vendor_id = this.id;
            this.special_comment_data.user_id = this.user_id;
            this.vendorService.addComment(this.special_comment_data)
                .subscribe(function (response) {
                if (response) { }
            }, function (error) {
                _this.toastr.error(error);
            });
            this.comment_data_update.stage_id = this.Form.value.stage_id;
            this.comment_data_update.comment_quiz = this.Form.value.comment_quiz;
            this.comment_data_update.comment_orientation = this.Form.value.comment_orientation;
            this.vendorService.update(this.id, this.comment_data_update)
                .subscribe(function (response) {
                if (response) {
                    _this.data_update = response;
                    _this.getApproval();
                    _this.toastr.success('Updated');
                }
            });
        }
    };
    ReviewTranslatorComponent.prototype.downloadVendorZip = function () {
        var _this = this;
        var data = {
            vendor_doc_uuid: this.file_id_data,
            vendor_ref: this.reference_id
        };
        this.vendorService.downloadVendorZip(data).subscribe(function (resposne) {
            if (resposne) {
                _this.file_d = resposne.url;
            }
        });
    };
    ReviewTranslatorComponent.prototype.getVendorDoc = function () {
        var _this = this;
        this.vendorService.getVendorDoc(this.id)
            .subscribe(function (res) {
            if (res) {
                _this.file_data = res.vendor_docs;
            }
        });
    };
    ReviewTranslatorComponent.prototype.selectFile = function (evt, res) {
        if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
            this.file_id_data.push(res.uuid);
        }
        else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
            var index = this.file_id_data.indexOf(res.uuid);
            this.file_id_data.splice(index, 1);
        }
        this.downloadVendorZip();
    };
    ReviewTranslatorComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        this.file_id_data = [];
        var check = false;
        this.file_data.forEach(function (element, key) {
            element["check"] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.file_id_data.push(element.uuid);
            }
            else {
                _this.file_id_data = [];
            }
        });
        this.downloadVendorZip();
        if (check) {
            this.bulk_selected_progress = true;
        }
    };
    ReviewTranslatorComponent.prototype.UncheckedFile = function () {
        var _this = this;
        this.bulk_selected_progress = false;
        this.file_data.forEach(function (element, key) {
            element["check"] = false;
            var index = _this.file_id_data.indexOf(element.uuid);
            _this.file_id_data.splice(index, 1);
        });
    };
    ReviewTranslatorComponent.prototype.adminApprovalPending = function (val) {
        var _this = this;
        if (this.Form.value.comment == '') {
            this.comment_data_approve.stage_id = val;
            this.comment_data_approve.comment_quiz = this.Form.value.comment_quiz;
            this.comment_data_approve.comment_orientation = this.Form.value.comment_orientation;
            this.comment_data_approve.status = 1;
            this.vendorService.update(this.id, this.comment_data_approve)
                .subscribe(function (response) {
                if (response) {
                    _this.data_update = response;
                    _this.getApproval();
                    _this.toastr.success('Approved');
                }
            });
        }
        else {
            if (this.Form.value.comment == null) {
                this.toastr.error('Special Comment required!!');
                return;
            }
            this.special_comment_data.comment = this.Form.value.comment;
            this.special_comment_data.vendor_id = this.id;
            this.special_comment_data.user_id = this.user_id;
            this.vendorService.addComment(this.special_comment_data)
                .subscribe(function (response) {
                if (response) {
                    _this.getApproval();
                    _this.toastr.success('Special comment Updated');
                }
            }, function (error) {
                _this.toastr.error(error);
            });
            this.comment_data_approve.stage_id = val;
            this.comment_data_approve.comment_quiz = this.Form.value.comment_quiz;
            this.comment_data_approve.comment_orientation = this.Form.value.comment_orientation;
            this.comment_data_approve.status = 1;
            this.vendorService.update(this.id, this.comment_data_approve)
                .subscribe(function (response) {
                if (response) {
                    _this.data_update = response;
                    _this.toastr.success('Approved');
                }
            });
        }
    };
    ReviewTranslatorComponent.prototype.finalApproval = function (val) {
        var _this = this;
        var data = {
            stage_id: val
        };
        this.vendorService.finalTranslatorApprove(this.id, data).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success('Translator Approved successfully');
                _this.router.navigate(['hr/translator']);
            }
        });
    };
    ReviewTranslatorComponent.prototype.submitDataReject = function (value) {
        var _this = this;
        if (this.Form.value.comment == '') {
            this.comment_data_reject = this.Form.value;
            this.comment_data_reject.stage_id = value;
            this.comment_data_reject.status = 0;
            this.vendorService.update(this.id, this.comment_data_reject)
                .subscribe(function (response) {
                if (response) {
                    _this.data_update = response;
                    _this.toastr.success('Rejected');
                }
            });
        }
        else {
            if (this.Form.value.comment == null) {
                this.toastr.error('Special Comment required!!');
                return;
            }
            this.special_comment_data.comment = this.Form.value.comment;
            this.special_comment_data.vendor_id = this.id;
            this.special_comment_data.user_id = this.user_id;
            this.vendorService.addComment(this.special_comment_data)
                .subscribe(function (response) {
                if (response) {
                    _this.toastr.success('Special comment Updated');
                }
            }, function (error) {
                _this.toastr.error(error);
            });
            this.comment_data_reject = this.Form.value;
            this.comment_data_reject.stage_id = value;
            this.comment_data_reject.status = 0;
            this.vendorService.update(this.id, this.comment_data_reject)
                .subscribe(function (response) {
                if (response) {
                    _this.data_update = response;
                    _this.toastr.success('Rejected');
                }
            });
        }
    };
    ReviewTranslatorComponent.prototype.getStage = function () {
        var _this = this;
        this.vendorService.getStage()
            .subscribe(function (response) {
            if (response) {
                _this.stageData = response.data;
            }
        });
    };
    ReviewTranslatorComponent.prototype.getApproval = function () {
        var _this = this;
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.stage_id = response.data.stage.data.id;
                _this.reference_id = response.data.reference_id;
                response.data.vendorprofileimage.data.forEach(function (element) {
                    _this.vendor_file_data = _this.BaseUrl_file + element.path;
                });
                if (response.data.vendorcontract.data.length != 0) {
                    response.data.vendorcontract.data.forEach(function (element) {
                        _this.vendor_contract_data = _this.BaseUrl_file + element.path;
                        var x = element.path.split('/');
                        _this.vendor_contract_name = x[2];
                    });
                }
                if (response.data.dob != '' && response.data.dob != null) {
                    var bdate = new Date(response.data.dob);
                    var ntimeDiff = Math.abs(Date.now() - bdate.getTime());
                    _this.age = Math.floor((ntimeDiff / (1000 * 3600 * 24)) / 365);
                }
                if (response.data.vendorresume.data.length != 0) {
                    response.data.vendorresume.data.forEach(function (element) {
                        _this.vendor_resume_data = _this.BaseUrl_file + element.path;
                        var x = element.path.split('/');
                        _this.vendor_resume_name = x[2];
                    });
                }
                if (response.data.extradocument != null) {
                    _this.vendor_exta_document_data = _this.BaseUrl_file + response.data.extradocument.data.path;
                    _this.vendor_exta_document_name = response.data.extradocument.data.name;
                }
                if (response.data.vendornativelanguage != null) {
                    _this.lang_name_native = response.data.vendornativelanguage.data.lang_name;
                }
                if (response.data.vendorcoafiles.data.length != 0) {
                    response.data.vendorcoafiles.data.forEach(function (element) {
                        var coa_url = _this.BaseUrl_file + element.coafile.data.path;
                        var coa_name_path = element.coafile.data.path.split('/');
                        var coa_name = coa_name_path[2];
                        var coa_obj = {
                            url: coa_url,
                            name: coa_name
                        };
                        // this.coa_id_array()
                        _this.coa_array.push(coa_obj);
                    });
                }
                _this.vendorcomment_data = response.data.vendorcomment.data;
                var day_1 = '';
                response.data.vendoravailabilities.data.forEach(function (elem) {
                    day_1 += elem.day_name + ', ';
                    _this.day_name_test = day_1;
                    _this.day_name_test = _this.day_name_test.slice(0, -2);
                });
                var specialities_1 = '';
                if (response.data.vendorspecialities.data.length > 0) {
                    response.data.vendorspecialities.data.forEach(function (elem) {
                        if (elem.specialities !== undefined) {
                            specialities_1 += elem.specialities.data.name + ', ';
                            _this.specialities_test = specialities_1;
                            _this.specialities_test = _this.specialities_test.slice(0, -2);
                        }
                    });
                }
                var skills_1 = '';
                if (response.data.vendorskill.data.length > 0) {
                    response.data.vendorskill.data.sort(function (a, b) {
                        return a.skill_id - b.skill_id;
                    });
                    response.data.vendorskill.data.forEach(function (elem) {
                        if (elem.skill !== undefined) {
                            skills_1 += elem.skill.data.name + ', ';
                            _this.skills = skills_1;
                            _this.skills = _this.skills.slice(0, -2);
                        }
                    });
                }
                var lang = '';
                response.data.vendortranslationlangs.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.lang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                    elem.proofreading_rate = elem.proofreading_rate;
                });
                var subtitlerslang = '';
                response.data.vendorSubtitler.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.subtiterslang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                    elem.subtitle_rate = elem.subtitle_rate;
                });
                //console.log(response.data.vendorSubtitler.data);
                if (response.data.vendorSubtitler.data.length > 0) {
                    response.data.vendorSubtitler.data = response.data.vendorSubtitler.data.sort(function (a, b) { return a.skillservice.data.name.localeCompare(b.skillservice.data.name); });
                }
                var treplang = '';
                response.data.vendorTreping.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                });
                _this.data_response = response.data;
                var resume_1 = '';
                response.data.vendorfiles.data.forEach(function (elem) {
                    resume_1 += elem.file.data.name + ', ';
                    _this.resume_name_test = resume_1;
                    _this.resume_name_test = _this.resume_name_test.slice(0, -2);
                });
                _this.Form.patchValue({
                    comment: _this.data_response.comment_admin,
                    stage_id: _this.stage_id
                });
            }
        });
    };
    ReviewTranslatorComponent.prototype.back = function () {
        this._location.back();
    };
    ReviewTranslatorComponent.prototype.Edit = function () {
        this.router.navigate(['hr/translator/' + this.id]);
    };
    ReviewTranslatorComponent.prototype.Send_mail_single = function (val) {
        var _this = this;
        var progress_single = [];
        var send_email_single_data = {};
        progress_single.push(val.user_id);
        send_email_single_data.ids = progress_single;
        send_email_single_data.resend = true;
        this.vendorService.sendProgressMail(send_email_single_data)
            .subscribe(function (response) {
            if (response) {
                send_email_single_data = {};
                progress_single = [];
                _this.toastr.success('Please check email');
                _this.getApproval();
            }
        });
    };
    return ReviewTranslatorComponent;
}());
export { ReviewTranslatorComponent };
