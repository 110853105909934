import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var MarketingGuard = /** @class */ (function () {
    function MarketingGuard(router) {
        this.router = router;
    }
    MarketingGuard.prototype.canActivate = function (route, state) {
        var roleData = SharedService.get(SharedService.user_group_id);
        if (SharedService.get(SharedService.LOGIN) == 'true' && (roleData == 'Hr-Admin' || roleData == 'Admin' || roleData == 'Project-Manager')) {
            return true;
        }
        this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    MarketingGuard.ngInjectableDef = i0.defineInjectable({ factory: function MarketingGuard_Factory() { return new MarketingGuard(i0.inject(i1.Router)); }, token: MarketingGuard, providedIn: "root" });
    return MarketingGuard;
}());
export { MarketingGuard };
