import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatPaginator } from '@angular/material';
import { MatTableDataSource, MatSort,MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { EmailLogPreviewComponent } from './email-log-preview/email-log-preview.component';
@Component({
  selector: 'app-project-email-log',
  templateUrl: './project-email-log.component.html',
  styleUrls: ['./project-email-log.component.scss']
})
export class ProjectEmailLogComponent implements OnInit {
  dataSource:any;
  emailLogResponse:any=[];
  paginate:any = {};
  pageSize:number = 10;
  query_projects:string ="";
  paginator: MatPaginator;
  project_id:string;
  pageIndex:number=1;
  total:number=0;
  constructor(private projectsService: ProjectsService,private toastr: ToastrService,
     private route: ActivatedRoute, private dialog:MatDialog) { }
  displayedColumns: string[] = [ 'subject', 'to', 'sent_on','status'];
  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getEmailLogs();
  }

  getEmailLogs()
  {
    this.projectsService.getProjectEmailLog(this.project_id)
    .subscribe((response:any) => {
      
        if(response) {
          this.emailLogResponse=response.data;
    
        this.dataSource = new MatTableDataSource(response.data);
        this.paginate = response.meta.pagination;
        this.total=response.meta.pagination.total;
    
         }
    });
  }


  paginateData(event) {
 
    let pQuery = this.project_id;
    this.pageIndex = event.pageIndex + 1;
    if(this.pageIndex < 0) {
        this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    
   this.query_projects='?page='+this.pageIndex+'&per_page='+this.pageSize;
    
   pQuery+=this.query_projects
  
    this.projectsService.getProjectEmailLog(pQuery)
    .subscribe((response) => {
    if(response) {
    
      this.dataSource = new MatTableDataSource(response.data);
      
      this.paginate = response.meta.pagination;
      this.total=response.meta.pagination.total;
     }
  });
  }

    // popup
    openDialogEmail(id:any) {
            
      const dailogRef = this.dialog.open(EmailLogPreviewComponent, {
          width: '1700px',
          data: id
      });
    }

}
