import { OnInit } from '@angular/core';
var FinancialDashboardComponent = /** @class */ (function () {
    function FinancialDashboardComponent() {
        this.pieChartLabels = ["Less then 50%", "50% - 64.99%", "65% - 74.99%", "75% - 99.99%", '100% and up'];
        this.pieChartData = [21, 39, 18, 15, 20];
        this.pieChartType = 'bar';
        this.pieChartOptions = { 'backgroundColor': [
                "#FF6384",
                "#4BC0C0",
                "#FFCE56",
                "#E7E9ED",
                "#36A2EB"
            ], 'responsive': false };
    }
    // events on slice click
    FinancialDashboardComponent.prototype.chartClicked = function (e) {
    };
    // event on pie chart slice hover
    FinancialDashboardComponent.prototype.chartHovered = function (e) {
    };
    FinancialDashboardComponent.prototype.ngOnInit = function () {
    };
    return FinancialDashboardComponent;
}());
export { FinancialDashboardComponent };
