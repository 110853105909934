<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" (click)="back()" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1">
      {{ data_response.title}} {{ data_response.first_name}} {{data_response.middle_name}} {{ data_response.last_name }}
      <span class="prfInfo">
        <!-- <span *ngIf="data_response?.is_new==0" class="pr-8">Existing Application</span> -->
        <span *ngIf="data_response?.is_new==0" class="pr-8">Translation Status</span>
        <span *ngIf="data_response?.is_new==1">New Application</span>
        <span class="prfo mr-10"
          *ngIf="data_response?.category_level != 'Closed' && data_response?.category_level != 'Blacklisted'">
          <span class="hText hYellow test" *ngIf="data_response?.stage!=null">
            {{data_response?.stage.data?.name}}
          </span>
        </span>
        <span class="prfo mr-10" *ngIf="data_response?.category_level!=null">
          <span class="pl-8 hText hRed"
            *ngIf="data_response?.category_level=='Closed' || data_response?.category_level=='Blacklisted' || data_response?.category_level=='Unavailable'">
            {{data_response?.category_level}}
          </span>
          <span class="pl-8 hText hYellow"
            *ngIf="data_response?.category_level!='Closed' && data_response?.category_level!='Blacklisted' && data_response?.category_level!='Unavailable'">
            {{data_response?.category_level}}
          </span>
        </span>
      </span>
    </span>
    <span class="title-text pt-40"> 
      <span class="pr-10">AVT Status</span>
      <span class="prfo" *ngIf="data_response?.avt_status!=null">
        <span class="pl-8 hText hRed mr-10"
          *ngIf="data_response?.avt_status=='Closed' || data_response?.avt_status=='Blacklisted' || data_response?.avt_status=='Unavailable'">
          {{data_response?.avt_status}}
        </span>
        <span class="pl-8 hText hYellow mr-10"
          *ngIf="data_response?.avt_status!='Closed' && data_response?.avt_status!='Blacklisted' && data_response?.avt_status!='Unavailable'">
          {{data_response?.avt_status}}
        </span>
      </span>
      <span class="mr-10" *ngIf="data_response?.avt_status==null">: NA</span>
    </span>
    <span class="title-text pt-40"> 
      <span class="pr-10">Terping Status</span>
      <span class="prfo" *ngIf="data_response?.treping_status!=null">
        <span class="pl-8 hText hRed mr-10"
          *ngIf="data_response?.treping_status=='Closed' || data_response?.treping_status=='Blacklisted' || data_response?.treping_status=='Unavailable'">
          {{data_response?.treping_status}}
        </span>
        <span class="pl-8 hText hYellow mr-10"
          *ngIf="data_response?.treping_status!='Closed' && data_response?.treping_status!='Blacklisted' && data_response?.treping_status!='Unavailable'">
          {{data_response?.treping_status}}
        </span>
      </span>
      <span class="mr-10" *ngIf="data_response?.treping_status==null">: NA</span>
    </span>

  </div>

  <div class="white-box">
    <form name="Form" [formGroup]="Form">
      <div class="profHeader p20 bb1">
        <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="auto">
            <div class="profImage">
              <img [src]="vendor_file_data" onerror="this.src='assets/images/avatars/profile.jpg'" width="120" /><br />
            </div>
            <h2> {{ data_response.title}} {{ data_response.first_name}} {{data_response.middle_name}}
              {{ data_response.last_name }}</h2>

            <!-- <p  class="font-16">Gender: <span class="txt-green" *ngIf="data_response?.gender==1">Male</span><span class="txt-green" *ngIf="data_response?.gender==2">Female</span></p> -->
            <p  class="font-16">Timezone: <span class="txt-green" *ngIf="data_response?.timezone!=null">{{data_response?.timezone}}</span></p>
            <!-- <p  class="font-16">Age: <span class="txt-green" >{{age}}</span></p> -->

            
            <h3>Native in: <span class="txt-green"
                *ngIf="data_response.vendornativelanguage!=null">{{data_response.vendornativelanguage.data?.lang_name}}</span>
            </h3>
            <h3>Status: <span class="txt-green" *ngIf="is_active==1">Active</span>
              <span class="txt-red" *ngIf="is_active==0">InActive</span>
            </h3>

            <h3>Notification Setting:</h3>
            <p *ngIf="data_response?.sms_notification==1">SMS: <span class="txt-green">Yes</span></p><p *ngIf="data_response?.sms_notification==0">SMS: <span class="txt-green">No</span></p>
            <p *ngIf="data_response?.wtsp_notification==1">WhatsApp: <span class="txt-green">Yes</span></p><p *ngIf="data_response?.wtsp_notification==0">WhatsApp: <span class="txt-green">No</span></p>
          </div>

          <div class="item item-2" fxFlex="auto">
            <div class="invAdd">



              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Address:</b></p>
                </div>

                <div class="item text-left" fxFlex="50%">
                  <p> {{data_response.address1}} {{ data_response.city }} {{data_response?.state}}
                    {{ data_response?.postal_code}} {{ data_response?.country }}
                  </p>
                </div>

              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>PR Scores:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.pr_scores }} </p>
                  <p *ngIf="data_response?.pr_scores==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Important notes:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.important_note }} </p>
                  <p *ngIf="data_response?.important_note==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Minimum Fee:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.info }} </p>
                  <p *ngIf="data_response?.info==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Email:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.email }} </p>
                  <p *ngIf="data_response?.email==null">NA</p>
                </div>
              </div>



              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Skype ID:</b></p>
                </div>

                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.skype_id }}</p>
                  <p *ngIf="data_response?.skype_id==null">NA</p>
                </div>
              </div>

              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Phone:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response?.phone }}</p>
                  <p *ngIf="data_response?.phone==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Alternative Email:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.email_sec }} </p>
                </div>
              </div>
             
              <!-- <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>AVT Status:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response.avt_status }} </p> 
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Treping Status:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response?.treping_status }} </p> 
                </div>
              </div> -->
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Minimum time:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response?.minimum_time }} </p> 
                  <p *ngIf="data_response?.minimum_time==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Travel cost:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response?.travel_cost }} </p> 
                  <p *ngIf="data_response?.travel_cost==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Distance willing to travel:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response?.distance_willing_to_travel }} </p> 
                  <p *ngIf="data_response?.distance_willing_to_travel==null">NA</p>
                </div>
              </div>
              <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div class="item item-2 text-right" fxFlex="50%">
                  <p><b>Cancellation cost:</b></p>
                </div>
                <div class="item text-left" fxFlex="50%">
                  <p>{{ data_response?.cancellation_cost }} </p> 
                  <p *ngIf="data_response?.cancellation_cost==null">NA</p>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="boxBody">
        <div class="boxBodyInn">
          <h4>Translation Service</h4>
          <div class="lanPirInfo">
            <div class="content">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Language Combinations</th>
                    <th class="text-right">Translation <span class="smlText pt-0"> Rate(USD) / Word</span></th>
                    <th class="text-right">Proofreading <span class="smlText pt-0"> Rate (USD) / Word </span></th>
                    <th class="text-right">DTP <span class="smlText pt-0"> Rate(USD) / Word</span></th>
                    <th class="text-right">QA <span class="smlText pt-0"> Rate (USD) / Word </span></th>
                    <th class="text-right">Speed <span class="smlText pt-0"> Words / Day</span></th>
                    <th class="text-right">Sworn </th>
                    <th class="text-right">Ata certified</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let langs of data_response?.vendortranslationlangs?.data">
                    <td>
                      <p *ngIf="langs.lang_data!=null">
                        {{langs.lang_data}}
                      </p>
                    </td>
                    <td class="text-right">
                      <p *ngIf="langs.translation_rate!=null">
                        ${{langs.translation_rate}} USD Per word
                      </p>
                    </td>
                    <td class="text-right">
                      <p *ngIf="langs.proofreading_rate!=null">
                        ${{langs.proofreading_rate}} USD Per word
                      </p>

                    </td>

                    <td class="text-right">
                      <p *ngIf="langs.dtp_rate!=null">
                        ${{langs.dtp_rate}} USD Per word
                      </p>
                    </td>
                    <td class="text-right">
                      <p *ngIf="langs.qa_rate!=null">
                        ${{langs.qa_rate}} USD Per word
                      </p>

                    </td>

                    <td class="text-right">
                      <p *ngIf="langs.speed!=null">
                        {{langs.speed}} words per day
                      </p>
                    </td>
                    <td class="text-right">
                      <p *ngIf='langs.sworn=="1"'>Yes</p>
                      <p *ngIf='langs.sworn=="0"'>No</p>
                    </td>
                    <td class="text-right">
                      <p *ngIf='langs.ata_certified=="1"'>Yes</p>
                      <p *ngIf='langs.ata_certified=="0"'>No</p>
                    </td>
                  </tr>
                </tbody>
              </table>


              <p><b>AVT</b></p>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Language Combinations</th>
                    <th>Service</th>
                    <th>Rate <span class="smlText pt-0"> Rate (USD) / minute </span></th>
                    <th>Rate <span class="smlText pt-0"> Rate (USD) / word </span></th>
                    <th>Speed <span class="smlText pt-0"> Words / Day</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subtitlerslang of data_response?.vendorSubtitler?.data">

                    <td>
                      <p *ngIf="subtitlerslang.subtiterslang_data!=null">
                        {{subtitlerslang.subtiterslang_data}}
                      </p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.skillservice.data.name!=null">{{subtitlerslang.skillservice.data.name}}</p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.subtitle_rate!=null">
                        ${{subtitlerslang.subtitle_rate}} USD Per minute
                      </p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.per_word!=null">
                        ${{subtitlerslang.per_word}} USD Per word
                      </p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.speed!=null">
                        {{subtitlerslang.speed}} 
                        <span *ngIf="subtitlerslang.skill_service_id==68">words/minutes per day</span>
                        <span *ngIf="subtitlerslang.skill_service_id!=68">minutes per day</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p><b>Terping</b></p>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Language Combinations</th>
                    <th>Service</th>
                    <th>Qualification</th>
                    <th>Hour Rate <span class="smlText pt-0"> Rate (USD) / hours </span></th>
                    <th>Minute Rate <span class="smlText pt-0"> Rate (USD) / minutes </span></th>
                    <!-- <th>Speed <span class="smlText pt-0"> Words / Day</span></th> -->
                    <th>Flexible rate <span class="smlText pt-0"> </span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let trepinglang of data_response?.vendorTreping?.data">

                    <td>
                      <p *ngIf="trepinglang.treplang!=null">
                        {{trepinglang.treplang}}
                      </p>
                    </td>
                    <td>
                      <p *ngIf="trepinglang.trepingSkillservice.data.name!=null">{{trepinglang.trepingSkillservice.data.name}}</p>
                    </td>
                    <td>
                      <p *ngIf="trepinglang.qualification!=null">{{trepinglang.qualification}}</p>
                    </td>
                    <td>
                      <p *ngIf="trepinglang.rate_hour!=null">
                        ${{trepinglang.rate_hour}} USD Per hour
                      </p>
                    </td>
                    <td>
                      <p *ngIf="trepinglang.rate_min!=null">
                        ${{trepinglang.rate_min}} USD Per minute
                      </p>
                    </td>
                    <!-- <td>
                      <p *ngIf="trepinglang.speed!=null">
                        {{trepinglang.speed}} words per day
                      </p>
                    </td> -->
                    <td>
                      <div *ngIf="trepinglang.flexible_rate!=null">
                        <p *ngIf='trepinglang.flexible_rate=="1"'>Yes</p>
                        <p *ngIf='trepinglang.flexible_rate=="0"'>No</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="box-blank" *ngIf="specialities_test">
                <p><b>Speciality</b></p>
                <p>
                  {{specialities_test}}
                </p>
              </div>
              <div class="box-blank" *ngIf="skills">
                <p><b>Skills</b></p>
                <p>
                  {{skills}}
                </p>
              </div>
            </div>

            <h4>Technology</h4>
            <p><b>Do you use CAT (Computer Assisted Translation) tools?</b>
              <span>
                <p *ngIf="data_response?.translation_tools==1">
                  No
                </p>
                <p *ngIf="data_response?.translation_tools==2">
                  Yes
                </p>
                <p *ngIf="data_response?.translation_tools==3">
                  Yes, but I will not use them unless requested
                </p>


              </span>
              <b>Boostlingo Affiliation :</b>
              <span>
                <p *ngIf="data_response?.boostlingo_affiliation=='1'">
                  Yes
                </p>
                <p *ngIf="data_response?.boostlingo_affiliation=='0'">
                  No
                </p>
              </span>

              <b>valid license :</b>
              <span>
                <p *ngIf="data_response?.tool_licence=='1'">
                  Yes
                </p>
                <p *ngIf="data_response?.tool_licence=='0'">
                  No
                </p>
              </span>
            </p>

            <p>
              <b>Tool Used:</b>
              <span>
                <p *ngIf="data_response?.tool_names">
                  {{data_response?.tool_names}}
                </p>
                <p *ngIf="data_response?.tool_names== null">
                  NA
                </p>
              </span>

              <b>Program/Software used for subtitles:</b>
              <span>
                <p *ngIf="data_response?.subtitles_program_software">
                  {{data_response?.subtitles_program_software}}
                </p>
                <p *ngIf="data_response?.subtitles_program_software== null">
                  NA
                </p>
              </span>

            <h4 *ngIf="data_response?.payment_method || data_response?.payment_method_id">Financials</h4>
            <p *ngIf="data_response?.payment_method"><b>Payment Method :</b> {{ data_response?.payment_method }}</p>
            <p *ngIf="data_response?.other_payment_method"><b>Other Method :</b> {{ data_response?.other_payment_method }}</p>
            <p *ngIf="data_response?.payment_method_id"><b>Payment Method Email:</b> {{ data_response?.payment_method_id
              }}</p>
          </div>
          <h4>Days Available</h4>
          <p *ngIf="day_name_test!=null || day_name_test!=''"> {{ day_name_test }}</p>
          <p *ngIf="day_name_test==null || day_name_test==''"> NA</p>
          <h4>Voice Sample</h4>
          <p *ngIf="data_response?.voice_sample!=null || data_response?.voice_sample!=''"> {{ data_response?.voice_sample }}</p>
          <p *ngIf="data_response?.education==null || data_response?.education==''"> NA</p>
          <h4>Notes yourself</h4>
          <p *ngIf="data_response?.education!=null || data_response?.education!=''"> {{ data_response?.education }}</p>
          <p *ngIf="data_response?.education==null || data_response?.education==''"> NA</p>
          <h4>Detailed cover letter</h4>
          <p *ngIf="data_response?.cover_letter!=null || data_response?.cover_letter!=''"> {{
            data_response?.cover_letter }}</p>
          <p *ngIf="data_response?.cover_letter==null || data_response?.cover_letter==''"> NA</p>


          <div class="white-box" style="width: 100%;">
            <div class="tHeader wMenu">
              <div class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;"
                fxLayoutGap="10px">
                <div fxFlex="30">
                  <h2>Translator Doc</h2>
                </div>
                <div fxFlex="70" class="text-right">
                  <button *ngIf="file_id_data?.length>0" mat-stroked-button (click)="UncheckedFile()">
                    <a [href]="file_d" target="_blank" download><i class="material-icons">
                        cloud_download
                      </i> Download All </a></button>
                </div>
              </div>
            </div>

            <div class="boxBody">
              <div class="boxBodyInn" *ngIf="file_data?.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        <mat-checkbox [(ngModel)]="bulk_selected_progress" [ngModelOptions]="{standalone: true}"
                          (change)="selectBulkProgress($event)">
                        </mat-checkbox>
                      </th>
                      <th scope="col">Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Last Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file_res of file_data">
                      <th scope="row">
                        <mat-checkbox [(ngModel)]="file_res.check" [ngModelOptions]="{standalone: true}"
                          (change)="selectFile($event,file_res)">
                        </mat-checkbox>
                      </th>
                      <td>
                        <a [href]="BaseUrl_file + file_res?.vendorfile?.path" target="_blank"
                          [download]="BaseUrl_file + file_res?.vendorfile?.path">
                          {{file_res?.vendorfile.name}}</a>
                      </td>
                      <td>
                        <p *ngIf="file_res?.type=='1'">Certificate</p>
                        <p *ngIf="file_res?.type=='2'">Contract</p>
                        <p *ngIf="file_res?.type=='3'">Identity Document</p>
                        <p *ngIf="file_res?.type=='4'">COA's</p>
                        <p *ngIf="file_res?.type=='5'">Other</p>
                      </td>
                      <td>{{file_res?.comment}}</td>
                      <td>{{file_res?.created_at | date: 'yyyy-MM-dd'}} {{file_res?.created_at | date:"HH:mm"}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="noDataFound" *ngIf="file_data?.length==0"> No data available!!
              </div>
            </div>

          </div>






          <h4>Resume</h4>
          <div class="box-blank" *ngIf="vendor_resume_name!=null">
          <p *ngIf="vendor_resume_name!=null"> {{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank"
              download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          </div>
          <p *ngIf="vendor_resume_name==null"> No record <a>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          <h4>Contract</h4>
          <!-- <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data"
              target="_blank" download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          <p *ngIf="vendor_contract_name==null"> No record
          </p> -->
          <div class="box-blank" *ngIf="vendor_contract_name!=null">
            <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data"
                target="_blank" download>
                <mat-icon>vertical_align_bottom</mat-icon>
              </a>
            </p>
          </div>
          <p *ngIf="vendor_contract_name==null"> No record</p>
          <!-- test -->
          <h4>Coa Files</h4>
          <!-- <p *ngIf="vendor_resume_name!=null"> {{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank"
              download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          <p *ngIf="vendor_resume_name==null"> No record <a>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p> -->
          <div class="box-blank" *ngIf="coa_array?.length>0">
            <span *ngFor="let resCoa of coa_array">
              <p>{{resCoa.name}} <a [href]="resCoa.url" target="_blank" download>
                  <mat-icon>vertical_align_bottom</mat-icon>
                </a></p>
              <p *ngIf="coa_array?.length ==0"> No record
              </p>
            </span>
          </div>

          <h4>Extra Document</h4>
          <div class="box-blank" *ngIf="vendor_exta_document_name!=null">
            <p *ngIf="vendor_exta_document_name!=null"> {{vendor_exta_document_name}} <a
                [href]="vendor_exta_document_data" target="_blank" download>
                <mat-icon>vertical_align_bottom</mat-icon>
              </a>
            </p>
          </div>
          <!-- test -->
          <h4>Important Notes</h4>
          <p *ngIf="data_response?.important_note!=null || data_response?.important_note!=''"> {{
            data_response?.important_note }}</p>
          <p *ngIf="data_response?.important_note==null || data_response?.important_note==''"> NA</p>

          <h4>Onboarding</h4>
          <p *ngIf="data_response?.on_boarding_date!=null || data_response?.on_boarding_date!=''"> {{
            data_response?.on_boarding_date | date:'yyyy-MM-dd' }}</p>
          <p *ngIf="data_response?.on_boarding_date==null || data_response?.on_boarding_date==''"> NA</p>



          <h4>HR Comments</h4>

          <div class="boxBody" *ngIf="vendorcomment_data?.length!=0">
            <div class="boxBodyInn2">
              <div class="commentDiv" *ngFor="let res of vendorcomment_data">

                <div class="quesDiv" *ngIf="res?.user?.data?.user_group_id=='Vendor'">
                  <p><b> {{ res?.user?.data?.fullname}} ({{ res?.user?.data?.user_group_id}})</b> <span class="tStamp">
                      {{ res?.created_at.date | date:'short' }}</span> </p>
                  <p>{{ res?.comment}}</p>
                </div>


                <div class="ansDiv" *ngIf="res?.user?.data.user_group_id!='Vendor'">
                  <div class="divinn">
                    <p><b> {{ res?.user?.data?.fullname}} ({{ res?.user?.data?.user_group_id}})</b> <span
                        class="tStamp">
                        {{ res?.created_at?.date | date:'short' }}</span> </p>
                    <p>{{ res?.comment}}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <h4>Personal information</h4>
          <p>Gender: 
            <span *ngIf="data_response?.gender==1">Male</span>
            <span *ngIf="data_response?.gender==2">Female</span>
          </p>
          <p >age: 
            <span >{{age}}</span>
          </p>

          <p>billing address: 
            <span >{{data_response?.address1}} {{data_response?.address2}}</span>
          </p>
          <p>total earned: 
            <span >NA</span>
          </p>
          <p>company website: 
            <span >NA</span>
          </p>




        </div>
      </div>

      <div class="boxBodyInn">

        <div class="box-blank bg-yellow">

          <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <div fxLayout="column" fxFlex="1 0 auto" fxFlex="100">
              <h4>Statics</h4>
              <mat-form-field fxFlex="100">
                <mat-label>Quiz Result Inputs:</mat-label>
                <textarea matInput rows="5" formControlName="comment_quiz"
                  [value]="data_response?.comment_quiz"></textarea>
              </mat-form-field>

              <mat-form-field fxFlex="100">
                <mat-label>Orientation Inputs:</mat-label>
                <textarea matInput rows="5" formControlName="comment_orientation"
                  [value]="data_response?.comment_orientation"></textarea>
              </mat-form-field>




              <mat-form-field>
                <mat-label>Special Comments from HR:</mat-label>
                <textarea matInput rows="5" formControlName="comment"></textarea>
              </mat-form-field>

              <!-- <span><span class="hText hYellow"> {{data_response?.stage.data?.name}}</span></span> -->
              <div *ngIf="data_response?.stage.data?.name!='Approved'">
                <mat-form-field fxFlex="30" class="mt-12">
                  <mat-label>Stage</mat-label>
                  <mat-select formControlName="stage_id">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option label="New Application" [value]="1">New Application</mat-option>
                    <mat-option label="Under Review" [value]="2">Under Review</mat-option>
                    <mat-option label="Quiz" [value]="3">Quiz</mat-option>
                    <mat-option label="Orientation" [value]="4">Orientation</mat-option>
                    <mat-option label="HR Approval" [value]="9">HR Approval</mat-option>
                    <mat-option label="Admin Approval Pending" [value]="7">Admin Approval Pending</mat-option>
                    <!-- <mat-option *ngFor="let stage_res of stageData" [value]="stage_res.id">
                  {{stage_res.name}}
                </mat-option> -->
                  </mat-select>
                </mat-form-field>
              </div>






            </div>


          </div>
          <p>
            <button matTooltip="Save" mat-flat-button color="accent" type="submit" (click)="submit()"
              [disabled]="Form.invalid" class="mr-8">
              <mat-label>Save Statics</mat-label>
            </button>
          </p>
        </div>
        <p class="text-right">


          <button mat-raised-button (click)="Edit()" class="mr-8"><span class="material-icons">edit</span> Edit
            User</button>

          <button (click)="Send_mail_single(data_response)" mat-raised-button class="mr-8">
            <span class="material-icons">vpn_key</span> Resend Login Credentials to this User
          </button>



        </p>

      </div>
      <div class="actWraper">
        <p class="tStatus text-left ml-4"> Current Stage:
          <span class="hText hYellow" *ngIf="data_response?.stage!=null"> {{data_response?.stage.data?.name}}</span>
        </p>
        <p>
          <button matTooltip="Bypass Admin and Approve" mat-raised-button
            *ngIf="data_response?.stage.data?.name!='Approved'" (click)="finalApproval(6)" class="mr-8">
            <mat-label>Bypass Admin and Approve</mat-label>
          </button>
          <button matTooltip="Admin Approval" mat-raised-button *ngIf="data_response?.stage.data?.name!='Approved'"
            (click)="adminApprovalPending(7)" class="mr-8">
            <mat-label>Send Admin for Approval </mat-label>
          </button>
          <button color="warn" mat-raised-button matTooltip="Click here to Reject"
            *ngIf="data_response?.stage.data?.name!='Approved'" (click)="submitDataReject(8)">Reject</button>
        </p>
      </div>
    </form>
  </div>
</div>