import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delivery-email-template',
  templateUrl: './delivery-email-template.component.html',
  styleUrls: ['./delivery-email-template.component.scss']
})
export class DeliveryEmailTemplateComponent implements OnInit {

  config: any;
  project_id: string;
  Form: FormGroup;
 constructor( private dialogRef: MatDialogRef<DeliveryEmailTemplateComponent>,
   @Inject(MAT_DIALOG_DATA) public delivery_data :any,private projectsService: ProjectsService,
   private toastr: ToastrService,private router: Router,private route: ActivatedRoute,
   private _formBuilder: FormBuilder) { }

 Onclose(res:any){
   this.dialogRef.close(res);
}


 ngOnInit() {
   this.project_id = this.route.snapshot.params['id'];
   this.config = {
      width: "1000px",
      height: '155px',
      toolbar : [
        { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
        { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
        { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
        '/',
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
        { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
        '/',
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        { name: 'about', items: [ 'About' ] }
 
     ]
     // toolbar: [
     //     { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
     //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }
     // ]

   
 };

 this.Form = this._formBuilder.group({ 

   to: ['',Validators.compose([Validators.required, Validators.email])],
   cc:['',Validators.compose([ Validators.email])],
   bcc:['',Validators.compose([ Validators.email])],
   subject: ['', Validators.compose([Validators.required])],
   ckeditorContent: [''],
});

 this.get_delivery_email();
 }

 


 updateMessage(){
   if(  this.Form.value.subject==''){
     this.toastr.error("Subject never be empty!!");
     return;
   }

   if(  this.Form.value.ckeditorContent==''){
     this.toastr.error("Body never be empty!!");
     return;
   }
   let data:any={
     mail_response:{}
   };
   

   let mail_response:any={
     to:'',
     cc:'',
     bcc:'',
     message:'',
     subject:'',
    

   }
   mail_response.to =  this.Form.value.to;
   mail_response.cc=  this.Form.value.cc;
   mail_response.bcc=  this.Form.value.bcc;
   mail_response.message=  this.Form.value.ckeditorContent;
   mail_response.subject=  this.Form.value.subject;
   data=this.delivery_data;
   data.mail_response=mail_response;
  

     this.projectsService.ProjectDelivered(data)
         .subscribe((response) => {
             if(response) {
               this.toastr.success("Project delivered successfully");
               this.Onclose(true);
             }  else{
                      // this.toastr.error("Something went wrong");
                      this.Onclose(false);
                    }
         }
        //  , (error) => {
    
        //      this.Onclose(false);
        //  }
         );
 }
 get_delivery_email(){    
   this.projectsService.get_delivery_email(this.delivery_data)
         .subscribe((response) => {
             if(response) {
             //   this.Form.value.ckeditorContent= response.mail_response.message;
             //  this.Form.value.subject=response.mail_response.subject;
             //  this.Form.value.to=response.mail_response.To;
             this.Form.patchValue({
               ckeditorContent: response.mail_response.message,
               subject: response.mail_response.subject,
               to: response.mail_response.To,
             });
         
       
             }
         });
 }



}
