import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/router";
import * as i3 from "ngx-toastr";
import * as i4 from "@angular/common/http";
import * as i5 from "../../@fuse/components/progress-bar/progress-bar.service";
var SharedService = /** @class */ (function () {
    function SharedService(snackBar, router, toasterService, http, _fuseProgressBarService) {
        this.snackBar = snackBar;
        this.router = router;
        this.toasterService = toasterService;
        this.http = http;
        this._fuseProgressBarService = _fuseProgressBarService;
    }
    SharedService.get = function (key) {
        return localStorage.getItem(key);
    };
    SharedService.clearStorage = function () {
        localStorage.clear();
    };
    SharedService.set = function (key, value) {
        localStorage.setItem(key, value);
    };
    SharedService.getHeader = function () {
        if (localStorage.getItem('token') != null) {
            return new HttpHeaders()
                .set('Accept', 'application/json')
                .set('Access-Control-Allow-Methods', "GET, POST, DELETE, PUT,PATCH");
        }
        return null;
    };
    SharedService.getPutHeader = function () {
        return new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/json');
    };
    SharedService.getFileHeader = function () {
        if (localStorage.getItem('token') != null) {
            return new HttpHeaders()
                .set('Authorization', localStorage.getItem('token'));
        }
        return null;
    };
    SharedService.prototype.handleError = function (err) {
        this._fuseProgressBarService.show();
        var message = 'Something went wrong. Please try later!!';
        this.snackBar.open(err, null, {
            duration: 3000,
            panelClass: 'msg-danger'
        });
        this._fuseProgressBarService.hide();
    };
    SharedService.prototype.getApi = function (url) {
        var _this = this;
        this._fuseProgressBarService.show();
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers }).pipe(map(function (response) {
            _this._fuseProgressBarService.hide();
            return response;
        }));
    };
    SharedService.prototype.postApi = function (url, data) {
        var _this = this;
        this._fuseProgressBarService.show();
        var headers = SharedService.getPutHeader();
        return this.http.post(url, data, { headers: headers }).pipe(map(function (response) {
            _this._fuseProgressBarService.hide();
            return response;
        }));
    };
    SharedService.prototype.patchApi = function (url, data) {
        var _this = this;
        this._fuseProgressBarService.show();
        var headers = SharedService.getPutHeader();
        return this.http.patch(url, data, { headers: headers }).pipe(map(function (response) {
            _this._fuseProgressBarService.hide();
            return response;
        }));
    };
    SharedService.prototype.deleteApi = function (url) {
        var _this = this;
        this._fuseProgressBarService.show();
        var headers = SharedService.getPutHeader();
        return this.http.delete(url, { headers: headers }).pipe(map(function (response) {
            _this._fuseProgressBarService.hide();
            return response;
        }));
    };
    SharedService.prototype.postFileApi = function (url, data) {
        var _this = this;
        this._fuseProgressBarService.show();
        var headers = SharedService.getFileHeader();
        return this.http.post(url, data, { headers: headers }).pipe(map(function (response) {
            _this._fuseProgressBarService.hide();
            return response;
        }));
    };
    SharedService.prototype.postFileWhisperApi = function (url, data) {
        var _this = this;
        this._fuseProgressBarService.show();
        return this.http.post(url, data, { responseType: 'text' }).pipe(map(function (response) {
            _this._fuseProgressBarService.hide();
            return response;
        }));
    };
    SharedService.prototype.toastr = function (data) {
        this.toasterService.error(data.message);
    };
    SharedService.prototype.show = function () {
        this._fuseProgressBarService.show();
    };
    SharedService.prototype.hide = function () {
        this._fuseProgressBarService.hide();
    };
    SharedService.TEAMEM_ID = 'teamID';
    SharedService.LOGIN = 'login';
    SharedService.TOKEN = 'token';
    SharedService.ID = 'id';
    SharedService.USER_NAME = 'username';
    SharedService.EMAIL = 'email';
    SharedService.PROFILE_PIC = 'profile_pic';
    SharedService.user_group_id = 'user_group_id';
    SharedService.SELECTED_TEAM = 'selectedTeam';
    SharedService.VIEW_CONTACT = 'viewcontact';
    SharedService.NAVIGATION = 'navigation';
    SharedService.NAME = 'name';
    SharedService.REMEMBER = 'remember';
    SharedService.QUOTE_ID = 'quote_id';
    SharedService.Project_id = 'Project_id';
    // pagination
    SharedService.table_name = 'table_name';
    SharedService.page = 'page';
    SharedService.per_page = 'per_page';
    SharedService.pagination = 'pagination';
    SharedService.hrNativeFilter = 'hrNativeFilter';
    SharedService.hrCategoryFilter = 'hrCategoryFilter';
    SharedService.hrSourceFilter = 'hrSourceFilter';
    SharedService.hrTargetFilter = 'hrTargetFilter';
    SharedService.newRequestFilter = 'newRequestFilter';
    SharedService.inProgressFilter = 'inProgressFilter';
    SharedService.nonResponsiveFilter = 'nonResponsiveFilter';
    SharedService.needApprovalFilter = 'needApprovalFilter';
    SharedService.pendingAdminApprovalFilter = 'pendingAdminApprovalFilter';
    SharedService.archiveFilter = 'archiveFilter';
    SharedService.newRequestPagination = 'newRequestPagination';
    SharedService.inProgressPagination = 'inProgressPagination';
    SharedService.nonResponsivePagination = 'nonResponsivePagination';
    SharedService.closedPagination = 'closedPagination';
    SharedService.blacklistedPagination = 'blacklistedPagination';
    SharedService.needApprovalPagination = 'needApprovalPagination';
    SharedService.pendingAdminApprovalPagination = 'pendingAdminApprovalPagination';
    SharedService.archivePagination = 'archivePagination';
    SharedService.t_native_filter = 't_native_filter';
    SharedService.t_specialization = 't_specialization';
    SharedService.t_source_lang = 't_source_lang';
    SharedService.t_target_lang = 't_target_lang';
    SharedService.Filter = 'filter';
    SharedService.importedFilter = 'importedFilter';
    SharedService.imported_native_filter = 'imported_native_filter';
    SharedService.imported_category = 'imported_category';
    SharedService.vednor_skill = 'vednor_skill';
    SharedService.vednor_search = 'vednor_search';
    SharedService.imported_source_lang = 'imported_source_lang';
    SharedService.imported_target_lang = 'imported_target_lang';
    SharedService.importedPagination = 'importedPagination';
    SharedService.pms_freelancer_Filter = 'pms_freelancer_Filter';
    SharedService.pms_freelancer_native_filter = 'pms_freelancer_native_filter';
    SharedService.pms_freelancer_specialization_filter = 'pms_freelancer_specialization_filter';
    SharedService.pms_freelancer_source_lang_filter = 'pms_freelancer_source_lang_filter';
    SharedService.pms_freelancer_target_lang_filter = 'pms_freelancer_target_lang_filter';
    SharedService.pms_freelancer_service_filter = 'pms_freelancer_service_filter';
    SharedService.pms_freelancer_search_filter = 'pms_freelancer_search_filter';
    SharedService.pms_freelancer_days_available_filter = 'pms_freelancer_days_available_filter';
    SharedService.pms_freelancer_gender_filter = 'pms_freelancer_gender_filter';
    SharedService.pms_freelancer_tools_filter = 'pms_freelancer_tools_filter';
    SharedService.pms_freelancer_timezone_filter = 'pms_freelancer_timezone_filter';
    SharedService.pms_freelancer_age_filter = 'pms_freelancer_age_filter';
    SharedService.pms_freelancer_category_level_filter = 'pms_freelancer_category_level_filter';
    SharedService.pms_freelancer_avt_status_level_filter = 'pms_freelancer_avt_status_level_filter';
    SharedService.pms_freelancer_country_filter = 'pms_freelancer_country_filter';
    SharedService.pms_freelancer_city_filter = 'pms_freelancer_city_filter';
    SharedService.pms_freelancer_rate_filter = 'pms_freelancer_rate_filter';
    SharedService.pms_freelancer_startdate_filter = 'pms_freelancer_startdate_filter';
    SharedService.pms_freelancer_enddate_filter = 'pms_freelancer_enddate_filter';
    SharedService.pms_freelancer_pagination = 'pms_freelancer_pagination';
    SharedService.pms_freelancer_avt_service_filter = 'pms_freelancer_avt_service_filter';
    SharedService.workflowAssignProcessObj = 'workflowAssignProcessObj';
    SharedService.ngInjectableDef = i0.defineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.inject(i1.MatSnackBar), i0.inject(i2.Router), i0.inject(i3.ToastrService), i0.inject(i4.HttpClient), i0.inject(i5.FuseProgressBarService)); }, token: SharedService, providedIn: "root" });
    return SharedService;
}());
export { SharedService };
