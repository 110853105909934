import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var BulkEmailTranslatorComponent = /** @class */ (function () {
    //constructor(private projectsService: ProjectsService,) { }
    function BulkEmailTranslatorComponent(route, router, dialogRef, data, _formBuilder, projectsService, toastr) {
        this.route = route;
        this.router = router;
        this.dialogRef = dialogRef;
        this.data = data;
        this._formBuilder = _formBuilder;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.emailTemplateData = [];
        this.freelancerArray = [];
    }
    BulkEmailTranslatorComponent.prototype.ngOnInit = function () {
        this.emailTemplateData = this.data.templateData;
        this.freelancerArray = this.data.freelacner;
        this.config = {
            width: "900px",
            toolbar: [
                { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'] },
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
                { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
                { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                '/',
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
                { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                '/',
                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                { name: 'colors', items: ['TextColor', 'BGColor'] },
                { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
                { name: 'about', items: ['About'] }
            ]
        };
        this.Form = this._formBuilder.group({
            subject: ['', Validators.compose([Validators.required])],
            ckeditorContent: [''],
            cc: [''],
        });
        // this.Form.patchValue({
        //   ckeditorContent: this.data.templateData[0].body,
        //   subject: this.data.templateData[0].subject,
        // });
    };
    BulkEmailTranslatorComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    BulkEmailTranslatorComponent.prototype.onTemplateSelect = function (evt) {
        var _this = this;
        this.data.templateData.forEach(function (element) {
            if (element.id == evt.value) {
                _this.Form.patchValue({
                    ckeditorContent: element.body,
                    subject: element.subject,
                });
            }
        });
    };
    BulkEmailTranslatorComponent.prototype.submit = function () {
        var _this = this;
        if (this.Form.value.subject == '') {
            this.toastr.error("Subject never be empty!!");
            return;
        }
        if (this.Form.value.ckeditorContent == '') {
            this.toastr.error("Body never be empty!!");
            return;
        }
        this.data.body = this.Form.value.ckeditorContent;
        this.data.subject = this.Form.value.subject;
        this.data.freelacner_id = this.freelancerArray;
        this.data.cc = this.Form.value.cc;
        this.projectsService.sendVendorRushEmail(this.data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success(response.message);
                _this.Onclose();
            }
            else {
                _this.toastr.error("Something went wrong");
            }
        });
    };
    return BulkEmailTranslatorComponent;
}());
export { BulkEmailTranslatorComponent };
