import { Injectable } from '@angular/core';
import { Observable, Subject, pipe, of } from 'rxjs';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  BaseUrl: any;
  private heroesUrl = 'https://dayinterpreting.daytranslations.com/conversations/';  // URL to web api
  constructor(public http: HttpClient, public sharedService: SharedService) {
    this.BaseUrl = environment.APIEndpoint;
  }

  getAll(query): Observable<any> {
    let url = this.BaseUrl + '/projects' + query;
    return this.sharedService.getApi(url);
  }

  getRequestQuote(id: any, query): Observable<any> {
    let url = this.BaseUrl + '/projects/' + id + query;
    return this.sharedService.getApi(url);
  }

  getAdditional_service( query): Observable<any> {
    let url = this.BaseUrl + '/project/additional_service' +  query;
    return this.sharedService.getApi(url);
  }

  getProjectDueToday(query): Observable<any> {
    let url = this.BaseUrl + '/project_due_today' + query;
    return this.sharedService.getApi(url);
  }


  getUnderGoingEdit(query): Observable<any> {
    let url = this.BaseUrl + '/undergoing_edits' + query;
    return this.sharedService.getApi(url);
  }


  getUnassigned(query): Observable<any> {
    let url = this.BaseUrl + '/un_assigned' + query;
    return this.sharedService.getApi(url);
  }

  getUnderGoing(query): Observable<any> {
    let url = this.BaseUrl + '/undergoing' + query;
    return this.sharedService.getApi(url);
  }

  getUnConfirmedByTz(query): Observable<any> {
    let url = this.BaseUrl + '/unconfirmed_by_tz' + query;
    return this.sharedService.getApi(url);
  }

  getClientApproval_Pending(query): Observable<any> {
    let url = this.BaseUrl + '/client_approval_pending' + query;
    return this.sharedService.getApi(url);
  }

  getRejectedQuotes(query): Observable<any> {
    let url = this.BaseUrl + '/project/rejected_quote' + query;
    return this.sharedService.getApi(url);
  }

  getProjectAssignmentPending(query): Observable<any> {
    let url = this.BaseUrl + '/assignment_pending' + query;
    return this.sharedService.getApi(url);
  }

  getUpdateByTz(query): Observable<any> {
    let url = this.BaseUrl + '/update_due_by_tz' + query;
    return this.sharedService.getApi(url);
  }


  get(id: any): Observable<any> {
    let url = this.BaseUrl + '/projects/' + id;
    return this.sharedService.getApi(url);
  }

  getProjectDetail(id: any): Observable<any> {
    let url = this.BaseUrl + '/project/' + id;
    return this.sharedService.getApi(url);
  }

  getImportantNotes(id: any): Observable<any> {
    let url = this.BaseUrl + '/project/important_messages/' + id;
    return this.sharedService.getApi(url);
  }

  updateComment(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/project/' + id + '/comments';
    return this.sharedService.postApi(url, data);
  }


  getEmailTemplate(): Observable<any> {
    let url = this.BaseUrl + '/email/customer_quote?per_page=100';
    return this.sharedService.getApi(url);
  }

  //  email template for workflows 

  getEmailTemplateTranslation(): Observable<any> {
    let url = this.BaseUrl + '/email/translation_assignment';
    return this.sharedService.getApi(url);
  }

  getEmailTemplateProofReading(): Observable<any> {
    let url = this.BaseUrl + '/email/proof_reading_assignment';
    return this.sharedService.getApi(url);
  }


  getEmailTemplateQA(): Observable<any> {
    let url = this.BaseUrl + '/email/qa_assignment';
    return this.sharedService.getApi(url);
  }


  getEmailTemplateEditing(): Observable<any> {
    let url = this.BaseUrl + '/email/editing_assignment';
    return this.sharedService.getApi(url);
  }

  getEmailTemplateDTP(): Observable<any> {
    let url = this.BaseUrl + '/email/dtp_assignment';
    return this.sharedService.getApi(url);
  }
  //Additional services
  getEmailTemplateMailingOptions(): Observable<any> {
    let url = this.BaseUrl + '/email/additional_service_assignment_mail';
    return this.sharedService.getApi(url);
  }


  getEmailTemplateNotarization(): Observable<any> {
    let url = this.BaseUrl + '/email/additional_service_assignment_notarization';
    return this.sharedService.getApi(url);
  }

  getEmailTemplateApostile(): Observable<any> {
    let url = this.BaseUrl + '/email/additional_service_assignment_apostile';
    return this.sharedService.getApi(url);
  }


  sendProjectQuote(data: any): Observable<any> {
    let url = this.BaseUrl + '/create_project_quote';
    return this.sharedService.postApi(url, data);
  }


  sendDraftProjectQuote(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/projectquote/' + id;
    return this.sharedService.patchApi(url, data);
  }


  ProjectTranslator(data: any): Observable<any> {
    let url = this.BaseUrl + '/project/freelancer';
    return this.sharedService.postApi(url, data);
  }

  

  ProjectTranslatorAssignment(data: any,query:any): Observable<any> {
    let url = this.BaseUrl + '/project/freelancer'+query;
    return this.sharedService.postApi(url, data);
  }

  // Download zip file 
  downloadZip(data: any): Observable<any> {
    let url = this.BaseUrl + '/projectfilezip';
    return this.sharedService.postApi(url, data);
  }

  //File prepration Project file save
  projectFileSave(data: any): Observable<any> {
    let url = this.BaseUrl + '/projectfilesave';
    return this.sharedService.postApi(url, data);
  }


  // Get Project Language 

  getProjectLanguage(id: any): Observable<any> {
    let url = this.BaseUrl + '/projectlanguage/project/' + id;
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers });
  }


  UpdateProjectPm(data: any): Observable<any> {
    let url = this.BaseUrl + '/updateprojectpm';
    return this.sharedService.postApi(url, data);
  }


  // Get File Type

  getFileType(): Observable<any> {
    let url = this.BaseUrl + '/filetype';
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers });
  }


  // Get Discount Type promotion
  getDiscountTypePromotion(): Observable<any> {
    let url = this.BaseUrl + '/promotion';
    return this.sharedService.getApi(url);
  }


  //project lock api
  projectLock(data: any): Observable<any> {
    let url = this.BaseUrl + '/lockproject';
    return this.sharedService.postApi(url, data);
  }


  //project release api
  projectRelease(data: any): Observable<any> {
    let url = this.BaseUrl + '/project_release';
    return this.sharedService.postApi(url, data);
  }

  // get all loced project by pm
  GetPmLockedProjects(data: any): Observable<any> {
    let url = this.BaseUrl + '/get_pm_locked_projects';
    return this.sharedService.postApi(url, data);
  }

  //  assign projects
  ProjectAssignment(data: any): Observable<any> {
    let url = this.BaseUrl + '/project_language_assignment';
    return this.sharedService.postApi(url, data);
  }

  //  assign projects workflow additional services
  ProjectAssignmentAddition_service(data: any): Observable<any> {
    let url = this.BaseUrl + '/project_language_assignment/addition_service';
    return this.sharedService.postApi(url, data);
  }


  getProjectAssignment(query: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/project/assignment?' + query;
    return this.sharedService.postApi(url, data);
  }

  // workflows
  getWorkFlow(): Observable<any> {
    let url = this.BaseUrl + '/workflow/1';
    return this.sharedService.getApi(url);
  }

  // workflows additional service
  getWorkFlowAdditionalServices(): Observable<any> {
    let url = this.BaseUrl + '/workflow/3';
    return this.sharedService.getApi(url);
  }

  // get Unique Project Language
  getUniqueProjectLanguage(data: any): Observable<any> {
    let url = this.BaseUrl + '/project_language';
    let headers = SharedService.getPutHeader();
    return this.http.post(url,data, { headers });
  }

  // update project language
  updateProjectLanguage(data: any): Observable<any> {
    let url = this.BaseUrl + '/update_project_language';
    return this.sharedService.postApi(url, data);
  }

  

  //  Project Workflow
  projectWorkFlow(data: any): Observable<any> {
    let url = this.BaseUrl + '/project_workflow';
    return this.sharedService.postApi(url, data);
  }

  //  get Project Workflow
  WorkFlowByProjectId(data: any): Observable<any> {
    let url = this.BaseUrl + '/project/worflows';
    return this.sharedService.postApi(url, data);
  }


  deleteWorkFlow(id: any): Observable<any> {
    let url = this.BaseUrl + '/project_workflow/' + id;
    return this.sharedService.deleteApi(url);
  }


  ProjectDelivered(data: any): Observable<any> {
    let url = this.BaseUrl + '/project/delivered';
    return this.sharedService.postApi(url, data);
  }

  getPmsProjects(query): Observable<any> {
    let url = this.BaseUrl + '/project/search' + query;
    return this.sharedService.getApi(url);

  }

  getReport(query): Observable<any> {
    let url = this.BaseUrl + '/get_report' + query;
    return this.sharedService.getApi(url);
  }


  getLanguages() {
    let url = this.BaseUrl + '/language?per_page=500';
    return this.sharedService.getApi(url);
  }

  getAllLanguages() {
    let url = this.BaseUrl + '/all_language?per_page=500';
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers });
  }

  getProjectStatus() {
    let url = this.BaseUrl + '/projectstage?per_page=500';
    return this.sharedService.getApi(url);
  }

  getcustomer() {
    let url = this.BaseUrl + '/customer';
    return this.sharedService.getApi(url);
  }

  getcustomerForLead(query) {
    let url = this.BaseUrl + '/customer/search'+query;
    return this.sharedService.getApi(url);
  }



  getProjectManager() {
    let url = this.BaseUrl + '/user?per_page=500';
    return this.sharedService.getApi(url);
  }


  getPortalUsers(query): Observable<any> {
    let url = this.BaseUrl + '/user?' + query + 'user_role=Admin,Finance-Manager,Hr-Admin,Operation-Manager,Project-Manager';
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers });
  }


  getAccountType() {
    let url = this.BaseUrl + '/account_type?per_page=100';
    let headers = SharedService.getPutHeader();
    return this.http.get(url, { headers });
  }

  getIndustry() {
    let url = this.BaseUrl + '/industry?per_page=100';
    return this.sharedService.getApi(url);
  }

   
  getLeadSource() {
    let url = this.BaseUrl + '/source?per_page=50';
    return this.sharedService.getApi(url);
  }


  getProjectQuotes(query): Observable<any> {
    let url = this.BaseUrl + '/quote/search' + query;
    return this.sharedService.getApi(url);
  }


  getProjectCustomer(query): Observable<any> {
    let url = this.BaseUrl + '/customer/search' + query;
    return this.sharedService.getApi(url);
  }


  getProjectSingleCustomer(id: any): Observable<any> {
    let url = this.BaseUrl + '/customer/' + id;
    return this.sharedService.getApi(url);
  }

  getFirst_Quote_Project(data: any): Observable<any> {
    let url = this.BaseUrl + '/customer/first_quote_project';
    return this.sharedService.postApi(url, data);
  }

  getCustomerProjects(id: any, query): Observable<any> {
    let url = this.BaseUrl + '/customer/project/' + id + query;
    return this.sharedService.getApi(url);
  }

  
  addCustomer(data: any): Observable<any> {
    let url = this.BaseUrl + '/customer/addCustomer';
    return this.sharedService.postApi(url, data);
  }



  getvendorbyskill(data: any): Observable<any> {
    let url = this.BaseUrl + '/project/freelancer/skill';
    return this.sharedService.postApi(url, data);
  }


  projectGraph(data: any): Observable<any> {
    let url = this.BaseUrl + '/project/graph';
    return this.sharedService.postApi(url, data);
  }


  getAdditionalServices(id: any): Observable<any> {
    let url = this.BaseUrl + '/project/additional_services/' + id;
    return this.sharedService.getApi(url);
  }

  getMailingAddress(id: any): Observable<any> {
    let url = this.BaseUrl + '/customer/account_address/' + id;
    return this.sharedService.getApi(url);
  }


  getProjectLanguageAssignment(id: any): Observable<any> {
    let url = this.BaseUrl + '/project_language_assignment/project/' + id;
    return this.sharedService.getApi(url);
  }

  getTranslatorMessages(): Observable<any> {
    let url = this.BaseUrl + '/project/messages/latest';
    return this.sharedService.getApi(url);

  }


  sendTranslatorMessages(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/project/' + id + '/comments';
    return this.sharedService.postApi(url, data);
  }

  //  assign projects
  changeStatusAdditionalService(data: any): Observable<any> {
    let url = this.BaseUrl + '/project/additional_service/status';
    return this.sharedService.postApi(url, data);
  }

  getFreelancer(id: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/' + id + '?include=vendoravailabilities,vendorSubtitler,vendorfiles,vendorjobs,vendorspecialities,vendortranslationlangs,vendornativelanguage,vendorprofileimage,vendorresume,vendorcontract,vendorcomment,vendorskill,extradocument,vendorTreping,vendorMarketingSkillPrice';
    return this.sharedService.getApi(url);
  }

  getFreelancerProjectHistory(id: any, query: any): Observable<any> {
    let url = this.BaseUrl + '/vendor/projects/' + id + query;
    return this.sharedService.getApi(url);
  }

  get_quote_email(data: any): Observable<any> {
    let url = this.BaseUrl + '/get_quote_email';
    let headers = SharedService.getPutHeader();
    return this.http.post(url, data, { headers });
  }

  get_delivery_email(data: any): Observable<any> {
    let url = this.BaseUrl + '/project_delivered_email';
    let headers = SharedService.getPutHeader();
    return this.http.post(url, data, { headers });
  }

  getIpAddress(): Observable<any> {
    let url = this.BaseUrl + '/ip_Address';
    return this.sharedService.getApi(url);
  }

  getService() {
    let url = this.BaseUrl + '/service';
    return this.sharedService.getApi(url);
  }

  updateSpeciality(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/project/' + id;
    return this.sharedService.patchApi(url, data);
  }

  updateDeadline(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/project/' + id;
    return this.sharedService.patchApi(url, data);
  }

  updateServiceType(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/project/' + id;
    return this.sharedService.patchApi(url, data);
  }

    // Get Discount Type promotion
    getDiscountType(): Observable<any> {
      let url = this.BaseUrl + '/discount_type';
      return this.sharedService.getApi(url);
    }

    addLead(data: any): Observable<any> {
      let url = this.BaseUrl + '/project/new_lead';
      return this.sharedService.postApi(url, data);
    }

    getTopPmsSearch(query): Observable<any> {
      let url = this.BaseUrl + '/search' + query;
      return this.sharedService.getApi(url);
  
    }

    getTopHrSearch(query,type='translator'): Observable<any> {
      let url = this.BaseUrl + `/vendors?page=1&per_page=10&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage,vendorskill&vendor_type=${type}` + query;
      return this.sharedService.getApi(url);
  
    }

    getAccountSearch(query): Observable<any> {
      let url = this.BaseUrl + '/account/search' + query;
      return this.sharedService.getApi(url);
  
    }

    // Notifications

    allNotification(query): Observable<any> {
      let url = this.BaseUrl + '/me/notification' + query;
      return this.sharedService.getApi(url);
  
    }

    unreadNotification(): Observable<any> {
      let url = this.BaseUrl + '/me/notification?read_status=unread' ;
      return this.sharedService.getApi(url);
  
    }

    notificationRead(data: any): Observable<any> {
      let url = this.BaseUrl + '/me/notification/all/read';
      return this.sharedService.postApi(url, data);
    }
    

    
  updateCustomer(id: any, data: any): Observable<any> {
    let url = this.BaseUrl + '/customer/' + id;
    return this.sharedService.patchApi(url, data);
  }

    //  Add additional service
    addAdditionalService(data: any): Observable<any> {
      let url = this.BaseUrl + '/project/additional_services';
      return this.sharedService.postApi(url, data);
    }

    // Delete project additional service
    deleteProjectAdditionalService(id: any): Observable<any> {
      let url = this.BaseUrl + '/project_additional_service/' + id;
      return this.sharedService.deleteApi(url);
    }
    
    //  Add multiple upload files 
    uploadFile(data: FormData): Observable<any> {
      let url = this.BaseUrl + '/vendors/uploadfile';
      return this.sharedService.postFileApi(url, data);
    }


    // whisper upload file
    whisperUploadFile(data: FormData): Observable<any> {
      let url = environment.whisperUrl+'/upload';
      return this.sharedService.postFileWhisperApi(url, data);
    }


    // whisper upload file
    whisperGetSubTitlesByUrl(payload: {}): Observable<any> {
      let url = environment.whisperUrl+'/extract-subtitle';
      return this.sharedService.postFileWhisperApi(url, payload);
    }


      //Add ticket
      addTicket(data: any): Observable<any> {
        let url = this.BaseUrl + '/new_ticket';
        return this.sharedService.postApi(url, data);
      }
  
      //to show ticket history
      getTicketdata(query): Observable<any> {
        let url = this.BaseUrl + '/ticket' + query;
        return this.sharedService.getApi(url);
      }

      // Get project email logs
      getProjectEmailLog(id:any): Observable<any> {
        let url = this.BaseUrl + '/project_email_log/' + id;
        return this.sharedService.getApi(url);
      }

      // getProjectEmailById(id:any): Observable<any> {
      //   let url = this.BaseUrl + '/email_log/' + id;
      //   return this.sharedService.getApi(url);
      // }

      getProjectEmailById(id:any): Observable<any> {
        let url = this.BaseUrl + '/email_log/' + id;
        let headers = SharedService.getPutHeader();
        return this.http.get(url, { headers });
      }


      // Activity

      projectActivity(id:any): Observable<any> {
        let url = this.BaseUrl + '/project_activity/'+ id ;
        return this.sharedService.getApi(url);
    
      }


      // transactions start

      searchTransaction(data: any): Observable<any> {
        let url = this.BaseUrl + '/search_transaction';
        return this.sharedService.postApi(url, data);
        }
      searchStripeTransaction(data: any): Observable<any> {
        let url = this.BaseUrl + '/stripe_search_transaction';
        return this.sharedService.postApi(url, data);
        }
        
        //
        searchcustomerTransaction(data: any): Observable<any> {
          let url = this.BaseUrl + '/search/customer_card_detail';
        return this.sharedService.postApi(url, data);
        }
        searchCustomerStripeTransaction(data: any): Observable<any> {
          let url = this.BaseUrl + '/search/stripe_customer_card_detail';
        return this.sharedService.postApi(url, data);
        }
        
        //Create Payment
        customerPayment(data: any): Observable<any> {
          let url = this.BaseUrl + '/customer_payment';
        return this.sharedService.postApi(url, data);
        }
        customerStripePayment(data: any): Observable<any> {
          let url = this.BaseUrl + '/stripe_customer_payment';
        return this.sharedService.postApi(url, data);
        }


        newCustomerPayment(data: any): Observable<any> {
          let url = this.BaseUrl + '/new_customer_payment';
            return this.sharedService.postApi(url, data);
    
        }
        newCustomerStripePayment(data: any): Observable<any> {
          let url = this.BaseUrl + '/stripe_new_customer_payment';
            return this.sharedService.postApi(url, data);
    
        }

          // transaction end

    // Get mark as 

    getMarkAsStatus() {
      let url = this.BaseUrl + '/status';
      return this.sharedService.getApi(url);
    }

    updateMarkAsStatus(id: any, data: any): Observable<any> {
      let url = this.BaseUrl + '/project/' + id;
      return this.sharedService.patchApi(url, data);
    }
       
       // Delete project 
    deleteProject(id: any): Observable<any> {
      let url = this.BaseUrl + '/project/' + id;
      return this.sharedService.deleteApi(url);
    }

    // update lead enquiry date
    updateLeadEnquiryDate(data: any): Observable<any> {
      let url = this.BaseUrl + '/update_lead_enquiry_date';
      return this.sharedService.postApi(url, data);
    }

       // Delete project file
       deleteProjectFile(id: any): Observable<any> {
        let url = this.BaseUrl + '/projectfile/' + id;
        return this.sharedService.deleteApi(url);
      }

      // Delete customer
      deleteCustomer(id: any): Observable<any> {
        let url = this.BaseUrl + '/customer/' + id;
        return this.sharedService.deleteApi(url);
      }

      getTransaction(id:any) {
        let url = this.BaseUrl + '/quote_detail/'+id;
        let headers = SharedService.getPutHeader();
        return this.http.get(url, { headers });
      }
      getStripeTransaction(id:any) {
        let url = this.BaseUrl + '/quote_detail/'+id;
        let headers = SharedService.getPutHeader();
        return this.http.get(url, { headers });
      }

      //Account Listing
      getAccountListing(query) {
        let url = this.BaseUrl + '/account'+query+'&include=accountaddress.address,createdby,assignedto,industry';
        return this.sharedService.getApi(url);
      }

      // get project with account detail
      getAccountProjects(id:any) {
        let url = this.BaseUrl + '/account/'+id+'?include=accountaddress.address,createdby,accountproject.project,assignedto,accountcustomer';
        return this.sharedService.getApi(url);
      }
    
      //Update Account Detail
      updateAccountDetail(id: any, data: any): Observable<any> {
        let url = this.BaseUrl + '/account/' + id;
        return this.sharedService.patchApi(url, data);
      }
  
        // add account
  addAccount(data: any): Observable<any> {
    let url = this.BaseUrl + '/account';
    let headers = SharedService.getPutHeader();
    return this.http.post(url,data, { headers });
  }

  // Calender get api
  getCalenderEvents(): Observable<any> {
    let url = this.BaseUrl + '/calender/project';
    return this.sharedService.getApi(url);
  }

  updateProjectCrmStatus(data: any): Observable<any> {
    let url = this.BaseUrl + '/update_project_crm_status';
    return this.sharedService.postApi(url, data);
  }

  getOcrFile(data: any): Observable<any> {
    let url = this.BaseUrl + '/process_image_ocr';
    return this.sharedService.postApi(url, data);
  }

     //to show ocr history
    getOcrHistory(query): Observable<any> {
      let url = this.BaseUrl + '/file_process?status=2' + query ;
      return this.sharedService.getApi(url);
    }

       //to show get Sms Twillo
       getSmsTwillo(query): Observable<any> {
        let url = this.BaseUrl + '/sms_twillo' + query ;
        return this.sharedService.getApi(url);
      }

      sendMessage( data: any): Observable<any> {
        let url = this.BaseUrl + '/send_sms';
        return this.sharedService.postApi(url, data);
      }

      /** GET heroes from the server */
  getWhatsapps(): Observable<any> {
    let url = this.BaseUrl + '/wtsp_conversations';
    return this.sharedService.getApi(url);
  }

  getSmsCount(): Observable<any> {
    let url = this.BaseUrl + '/sms_conversations/count';
    return this.sharedService.getApi(url);
  }

   getWhatsappCount(): Observable<any> {
    let url = this.BaseUrl + '/wtsp_conversations/count';
    return this.sharedService.getApi(url);
  }

  

  getWhatsappsSearch(query): Observable<any> {
    let url = this.BaseUrl + '/wtsp_conversations'+ query;
    return this.sharedService.getApi(url);
  }

  getWhatsappHero(id: number): Observable<any> {
    let url = this.BaseUrl + '/wtsp_conversations/'+ id;
    return this.sharedService.getApi(url);
  }

  
  sendWhatsappto( data: any): Observable<any> {
    let url = this.BaseUrl + '/wtsp_conversations';
    return this.sharedService.postApi(url, data);
  }

  // sendWhatsappto(hero: any): Observable<any> {
  //   const url = `${this.heroesUrl}conversation/outgoingwhatsapp`;

  //   let bodyparams = new HttpParams();
  //   var tonumber = hero.tonumber;
  //   var content = hero.body;
  //   bodyparams = bodyparams.set('tonumber', tonumber);
  //   bodyparams = bodyparams.set('content', content);
  //   return this.http.post<any>(url, bodyparams).pipe(
      // tap((hero: any) => this.log(`added hero w/ id=${hero.id}`)),
      // catchError(this.handleError<any>('addHero'))
    // );
   // return this.http.post<any>(url, hero, httpOptions);
  // }

  getWhatsappTotalUnread(id: number): Observable<any> {
    let url = this.BaseUrl + '/wtsp_conversations/getunreadmessages/'+ id;
    return this.sharedService.getApi(url);
  }

  // sms api's
  getSms(): Observable<any> {
    const url = this.BaseUrl + '/sms_conversations';
    return this.sharedService.getApi(url);
  }
  
  getSmsHero(id: number): Observable<any> {
    let url = this.BaseUrl + '/sms_conversations/' + id;
    return this.sharedService.getApi(url);
  }

  sendSmsto( data: any): Observable<any> {
    let url = this.BaseUrl + '/sms_conversations';
    return this.sharedService.postApi(url, data);
  }

  markAsReadSms( data: any): Observable<any> {
    let url = this.BaseUrl + '/sms_conversations/mark_read';
    return this.sharedService.postApi(url, data);
  }

  markAsReadWhatsapp( data: any): Observable<any> {
    let url = this.BaseUrl + '/wtsp_conversations/mark_read';
    return this.sharedService.postApi(url, data);
  }

  getSmsTotalUnread(id: number): Observable<any> {
    let url = this.BaseUrl + '/sms_conversations/getunreadmessages/'+ id;
    return this.sharedService.getApi(url);
  } 

  // cancel the assigned projects
  cancelAssignProject(data: any): Observable<any> {
    let url = this.BaseUrl + '/project_language_assignment/cancel';
    return this.sharedService.postApi(url,data);
  }

  getCrmdocuments(id: string): Observable<any> {
    let url = this.BaseUrl + '/document_info/'+ id;
    return this.sharedService.getApi(url);
  }

  getDetailMain(id: string): Observable<any> {
    let url = this.BaseUrl + '/quote_info/'+ id;
    return this.sharedService.getApi(url);
  }

  getAutoQuoteInfo(id: string): Observable<any> {
    let url = this.BaseUrl + '/auto_quote_info/'+ id;
    return this.sharedService.getApi(url);
  }

  getCrmAccountInfo(id: string): Observable<any> {
    let url = this.BaseUrl + '/account_info/'+ id;
    return this.sharedService.getApi(url);
  }

  getAddonInfo(id: string): Observable<any> {
    let url = this.BaseUrl + '/add_on_service_info/'+ id;
    return this.sharedService.getApi(url);
  }

  getCrmEmailLog(id: string): Observable<any> {
    let url = this.BaseUrl + '/crm_email_info/'+ id;
    return this.sharedService.getApi(url);
  }

  getEmailTemp(id: number): Observable<any> {
    let url = this.BaseUrl + '/emails/'+ id;
    return this.sharedService.getApi(url);
  }


  add(data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/add_temp_translator';
    return this.sharedService.postApi(url, data);
  }


  getFreelancerEmailTemplate(): Observable<any> {
    let url = this.BaseUrl + '/email/freelancer_email';
    return this.sharedService.getApi(url);
  }

  sendVendorRushEmail(data: any): Observable<any> {
    let url = this.BaseUrl + '/vendors/email_for_rush_project';
    return this.sharedService.postApi(url, data);
  }

  markAsRushProject(data: any): Observable<any> {
    let url = this.BaseUrl + '/mark_as_rush_propject';
    return this.sharedService.postApi(url, data);
  }
}
