import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var SendWhatsappMessageComponent = /** @class */ (function () {
    function SendWhatsappMessageComponent(_formBuilder, toastr, projectsService, dialogRef) {
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.projectsService = projectsService;
        this.dialogRef = dialogRef;
    }
    SendWhatsappMessageComponent.prototype.ngOnInit = function () {
        this.Form = this._formBuilder.group({
            recipient: ['', Validators.compose([Validators.required, Validators.pattern("^\\+(?:[0-9] ?){10,14}[0-9]$")])],
            body: [''],
            contentTemplate: ['', Validators.compose([Validators.required])]
        });
    };
    SendWhatsappMessageComponent.prototype.Onclose = function () {
        this.dialogRef.close(true);
    };
    SendWhatsappMessageComponent.prototype.submit = function () {
        var _this = this;
        if (this.Form.value.body.length > 250) {
            this.toastr.error('Maximum 250 characters');
            return false;
        }
        var recipientFormat = this.Form.value.recipient.replace("+", "");
        if (this.Form.valid) {
            var obj = {
                recipient: recipientFormat,
                body: this.Form.value.body,
                contentTemplate: this.Form.value.contentTemplate
            };
            this.projectsService.sendWhatsappto(obj)
                .subscribe(function (response) {
                _this.toastr.success("Message sent Successfully");
                _this.dialogRef.close(true);
            });
        }
    };
    return SendWhatsappMessageComponent;
}());
export { SendWhatsappMessageComponent };
