import { OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { CrmEmailLogPreviewComponent } from './crm-email-log-preview/crm-email-log-preview.component';
var CRMEmailLogComponent = /** @class */ (function () {
    function CRMEmailLogComponent(projectsService, toastr, route, dialog) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.route = route;
        this.dialog = dialog;
        this.emailLogResponse = [];
        this.paginate = {};
        this.pageSize = 10;
        this.query_projects = "";
        this.pageIndex = 1;
        this.allData = [];
        this.total = 0;
        this.CrmEmailResponse = '';
        this.displayedColumns = ['subject', 'sent_on', 'status'];
    }
    CRMEmailLogComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getProjectDetail();
    };
    // popup
    CRMEmailLogComponent.prototype.openDialogEmail = function (data) {
        var dailogRef = this.dialog.open(CrmEmailLogPreviewComponent, {
            width: '1700px',
            data: data
        });
    };
    CRMEmailLogComponent.prototype.getProjectDetail = function () {
        var _this = this;
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.getCrmEmailLog(response.data.crm_opp_id);
            }
        });
    };
    CRMEmailLogComponent.prototype.getCrmEmailLog = function (id) {
        var _this = this;
        this.projectsService.getCrmEmailLog(id)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.allData = response.data;
            }
        });
    };
    return CRMEmailLogComponent;
}());
export { CRMEmailLogComponent };
