import { OnInit, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ActivatedRoute } from "@angular/router";
var AdditionalInformationNotarizationComponent = /** @class */ (function () {
    function AdditionalInformationNotarizationComponent(projectsService, toastr, route) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.route = route;
        this.apostile_show = false;
        this.notarization_quantity = 0;
        this.notarization_other_state = '';
        this.notarization_additional_quantity = 0;
        this.notarization_affidavit_court = "No";
        this.additonal_service_array = [];
        this.myoutputNotarization = new EventEmitter();
        this.outputString = "(NA)";
    }
    AdditionalInformationNotarizationComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getAdditionalServices();
    };
    AdditionalInformationNotarizationComponent.prototype.getAdditionalServices = function () {
        var _this = this;
        this.projectsService.getAdditionalServices(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.additonal_service_array = response.project_addition_service;
                if (_this.additonal_service_array.length == 0) {
                    _this.sendNotApply();
                }
                if (response.project_addition_service.length > 0) {
                    response.project_addition_service.forEach(function (element) {
                        if (element.additional_service.code == "notarization_coa_il_ca") {
                            // add copy
                            _this.notarization_quantity = element.quantity;
                            _this.apostile_show = true;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == "notarization_other_state") {
                            _this.notarization_other_state = element.notarization_other_state;
                            _this.apostile_show = true;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == "notarization_additional") {
                            _this.notarization_additional_quantity = element.quantity;
                            _this.apostile_show = true;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == "notarization_affidavit_court") {
                            _this.notarization_affidavit_court = "Yes";
                            _this.apostile_show = true;
                            _this.sendApply();
                        }
                    });
                }
            }
        });
    };
    AdditionalInformationNotarizationComponent.prototype.sendNotApply = function () {
        this.outputString = "(NA)";
        var data = {
            title: this.outputString,
            click_value: 0
        };
        this.myoutputNotarization.emit(data);
    };
    AdditionalInformationNotarizationComponent.prototype.sendApply = function () {
        this.outputString = "(Applied)";
        var data = {
            title: this.outputString,
            click_value: 0
        };
        this.myoutputNotarization.emit(data);
    };
    AdditionalInformationNotarizationComponent.prototype.Edit = function () {
        this.outputString = "(Applied)";
        var data = {
            title: this.outputString,
            click_value: 1
        };
        this.myoutputNotarization.emit(data);
    };
    return AdditionalInformationNotarizationComponent;
}());
export { AdditionalInformationNotarizationComponent };
