import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'app/main/app.global';
import { Router, ActivatedRoute } from '@angular/router';
var SearchTransactionComponent = /** @class */ (function () {
    function SearchTransactionComponent(_formBuilder, router, projectsService, toastr, _global, route) {
        this._formBuilder = _formBuilder;
        this.router = router;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this._global = _global;
        this.route = route;
        this.show_record = false;
        this.displayedColumns = ['id', 'amount', 'status', 'cardholderName', 'creditCardNumber', 'email', 'date'];
    }
    SearchTransactionComponent.prototype.ngOnInit = function () {
        this.quote_id = this.route.snapshot.params['id'];
        this.searchTransactionForm = this._formBuilder.group({
            field_name: ['', Validators.required],
            operator: ['', Validators.required],
            field_value: ['', Validators.required],
        });
    };
    SearchTransactionComponent.prototype.search = function () {
        var _this = this;
        var formData = this.searchTransactionForm.value;
        this.show_record = true;
        this.projectsService.searchTransaction(formData)
            .subscribe(function (response) {
            if (response) {
                _this.totaltransaction = response.data.length;
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.paginator = _this.paginator;
            }
        }, function (error) {
            _this.toastr.error(_this._global.errorMessage);
        });
    };
    SearchTransactionComponent.prototype.searchTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/transactions/search/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/transactions/search']);
        }
    };
    SearchTransactionComponent.prototype.CreateTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/transactions/create/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/transactions/create']);
        }
    };
    return SearchTransactionComponent;
}());
export { SearchTransactionComponent };
