import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from '../../shared.service';
import { CrmService } from 'app/main/Services/crm.service';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TableUtil } from 'app/utils/tableUtil';
import * as XLSX from "xlsx";
@Component({
  selector: 'app-gclid',
  templateUrl: './gclid.component.html',
  styleUrls: ['./gclid.component.scss']
})
export class GclidComponent implements OnInit {
  dataSource: any;
  paginate: any = {};
  pageIndex: number = 1;
  query_approve: string = '';
  filterSpecialization: string = '';
  notArchive: string = 'Unavailable,Blacklisted,Closed';
  filter_src_lang: string = '';
  filter_target_lang: string = '';
  fQuery: string = '';
  ids_progress: any = [];
  all_freelancer_id: any = [];
  bulk_selected_progress: boolean = false;
  dataSource1: any;
  send_email_progress_data: any = {};
  emailTemplateData: string = '';
  login_enable: Boolean = true;
  specialityData: any = [];
  query_user_sort: string = '';
  languageData: any = [];
  pageSize: number = 10;
  filter_native_lang: number = null;
  fdata: any =
    {
      specialization: '',
      Source: '',
      Target: '',
      Native: ''
    }
  ApprovaldisplayedColumns = ['type','gclid','msclkid','currency','amount','payment_status','date_entered'];
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatSort)
  sort2: MatSort;
  @ViewChild(MatSort)
  sort3: MatSort;

  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;

  constructor(private _fuseNavigationService: FuseNavigationService,
    private crmService: CrmService, private projectsService: ProjectsService,
    private toastr: ToastrService, private router: Router, private dialog: MatDialog) {


  }
  ngOnInit() {
    this.getGclids();
  }
  applyFilterApproved(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getGclids() {
    let query = '';
    let gclidFilter = localStorage.getItem('filter');
    let filterObject = JSON.parse(gclidFilter);
    let gclidPagination = localStorage.getItem('pagination');
    let gclidObject = JSON.parse(gclidPagination);
    if (gclidFilter != null && filterObject.filter) {
      query = filterObject.filter;
    } else if (gclidPagination !== null && gclidObject.table_name == 'gclidPagination') {
      query = '?page=' + gclidObject.page + '&per_page=' + gclidObject.per_page;
    } else {
      query = '?page=1&per_page=200&test=1&not_archive=' + this.notArchive;

    }
    this.crmService.getAllGclid(query)
      .subscribe((response) => {
        if (response) {
          this.dataSource1 = response;
          this.dataSource = new MatTableDataSource(response);
          //this.paginate = response.meta.pagination;
        }
      });
  }
  export() {
    let data=[['Type','Google Click ID (GCLID)','Microsofts Click ID (MSCLKID)', 'Currency','Amount','Payment Status','Date Entered']];
    this.dataSource1.forEach(record=>{
        let paymentDetail=`Method: ${record.payment_method_c}
          Status: ${record.payment_status_c} / ${record.approval_payment_dd_c}`
        data.push([(record.type=='ld'?'Lead':'Opportunity'),record.gclid_c,record.msclkid_c,(record.currencyName?record.currencyName + ':'+ record.currencySymbol:'US Dollars : $'),(record.type=="ld"?record.opportunity_amount:record.amount),paymentDetail,record.date_entered])
    })
    TableUtil.exportArrayToExcel(data);
  }

}


