import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
var ToolsComponent = /** @class */ (function () {
    function ToolsComponent(vendorService, route, projectsService, router, toastr, _formBuilder) {
        this.vendorService = vendorService;
        this.route = route;
        this.projectsService = projectsService;
        this.router = router;
        this.toastr = toastr;
        this._formBuilder = _formBuilder;
        this.fileError = '';
        this.file_multiple_data = [];
        this.file_id_array = [];
        this.uploaded_files = [];
        this.uploaded_files_array = [];
        this.mainprogressbar = false;
        this.mainProgressbarConverted = false;
        this.mainProgressbarhistory = false;
        this.download_file = '';
        this.download_file_array = [];
        this.paginate = {};
        this.pageSize = 20;
        this.pageIndex = 1;
        this.query = "";
        this.filterSearchQuery = "";
        this.fQuery = "";
        this.project_id = '';
        this.displayedColumns = ['id', 'file_name', 'created_at', 'download'];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    ToolsComponent.prototype.ngOnInit = function () {
        this.href = this.router.url;
        if (this.href != '/pms/tools') {
            this.project_id = localStorage.getItem('Project_id');
        }
        // this.project_id = this.route.snapshot.params['id'];
        this.getOcrHistory();
        this.Form = this._formBuilder.group({
            search_value: [''],
        });
    };
    // start  file upload
    ToolsComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        this.uploaded_files_array = [];
        this.download_file_array = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'ppt', 'pptx'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = "File size should not be more than 20MB.";
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, ppt or pptx format.';
                    _this.fileSuccess = '';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    // addDocument
    ToolsComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        this.mainprogressbar = true;
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append("file[]", file[x]);
        }
        uploadData.append("project_reference", 'Image-Process-Queue');
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files_array = [];
            _this.file_id_array = [];
            _this.uploaded_files = response.data;
            _this.uploaded_files_array.push(_this.uploaded_files);
            response.data.forEach(function (element) {
                _this.fileId = element.id;
                _this.file_id_array.push(element.id);
            });
            _this.fileError = '';
            _this.fileSuccess = 'Uploaded successfully!!';
            _this.getOcrFile(_this.file_id_array);
            _this.mainprogressbar = false;
        }, function (error) {
            _this.mainprogressbar = false;
        });
    };
    // end  file upload
    ToolsComponent.prototype.getOcrFile = function (fileId) {
        var _this = this;
        this.mainProgressbarConverted = true;
        var data = {
            file_id: fileId,
            project_id: this.project_id
        };
        this.projectsService.getOcrFile(data).pipe().subscribe(function (response) {
            _this.download_file = '';
            _this.download_file_array = [];
            if (response.status === true) {
                var data_1 = {
                    file_name: '',
                    file_url: ''
                };
                response.data.forEach(function (element) {
                    data_1 = {};
                    data_1.file_name = element.file_name;
                    data_1.file_url = _this.BaseUrl_file + element.file_path;
                    _this.download_file_array.push(data_1);
                });
                _this.mainProgressbarConverted = false;
                _this.getOcrHistory();
                _this.toastr.success("Success");
            }
            else {
                _this.toastr.error(response.file_path);
                _this.mainProgressbarConverted = false;
            }
        });
    };
    // OCR HISTORY
    ToolsComponent.prototype.getOcrHistory = function () {
        var _this = this;
        this.mainProgressbarhistory = true;
        var query = '';
        this.projectsService.getOcrHistory(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.mainProgressbarhistory = false;
                // this.totalticket=response.meta.pagination.total;
            }
        }, function (error) {
            _this.mainProgressbarhistory = false;
        });
    };
    ToolsComponent.prototype.paginateData = function (event) {
        var _this = this;
        this.mainProgressbarhistory = true;
        var pQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '&page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query += pQuery;
        if (this.Form.value.search_value !== "") {
            this.query += "&search_text=" + this.Form.value.search_value;
        }
        this.projectsService.getOcrHistory(this.query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.mainProgressbarhistory = false;
            }
        }, function (error) {
            _this.mainProgressbarhistory = false;
        });
    };
    ToolsComponent.prototype.submit = function () {
        this.onFilter(this.Form.value.search_value, 'search');
    };
    ToolsComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '&page=1&per_page=10';
        // project search filter
        if ((type == "search" && evt != "" && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        else if (type == "search" && evt == "") {
            this.filterSearchQuery = evt;
        }
        else if (this.filterSearchQuery !== "") {
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        var query = this.fQuery;
        this.projectsService.getOcrHistory(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    return ToolsComponent;
}());
export { ToolsComponent };
