import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-send-whatsapp-message',
  templateUrl: './send-whatsapp-message.component.html',
  styleUrls: ['./send-whatsapp-message.component.scss']
})
export class SendWhatsappMessageComponent implements OnInit {
  Form:any;
  constructor(private _formBuilder: FormBuilder, private toastr: ToastrService,
    private projectsService: ProjectsService, 
    public dialogRef: MatDialogRef<SendWhatsappMessageComponent>) { }

  ngOnInit() {
    this.Form = this._formBuilder.group({
      recipient   : ['', Validators.compose([Validators.required, Validators.pattern("^\\+(?:[0-9] ?){10,14}[0-9]$")])],
      body : [''],
      contentTemplate:['', Validators.compose([Validators.required])]
      
  });
  }

  Onclose(){
    this.dialogRef.close(true);
}


submit() {
  if(this.Form.value.body.length > 250){
    this.toastr.error('Maximum 250 characters');
    return false;
  }
  const recipientFormat = this.Form.value.recipient.replace("+", "");
  if (this.Form.valid) {
   let obj ={
    recipient: recipientFormat,
     body: this.Form.value.body,
     contentTemplate:this.Form.value.contentTemplate
   }

  this.projectsService.sendWhatsappto(obj)
    .subscribe(
      (response: any) => {
        this.toastr.success("Message sent Successfully");
        this.dialogRef.close(true);
      }
    );
  }
}
}
