import { OnInit } from '@angular/core';
import { ChatService } from 'app/main/Services/chat.service';
var KbComponent = /** @class */ (function () {
    function KbComponent(chatService) {
        this.chatService = chatService;
        this.messageList = [];
    }
    KbComponent.prototype.sendMessage = function () {
        this.chatService.sendMessage(this.newMessage);
        this.newMessage = '';
    };
    KbComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chatService
            .getMessages()
            .subscribe(function (message) {
            _this.messageList.push(message);
        });
    };
    return KbComponent;
}());
export { KbComponent };
