<mat-toolbar >
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title text-uppercase">Change status</span>
      <button mat-icon-button (click)="Onclose()"  matTooltip="Close" aria-label="Close dialog">
              <mat-icon>close</mat-icon>
      </button>
  </mat-toolbar-row>
</mat-toolbar>

<div mat-dialog-content class="p-16 m-0" fusePerfectScrollbar>
  <form class="mat-employee-form" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
     name="form" [formGroup]="Form">


    <div fxLayout="row" class="mt-8" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field   fxFlex="100">
          <mat-label> Status</mat-label>
          <mat-select formControlName="status">
              <mat-option [value]="">
                  Select Status
              </mat-option>
              
              <mat-option [value]="1">
                Assigned
              </mat-option>
              <mat-option [value]="5">
              Completed
              </mat-option>
              <mat-option [value]="7">
                Cancel
                </mat-option>
          </mat-select>
          <mat-error *ngIf="Form.get('status').hasError('required')">
           Status is required!
        </mat-error>
      </mat-form-field>
  </div>


<div fxLayout="row" class="mt-8" fxLayoutAlign="start center" fxFlex="1 0 auto">
  
  <mat-form-field   fxFlex="100">
    <mat-label>Tracking Inforamtion</mat-label>
    <textarea matInput placeholder="Tracking Inforamtion" formControlName="final_comment"> </textarea>
    <mat-error *ngIf="Form.get('final_comment').hasError('required')">
      Tracking Inforamtion is required!
   </mat-error>
</mat-form-field>
</div>
    <div fxLayoutAlign="space-between end" >
      <div></div>
      <div>
        <button mat-raised-button color="primary" (click)="submit()" [disabled]="Form.invalid" class="mr-8">Submit</button>
      </div>
     </div>


    </form>
</div>    