<div class="mopt p-20 " *ngIf="additonal_service_array.length==0 || !mailing_show">
  <p>Not Applied</p>
</div>

<div class="mopt p-20" *ngIf="additonal_service_array.length>0 && mailing_show">

  <div *ngIf="show_address">
    <div *ngFor="let res of additonal_service_array">

      <div *ngIf="res.additional_service.code == 'certified_Mailing' || 
            res.additional_service.code == 'certified_Mailing_1Day' ||
             res.additional_service.code == 'certified_Mailing_International' ||
             res.additional_service.code == 'certified_Mailing_Canada' ">

       <p *ngIf="res?.additional_service?.title"><b> Mailing Options : </b> {{res?.additional_service?.title}}</p>
        <p *ngIf="res?.shipping_address"><b>Street Address:</b> {{res?.shipping_address}}</p>
        <p *ngIf="res?.shipping_city"><b> City : </b> {{res?.shipping_city}}</p>
        <p *ngIf="res?.shipping_state"><b> State : </b> {{res?.shipping_state}}</p>
        <p *ngIf="res?.shipping_country"> <b> Country : </b>{{res?.shipping_country}}</p>
        <p *ngIf="res?.shipping_phone"><b>Phone Number:</b> {{res?.shipping_phone}} </p>
      </div>

      <div *ngIf="res.additional_service.code == 'additional_copy' ">
        <p *ngIf="res.additional_service.title"><b> Mailing Options : </b> {{res.additional_service.title}}</p>
        <p *ngIf="res?.quantity"> <b> Additional copy required:</b> {{res?.quantity}} Set</p>
      </div>

      <div *ngIf="res.additional_service.code == 'regular_PickUp' ">
          <p *ngIf="res.additional_service.title"><b> Mailing Options : </b> {{res.additional_service.title}}</p>
          <p *ngIf="res?.pickup_location"><b > PickUp Location : </b> {{res?.pickup_location}}</p>
        </div>
    </div>
  </div>
  <div fxLayoutAlign="space-between end" >
    <div></div>
    <div>
      <button mat-raised-button color="primary" (click)="Edit()"  class="mr-8">Edit</button>
    </div>
   </div>
</div>