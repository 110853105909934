import { Component, OnInit } from '@angular/core';
import {  Input ,Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-whatsapp-attachment',
  templateUrl: './whatsapp-attachment.component.html',
  styleUrls: ['./whatsapp-attachment.component.scss']
})
export class WhatsappAttachmentComponent implements OnInit {
  @Input() childAttachment: any;
  peopleArray: any;
  constructor() { }

  ngOnInit() {
  	this.peopleArray = Object.keys(this.childAttachment).map(i => this.childAttachment[i]);
  }

}
