<div id="portal-selector" class="page-layout simple fullwidth">
    <div class="header" fxLayout="row" fxLayoutAlign="start start">
        <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>
            <div class="h1">Hi {{user_name}}, Good Day!</div>
            <div class="h3">
    Based on your roles you have following portal access. You can also select specific portal by default to open.
            </div>
        </div>
    </div>
    <div class="content">
        <mat-grid-list cols="4">
            <mat-grid-tile *ngIf="admin"> 
                <div class="card">
                <div class="card-body">
                        <button class="btnPlain"  (click)="admin_select()">
                    <div class="cinn">
                    <div class="card-icon">
                        <span>
                            <mat-icon>supervised_user_circle</mat-icon>
                        </span>
                    </div>
                    <div class="titleWRaper">
                        <h2>Admin Portal <i class="iconArrow material-icons"> arrow_forward </i> </h2>
                    </div>
                    <hr/>
                    <div class="info">
                        <p>Manage portal users, review and approve freelancers and more</p>
                    </div>
                </div>
            </button>
                <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>
                </div>
            </div>
           </mat-grid-tile >
            <mat-grid-tile *ngIf="hr">  
            <div class="card">
                <div class="card-body">
                        <button class="btnPlain"  (click)="hr_select()">
                        <div class="cinn">
                    <div class="card-icon">
                        <span>
                            <mat-icon>group</mat-icon>
                        </span>
                    </div>
                    <div class="titleWRaper">
                        <h2>HRD Portal <i class="iconArrow material-icons"> arrow_forward </i></h2>
                    </div>
                    <hr />
                    <div class="info" fxLayout="column">
                        <p>Manage freelancers, new resouces, evaluation and more</p>
                    </div> </div>
                    </button>
                    <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>
                </div>
            </div>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="marketing">  
            <div class="card">
                <div class="card-body">
                        <button class="btnPlain"  (click)="marketing_select()">
                        <div class="cinn">
                    <div class="card-icon">
                        <span>
                            <mat-icon>group</mat-icon>
                        </span>
                    </div>
                    <div class="titleWRaper">
                        <h2>Marketing Portal <i class="iconArrow material-icons"> arrow_forward </i></h2>
                    </div>
                    <hr />
                    <div class="info" fxLayout="column">
                        <p>Manage freelancers, new resouces, evaluation and more</p>
                    </div> </div>
                    </button>
                    <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>
                </div>
            </div>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="pms">   
            <div class="card">
                <div class="card-body">
                        <button class="btnPlain" (click)="pms_select()">
                        <div class="cinn">
                    <div class="card-icon">
                        <span>
                            <mat-icon>group_work </mat-icon>
                        </span>
                    </div>
                    <div class="titleWRaper">
                        <h2>PMS Portal <i class="iconArrow material-icons"> arrow_forward </i></h2>
                    </div>
                    <hr />
                    <div class="info" fxLayout="column">
                        <p>Manage project, estimation, assignation, delivery and more</p>
                    </div>
                </div>
             </button>
                    <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>
                </div>
            </div>
            </mat-grid-tile>
            <!-- <mat-grid-tile *ngIf="finance">
                <div class="card">
                    <div class="card-body">
                            <button class="btnPlain" (click)="financial_select()" >
                            <div class="cinn">
                        <div class="card-icon">
                            <span>
                                <mat-icon>monetization_on </mat-icon>
                            </span>
                        </div>
                        <div class="titleWRaper">
                            <h2>Finance Portal <i class="iconArrow material-icons"> arrow_forward </i></h2>
                        </div>
                        <hr />
                        <div class="info" fxLayout="column">
                            <p>Fincancial analytics, revew project lifecycle and more</p>
                        </div>
                            </div>
                    </button>
                        <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>
                    </div>
                </div>
            </mat-grid-tile> -->
        </mat-grid-list>
    </div>
</div>
