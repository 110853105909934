import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProjectsService } from "app/main/Services/projects.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { SendMessageComponent } from './popup/send-message/send-message.component';
import { environment } from 'environments/environment';
import { DataService } from 'app/main/Services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
@Component({
    selector: "app-sms-conversation",
    templateUrl: "./sms-conversation.component.html",
    styleUrls: ["./sms-conversation.component.scss"],
})
export class SmsConversationComponent implements OnInit {
  @ViewChild('chatPS1') chatPS1: PerfectScrollbarComponent;
  @ViewChild('scrollMe1') private myScrollContainer: ElementRef;
    heroes = [];
    fromNumber;
    searchText = "";
    BaseNumber ='';
    hero;
    id: number;
    public heroForm: FormGroup;
    constructor(private projectsService: ProjectsService, private data: DataService,
      private route: ActivatedRoute,
      public formBuilder: FormBuilder, private dialog: MatDialog) {
        this.BaseNumber = environment.BaseNumber;
      }

    ngOnInit() {
      this.id = this.route.snapshot.params['id'];
      if(this.id)
      {
        this.fromNumber = this.id;
      }
        this.getSms();
        this.heroForm = this.formBuilder.group({
          body: this.formBuilder.control('', [Validators.required]),
        });
    }

    getSms(): void {
        this.projectsService.getSms().subscribe((heroes) => {
            this.heroes = [];
            heroes.data.forEach((element) => {
                this.heroes.push(element);
            });
           
            if(this.fromNumber === undefined )
            {
             this.getHero(this.heroes[0].recipient);
            }else{
              this.getHero(this.fromNumber);
            }
        
        });
    }

    getHero(id) {
        this.fromNumber = id;
        this.projectsService.getSmsHero(id).subscribe((response) => {
            let hero = response.sms_conversations;
            var herolength = Object.keys(hero).length;
            for (var i = 0; i < herolength; ++i) {
                if (hero[i]["attachment"] != null) {
                    var attachvar = hero[i]["attachment"];
                    var obj = JSON.parse(attachvar);
                    hero[i]["attachment"] = obj;
                } else {
                }
            }
            this.hero = hero;
            // this.newMessage();
            let scrollerHeight = this.myScrollContainer.nativeElement.offsetHeight;
            this.chatPS1.directiveRef.scrollToTop(scrollerHeight * 100, 100); 
        });
    }

    sendSmsto() {
      if (this.heroForm.valid) {
       let obj ={
        recipient: this.fromNumber,
         body: this.heroForm.value.body
       }
   
      this.projectsService.sendSmsto(obj)
        .subscribe(
          (response: any) => {
            this.heroForm.reset(undefined);
            // this.getHero(this.fromNumber);
            this.getSms();
          }
        );
      }
    }

    // popup
    sendMessagePopup() {
      const dailogRef = this.dialog.open(SendMessageComponent, {
        width: '500px'
  
      })
      dailogRef.afterClosed().subscribe((response) => {
        if (response) {
          // this.getProjectDetail();
          this.getSms();
        }
      });
    }



   newMessage() {
    this.data.changeMessage("sms");
  }

  markAsRead() {

     let obj ={
      recipient: this.fromNumber
     }
 
    this.projectsService.markAsReadSms(obj)
      .subscribe(
        (response: any) => {
          // this.getHero(this.fromNumber);
          this.getSms();
          this.newMessage();
        }
      );
    }

    pageRefresh() {
      this.getSms();
      this.newMessage();
   }
  

}
