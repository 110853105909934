import { UserComponent } from './user/user.component';
import { ReportsComponent } from './reports/reports.component';
import { ApprovalComponent } from './approval/approval.component';
import { AddUserComponent } from './user/add-user/add-user.component';
import { ReviewTranslatorComponent } from './user/review-translator/review-translator.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { EditReviewTranslatorComponent } from './user/edit-review-translator/edit-review-translator.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AdminGuard } from 'app/_guards/admin.guard';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { LanguageMasterComponent } from './masterdata/language-master/language-master.component';
import { LocationMasterComponent } from './masterdata/location-master/location-master.component';
import { AdditionalServicesMasterComponent } from './masterdata/additional-services-master/additional-services-master.component';
import { ServicesMasterComponent } from './masterdata/services-master/services-master.component';
import { WorkflowMasterComponent } from './masterdata/workflow-master/workflow-master.component';
import { TemplateMasterComponent } from './masterdata/template-master/template-master.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketDetailComponent } from './tickets/ticket-detail/ticket-detail.component';
var routes = [
    {
        path: 'user-management',
        component: UserComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'user-management/add-user',
        component: AddUserComponent,
        canActivate: [AdminGuard]
    },
    {
        path: "user-management/:id",
        component: AddUserComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'approval/review-translator/:id',
        component: ReviewTranslatorComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'approval/edit-review-translator',
        component: EditReviewTranslatorComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'approval',
        component: ApprovalComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'admin-dashboard',
        component: AdminDashboardComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'admin-profile',
        component: AdminProfileComponent
    },
    {
        path: 'masterdata',
        component: MasterdataComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'masterdata/language-master',
        component: LanguageMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'masterdata/location-master',
        component: LocationMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'masterdata/additionalservices-master',
        component: AdditionalServicesMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'masterdata/services-master',
        component: ServicesMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'masterdata/template-master',
        component: TemplateMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'masterdata/workflow-master',
        component: WorkflowMasterComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'tickets',
        component: TicketsComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'tickets/:id',
        component: TicketDetailComponent,
        canActivate: [AdminGuard]
    }
];
var AdminModule = /** @class */ (function () {
    function AdminModule() {
    }
    return AdminModule;
}());
export { AdminModule };
