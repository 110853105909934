import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { VendorService } from 'app/main/Services/vendor.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { EmailTemplateViewComponent } from '../email-template-view/email-template-view.component';
import { AddTempTranslatorComponent } from '../add-temp-translator/add-temp-translator.component';
import { DataService } from 'app/main/Services/data.service';
var ProjectQaComponent = /** @class */ (function () {
    function ProjectQaComponent(dialog, route, projectsService, httpService, toastr, dataService, vendorService, _formBuilder, router) {
        this.dialog = dialog;
        this.route = route;
        this.projectsService = projectsService;
        this.httpService = httpService;
        this.toastr = toastr;
        this.dataService = dataService;
        this.vendorService = vendorService;
        this._formBuilder = _formBuilder;
        this.router = router;
        this.file_data = [];
        this.noData = false;
        this.Project_Assigned_obj = [];
        this.Project_Assigned_obj_compare = [];
        this.opportunityAmount = '';
        this.DetailMainResponse = '';
        this.ProjectLanguage_data = [];
        this.target_language_select = [];
        this.vendor_ids = [];
        this.vendor = [];
        this.lang_pair = [];
        this.vendor_array = [];
        this.vendor_array_match = [];
        this.project_workflow_id = 3;
        // vendor_translator_number : any;
        this.lang1 = '';
        this.quote_approve = [];
        this.languageData = [];
        this.show_search = false;
        this.workflow_check = false;
        this.show_table = false;
        this.show_translator = false;
        this.specialityData = [];
        this.response_email_data = [];
        this.response_workflow = [];
        this.assignment_data = {};
        this.languageDataArray = [];
        this.assignment_obj = {};
        this.projectWorkFlowResponse = [];
        this.paginate = {};
        this.pageSize = 10;
        this.pageIndex = 1;
        this.arrType = [];
        this.workflow_data = [];
        this.ProjectAssignedStatus = [];
        this.show_assign_button = true;
        // assign_show:boolean=true;
        this.completed_workflow_array = [];
        this.file_id_data = [];
        this.data = {
            source_lang_id: '',
            project_language_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
        };
        this.data_approved = {
            source_lang_id: '',
            service_id: '',
            project_language_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            quantity: '',
            pricing_method: '',
            rate: '',
            sub_total: ''
        };
        this.customer_duration = false;
        this.assignment_update = false;
        this.bulk_selected_progress = false;
        this.filterSearchQuery = "";
        this.filterSearchObj = {
            specilization_id: Number,
            source_language_id: Number,
            target_language_id: Number
        };
        this.today = new Date();
        this.filterTypeQuery = "";
        this.filterNativeLanguageQuery = "";
        this.filterSpecializationQuery = "";
        this.fQuery = "";
        this.displayedColumns2 = ['id', 'processType', 'languages', 'freelancer', 'plannedStartDate', 'plannedDeadline', 'status'];
        this.displayedColumns = ['position', 'preference', 'name', 'tztype', 'native_language', 'category_level', 'currentJobs', 'completedJobs', 'bestRates'];
        this.BaseUrl_file = environment.baseUrl_file;
        this.translatorType = '';
        this.native_language = '';
        this.specialization = '';
        this.searchTerm = '';
    }
    ProjectQaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.project_id = this.route.snapshot.params['id'];
        this.httpService.get('/assets/TranslatorType.json').subscribe(function (data) {
            _this.arrType = data; // FILL THE ARRAY WITH DATA.
        }, function (err) {
            _this.toastr.warning(err.message);
        });
        this.httpService.get('/assets/ProjectAssignedStatus.json').subscribe(function (data) {
            _this.ProjectAssignedStatus = data; // FILL THE ARRAY WITH DATA.
        }, function (err) {
            _this.toastr.warning(err.message);
        });
        this.httpService.get('/assets/WorkFlowStatus.json').subscribe(function (data) {
            _this.workflow_data = data; // FILL THE ARRAY WITH DATA.
        }, function (err) {
            _this.toastr.warning(err.message);
        });
        this.getLanguage();
        this.getSpeciality();
        this.getEmailTemplate();
        this.getWorkflow();
        this.getProjectDetail();
        this.getProjectAssignment_compare();
        this.getProjectLanguage();
        this.WorkFlowByProjectId();
        this.getProjectAssignment();
        this.Form = this._formBuilder.group({
            duration: ['30', Validators.compose([Validators.required])],
            duration_unit: ['1'],
            duration_comment: [''],
            email_template_id: ['', Validators.compose([Validators.required])],
            update_status_interval: ['240', Validators.compose([Validators.required])],
            update_status_note: [''],
            instruction: [''],
            vendor_id: [],
            due_date: ['', Validators.compose([Validators.required])],
        });
        this.workFlowForm = this._formBuilder.group({
            workflow_id: ['', Validators.compose([Validators.required])]
        });
    };
    ProjectQaComponent.prototype.getProjectDetail = function () {
        var _this = this;
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.project_type = response.data.service_id;
                _this.specialization_id = response.data.specilization_id;
                _this.opportunityAmount = response.data.project_amount;
                _this.getDetailMain(response.data.crm_opp_id);
                _this.DownloadZip();
                response.data.projectLanguage.data.forEach(function (element) {
                    _this.languageDataArray.push(element);
                });
                response.data.projectquote.data.forEach(function (res) {
                    if (res.status == 4) {
                        _this.workflow_check = true;
                        _this.quoteTotal = res.subTotal;
                        res.projectquoteservice.data.forEach(function (element) {
                            _this.data_approved = {
                                source_lang_id: '',
                                service_id: '',
                                project_language_id: '',
                                sourcelanguage: {
                                    data: {
                                        lang_name: ''
                                    }
                                },
                                targetlanguage: {
                                    data: {
                                        lang_name: ''
                                    }
                                },
                                target_lang_id: '',
                                quantity: '',
                                pricing_method: '',
                                rate: '',
                                sub_total: '',
                            };
                            _this.data_approved.quantity = element.quantity;
                            _this.data_approved.pricing_method = element.pricing_method;
                            _this.data_approved.rate = element.rate;
                            _this.data_approved.sub_total = element.subTotal;
                            element.projectlanguages.data.forEach(function (elem) {
                                _this.data_approved.sourcelanguage.data.lang_name = elem.sourcelanguage.data.lang_name;
                                _this.data_approved.targetlanguage.data.lang_name = elem.targetlanguage.data.lang_name;
                                if (res.status == 4) {
                                    _this.compare_target_lang = _this.data.targetlanguage.data.lang_name;
                                }
                            });
                            _this.quote_approve.push(_this.data_approved);
                        });
                    }
                });
            }
        });
    };
    ProjectQaComponent.prototype.DownloadZip = function () {
        var _this = this;
        var data = {
            project_id: '',
            type: 1
        };
        data.project_id = this.project_id;
        data.type = this.project_type;
        this.projectsService.downloadZip(data).subscribe(function (resposne) {
            if (resposne) {
                _this.file_d = resposne.url;
            }
        });
    };
    // Get Project Language
    ProjectQaComponent.prototype.getProjectLanguage = function () {
        var _this = this;
        this.projectsService.getProjectLanguage(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.ProjectLanguage_data = response.data;
                _this.language_pair_count = _this.ProjectLanguage_data.length;
                var target_language_obj_1 = {
                    targetlanguage_id: Number,
                    lang_name: String,
                    project_lang_id: Number,
                    sourcelanguage_id: Number,
                    source_language_name: String
                };
                _this.ProjectLanguage_data.forEach(function (element) {
                    target_language_obj_1.targetlanguage_id = element.targetlanguage.id;
                    target_language_obj_1.lang_name = element.targetlanguage.lang_name;
                    target_language_obj_1.source_language_name = element.sourcelanguage.lang_name;
                    target_language_obj_1.sourcelanguage_id = element.sourcelanguage.id;
                    target_language_obj_1.project_lang_id = element.id;
                    _this.target_language_select.push(target_language_obj_1);
                    target_language_obj_1 = {
                        targetlanguage_id: '',
                        lang_name: '',
                        project_lang_id: '',
                        source_language_name: '',
                        sourcelanguage_id: ''
                    };
                });
                if (response.data) {
                    response.data.forEach(function (element) {
                        _this.data.project_language_id = element.id;
                        if (_this.Project_Assigned_obj_compare) {
                            _this.Project_Assigned_obj_compare.forEach(function (ele) {
                                // if(element.id==ele.project_language_id && ele.status!='3' && ele.project_workflow.workflow_id==3){
                                //   this.vendor_array_match.push(ele);
                                // }
                                if (element.id == ele.project_language_id && ele.status == '5' && ele.project_workflow.workflow_id == 3) {
                                    _this.vendor_array_match.push(ele);
                                }
                                else if (element.id == ele.project_language_id && ele.status != '3' && ele.status != '7' && ele.project_workflow.workflow_id == 3) {
                                    _this.vendor_array_match.push(ele);
                                }
                            });
                        }
                        // this.lang1 += element.targetlanguage.lang_name+ ', ' ;
                        // this.source_language_value= element.sourcelanguage.lang_name;
                        // this.source_lang_name=this.source_language_value;
                        // this.source_language_id_value= element.source_language_id;
                        // this.data.sourcelanguage.data.lang_name=this.source_language_value;
                        // this.data.source_lang_id=this.source_language_id_value;
                    });
                    // this.target_language_value=this.lang1.slice(0, -2);
                }
            }
        });
    };
    // get Language
    ProjectQaComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res;
            }
        });
    };
    // select source Name
    ProjectQaComponent.prototype.getSourceName = function (evt) {
        var _this = this;
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.data.sourcelanguage.data.lang_name = element.lang_name;
            }
        });
    };
    // selcect target language
    ProjectQaComponent.prototype.getTargetName = function (evt, res) {
        var _this = this;
        if (evt.value == "") {
            this.show_search = false;
            this.show_table = false;
            return;
        }
        this.languageData.data.forEach(function (element) {
            if (element.id == evt.value) {
                _this.data.targetlanguage.data.lang_name = element.lang_name;
                _this.target_lang_name = element.lang_name;
                _this.show_search = true;
                _this.show_table = false;
                _this.vendor_ids = [];
            }
        });
        this.languageDataArray.forEach(function (elem) {
            if (elem.targetlanguage.data.lang_name == _this.data.targetlanguage.data.lang_name) {
                _this.data.project_language_id = elem.id;
                _this.project_language_id = elem.id;
            }
        });
        this.quote_approve.forEach(function (elem) {
            if (elem.targetlanguage.data.lang_name == _this.data.targetlanguage.data.lang_name) {
                var total = void 0;
                _this.quantity_approve = parseFloat(elem.quantity);
                _this.quantity_rate = parseFloat(elem.rate);
                total = _this.quantity_approve * _this.quantity_rate;
                _this.profit_margin = elem.sub_total - _this.quantity_rate;
                _this.Opp_Amount = _this.roundToTwo(total);
            }
        });
    };
    // Get Speciality 
    ProjectQaComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                _this.specialityData = res;
            }
        });
    };
    // GetTranslator Languages
    ProjectQaComponent.prototype.getLanguageData = function (language_res) {
        var _this = this;
        this.language_resObj = language_res;
        this.project_language_id = language_res.project_lang_id;
        this.target_lang_name = language_res.lang_name;
        this.source_lang_name = language_res.source_language_name;
        this.quote_approve.forEach(function (elem) {
            if (elem.targetlanguage.data.lang_name == _this.target_lang_name) {
                var total = void 0;
                _this.quantity_approve = parseFloat(elem.quantity);
                _this.quantity_rate = parseFloat(elem.rate);
                total = _this.quantity_approve * _this.quantity_rate;
                _this.profit_margin = elem.sub_total - _this.quantity_rate;
                _this.Opp_Amount = _this.roundToTwo(total);
            }
        });
        var obj = {
            specilization_id: Number,
            source_language_id: Number,
            target_language_id: Number
        };
        obj.specilization_id = this.specialization_id;
        obj.source_language_id = language_res.sourcelanguage_id;
        obj.target_language_id = language_res.targetlanguage_id;
        obj.skill_id = 4;
        this.filterSearchObj = obj;
        this.projectsService.ProjectTranslatorAssignment(obj, '')
            .subscribe(function (response) {
            if (response) {
                _this.translatorType = '';
                _this.native_language = '';
                _this.specialization = '';
                if (response.data.length > 0) {
                    var show_available_translator = document.getElementById('show_available_translator');
                    if (show_available_translator != null) {
                        show_available_translator.scrollIntoView();
                        show_available_translator = null;
                    }
                    // this.toastr.success("Data available!!");
                    response.data.forEach(function (element) {
                        element.preference = false;
                    });
                    _this.dataSource_translator = new MatTableDataSource(response.data);
                    _this.vendor = [];
                    _this.vendor_ids = [];
                    _this.show_table = true;
                    _this.noData = false;
                }
                else {
                    _this.show_table = false;
                    _this.toastr.error("No record available");
                    _this.noData = true;
                }
            }
        });
    };
    // selectFinance(evt:any,val){
    //   if (evt.checked && this.vendor_ids.indexOf(val.id== -1)) {
    //     this.vendor_first_name=val.first_name;
    //      this.vendor.push(val);
    //      this.vendor_ids.push(val.id);
    //   }
    //   else if (!evt.checked && this.vendor_ids.indexOf(val.id != -1)) {
    //       let index = this.vendor_ids.indexOf(val.id);
    //       this.vendor_ids.splice(index, 1);
    //       this.vendor.splice(index, 1);
    //   }
    //   this.getFileDetail();
    //   this.Form.patchValue({
    //     vendor_id:this.vendor_ids
    //   });
    // }
    ProjectQaComponent.prototype.selectFinance = function (evt, val) {
        var obj = {
            id: '',
            preference: 0
        };
        var index = this.vendor_ids.findIndex(function (item) { return item.id == val.id; });
        if (evt.checked && index == -1) {
            this.vendor_first_name = val.first_name;
            this.vendor.push(val);
            console.log(this.vendor);
            obj.id = val.id;
            this.vendor_ids.push(obj);
        }
        else if (!evt.checked && index != -1) {
            var indexVendor = this.vendor.indexOf(val.id);
            this.vendor_ids.splice(index, 1);
            this.vendor.splice(indexVendor, 1);
            this.dataSource_translator.data.forEach(function (element) {
                if (element.id == val.id) {
                    element.preference = false;
                }
            });
        }
        this.getFileDetail();
        this.Form.patchValue({
            vendor_id: this.vendor_ids
        });
    };
    ProjectQaComponent.prototype.radioChangeHandler = function (event, val) {
        if (event.target.checked) {
            this.vendor_ids.map(function (element) {
                return element.preference = 0;
            });
            this.dataSource_translator.data.forEach(function (rec) {
                if (rec.id != val.id) {
                    rec.preference = false;
                }
            });
            this.vendor_ids.forEach(function (element) {
                if (element.id == val.id) {
                    element.preference = 1;
                }
            });
        }
    };
    ProjectQaComponent.prototype.getEmailTemplate = function () {
        var _this = this;
        this.projectsService.getEmailTemplateQA()
            .subscribe(function (response) {
            if (response) {
                _this.response_email_data = response.data;
            }
        });
    };
    ProjectQaComponent.prototype.getWorkflow = function () {
        var _this = this;
        this.projectsService.getWorkFlow()
            .subscribe(function (response) {
            if (response) {
                _this.response_workflow = response.data;
            }
        });
    };
    // Submit Assignment project
    ProjectQaComponent.prototype.submit = function () {
        var _this = this;
        if (this.file_id_data.length == 0) {
            this.toastr.error("Please select any files");
            return;
        }
        this.assignment_obj = this.Form.value;
        this.assignment_obj.due_date = this.dateFormat(this.Form.value.due_date);
        this.assignment_obj.project_language_id = this.project_language_id;
        this.assignment_obj.service_id = 1;
        this.assignment_obj.start_date = '';
        this.assignment_obj.completed_percentage = 0;
        this.projectWorkFlowResponse.forEach(function (element) {
            if (element.workflow_id == 3) {
                _this.project_workflow_id = element.id;
            }
        });
        this.assignment_obj.project_workflow_id = this.project_workflow_id;
        //this.assignment_obj.quantity_words = this.quantity_approve;
        this.assignment_obj.quantity_words = this.DetailMainResponse.sprj1_t9n_wordcount_c.value;
        this.assignment_obj.file_id = this.file_id_data;
        this.projectsService.ProjectAssignment(this.assignment_obj)
            .subscribe(function (response) {
            if (response) {
                _this.show_table = false;
                _this.vendor_ids = [];
                _this.data.target_lang_id = '';
                _this.show_search = false;
                _this.vendor = [];
                _this.Form = _this._formBuilder.group({
                    duration: ['30', Validators.compose([Validators.required])],
                    duration_unit: ['1'],
                    duration_comment: [''],
                    email_template_id: ['', Validators.compose([Validators.required])],
                    update_status_interval: ['240', Validators.compose([Validators.required])],
                    update_status_note: [''],
                    instruction: [''],
                    vendor_id: [],
                    due_date: ['', Validators.compose([Validators.required])],
                });
                _this.toastr.success(response.message);
                var top_1 = document.getElementById('top');
                if (top_1 !== null) {
                    top_1.scrollIntoView();
                    top_1 = null;
                }
                _this.target_language_select = [];
                // this.getProjectAssignment();
                // this.getProjectAssignment_compare();
                // this.WorkFlowByProjectId();
                // this.getProjectLanguage();
                window.location.href = '/pms/project/details/' + _this.project_id;
            }
            // else {
            //   this.toastr.error("Something went wrong");
            // }
        });
    };
    // date format
    ProjectQaComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt + ' 00:00:00';
    };
    // get project assignment
    ProjectQaComponent.prototype.getProjectAssignment = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        var query = 'page=1&per_page=5';
        this.projectsService.getProjectAssignment(query, obj)
            .subscribe(function (response) {
            if (response) {
                response.response.data.forEach(function (element) {
                    var project_status = '';
                    _this.ProjectAssignedStatus.forEach(function (ele) {
                        if (ele.id == element.status) {
                            project_status = ele.value;
                        }
                    });
                    element.project_status = project_status;
                    _this.vendor_name = element.vendor.first_name + ' ' + element.vendor.last_name + '';
                    var lang1 = '';
                    var lang_array = [];
                    element.project_language.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    _this.lang_pair = lang_array;
                    _this.vendor_array.push(element);
                    response.response.lang_array = lang_array;
                });
                _this.Project_Assigned_obj = response.response.data;
                _this.dataSource2 = new MatTableDataSource(response.response.data);
                _this.paginate = response.response;
            }
            // else {
            //   this.toastr.error("Something went wrong");
            // }
        });
    };
    // get project assignment
    ProjectQaComponent.prototype.getProjectAssignment_compare = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        var query = 'page=1&per_page=20';
        this.projectsService.getProjectAssignment(query, obj)
            .subscribe(function (response) {
            if (response) {
                _this.Project_Assigned_obj_compare = response.response.data;
                if (_this.Project_Assigned_obj_compare.length > 0) {
                    _this.Project_Assigned_obj_compare.forEach(function (element) {
                        if (element.status == "5" && (element.project_workflow.workflow.id == 1 || element.project_workflow.workflow.id == 2 || element.project_workflow.workflow.id == 3)) {
                            var obj_1 = {
                                workflow_id: '',
                                workflow_name: '',
                                vendor_name: '',
                                assignment_date: ''
                            };
                            obj_1.workflow_id = element.project_workflow.workflow.id;
                            obj_1.workflow_name = element.project_workflow.workflow.name;
                            obj_1.vendor_name = element.vendor.first_name + element.vendor.last_name;
                            obj_1.assignment_date = element.project_workflow.updated_at;
                            _this.completed_workflow_array.push(obj_1);
                        }
                    });
                    _this.completed_workflow_array.sort(function (a, b) {
                        return a.workflow_id - b.workflow_id;
                    });
                }
            }
            // else {
            //   this.toastr.error("Something went wrong");
            // }
        });
    };
    ProjectQaComponent.prototype.paginateData = function (event) {
        var _this = this;
        var Query = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        Query = 'page=' + this.pageIndex + '&per_page=' + this.pageSize;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        this.projectsService.getProjectAssignment(Query, obj)
            .subscribe(function (response) {
            if (response) {
                response.response.forEach(function (element) {
                    var project_status = '';
                    _this.ProjectAssignedStatus.forEach(function (ele) {
                        if (ele.id == element.status) {
                            project_status = ele.value;
                        }
                    });
                    // response.response.data.project_status=project_status;
                    element.project_status = project_status;
                    var lang1 = '';
                    var lang_array = [];
                    element.project_language.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.response.lang_array = lang_array;
                });
                _this.Project_Assigned_obj = response.response.data;
                _this.dataSource2 = new MatTableDataSource(response.response.data);
                _this.paginate = response.response;
            }
            // else {
            //   this.toastr.error("Something went wrong");
            // }
        });
    };
    ProjectQaComponent.prototype.roundToTwo = function (num) {
        return Math.round(num * 100) / 100;
    };
    ProjectQaComponent.prototype.roundToTwoWithName = function (num) {
        var checknumber = Math.round(num * 100) / 100;
        if (checknumber < 0) {
            return '-$' + (-checknumber) + ' USD';
        }
        else {
            return '$' + checknumber + ' USD';
        }
    };
    // Submit WorkFlow
    ProjectQaComponent.prototype.submitWorkFlow = function () {
        var _this = this;
        var index = this.projectWorkFlowResponse.findIndex(function (item) { return item.workflow_id == _this.workFlowForm.value.workflow_id; });
        if (index != -1) {
            this.toastr.error("Process Already Exist!!");
            return;
        }
        var object;
        object = this.workFlowForm.value;
        object.project_id = this.project_id;
        object.status = 1;
        this.projectsService.projectWorkFlow(object)
            .subscribe(function (response) {
            if (response) {
                _this.WorkFlowByProjectId();
            }
        });
    };
    ProjectQaComponent.prototype.WorkFlowByProjectId = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        this.projectsService.WorkFlowByProjectId(obj)
            .subscribe(function (response) {
            if (response) {
                if (response.response.length > 0) {
                    response.response.forEach(function (element) {
                        var project_status;
                        _this.workflow_data.forEach(function (ele) {
                            if (ele.id == element.status) {
                                project_status = ele.value;
                            }
                            if (element.status == 3 && element.workflow_id == 3) {
                                _this.show_assign_button = false;
                            }
                            if (element.workflow_id == 3) {
                                _this.show_workflow_status = project_status;
                            }
                        });
                        element.project_status = project_status;
                    });
                    _this.projectWorkFlowResponse = response.response;
                }
            }
        });
    };
    // file section start
    ProjectQaComponent.prototype.selectFile = function (evt, res) {
        if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
            this.file_id_data.push(res.id);
        }
        else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
            var index = this.file_id_data.indexOf(res.id);
            this.file_id_data.splice(index, 1);
        }
    };
    ProjectQaComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        this.file_id_data = [];
        var check = false;
        this.file_data.forEach(function (element, key) {
            element["check"] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.file_id_data.push(element.id);
            }
            else {
                _this.file_id_data = [];
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
    };
    // Get file data
    ProjectQaComponent.prototype.getFileDetail = function () {
        var _this = this;
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.file_data = [];
                var fullname = "Customer";
                response.data.projectfile.data.forEach(function (element) {
                    if (element.createdby) {
                        fullname = element.createdby.data.fullname;
                    }
                    if (element.targetlanguage) {
                        var lang_name = element.targetlanguage.data.lang_name;
                    }
                    if (element.filetype) {
                        var filetype = element.filetype.data.name;
                    }
                    element.file.data.forEach(function (elm) {
                        _this.project_file_data = _this.BaseUrl_file + elm.path;
                        elm.type = element.type;
                        elm.fullname = fullname;
                        //elm.created_at= created_at;
                        elm.lang_name = lang_name;
                        elm.filetype = filetype;
                        _this.file_data.push(elm);
                    });
                });
            }
        });
    };
    // file section end
    ProjectQaComponent.prototype.getDuration = function (evt) {
        var duration_value = parseInt(evt.value);
        if (duration_value > 30) {
            this.customer_duration = true;
        }
        else {
            this.customer_duration = false;
        }
    };
    ProjectQaComponent.prototype.getAssignmentUpdate = function (evt) {
        var duration_value = parseInt(evt.value);
        if (duration_value > 240) {
            this.assignment_update = true;
        }
        else {
            this.assignment_update = false;
        }
    };
    /**
  * Filter courses by category
  */
    ProjectQaComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=100';
        //  translator type filter
        if ((type == "type" && evt.value != "" && evt.value !== undefined)) {
            this.filterTypeQuery = evt.value;
            this.fQuery += "&translation_type=" + this.filterTypeQuery;
        }
        else if (type == "type" && evt.value == "") {
            this.filterTypeQuery = evt.value;
        }
        else if (this.filterTypeQuery !== "") {
            this.fQuery += "&translation_type=" + this.filterTypeQuery;
        }
        //  native language filter
        if ((type == "native_language" && evt.value != "" && evt.value !== undefined)) {
            this.filterNativeLanguageQuery = evt.value;
            this.fQuery += "&native_language=" + this.filterNativeLanguageQuery;
        }
        else if (type == "native_language" && evt.value == "") {
            this.filterNativeLanguageQuery = evt.value;
        }
        else if (this.filterNativeLanguageQuery !== "") {
            this.fQuery += "&native_language=" + this.filterNativeLanguageQuery;
        }
        //  specialization filter
        if ((type == "speciality" && evt.value != "" && evt.value !== undefined)) {
            this.filterSpecializationQuery = evt.value;
            this.fQuery += "&specialization=" + this.filterSpecializationQuery;
        }
        else if (type == "speciality" && evt.value == "") {
            this.filterSpecializationQuery = evt.value;
        }
        else if (this.filterSpecializationQuery !== "") {
            this.fQuery += "&specialization=" + this.filterSpecializationQuery;
        }
        // project search filter
        if ((type == "search" && evt.value != "" && evt.value !== undefined)) {
            this.filterSearchQuery = evt.value;
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        else if (type == "search" && evt.value == "") {
            this.filterSearchQuery = evt.value;
        }
        else if (this.filterSearchQuery !== "") {
            this.fQuery += "&search_text=" + this.filterSearchQuery;
        }
        this.projectsService.ProjectTranslatorAssignment(this.filterSearchObj, this.fQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    element.preference = false;
                });
                _this.dataSource_translator = new MatTableDataSource(response.data);
            }
        });
    };
    ProjectQaComponent.prototype.getDetailMain = function (crmid) {
        var _this = this;
        this.projectsService.getDetailMain(crmid)
            .subscribe(function (response) {
            if (response) {
                _this.DetailMainResponse = response.data;
            }
            ;
        });
    };
    ProjectQaComponent.prototype.openDialog = function () {
        var _this = this;
        if (this.file_id_data.length == 0) {
            this.toastr.error("Please select any files");
            return;
        }
        this.assignment_obj = this.Form.value;
        this.assignment_obj.due_date = this.dateFormat(this.Form.value.due_date);
        this.assignment_obj.project_language_id = this.project_language_id;
        this.assignment_obj.service_id = 1;
        this.assignment_obj.start_date = '';
        this.assignment_obj.completed_percentage = 0;
        this.projectWorkFlowResponse.forEach(function (element) {
            if (element.workflow_id == 3) {
                _this.project_workflow_id = element.id;
            }
        });
        this.assignment_obj.project_workflow_id = this.project_workflow_id;
        //this.assignment_obj.quantity_words = this.quantity_approve;
        this.assignment_obj.quantity_words = this.DetailMainResponse.sprj1_t9n_wordcount_c.value;
        this.assignment_obj.file_id = this.file_id_data;
        this.email_temp_id = this.assignment_obj.email_template_id;
        this.projectsService.getEmailTemp(this.email_temp_id)
            .subscribe(function (response) {
            if (response) {
                // console.log(response.data);
                // console.log(this.project_id);
                _this.assignment_obj.emailTemp = response.data;
                _this.assignment_obj.project_id = _this.project_id;
                var dailogRef = _this.dialog.open(EmailTemplateViewComponent, {
                    width: '850px',
                    data: _this.assignment_obj
                });
            }
            ;
        });
    };
    ProjectQaComponent.prototype.openDialogAddTranslator = function () {
        var _this = this;
        var dailogRef = this.dialog.open(AddTempTranslatorComponent, {
            width: '1200px',
            height: '600px',
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getLanguageData(_this.language_resObj);
                _this.dataService.changeWorkflow("qa");
            }
        });
    };
    return ProjectQaComponent;
}());
export { ProjectQaComponent };
