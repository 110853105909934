import { OnInit } from "@angular/core";
import { VendorService } from "app/main/Services/vendor.service";
import { ToastrService } from "ngx-toastr";
import { MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { SharedService } from "app/main/shared.service";
var ClosedBlacklistedComponent = /** @class */ (function () {
    function ClosedBlacklistedComponent(vendorService, toastr, router) {
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.router = router;
        this.StageID = 6;
        this.paginate1 = {};
        this.pageIndex = 1;
        this.pageSize = 10;
        this.query = "";
        this.ids_progress = [];
        this.send_email_progress_data = {};
        this.bulk_selected_progress = false;
        this.login_enable = true;
        this.languageResponse = [];
        this.specialityResponse = [];
        this.fQuery = "";
        this.filterSpecialization = "";
        this.filtercategoryLevel = "";
        this.filter_src_lang = "";
        this.filter_target_lang = "";
        this.filter_native_lang = null;
        this.fdata = {
            category: "",
            Source: "",
            Target: "",
            Native: "",
        };
        this.displayedColumns = [
            "ref",
            "first_name",
            "category_level",
            "native_language",
            "language",
            "email",
            "stage",
        ];
    }
    ClosedBlacklistedComponent.prototype.ngOnInit = function () {
        this.getLanguage();
        this.getSpeciality();
        this.getData();
    };
    ClosedBlacklistedComponent.prototype.getData = function () {
        var _this = this;
        var query = "";
        var imported_category = localStorage.getItem("imported_category");
        imported_category = JSON.parse(imported_category);
        if (imported_category !== "" || imported_category !== null) {
            this.fdata.category = imported_category;
        }
        var imported_source_lang = localStorage.getItem("imported_source_lang");
        var source_langFilter = JSON.parse(imported_source_lang);
        if (source_langFilter !== "" || source_langFilter !== null) {
            this.fdata.Source = source_langFilter;
        }
        var imported_target_lang = localStorage.getItem("imported_target_lang");
        var target_langFilter = JSON.parse(imported_target_lang);
        if (target_langFilter !== "" || target_langFilter !== null) {
            this.fdata.Target = target_langFilter;
        }
        var imported_native_filter = localStorage.getItem("imported_native_filter");
        var nativeFilter = JSON.parse(imported_native_filter);
        if (nativeFilter !== "" || nativeFilter !== null) {
            this.fdata.Native = nativeFilter;
        }
        var importedFilter = localStorage.getItem("importedFilter");
        var filterObject = JSON.parse(importedFilter);
        var pagination = localStorage.getItem("importedPagination");
        var Object = JSON.parse(pagination);
        if (this.StageID == 6) {
            if (importedFilter != null && filterObject) {
                query = filterObject;
            }
            else if (pagination !== null &&
                Object.table_name == "translatorInImported") {
                query = "?page=" + Object.page + "&per_page=" + Object.per_page;
            }
            else {
                query = "?page=1&per_page=10";
            }
            query += "&archive=Blacklisted,Closed";
        }
        this.vendorService
            .getHrDashboardClosedBlacklistedListing(query, this.StageID)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = "";
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ", ";
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = "";
                    var lang_array = [];
                    var translation_rate_value1 = "";
                    elem.vendorSubtitler.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = "<>";
                        }
                        else {
                            res.bi_direction = ">";
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang =
                            res.sourcelanguage.data.lang_name +
                                res.bi_direction +
                                res.targetlanguage.data.lang_name +
                                ",";
                        lang_array.push(lang);
                    });
                    elem.check = false;
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    ClosedBlacklistedComponent.prototype.paginateData = function (event) {
        var _this = this;
        var aQuery = "";
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query =
            "?page=" +
                this.pageIndex +
                "&per_page=" +
                this.pageSize +
                "&archive=Blacklisted,Closed";
        var pagination = {
            table_name: "translatorInImported",
            page: this.pageIndex,
            per_page: this.pageSize,
            tabIndex: 0,
        };
        SharedService.set(SharedService.importedPagination, JSON.stringify(pagination));
        if (this.filtercategoryLevel !== "") {
            aQuery += "&category_level=" + this.filtercategoryLevel;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (this.fdata.category) {
            aQuery += "&category_level=" + this.fdata.category;
        }
        if (this.filter_src_lang !== "") {
            aQuery += "&source_lang=" + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source) {
            aQuery += "&source_lang=" + this.fdata.Source;
        }
        if (this.filter_target_lang !== "") {
            aQuery += "&target_lang=" + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target) {
            aQuery += "&target_lang=" + this.fdata.Target;
        }
        if (this.filter_native_lang !== null) {
            aQuery += "&native_lang=" + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.fdata.Native) {
            aQuery += "&native_lang=" + this.fdata.Native;
        }
        this.query += aQuery;
        SharedService.set(SharedService.importedFilter, JSON.stringify(this.fQuery));
        this.vendorService
            .getHrDashboardClosedBlacklistedListing(this.query, this.StageID)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = "";
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ", ";
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = "";
                    var lang_array = [];
                    var translation_rate_value1 = "";
                    elem.vendorSubtitler.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = "<>";
                        }
                        else {
                            res.bi_direction = ">";
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang =
                            res.sourcelanguage.data.lang_name +
                                res.bi_direction +
                                res.targetlanguage.data.lang_name +
                                ",";
                        lang_array.push(lang);
                    });
                    elem.check = false;
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    ClosedBlacklistedComponent.prototype.editData = function (value) {
        this.router.navigate(["/hr/translator/" + value.id]);
    };
    ClosedBlacklistedComponent.prototype.selectMailProgress = function (evt, val) {
        if (evt.checked && this.ids_progress.indexOf(val.user_id == -1)) {
            this.ids_progress.push(val.user_id);
        }
        else if (!evt.checked &&
            this.ids_progress.indexOf(val.user_id != -1)) {
            var index = this.ids_progress.indexOf(val.user_id);
            this.ids_progress.splice(index, 1);
        }
        if (this.ids_progress.length == 0) {
            this.bulk_selected_progress = false;
        }
        this.send_email_progress_data.ids = this.ids_progress;
    };
    ClosedBlacklistedComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        var check = false;
        this.dataSource1.data.forEach(function (element, key) {
            element["check"] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.login_enable = false;
                _this.ids_progress.push(element.user_id);
            }
            else {
                _this.ids_progress = [];
                _this.login_enable = true;
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
        this.send_email_progress_data.ids = this.ids_progress;
    };
    // get filters
    ClosedBlacklistedComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality().subscribe(function (res) {
            if (res) {
                res.forEach(function (element) {
                    element.name =
                        element.name.charAt(0).toUpperCase() +
                            element.name.slice(1);
                });
                _this.specialityResponse = res;
            }
        });
    };
    ClosedBlacklistedComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages().subscribe(function (res) {
            if (res) {
                _this.languageResponse = res.data;
            }
        });
    };
    // On filters
    ClosedBlacklistedComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = "?page=1&per_page=10";
        if (type === "categoryLevel" &&
            evt.value !== "" &&
            evt.value !== undefined) {
            this.filtercategoryLevel = evt.value;
            this.fQuery += "&category_level=" + this.filtercategoryLevel;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (type === "categoryLevel" && evt.value === "") {
            this.filtercategoryLevel = evt.value;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (this.filtercategoryLevel !== "") {
            this.fQuery += "&category_level=" + this.filtercategoryLevel;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (this.fdata.category) {
            this.fQuery += "&category_level=" + this.fdata.category;
        }
        if (type === "s_lang" && evt.value !== "" && evt.value !== undefined) {
            this.filter_src_lang = evt.value;
            this.fQuery += "&source_lang=" + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (type === "s_lang" && evt.value === "") {
            this.filter_src_lang = evt.value;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.filter_src_lang !== "") {
            this.fQuery += "&source_lang=" + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source) {
            this.fQuery += "&source_lang=" + this.fdata.Source;
        }
        if (type === "t_lang" && evt.value !== "" && evt.value !== undefined) {
            this.filter_target_lang = evt.value;
            this.fQuery += "&target_lang=" + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (type === "t_lang" && evt.value === "") {
            this.filter_target_lang = evt.value;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.filter_target_lang !== "") {
            this.fQuery += "&target_lang=" + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target) {
            this.fQuery += "&target_lang=" + this.fdata.Target;
        }
        if (type === "native_lang" &&
            evt.value != null &&
            evt.value !== undefined) {
            this.filter_native_lang = evt.value;
            this.fQuery += "&native_lang=" + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (type == "native_lang" && evt.value === "") {
            this.filter_native_lang = evt.value;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.filter_native_lang != null) {
            this.fQuery += "&native_lang=" + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.fdata.Native) {
            this.fQuery += "&native_lang=" + this.fdata.Native;
        }
        this.fQuery += "&archive=Blacklisted,Closed";
        SharedService.set(SharedService.importedFilter, JSON.stringify(this.fQuery));
        var query = this.fQuery;
        this.vendorService
            .getHrDashboardClosedBlacklistedListing(query, this.StageID)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = "";
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ", ";
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = "";
                    var lang_array = [];
                    var translation_rate_value1 = "";
                    elem.vendorSubtitler.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = "<>";
                        }
                        else {
                            res.bi_direction = ">";
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang =
                            res.sourcelanguage.data.lang_name +
                                res.bi_direction +
                                res.targetlanguage.data.lang_name +
                                ",";
                        lang_array.push(lang);
                    });
                    elem.check = false;
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    ClosedBlacklistedComponent.prototype.Send_mail_single = function (val) {
        var _this = this;
        var progress_single = [];
        var send_email_single_data = {};
        progress_single.push(val.user_id);
        send_email_single_data.ids = progress_single;
        send_email_single_data.resend = true;
        this.vendorService
            .sendProgressMail(send_email_single_data)
            .subscribe(function (response) {
            if (response) {
                send_email_single_data = {};
                progress_single = [];
                _this.toastr.success("Please check email");
                _this.getData();
                window.location.href = "/hr/hr-dashboard";
            }
        });
    };
    ClosedBlacklistedComponent.prototype.applyFilterProgress = function (filterValue) {
        this.dataSource1.filter = filterValue.trim().toLowerCase();
    };
    ClosedBlacklistedComponent.prototype.Send_mail = function () {
        var _this = this;
        if (Object.keys(this.send_email_progress_data).length === 0) {
            this.toastr.error("Please first select any translator");
            return;
        }
        this.vendorService
            .sendProgressMail(this.send_email_progress_data)
            .subscribe(function (response) {
            if (response) {
                _this.send_email_progress_data = {};
                _this.bulk_selected_progress = false;
                _this.ids_progress = [];
                _this.dataSource1.data.forEach(function (element) {
                    element.check = false;
                });
                _this.toastr.success("Please check emails");
                _this.getData();
            }
        });
    };
    return ClosedBlacklistedComponent;
}());
export { ClosedBlacklistedComponent };
