var FuseNavHorizontalItemComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FuseNavHorizontalItemComponent() {
        this.classes = 'nav-item';
    }
    return FuseNavHorizontalItemComponent;
}());
export { FuseNavHorizontalItemComponent };
