import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { SharedService } from 'app/main/shared.service';
var AddFileManagementComponent = /** @class */ (function () {
    function AddFileManagementComponent(_formBuilder, vendorService, toastr, projectsService, snackBar, sharedService, dialogRef, data) {
        this._formBuilder = _formBuilder;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.projectsService = projectsService;
        this.snackBar = snackBar;
        this.sharedService = sharedService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.mainprogressbar = false;
        this.target_language_select = [];
        this.File_Type_data = [];
        this.ProjectLanguage_data = [];
        this.fileError = '';
        this.file_multiple_data = [];
        this.file_id_array = [];
        this.uploaded_files = [];
        this.uploaded_files_array = [];
    }
    AddFileManagementComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    AddFileManagementComponent.prototype.ngOnInit = function () {
        this.getFileType();
        this.getProjectLanguage();
        this.form = this._formBuilder.group({
            file_type_id: ['', Validators.compose([Validators.required])],
            type: ['', Validators.compose([Validators.required])]
        });
    };
    // Get File Type
    AddFileManagementComponent.prototype.getFileType = function () {
        var _this = this;
        this.projectsService.getFileType()
            .subscribe(function (response) {
            if (response) {
                _this.File_Type_data = response.data;
            }
        });
    };
    // Get Project Language
    AddFileManagementComponent.prototype.getProjectLanguage = function () {
        var _this = this;
        this.projectsService.getProjectLanguage(this.data.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.ProjectLanguage_data = response.data;
                var target_language_obj_1 = {
                    id: Number,
                    lang_name: String
                };
                _this.ProjectLanguage_data.forEach(function (element) {
                    target_language_obj_1.id = element.targetlanguage.id;
                    target_language_obj_1.lang_name = element.targetlanguage.lang_name;
                    _this.target_language_select.push(target_language_obj_1);
                    target_language_obj_1 = {
                        id: '',
                        lang_name: ''
                    };
                });
            }
        });
    };
    // start multiple file upload
    AddFileManagementComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = "File size should not be more than 20MB.";
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    // addDocument
    AddFileManagementComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        this.mainprogressbar = true;
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append("file[]", file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files = response.data;
            _this.uploaded_files_array.push(_this.uploaded_files);
            response.data.forEach(function (element) {
                _this.file_id_array.push(element.id);
            });
            _this.fileSuccess = 'Uploaded successfully!!';
            _this.mainprogressbar = false;
        });
    };
    // end multiple file upload
    // Submit
    AddFileManagementComponent.prototype.submit = function () {
        var _this = this;
        var filedata = this.form.value;
        filedata.file_id = this.file_id_array;
        filedata.project_id = this.data.project_id;
        this.projectsService.projectFileSave(filedata)
            .subscribe(function (response) {
            _this.toastr.success("Data added Successfully");
            _this.dialogRef.close(true);
        });
    };
    return AddFileManagementComponent;
}());
export { AddFileManagementComponent };
