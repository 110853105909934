import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from 'app/main/shared.service';
@Component({
  selector: 'app-review-translator',
  templateUrl: './review-translator.component.html',
  styleUrls: ['./review-translator.component.scss']
})
export class ReviewTranslatorComponent implements OnInit {
  id: number;
  BaseUrl_file: any;
  data_update:any={};
  Form: FormGroup;
  comment_data:any={};
  vendor_exta_document_name: string;
  vendor_exta_document_data: string;
  day_name_test:string;
  vendorcomment_data:[];
  vendor_file_data:string;
  vendor_contract_data:string;
  vendor_resume_data:string;
  vendor_contract_name:string;
  vendor_resume_name:string;
  lang_test :string;
  specialities_test:string;
  address_test:string;
  skills: string;
  coa_array: any = [];

response_data:any = {

  tool_licence: '',
  vendoravailabilities: {
    data:[]
  },
  vendorspecialities : {
    data:[]
  },
  country: '',
  first_name: '',
  last_name: '',
  reference_id: '',
  email: '',
  skype_id: '',
  address1: '',
  city: '',
  state: '',
  postal_code: '',
  payment_method: '',
  translation_tools: '',
  education: '',
  cover_letter: '',
  vendornativelanguage: {}

  }

  constructor(private router:Router,  
    private route: ActivatedRoute,
    private vendorService : VendorService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,private _fuseNavigationService: FuseNavigationService) { 
      this.BaseUrl_file = environment.baseUrl_file;    }

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.params['id']);
    this.getApproval();
    this.Form = this._formBuilder.group({
      comment_admin : ['']
  });
  }

  submitDataApprove(val){

this.comment_data.comment_admin=this.Form.value.comment_admin;
this.comment_data.stage_id=val;
this.comment_data.status=1;

this.vendorService.update(this.id,this.comment_data)
    .subscribe((response) => {
        if(response) {
          this.data_update = response;
          this.router.navigate(['/approval']);
       this.toastr.success("Approved");
        
        }
    });
  }

  submitDataReject(val){
    this.comment_data.comment_admin = this.Form.value.comment_admin;
    this.comment_data.stage_id = val;
    this.comment_data.status = 0;
    this.vendorService.update(this.id,this.comment_data)
        .subscribe((response) => {
            if(response) {
              this.data_update = response;
              this.router.navigate(['/approval']);
              this.toastr.success("Rejected");
            }
        });
      }
  getApproval(){
    this.vendorService.get(this.id)
    .subscribe((response) => {
        if(response) {
          let day = '';
          response.data.vendoravailabilities.data.forEach(elem => {
           day += elem.day_name+', ';
          this.day_name_test=day;
          this.day_name_test = this.day_name_test.slice(0, -2);
          });
          let specialities = '';
          response.data.vendorspecialities.data.forEach(elem => {
            specialities += elem.specialities.data.name + ', ';
           this.specialities_test = specialities;
           this.specialities_test = this.specialities_test.slice(0, -2);
           });
  
           let lang = '';
           response.data.vendortranslationlangs.data.forEach(elem => {
            if(elem.bi_direction==2){
              elem.bi_direction='<>';
          }else{
            elem.bi_direction='>';
          }
            elem.lang_data = elem.sourcelanguage.data.lang_name+elem.bi_direction+ elem.targetlanguage.data.lang_name;
 
          });

          let subtitlerslang = '';
          response.data.vendorSubtitler.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.subtiterslang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
            elem.subtitle_rate = elem.subtitle_rate;
          });

          let treplang = '';
          response.data.vendorTreping.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
          });


          let skills = '';
          if (response.data.vendorskill.data.length > 0) {
            response.data.vendorskill.data.sort(function (a, b) {
              return a.skill_id - b.skill_id;
            });
            response.data.vendorskill.data.forEach(elem => {
              if (elem.skill !== undefined) {
                skills += elem.skill.data.name + ', ';
                this.skills = skills;
                this.skills = this.skills.slice(0, -2);
              }
            });
          }

          if (response.data.vendorcoafiles.data.length != 0) {
            response.data.vendorcoafiles.data.forEach(element => {
              let coa_url = this.BaseUrl_file + element.coafile.data.path;
              let coa_name_path = element.coafile.data.path.split('/');
              let coa_name = coa_name_path[2];
              let coa_obj = {
                url: coa_url,
                name: coa_name
              }
              // this.coa_id_array()
              this.coa_array.push(coa_obj);
            });
          }

           if (response.data.extradocument != null) {
              this.vendor_exta_document_data = this.BaseUrl_file + response.data.extradocument.data.path;
              this.vendor_exta_document_name = response.data.extradocument.data.name;
          }

          this.vendorcomment_data = response.data.vendorcomment.data;
          this.response_data = response.data;
          if(this.response_data.address2!=null || this.response_data.address2!=" "){
            this.address_test= this.response_data.address1 + ', '+ this.response_data.address2;
          }
          else{
            this.address_test= this.response_data.address1;
          }

response.data.vendorprofileimage.data.forEach(element => {
  this.vendor_file_data=this.BaseUrl_file + element.path;
});
response.data.vendorcontract.data.forEach(element => {
  this.vendor_contract_data=this.BaseUrl_file + element.path;
  let x=element.path.split('/');
  this.vendor_contract_name = x[2];

});

response.data.vendorresume.data.forEach(element => {
  this.vendor_resume_data=this.BaseUrl_file + element.path;
  let x=element.path.split('/');
  this.vendor_resume_name = x[2];

});

          this.Form.patchValue({
            comment_admin: this.response_data.comment_admin
        });
        
        }
    });
  }

  back(){
    this.router.navigate(['approval']);

      }  
}
