import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var CrmEmailLogPreviewComponent = /** @class */ (function () {
    function CrmEmailLogPreviewComponent(data, toastr, dialogRef, projectsService) {
        this.data = data;
        this.toastr = toastr;
        this.dialogRef = dialogRef;
        this.projectsService = projectsService;
    }
    CrmEmailLogPreviewComponent.prototype.ngOnInit = function () {
        if (this.data) {
            this.id = this.data;
            this.response_email = this.data;
        }
    };
    CrmEmailLogPreviewComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    return CrmEmailLogPreviewComponent;
}());
export { CrmEmailLogPreviewComponent };
