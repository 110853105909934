<div fxLayout="row" fxFlex="100">
<div >
  <button color="accent" (click)="back()" matTooltip="Back" mat-icon-button ><mat-icon>keyboard_backspace</mat-icon></button>
</div>
<fuse-widget  class="widget" style="margin: 0 auto;" fxLayoutAlign="center center"  fxLayout="row" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">
  <!-- Front -->
  <div fxLayout="row" fxLayoutAlign="center center" class="fuse-widget-front white-bg h-200" fxFlex="1 0 auto">
      <div class="pt-8 pb-4" fxLayout="column" fxLayoutAlign="center center">
          <!-- <div class="white-fg font-size-60 line-height-88"> Welcome </div> -->
          <div class="h4 white-fg font-weight-500 line-height-112">Coming Soon</div>
      </div>
  </div>
</fuse-widget>
</div>