import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatSort } from '@angular/material';
import * as moment from 'moment';
import { SharedService } from 'app/main/shared.service';
import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Chart} from 'chart.js'
@Component({
    selector: 'app-pm-dashboard',
    templateUrl: './pm-dashboard.component.html',
    styleUrls: ['./pm-dashboard.component.scss']
})
export class PmDashboardComponent {
    totalcount: number = 0;
    all_locked_projects: string;
    setIntervalId:any;
    project_locked_record: any = [];
    dataSource_unconfirmed: any;
    dataSource_Request_in_queue: any;
    dataSource_Additional_Services_request: any;
    dataSource_Due_Today: any;
    dataSource_Assignment_Pending: any;
    dataSource_UpdateByTz: any;
    dataSource_Undergoing_Edit: any;
    dataSource_ClientApproval_Pending: any;
    dataSource_rejected_quote: any;
    dataSource_Unassigned: any;
    dataSource_Undergoing: any;
    searchKey: string;
    paginate_unconfirmed: any = {};
    paginate_Request_in_queue: any = {};
    paginate_DueToday: any = {};
    paginate_Additional_Service: any = {};
    paginate_AssignmentPending: any = {};
    paginate_UpdateByTz: any = {};
    paginate_Undergoing_Edit: any = {};
    paginate_Undergoing: any = {};
    paginate_Unassigned: any = {};
    paginate_ClientApproval_Pending: any = {};
    paginate_Rejected_quotes: any={};
    pageIndex_unconfirmed: number = 1;
    pageIndex_RequestQueue: number = 1;
    pageIndex_DueToday: number = 1;
    pageIndex_Undergoing_Edit: number = 1;
    pageIndex_Unassigned: number = 1;
    pageIndex_ClientApproval_Pending: number = 1;
    pageIndex_Rejected_Quote: number = 1;
    pageSize_unconfirmed: number = 5;
    pageSize_RequestQueue: number = 5;
    pageSize_DueToday: number = 5;
    pageSize_AdditionalService: number = 5;
    pageSize_Undergoing_Edit: number = 5;
    pageSize_Unassigned: number = 5;
    pageSize_ClientApproval_Pending: number = 5;
    pageSize_Rejected_Quotes: number = 5;
    query_unconfirmed: string = "";
    query_RequestQueue: string = "";
    query_DueToday: string = "";
    query_Additional_Service: string = "";
    query_Undergoing_Edit: string = "";
    query_Unassigned: string = "";
    query_ClientApproval_Pending: string = "";
    query_Rejected_quote: string = "";
    total_count: number = 0;
    total_record_RequestQueue: number = 0;
    total_record_Additional_Service: number = 0;
    total_record_AssignmentPending: number = 0;
    total_record_DueDate: number = 0;
    total_record_Undergoing_Edit: number = 0;
    total_record_Undergoing: number = 0;
    total_record_Unassigned: number = 0;
    total_record_UnConfirmed: number = 0;
    total_record_ClientApproval_Pending: number = 0;
    total_record_rejected_Quote: number=0;
    total_record_UpdatesDueByTz: number = 0;
    current_page_count: number = 0;
    current_page_count_due_date: number = 0;
    total_count_due_date: number = 0;
    query: string = "";
    id: number = 1;
    estimate_hours: number;
    estimate_hours_Unconfirmed: number;
    estimate_hours_Due_Today: number;
    estimate_hours_RequestQueue: number;
    estimate_hours_assinment_pending: number;
    displayedColumns_Unconfirmed = ["ref", "language_pair", "due_date"];
    displayedColumns_RequestQueue = ["ref", "language_pair", "qemail", "type", "start_date", "due_date"];
    displayedColumns_DueToday = ["ref", "language_pair", "due_date"];
    displayedColumns_Additional_Service = ["ref", "language_pair", "due_date"];
    displayedColumns_Undergoing_Edit = ["ref", "language_pair", "due_date"];
    displayedColumns_Unassigned = ["ref", "language_pair", "qemail", "type", "start_date", "due_date"];
    displayedColumns_ClientApproval_Pending = ["ref", "language_pair", "due_date"];
    displayedColumns_Assignment_Pending = ["ref", "language_pair", "due_date"];
    displayedColumns_UpdateByTz = ["ref", "language_pair", "due_date"];
    displayedColumns_Rejected_Quotes = ["ref", "language_pair", "due_date"];
    // pieChartLabels: string[] = ["New Request", "InProgress", "Approved"];
    pieChartData = [];
    pieChartError:string='';
    pieChartType: string = 'pie';
    DueTimeClass: any;
    translatorMessages: any = [];
    showMessage: number = 0;
    Form: FormGroup;
    PieChart=[];
    sendMessageObj: any = {
        from_user_id: '',
        message: '',
        message_type: '',
        message_id: '' // optional use only for dashbaord message reply

    };


    constructor(
        @Inject(DOCUMENT) private document: Document,
        private projectsService: ProjectsService, private datePipe: DatePipe, private _formBuilder: FormBuilder,
        private toastr: ToastrService) {

    }

    @ViewChild(MatSort)
    sort: MatSort;
    date: Date;
    graphDate: string;
    user_id: string;

    ngOnInit() {
        this.user_id = SharedService.get('id');
        this.date = new Date();
        this.graphDate = this.datePipe.transform(new Date(), "MMM d y");
        this.projectGraph();
        this.GetPmLockedProjects();
        this.getRequestQueue();
        this.getProjectDueDate();
        this.getRequestAdditionalServices();
        this.getUnConfirmedByTz();
        this.getProjectUpdateDueByTz();
        this.getProjectAssignmentPending();
        this.getUndergoing_Edit();
        this.getClientApproval_Pending();
        this.getRejectedQuotes();
        this.getUnassigned();
        this.getUndergoing();
        this.getTranslatorMessages();
        this.Form = this._formBuilder.group({

            message: ['', Validators.required],

        });

        this.setIntervalId = setInterval(() => {
            this.getRequestQueue();
        }, 30000)

    }

    ngOnDestroy(){
        // prevent memory leak when component destroyed
        clearInterval( this.setIntervalId);
    
      }

    // get all loced project by pm
    GetPmLockedProjects() {

        let data: any = {
            user_id: '',

        }
        data.user_id = this.user_id;

        this.projectsService.GetPmLockedProjects(data)
            .subscribe((response) => {
                if (response) {

                    this.all_locked_projects = response.data;
                    this.project_locked_record = response.records;

                } else {
                    this.all_locked_projects = '';
                }
            }
            );
    }


    getRequestQueue() {
        let uQuery = ''
        this.projectsService.getRequestQuote(this.id, uQuery)
            .subscribe((response) => {
                if (response) {

                    response.data.forEach(element => {

                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {

                            if (months == 1) {
                                element['DueTimeClass'] = this.DueTimeClass;

                                DueTime = months + ' Month Passed';
                            }
                            else {

                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {

                                DueTime = day + ' Day Passed';
                            }
                            else {

                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {

                            if (hour == 1) {

                                DueTime = hour + ' Hour Passed';
                            }
                            else {

                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {

                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {

                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {

                                DueTime = seconds + ' Second Passed';
                            }
                            else {

                                DueTime = seconds + ' Seconds Passed';
                            }

                        }

                        if (minutes <= 10) {
                            element['DueTimeClass'] = "normal";
                        } else if (minutes > 10 && minutes <= 20) {
                            element['DueTimeClass'] = "warning";
                        }
                        else if (minutes > 20) {
                            element['DueTimeClass'] = "danger";
                        }


                        element['difference'] = DueTime;
                    });
                    this.dataSource_Request_in_queue = new MatTableDataSource(response.data);
                    this.paginate_Request_in_queue = response.meta.pagination;

                    this.total_record_RequestQueue = response.meta.pagination.total;

                }
            });
    }


    paginate_RequestQueue(event) {
        let uQuery = ''
        this.pageIndex_RequestQueue = event.pageIndex + 1;
        if (this.pageIndex_RequestQueue < 0) {
            this.pageIndex_RequestQueue = 1;
        }
        this.pageSize_RequestQueue = event.pageSize;

        this.query_RequestQueue = '?page=' + this.pageIndex_RequestQueue + '&per_page=' + this.pageSize_RequestQueue;

        this.query_RequestQueue += uQuery;


        this.projectsService.getRequestQuote(this.id, this.query_RequestQueue)
            .subscribe((response) => {
                if (response) {
                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        if(element.projectlang.data.length>0){
                            element.projectlang.data.forEach(elm => {
                                if(elm.sourcelanguage!=null && elm.targetlanguage!=null){
                                    lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                                    lang_array.push(lang1);
                                }
                               
                            });

                        }
                       

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        if (lang_array.length > 0) {
                            element.lang_array = lang_array;
                         response.data.lang_array = lang_array;
                        }
                        
                    });

                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {

                            if (months == 1) {
                                element['DueTimeClass'] = this.DueTimeClass;
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {

                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }

                        if (minutes <= 10) {
                            element['DueTimeClass'] = "normal";
                        } else if (minutes > 10 && minutes <= 20) {
                            element['DueTimeClass'] = "warning";
                        }
                        else if (minutes > 20) {
                            element['DueTimeClass'] = "danger";
                        }
                        element['difference'] = DueTime;
                    });

                    this.dataSource_Request_in_queue = new MatTableDataSource(response.data);
                    this.paginate_Request_in_queue = response.meta.pagination;
                    this.total_record_RequestQueue = response.meta.pagination.total;
                }
            });
    }


    getRequestAdditionalServices() {
        let uQuery = ''
        this.projectsService.getAdditional_service(uQuery)
            .subscribe((response) => {
                if (response) {

                    response.data.forEach(element => {

                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.forEach(elm => {
                            lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    this.dataSource_Additional_Services_request = new MatTableDataSource(response.data);
                    this.paginate_Additional_Service = response;

                     this.total_record_Additional_Service = response.total;

                }
            });
    }

    
    paginate_Additional_serviceData(event) {
        let uQuery = ''
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_AdditionalService = event.pageSize;
        this.query_Additional_Service = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_AdditionalService;
        this.query_Additional_Service += uQuery;
        this.projectsService.getAdditional_service(this.query_Additional_Service)
        .subscribe((response) => {
            if (response) {
                response.data.forEach(element => {
                    let lang1 = '';
                    let lang_array = [];
                    element.projectlang.forEach(elm => {
                        lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                    });

                    if (lang_array.length > 0) {
                        let test;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1)

                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });

                this.dataSource_Additional_Services_request = new MatTableDataSource(response.data);
                this.paginate_Additional_Service = response;
                this.total_record_Additional_Service = response.total;

            }
        });
    }

    getProjectDueDate() {
        let uQuery = ''

        this.projectsService.getProjectDueToday(uQuery)
            .subscribe((response) => {
                if (response) {

                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        if (element.project_languages != null) {
                            lang1 = element.project_languages.sourcelanguage.lang_name + '-' + element.project_languages.targetlanguage.lang_name + ',';
                            lang_array.push(lang1);
                            if (lang_array.length > 0) {
                                let test;
                                test = lang_array[lang_array.length - 1];
                                lang_array[lang_array.length - 1] = test.slice(0, -1)
                            }
                            element.lang_array = lang_array;
                            response.data.lang_array = lang_array;
                        }

                    });

                    response.data.forEach(element => {

                        let dt1 = new Date(element.created_at);
                        let dt2 = new Date();

                        this.estimate_hours_Due_Today = this.diff_hours(dt2, dt1);
                        element['difference'] = this.estimate_hours_Due_Today;
                        element.estimate_hours = this.estimate_hours;
                    });
                    this.dataSource_Due_Today = new MatTableDataSource(response.data);
                    this.paginate_DueToday = response;

                    this.total_record_DueDate = response.total;


                }
            });
    }


    paginate_DueTodayData(event) {
        let uQuery = ''
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_DueToday = event.pageSize;
        this.query_DueToday = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_DueToday;
        this.query_DueToday += uQuery;
        this.projectsService.getProjectDueToday(this.query_DueToday)
            .subscribe((response) => {
                if (response) {
                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        lang1 = element.project_languages.sourcelanguage.lang_name + '-' + element.project_languages.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    response.data.forEach(element => {
                        let dt1 = new Date(element.created_at);
                        let dt2 = new Date();

                        this.estimate_hours_Due_Today = this.diff_hours(dt2, dt1);
                        element['difference'] = this.estimate_hours_Due_Today;
                        //    element.estimate_hours=this.estimate_hours;
                    });

                    this.dataSource_Due_Today = new MatTableDataSource(response.data);
                    this.paginate_DueToday = response;

                    this.total_record_DueDate = response.total;
                }
            });
    }

    getUnConfirmedByTz() {
        let uQuery = '';
        this.projectsService.getUnConfirmedByTz(uQuery)
            .subscribe((response) => {
                if (response) {
                    response.data.forEach(element => {
                        let dt1 = new Date(element.created_at.date);
                        let dt2 = new Date();
                        this.estimate_hours_Unconfirmed = this.diff_hours(dt2, dt1);
                        element['difference'] = this.estimate_hours_Unconfirmed;


                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;

                    });
                    this.dataSource_unconfirmed = new MatTableDataSource(response.data);
                    this.paginate_unconfirmed = response.meta.pagination;
                    this.total_record_UnConfirmed = response.meta.pagination.total;
      
                }
            });
    }


    paginate_unconfirmedData(event) {
        let uQuery = ''
        this.pageIndex_unconfirmed = event.pageIndex + 1;
        if (this.pageIndex_unconfirmed < 0) {
            this.pageIndex_unconfirmed = 1;
        }
        this.pageSize_unconfirmed = event.pageSize;

        this.query_unconfirmed = '?page=' + this.pageIndex_unconfirmed + '&per_page=' + this.pageSize_unconfirmed;

        this.query_unconfirmed += uQuery;


        this.projectsService.getUnConfirmedByTz(this.query_unconfirmed)
            .subscribe((response) => {
                if (response) {

                    response.data.forEach(element => {
                        let dt1 = new Date(element.created_at.date);
                        let dt2 = new Date();
                        this.estimate_hours_Unconfirmed = this.diff_hours(dt2, dt1);
                        element['difference'] = this.estimate_hours_Unconfirmed;

                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;

                    });
                    this.dataSource_unconfirmed = new MatTableDataSource(response.data);
                    this.paginate_unconfirmed = response.meta.pagination;

                    this.total_record_UnConfirmed = response.meta.pagination.total;

                }
            });
    }


    getUndergoing_Edit() {
        let uQuery = '';

        this.projectsService.getUnderGoingEdit(uQuery)
            .subscribe((response) => {
                if (response) {
                    // GET due time
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }
                        element['difference'] = DueTime;
                    });

                    // get source language & target language 

                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });
                    this.dataSource_Undergoing_Edit = new MatTableDataSource(response.data);
                    this.paginate_Undergoing_Edit = response.meta.pagination;
                    this.total_record_Undergoing_Edit = response.meta.pagination.total;
                }
            });
    }


    getUndergoing() {
        let uQuery = ''

        this.projectsService.getUnderGoing(uQuery)
            .subscribe((response) => {
                if (response) {
                    // GET due time
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }
                        element['difference'] = DueTime;
                    });

                    // get source language & target language 

                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });
                    this.dataSource_Undergoing = new MatTableDataSource(response.data);
                    this.paginate_Undergoing = response.meta.pagination;
                    this.total_record_Undergoing = response.meta.pagination.total;
                }
            });
    }

    getClientApproval_Pending() {
        let uQuery = ''
        this.projectsService.getClientApproval_Pending(uQuery)
            .subscribe((response) => {
                if (response) {
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {
                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }
                        element['difference'] = DueTime;
                    });

                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                                lang_array.push(lang1);
                            }
                        });
                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1);
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });
                    this.dataSource_ClientApproval_Pending = new MatTableDataSource(response.data);
                    this.paginate_ClientApproval_Pending = response.meta.pagination;
                    this.total_record_ClientApproval_Pending = response.meta.pagination.total;
                }
            });
    }


    getRejectedQuotes() {
        let uQuery = ''
        this.projectsService.getRejectedQuotes(uQuery)
            .subscribe((response) => {
                if (response) {
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }
                        }
                        element['difference'] = DueTime;
                    });

                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                                lang_array.push(lang1);
                            }
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });
                      this.dataSource_rejected_quote = new MatTableDataSource(response.data);
                      this.paginate_Rejected_quotes = response.meta.pagination;
                      this.total_record_rejected_Quote = response.meta.pagination.total;
                }
            });
    }

    getUnassigned() {
        let uQuery = '';
        this.projectsService.getUnassigned(uQuery)
            .subscribe((response) => {
                if (response) {
                    // GET due time
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {
                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }
                        }
                        element['difference'] = DueTime;
                    });

                    // get source language & target language 
                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1);
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });
                    this.dataSource_Unassigned = new MatTableDataSource(response.data);
                    this.paginate_Unassigned = response.meta.pagination;
                    this.total_record_Unassigned = response.meta.pagination.total;
                }
            });
    }

    diff_hours(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    }

    paginate_ClientApproval_PendingData(event) {
        let uQuery = ''
        this.pageIndex_ClientApproval_Pending = event.pageIndex + 1;
        if (this.pageIndex_ClientApproval_Pending < 0) {
            this.pageIndex_ClientApproval_Pending = 1;
        }
        this.pageSize_ClientApproval_Pending = event.pageSize;
        this.query_ClientApproval_Pending = '?page=' + this.pageIndex_ClientApproval_Pending + '&per_page=' + this.pageSize_ClientApproval_Pending;
        this.query_ClientApproval_Pending += uQuery;
        this.projectsService.getClientApproval_Pending(this.query_ClientApproval_Pending)
            .subscribe((response) => {
                if (response) {
                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                                lang_array.push(lang1);
                            }
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }
                        element['difference'] = DueTime;

                    });

                    this.dataSource_ClientApproval_Pending = new MatTableDataSource(response.data);
                    this.paginate_ClientApproval_Pending = response.meta.pagination;
                    this.total_record_ClientApproval_Pending = response.meta.pagination.total;


                }
            });
    }


    paginate_RejectedQuote_PendingData(event) {
        let uQuery = ''
        this.pageIndex_Rejected_Quote = event.pageIndex + 1;
        if (this.pageIndex_Rejected_Quote < 0) {
            this.pageIndex_Rejected_Quote = 1;
        }
        this.pageSize_Rejected_Quotes = event.pageSize;

        this.query_Rejected_quote = '?page=' + this.pageIndex_Rejected_Quote + '&per_page=' + this.pageSize_Rejected_Quotes;

        this.query_Rejected_quote += uQuery;


        this.projectsService.getRejectedQuotes(this.query_Rejected_quote)
            .subscribe((response) => {
                if (response) {
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }
                        element['difference'] = DueTime;
                    });

                    response.data.forEach(element => {

                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                                lang_array.push(lang1);
                            }



                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;

                        response.data.lang_array = lang_array;

                    });



                    this.dataSource_rejected_quote = new MatTableDataSource(response.data);

                      this.paginate_Rejected_quotes = response.meta.pagination;
                     this.total_record_rejected_Quote = response.meta.pagination.total;


                }
            });
    }

    paginate_Undergoing_EditData(event) {


        let uQuery = ''
        this.pageIndex_Undergoing_Edit = event.pageIndex + 1;
        if (this.pageIndex_Undergoing_Edit < 0) {
            this.pageIndex_Undergoing_Edit = 1;
        }
        this.pageSize_Undergoing_Edit = event.pageSize;

        this.query_Undergoing_Edit = '?page=' + this.pageIndex_Undergoing_Edit + '&per_page=' + this.pageSize_Undergoing_Edit;

        this.query_Undergoing_Edit += uQuery;


        this.projectsService.getUnderGoingEdit(this.query_Undergoing_Edit)
            .subscribe((response) => {
                if (response) {
                    // GET due time
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }
                        element['difference'] = DueTime;
                    });

                    // get source language & target language 
                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });
                    this.dataSource_Undergoing_Edit = new MatTableDataSource(response.data);
                    this.paginate_Undergoing_Edit = response.meta.pagination;
                    this.total_record_Undergoing_Edit = response.meta.pagination.total;


                }
            });
    }


    paginate_Unassigned_Data(event) {


        let uQuery = ''
        this.pageIndex_Unassigned = event.pageIndex + 1;
        if (this.pageIndex_Unassigned < 0) {
            this.pageIndex_Unassigned = 1;
        }
        this.pageSize_Unassigned = event.pageSize;

        this.query_Unassigned = '?page=' + this.pageIndex_Unassigned + '&per_page=' + this.pageSize_Unassigned;

        this.query_Unassigned += uQuery;


        this.projectsService.getUnassigned(this.query_Unassigned)
            .subscribe((response) => {
                if (response) {
                    // GET due time
                    response.data.forEach(element => {
                        var DueTime: any;
                        let dt2 = new Date();
                        var datetime = moment(element.created_at.date);
                        var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                        var minutes = current.diff(datetime, 'minutes');
                        var seconds = current.diff(datetime, 'seconds');
                        var hour = current.diff(datetime, 'hours');
                        var day = current.diff(datetime, 'day');
                        var months = current.diff(datetime, 'month');
                        if (day > 30) {
                            if (months == 1) {
                                DueTime = months + ' Month Passed';
                            }
                            else {
                                DueTime = months + ' Months Passed';
                            }
                        }
                        else if (hour > 24) {
                            if (day == 1) {
                                DueTime = day + ' Day Passed';
                            }
                            else {
                                DueTime = day + ' Days Passed';
                            }
                        }
                        else if (minutes > 60) {
                            if (hour == 1) {
                                DueTime = hour + ' Hour Passed';
                            }
                            else {
                                DueTime = hour + ' Hours Passed';
                            }
                        }
                        else if (seconds > 60) {

                            if (minutes == 1) {
                                DueTime = minutes + ' Mintue Passed';
                            }
                            else {
                                DueTime = minutes + ' Mintues Passed';
                            }
                        }
                        else if (seconds < 60) {
                            if (seconds == 1) {
                                DueTime = seconds + ' Second Passed';
                            }
                            else {
                                DueTime = seconds + ' Seconds Passed';
                            }

                        }
                        element['difference'] = DueTime;
                    });

                    // get source language & target language 
                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elm => {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)

                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });
                    this.dataSource_Unassigned = new MatTableDataSource(response.data);
                    this.paginate_Unassigned = response.meta.pagination;
                    this.total_record_Unassigned = response.meta.pagination.total;


                }
            });
    }


    // Assignment pending

    getProjectAssignmentPending() {
        let uQuery = ''

        this.projectsService.getProjectAssignmentPending(uQuery)
            .subscribe((response) => {
                if (response) {

                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elem => {
                            lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });
                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    response.data.forEach(element => {

                        let dt1 = new Date(element.created_at.date);
                        let dt2 = new Date();

                        this.estimate_hours_assinment_pending = this.diff_hours(dt2, dt1);
                        element['difference'] = this.estimate_hours_assinment_pending;

                    });
                    this.dataSource_Assignment_Pending = new MatTableDataSource(response.data);
                    this.paginate_AssignmentPending = response.meta.pagination;

                    this.total_record_AssignmentPending = response.meta.pagination.total;


                }
            });
    }


    paginate_AssignmentPendingData(event) {


        let uQuery = ''
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_DueToday = event.pageSize;

        let query = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_DueToday;

        query += uQuery;


        this.projectsService.getProjectAssignmentPending(query)
            .subscribe((response) => {
                if (response) {


                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elem => {
                            lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    response.data.forEach(element => {

                        let dt1 = new Date(element.created_at.date);
                        let dt2 = new Date();

                        this.estimate_hours_assinment_pending = this.diff_hours(dt2, dt1);
                        element['difference'] = this.estimate_hours_assinment_pending;
                        //    element.estimate_hours=this.estimate_hours;
                    });

                    this.dataSource_Assignment_Pending = new MatTableDataSource(response.data);
                    this.paginate_AssignmentPending = response.meta.pagination;

                    this.total_record_AssignmentPending = response.meta.pagination.total;


                }
            });
    }


    // updates due by tz
    getProjectUpdateDueByTz() {
        let uQuery = ''

        this.projectsService.getUpdateByTz(uQuery)
            .subscribe((response) => {
                if (response) {

                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elem => {
                            lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });
                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    response.data.forEach(element => {
                        let estimate_hours_updateByTz;
                        let dt1 = new Date(element.created_at.date);
                        let dt2 = new Date();

                        estimate_hours_updateByTz = this.diff_hours(dt2, dt1);
                        element['difference'] = estimate_hours_updateByTz;

                    });
                    this.dataSource_UpdateByTz = new MatTableDataSource(response.data);
                    this.paginate_UpdateByTz = response.meta.pagination;

                    this.total_record_UpdatesDueByTz = response.meta.pagination.total;


                }
            });
    }


    paginate_UpdateDueByTzData(event) {
        let uQuery = '';
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_DueToday = event.pageSize;

        let query = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_DueToday;

        query += uQuery;


        this.projectsService.getUpdateByTz(query)
            .subscribe((response) => {
                if (response) {


                    response.data.forEach(element => {
                        let lang1 = '';
                        let lang_array = [];
                        element.projectlang.data.forEach(elem => {
                            lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        });

                        if (lang_array.length > 0) {
                            let test;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1)
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    });

                    response.data.forEach(element => {
                        let estimate_hours_updateByTz;
                        let dt1 = new Date(element.created_at.date);
                        let dt2 = new Date();

                        estimate_hours_updateByTz = this.diff_hours(dt2, dt1);
                        element['difference'] = estimate_hours_updateByTz;
                        //    element.estimate_hours=this.estimate_hours;
                    });

                    this.dataSource_UpdateByTz = new MatTableDataSource(response.data);
                    this.paginate_UpdateByTz = response.meta.pagination;

                    this.total_record_UpdatesDueByTz = response.meta.pagination.total;


                }
            });
    }


    // get Graph data
    projectGraph() {
        let data: any = {
            date: '',

        }

        var date = new Date();
        var test = this.dateFormat(date);
        data.date = test;
        this.projectsService.projectGraph(data)
            .subscribe((response) => {
                if (response) {
                    this.pieChartData.push(response.unassigned_project);
                    this.pieChartData.push(response.inprogress_projects);
                    this.pieChartData.push(response.delivered_projects);
                    if(this.pieChartData.length==0){
                       this.pieChartError=" No data available!!"
                    }
                    this.getChart();
                }
            });
    }


    getChart(){
        // pie chart:
this.PieChart = new Chart('pieChart', {
  type: 'pie',
data: {
 labels: ["UnAssigned", "InProgress", "Delivered"],
 datasets: [{
     label: '# of Votes',
     data: this.pieChartData,
     backgroundColor: [
            "#006400",
            "#FF4500",
            "#1C86EE",
 
     ],
     borderWidth: 1
 }]
} 
});
  }

    // date format
    dateFormat(data) {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: any = date.getMonth() + 1;
        let dt: any = date.getDate();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }

        return year + '-' + month + '-' + dt;
    }


    // get translator messages
    getTranslatorMessages() {
        this.projectsService.getTranslatorMessages()
            .subscribe((response) => {
                if (response) {
                    this.translatorMessages = response.data;
                }
            });
    }


    showMessageSection(res: any) {

        this.showMessage = res;
    }


    submit(res: any) {
      
        if (this.Form.value.message == '') {
            this.toastr.error("Message is Required!!");
            return;
        }
        this.sendMessageObj.from_user_id = this.user_id;
        this.sendMessageObj.message = this.Form.value.message;
        this.sendMessageObj.message_type = "2";
        this.sendMessageObj.message_id = res.id; // optional use only for dashbaord message reply

        this.projectsService.sendTranslatorMessages(res.project_id, this.sendMessageObj)
            .subscribe((response) => {
                if (response) {
                    this.toastr.success("Message sent!!");
              
                    this.Form.patchValue({
                        message: ''                     
                      });
                    this.getTranslatorMessages();
                }

            });
    }

}
