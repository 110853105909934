import { ProjectsService } from "app/main/Services/projects.service";
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatTableDataSource, MatSort } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import * as braintree from "braintree-web";
import { AppGlobals } from "app/main/app.global";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';
var CreateTransactionComponent = /** @class */ (function () {
    function CreateTransactionComponent(_formBuilder, toastr, projectsService, router, route, 
    // private dialogRef: MatDialogRef<CreateTransactionComponent>,
    // @Inject(MAT_DIALOG_DATA) public data :any,
    _global) {
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.projectsService = projectsService;
        this.router = router;
        this.route = route;
        this._global = _global;
        this.query_quote = "";
        this.paginate = {};
        this.displayedColumns = [
            "id",
            "name",
            "savedDate",
            "paymentMethod",
            "token",
            "action",
        ];
        this.show_existingUser = false;
        this.show_newUser = false;
        this.show_existing = true;
        this.show_new = false;
        this.show_search = true;
        this.dataCustomer = [];
        this.dataTransaction = [];
        this.totalCustomerTransaction = 0;
        this.payloadNonce = [];
        this.show_success = false;
        this.quote_id = null;
        this.tokenization_key = environment.tokenization_key;
    }
    CreateTransactionComponent.prototype.ngOnInit = function () {
        // this.quote_id = this.route.snapshot.params['id'];
        // this.quote_id=this.data.quote_id;
        this.quote_id = null;
        this.dataSource = [];
        this.href = this.router.url;
        if (this.href != "/pms/transactions/create") {
            this.quote_id = localStorage.getItem("quote_id");
        }
        this.getTransactions();
        this.createBraintreeUI();
        this.searchCustomerForm = this._formBuilder.group({
            field_value: ["", Validators.compose([Validators.required])],
        });
        this.paymentForm = this._formBuilder.group({
            transaction_amount: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern("^[+]?[0-9]*.?[0-9]+"),
                ]),
            ],
            order_id: ["", Validators.required],
            transaction_description: [""],
        });
        this.paymentFormNewUser = this._formBuilder.group({
            amount: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern("^[+]?[0-9]*.?[0-9]+"),
                ]),
            ],
            order_id: ["", Validators.required],
            transaction_description: [""],
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            phone: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern("^[0-9+d]{10}$"),
                ]),
            ],
            email: [
                "",
                Validators.compose([Validators.required, Validators.email]),
            ],
            saveToVault: [],
        });
    };
    CreateTransactionComponent.prototype.resetForm = function (value) {
        if (value === void 0) { value = undefined; }
        this.paymentFormNewUser.reset(value);
    };
    // first tab start
    CreateTransactionComponent.prototype.check_user = function (event) {
        if (event.source.value == "existing") {
            this.show_existing = true;
            this.show_search = true;
            this.show_new = false;
            this.show_newUser = false;
            this.dataSource = [];
        }
        else if (event.source.value == "new") {
            this.show_search = false;
            this.show_existing = false;
            this.show_new = true;
        }
    };
    // Existing vault customer start
    CreateTransactionComponent.prototype.searchCustomerTransactions = function () {
        var _this = this;
        this.dataSource = [];
        if (this.searchCustomerForm.value.field_value == " ") {
            this.toastr.error("Field value never be empty");
            return false;
        }
        var formValues = this.searchCustomerForm.value;
        this.projectsService.searchcustomerTransaction(formValues).subscribe(function (response) {
            if (response) {
                _this.dataTransaction = response.data;
                if (_this.dataTransaction.length == 0) {
                    // this.show_existingUser = false;
                    if (_this.quote_detail.quote_detail) {
                        _this.paymentFormNewUser.patchValue({
                            amount: _this.quote_detail.quote_detail.total,
                            order_id: _this.quote_detail.project_detail
                                .reference,
                            firstName: _this.quote_detail.customer_detail
                                .first_name,
                            lastName: _this.quote_detail.customer_detail
                                .last_name,
                            email: _this.quote_detail.customer_detail
                                .email_primary,
                            transaction_description: _this.quote_detail
                                .transaction_description,
                        });
                    }
                }
                else {
                    _this.show_existingUser = true;
                    if (_this.quote_detail.quote_detail) {
                        _this.paymentForm.patchValue({
                            transaction_amount: _this.quote_detail
                                .quote_detail.total,
                            order_id: _this.quote_detail.project_detail
                                .reference,
                            transaction_description: _this.quote_detail
                                .transaction_description,
                        });
                    }
                    _this.paymentFormNewUser.patchValue({
                        amount: "",
                        order_id: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                    });
                    _this.dataSource = new MatTableDataSource(_this.dataTransaction);
                }
            }
        }, function (error) {
            _this.toastr.error(_this._global.errorMessage);
        });
    };
    CreateTransactionComponent.prototype.create_payment = function (type, res) {
        this.token = res.token;
        if (type == "payment") {
            this.show_newUser = true;
        }
        this.customerdetail = res;
    };
    CreateTransactionComponent.prototype.createPaymentTransactions = function () {
        var _this = this;
        var obj = {
            transaction_amount: this.paymentForm.value.transaction_amount,
            token: this.token,
            order_id: this.paymentForm.value.order_id,
            transaction_description: this.paymentForm.value
                .transaction_description,
            quote_id: this.quote_id,
            email: this.customerdetail.email,
            first_name: this.customerdetail.first_name,
            last_name: this.customerdetail.last_name,
            phone: this.customerdetail.phone,
        };
        this.projectsService.customerPayment(obj).subscribe(function (response) {
            if (response) {
                _this.searchCustomerForm.patchValue({
                    field_value: [""],
                });
                _this.paymentForm.patchValue({
                    transaction_amount: [""],
                    order_id: [""],
                    transaction_description: [""],
                });
                _this.show_newUser = false;
                _this.show_existingUser = false;
                _this.toastr.success(response.status);
                if (_this.href != "/pms/transactions/create") {
                    // this.dialogRef.close(true);
                }
            }
        }, function (error) {
            _this.toastr.error(_this._global.errorMessage);
            if (_this.href != "/pms/transactions/create") {
                // this.dialogRef.close(true);
            }
        });
    };
    // Existing vault customer end
    // New payment with cc info start
    CreateTransactionComponent.prototype.createBraintreeUI = function () {
        var _this = this;
        braintree.client
            .create({
            authorization: this.tokenization_key
        })
            .then(function (clientInstance) {
            braintree.hostedFields
                .create({
                client: clientInstance,
                styles: {
                // Override styles for the hosted fields
                },
                // The hosted fields that we will be using
                // NOTE : cardholder's name field is not available in the field options
                // and a separate input field has to be used incase you need it
                fields: {
                    number: {
                        selector: "#card-number",
                        placeholder: "1111 1111 1111 1111",
                    },
                    cvv: {
                        selector: "#cvv",
                        placeholder: "111",
                    },
                    expirationDate: {
                        selector: "#expiration-date",
                        placeholder: "MM/YY",
                    },
                },
            })
                .then(function (hostedFieldsInstance) {
                _this.hostedFieldsInstance = hostedFieldsInstance;
                hostedFieldsInstance.on("focus", function (event) {
                    var field = event.fields[event.emittedBy];
                    var label = _this.findLabel(field);
                    label.classList.remove("filled"); // added and removed css classes
                    // can add custom code for custom validations here
                });
                hostedFieldsInstance.on("blur", function (event) {
                    var field = event.fields[event.emittedBy];
                    var label = _this.findLabel(field); // fetched label to apply custom validations
                    // can add custom code for custom validations here
                });
                hostedFieldsInstance.on("empty", function (event) {
                    var field = event.fields[event.emittedBy];
                    // can add custom code for custom validations here
                });
                hostedFieldsInstance.on("validityChange", function (event) {
                    var field = event.fields[event.emittedBy];
                    var label = _this.findLabel(field);
                    if (field.isPotentiallyValid) {
                        // applying custom css and validations
                        label.classList.remove("invalid");
                    }
                    else {
                        label.classList.add("invalid");
                    }
                    // can add custom code for custom validations here
                });
            });
        });
    };
    CreateTransactionComponent.prototype.tokenizeUserDetails = function () {
        var _this = this;
        if (this.paymentFormNewUser.value.amount == "" ||
            this.paymentFormNewUser.value.amount == null) {
            this.toastr.error("transaction amount never be empty!!");
            return;
        }
        if (this.paymentFormNewUser.value.amount < 0) {
            this.toastr.error("transaction amount never be negative!!");
            return;
        }
        if (this.paymentFormNewUser.value.order_id == "" ||
            this.paymentFormNewUser.value.order_id == null) {
            this.toastr.error("order id never be empty!!");
            return;
        }
        if (this.paymentFormNewUser.value.firstName == "" ||
            this.paymentFormNewUser.value.firstName == null) {
            this.toastr.error("customer first name  never be empty!!");
            return;
        }
        if (this.paymentFormNewUser.value.lastName == "" ||
            this.paymentFormNewUser.value.lastName == null) {
            this.toastr.error("customer last name  never be empty!!");
            return;
        }
        if (this.paymentFormNewUser.value.phone == "" ||
            this.paymentFormNewUser.value.phone == null) {
            this.toastr.error("phone never be empty!!");
            return;
        }
        if (this.paymentFormNewUser.value.email == "" ||
            this.paymentFormNewUser.value.email == null) {
            this.toastr.error("email never be empty!!");
            return;
        }
        this.hostedFieldsInstance
            .tokenize({ cardholderName: this.cardholdersName })
            .then(function (payload) {
            var obj = {
                amount: _this.paymentFormNewUser.value.amount,
                order_id: _this.paymentFormNewUser.value.order_id,
                firstName: _this.paymentFormNewUser.value.firstName,
                lastName: _this.paymentFormNewUser.value.lastName,
                phone: _this.paymentFormNewUser.value.phone,
                email: _this.paymentFormNewUser.value.email,
                payloadNonce: payload.nonce,
                saveToVault: _this.paymentFormNewUser.value.saveToVault,
                transaction_description: _this.paymentFormNewUser.value
                    .transaction_description,
                quote_id: _this.quote_id,
            };
            _this.projectsService.newCustomerPayment(obj).subscribe(function (response) {
                if (response) {
                    _this.hostedFieldsInstance.clear("number");
                    _this.hostedFieldsInstance.clear("cvv");
                    _this.hostedFieldsInstance.clear("expirationDate");
                    _this.cardholdersName = "";
                    _this.paymentFormNewUser.reset();
                    Object.keys(_this.paymentFormNewUser.controls).forEach(function (key) {
                        _this.paymentFormNewUser
                            .get(key)
                            .setErrors(null);
                    });
                    // this.paymentFormNewUser.reset({});
                    // this.paymentFormNewUser = this._formBuilder.group({
                    //   amount: ['',Validators.compose([Validators.required, Validators.pattern('^[+]?[0-9]*\.?[0-9]+')])],
                    //   order_id: ['', Validators.required],
                    //   transaction_description: [''],
                    //   firstName: ['', Validators.required],
                    //   lastName: ['', Validators.required],
                    //   phone: ['', Validators.compose([Validators.required, Validators.pattern('^\[0-9+\d]{10}$')])],
                    //   email: ['', Validators.compose([Validators.required, Validators.email])],
                    //   saveToVault: []
                    // });
                    // this.paymentFormNewUser.patchValue({
                    //   amount: [''],
                    //   order_id: [''],
                    //   transaction_description: [''],
                    //   firstName: [''],
                    //   lastName: [''],
                    //   phone: [''],
                    //   email: [''],
                    //   saveToVault: [false]
                    // });
                    // this.hostedFieldsInstance.teardown(function (err) {
                    //   if (err) {
                    //     console.error('Could not tear down Hosted Fields!');
                    //   } else {
                    //     console.info('Hosted Fields has been torn down!');
                    //   }
                    // });
                    _this.toastr.success(response.status);
                    if (_this.href != "/pms/transactions/create") {
                        // this.dialogRef.close(true);
                    }
                }
            }, function (error) {
                _this.toastr.error(_this._global.errorMessage);
            });
            // submit payload.nonce to the server from here
        })
            .catch(function (error) {
            _this.toastr.error(error.message);
            if (_this.href != "/pms/transactions/create") {
                // this.dialogRef.close(true);
            }
            // perform custom validation here or log errors
        });
    };
    CreateTransactionComponent.prototype.findLabel = function (field) {
        return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
    };
    // New payment with cc info  end
    // first tab end
    // second tab start
    CreateTransactionComponent.prototype.searchTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(["/pms/transactions/search/" + this.quote_id]);
        }
        else {
            this.router.navigate(["/pms/transactions/search"]);
        }
    };
    CreateTransactionComponent.prototype.CreateTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(["/pms/transactions/create/" + this.quote_id]);
        }
        else {
            this.router.navigate(["/pms/transactions/create"]);
        }
    };
    CreateTransactionComponent.prototype.getTransactions = function () {
        var _this = this;
        this.projectsService.getTransaction(this.quote_id).subscribe(function (res) {
            if (res) {
                _this.quote_detail = res;
                _this.searchCustomerForm.patchValue({
                    field_value: res.customer_detail.email_primary,
                });
                // this.searchCustomerTransactions();
            }
        }, function (error) {
            _this.toastr.error("Something went wrong, Please try later!");
        });
    };
    return CreateTransactionComponent;
}());
export { CreateTransactionComponent };
