import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { UserService } from 'app/main/Services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
var LanguageMasterComponent = /** @class */ (function () {
    function LanguageMasterComponent(userService, _formBuilder, toastr) {
        this.userService = userService;
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.type = 'Add';
        this.paginate = {};
        this.pageIndex = 1;
        this.pageSize = 10;
        this.query = '';
        this.filterSpecialization = '';
        this.languageData = [];
        this.displayedColumns = ['id', 'lang_name', 'lang', 'rate', 'margin', 'type', 'status', 'action'];
        this.final_data = {};
        this.updated_data = {};
        this.fQuery = '';
        this.filterStatus = '';
        this.filterMargin = '';
        this.filterCategory = '';
        this.filterSearchQuery = '';
        this.fChipsArray = [];
        this.search = {
            value: ''
        };
    }
    LanguageMasterComponent.prototype.ngOnInit = function () {
        this.getLanguage();
        this.addlanguageForm = this._formBuilder.group({
            lang_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
            lang: ['', Validators.compose([Validators.required])],
            rate: ['', Validators.compose([Validators.required])],
            rate_tier2: ['', Validators.compose([Validators.required])],
            rate_tier3: ['', Validators.compose([Validators.required])],
            rate_tier4: ['', Validators.compose([Validators.required])],
            rate_tier5: ['', Validators.compose([Validators.required])],
            margin: ['', Validators.compose([Validators.required])],
            type: ['', Validators.compose([Validators.required])],
            is_active: ['1', Validators.compose([Validators.required])]
        });
    };
    LanguageMasterComponent.prototype.submit = function () {
        if (this.type == 'Add') {
            this.addData();
        }
        else {
            this.updateData();
        }
    };
    LanguageMasterComponent.prototype.addData = function () {
        var _this = this;
        this.final_data = this.addlanguageForm.value;
        this.userService.addLangugae(this.final_data).subscribe(function (resposne) {
            if (resposne) {
                window.location.href = '/masterdata/language-master';
                _this.toastr.success('Language added successfully');
            }
        });
    };
    LanguageMasterComponent.prototype.updateData = function () {
        var _this = this;
        this.updated_data = this.addlanguageForm.value;
        this.userService.updateMasterLanguage(this.languageId, this.updated_data).subscribe(function (resposne) {
            if (resposne) {
                window.location.href = '/masterdata/language-master';
                _this.toastr.success('Language updated successfully');
            }
        });
    };
    LanguageMasterComponent.prototype.getLanguage = function () {
        var _this = this;
        var query = '?page=1&per_page=10';
        this.userService.getLanguagesListing(query)
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
                _this.dataSource = new MatTableDataSource(_this.languageData);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    LanguageMasterComponent.prototype.paginateData = function (event) {
        var _this = this;
        var aQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query += aQuery;
        this.userService.getLanguagesListing(this.query)
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
                _this.dataSource = new MatTableDataSource(_this.languageData);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    LanguageMasterComponent.prototype.editData = function (index) {
        var _this = this;
        this.type = 'Update';
        this.languageData.forEach(function (element, ind) {
            if (index == ind) {
                _this.languageId = element.id;
                _this.addlanguageForm.patchValue({
                    lang_name: element.lang_name,
                    lang: element.lang,
                    rate: parseFloat(element.rate),
                    rate_tier2: parseFloat(element.rate_tier2),
                    rate_tier3: parseFloat(element.rate_tier3),
                    rate_tier4: parseFloat(element.rate_tier4),
                    rate_tier5: parseFloat(element.rate_tier5),
                    margin: element.margin,
                    type: element.type,
                    is_active: element.is_active
                });
            }
        });
        var element = document.querySelector('#scrolTo');
        element.scrollIntoView();
    };
    LanguageMasterComponent.prototype.SubmitSearch = function () {
        this.onFilter(this.search.value, 'search');
    };
    LanguageMasterComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10';
        //  search filter
        if ((type == 'search' && evt != '' && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += '&search_text=' + this.filterSearchQuery;
        }
        else if (type == 'search' && evt == '') {
            this.filterSearchQuery = evt;
        }
        else if (this.filterSearchQuery !== '') {
            this.fQuery += '&search_text=' + this.filterSearchQuery;
        }
        if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
            this.filterStatus = evt.value;
            this.fQuery += '&status_is=' + this.filterStatus;
        }
        else if (type == 'status' && evt.value == '') {
            this.filterStatus = evt.value;
        }
        else if (this.filterStatus !== '') {
            this.fQuery += '&status_is=' + this.filterStatus;
        }
        if ((type == 'category_is' && evt.value != '' && evt.value !== undefined)) {
            this.filterCategory = evt.value;
            this.fQuery += '&category_is=' + this.filterCategory;
        }
        else if (type == 'category_is' && evt.value == '') {
            this.filterCategory = evt.value;
        }
        else if (this.filterCategory !== '') {
            this.fQuery += '&category_is=' + this.filterCategory;
        }
        if ((type == 'margin_is' && evt.value != '' && evt.value !== undefined)) {
            this.filterMargin = evt.value;
            this.fQuery += '&margin_is=' + this.filterMargin;
        }
        else if (type == 'margin_is' && evt.value == '') {
            this.filterMargin = evt.value;
        }
        else if (this.filterMargin !== '') {
            this.fQuery += '&margin_is=' + this.filterMargin;
        }
        this.userService.getLanguagesListing(this.fQuery)
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
                _this.dataSource = new MatTableDataSource(_this.languageData);
                _this.paginate = res.meta.pagination;
            }
        });
    };
    return LanguageMasterComponent;
}());
export { LanguageMasterComponent };
