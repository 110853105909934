import { Component ,OnInit} from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { SharedService } from 'app/main/shared.service';
@Component({
    selector   : 'welcome',
    templateUrl: './welcome.component.html',
    styleUrls  : ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit
{
    user_name:string;
    admin:boolean= false;
    hr:boolean= false;
    pms:boolean= false;
    finance:boolean= false;
    marketing:boolean= false;
   
    /**
     * Constructor
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    )
    {
 // Configure the layout
 this._fuseConfigService.config = {
    layout: {
        navbar   : {
            hidden: true
        },
        toolbar  : {
            hidden: false
        },
        footer   : {
            hidden: false
        },
        sidepanel: {
            hidden: true
        }
    }
};

        
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }

    ngOnInit() : void
    {
       

        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var portal_ids = currentUser.data.portal_ids;
        var user_group_id= currentUser.data.user_group_id;
        this.user_name=SharedService.get('username');
        if(user_group_id=="Admin"){

            portal_ids.forEach(res => {
            
                if(res==1 ){
                    this.admin=true;
                }
                if(res==2){
                    this.hr=true;
                }
                if(res==3){
                    this.pms=true;
                }
                if(res==4){
                    this.finance=true;
                }
                if(res==5){
                    this.marketing=true;
                }
              });
    
        }
        else{
            portal_ids.forEach(res => {
            
                // On Welcome Screen: Allow access based on portal_access change start
                // if(res==1 ){
                //     this.admin=true;
                // }
            // On Welcome Screen: Allow access based on portal_access change end
                if(res==2){
                    this.hr=true;
                }
                if(res==3){
                    this.pms=true;
                }
                if(res==4){
                    this.finance= true;
                }
                if(res==5){
                    this.marketing= true;
                }
              });
        }    
    }

    admin_select(){
        window.location.href = '/admin-dashboard';
    }

    hr_select(){
        window.location.href = '/hr/hr-dashboard';
    }

    marketing_select(){
        window.location.href = '/marketing/marketing-dashboard';
    }

    pms_select(){
        //window.location.href = '/pms/dashboard';
        window.location.href = '/pms/freelancer';
    }

    // financial_select(){
    //     window.location.href = '/financial/financial-dashboard';
    // }
}
