import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-deadline-change',
  templateUrl: './deadline-change.component.html',
  styleUrls: ['./deadline-change.component.scss']
})
export class DeadlineChangeComponent implements OnInit {

  Form:any;
  specialityData: any = [];
  today: Date = new Date();
  minDate = this.today;
  constructor( private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectsService: ProjectsService,
    private dialogRef: MatDialogRef<DeadlineChangeComponent>) { }

  ngOnInit() {
    
    this.Form = this._formBuilder.group({
      due_date : ['',Validators.compose([Validators.required])]
  });

  if(this.data.due_date!='' || this.data.due_date!=null){
    this.Form.patchValue({
      due_date: this.data.due_date
  });
  }
 

  }

  Onclose(){
    this.dialogRef.close(true);
}




  submit(){

    let date_value=this.dateFormat(this.Form.value.due_date) ;

   let final_data= {
      due_date: date_value
    }

    this.projectsService.updateDeadline(this.data.project_id , final_data)
    .subscribe((response) => {
        if(response) {
          this.toastr.success("Deadline change successfully!");
         this.Onclose();

        }
    });
  }


    
  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + '-' + month + '-' + dt + ' 00:00:00';
  }

}
