import { OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { Router } from '@angular/router';
var AddLeadComponent = /** @class */ (function () {
    function AddLeadComponent(projectsService, toastr, router, fb, vendorService) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.router = router;
        this.fb = fb;
        this.vendorService = vendorService;
        this.projectLeadsource = [];
        this.allCustomer = [];
        this.languageData = [];
        this.DiscountType = [];
        this.classValue = '';
        this.customerList = true;
        this.emaiExist = false;
        this.today = new Date();
        this.minDate = this.today;
        this.file_multiple_data = [];
        this.fileError = '';
        this.specialityResponse = [];
        this.serviceResponse = [];
        this.uploaded_files = [];
        this.uploaded_files_array = [];
        this.file_id_array = [];
        this.existing_check = false;
        this.company_id = null;
        this.customeraddress_id = null;
        this.customer_id = null;
        this.data = {
            selectCustomer: '',
            customerType: 'New'
        };
        this.filterSearchQuery = '';
        this.fQuery = '';
        this.dropdownSettings = {};
        this.dropdownList = [];
    }
    AddLeadComponent.prototype.ngOnInit = function () {
        this.getProjectSource();
        this.getLanguage();
        this.getIpAddress();
        this.getSpeciality();
        this.getDiscountType();
        this.getService();
        this.Form = this.fb.group({
            source_id: [5, Validators.compose([Validators.required])],
            request_type: [1, Validators.compose([Validators.required])],
            source_language: [null, Validators.compose([Validators.required])],
            target_language: [null, Validators.compose([Validators.required])],
            due_date: ['', Validators.compose([Validators.required])],
            first_name: ['', Validators.compose([Validators.required])],
            last_name: [''],
            email_address: ['', Validators.compose([Validators.required, Validators.email])],
            title: [''],
            phone_number: [''],
            company_name: [''],
            website_source: [''],
            city: [''],
            zip_code: [''],
            payment_method: [3, Validators.compose([Validators.required])],
            country: [''],
            state: [''],
            promo_code: [''],
            address_type: [1],
            address: [''],
            note: [''],
            service_type: ['Translation', Validators.compose([Validators.required])],
            specilization_id: ['', Validators.compose([Validators.required])],
            ip_address: [''],
            browser: [''],
            operating_system: [''],
            files_path: [''],
            file_ids: [''],
            certification_required: [true]
        });
        this.dropdownSettings = {
            singleSelection: false,
            textField: 'lang_name',
            allowSearchFilter: true
        };
    };
    // get project source
    AddLeadComponent.prototype.getProjectSource = function () {
        var _this = this;
        this.projectsService.getLeadSource()
            .subscribe(function (res) {
            if (res) {
                _this.projectLeadsource = res.data;
            }
        });
    };
    // get Source Language & Target Language 
    AddLeadComponent.prototype.getLanguage = function () {
        var _this = this;
        this.projectsService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
                _this.dropdownList = res.data;
            }
        });
    };
    AddLeadComponent.prototype.getIpAddress = function () {
        var _this = this;
        this.projectsService.getIpAddress()
            .subscribe(function (res) {
            if (res) {
                _this.ip_address = res.ip_address;
                _this.browser = res.name;
                _this.operating_system = res.platform;
            }
        });
    };
    AddLeadComponent.prototype.getDiscountType = function () {
        var _this = this;
        this.projectsService.getDiscountType()
            .subscribe(function (res) {
            if (res) {
                _this.DiscountType = res.data;
            }
        });
    };
    AddLeadComponent.prototype.discountType = function (evt) {
        this.Form.patchValue({
            promo_code: evt.value
        });
    };
    // start multiple file upload
    AddLeadComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    // addDocument
    AddLeadComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files = response.data;
            _this.uploaded_files_array.push(_this.uploaded_files);
            response.data.forEach(function (element) {
                _this.file_id_array.push(element.id);
            });
            _this.fileSuccess = 'Uploaded successfully!!';
        });
    };
    // end multiple file upload
    // Get Speciality 
    AddLeadComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                _this.specialityResponse = res;
            }
        });
    };
    AddLeadComponent.prototype.getService = function () {
        var _this = this;
        this.projectsService.getService()
            .subscribe(function (res) {
            if (res) {
                _this.serviceResponse = res.data;
            }
        });
    };
    // date format
    AddLeadComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt + ' 00:00:00';
    };
    AddLeadComponent.prototype.onFilter = function (evt, type) {
        if (evt == '') {
            this.data.selectCustomer = '';
            this.allCustomer = [];
            return;
        }
        if (evt.length > 2) {
            this.fQuery = '';
            // project search filter
            if ((type == 'search' && evt != '' && evt !== undefined)) {
                this.filterSearchQuery = evt;
                this.fQuery += '?search_text=' + this.filterSearchQuery;
            }
            else if (type == 'search' && evt == '') {
                this.filterSearchQuery = evt;
            }
            else if (this.filterSearchQuery !== '') {
                this.fQuery += '?search_text=' + this.filterSearchQuery;
            }
            var query = this.fQuery;
            this.getcustomer(query);
        }
        else {
            return;
        }
    };
    //get customer
    AddLeadComponent.prototype.getcustomer = function (query) {
        var _this = this;
        this.projectsService.getcustomerForLead(query)
            .subscribe(function (res) {
            if (res) {
                _this.allCustomer = res.data;
            }
        });
    };
    AddLeadComponent.prototype.submit = function () {
        var _this = this;
        var obj = {
            source_id: '',
            request_type: '',
            source_language: null,
            target_language: null,
            due_date: '',
            first_name: '',
            last_name: '',
            email_address: '',
            title: '',
            phone_number: '',
            company_name: '',
            website_source: '',
            city: '',
            zip_code: '',
            payment_method: 3,
            country: '',
            state: '',
            promo_code: '',
            address_type: 1,
            address: '',
            note: '',
            service_type: 'Translation',
            specilization_id: '',
            ip_address: '',
            browser: '',
            operating_system: '',
            files_path: '',
            file_ids: '',
            certification_required: true
        };
        if (this.file_id_array.length == 0) {
            this.toastr.error('Please upload file!!');
            return;
        }
        if (this.Form.value.source_language == null || this.Form.value.source_language.length == 0) {
            this.toastr.error('Please select source language!! ');
            return;
        }
        if (this.Form.value.target_language == null || this.Form.value.target_language.length == 0) {
            this.toastr.error('Please select target language!! ');
            return;
        }
        this.Form.value.file_ids = this.file_id_array;
        obj.file_ids = this.Form.value.file_ids;
        obj.ip_address = this.ip_address;
        obj.browser = this.browser;
        obj.operating_system = this.operating_system;
        obj.due_date = this.dateFormat(this.Form.value.due_date);
        obj.source_language = this.getLanguageName(this.Form.value.source_language);
        obj.target_language = this.getLanguageName(this.Form.value.target_language);
        obj.source_id = this.Form.value.source_id;
        obj.request_type = this.Form.value.request_type;
        obj.first_name = this.Form.value.first_name;
        obj.last_name = this.Form.value.last_name;
        obj.email_address = this.Form.value.email_address;
        obj.title = this.Form.value.title;
        obj.phone_number = this.Form.value.phone_number;
        obj.company_name = this.Form.value.company_name;
        obj.website_source = this.Form.value.website_source;
        obj.city = this.Form.value.city;
        obj.country = this.Form.value.country;
        obj.zip_code = this.Form.value.zip_code;
        obj.payment_method = this.Form.value.payment_method;
        obj.state = this.Form.value.state;
        obj.promo_code = this.Form.value.promo_code;
        obj.address_type = this.Form.value.address_type;
        obj.address = this.Form.value.address;
        obj.note = this.Form.value.note;
        obj.service_type = this.Form.value.service_type;
        obj.specilization_id = this.Form.value.specilization_id;
        obj.certification_required = this.Form.value.certification_required;
        if (obj.target_language == obj.source_language) {
            this.toastr.error('Source language & Target language must be diffrent!!');
            return;
        }
        this.projectsService.addLead(obj).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success('Add Lead Successfully!!');
                _this.router.navigate(['/pms/dashboard']);
            }
        });
    };
    AddLeadComponent.prototype.Cancel = function () {
        this.router.navigate(['/pms/dashboard']);
    };
    AddLeadComponent.prototype.CustomerType = function (evt) {
        if (evt.value == 'Existing') {
            this.customerList = false;
            this.existing_check = true;
            this.classValue = 'div_disabled';
        }
        else {
            this.customerList = true;
            this.existing_check = false;
            this.classValue = '';
            this.data.selectCustomer = '';
            this.Form.patchValue({
                first_name: '',
                last_name: '',
                email_address: '',
                title: '',
                phone_number: '',
                company_name: '',
                city: '',
                country: '',
                zip_code: '',
                address: '',
                state: '',
                website_source: ''
            });
        }
    };
    AddLeadComponent.prototype.searchCustomer = function (evt) {
        if (evt.option.value == '') {
            this.Form.patchValue({
                first_name: '',
                last_name: '',
                email_address: '',
                title: '',
                phone_number: '',
                company_name: '',
                city: '',
                country: '',
                zip_code: '',
                address: '',
                state: ''
            });
            this.toastr.error('Please select any customer!!');
            return;
        }
        this.project_id = evt.option.value;
        this.getProjectSingleCustomer();
    };
    AddLeadComponent.prototype.getProjectSingleCustomer = function () {
        var _this = this;
        this.projectsService.getProjectSingleCustomer(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.singleCustomer = response.data;
                _this.classValue = 'div_disabled';
                _this.existing_check = true;
                if (response.data.company) {
                    _this.company_id = response.data.company.data.id;
                }
                if (response.data.last_name == null) {
                    _this.data.selectCustomer = response.data.first_name;
                }
                else {
                    _this.data.selectCustomer = response.data.first_name + ' ' + response.data.last_name;
                }
                if (response.data.customeraddress != null) {
                    if (response.data.customeraddress.data.length > 0) {
                        _this.customeraddress_id = response.data.customeraddress.data[0].id;
                        _this.customer_id = response.data.customeraddress.data[0].customer_id;
                    }
                    if (response.data.company != null) {
                        if (response.data.customeraddress.data.length > 0) {
                            _this.Form.patchValue({
                                city: response.data.customeraddress.data[0].city,
                                country: response.data.customeraddress.data[0].country,
                                zip_code: response.data.customeraddress.data[0].zip_code,
                                address: response.data.customeraddress.data[0].address1,
                                state: response.data.customeraddress.data[0].state
                            });
                        }
                        _this.Form.patchValue({
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            email_address: response.data.email_primary,
                            title: response.data.title,
                            phone_number: response.data.phone,
                            website: response.data.company.data.website,
                            company_name: response.data.company.data.name
                        });
                    }
                    else {
                        if (response.data.customeraddress.data.length > 0) {
                            _this.Form.patchValue({
                                city: response.data.customeraddress.data[0].city,
                                country: response.data.customeraddress.data[0].country,
                                zip_code: response.data.customeraddress.data[0].zip_code,
                                address: response.data.customeraddress.data[0].address1,
                                state: response.data.customeraddress.data[0].state
                            });
                        }
                        _this.Form.patchValue({
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            email_address: response.data.email_primary,
                            title: response.data.title,
                            phone_number: response.data.phone,
                            website: '',
                            company_name: '',
                        });
                    }
                }
                else {
                    if (response.data.company != null) {
                        _this.Form.patchValue({
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            email_address: response.data.email_primary,
                            title: response.data.title,
                            phone_number: response.data.phone,
                            website: response.data.company.data.website,
                            company_name: response.data.company.data.name,
                            city: '',
                            country: '',
                            zip_code: '',
                            address: '',
                            state: ''
                        });
                    }
                    else {
                        _this.Form.patchValue({
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            email_address: response.data.email_primary,
                            title: response.data.title,
                            website: response.data.website,
                            phone_number: response.data.phone,
                            company_name: '',
                            city: '',
                            country: '',
                            zip_code: '',
                            address: '',
                            state: ''
                        });
                    }
                }
            }
        });
    };
    AddLeadComponent.prototype.getLanguageName = function (res) {
        var lang = '';
        if (res.length > 0) {
            res.forEach(function (element) {
                lang += element.lang_name + ', ';
            });
        }
        lang = lang.slice(0, -2);
        return lang;
    };
    AddLeadComponent.prototype.updateData = function () {
        var _this = this;
        var obj = {
            uuid: this.singleCustomer.uuid,
            title: this.Form.value.title,
            first_name: this.Form.value.first_name,
            last_name: this.Form.value.last_name,
            phone: this.Form.value.phone_number,
            email_primary: this.Form.value.email_address,
            company_id: this.company_id,
            company: {
                id: this.company_id,
                name: this.Form.value.company_name,
                website: ''
            },
            customeraddress: {
                id: this.customeraddress_id,
                customer_id: this.singleCustomer.id,
                address_type: this.Form.value.address_type,
                address1: this.Form.value.address,
                address2: null,
                city: this.Form.value.city,
                state: this.Form.value.state,
                country: this.Form.value.country,
                zip_code: this.Form.value.zip_code,
                suite: null
            }
        };
        this.projectsService.updateCustomer(this.singleCustomer.uuid, obj).subscribe(function (resposne) {
            if (resposne) {
                _this.toastr.success('Customer updated successfully');
            }
        });
    };
    AddLeadComponent.prototype.onItemSelect = function (item) {
    };
    AddLeadComponent.prototype.onSelectAll = function (items) {
    };
    AddLeadComponent.prototype.onItemDeSelect = function (items) {
    };
    AddLeadComponent.prototype.onDeSelectAll = function (items) {
    };
    return AddLeadComponent;
}());
export { AddLeadComponent };
