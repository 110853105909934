import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from 'app/main/shared.service';
import { Location } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-review-translator',
  templateUrl: './review-translator.component.html',
  styleUrls: ['./review-translator.component.scss']
})
export class ReviewTranslatorComponent implements OnInit {
  id: number;
  file_data: any = [];
  file_d:string;
  lang_name_native: string;
  reference_id: any;
  bulk_selected_progress: boolean = false;

  table_vendor_marketing_skillprice_data: any = {
    'social_media_rate':'',
    'podcast_rate':'',
    'skill_service_id':'',
    'event_rate':'',
    'webinar_rate':'',
    'reel_rate':'',
    'ads_rate':'',
    'youtube_rate':'',
    'post600_rate':'',
    'post900_rate':'',
    'post1500_rate':'',
    'casestudy_rate':'',
    'scripts_rate':''
  };
  file_id_data: any = [];
  data_update: any = {};
  BaseUrl_file: any;
  vendorcomment_data: [];
  Form: FormGroup;
  comment_data_update: any = {};
  user_id: string;
  comment_data_approve: any = {};
  comment_data_reject: any = {};
  special_comment_data: any = {};
  stage_id: number;
  day_name_test: string;
  resume_name_test: string;
  lang_test: string;
  specialities_test: string;
  skills: string;
  stageData: any = [];
  vendor_file_data: string;
  vendor_contract_data: string;
  vendor_resume_data: string;
  vendor_exta_document_data: string;
  vendor_contract_name: string;
  vendor_resume_name: string;
  vendor_exta_document_name: string;
  is_active: any;
  age: any = '';
 
  coa_array: any = [];
  data_response: any = {
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    is_new: '',
    stage: '',
    address1: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    email: '',
    skype_id: '',
    phone: '',
    translation_tools: '',
    tool_licence: '',
    payment_method: '',
    comment_orientation: '',
    cover_letter: '',
    education: '',
    comment_quiz: '',
    sms_notification: '',
    wtsp_notification: '',

    vendornativelanguage: {
      data: []
    },
    vendortranslationlangs: {
      data: []
    },
    vendorSubtitler: {
      data: []
    },
    vendoravailabilities: {
      data: []
    },
    vendorspecialities: {
      data: []
    },
  }

  displayedMarketingSkillPriceColumns = [{label:'Social Media',key:'social_media_rate',service_id:12},{label:'Podcast',key:'podcast_rate',service_id:12}, {label:'Event',key:'event_rate',service_id:12},{label:'Webinar',key:'webinar_rate',service_id:12}, {label:'Reel',key:'reel_rate',service_id:12}, {label:'Ads',key:'ads_rate',service_id:12}, {label:'Youtube',key:'youtube_rate',service_id:12}, {label:'Post 600',key:'post600_rate',service_id:13}, {label:'Post 900',key:'post900_rate',service_id:13}, {label:'Post 1500',key:'post1500_rate',service_id:13},{label:'Case Study',key:'casestudy_rate',service_id:13},{label:'Scripts',key:'scripts_rate',service_id:13}];
  constructor(private router: Router,
    private route: ActivatedRoute,
    private vendorService: VendorService,
    private toastr: ToastrService, private _fuseNavigationService: FuseNavigationService,
    private _formBuilder: FormBuilder,
    private _location: Location) {
    this.BaseUrl_file = environment.baseUrl_file;
  }

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.params['id']);

    this.user_id = SharedService.get('id');
    // this.getStage();
    this.getApproval();
    this.getTranslatorStatus();
    this.getVendorDoc();
    this.Form = this._formBuilder.group({
      comment: [''],
      stage_id: [''],
      comment_quiz: [''],
      comment_orientation: ['']
    });
  }

  getTranslatorStatus() {
    this.vendorService.getTranslatorStatus(this.id)
      .subscribe((res) => {
        if (res) {
          this.is_active = res.user_detail.status;
        }
      });
  }
  submit() {
    if (this.Form.value.comment == '') {
      this.comment_data_update.stage_id = this.Form.value.stage_id;
      this.comment_data_update.comment_quiz = this.Form.value.comment_quiz;
      this.comment_data_update.comment_orientation = this.Form.value.comment_orientation;
      this.vendorService.update(this.id, this.comment_data_update)
        .subscribe((response) => {
          if (response) {
            this.data_update = response;
            this.getApproval();
            this.toastr.success('Updated');
          }
        });
    }
    else {

      if (this.Form.value.comment == null) {
        this.toastr.error('Special Comment required!!');
        return;
      }
      this.special_comment_data.comment = this.Form.value.comment;
      this.special_comment_data.vendor_id = this.id;
      this.special_comment_data.user_id = this.user_id;
      this.vendorService.addComment(this.special_comment_data)
        .subscribe((response) => {
          if (response) { }
        }, (error) => {
          this.toastr.error(error);
        });

      this.comment_data_update.stage_id = this.Form.value.stage_id;
      this.comment_data_update.comment_quiz = this.Form.value.comment_quiz;
      this.comment_data_update.comment_orientation = this.Form.value.comment_orientation;
      this.vendorService.update(this.id, this.comment_data_update)
        .subscribe((response) => {
          if (response) {
            this.data_update = response;
            this.getApproval();
            this.toastr.success('Updated');
          }
        });
    }
  }

  downloadVendorZip(){
    let data={
      vendor_doc_uuid:this.file_id_data,
      vendor_ref:this.reference_id
    }
     this.vendorService.downloadVendorZip(data).subscribe(
         resposne => {
             if (resposne) {
                   this.file_d= resposne.url;
             }
         }
     );
 }
  getVendorDoc() {
    this.vendorService.getVendorDoc(this.id)
      .subscribe((res) => {
        if (res) {
          this.file_data = res.vendor_docs;
        }
      });
  }


  selectFile(evt, res) {
    if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
      this.file_id_data.push(res.uuid);
    }
    else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
      let index = this.file_id_data.indexOf(res.uuid);
      this.file_id_data.splice(index, 1);
    }
    this.downloadVendorZip();
  }


  selectBulkProgress(evt) {
    this.file_id_data = [];
    let check = false;
    this.file_data.forEach((element, key) => {
      element["check"] = evt.checked;
      if (evt.checked) {
        element.check = true;
        this.file_id_data.push(element.uuid);
      }
      else {
        this.file_id_data = [];
      }
    });
    this.downloadVendorZip();
    if (check) {
      this.bulk_selected_progress = true;
    }
  }


  UncheckedFile() {
    this.bulk_selected_progress = false;
    this.file_data.forEach((element, key) => {
      element["check"] = false;
      let index = this.file_id_data.indexOf(element.uuid);
      this.file_id_data.splice(index, 1);
    });

  }

  adminApprovalPending(val) {

    if (this.Form.value.comment == '') {
      this.comment_data_approve.stage_id = val;
      this.comment_data_approve.comment_quiz = this.Form.value.comment_quiz;
      this.comment_data_approve.comment_orientation = this.Form.value.comment_orientation;
      this.comment_data_approve.status = 1;
      this.vendorService.update(this.id, this.comment_data_approve)
        .subscribe((response) => {
          if (response) {
            this.data_update = response;
            this.getApproval();
            this.toastr.success('Approved');
          }
        });
    }
    else {

      if (this.Form.value.comment == null) {
        this.toastr.error('Special Comment required!!');
        return;
      }
      this.special_comment_data.comment = this.Form.value.comment;
      this.special_comment_data.vendor_id = this.id;
      this.special_comment_data.user_id = this.user_id;
      this.vendorService.addComment(this.special_comment_data)
        .subscribe((response) => {
          if (response) {
            this.getApproval();
            this.toastr.success('Special comment Updated');
          }
        }, (error) => {
          this.toastr.error(error);
        });
      this.comment_data_approve.stage_id = val;
      this.comment_data_approve.comment_quiz = this.Form.value.comment_quiz;
      this.comment_data_approve.comment_orientation = this.Form.value.comment_orientation;
      this.comment_data_approve.status = 1;
      this.vendorService.update(this.id, this.comment_data_approve)
        .subscribe((response) => {
          if (response) {
            this.data_update = response;
            this.toastr.success('Approved');
          }
        });
    }
  }

  finalApproval(val) {
    let data = {
      stage_id: val
    }

    this.vendorService.finalTranslatorApprove(this.id, data).subscribe(
      resposne => {
        if (resposne) {
          this.toastr.success('Freelancer Approved successfully');
          this.router.navigate(['marketing/freelancer']);
        }
      }
    );

  }

  submitDataReject(value) {
    if (this.Form.value.comment == '') {
      this.comment_data_reject = this.Form.value;
      this.comment_data_reject.stage_id = value;
      this.comment_data_reject.status = 0;
      this.vendorService.update(this.id, this.comment_data_reject)
        .subscribe((response) => {
          if (response) {
            this.data_update = response;
            this.toastr.success('Rejected');
          }
        });
    }
    else {
      if (this.Form.value.comment == null) {
        this.toastr.error('Special Comment required!!');
        return;
      }
      this.special_comment_data.comment = this.Form.value.comment;
      this.special_comment_data.vendor_id = this.id;
      this.special_comment_data.user_id = this.user_id;
      this.vendorService.addComment(this.special_comment_data)
        .subscribe((response) => {
          if (response) {
            this.toastr.success('Special comment Updated');
          }
        }, (error) => {
          this.toastr.error(error);
        });
      this.comment_data_reject = this.Form.value;
      this.comment_data_reject.stage_id = value;
      this.comment_data_reject.status = 0;
      this.vendorService.update(this.id, this.comment_data_reject)
        .subscribe((response) => {
          if (response) {
            this.data_update = response;
            this.toastr.success('Rejected');
          }
        });
    }
  }

  getStage() {
    this.vendorService.getStage()
      .subscribe((response: any) => {
        if (response) {
          this.stageData = response.data;
        }
      });
  }
  service_id='';
  getApproval() {
    this.vendorService.get(this.id)
      .subscribe((response) => {
        if (response) {
          this.stage_id = response.data.stage.data.id;
          this.reference_id = response.data.reference_id;
          response.data.vendorprofileimage.data.forEach(element => {
            this.vendor_file_data = this.BaseUrl_file + element.path;
          });
          if (response.data.vendorcontract.data.length != 0) {
            response.data.vendorcontract.data.forEach(element => {
              this.vendor_contract_data = this.BaseUrl_file + element.path;
              let x = element.path.split('/');
              this.vendor_contract_name = x[2];
            });
          }

          if(response.data.dob !='' && response.data.dob !=null){
                let bdate = new Date(response.data.dob);
                let ntimeDiff = Math.abs(Date.now() - bdate.getTime() );
                this.age = Math.floor((ntimeDiff / (1000 * 3600 * 24)) / 365);
          }

          if (response.data.vendorresume.data.length != 0) {
            response.data.vendorresume.data.forEach(element => {
              this.vendor_resume_data = this.BaseUrl_file + element.path;
              let x = element.path.split('/');
              this.vendor_resume_name = x[2];
            });
          }


          if (response.data.extradocument != null) {
              this.vendor_exta_document_data = this.BaseUrl_file + response.data.extradocument.data.path;
              this.vendor_exta_document_name = response.data.extradocument.data.name;
          }

          if (response.data.vendornativelanguage != null) {
            this.lang_name_native = response.data.vendornativelanguage.data.lang_name;
          }

          if (response.data.vendorcoafiles.data.length != 0) {
            response.data.vendorcoafiles.data.forEach(element => {
              let coa_url = this.BaseUrl_file + element.coafile.data.path;
              let coa_name_path = element.coafile.data.path.split('/');
              let coa_name = coa_name_path[2];
              let coa_obj = {
                url: coa_url,
                name: coa_name
              }
              // this.coa_id_array()
              this.coa_array.push(coa_obj);
            });
          }

          this.vendorcomment_data = response.data.vendorcomment.data;
          let day = '';
          response.data.vendoravailabilities.data.forEach(elem => {
            day += elem.day_name + ', ';
            this.day_name_test = day;
            this.day_name_test = this.day_name_test.slice(0, -2);
          });

          let specialities = '';
          if (response.data.vendorspecialities.data.length > 0) {
            response.data.vendorspecialities.data.forEach(elem => {
              if (elem.specialities !== undefined) {
                specialities += elem.specialities.data.name + ', ';
                this.specialities_test = specialities;
                this.specialities_test = this.specialities_test.slice(0, -2);
              }
            });
          }

          let skills = '';
          if (response.data.vendorskill.data.length > 0) {
            response.data.vendorskill.data.sort(function (a, b) {
              return a.skill_id - b.skill_id;
            });
            response.data.vendorskill.data.forEach(elem => {
              if (elem.skill !== undefined) {
                console.log("elem.skill.data",elem.skill.data)
                skills += elem.skill.data.name + ', ';
                this.service_id=elem.skill.data.service_id;
                this.skills = skills;
                this.skills = this.skills.slice(0, -2);
              }
            });
          }

          let lang = '';
          response.data.vendortranslationlangs.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.lang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
            elem.proofreading_rate = elem.proofreading_rate;
          });


          let subtitlerslang = '';
          response.data.vendorSubtitler.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.subtiterslang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
            elem.subtitle_rate = elem.subtitle_rate;
          });
          //console.log(response.data.vendorSubtitler.data);
          if(response.data.vendorSubtitler.data.length > 0){
             response.data.vendorSubtitler.data = response.data.vendorSubtitler.data.sort((a,b) => a.skillservice.data.name.localeCompare(b.skillservice.data.name));
          }

          let treplang = '';
          response.data.vendorTreping.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
          });

          if(response.data.vendorMarketingSkillPrice.data.length>0){
            this.table_vendor_marketing_skillprice_data=response.data.vendorMarketingSkillPrice.data[0];
          }else{
            this.table_vendor_marketing_skillprice_data={
              'social_media_rate':'',
              'podcast_rate':'',
              'skill_service_id':'',
              'event_rate':'',
              'webinar_rate':'',
              'reel_rate':'',
              'ads_rate':'',
              'youtube_rate':'',
              'post600_rate':'',
              'post900_rate':'',
              'post1500_rate':'',
              'casestudy_rate':'',
              'scripts_rate':''
            };
          }
          this.data_response = response.data;
          let resume = '';
          response.data.vendorfiles.data.forEach(elem => {
            resume += elem.file.data.name + ', ';
            this.resume_name_test = resume;
            this.resume_name_test = this.resume_name_test.slice(0, -2);
          });
          this.Form.patchValue({
            comment: this.data_response.comment_admin,
            stage_id: this.stage_id
          });
        }
      });
  }

  back() {
    this._location.back();
  }

  Edit() {
    this.router.navigate(['marketing/freelancer/' + this.id]);
  }

  Send_mail_single(val) {
    let progress_single = [];
    let send_email_single_data: any = {};
    progress_single.push(val.user_id);
    send_email_single_data.ids = progress_single;
    send_email_single_data.resend = true;
    this.vendorService.sendProgressMail(send_email_single_data)
      .subscribe((response) => {
        if (response) {
          send_email_single_data = {};
          progress_single = [];
          this.toastr.success('Please check email');
          this.getApproval();
        }
      });
  }
}
