import { Component, OnInit } from '@angular/core';
export interface addServData {
  position: number;
  addServTitle: string;
  addServDesc: string;
}
const ELEMENT_DATA: addServData[] = [
  {position: 1, addServTitle: 'Notorization', addServDesc: 'Document Translation'},
  {position: 2, addServTitle: ' Apostile', addServDesc: 'Attestation/Apostile of Documents and Implementation of the  Apostile'},

];
@Component({
  selector: 'app-additional-services-master',
  templateUrl: './additional-services-master.component.html',
  styleUrls: ['./additional-services-master.component.scss']
})
export class AdditionalServicesMasterComponent implements OnInit {
  displayedColumns: string[] = ['position', 'addServTitle', 'addServDesc', 'action'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit() {
  }

}
