
    <form name="Form" [formGroup]="Form">
      <div class="p-16 pt-4 m-0">
      <div fxLayout="column"  fxFlex="100">
        <mat-form-field  fxFlex="100">
          <mat-label>Select Email Template</mat-label>
          <mat-select (selectionChange)="onTemplateSelect($event)">
            <mat-option [value]="''">
              Select
            </mat-option>
            <mat-option *ngFor="let tempdata of emailTemplateData" [value]="tempdata.id">
              {{tempdata.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  <div class="p-16 pt-4 m-0">
    <div fxLayout="column" fxLayoutAlign="start" fxFlex="100">
       <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
        <mat-form-field fxFlex="50">
          <mat-label>Subject</mat-label>
          <input matInput formControlName="subject">
          <mat-error  *ngIf="Form.get('subject').hasError('required')">
            Subject is required!
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <mat-label>cc</mat-label>
          <input matInput formControlName="cc">
        </mat-form-field>

      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
        <ckeditor formControlName="ckeditorContent" [config]="config" [readonly]="false" debounce="500">
        </ckeditor>
      </div>
    </div>
  </div>
  <div class="actWraper" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
    <button mat-stroked-button color="warn" (click)="Onclose()">Cancel</button>
    <button mat-raised-button color="accent" (click)="submit()" [disabled]="Form.invalid">Submit</button>
  </div>
</form>