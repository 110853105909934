<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>

  <div class="white-box">
    <div class="tHeader">
      <h2>Cloud OCR Tools</h2>
    </div>
    <div class="boxBodyInn">

      <div fxLayout="row wrap" fxLayoutGap="20px">
        <div fxFlex="48%">

          <div *ngIf='mainprogressbar' class="cssloadcube-overlay2">
            <img src="assets/images/preloader_logo.gif" />
          </div>

          <h3 class="text-center"> Load source file (*.png or *.jpg)</h3>

          <div class="fileUploadWraper">
            <mat-label>Drag & Drop Files Here / Click "Add Files": </mat-label>
            <button mat-raised-button color="basic" type="button" matTooltip="Upload Document" (click)="onFileUpload()">
              <i class="material-icons">cloud_upload</i> Add Files
            </button>
            <input type="file" id="docUpload" name="docUpload" style="display:none;" multiple />
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
              <span style="color: green;">{{fileSuccess}}</span>
            </div>
          </div>

          <div class="box-blank" *ngIf="uploaded_files_array.length>0">
            <span *ngFor="let res of uploaded_files_array">
              <p *ngFor="let res1 of res">
                {{res1.name}}
              </p>
            </span>
          </div>

        </div>

        <div fxFlex="48%" class="text-center box-blank">

          <h3 class="text-center"> Converted file (*.rtf)</h3>
          <div *ngIf='mainProgressbarConverted' class="cssloadcube-overlay2">
            <img src="assets/images/preloader_logo.gif" />
          </div>
          <div *ngIf="download_file_array.length>0">
            <div class="resultWraper" *ngFor="let res of download_file_array">
              <p>
                <a [href]="res.file_url" class="mat-flat-button" target="_blank" download> <i
                    class="material-icons">cloud_download</i> {{res.file_name}}</a>
              </p>

            </div>
          </div>

        </div>
      </div>



    </div>
  </div>

  <!-- OCR tools listing -->
  <div class="white-box">
    <div class="tHeader">
      <h2>OCR History :</h2>
    </div>
    <div class="boxBodyInn2">
      <form name="Form" [formGroup]="Form">
        <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
          <div fxFlex="32" class="m-8">
            <mat-form-field>
              <mat-label>Search by File Name</mat-label>
              <input matInput formControlName="search_value">
            </mat-form-field>
          </div>
          <div fxFlex="20">
            <button mat-stroked-button color="primary" (click)="submit()" [disabled]="Form.invalid"><i
                class="material-icons">search</i></button>
          </div>
        </div>
      </form>

      <!--list Ticket History-->
      <div *ngIf="paginate.total>0">
        <div *ngIf='mainProgressbarhistory' class="cssloadcube-overlay2">
          <img src="assets/images/preloader_logo.gif" />
        </div>
        <table mat-table [dataSource]="dataSource" class="table">
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef fxFlex="8">Id</mat-header-cell>
            <mat-cell *matCellDef="let product; let i =index;" fxFlex="8">
              <div class="text-truncate"> {{i+1}}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="file_name">
            <mat-header-cell *matHeaderCellDef fxFlex="70"> File Name</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="70">
              <div class="text-truncate">{{product?.process?.data?.name}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef fxFlex="10">Created At</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="10">
              <div class="text-truncate">{{product?.created_at.date | date:'yyyy-MM-dd'}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="download">
            <mat-header-cell *matHeaderCellDef fxFlex="10"> </mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="10">
              <a [href]="BaseUrl_file + product?.process?.data?.url" class="mat-accent" target="_blank" download>
                Download</a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
          <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
          </mat-row>
        </table>
        <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
          [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
          [pageSizeOptions]="[5,10,20,30,40,50,60]" (page)='paginateData($event)'>
        </mat-paginator>
      </div>

    </div>
    <div *ngIf="paginate.total==0" class="noDataFound"> No data available!!
    </div>
  </div>
</div>