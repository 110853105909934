<div class="white-box">
  <div class="tHeader wMenu">
      <div  class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;" fxLayoutGap="10px">
          <!-- <div *ngIf='mainprogressbar' class="cssloadcube-overlay">
              <span class="helper"></span>
              <img src="assets/images/preloader_logo.gif" />
          </div> -->
          <div fxFlex="30">
          <h2>Files</h2>
          </div>
          <div fxFlex="70" class="text-right">
            <button *ngIf="file_id_data.length>0" mat-stroked-button (click)="UncheckedFile()" > 
                  <a [href]="file_d" target="_blank" download ><i class="material-icons" >
               cloud_download
             </i> Download All </a></button>
              <button class="mr-4"  mat-stroked-button (click)="onCreate()"><i class="material-icons"> cloud_upload </i> Add Files </button>
              <button  mat-stroked-button (click)="ocrTools()"><i class="material-icons"> cloud_upload </i> Ocr Tools </button>
          </div>
         </div>
  </div>

   <div class="boxBody">
      <div class="boxBodyInn" *ngIf="file_data.length>0">


          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
   
              <div class="mr-8"> <i class="material-icons">filter_list</i>   Short by  </div>
              <mat-form-field   fxFlex="20">
                  <mat-label>Category</mat-label>
                  <mat-select>
                      <mat-option label="New" value="New">Admin</mat-option>
                      <mat-option label="Sample" value="Sample">HR</mat-option>
                      <mat-option label="Sample" value="Sample">PM</mat-option>
                      <mat-option label="Sample" value="Sample">Management</mat-option>
                      <mat-option label="Sample" value="Sample">IT</mat-option>
                   </mat-select>
                </mat-form-field>
                     <mat-form-field   fxFlex="20">
                         <mat-label>Added by</mat-label>
                         <mat-select>
                             <mat-option label="New" value="New">Admin</mat-option>
                             <mat-option label="Sample" value="Sample">HR</mat-option>
                             <mat-option label="Sample" value="Sample">PM</mat-option>
                             <mat-option label="Sample" value="Sample">Management</mat-option>
                             <mat-option label="Sample" value="Sample">IT</mat-option>
                          </mat-select>
                       </mat-form-field>
                       <mat-form-field   fxFlex="20">
                         <mat-label>Language</mat-label>
                         <mat-select>
                             <mat-option label="New" value="New">Quote Creation</mat-option>
                             <mat-option label="Sample" value="Sample">Project Assignmment</mat-option>
                             <mat-option label="Sample" value="Sample">User Login</mat-option>
                             <mat-option label="Sample" value="Sample">Payments</mat-option>
                             <mat-option label="Sample" value="Sample">Approval</mat-option>
                             <mat-option label="Sample" value="Sample">Promotion</mat-option>
                             <mat-option label="Sample" value="Sample">Subscription</mat-option>
                          </mat-select>
                       </mat-form-field>
                       <mat-form-field   fxFlex="20">
                          <mat-label>Shared with</mat-label>
                          <mat-select>
                              <mat-option label="New" value="New">Jidcber(Customer)</mat-option>
                              <mat-option label="Sample" value="Sample">Brdeu (Vendor)</mat-option>
                             
                           </mat-select>
                        </mat-form-field>
             
             
             
             
             
             </div>
      
            <table class="table table-bordered" *ngIf="file_data.length>0">
              <thead>
                <tr>
                  <th scope="col"><mat-checkbox [(ngModel)]="bulk_selected_progress" (change)="selectBulkProgress($event)"></mat-checkbox></th>
                  <th scope="col">Name</th>
                  <th scope="col">Category</th>
                  <th scope="col">Language</th>
                  <th scope="col">Last Modified</th>
                  <th scope="col">Added By</th>
                  <th scope="col">Shared With</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let file_res of file_data">
                  <th scope="row"><mat-checkbox  [(ngModel)]="file_res.check"  (change)="selectFile($event,file_res)"></mat-checkbox></th>
                  <td>                
                        <a [href]="file_res.file_url" target="_blank" download>{{file_res.name}}</a>
                  </td>
                  <td>{{file_res?.filetype}}</td>
                  <td>{{file_res?.type}}</td>
                  <td>{{file_res?.created_at?.date | date: 'yyyy-MM-dd'}}  {{file_res?.created_at?.date | date:"HH:mm"}}</td>
                  <td>{{file_res?.fullname}}</td>
                  <td></td>
                  <td> <button mat-icon-button class="remove-color-button secondary-text" matTooltip="Delete"
                    (click)="removeProjectFile(file_res)">
                    <mat-icon class="s-20">delete</mat-icon>
                  </button></td>
                </tr>
              </tbody>
            </table>
      </div>
      <div *ngIf="file_data.length==0" class="noDataFound"> No data available!!
      </div>
  </div>

</div>