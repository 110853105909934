import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatTableDataSource,MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'app/main/app.global';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-stripe-transaction',
  templateUrl: './search-stripe-transaction.component.html',
  styleUrls: ['./search-stripe-transaction.component.scss'],
  providers: [AppGlobals]
})
export class SearchStripeTransactionComponent implements OnInit {
  searchTransactionForm: FormGroup;
  totaltransaction:number;
  dataSource: any;
  noRecord: any;
  show_record:boolean=false;
  quote_id:number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'amount', 'status', 'cardholderName', 'creditCardNumber', 'email', 'date'];
  constructor(private _formBuilder: FormBuilder,private router: Router,
    private projectsService: ProjectsService,
    private toastr: ToastrService,private _global: AppGlobals,private route: ActivatedRoute) { }

  ngOnInit() {
    this.quote_id = this.route.snapshot.params['id'];
    this.searchTransactionForm = this._formBuilder.group({
      email: ["",Validators.compose([Validators.required, Validators.email]),]
    });
  }

  search() {
    let formData = this.searchTransactionForm.value;
    this.show_record=true;
    this.projectsService.searchStripeTransaction(formData)
      .subscribe((response:any) => {
        if (response) {
          //console.log(response);
          if(response.data.length >0){
              this.totaltransaction=response.data.length;
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.noRecord = '';
          }else{
            this.dataSource = '';
            this.noRecord ='no record found';
          }
        }
      }, (error) => {
        this.toastr.error(this._global.errorMessage);
      });
  }

  searchTransaction(){
    if(this.quote_id!=null){
      this.router.navigate(['/pms/stripetransactions/search/'+this.quote_id]);
     }else{
      this.router.navigate(['/pms/stripetransactions/search']);
     }
 
  }

  CreateTransaction(){
    if(this.quote_id!=null){
      this.router.navigate(['/pms/stripetransactions/create/'+this.quote_id]);
     }else{
      this.router.navigate(['/pms/stripetransactions/create']);
     }
    
 
  }

}
