
<div class="page-layout profilePage w75 p-24 bg-white" fusePerfectScrollbar>
    
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" (click)="back()" matTooltip="Back" mat-icon-button class="mr-12 mat-back"><mat-icon>keyboard_backspace</mat-icon></button>
    <span  class="title-text h1 " *ngIf="type == 'Update'"> Edit portal User</span>
    <span class="title-text h1 " *ngIf="type == 'Add'">
        Add new portal user
    </span>
</div>
 

  <div class="content">
    <div class="white-box">

        <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            
           
                <form class="mt-16" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="userForm"  [formGroup]="userForm">
                  <div class="pr-24 pl-24" fxLayout="column" fxFlex="1 0 auto" fxFlex="100">
                    <h4>Personal Information</h4>

                    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start" class="mb-12" fxLayoutGap="20px">
                     
                      <mat-form-field   fxFlex="45">
                            <mat-label class="font-size-12">Full Name*</mat-label>
                            <input matInput formControlName="fullname">
              
                            <mat-error *ngIf="userForm.get('fullname').hasError('required')">
                                    Full Name is required!
                                </mat-error>
                                <mat-error
                                    *ngIf="!userForm.get('fullname').hasError('required') &&
                                    userForm.get('fullname').hasError('minlength')">
                                    Full Name must atleast 4 characters!
                                </mat-error>
                        </mat-form-field>
                 
                        <mat-form-field   fxFlex="45">
                          <mat-label class="font-size-12">Email Address*</mat-label>
                          <input matInput  formControlName="email">
                          <mat-error *ngIf="userForm.get('email').hasError('required')">
                                Email is required
                            </mat-error>
                            <mat-error
                                *ngIf="!userForm.get('email').hasError('required') &&
                                userForm.get('email').hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                      </mat-form-field>
                    </div>

                      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start" class="mb-12" fxLayoutGap="20px">
                      <mat-form-field   fxFlex="45">
                        <mat-label class="font-size-12">User Name*</mat-label>
                        <input matInput  formControlName="username">
                        <mat-error *ngIf="userForm.get('username').hasError('required')">
                                User Name is required
                            </mat-error>
                            <mat-error *ngIf="userForm.get('username').hasError('minlength')">
                                    Minimum 3 characters required!
                                </mat-error>
                  
                    </mat-form-field>
                      
                          <mat-form-field   fxFlex="45">
                              <mat-label class="font-size-12" *ngIf="type == 'Add'" >Password*</mat-label>
                              <mat-label class="font-size-12" *ngIf="type == 'Update'" >Password</mat-label>
                              <input matInput  type="text" formControlName="password" >
                
                                  <mat-error *ngIf="userForm.get('password').hasError('required')">
                                        Password is required
                                    </mat-error>
                                    <mat-error *ngIf="userForm.get('password').hasError('minlength')">
                                            Minimum 6 characters required!
                                    </mat-error>
                          </mat-form-field>
                       
                      </div>
                     
                      <mat-checkbox  (change)="generate_password($event)"   fxFlex="25" fxFlex.gt-sm="25">Auto-generate password</mat-checkbox>
              
                      <!-- <mat-checkbox   fxFlex="25" fxFlex.gt-sm="25" [disabled]="type=='Update'">
                        Make this user change their password when they first sign in
                      </mat-checkbox> -->

                      <mat-divider class="mt-12"></mat-divider>
                      <h4>Role/Position*</h4>
                      <mat-radio-group  class="mt-12 mb-12"  fxFlex="100" fxFlex.gt-sm="25" formControlName="user_group_id">
                        <mat-radio-button value="Admin" class="pr-20">Admin</mat-radio-button>
                        <mat-radio-button value="Hr-Admin" class="pr-20">HR Manager</mat-radio-button>
                        <!-- <mat-radio-button value="Operation-Manager" class="pr-20">Operation Manager</mat-radio-button> -->
                        <mat-radio-button value="Project-Manager" class="pr-20">Project Manager</mat-radio-button>
                        <!-- <mat-radio-button value="Finance-Manager" class="pr-20">Finance Manager</mat-radio-button> -->
                     </mat-radio-group>

                     <h4>Portal Access</h4>
                     <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start" fxLayoutGap="10px">
                       <mat-checkbox  value="1" [checked]="selected_portal_access.includes(1)" fxFlex="25" fxFlex.gt-sm="25" (change)="check_portal_access($event)">
                        Admin 
                      </mat-checkbox>
                      <mat-checkbox  value="2"  [checked]="selected_portal_access.includes(2)" fxFlex="25" fxFlex.gt-sm="25" (change)="check_portal_access($event)">
                          HR
                        </mat-checkbox>
                        
                     </div>

                     <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start" fxLayoutGap="10px">
                        <mat-checkbox  value="3" [checked]="selected_portal_access.includes(3)" fxFlex="25" fxFlex.gt-sm="25" (change)="check_portal_access($event)">
                         PMS 
                       </mat-checkbox>
                       <mat-checkbox  value="5"  fxFlex="25" [checked]="selected_portal_access.includes(5)" fxFlex.gt-sm="25" (change)="check_portal_access($event)">
                           Marketing 
                         </mat-checkbox>
                    
                      </div>
                      <h4>Status*</h4>
                      <div fxLayout="column" fxLayout.gt-sm="row" class="mb-12" fxLayoutAlign="start" fxLayoutGap="10px">

                            <mat-radio-group  fxFlex="100" fxFlex.gt-sm="100" formControlName="status" >
                        
                                    <mat-radio-button value="1" class="pr-20">Active</mat-radio-button>
                                    <mat-radio-button value="0" class="pr-20">Inactive </mat-radio-button>
                                   
                         </mat-radio-group>
                        
                        </div>

                        <h4>User Image</h4>
                        <div class="pUserImage mt-12 mb-12">
                            <img [src]="vendor_file_data" onerror="this.src='assets/images/avatars/profile.jpg'" width="120"  /><br />
                            <button mat-stroked-button type="button" (click)="onFileUpload()" class="mt-8">Upload Image</button>
                            <input type="file" id="docUpload" name="docUpload" style="display:none;" />
                          </div>
                    </div>
                  
                    <div class="actWraper">
                        <p class="section">

                            <mat-checkbox (change)="check_send_detail($event)" [disabled]="type=='Update' && userForm.value.password=='' " formControlName="send_detail" >Send Login Credentials on Email</mat-checkbox>
                            </p>

                      <button matTooltip="{{type}} portal user" mat-raised-button type="button" (click)="submit()" [disabled]="userForm.invalid" class="green-bg">
                              <mat-label class="Text_Color">{{type}} portal user</mat-label>
                      </button>
                  </div>
                </form>
                </div>  
             
        </div>
</div>
</div>