import { Component, OnInit } from '@angular/core';
export interface addServData {
  position: number;
  addServTitle: string;
  addServDesc: string;
}
const ELEMENT_DATA: addServData[] = [
  {position: 1, addServTitle: 'Notorization', addServDesc: 'Document Translation'},
  {position: 2, addServTitle: ' Apostile', addServDesc: 'Attestation/Apostile of Documents and Implementation of the  Apostile'},

];
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  displayedColumns: string[] = ['position', 'addServTitle', 'addServDesc'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit() {
  }

}
  