import { OnInit } from '@angular/core';
var WhatsappAttachmentComponent = /** @class */ (function () {
    function WhatsappAttachmentComponent() {
    }
    WhatsappAttachmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.peopleArray = Object.keys(this.childAttachment).map(function (i) { return _this.childAttachment[i]; });
    };
    return WhatsappAttachmentComponent;
}());
export { WhatsappAttachmentComponent };
