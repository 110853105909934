import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared.service";
var ProjectsService = /** @class */ (function () {
    function ProjectsService(http, sharedService) {
        this.http = http;
        this.sharedService = sharedService;
        this.heroesUrl = 'https://dayinterpreting.daytranslations.com/conversations/'; // URL to web api
        this.BaseUrl = environment.APIEndpoint;
    }
    ProjectsService.prototype.getAll = function (query) {
        var url = this.BaseUrl + '/projects' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getRequestQuote = function (id, query) {
        var url = this.BaseUrl + '/projects/' + id + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getAdditional_service = function (query) {
        var url = this.BaseUrl + '/project/additional_service' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectDueToday = function (query) {
        var url = this.BaseUrl + '/project_due_today' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getUnderGoingEdit = function (query) {
        var url = this.BaseUrl + '/undergoing_edits' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getUnassigned = function (query) {
        var url = this.BaseUrl + '/un_assigned' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getUnderGoing = function (query) {
        var url = this.BaseUrl + '/undergoing' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getUnConfirmedByTz = function (query) {
        var url = this.BaseUrl + '/unconfirmed_by_tz' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getClientApproval_Pending = function (query) {
        var url = this.BaseUrl + '/client_approval_pending' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getRejectedQuotes = function (query) {
        var url = this.BaseUrl + '/project/rejected_quote' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectAssignmentPending = function (query) {
        var url = this.BaseUrl + '/assignment_pending' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getUpdateByTz = function (query) {
        var url = this.BaseUrl + '/update_due_by_tz' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.get = function (id) {
        var url = this.BaseUrl + '/projects/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectDetail = function (id) {
        var url = this.BaseUrl + '/project/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getImportantNotes = function (id) {
        var url = this.BaseUrl + '/project/important_messages/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.updateComment = function (id, data) {
        var url = this.BaseUrl + '/project/' + id + '/comments';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getEmailTemplate = function () {
        var url = this.BaseUrl + '/email/customer_quote?per_page=100';
        return this.sharedService.getApi(url);
    };
    //  email template for workflows 
    ProjectsService.prototype.getEmailTemplateTranslation = function () {
        var url = this.BaseUrl + '/email/translation_assignment';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getEmailTemplateProofReading = function () {
        var url = this.BaseUrl + '/email/proof_reading_assignment';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getEmailTemplateQA = function () {
        var url = this.BaseUrl + '/email/qa_assignment';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getEmailTemplateEditing = function () {
        var url = this.BaseUrl + '/email/editing_assignment';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getEmailTemplateDTP = function () {
        var url = this.BaseUrl + '/email/dtp_assignment';
        return this.sharedService.getApi(url);
    };
    //Additional services
    ProjectsService.prototype.getEmailTemplateMailingOptions = function () {
        var url = this.BaseUrl + '/email/additional_service_assignment_mail';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getEmailTemplateNotarization = function () {
        var url = this.BaseUrl + '/email/additional_service_assignment_notarization';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getEmailTemplateApostile = function () {
        var url = this.BaseUrl + '/email/additional_service_assignment_apostile';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.sendProjectQuote = function (data) {
        var url = this.BaseUrl + '/create_project_quote';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.sendDraftProjectQuote = function (id, data) {
        var url = this.BaseUrl + '/projectquote/' + id;
        return this.sharedService.patchApi(url, data);
    };
    ProjectsService.prototype.ProjectTranslator = function (data) {
        var url = this.BaseUrl + '/project/freelancer';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.ProjectTranslatorAssignment = function (data, query) {
        var url = this.BaseUrl + '/project/freelancer' + query;
        return this.sharedService.postApi(url, data);
    };
    // Download zip file 
    ProjectsService.prototype.downloadZip = function (data) {
        var url = this.BaseUrl + '/projectfilezip';
        return this.sharedService.postApi(url, data);
    };
    //File prepration Project file save
    ProjectsService.prototype.projectFileSave = function (data) {
        var url = this.BaseUrl + '/projectfilesave';
        return this.sharedService.postApi(url, data);
    };
    // Get Project Language 
    ProjectsService.prototype.getProjectLanguage = function (id) {
        var url = this.BaseUrl + '/projectlanguage/project/' + id;
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    ProjectsService.prototype.UpdateProjectPm = function (data) {
        var url = this.BaseUrl + '/updateprojectpm';
        return this.sharedService.postApi(url, data);
    };
    // Get File Type
    ProjectsService.prototype.getFileType = function () {
        var url = this.BaseUrl + '/filetype';
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    // Get Discount Type promotion
    ProjectsService.prototype.getDiscountTypePromotion = function () {
        var url = this.BaseUrl + '/promotion';
        return this.sharedService.getApi(url);
    };
    //project lock api
    ProjectsService.prototype.projectLock = function (data) {
        var url = this.BaseUrl + '/lockproject';
        return this.sharedService.postApi(url, data);
    };
    //project release api
    ProjectsService.prototype.projectRelease = function (data) {
        var url = this.BaseUrl + '/project_release';
        return this.sharedService.postApi(url, data);
    };
    // get all loced project by pm
    ProjectsService.prototype.GetPmLockedProjects = function (data) {
        var url = this.BaseUrl + '/get_pm_locked_projects';
        return this.sharedService.postApi(url, data);
    };
    //  assign projects
    ProjectsService.prototype.ProjectAssignment = function (data) {
        var url = this.BaseUrl + '/project_language_assignment';
        return this.sharedService.postApi(url, data);
    };
    //  assign projects workflow additional services
    ProjectsService.prototype.ProjectAssignmentAddition_service = function (data) {
        var url = this.BaseUrl + '/project_language_assignment/addition_service';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getProjectAssignment = function (query, data) {
        var url = this.BaseUrl + '/project/assignment?' + query;
        return this.sharedService.postApi(url, data);
    };
    // workflows
    ProjectsService.prototype.getWorkFlow = function () {
        var url = this.BaseUrl + '/workflow/1';
        return this.sharedService.getApi(url);
    };
    // workflows additional service
    ProjectsService.prototype.getWorkFlowAdditionalServices = function () {
        var url = this.BaseUrl + '/workflow/3';
        return this.sharedService.getApi(url);
    };
    // get Unique Project Language
    ProjectsService.prototype.getUniqueProjectLanguage = function (data) {
        var url = this.BaseUrl + '/project_language';
        var headers = SharedService.getPutHeader();
        return this.http.post(url, data, { headers: headers });
    };
    // update project language
    ProjectsService.prototype.updateProjectLanguage = function (data) {
        var url = this.BaseUrl + '/update_project_language';
        return this.sharedService.postApi(url, data);
    };
    //  Project Workflow
    ProjectsService.prototype.projectWorkFlow = function (data) {
        var url = this.BaseUrl + '/project_workflow';
        return this.sharedService.postApi(url, data);
    };
    //  get Project Workflow
    ProjectsService.prototype.WorkFlowByProjectId = function (data) {
        var url = this.BaseUrl + '/project/worflows';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.deleteWorkFlow = function (id) {
        var url = this.BaseUrl + '/project_workflow/' + id;
        return this.sharedService.deleteApi(url);
    };
    ProjectsService.prototype.ProjectDelivered = function (data) {
        var url = this.BaseUrl + '/project/delivered';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getPmsProjects = function (query) {
        var url = this.BaseUrl + '/project/search' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getReport = function (query) {
        var url = this.BaseUrl + '/get_report' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getLanguages = function () {
        var url = this.BaseUrl + '/language?per_page=500';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getAllLanguages = function () {
        var url = this.BaseUrl + '/all_language?per_page=500';
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    ProjectsService.prototype.getProjectStatus = function () {
        var url = this.BaseUrl + '/projectstage?per_page=500';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getcustomer = function () {
        var url = this.BaseUrl + '/customer';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getcustomerForLead = function (query) {
        var url = this.BaseUrl + '/customer/search' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectManager = function () {
        var url = this.BaseUrl + '/user?per_page=500';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getPortalUsers = function (query) {
        var url = this.BaseUrl + '/user?' + query + 'user_role=Admin,Finance-Manager,Hr-Admin,Operation-Manager,Project-Manager';
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    ProjectsService.prototype.getAccountType = function () {
        var url = this.BaseUrl + '/account_type?per_page=100';
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    ProjectsService.prototype.getIndustry = function () {
        var url = this.BaseUrl + '/industry?per_page=100';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getLeadSource = function () {
        var url = this.BaseUrl + '/source?per_page=50';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectQuotes = function (query) {
        var url = this.BaseUrl + '/quote/search' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectCustomer = function (query) {
        var url = this.BaseUrl + '/customer/search' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectSingleCustomer = function (id) {
        var url = this.BaseUrl + '/customer/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getFirst_Quote_Project = function (data) {
        var url = this.BaseUrl + '/customer/first_quote_project';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getCustomerProjects = function (id, query) {
        var url = this.BaseUrl + '/customer/project/' + id + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.addCustomer = function (data) {
        var url = this.BaseUrl + '/customer/addCustomer';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getvendorbyskill = function (data) {
        var url = this.BaseUrl + '/project/freelancer/skill';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.projectGraph = function (data) {
        var url = this.BaseUrl + '/project/graph';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getAdditionalServices = function (id) {
        var url = this.BaseUrl + '/project/additional_services/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getMailingAddress = function (id) {
        var url = this.BaseUrl + '/customer/account_address/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getProjectLanguageAssignment = function (id) {
        var url = this.BaseUrl + '/project_language_assignment/project/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getTranslatorMessages = function () {
        var url = this.BaseUrl + '/project/messages/latest';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.sendTranslatorMessages = function (id, data) {
        var url = this.BaseUrl + '/project/' + id + '/comments';
        return this.sharedService.postApi(url, data);
    };
    //  assign projects
    ProjectsService.prototype.changeStatusAdditionalService = function (data) {
        var url = this.BaseUrl + '/project/additional_service/status';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getFreelancer = function (id) {
        var url = this.BaseUrl + '/vendors/' + id + '?include=vendoravailabilities,vendorSubtitler,vendorfiles,vendorjobs,vendorspecialities,vendortranslationlangs,vendornativelanguage,vendorprofileimage,vendorresume,vendorcontract,vendorcomment,vendorskill,extradocument,vendorTreping,vendorMarketingSkillPrice';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getFreelancerProjectHistory = function (id, query) {
        var url = this.BaseUrl + '/vendor/projects/' + id + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.get_quote_email = function (data) {
        var url = this.BaseUrl + '/get_quote_email';
        var headers = SharedService.getPutHeader();
        return this.http.post(url, data, { headers: headers });
    };
    ProjectsService.prototype.get_delivery_email = function (data) {
        var url = this.BaseUrl + '/project_delivered_email';
        var headers = SharedService.getPutHeader();
        return this.http.post(url, data, { headers: headers });
    };
    ProjectsService.prototype.getIpAddress = function () {
        var url = this.BaseUrl + '/ip_Address';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getService = function () {
        var url = this.BaseUrl + '/service';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.updateSpeciality = function (id, data) {
        var url = this.BaseUrl + '/project/' + id;
        return this.sharedService.patchApi(url, data);
    };
    ProjectsService.prototype.updateDeadline = function (id, data) {
        var url = this.BaseUrl + '/project/' + id;
        return this.sharedService.patchApi(url, data);
    };
    ProjectsService.prototype.updateServiceType = function (id, data) {
        var url = this.BaseUrl + '/project/' + id;
        return this.sharedService.patchApi(url, data);
    };
    // Get Discount Type promotion
    ProjectsService.prototype.getDiscountType = function () {
        var url = this.BaseUrl + '/discount_type';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.addLead = function (data) {
        var url = this.BaseUrl + '/project/new_lead';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getTopPmsSearch = function (query) {
        var url = this.BaseUrl + '/search' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getTopHrSearch = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + ("/vendors?page=1&per_page=10&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage,vendorskill&vendor_type=" + type) + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getAccountSearch = function (query) {
        var url = this.BaseUrl + '/account/search' + query;
        return this.sharedService.getApi(url);
    };
    // Notifications
    ProjectsService.prototype.allNotification = function (query) {
        var url = this.BaseUrl + '/me/notification' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.unreadNotification = function () {
        var url = this.BaseUrl + '/me/notification?read_status=unread';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.notificationRead = function (data) {
        var url = this.BaseUrl + '/me/notification/all/read';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.updateCustomer = function (id, data) {
        var url = this.BaseUrl + '/customer/' + id;
        return this.sharedService.patchApi(url, data);
    };
    //  Add additional service
    ProjectsService.prototype.addAdditionalService = function (data) {
        var url = this.BaseUrl + '/project/additional_services';
        return this.sharedService.postApi(url, data);
    };
    // Delete project additional service
    ProjectsService.prototype.deleteProjectAdditionalService = function (id) {
        var url = this.BaseUrl + '/project_additional_service/' + id;
        return this.sharedService.deleteApi(url);
    };
    //  Add multiple upload files 
    ProjectsService.prototype.uploadFile = function (data) {
        var url = this.BaseUrl + '/vendors/uploadfile';
        return this.sharedService.postFileApi(url, data);
    };
    // whisper upload file
    ProjectsService.prototype.whisperUploadFile = function (data) {
        var url = environment.whisperUrl + '/upload';
        return this.sharedService.postFileWhisperApi(url, data);
    };
    // whisper upload file
    ProjectsService.prototype.whisperGetSubTitlesByUrl = function (payload) {
        var url = environment.whisperUrl + '/extract-subtitle';
        return this.sharedService.postFileWhisperApi(url, payload);
    };
    //Add ticket
    ProjectsService.prototype.addTicket = function (data) {
        var url = this.BaseUrl + '/new_ticket';
        return this.sharedService.postApi(url, data);
    };
    //to show ticket history
    ProjectsService.prototype.getTicketdata = function (query) {
        var url = this.BaseUrl + '/ticket' + query;
        return this.sharedService.getApi(url);
    };
    // Get project email logs
    ProjectsService.prototype.getProjectEmailLog = function (id) {
        var url = this.BaseUrl + '/project_email_log/' + id;
        return this.sharedService.getApi(url);
    };
    // getProjectEmailById(id:any): Observable<any> {
    //   let url = this.BaseUrl + '/email_log/' + id;
    //   return this.sharedService.getApi(url);
    // }
    ProjectsService.prototype.getProjectEmailById = function (id) {
        var url = this.BaseUrl + '/email_log/' + id;
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    // Activity
    ProjectsService.prototype.projectActivity = function (id) {
        var url = this.BaseUrl + '/project_activity/' + id;
        return this.sharedService.getApi(url);
    };
    // transactions start
    ProjectsService.prototype.searchTransaction = function (data) {
        var url = this.BaseUrl + '/search_transaction';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.searchStripeTransaction = function (data) {
        var url = this.BaseUrl + '/stripe_search_transaction';
        return this.sharedService.postApi(url, data);
    };
    //
    ProjectsService.prototype.searchcustomerTransaction = function (data) {
        var url = this.BaseUrl + '/search/customer_card_detail';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.searchCustomerStripeTransaction = function (data) {
        var url = this.BaseUrl + '/search/stripe_customer_card_detail';
        return this.sharedService.postApi(url, data);
    };
    //Create Payment
    ProjectsService.prototype.customerPayment = function (data) {
        var url = this.BaseUrl + '/customer_payment';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.customerStripePayment = function (data) {
        var url = this.BaseUrl + '/stripe_customer_payment';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.newCustomerPayment = function (data) {
        var url = this.BaseUrl + '/new_customer_payment';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.newCustomerStripePayment = function (data) {
        var url = this.BaseUrl + '/stripe_new_customer_payment';
        return this.sharedService.postApi(url, data);
    };
    // transaction end
    // Get mark as 
    ProjectsService.prototype.getMarkAsStatus = function () {
        var url = this.BaseUrl + '/status';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.updateMarkAsStatus = function (id, data) {
        var url = this.BaseUrl + '/project/' + id;
        return this.sharedService.patchApi(url, data);
    };
    // Delete project 
    ProjectsService.prototype.deleteProject = function (id) {
        var url = this.BaseUrl + '/project/' + id;
        return this.sharedService.deleteApi(url);
    };
    // update lead enquiry date
    ProjectsService.prototype.updateLeadEnquiryDate = function (data) {
        var url = this.BaseUrl + '/update_lead_enquiry_date';
        return this.sharedService.postApi(url, data);
    };
    // Delete project file
    ProjectsService.prototype.deleteProjectFile = function (id) {
        var url = this.BaseUrl + '/projectfile/' + id;
        return this.sharedService.deleteApi(url);
    };
    // Delete customer
    ProjectsService.prototype.deleteCustomer = function (id) {
        var url = this.BaseUrl + '/customer/' + id;
        return this.sharedService.deleteApi(url);
    };
    ProjectsService.prototype.getTransaction = function (id) {
        var url = this.BaseUrl + '/quote_detail/' + id;
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    ProjectsService.prototype.getStripeTransaction = function (id) {
        var url = this.BaseUrl + '/quote_detail/' + id;
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    //Account Listing
    ProjectsService.prototype.getAccountListing = function (query) {
        var url = this.BaseUrl + '/account' + query + '&include=accountaddress.address,createdby,assignedto,industry';
        return this.sharedService.getApi(url);
    };
    // get project with account detail
    ProjectsService.prototype.getAccountProjects = function (id) {
        var url = this.BaseUrl + '/account/' + id + '?include=accountaddress.address,createdby,accountproject.project,assignedto,accountcustomer';
        return this.sharedService.getApi(url);
    };
    //Update Account Detail
    ProjectsService.prototype.updateAccountDetail = function (id, data) {
        var url = this.BaseUrl + '/account/' + id;
        return this.sharedService.patchApi(url, data);
    };
    // add account
    ProjectsService.prototype.addAccount = function (data) {
        var url = this.BaseUrl + '/account';
        var headers = SharedService.getPutHeader();
        return this.http.post(url, data, { headers: headers });
    };
    // Calender get api
    ProjectsService.prototype.getCalenderEvents = function () {
        var url = this.BaseUrl + '/calender/project';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.updateProjectCrmStatus = function (data) {
        var url = this.BaseUrl + '/update_project_crm_status';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getOcrFile = function (data) {
        var url = this.BaseUrl + '/process_image_ocr';
        return this.sharedService.postApi(url, data);
    };
    //to show ocr history
    ProjectsService.prototype.getOcrHistory = function (query) {
        var url = this.BaseUrl + '/file_process?status=2' + query;
        return this.sharedService.getApi(url);
    };
    //to show get Sms Twillo
    ProjectsService.prototype.getSmsTwillo = function (query) {
        var url = this.BaseUrl + '/sms_twillo' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.sendMessage = function (data) {
        var url = this.BaseUrl + '/send_sms';
        return this.sharedService.postApi(url, data);
    };
    /** GET heroes from the server */
    ProjectsService.prototype.getWhatsapps = function () {
        var url = this.BaseUrl + '/wtsp_conversations';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getSmsCount = function () {
        var url = this.BaseUrl + '/sms_conversations/count';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getWhatsappCount = function () {
        var url = this.BaseUrl + '/wtsp_conversations/count';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getWhatsappsSearch = function (query) {
        var url = this.BaseUrl + '/wtsp_conversations' + query;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getWhatsappHero = function (id) {
        var url = this.BaseUrl + '/wtsp_conversations/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.sendWhatsappto = function (data) {
        var url = this.BaseUrl + '/wtsp_conversations';
        return this.sharedService.postApi(url, data);
    };
    // sendWhatsappto(hero: any): Observable<any> {
    //   const url = `${this.heroesUrl}conversation/outgoingwhatsapp`;
    //   let bodyparams = new HttpParams();
    //   var tonumber = hero.tonumber;
    //   var content = hero.body;
    //   bodyparams = bodyparams.set('tonumber', tonumber);
    //   bodyparams = bodyparams.set('content', content);
    //   return this.http.post<any>(url, bodyparams).pipe(
    // tap((hero: any) => this.log(`added hero w/ id=${hero.id}`)),
    // catchError(this.handleError<any>('addHero'))
    // );
    // return this.http.post<any>(url, hero, httpOptions);
    // }
    ProjectsService.prototype.getWhatsappTotalUnread = function (id) {
        var url = this.BaseUrl + '/wtsp_conversations/getunreadmessages/' + id;
        return this.sharedService.getApi(url);
    };
    // sms api's
    ProjectsService.prototype.getSms = function () {
        var url = this.BaseUrl + '/sms_conversations';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getSmsHero = function (id) {
        var url = this.BaseUrl + '/sms_conversations/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.sendSmsto = function (data) {
        var url = this.BaseUrl + '/sms_conversations';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.markAsReadSms = function (data) {
        var url = this.BaseUrl + '/sms_conversations/mark_read';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.markAsReadWhatsapp = function (data) {
        var url = this.BaseUrl + '/wtsp_conversations/mark_read';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getSmsTotalUnread = function (id) {
        var url = this.BaseUrl + '/sms_conversations/getunreadmessages/' + id;
        return this.sharedService.getApi(url);
    };
    // cancel the assigned projects
    ProjectsService.prototype.cancelAssignProject = function (data) {
        var url = this.BaseUrl + '/project_language_assignment/cancel';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getCrmdocuments = function (id) {
        var url = this.BaseUrl + '/document_info/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getDetailMain = function (id) {
        var url = this.BaseUrl + '/quote_info/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getAutoQuoteInfo = function (id) {
        var url = this.BaseUrl + '/auto_quote_info/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getCrmAccountInfo = function (id) {
        var url = this.BaseUrl + '/account_info/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getAddonInfo = function (id) {
        var url = this.BaseUrl + '/add_on_service_info/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getCrmEmailLog = function (id) {
        var url = this.BaseUrl + '/crm_email_info/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.getEmailTemp = function (id) {
        var url = this.BaseUrl + '/emails/' + id;
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.add = function (data) {
        var url = this.BaseUrl + '/vendors/add_temp_translator';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.getFreelancerEmailTemplate = function () {
        var url = this.BaseUrl + '/email/freelancer_email';
        return this.sharedService.getApi(url);
    };
    ProjectsService.prototype.sendVendorRushEmail = function (data) {
        var url = this.BaseUrl + '/vendors/email_for_rush_project';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.prototype.markAsRushProject = function (data) {
        var url = this.BaseUrl + '/mark_as_rush_propject';
        return this.sharedService.postApi(url, data);
    };
    ProjectsService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectsService_Factory() { return new ProjectsService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService)); }, token: ProjectsService, providedIn: "root" });
    return ProjectsService;
}());
export { ProjectsService };
