import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { UserService } from 'app/main/Services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-language-master',
  templateUrl: './language-master.component.html',
  styleUrls: ['./language-master.component.scss']
})
export class LanguageMasterComponent implements OnInit {
  type: string = 'Add';
  addlanguageForm: any;
  paginate: any = {};
  pageIndex: number = 1;
  languageId: number;
  pageSize: number = 10;
  query: string = '';
  filterSpecialization: string = '';
  languageData: any = [];
  displayedColumns: string[] = ['id', 'lang_name', 'lang', 'rate', 'margin', 'type','status', 'action'];
  dataSource: any;
  final_data: any = {};
  updated_data: any = {};
  fQuery: string = '';
  filterStatus: string = '';
  filterMargin: string = '';
  filterCategory: string = '';
  filterSearchQuery: string='';
  fChipsArray: any = [];
  search:any =
  {
    value : ''
  }
  constructor(private userService: UserService, private _formBuilder: FormBuilder, private toastr: ToastrService) { }
  ngOnInit() {
    this.getLanguage();
    this.addlanguageForm = this._formBuilder.group({
      lang_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
      lang: ['', Validators.compose([Validators.required])],
      rate: ['', Validators.compose([Validators.required])],
      rate_tier2: ['', Validators.compose([Validators.required])],
      rate_tier3: ['', Validators.compose([Validators.required])],
      rate_tier4: ['', Validators.compose([Validators.required])],
      rate_tier5: ['', Validators.compose([Validators.required])],
      margin: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      is_active: ['1', Validators.compose([Validators.required])]
    });
  }
  submit() {
    if (this.type == 'Add') {
      this.addData();
    } else {
      this.updateData();
    }
  }
  addData() {
    this.final_data = this.addlanguageForm.value;
    this.userService.addLangugae(this.final_data).subscribe(
      resposne => {
        if (resposne) {
          window.location.href = '/masterdata/language-master';
          this.toastr.success('Language added successfully');
        }
      }
    );
  }
  updateData() {
    this.updated_data = this.addlanguageForm.value;
    this.userService.updateMasterLanguage(this.languageId, this.updated_data).subscribe(
      resposne => {
        if (resposne) {
          window.location.href = '/masterdata/language-master';
          this.toastr.success('Language updated successfully');
        }
      });
  }

  getLanguage() {
    let query = '?page=1&per_page=10';
    this.userService.getLanguagesListing(query)
      .subscribe((res: any) => {
        if (res) {
          this.languageData = res.data;
          this.dataSource = new MatTableDataSource(this.languageData);
          this.paginate = res.meta.pagination;
        }
      });
  }

  paginateData(event) {
    let aQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
    this.query += aQuery;
    this.userService.getLanguagesListing(this.query)
      .subscribe((res: any) => {
        if (res) {
          this.languageData = res.data;
          this.dataSource = new MatTableDataSource(this.languageData);
          this.paginate = res.meta.pagination;
        }
      });
  }

  editData(index) {
    this.type = 'Update';
    this.languageData.forEach((element, ind) => {
      if (index == ind) {
        this.languageId = element.id;
        this.addlanguageForm.patchValue({
          lang_name: element.lang_name,
          lang: element.lang,
          rate: parseFloat(element.rate),
          rate_tier2: parseFloat(element.rate_tier2),
          rate_tier3: parseFloat(element.rate_tier3),
          rate_tier4: parseFloat(element.rate_tier4),
          rate_tier5: parseFloat(element.rate_tier5),
          margin: element.margin,
          type: element.type,
          is_active:element.is_active
        });
      }
    });
    const element = document.querySelector('#scrolTo');
    element.scrollIntoView();
  }

  SubmitSearch(){
    this.onFilter(this.search.value,'search');
  }

  onFilter(evt, type) {
    this.fQuery = '?page=1&per_page=10';
      //  search filter
      if ((type == 'search' && evt != '' && evt !== undefined)) {
        this.filterSearchQuery = evt;
        this.fQuery += '&search_text=' + this.filterSearchQuery;
       }
      else if(type == 'search' && evt == ''){
        this.filterSearchQuery = evt;
      }
      else if(this.filterSearchQuery!==''){
        this.fQuery += '&search_text=' + this.filterSearchQuery;
      }  
    if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
      this.filterStatus = evt.value;
      this.fQuery += '&status_is=' + this.filterStatus;
    }
    else if (type == 'status' && evt.value == '') {
      this.filterStatus = evt.value;
    }
    else if (this.filterStatus !== '') {
      this.fQuery += '&status_is=' + this.filterStatus;
    }

    if ((type == 'category_is' && evt.value != '' && evt.value !== undefined)) {
      this.filterCategory = evt.value;
      this.fQuery += '&category_is=' + this.filterCategory;
    }
    else if (type == 'category_is' && evt.value == '') {
      this.filterCategory = evt.value;

    }
    else if (this.filterCategory !== '') {
      this.fQuery += '&category_is=' + this.filterCategory;
    }

    if ((type == 'margin_is' && evt.value != '' && evt.value !== undefined)) {
      this.filterMargin = evt.value;
      this.fQuery += '&margin_is=' + this.filterMargin;
    }
    else if (type == 'margin_is' && evt.value == '') {
      this.filterMargin = evt.value;
    }
    else if (this.filterMargin !== '') {
      this.fQuery += '&margin_is=' + this.filterMargin;
    }
    this.userService.getLanguagesListing(this.fQuery)
      .subscribe((res: any) => {
        if (res) {
          this.languageData = res.data;
          this.dataSource = new MatTableDataSource(this.languageData);
          this.paginate = res.meta.pagination;
        }
      });
  }
}
