import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-speciality-change',
  templateUrl: './speciality-change.component.html',
  styleUrls: ['./speciality-change.component.scss']
})
export class SpecialityChangeComponent implements OnInit {
  Form:any;
  specialityData: any = [];
  constructor( private _formBuilder: FormBuilder,
    private vendorService : VendorService,  private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data :any,
    private projectsService: ProjectsService,
    private dialogRef: MatDialogRef<SpecialityChangeComponent>) { }

  ngOnInit() {
    this.getSpeciality();
    this.Form = this._formBuilder.group({
      specilization_id : ['',Validators.compose([Validators.required])]
  });
  if(this.data.specilization_id != null)
  {

    this.Form.patchValue({
      specilization_id:  this.data.specilization_id 
  });
   
  }
  }

  Onclose(){
    this.dialogRef.close();
}


  getSpeciality() {
    this.vendorService.getSpeciality()
    .subscribe((res) => {
        if(res) {
          this.specialityData=res;
        }
    });
  }

  submit(){
    this.projectsService.updateSpeciality(this.data.project_id , this.Form.value)
    .subscribe((response) => {
        if(response) {
          this.toastr.success("Speciality change successfully!");
         this.Onclose();

        }
    });
  }

}
