<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
  <form fxLayout="column" [formGroup]="Form" name="Form">
    <div class="white-box">
      <div class="tHeader">
          <h2  *ngIf="type == 'Add'">Add Customer</h2>
        <h2  *ngIf="type == 'Update'">Edit Customer</h2>
      </div>
      <div class="boxBodyInn">
        <div class="mb-8">
          <mat-radio-group aria-label="Select an option" formControlName="contract_type">
            <mat-radio-button value="Individual">Individual</mat-radio-button>
            <mat-radio-button value="Corporate">Corporate</mat-radio-button>
          </mat-radio-group>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-form-field   fxFlex="20">
            <mat-label>Source</mat-label>
            <mat-select formControlName="source_id">
              <mat-option [value]="''">
                Select
              </mat-option>
              <mat-option *ngFor="let source of sourceResponse" [value]="source.id">
                {{source.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>Customer Type*</mat-label>
            <mat-select formControlName="payment_method" (selectionChange)="customerType($event)">
              <mat-option [value]="''"> Select</mat-option>
              <mat-option value="1">NET30</mat-option>
              <mat-option value="2">NET45</mat-option>
              <mat-option value="3">Upfront</mat-option>
              <mat-option value="4">Credit Available</mat-option>
            </mat-select>
            <mat-error *ngIf="Form.get('payment_method').hasError('required')">
                Customer Type is required!
            </mat-error>
         
        
          </mat-form-field>
         

          <mat-form-field  >
            <mat-label>Account</mat-label>
            <!-- <input matInput formControlName="account_id"> -->
            <input matInput (keyup)="onFilter($event.target.value,'search')"
            placeholder="Search account" [matAutocomplete]="auto" formControlName="account_id" >
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectSearch($event)">
            <mat-option *ngFor="let option of search_response" [value]="option">
                <ul class="list_items">
                    <li>
                        {{option?.name}} 
                    </li>
                    <li>
                      {{option?.email}}
                    </li>
                </ul>

            </mat-option>
        </mat-autocomplete>
          </mat-form-field>

          <div fxFlex="20">
            <button mat-stroked-button color="primary" (click)="goToAccountDetail()" [disabled]="account_id_value==null">View Account</button>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="showContact">
          <div fxFlex="20"></div>
          <a href="https://account.daytranslations.com/payment-contract" target="_blank" > Please signup contact with client</a>
        </div>

        <h4>Contact Info:</h4>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-form-field   fxFlex="15">
            <mat-label>Select Title</mat-label>
            <mat-select formControlName="title">
                <mat-option value=""> Select</mat-option>
              <mat-option label="Mr." value="Mr.">Mr.</mat-option>
              <mat-option label="Ms." value="Ms.">Ms.</mat-option>
              <mat-option label="Mrs." value="Mrs.">Mrs.</mat-option>
              <mat-option label="Mx." value="Mx.">Mx.</mat-option>
              <mat-option label="Dr." value="Dr.">Dr.</mat-option>
              <mat-option label="Prof." value="Prof.">Prof.</mat-option>
              <mat-option label="Sr." value="Sr.">Sr.</mat-option>
              <mat-option label="Sra." value="Sra.">Sra.</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field  >
            <mat-label>First Name*</mat-label>
            <input matInput formControlName="first_name">
            <mat-error *ngIf="Form.get('first_name').hasError('required')">
              First Name is required!
            </mat-error>
          </mat-form-field>

          <mat-form-field  >
            <mat-label>Last Name*</mat-label>
            <input matInput formControlName="last_name">
            <mat-error *ngIf="Form.get('last_name').hasError('required')">
              Last Name is required!
            </mat-error>
          </mat-form-field>

          <div class="w30">
              <mat-form-field  >
                <mat-label>Email Address*</mat-label>
                <input matInput formControlName="email_primary">
                <mat-error *ngIf="Form.get('email_primary').hasError('required')">
                    Email is required!
                  </mat-error>
                  <mat-error
                  *ngIf="Form.get('email_primary').hasError('email')">
                  Please enter a valid email address
              </mat-error>
              </mat-form-field>
            </div>

        </div>


        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
           
            <!-- <mat-form-field   fxFlex="15">
                <mat-label>State*</mat-label>
                <input matInput  formControlName="state">
                <mat-error *ngIf="Form.get('state').hasError('required')">
                    State is required!
                  </mat-error>
              </mat-form-field> -->
          
       
                <mat-form-field   fxFlex="15">
                  <mat-label>Company*</mat-label>
                  <input matInput formControlName="company">
                  <mat-error *ngIf="Form.get('company').hasError('required')">
                      Company  is required!
                    </mat-error>
                </mat-form-field>
            
              
                <mat-form-field  >
                  <mat-label>Company Website</mat-label>
                  <input matInput formControlName="company_website">
                </mat-form-field>
        
                <div >
            <mat-form-field  >
              <mat-label>Phone*</mat-label>
              <input matInput type="number" formControlName="phone">
              <mat-error fxLayout="column" fxLayoutGap="3px">
                <span *ngIf="Form.get('phone').hasError('required')">
                  Phone is required!
                </span>
              </mat-error>
                <!-- <mat-error fxLayout="column" fxLayoutGap="3px"
                *ngIf="!Form.get('phone').hasError('required') &&
                Form.get('phone').hasError('maxlength')">
                Max 12 characters allowed!
            </mat-error> -->
            </mat-form-field>


            </div>

            <mat-form-field  >
                <mat-label>Newsletter</mat-label>
                <input matInput formControlName="news_letter">
              </mat-form-field>

              <mat-form-field  >
                <mat-label>Day Vip</mat-label>
                <input matInput formControlName="day_vip">
              </mat-form-field>
      
        </div>


        <h4>Address</h4>
        <!-- 
      <p>
        <mat-checkbox>Billing Address</mat-checkbox>
        <mat-checkbox>Mailing Address</mat-checkbox>
      </p> -->

        <p>
          <mat-radio-group aria-label="Select an option" formControlName="address_type">
            <mat-radio-button value="1">Billing Address</mat-radio-button>
            <mat-radio-button value="2">Mailing Address</mat-radio-button>
          </mat-radio-group>
        </p>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex="95">
            <mat-form-field  >
              <mat-label>Address</mat-label>
              <textarea matInput placeholder="Address" formControlName="address1"></textarea>
            </mat-form-field>
          </div>

        </div>

          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex="15">
          <mat-form-field  >
            <mat-label>State</mat-label>
            <input matInput  formControlName="state">
            <!-- <mat-error *ngIf="Form.get('state').hasError('required')">
                State is required!
              </mat-error> -->
          </mat-form-field>
          </div>
          <div >
            <mat-form-field  >
              <mat-label>City</mat-label>
              <input matInput formControlName="city">
            </mat-form-field>
          </div>

          <div >
            <mat-form-field  >
              <mat-label>Country</mat-label>
              <input matInput formControlName="country">
            </mat-form-field>
          </div>

          <div class="w30">
            <mat-form-field  >
              <mat-label>Zip</mat-label>
              <input matInput formControlName="zip_code">
            </mat-form-field>
          </div>

        </div>


        <div class="box-blank" *ngIf="false">
          <h4>Payment Method to Vault:</h4>
          <div class="ccInput" fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
            <div fxFlex="35">
              <mat-form-field  >
                <mat-label>Card Holder Name</mat-label>
                <input matInput>
              </mat-form-field>
            </div>

            <div fxFlex="35">
              <mat-form-field  >
                <mat-label>Credit Card Number</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
            <div fxFlex="20">
              <mat-form-field  >
                <mat-label>Expiry Info</mat-label>
                <input matInput placeholder="MM/YY">
              </mat-form-field>
            </div>

            <div fxFlex="10">
              <mat-form-field  >
                <mat-label>CVV</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex="95">
            <mat-form-field  >
              <mat-label>Additional Notes: </mat-label>
              <textarea matInput placeholder="Additional Notes:" formControlName="note"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="actWraper">
        <button mat-flat-button color="accent" class="mr-16" [disabled]="Form.invalid" (click)="Submit()">{{type}}</button>
        <button mat-stroked-button color="warn" (click)="Cancel()">Cancel</button>
      </div>
    </div>
  </form>
</div>