import { MarketingDashboardComponent } from "./marketing-dashboard/marketing-dashboard.component";
import { TranslatorComponent } from "./translator/translator.component";
import { AddTranslatorComponent } from "./translator/add-translator/add-translator.component";
import { MarketingGuard } from "app/_guards/marketing.guard";
import { ReviewTranslatorComponent } from "./translator/review-translator/review-translator.component";
import { SharedListingComponent } from "./marketing-dashboard/shared-listing/shared-listing.component";
import { ClosedBlacklistedComponent } from "./closed-blacklisted/closed-blacklisted.component";
import { GclidComponent } from "./gclid/gclid.component";
var routes = [
    {
        path: "marketing/marketing-dashboard-shared-listing",
        component: SharedListingComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/marketing-dashboard",
        component: MarketingDashboardComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer",
        component: TranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/gclid-records",
        component: GclidComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer/review/:id",
        component: ReviewTranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer/add-freelancer",
        component: AddTranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/freelancer/:id",
        component: AddTranslatorComponent,
        canActivate: [MarketingGuard],
    },
    {
        path: "marketing/closed-blacklisted",
        component: ClosedBlacklistedComponent,
        canActivate: [MarketingGuard],
    },
];
var MarketingModule = /** @class */ (function () {
    function MarketingModule() {
    }
    return MarketingModule;
}());
export { MarketingModule };
