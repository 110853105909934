import { OnInit } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var ReportComponent = /** @class */ (function () {
    function ReportComponent(projectsService, toastr) {
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.paginate = {};
        this.total_due_today = 0;
        this.pageIndex = 1;
        this.pageSize = 20;
        this.query_projects = "";
        this.displayedColumns = ['id', 'short_url', 'long_url', 'clicks', 'created_at'];
    }
    ReportComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    ReportComponent.prototype.getData = function () {
        var _this = this;
        var query = '';
        this.projectsService.getReport(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response;
                _this.total_due_today = response.total;
            }
        });
    };
    ReportComponent.prototype.paginateData = function (event) {
        var _this = this;
        var pQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_projects = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query_projects += pQuery;
        this.projectsService.getReport(this.query_projects)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response;
            }
        });
    };
    return ReportComponent;
}());
export { ReportComponent };
