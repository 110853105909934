// filter.pipe.ts
import { PipeTransform } from '@angular/core';
var FilterPipe = /** @class */ (function () {
    function FilterPipe() {
    }
    /**
     * Transform
     *
     * @param {any[]} items
     * @param {string} searchText
     * @returns {any[]}
     */
    FilterPipe.prototype.transform = function (items, searchText) {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(function (it) {
            return it.recipient.toLowerCase().includes(searchText);
        });
    };
    return FilterPipe;
}());
export { FilterPipe };
