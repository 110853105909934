import { OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router } from "@angular/router";
var ComingSoonComponent = /** @class */ (function () {
    function ComingSoonComponent(_fuseConfigService, router) {
        this._fuseConfigService = _fuseConfigService;
        this.router = router;
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
    ComingSoonComponent.prototype.ngOnInit = function () {
    };
    ComingSoonComponent.prototype.back = function () {
        this.router.navigate(['welcome']);
    };
    return ComingSoonComponent;
}());
export { ComingSoonComponent };
