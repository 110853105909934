import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { SharedService } from 'app/main/shared.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {  MatDialog } from '@angular/material';
import { DeliveryEmailTemplateComponent } from './delivery-email-template/delivery-email-template.component';
@Component({
  selector: 'app-project-delivery',
  templateUrl: './project-delivery.component.html',
  styleUrls: ['./project-delivery.component.scss']
})
export class ProjectDeliveryComponent implements OnInit {
  project_id: string;
  Project_Assigned_obj_compare:any=[];
  Project_Assigned_obj_id:any=[];
  completed_workflow_array:any=[];
  ProjectLanguage_data:any=[];
  target_language_select:any=[];
  language_pair_count:number;
  Form: FormGroup;
  show:boolean=true;
  file_id_data:any=[];
  file_data:any=[];
  bulk_selected_progress: boolean = false;
  project_file_data:string;
  BaseUrl_file: any;
  email:string;
  assignment_completed_id:number;
  workflow_data: any= [];
  show_deliver_button:boolean=false;
  show_deliver_form:boolean=false;
  show_Delivery_status:string;
  delivery_workflow_id:number;
  customer_email:string;
  constructor(private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private toastr: ToastrService
    ,private _formBuilder: FormBuilder,private dialog:MatDialog,private router: Router,
    private httpService: HttpClient) { 
      this.BaseUrl_file = environment.baseUrl_file;
    }

  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.email=SharedService.get('email');

    this.httpService.get('/assets/WorkFlowStatus.json').subscribe(
      data => {
        this.workflow_data = data as string [];	 // FILL THE ARRAY WITH DATA.
  
      },
      (err: HttpErrorResponse) => {
        this.toastr.warning(err.message);
      }
      );

    this.WorkFlowByProjectId();
    this.getProjectDetail();
    this.getProjectAssignment_compare();
     this.getProjectLanguage();

     this.Form = this._formBuilder.group({
      payment_checked  : ['',Validators.compose([Validators.required])],
      finished_file_naming  : ['',Validators.compose([Validators.required])],
      due_date  : ['',Validators.compose([Validators.required])],
      customer_notes  : ['',Validators.compose([Validators.required])]
  });
  }



  WorkFlowByProjectId() {
    let obj:any={
      project_id:''
    };
    obj.project_id=this.project_id;
    this.projectsService.WorkFlowByProjectId(obj)
    .subscribe((response) => {
        if(response) {
         
              if(response.response.length>0){
                response.response.forEach(element => {
                  let project_status;
            
                  this.workflow_data.forEach(ele => {
                    if(ele.id==element.status){
                      project_status=ele.value;
                     

                      if(element.workflow_id == 6){
                        this.delivery_workflow_id=element.id;
                        this.show_Delivery_status= project_status;
                      }
                    }
                  });
                  element.project_status=project_status;
               
               
               
        
                });
     
              }
     
        }
    });
    }

  // project detail
  
  getProjectDetail() {
    this.projectsService.getProjectDetail(this.project_id)
    .subscribe((response) => {
        if(response) {
            this.file_data=[];
            this.customer_email=response.data.customer.data.email_primary
              var fullname='Customer';
             response.data.projectfile.data.forEach(element => {
                if(element.createdby){
                    var fullname =element.createdby.data.fullname;
                }
                    if(element.targetlanguage){
                        var lang_name=element.targetlanguage.data.lang_name;
                    }

                    if( element.filetype){
                        var filetype=element.filetype.data.name;
                    }
                 element.file.data.forEach(elm => {
                       this.project_file_data=this.BaseUrl_file + elm.path;
                       elm.type=element.type;
                        elm.fullname= fullname;
                        //elm.created_at= created_at;
                        elm.lang_name=lang_name;
                        elm.filetype=filetype;
                   
                        this.file_data.push(elm);
                    });
                    
              
            });
    

           
        }
    });
    }

    // get project assignment
    getProjectAssignment_compare(){
  
      let obj:any={
        project_id: ''
      };
      obj.project_id=this.project_id;
      let query = 'page=1&per_page=20';
      this.projectsService.getProjectAssignment(query,obj)
      .subscribe((response:any) => {
          if(response) {
            this.Project_Assigned_obj_compare=response.response.data;
            this.Project_Assigned_obj_id=this.Project_Assigned_obj_compare;
            if(this.Project_Assigned_obj_compare.length>0){
              this.Project_Assigned_obj_compare.forEach(element => {
                if(element.status=="5"){
                  let obj:any={
                    workflow_id:'',
                    workflow_name: '',
                    vendor_name:'',
                    assignment_date:''
                  }
                  obj.workflow_id=element.project_workflow.workflow.id;
                  obj.workflow_name=element.project_workflow.workflow.name;
                  obj.vendor_name=element.vendor.first_name + element.vendor.last_name;
                  obj.assignment_date=element.project_workflow.updated_at;
                  this.completed_workflow_array.push(obj);
                }
              });

              
              this.completed_workflow_array.sort(function (a, b) {
                return a.workflow_id - b.workflow_id;
              });
            }

            this.Project_Assigned_obj_id.sort(function (a, b) {
              return a.id - b.id;
            });

            if(this.Project_Assigned_obj_id.length>0){
              this.Project_Assigned_obj_id.forEach(element => {
                if(element.status=="5"){
                    
                    this.assignment_completed_id=element.id;
                }
              });
            }
          
           
           
          }
          // else{
          //   this.toastr.error("Something went wrong");
       
          // }
      });
    }

    
  // Get Project Language
  getProjectLanguage() {
    this.projectsService.getProjectLanguage(this.project_id)
    .subscribe((response) => {
        if(response) {
          this.ProjectLanguage_data=response.data;
          this.language_pair_count=this.ProjectLanguage_data.length;
          let target_language_obj:any={
            id:Number,
            target_lang_name:String,
            source_lang_name:'',
            project_lang_id:Number
          };
          this.ProjectLanguage_data.forEach(element => {
            target_language_obj.id=element.targetlanguage.id;
            target_language_obj.source_lang_name=element.sourcelanguage.lang_name;
            target_language_obj.target_lang_name=element.targetlanguage.lang_name;
            target_language_obj.project_lang_id=element.id;
            this.target_language_select.push(target_language_obj);
            target_language_obj={
              id:'',
              target_lang_name:'',
              source_lang_name:'',
              project_lang_id:''
            };
            
          });
         
        }
    });
    }

    check_term_conditions(evt:any){
      if(evt.checked && this.Form.value.payment_checked && this.Form.value.finished_file_naming && 
        this.Form.value.due_date && this.Form.value.customer_notes  ){
        this.show=false;
      }
      else{
        this.show=true;
      }
    }

      
  selectFile(evt,res){
  
    if (evt.checked && this.file_id_data.indexOf(res.id== -1)) {
      this.file_id_data.push(res.id);
    }
    else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
        let index = this.file_id_data.indexOf(res.id);
        this.file_id_data.splice(index, 1);
    }
 
}


selectBulkProgress(evt) {
    this.file_id_data=[];
    let check = false;
    this.file_data.forEach((element, key) => {
    element["check"] = evt.checked;
      if (evt.checked) {
        element.check = true;
        this.file_id_data.push(element.id);
      }
      else {
          this.file_id_data = [];  
      }
  });
  if (check) {
      this.bulk_selected_progress = true;  
  } 
}


// Submit Assignment project
submitProject(){

  if(this.file_id_data==0){
    this.toastr.error("Please select any file");
    return;
  }

  let assignment_obj:any={
    file_id: '',
    project_id:'',
    customer_email:'',
    project_assignment_id:'',
    delivery_workflow_id:''

  }
       
  assignment_obj.file_id=this.file_id_data;
  assignment_obj.project_id=this.project_id;
  assignment_obj.customer_email=this.customer_email;
  assignment_obj.project_assignment_id=this.assignment_completed_id;
  assignment_obj.delivery_workflow_id=this.delivery_workflow_id;

  this.openDialog(assignment_obj);
  // this.projectsService.ProjectDelivered(assignment_obj)
  //   .subscribe((response) => {
  //       if(response) {
        
  //          window.location.href = '/pms/project/details/'+this.project_id;
  //         this.toastr.success("Project delivered successfully");
  //       }
  //       else{
  //         this.toastr.error("Something went wrong");
  //       }
  //   }, (error) => {
  //       this.toastr.error("Someting went wrong");
  //   });
}


openDialog(res:any) {
  let data:any;

  data=res;
  const dailogRef = this.dialog.open(DeliveryEmailTemplateComponent, {
      width: '1220px',
      data: data
    
  });

             
  dailogRef.afterClosed().subscribe((response) => {
   
    if(response){
      
    window.location.href = '/pms/project/details/'+this.project_id;

    }
  
});
}

showDeliverForm(){
this.show_deliver_form=true;
}


}
