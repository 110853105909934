import { OnInit } from '@angular/core';
import { UserService } from './../../Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatSort } from '@angular/material';
var TicketsComponent = /** @class */ (function () {
    function TicketsComponent(userService, toastr) {
        this.userService = userService;
        this.toastr = toastr;
        this.paginate = {};
        this.pageSize = 20;
        this.pageIndex = 1;
        this.filterStatus = '';
        this.fQuery = '';
        this.filterIssue = '';
        this.filterDepartment = '';
        this.totalticket = 0;
        this.query_quote = '';
        this.displayedColumns = ['id', 'subject', 'issue_type', 'portal_id', 'created_at', 'updated_at', 'status'];
    }
    TicketsComponent.prototype.ngOnInit = function () {
        this.getTicketDetails();
    };
    TicketsComponent.prototype.getTicketDetails = function () {
        var _this = this;
        var query = '';
        this.userService.getallTickets(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.totalticket = response.meta.pagination.total;
            }
        });
    };
    TicketsComponent.prototype.paginateData = function (event) {
        var _this = this;
        var pQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_quote = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query_quote += pQuery;
        this.userService.getallTickets(this.query_quote)
            .subscribe(function (response) {
            if (response)
                _this.dataSource = new MatTableDataSource(response.data);
            _this.paginate = response.meta.pagination;
            _this.totalticket = response.meta.pagination.total;
        });
    };
    TicketsComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10';
        if ((type == 'department' && evt.value != '' && evt.value !== undefined)) {
            this.filterDepartment = evt.value;
            this.fQuery += '&department=' + this.filterDepartment;
        }
        else if (type == 'department' && evt.value == '') {
            this.filterDepartment = evt.value;
        }
        else if (this.filterDepartment !== '') {
            this.fQuery += '&department=' + this.filterDepartment;
        }
        if ((type == 'issue_type' && evt.value != '' && evt.value !== undefined)) {
            this.filterIssue = evt.value;
            this.fQuery += '&issue_type=' + this.filterIssue;
        }
        else if (type == 'issue_type' && evt.value == '') {
            this.filterIssue = evt.value;
        }
        else if (this.filterIssue !== '') {
            this.fQuery += '&issue_type=' + this.filterIssue;
        }
        if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
            this.filterStatus = evt.value;
            this.fQuery += '&status=' + this.filterStatus;
        }
        else if (type == 'status' && evt.value == '') {
            this.filterStatus = evt.value;
        }
        else if (this.filterStatus !== '') {
            this.fQuery += '&status=' + this.filterStatus;
        }
        var query = this.fQuery;
        this.userService.getallTickets(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.totalticket = response.meta.pagination.total;
            }
        });
    };
    return TicketsComponent;
}());
export { TicketsComponent };
