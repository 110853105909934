<div class="page-layout pmsDashBoard blank p-24 bg-white" fusePerfectScrollbar>
  <div class="container" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="15px">
    <div class="item item-1" fxFlex="70%">
        <!-- <div class="box-blank bg-red">
            <p> <i class="material-icons">info</i> You have locked quote(s)
              
            <a routerLink="/pms/project/details/" href="#">  12082473 </a> for estimation.
            
            </p>
        </div> -->
        <!-- <div class="box-blank bg-red" *ngIf="project_locked_record.length>0">
          <p> <i class="material-icons"> info      </i> 
            {{all_locked_projects}} 
            <span *ngFor="let res of project_locked_record">
              <a routerLink="/pms/project/details/{{res.uuid }}"> {{res?.reference}} </a>
            </span>
            </p>
      </div> -->
      
      <!-- Request In Queue -->
      <!--
      <div class="container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="15px">
        <div class="item item-1" fxFlex>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex>
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_RequestQueue" matBadgeOverlap="false"
                  matBadgeColor="accent">Request In Queue </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>

            </div>
                <div class="content-card" *ngIf="paginate_Request_in_queue.total>0">
                    <mat-table class="queue-table" #table [dataSource]="dataSource_Request_in_queue" matSort  fusePerfectScrollbar>
                        <ng-container matColumnDef="ref">
                            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15">Reference</mat-header-cell>
                            <mat-cell *matCellDef="let product"  fxFlex="15">
                                <div fxLayout="column">
                                    <span class="text-truncate w-100">{{product.reference}}</span>
                                    <div fxFlex class="navigat" *ngIf="product?.locked_by == user_id || product?.locked_by ==null ">
                                        <button mat-button class="btn-link" routerLink="/pms/project/{{ product.uuid }}/create-quote" title="Create Quote">Create Quote</button>
                                    </div>
                              <div class="lcby" *ngIf="product?.lockedby?.username"><i class="material-icons">lock</i> {{product?.lockedby?.username}}</div>
                                </div>
                            </mat-cell>
                        </ng-container>
              
                        <ng-container matColumnDef="language_pair">
                            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="20">Language</mat-header-cell>
                            <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="20">
                                <span class="price text-truncate w-100">
                                    <span *ngFor="let res of product.lang_array">
                                    <p>{{res}}</p>
                                    </span>
                                  </span>
                            </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="qemail">
                          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="20">Email</mat-header-cell>
                          <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="20">
                              <span routerLink="/pms/project/details/{{ product.uuid }}" title="View Details" class="price tlink text-truncate">
                                  {{product?.customer?.data?.email_primary}} 
                                 </span>
                          </mat-cell>
                      </ng-container>
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="15">Type</mat-header-cell>
                            <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="15">
              
              
                                <span  class="bdg blank" *ngIf="product.translation_type">
                                    {{product?.translation_type}}
                              </span>
              
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="start_date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="15">Start Time</mat-header-cell>
                            <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="15">
              
              
                                <span>
                                    {{product.created_at?.date | date: 'yyyy-MM-dd'}}    {{product.created_at?.date | date:"HH:mm"}}
                              </span>
              
                            </mat-cell>
                        </ng-container>
              
                        <ng-container matColumnDef="due_date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="15">Due Date</mat-header-cell>
                            <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="15">
              
              
                                <div fxLayout="column">
                                    <span>
                                        {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}
                                  </span>
                                  <div fxFlex>
                                      <span class="tLapse text-truncate w-100 " > {{product.difference}} </span>
                                      </div>
                                 </div>
              
                            </mat-cell>
                        </ng-container>
              
                        <mat-header-row *matHeaderRowDef="displayedColumns_RequestQueue; sticky:true"></mat-header-row>
              
                        <mat-row *matRowDef="let product; columns: displayedColumns_RequestQueue;" 
                        class="fList  {{product.DueTimeClass}}">
                        </mat-row>
              
                    </mat-table>
    
                    <mat-paginator #paginator *ngIf="paginate_Request_in_queue?.total != 0" [length]="paginate_Request_in_queue?.total" [pageIndex]="paginate_Request_in_queue?.current_page - 1" [pageSize]="paginate_Request_in_queue?.per_page" [pageSizeOptions]="[10,15,20,30,40,50,60,70,80,90,100]" (page)='paginate_RequestQueue($event)'>
                      </mat-paginator>
            </div>
            <div *ngIf="paginate_Request_in_queue.total==0" class="noDataFound"> No data available!!
            </div>
          </div>
        </div>
      </div>
    -->
      <!-- Unassigned -->
      <div class="container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="15px">
        <div class="item item-1" fxFlex>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_Unassigned" matBadgeOverlap="false"
                  matBadgeColor="info">
                  Unassigned
                </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>
            </div>
          <div class="content-card" >
              <mat-table class="queue-table" #table [dataSource]="dataSource_Unassigned" matSort  fusePerfectScrollbar>
                  <ng-container matColumnDef="ref">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="24">REFERENCE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxFlex="24" >
                          <div fxLayout="column">
                              <span routerLink="/pms/project/details/{{ product.uuid }}" class="tlink">{{product.reference}}</span>
                          </div>
                      </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="language_pair">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="20">LANGUAGES</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="20">
                          <span class="price text-truncate">
                            <span *ngFor="let res of product?.lang_array">
                              <p>{{res}}</p>
                              </span>
                          </span>
                      </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="qemail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="20">Email</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="20">
                      <span routerLink="/pms/project/details/{{ product.uuid }}" title="View Details" class="price tlink text-truncate">
                          {{product?.customer?.data?.email_primary}} 
                         </span>
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12">Type</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="12">
                      <span  class="bdg blank" *ngIf="product.translation_type">
                        {{product?.translation_type}}
                      </span>
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="start_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12">Start Time</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="12">
                        <span>
                            {{product.created_at?.date | date: 'yyyy-MM-dd'}}    {{product.created_at?.date | date:"HH:mm"}}
                      </span>
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="due_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12">DUE DATE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="12">
                        <div fxLayout="column">
                          <span class="text-truncate">
                                {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}
                          </span>
                          <div fxFlex>
                              <span class="tLapse ml-4"> {{product.difference}} </span>
                              </div>
                         </div>
                      </mat-cell>
                  </ng-container>
                  <mat-header-row  *matHeaderRowDef="displayedColumns_Unassigned; sticky:true"></mat-header-row>
                  <mat-row *matRowDef="let product; columns: displayedColumns_Unassigned;" 
                  class="fList">
                  </mat-row>
              </mat-table>
              <mat-paginator #paginator *ngIf="paginate_Unassigned?.total != 0" [length]="paginate_Unassigned?.total" [pageIndex]="paginate_Unassigned?.current_page - 1" [pageSize]="paginate_Unassigned?.per_page" [pageSizeOptions]="[5, 10, 15, 20]" (page)='paginate_Unassigned_Data($event)'>
                </mat-paginator>
                <div *ngIf="paginate_Unassigned.total==0" class="noDataFound"> No data available!!
                  </div>
            </div>
            
          </div>
        </div>
      </div>

      


      <div cdkDropList class="container" fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="15px">
        <!-- Due Today change to Due Next-->
        <div class="item item-1" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_DueDate" matBadgeOverlap="false"
                  matBadgeColor="accent"> Due Next </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>
            </div>
            <div class="content-card" >
          
                  <mat-table class="queue-table" #table [dataSource]="dataSource_Due_Today" matSort  fusePerfectScrollbar>
                      <ng-container matColumnDef="ref">
                          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex>Reference</mat-header-cell>
                          <mat-cell *matCellDef="let product"  fxFlex>
                              <div fxLayout="column">
                                  <!-- <span class="text-truncate w-90">{{product?.project_languages?.project?.reference}}</span>
                                  <div fxFlex class="navigat">
                                      <button mat-button class="btn-link" title="Create Quote">Create Quote</button>
                                  </div> -->
                                  <span routerLink="/pms/project/details/{{ product?.project_languages?.project?.uuid}}" 
                                  title="View Details" class="text-truncate tlink w-120">{{product?.project_languages?.project?.reference}}</span>
                              </div>

                          </mat-cell>
                      </ng-container>
            
                      <ng-container matColumnDef="language_pair">
                          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex>Language</mat-header-cell>
                          <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex>
                              <span class="price text-truncate w-100">
                                  <!-- {{product.sourceLanguage?.data.lang_name}} - {{product.targetlanguage?.data.lang_name}} -->
                                  <span *ngFor="let res of product?.lang_array">
                                  <p>{{res}}</p>
                                  </span></span>
                              
                          </mat-cell>
                      </ng-container>
  
  
            
                      <ng-container matColumnDef="due_date">
                          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex>Due Date</mat-header-cell>
                          <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex>
            
            
                              <div fxLayout="column">
                                  <span fxFlex >
                                     <p> {{product?.due_date | date: 'yyyy-MM-dd'}}    {{product?.due_date | date:"HH:mm"}}</p>
                                </span>
                                <div fxFlex>
                                    <span class="tLapse"> ({{product.difference}} Hrs Passed)</span>
                                    </div>
                               </div>
            
                          </mat-cell>
                      </ng-container>
            
                      <mat-header-row *matHeaderRowDef="displayedColumns_DueToday; sticky:true"></mat-header-row>
            
                      <mat-row *matRowDef="let product; columns: displayedColumns_DueToday;" 
                      class="fList">
                      </mat-row>
            
                  </mat-table>
                  <mat-paginator #paginator *ngIf="paginate_DueToday?.total != 0" [length]="paginate_DueToday?.total" [pageIndex]="paginate_DueToday?.current_page - 1" [pageSize]="paginate_DueToday?.per_page" [pageSizeOptions]="[10,15,20,30,40,50,60,70,80,90,100]" (page)='paginate_DueTodayData($event)'>
                    </mat-paginator>
              
              <div *ngIf="paginate_DueToday.total==0" class="noDataFound"> No data available!!
                </div>
            </div>
           
          </div>
        </div>

        <!-- Additional services request -->
        <div class="item item-2" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex="80%" cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_Additional_Service" matBadgeOverlap="false"
                  matBadgeColor="info">Additional services request </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>

            </div>
            <div class="content-card" >
                <mat-table class="queue-table" #table
                 [dataSource]="dataSource_Additional_Services_request" matSort  fusePerfectScrollbar>
                    <ng-container matColumnDef="ref">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex>Reference</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxFlex>
                            <div fxLayout="column">
                                <span routerLink="/pms/project/details/{{ product.uuid }}" 
                                title="View Details" class="text-truncate tlink w-90">{{product.reference}}</span>
                 
                            </div>
                        </mat-cell>
                    </ng-container>
          
                    <ng-container matColumnDef="language_pair">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex>Language</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex>
                            <span class="price text-truncate w-100">
                                <span *ngFor="let res of product?.lang_array">
                                <p>{{res}}</p>
                                </span></span>
                            
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="due_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex>Due Date</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex>
                            <div fxLayout="column">
                                <span fxFlex >
                                   <p> {{product?.due_date | date: 'yyyy-MM-dd'}}    {{product?.due_date | date:"HH:mm"}}</p>
                              </span>
                             </div>
                        </mat-cell>
                    </ng-container>
          
                    <mat-header-row *matHeaderRowDef="displayedColumns_Additional_Service; sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let product; columns: displayedColumns_Additional_Service;" 
                    class="fList">
                    </mat-row>
                </mat-table>

                <mat-paginator #paginator *ngIf="paginate_Additional_Service?.total != 0" 
                [length]="paginate_Additional_Service?.total" 
                [pageIndex]="paginate_Additional_Service?.current_page - 1" 
                [pageSize]="paginate_Additional_Service?.per_page"
                 [pageSizeOptions]="[10,15,20,30,40,50,60,70,80,90,100]" 
                 (page)='paginate_Additional_serviceData($event)'>
                  </mat-paginator>
                  <div *ngIf="paginate_Additional_Service.total==0" class="noDataFound"> No data available!!
                    </div>
            </div>
          
          </div>

        </div>
  <!-- Additional services request end -->
  <div class="item item-2" fxFlex="48%" cdkDrag>
      <div class="white-box">
        <div class="tHeader" fxLayout>
          <h2 fxFlex="80%" cdkDragHandle class="cMove">
            <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_AssignmentPending" matBadgeOverlap="false"
              matBadgeColor="info">Assignment/Workflow Pending</span>
          </h2>
          <div class="text-right" fxFlex>
            <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
          </div>
        </div>
        <div class="boxBody" >
          <mat-table class="queue-table" #table [dataSource]="dataSource_Assignment_Pending" matSort  fusePerfectScrollbar>
            <ng-container matColumnDef="ref">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex>Reference</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxFlex>
                    <div fxLayout="column">
                        <span  class="price tlink text-truncate w-90" routerLink="/pms/project/details/{{ product.uuid }}">{{product.reference}}</span>
                        <!-- <div fxFlex class="navigat">
                            <button mat-button class="btn-link" routerLink="/pms/project/{{ product.uuid }}/create-quote" title="Create Quote">Create Quote</button>
                        </div> -->
                  
                    </div>
                </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="language_pair">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex>Language</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex>
                    <span class="price text-truncate">
                        <!-- {{product.sourceLanguage?.data.lang_name}} - {{product.targetlanguage?.data.lang_name}} -->
                        <span *ngFor="let res of product?.lang_array">
                        <p>{{res}}</p>
                        </span></span>
                    
                </mat-cell>
            </ng-container>


  
            <ng-container matColumnDef="due_date">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex>Due Date</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex>
  
  
                    <div fxLayout="column">
                        <span fxFlex >
                           <p> {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}</p>
                      </span>
                      <div fxFlex>
                          <span class="tLapse"> ({{product.difference}} Hrs Passed)</span>
                          </div>
                     </div>
  
                </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns_Assignment_Pending; sticky:true"></mat-header-row>
  
            <mat-row *matRowDef="let product; columns: displayedColumns_Assignment_Pending;" 
            class="fList">
            </mat-row>
  
        </mat-table>

        <mat-paginator #paginator 
        *ngIf="paginate_AssignmentPending?.total != 0"
         [length]="paginate_AssignmentPending?.total"
          [pageIndex]="paginate_AssignmentPending?.current_page - 1"
          [pageSize]="paginate_AssignmentPending?.per_page"
           [pageSizeOptions]="[10,15,20,30,40,50,60,70,80,90,100]" (page)='paginate_AssignmentPendingData($event)'>

        </mat-paginator>
        <div *ngIf="paginate_AssignmentPending.total==0" class="noDataFound"> No data available!!
          </div>
        </div>

    
      </div>
    </div>
        <!-- Undergoing change to  In Progress -->
        <div class="item item-1" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> 
                <!-- <span matBadge="" matBadgeOverlap="false"
                  matBadgeColor="accent">
                  Undergoing
                </span> -->
                <span matBadge="" matBadgeOverlap="false"
                matBadgeColor="accent">
                In Progress
              </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>

            </div>

            <div class="content-card" >
              <mat-table class="queue-table" #table [dataSource]="dataSource_Undergoing" matSort  fusePerfectScrollbar>
                  <ng-container matColumnDef="ref">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">REFERENCE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxFlex="28" >
                          <div fxLayout="column">
                              <span routerLink="/pms/project/details/{{ product.uuid }}" title="View Details" class="text-truncate tlink w-90">{{product.reference}}</span>
                                            
                          </div>
                      </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="language_pair">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">LANGUAGES</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                          <span class="price text-truncate">
                            <span *ngFor="let res of product?.lang_array">
                              <p>{{res}}</p>
                              </span>
                          </span>

                          
                      </mat-cell>
                  </ng-container>


        
                  <ng-container matColumnDef="due_date">
                      <mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">DUE DATE</mat-header-cell>
                      <mat-cell class="text-right" *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
        
                        <div fxLayout="column">
                            <span class="text-truncate">
                                {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}
                          </span>
                          <div fxFlex>
                              <span class="tLapse ml-4"> {{product.difference}} </span>
                              </div>
                         </div>
        
                      </mat-cell>
                  </ng-container>
        
                  <mat-header-row  *matHeaderRowDef="displayedColumns_Undergoing_Edit; sticky:true"></mat-header-row>
        
                  <mat-row *matRowDef="let product; columns: displayedColumns_Undergoing_Edit;" 
                  class="fList">
                  </mat-row>
        
              </mat-table>

              <!-- <mat-paginator #paginator *ngIf="paginate_Undergoing?.total != 0" [length]="paginate_Undergoing?.total" [pageIndex]="paginate_Undergoing_Edit?.current_page - 1" [pageSize]="paginate_Undergoing?.per_page" [pageSizeOptions]="[5, 10, 15, 20]" (page)='paginate_UndergoingData($event)'>

                </mat-paginator> -->

                <mat-paginator #paginator *ngIf="paginate_Undergoing?.total != 0" [length]="paginate_Undergoing?.total" [pageIndex]="paginate_Undergoing_Edit?.current_page - 1" [pageSize]="paginate_Undergoing?.per_page" [pageSizeOptions]="[5, 10, 15, 20]" >
                </mat-paginator>
                <div *ngIf="paginate_Undergoing.total==0" class="noDataFound"> No data available!!
                  </div>
            </div>
           
          </div>
        </div>

         <!-- Unassigned -->
        <!-- <div class="item item-2" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_Unassigned" matBadgeOverlap="false"
                  matBadgeColor="info">
                  Unassigned
                </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>
            </div>
          <div class="content-card" >
              <mat-table class="queue-table" #table [dataSource]="dataSource_Unassigned" matSort  fusePerfectScrollbar>
                  <ng-container matColumnDef="ref">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">REFERENCE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxFlex="28" >
                          <div fxLayout="column">
                              <span routerLink="/pms/project/details/{{ product.uuid }}" class="text-truncate tlink w-90">{{product.reference}}</span>
                                            
                          </div>
                      </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="language_pair">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">LANGUAGES</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                          <span class="price text-truncate">
                            <span *ngFor="let res of product?.lang_array">
                              <p>{{res}}</p>
                              </span>
                          </span>
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="due_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">DUE DATE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
                        <div fxLayout="column">
                          <span class="text-truncate">
                                {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}
                          </span>
                          <div fxFlex>
                              <span class="tLapse ml-4"> {{product.difference}} </span>
                              </div>
                         </div>
                      </mat-cell>
                  </ng-container>
                  <mat-header-row  *matHeaderRowDef="displayedColumns_Unassigned; sticky:true"></mat-header-row>
                  <mat-row *matRowDef="let product; columns: displayedColumns_Unassigned;" 
                  class="fList">
                  </mat-row>
              </mat-table>
              <mat-paginator #paginator *ngIf="paginate_Unassigned?.total != 0" [length]="paginate_Unassigned?.total" [pageIndex]="paginate_Unassigned?.current_page - 1" [pageSize]="paginate_Unassigned?.per_page" [pageSizeOptions]="[5, 10, 15, 20]" (page)='paginate_Unassigned_Data($event)'>
                </mat-paginator>
                <div *ngIf="paginate_Unassigned.total==0" class="noDataFound"> No data available!!
                  </div>
            </div>
            
          </div>
        </div> -->


        <!-- Unconfirmed by TZ -->
        <div class="item item-1" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_UnConfirmed" matBadgeOverlap="false"
                  matBadgeColor="accent">
                  Unconfirmed by TZ
                </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>
            </div>
            <div class="content-card" >
                <mat-table class="queue-table" #table [dataSource]="dataSource_unconfirmed" matSort  fusePerfectScrollbar>
                    <ng-container matColumnDef="ref">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Reference</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxFlex="28" >
                            <div fxLayout="column">
                                <span routerLink="/pms/project/details/{{ product.uuid }}" class="text-truncate tlink w-90">{{product.reference}}</span>  
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="language_pair">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">Language</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                          <span class="price text-truncate">
                            <span *ngFor="let res of product?.lang_array">
                              <p>{{res}}</p>
                              </span>
                          </span>

                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="due_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Due Date</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
                            <div fxLayout="column">
                                <span class="text-truncate" >
                                    {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}
                              </span>
                              <div fxFlex>
                                  <span class="tLapse ml-4"> ({{product.difference}} Hrs Passed)</span>
                                  </div>
                             </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns_Unconfirmed; sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let product; columns: displayedColumns_Unconfirmed;" 
                    class="fList">
                    </mat-row>
                </mat-table>
                <mat-paginator #paginator *ngIf="paginate_unconfirmed?.total != 0" [length]="paginate_unconfirmed?.total" [pageIndex]="paginate_unconfirmed?.current_page - 1" [pageSize]="paginate_unconfirmed?.per_page" [pageSizeOptions]="[10,15,20,30,40,50,60,70,80,90,100]" (page)='paginate_unconfirmedData($event)'>
                  </mat-paginator>
                  <div *ngIf="paginate_unconfirmed.total==0" class="noDataFound"> No data available!!
                    </div>
            </div>
          
            
          </div>

        </div>

        <!-- Updates due by tz -->
        <div class="item item-2" fxFlex="48%" cdkDrag>

          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex="80%" cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_UpdatesDueByTz" matBadgeOverlap="false"
                matBadgeColor="accent">
                Updates due by tz</span>

              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>

            </div>

            <div class="boxBody"  >
              <mat-table class="queue-table" #table [dataSource]="dataSource_UpdateByTz" matSort  fusePerfectScrollbar>
                <ng-container matColumnDef="ref">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex>Reference</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxFlex>
                        <div fxLayout="column">
                            <span class="price tlink text-truncate w-90" routerLink="/pms/project/details/{{ product.uuid }}">{{product.reference}}</span>
                            <!-- <div fxFlex class="navigat">
                                <button mat-button class="btn-link" routerLink="/pms/project/{{ product.uuid }}/create-quote" title="Create Quote">Create Quote</button>
                            </div> -->
                        </div>
                    </mat-cell>
                </ng-container>
      
                <ng-container matColumnDef="language_pair">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex>Language</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex>
                        <span class="price text-truncate">
                            <!-- {{product.sourceLanguage?.data.lang_name}} - {{product.targetlanguage?.data.lang_name}} -->
                            <span *ngFor="let res of product.lang_array">
                            <p>{{res}}</p>
                            </span></span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="due_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex>Due Date</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex>
                        <div fxLayout="column">
                            <span fxFlex >
                               <p> {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}</p>
                          </span>
                          <div fxFlex>
                              <span class="tLapse"> ({{product.difference}} Hrs Passed)</span>
                              </div>
                         </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns_UpdateByTz; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let product; columns: displayedColumns_UpdateByTz;" 
                class="fList">
                </mat-row>
            </mat-table>
            <mat-paginator #paginator *ngIf="paginate_UpdateByTz?.total != 0" [length]="paginate_UpdateByTz?.total" [pageIndex]="paginate_UpdateByTz?.current_page - 1" [pageSize]="paginate_UpdateByTz?.per_page" [pageSizeOptions]="[10,15,20,30,40,50,60,70,80,90,100]" (page)='paginate_UpdateDueByTzData($event)'>
            </mat-paginator>
            <div *ngIf="paginate_UpdateByTz.total==0" class="noDataFound"> No data available!!
              </div>
            </div>

           
          </div>

        </div>

     
        <!-- Undergoing Edits -->
        <div class="item item-1" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon> <span [matBadge]="total_record_Undergoing_Edit" matBadgeOverlap="false"
                  matBadgeColor="accent">
                  Undergoing Edits
                </span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>

            </div>

            <div class="content-card" >
              <mat-table class="queue-table" #table [dataSource]="dataSource_Undergoing_Edit" matSort  fusePerfectScrollbar>

                  <ng-container matColumnDef="ref">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">REFERENCE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxFlex="28" >
                          <div fxLayout="column">
                              <span class="text-truncate tlink w-90" routerLink="/pms/project/details/{{ product.uuid }}" title="View Details">{{product.reference}}</span>
                                            
                          </div>
                      </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="language_pair">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">LANGUAGES</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                          <span class="price text-truncate">
                            <span *ngFor="let res of product?.lang_array">
                              <p>{{res}}</p>
                              </span>
                          </span>
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="due_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">DUE DATE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
                        <div fxLayout="column">
                            <span class="text-truncate">
                                {{product?.due_date.date | date: 'yyyy-MM-dd'}}    {{product?.due_date.date | date:"HH:mm"}}
                          </span>
                          <div fxFlex>
                              <span class="tLapse ml-4"> {{product.difference}} </span>
                              </div>
                         </div>
                      </mat-cell>
                  </ng-container>
                  <mat-header-row  *matHeaderRowDef="displayedColumns_Undergoing_Edit; sticky:true"></mat-header-row>
                  <mat-row *matRowDef="let product; columns: displayedColumns_Undergoing_Edit;" 
                  class="fList">
                  </mat-row>
              </mat-table>
              <mat-paginator #paginator *ngIf="paginate_Undergoing_Edit?.total != 0" [length]="paginate_Undergoing_Edit?.total" [pageIndex]="paginate_Undergoing_Edit?.current_page - 1" [pageSize]="paginate_Undergoing_Edit?.per_page" [pageSizeOptions]="[5, 10, 15, 20]" (page)='paginate_Undergoing_EditData($event)'>
            </mat-paginator>
            <div *ngIf="paginate_Undergoing_Edit.total==0" class="noDataFound"> No data available!!
              </div>
            </div>
           
          </div>
        </div>

        <!-- Payment pending start -->
        <!-- <div class="item item-2" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex="80%" cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon>
               <span [matBadge]="total_record_ClientApproval_Pending" matBadgeOverlap="false"
                  matBadgeColor="accent">Payment pending</span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>

            </div>

            <div class="content-card" >

              <mat-table class="queue-table" #table [dataSource]="dataSource_ClientApproval_Pending" matSort  fusePerfectScrollbar>
                  <ng-container matColumnDef="ref">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">REFERENCE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxFlex="28" >
                          <div fxLayout="column">
                              <span routerLink="/pms/project/details/{{ product.uuid }}" title="View Details" class="text-truncate tlink w-90">{{product.reference}}</span>
                          </div>
                      </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="language_pair">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">LANGUAGES</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                          <span class="price text-truncate">
                          <span *ngFor="let res of product?.lang_array">
                          <p>{{res}}</p>
                          </span></span>
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="due_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">DUE DATE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="44">
                          <div fxLayout="column">
                          <span class="text-truncate" >
                              {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}
                        </span>
                        <div fxFlex>
                            <span class="tLapse ml-4"> {{product.difference}} </span>
                            </div>
                       </div>
                      </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns_ClientApproval_Pending; sticky:true"></mat-header-row>
                  <mat-row *matRowDef="let product; columns: displayedColumns_ClientApproval_Pending;" 
                  class="fList">
                  </mat-row>
              </mat-table>
              <mat-paginator #paginator *ngIf="paginate_ClientApproval_Pending?.total != 0" [length]="paginate_ClientApproval_Pending?.total" [pageIndex]="paginate_ClientApproval_Pending?.current_page - 1" [pageSize]="paginate_ClientApproval_Pending?.per_page" [pageSizeOptions]="[ 5,10, 15, 20]" (page)='paginate_ClientApproval_PendingData($event)'>
            </mat-paginator>
            <div *ngIf="paginate_ClientApproval_Pending.total==0" class="noDataFound"> No data available!!
              </div>
            </div>
            
          </div>
        </div> -->
   <!-- Payment pending end -->

      <!-- rejected quote start-->
      <!-- <div class="item item-2" fxFlex="48%" cdkDrag>
          <div class="white-box">
            <div class="tHeader" fxLayout>
              <h2 fxFlex="80%" cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon>
               <span [matBadge]="total_record_rejected_Quote" matBadgeOverlap="false"
                  matBadgeColor="accent">Rejected Quote</span>
              </h2>
              <div class="text-right" fxFlex>
                <button mat-button color="primary" class="btn-link" routerLink="/pms/projects">View All</button>
              </div>

            </div>

            <div class="content-card" >
              <mat-table class="queue-table" #table [dataSource]="dataSource_rejected_quote" matSort  fusePerfectScrollbar>
                  <ng-container matColumnDef="ref">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">REFERENCE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxFlex="28" >
                          <div fxLayout="column">
                              <span routerLink="/pms/project/details/{{ product.uuid }}" title="View Details" class="text-truncate tlink w-90">{{product.reference}}</span>
               
                          </div>
                      </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="language_pair">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">LANGUAGES</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                          <span class="price text-truncate">
                          <span *ngFor="let res of product?.lang_array">
                          <p>{{res}}</p>
                          </span></span>
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="due_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">DUE DATE</mat-header-cell>
                      <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="44">
                          <div fxLayout="column">
                          <span class="text-truncate" >
                              {{product?.due_date?.date | date: 'yyyy-MM-dd'}}    {{product?.due_date?.date | date:"HH:mm"}}
                        </span>
                        <div fxFlex>
                            <span class="tLapse ml-4"> {{product.difference}} </span>
                            </div>
                       </div>
                      </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns_Rejected_Quotes; sticky:true"></mat-header-row>
                  <mat-row *matRowDef="let product; columns: displayedColumns_Rejected_Quotes;" 
                  class="fList">
                  </mat-row>
              </mat-table>
              <mat-paginator #paginator *ngIf="paginate_Rejected_quotes?.total != 0" [length]="paginate_Rejected_quotes?.total" [pageIndex]="paginate_Rejected_quotes?.current_page - 1" [pageSize]="paginate_Rejected_quotes?.per_page" [pageSizeOptions]="[ 5,10, 15, 20]" (page)='paginate_RejectedQuote_PendingData($event)'>
            </mat-paginator>
            <div *ngIf="paginate_Rejected_quotes.total==0" class="noDataFound"> No data available!!
              </div>
            </div>
            
          </div>
        </div> -->
      <!-- rejected quote end-->
   
        <!-- Please invoice -->
        <!-- <div class="item item-1" fxFlex="48%" cdkDrag>
          <div class="white-box coming-soon">
            <div class="tHeader" fxLayout>
              <h2 fxFlex cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon>  Please invoice                
              </h2>
              <div class="text-right" fxFlex>
                
              </div>
            </div>
            <div class="boxBody">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Reference</th>
                    <th>Languages</th>
                    <th>Due Date</th>
                  </tr>
                </tbody>
                <tr >
                  <td>123489</td>
                  <td>Eurdo < English-US</td> <td>June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>Eurdo < English-US</td> <td>June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>Eurdo < English-US</td> <td>June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>Eurdo < English-US</td> <td>June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>Eurdo < English-US</td> <td>June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
              </table>
              <div class="boxFooter" fxLayout>
                <div class="cnt" fxFlex>
                  <span>Showing <b>5/32</b></span>
                </div>
                <div class="arWrp text-right" fxFlex>
                  <button mat-stroked-button class="bicon mr-4">
                    <i class="material-icons">keyboard_arrow_left</i>
                  </button>
                  <button mat-stroked-button class="bicon">
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Refunds -->
        <!-- <div class="item item-2" fxFlex="48%" cdkDrag>

          <div class="white-box coming-soon">
            <div class="tHeader" fxLayout>
              <h2 fxFlex="80%" cdkDragHandle class="cMove">
                <mat-icon>file_copy</mat-icon>
                Refunds
              </h2>
              <div class="text-right" fxFlex>
                
              </div>

            </div>

            <div class="boxBody">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Reference</th>
                    <th>Languages</th>
                    <th>Due Date</th>
                  </tr>
                </tbody>
                <tr>
                  <td>123489</td>
                  <td>Eurdo < English-US</td> <td>June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>Eurdo < English-US</td> <td>June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>
                    <span class="blk"> Eurdo < English-US </span> <span class="blk"> Eurdo < English-US </span> </td>
                          <td>
                          June 31, <span class="tLapse"> (6 Hrs Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>
                    <span class="blk"> Eurdo < English-US </span> </td> <td>June 31, <span class="tLapse"> (6 Hrs
                          Passed)</span> </td>
                </tr>
                <tr>
                  <td>123489</td>
                  <td>
                    <span class="blk"> Eurdo < English-US </span> </td> <td>June 31, <span class="tLapse"> (6 Hrs
                          Passed)</span> </td>
                </tr>
              </table>
              <div class="boxFooter" fxLayout>
                <div class="cnt" fxFlex>
                  <span>Showing <b>5/32</b></span>
                </div>
                <div class="arWrp text-right" fxFlex>
                  <button mat-stroked-button class="bicon mr-4">
                    <i class="material-icons">keyboard_arrow_left</i>
                  </button>
                  <button mat-stroked-button class="bicon">
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      </div>

    </div>


    <!-- Right section -->
    <div class="item item-2" fxFlex="30%">

      <div class="white-box">
        <div class="tHeader" fxLayout>

          <!-- <div fxFlex>
            <button mat-stroked-button class="bicon mr-4">
              <i class="material-icons">keyboard_arrow_left</i>
            </button>
          </div> -->
          <h2 flxFlex> Today: <span class="txt-green">{{graphDate}}</span> </h2>
          <!-- <div fxFlex class="text-right">
            <button mat-stroked-button class="bicon ml-4">
              <i class="material-icons">keyboard_arrow_right</i>
            </button>
          </div> -->
        </div>

        <div class="boxBody">
          <!-- <canvas  baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
            [options]="pieChartOptions" (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas> -->
            <div class="mopt p-20 ">
              <p *ngIf="pieChartError!=''">{{pieChartError}}</p>
            </div>
     
            <canvas id="pieChart" ></canvas>
            

          <div class="projStat" fxLayout="row" fxLayoutAlign="center center" *ngIf="pieChartError==''">

            <div fxFlex>
              <h3>{{pieChartData[0]}}</h3>
              <!-- <p class="txt-green">New Request</p> -->
              <p class="txt-green">UnAssigned</p>
            </div>

            <div fxFlex>
              <h3>{{pieChartData[1]}}</h3>
              <p class="txt-red">InProgress</p>
            </div>

            <div fxFlex>
              <h3>{{pieChartData[2]}}</h3>
              <p class="txt-blue">Delivered</p>
            </div>
          </div>
          <div class="msgCenter">
            <div class="pl-20 pr-20">
              <h4><i class="material-icons">message</i> Question / Clarification</h4>
            </div>
            <mat-tab-group class="top-tabs">
              <mat-tab label="Translator">
                <div fxLayout="row" class="pl-20 pr-20" fxLayoutAlign="center center">
                  <h4 fxFlex> <span  matBadgeOverlap="false" matBadgeColor="accent">Today</span></h4>
                  <!-- <div fxFlex class="text-right">
                    <span>Mark all as read</span>
                  </div> -->
                </div>
                <div class="msgRow mRead" *ngFor="let res of translatorMessages">
                  <p><a routerLink="/pms/project/details/{{res?.project_id}}">{{res?.project?.data?.reference}}</a> {{res?.users?.data?.user_group_id}}: {{res?.users?.data?.fullname}}</p>
                  <p>{{res.message}}</p>
                  <p class="msgAct"><button mat-button class="btn-link" color="accent" (click)="showMessageSection(res.id)">REPLY</button>
                     <!-- <span class="msgTime">2 Hours ago</span> -->
                    </p>

                   
                   <div *ngIf="showMessage==res.id">
                      <form name="Form"  [formGroup]="Form">
                    <p>
                      <mat-form-field  >
                        <textarea matInput placeholder="Write a reply" formControlName="message"></textarea>
                        <mat-error>Message is required!</mat-error>
                      </mat-form-field>
                    </p>
                    <p class="msgAct"><button  mat-raised-button color="primary" type="button" (click)="submit(res)" [disabled]="Form.invalid">SEND</button></p>
                  </form>
                  </div>   
            
                </div>
                <!-- <div class="msgRow munRead">
                  <p><a href="#">Ref# 123456, Translator: Mr. Mustafa</a></p>
                  <p>I need some clarificaiton for this project please help to understand the text mentioned on the
                    file.
                  </p>
                  <p class="msgAct"><button mat-button class="btn-link" color="accent">REPLY</button> <span
                      class="msgTime">2 Hours ago</span></p>
                </div> -->
                <!-- <div class="msgRow munRead">
                  <p><a href="#">Ref# 123456, Translator: Mr. Mustafa</a></p>
                  <p>I need some clarificaiton for this project please help to understand the text mentioned on the
                    file.
                  </p>
                  <p class="msgAct"><button mat-button class="btn-link" color="accent">REPLY</button> <span
                      class="msgTime">2 Hours ago</span></p>
                </div> -->
                <!-- <div class="msgRow munRead">
                  <p><a href="#">Ref# 123456, Translator: Mr. Mustafa</a></p>
                  <p>I need some clarificaiton for this project please help to understand the text mentioned on the
                    file.
                  </p>
                  <p class="msgAct"><button mat-button class="btn-link" color="accent">REPLY</button> <span
                      class="msgTime">2 Hours ago</span></p>
                </div> -->
                <!-- <div class="msgRow munRead">
                  <p><a href="#">Ref# 123456, Translator: Mr. Mustafa</a></p>
                  <p>I need some clarificaiton for this project please help to understand the text mentioned on the
                    file.
                  </p>
                  <p class="msgAct"><button mat-button class="btn-link" color="accent">REPLY</button> <span
                      class="msgTime">2 Hours ago</span></p>
                </div> -->
                <!-- <div class="msgRow munRead">
                  <p><a href="#">Ref# 123456, Translator: Mr. Mustafa</a></p>
                  <p>I need some clarificaiton for this project please help to understand the text mentioned on the
                    file.
                  </p>
                  <p class="msgAct"><button mat-button class="btn-link" color="accent">REPLY</button> <span
                      class="msgTime">2 Hours ago</span></p>
                </div> -->
                <!-- <div class="msgRow munRead">
                  <p><a href="#">Ref# 123456, Translator: Mr. Mustafa</a></p>
                  <p>I need some clarificaiton for this project please help to understand the text mentioned on the
                    file.
                  </p>
                  <p class="msgAct"><button mat-button class="btn-link" color="accent">REPLY</button> <span
                      class="msgTime">2 Hours ago</span></p>
                </div> -->
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>