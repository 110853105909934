<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="white-box">
    <form name="Form" [formGroup]="Form">
      <div class="tHeader">
        <h4>Filter</h4>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div>
            <mat-form-field  >
              <mat-label>Name</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field  >
              <mat-label>Any Phone</mat-label>
              <input type="number" matInput formControlName="phone">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field  >
              <mat-label>Any Email</mat-label>
              <input matInput formControlName="email">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div>
            <mat-form-field  >
              <mat-label>Any Address</mat-label>
              <input matInput formControlName="address">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field  >
              <mat-label>City</mat-label>
              <input matInput formControlName="city">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field  >
              <mat-label>State</mat-label>
              <input matInput formControlName="state">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field  >
              <mat-label>Postal Code</mat-label>
              <input matInput formControlName="postal_code">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field  >
              <mat-label>Country</mat-label>
              <input matInput formControlName="country">
            </mat-form-field>
          </div>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

          <mat-form-field   fxFlex="20">
            <mat-label>Account Type</mat-label>
            <mat-select formControlName="account_type">
              <mat-option value="">
                Select
              </mat-option>
              <mat-option *ngFor="let res of accountType" [value]="res?.name"> {{res?.name}}
                 
               </mat-option>


            </mat-select>

          </mat-form-field>



          <mat-form-field   fxFlex="20">
            <mat-label>Industry</mat-label>
            <mat-select formControlName="industry" >
              <mat-option value="">
                Select
              </mat-option>
              <mat-option *ngFor="let res of industryData" [value]="res?.id">{{res?.name}}</mat-option>
            </mat-select>

          </mat-form-field>


          <mat-form-field   fxFlex="28">
            <mat-label>Assigned to</mat-label>
            <mat-select formControlName="assigned_to" >
              <mat-option value="">
                Select
              </mat-option>

              <mat-option *ngFor="let res of portalUsers" [value]="res?.user_name">
                 <ul class="list_items">
                      <li>
                          {{res?.user_name}}
                      </li>
                      <li>
                          {{res?.email}}
                      </li>
                  </ul>
              </mat-option>

            </mat-select>

          </mat-form-field>

          <mat-form-field   fxFlex="28">
            <mat-label>Created By</mat-label>
            <mat-select formControlName="created_by">
              <mat-option value="">
                Select
              </mat-option>

              <mat-option *ngFor="let res of portalUsers" [value]="res?.user_name">
                  <ul class="list_items">
                      <li>
                          {{res?.user_name}}
                      </li>
                      <li>
                          {{res?.email}}
                      </li>
                  </ul>
              </mat-option>

            </mat-select>

          </mat-form-field>


          <!-- <mat-form-field   fxFlex="18">
          <mat-label>Modified by</mat-label>
          <mat-select>
            <mat-option>
              Select
            </mat-option>

            <mat-option label="Alliance" value="Alliances">Melisa Arieas</mat-option>

          </mat-select>

        </mat-form-field> -->






        </div>

        <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

        <mat-form-field   fxFlex="20">
          <mat-label>Date Created</mat-label>
          <mat-select>
            <mat-option>
              Select
            </mat-option>

            <mat-option label="Equals" value="=" selected="selected">Equals</mat-option>
            <mat-option label="Not On" value="not_equal">Not On</mat-option>
            <mat-option label="After" value="greater_than">After</mat-option>
            <mat-option label="Before" value="less_than">Before</mat-option>
            <mat-option label="Last 7 Days" value="last_7_days">Last 7 Days</mat-option>
            <mat-option label="Next 7 Days" value="next_7_days">Next 7 Days</mat-option>
            <mat-option label="Last 30 Days" value="last_30_days">Last 30 Days</mat-option>
            <mat-option label="Next 30 Days" value="next_30_days">Next 30 Days</mat-option>
            <mat-option label="Last Month" value="last_month">Last Month</mat-option>
            <mat-option label="This Month" value="this_month">This Month</mat-option>
            <mat-option label="Next Month" value="next_month">Next Month</mat-option>
            <mat-option label="Last Year" value="last_year">Last Year</mat-option>
            <mat-option label="This Year" value="this_year">This Year</mat-option>
            <mat-option label="Next Year" value="next_year">Next Year</mat-option>
            <mat-option label="Is Between" value="between">Is Between</mat-option>

          </mat-select>

        </mat-form-field>

        <div>
          <mat-form-field  >
            <mat-label>DD/MM/YYY</mat-label>
            <input matInput>
          </mat-form-field>
        </div>



        <mat-form-field   fxFlex="20">
          <mat-label>Date Modified</mat-label>
          <mat-select>
            <mat-option>
              Select
            </mat-option>

            <mat-option label="Equals" value="=" selected="selected">Equals</mat-option>
            <mat-option label="Not On" value="not_equal">Not On</mat-option>
            <mat-option label="After" value="greater_than">After</mat-option>
            <mat-option label="Before" value="less_than">Before</mat-option>
            <mat-option label="Last 7 Days" value="last_7_days">Last 7 Days</mat-option>
            <mat-option label="Next 7 Days" value="next_7_days">Next 7 Days</mat-option>
            <mat-option label="Last 30 Days" value="last_30_days">Last 30 Days</mat-option>
            <mat-option label="Next 30 Days" value="next_30_days">Next 30 Days</mat-option>
            <mat-option label="Last Month" value="last_month">Last Month</mat-option>
            <mat-option label="This Month" value="this_month">This Month</mat-option>
            <mat-option label="Next Month" value="next_month">Next Month</mat-option>
            <mat-option label="Last Year" value="last_year">Last Year</mat-option>
            <mat-option label="This Year" value="this_year">This Year</mat-option>
            <mat-option label="Next Year" value="next_year">Next Year</mat-option>
            <mat-option label="Is Between" value="between">Is Between</mat-option>

          </mat-select>

        </mat-form-field>

        <div>
          <mat-form-field  >
            <mat-label>DD/MM/YYY</mat-label>
            <input matInput>
          </mat-form-field>
        </div>



      </div> -->

      </div>
      <div class="actWraper">
        <button mat-flat-button color="accent" class="mr-16" (click)="submit()"
          [disabled]="Form.invalid">Search</button>
      </div>
    </form>

  </div>
  <div class="white-box">
    <div class="tHeader">
     
      <div fxLayout="row wrap">
        <div fxFlex>
          <h4>Total {{total_records}} Accounts</h4>
        </div>
        <div fxFlex class="text-right">
          <button mat-stroked-button routerLink="/pms/account/add-account" >Add Account</button>
          <!-- <button mat-stroked-button (click)="deleteCustomer()">Move to Trash</button> -->
        </div>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="table">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20"> Name</mat-header-cell>
        <mat-cell *matCellDef="let product" fxFlex="20">
          <p class="text-truncate">
            <a routerLink="/pms/account/details/{{ product.id }}">{{product.name}}</a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20"> Email</mat-header-cell>
        <mat-cell *matCellDef="let product" fxFlex="20">
          <p class="text-truncate">{{product.email}}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="contact">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20">Contact</mat-header-cell>
        <mat-cell *matCellDef="let product" fxFlex="20">
          <p class="text-truncate">{{product.office_phone}}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20"> Type</mat-header-cell>
        <mat-cell *matCellDef="let product" fxFlex="20">
          <p class="text-truncate">{{product.account_type}}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Created">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12">Created</mat-header-cell>
        <mat-cell *matCellDef="let product" fxFlex="12">
          <p class="text-truncate">{{product.created_at.date | date: 'MM/dd/yyyy'}}</p>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
      <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
      </mat-row>
    </table>
    <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
      [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[5,10,20,30,40,50,60]"
      (page)='paginateData($event)'>
    </mat-paginator>
    <div *ngIf="paginate.total==0" class="noDataFound"> No data available!!
    </div>
  </div>
</div>