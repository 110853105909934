

<div class="white-box">
  <div class="main-section sms">
    <div class="head-section">
      <div class="headLeft-section">
        <div class="headLeft-sub">
          <span class="tSms">
            <i class="material-icons"> sms </i> Text / MMS
          </span>
          <span>Base # {{BaseNumber}}</span>
        </div>
      </div>
      <div class="headRight-section">
        <div class="headRight-sub">

          <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px">
            <div fxFlex="55">

              <div class="msgDisplay">
                <h3>+{{fromNumber}}</h3>
                <small>Conversation History</small>
              </div>
              <div class="inputDisplay" style="display: none;">
                <mat-form-field>
                  <mat-label>Enter mobile number with country code e.g +1234567890</mat-label>
                  <input matInput>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="40" class="text-right">
              <button mat-flat-button color="accent" class="mt-8 mr-8" (click)="sendMessagePopup()"><i class="material-icons"> add_circle </i> New
                Message</button>

                <button mat-stroked-button class="mt-8"  (click)="pageRefresh()"><i class="material-icons"> refresh </i> Refresh </button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="body-section">
      <div class="left-section">
        <div class="search-section">
          <div class="search-sub p-16">
            <mat-form-field>
              <mat-label>Search</mat-label>
              <input matInput [(ngModel)]="searchText">
            </mat-form-field>

            

          </div>
        </div>
        <div class="h500 mCustomScrollbar" data-mcs-theme="minimal-dark">
          <ul>
            <li *ngFor="let hero of heroes | appFilter: searchText"
            [ngClass]="{'active': hero.recipient === fromNumber}" (click)="getHero(hero.recipient)">
              <div class="chatList">
                <div class="img">
                  <span class="material-icons">account_circle</span>
                </div>
                <div class="desc">
                  <small class="time">{{hero.datetime.date | date: 'yyyy-MM-dd'}}</small>
                  <h5>+{{hero.recipient}}</h5>
                  <small>{{hero.body}}</small>
                </div>
              </div>
              <span class="unreadmessages" *ngIf="hero.unread_count!=0">{{hero.unread_count}}</span>
              <!-- <div [ngClass]="{'new': showblock}" *ngIf="total!=0"></div> -->
             <!-- <app-sms-unreadmessage [unreadtotal]="hero.recipient"></app-sms-unreadmessage> -->
            </li>
            <!-- <li>
              <div class="chatList">
                <div class="img">

                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">3 day</small>
                  <h5>+8741235648745</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li>
            <li class="active">
              <div class="chatList">
                <div class="img">
                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">4 day</small>
                  <h5>Lajy Ion</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li>
            <li>
              <div class="chatList">
                <div class="img">
                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">18 day</small>
                  <h5>Lod Kine</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li>
            <li>
              <div class="chatList">
                <div class="img">

                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">11:50 am</small>
                  <h5>Nik Minaj</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li>
            <li>
              <div class="chatList">
                <div class="img">
                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">20 day</small>
                  <h5>Win Sina</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li>
            <li>
              <div class="chatList">
                <div class="img">
                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">18 day</small>
                  <h5>Jack Clerk</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li>
            <li>
              <div class="chatList">
                <div class="img">
                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">20 day</small>
                  <h5>Win Sina</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li>
            <li>
              <div class="chatList">
                <div class="img">
                  <span class="material-icons">
                    account_circle
                  </span>
                </div>
                <div class="desc">
                  <small class="time">18 day</small>
                  <h5>Jack Clerk</h5>
                  <small>Lorem ipsum dolor sit amet...</small>
                </div>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="right-section">
        <div class="message mCustomScrollbar" data-mcs-theme="minimal-dark" #scrollMe1>
          <perfect-scrollbar #chatPS1  >
          <ul>
            <li *ngFor="let herohistory of hero; let i=index" 
            [ngClass]="{'msg-right':herohistory.sent === 1, 'msg-left':herohistory.sent === 0}">
              <div class="msg-left-sub">
                <span class="material-icons">account_circle</span> 
                {{herohistory.sent === 0 ? '+'+herohistory.recipient : 'Day PM'}}
                <span *ngIf="herohistory?.senderdetail!=null && herohistory.sent === 1"> ({{herohistory?.senderdetail?.fullname}})</span>
                <span *ngIf="herohistory?.readerdetail!=null && herohistory.sent === 0"> ({{herohistory?.readerdetail?.fullname}})</span>
                <div class="msg-desc">
                  {{herohistory.body}}
                </div>
                <small>{{herohistory.datetime}}</small>
                <a style="cursor: pointer;" *ngIf="herohistory.readstatus==0 && herohistory.sent==0 && (hero.length == i+1)" (click)="markAsRead()">Mark as read</a>
              </div>
            </li>
            <!-- <li class="msg-left">
              <div class="msg-left-sub">
                <span class="material-icons">account_circle</span> +14235467897865
                <div class="msg-desc">
                  Your translation is currently underway. Please Email (contact@daytranslations.com) us if you have any
                  questions. Thank you!
                </div>
                <small>05:25 am</small>
              </div>
            </li>
            <li class="msg-right">
              <div class="msg-left-sub">
                <span class="material-icons">account_circle</span> Day PM
                <div class="msg-desc">
                  Your translation is currently underway. Please Email (contact@daytranslations.com) us if you have any
                  questions. Thank you!
                </div>
                <small>05:25 am</small>
              </div>
            </li> -->

            <!-- <li class="msg-left">
              <div class="msg-left-sub">
                <span class="material-icons">account_circle</span> +14235467897865

                <div class="msg-desc">
                  Your translation is currently underway. Please Email (contact@daytranslations.com) us if you have any
                  questions. Thank you!
                </div>
                <small>05:25 am</small>
              </div>
            </li>
            <li class="msg-right">
              <div class="msg-left-sub">
                <small>05:25 am</small> Day PM
                <div class="msg-desc">
                  Your translation is currently underway. Please Email (contact@daytranslations.com) us if you have any
                  questions. Thank you!
                </div>
                <small>05:25 am</small>
              </div>
            </li>
            <li class="msg-left">
              <div class="msg-left-sub">
                <span class="material-icons">account_circle</span> +14235467897865

                <div class="msg-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit
                </div>
                <small>05:25 am</small>
              </div>
            </li>
            <li class="msg-right">
              <div class="msg-left-sub">
                <span class="material-icons">account_circle</span> Day PM

                <div class="msg-desc">
                  Your translation is currently underway. Please Email (contact@daytranslations.com) us if you have any
                  questions. Thank you!
                </div>
                <small>05:25 am</small>
              </div>
            </li> -->
          </ul>
          </perfect-scrollbar>
        </div>

        <div class="right-section-bottom">
          <form [formGroup]="heroForm" (ngSubmit)="sendSmsto()">
            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px">
              <div fxFlex="90">
                <mat-form-field>
                  <mat-label>Type a message</mat-label>
                  <textarea matInput formControlName="body"> </textarea>
                </mat-form-field>
                <div class="inputInfo mt-4"><small>Maximum 160 characters</small></div>
              </div>
              <div fxFlex="8">
                <button mat-flat-button color="accent" class="mt-24"  [disabled]="!heroForm.valid"><span class="material-icons">send</span>
                  Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>