import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatSort } from '@angular/material';
import * as moment from 'moment';
import { SharedService } from 'app/main/shared.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { Chart } from 'chart.js';
var PmDashboardComponent = /** @class */ (function () {
    function PmDashboardComponent(document, projectsService, datePipe, _formBuilder, toastr) {
        this.document = document;
        this.projectsService = projectsService;
        this.datePipe = datePipe;
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.totalcount = 0;
        this.project_locked_record = [];
        this.paginate_unconfirmed = {};
        this.paginate_Request_in_queue = {};
        this.paginate_DueToday = {};
        this.paginate_Additional_Service = {};
        this.paginate_AssignmentPending = {};
        this.paginate_UpdateByTz = {};
        this.paginate_Undergoing_Edit = {};
        this.paginate_Undergoing = {};
        this.paginate_Unassigned = {};
        this.paginate_ClientApproval_Pending = {};
        this.paginate_Rejected_quotes = {};
        this.pageIndex_unconfirmed = 1;
        this.pageIndex_RequestQueue = 1;
        this.pageIndex_DueToday = 1;
        this.pageIndex_Undergoing_Edit = 1;
        this.pageIndex_Unassigned = 1;
        this.pageIndex_ClientApproval_Pending = 1;
        this.pageIndex_Rejected_Quote = 1;
        this.pageSize_unconfirmed = 5;
        this.pageSize_RequestQueue = 5;
        this.pageSize_DueToday = 5;
        this.pageSize_AdditionalService = 5;
        this.pageSize_Undergoing_Edit = 5;
        this.pageSize_Unassigned = 5;
        this.pageSize_ClientApproval_Pending = 5;
        this.pageSize_Rejected_Quotes = 5;
        this.query_unconfirmed = "";
        this.query_RequestQueue = "";
        this.query_DueToday = "";
        this.query_Additional_Service = "";
        this.query_Undergoing_Edit = "";
        this.query_Unassigned = "";
        this.query_ClientApproval_Pending = "";
        this.query_Rejected_quote = "";
        this.total_count = 0;
        this.total_record_RequestQueue = 0;
        this.total_record_Additional_Service = 0;
        this.total_record_AssignmentPending = 0;
        this.total_record_DueDate = 0;
        this.total_record_Undergoing_Edit = 0;
        this.total_record_Undergoing = 0;
        this.total_record_Unassigned = 0;
        this.total_record_UnConfirmed = 0;
        this.total_record_ClientApproval_Pending = 0;
        this.total_record_rejected_Quote = 0;
        this.total_record_UpdatesDueByTz = 0;
        this.current_page_count = 0;
        this.current_page_count_due_date = 0;
        this.total_count_due_date = 0;
        this.query = "";
        this.id = 1;
        this.displayedColumns_Unconfirmed = ["ref", "language_pair", "due_date"];
        this.displayedColumns_RequestQueue = ["ref", "language_pair", "qemail", "type", "start_date", "due_date"];
        this.displayedColumns_DueToday = ["ref", "language_pair", "due_date"];
        this.displayedColumns_Additional_Service = ["ref", "language_pair", "due_date"];
        this.displayedColumns_Undergoing_Edit = ["ref", "language_pair", "due_date"];
        this.displayedColumns_Unassigned = ["ref", "language_pair", "qemail", "type", "start_date", "due_date"];
        this.displayedColumns_ClientApproval_Pending = ["ref", "language_pair", "due_date"];
        this.displayedColumns_Assignment_Pending = ["ref", "language_pair", "due_date"];
        this.displayedColumns_UpdateByTz = ["ref", "language_pair", "due_date"];
        this.displayedColumns_Rejected_Quotes = ["ref", "language_pair", "due_date"];
        // pieChartLabels: string[] = ["New Request", "InProgress", "Approved"];
        this.pieChartData = [];
        this.pieChartError = '';
        this.pieChartType = 'pie';
        this.translatorMessages = [];
        this.showMessage = 0;
        this.PieChart = [];
        this.sendMessageObj = {
            from_user_id: '',
            message: '',
            message_type: '',
            message_id: '' // optional use only for dashbaord message reply
        };
    }
    PmDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user_id = SharedService.get('id');
        this.date = new Date();
        this.graphDate = this.datePipe.transform(new Date(), "MMM d y");
        this.projectGraph();
        this.GetPmLockedProjects();
        this.getRequestQueue();
        this.getProjectDueDate();
        this.getRequestAdditionalServices();
        this.getUnConfirmedByTz();
        this.getProjectUpdateDueByTz();
        this.getProjectAssignmentPending();
        this.getUndergoing_Edit();
        this.getClientApproval_Pending();
        this.getRejectedQuotes();
        this.getUnassigned();
        this.getUndergoing();
        this.getTranslatorMessages();
        this.Form = this._formBuilder.group({
            message: ['', Validators.required],
        });
        this.setIntervalId = setInterval(function () {
            _this.getRequestQueue();
        }, 30000);
    };
    PmDashboardComponent.prototype.ngOnDestroy = function () {
        // prevent memory leak when component destroyed
        clearInterval(this.setIntervalId);
    };
    // get all loced project by pm
    PmDashboardComponent.prototype.GetPmLockedProjects = function () {
        var _this = this;
        var data = {
            user_id: '',
        };
        data.user_id = this.user_id;
        this.projectsService.GetPmLockedProjects(data)
            .subscribe(function (response) {
            if (response) {
                _this.all_locked_projects = response.data;
                _this.project_locked_record = response.records;
            }
            else {
                _this.all_locked_projects = '';
            }
        });
    };
    PmDashboardComponent.prototype.getRequestQueue = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getRequestQuote(this.id, uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            element['DueTimeClass'] = _this.DueTimeClass;
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    if (minutes <= 10) {
                        element['DueTimeClass'] = "normal";
                    }
                    else if (minutes > 10 && minutes <= 20) {
                        element['DueTimeClass'] = "warning";
                    }
                    else if (minutes > 20) {
                        element['DueTimeClass'] = "danger";
                    }
                    element['difference'] = DueTime;
                });
                _this.dataSource_Request_in_queue = new MatTableDataSource(response.data);
                _this.paginate_Request_in_queue = response.meta.pagination;
                _this.total_record_RequestQueue = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_RequestQueue = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_RequestQueue = event.pageIndex + 1;
        if (this.pageIndex_RequestQueue < 0) {
            this.pageIndex_RequestQueue = 1;
        }
        this.pageSize_RequestQueue = event.pageSize;
        this.query_RequestQueue = '?page=' + this.pageIndex_RequestQueue + '&per_page=' + this.pageSize_RequestQueue;
        this.query_RequestQueue += uQuery;
        this.projectsService.getRequestQuote(this.id, this.query_RequestQueue)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    if (element.projectlang.data.length > 0) {
                        element.projectlang.data.forEach(function (elm) {
                            if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                                lang_array.push(lang1);
                            }
                        });
                    }
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    if (lang_array.length > 0) {
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    }
                });
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            element['DueTimeClass'] = _this.DueTimeClass;
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    if (minutes <= 10) {
                        element['DueTimeClass'] = "normal";
                    }
                    else if (minutes > 10 && minutes <= 20) {
                        element['DueTimeClass'] = "warning";
                    }
                    else if (minutes > 20) {
                        element['DueTimeClass'] = "danger";
                    }
                    element['difference'] = DueTime;
                });
                _this.dataSource_Request_in_queue = new MatTableDataSource(response.data);
                _this.paginate_Request_in_queue = response.meta.pagination;
                _this.total_record_RequestQueue = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.getRequestAdditionalServices = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getAdditional_service(uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_Additional_Services_request = new MatTableDataSource(response.data);
                _this.paginate_Additional_Service = response;
                _this.total_record_Additional_Service = response.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_Additional_serviceData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_AdditionalService = event.pageSize;
        this.query_Additional_Service = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_AdditionalService;
        this.query_Additional_Service += uQuery;
        this.projectsService.getAdditional_service(this.query_Additional_Service)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_Additional_Services_request = new MatTableDataSource(response.data);
                _this.paginate_Additional_Service = response;
                _this.total_record_Additional_Service = response.total;
            }
        });
    };
    PmDashboardComponent.prototype.getProjectDueDate = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getProjectDueToday(uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    if (element.project_languages != null) {
                        lang1 = element.project_languages.sourcelanguage.lang_name + '-' + element.project_languages.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                        if (lang_array.length > 0) {
                            var test = void 0;
                            test = lang_array[lang_array.length - 1];
                            lang_array[lang_array.length - 1] = test.slice(0, -1);
                        }
                        element.lang_array = lang_array;
                        response.data.lang_array = lang_array;
                    }
                });
                response.data.forEach(function (element) {
                    var dt1 = new Date(element.created_at);
                    var dt2 = new Date();
                    _this.estimate_hours_Due_Today = _this.diff_hours(dt2, dt1);
                    element['difference'] = _this.estimate_hours_Due_Today;
                    element.estimate_hours = _this.estimate_hours;
                });
                _this.dataSource_Due_Today = new MatTableDataSource(response.data);
                _this.paginate_DueToday = response;
                _this.total_record_DueDate = response.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_DueTodayData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_DueToday = event.pageSize;
        this.query_DueToday = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_DueToday;
        this.query_DueToday += uQuery;
        this.projectsService.getProjectDueToday(this.query_DueToday)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    lang1 = element.project_languages.sourcelanguage.lang_name + '-' + element.project_languages.targetlanguage.lang_name + ',';
                    lang_array.push(lang1);
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                response.data.forEach(function (element) {
                    var dt1 = new Date(element.created_at);
                    var dt2 = new Date();
                    _this.estimate_hours_Due_Today = _this.diff_hours(dt2, dt1);
                    element['difference'] = _this.estimate_hours_Due_Today;
                    //    element.estimate_hours=this.estimate_hours;
                });
                _this.dataSource_Due_Today = new MatTableDataSource(response.data);
                _this.paginate_DueToday = response;
                _this.total_record_DueDate = response.total;
            }
        });
    };
    PmDashboardComponent.prototype.getUnConfirmedByTz = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getUnConfirmedByTz(uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var dt1 = new Date(element.created_at.date);
                    var dt2 = new Date();
                    _this.estimate_hours_Unconfirmed = _this.diff_hours(dt2, dt1);
                    element['difference'] = _this.estimate_hours_Unconfirmed;
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_unconfirmed = new MatTableDataSource(response.data);
                _this.paginate_unconfirmed = response.meta.pagination;
                _this.total_record_UnConfirmed = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_unconfirmedData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_unconfirmed = event.pageIndex + 1;
        if (this.pageIndex_unconfirmed < 0) {
            this.pageIndex_unconfirmed = 1;
        }
        this.pageSize_unconfirmed = event.pageSize;
        this.query_unconfirmed = '?page=' + this.pageIndex_unconfirmed + '&per_page=' + this.pageSize_unconfirmed;
        this.query_unconfirmed += uQuery;
        this.projectsService.getUnConfirmedByTz(this.query_unconfirmed)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var dt1 = new Date(element.created_at.date);
                    var dt2 = new Date();
                    _this.estimate_hours_Unconfirmed = _this.diff_hours(dt2, dt1);
                    element['difference'] = _this.estimate_hours_Unconfirmed;
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_unconfirmed = new MatTableDataSource(response.data);
                _this.paginate_unconfirmed = response.meta.pagination;
                _this.total_record_UnConfirmed = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.getUndergoing_Edit = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getUnderGoingEdit(uQuery)
            .subscribe(function (response) {
            if (response) {
                // GET due time
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                // get source language & target language 
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_Undergoing_Edit = new MatTableDataSource(response.data);
                _this.paginate_Undergoing_Edit = response.meta.pagination;
                _this.total_record_Undergoing_Edit = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.getUndergoing = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getUnderGoing(uQuery)
            .subscribe(function (response) {
            if (response) {
                // GET due time
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                // get source language & target language 
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_Undergoing = new MatTableDataSource(response.data);
                _this.paginate_Undergoing = response.meta.pagination;
                _this.total_record_Undergoing = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.getClientApproval_Pending = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getClientApproval_Pending(uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        }
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_ClientApproval_Pending = new MatTableDataSource(response.data);
                _this.paginate_ClientApproval_Pending = response.meta.pagination;
                _this.total_record_ClientApproval_Pending = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.getRejectedQuotes = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getRejectedQuotes(uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        }
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_rejected_quote = new MatTableDataSource(response.data);
                _this.paginate_Rejected_quotes = response.meta.pagination;
                _this.total_record_rejected_Quote = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.getUnassigned = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getUnassigned(uQuery)
            .subscribe(function (response) {
            if (response) {
                // GET due time
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                // get source language & target language 
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_Unassigned = new MatTableDataSource(response.data);
                _this.paginate_Unassigned = response.meta.pagination;
                _this.total_record_Unassigned = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.diff_hours = function (dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    };
    PmDashboardComponent.prototype.paginate_ClientApproval_PendingData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_ClientApproval_Pending = event.pageIndex + 1;
        if (this.pageIndex_ClientApproval_Pending < 0) {
            this.pageIndex_ClientApproval_Pending = 1;
        }
        this.pageSize_ClientApproval_Pending = event.pageSize;
        this.query_ClientApproval_Pending = '?page=' + this.pageIndex_ClientApproval_Pending + '&per_page=' + this.pageSize_ClientApproval_Pending;
        this.query_ClientApproval_Pending += uQuery;
        this.projectsService.getClientApproval_Pending(this.query_ClientApproval_Pending)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        }
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                _this.dataSource_ClientApproval_Pending = new MatTableDataSource(response.data);
                _this.paginate_ClientApproval_Pending = response.meta.pagination;
                _this.total_record_ClientApproval_Pending = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_RejectedQuote_PendingData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_Rejected_Quote = event.pageIndex + 1;
        if (this.pageIndex_Rejected_Quote < 0) {
            this.pageIndex_Rejected_Quote = 1;
        }
        this.pageSize_Rejected_Quotes = event.pageSize;
        this.query_Rejected_quote = '?page=' + this.pageIndex_Rejected_Quote + '&per_page=' + this.pageSize_Rejected_Quotes;
        this.query_Rejected_quote += uQuery;
        this.projectsService.getRejectedQuotes(this.query_Rejected_quote)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        if (elm.sourcelanguage != null && elm.targetlanguage != null) {
                            lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                            lang_array.push(lang1);
                        }
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_rejected_quote = new MatTableDataSource(response.data);
                _this.paginate_Rejected_quotes = response.meta.pagination;
                _this.total_record_rejected_Quote = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_Undergoing_EditData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_Undergoing_Edit = event.pageIndex + 1;
        if (this.pageIndex_Undergoing_Edit < 0) {
            this.pageIndex_Undergoing_Edit = 1;
        }
        this.pageSize_Undergoing_Edit = event.pageSize;
        this.query_Undergoing_Edit = '?page=' + this.pageIndex_Undergoing_Edit + '&per_page=' + this.pageSize_Undergoing_Edit;
        this.query_Undergoing_Edit += uQuery;
        this.projectsService.getUnderGoingEdit(this.query_Undergoing_Edit)
            .subscribe(function (response) {
            if (response) {
                // GET due time
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                // get source language & target language 
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_Undergoing_Edit = new MatTableDataSource(response.data);
                _this.paginate_Undergoing_Edit = response.meta.pagination;
                _this.total_record_Undergoing_Edit = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_Unassigned_Data = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_Unassigned = event.pageIndex + 1;
        if (this.pageIndex_Unassigned < 0) {
            this.pageIndex_Unassigned = 1;
        }
        this.pageSize_Unassigned = event.pageSize;
        this.query_Unassigned = '?page=' + this.pageIndex_Unassigned + '&per_page=' + this.pageSize_Unassigned;
        this.query_Unassigned += uQuery;
        this.projectsService.getUnassigned(this.query_Unassigned)
            .subscribe(function (response) {
            if (response) {
                // GET due time
                response.data.forEach(function (element) {
                    var DueTime;
                    var dt2 = new Date();
                    var datetime = moment(element.created_at.date);
                    var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                    var minutes = current.diff(datetime, 'minutes');
                    var seconds = current.diff(datetime, 'seconds');
                    var hour = current.diff(datetime, 'hours');
                    var day = current.diff(datetime, 'day');
                    var months = current.diff(datetime, 'month');
                    if (day > 30) {
                        if (months == 1) {
                            DueTime = months + ' Month Passed';
                        }
                        else {
                            DueTime = months + ' Months Passed';
                        }
                    }
                    else if (hour > 24) {
                        if (day == 1) {
                            DueTime = day + ' Day Passed';
                        }
                        else {
                            DueTime = day + ' Days Passed';
                        }
                    }
                    else if (minutes > 60) {
                        if (hour == 1) {
                            DueTime = hour + ' Hour Passed';
                        }
                        else {
                            DueTime = hour + ' Hours Passed';
                        }
                    }
                    else if (seconds > 60) {
                        if (minutes == 1) {
                            DueTime = minutes + ' Mintue Passed';
                        }
                        else {
                            DueTime = minutes + ' Mintues Passed';
                        }
                    }
                    else if (seconds < 60) {
                        if (seconds == 1) {
                            DueTime = seconds + ' Second Passed';
                        }
                        else {
                            DueTime = seconds + ' Seconds Passed';
                        }
                    }
                    element['difference'] = DueTime;
                });
                // get source language & target language 
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource_Unassigned = new MatTableDataSource(response.data);
                _this.paginate_Unassigned = response.meta.pagination;
                _this.total_record_Unassigned = response.meta.pagination.total;
            }
        });
    };
    // Assignment pending
    PmDashboardComponent.prototype.getProjectAssignmentPending = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getProjectAssignmentPending(uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elem) {
                        lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                response.data.forEach(function (element) {
                    var dt1 = new Date(element.created_at.date);
                    var dt2 = new Date();
                    _this.estimate_hours_assinment_pending = _this.diff_hours(dt2, dt1);
                    element['difference'] = _this.estimate_hours_assinment_pending;
                });
                _this.dataSource_Assignment_Pending = new MatTableDataSource(response.data);
                _this.paginate_AssignmentPending = response.meta.pagination;
                _this.total_record_AssignmentPending = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_AssignmentPendingData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_DueToday = event.pageSize;
        var query = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_DueToday;
        query += uQuery;
        this.projectsService.getProjectAssignmentPending(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elem) {
                        lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                response.data.forEach(function (element) {
                    var dt1 = new Date(element.created_at.date);
                    var dt2 = new Date();
                    _this.estimate_hours_assinment_pending = _this.diff_hours(dt2, dt1);
                    element['difference'] = _this.estimate_hours_assinment_pending;
                    //    element.estimate_hours=this.estimate_hours;
                });
                _this.dataSource_Assignment_Pending = new MatTableDataSource(response.data);
                _this.paginate_AssignmentPending = response.meta.pagination;
                _this.total_record_AssignmentPending = response.meta.pagination.total;
            }
        });
    };
    // updates due by tz
    PmDashboardComponent.prototype.getProjectUpdateDueByTz = function () {
        var _this = this;
        var uQuery = '';
        this.projectsService.getUpdateByTz(uQuery)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elem) {
                        lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                response.data.forEach(function (element) {
                    var estimate_hours_updateByTz;
                    var dt1 = new Date(element.created_at.date);
                    var dt2 = new Date();
                    estimate_hours_updateByTz = _this.diff_hours(dt2, dt1);
                    element['difference'] = estimate_hours_updateByTz;
                });
                _this.dataSource_UpdateByTz = new MatTableDataSource(response.data);
                _this.paginate_UpdateByTz = response.meta.pagination;
                _this.total_record_UpdatesDueByTz = response.meta.pagination.total;
            }
        });
    };
    PmDashboardComponent.prototype.paginate_UpdateDueByTzData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex_DueToday = event.pageIndex + 1;
        if (this.pageIndex_DueToday < 0) {
            this.pageIndex_DueToday = 1;
        }
        this.pageSize_DueToday = event.pageSize;
        var query = '?page=' + this.pageIndex_DueToday + '&per_page=' + this.pageSize_DueToday;
        query += uQuery;
        this.projectsService.getUpdateByTz(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (element) {
                    var lang1 = '';
                    var lang_array = [];
                    element.projectlang.data.forEach(function (elem) {
                        lang1 = elem.sourcelanguage.data.lang_name + '-' + elem.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang1);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                response.data.forEach(function (element) {
                    var estimate_hours_updateByTz;
                    var dt1 = new Date(element.created_at.date);
                    var dt2 = new Date();
                    estimate_hours_updateByTz = _this.diff_hours(dt2, dt1);
                    element['difference'] = estimate_hours_updateByTz;
                    //    element.estimate_hours=this.estimate_hours;
                });
                _this.dataSource_UpdateByTz = new MatTableDataSource(response.data);
                _this.paginate_UpdateByTz = response.meta.pagination;
                _this.total_record_UpdatesDueByTz = response.meta.pagination.total;
            }
        });
    };
    // get Graph data
    PmDashboardComponent.prototype.projectGraph = function () {
        var _this = this;
        var data = {
            date: '',
        };
        var date = new Date();
        var test = this.dateFormat(date);
        data.date = test;
        this.projectsService.projectGraph(data)
            .subscribe(function (response) {
            if (response) {
                _this.pieChartData.push(response.unassigned_project);
                _this.pieChartData.push(response.inprogress_projects);
                _this.pieChartData.push(response.delivered_projects);
                if (_this.pieChartData.length == 0) {
                    _this.pieChartError = " No data available!!";
                }
                _this.getChart();
            }
        });
    };
    PmDashboardComponent.prototype.getChart = function () {
        // pie chart:
        this.PieChart = new Chart('pieChart', {
            type: 'pie',
            data: {
                labels: ["UnAssigned", "InProgress", "Delivered"],
                datasets: [{
                        label: '# of Votes',
                        data: this.pieChartData,
                        backgroundColor: [
                            "#006400",
                            "#FF4500",
                            "#1C86EE",
                        ],
                        borderWidth: 1
                    }]
            }
        });
    };
    // date format
    PmDashboardComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt;
    };
    // get translator messages
    PmDashboardComponent.prototype.getTranslatorMessages = function () {
        var _this = this;
        this.projectsService.getTranslatorMessages()
            .subscribe(function (response) {
            if (response) {
                _this.translatorMessages = response.data;
            }
        });
    };
    PmDashboardComponent.prototype.showMessageSection = function (res) {
        this.showMessage = res;
    };
    PmDashboardComponent.prototype.submit = function (res) {
        var _this = this;
        if (this.Form.value.message == '') {
            this.toastr.error("Message is Required!!");
            return;
        }
        this.sendMessageObj.from_user_id = this.user_id;
        this.sendMessageObj.message = this.Form.value.message;
        this.sendMessageObj.message_type = "2";
        this.sendMessageObj.message_id = res.id; // optional use only for dashbaord message reply
        this.projectsService.sendTranslatorMessages(res.project_id, this.sendMessageObj)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Message sent!!");
                _this.Form.patchValue({
                    message: ''
                });
                _this.getTranslatorMessages();
            }
        });
    };
    return PmDashboardComponent;
}());
export { PmDashboardComponent };
