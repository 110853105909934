import { OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
var ELEMENT_DATA = [
    { position: 1, id: 'Bondarev-08201931972', leadSrouce: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Paid', projValue: '$455.00 USD' },
    { position: 2, id: 'Bondarev-08201931972', leadSrouce: 'DayVIP', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'In-Process', projValue: '$455.00 USD' },
    { position: 3, id: 'Bondarev-08201931972', leadSrouce: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Lost', projValue: '$455.00 USD' },
    { position: 4, id: 'Bondarev-08201931972', leadSrouce: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Under Quotation', projValue: '$455.00 USD' },
    { position: 5, id: 'Bondarev-08201931972', leadSrouce: 'YST Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Approved', projValue: '$455.00 USD' },
    { position: 6, id: 'Bondarev-08201931972', leadSrouce: 'DT Website', projservices: 'Translation, Notarization', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'New Request', projValue: '$455.00 USD' },
    { position: 7, id: 'Bondarev-08201931972', leadSrouce: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Paid', projValue: '$455.00 USD' },
    { position: 8, id: 'Bondarev-08201931972', leadSrouce: 'DT Website', projservices: 'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate: '05/06/2018', projStatus: 'Closed Paid', projValue: '$455.00 USD' },
];
var FreelancerDetailsComponent = /** @class */ (function () {
    function FreelancerDetailsComponent(projectsService, route, httpService, datePipe) {
        this.projectsService = projectsService;
        this.route = route;
        this.httpService = httpService;
        this.datePipe = datePipe;
        this.profileData = {};
        this.day_name_test = '';
        this.skill_name = '';
        this.fileError = '';
        this.pageIndex = 1;
        this.pageSize = 5;
        this.total_record = 0;
        this.paginate = {};
        this.data = {
            tool_licence: '',
            vendoravailabilities: {
                data: []
            },
            vendorspecialities: {
                data: []
            },
            country: '',
            first_name: '',
            last_name: '',
            reference_id: '',
            email: '',
            skype_id: '',
            address1: '',
            city: '',
            state: '',
            postal_code: '',
            payment_method: '',
            translation_tools: '',
            education: '',
            cover_letter: '',
            vendornativelanguage: {}
        };
        this.ProjectAssignedStatus = [];
        this.responseData = [];
        this.age = '';
        this.displayedColumns = ['id', 'leadSrouce', 'projservices', 'languagePair', 'projStartDate', 'projEndDate', 'projStatus'];
        this.BaseUrl_file = environment.baseUrl_file;
    }
    FreelancerDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.id = this.route.snapshot.params['id'];
        this.getFreelancer();
        this.getFreelancerProjectHistory();
        this.httpService.get('/assets/ProjectAssignedStatus.json').subscribe(function (data) {
            _this.ProjectAssignedStatus = data; // FILL THE ARRAY WITH DATA.
        }, function (err) {
            //console.log(err.message);
        });
    };
    // dataSource = ELEMENT_DATA;
    FreelancerDetailsComponent.prototype.getFreelancer = function () {
        var _this = this;
        this.projectsService.getFreelancer(this.id)
            .subscribe(function (response) {
            if (response) {
                _this.profileData = response.data;
                //console.log(response.data.vendorSubtitler.data);
                if (response.data.vendorSubtitler.data.length > 0) {
                    response.data.vendorSubtitler.data = response.data.vendorSubtitler.data.sort(function (a, b) { return a.skillservice.data.name.localeCompare(b.skillservice.data.name); });
                }
                // console.log(response.data.vendorSubtitler.data);
                if (response.data.dob != '' && response.data.dob != null) {
                    var bdate = new Date(response.data.dob);
                    var ntimeDiff = Math.abs(Date.now() - bdate.getTime());
                    _this.age = Math.floor((ntimeDiff / (1000 * 3600 * 24)) / 365);
                }
                if (response.data.tool_names) {
                    _this.selected_tools = response.data.tool_names.replace(/,/g, ', ');
                }
                if (response.data.tool_licence == 0) {
                    _this.profileData.tool_licence = 'No';
                }
                else {
                    _this.profileData.tool_licence = 'Yes';
                }
                var specialities_1 = '';
                response.data.vendorspecialities.data.forEach(function (elem) {
                    specialities_1 += elem.specialities.data.name + ', ';
                    _this.specialities_test = specialities_1;
                    _this.specialities_test = _this.specialities_test.slice(0, -2);
                });
                var day_1 = '';
                response.data.vendoravailabilities.data.forEach(function (elem) {
                    day_1 += elem.day_name + ', ';
                    _this.day_name_test = day_1;
                    _this.day_name_test = _this.day_name_test.slice(0, -2);
                });
                if (response.data.vendorprofileimage.data.length > 0) {
                    response.data.vendorprofileimage.data.forEach(function (element) {
                        _this.vendor_file_data = _this.BaseUrl_file + element.path;
                    });
                }
                response.data.vendorcontract.data.forEach(function (element) {
                    _this.vendor_contract_data = _this.BaseUrl_file + element.path;
                    var x = element.path.split('/');
                    _this.vendor_contract_name = x[2];
                });
                response.data.vendorresume.data.forEach(function (element) {
                    _this.vendor_resume_data = _this.BaseUrl_file + element.path;
                    var x = element.path.split('/');
                    _this.vendor_resume_name = x[2];
                });
                // response.data.extradocument.data.forEach(element => {
                //   this.vendor_exta_document_data=this.BaseUrl_file + element.path;
                //   let x = element.path.split('/');
                //   this.vendor_exta_document_name = x[2];
                // });
                if (response.data.extradocument != null) {
                    _this.vendor_exta_document_data = _this.BaseUrl_file + response.data.extradocument.data.path;
                    _this.vendor_exta_document_name = response.data.extradocument.data.name;
                }
                if (response.data.vendorskill.data.length > 0) {
                    var skill_1 = '';
                    response.data.vendorskill.data.forEach(function (elem) {
                        skill_1 += elem.skill.data.name + ', ';
                    });
                    _this.skill_name = skill_1.slice(0, -2);
                }
                response.data.vendortranslationlangs.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.lang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                });
                var subtitlerslang = '';
                response.data.vendorSubtitler.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.subtiterslang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                    elem.subtitle_rate = elem.subtitle_rate;
                });
                var treplang = '';
                response.data.vendorTreping.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                });
                _this.data = response.data;
            }
        });
    };
    FreelancerDetailsComponent.prototype.getFreelancerProjectHistory = function () {
        var _this = this;
        var query = '';
        this.projectsService.getFreelancerProjectHistory(this.id, query)
            .subscribe(function (response) {
            if (response) {
                //console.log(response);
                response.data.data.forEach(function (element) {
                    var project_status = '';
                    _this.ProjectAssignedStatus.forEach(function (ele) {
                        if (ele.id == element.status) {
                            project_status = ele.value;
                        }
                    });
                    element.project_status = project_status;
                    var lang1 = '';
                    var ref = '';
                    var lead_source = '';
                    var lang_array = [];
                    var due_date = '';
                    var start_date = '';
                    var uuid = '';
                    if (element.projectlanguage.length > 0) {
                        element.projectlanguage.forEach(function (elm) {
                            lang1 = elm.sourcelanguage.lang_name + ' - ' + elm.targetlanguage.lang_name + ',';
                            lang_array.push(lang1);
                            if (elm.project) {
                                if (elm.project.reference != null) {
                                    ref = elm.project.reference;
                                }
                                if (elm.project.customer.source.name) {
                                    lead_source = elm.project.customer.source.name;
                                }
                                due_date = elm.project.due_date;
                                start_date = elm.project.start_date;
                                uuid = elm.project.uuid;
                            }
                        });
                    }
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    element.lang_array = lang_array;
                    element.ref = ref;
                    element.lead_source = lead_source;
                    element.uuid = uuid;
                    if (due_date != null) {
                        element.due_date = due_date;
                    }
                    if (start_date != null) {
                        element.start_date = start_date;
                    }
                });
                _this.responseData = response.data.data;
                _this.dataSource = new MatTableDataSource(response.data.data);
                _this.paginate = response.data;
                _this.total_record = response.data.total;
            }
        });
    };
    FreelancerDetailsComponent.prototype.paginateData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        var query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        query += uQuery;
        this.projectsService.getFreelancerProjectHistory(this.id, query)
            .subscribe(function (response) {
            if (response) {
                response.data.data.forEach(function (element) {
                    var project_status = '';
                    _this.ProjectAssignedStatus.forEach(function (ele) {
                        if (ele.id == element.status) {
                            project_status = ele.value;
                        }
                    });
                    element.project_status = project_status;
                    var lang1 = '';
                    var ref = '';
                    var lead_source = '';
                    var lang_array = [];
                    var due_date = '';
                    var start_date = '';
                    var uuid = '';
                    element.projectlanguage.forEach(function (elm) {
                        lang1 = elm.sourcelanguage.lang_name + ' - ' + elm.targetlanguage.lang_name + ',';
                        lang_array.push(lang1);
                        ref = elm.project.reference;
                        lead_source = elm.project.customer.source.name;
                        due_date = elm.project.due_date;
                        start_date = elm.project.start_date;
                        uuid = elm.project.uuid;
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    element.lang_array = lang_array;
                    element.lang_array = lang_array;
                    element.ref = ref;
                    element.lead_source = lead_source;
                    element.uuid = uuid;
                    if (due_date != null) {
                        element.due_date = due_date;
                    }
                    if (start_date != null) {
                        element.start_date = start_date;
                    }
                });
                _this.responseData = response.data.data;
                _this.dataSource = new MatTableDataSource(response.data.data);
                _this.paginate = response.data;
                _this.total_record = response.data.total;
            }
        });
    };
    return FreelancerDetailsComponent;
}());
export { FreelancerDetailsComponent };
