import { Component, OnInit, Inject } from '@angular/core';
import { VendorService } from 'app/main/Services/vendor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { debug } from 'util';
@Component({
  selector: 'app-add-vendor-doc',
  templateUrl: './add-vendor-doc.component.html',
  styleUrls: ['./add-vendor-doc.component.scss']
})
export class AddVendorDocComponent implements OnInit {
  form: FormGroup;
  file_multiple_data: any = [];
  fileError: string = '';
  fileSuccess: string;
  mainprogressbar: boolean = false;
  uploaded_files: any = [];
  file_id_array: any = [];
  uploaded_files_array: any = [];
  constructor(private _formBuilder: FormBuilder, private toastr: ToastrService, private vendorService: VendorService, public dialogRef: MatDialogRef<AddVendorDocComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      type: ['', Validators.compose([Validators.required])],
      comment: ['']
    });

  }


  // start multiple file upload

  onFileUpload() {
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = "File size should not be more than 20MB.";
            return;
          }
          this.file_multiple_data.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addDocument(this.file_multiple_data);
    };

    fileUpload.click();
  }
  // addDocument
  addDocument(file) {

    this.fileSuccess = '';
    this.mainprogressbar = true;
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append("file[]", file[x]);
      
    }
    uploadData.append("project_reference", this.data.reference_id);
    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploaded_files = response.data;
        this.uploaded_files_array.push(this.uploaded_files);
        response.data.forEach(element => {
          this.file_id_array.push(element.id);
        });
        this.fileSuccess = 'Uploaded successfully!!';
        this.mainprogressbar = false;
      });
  }

  submit() {
    let filedata = this.form.value;
    filedata.file_id = this.file_id_array;
    filedata.vendor_id = this.data.vendor_id;
    this.vendorService.vendorDocSave(filedata)
      .subscribe((response) => {
        this.toastr.success(response.message);
        this.dialogRef.close(true);
      });
  }



}
