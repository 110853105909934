/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./additional-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "../additional-services-mailing-options/additional-services-mailing-options.component.ngfactory";
import * as i5 from "../additional-services-mailing-options/additional-services-mailing-options.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/forms";
import * as i8 from "../../../Services/projects.service";
import * as i9 from "ngx-toastr";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../additional-services-notarization/additional-services-notarization.component.ngfactory";
import * as i12 from "../additional-services-notarization/additional-services-notarization.component";
import * as i13 from "../additional-services-apostile/additional-services-apostile.component.ngfactory";
import * as i14 from "../additional-services-apostile/additional-services-apostile.component";
import * as i15 from "./additional-services.component";
var styles_AdditionalServicesComponent = [i0.styles];
var RenderType_AdditionalServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdditionalServicesComponent, data: {} });
export { RenderType_AdditionalServicesComponent as RenderType_AdditionalServicesComponent };
function View_AdditionalServicesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Mailing Options "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "aText"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getValueMailing; _ck(_v, 2, 0, currVal_0); }); }
function View_AdditionalServicesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Notarization "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "aText"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getValueNotarization; _ck(_v, 2, 0, currVal_0); }); }
function View_AdditionalServicesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Apostile "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "aText"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getValueApostile; _ck(_v, 2, 0, currVal_0); }); }
export function View_AdditionalServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "white-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "mat-tab-group", [["animationDuration", "0ms"], ["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(2, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG]], { animationDuration: [0, "animationDuration"] }, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵeld(4, 16777216, null, null, 7, "mat-tab", [["label", "Mailing Options"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(5, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AdditionalServicesComponent_1)), i1.ɵdid(9, 16384, [[2, 4]], 0, i3.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "app-additional-services-mailing-options", [], null, [[null, "myoutputMailing"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("myoutputMailing" === en)) {
        var pd_0 = (_co.getDataMailing($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AdditionalServicesMailingOptionsComponent_0, i4.RenderType_AdditionalServicesMailingOptionsComponent)), i1.ɵdid(11, 114688, null, 0, i5.AdditionalServicesMailingOptionsComponent, [i6.ActivatedRoute, i7.FormBuilder, i8.ProjectsService, i9.ToastrService, i10.MatDialog], null, { myoutputMailing: "myoutputMailing" }), (_l()(), i1.ɵeld(12, 16777216, null, null, 7, "mat-tab", [["label", "Notarization"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(13, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AdditionalServicesComponent_2)), i1.ɵdid(17, 16384, [[4, 4]], 0, i3.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(18, 0, null, 0, 1, "app-additional-services-notarization", [], null, [[null, "myoutputNotarization"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("myoutputNotarization" === en)) {
        var pd_0 = (_co.getDataNotarization($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_AdditionalServicesNotarizationComponent_0, i11.RenderType_AdditionalServicesNotarizationComponent)), i1.ɵdid(19, 114688, null, 0, i12.AdditionalServicesNotarizationComponent, [i6.ActivatedRoute, i7.FormBuilder, i8.ProjectsService, i9.ToastrService, i10.MatDialog], null, { myoutputNotarization: "myoutputNotarization" }), (_l()(), i1.ɵeld(20, 16777216, null, null, 7, "mat-tab", [["label", " Apostile"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(21, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 6, { templateLabel: 0 }), i1.ɵqud(335544320, 7, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AdditionalServicesComponent_3)), i1.ɵdid(25, 16384, [[6, 4]], 0, i3.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(26, 0, null, 0, 1, "app-additional-services-apostile", [], null, [[null, "myoutputApostile"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("myoutputApostile" === en)) {
        var pd_0 = (_co.getDataApostile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_AdditionalServicesApostileComponent_0, i13.RenderType_AdditionalServicesApostileComponent)), i1.ɵdid(27, 114688, null, 0, i14.AdditionalServicesApostileComponent, [i6.ActivatedRoute, i7.FormBuilder, i8.ProjectsService, i9.ToastrService, i10.MatDialog], null, { myoutputApostile: "myoutputApostile" })], function (_ck, _v) { var currVal_2 = "0ms"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "Mailing Options"; _ck(_v, 5, 0, currVal_3); _ck(_v, 11, 0); var currVal_4 = "Notarization"; _ck(_v, 13, 0, currVal_4); _ck(_v, 19, 0); var currVal_5 = " Apostile"; _ck(_v, 21, 0, currVal_5); _ck(_v, 27, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_AdditionalServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-additional-services", [], null, null, null, View_AdditionalServicesComponent_0, RenderType_AdditionalServicesComponent)), i1.ɵdid(1, 114688, null, 0, i15.AdditionalServicesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdditionalServicesComponentNgFactory = i1.ɵccf("app-additional-services", i15.AdditionalServicesComponent, View_AdditionalServicesComponent_Host_0, {}, {}, []);
export { AdditionalServicesComponentNgFactory as AdditionalServicesComponentNgFactory };
