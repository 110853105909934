import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this.messageSource = new BehaviorSubject('default message');
        this.currentMessage = this.messageSource.asObservable();
        //workflow
        this.workflowSource = new BehaviorSubject('workflow default message');
        this.currentWorkflowMessage = this.workflowSource.asObservable();
    }
    DataService.prototype.changeMessage = function (message) {
        this.messageSource.next(message);
    };
    DataService.prototype.changeWorkflow = function (message) {
        this.workflowSource.next(message);
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
