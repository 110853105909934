<div class="dialog-content-wrapper"  style="position:relative;">
    <div *ngIf='mainprogressbar' class="cssloadcube-overlay">

        <span class="helper"></span>
        <img src="assets/images/preloader_logo.gif" />
    </div>
    <div class="tHeader">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <div fxFlex>
            <h4 class="mt-0"><b>Add File</b></h4>
            </div> 
            <div fxFlex class="text-right">
            <button mat-icon-button (click)="dialogRef.close(false)" aria-label="Close dialog"><mat-icon>close</mat-icon>
            </button>
            </div>
    </div>
</div>
<form  name="form" [formGroup]="form" (ngSubmit)="submit();">
<div class="p-16 m-0">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field   fxFlex>
                    <mat-label>File Category</mat-label>
                        <mat-select  formControlName="file_type_id" >
                            <mat-option [value]="">
                                Select
                            </mat-option>
                            <mat-option *ngFor="let res of File_Type_data" [value]="res.id">
                                {{res.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('file_type_id').invalid && form.get('file_type_id').touched">
                            <span *ngIf="form.get('file_type_id').errors.required">Select file Category!</span>
                        </mat-error>
                </mat-form-field>
                <mat-form-field   fxFlex>
                    <mat-label>Language</mat-label>
                    <mat-select  formControlName="type" >
                        <mat-option [value]="''">
                            Select
                        </mat-option>
                        <mat-option  value="Source">Source</mat-option>
                        <mat-option  value="Target">Target</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field   fxFlex>
                    <mat-label>File notes</mat-label>
                    <input matInput placeholder="Note about files if any">
                </mat-form-field>
            </div>


            <div class="fileUploadWraper">
                <mat-label>Drag & Drop Files Here / Click "Add Files": </mat-label> 
                <button mat-raised-button color="accent" type="button" matTooltip="Upload Document" (click)="onFileUpload()">
                    <i class="material-icons">cloud_upload</i> Add Files
                </button>
                <input type="file" id="docUpload" name="docUpload" style="display:none;" multiple/>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
                    <span style="color: green;">{{fileSuccess}}</span>
                </div>
            </div>

            <div class="box-blank" *ngIf="uploaded_files_array.length>0">
                <p *ngFor="let res of uploaded_files_array">
                    <span *ngFor="let res1 of res">
                            {{res1.name}}
                    </span>
                </p>
            </div>
           
    </div>
    <div class="actWraper" fxLayout="row wrap">
        <button mat-raised-button matTooltip="Apply" type="submit"  [disabled]="(!form.valid) || file_id_array.length==0" class="green-bg">
        <mat-label class="Text_Color">Add</mat-label>
        </button>
    </div>
</form>
  <!-- / CONTENT -->

</div>