import { PmDashboardComponent } from "./pm-dashboard/pm-dashboard.component";
import { ProjectDetailsComponent } from "./project-details/project-details.component";
import { CreateQuoteComponent } from "./create-quote/create-quote.component";
import { ProjectListComponent } from "./project-list/project-list.component";
import { AddFileManagementComponent } from "./project-details/file-management/add-file-management/add-file-management.component";
import { ProjectReleaseComponent } from "./project-details/project-release/project-release.component";
import { QuotesComponent } from "./quotes/quotes.component";
import { FreelancerComponent } from "./freelancer/freelancer.component";
import { FreelancerDetailsComponent } from "./freelancer/freelancer-details/freelancer-details.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AddLeadComponent } from "./add-lead/add-lead.component";
import { HelpComponent } from "./help/help.component";
import { KbComponent } from "./kb/kb.component";
import { SupportComponent } from "./support/support.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { ProjectEmailLogComponent } from "./project-details/project-email-log/project-email-log.component";
import { PmsGuard } from "app/_guards/pms.guard";
import { ToolsComponent } from "./tools/tools.component";
import { SmsComponent } from "./sms/sms.component";
import { WhatsappComponent } from "./whatsapp/whatsapp.component";
import { ReportComponent } from "./report/report.component";
import { BlacklistedComponent } from "./blacklisted/blacklisted.component";
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
};
var routes = [
    {
        path: "pms/dashboard",
        component: PmDashboardComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/details/:id",
        component: ProjectDetailsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/details/create-quote/add-file-management",
        component: AddFileManagementComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/details/create-quote/project-release",
        component: ProjectReleaseComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/projects",
        component: ProjectListComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/addlead",
        component: AddLeadComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/:id/create-quote",
        component: CreateQuoteComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/quotes",
        component: QuotesComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/freelancer",
        component: FreelancerComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/freelancer/details/:id",
        component: FreelancerDetailsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/calendar",
        component: CalendarComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/help",
        component: HelpComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/kb",
        component: KbComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/support",
        component: SupportComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/notifications",
        component: NotificationsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/email-log",
        component: ProjectEmailLogComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/tools",
        component: ToolsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/tools/:id",
        component: ToolsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/sms",
        component: SmsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/report",
        component: ReportComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/closed-blacklisted",
        component: BlacklistedComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/sms/detail/:id",
        component: SmsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/whatsapp",
        component: WhatsappComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/whatsapp/detail/:id",
        component: WhatsappComponent,
        canActivate: [PmsGuard],
    },
];
var ɵ0 = adapterFactory, ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var PmsModule = /** @class */ (function () {
    function PmsModule() {
    }
    return PmsModule;
}());
export { PmsModule };
export { ɵ0, ɵ1 };
