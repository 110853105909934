import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'app/main/shared.service';
import { environment } from 'environments/environment';
import { MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { MatTableDataSource} from '@angular/material';
import { VendorService } from 'app/main/Services/vendor.service';
import { ProjectReleaseComponent } from '../project-details/project-release/project-release.component';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { SpecialityChangeComponent } from '../project-details/speciality-change/speciality-change.component';
import { LanguageChangeComponent } from '../project-details/language-change/language-change.component';
import { DeadlineChangeComponent } from '../project-details/deadline-change/deadline-change.component';
import { UpdateServiceTypeComponent } from '../project-details/update-service-type/update-service-type.component';
@Component({
  selector: 'app-create-quote',
  templateUrl: './create-quote.component.html',
  styleUrls: ['./create-quote.component.scss']
})
export class CreateQuoteComponent implements OnInit {
  project_id: string;
  index_array: number = null;
  project_manager_id: string;
  important_messages_response: any;
  response_data: any;
  is_locked: string;
  locked_by: string;
  response_data_quoteArray: any = [];
  // mainprogressbar: boolean;
  showAddAdditionService: boolean = false;
  languageData: any = [];
  DiscoutPromotion: any = [];
  is_draft: boolean = false;
  user_id: string;
  username: string;
  checkdueTime: boolean;
  version: any = '';
  response_email_data: any = [];
  Form: FormGroup;
  table_data: any = [];
  locked_by_value: any;
  commentForm: FormGroup;
  test: boolean = false;
  Dublicate: boolean = false;
  Finance_update: any = {};
  project_pm_data: any = {};
  comment_data_update: any = {};
  NewAdditionalServicesTotal: number = 0;
  quote_id: any = '';
  due_in: number;
  DueTime: any;
  target_language_select: any = [];
  source_language_select:any= [];
  project_language_id_select: number;
  file_multiple_data: any = [];
  project_quote_additional_service: any = [];
  file_prepration_url: string;
  file_prepration_data: any = [];
  BaseUrl_file: any;
  data_zip_file_prepration: any = {};
  file_prepration_download: string;
  project_type: number;
  estimate_hours: number;
  estimate_mins_project: number;
  count = 0;
  count_test = 0;
  fileSuccess: string;
  fileError: string = '';
  file_id_array: any = [];
  language: any = [];
  languageDataArray: any = [];
  quote_status: any;
  uploaded_files: any = [];
  file_data_save: any = {};
  searchKey: string;
  source_language_value: string;
  source_language_id_value: number;
  target_language_value: string;
  ProjectLanguage_data: any = [];
  ProjectLanguage_data_translator: any = [];
  lang1 = '';
  total_temp: number = 0;
  total_quote_amount: number;
  // lang_combination_id: number;
  specialization_id: number;
  target_language_margin: number;
  target_language_name: string;
  target_language_recomended_rate: number;
  target_language_rate: number;
  DueTime_env: any;
  TimeElapsed: any;
  target_language_show: boolean = false;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  discount_value: number;
  // Additional service 
  additonal_service_array: any = [];
  getValueMailing: string = '(NA)';
  getValueNotarization: string ='(NA)';
  getValueApostile: string ='(NA)';
  displayedColumns_translator = ['ref', 'name', 'specialization', 'overallEvaluation', 'completedJobs', 'BestRateUSD'];
  user_group_id: string;
  language_source_target:string = '';
  public index_id:number;

  dataTypePromotion: any =
    {
      value: ''
    }
  data: any =
    {
      source_lang_id: '',
      service_id: '',
      project_language_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      quantity: '',
      pricing_method: 'Words',
      rate: '',
      sub_total: ''
    };
  today: Date = new Date();
  minDate = this.today;
  dataSource: any;
  dataSource_translator: any;
  isLinear = true;
  first: boolean = false;
  second: boolean = false;
  third: boolean = false;
  source_id:any=[];
  target_id:any=[];
  unique_source_language:any= [];
  unique_target_language:any= [];
  check_discount:boolean = true;
  source_language_array:any= [];
  target_language_array:any= [];
  priceRecomendations: any = {
    rate : '',
    rate_tier2 : '',
    rate_tier3 : '',
    rate_tier4 : '',
    rate_tier5 : '',
  }

  constructor(private route: ActivatedRoute,
    private projectsService: ProjectsService, private _formBuilder: FormBuilder,
    private vendorService: VendorService, private toastr: ToastrService, private router: Router,
    private dialog: MatDialog) {
    this.BaseUrl_file = environment.baseUrl_file;
    this.DueTime_env = environment.Due_time;
  }
  ngOnInit() {
    this.user_id = SharedService.get('id');
    this.username = SharedService.get('username');
    this.user_group_id = SharedService.get('user_group_id');
    this.getLanguage();
    this.project_id = this.route.snapshot.params['id'];
    this.getUniqueProjectLanguage();
    this.getAdditionalServices();
    this.getDiscountTypePromotion();
    this.UpdateProjectPm();
    this.getProjectDetail();
    this.getEmailTemplate();
    this.getProjectLanguageTranslator();
    this.Form = this._formBuilder.group({
      total: ['', Validators.compose([Validators.required])],
      discount_amount: [''],
      quote_subtotal: [''],
      discount: [''],
      email_template_id: ['', Validators.compose([Validators.required])],
      comment: [''],
      delivery_date: ['', Validators.compose([Validators.required])],
    });
    this.dataSource_translator = [
      { 'name': 'Tanika Evans', 'specialization': 'Medical', 'overallEvaluation': '4 Star', 'completedJobs': '42', 'BestRateUSD': '0.7 USD' },
      { 'name': 'Boskfie sebe', 'specialization': 'Medical', 'overallEvaluation': '3 Star', 'completedJobs': '16', 'BestRateUSD': '0.67 USD' },
      { 'name': 'Rakgyioi selo', 'specialization': 'Medical', 'overallEvaluation': '5 Star', 'completedJobs': '6', 'BestRateUSD': '0.48 USD' },
      { 'name': 'Heloer bera', 'specialization': 'Medical', 'overallEvaluation': '1 Star', 'completedJobs': '23', 'BestRateUSD': '0.5 USD' },
      { 'name': 'Tanika Evans', 'specialization': 'Medical', 'overallEvaluation': '4 Star', 'completedJobs': '33', 'BestRateUSD': '0.7 USD' }
    ];
    this.dataSource_translator = new MatTableDataSource(this.dataSource_translator);
    this.dataSource_translator.paginator = this.paginator;
  }
  getImportantNotes(){
    this.projectsService.getImportantNotes(this.project_id)
  .subscribe((response) => {
      if(response) {
        this.important_messages_response=response.data;
  }
  });
  }

  getProjectDetail() {
    this.commentForm = this._formBuilder.group({
      message: ['', Validators.compose([Validators.required])],
      message_type: ['0', Validators.compose([Validators.required])],
    });

    this.projectsService.getProjectDetail(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.response_data = response.data;
          this.version = this.response_data.version;
          this.DiscoutPromotion.forEach(dis_res => {
            if (this.response_data.promo_code == dis_res.coupon) {
              this.discount_value = dis_res.value;
            }
          });
          let dt2 = new Date();
          var datetime = moment(response.data.created_at.date);
          var current = moment(dt2.toISOString(), 'YYYY-MM-DD HH:mm:ss.SSSS');
          var minutes = current.diff(datetime, 'minutes');
          if (minutes > 60) {
            var diff;
            this.TimeElapsed = this.timeConvert(minutes);
            diff = this.DueTime_env - minutes;
            if (diff < 0) {
              this.checkdueTime = false;
              this.DueTime = this.timeConvert(diff);
              this.DueTime = '-' + this.DueTime;
            }
            else {
              this.checkdueTime = true;
              this.DueTime = this.timeConvert(diff);
            }
          }
          else {
            this.TimeElapsed = minutes + ' Mintue(s)';
            this.DueTime = this.DueTime_env - minutes;
            if (this.DueTime < 0) {
              this.checkdueTime = false;
              this.DueTime = this.DueTime_env - minutes + ' Mintue(s)';
            }
            else {
              this.checkdueTime = true;
              this.DueTime = this.DueTime_env - minutes + ' Mintue(s)';
            }
          }
          this.is_locked = this.response_data.is_locked;
          this.locked_by = this.response_data.locked_by;
          this.locked_by_value = this.locked_by;
          if (this.user_id != this.locked_by) {
            this.projectLock();
          }
          this.specialization_id = response.data.specilization_id;
          this.project_type = response.data.service_id;
          if (response.data.projectmanager != null) {
            this.project_manager_id = response.data.projectmanager.data.id;
          }
          this.DownloadZip_file_prepration();
          response.data.projectLanguage.data.forEach(element => {
            this.priceRecomendations.rate = parseFloat(element.targetlanguage.data.rate);
            this.priceRecomendations.rate_tier2 = parseFloat(element.targetlanguage.data.rate_tier2);
            this.priceRecomendations.rate_tier3 = parseFloat(element.targetlanguage.data.rate_tier3);
            this.priceRecomendations.rate_tier4 = parseFloat(element.targetlanguage.data.rate_tier4);
            this.priceRecomendations.rate_tier5 = parseFloat(element.targetlanguage.data.rate_tier5);         
            this.languageDataArray.push(element);
          });
          response.data.projectfile.data.forEach(element => {
            if (element.type == 2) {
              element.file.data.forEach(elm => {
                this.file_prepration_url = this.BaseUrl_file + elm.path;
                this.file_prepration_data.push(elm);
              });
            }
          });

          if (this.response_data.customer != null && this.response_data.deadline_date != null) {
            let dt1 = new Date(this.response_data.customer.data.created_at.date);
            let dt2 = new Date(this.response_data.deadline_date.date);
            this.estimate_hours = this.diff_hours(dt2, dt1);
          }

          if (response.data.projectquote.data.length > 0) {
            response.data.projectquote.data.forEach(first_ele => {
              if (first_ele.status == 5) {
                this.is_draft = true;
              }else{
                  this.is_draft = false;
              }
            });

            response.data.projectquote.data.forEach(ele => {
              // if (this.is_draft !== true) {
              //   this.language = [];
              // }
              if (ele.status != 5) {
                this.language = [];
              }
              this.dataTypePromotion.value = parseInt(ele.discount);
              ele.projectquoteservice.data.forEach(element => {
                element.language = [];
                this.data = {
                  source_lang_id: '',
                  service_id: '',
                  project_language_id: '',
                  sourcelanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  targetlanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  target_lang_id: '',
                  quantity: '',
                  pricing_method: '',
                  rate: '',
                  sub_total: '',
                };
                this.data.quantity = element.quantity;
                this.data.pricing_method = element.pricing_method;
                this.data.rate = element.rate;
                this.data.sub_total = element.subTotal;
                this.data.project_language_id = element.project_language_id;
                element.projectlanguages.data.forEach(element => {
                  this.data.sourcelanguage.data.lang_name = element.sourcelanguage.data.lang_name;
                  this.data.targetlanguage.data.lang_name = element.targetlanguage.data.lang_name;
                  this.data.source_lang_id = element.sourcelanguage.data.id;
                  this.data.target_lang_id = element.targetlanguage.data.id;
                  this.data.service_id = this.project_type;
                });
                if (this.is_draft == true) {
                  if (ele.status == 5) {
                    this.data.id = element.id;
                    this.data.quote_id = element.quote_id;
                    this.quote_id = element.quote_id;
                    this.language.push(this.data);
                    this.Form.patchValue({
                      total: ele.total,
                      quote_subtotal: ele.quote_subtotal,
                      discount: ele.discount,
                      discount_amount: ele.discount_amount,
                      email_template_id: ele.email_template_id
                    });
                  }
                } else {
                  this.language.push(this.data);
                  this.Form.patchValue({
                    total: ele.total,
                    quote_subtotal: ele.quote_subtotal,
                    discount: ele.discount,
                    discount_amount: ele.discount_amount
                  });
                }
              });
            });

            this.dataSource = new MatTableDataSource(this.language)
            this.count_test = this.language.length;
            this.response_data_quoteArray = response.data.projectquote.data;
            this.response_data_quoteArray.forEach(res => {
              let is_active_class = '';
              if (res.is_active == 0) {
                is_active_class = 'quote_inactive';
              }
              else if (res.is_active == 1) {
                is_active_class = 'quote_active';
              }
              res.is_active_class = is_active_class;
              this.quote_status = res.status;
              if (this.quote_status == 2 || this.quote_status == 3) {
                this.first = true;
              } else if (this.quote_status == 4) {
                this.first = true;
                this.second = true;
              }
              if (this.quote_status == 1 || this.quote_status == 2 || 
                this.quote_status == 3 || this.quote_status == 5) {
                this.data =
                  {
                    source_lang_id: this.source_language_id_value,
                    service_id: '',
                    project_language_id: '',
                    sourcelanguage: {
                      data: {
                        lang_name: this.source_language_value
                      }
                    },

                    targetlanguage: {
                      data: {
                        lang_name: ''
                      }
                    },
                    target_lang_id: '',
                    quantity: '',
                    pricing_method: 'Words',
                    rate: '',
                    sub_total: ''
                  };
              }
            });
          }
        }
      });
  }

  diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }

  getEmailTemplate() {
    this.projectsService.getEmailTemplate()
      .subscribe((response) => {
        if (response) {
          if(response.data.length>0){
            response.data.forEach(element => {
              if(element.is_active==1){
                this.response_email_data.push(element);
              }
              
            });
          }
        }
      });
  }

  /*
  * Function name Submit
  * Description: Save quote and save draft quote here
  * Param: type (draft or not)
  * save_status: 1  draft, 2 update draft quote
  */
  submit(type: any) {
     this.Finance_update={};
    this.Finance_update = this.Form.value;
    this.Finance_update.delivery_date = this.dateFormat(this.Form.value.delivery_date);
    if (type == 'draft') {
      this.Finance_update.save_status = 1;
    } else {
      this.Finance_update.save_status = 2;
    }

    this.Finance_update.project_id = this.project_id;
    this.Finance_update.discount_title = 'discount';
    this.Finance_update.project_quote_service = this.language;
    this.Finance_update.project_quote_additional_service = this.project_quote_additional_service;
    //Send draft to update query here
      // if (type == 'draft') {
        if (type == 'draft' && this.is_draft == true) {
      this.Finance_update.save_status = 1;
      this.Finance_update.status = 5;
      this.Finance_update.is_active = true;
      this.Finance_update.project_manager_id = this.project_manager_id;
      
      this.projectsService.sendDraftProjectQuote(this.quote_id, this.Finance_update)
        .subscribe((response) => {
          if (response) {
            this.router.navigate(['/pms/project/details/' + this.project_id]);
            this.toastr.success('Quote save as draft again');
          }
        }, (error) => {
          this.toastr.error(error.error.message);
        });
      //Save draft OR send New quote here
    } else if(type == 'draft' && this.is_draft == false) {
      let data:any={
        mail_response:{
            to:'',
            cc:'',
             bcc:'',
            message:'',
            subject:''
          }
      };
      data = this.Finance_update;
      this.projectsService.sendProjectQuote(data)
      .subscribe((response:any) => {
          if(response) {
            this.router.navigate(['/pms/project/details/'+this.project_id]);
            this.toastr.success('Quote save as draft');
          }
      }, (error) => {
          this.toastr.error(error.message);
      });
    }
    // create quote
    if(type == 'not_draft' ) {
      this.openDialogEmailPreview(this.Finance_update);
    }
  }
  
  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + '-' + month + '-' + dt + ' 00:00:00';
  }

  UpdateProjectPm() {
    this.project_pm_data.project_id = this.project_id;
    this.projectsService.UpdateProjectPm(this.project_pm_data)
      .subscribe((response) => {
        if (response) {
        }
      }, (error) => {
        this.toastr.error(error.message);
      });
  }

  submitComment() {
    this.comment_data_update.message_type = this.commentForm.value.message_type;
    this.comment_data_update.message = this.commentForm.value.message;
    this.comment_data_update.from_user_id = this.user_id;
    this.projectsService.updateComment(this.project_id, this.comment_data_update)
      .subscribe((response) => {
        if (response) {
          if (response.data.mail_response) {
            this.toastr.success(response.data.mail_response);
          }
          else {
            this.toastr.success('Updated');
          }
          this.getProjectDetail();
          this.getEmailTemplate();
        }
      });
  }



  Total_cost_new() {
    if (this.data.quantity < 0) {
      this.toastr.error('Quantity must be positive');
      return;
    }
    if (this.data.quantity % 1 != 0) {
      this.toastr.error('Quantity never be floating number');
      return;
    }
    if (this.data.rate < 0) {
      this.toastr.error('Rate must be positive');
      return;
    }
    // rate according to quantity start
    let recomended_rate;
    this.languageDataArray.forEach(elem => {
      if (this.data.quantity >environment.rate && this.data.quantity <=environment.rate_tier2 ) {
        this.target_language_rate= parseFloat(elem.targetlanguage.data.rate);
      }else if (this.data.quantity >environment.rate_tier2 && this.data.quantity <=environment.rate_tier3 ) {
        this.target_language_rate= parseFloat(elem.targetlanguage.data.rate_tier2);
      }else if (this.data.quantity >environment.rate_tier3 && this.data.quantity <=environment.rate_tier4 ) {
        this.target_language_rate= parseFloat(elem.targetlanguage.data.rate_tier3);
      }else if (this.data.quantity >environment.rate_tier4 && this.data.quantity <=environment.rate_tier5 ) {
        this.target_language_rate= parseFloat(elem.targetlanguage.data.rate_tier4);
      }else if (this.data.quantity >environment.rate_tier5) {
        this.target_language_rate= parseFloat(elem.targetlanguage.data.rate_tier5);
      }
      this.target_language_margin = elem.targetlanguage.data.margin;
      recomended_rate = this.target_language_rate + ((this.target_language_rate * this.target_language_margin) / 100);
      this.target_language_recomended_rate = this.roundToTwo(recomended_rate);
    });
// rate according to quantity end
    if (this.data.quantity != '' && this.data.rate != '') {
      this.data.sub_total = (this.data.quantity * this.data.rate).toFixed(2) ;
    }
    else {
      this.data.sub_total = '';
    }
  }

  Discount_total() {
    let total;
    let discount_amt;
    let quote_subtotal = 0;
    this.target_language_show = false;
    this.language = this.dataSource.data;
    this.language.forEach(element => {
      quote_subtotal += parseFloat(element.sub_total)
    });
    this.NewAdditionalServicesTotal = this.roundToTwo(this.NewAdditionalServicesTotal);
    quote_subtotal = this.roundToTwo(quote_subtotal + this.NewAdditionalServicesTotal);
    discount_amt = quote_subtotal * (this.Form.value.discount / 100);
    discount_amt = discount_amt.toFixed(2);
    total = quote_subtotal - discount_amt;
    total = total.toFixed(2);
    this.Form.patchValue({
      total: total,
      quote_subtotal: quote_subtotal,
      discount_amount: discount_amt
    });
  }

  Discount_total_old() {
    let final_value;
    let discount_amt;
    this.target_language_show = false;
    this.language = this.dataSource.data;
    let subtotal_test = 0;
    this.language.forEach(element => {
      subtotal_test += parseFloat(element.sub_total)
    });
    subtotal_test = this.roundToTwo(subtotal_test);
    discount_amt = subtotal_test * (this.Form.value.discount / 100);
    discount_amt = discount_amt.toFixed(2);
    final_value = subtotal_test - discount_amt;
    final_value = this.roundToTwo(final_value);
    this.NewAdditionalServicesTotal = this.roundToTwo(this.NewAdditionalServicesTotal);
    final_value = this.roundToTwo(final_value + this.NewAdditionalServicesTotal);
    subtotal_test = this.roundToTwo(subtotal_test + this.NewAdditionalServicesTotal);
    this.Form.patchValue({
      total: final_value,
      quote_subtotal: subtotal_test,
      discount_amount: discount_amt
    });
  }

  DownloadZip_file_prepration() {
    this.data_zip_file_prepration.project_id = this.project_id;
    this.data_zip_file_prepration.type = 2;
    this.projectsService.downloadZip(this.data_zip_file_prepration).subscribe(
      response => {
        if (response) {
          this.file_prepration_download = response.url;
        }
      }
    );
  }

  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res:any) => {
        if (res) {
          this.languageData = res.data;
        }
      });
  }

  getSourceName(evt) {
    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        this.data.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  PricingType(evt) {
    this.data.pricing_method = evt.value;
  }

  getTargetName(evt) {
    if (evt.value == '') {
      this.target_language_show = false;
    } else {
      this.target_language_show = true;
    }
    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        let recomended_rate;
        this.data.targetlanguage.data.lang_name = element.lang_name;
        this.target_language_name = element.lang_name;
        this.target_language_margin = element.margin;
        this.target_language_rate = parseFloat(element.rate);
        recomended_rate = this.target_language_rate + ((this.target_language_rate * this.target_language_margin) / 100);
        this.target_language_recomended_rate = this.roundToTwo(recomended_rate);
      }
    });
    this.languageDataArray.forEach(elem => {
      if ((elem.targetlanguage.data.lang_name == this.data.targetlanguage.data.lang_name)
      && (elem.sourcelanguage.data.lang_name == this.data.sourcelanguage.data.lang_name)) {
        this.data.project_language_id = elem.id;
      }
    });
  }

  addLanguageTable() {
    
    if (this.data.quantity % 1 != 0) {
      this.toastr.error('Quantity never be floating number');
      return;
    }
    this.Dublicate = false;
    this.language.forEach(element => {
      if ((this.data.targetlanguage.data.lang_name == element.targetlanguage.data.lang_name)
      && (this.data.sourcelanguage.data.lang_name == element.sourcelanguage.data.lang_name)) {
        this.Dublicate = true;
      }
    });

    if (this.index_array != null) {
      this.Dublicate = false;
      if (this.data.sourcelanguage.data.lang_name == '') {
        this.toastr.error('please select source language');
        return
      }
      if (this.data.targetlanguage.data.lang_name == '') {
        this.toastr.error('please select target language');
        return
      }

      if (this.data.pricing_method == '') {
        this.toastr.error('please select pricing method');
        return
      }

      if (this.data.quantity == '' || this.data.quantity==null) {
        this.toastr.error('please select Quantity');
        return
      } else if (this.data.quantity < 0) {
        this.toastr.error('Quantity must be positive');
        return;
      }
      if (this.data.rate == ''  || this.data.rate==null) {
        this.toastr.error('please select rate');
        return;
      }
      else if (this.data.rate < 0) {
        this.toastr.error('Rate must be positive');
        return;
      }

      if (this.data.sub_total == '') {
        this.toastr.error('please select sub_total');
        return
      }

      this.count_test = this.count_test + 1;
      this.language.forEach((element, ind) => {
        if (this.index_array != ind) {
          if ((this.data.targetlanguage.data.lang_name == element.targetlanguage.data.lang_name)
            && (this.data.sourcelanguage.data.lang_name == element.sourcelanguage.data.lang_name)) {
            this.Dublicate = true;
          }
        }
      });

      if (this.Dublicate) {
        this.toastr.error('Please select unique language pair!!');
        return;
      }
      this.dataSource.data[this.index_array] = this.data;
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      this.data.service_id = this.project_type;
      if (this.Form.value.discount) {
        // this.count_test = this.count_test + 1;
    
        this.Discount_total();
      } else {
        this.language = this.dataSource.data;
        // this.count_test = this.count_test + 1;
        let subtotal_test = 0;
        this.language.forEach(element => {
          subtotal_test += parseFloat(element.sub_total);
        });
        let total;
        total = this.roundToTwo(subtotal_test + this.NewAdditionalServicesTotal);
        this.Form.patchValue({
          total: total,
          quote_subtotal: total
        });
      }
      this.index_array = null;
      this.data = {
        source_lang_id: this.data.source_lang_id ,
        service_id: '',
        project_language_id: this.data.project_language_id ,
        sourcelanguage: {
          data: {
            lang_name: this.data.sourcelanguage.data.lang_name
          }
        },
        targetlanguage: {
          data: {
            lang_name: this.data.targetlanguage.data.lang_name
          }
        },
        target_lang_id: this.data.target_lang_id,
        quantity: this.data.quantity,
        pricing_method: 'Words',
        rate: this.data.rate,
        sub_total: this.data.sub_total,
      };
    }
    else {
      if (this.Dublicate == false) {
        if (this.data.sourcelanguage.data.lang_name == '') {
          this.toastr.error('please select source language');
          return
        }
        if (this.data.targetlanguage.data.lang_name == '') {
          this.toastr.error('please select target language');
          return
        }
        if (this.data.pricing_method == '') {
          this.toastr.error('please select pricing method');
          return
        }
        if (this.data.quantity == '' || this.data.quantity==null) {
          this.toastr.error('please select Quantity');
          return
        } else if (this.data.quantity < 0) {
          this.toastr.error('Quantity must be positive');
          return;
        }
        if (this.data.rate == '' || this.data.rate==null) {
          this.toastr.error('please select rate');
          return
        }
        else if (this.data.rate < 0) {
          this.toastr.error('Rate must be positive');
          return;
        }
        if (this.data.sub_total == '') {
          this.toastr.error('please select sub_total');
          return
        }
        this.count_test = this.count_test + 1;
        this.dataSource = [];
        this.data.service_id = this.project_type;
        this.language.push(this.data);
        let subtotal_test = 0;
        this.language.forEach(element => {
          subtotal_test += parseFloat(element.sub_total)
        });
        let total;
        total = this.roundToTwo(subtotal_test + this.NewAdditionalServicesTotal);
        this.Form.patchValue({
          total: total,
          quote_subtotal: total
        });
        this.dataSource = new MatTableDataSource(this.language);
        this.data = {
          source_lang_id: '',
          service_id: '',
          project_language_id: this.project_language_id_select,
          sourcelanguage: {
            data: {
              lang_name: this.source_language_value
            }
          },
          targetlanguage: {
            data: {
              lang_name: ''
            }
          },
          target_lang_id: '',
          quantity: '',
          pricing_method: 'Words',
          rate: '',
          sub_total: '',
        };
      } else {
        this.toastr.error('Please select unique language pair!!');
        return;
      }
    }
  }

  showAddAdditionalServices() {
    this.showAddAdditionService = true;
  }

  showAddAdditionalServices_click() {
    this.index_id=0;
    this.showAddAdditionService = true;
  }
  //get child component data start
  getDataMailing(value:any) {
    this.index_id=0;
    if(value.title=='(Applied)'){
      this.getValueMailing = value.title;
    }else{
      this.getValueMailing = '(NA)';
    }
   
    
   if(value.click_value==1){
    this.showAddAdditionalServices();
    let top_additional_service = document.getElementById('top_additional_service');
          if (top_additional_service != null) {
            top_additional_service.scrollIntoView();
            top_additional_service = null;
          }
        
   }
     
    

  }

  getDataNotarization(value:any) {
    // this.getValueNotarization = value;
    this.index_id=1;
    if(value.title=='(Applied)'){
      this.getValueNotarization = value.title;
    }else{
      this.getValueNotarization = '(NA)';
    }
   
  
   if(value.click_value==1){
    this.showAddAdditionalServices();
    let top_additional_service = document.getElementById('top_additional_service');
          if (top_additional_service != null) {
            top_additional_service.scrollIntoView();
            top_additional_service = null;
          }
        
   }
  }

  getDataApostile(value) {
    this.index_id=2;
    // this.getValueApostile = value;
    if(value.title=='(Applied)'){
      this.getValueApostile = value.title;
    }else{
      this.getValueApostile = '(NA)';
    }
   
   if(value.click_value==1){
    this.showAddAdditionalServices();
    let top_additional_service = document.getElementById('top_additional_service');
          if (top_additional_service != null) {
            top_additional_service.scrollIntoView();
            top_additional_service = null;
          }
        
   }
  }

  // edit finance table
  editData(value:any, index:any) {
    
    if (this.count_test <= this.count) {
      this.count_test = this.count_test - 1;
    }
    this.data.source_lang_id = value.source_lang_id;
    this.data.target_lang_id = value.target_lang_id;
    this.data.project_language_id = value.project_language_id;
    this.data.targetlanguage.data.lang_name = value.targetlanguage.data.lang_name;
    this.data.sourcelanguage.data.lang_name= value.sourcelanguage.data.lang_name;
    this.data.rate = value.rate;
    this.data.pricing_method = value.pricing_method;
    this.data.quantity = value.quantity;
    this.data.sub_total = value.sub_total;
    this.index_array = index;
  }

  roundToTwo(num) {
    return Math.round(num * 100) / 100;
  }

  //multiple file upload
  onFileUpload() {
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip','txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = 'File size should not be more than 20MB.';
            return;
          }
          this.file_multiple_data.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addDocument(this.file_multiple_data);
    };
    fileUpload.click();
  }

  addDocument(file) {
    this.fileSuccess = '';
    const uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
    }
    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploaded_files = response.data;
        response.data.forEach(element => {
          this.file_id_array.push(element.id);
        });
        this.fileSuccess = 'Uploaded successfully!!';
      });
  }


  //Project File prepration Save
  ProjectFileSave() {
    this.file_data_save.project_id = this.project_id;
    this.file_data_save.file_id = this.file_id_array;
    this.file_data_save.type = 2;
    this.projectsService.projectFileSave(this.file_data_save).subscribe(
      response => {
        if (response) {
          this.getProjectDetail();
        }
      }
    );

  }


  getUniqueProjectLanguage() {
    let data:any={
      project_id: this.project_id
    }
    this.count = 0;
    this.source_language_array = [];
    this.target_language_array = [];
    this.target_language_value = '';
    this.source_language_value = '';
    this.source_language_select = [];
    this.target_language_select = [];
    this.projectsService.getUniqueProjectLanguage(data)
      .subscribe((response: any) => {
        if (response) {
          this.source_language_array=response.source_language_name;
          this.target_language_array=response.target_language_name;
          this.count += this.source_language_array.length;
          this.count *= this.target_language_array.length;
          this.source_language_value = this.getLanguageName(this.source_language_array);
          this.target_language_value = this.getLanguageName(this.target_language_array);
          this.source_language_select = this.source_language_array;
          this.target_language_select = this.target_language_array;
        }
      }, (error) => {
        this.toastr.error('Language pair not found!');
  
      });
  }

  getLanguageName(res: any) {
    let lang = '';
    if(res.length>0){
      res.forEach(element => {
            lang += element.lang_name + ', ';
        });
    }
    lang = lang.slice(0, -2);
    return lang;
  }


  getLanguageArray(uniqueLanguage:any){
 
    let lang_array = [];
    let obj: any = {
      id: Number,
      lang_name: String
    };
    this.languageData.forEach(target_element => {
      uniqueLanguage.forEach(elm => {
        if(elm==target_element.lang_name){
          obj.id = target_element.id;
          obj.lang_name = target_element.lang_name;
          lang_array.push(obj);
          obj = {
            id: '',
            lang_name: ''
          };
        
        }
      });
     
    });
    return  lang_array;
  }


  // Get Project Language for translator table
  getProjectLanguageTranslator() {
    this.projectsService.ProjectTranslator(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.ProjectLanguage_data_translator = response.data;
          let obj: any = {
            specilization_id: Number,
            source_language_id: Number,
            target_language_id: Number
          };
          this.ProjectLanguage_data_translator.forEach(element => {
            obj.specilization_id = this.specialization_id;
            obj.source_language_id = element.source_language_id;
            obj.target_language_id = element.target_language_id;
            this.projectsService.ProjectTranslator(obj)
              .subscribe((response) => {
                if (response) {
                }
              }, (error) => {
                this.toastr.error(error.message);
              });
          });
        }
      });
  }


  Cancel() {
    this.router.navigate(['/pms/project/details/' + this.project_id]);
  }

  projectLock() {
    let data: any = {
      project_id: '',
      is_locked: false
    }
    data.project_id = this.project_id;
    data.is_locked = true;
    this.projectsService.projectLock(data)
      .subscribe((response) => {
        if (response) {
        }
      });
  }


  // pop-up 

  projectRelease() {
    const dailogRef = this.dialog.open(ProjectReleaseComponent, {
      width: '500px',
      data: { project_id: this.project_id }
    })

    dailogRef.afterClosed().subscribe((response) => {
      if (response) {
      }
    });
  }

  /// Discount Type
  DiscountType(evt: any) {
      if(evt.value==0 ){
        this.check_discount=false;
        this.Form.patchValue({
          discount: evt.value
        });
        this.Discount_total();
      }
      
      if(evt.value==undefined ){
      this.check_discount=true;
        this.Form.patchValue({
          discount: 0
        });
        this.Discount_total();
       
      }

      if(evt.value!=undefined && evt.value!=0 ){
        this.check_discount=true;
        this.Form.patchValue({
          discount: evt.value
        });
        this.Discount_total();
      }
    }
   
  

  DiscountType_input() {
    if(this.Form.value.discount<0){
      this.toastr.error('Discount never be negative');
      return;
    }
  
    if(this.Form.value.discount==0){
      this.Form.patchValue({
        discount: 0
      });
      this.Discount_total();
    }
    else{
      this.Form.patchValue({
        discount: this.Form.value.discount
      });
      this.Discount_total();
    }
   
  }

  // Discount type promotion
  getDiscountTypePromotion() {
    this.projectsService.getDiscountTypePromotion()
      .subscribe((res) => {
        if (res) {
          this.DiscoutPromotion = res.data;
        }
      }, (error) => {
        this.toastr.error('Something went wrong, Please try later!');
      });
  }

  // calulate time from minute
  timeConvert(n) {
    if (n < 0) {
      n = -1 * n;
    }
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var days = (hours / 24);
    var rdays = Math.floor(days);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + ' hour(s) and ' + rminutes + ' minute(s)';
  }

  // tslint:disable-next-line: typedef
  openDialogEmailPreview(res: any) {
    let data: any;
    data = res;
    const dailogRef = this.dialog.open(EmailPreviewComponent, {
            data: data
    });
    dailogRef.afterClosed().subscribe((response) => {
    });
  }
  // get Additional Services
  getAdditionalServices() {
    this.projectsService.getAdditionalServices(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.additonal_service_array = response.project_addition_service;
          this.NewAdditionalServicesTotal = 0;
          if (this.additonal_service_array.length > 0) {
            this.project_quote_additional_service = [];
            this.additonal_service_array.forEach(element => {
              let obj: any =
              {
                service_id: element.additional_service.service_id,
                additional_service_id: element.additional_service_id,
                title: element.additional_service.title,
                quantity: element.quantity,
                rate: element.price,
                sub_total: element.sub_total,
                additional_info: null
              }
              this.NewAdditionalServicesTotal += parseFloat(element.sub_total);
              this.project_quote_additional_service.push(obj);
            });
            this.NewAdditionalServicesTotal = this.roundToTwo(this.NewAdditionalServicesTotal);
          }
        }
      });
  }

  // Delete Additional Service
  removeAdditionalService(res: any) {
    this.projectsService.deleteProjectAdditionalService(res.id).subscribe(
      (response) => {
        if(response){
           this.toastr.success(' Delete Project Additional Service  Successfully!!');
        let quote_subtotal = this.roundToTwo(this.Form.value.quote_subtotal - res.sub_total);
        let discount_amt=0;
        if(this.Form.value.discount){
          discount_amt = quote_subtotal * (this.Form.value.discount / 100);
          discount_amt = this.roundToTwo(discount_amt);
        }
        let final__total = quote_subtotal - discount_amt;
        final__total = this.roundToTwo(final__total);
        this.Form.patchValue({
          total: final__total,
          quote_subtotal: quote_subtotal,
          email_template_id: ['', Validators.compose([Validators.required])],
          delivery_date: ['', Validators.compose([Validators.required])],
          discount_amount: discount_amt
        });
        this.getAdditionalServices();
        }
      }
    );
  }

   // popup speciality
   openDialog() {      
    const dailogRef = this.dialog.open(SpecialityChangeComponent, {
        width: '400px',
        data: this.project_id
    });
      dailogRef.afterClosed().subscribe((response) => {
      this.getProjectDetail();
    });
  }

  // popup Change Language
  openDialogLanguage() {
    const dailogRef = this.dialog.open(LanguageChangeComponent, {
      width: '450px',
      data: this.project_id
    });
    dailogRef.afterClosed().subscribe((response) => {
   
      if(response){
        this.getProjectDetail();
        this.getUniqueProjectLanguage();
      }
    
    });
  }

  
     // popup Change Deadline
     openDialogDeadline() {
      let data={
        project_id: this.project_id,
        due_date: ''

      }
      if(this.response_data.due_date.date){
       data.due_date=this.response_data.due_date.date;
      }
     
     const dailogRef = this.dialog.open(DeadlineChangeComponent, {
       width: '350px',
       data: data
     });
     dailogRef.afterClosed().subscribe((response) => {
     
       if(response){
         
         this.getProjectDetail();
       }
     
     });
   }

       // popup Update service type
       openDialogServiceType() {
        let data={
          project_id: this.project_id,
          translation_type: ''
 
        }
        if(this.response_data.translation_type){
         data.translation_type = this.response_data.translation_type;
        }
       
       const dailogRef = this.dialog.open(UpdateServiceTypeComponent, {
         width: '350px',
         data: data
       });
       dailogRef.afterClosed().subscribe((response) => {
       
         if(response){
           
           this.getProjectDetail();
         }
       
       });
     }
}
