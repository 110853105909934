import { Component, OnInit } from '@angular/core';
import { VendorService } from 'app/main/Services/vendor.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { EditTemplateMasterComponent } from './edit-template-master/edit-template-master.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
@Component({
  selector: 'app-template-master',
  templateUrl: './template-master.component.html',
  styleUrls: ['./template-master.component.scss']
})
export class TemplateMasterComponent implements OnInit {
  displayedColumns: string[] = ['position', 'title', 'desc','email_from','email_to','status','action'];
  dataSource: any;
  paginate: any = {};
  pageSize: number = 10;
  pageIndex: number = 1;
  config: any;
  Form: any;
  filterStatus: any = '';
  filterSearchQuery: any = '';
  templateType : any = [];
  constructor(private vendorService: VendorService,private router: Router,
    private toastr: ToastrService, private dialog: MatDialog, private _formBuilder: FormBuilder) { }

  ngOnInit() { 
    let query = ''; 
    this.getAllTemplate(query);
    this.getTemplateType();
    this.config = {
      width: '100%',
      height: '155px',
      toolbar: [
        { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
        { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
        { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
        '/',
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
        { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
        '/',
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        { name: 'about', items: [ 'About' ] }
      ]
      // toolbar: [
      //     { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
      //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }
      // ]
    };

    this.Form = this._formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
      subject: ['', Validators.compose([Validators.required])],
      ckeditorContent: [''],
      status:[0],
      email_from: [''],
      email_to: [''],
      type : ['']
    });

  }

  getAllTemplate(query) {
    let template_pagination = localStorage.getItem('pagination');
    let templateObject = JSON.parse(template_pagination);
    
    if(template_pagination !== null && templateObject.table_name == 'template'){
      query = '?page=' + templateObject.page + '&per_page=' +  templateObject.per_page;
    }else{
       query = '?page=1&per_page=10';

    }
    this.vendorService.getAllTemplate(query)
      .subscribe((res: any) => {
        if (res) {
          this.dataSource = new MatTableDataSource(res.data);
          this.paginate = res.meta.pagination;
        }
      });
  }

  paginateData(event) {
    let pQuery = '';
    let query_projects= '';
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    query_projects = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;

    if ((this.filterStatus != '' && this.filterStatus !== undefined)) {
    
      query_projects += '&is_active=' + this.filterStatus;
    }
    else if (this.filterStatus == '') {
      this.filterStatus = '';
    }
    else if (this.filterStatus !== '') {
      query_projects += '&is_active=' + this.filterStatus;
    }

    if ((this.filterSearchQuery != '' && this.filterSearchQuery !== undefined)) {
      query_projects += '&search_text=' + this.filterSearchQuery;
     }
    else if(this.filterSearchQuery == ''){
      this.filterSearchQuery = '';
    }
    else if(this.filterSearchQuery!==''){
      query_projects += '&search_text=' + this.filterSearchQuery;
    }

    var templateObject = {table_name: 'template' ,page: this.pageIndex, per_page: this.pageSize};
    SharedService.set(SharedService.pagination, JSON.stringify(templateObject));

     pQuery += query_projects;
    this.vendorService.getAllTemplate(pQuery)
      .subscribe((response: any) => {
        if (response) {
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
        }
      });
  }

  openDialog(res: any) {
    let data: any;
    data = res;
    const dailogRef = this.dialog.open(EditTemplateMasterComponent, {
      width: '1050px',
      data: data

    });
    dailogRef.afterClosed().subscribe((response) => {
      let query_projects = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
      this.getAllTemplate(query_projects);

    });
  }

  submit() {
    if (this.Form.value.ckeditorContent == '') {
      this.toastr.error('Template body never be empty!!');
      return;
    }
    let data: any = {
      name: '',
      subject: '',
      body: '',
      is_active: '',
      email_from: '',
      email_to : '',
      type : ''
    };
    data.name = this.Form.value.title;
    data.subject = this.Form.value.subject;
    data.body = this.Form.value.ckeditorContent;
    data.is_active = this.Form.value.status;
    data.email_from =  this.Form.value.email_from;
    data.email_to = this.Form.value.email_to;
    data.type = this.Form.value.type;
    this.vendorService.addTemplateMaster(data).subscribe(
      resposne => {
        if (resposne) {
          const query = '';
          this.Form.patchValue({
            title: '',
            subject: '',
            status: 0,
            ckeditorContent: ''
          });    
          this.getAllTemplate(query);
       this.toastr.success('Template Master added successfully');
        }
      }
    );
  }

  back(){
    window.history.back();
  }
  onFilter(evt, type) {
    let tQuery = '?page=1&per_page=10';
      //  search filter
    if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
      this.filterStatus = evt.value;
      tQuery += '&is_active=' + this.filterStatus;
    }
    else if (type == 'status' && evt.value == '') {
      this.filterStatus = evt.value;
    }
    else if (this.filterStatus !== '') {
      tQuery += '&is_active=' + this.filterStatus;
    }

    if ((type == 'search' && evt != '' && evt !== undefined)) {
      this.filterSearchQuery = evt;
      tQuery += '&search_text=' + this.filterSearchQuery;
     }
    else if(type == 'search' && evt == ''){
      this.filterSearchQuery = evt;
    }
    else if(this.filterSearchQuery!==''){
      tQuery += '&search_text=' + this.filterSearchQuery;
    }

    let query = tQuery;
    this.vendorService.getAllTemplate(query)
      .subscribe((response: any) => {
        if (response) {
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
        }
      });
  }

  getTemplateType() {
    const query = '';
    this.vendorService.getTemplateType(query)
      .subscribe((res: any) => {
        if (res) {
          this.templateType = res;
        }
      });
  }
}
