<div class="white-box mt-12">
        <div class="tHeader">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex>
              <h2>Project Email Logs</h2>
            </div>          </div>
        </div>

        <div class="boxBody">
          <div class="boxInn" *ngIf="paginate.total>0">
            <table mat-table [dataSource]="dataSource" class="table">
          
              <ng-container matColumnDef="subject">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32"> Subject</mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="32">
                  <p class="text-truncate" (click)="openDialogEmail(product?.emaillog?.data?.id)" style="cursor: pointer;">
                    <a>{{product?.emaillog?.data?.subject}}</a>
                  </p>
                </mat-cell> 
              </ng-container>

         
              <ng-container matColumnDef="to">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32"> To 
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="32">
                  <p class="text-truncate">{{product?.emaillog?.data?.to}}</p>

                </mat-cell>
              </ng-container>

             
              <ng-container matColumnDef="sent_on">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16">Sent on
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
                  <p class="text-truncate">{{product?.emaillog?.data?.created_at?.date  | date:'yyyy-MM-dd' }}</p>

                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16">Status
                </mat-header-cell>
                <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
                  <p class="text-truncate">{{product?.emaillog?.data?.status }}</p>

                </mat-cell>
              </ng-container>

            
              
              <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

              <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
              </mat-row>
            </table>

            <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
              [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
              [pageSizeOptions]="[5,10,20,30,40,50,60]" (page)='paginateData($event)'>
            </mat-paginator>
           
          </div>
          <div *ngIf="paginate.total==0" class="noDataFound"> No data available!!
          </div>
        </div>
      </div>