import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/main/Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from 'app/main/shared.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
    type: string = 'Add';
    userForm: FormGroup;
    id: string;
    selected_portal_access: any = [];
    portal_access_update: any = [];
    data: any = {};
    fileError: string = '';
    fileSuccess: string;
    vendor_file_data: string;
    profile_file_id: number = 0;
    BaseUrl_file: any;
  
    file_multiple_data: any = [];

    uploaded_files: any = [];
    constructor(
        private _formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private FuseProgressBarService: FuseProgressBarService,
        private toastr: ToastrService,
        private SharedService: SharedService
    ) {
        this.BaseUrl_file = environment.baseUrl_file;
    }

    ngOnInit() {
        //  this.id= this.route.snapshot.params['id'];
        this.id = this.route.snapshot.params['id'];
        if (this.id) {
            this.getUserData();

            this.userForm = this._formBuilder.group({
                fullname: ['', [Validators.required, Validators.minLength(4)]],
                email: ['', [Validators.required, Validators.email]],
                password: [''],
                username: ['', [Validators.required, Validators.minLength(3)]],
                user_group_id: ['Admin'],
                status: ['1'],
                send_detail: [],
            });
        } else {
            this.userForm = this._formBuilder.group({
                fullname: ['', [Validators.required, Validators.minLength(4)]],
                email: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required, Validators.minLength(6)]],
                username: ['', [Validators.required, Validators.minLength(3)]],
                user_group_id: ['Admin'],
                status: ['1'],
                send_detail: [],
            });
        }
    }

    get fullname() {
        return this.userForm.get('fullname');
    }

    get f() {
        return this.userForm.controls;
    }

    getUserData() {
        this.userService.getUser(this.id).subscribe(
            (response) => {
                if (response) {
                    this.type = 'Update';
                    response.data.user_portal.forEach((element) => {
                        this.selected_portal_access.push(element.id);
                    });
                    if (response.data.profile_file_id != null) {
                        this.profile_file_id = response.data.profile_file_id.id;
                        this.vendor_file_data =
                            this.BaseUrl_file +
                            response.data.profile_file_id.path;
                    }
                    this.userForm.patchValue({
                        fullname: response.data.fullname,
                        email: response.data.email,
                        username: response.data.user_name,
                        user_group_id: response.data.user_group_id,
                        status: response.data.status.toString(),
                        send_detail: this.userForm.value.send_detail,
                    });
                }
            }
        );
    }

    check_portal_access(evt: any) {
        if (
            evt.checked &&
            this.selected_portal_access.indexOf(parseInt(evt.source.value) == -1 )
        ) {
            this.selected_portal_access.push(parseInt(evt.source.value));
        } else if (
            !evt.checked &&
            this.selected_portal_access.indexOf(
                parseInt(evt.source.value) != -1
            )
        ) {
            let index = this.selected_portal_access.indexOf(
                parseInt(evt.source.value)
            );
            this.selected_portal_access.splice(index, 1);
        }
    }

    submit() {
        if (this.type == 'Add') {
            this.addData();
        } else {
            this.updateData();
        }
    }

    addData() {
        if (this.userForm.value.user_group_id === '') {
            this.toastr.error('The User group field is required');
            return false;
        }

        if (this.userForm.value.status === '') {
            this.toastr.error('The status field is required.');
            return false;
        }

        if (this.selected_portal_access.length === 0) {
            this.toastr.error('Please select any portal access');
            return false;
        }
        this.data = this.userForm.value;
        if (this.profile_file_id !== 0) {
            this.data.profile_file_id = this.profile_file_id;
        }
        this.data.portal_access = this.selected_portal_access;
        this.userService.add(this.data).subscribe(
            (resposne) => {
                if (resposne) {
                    this.toastr.success('New user added successfully');
                    this.router.navigate(['user-management']);
                }
            }
        );
    }

    updateData() {
        this.data = this.userForm.value;
        if (this.profile_file_id != 0) {
            this.data.profile_file_id = this.profile_file_id;
        }
        this.data.portal_access = this.selected_portal_access;
        this.userService.update(this.id, this.data).subscribe(
            (resposne) => {
                if (resposne) {
                    this.toastr.success('User updated successfully');
                    this.router.navigate(['user-management']);
                }
            }
        );
    }

    generate_password(evt) {
        if (evt.checked) {
            let rString = this.randomString(
                8,
                'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$%^@'
            );
            this.userForm.patchValue({
                password: rString,
            });
        } else {
            this.userForm.patchValue({
                password: '',
            });
        }
    }

    randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }

    check_send_detail(evt) {
        if (evt.checked) {
            this.userForm.patchValue({
                send_detail: 1,
            });
        } else {
            this.userForm.patchValue({
                send_detail: 0,
            });
        }
    }

    // start multiple file upload
    onFileUpload() {
        this.file_multiple_data = [];
        const fileUpload = document.getElementById(
            'docUpload'
        ) as HTMLInputElement;
        fileUpload.onchange = () => {
            let filecheck = [
                'jpeg',
                'jpg',
                'png',
                'gif'
            ];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    let size = file.size / 1024 / 1024;
                    if (size > 20) {
                        this.fileError =
                            'File size should not be more than 20MB.';
                        return;
                    }
                    this.file_multiple_data.push(file);
                } else {
                    this.fileError =
                        'Only accepts jpg, jpeg, png, gif format.';
                }
            }
            this.addDocument(this.file_multiple_data);
        };

        fileUpload.click();
    }

    // addDocument
    addDocument(file) {
        this.fileSuccess = '';
       
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file[]', file[x]);
        }
        this.userService.uploadFile(uploadData).subscribe(
            (response) => {
                this.uploaded_files = response.data;

                response.data.forEach((element) => {
                    this.profile_file_id = element.id;
                    this.vendor_file_data =
                        this.BaseUrl_file + '/uploads/' + element.name;
                });
                this.fileSuccess = 'Uploaded successfully!!';
               
            }
        );
    }
    // end multiple file upload

    back() {
        this.router.navigate(['user-management']);
    }
}
