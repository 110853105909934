import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-translator-orientation',
  templateUrl: './translator-orientation.component.html',
  styleUrls: ['./translator-orientation.component.scss']
})
export class TranslatorOrientationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
