import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
export interface CustProjList {
  position: number;
  id: string;
  projservices:string;
  languagePair: string;
  projStartDate: string;
  projEndDate: string;
  projStatus: string;
  projValue: string;
  leadSource:string;
}

const ELEMENT_DATA: CustProjList[] = [
  {position: 1, id: 'Bondarev-08201931972',leadSource:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Paid', projValue:'$455.00 USD'},
  {position: 2, id: 'Bondarev-08201931972',leadSource:'DayVIP', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'In-Process', projValue:'$455.00 USD'},
  {position: 3, id: 'Bondarev-08201931972',leadSource:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Lost', projValue:'$455.00 USD'},
  {position: 4, id: 'Bondarev-08201931972',leadSource:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Under Quotation', projValue:'$455.00 USD'},
  {position: 5, id: 'Bondarev-08201931972',leadSource:'YST Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Approved', projValue:'$455.00 USD'},
  {position: 6, id: 'Bondarev-08201931972',leadSource:'DT Website', projservices:'Translation, Notarization', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'New Request', projValue:'$455.00 USD'},
  {position: 7, id: 'Bondarev-08201931972',leadSource:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Paid', projValue:'$455.00 USD'},
  {position: 8, id: 'Bondarev-08201931972',leadSource:'DT Website', projservices:'Translation', languagePair: 'English < Hindi', projStartDate: '05/05/2017', projEndDate:'05/06/2018', projStatus:'Closed Paid', projValue:'$455.00 USD'},

];
@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  id:any;
  response:any;
  customer_response:any = [];
  response_customer_project:any;
  pageIndex:number = 1;
  pageSize:number = 5;
  total:number = 0;
  query:string = '';
  paginate:any = {};
  first_created_project:string = '';
  first_created_quote:string = '';
  last_created_project:string = '';
  last_created_quote:string = '';
  billing_address:any;
  mailing_address:any;
  constructor(private projectsService: ProjectsService,private router: Router,
    private route: ActivatedRoute,private toastr: ToastrService,private _location: Location) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getProjectSingleCustomer();
    this.getFirst_Quote_Project();
    this.getCustomerProject();
  }
  displayedColumns: string[] = [ 'id', 'leadSource', 'projservices', 'languagePair', 'projStartDate', 'projEndDate', 'projStatus', 'projValue'];
  dataSource :any;
  getProjectSingleCustomer() {
    this.projectsService.getProjectSingleCustomer(this.id)
    .subscribe((response)=>{
      if(response){
        this.response=response.data;
        if(this.response.customeraddress.data.length>0){
          this.response.customeraddress.data.forEach(element => {
            if(element.address_type == '1'){
              this.billing_address = element;
            }
            else   if(element.address_type=='2'){
              this.mailing_address = element;
            }
            
          });
        }
      }
    }
    );
}

getCustomerProject() {
  let query='';
  this.projectsService.getCustomerProjects(this.id,query)
  .subscribe((response)=>{
    if(response){
      response.data.forEach(element => {
        let lang1 = '';
        let lang_array=[];
        element.projectLanguage.data.forEach(elm => {
            lang1 = elm.sourcelanguage.data.lang_name+ '-' +elm.targetlanguage.data.lang_name+ ',' ;
            lang_array.push(lang1);
        });

        if(lang_array.length>0){
            let test;
            test=lang_array[lang_array.length-1];
            lang_array[lang_array.length-1]=test.slice(0,-1)
            
        }
        element.lang_array = lang_array;
        response.data.lang_array=lang_array;
    });
      this.dataSource=response.data;
      this.customer_response=response.data;
      this.paginate = response.meta.pagination;
      this.total=response.meta.pagination.total;
    }
  }
  );
}

paginateData(event) {
  let uQuery = ''
  this.pageIndex = event.pageIndex + 1;
  if(this.pageIndex < 0) {
      this.pageIndex = 1;
  }
  this.pageSize = event.pageSize;
  
 this.query='?page='+this.pageIndex+'&per_page='+this.pageSize;

 this.query+=uQuery;
  
 this.projectsService.getCustomerProjects(this.id,this.query)
  .subscribe((response)=>{
    if(response){

      response.data.forEach(element => {
        
        let lang1 = '';
        let lang_array=[];
        element.projectLanguage.data.forEach(elm => {
            lang1 = elm.sourcelanguage.data.lang_name+ '-' +elm.targetlanguage.data.lang_name+ ',' ;
            lang_array.push(lang1);
        });

        if(lang_array.length>0){
            let test;
            test=lang_array[lang_array.length-1];
            lang_array[lang_array.length-1]=test.slice(0,-1)
            
        }
        element.lang_array = lang_array;
        response.data.lang_array=lang_array;
    });
      this.dataSource=response.data;
      this.customer_response=response.data;
      this.paginate = response.meta.pagination;
      this.total=response.meta.pagination.total;
    }

  }
  );

}



getFirst_Quote_Project(){
  let obj:any={
    customer_uuid:''
  }
  obj.customer_uuid=this.id;

this.projectsService.getFirst_Quote_Project(obj)
    .subscribe((response) => {
        if(response) {
          if(response){
            this.first_created_project= response.first_created_project;
            this.first_created_quote= response.first_created_project;
            this.last_created_project= response.first_created_project;
            this.last_created_quote= response.first_created_project;
          }
        }
    });
}

editData(value:any){
  this.router.navigate(['/pms/customer/editCustomer/'+value.uuid]);

}

// Delete customer

  // Remove Project File
  deleteCustomer(){
    this.projectsService.deleteCustomer(this.id).subscribe(
      (response) => {
        if(response){
           this.toastr.success(' Delete Customer Successfully!!');
           this.router.navigate(['/pms/customer']);
        }
      }
    );
  }

  back() {
    this._location.back();
  }


}