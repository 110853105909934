import { SharedService } from 'app/main/shared.service';
import { ProjectsService } from './../../Services/projects.service';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatSort } from '@angular/material';
var SupportComponent = /** @class */ (function () {
    function SupportComponent(toastr, _formBuilder, sharedService, projectsService) {
        this.toastr = toastr;
        this._formBuilder = _formBuilder;
        this.sharedService = sharedService;
        this.projectsService = projectsService;
        // mainprogressbar:boolean = false;
        this.fileError = '';
        this.file_multiple_data = [];
        this.file_id_array = [];
        this.uploaded_files = [];
        this.uploaded_files_array = [];
        this.project_id = [];
        this.totalticket = 0;
        this.query_quote = "";
        this.paginate = {};
        this.pageSize = 20;
        this.pageIndex = 1;
        this.filterStatus = "";
        this.fQuery = "";
        this.filterIssue = "";
        this.displayedColumns = ['second_id', 'subject', 'issue_type', 'portal_id', 'created_at', 'updated_at', 'status'];
    }
    SupportComponent.prototype.getTicketDetails = function () {
        var _this = this;
        var query = "";
        this.projectsService.getTicketdata(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.totalticket = response.meta.pagination.total;
            }
        });
    };
    SupportComponent.prototype.paginateData = function (event) {
        var _this = this;
        var pQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_quote = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        this.query_quote += pQuery;
        this.projectsService.getTicketdata(this.query_quote)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.totalticket = response.meta.pagination.total;
            }
        });
    };
    SupportComponent.prototype.departmentFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10';
        if ((type == "department" && evt.value != "" && evt.value !== undefined)) {
            this.filterStatus = evt.value;
            this.fQuery += "&department=" + this.filterStatus;
        }
        else if (type == "department" && evt.value == "") {
            this.filterStatus = evt.value;
        }
        else if (this.filterStatus !== "") {
            this.fQuery += "&department=" + this.filterStatus;
        }
        if ((type == "issue_type" && evt.value != "" && evt.value !== undefined)) {
            this.filterIssue = evt.value;
            this.fQuery += '&issue_type=' + this.filterIssue;
        }
        else if (type == "issue_type" && evt.value == "") {
            this.filterIssue = evt.value;
        }
        else if (this.filterIssue !== "") {
            this.fQuery += '&issue_type=' + this.filterIssue;
        }
        var query = this.fQuery;
        this.projectsService.getTicketdata(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource = new MatTableDataSource(response.data);
                _this.dataSource.sort = _this.sort;
                _this.paginate = response.meta.pagination;
                _this.totalticket = response.meta.pagination.total;
            }
        });
    };
    SupportComponent.prototype.ngOnInit = function () {
        this.getTicketDetails();
        this.form = this._formBuilder.group({
            subject: ['', Validators.required],
            project: ['', Validators.required],
            portal_id: ['', Validators.required],
            message: ['', Validators.required],
            issue_type: ['', Validators.required]
        });
    };
    SupportComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = "File size should not be more than 20MB.";
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    SupportComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        // this.mainprogressbar = true;
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append("file[]", file[x]);
        }
        this.projectsService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files = response.data;
            _this.uploaded_files_array.push(_this.uploaded_files);
            response.data.forEach(function (element) {
                _this.file_id_array.push(element.id);
            });
            _this.fileSuccess = 'Uploaded successfully!!';
            // this.mainprogressbar = false;
        });
    };
    SupportComponent.prototype.submit = function () {
        var _this = this;
        if (this.form.value.subject == '') {
            this.toastr.error("Please select subject !!");
            return false;
        }
        if (this.form.value.project == '') {
            this.toastr.error("Please select project!!");
            return false;
        }
        if (this.form.value.portal_id == '') {
            this.toastr.error("Please select portal id!!");
            return false;
        }
        if (this.form.value.message == '') {
            this.toastr.error("Please select message!!");
            return false;
        }
        if (this.form.value.issue_type == '') {
            this.toastr.error("Please select issue type!!");
            return false;
        }
        if (this.file_id_array.length == 0) {
            this.toastr.error("Please Upload file");
            return false;
        }
        var ticketData = this.form.value;
        ticketData.file_ids = this.file_id_array;
        ticketData.reference = null;
        this.projectsService.addTicket(ticketData)
            .subscribe(function (response) {
            if (response) {
                //   this.toastr.success("Ticket Created Successfully");
                //   window.location.href = '/pms/support';
                // }
                _this.form.patchValue({
                    subject: [''],
                    project: [''],
                    portal_id: [''],
                    message: [''],
                    issue_type: ['']
                });
                _this.file_id_array = [];
                _this.uploaded_files_array = [];
                _this.fileSuccess = null;
                _this.toastr.success("Ticket Created Successfully");
                _this.getTicketDetails();
            }
        });
    };
    return SupportComponent;
}());
export { SupportComponent };
