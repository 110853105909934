import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {
  Form: any;
  smsForm: any;
  dataSource:any;
  paginate: any = {};
  pageSize: number = 20;
  pageIndex: number = 1;
  mainProgressbarhistory = false ;
  query:string ="";
  filterSearchQuery:string="";
  fQuery: string = "";
  constructor(private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private _fuseProgressBarService: FuseProgressBarService,
     private toastr: ToastrService, private _formBuilder: FormBuilder) { }
  displayedColumns: string[] = ['id', 'from','to','body','created_at'];
  ngOnInit( ) { 
//     this.getSmsTwillo();
//     this.Form = this._formBuilder.group({ 
//       search_value: ['']
//   });
//   this.smsForm = this._formBuilder.group({ 
//     to : ['', Validators.compose([Validators.required])],
//     body : ['', Validators.compose([Validators.required])]
// });
   }
   

   // OCR HISTORY
   getSmsTwillo()
  {
    this.mainProgressbarhistory = true;
    let query = '';
    this.projectsService.getSmsTwillo(query)
    .subscribe((response) => {
        if(response) {
    
        this.dataSource = new MatTableDataSource(response.data);
        this.paginate = response.meta.pagination;
        this.mainProgressbarhistory = false;
        // this.totalticket=response.meta.pagination.total;
         }
    }
    , (error) => {
        // this.toastr.error("Something went wrong, Please try later!");
        this.mainProgressbarhistory = false;
        
    }
    );
  }

  paginateData(event) {
    this.mainProgressbarhistory = true;
    let pQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if(this.pageIndex < 0) {
        this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    
   this.query='?page='+this.pageIndex+'&per_page='+this.pageSize;
    
   this.query+=pQuery;
   if(this.Form.value.search_value!==""){
    this.query += "&search_text=" + this.Form.value.search_value;
  }  
    
  
    this.projectsService.getSmsTwillo(this.query)
    .subscribe((response) => {
    if(response) {
     
      this.dataSource = new MatTableDataSource(response.data);
      this.paginate = response.meta.pagination;
      this.mainProgressbarhistory = false;
     }
  }, (error) => {
    //  this.toastr.error("Something went wrong, Please try later!");
     this.mainProgressbarhistory = false;
     
  });
  }

  submit(){
    this.onFilter(this.Form.value.search_value,'search');
  }

  onFilter(evt,type){
    this.mainProgressbarhistory = true;
    this.fQuery = '?page=1&per_page=10';

       // project search filter
       if ((type == "search" && evt != "" && evt !== undefined)) {
        this.filterSearchQuery = evt;
        this.fQuery += "&search_text=" + this.filterSearchQuery;
       }
      else if(type == "search" && evt == ""){
        this.filterSearchQuery = evt;
      }
      else if(this.filterSearchQuery!==""){
        this.fQuery += "&search_text=" + this.filterSearchQuery;
      }  
  
      let query = this.fQuery;
      this.projectsService.getSmsTwillo(query)
    .subscribe((response) => {
    if(response) {
     
      this.dataSource = new MatTableDataSource(response.data);
      this.paginate = response.meta.pagination;
      this.mainProgressbarhistory = false;
     }
  }, (error) => {
    //  this.toastr.error("Something went wrong, Please try later!");
     this.mainProgressbarhistory = false;
  });
  
    }

  
    sendSms() {
      if(this.smsForm.value.to == ''){
        this.toastr.error('Phone is required');
        return false;
      }
   
      if(this.smsForm.value.from == ''){
        this.toastr.error('Message is required');
        return false;
      }
      let data = {
        to : this.smsForm.value.to,
        body : this.smsForm.value.body
      }
      this.projectsService.sendMessage(data)
      .subscribe((response) => {
        if (response) {
          this.smsForm.patchValue({
            to: '',
            body: ''
          });    
          this.toastr.success('Message sent!!');
          this.getSmsTwillo();
        }
      }, (error) => {
        this.mainProgressbarhistory = false;
        this.toastr.error(error.error.message);
        
      });
  }

}
