<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" 
    [routerLink]="'/pms/project/details/'+project_id">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1">
      {{response_data?.reference}}
      <span class="prfInfo">
        <span *ngIf="quote_status==null"> <b>Time Elapsed:</b> {{TimeElapsed}}</span>
        <span class="prfo" *ngIf="quote_status==null"><b>Due in:</b>
          <span class="hText" [ngClass]="( checkdueTime == true)? 'hGreen' : 'hRed'"> {{DueTime}} </span>
        </span>
        <span class="prfo">
          <span class="pl-4 hText hGreen" *ngIf="response_data?.projectstage.data?.pm_status!=null">Status
            <b>{{response_data?.projectstage.data?.pm_status}}</b></span>
        </span>
      </span>
    </span>
  </div>

  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="75%">
      <div class="white-box">
        <div class="tHeader">
          <h2>Quote Summary</h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex>
                <p class="dLabel">Service Type</p>
                <p class="dValue" *ngIf="response_data?.translation_type"><span class="bdg blank bg-yellow">
                    {{response_data?.translation_type}}</span>
                    <span class="bdg blank tlink" title="Edit" (click)="openDialogServiceType()">
                      <mat-icon>
                        edit
                      </mat-icon> Update
                    </span> 
                  </p>
              </div>
              <div class="item item-1" fxFlex>
                <p class="dLabel">Source Language(s)</p>
                <p class="dValue">{{source_language_value}}
                  <span class="bdg blank tlink" title="Edit"
                   (click)="openDialogLanguage()">  <mat-icon>
                    edit
                  </mat-icon > Update</span>
                </p>
              </div>
              <div class="item item-2" fxFlex>
                <p class="dLabel">Target Language(s)</p>
                <p class="dValue">{{target_language_value}}
                  <span class="bdg blank tlink" 
                  title="Edit" (click)="openDialogLanguage()" 
                   >  <mat-icon>
                    edit
                  </mat-icon > Update</span>
                </p>
              </div>
              <div class="item item-4" fxFlex>
                <p class="dLabel">Deadline</p>
                <p class="dValue">{{response_data?.due_date?.date | date: 'yyyy-MM-dd'}}
                  <!-- {{response_data?.due_date?.date | date:"HH:mm"}} -->
                  <span class="bdg blank tlink" title="Edit" (click)="openDialogDeadline()">
                      <mat-icon>
                        edit
                      </mat-icon> Update
                    </span>
                </p>
              </div>
            </div>
            <h4> Schedule </h4>
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex *ngIf="response_data?.customer?.data.created_at.date">
                <p class="dLabel">Planned Start Date
                </p>
                <p class="dValue">{{response_data?.customer?.data.created_at.date | date: 'yyyy-MM-dd'}}
                  {{response_data?.customer?.data.created_at.date | date:"HH:mm"}}</p>
              </div>
              <div class="item item-2" fxFlex *ngIf="response_data?.deadline_date?.date">
                <p class="dLabel">Planned Deadline
                </p>
                <p class="dValue">{{response_data?.deadline_date?.date | date: 'yyyy-MM-dd'}}
                  {{response_data?.deadline_date?.date | date:"HH:mm"}}</p>
              </div>
              <div class="item item-3" fxFlex *ngIf="estimate_hours!=null">
                <p class="dLabel">Estimated Time:</p>
                <p class="dValue" *ngIf="estimate_hours!=null">{{estimate_hours}} Hours</p>
              </div>
              <div class="item item-3" fxFlex>
                <p class="dLabel">Specialization</p>
                <p class="dValue" *ngIf="response_data?.specilization_id==null">
                  <span class="bdg blank tlink" title="Edit" (click)="openDialog()">
                    <mat-icon>
                      edit
                    </mat-icon> Add
                  </span>
                </p>

                <p class="dValue" *ngIf="response_data?.specilization?.data?.name">
                  {{response_data?.specilization?.data?.name}}
                  <span class="bdg blank tlink" title="Edit" (click)="openDialog()">
                    <mat-icon>
                      edit
                    </mat-icon> Update
                  </span>
                </p>
              </div>
            </div>

            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex="25" *ngIf="response_data?.promo_code">
                <p class="dLabel">Promo Code</p>
                <p ><span class="promoInput"><i class="material-icons">new_releases</i>
                    {{response_data?.promo_code}}
                  </span></p>
              </div>

              <div class="item item-1" fxFlex="25" *ngIf="response_data?.crm_type">
                  <p class="dLabel">Type</p>
                  <p >{{response_data?.crm_type}}</p>
                </div>
            </div>
            <div class="box-blank">
              <p class="bLabel">Project Notes from Customer:</p>
              <p>{{response_data?.note}}</p>
            </div>
            <div class="box-blank bg-yellow" id="top_additional_service">
              <h4>Additional Services Request <span class="bdg blank tlink" (click)="showAddAdditionalServices_click()"><i
                    class="material-icons">add</i> Add </span> </h4>
              <!-- Additional services start -->
              <app-add-additional-services *ngIf="showAddAdditionService" [indexID]="index_id"></app-add-additional-services>
              <!-- Additional services end -->
              <mat-tab-group animationDuration="0ms">
                <mat-tab label="Mailing Options ">
                  <ng-template mat-tab-label>
                    Mailing Options <span class="aText">{{getValueMailing}}</span>
                  </ng-template>
                  <app-additional-information-mailing (myoutputMailing)="getDataMailing($event)">
                  </app-additional-information-mailing>
                </mat-tab>
                <mat-tab label="Notarization">
                  <ng-template mat-tab-label>
                    Notarization <span class="aText">{{getValueNotarization}}</span>
                  </ng-template>
                  <app-additional-information-notarization (myoutputNotarization)="getDataNotarization($event)">
                  </app-additional-information-notarization>
                </mat-tab>
                <mat-tab label=" Apostile">
                  <ng-template mat-tab-label>
                     Apostile <span class="aText">{{getValueApostile}}</span>
                  </ng-template>
                  <app-additional-information-apostile (myoutputApostile)="getDataApostile($event)">
                  </app-additional-information-apostile>
                </mat-tab>
              </mat-tab-group>
            </div>

            <h4> Customer Information </h4>
            <div class="container" fxLayout="row wrap" fxFlexFill fxLayout.xs="column" fxLayoutAlign="left"
              fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex *ngIf="false">
                <div class="box-blank bg-green">
                  <p class="dLabel">Newsletter </p>
                  <p class="dValue"> 5% discount! </p>
                </div>
              </div>
              <div class="item item-1" fxFlex *ngIf="discount_value">
                <div class="box-blank bg-green">
                  <p class="dLabel"> DayVIP </p>
                  <p class="dValue" *ngIf="discount_value"> {{discount_value}}% discount! </p>
                </div>
              </div>
              <div class="item item-1" fxFlex *ngIf="response_data?.customer?.data?.payment_method">
                <div class="box-blank bg-green">
                  <p class="dLabel">Payment Method </p>
                  <p class="dValue" *ngIf="response_data?.customer?.data?.payment_method==1">NET30</p>
                  <p class="dValue" *ngIf="response_data?.customer?.data?.payment_method==2"> NET45 </p>
                  <p class="dValue" *ngIf="response_data?.customer?.data?.payment_method==3"> Regular/Upfront
                  </p>
                  <p class="dValue" *ngIf="response_data?.customer?.data?.payment_method==4"> Credit Available
                  </p>
                </div>
              </div>

              <div class="item item-1" fxFlex *ngIf="response_data?.customer?.data?.credit_available">
                <div class="box-blank bg-green">
                  <p class="dLabel"> Credit Available </p>
                  <p class="dValue"> {{response_data?.customer?.data?.credit_available}} </p>
                </div>
              </div>

              <div class="item item-1" fxFlex *ngIf="response_data?.customer?.data?.contract_type">
                <div class="box-blank bg-green">
                  <p class="dLabel"> Contract Type </p>
                  <p class="dValue"> {{response_data?.customer?.data?.contract_type}} </p>
                </div>
              </div>
            </div>
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex>
                <p class="dLabel">Name </p>
                <p class="dValue">{{response_data?.customer?.data?.first_name}}
                  {{response_data?.customer?.data?.last_name}}</p>
              </div>
              <div class="item item-2" fxFlex>
                <p class="dLabel">Email Address</p>
                <p class="dValue">{{response_data?.customer?.data.email_primary}}</p>
              </div>
              <div class="item item-3" fxFlex>
                <p class="dLabel">Company</p>
                <p class="dValue">{{response_data?.customer?.data?.company?.data.name}}</p>
              </div>
              <div class="item item-4" fxFlex>
                <p class="dLabel">Phone</p>
                <p class="dValue">{{response_data?.customer?.data?.phone}}</p>
              </div>
            </div>
            <p><b>Start Time:</b> {{response_data?.customer?.data?.created_at.date | date: 'yyyy-MM-dd'}}
              {{response_data?.customer?.data?.created_at.date | date:"HH:mm"}}
              <b>User IP :</b> {{response_data?.customer?.data?.ip_address}}
              <b>Browser</b>: {{response_data?.customer?.data?.browser}}
              <b>OS</b> : {{response_data?.customer?.data?.os}}
              <b>Source</b>: {{response_data?.customer?.data?.source?.data?.name}}
            </p>
          </div>
        </div>
      </div>

      <div class="white-box">
        <form name="Form" [formGroup]="Form">
          <div class="tHeader">
            <div fxLayout="row wrap">
              <div fxFlex>
                <h2>Estimation</h2>
              </div>
              <div class="pstat" fxFlex> <span class="hText hYellow" *ngIf="version!='0.0'">Version {{version}}</span>
                <span class="pl-4 hText hYellow" *ngIf="quote_status==1">Status <b> Sent to customer</b></span>
                <span class="pl-4 hText hYellow" *ngIf="quote_status==2">Status <b> Requested Changes by
                    customer</b></span>
                <span class="pl-4 hText hYellow" *ngIf="quote_status==3">Status <b> Declined by customer</b></span>
                <span class="pl-4 hText hGreen" *ngIf="quote_status==4">Status <b> Approved by customer</b></span>
                <span class="pl-4 hText hYellow" *ngIf="quote_status==5">Status <b> Save As Draft</b></span>
              </div>
            </div>
          </div>

          <div class="boxBody">
            <div class="boxBodyInn">
              <mat-accordion>
                <mat-expansion-panel *ngFor="let res_quote_array of response_data_quoteArray">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="{{res_quote_array?.is_active_class}}">
                      <p>
                        <span class="vers">
                          <span *ngIf="res_quote_array.version!='0.0'">Version
                            {{res_quote_array.version}}</span>
                        </span>
                        <span class="pl-4" *ngIf="res_quote_array.status==1">Status <b> Sent to customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==2">Status <b> Requested Changes by
                            customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==3">Status <b> Declined by customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==4">Status <b> Approved by customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==5">Status <b> Save As Draft</b></span>
                        <span class="tAmount pl-4">
                          Total Amount: <b> {{res_quote_array.total}}</b></span> </p>
                    </mat-panel-title>
                    <mat-panel-description>
                      <p class="pl-4"><span class="qtDate">Sent on: <b> {{res_quote_array?.created_at?.date | date: 'yyyy-MM-dd'}}
                            {{res_quote_array?.created_at?.date | date:"HH:mm"}} </b></span>
                        <span class="pmName pl-4">By <b>{{username}}</b></span>
                      </p>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div *ngIf="(( language.length > 0) && response_data?.projectquote?.data.length==0) ||
                     (( language.length > 0 && response_data?.projectquote?.data.length>0 &&
                      (quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5) ))">
                    <table class="table table-bordered mb-32 mt-32">
                      <tr>
                        <th>Languages</th>
                        <th>Quantity</th>
                        <th>Rate(USD)</th>
                        <th>Sub Total(USD)</th>
                      </tr>
                      <!-- <tr *ngFor="let lang_combination of language ;let i = index"> -->
                          <tr *ngFor="let lang_combination of language ;let i = index">
                        <td>{{lang_combination.sourcelanguage.data.lang_name }} -
                          {{lang_combination.targetlanguage.data.lang_name}}</td>
                        <td>{{lang_combination.quantity}}</td>
                        <td>{{lang_combination.rate}} / {{lang_combination.pricing_method}}</td>
                        <td>{{lang_combination.sub_total}}</td>
                      </tr>
                    </table>
                  </div>

                  <!-- test start -->
                  <!-- additional service show -->
                  <h2>Additional Services</h2>
                                   <!-- additional service show -->

                                   <table class="table table-bordered">

                                    <tr>
                                      <th>Service Description</th>
                                      <th class="text-center">Quantity</th>
                                      <th class="text-center">Sub Total(USD)</th>
                                    </tr>
                  <tr *ngFor="let res of res_quote_array?.project_quote_additional_service?.data">
                    <!-- Mailing options start-->
                  
                     
                        <!-- Mailing options start-->
                                          
                        <td *ngIf="res?.title">
                          {{res?.title}}
                         
                      </td>
  
                        <td class="text-right"  *ngIf="res?.quantity">
                            <b>{{res?.quantity}}</b>
                           
                        </td>
                          <td class="text-right" *ngIf="res?.price">
                              <b>${{res?.price}}</b>
                             
                          </td>
                        <td class="text-right">
                          <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                        </td>
                    
                  
                    <!-- Mailing options end-->
                  </tr>
                  </table>
                  <div class="text-center mt-16" fxLayout="column" *ngIf="ProjectLanguage_data.length>0"
                    fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
                    fxLayoutGap="10px">
                    <div class="item item-1" fxFlex>
                      <p class="dLabel">Discount (%)</p>
                      <p class="dValue"><b>{{res_quote_array.discount}}</b>
                      </p>
                    </div>
                    <div class="item item-2" fxFlex>
                      <p class="dLabel">Discount Amount(USD)</p>
                      <p class="dValue"><b>{{res_quote_array.discount_amount}}</b>
                      </p>
                    </div>
                    <div class="item item-2" fxFlex>
                      <p class="dLabel">Total(USD)</p>
                      <p class="dValue"><b>{{res_quote_array.total}}</b>
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div
                *ngIf="(( language?.length > 0) &&  response_data?.projectquote?.data?.length==0) || (( language?.length > 0 &&  response_data?.projectquote?.data?.length>0 && (quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5) ))">
                <table class="table table-bordered mb-32 mt-32">
                  <tr>
                    <th>Languages</th>
                    <th>Quantity</th>
                    <th>Rate(USD)</th>
                    <th>Sub Total(USD)</th>
                    <th
                      *ngIf=" response_data?.projectquote?.data?.length==0 ||  ((  response_data?.projectquote?.data?.length>0 && ( quote_status==1 ||  quote_status==2 || quote_status==3 || quote_status==5) ))">
                      Actions</th>
                  </tr>
                  <tr *ngFor="let lang_combination of language ;let i = index">
                    <td>{{lang_combination.sourcelanguage.data.lang_name }} -
                      {{lang_combination.targetlanguage.data.lang_name}}</td>
                    <td>{{lang_combination.quantity}}</td>
                    <td>{{lang_combination.rate}} / {{lang_combination.pricing_method}}</td>
                    <td>{{lang_combination.sub_total}}</td>
                    <td
                      *ngIf=" response_data?.projectquote?.data.length==0 ||  ((  response_data?.projectquote?.data.length>0 && ( quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5) ))">
                      <button mat-stroked-button class="bicon" (click)="editData(lang_combination,i)"
                        *ngIf=" response_data?.projectquote?.data.length==0 ||  ((  response_data?.projectquote?.data.length>0 && ( quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5) ))">
                        <mat-icon matTooltip="Click here to Edit">
                          edit
                        </mat-icon>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="container mt-8 mb-16">
                <div class="container"
                  *ngIf="(( count_test<count) &&  response_data?.projectquote?.data.length==0) ||  
                  ((( count_test<count) &&  response_data?.projectquote?.data.length>0 &&
                     ( quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5)))"
                  >
                  
                  <div class="box-blank"  fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">
                    
                    <h4 fxFlex="100%">Language Pair</h4>
                  
                  <div class="item item-1" fxFlex="15%">
                    <p class="dLabel">Source Language</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <mat-select [(ngModel)]="data.source_lang_id" [ngModelOptions]="{standalone: true}"
                          (selectionChange)="getSourceName($event)" disableOptionCentering class="myPanelClass">
                          <mat-option [value]="''">
                            Select
                          </mat-option>
                          <!-- <mat-option [value]="source_language_id_value">
                            {{source_language_value}}
                          </mat-option> -->
                          <mat-option *ngFor="let language_res of source_language_select" [value]="language_res.id">
                            {{language_res.lang_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </p>
                  </div>

                  <div class="item item-1" fxFlex="15%">
                    <p class="dLabel">Target Language</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <mat-select [(ngModel)]="data.target_lang_id" [ngModelOptions]="{standalone: true}"
                          (selectionChange)="getTargetName($event)" disableOptionCentering class="myPanelClass">
                          <mat-option [value]="''">
                            Select
                          </mat-option>
                          <mat-option *ngFor="let language_res of target_language_select" [value]="language_res.id">
                            {{language_res.lang_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </p>
                  </div>

                  <div class="item item-1" fxFlex="15%">
                    <p class="dLabel">Pricing Method</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <mat-select [(ngModel)]="data.pricing_method" [ngModelOptions]="{standalone: true}"
                          (selectionChange)="PricingType($event)" disableOptionCentering class="myPanelClass">
                          <mat-option [value]="''">
                            Select
                          </mat-option>
                          <mat-option value="Words">
                            Words
                          </mat-option>
                          <mat-option value="Pages">
                            Pages
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </p>
                  </div>

                  <div class="item item-1" fxFlex="12%">
                    <p class="dLabel">Quantity*</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <input matInput type="number" [(ngModel)]="data.quantity" pattern="/^\d+$/"
                          [ngModelOptions]="{standalone: true}" (change)="Total_cost_new()">
                      </mat-form-field>
                    </p>
                  </div>

                  <div class="item item-2" fxFlex="12%">
                    <p class="dLabel">Rate(USD)*</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <input matInput type="number" [(ngModel)]="data.rate" [ngModelOptions]="{standalone: true}"
                          (change)="Total_cost_new()">
                      </mat-form-field>
                    </p>
                  </div>

                  <div class="valueItem" fxFlex="15%">
                    <p class="dValue">
                      <mat-form-field  >
                        <input class="f20" matInput type="text"  [(ngModel)]="data.sub_total" placeholder="-.--"
                          [ngModelOptions]="{standalone: true}" readonly>
                        <mat-hint>Sub Total(USD)</mat-hint>
                      </mat-form-field>
                    </p>
                  </div>

                  <div class="item text-center" fxFlex="8%">
                    <p>
                      <button mat-stroked-button color="accent" (click)="addLanguageTable()">
                        <p *ngIf="!index_array && index_array != 0"><i class="material-icons">add_circle_outline</i> Add
                        </p>
                        <p *ngIf="index_array>0 || index_array == 0"><i class="material-icons">add_circle_outline</i>
                          Update</p>
                      </button>
                    </p>
                  </div>
            </div>




                </div>


                <div class="box-blank">
                          <!-- additional service show -->
                          <h4> Additional Services </h4>
                          <div *ngIf="additonal_service_array.length==0" class="noDataFound">
                            No data available!!
                        </div>

                <div *ngFor="let res of additonal_service_array">
                  <!-- Mailing options start-->
                  <div *ngIf="res.additional_service.code == 'certified_Mailing' || 
                          res.additional_service.code == 'certified_Mailing_1Day' ||
                          res.additional_service.code == 'certified_Mailing_International' ||
                          res.additional_service.code == 'certified_Mailing_Canada' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                        <span>{{res?.additional_service?.title}} </span>
                        <button mat-stroked-button class="bicon" (click)="removeAdditionalService(res)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                      
                      <div fxFlex="50">
                         <b *ngIf="res?.sub_total">${{res.sub_total}}</b> 
                      </div>
                    </div>
                  </div>

                  <div *ngIf="res.additional_service.code == 'additional_copy' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.quantity">
                        Additional copy required: $10.00 {{'X' }} {{res?.quantity}}
                        <button mat-stroked-button class="bicon" (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="res.additional_service.code == 'regular_PickUp' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                        {{res?.additional_service?.title}} {{' X ' }} {{res?.quantity}}
                        <button mat-stroked-button class="bicon" (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>

                  <!-- Mailing options end-->

                  <!-- Notarization options start-->
                  <div *ngIf="res.additional_service.code == 'notarization_coa_il_ca' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                       {{res?.additional_service?.title}} {{' X ' }} {{res?.quantity}} 
                        <button mat-stroked-button class="bicon"
                          (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="res.additional_service.code == 'notarization_other_state' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                       {{res?.additional_service?.title}} {{' X ' }} {{res?.quantity}} 
                        <button mat-stroked-button class="bicon"
                          (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="res.additional_service.code == 'notarization_additional' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                       {{res?.additional_service?.title}} {{' X ' }} {{res?.quantity}} 
                        <button mat-stroked-button class="bicon"
                          (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="res.additional_service.code == 'notarization_affidavit_court' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                        {{res?.additional_service?.title}} {{' X ' }} {{res?.quantity}} 
                        <button mat-stroked-button class="bicon"
                          (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>
                  <!-- Notarization options end-->
                  <!-- Apostile options start-->
                  <div *ngIf="res.additional_service.code == ' Apostile_service_Illinois' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                        {{res?.additional_service?.title}} {{' X ' }} {{'1'}} 
                        <button mat-stroked-button class="bicon"
                          (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="res.additional_service.code == ' Apostile_service_outside_Illinois' ">
                    <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxFlex="50" *ngIf="res?.additional_service?.title">
                       {{res?.additional_service?.title}} {{' X ' }} {{'1'}}
                        <button mat-stroked-button class="bicon"
                          (click)="removeAdditionalService(res)">
                          <mat-icon matTooltip="Delete">delete</mat-icon>
                        </button>
                      </div>
                      <div fxFlex="50">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </div>
                    </div>
                  </div>
                  <!-- Apostile options end-->
                </div>


                <!-- New Additional Services Total start-->
                <div *ngIf="NewAdditionalServicesTotal>0">

                  <div class="actWraper mEqual20 text-left">
                  <div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    
                    <div fxFlex="50" class="text-right">
                      <b>New Additional Services Total: </b>
                    </div>
                    <div fxFlex="50">
                      <b class="f20">${{NewAdditionalServicesTotal}}</b>
                    </div>
                  </div>
                </div>
                </div>
                <!-- New Additional Services Total end-->
                <!-- additional service show end-->


              </div>


                <div class="box-blank">
                  <h4>Price Summary</h4>
                  <div class="container" fxLayout="column"
                    *ngIf="(response_data?.projectquote?.data.length==0) ||  ( response_data?.projectquote?.data.length>0 && ( quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5)) "
                    fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
                    fxLayoutGap="10px">
                    <div class="item item-1 mb-16" fxFlex="25%">
                      <p class="dLabel">Discount Type</p>
                      <p class="dValue">
                        <mat-form-field  >
                          <mat-select placeholder="Select" [(ngModel)]="dataTypePromotion.value"
                            [ngModelOptions]="{standalone: true}" (selectionChange)="DiscountType($event)">
                            <mat-option [value]="">
                              Select
                            </mat-option>
                            <mat-option *ngFor="let res of DiscoutPromotion" [value]="res.value">
                              {{res.title}}
                            </mat-option>
                            <mat-option [value]="0">
                              Other
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </p>
                    </div>
                    <div class="valueItem" fxFlex="18">
                      <p class="dValue">
                        <mat-form-field  >
                          <input matInput class="f20" type="number" formControlName="discount" [readonly]="check_discount" (change)="DiscountType_input()" placeholder="-.--">
                          <mat-hint>Discount (%)</mat-hint>
                        </mat-form-field>
                      </p>
                    </div>
                    <div class="valueItem" fxFlex="18">
                      <p class="dValue">
                        <mat-form-field  >
                          <input class="f20" matInput type="number" formControlName="discount_amount" readonly placeholder="-.--">
                          <mat-hint>Discount Amount(USD)</mat-hint>
                        </mat-form-field>
                      </p>
                    </div>
                    <div class="valueItem" fxFlex="18">
                      <p class="dValue">
                        <mat-form-field  >
                          <input class="f20" matInput type="number" placeholder="-.--"
                            formControlName="quote_subtotal" readonly>
                          <mat-hint>Quote SubTotal Amount(USD)</mat-hint>
                        </mat-form-field>
                      </p>
                    </div>
                    <div class="valueItem" fxFlex="18">
                      <p class="dValue">
                        <mat-form-field  >
                          <input class="f20" matInput type="number" placeholder="-.--" formControlName="total"
                            readonly>
                          <mat-hint>Total Amount(USD)</mat-hint>
                        </mat-form-field>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="box-blank bg-green" *ngIf="target_language_show">
                  <h4>Price Recomendations for {{target_language_name}}:</h4>
                
                  <div fxLayout="row wrap">
                    <!-- <div fxFlex>
                      <p class="dLabel">Available rate for {{target_language_name}}: </p>
                      <p class="dValue">
                        <b *ngIf="target_language_rate">$ {{target_language_rate}} USD </b>
                      </p>
                    </div>
                    <div fxFlex>
                      <p class="dLabel">Profit Margin</p>
                      <p class="dValue">
                        <b *ngIf="target_language_margin"> {{target_language_margin}}% </b> </p>
                    </div>
                    <div fxFlex>
                      <p class="dLabel">Recomended Rate: </p>
                      <p class="dValue">
                        <b *ngIf="target_language_recomended_rate"> $ {{target_language_recomended_rate}} USD</b></p>
                    </div> -->
                    <div fxFlex>
                      <p class="dLabel">Translation Rate<span class="block">(0-2000 Words)</span></p>
                      <p class="dValue">
                        <b *ngIf="priceRecomendations.rate">$ {{priceRecomendations.rate}} USD </b>
                      </p>
                    </div>
                    <div fxFlex>
                      <p class="dLabel">Translation Rate*<span class="block">(2001-5000 Words)</span></p>
                      <p class="dValue">
                        <b *ngIf="priceRecomendations.rate_tier2">$ {{priceRecomendations.rate_tier2}} USD </b>
                      </p>
                    </div>
                    <div fxFlex>
                      <p class="dLabel">Translation Rate*<span class="block">(5,001-10,000 Words)</span></p>
                      <p class="dValue">
                        <b *ngIf="priceRecomendations.rate_tier3">$ {{priceRecomendations.rate_tier3}} USD </b>
                      </p>
                    </div>

                    <div fxFlex>
                      <p class="dLabel">Translation Rate*<span class="block">(10,001-20,000 Words)</span></p>
                      <p class="dValue">
                        <b *ngIf="priceRecomendations.rate_tier4">$ {{priceRecomendations.rate_tier4}} USD </b>
                      </p>
                    </div>

                    <div fxFlex>
                      <p class="dLabel">Translation Rate*<span class="block">(Over 20,000 Words)</span></p>
                      <p class="dValue">
                        <b *ngIf="priceRecomendations.rate_tier5">$ {{priceRecomendations.rate_tier5}} USD </b>
                      </p>
                    </div>
                   
                  </div>
                </div>
                <div class="mt-16" fxLayout="row" fxLayoutGap="10px"
                  *ngIf="response_data?.projectquote?.data.length==0  ||  (( response_data?.projectquote?.data.length>0 && ( quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5) ))">
                  <div class="item item-1" fxFlex>
                    <p class="dLabel">Payment Terms</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <mat-select placeholder="Upfront" [disabled]="true">
                          <mat-option value="Upfront">Upfront</mat-option>
                          <mat-option value="NET 15">NET 15</mat-option>
                          <mat-option value="NET 30">NET 30</mat-option>
                          <mat-option value="NET 45">NET 45</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </p>
                  </div>
                  <div class="item item-1" fxFlex>
                    <p class="dLabel">Completion Time*</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <input matInput [min]="today" (click)="dp1.open()" [matDatepicker]="dp1"
                          formControlName="delivery_date">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker (dateChange)="checkStart()" #dp1></mat-datepicker>
                        <mat-error>Completion Time is required!</mat-error>
                      </mat-form-field>
                    </p>
                  </div>
                  <div fxFlex>
                    <p class="dLabel">Email Template*</p>
                    <p class="dValue">
                      <mat-form-field  >
                        <mat-select formControlName="email_template_id">
                          <mat-option [value]="''">
                            Select
                          </mat-option>
                          <mat-option *ngFor="let res of response_email_data" [value]="res.id">
                           <p>{{res.name}}</p> 
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="Form.get('email_template_id').hasError('required')">
                          Email Template is required!
                        </mat-error>
                      </mat-form-field>
                    </p>
                  </div>
                </div>
                <p class="bLabel">Special Instructions Note:</p>
                <mat-form-field   class="d-full-width">
                  <textarea matInput formControlName="comment"  >
                   </textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="box-blank" *ngIf="test">
            <p class="bLabel">File Prepration:</p>
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px">
              <p *ngFor="let file_res of file_prepration_data">
                <button mat-button>
                  <p class="text-truncate"> </p>
                  <a [href]="file_prepration_url" target="_blank" download> {{file_res.name}}<i class="material-icons">
                      arrow_downward
                    </i></a>
                </button>
              </p>
              <p *ngIf="file_prepration_data.length>1">
                <a [href]="file_prepration_download" target="_blank" download><i class="material-icons">
                    cloud_download
                  </i> Download All </a>
              </p>
            </div>
          </div>
          <div class="actWraper" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">
            <div>
              <!-- <button mat-stroked-button color="warn" type="button" (click)="projectRelease()" class="mr-8"
                *ngIf="quote_status!=4">Release</button> -->
              <button mat-stroked-button color="warn" type="button" (click)="projectRelease()"
                *ngIf="user_group_id=='Admin'" class="mr-8">Release
              </button>
            </div>
            <div>
                <button mat-raised-button class="mr-8" [disabled]="Form.invalid || ( count_test < count) "
                 (click)="submit('draft')">
                Save As Draft
              </button>
              <!-- <button mat-raised-button class="mr-8" [disabled]="Form.invalid || ( count_test < count) "
                *ngIf=" !is_draft" (click)="submit('draft')">
                Save As Draft
              </button> -->
              <button mat-raised-button color="accent" type="button" (click)="submit('not_draft')"
                [disabled]="Form.invalid || ( count_test<count) ">Review and Send</button>
            </div>
          </div>
        </form>
      </div>
      <!-- file management start -->
      <app-file-management></app-file-management>
      <!-- file management end -->
      <!--project assignments start -->
      <!-- <app-project-assignments></app-project-assignments> -->
      <!-- project assignments end -->
      <!-- <app-additional-services></app-additional-services> -->
      <div class="white-box" style="position: relative;" *ngIf="test">
        <!-- <div *ngIf='mainprogressbar' class="cssloadcube-overlay">
          <span class="helper"></span>
          <img src="assets/images/preloader_logo.gif" />
        </div> -->
        <div class="boxBody">
          <div class="boxBodyInn">
            <h4> File Preparation </h4>
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex>
                <p><b>UPLOAD REFERENCE FILES</b> </p>
                <div class="ml-148">Upload Reference file(s) for Translator if any <button mat-flat-button
                    color="accent" type="button" (click)="onFileUpload()">Upload</button></div>
                <input type="file" id="docUpload" name="docUpload" style="display:none;" multiple />
                <div>
                  <mat-error>{{fileError}}</mat-error>
                  <span style="color: green;">{{fileSuccess}}</span>
                </div>
              </div>
            </div>

            <div class="actWraper mt-12">
              <p *ngFor="let res of uploaded_files">
                {{res.name}}
              </p>
              <p>
                <button mat-raised-button color="accent" type="button" (click)="ProjectFileSave()"
                  [disabled]="file_id_array.length==0">CONTINUE</button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="white-box" style="position: relative;" *ngIf="test">
        <div class="boxBody">
          <div class="boxBodyInn">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
              fxLayoutAlign.gt-sm="space-between center">
              <h4>
                <mat-icon>notification_important</mat-icon>Please select min 3 Translator/ Vendor
              </h4>
              <!-- <h2 > <mat-icon class="ml-24">group</mat-icon>Translations Projects</h2> -->
              <div>
                <button mat-stroked-button color="primary">
                  <mat-icon>calendar_today</mat-icon>Set Common Duration
                </button>
              </div>
              <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>search</mat-icon>
                  <input [(ngModel)]="searchKey" placeholder="Search">
                </div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
              fxLayoutAlign.gt-sm="space-between center">
              <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100">
                <mat-form-field   fxFlex="20" class="ml-8">
                  <mat-label>Active</mat-label>
                  <mat-select>
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option [value]="'1'">
                      Active
                    </mat-option>
                    <mat-option [value]="'0'">
                      InActive
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field   fxFlex="20" class="ml-8">
                  <mat-label>Job Type</mat-label>
                  <mat-select>
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option [value]="'1'">
                      job type 1
                    </mat-option>
                    <mat-option [value]="'0'">
                      job type 2
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field   fxFlex="20" class="ml-8">
                  <mat-label>Native Language</mat-label>
                  <mat-select>
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option [value]="'1'">
                      Native Language 1
                    </mat-option>
                    <mat-option [value]="'0'">
                      Native Language 2
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field   fxFlex="20" class="ml-8">
                  <mat-label>Specialization</mat-label>
                  <mat-select>
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option [value]="'1'">
                      Specialization 1
                    </mat-option>
                    <mat-option [value]="'0'">
                      Specialization 2
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field   fxFlex="20" class="ml-8">
                  <mat-label>Rate</mat-label>
                  <mat-select>
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option [value]="'1'">
                      Rate 1
                    </mat-option>
                    <mat-option [value]="'0'">
                      Rate 2
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="content-card">
              <table style="width:100%">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Specialization</th>
                  <th>Overall Evaluation</th>
                  <th>Completed Jobs</th>
                  <th>Best Rate USD</th>
                </tr>
                <tbody>
                  <tr>
                    <td>
                      <mat-checkbox></mat-checkbox>
                    </td>
                    <td>Tanika Evans</td>
                    <td>Medical</td>
                    <td>4 Star</td>
                    <td>42</td>
                    <td>0.7 USD</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="actWraper mt-12">
              <button mat-raised-button color="accent" type="button">CONTINUE</button>
            </div>
          </div>
        </div>
      </div>

      <div class="white-box">
        <div class="tHeader">
          <h2> COMMENTS? </h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div class="commentDiv" *ngFor="let res of response_data?.projectcomment.data">
              <div class="quesDiv" *ngIf="res?.users?.data?.user_group_id=='Vendor'">
                <p><b>{{res.users?.data.fullname}}</b>
                  <span class="mUsr"> ({{res?.users?.data?.user_group_id}} )</span>
                  <span class="tStamp">
                    {{res?.created_at.date | date: 'yyyy-MM-dd'}} {{res?.created_at.date | date:"HH:mm"}}</span> </p>
                <p>{{res?.message}}</p>
              </div>
              <div class="ansDiv" *ngIf="res?.users?.data?.user_group_id!='Vendor'">
                <div class="divinn">
                  <p class="mb-4"><b>{{res.users?.data?.fullname}}</b>
                    <span class="mUsr"> ({{res?.users?.data?.user_group_id}} )</span>
                    <span class="mStamp"> {{res?.created_at?.date | date: 'yyyy-MM-dd'}}
                      {{res?.created_at.date | date:"HH:mm"}}</span>
                  </p>
                  <p class="mCmnt">{{res?.message}}</p>
                </div>
              </div>
            </div>
            <div class="box-blank">
              <form name="commentForm" [formGroup]="commentForm">
                <p>
                  <mat-form-field   class="d-full-width">
                    <textarea matInput formControlName="message" placeholder="Leave a comment"
                       ></textarea>
                    <mat-hint>Note: if you have questions, please enter them here and click I have questions
                      to not accept for now.</mat-hint>
                  </mat-form-field>
                </p>
                <p>
                  <b>Message Type:</b>
                  <mat-radio-group formControlName="message_type">
                    <mat-radio-button value="0" class="pr-20">Internal</mat-radio-button>
                    <mat-radio-button value="1" class="pr-20">Customer</mat-radio-button>
                  </mat-radio-group>
                </p>
                <p> <button mat-raised-button color="primary" type="submit" (click)="submitComment()"
                    [disabled]="commentForm.invalid">Save</button></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="25%">
      <div class="projSteps">
        <app-project-history></app-project-history>
      </div>
    </div>
  </div>
</div>