import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared.service";
var UserService = /** @class */ (function () {
    function UserService(http, sharedService) {
        this.http = http;
        this.sharedService = sharedService;
        this.BaseUrl = environment.APIEndpoint;
    }
    UserService.prototype.add = function (data) {
        var url = this.BaseUrl + '/user/register';
        return this.sharedService.postApi(url, data);
    };
    // get(query): Observable<any> {
    //   let url = this.BaseUrl + '/user?' + query + 'user_role=Admin,Finance-Manager,Hr-Admin,Operation-Manager,Project-Manager';
    //   return this.sharedService.getApi(url);
    // }
    UserService.prototype.getAll = function (query) {
        var url = this.BaseUrl + '/user?' + query;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.getAdminProfile = function () {
        var url = this.BaseUrl + '/me?';
        return this.sharedService.getApi(url);
    };
    UserService.prototype.getUser = function (id) {
        var url = this.BaseUrl + '/user/' + id;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.getRole = function () {
        var url = this.BaseUrl + '/userGroup?exclude=Vendor14';
        return this.sharedService.getApi(url);
    };
    UserService.prototype.update = function (id, data) {
        var url = this.BaseUrl + '/user/' + id;
        return this.sharedService.patchApi(url, data);
    };
    UserService.prototype.updateProfileImage = function (data) {
        var url = this.BaseUrl + '/user/profile_image';
        return this.sharedService.postApi(url, data);
    };
    UserService.prototype.uploadFile = function (data) {
        var url = this.BaseUrl + '/vendors/uploadfile';
        return this.sharedService.postFileApi(url, data);
    };
    UserService.prototype.getLanguages = function (query) {
        var url = this.BaseUrl + '/language?per_page=500' + query;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.getLanguagesListing = function (query) {
        var url = this.BaseUrl + '/languages' + query;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.addLangugae = function (data) {
        var url = this.BaseUrl + '/language';
        return this.sharedService.postApi(url, data);
    };
    UserService.prototype.updateMasterLanguage = function (id, data) {
        var url = this.BaseUrl + '/language/' + id;
        return this.sharedService.patchApi(url, data);
    };
    UserService.prototype.getWorkflow = function (query) {
        var url = this.BaseUrl + '/worflow' + query;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.addWorkflow = function (data) {
        var url = this.BaseUrl + '/worflow';
        return this.sharedService.postApi(url, data);
    };
    UserService.prototype.getLocation = function (query) {
        var url = this.BaseUrl + '/location' + query;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.getSingleLocation = function (id) {
        var url = this.BaseUrl + '/location/' + id;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.addLocation = function (query) {
        var url = this.BaseUrl + '/location';
        return this.sharedService.postApi(url, query);
    };
    UserService.prototype.updateMasterLocation = function (id, data) {
        var url = this.BaseUrl + '/location/' + id;
        return this.sharedService.patchApi(url, data);
    };
    // Tickets
    UserService.prototype.getallTickets = function (query) {
        var url = this.BaseUrl + '/all_ticket' + query;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.getallTicketbyId = function (id) {
        var url = this.BaseUrl + '/ticket/' + id;
        return this.sharedService.getApi(url);
    };
    UserService.prototype.updateTicketStatus = function (id, data) {
        var url = this.BaseUrl + '/ticket/' + id;
        return this.sharedService.patchApi(url, data);
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
