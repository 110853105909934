import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared.service";
var VendorService = /** @class */ (function () {
    function VendorService(http, sharedService) {
        this.http = http;
        this.sharedService = sharedService;
        this.BaseUrl = environment.APIEndpoint;
    }
    VendorService.prototype.add = function (data, type) {
        if (type === void 0) { type = 'translator'; }
        if (typeof data == 'object') {
            data["vendor_type"] = type;
        }
        var url = this.BaseUrl + '/vendors/addtranslator';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.addComment = function (data) {
        var url = this.BaseUrl + '/addvendorcomment';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.getAll = function (type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + ("?include=vendorspecialities,vendortranslationlangs,vendornativelanguage&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getTranslatorStatus = function (id) {
        var url = this.BaseUrl + '/vendors_user_detail/' + id;
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getAdminApprovalPending = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendortranslationlangs,vendornativelanguage&stage=7&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    // getAllApprove(query): Observable<any> {
    //   let url = this.BaseUrl + '/vendors' + query + '&include=vendorspecialities,vendortranslationlangs,vendornativelanguage&stage=5';
    //   return this.sharedService.getApi(url);
    // }
    VendorService.prototype.getAllTranslator = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendorSubtitler,vendornativelanguage&stage=6&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getAllTranslators = function (type) {
        if (type === void 0) { type = 'translator'; }
        var query = '?page=1&per_page=1000';
        var url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendorSubtitler,vendornativelanguage&stage=6&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getTranslatorProgress = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&stage=2,3,4&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getHrDashboardListing = function (query, stage_id, type) {
        if (type === void 0) { type = 'translator'; }
        var url = '';
        if (stage_id != 0) {
            url = this.BaseUrl + '/vendors' + query + ("&include=vendorskill,vendorspecialities,vendorSubtitler,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=" + type + "&stage=") + stage_id;
        }
        else if (stage_id == 0) {
            url = this.BaseUrl + '/vendors' + query + ("&include=vendorskill,vendorspecialities,vendorSubtitler,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=" + type);
        }
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getHrDashboardSubtitlerListing = function (query, stage_id, type) {
        if (type === void 0) { type = 'translator'; }
        var url = '';
        if (stage_id != 0) {
            url = this.BaseUrl + '/vendors' + query + ("&skill=8&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=" + type + "&stage=") + stage_id;
        }
        else if (stage_id == 0) {
            url = this.BaseUrl + '/vendors' + query + ("&skill=8&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=" + type);
        }
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getHrDashboardTrepingListing = function (query, stage_id, type) {
        if (type === void 0) { type = 'translator'; }
        var url = '';
        if (stage_id != 0) {
            url = this.BaseUrl + '/vendors' + query + ("&skill=78&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill,vendorTreping,vendorMarketingSkillPrice&vendor_type=" + type + "&stage=") + stage_id;
        }
        else if (stage_id == 0) {
            url = this.BaseUrl + '/vendors' + query + ("&skill=78&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill,vendorTreping,vendorMarketingSkillPrice&vendor_type=" + type);
        }
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getHrDashboardClosedBlacklistedListing = function (query, stage_id, type) {
        if (type === void 0) { type = 'translator'; }
        var url = '';
        if (stage_id != 0) {
            url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=" + type + "&stage=") + stage_id;
        }
        else if (stage_id == 0) {
            url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendorSubtitler,vendoravailabilities,vendornativelanguage,vendortranslationlangs,vendorskill&vendor_type=" + type);
        }
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getNeedApproval = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&stage=9&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.filterSpeciality = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + ("?include=vendorspecialities,vendortranslationlangs,vendoravailabilities&vendor_type=" + type) + query;
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.filterSpecialityProgress = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + ("?include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=" + type) + query;
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.filterSpecialityApprove = function (query, type) {
        if (type === void 0) { type = 'translator'; }
        var url = this.BaseUrl + '/vendors' + query + ("&include=vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.get = function (id, type) {
        if (type === void 0) { type = 'translator'; }
        // tslint:disable-next-line: max-line-length
        var url = this.BaseUrl + '/vendors/' + id + ("?include=vendorSubtitler,vendorspecialities,vendortranslationlangs,vendoravailabilities,vendorfiles,vendornativelanguage,vendorprofileimage,vendorresume,vendorcontract,vendorcomment,vendorskill,vendorcoafiles,extradocument,vendorTreping,vendorMarketingSkillPrice&vendor_type=" + type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.update = function (id, data) {
        var url = this.BaseUrl + '/vendors/' + id;
        return this.sharedService.patchApi(url, data);
    };
    VendorService.prototype.updateLanguage = function (id, data) {
        var url = this.BaseUrl + '/translatorlang/' + id;
        return this.sharedService.patchApi(url, data);
    };
    VendorService.prototype.updateSubtitlerLanguage = function (id, data) {
        var url = this.BaseUrl + '/subtitlerlang/' + id;
        return this.sharedService.patchApi(url, data);
    };
    VendorService.prototype.updateTrepingLanguage = function (id, data) {
        var url = this.BaseUrl + '/trepinglang/' + id;
        return this.sharedService.patchApi(url, data);
    };
    VendorService.prototype.addLanguage_edit = function (data) {
        var url = this.BaseUrl + '/vendors/addtranslatorlang';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.addSubtitlerLanguage_edit = function (data) {
        var url = this.BaseUrl + '/vendors/addSubtitlerlang';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.addTrepingLanguage_edit = function (data) {
        var url = this.BaseUrl + '/vendors/addTrepinglang';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.updateTranslator = function (id, data) {
        var url = this.BaseUrl + '/translator/' + id;
        return this.sharedService.patchApi(url, data);
    };
    VendorService.prototype.finalTranslatorApprove = function (id, data) {
        var url = this.BaseUrl + '/vendors_approve/' + id;
        return this.sharedService.patchApi(url, data);
    };
    VendorService.prototype.getSpeciality = function () {
        var url = this.BaseUrl + '/speciality';
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    VendorService.prototype.uploadFile = function (data) {
        var url = this.BaseUrl + '/vendors/uploadfile';
        return this.sharedService.postFileApi(url, data);
    };
    VendorService.prototype.VendorsCoaFile = function (id, data) {
        var url = this.BaseUrl + '/vendors_coa_file/' + id;
        return this.sharedService.postFileApi(url, data);
    };
    VendorService.prototype.getLanguages = function () {
        var url = this.BaseUrl + '/language?per_page=500';
        return this.sharedService.getApi(url);
    };
    // get language by id
    VendorService.prototype.getLanguageById = function (id) {
        var url = this.BaseUrl + '/language/' + id;
        return this.sharedService.getApi(url);
    };
    // create quote api
    VendorService.prototype.getAllLanguages = function () {
        var url = this.BaseUrl + '/all_language?per_page=500';
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getAllTimezone = function () {
        var url = this.BaseUrl + '/timezones?per_page=500';
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getSkillService = function () {
        var url = this.BaseUrl + '/skill_service/9';
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getSkillServiceById = function (id) {
        var url = this.BaseUrl + ("/skill_service/" + id);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getTrepingSkillService = function () {
        var url = this.BaseUrl + '/skill_service/11';
        return this.sharedService.getApi(url);
    };
    // get all county api
    VendorService.prototype.getAllCounty = function () {
        var url = this.BaseUrl + '/country_list';
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.sendProgressMail = function (ids) {
        var url = this.BaseUrl + '/vendors/sendlogindetail';
        return this.sharedService.postApi(url, ids);
    };
    VendorService.prototype.getStage = function () {
        var url = this.BaseUrl + '/stage' + '?exclude=1,2,3,4,9,7';
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getService = function () {
        var url = this.BaseUrl + '/skill';
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getUserCount = function () {
        var url = this.BaseUrl + '/user/count';
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getAllTemplate = function (query) {
        var url = this.BaseUrl + '/email' + query;
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getTemplateType = function (query) {
        var url = this.BaseUrl + '/get_all_email_type' + query;
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.getSingleEmailTemplate = function (id) {
        var url = this.BaseUrl + '/emails/' + id;
        var headers = SharedService.getPutHeader();
        return this.http.get(url, { headers: headers });
    };
    VendorService.prototype.updateEmailTemplate = function (id, data) {
        var url = this.BaseUrl + '/email/' + id;
        return this.sharedService.patchApi(url, data);
    };
    VendorService.prototype.removeExtradocument = function (data) {
        var url = this.BaseUrl + '/vendors/removedExtrafile';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.addTemplateMaster = function (data) {
        var url = this.BaseUrl + '/email';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.getVendorListingCount = function (vendor_type) {
        if (vendor_type === void 0) { vendor_type = 'translator'; }
        var url = this.BaseUrl + ("/vendors_listing_count?vendor_type=" + vendor_type);
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.deleteCoaFile = function (id) {
        var url = this.BaseUrl + '/vendor_coa_file/' + id;
        return this.sharedService.deleteApi(url);
    };
    VendorService.prototype.deleteVendorLanguage = function (id) {
        var url = this.BaseUrl + '/vendor_language/' + id;
        return this.sharedService.deleteApi(url);
    };
    VendorService.prototype.deleteVendorSubtitlerLanguage = function (id) {
        var url = this.BaseUrl + '/vendor_subtitler/' + id;
        return this.sharedService.deleteApi(url);
    };
    VendorService.prototype.deleteVendorTrepingLanguage = function (id) {
        var url = this.BaseUrl + '/vendor_treping/' + id;
        return this.sharedService.deleteApi(url);
    };
    VendorService.prototype.getVendorDoc = function (id) {
        var url = this.BaseUrl + '/vendor_docs/' + id;
        return this.sharedService.getApi(url);
    };
    VendorService.prototype.vendorDocSave = function (data) {
        var url = this.BaseUrl + '/vendor_docs';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.downloadVendorZip = function (data) {
        var url = this.BaseUrl + '/vendor_docs_zip';
        return this.sharedService.postApi(url, data);
    };
    VendorService.prototype.deleteVendorDoc = function (id) {
        var url = this.BaseUrl + '/vendor_docs/' + id;
        return this.sharedService.deleteApi(url);
    };
    VendorService.ngInjectableDef = i0.defineInjectable({ factory: function VendorService_Factory() { return new VendorService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService)); }, token: VendorService, providedIn: "root" });
    return VendorService;
}());
export { VendorService };
