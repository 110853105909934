<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" [routerLink]="'/welcome'">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1">
      {{userDetail?.fullname}}
     <span class="prfInfo">
        <b>Added:</b> {{userDetail?.created_at | date:'dd-MM-yyyy' }}
        <span class="prfo"><b>Role:</b>
          <span> {{userDetail?.user_group_id}} </span>
        </span>
      </span>
    </span>
  </div>
  <div class="con-box">
    <div class="boxBody">
      <div class="custInfo">

        <div class="white-box">

        
        <div class="boxBodyInn" fxLayout fxLayoutAlign="start" fxLayoutGap="10px">
            <div class="frlncrPic" fxFlex="15">
              <div class="prfImg">
                <img [src]="vendor_file_data" onerror="this.src='assets/images/avatars/profile.jpg'" width="120"  /><br />
                <button mat-stroked-button type="button" (click)="onFileUpload()" class="mt-8">Upload Image</button>
                <input type="file" id="docUpload" name="docUpload" style="display:none;" />
              </div>
             
             
              </div>
          <div class="item item-1" fxFlex>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Name: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{userDetail?.fullname}}</b></p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Email: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{userDetail?.email}}</b></p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Status: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b class="bdg grn" *ngIf="userDetail?.status==1">Active</b></p>
                <p><b class="bdg rdb" *ngIf="userDetail?.status==0">InActive</b></p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Portal Access: </p>
              </div>
              <div class="item item-1" fxFlex>
                 <p><b>{{userDetail?.portal_access_value}}</b></p> 
              </div>
            </div>
          </div>
        </div>

        <div class="actWraper">
          <button mat-raised-button type="button"  (click)="updateForm()"  class="green-bg Text_Color">
            <mat-label class="test_text">Update Image</mat-label>
    </button>
        </div>

      </div>

      <form name="changeForm" [formGroup]="changeForm">
      <div class="white-box">
        <div class="tHeader">
        <h4>Update Password</h4>
      </div>
        <div class="boxBodyInn">

       
        
                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" class="w30">
                    <mat-form-field  >
                        <mat-label>Old password</mat-label>
                        <input type="password" matInput formControlName="old_password">
                        <mat-error *ngIf="changeForm.get('old_password').hasError('required')">
                          Old Password is required!
                      </mat-error>
                      <mat-error
                          *ngIf="!changeForm.get('old_password').hasError('required') &&
                          changeForm.get('old_password').hasError('minlength')">
                          Old Password must atleast 6 characters!
                      </mat-error>       
                    </mat-form-field>
                    <mat-form-field  >
                        <mat-label>New password</mat-label>
                        <input type="password" matInput formControlName="new_password">
                        <mat-error *ngIf="changeForm.get('new_password').hasError('required')">
                          New Password is required!
                      </mat-error>
                      <mat-error
                          *ngIf="!changeForm.get('new_password').hasError('required') &&
                          changeForm.get('new_password').hasError('minlength')">
                          New Password must atleast 6 characters!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field  >
                        <mat-label>Confirm password</mat-label>
                        <input type="password" matInput formControlName="confirm_password">
                        <mat-error *ngIf="changeForm.get('confirm_password').hasError('required')">
                            Confirm Password is required
                        </mat-error>
                        <mat-error *ngIf="changeForm.get('confirm_password').hasError('minlength')">
                                Minimum 6 characters required!
                        </mat-error>
                        <mat-error  *ngIf="
                        !changeForm.get('confirm_password').hasError('required') &&
                        changeForm.get('confirm_password').hasError('passwordsNotMatching')
                      ">
                      Passwords must match
                      </mat-error>   
                    </mat-form-field>
                </div>
              
            
          </div>
          <div class="actWraper">
            <button mat-raised-button type="button"  (click)="updatePassword()" [disabled]="changeForm.invalid" class="green-bg Text_Color">
                    <mat-label class="test_text">Update Password</mat-label>
            </button>
        </div>
</div>

</form>

      </div>
    </div>
  </div>
</div>