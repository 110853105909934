import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io";
var ChatService = /** @class */ (function () {
    function ChatService(socket) {
        var _this = this;
        this.socket = socket;
        this.getMessages = function () {
            return Observable.create(function (observer) {
                _this.socket.on('new-message', function (message) {
                    observer.next(message);
                });
            });
        };
    }
    ChatService.prototype.sendMessage = function (message) {
        this.socket.emit('new-message', message);
    };
    ChatService.ngInjectableDef = i0.defineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.inject(i1.Socket)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}());
export { ChatService };
