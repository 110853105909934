<div class=" bg-white m-auto custom-height-popup" fusePerfectScrollbar>
  <div class="white-box bNone">
    <div class="tHeader" *ngIf="href=='/pms/stripetransactions/create'">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2>Create Stripe Payment</h2>
        <div class="text-right">
          <button mat-button color="accent" (click)="searchTransaction()">
            <span class="material-icons">pageview</span> Search Stripe Transaction
          </button>
        </div>
      </div>
    </div>

    <div class="boxBody">
      <div class="tabWraper custom-height-payment">
        <div class="wp20">
          <div class="p20">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button [checked]='true' (change)="check_user($event)" value="existing">Existing
                vault customer?</mat-radio-button>
              <mat-radio-button (change)="check_user($event)" value="new">New Payment with CC info
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="show_existing">
            <div *ngIf="show_search">
              <!-- <div [hidden]="!show_existing">
        <div  [hidden]="!show_search"> -->
              <p class="pl-20"><b>Want to create a transaction for an existing customer?</b></p>
              <form name="searchCustomerForm" [formGroup]="searchCustomerForm"
                (ngSubmit)="searchCustomerTransactions();">
                <div class="pl-20" fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex="70">
                    <mat-form-field>
                      <mat-label>Enter Email Id </mat-label>
                      <input matInput formControlName="field_value">
                      <mat-error *ngIf="searchCustomerForm.get('field_value').hasError('required')">
                        Required!
                      </mat-error>

                    </mat-form-field>
                  </div>

                  <div fxFlex="20">
                    <button type="submit" [disabled]="searchCustomerForm.invalid" mat-raised-button
                      color="accent">Search
                      <mat-icon matSuffix>search
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div [hidden]="!show_existingUser">

              <div class="tHeader">
                <h2></h2>
              </div>

              <div class="custSearchResult">
                <div class="boxBodyInn">
                  <div *ngIf="dataTransaction.length>0">
                    <div class="boxBody">
                      <table mat-table [dataSource]="dataSource" class="table bdr1">
                        <!-- Position Column -->
                        <ng-container matColumnDef="id">
                          <th mat-header-cell *matHeaderCellDef> ID </th>
                          <td mat-cell *matCellDef="let element" class="Pl-1"> <a
                              (click)="create_payment('payment',element)"> {{element.cus_id}} </a>
                          </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Name </th>
                          <!-- <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}}</td> -->
                          <td mat-cell *matCellDef="let element"> {{element.cus_name}}</td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="savedDate">
                          <th mat-header-cell *matHeaderCellDef> Added </th>
                          <td mat-cell *matCellDef="let element"> {{element.cus_date}} </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="paymentMethod">
                          <th mat-header-cell *matHeaderCellDef> Payment Method </th>
                          <td mat-cell *matCellDef="let element">
                            <!-- <img [src]="element.image_url" width="40" height="30">  -->
                            {{element.card_brand}} **** {{element.last4}} <span *ngIf="element.default">(Default)</span>
                          </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="token">
                    <th mat-header-cell *matHeaderCellDef> Token </th>
                    <td mat-cell *matCellDef="let element"> {{element.token}} </td>
                  </ng-container> -->
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef> </th>
                          <td mat-cell *matCellDef="let element"> <button type="button"
                              (click)="create_payment('payment',element)" value="payment" mat-raised-button
                              color="accent">Create Payment</button>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                      </table>

                    </div>
                  </div>

                  
                </div>
              </div>
            </div>
            <div *ngIf="no_record_found" class="noDataFound">
              No data available!
            </div>
            <!-- <div class="box-blank" *ngIf="show_newUser"> -->
            <div id="existinguser">
              <div class="box-blank" [hidden]="!show_newUser">
                <!-- <h1>Existing user</h1> -->
                <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left">
                  <div class="item item-1 mt-12" fxFlex="20">
                    <b><span class="title">Customer ID</span></b>
                  </div>
                  <div class="item item-1  mt-12" fxFlex="80">
                    <span>{{customerdetail?.cus_id}}
                    </span>
                  </div>
                </div>

                <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left">
                  <div class="item item-1" fxFlex="20">
                    <b><span class="title">Customer Name</span></b>
                  </div>
                  <div class="item item-1" fxFlex="80">
                    <!-- <span>{{customerdetail?.first_name}} {{customerdetail?.last_name}}</span> -->
                    <span>{{customerdetail?.cus_name}}</span>
                  </div>
                </div>
                <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left">
                  <div class="item item-1" fxFlex="20">
                    <b><span class="title">Company</span></b>
                  </div>
                  <div class="item item-1" fxFlex="80">
                    <span>{{customerdetail?.company}}
                    </span>
                  </div>
                </div>
                <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left">
                  <div class="item item-1" fxFlex="20">
                    <b><span class="title">Phone No.</span></b>
                  </div>
                  <div class="item item-1" fxFlex="80">
                    <span>{{customerdetail?.cus_phone}}
                    </span>
                  </div>
                </div>

                <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left">
                  <div class="item item-1" fxFlex="20">
                    <b><span class="title">Email</span></b>
                  </div>
                  <div class="item item-1" fxFlex="80">
                    <span>{{customerdetail?.cus_email}}
                    </span>
                  </div>
                </div>

                <h4>Create new Transaction with invoice:</h4>
                <form name="paymentForm" [formGroup]="paymentForm" (ngSubmit)="createPaymentTransactions();">

                  <div fxFlexLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                    <div>
                      <mat-form-field>
                        <mat-label>Enter transaction amount *</mat-label>
                        <input matInput formControlName="transaction_amount">
                        <mat-error *ngIf="paymentForm.get('transaction_amount').hasError('required')">
                          Amount is required!
                        </mat-error>
                        <mat-error *ngIf="paymentForm.get('transaction_amount').hasError('pattern')">
                          Enter Valid amount!
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div>
                      <mat-form-field>
                        <mat-label>Enter order Id *</mat-label>
                        <input matInput formControlName="order_id">
                        <mat-error *ngIf="paymentForm.get('order_id').hasError('required')">
                          order id is required!
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>


                  <div>
                    <mat-form-field>
                      <mat-label>Enter invoice description*</mat-label>
                      <textarea matInput formControlName="transaction_description"> </textarea>
                    </mat-form-field>
                  </div>
                  <p><button mat-raised-button color="accent">Create</button></p>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="box-blank" *ngIf="show_new"> -->
        <div class="p20" [hidden]="!show_new">
          <h4>Create new Transaction with invoice for New User:</h4>
          <form name="paymentFormNewUser" [formGroup]="paymentFormNewUser" (ngSubmit)="tokenizeUserDetails()">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="w30">
                <mat-form-field>
                  <mat-label>Enter Transaction Amount *</mat-label>
                  <input matInput formControlName="amount">
                  <mat-error *ngIf="paymentFormNewUser.get('amount').hasError('required')">
                    Transaction Amount is required!
                  </mat-error>
                  <mat-error *ngIf="paymentFormNewUser.get('amount').hasError('pattern')">
                    Enter Valid Transaction Amount!
                  </mat-error>
                </mat-form-field>
              </div>



              <div class="w30">
                <mat-form-field>
                  <mat-label>Enter Order ID *</mat-label>
                  <input matInput formControlName="order_id">
                  <mat-error *ngIf="paymentFormNewUser.get('order_id').hasError('required')">
                    Order ID is required!
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="ccInput" fxFlexLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <div fxFlex="30">
                <mat-form-field>
                  <mat-label>Customer First Name *</mat-label>
                  <input matInput formControlName="firstName">
                  <mat-error *ngIf="paymentFormNewUser.get('firstName').hasError('required')">
                    Customer First Name is required!
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="30">
                <mat-form-field>
                  <mat-label>Customer Last Name *</mat-label>
                  <input matInput formControlName="lastName">
                  <mat-error *ngIf="paymentFormNewUser.get('lastName').hasError('required')">
                    Customer Last Name is required!
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxFlex="30">
                <mat-form-field>
                  <mat-label>Phone *</mat-label>
                  <input matInput formControlName="phone">
                  <mat-error *ngIf="paymentFormNewUser.get('phone').hasError('required')">
                    Phone is required!
                  </mat-error>
                  <mat-error *ngIf="paymentFormNewUser.get('phone').hasError('pattern')">
                    Enter Valid Phone Number
                  </mat-error>
                </mat-form-field>

              </div>

              <div fxFlex="30">
                <mat-form-field>
                  <mat-label>Email *</mat-label>
                  <input matInput formControlName="email">
                  <mat-error *ngIf="paymentFormNewUser.get('email').hasError('required')">
                    Email is required!
                  </mat-error>
                  <mat-error *ngIf="paymentFormNewUser.get('email').hasError('email')">
                    Enter Valid Email
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div>
              <mat-form-field>
                <mat-label>Enter invoice description</mat-label>
                <textarea matInput formControlName="transaction_description"> </textarea>
              </mat-form-field>
            </div>

            <div class="panel2">

              <div class="panel__content2">
                <h4>Card Payment</h4>
                <div class="custom-name">
                  <label class="hosted-field--label" for="cardholder-name" name="cardholderName">
                    <span class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path
                          d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </span>
                    Cardholder's Name</label>
                  <input type="text" placeholder="e.g. JOHN DOE" formControlName="name" class="name-custom-input" />
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                </div>
              </div>
              <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
            </div>
            <p>
              <mat-checkbox formControlName="saveToVault">Save this to vault</mat-checkbox>
            </p>
            <p><button type="submit" [disabled]="paymentFormNewUser.invalid" color="accent"
                mat-raised-button>Create</button></p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>