import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {
  fileError: string = '';
  Form: any;
  file_multiple_data:any=[];
  file_id_array:any=[];
  uploaded_files:any=[];
  uploaded_files_array:any=[];
  fileSuccess: string;
  mainprogressbar:boolean = false;
  mainProgressbarConverted:boolean = false;
  mainProgressbarhistory:boolean = false;
  fileId : number ;
  fileUrl : string ;
  BaseUrl_file: any;
  download_file :string = '';
  download_file_array : any =[];
  dataSource:any;
  paginate: any = {};
  pageSize:number = 20;
  pageIndex:number=1;
  query:string ="";
  filterSearchQuery:string="";
  fQuery: string = "";
  project_id = '';
  href: string;
  displayedColumns: string[] = ['id', 'file_name','created_at','download'];
  constructor(private vendorService : VendorService,
    private route: ActivatedRoute,
    private projectsService : ProjectsService, private router: Router,
     private toastr: ToastrService, private _formBuilder: FormBuilder) { 
      this.BaseUrl_file = environment.baseUrl_file;
     }

  ngOnInit() {
    this.href = this.router.url;
    if(this.href!='/pms/tools'){
      this.project_id=localStorage.getItem('Project_id');
    }
    // this.project_id = this.route.snapshot.params['id'];
    this.getOcrHistory();
    this.Form = this._formBuilder.group({ 
      search_value: [''],
  });
  }

      // start  file upload

      onFileUpload() { 
        this.file_multiple_data = [];
        this.uploaded_files_array = [];
        this.download_file_array = [];
        const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
        fileUpload.onchange = () => {
            let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'ppt', 'pptx'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
              // loop through files
            for (var i = 0; i < files.length; i++) {
            // get item
            file = files.item(i);
            //or
            file = files[i];
            var ext = re.exec(file.name)[1];
            if(file && filecheck.indexOf(ext) != -1) {
                let size = file.size/1024/1024;
                if (size > 20) {
                    this.fileError = "File size should not be more than 20MB.";
                    return;
                }
              this.file_multiple_data.push(file);
            }
            else {
                this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, ppt or pptx format.';
                this.fileSuccess = '';
            }
     }
     this.addDocument(this.file_multiple_data);      
        };
       
        fileUpload.click();
      }
    
      // addDocument
      addDocument(file) {
        this.fileSuccess = '';
        this.mainprogressbar = true;
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
          uploadData.append("file[]", file[x]);
         }
         uploadData.append("project_reference",'Image-Process-Queue');
        this.vendorService.uploadFile(uploadData)
        .subscribe((response) => {
          this.uploaded_files_array = [];
          this.file_id_array= [] ;
          this.uploaded_files=response.data;
          this.uploaded_files_array.push(this.uploaded_files);
          response.data.forEach(element => {
            this.fileId = element.id;
          this.file_id_array.push(element.id);  
          });
            this.fileError = '';
            this.fileSuccess = 'Uploaded successfully!!';
            this.getOcrFile(this.file_id_array);
            this.mainprogressbar = false;
        }, (error) => {
            this.mainprogressbar = false;
        });
      }
    // end  file upload

    getOcrFile(fileId: any) {
      this.mainProgressbarConverted = true;
   
      let data : any = {
        file_id : fileId,
        project_id : this.project_id
      }
      
      this.projectsService.getOcrFile(data).pipe().subscribe((response: any) => {
        this.download_file = '';
        this.download_file_array = [];
        if (response.status === true) { 
          let data : any ={
              file_name : '',
              file_url  : ''
          }
          
          response.data.forEach(element => {
            data  = {} ;
            data.file_name = element.file_name;
            data.file_url = this.BaseUrl_file + element.file_path;
            this.download_file_array.push(data);
            
          });

          this.mainProgressbarConverted = false;
          
          this.getOcrHistory();
          this.toastr.success("Success");
        }else{
          this.toastr.error(response.file_path);
          this.mainProgressbarConverted = false;
        }
  });
  }

  // OCR HISTORY
  getOcrHistory()
  {
    this.mainProgressbarhistory = true;
    let query = '';
    this.projectsService.getOcrHistory(query)
    .subscribe((response) => {
        if(response) {
    
        this.dataSource = new MatTableDataSource(response.data);
        this.paginate = response.meta.pagination;
        this.mainProgressbarhistory = false;
        // this.totalticket=response.meta.pagination.total;
         }
    }, (error) => {
        this.mainProgressbarhistory = false;
        
    });
  }

  paginateData(event) {
    this.mainProgressbarhistory = true;
    let pQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if(this.pageIndex < 0) {
        this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    
   this.query = '&page=' + this.pageIndex + '&per_page=' + this.pageSize;
    
   this.query += pQuery;
   if(this.Form.value.search_value!==""){
    this.query += "&search_text=" + this.Form.value.search_value;
  }  
    
  
    this.projectsService.getOcrHistory(this.query)
    .subscribe((response) => {
    if(response) {
     
      this.dataSource = new MatTableDataSource(response.data);
      this.paginate = response.meta.pagination;
      this.mainProgressbarhistory = false;
     }
  }, (error) => {
     this.mainProgressbarhistory = false;
     
  });
  }

  submit(){
    this.onFilter(this.Form.value.search_value,'search');
  }

  onFilter(evt,type){

    this.fQuery = '&page=1&per_page=10';

       // project search filter
       if ((type == "search" && evt != "" && evt !== undefined)) {
        this.filterSearchQuery = evt;
        this.fQuery += "&search_text=" + this.filterSearchQuery;
       }
      else if(type == "search" && evt == ""){
        this.filterSearchQuery = evt;
      }
      else if(this.filterSearchQuery!==""){
        this.fQuery += "&search_text=" + this.filterSearchQuery;
      }  
  
      let query = this.fQuery;
      this.projectsService.getOcrHistory(query)
    .subscribe((response) => {
    if(response) {
     
      this.dataSource = new MatTableDataSource(response.data);
      this.paginate = response.meta.pagination;
     }
  });
  
    }
}
