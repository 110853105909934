import { AfterViewInit, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
// @ts-ignore
import Resumable from "resumablejs";
var AITranscriptionComponent = /** @class */ (function () {
    function AITranscriptionComponent(projectsService, domSanitizer) {
        this.projectsService = projectsService;
        this.domSanitizer = domSanitizer;
        this.supportResumable = true;
        this.maxFileAllowed = 1;
        this.chunkSize = 1024 * 1024;
        this.fileRestrict = ['flac', 'm4a', 'mp3', 'mp4', 'mpeg', 'mpga', 'oga', 'ogg', 'wav', 'webm'];
        this.file_multiple_data = [];
        this.fileError = '';
        this.updated_data = {};
        this.videoUrl = "";
        this.progressBarConfig = {
            percentage: 0
        };
        this.subtitlingIsInProgress = false;
        this.dataResponse = "";
        this.duration = "";
    }
    AITranscriptionComponent.prototype.ngOnInit = function () {
    };
    AITranscriptionComponent.prototype.ngAfterViewInit = function () {
        this.setQuery();
    };
    AITranscriptionComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            _this.fileError = '';
            var filecheck = ['mp3'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            _this.videoUrl = _this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = 'File size should not be more than 20MB.';
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Invalid file type.';
                    return;
                }
            }
        };
        fileUpload.click();
    };
    AITranscriptionComponent.prototype.setQuery = function () {
        var query = {};
        var fileUploadOptions = {
            simultaneousUploads: 1,
            maxFiles: this.maxFileAllowed,
            fileParameterName: 'file',
            chunkSize: this.chunkSize,
            forceChunkSize: true,
            query: query,
            headers: {},
            fileType: this.fileRestrict,
            fileTypeErrorCallback: this.onFileTypeError.bind(this)
        };
        this.resumable = new Resumable(fileUploadOptions);
        this.supportResumable = this.resumable.support;
        if (this.supportResumable) {
            this.resumable = new Resumable(fileUploadOptions);
            this.resumable.assignBrowse(this.imgRef.nativeElement, false);
            this.resumable.assignBrowse(this.browseButton._elementRef.nativeElement, false);
            this.assignDragNDrop();
        }
        this.setEvents();
    };
    //function to add events
    AITranscriptionComponent.prototype.setEvents = function () {
        this.resumable.on('fileAdded', this.onFileAdded.bind(this));
        this.resumable.on('error', this.onError.bind(this));
        this.resumable.on('fileError', this.onFileError.bind(this));
        this.resumable.on('cancel', this.onUploadCancel.bind(this));
        this.resumable.on('fileSuccess', this.onFileSuccess.bind(this));
        this.resumable.on('complete', this.onComplete.bind(this));
    };
    //on file added callback
    AITranscriptionComponent.prototype.onFileAdded = function (file, event) {
        var _this = this;
        file['resumableObj'].opts.target = environment.whisperUrl + "/resumable_upload";
        if (file['resumableObj'].opts.query.docId) {
            delete file['resumableObj'].opts.query.docId;
        }
        var resumableFile = this.resumable.files[0];
        resumableFile.fileReader = new FileReader();
        resumableFile.fileReader.readAsDataURL(file.file);
        this.fileSuccess = "";
        this.fileError = "";
        this.dataResponse = "";
        this.videoUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file.file));
        resumableFile.fileReader.addEventListener('load', function () {
            _this.progressBarConfig.percentage = 0;
        });
    };
    /**
     * return individual file upload progress
     */
    AITranscriptionComponent.prototype.parseProgress = function (file) {
        this.progressBarConfig.percentage = Math.round(Math.floor(file.progress(false) * 100) * 10) / 10;
        return true;
    };
    AITranscriptionComponent.prototype.onError = function () {
    };
    AITranscriptionComponent.prototype.uploadTheFile = function () {
        this.resumable.upload();
    };
    AITranscriptionComponent.prototype.pauseFile = function () {
        this.resumable.pause();
    };
    AITranscriptionComponent.prototype.cancelUpload = function () {
        this.videoUrl = "";
        this.duration = "";
        this.resumable.cancel();
    };
    AITranscriptionComponent.prototype.onFileError = function () {
        this.videoUrl = "";
        this.duration = "";
    };
    AITranscriptionComponent.prototype.onUploadCancel = function () {
        this.videoUrl = "";
        this.duration = "";
    };
    AITranscriptionComponent.prototype.onFileSuccess = function ($event, message) {
        var _this = this;
        this.subtitlingIsInProgress = true;
        this.projectsService.whisperGetSubTitlesByUrl({ file: message }).subscribe(function (response) {
            _this.subtitlingIsInProgress = false;
            _this.dataResponse = response;
            _this.fileSuccess = 'Request successful!!';
        });
    };
    AITranscriptionComponent.prototype.onComplete = function ($event) {
        console.log($event);
    };
    AITranscriptionComponent.prototype.assignDragNDrop = function () {
        this.resumable.assignDrop(this.imgRef.nativeElement);
    };
    AITranscriptionComponent.prototype.onFileTypeError = function ($event) {
    };
    AITranscriptionComponent.prototype.uploadDocument = function () {
        var _this = this;
        this.fileSuccess = '';
        var uploadData = new FormData();
        var ins = this.file_multiple_data.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append('file', this.file_multiple_data[x]);
        }
        this.projectsService.whisperUploadFile(uploadData).subscribe(function (response) {
            _this.file_multiple_data = [];
            _this.dataResponse = response;
            console.log('response', response);
            _this.fileSuccess = 'Request successful!!';
        });
    };
    AITranscriptionComponent.prototype.getDuration = function (e) {
        this.duration = e.target.duration;
    };
    AITranscriptionComponent.prototype.toHoursAndMinutes = function (totalMinutes) {
        var minutes = totalMinutes % 60;
        var hours = Math.floor(totalMinutes / 60);
        return this.padTo2Digits(hours) + ":" + this.padTo2Digits(minutes.toFixed(2));
    };
    AITranscriptionComponent.prototype.padTo2Digits = function (num) {
        return parseInt(num.toString().padStart(2, '0'));
    };
    return AITranscriptionComponent;
}());
export { AITranscriptionComponent };
