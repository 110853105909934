import { OnInit } from "@angular/core";
import { VendorService } from "app/main/Services/vendor.service";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "app/main/shared.service";
var HrDashboardComponent = /** @class */ (function () {
    function HrDashboardComponent(vendorService, toastr) {
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.imported_count = 0;
        this.new_request_count = 0;
        this.inprogress_count = 0;
        this.need_approval_count = 0;
        this.admin_approval_count = 0;
        this.non_responsive_count = 0;
        this.closed_count = 0;
        this.blacklisted_count = 0;
        this.archive_count = 0;
        this.pageIndex = 1;
        this.pageSize = 10;
    }
    // tslint:disable-next-line: typedef
    HrDashboardComponent.prototype.ngOnInit = function () {
        this.getData();
        var tab = {};
        tab.index = 0;
        this.tabPagination = localStorage.getItem("pagination");
        this.tabObject = JSON.parse(this.tabPagination);
        if (this.tabPagination !== null) {
            tab.index = this.tabObject.tabIndex;
            this.selectIndex = this.tabObject.tabIndex;
            this.tabChanged(tab);
        }
        else {
            this.selectIndex = 0;
            this.tabChanged(tab);
        }
    };
    HrDashboardComponent.prototype.tabChanged = function (tabChangeEvent) {
        //debugger;
        var pagination;
        if (tabChangeEvent.index === 0) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 0) {
                pagination = {
                    table_name: "translatorInNewRequest",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 0,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "translatorInNewRequest",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 0,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 1;
            this.tab_index = 0;
        }
        if (tabChangeEvent.index === 1) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 1) {
                pagination = {
                    table_name: "translatorInProgress",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 1,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "translatorInProgress",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 1,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 2;
            this.tab_index = 1;
        }
        if (tabChangeEvent.index === 2) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 2) {
                pagination = {
                    table_name: "translatorNeedApproval",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 2,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "translatorNeedApproval",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 2,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 9;
            this.tab_index = 2;
        }
        if (tabChangeEvent.index === 3) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 3) {
                pagination = {
                    table_name: "translatorPendingAdminApproval",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 3,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "translatorPendingAdminApproval",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 3,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 7;
            this.tab_index = 3;
        }
        if (tabChangeEvent.index === 4) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 4) {
                pagination = {
                    table_name: "Archive",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 4,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "Archive",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 4,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 0;
            this.tab_index = 4;
        }
        if (tabChangeEvent.index === 5) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 5) {
                pagination = {
                    table_name: "translatorNonResponsive",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 5,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "translatorNonResponsive",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 5,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 3;
            this.tab_index = 5;
        }
        if (tabChangeEvent.index === 6) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 6) {
                pagination = {
                    table_name: "translatorClosed",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 6,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "translatorClosed",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 6,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 0;
            this.tab_index = 6;
        }
        if (tabChangeEvent.index === 7) {
            if (this.tabPagination != null && this.tabObject.tabIndex == 7) {
                pagination = {
                    table_name: "translatorBlacklisted",
                    page: this.tabObject.page,
                    per_page: this.tabObject.per_page,
                    tabIndex: 7,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            else {
                pagination = {
                    table_name: "translatorBlacklisted",
                    page: this.pageIndex,
                    per_page: this.pageSize,
                    tabIndex: 7,
                };
                SharedService.set(SharedService.pagination, JSON.stringify(pagination));
            }
            this.stage_id = 0;
            this.tab_index = 7;
        }
    };
    HrDashboardComponent.prototype.getData = function () {
        var _this = this;
        this.vendorService.getVendorListingCount().subscribe(function (response) {
            if (response) {
                _this.imported_count = response.imported_count;
                _this.new_request_count = response.new_request_count;
                _this.inprogress_count = response.inprogress_count;
                _this.need_approval_count = response.need_approval_count;
                _this.admin_approval_count = response.admin_approval_count;
                _this.archive_count = response.archive_count;
                _this.non_responsive_count = response.non_responsive;
                _this.closed_count = response.closed_count;
                _this.blacklisted_count = response.blacklisted_count;
            }
        });
    };
    return HrDashboardComponent;
}());
export { HrDashboardComponent };
