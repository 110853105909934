<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
  <div class="white-box">
    <div class="boxBody">
      <div class="tabWraper">
        <div class="actWraper mb-4" fxLayout="column" fxLayout.gt-sm="row" fxFlex="100">
          <button mat-raised-button color="accent" (click)="CreateTransaction()">Create Braintree Transaction</button>
          <button mat-raised-button color="accent" class="ml-12" (click)="searchTransaction()">Search Braintree Transaction</button>
        </div>
      </div>
    </div>
  </div>
</div>