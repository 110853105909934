import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/main/Services/user.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
var AdminDashboardComponent = /** @class */ (function () {
    function AdminDashboardComponent(router, userService, vendorService, toastr, _fuseNavigationService) {
        this.router = router;
        this.userService = userService;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this._fuseNavigationService = _fuseNavigationService;
        this.paginate = {};
        this.fChipsArray = [];
        this.fChipsArrayApprove = [];
        this.paginate1 = {};
        this.pageSize = 10;
        this.searchQuery = '';
        this.filterStatus = '';
        this.query_freelancer_sort = '';
        this.filterRole = '';
        this.specialityData = [];
        this.languageData = [];
        this.roleData = [];
        this.fQuery = '';
        this.filterlang_pair = '';
        this.filterSpecialization = '';
        this.filterPortal_Access = '';
        this.filter_src_lang = '';
        this.filter_target_lang = '';
        this.query_approve = '';
        this.query_user_sort = '';
        this.query_user = '';
        this.filter_native_lang = null;
        this.fQuery1 = '';
        this.pageIndex = 1;
        this.pageSize_approval = 6;
        this.pageIndex_approval = 1;
        this.filterSearchQuery = '';
        this.filterSearchQueryApproval = '';
        this.displayedColumns = ['fullname', 'user_group_id', 'email', 'portal_access', 'last_login', 'status', 'actions'];
        this.VendordisplayedColumns = ['first_name', 'email', 'native_language', 'language', 'stage'];
    }
    AdminDashboardComponent.prototype.ngOnInit = function () {
        this.getLanguage();
        this.getUserCount();
        this.getSpeciality();
        this.getRole();
        this.getDataUser();
        this.getDataVendor();
    };
    AdminDashboardComponent.prototype.getRole = function () {
        var _this = this;
        this.userService.getRole()
            .subscribe(function (res) {
            if (res) {
                _this.roleData = res.data;
            }
        });
    };
    AdminDashboardComponent.prototype.getDataUser = function () {
        var _this = this;
        var query = 'page=1&per_page=6';
        this.userService.getAll(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.total_user = response.meta.pagination.total;
            }
        });
    };
    AdminDashboardComponent.prototype.getDataVendor = function () {
        var _this = this;
        var query = '?page=1&per_page=6';
        this.vendorService.getAdminApprovalPending(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        lang = res.sourcelanguage.data.lang_name + res.bi_direction +
                            res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
                _this.total_vendor = response.meta.pagination.total;
            }
        });
    };
    AdminDashboardComponent.prototype.userMangement = function () {
        this.router.navigate(['user-management']);
    };
    AdminDashboardComponent.prototype.Approval = function () {
        this.router.navigate(['/approval']);
    };
    AdminDashboardComponent.prototype.applyFilterVendor = function (filterValue) {
        this.dataSource1.filter = filterValue.trim().toLowerCase();
    };
    AdminDashboardComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '';
        // project search filter
        if ((type == 'search' && evt != '' && evt !== undefined)) {
            this.filterSearchQuery = evt;
            this.fQuery += '&search_text=' + this.filterSearchQuery;
        }
        else if (type == 'search' && evt == '') {
            this.filterSearchQuery = evt;
        }
        else if (this.filterSearchQuery !== '') {
            this.fQuery += '&search_text=' + this.filterSearchQuery;
        }
        if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
            this.filterStatus = evt.value;
            this.fQuery += '&status=' + this.filterStatus;
        }
        else if (type == 'status' && evt.value == '') {
            this.filterStatus = evt.value;
        }
        else if (this.filterStatus !== '') {
            this.fQuery += '&status=' + this.filterStatus;
        }
        if ((type == 'role' && evt.value != '' && evt.value !== undefined)) {
            this.filterRole = evt.value;
            this.fQuery += '&role=' + this.filterRole;
        }
        else if (type == 'role' && evt.value == '') {
            this.filterRole = evt.value;
        }
        else if (this.filterRole !== '') {
            this.fQuery += '&role=' + this.filterRole;
        }
        if ((type == 'portal_access' && evt.value != '' && evt.value !== undefined)) {
            this.filterPortal_Access = evt.value;
            this.fQuery += '&portal_access=' + this.filterPortal_Access;
        }
        else if (type == 'portal_access' && evt.value == '') {
            this.filterPortal_Access = evt.value;
        }
        else if (this.filterPortal_Access !== '') {
            this.fQuery += '&portal_access=' + this.filterPortal_Access;
        }
        var query = this.fQuery;
        this.parseQueryString(this.fQuery);
        this.userService.getAll(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.total_user = response.meta.pagination.total;
            }
        });
    };
    AdminDashboardComponent.prototype.parseQueryString = function (queryString) {
        this.fChipsArray = [];
        var params = {
            status: '',
            role: '',
            portal_access: ''
        }, queries, temp, i, l;
        // Split into key/value pairs
        queries = queryString.split('&');
        // Convert the array of strings into an object
        for (i = 0, l = queries.length; i < l; i++) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        this.get_filter_value(params);
    };
    AdminDashboardComponent.prototype.get_filter_value = function (data) {
        var key = Object.keys(data);
        if (key[0] == 'status') {
            if (data.status != '') {
                if (data.status == 1) {
                    this.fChipsArray.push('Active');
                }
                else {
                    this.fChipsArray.push('Inactive');
                }
            }
        }
        if (key[1] == 'role') {
            if (data.role != '') {
                this.fChipsArray.push(data.role);
            }
        }
        if (key[2] == 'portal_access') {
            if (data.portal_access != '') {
                if (data.portal_access == 1) {
                    this.fChipsArray.push('Admin');
                }
                else if (data.portal_access == 2) {
                    this.fChipsArray.push('Hr');
                }
                else if (data.portal_access == 3) {
                    this.fChipsArray.push('PMS');
                }
                else if (data.portal_access == 4) {
                    this.fChipsArray.push('Finance');
                }
            }
        }
    };
    AdminDashboardComponent.prototype.onFilterSpeciality = function (evt, type) {
        var _this = this;
        this.fQuery1 = '?page=1&per_page=10';
        // project search filter
        if ((type == 'search' && evt != '' && evt !== undefined)) {
            this.filterSearchQueryApproval = evt;
            this.fQuery1 += '&search_text=' + this.filterSearchQueryApproval;
        }
        else if (type == 'search' && evt == '') {
            this.filterSearchQueryApproval = evt;
        }
        else if (this.filterSearchQueryApproval !== '') {
            this.fQuery1 += '&search_text=' + this.filterSearchQueryApproval;
        }
        if ((type == 'speciality' && evt.value != '' && evt.value !== undefined)) {
            this.filterSpecialization = evt.value;
            this.fQuery1 += '&specialization=' + this.filterSpecialization;
        }
        else if (type == 'speciality' && evt.value == '') {
            this.filterSpecialization = evt.value;
        }
        else if (this.filterSpecialization !== '') {
            this.fQuery1 += '&specialization=' + this.filterSpecialization;
        }
        if ((type == 's_lang' && evt.value != '' && evt.value !== undefined)) {
            this.filter_src_lang = evt.value;
            this.fQuery1 += '&source_lang=' + this.filter_src_lang;
        }
        else if (type == 's_lang' && evt.value == '') {
            this.filter_src_lang = evt.value;
        }
        else if (this.filter_src_lang !== '') {
            this.fQuery1 += '&source_lang=' + this.filter_src_lang;
        }
        if ((type == 't_lang' && evt.value != '' && evt.value !== undefined)) {
            this.filter_target_lang = evt.value;
            this.fQuery1 += '&target_lang=' + this.filter_target_lang;
        }
        else if (type == 't_lang' && evt.value == '') {
            this.filter_target_lang = evt.value;
        }
        else if (this.filter_target_lang !== '') {
            this.fQuery1 += '&target_lang=' + this.filter_target_lang;
        }
        if ((type == 'native_lang' && evt.value != null && evt.value !== undefined)) {
            this.filter_native_lang = evt.value;
            this.fQuery1 += '&native_lang=' + this.filter_native_lang;
        }
        else if (type == 'native_lang' && evt.value == null) {
            evt.value = '';
            this.filter_native_lang = evt.value;
        }
        else if (this.filter_native_lang != null) {
            this.fQuery1 += '&native_lang=' + this.filter_native_lang;
        }
        var query = this.fQuery1;
        this.parseQueryStringApprove(this.fQuery1);
        this.vendorService.getAdminApprovalPending(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        lang = res.sourcelanguage.data.lang_name + res.bi_direction +
                            res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
                _this.total_vendor = response.meta.pagination.total;
            }
        });
    };
    AdminDashboardComponent.prototype.parseQueryStringApprove = function (queryString) {
        this.fChipsArrayApprove = [];
        var params = {
            native_lang: '',
            source_lang: '',
            target_lang: ''
        }, queries, temp, i, l;
        // Split into key/value pairs
        queries = queryString.split('&');
        // Convert the array of strings into an object
        for (i = 0, l = queries.length; i < l; i++) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        this.get_filter_valueApprove(params);
    };
    AdminDashboardComponent.prototype.get_filter_valueApprove = function (data) {
        var _this = this;
        var key = Object.keys(data);
        if (key[0] == 'native_lang') {
            if (data.native_lang != '') {
                this.languageData.forEach(function (element) {
                    if (element.id == data.native_lang) {
                        _this.fChipsArrayApprove.push(element.lang_name);
                    }
                });
            }
        }
        if (key[1] == 'source_lang') {
            if (data.source_lang != '') {
                this.fChipsArrayApprove.push(data.source_lang);
            }
        }
        if (key[2] == 'target_lang') {
            if (data.target_lang != '') {
                this.fChipsArrayApprove.push(data.target_lang);
            }
        }
    };
    AdminDashboardComponent.prototype.paginateData_approve = function (event) {
        var _this = this;
        var aQuery = '';
        this.pageIndex_approval = event.pageIndex + 1;
        if (this.pageIndex_approval < 0) {
            this.pageIndex_approval = 1;
        }
        this.pageSize_approval = event.pageSize;
        this.query_approve = '?page=' + this.pageIndex_approval + '&per_page=' + this.pageSize_approval;
        if (this.filterSpecialization !== '') {
            aQuery += '&specialization=' + this.filterSpecialization;
        }
        if (this.filter_src_lang !== '') {
            aQuery += '&source_lang=' + this.filter_src_lang;
        }
        if (this.filter_target_lang !== '') {
            aQuery += '&target_lang=' + this.filter_target_lang;
        }
        if (this.filter_native_lang !== null) {
            aQuery += '&native_lang=' + this.filter_native_lang;
        }
        else {
            var filter_native_lang = '';
            aQuery += '&native_lang=' + filter_native_lang;
        }
        this.query_approve += aQuery;
        this.vendorService.getAdminApprovalPending(this.query_approve)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        lang = res.sourcelanguage.data.lang_name +
                            res.bi_direction + res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
                _this.total_vendor = response.meta.pagination.total;
            }
        });
    };
    AdminDashboardComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                _this.specialityData = res;
            }
        });
    };
    AdminDashboardComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
            }
        });
    };
    AdminDashboardComponent.prototype.getUserCount = function () {
        var _this = this;
        this.vendorService.getUserCount()
            .subscribe(function (res) {
            if (res) {
                _this.userCount = res;
            }
        });
    };
    AdminDashboardComponent.prototype.paginateData = function (event) {
        var _this = this;
        var uQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_user = 'page=' + this.pageIndex + '&per_page=' + this.pageSize;
        if (this.filterStatus !== '') {
            uQuery += '&status=' + this.filterStatus;
        }
        if (this.filterRole !== '') {
            uQuery += '&role=' + this.filterRole;
        }
        this.query_user += uQuery;
        this.userService.getAll(this.query_user)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
                _this.total_user = response.meta.pagination.total;
            }
        });
    };
    // user sorting
    AdminDashboardComponent.prototype.sortUser = function (evt) {
        var _this = this;
        if (evt.direction == '') {
            evt.direction = 'asc';
        }
        this.query_user_sort = 'page=' + this.pageIndex + '&per_page=' + this.pageSize + '&sort=' + evt.active + '&order=' + evt.direction;
        this.userService.getAll(this.query_user_sort)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var user_portal = '';
                    elem.user_portal.forEach(function (res) {
                        user_portal += res.access_title + ', ';
                    });
                    elem.user_portal = user_portal.slice(0, -2);
                });
                _this.dataSource = new MatTableDataSource(response.data);
                //   this.dataSource.sort=this.sort;
                _this.paginate = response.meta.pagination;
            }
        });
    };
    // freelancer sorting
    AdminDashboardComponent.prototype.sortFreelancer = function (evt) {
        var _this = this;
        if (evt.direction == '') {
            evt.direction = 'asc';
        }
        this.query_freelancer_sort = '?page=' + this.pageIndex_approval + '&per_page=' + this.pageSize_approval + '&sort=' + evt.active + '&order=' + evt.direction;
        this.vendorService.getAdminApprovalPending(this.query_freelancer_sort)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        lang = res.sourcelanguage.data.lang_name + res.bi_direction +
                            res.targetlanguage.data.lang_name + '  Rate:$' + translation_rate_value + '/word' + ' Speed:' + res.speed + ' words' + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
                _this.total_vendor = response.meta.pagination.total;
            }
        });
    };
    AdminDashboardComponent.prototype.editData = function (value) {
        this.router.navigate(['/user-management/' + value.id]);
    };
    return AdminDashboardComponent;
}());
export { AdminDashboardComponent };
