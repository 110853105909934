import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
var ReviewTranslatorComponent = /** @class */ (function () {
    function ReviewTranslatorComponent(router, route, vendorService, toastr, _formBuilder, _fuseNavigationService) {
        this.router = router;
        this.route = route;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this._formBuilder = _formBuilder;
        this._fuseNavigationService = _fuseNavigationService;
        this.data_update = {};
        this.comment_data = {};
        this.coa_array = [];
        this.response_data = {
            tool_licence: '',
            vendoravailabilities: {
                data: []
            },
            vendorspecialities: {
                data: []
            },
            country: '',
            first_name: '',
            last_name: '',
            reference_id: '',
            email: '',
            skype_id: '',
            address1: '',
            city: '',
            state: '',
            postal_code: '',
            payment_method: '',
            translation_tools: '',
            education: '',
            cover_letter: '',
            vendornativelanguage: {}
        };
        this.BaseUrl_file = environment.baseUrl_file;
    }
    ReviewTranslatorComponent.prototype.ngOnInit = function () {
        this.id = parseInt(this.route.snapshot.params['id']);
        this.getApproval();
        this.Form = this._formBuilder.group({
            comment_admin: ['']
        });
    };
    ReviewTranslatorComponent.prototype.submitDataApprove = function (val) {
        var _this = this;
        this.comment_data.comment_admin = this.Form.value.comment_admin;
        this.comment_data.stage_id = val;
        this.comment_data.status = 1;
        this.vendorService.update(this.id, this.comment_data)
            .subscribe(function (response) {
            if (response) {
                _this.data_update = response;
                _this.router.navigate(['/approval']);
                _this.toastr.success("Approved");
            }
        });
    };
    ReviewTranslatorComponent.prototype.submitDataReject = function (val) {
        var _this = this;
        this.comment_data.comment_admin = this.Form.value.comment_admin;
        this.comment_data.stage_id = val;
        this.comment_data.status = 0;
        this.vendorService.update(this.id, this.comment_data)
            .subscribe(function (response) {
            if (response) {
                _this.data_update = response;
                _this.router.navigate(['/approval']);
                _this.toastr.success("Rejected");
            }
        });
    };
    ReviewTranslatorComponent.prototype.getApproval = function () {
        var _this = this;
        this.vendorService.get(this.id)
            .subscribe(function (response) {
            if (response) {
                var day_1 = '';
                response.data.vendoravailabilities.data.forEach(function (elem) {
                    day_1 += elem.day_name + ', ';
                    _this.day_name_test = day_1;
                    _this.day_name_test = _this.day_name_test.slice(0, -2);
                });
                var specialities_1 = '';
                response.data.vendorspecialities.data.forEach(function (elem) {
                    specialities_1 += elem.specialities.data.name + ', ';
                    _this.specialities_test = specialities_1;
                    _this.specialities_test = _this.specialities_test.slice(0, -2);
                });
                var lang = '';
                response.data.vendortranslationlangs.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.lang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                });
                var subtitlerslang = '';
                response.data.vendorSubtitler.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.subtiterslang_data = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                    elem.subtitle_rate = elem.subtitle_rate;
                });
                var treplang = '';
                response.data.vendorTreping.data.forEach(function (elem) {
                    if (elem.bi_direction == 2) {
                        elem.bi_direction = '<>';
                    }
                    else {
                        elem.bi_direction = '>';
                    }
                    elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
                });
                var skills_1 = '';
                if (response.data.vendorskill.data.length > 0) {
                    response.data.vendorskill.data.sort(function (a, b) {
                        return a.skill_id - b.skill_id;
                    });
                    response.data.vendorskill.data.forEach(function (elem) {
                        if (elem.skill !== undefined) {
                            skills_1 += elem.skill.data.name + ', ';
                            _this.skills = skills_1;
                            _this.skills = _this.skills.slice(0, -2);
                        }
                    });
                }
                if (response.data.vendorcoafiles.data.length != 0) {
                    response.data.vendorcoafiles.data.forEach(function (element) {
                        var coa_url = _this.BaseUrl_file + element.coafile.data.path;
                        var coa_name_path = element.coafile.data.path.split('/');
                        var coa_name = coa_name_path[2];
                        var coa_obj = {
                            url: coa_url,
                            name: coa_name
                        };
                        // this.coa_id_array()
                        _this.coa_array.push(coa_obj);
                    });
                }
                if (response.data.extradocument != null) {
                    _this.vendor_exta_document_data = _this.BaseUrl_file + response.data.extradocument.data.path;
                    _this.vendor_exta_document_name = response.data.extradocument.data.name;
                }
                _this.vendorcomment_data = response.data.vendorcomment.data;
                _this.response_data = response.data;
                if (_this.response_data.address2 != null || _this.response_data.address2 != " ") {
                    _this.address_test = _this.response_data.address1 + ', ' + _this.response_data.address2;
                }
                else {
                    _this.address_test = _this.response_data.address1;
                }
                response.data.vendorprofileimage.data.forEach(function (element) {
                    _this.vendor_file_data = _this.BaseUrl_file + element.path;
                });
                response.data.vendorcontract.data.forEach(function (element) {
                    _this.vendor_contract_data = _this.BaseUrl_file + element.path;
                    var x = element.path.split('/');
                    _this.vendor_contract_name = x[2];
                });
                response.data.vendorresume.data.forEach(function (element) {
                    _this.vendor_resume_data = _this.BaseUrl_file + element.path;
                    var x = element.path.split('/');
                    _this.vendor_resume_name = x[2];
                });
                _this.Form.patchValue({
                    comment_admin: _this.response_data.comment_admin
                });
            }
        });
    };
    ReviewTranslatorComponent.prototype.back = function () {
        this.router.navigate(['approval']);
    };
    return ReviewTranslatorComponent;
}());
export { ReviewTranslatorComponent };
