<div class="dialog-content-wrapper"  style="position:relative;">
  <div class="tHeader">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
  <div fxFlex>
      <h4 class="m0 title dialog-title text-uppercase">Change Languages</h4>
    </div>
    <div fxFlex class="align-right">
        <button mat-icon-button (click)="Onclose()"  matTooltip="Close" aria-label="Close dialog">
               <mat-icon>close</mat-icon>
        </button>
      </div>
      </div>
  </div>

  
  <div mat-dialog-content class="p-16 pt-4 m-0" fusePerfectScrollbar>
    <form class="language-popup"
    name="form" [formGroup]="Form">
      <!-- <div fxLayout="row" class="mt-8" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field   fxFlex="100">
          <mat-label>Source Language*</mat-label>
          <mat-select multiple placeholder="Select Field" formControlName="source_language"
           disableOptionCentering class="myPanelClass">
            <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
              {{language.lang_name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="Form.get('source_language').hasError('required')">
            Source Language is required!
          </mat-error>
        </mat-form-field>
      </div> -->
     
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="mt-12">
        <div fxFlex="100">
        <p class="dLabel">Source Language*</p>
          <ng-multiselect-dropdown-angular7
          [data]="dropdownList"
          formControlName="source_language"
          [settings]="dropdownSettings"
          (onSelect)="onItemSelect($event)"
          (onDeSelect)="onItemDeSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown-angular7>
        </div>
      </div>
      <!-- <div fxLayout="row" class="mt-8" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field   fxFlex="100">
          <mat-label>Target Language*</mat-label>
          <mat-select multiple placeholder="Select Field" formControlName="target_language" 
          disableOptionCentering class="myPanelClass">
            <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
              {{language.lang_name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="Form.get('target_language').hasError('required')">
            Target Language is required!
          </mat-error>
        </mat-form-field>
    </div> -->
   
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="mt-12">
      <div  fxFlex="100">
      <p class="dLabel">Target Language*</p>
      <ng-multiselect-dropdown-angular7
            [data]="dropdownList"
            formControlName="target_language"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="onItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
        >
      </ng-multiselect-dropdown-angular7>
    </div>
  </div> 

    <div fxLayoutAlign="space-between end" class=" mt-32 mb-4 ml-0" >
        
          <button mat-raised-button color="primary" (click)="submit()" [disabled]="Form.invalid">Update</button>
        
       </div>
  
      </form>
   
  </div>    

</div>