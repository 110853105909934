import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
var TransactionsComponent = /** @class */ (function () {
    function TransactionsComponent(router, route) {
        this.router = router;
        this.route = route;
    }
    TransactionsComponent.prototype.ngOnInit = function () {
        this.quote_id = this.route.snapshot.params['id'];
    };
    TransactionsComponent.prototype.searchTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/transactions/search/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/transactions/search']);
        }
    };
    TransactionsComponent.prototype.CreateTransaction = function () {
        if (this.quote_id != null) {
            this.router.navigate(['/pms/transactions/create/' + this.quote_id]);
        }
        else {
            this.router.navigate(['/pms/transactions/create']);
        }
    };
    return TransactionsComponent;
}());
export { TransactionsComponent };
