import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notification_count:number=0;
  notification_response:any=[];
  paginate: any = {};
  pageIndex: number = 1;
  pageSize: number = 5;
  query: string = "";
  constructor(private projectsService: ProjectsService,private toastr: ToastrService,) { }

  ngOnInit() {
    this.getAllNotifications();
  }

  getAllNotifications(){
    let Query = ''

    this.projectsService.allNotification(Query)
        .subscribe((response:any) => {
            this.notification_response = [];
            response.data.forEach(element => {
                if(element.type=="App\\Notifications\\ProjectCreatedNotification"){
                    if(element.data.reference){
                        let message=" New "+ element.data.service_type+ " Request for Language "+ element.data.source_language+" - " + element.data.target_language+ " Received";
                        this.notification_response.push(message);
                    }
                }

                if(element.type=="App\\Notifications\\ProjectQuoteCreatedNotification"){
                    if(element.data.due_date){
                        let lang = '';
                            element.data.language_pair.forEach(res => {
                                lang += res + ', ' ;
                            });
                            lang = lang.slice(0, -2);
                        let message=lang + ' Translation request due on ' + element.data.due_date;
                       
                        this.notification_response.push(message);
                    }
                }

                if(element.type=="App\\Notifications\\AssignmentDeclinedNotification"){

                    if(element.data.reference_number){
                        let message= element.data.reference_number+ " assignment was declined by "+ element.data.freelance_name;
                        this.notification_response.push(message);
                    }
                }

                if(element.type=="App\Notifications\AssignmentAcceptNotification"){
                    if(element.data.reference_number){
                        let message= element.data.freelance_name+ " accepted "+ element.data.reference_number +" assignment due on "+ element.data.reference_number;
                        this.notification_response.push(message);
                    }
                }

                if(element.type=="App\\Notifications\\ProjectStatusUpdateNotification"){
                    if(element.data.content){
                        this.notification_response.push(element.data.content);
                    }
                }
            });
        this.paginate = response.meta.pagination;
        this.notification_count = response.meta.pagination.total;
      }
    //   , (error) => {
    //       this.toastr.error(error);
          
    //   }
      );

}


paginateData(event) {
    let uQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
        this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;

    this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;

    this.query += uQuery;


    this.projectsService.allNotification(this.query)
    .subscribe((response:any) => {
        this.notification_response = [];

        response.data.forEach(element => {
            if(element.type=="App\\Notifications\\ProjectCreatedNotification"){
                if(element.data.reference){
                    let message=" New "+ element.data.service_type+ " Request for Language "+ element.data.source_language+" - " + element.data.target_language+ " Received";
                    this.notification_response.push(message);
                }
            }

            if(element.type=="App\\Notifications\\ProjectQuoteCreatedNotification"){
                if(element.data.due_date){
                    let lang = '';
                        element.data.language_pair.forEach(res => {
                            lang += res + ', ' ;
                        });
                        lang = lang.slice(0, -2);
                    let message=lang + ' Translation request due on ' + element.data.due_date;
                   
                    this.notification_response.push(message);
                }
            }

            if(element.type=="App\\Notifications\\AssignmentDeclinedNotification"){

                if(element.data.reference_number){
                    let message= element.data.reference_number+ " assignment was declined by "+ element.data.freelance_name;
                    this.notification_response.push(message);
                }
            }

            if(element.type=="App\Notifications\AssignmentAcceptNotification"){
                if(element.data.reference_number){
                    let message= element.data.freelance_name+ " accepted "+ element.data.reference_number +" assignment due on "+ element.data.reference_number;
                    this.notification_response.push(message);
                }
            }

            if(element.type=="App\\Notifications\\ProjectStatusUpdateNotification"){
                if(element.data.content){
                    this.notification_response.push(element.data.content);
                }
            }
        });
    this.paginate = response.meta.pagination;
    this.notification_count = response.meta.pagination.total;
  }
//   , (error) => {
//       this.toastr.error(error);
      
//   }
  );
}


}
