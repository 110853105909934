<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
<!-- app-sms-conversation -->
 <app-sms-conversation></app-sms-conversation> 
 <!-- app-sms-conversation -->

<!-- <div class="white-box">
  <div class="tHeader">
    <h2 style="color:orange">Send SMS</h2>
    </div>
<div class="boxBodyInn">
  <form name="smsForm" [formGroup]="smsForm">
  <div fxLayout="row wrap" fxLayoutGap="20px">
    <div fxFlex="36" class="m-8">
      <mat-form-field  >
        <mat-label>Phone Number*</mat-label>
        <input matInput formControlName="to">
        
        <mat-error *ngIf="smsForm.get('to').hasError('required')">
          Phone is required!
      </mat-error>
      </mat-form-field>
      <p>Enter Mobile number with country code e.g +12345678901</p>
    </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="20px">
      <div fxFlex="64" class="m-8">
        <mat-form-field  >
          <mat-label>Message*</mat-label>
          <textarea matInput formControlName="body"></textarea>
          <mat-error *ngIf="smsForm.get('body').hasError('required')">
            Message is required!
        </mat-error>
        </mat-form-field>
      </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="20px">
      <div fxFlex="20" class="m-8 ">
        <button mat-stroked-button class="green-bg Text_Color" (click)="sendSms()"  [disabled]="smsForm.invalid">Send Sms</button>
      </div>
      </div>
   </form>   
  </div>
  </div> -->


<!-- <div class="white-box">
  <div class="tHeader">
    <h2>SMS History :</h2>
    </div>
  <div class="boxBodyInn2" >
    <form name="Form" [formGroup]="Form">
      <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
        <div fxFlex="32" class="m-8">
          <mat-form-field  >
            <mat-label>Search</mat-label>
            <input matInput formControlName="search_value">
          </mat-form-field>
        </div>
        <div fxFlex="20">
          <button mat-stroked-button color="primary" (click)="submit()" [disabled]="Form.invalid"><i
              class="material-icons">search</i></button>
        </div>
      </div>
    </form>
  
  <div >
    <div *ngIf='mainProgressbarhistory' class="cssloadcube-overlay2">
      <img src="assets/images/preloader_logo.gif" />
  </div>
  <table mat-table [dataSource]="dataSource" class="table" >
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef fxFlex="8">Id</mat-header-cell>
        <mat-cell *matCellDef="let product; let i =index;"   fxFlex="8">
          <div class="text-truncate"> {{i+1}}</div> 
        </mat-cell>
    </ng-container>
  <ng-container matColumnDef="from">
        <mat-header-cell *matHeaderCellDef fxFlex="10">From</mat-header-cell>
            <mat-cell *matCellDef="let product"  fxFlex="10">
                <div class="text-truncate">{{product?.from}}</div>
            </mat-cell>
      </ng-container>
      <ng-container matColumnDef="to">
        <mat-header-cell *matHeaderCellDef  fxFlex="10"> To</mat-header-cell>
          <mat-cell *matCellDef="let product"  fxFlex="10">
            <div class="text-truncate">{{product?.to}}</div> 
          </mat-cell>
      </ng-container>
      <ng-container matColumnDef="body">
        <mat-header-cell *matHeaderCellDef  fxFlex="50"> Body</mat-header-cell>
          <mat-cell *matCellDef="let product"  fxFlex="50">
            <div class="text-truncate">{{product?.body}}</div> 
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef fxFlex="10">Created At</mat-header-cell>
          <mat-cell *matCellDef="let product"   fxFlex="10">
            <div class="text-truncate">{{product?.created_at.date | date:'yyyy-MM-dd'}}</div> 
          </mat-cell>
      </ng-container>
   


      <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
      <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
      </mat-row>
  </table>
  <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[5,10,20,30,40,50,60]" (page)='paginateData($event)'>
    </mat-paginator>
  </div>
   
  </div>
  <div *ngIf="paginate.total==0" class="noDataFound"> No data available!!
  </div>
  </div> -->
  
</div>