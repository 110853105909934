<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
  <div class="white-box">
    <div class="tHeader">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">

        <h2>Search Braintree Transaction</h2>

        <div class="text-right">
          <button mat-button color="accent" (click)="CreateTransaction()"><span
              class="material-icons">queue</span>Create Braintree Payment
          </button>
        </div>
      </div>
    </div>







    <div class="boxBody">
      <div class="tabWraper">
        <div class="p20">
          <form name="searchTransactionForm" [formGroup]="searchTransactionForm" (ngSubmit)="search();">
            <div class="box-blank p20" fxFlexLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex>
                <mat-form-field>
                  <mat-select formControlName="field_name">
                    <mat-option value="">Select field Name</mat-option>
                    <mat-option value="email">Customer Email</mat-option>
                    <mat-option value="id" selected="option2">Transaction ID</mat-option>
                    <mat-option value="cardholderName"> Cardholder Name</mat-option>
                    <mat-option value="creditCardNumber">Credit Card Number</mat-option>
                    <mat-option value="firstName"> Customer First Name</mat-option>
                    <mat-option value="lastName">Customer Last Name</mat-option>
                    <mat-option value="company">Customer Company</mat-option>
                  </mat-select>
                  <mat-error *ngIf="searchTransactionForm.get('field_name').hasError('required')">
                    field name is required!
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxFlex="20">
                <mat-form-field>
                  <mat-select formControlName="operator">
                    <mat-option value=""> Select Operator</mat-option>
                    <mat-option value="is"> is</mat-option>
                    <mat-option value="isNot"> is not</mat-option>
                    <mat-option value="startsWith"> starts with</mat-option>
                    <mat-option value="endsWith">ends with</mat-option>
                    <mat-option value="contains"> contains</mat-option>
                  </mat-select>
                  <mat-error *ngIf="searchTransactionForm.get('operator').hasError('required')">
                    operator is required!
                  </mat-error>

                </mat-form-field>
              </div>

              <div fxFlex="40">
                <mat-form-field>
                  <mat-label>Value</mat-label>
                  <input matInput formControlName="field_value">
                  <mat-error *ngIf="searchTransactionForm.get('field_value').hasError('required')">
                    value is required!
                  </mat-error>
                </mat-form-field>

              </div>

              <div fxFlex>
                <button type="submit" [disabled]="(!searchTransactionForm.valid)" mat-raised-button
                  color="accent">Search <mat-icon matSuffix>search</mat-icon></button>
              </div>
            </div>
          </form>

          <!-- <div class="transSearch">
                
                  <div class="boxBody" >
               
             
                  </div>
                
                </div> -->

          <div class="boxBody">
            <div class="boxInn" *ngIf="show_record">

              <table mat-table [dataSource]="dataSource" class="table">
                <!-- Name Column -->
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> Id
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                    <div fxLayout="column">

                      <p class="text-truncate">
                        {{product?.id}}
                      </p>

                    </div>
                  </mat-cell>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="amount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8"> Amount
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                    <p class="text-truncate">{{product?.amount}}</p>

                  </mat-cell>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20"> Status
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                    <p class="text-truncate">{{product.status}}</p>

                  </mat-cell>
                </ng-container>


                <!-- Symbol Column -->

                <ng-container matColumnDef="cardholderName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8"> Customer
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                    <span>
                      {{product.cardholder_name}}
                    </span>

                  </mat-cell>
                </ng-container>

                <!-- Symbol Column -->

                <ng-container matColumnDef="creditCardNumber">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="16"> Card Number
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="16">
                    <img [src]="product?.image_url" width="30">
                    <!-- /// {{product?.bin}} **** {{product?.last4}} -->
                    **** **** {{product?.last4}}
                  </mat-cell>
                </ng-container>

                <!-- Symbol Column -->

                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="24"> Email
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="24">
                    <p class="text-truncate">{{product?.email}}</p>
                  </mat-cell>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="12"> Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                    <p class="text-truncate">{{product?.date}}</p>
                  </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
                </mat-row>
              </table>
              <mat-paginator [length]="totaltransaction" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>

              <!-- <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[20,30,40,50,60]" (page)='paginateData($event)'>
                          </mat-paginator> -->
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>