<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" (click)="back()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
      {{RequestQueue_response?.reference}}
      <span class="prfInfo">
        <!-- <span *ngIf="quote_status==null"> <b>Time Elapsed:</b> {{TimeElapsed}}</span> -->
        <!-- <span class="prfo" *ngIf="quote_status==null"><b>Due in:</b>
          <span class="hText" [ngClass]="( checkdueTime == true)? 'hGreen' : 'hRed'"> {{DueTime}} </span>

        </span> -->
        <span class="prfo">
          <span class="pl-4 hText hGreen" *ngIf="RequestQueue_response?.projectstage?.data?.pm_status!=null">Status
            <b>{{RequestQueue_response?.projectstage.data?.pm_status}}</b></span>
        </span>

        <span class="prfo" *ngIf="RequestQueue_response?.status?.data?.id>1">
          <span class="pl-4 hText hGreen">
            <b>{{RequestQueue_response?.status?.data?.name}}</b></span>
        </span>
      </span>


    </span>
  </div>

  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="75%">
      <div class="white-box">
        <div class="tHeader wMenu">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex>
              <h2>Quote Summary</h2>
            </div>
            <div fxFlex class="text-right">
              <mat-slide-toggle [(ngModel)]="activeInternal" (change)="markAsRushProject($event)">Mark As Rush Project</mat-slide-toggle>
              <!-- <button mat-button *ngIf="CrmUrl">
                <a [href]="CrmUrl" target="_blank"><i class="material-icons">open_in_new</i> Open in CRM</a>
              </button> -->

              <button mat-button [matMenuTriggerFor]="menu" class="mpw0"><i class="f30 material-icons">
                  more_vert
                </i></button>
              <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item>Create Duplicate</button> -->
                <button mat-menu-item (click)="openDialogDeleteProject()">Delete Project</button>
                <!-- <button mat-menu-item> Add Expenses</button>
                <button mat-menu-item> Update Status</button> -->
                <button mat-menu-item (click)="doNotFollowUp()"> Donot Follow Up</button>
                <button mat-menu-item [matMenuTriggerFor]="markAs">Mark as</button>
                <!-- <button mat-menu-item> Move to Pipedrive</button> -->

              </mat-menu>

              <mat-menu #markAs="matMenu">
                <div *ngFor="let res of MarkAsStatus">
                  <button mat-menu-item *ngIf="res.name!=''" (click)="updateMarkAs(res.id)"> {{res.name}}</button>
                </div>
                <!-- <button mat-menu-item> Mark as Invalid</button>
                <button mat-menu-item> Mark as Duplicate Request</button>
                <button mat-menu-item> Mark as Sample/Free</button>
                <button mat-menu-item> Mark as Do Not Use</button>
                <button mat-menu-item> Mark as Closed Lost</button>
                <button mat-menu-item> Mark as Closed Won</button>
                <button mat-menu-item> Mark as Closed Free</button>
                <button mat-menu-item> Mark as Closed Paid</button>
                <button mat-menu-item> Mark as Manual Invoicing</button>
                <button mat-menu-item> Mark as Collections</button> -->
              </mat-menu>

              <!-- <mat-menu #markAs="matMenu">
                <button mat-menu-item> Mark as Invalid</button>
                <button mat-menu-item> Mark as Duplicate Request</button>
                <button mat-menu-item> Mark as Sample/Free</button>
                <button mat-menu-item> Mark as Do Not Use</button>
                <button mat-menu-item> Mark as Closed Lost</button>
                <button mat-menu-item> Mark as Closed Won</button>
                <button mat-menu-item> Mark as Closed Free</button>
                <button mat-menu-item> Mark as Closed Paid</button>
                <button mat-menu-item> Mark as Manual Invoicing</button>
                <button mat-menu-item> Mark as Collections</button>
              </mat-menu> -->
            </div>


          </div>

        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex>
                <p class="dLabel">Service Type</p>
                <p class="dValue" *ngIf="RequestQueue_response?.translation_type"><span class="bdg blank bg-yellow">
                    {{RequestQueue_response?.translation_type}}</span>
                  <!-- span class="bdg blank tlink" title="Edit" (click)="openDialogServiceType()">
                      <mat-icon>
                        edit
                      </mat-icon> Edit
                    </span -->
                </p>
              </div>
              <div class="item item-1" fxFlex>
                <p class="dLabel">Source Language(s)</p>
                <p class="dValue">{{source_language_value}}
                  <!--span class="bdg blank tlink" title="Edit" (click)="openDialogLanguage()">
                    <mat-icon>
                      edit
                    </mat-icon> Edit
                  </span -->
                </p>
              </div>
              <div class="item item-2" fxFlex>
                <p class="dLabel">Target Language(s)</p>
                <p class="dValue">{{target_language_value}}
                  <!-- <span class="bdg blank tlink" title="Edit" (click)="openDialogLanguage()">
                    <mat-icon>
                      edit
                    </mat-icon> Edit
                  </span> -->
                </p>
              </div>
              <div class="item item-4" fxFlex>
                <p class="dLabel">Deadline</p>
                <p class="dValue">{{RequestQueue_response?.due_date?.date | date: 'yyyy-MM-dd'}}
                  <!-- {{RequestQueue_response?.due_date?.date | date:"HH:mm"}} -->
                  <!-- <span class="bdg blank tlink" title="Edit" (click)="openDialogDeadline()">
                      <mat-icon>
                        edit
                      </mat-icon> Edit
                    </span> -->
                </p>
              </div>
              <div class="item item-4" fxFlex>
                <p class="dLabel">Inquiry</p>
                <p class="dValue">{{RequestQueue_response?.inquiry_date | date: 'yyyy-MM-dd'}}
                </p>
              </div>
              <div class="item item-4" fxFlex>
                <p class="dLabel">Lead Source</p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Campaign'">
                  <span class="bdg blank bg-yellow">Campaign</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Cold Call'">
                  <span class="bdg blank bg-yellow">Cold Call</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Conference'">
                  <span class="bdg blank bg-yellow">Conference</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Craigslist'">
                  <span class="bdg blank bg-yellow">Craigslist Bid</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Contact_Us_Form'">
                  <span class="bdg blank bg-yellow">Contact Us Form</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Direct Mail'">
                  <span class="bdg blank bg-yellow">Direct Mail</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='DT_Web_QAPS'">
                  <span class="bdg blank bg-yellow">DT-Web-QAPS</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Email'">
                  <span class="bdg blank bg-yellow">Email</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Employee'">
                  <span class="bdg blank bg-yellow">Employee</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Fax'">
                  <span class="bdg blank bg-yellow">Fax</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='App'">
                  <span class="bdg blank bg-yellow">Mobile App</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Office'">
                  <span class="bdg blank bg-yellow">Offices</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Outreach'">
                  <span class="bdg blank bg-yellow">Outreach</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Other'">
                  <span class="bdg blank bg-yellow">Other</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Partner'">
                  <span class="bdg blank bg-yellow">Partner</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Public Relations'">
                  <span class="bdg blank bg-yellow">Public Relations</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Self Generated'">
                  <span class="bdg blank bg-yellow">Self Generated</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Social_Media'">
                  <span class="bdg blank bg-yellow">Social Media</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='SMS'">
                  <span class="bdg blank bg-yellow">SMS</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Trade Show'">
                  <span class="bdg blank bg-yellow">Trade Show</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Web Site'">
                  <span class="bdg blank bg-yellow">Web Site</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Word of mouth'">
                  <span class="bdg blank bg-yellow">Word of mouth</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='SalesLinkedIn'">
                  <span class="bdg blank bg-yellow">SALES</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value=='Chat'">
                  <span class="bdg blank bg-yellow">Chat</span>
                </p>
                <p class="dValue" *ngIf="DetailMainResponse?.lead_source?.value==''">
                  <span class="bdg blank bg-yellow">NA</span>
                </p>
              </div>
            </div>
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <!-- <div class="item item-3" fxFlex="25">
                <p class="dLabel">Specialization</p>
                <p class="dValue" *ngIf="RequestQueue_response?.specilization_id==null">
                   <span class="bdg blank tlink" title="Edit" (click)="openDialog()">
                    <mat-icon>
                      edit
                    </mat-icon> Add
                  </span> 
                </p>

                <p class="dValue" *ngIf="RequestQueue_response?.specilization?.data?.name">
                  {{RequestQueue_response?.specilization?.data?.name}}
                   <span class="bdg blank tlink" title="Edit" (click)="openDialog()">
                    <mat-icon>
                      edit
                    </mat-icon> Edit
                  </span> 
                </p>
              </div> -->
              <div class="item item-1" fxFlex="25" *ngIf="RequestQueue_response?.promo_code">
                <p class="dLabel">Promo Code</p>
                <p><span class="promoInput"><i class="material-icons">new_releases</i>
                    {{RequestQueue_response?.promo_code}}</span></p>
              </div>


              <!-- <div class="item item-1" fxFlex="25" *ngIf="RequestQueue_response?.crm_type">
                <p class="dLabel">Name Correction</p>
                <p>{{RequestQueue_response?.name_correction}}</p>
              </div> -->

              <!-- <div class="item item-1" fxFlex="25" *ngIf="RequestQueue_response?.crm_type">
                <p class="dLabel">Language Dialect</p>
                <p>{{RequestQueue_response?.language_dialect}}</p>
              </div> -->



            </div>
            <!--Crm Informatin start-->
            <div class="box-blank">
              <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="5px">
                <div>
                  <h4>CRM Information</h4>
                </div>

                <div *ngIf="CrmUrl" class="text-right mt-16">
                  <a [href]="CrmUrl" target="_blank"><i class="material-icons">open_in_new</i> Open this project in
                    CRM</a>
                </div>

              </div>



              <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="15px">


                <div class="item" fxFlex="20" *ngIf="RequestQueue_response?.crm_type">
                  <p class="dLabel">Project Type</p>
                  <p>{{RequestQueue_response?.crm_type | titlecase}}</p>
                </div>

                <div class="item" fxFlex="60">

                  <p class="dLabel"> Project Info </p>
                  <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px">
                    <div fxFlex="70">
                      <mat-form-field *ngIf="RequestQueue_response?.crm_type=='lead'">
                        <mat-label>CRM Status</mat-label>
                        <mat-select [(ngModel)]="crmStatusObject.lead_status" [ngModelOptions]="{standalone: true}"
                          (selectionChange)="crmStatusChange($event)">
                          <mat-option value="">
                            Select
                          </mat-option>
                          <mat-option value="Alliance">Aliance</mat-option>
                          <mat-option value="Assigned">Assigned</mat-option>
                          <mat-option value="Prelim_Price_Quote_BD">Backdated Preliminary Price Quote</mat-option>
                          <mat-option value="Prelim_Price_Quote_SP_BD">Backdated Preliminary Price Quote SP</mat-option>
                          <mat-option value="Price_Quote_BD">Backdated Price Quote</mat-option>
                          <mat-option value="Price_Quote_SP_BD">Backdated Price Quote SP</mat-option>
                          <mat-option value="Blacklisted">Blacklisted</mat-option>
                          <mat-option value="Converted">Converted</mat-option>
                          <mat-option value="Dead">Dead</mat-option>
                          <mat-option value="Duplicate_Request">Duplicate Request</mat-option>
                          <mat-option value="In Process">In Process</mat-option>
                          <mat-option value="Invalid">Invalid</mat-option>
                          <mat-option value="Invoicing">Invoicing</mat-option>
                          <mat-option value="New">New</mat-option>
                          <mat-option value="Perception_Analysis">Pending PMT to assign </mat-option>
                          <mat-option value="Pending_Verification">Pending Verification </mat-option>
                          <mat-option value="Prelim_Price_Quote">Preliminary Price Quote</mat-option>
                          <mat-option value="Prelim_Price_Quote_SP">Preliminary Price Quote SP</mat-option>
                          <mat-option value="Price_Quote">Price Quote</mat-option>
                          <mat-option value="Price_Quote_SP">Price Quote - SP</mat-option>
                          <mat-option value="Recycled">Recycled</mat-option>
                          <mat-option value="Registered_Company">Registered Client/Company</mat-option>
                          <mat-option value="Registered_Personal">Registered Client/Personal</mat-option>
                          <mat-option value="Sample_Free">Sample/Free</mat-option>
                          <mat-option value="Temporary">Temporary</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field *ngIf="RequestQueue_response?.crm_type=='Opportunity'">
                        <mat-label>Stage</mat-label>
                        <mat-select [(ngModel)]="crmStatusObject.opp_stage" [ngModelOptions]="{standalone: true}"
                          (selectionChange)="crmStatusChange($event)">
                          <mat-option value="">
                            Select
                          </mat-option>
                          <mat-option value="Prelim_Proposal_Price_Quote_BD">Backdated Preliminary Proposal/Price Quote
                          </mat-option>
                          <mat-option value="Prelim_Proposal_Price_Quote_SP_BD">Backdated Preliminary Proposal/Price
                            Quote SP</mat-option>
                          <mat-option value="Proposal_Price_Quote_BD">Backdated Proposal/Price Quote</mat-option>
                          <mat-option value="Proposal_Price_Quote_SP_BD">Backdated Proposal/Price Quote SP</mat-option>
                          <mat-option value="Closed_Free">Closed Free</mat-option>
                          <mat-option value="Closed Lost">Closed Lost</mat-option>
                          <mat-option value="Closed_Paid">Closed Paid</mat-option>
                          <mat-option value="Closed Won">Closed Won</mat-option>
                          <mat-option value="Collections">Collections</mat-option>
                          <mat-option value="Do_NotUse">Do Not Use</mat-option>
                          <mat-option value="Duplicate_Request">Duplicate Request</mat-option>
                          <mat-option value="Invalid">Invalid</mat-option>
                          <mat-option value="Invoicing">Invoicing</mat-option>
                          <mat-option value="Needs Analysis">NET30</mat-option>
                          <mat-option value="Perception Analysis">Pending PMT to assign</mat-option>
                          <mat-option value="Negotiation/Review">Pending PMT to deliver</mat-option>
                          <mat-option value="Pending_Verification">Pending Verification </mat-option>
                          <mat-option value="Prelim_Proposal_Price_Quote">Preliminary Proposal/Price Quote</mat-option>
                          <mat-option value="Prelim_Proposal_Price_Quote_SP">Preliminary Proposal/Price Quote SP
                          </mat-option>
                          <mat-option value="Proposal/Price Quote">Proposal/Price Quote</mat-option>
                          <mat-option value="Proposal_Price_Quote_SP">Proposal/Price Quote - SP</mat-option>
                          <mat-option value="Prospecting">Prospecting</mat-option>
                          <mat-option value="Sample_Free">Sample/Free</mat-option>
                          <mat-option value="unconfirmed_invoicing">Unconfirmed Invoicing </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div>
                      <button mat-stroked-button color="accent" type="button" [disabled]="checkCrmStatus"
                        (click)="updateCrmStatus()">Update</button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="box">
                <p class="bLabel">CRM Notes</p>
                <div [innerHTML]="RequestQueue_response?.note | keepHtml"></div>
                <!-- <p>{{RequestQueue_response?.note}}</p> -->
              </div>

              <div class="row" *ngIf="DetailMainResponse?.length != 0 ">
                <div class="col-sm-3" *ngIf="DetailMainResponse?.project_discount_c?.value !='' ">
                  <p class="dLabel">Project Discount</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_discount_c?.value=='5percent_dayvip'">
                    <span class="bdg blank bg-yellow">5% DayVIP</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_discount_c?.value=='5percent_newsletter'">
                    <span class="bdg blank bg-yellow">5% Newsletter</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_discount_c?.value=='5percent_other'">
                    <span class="bdg blank bg-yellow">5% Other</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_discount_c?.value=='10percent_code'">
                    <span class="bdg blank bg-yellow">10% Code</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_discount_c?.value=='10percent_alliance'">
                    <span class="bdg blank bg-yellow">10% Alliance</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_discount_c?.value=='other'">
                    <span class="bdg blank bg-yellow">Other</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_discount_c?.value==''">
                    <span class="bdg blank bg-yellow">NA</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_proj_dtdiscount_c?.value !='' ">
                  <p class="dLabel">Project Discount Code</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_proj_dtdiscount_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_proj_dtdiscount_c?.value}}</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_pricematch_rate_c?.value !='' ">
                  <p class="dLabel">Price Match Rate</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_pricematch_rate_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_pricematch_rate_c?.value}}</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_t9n_rate_c?.value !='' ">
                  <p class="dLabel">Translation Rate</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_t9n_rate_c?.value"><span class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_t9n_rate_c?.value}}</span>
                  </p>
                </div>
  
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_i12n_rate_c?.value !='' ">
                  <p class="dLabel">Interpretation Rate</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_i12n_rate_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_i12n_rate_c?.value}}</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_t9n_wordcount_c?.value !='' ">
                  <p class="dLabel">Word Count</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_t9n_wordcount_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_t9n_wordcount_c?.value}}</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.cstm_page_count_c?.value !='' ">
                  <p class="dLabel">Number Of Pages</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.cstm_page_count_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.cstm_page_count_c?.value}}</span>
                  </p>
                </div>
                <!-- <div class="col-sm-3" *ngIf="DetailMainResponse.sprj1_source_lang_c.value !='' ">
                  <p class="dLabel">Source Language</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_source_lang_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_source_lang_c?.value}}</span>
                  </p>
                </div>
  
                <div class="col-sm-3" *ngIf="DetailMainResponse.sprj1_target_lang_c.value !='' ">
                  <p class="dLabel">Target Language</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_target_lang_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_target_lang_c?.value}}</span>
                  </p>
                </div> -->
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_service_c?.value !='' ">
                  <p class="dLabel">Service</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_c?.value=='interpreting'">
                    <span class="bdg blank bg-yellow">Interpretation</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_c?.value=='translation'">
                    <span class="bdg blank bg-yellow">Translation</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_c?.value=='localization'">
                    <span class="bdg blank bg-yellow">Localization</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_c?.value=='audiovisual'">
                    <span class="bdg blank bg-yellow">Audiovisual</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_c?.value=='addon'">
                    <span class="bdg blank bg-yellow">Add-ons</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value==''">
                    <span class="bdg blank bg-yellow">NA</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value !='' ">
                  <p class="dLabel">Project or Document Status</p>
  
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='DTP'"><span
                      class="bdg blank bg-yellow">DTP</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Draft'"><span
                      class="bdg blank bg-yellow">Draft</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Editing'"><span
                      class="bdg blank bg-yellow">Editing</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Pending'"><span
                      class="bdg blank bg-yellow">Pending</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='For_Approval'"><span
                      class="bdg blank bg-yellow">Sent for Approval - DO NOT USE</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='For_Approval_Var'"><span
                      class="bdg blank bg-yellow">Sent for Approval - VAR</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Turned_In'"><span
                      class="bdg blank bg-yellow">Turned In</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Finished Report Pending'">
                    <span class="bdg blank bg-yellow">Finished Report Pending</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Finished Interpretation'">
                    <span class="bdg blank bg-yellow">Finished Interpretation</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='In_Progress'"><span
                      class="bdg blank bg-yellow">In Progress</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Proofreading'"><span
                      class="bdg blank bg-yellow">Proofreading</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Notarization'"><span
                      class="bdg blank bg-yellow">Notarization</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Apostille'"><span
                      class="bdg blank bg-yellow">Apostille</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Academic_Evaluation'"><span
                      class="bdg blank bg-yellow">Academic Evaluation</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Mailed'"><span
                      class="bdg blank bg-yellow">Mailed</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_document_status_c?.value=='Pick_Up'"><span
                      class="bdg blank bg-yellow">Pick Up</span>
                  </p>
  
                  <p class="dValue" *ngIf="DetailMainResponse?.cstm_page_count_c?.value==''">
                    <span class="bdg blank bg-yellow">NA</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value !='' ">
                  <p class="dLabel">Service Type</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_affidavit'">
                    <span class="bdg blank bg-yellow">Affidavit</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_business'">
                    <span class="bdg blank bg-yellow">Business</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_document'">
                    <span class="bdg blank bg-yellow">Documents</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_dmv'">
                    <span class="bdg blank bg-yellow">Drivers License</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_transcript'">
                    <span class="bdg blank bg-yellow">Educational Transcript</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_email'">
                    <span class="bdg blank bg-yellow">Email/Chat</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_filmscript'">
                    <span class="bdg blank bg-yellow">Film Scripts</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_financial'">
                    <span class="bdg blank bg-yellow">Financial</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_ime'">
                    <span class="bdg blank bg-yellow">IME Reports</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_legal'">
                    <span class="bdg blank bg-yellow">Legal</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_literary'">
                    <span class="bdg blank bg-yellow">Literary</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_localization'">
                    <span class="bdg blank bg-yellow">Localization</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_magazine'">
                    <span class="bdg blank bg-yellow">Magazine</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_manuals'">
                    <span class="bdg blank bg-yellow">Manuals</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_medical'">
                    <span class="bdg blank bg-yellow">Medical</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_patents'">
                    <span class="bdg blank bg-yellow">Patents</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_rfp'">
                    <span class="bdg blank bg-yellow">RFP</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_signage'">
                    <span class="bdg blank bg-yellow">Signage</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_technical'">
                    <span class="bdg blank bg-yellow">Technical Documents</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_uscis'">
                    <span class="bdg blank bg-yellow">USCIS</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value=='translation_audios'">
                    <span class="bdg blank bg-yellow">Audios</span>
                  </p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_service_type_c?.value==''">
                    <span class="bdg blank bg-yellow">NA</span>
                  </p>
                </div>
  
                <div class="col-sm-3" *ngIf="DetailMainResponse?.sprj1_notes_c?.value !='' ">
                  <p class="dLabel">Additional Notes/Project Description</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.sprj1_notes_c?.value"><span class="bdg blank bg-yellow">
                      {{DetailMainResponse?.sprj1_notes_c?.value}}</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.name_correction_c?.value !='' ">
                  <p class="dLabel">Name Correction</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.name_correction_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.name_correction_c?.value}}</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.language_dialect_c?.value !='' ">
                  <p class="dLabel">Language Dialect</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.language_dialect_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.language_dialect_c?.value}}</span>
                  </p>
                </div>
                <div class="col-sm-3" *ngIf="DetailMainResponse?.project_doc_status_mdate_c?.value !='' ">
                  <p class="dLabel">Project or Document Status Modified Date</p>
                  <p class="dValue" *ngIf="DetailMainResponse?.project_doc_status_mdate_c?.value"><span
                      class="bdg blank bg-yellow">
                      {{DetailMainResponse?.project_doc_status_mdate_c?.value | date: 'yyyy-MM-dd HH:mm a'}}</span>
                  </p>
                </div>
  
              </div>

              <!-- <p [innerHtml]="RequestQueue_response?.note | newline"></p> -->
            </div>
            <!--Crm Informatin end-->

            <div class="box-blank" *ngIf="important_messages_response.length>0">
              <p class="bLabel">Important Notes:</p>
              <div class="commentDiv" *ngFor="let res of important_messages_response">
                <div class="quesDiv" *ngIf="res?.users?.data?.user_group_id=='Vendor'">
                  <p class="mb-4"><b>{{res.users?.data?.fullname}}</b>
                    <span class="mUsr"> ({{res?.users?.data?.user_group_id}} )</span>
                    <span class="mStamp"> {{res?.created_at?.date | date: 'yyyy-MM-dd'}}
                      {{res?.created_at.date | date:"HH:mm"}}</span>
                  </p>
                  <p class="mCmnt">{{res?.message}}</p>
                </div>

                <div class="ansDiv" *ngIf="res?.users?.data?.user_group_id!='Vendor'">
                  <div class="divinn">
                    <p class="mb-4"><b>{{res.users?.data?.fullname}}</b>
                      <span class="mUsr"> ({{res?.users?.data?.user_group_id}} )</span>
                      <span class="mStamp"> {{res?.created_at?.date | date: 'yyyy-MM-dd'}}
                        {{res?.created_at.date | date:"HH:mm"}}</span>
                    </p>
                    <p class="mCmnt">{{res?.message}}</p>
                  </div>
                </div>

              </div>
            </div>

            <!-- <div class="box-blank bg-yellow" id="top_additional_service">

              <h4>Additional Services Request <span class="bdg blank tlink"
                  (click)="showAddAdditionalServices_click()"><i class="material-icons">add</i> Add </span> </h4> -->

            <!-- Additional services start -->
            <!-- <div id="top1"> -->
            <!-- <app-add-additional-services *ngIf="showAddAdditionService" [indexID]="index_id">
              </app-add-additional-services> -->
            <!-- </div> -->

            <!-- Additional services end -->

            <!-- <mat-tab-group animationDuration="0ms">
                <mat-tab label="Mailing Options ">
                  <ng-template mat-tab-label>
                    Mailing Options <span class="aText">{{getValueMailing}}</span>
                  </ng-template>
                  <app-additional-information-mailing (myoutputMailing)="getDataMailing($event)">
                  </app-additional-information-mailing>
                </mat-tab>
                <mat-tab label="Notarization">
                  <ng-template mat-tab-label>
                    Notarization <span class="aText">{{getValueNotarization}}</span>
                  </ng-template>
                  <app-additional-information-notarization (myoutputNotarization)="getDataNotarization($event)">
                  </app-additional-information-notarization>
                </mat-tab>
                <mat-tab label=" Apostile">
                  <ng-template mat-tab-label>
                     Apostile <span class="aText">{{getValueApostile}}</span>
                  </ng-template>
                  <app-additional-information-apostile (myoutputApostile)="getDataApostile($event)">
                  </app-additional-information-apostile>
                </mat-tab>
              </mat-tab-group>
            </div> -->

            <!-- <div class="box-blank bg-yellow">
            <h4>Additional Services Request</h4>
            <mat-tab-group animationDuration="0ms">
                  <mat-tab label="Mailing Options "> 
                        <ng-template mat-tab-label>
                          Mailing Options <span class="aText">(Applied)</span>
                        </ng-template>
                        <div class="mopt">
                          <p><b> Mailing Options  : </b> Electronic version only / Regular mailing (2-3 business days)</p>
                          <p><b> Documents Shipping address: </b></p>
                          <p><b>Street Address:</b> 202, Wert St, North Avenue, 34567, 
                          <b> City : </b> Barcelona
                          <b>  State : </b> Bolavia
                            <b> Country : </b> South Africa
                              <b> Zip Code :</b> 223342
                          </p>
                          <p><b>Phone Number:</b> +62-234524567543 </p>
                          <p> <b> Additional copy required:</b> 2 Set</p>
                          <p>  <b>Second address for additional copy:</b> 
                          202, Wert St, North Avenue, 34567, Florida, USA.</p>
                        </div>
                  </mat-tab>
                  <mat-tab label="Notarization">  
                      <ng-template mat-tab-label>
                          Notarization <span class="naText">(NA)</span>
                      </ng-template>
                      <p>Not Applied</p>
                      <p>
                      Notarization of COA in CA or IL: <b>2 Copies</b> 
                      </p>
                      <p> Notarization in any other state: <b>Barcelona</b> </p>
                      <p>  Additional Notarization: <b>Yes</b> </p>
                      <p> Notarization Affidavit for court: <b>Yes</b> </p>
                </mat-tab>
                <mat-tab label=" Apostile"> 
                        <ng-template mat-tab-label>
                         Apostile <span class="naText">(NA)</span>
                        </ng-template>
                        <p>Not Applied</p>
                        <p> <b> Apostile service required (translation only)</b></p>
                          <p>  Country name for which  Apostile service is required :<b> United States </b></p>
                          <p>  State for which  Apostile service is required: <b> From Illinois</b></p>
                            <p>     Apostile service outside Illinois  :  <b>State Name</b> </p>
                </mat-tab>
            </mat-tab-group>
          </div> -->
            <!-- <div class="box-blank bg-red" *ngIf="all_locked_projects!=''">
              <p> <i class="material-icons"> info      </i> 
                {{all_locked_projects}} 
                <span *ngFor="let res of project_locked_record">
                  <a routerLink="/pms/project/details/{{res.uuid }}" target="_blank"> {{res.reference}} </a>
                </span>
                </p>
          </div> -->
            <h4> Customer Information </h4>
            <div class="container" fxLayout="row wrap" fxFlexFill fxLayout.xs="column" fxLayoutAlign="left"
              fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex *ngIf="false">
                <div class="box-blank bg-green">
                  <p class="dLabel">Newsletter </p>
                  <p class="dValue"> 5% discount! </p>
                </div>
              </div>
              <div class="item item-1" fxFlex *ngIf="RequestQueue_response?.customer?.data?.discount_value">
                <div class="box-blank bg-green">
                  <p class="dLabel"> DayVIP </p>
                  <p class="dValue" *ngIf="discount_value"> {{RequestQueue_response?.customer?.data?.discount_value}}
                  </p>
                </div>
              </div>
              <div class="item item-1" fxFlex>
                <div class="box-blank bg-green" *ngIf="RequestQueue_response?.customer?.data?.payment_method">
                  <p class="dLabel">Payment Method </p>
                  <p class="dValue" *ngIf="RequestQueue_response?.customer?.data?.payment_method==1">NET30</p>
                  <p class="dValue" *ngIf="RequestQueue_response?.customer?.data?.payment_method==2"> NET45 </p>
                  <p class="dValue" *ngIf="RequestQueue_response?.customer?.data?.payment_method==3"> Regular/Upfront
                  </p>
                  <p class="dValue" *ngIf="RequestQueue_response?.customer?.data?.payment_method==4"> Credit Available
                  </p>

                </div>
              </div>
              <div class="item item-1" fxFlex *ngIf="RequestQueue_response?.customer?.data?.credit_available">
                <div class="box-blank bg-green">
                  <p class="dLabel"> Credit Available </p>
                  <p class="dValue"> {{RequestQueue_response?.customer?.data?.credit_available}}</p>
                </div>
              </div>
              <div class="item item-1" fxFlex *ngIf="RequestQueue_response?.customer?.data?.contract_type">
                <div class="box-blank bg-green">
                  <p class="dLabel"> Contract Type </p>
                  <p class="dValue"> {{RequestQueue_response?.customer?.data?.contract_type}} </p>
                </div>
              </div>
            </div>

            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex>
                <p class="dLabel">Name </p>
                <span class="dValue" *ngIf="CrmAccountInfoResponse?.id?.value !=''">
                  <a [href]="CrmAccountUrl"  target="_blank">
                    {{RequestQueue_response?.customer?.data.first_name}}
                    {{RequestQueue_response?.customer?.data.last_name}}
                  </a></span>
                <!-- <span class="dValue">
                  <a routerLink="/pms/customer/details/{{RequestQueue_response?.customer?.data.uuid}}">
                    {{RequestQueue_response?.customer?.data.first_name}}
                    {{RequestQueue_response?.customer?.data.last_name}}
                  </a></span> -->
              </div>
              <div class="item item-2" fxFlex>
                <p class="dLabel">Email Address</p>
                <p class="dValue">{{RequestQueue_response?.customer?.data.email_primary}}</p>
              </div>
              <div class="item item-3" fxFlex>
                <p class="dLabel">Company</p>
                <p class="dValue">{{RequestQueue_response?.customer?.data?.company?.data.name}}</p>
              </div>
              <div class="item item-4" fxFlex>
                <p class="dLabel">Phone</p>
                <p class="dValue">{{RequestQueue_response?.customer?.data.phone}}</p>
              </div>
            </div>

            <p><b>Start Time:</b> {{RequestQueue_response?.customer?.data.created_at.date | date: 'yyyy-MM-dd'}}
              {{RequestQueue_response?.customer?.data.created_at.date | date:"HH:mm"}}
              <!-- <b class="ml-8">User IP :</b> {{RequestQueue_response?.customer?.data.ip_address}}
              <b class="ml-8">Browser</b>: {{RequestQueue_response?.customer?.data.browser}}
              <b class="ml-8">OS</b> : {{RequestQueue_response?.customer?.data.os}}
              <b class="ml-8"> Source</b>: {{RequestQueue_response?.customer?.data.source?.data.name}} -->
              <b class="ml-8" *ngIf="RequestQueue_response?.website_source!=null">Website Source</b> :
              {{RequestQueue_response?.website_source}}

            </p>
          </div>
        </div>
      </div>

      <!-- Estimation start -->
      <!-- <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap">
            <div fxFlex>
              <h2>Estimation:</h2>
            </div>
            <div class="pstat" fxFlex> <span class="hText hYellow ml-4" *ngIf="version!='0.0'">Version
                {{version}}</span>
              <span class="pl-4" *ngIf="quote_status==1">Status <b> Sent to customer</b></span>
              <span class="pl-4 hText hYellow" *ngIf="quote_status==2">Status <b> Requested Changes by
                  customer</b></span>
              <span class="pl-4 hText hYellow" *ngIf="quote_status==3">Status <b> Declined by customer</b></span>
              <span class="pl-4 hText hGreen" *ngIf="quote_status==4">Status <b> Approved by customer</b></span>
              <span class="pl-4 hText hYellow" *ngIf="quote_status==5">Status <b> Save As Draft</b></span>
            </div>
          </div>
        </div>

        <div class="boxBody">
          <div *ngIf=" RequestQueue_response?.projectquote?.data?.length>0" class="boxBodyInn">
            <div class="quoteHistoryBox">


              <mat-accordion>

                <mat-expansion-panel *ngFor="let res_quote_array of response_data_quoteArray">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="{{res_quote_array?.is_active_class}}">
                      <p>
                        <span class="vers"><span *ngIf="res_quote_array.version!='0.0'">Version
                            {{res_quote_array.version}}</span></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==1">Status <b> Sent to customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==2">Status <b> Requested Changes by
                            customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==3">Status <b> Declined by customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==4">Status <b> Approved by customer</b></span>
                        <span class="pl-4" *ngIf="res_quote_array.status==5">Status <b> Save As Draft</b></span>
                        <span class="tAmount pl-4">Total Amount: <b> {{res_quote_array.total}}</b></span>
                      </p>
                    </mat-panel-title>
                    <mat-panel-description>
                      <p class="pl-4"><span class="qtDate">Sent on:
                          {{res_quote_array?.created_at?.date | date: 'yyyy-MM-dd'}}
                          {{res_quote_array?.created_at?.date | date:"HH:mm"}}</span>
                        <span class="pmName pl-4" *ngIf="res_quote_array?.quotesentby?.data?.fullname">By
                          {{res_quote_array?.quotesentby?.data?.fullname}}</span>
                      </p>
                      <p *ngIf="res_quote_array.status==5 && quote_status!=4">
                        <button mat-button color="accent" class="btn-link ml-8"
                          routerLink="/pms/project/{{ RequestQueue_response?.uuid }}/create-quote/">
                          <mat-icon>
                            edit</mat-icon>Edit Draft
                        </button></p>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <table *ngIf=" RequestQueue_response?.projectquote?.data?.length>0" class="table table-bordered">
                    <tr>
                      <th>Languages</th>
                      <th class="text-center">Quantity</th>
                      <th class="text-center">Rate(USD)</th>
                      <th class="text-center">Sub Total(USD)</th>
                    </tr>
                    <tbody *ngFor="let langs of res_quote_array.projectquoteservice.data ;let in = index">
                      <tr *ngFor="let lang_combination of langs.language ;let i = index">
                        <td>{{lang_combination.sourcelanguage.data.lang_name }} -
                          {{lang_combination.targetlanguage.data.lang_name}}</td>
                        <td class="text-right">{{lang_combination.quantity}}</td>
                        <td class="text-right">{{lang_combination.rate}} / {{lang_combination.pricing_method}}</td>
                        <td class="text-right">{{lang_combination.sub_total}}</td>
                      </tr>
                    </tbody>
                  </table> -->

      <!-- test start -->
      <!-- <h2>Additional Services</h2> -->
      <!-- additional service show -->

      <!-- <table class="table table-bordered">
                    <tr>
                      <th>Service Description</th>
                      <th class="text-center">Quantity</th>
                      <th class="text-center">Sub Total(USD)</th>
                    </tr>

                    <tr *ngFor="let res of res_quote_array?.project_quote_additional_service?.data">
                       -->
      <!-- Mailing options start-->

      <!-- <td *ngIf="res?.title">
                        {{res?.title}}

                      </td>

                      <td class="text-right" *ngIf="res?.quantity">
                        <b>{{res?.quantity}}</b>

                      </td>
                      <td class="text-right" *ngIf="res?.price">
                        <b>${{res?.price}}</b>

                      </td>
                      <td class="text-right">
                        <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                      </td> -->




      <!-- 
                                        <div *ngIf="res.additional_service.code == 'additional_copy' ">
                      
                                          <div class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;"
                                          fxLayoutGap="10px">
                                        
                      
                                          <div fxFlex="40" *ngIf="res?.quantity">
                                              <b>Additional copy required</b>
                                            
                                            </div>
                      
                                            <div fxFlex="16" *ngIf="res?.quantity">
                                                <b>${{res?.quantity}}</b>
                                              
                                              </div>
                                              <div fxFlex="20" *ngIf="res?.price">
                                                  <b>${{res?.price}}</b>
                                                
                                                </div>
                                            <div fxFlex="20">
                                              <b *ngIf="res?.sub_total">${{res.sub_total}}</b>
                                            </div>
                                          </div>
                                        </div> -->

      <!-- Mailing options end-->

      <!-- 
                    </tr>
                  </table> -->

      <!-- additional service show end-->

      <!-- test end -->

      <!-- <div class="actWraper mEqual text-center mt-16" fxLayout="column" *ngIf="project_lang_data.length>0"
                    fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
                    fxLayoutGap="10px">
                    <div class="item item-1" fxFlex>
                      <p class="dLabel">Discount (%)</p>
                      <p class="dValue"><b>{{res_quote_array.discount}}</b></p>
                    </div>
                    <div class="item item-2" fxFlex>
                      <p class="dLabel">Discount Amount(USD)</p>
                      <p class="dValue"><b>{{res_quote_array.discount_amount}}</b></p>
                    </div>
                    <div class="item item-2" fxFlex>
                      <p class="dLabel">Total(USD)</p>
                      <p class="dValue"><b>{{res_quote_array.total}}</b>
                      </p>
                    </div> -->
      <!-- <button mat-stroked-button color="success" type="button" routerLink="/pms/transactions/create/{{res_quote_array.id }}"
                    *ngIf="user_group_id=='Admin' && res_quote_array.status==1" class="mr-8">Create Payment
                  </button> -->
      <!-- <button mat-raised-button color="accent" type="button" (click)="Transaction(res_quote_array.id)"
                      *ngIf="user_group_id=='Admin' && res_quote_array.status==1" class="mr-8">Create Payment
                    </button>
                  </div> -->

      <!-- <form name="Form" [formGroup]="Form"
                    *ngIf="RequestQueue_response?.projectquote?.data?.projectquoteservice?.data?.length>0">
                    <div class="container" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                      fxLayoutAlign.gt-sm="space-between center" fxLayoutGap="10px">
                      <div class="item item-1" fxFlex="18%">
                        <p class="dLabel">Discount (%)</p>
                        <p class="dValue">
                          <mat-form-field>
                            <input matInput type="number" formControlName="discount" (change)="Discount_total()"
                              [readonly]="RequestQueue_response?.projectquote?.data?.projectquoteservice?.data?.length>0">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="item item-2" fxFlex="18%">
                        <p class="dLabel">Discount Amount(USD)</p>
                        <p class="dValue">
                          <mat-form-field>
                            <input matInput type="number" formControlName="discount_amount" readonly>
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="item item-2" fxFlex="18%">
                        <p class="dLabel">Total(USD)</p>
                        <p class="dValue">
                          <mat-form-field>
                            <input matInput type="number" mask="0.000" formControlName="total" readonly>
                          </mat-form-field>
                        </p>
                      </div>
                    </div>
                  </form>
                </mat-expansion-panel>

              </mat-accordion>
            </div>
          </div>
        </div>

        <div class="actWraper" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="space-between center">
          <div>
            <button mat-stroked-button color="warn" type="button" (click)="projectRelease()"
              *ngIf="user_group_id=='Admin'" class="mr-8">Release
            </button> -->
      <!-- <button mat-stroked-button color="warn" type="button" (click)="projectRelease()"
              *ngIf="locked_by==user_id && quote_status!=4" class="mr-8">Release
            </button> -->

      <!-- </div>
          <div class="box-blank bg-red" *ngIf="locked_by_value && locked_by!=user_id && project_stage_id==2">
            <p> <i class="material-icons"> info </i> {{locked_message}} </p>
          </div> -->

      <!--div>
            <button
              *ngIf="response_data_quoteArray.length==0 && locked_by==user_id || locked_by_value==null && response_data_quoteArray.length==0 || show && response_data_quoteArray.length==0"
              mat-raised-button color="accent"
              routerLink="/pms/project/{{ RequestQueue_response?.uuid }}/create-quote/">Create Quote</button>
            <button mat-raised-button color="accent"
              *ngIf="response_data_quoteArray.length>0 && ( quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5) && (locked_by==user_id || locked_by==null)"
              routerLink="/pms/project/{{ RequestQueue_response?.uuid }}/create-quote/">Re-Create</button>
          </div -->
      <!-- <div>
            <button
              *ngIf="response_data_quoteArray.length==0 && locked_by==user_id || locked_by_value==null && response_data_quoteArray.length==0 || show && response_data_quoteArray.length==0"
              mat-raised-button color="accent"
              [routerLink]="'/pms/project/' + RequestQueue_response?.uuid +'/create-quote/' ">Create Quote</button>
            <button mat-raised-button color="accent"
              *ngIf="response_data_quoteArray.length>0 && ( quote_status==1 || quote_status==2 || quote_status==3 || quote_status==5) && (locked_by==user_id || locked_by==null)"
              [routerLink]="'/pms/project/' + RequestQueue_response?.uuid +'/create-quote/' ">Re-Create</button>
          </div> -->
      <!-- </div>

      </div> -->
      <!-- Estimation end -->


      <!-- Auto Quote Information start -->
      <div class="white-box">
        <div class="tHeader wMenu">
          <div class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;"
            fxLayoutGap="10px">
            <div fxFlex="30">
              <h2>Auto Quote Information</h2>
            </div>
          </div>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn" *ngIf="AutoQuoteInfoResponse!=''">

            <div class="row">
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_pages_c.value !='' ">
                <p class="dLabel">Additional Pages</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_pages_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.additional_pages_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_words_c.value !='' ">
                <p class="dLabel">Additional Words</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_words_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.additional_words_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.autoquote_completed_step_c.value !='' ">
                <p class="dLabel">Autoquote Completed Step</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.autoquote_completed_step_c.value=='basic_details'">
                  <span class="bdg blank bg-yellow">Basic Details</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.autoquote_completed_step_c.value=='project_details'">
                  <span class="bdg blank bg-yellow">Project Details</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.autoquote_completed_step_c.value=='files'">
                  <span class="bdg blank bg-yellow">Files</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.autoquote_completed_step_c.value=='additional_services'">
                  <span class="bdg blank bg-yellow">Additional Services</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.autoquote_completed_step_c.value=='completed'">
                  <span class="bdg blank bg-yellow">Completed</span>
                </p>
                
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_cost_c.value !='' ">
                <p class="dLabel">Additional Cost</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_cost_c.value">
                  <span
                    class="bdg blank bg-yellow">{{convertIntoFloat(AutoQuoteInfoResponse.additional_cost_c.value)}}</span>
                </p>
              </div>

              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_apostille_c.value !='' ">
                <p class="dLabel">Additional Apostille</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_apostille_c.value=='1'">
                  <span class="bdg blank bg-yellow">Yes</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_apostille_c.value=='0'">
                  <span class="bdg blank bg-yellow">No</span>
                </p>
                
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_cost_c.value !='' ">
                <p class="dLabel">Additional Services Cost</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_cost_c.value">
                  <span
                    class="bdg blank bg-yellow">{{convertIntoFloat(AutoQuoteInfoResponse.additional_cost_c.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.jjwg_maps_address_c.value !='' ">
                <p class="dLabel">Address</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.jjwg_maps_address_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.jjwg_maps_address_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.sprj1_ct_address_c.value !='' ">
                <p class="dLabel">Mailing Address</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.sprj1_ct_address_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.sprj1_ct_address_c.value}}</span>
                </p>
              </div>


              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.mail_opt_additional_copy_c.value !='' ">
                <p class="dLabel">Mailing Option for Additional Copy</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.mail_opt_additional_copy_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.mail_opt_additional_copy_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.apostille_no_of_copies_c.value !='' ">
                <p class="dLabel">Apostille No. of Copies</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse?.apostille_no_of_copies_c?.value">
                  <span
                    class="bdg blank bg-yellow">{{AutoQuoteInfoResponse?.apostille_no_of_copies_c?.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.apostille_services_type_c.value !='' ">
                <p class="dLabel">Apostille Services Type</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.apostille_services_type_c.value=='apostille_in_il'">
                  <span class="bdg blank bg-yellow">Apostille Service(translation only) in IL</span>
                </p>
                <p class="dValue"
                  *ngIf="AutoQuoteInfoResponse.apostille_services_type_c.value=='apostille_in_other_state'">
                  <span class="bdg blank bg-yellow">Apostille Service(translation only) in other state</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.apostille_services_type_c.value==''">
                  <span class="bdg blank bg-yellow">NA</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.notarization_services_type_c.value !='' ">
                <p class="dLabel">Notarization Services Type</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.notarization_services_type_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.notarization_services_type_c.value}}</span>
                </p>
              </div>


              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.notarization_no_of_copies_c.value !='' ">
                <p class="dLabel">Notarization No. of Copies</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.notarization_no_of_copies_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.notarization_no_of_copies_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.service_type_other_c.value !='' ">
                <p class="dLabel">Service Type Other</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.service_type_other_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.service_type_other_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.ship_additional_copy_name_c.value !='' ">
                <p class="dLabel">Shippnig Address Name for Addional copy</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.ship_additional_copy_name_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.ship_additional_copy_name_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.total_amount_paid_c.value !='' ">
                <p class="dLabel">Total Amount Paid</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.total_amount_paid_c.value">
                  <span
                    class="bdg blank bg-yellow">{{convertIntoFloat(AutoQuoteInfoResponse.total_amount_paid_c.value)}}</span>
                </p>
              </div>


              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.ship_add_address_city_c.value !='' ">
                <p class="dLabel">Shippnig Address for Addional copy City</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.ship_add_address_city_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.ship_add_address_city_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.ship_add_address_postalcode_c.value !='' ">
                <p class="dLabel">Shippnig Address for Addional copy PostalCode</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.ship_add_address_postalcode_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.ship_add_address_postalcode_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.dtp_source_file_c.value !='' ">
                <p class="dLabel">Source File</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.dtp_source_file_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.dtp_source_file_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.quote_form_outcome_c.value !='' ">
                <p class="dLabel">Quote form outcome</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.quote_form_outcome_c.value=='incomplete'">
                  <span class="bdg blank bg-yellow">Incomplete</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.quote_form_outcome_c.value=='manual_request'">
                  <span class="bdg blank bg-yellow">Manual Request</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.quote_form_outcome_c.value=='manual_quote'">
                  <span class="bdg blank bg-yellow">Manual Quote</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.quote_form_outcome_c.value=='auto_quote'">
                  <span class="bdg blank bg-yellow">Auto Quote</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.quote_form_outcome_c.value==''">
                  <span class="bdg blank bg-yellow">NA</span>
                </p>
              </div>

              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_pages_cost_c.value !='' ">
                <p class="dLabel">Additional Pages Cost</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_pages_cost_c.value">
                  <span
                    class="bdg blank bg-yellow">{{convertIntoFloat(AutoQuoteInfoResponse.additional_pages_cost_c.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_words_cost_c.value !='' ">
                <p class="dLabel">Additional Words Cost</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_words_cost_c.value">
                  <span
                    class="bdg blank bg-yellow">{{convertIntoFloat(AutoQuoteInfoResponse.additional_words_cost_c.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.embedded_images_c.value !='' ">
                <p class="dLabel">Embedded Images</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.embedded_images_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.embedded_images_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_copy_c.value !='0' ">
                <p class="dLabel">Additional Copy</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_copy_c.value=='1'">
                  <span class="bdg blank bg-yellow">Yes</span>
                </p>
              </div>

              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.additional_notarization_c.value !='0' ">
                <p class="dLabel">Additional Notarization</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.additional_notarization_c.value=='1'">
                  <span class="bdg blank bg-yellow">Yes</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.mailing_pickup_location_c.value !='' ">
                <p class="dLabel">Choose Pickup Location</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.mailing_pickup_location_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.mailing_pickup_location_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.translator_email_sent_c.value !='0' ">
                <p class="dLabel">Email Sent to Translator</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.translator_email_sent_c.value=='1'">
                  <span class="bdg blank bg-yellow">Yes</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.finish_days_c.value !='' ">
                <p class="dLabel">Finish Days</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.finish_days_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.finish_days_c.value}}</span>
                </p>
              </div>


              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.dtp_no_of_pages_c.value !='' ">
                <p class="dLabel">No. of pages for DTP</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.dtp_no_of_pages_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.dtp_no_of_pages_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.no_of_additional_copy_c.value !='' ">
                <p class="dLabel">Number of Additional Copies</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.no_of_additional_copy_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.no_of_additional_copy_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.translator_paid_c.value !='0' ">
                <p class="dLabel">Paid</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.translator_paid_c.value=='1'">
                  <span class="bdg blank bg-yellow">Yes</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.shipping_address_name_c.value !='' ">
                <p class="dLabel">Shipping Address Name</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.shipping_address_name_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.shipping_address_name_c.value}}</span>
                </p>
              </div>


              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.shipping_address_phone_c.value !='' ">
                <p class="dLabel">Shipping Address Phone</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.shipping_address_phone_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.shipping_address_phone_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.ship_additional_copy_phone_c.value !='' ">
                <p class="dLabel">Shippnig Address Phone for Addional copy</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.ship_additional_copy_phone_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.ship_additional_copy_phone_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.ship_add_address_c.value !='' ">
                <p class="dLabel">Shippnig Address for Addional copy</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.ship_add_address_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.ship_add_address_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.ship_add_address_country_c.value !='' ">
                <p class="dLabel">Shippnig Address for Addional copy Country</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.ship_add_address_country_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.ship_add_address_country_c.value}}</span>
                </p>
              </div>

              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.ship_add_address_state_c.value !='' ">
                <p class="dLabel">Shippnig Address for Addional copy State</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.ship_add_address_state_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.ship_add_address_state_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.state_notarization_c.value !='' ">
                <p class="dLabel">State and County for Notarization</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.state_notarization_c.value">
                  <span class="bdg blank bg-yellow">{{AutoQuoteInfoResponse.state_notarization_c.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AutoQuoteInfoResponse.client_abandoned_reason_c.value !='' ">
                <p class="dLabel">Client Abandoned Reason</p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.client_abandoned_reason_c.value=='stayed'">
                  <span class="bdg blank bg-yellow">Stayed</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.client_abandoned_reason_c.value=='will_be_back_later'">
                  <span class="bdg blank bg-yellow">Will be back later</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.client_abandoned_reason_c.value=='price_was_high'">
                  <span class="bdg blank bg-yellow">Price was high</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.client_abandoned_reason_c.value=='not_interested'">
                  <span class="bdg blank bg-yellow">Not Interested</span>
                </p>
                <p class="dValue" *ngIf="AutoQuoteInfoResponse.client_abandoned_reason_c.value==''">
                  <span class="bdg blank bg-yellow">NA</span>
                </p>
              </div>


            </div>

          </div>
        </div>
      </div>

      <!-- Auto Quote Information end -->



      <!-- Add-on Services start -->
      <div class="white-box">
        <div class="tHeader wMenu">
          <div class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;"
            fxLayoutGap="10px">
            <div fxFlex="30">
              <h2>Add-on Services</h2>
            </div>
          </div>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn" *ngIf="AddonResponse!=''">
            <div class="row">
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_certification_c.value !='' ">
                <p class="dLabel">Certification Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_certification_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_certification_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_notarization_c.value !='' ">
                <p class="dLabel">Notarization Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_notarization_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_notarization_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_subtitling_c.value !='' ">
                <p class="dLabel">Subtitling Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_subtitling_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_subtitling_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_proofreading_c.value !='' ">
                <p class="dLabel">Proofreading Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_proofreading_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_proofreading_c?.value)}}</span>
                </p>
              </div>

              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_rushfee_c.value !='' ">
                <p class="dLabel">Rush Fees</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_rushfee_c?.value"><span class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_rushfee_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_apostille_c.value !='' ">
                <p class="dLabel">Apostille Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_apostille_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_apostille_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.file_engineering_cost_c.value !='' ">
                <p class="dLabel">File Engineering Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.file_engineering_cost_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.file_engineering_cost_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_dtp_c.value !='' ">
                <p class="dLabel">DTP Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_dtp_c?.value"><span class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_dtp_c?.value)}}</span>
                </p>
              </div>


              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_academic_eval_c.value !='' ">
                <p class="dLabel">Academic Eval Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_academic_eval_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_academic_eval_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_transcription_c.value !='' ">
                <p class="dLabel">Transcription Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_transcription_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_transcription_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_voiceover_c.value !='' ">
                <p class="dLabel">Voiceover Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_voiceover_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_voiceover_c?.value)}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.sprj1_cost_equipment_c.value !='' ">
                <p class="dLabel">Equipment Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.sprj1_cost_equipment_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.sprj1_cost_equipment_c?.value)}}</span>
                </p>
              </div>

              <div class="col-sm-3" *ngIf="AddonResponse.apostille_service_country_c.value !='' ">
                <p class="dLabel">Apostille Country</p>
                <p class="dValue" *ngIf="AddonResponse?.apostille_service_country_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{AddonResponse?.apostille_service_country_c?.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.apostille_service_state_c.value !='' ">
                <p class="dLabel">Apostille State</p>
                <p class="dValue" *ngIf="AddonResponse?.apostille_service_state_c?.value"><span
                    class="bdg blank bg-yellow">
                    {{AddonResponse?.apostille_service_state_c?.value}}</span>
                </p>
              </div>
              <div class="col-sm-3" *ngIf="AddonResponse.cost_localization_c.value !='' ">
                <p class="dLabel">Localization Cost</p>
                <p class="dValue" *ngIf="AddonResponse?.cost_localization_c?.value"><span class="bdg blank bg-yellow">
                    {{convertIntoFloat(AddonResponse?.cost_localization_c?.value)}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add-on Services end -->
      <!-- file management start -->
      <app-file-management></app-file-management>
      <!-- file management end -->

      <!-- Crm document block start-->
      <div class="white-box">
        <div class="tHeader wMenu">
          <div class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;"
            fxLayoutGap="10px">
            <div fxFlex="30">
              <h2>CRM documents</h2>
            </div>
            <div fxFlex="70" class="text-right" *ngIf="crmdoc_data.length>0">
              <button class="mr-4"  mat-stroked-button (click)="onCreate()"><i class="material-icons"> cloud_upload </i> Add to TMS docs</button>
          </div>
          </div>
        </div>
        <!-- <div class="white-box">
          <div class="tHeader wMenu">
              <div  class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;" fxLayoutGap="10px">
                  <div fxFlex="30">
                  </div>
                  <div fxFlex="70" class="text-right">
                      <button class="mr-4"  mat-stroked-button (click)="onCreate()"><i class="material-icons"> cloud_upload </i> Add to TMS docs</button>
                  </div>
                 </div>
          </div>
        </div> -->
        <div class="boxBody">
          <div class="boxBodyInn" *ngIf="crmdoc_data.length>0">
            <table class="table table-bordered" *ngIf="crmdoc_data.length>0">
              <thead>
                <tr>
                  <!-- <th scope="col"><mat-checkbox [(ngModel)]="bulk_selected_progress" (change)="selectBulkProgress($event)"></mat-checkbox></th> -->
                  <th scope="col">Name</th>
                  <th scope="col">Active Date</th>
                  <th scope="col">Download Link</th>
                  <th scope="col">Show source file in TMT</th>
                  <th scope="col">Show Translated File in TMT</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let crmdoc_res of crmdoc_data">
                  <!-- <th scope="row"><mat-checkbox   (change)="selectFile($event,crmdoc_data)"></mat-checkbox></th> -->
                  <td>
                    <a [href]="crmdoc_res.download_link_without_login_c.value" target="_blank"
                      download>{{ crmdoc_res.filename.value }}</a>
                  </td>
                  <td>
                    {{crmdoc_res?.active_date?.value | date: 'yyyy-MM-dd'}}
                    {{crmdoc_res?.active_date?.value | date:"HH:mm"}}
                  </td>
                  <td>
                    <!-- <a [href]="crmdoc_res.download_link_without_login_c.value" target="_blank" download>
                      {{ crmdoc_res.download_link_without_login_c.value }} </a> -->
                      <a [href]="crmdoc_res.download_link_without_login_c.value" target="_blank" download>
                        <mat-icon>cloud_download</mat-icon> Download </a>
                  </td>
                  <td>
                    <p class="dValue" *ngIf="crmdoc_res?.show_file_in_tmt_c?.value=='1'">
                      <span class="bdg blank bg-yellow">Yes</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.show_file_in_tmt_c?.value=='0'">
                      <span class="bdg blank bg-yellow">No</span>
                    </p>
                  </td>
                  <td>
                    <p class="dValue" *ngIf="crmdoc_res?.show_translatedfile_in_tmt_c?.value=='1'">
                      <span class="bdg blank bg-yellow">Yes</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.show_translatedfile_in_tmt_c?.value=='0'">
                      <span class="bdg blank bg-yellow">No</span>
                    </p>
                  </td>
                  <td>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='Active'">
                      <span class="bdg blank bg-yellow">Active</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='Draft'">
                      <span class="bdg blank bg-yellow">Draft</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='Expired'">
                      <span class="bdg blank bg-yellow">Expired</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='FAQ'">
                      <span class="bdg blank bg-yellow">FAQ</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='Finished_Translation'">
                      <span class="bdg blank bg-yellow">Finished Translation</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='For_Approval'">
                      <span class="bdg blank bg-yellow">Sent for Approval</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='For_Approval_Var'">
                      <span class="bdg blank bg-yellow">Sent for Approval - VAR</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='Pending'">
                      <span class="bdg blank bg-yellow">Pending</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='Turned_In'">
                      <span class="bdg blank bg-yellow">Turned In</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value=='Under Review'">
                      <span class="bdg blank bg-yellow">Under Review</span>
                    </p>
                    <p class="dValue" *ngIf="crmdoc_res?.status_id?.value==''">
                      <span class="bdg blank bg-yellow">NA</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="crmdoc_data.length==0" class="noDataFound">No data available!!</div>
        </div>
      </div>


      <!-- Crm document block end  -->
      <!--project assignments start -->
      <app-project-assignments></app-project-assignments>
      <!-- project assignments end -->

      <!--project process additional services -->
      <!-- <app-additional-services ></app-additional-services> -->
      <!--project process additional services end -->

      <!--project financials start -->
      <!-- <app-project-financials></app-project-financials> -->
      <!--project financials end -->

      <div class="white-box">
        <div class="tHeader">
          <h2> COMMENTS / COMMUNICATION / SPECIAL INSTRUCTIONS </h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="mr-8"> <i class="material-icons">filter_list</i> Short by </div>
              <mat-form-field fxFlex="20">
                <mat-label>Type</mat-label>
                <mat-select>
                  <mat-option label="New" value="New">Internal</mat-option>
                  <mat-option label="Sample" value="Sample">Customer</mat-option>
                  <mat-option label="Sample" value="Sample">Vendor</mat-option>

                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="20">
                <mat-label>Added by</mat-label>
                <mat-select>
                  <mat-option label="New" value="New">Joan mark</mat-option>
                  <mat-option label="Sample" value="Sample">Eryon gert</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="commentDiv" *ngFor="let res of RequestQueue_response?.projectcomment.data">
              <div class="quesDiv" *ngIf="res?.users?.data?.user_group_id=='Vendor'">
                <p class="mb-4"><b>{{res.users?.data?.fullname}}</b>
                  <span class="mUsr"> ({{res?.users?.data?.user_group_id}} )</span>
                  <span class="mStamp"> {{res?.created_at?.date | date: 'yyyy-MM-dd'}}
                    {{res?.created_at.date | date:"HH:mm"}}</span>
                </p>
                <p class="mCmnt">{{res?.message}}</p>
              </div>

              <div class="ansDiv" *ngIf="res?.users?.data?.user_group_id!='Vendor'">
                <div class="divinn">
                  <p class="mb-4"><b>{{res.users?.data?.fullname}}</b>
                    <span class="mUsr"> ({{res?.users?.data?.user_group_id}} )</span>
                    <span class="mStamp"> {{res?.created_at?.date | date: 'yyyy-MM-dd'}}
                      {{res?.created_at.date | date:"HH:mm"}}</span>
                  </p>
                  <p class="mCmnt">{{res?.message}}</p>
                </div>
              </div>

            </div>


            <div class="box-blank">
              <form name="commentForm" [formGroup]="commentForm">
                <p>
                  <mat-form-field class="d-full-width">
                    <textarea matInput formControlName="message" placeholder="Leave a comment"></textarea>
                    <mat-hint>Note: if you have questions, please enter them here and click I have questions
                      to not accept for now.</mat-hint>
                  </mat-form-field>
                </p>
                <p class="mt-32 mb-32">
                  <b>Message Type:</b>
                  <mat-radio-group formControlName="message_type">
                    <mat-radio-button value="1" class="pr-20">Internal</mat-radio-button>
                    <mat-radio-button value="2" class="pr-20">Customer</mat-radio-button>
                    <mat-radio-button value="3" class="pr-20">Freelancer</mat-radio-button>
                  </mat-radio-group>
                </p>
                <p>
                  <mat-checkbox formControlName="is_important">Important Message</mat-checkbox>
                </p>
                <p>
                  <button mat-raised-button color="primary" type="submit" (click)="submitComment()"
                    [disabled]="commentForm.invalid">Save</button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Project History Start -->
      <!-- <app-project-history ></app-project-history> -->
      <!-- Project History end -->
      <!-- Project email log Start -->
      <app-project-email-log></app-project-email-log>
      <!-- Project email log end -->

      <!-- CRM email log start -->
      <app-crm-email-log></app-crm-email-log>
      <!-- CRM email log end -->
    </div>

    <div fxFlex="25%">
      <div class="projSteps">
        <app-project-history></app-project-history>

      </div>
    </div>
  </div>
</div>