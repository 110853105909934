import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-email-log-preview',
  templateUrl: './email-log-preview.component.html',
  styleUrls: ['./email-log-preview.component.scss']
})
export class EmailLogPreviewComponent implements OnInit {

  id:any;
  response_email:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data :any,private toastr: ToastrService,
  private dialogRef: MatDialogRef<EmailLogPreviewComponent>,private projectsService: ProjectsService) { }

  ngOnInit() {
    if(this.data) {
      this.id=this.data;
      this.getsingleEmailLogs();
    }
  }

  Onclose(){
    this.dialogRef.close();
}

  getsingleEmailLogs()
  {
    
    this.projectsService.getProjectEmailById(this.id)
    .subscribe((response:any) => {
      
        if(response) {
    
        this.response_email=response.data;
     
        // this.paginate = response.meta.pagination;
        // this.total=response.meta.pagination.total;
         }
    });
  }

}
