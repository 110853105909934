import { moveItemInArray } from '@angular/cdk/drag-drop';
var VaultComponent = /** @class */ (function () {
    function VaultComponent() {
        this.timePeriods = [
            'Bronze age',
            'Iron age',
            'Middle ages',
            'Early modern period',
            'Long nineteenth century'
        ];
    }
    VaultComponent.prototype.drop = function (event) {
        moveItemInArray(this.timePeriods, event.previousIndex, event.currentIndex);
    };
    return VaultComponent;
}());
export { VaultComponent };
