<div class="dialog-content-wrapper"  style="position:relative;">
  <div class="tHeader">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <div fxFlex>
        <h4 class="m0 title dialog-title text-uppercase">Change Service Type</h4>
      </div>
      <div fxFlex class="align-right">
          <button mat-icon-button (click)="Onclose()"  matTooltip="Close" aria-label="Close dialog">
                 <mat-icon>close</mat-icon>
          </button>
        </div>
  </div>
  </div>
    
    
    <div mat-dialog-content class="p-16 pt-4 m-0" fusePerfectScrollbar>
      <form class="mat-employee-form" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
         name="Form" [formGroup]="Form">
        <div fxLayout="row" class="mt-8" fxLayoutAlign="start center" fxFlex="1 0 auto">
              <mat-form-field fxFlex="100">
                <mat-label>Service Type*</mat-label>
                <mat-select  placeholder="Select service type" disableOptionCentering class="myPanelClass"  formControlName="translation_type">
                  <mat-option [value]="''">
                    Select
                   </mat-option>
                  <mat-option  value="Certified">  
                    Certified
                  </mat-option>
                 <mat-option  value="Non Certified">
                  Non Certified
                 </mat-option>
              
                </mat-select>
                <mat-error *ngIf="Form.get('translation_type').hasError('required')">
                  Service Type is required!
                </mat-error>
              </mat-form-field>
      </div>
       
      <div fxLayoutAlign="space-between end" >
          
            <button mat-raised-button color="primary" (click)="submit()" [disabled]="Form.invalid">Update</button>
          
         </div>
    
    
        </form>
    </div>    
    </div>