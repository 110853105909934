<div class="dialog-content-wrapper"  style="position:relative;">
  <div class="tHeader">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
  <div fxFlex>
      <h4 class="m0 title dialog-title text-uppercase">Change speciality</h4>
    </div>
    <div fxFlex class="align-right">
        <button mat-icon-button (click)="Onclose()"  matTooltip="Close" aria-label="Close dialog">
               <mat-icon>close</mat-icon>
        </button>
      </div>
</div>
</div>
  
  
  <div mat-dialog-content class="p-16 pt-4 m-0" fusePerfectScrollbar>
    <form class="mat-employee-form" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
       name="form" [formGroup]="Form">
      <div fxLayout="row" class="mt-8" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field   fxFlex="100">
            <mat-label>Specialization</mat-label>
            <mat-select  formControlName="specilization_id" disableOptionCentering class="myPanelClass">         
              <mat-option [value]="''">
                      Select
                  </mat-option>
          <mat-option *ngFor="let speciality of specialityData" [value]="speciality.id">
              {{speciality.name}}
          </mat-option>
      </mat-select>
      <mat-error *ngIf="Form.get('specilization_id').hasError('required')">
          Specialization is required!
      </mat-error>
        </mat-form-field>
    </div>
     
    <div fxLayoutAlign="space-between end" >
        
          <button mat-raised-button color="primary" (click)="submit()" [disabled]="Form.invalid">Update</button>
        
       </div>
  
  
      </form>
  </div>    
  </div>