import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,} from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-status-change-additional-service',
  templateUrl: './status-change-additional-service.component.html',
  styleUrls: ['./status-change-additional-service.component.scss']
})
export class StatusChangeAdditionalServiceComponent implements OnInit {
  Form: FormGroup;
  project_assignment_id:number;
  constructor(private _formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data :any,
    private dialogRef: MatDialogRef<StatusChangeAdditionalServiceComponent>,private toastr: ToastrService,
    private projectsService: ProjectsService) { }

  ngOnInit() {

    if(this.data) {
      this.project_assignment_id=this.data.project_assignment_id;
  
     
    }

    this.Form = this._formBuilder.group({
     
      status : ['',Validators.compose([Validators.required])],
      final_comment:['',Validators.compose([Validators.required])]
    
  });
  }

  Onclose(){
    this.dialogRef.close();
}

submit(){
  let assignment_obj;
  assignment_obj=this.data;
  assignment_obj.final_comment=this.Form.value.final_comment;
  assignment_obj.status=this.Form.value.status;

  this.projectsService.changeStatusAdditionalService(assignment_obj)
    .subscribe((response) => {
        if(response) {
           this.toastr.success("Status Updated!!");
           this.dialogRef.close(true);
          //  window.location.href = '/pms/project/details/'+this.data.project_id;
        }
        
    });
}
}
