
<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="white-box">
    <div class="main-section whatsApp">
      <div class="head-section">
        <div class="headLeft-section">
          <div class="headLeft-sub">
            <img src="/assets/images/wa.svg" />
            <span>Base # {{BaseNumber}}</span>
          </div>
        </div>
        <div class="headRight-section">
          <div class="headRight-sub">

            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px">
              <div fxFlex="55">

                <div class="msgDisplay">
                  <h3>+{{fromNumber}}</h3>
                  <small>Conversation History</small>
                </div>
                <div class="inputDisplay" style="display: none;">
                  <mat-form-field>
                    <mat-label>Enter mobile number with country code e.g +1234567890</mat-label>
                    <input matInput>
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex="40" class="text-right">
                <button mat-flat-button color="accent" class="mt-8 mr-8" (click)="sendMessagePopup()"><i class="material-icons"> add_circle </i> New
                  Message</button>

                  <button mat-stroked-button class="mt-8"  (click)="pageRefresh()"><i class="material-icons"> refresh </i> Refresh</button>

              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="body-section">
        <div class="left-section">
          <div class="search-section">
            <div class="search-sub p-16">
              <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="searchText">
              </mat-form-field>

            </div>
          </div>
          <div class="h500 mCustomScrollbar" data-mcs-theme="minimal-dark">
            <ul>
              <li *ngFor="let hero of heroes | appFilter: searchText"
                [ngClass]="{'active': hero.recipient === fromNumber,'UnreadClass': hero.unread_count!=0}"
                (click)="getHero(hero.recipient)">
                <div class="chatList">
                  <div class="img">
                    <span class="material-icons">account_circle</span>
                  </div>
                  <div class="desc">
                    <small class="time">{{hero.datetime.date | date: 'yyyy-MM-dd'}}</small>
                    <h5>+{{hero.recipient}}</h5>
                    <small>{{hero.body}}</small>
                  </div>
                  <span class="unreadmessages" *ngIf="hero.unread_count!=0">{{hero.unread_count}}</span>
                  <!-- <app-whatsapp-unreadmessages [unreadtotal]="hero.recipient"></app-whatsapp-unreadmessages> -->
                </div>
               
              </li>

            </ul>
          </div>
        </div>
        <div class="right-section">
          <div class="message" data-mcs-theme="minimal-dark" #scrollMe>
            <perfect-scrollbar #chatPS >
            <ul>
              <li *ngFor="let herohistory of hero; let i=index"
                [ngClass]="{'msg-right':herohistory.sent === 1, 'msg-left':herohistory.sent === 0}">
                <div class="msg-left-sub">
                  <span class="material-icons">account_circle</span>
                  {{herohistory.sent === 0 ? '+'+herohistory.recipient : 'Day PM'}}
                  <span *ngIf="herohistory?.senderdetail!=null && herohistory.sent === 1"> ({{herohistory?.senderdetail?.fullname}})</span>
                  <span *ngIf="herohistory?.readerdetail!=null && herohistory.sent === 0"> ({{herohistory?.readerdetail?.fullname}})</span>
                  
                  <div class="msg-desc" *ngIf="herohistory.body !='' && herohistory.body != undefined">
                    {{herohistory.body}}
                  </div>
                  <small>{{herohistory.datetime}}</small>
                  <!-- test start -->
                  <span class="clearfix" style="display: block;"></span> 
                  <app-whatsapp-attachment *ngIf="herohistory.attachment" 
                    [childAttachment]="herohistory.attachment"></app-whatsapp-attachment>
                    <a style="cursor: pointer;" *ngIf="herohistory.readstatus==0 && herohistory.sent==0 && (hero.length == i+1)" (click)="markAsRead()">Mark as read</a>
                  <!-- test end -->
                </div>
              </li>
              
            </ul>
            </perfect-scrollbar>
          </div>
          <div class="right-section-bottom">
            <form [formGroup]="heroForm" (ngSubmit)="sendWhatsappto()">
              <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px">
                <ng-container *ngIf="hasUserMessage">
                    <div fxFlex="90">
                      <mat-form-field>
                        <mat-label>Type a message</mat-label>
                        <textarea matInput id="body" formControlName="body"> </textarea>
                      </mat-form-field>
                      <div class="inputInfo mt-4"><small> Maximum 250 characters</small></div>
                    </div>
                    <div fxFlex="8">
                      <button mat-flat-button color="accent" class="mt-24" [disabled]="!heroForm.valid"><span
                          class="material-icons">send</span> Send</button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!hasUserMessage">
                  <div fxFlex="98">
                    Once we receive a message from the user, you will be able to send a message in return.
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>




















</div>