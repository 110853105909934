import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
var EditReviewTranslatorComponent = /** @class */ (function () {
    function EditReviewTranslatorComponent(_formBuilder, router, route) {
        this._formBuilder = _formBuilder;
        this.router = router;
        this.route = route;
        this.type = 'Add';
    }
    EditReviewTranslatorComponent.prototype.ngOnInit = function () {
        this.reviewForm = this._formBuilder.group({
            firstName: [''],
        });
    };
    EditReviewTranslatorComponent.prototype.back = function () {
        this.router.navigate(['approval']);
    };
    return EditReviewTranslatorComponent;
}());
export { EditReviewTranslatorComponent };
