var FooterComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FooterComponent() {
        this.currentYear = new Date().getFullYear();
    }
    return FooterComponent;
}());
export { FooterComponent };
