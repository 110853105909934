import { Component, OnInit,Output,EventEmitter  } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-additional-information-mailing',
  templateUrl: './additional-information-mailing.component.html',
  styleUrls: ['./additional-information-mailing.component.scss']
})
export class AdditionalInformationMailingComponent implements OnInit {

  project_id:string;
  additonal_service_array:any=[];
  show_address:boolean=true;
  mailing_show:boolean=false;
  @Output() myoutputMailing:EventEmitter<any> = new EventEmitter();
  outputString:string="(NA)";
  constructor( private projectsService: ProjectsService,private route: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getAdditionalServices();
  }

  getAdditionalServices() {

 
    this.projectsService.getAdditionalServices(this.project_id)
    .subscribe((response:any) => {
        if(response) {
          this.additonal_service_array=response.project_addition_service;
          if(this.additonal_service_array.length==0){
            this.mailing_show=false;
            this.sendNotApply();
          }
    
         if(response.project_addition_service.length>0){
          response.project_addition_service.forEach(element => {
            if(element.additional_service.code == "e_preference"){
              this.mailing_show=true;
                this.show_address=false;
                this.sendApply();
            }

            if(element.additional_service.code == "certified_Mailing" || 
            element.additional_service.code == "certified_Mailing_1Day" ||
            element.additional_service.code == "certified_Mailing_International" ||
            element.additional_service.code == "certified_Mailing_Canada"){
              this.show_address=true;
              this.mailing_show=true;       
              this.sendApply();
          }
             
            if(element.additional_service.code=="additional_copy" ){
              this.show_address=true;
              this.mailing_show=true;
              this.sendApply();
            }
            if(element.additional_service.code=="regular_PickUp" ){
              this.mailing_show=true;
              this.show_address=true;
              this.sendApply();
            }
          });
         }
        }
    });
  }

  sendNotApply(){
    this.outputString="(NA)";
    let data:any={
      title:this.outputString,
      click_value:0

    }
    this.myoutputMailing.emit(data);
  }

  sendApply(){

    this.outputString="(Applied)";
    let data:any={
      title:this.outputString,
      click_value:0

    }
    this.myoutputMailing.emit(data);
  }

  Edit(){
    this.outputString="(Applied)";
    let data:any={
      title:this.outputString,
      click_value:1

    }
    this.myoutputMailing.emit(data);
  }

}
