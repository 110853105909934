/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style-1.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i6 from "../../../../../../@fuse/services/config.service";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../../@fuse/components/navigation/navigation.component.ngfactory";
import * as i10 from "../../../../../../@fuse/components/navigation/navigation.component";
import * as i11 from "../../../../../../@fuse/components/navigation/navigation.service";
import * as i12 from "./style-1.component";
import * as i13 from "../../../../../../@fuse/components/sidebar/sidebar.service";
var styles_NavbarVerticalStyle1Component = [i0.styles];
var RenderType_NavbarVerticalStyle1Component = i1.ɵcrt({ encapsulation: 2, styles: styles_NavbarVerticalStyle1Component, data: {} });
export { RenderType_NavbarVerticalStyle1Component as RenderType_NavbarVerticalStyle1Component };
export function View_NavbarVerticalStyle1Component_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { directive: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "navbar-top"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "logo-icon"], ["src", "assets/images/logos/logo-icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "navbar-scroll-container"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(8, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵdid(9, 4341760, [[1, 4]], 0, i5.FusePerfectScrollbarDirective, [i1.ElementRef, i6.FuseConfigService, i7.Platform, i8.Router], { fusePerfectScrollbarOptions: [0, "fusePerfectScrollbarOptions"], enabled: [1, "enabled"] }, null), i1.ɵpod(10, { suppressScrollX: 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "navbar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "fuse-navigation", [["class", "tmsNav"], ["layout", "vertical"]], null, null, null, i9.View_FuseNavigationComponent_0, i9.RenderType_FuseNavigationComponent)), i1.ɵdid(13, 114688, null, 0, i10.FuseNavigationComponent, [i1.ChangeDetectorRef, i11.FuseNavigationService], { layout: [0, "layout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "navbar-top"; var currVal_1 = _co.fuseConfig.layout.navbar.secondaryBackground; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.fuseConfig.layout.navbar.secondaryBackground; var currVal_3 = "navbar-top"; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = "navbar-scroll-container"; var currVal_5 = _co.fuseConfig.layout.navbar.primaryBackground; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = _co.fuseConfig.layout.navbar.primaryBackground; var currVal_7 = "navbar-scroll-container"; _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_8 = _ck(_v, 10, 0, true); var currVal_9 = ""; _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_10 = "vertical"; _ck(_v, 13, 0, currVal_10); }, null); }
export function View_NavbarVerticalStyle1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar-vertical-style-1", [], null, null, null, View_NavbarVerticalStyle1Component_0, RenderType_NavbarVerticalStyle1Component)), i1.ɵdid(1, 245760, null, 0, i12.NavbarVerticalStyle1Component, [i6.FuseConfigService, i11.FuseNavigationService, i13.FuseSidebarService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarVerticalStyle1ComponentNgFactory = i1.ɵccf("navbar-vertical-style-1", i12.NavbarVerticalStyle1Component, View_NavbarVerticalStyle1Component_Host_0, {}, {}, []);
export { NavbarVerticalStyle1ComponentNgFactory as NavbarVerticalStyle1ComponentNgFactory };
