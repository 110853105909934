import { Component, OnInit, Output,EventEmitter  } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-additional-information-apostile',
  templateUrl: './additional-information-apostile.component.html',
  styleUrls: ['./additional-information-apostile.component.scss']
})
export class AdditionalInformationApostileComponent implements OnInit {
  project_id:string;
  apostile_show: boolean=false;
  additonal_service_array:any=[];
  apostile_state: boolean=false;
  shipping_state:string;
  shipping_country:string;
  @Output() myoutputApostile:EventEmitter<any> = new EventEmitter();
  outputString:string="(NA)";
  constructor(private route: ActivatedRoute,
      private projectsService: ProjectsService,private toastr: ToastrService) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getAdditionalServices();
  }

  getAdditionalServices() {
 
    this.projectsService.getAdditionalServices(this.project_id)
    .subscribe((response) => {
        if(response) {
          this.additonal_service_array=response.project_addition_service;
          if(this.additonal_service_array.length==0){
            this.sendNotApply();
          }
    
         if(response.project_addition_service.length>0){
          response.project_addition_service.forEach(element => {
            if(element.additional_service.code==" Apostile_service_Illinois"){
                this.apostile_show=true;
                this.shipping_country=element.apostile_country_source;
                this.apostile_state=true;
                this.sendApply();
            }

            if(
            element.additional_service.code == " Apostile_service_outside_Illinois"){
                this.apostile_show=true;
                this.apostile_state=false;
                this.shipping_country=element.apostile_country_source;
                this.shipping_state=element.apostile_from_state;
                this.sendApply();
            }
          });
         }
        }
    });
  }

  sendNotApply(){
    this.outputString="(NA)";
    let data:any={
      title:this.outputString,
      click_value:0

    }
    this.myoutputApostile.emit(data);
  }

  sendApply(){
    this.outputString="(Applied)";
    let data:any={
      title:this.outputString,
      click_value:0

    }
    this.myoutputApostile.emit(data);
  }

  Edit(){
    this.outputString="(Applied)";
    let data:any={
      title:this.outputString,
      click_value:1
    }
    this.myoutputApostile.emit(data);
  }

}
