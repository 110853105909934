import { HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedService } from '../main/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor(sharedService, router, route) {
        this.sharedService = sharedService;
        this.router = router;
        this.route = route;
    }
    ErrorInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        this.sharedService.show();
        return next.handle(request).pipe(tap(function (evt) {
            if (evt instanceof HttpResponse) {
                _this.sharedService.hide();
            }
        }), catchError(function (err) {
            _this.sharedService.hide();
            if (err.status === 401) {
                var message = {
                    title: 'Error',
                    message: err.error.message,
                    status: 'danger'
                };
                _this.sharedService.toastr(message);
                _this.route.queryParams.subscribe(function (params) {
                    if (params.returnUrl) {
                        _this.router.navigate([params.returnUrl]);
                    }
                    else {
                        _this.router.navigate(['auth/login']);
                    }
                });
                localStorage.clear();
            }
            else if (err.status === 422) {
                var message = {
                    title: 'Error',
                    message: err.error,
                    status: 'danger',
                    duration: 7000
                };
                _this.sharedService.toastr(message);
            }
            else if (err.status === 403) {
                var message = {
                    title: 'Error',
                    message: err.error,
                    status: 'danger',
                    duration: 7000
                };
                _this.sharedService.toastr(message);
            }
            else if (err.status === 404) {
                var message = {
                    title: 'Error',
                    message: 'URL is not valid. Please open correct url.',
                    status: 'danger',
                    duration: 7000
                };
                _this.sharedService.toastr(message);
            }
            else if (err.status === 405) {
                _this.sharedService.toastr(err.error);
            }
            else if (err.status === 410) {
                _this.router.navigate(['not-found']);
            }
            else if (err.status === 466) {
                var message = {
                    title: 'Error',
                    message: 'Something went wrong',
                    status: 'danger',
                    duration: 7000
                };
                _this.sharedService.toastr(message);
            }
            else if (err.status === 400) {
                // const message = {
                //     message: err.error,
                //     status: 'danger'
                // };
                _this.sharedService.toastr(err.error);
            }
            else if (err.status == 0) {
                var message = {
                    message: err.statusText,
                    status: 'danger'
                };
                _this.sharedService.toastr(message);
            }
            else if (err.status == 500) {
                _this.sharedService.toastr(err.error);
            }
            return throwError(err);
        }));
    };
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
