import { Component, OnInit } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from '../../main/shared.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router,ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  constructor( private _fuseConfigService: FuseConfigService,private router:Router,) { 
    this._fuseConfigService.config = {
      layout: {
          navbar   : {
              hidden: true
          },
          toolbar  : {
              hidden: true
          },
          footer   : {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
  };
  }

  ngOnInit() {
  }

  back(){
    this.router.navigate(['welcome']);
      }
}
