import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {  FormBuilder } from '@angular/forms';
import { UserService } from 'app/main/Services/user.service';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'app/main/shared.service';
import { environment } from 'environments/environment';
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class AdminProfileComponent implements OnInit {
  profileForm: FormGroup;
  changeForm: FormGroup;
  id:string;
  profile_file_id:number=0;
  fileError: string = '';
  fileSuccess: string;
  dataPassword:any;
  dataForm:any;
  BaseUrl_file: any;
  file_multiple_data:any=[];
  email:string;
 vendor_file_data:string;
 uploaded_files:any=[];
 userDetail:any;
  constructor(private _formBuilder: FormBuilder,
      private userService: UserService,private router:Router,
      private toastr: ToastrService) { 
        this.BaseUrl_file = environment.baseUrl_file;
      }

  ngOnInit() {
    this.id=SharedService.get('id');
    this.getUserData();
    this.profileForm = this._formBuilder.group({
      fullname  : ['' ,Validators.compose([Validators.required])  ],
      email     : ['',Validators.compose([Validators.required,Validators.email])],
      status : [],
  });

  this.changeForm = this._formBuilder.group({
    old_password : ['' ,Validators.compose([Validators.required, Validators.minLength(6)]) ],
    new_password  : ['' ,Validators.compose([Validators.required, Validators.minLength(6)]) ],
    confirm_password: [
      '',
      [
        confirmPasswordValidator,
        Validators.required,
        Validators.minLength(6)
      ]
    ],
});
  }

  updateForm(){
    let data :any={
      file_id:''
    };
    if(this.profile_file_id !== 0){
      data.file_id = this.profile_file_id;
     }
    this.userService.updateProfileImage(data).subscribe(
      resposne => {
          if (resposne) {
            SharedService.set(SharedService.PROFILE_PIC, this.vendor_file_data );
            SharedService.set(SharedService.USER_NAME, this.profileForm.value.fullname);
            this.toastr.success(resposne.response);
            window.location.href = '/admin-profile';
          }
      });
 }

 updatePassword(){
    if(this.changeForm.value.confirm_password!='' && this.changeForm.value.new_password!=''  && this.changeForm.value.old_password!=''){
      this.dataPassword=this.changeForm.value;  
      this.dataPassword.email=this.profileForm.value.email;
      this.dataPassword.fullname=this.profileForm.value.fullname;
      this.dataPassword.status=this.profileForm.value.status;
    this.userService.update(this.id,this.dataPassword).subscribe(
      resposne => {
          if (resposne) {
               this.toastr.success('Password updated successfully');
               window.location.href = '/admin-profile';
          }
      });
    }
    else{
      this.toastr.error('please select all password fields');
      
    }
 }
  getUserData(){
    this.userService.getUser(this.id)
    .subscribe((response:any) => {
        if(response) {
          if(response.data.profile_file_id!=null){
            this.profile_file_id=response.data.profile_file_id.id;
            this.vendor_file_data=this.BaseUrl_file + response.data.profile_file_id.path;
          }
          
          if(response.data.user_portal.length>0)
          {
            let portal_access_value = '';
            response.data.user_portal.forEach(elem => {
              portal_access_value += elem.access_title+ ', ';
            });
            response.data.portal_access_value = portal_access_value.slice(0, -2);
          }
        
          this.userDetail=response.data;
          this.profileForm.patchValue({
            fullname: response.data.fullname,
            email : response.data.email,
            status : response.data.status.toString(),
        });
        }
    });
  }

// start multiple file upload

onFileUpload() { 
  this.file_multiple_data=[];
  const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
  fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx','zip','txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
        // loop through files
      for (var i = 0; i < files.length; i++) {
      // get item
      file = files.item(i);
      //or
      file = files[i];
      var ext = re.exec(file.name)[1];
      if(file && filecheck.indexOf(ext) != -1) {
          let size = file.size/1024/1024;
          if (size > 20) {
              this.fileError = 'File size should not be more than 20MB.';
              return;
          }
        this.file_multiple_data.push(file);
      }
      else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
      }
}
this.addDocument(this.file_multiple_data);      
  };
 
  fileUpload.click();
}

// addDocument
addDocument(file) {
  this.fileSuccess = '';
  var uploadData = new FormData();
  var ins = file.length;
  for (var x = 0; x < ins; x++) {
    uploadData.append('file[]', file[x]);
   }
  this.userService.uploadFile(uploadData)
  .subscribe((response) => {
    this.uploaded_files=response.data;
    response.data.forEach(element => {
    this.profile_file_id=element.id;  
    this.vendor_file_data=this.BaseUrl_file+'/uploads/'+element.name;
    });
      this.fileSuccess ='Uploaded successfully!!';
  }
  );
}
// end multiple file upload
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }
  const password = control.parent.get('new_password');
  const passwordConfirm = control.parent.get('confirm_password');
  if (!password || !passwordConfirm) {
    return null;
  }
  if (passwordConfirm.value === '') {
    return null;
  }
  if (password.value === passwordConfirm.value) {
    return null;
  }
  return { passwordsNotMatching: true };
};