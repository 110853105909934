import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatDialogRef } from '@angular/material';
var DeadlineChangeComponent = /** @class */ (function () {
    function DeadlineChangeComponent(_formBuilder, toastr, data, projectsService, dialogRef) {
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.data = data;
        this.projectsService = projectsService;
        this.dialogRef = dialogRef;
        this.specialityData = [];
        this.today = new Date();
        this.minDate = this.today;
    }
    DeadlineChangeComponent.prototype.ngOnInit = function () {
        this.Form = this._formBuilder.group({
            due_date: ['', Validators.compose([Validators.required])]
        });
        if (this.data.due_date != '' || this.data.due_date != null) {
            this.Form.patchValue({
                due_date: this.data.due_date
            });
        }
    };
    DeadlineChangeComponent.prototype.Onclose = function () {
        this.dialogRef.close(true);
    };
    DeadlineChangeComponent.prototype.submit = function () {
        var _this = this;
        var date_value = this.dateFormat(this.Form.value.due_date);
        var final_data = {
            due_date: date_value
        };
        this.projectsService.updateDeadline(this.data.project_id, final_data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success("Deadline change successfully!");
                _this.Onclose();
            }
        });
    };
    // date format
    DeadlineChangeComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt + ' 00:00:00';
    };
    return DeadlineChangeComponent;
}());
export { DeadlineChangeComponent };
