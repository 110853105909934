import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var SendMessageComponent = /** @class */ (function () {
    function SendMessageComponent(_formBuilder, toastr, projectsService, dialogRef) {
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.projectsService = projectsService;
        this.dialogRef = dialogRef;
    }
    SendMessageComponent.prototype.ngOnInit = function () {
        this.Form = this._formBuilder.group({
            recipient: ['', Validators.compose([Validators.required, Validators.pattern("^\\+(?:[0-9] ?){10,14}[0-9]$")])],
            body: ['', Validators.compose([Validators.required])]
        });
    };
    SendMessageComponent.prototype.Onclose = function () {
        this.dialogRef.close(true);
    };
    SendMessageComponent.prototype.submit = function () {
        var _this = this;
        var recipientFormat = this.Form.value.recipient.replace("+", "");
        if (this.Form.valid) {
            var obj = {
                recipient: recipientFormat,
                body: this.Form.value.body
            };
            this.projectsService.sendSmsto(obj)
                .subscribe(function (response) {
                _this.toastr.success("Message sent Successfully");
                _this.dialogRef.close(true);
            });
        }
    };
    return SendMessageComponent;
}());
export { SendMessageComponent };
