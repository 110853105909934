/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/vertical/layout-1/layout-1.component.ngfactory";
import * as i3 from "./layout/vertical/layout-1/layout-1.component";
import * as i4 from "../@fuse/services/config.service";
import * as i5 from "./layout/horizontal/layout-1/layout-1.component.ngfactory";
import * as i6 from "./layout/horizontal/layout-1/layout-1.component";
import * as i7 from "../@fuse/components/progress-bar/progress-bar.component.ngfactory";
import * as i8 from "../@fuse/components/progress-bar/progress-bar.component";
import * as i9 from "../@fuse/components/progress-bar/progress-bar.service";
import * as i10 from "@angular/common";
import * as i11 from "../@fuse/components/sidebar/sidebar.component.ngfactory";
import * as i12 from "../@fuse/components/sidebar/sidebar.component";
import * as i13 from "@angular/animations";
import * as i14 from "../@fuse/services/match-media.service";
import * as i15 from "../@fuse/components/sidebar/sidebar.service";
import * as i16 from "@angular/flex-layout/core";
import * as i17 from "../@fuse/components/theme-options/theme-options.component.ngfactory";
import * as i18 from "../@fuse/components/theme-options/theme-options.component";
import * as i19 from "@angular/forms";
import * as i20 from "../@fuse/components/navigation/navigation.service";
import * as i21 from "./app.component";
import * as i22 from "../@fuse/services/splash-screen.service";
import * as i23 from "../@fuse/services/translation-loader.service";
import * as i24 from "@ngx-translate/core";
import * as i25 from "@angular/cdk/platform";
import * as i26 from "@angular/router";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-1", [], null, null, null, i2.View_VerticalLayout1Component_0, i2.RenderType_VerticalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i3.VerticalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "horizontal-layout-1", [], null, null, null, i5.View_HorizontalLayout1Component_0, i5.RenderType_HorizontalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i6.HorizontalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, i7.View_FuseProgressBarComponent_0, i7.RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i8.FuseProgressBarComponent, [i9.FuseProgressBarService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "fuse-sidebar", [["class", "theme-options-sidebar"], ["name", "themeOptionsPanel"], ["position", "right"]], [[2, "open", null], [2, "locked-open", null], [2, "unfolded", null], [2, "animations-enabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_FuseSidebarComponent_0, i11.RenderType_FuseSidebarComponent)), i1.ɵdid(7, 245760, null, 0, i12.FuseSidebarComponent, [i13.AnimationBuilder, i1.ChangeDetectorRef, i1.ElementRef, i4.FuseConfigService, i14.FuseMatchMediaService, i15.FuseSidebarService, i16.MediaObserver, i1.Renderer2], { name: [0, "name"], position: [1, "position"], invisibleOverlay: [2, "invisibleOverlay"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "fuse-theme-options", [], [[2, "bar-closed", null]], null, null, i17.View_FuseThemeOptionsComponent_0, i17.RenderType_FuseThemeOptionsComponent)), i1.ɵdid(9, 245760, null, 0, i18.FuseThemeOptionsComponent, [i10.DOCUMENT, i19.FormBuilder, i4.FuseConfigService, i20.FuseNavigationService, i15.FuseSidebarService, i1.Renderer2], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.fuseConfig.layout.style === "vertical-layout-1"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.fuseConfig.layout.style === "horizontal-layout-1"); _ck(_v, 5, 0, currVal_1); var currVal_6 = "themeOptionsPanel"; var currVal_7 = "right"; var currVal_8 = true; _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).opened; var currVal_3 = i1.ɵnov(_v, 7).isLockedOpen; var currVal_4 = i1.ɵnov(_v, 7).unfolded; var currVal_5 = i1.ɵnov(_v, 7)._animationsEnabled; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_9 = i1.ɵnov(_v, 9).barClosed; _ck(_v, 8, 0, currVal_9); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i21.AppComponent, [i10.DOCUMENT, i4.FuseConfigService, i20.FuseNavigationService, i15.FuseSidebarService, i22.FuseSplashScreenService, i23.FuseTranslationLoaderService, i24.TranslateService, i25.Platform, i9.FuseProgressBarService, i26.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i21.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
