<div class="page-layout profilePage p-20 blank bg-white">
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <span class="title-text h1">
      Transcription
    </span>
  </div>
    <div class="white-box p-40">
        <div class="upload-image">
          <div #imgRef  class="drag-n-drop">
              <div class="text">
                  <div class="surface-card p-3 border-round-md text-xl upload-icon">
                    <i class="fa-regular fa-arrow-up-from-bracket"></i>
                  </div>
                  <p class="font-medium mb-2 text-700 font-size-16" >Drag and Drop file</p>
                  <p class="font-medium mb-2 text-700">OR</p>
              </div>
          </div>
          <div class="flex flex-row button-group" style="width: 280px; text-align: center;">
              <button  type="button" #browseButton mat-raised-button class="ml-4">Browse File</button>
              <button type="button"  mat-raised-button  class="ml-4" [disabled]="!(resumable && resumable.files && resumable.files.length > 0)" (click)="uploadTheFile()">Upload</button>
          </div>
      </div>
      <div class="t-resumable-attachment-file-list" *ngIf="resumable && resumable?.files">
          <div *ngFor="let file of resumable?.files"
              class="t-resumable-attachment-file-item flex flex-row justify-content-between align-items-center mt-2 border-200 border-bottom-1 border-none border-solid mb-2 pb-2">
              <!-- Image preview -->
              <div>
                  <i class="fal fa-file"></i>
              </div>

              <!-- File details -->
              <div class="resumable-file-details  ml-3 mr-3" styleClass="ml-0">
                  <span class="resumable-filename flex flex-column"  >
                      <span class="text-800 font-medium">File Name : {{file.fileName }}</span>
                      <p class="resumable-filesize text-500 text-xs mt-1">
                         Size : ({{file.size | filesize}})
                      </p>
                  </span>
              </div>
              <div class="flex align-item-center" style="width: 100%; position: relative;">
                    <div class="progress-pie-chart float-left" [ngClass]="{'gt-50':progressBarConfig.percentage > 50}"
                        *ngIf="parseProgress(file)"  style="width: 96%;">
                        <div class="ppc-progress">
                                <mat-progress-bar mode="determinate" [value]="progressBarConfig.percentage"></mat-progress-bar>
                        </div>
                        <div class="ppc-percents">
                            <div class="pcc-percents-wrapper">
                                <span>{{progressBarConfig.percentage}}%</span>
                            </div>
                        </div>
                    </div> 
                    <div style="position: absolute; right: 4px; top: -23px;" class="float-left;">
                        <button mat-icon-button  [disabled]="!(resumable && resumable.files && resumable.files.length > 0)"   (click)="cancelUpload()" style="background: red; height: 30px;width: 30px;color: white;margin-top: 8px;">
                            <mat-icon style="margin-top: -10px;">close</mat-icon>
                        </button>
                    </div>
              </div> 
          </div>
      </div>
      <video #video style="background-color: #f8f8f8;" (loadedmetadata)="getDuration($event)" *ngIf="videoUrl" controls class="mt-8" [attr.src]="videoUrl" width="200px"> </video>
      <p *ngIf="duration">Duration : {{duration | durationFormat: 's':'hhmmssLong'}} </p>
      <div fxLayout="row" fxLayoutAlign="center center">
        {{subtitlingIsInProgress?"Subtitling Is In Progress...":""}}
        <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
        <span style="color: green;">{{fileSuccess}}</span>
      </div>
      <div class="box-blank">
          <p  style="margin-bottom: 2rem;white-space:pre-wrap;" [innerHtml]="dataResponse">
          </p>
      </div>
    </div>  
</div>  