import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var PmsGuard = /** @class */ (function () {
    function PmsGuard(router) {
        this.router = router;
    }
    PmsGuard.prototype.canActivate = function (route, state) {
        var roleData = SharedService.get(SharedService.user_group_id);
        if (SharedService.get(SharedService.LOGIN) == 'true' &&
            (roleData == 'Hr-Admin' || roleData == 'Admin' || roleData == 'Project-Manager')) {
            return true;
        }
        this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
        // this.router.navigate(['auth/login']);
        // return false;
    };
    PmsGuard.ngInjectableDef = i0.defineInjectable({ factory: function PmsGuard_Factory() { return new PmsGuard(i0.inject(i1.Router)); }, token: PmsGuard, providedIn: "root" });
    return PmsGuard;
}());
export { PmsGuard };
