import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared.service";
var CrmService = /** @class */ (function () {
    function CrmService(http, sharedService) {
        this.http = http;
        this.sharedService = sharedService;
        this.BaseUrl = environment.APIEndpoint;
    }
    CrmService.prototype.getAllGclids = function () {
        var url = this.BaseUrl + '/gclidUsers';
        return this.sharedService.getApi(url);
    };
    CrmService.prototype.getAllGclid = function (query) {
        var url = this.BaseUrl + '/gclidUsers';
        return this.sharedService.getApi(url);
    };
    CrmService.ngInjectableDef = i0.defineInjectable({ factory: function CrmService_Factory() { return new CrmService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService)); }, token: CrmService, providedIn: "root" });
    return CrmService;
}());
export { CrmService };
