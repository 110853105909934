import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { VendorService } from 'app/main/Services/vendor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EmailTemplateViewComponent } from '../email-template-view/email-template-view.component';
import { AddTempTranslatorComponent } from '../add-temp-translator/add-temp-translator.component';
import { DataService } from 'app/main/Services/data.service';
import { SharedService } from 'app/main/shared.service';
export interface PeriodicElement {
  name: string;
  specilization: string;
  overallEvaluation: string;
  completedJobs: number;
  bestRates: number;
  position: string;
  tztype: string;
}
export interface assignedInfo {
  id: string;
  processType: string;
  languages: string;
  freelancer: string;
  plannedStartDate: string;
  plannedDeadline: string;
  status: string;
}
@Component({
  selector: 'app-project-assignments',
  templateUrl: './project-assignments.component.html',
  styleUrls: ['./project-assignments.component.scss']
})
export class ProjectAssignmentsComponent implements OnInit {
  file_data: any = [];
  project_file_data: string;
  project_id: string;
  BaseUrl_file: any;
  Project_Assigned_obj: any = [];
  Project_Assigned_obj_compare: any = [];
  project_type: number;
  language_pair_count: number;
  file_d: string;
  specialization_id: number;
  ProjectLanguage_data: any = [];
  DetailMainResponse: any = '';
  target_language_select: any = [];
  source_language_value: string;
  source_language_id_value: number;
  vendor_ids: any = [];
  vendor_first_name: any;
  email_temp_id: any;
  vendor: any = [];
  lang_pair: any = [];
  vendor_array: any = [];
  opportunityAmount: any = '';
  vendor_array_match: any = [];
  emailTempResponse: any = [];
  showAssignBlock: boolean = false;
  project_workflow_id: number = 1;
  // vendor_translator_number : any;
  lang1 = '';
  quote_approve: any = [];
  quoteTotal: number;
  target_language_value: string;
  languageData: any = [];
  dataSource_translator: any;
  dataSource2: any;
  source_lang_name: any;
  target_lang_name: any;
  show_search: boolean = false;
  workflow_check: boolean = false;
  show_table: boolean = false;
  show_translator: boolean = false;
  specialityData: any = [];
  response_email_data: any = [];
  response_workflow: any = [];
  response_workflow_additional_services: any = [];
  assignment_data: any = {};
  languageDataArray: any = [];
  Form: FormGroup;
  workFlowForm: FormGroup;
  project_language_id: number;
  assignment_obj: any = {};
  projectWorkFlowResponse: any = [];
  paginate: any = {};
  pageSize: number = 10;
  pageIndex: number = 1;
  compare_target_lang: string;
  quantity_approve: number;
  quantity_rate: number;
  profit_margin: number;
  freelancer_cost_approve: number;
  Opp_Amount: number;
  arrType: any = [];
  workflow_data: any = [];
  show_Proofreading: boolean = false;
  show_Dtp: boolean = false;
  show_Qa: boolean = false;
  show_Editing: boolean = false;
  show_Delivery: boolean = false;
  show_mailing_options: boolean = false;
  show_notarization: boolean = false;
  show_apostile: boolean = false;
  ProjectAssignedStatus: any = [];
  vendor_name: string;
  show_assign_button: boolean = true;
  completed_workflow_array: any = [];
  show_workflow_status: string;
  // filter
  fQuery: string = "";
  filterNativeLanguageQuery: string = "";
  filterSpecializationQuery: string = "";
  filterSearchQuery: string = "";
  filterTypeQuery: string = "";
  translatorType: string;
  native_language: string;
  specialization: string;
  noData: boolean = false;
  assignProcessObj:any;
  language_resObj:any;
  // filter end
  data: any =
    {
      source_lang_id: '',
      project_language_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
    };
  data_approved: any =
    {
      source_lang_id: '',
      service_id: '',
      project_language_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      quantity: '',
      pricing_method: '',
      rate: '',
      sub_total: ''
    };
  file_id_data: any = [];
  bulk_selected_progress: boolean = false;
  customer_duration: boolean = false;
  assignment_update: boolean = false;
  FormAdditionalService: any;
  displayedColumns2: string[] = ['id', 'processType', 'languages', 'freelancer', 'plannedStartDate', 'plannedDeadline', 'status'];
  displayedColumns: string[] = ['position', 'preference', 'name', 'tztype', 'native_language', 'category_level', 'currentJobs', 'completedJobs', 'bestRates'];
  today: Date = new Date();
  getLanguageDataObj: any = {
    specilization_id: Number,
    source_language_id: Number,
    target_language_id: Number,
    skill_id: Number
  };
  constructor(private route: ActivatedRoute, private projectsService: ProjectsService, private httpService: HttpClient,
    private toastr: ToastrService, 
    private vendorService: VendorService, 
    private _formBuilder: FormBuilder, private router: Router,
     private dialog: MatDialog,private dataService: DataService ) {
    this.BaseUrl_file = environment.baseUrl_file;
  }

  ngOnInit() {
    this.dataService.currentWorkflowMessage.subscribe((response) => {
      if(response=='proofreading' || response=='dtp' || response=='editing' || response=='qa')
      {
        let workflowAssignProcessObj = localStorage.getItem('workflowAssignProcessObj');
        if(workflowAssignProcessObj!=null){
          this.assignProcess(this.assignProcessObj);
        }
      } 
 });
    this.project_id = this.route.snapshot.params['id'];
    this.getProjectAssignment_compare();
    this.getProjectAssignment();
    this.httpService.get('/assets/TranslatorType.json').subscribe(
      data => {
        this.arrType = data as string[];	 // FILL THE ARRAY WITH DATA.
      },
      (err: HttpErrorResponse) => {
        this.toastr.warning(err.message);
      }
    );

    this.httpService.get('/assets/ProjectAssignedStatus.json').subscribe(
      data => {
        this.ProjectAssignedStatus = data as string[];	 // FILL THE ARRAY WITH DATA.
      },
      (err: HttpErrorResponse) => {
        this.toastr.warning(err.message);
      }
    );
    this.httpService.get('/assets/WorkFlowStatus.json').subscribe(
      data => {
        this.workflow_data = data as string[];	 // FILL THE ARRAY WITH DATA.
      },
      (err: HttpErrorResponse) => {
        this.toastr.warning(err.message);
      }
    );
    this.getLanguage();
    this.getSpeciality();
    this.getEmailTemplate();
    this.getWorkflow();
    this.getWorkFlowAdditionalServices();
    this.getProjectDetail();
    this.getProjectLanguage();
    this.Form = this._formBuilder.group({
      duration: ['30', Validators.compose([Validators.required])],
      duration_unit: ['1'],
      duration_comment: [''],
      email_template_id: ['', Validators.compose([Validators.required])],
      update_status_interval: ['240', Validators.compose([Validators.required])],
      update_status_note: [''],
      instruction: [''],
      vendor_id: [],
      due_date: ['', Validators.compose([Validators.required])],
    });

    this.workFlowForm = this._formBuilder.group({
      workflow_id: ['', Validators.compose([Validators.required])]
    });
    this.FormAdditionalService = this._formBuilder.group({
      workflow_id: ['', Validators.compose([Validators.required])]
    });
  }


  getProjectDetail() {
    this.projectsService.getProjectDetail(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.project_type = response.data.service_id;
          this.opportunityAmount = response.data.project_amount;
          this.specialization_id = response.data.specilization_id;
          this.getDetailMain(response.data.crm_opp_id);
          this.DownloadZip();
          response.data.projectLanguage.data.forEach(element => {
            this.languageDataArray.push(element);
          });
          this.workflow_check = true;
          this.WorkFlowByProjectId();
          response.data.projectquote.data.forEach(res => {
            if (res.status == 4) {
              this.workflow_check = true;
              this.quoteTotal = res.subTotal;
              this.WorkFlowByProjectId();
              res.projectquoteservice.data.forEach(element => {
                this.data_approved = {
                  source_lang_id: '',
                  service_id: '',
                  project_language_id: '',
                  sourcelanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  targetlanguage: {
                    data: {
                      lang_name: ''
                    }
                  },
                  target_lang_id: '',
                  quantity: '',
                  pricing_method: '',
                  rate: '',
                  sub_total: '',
                };
                this.data_approved.quantity = element.quantity;
                this.data_approved.pricing_method = element.pricing_method;
                this.data_approved.rate = element.rate;
                this.data_approved.sub_total = element.subTotal;
                element.projectlanguages.data.forEach(elem => {
                  this.data_approved.sourcelanguage.data.lang_name = elem.sourcelanguage.data.lang_name;
                  this.data_approved.targetlanguage.data.lang_name = elem.targetlanguage.data.lang_name;
                  if (res.status == 4) {
                    this.compare_target_lang = this.data.targetlanguage.data.lang_name;
                  }
                });
                this.quote_approve.push(this.data_approved);
              });
            }
          });
        }
      });
  }



  DownloadZip() {
    let data = {
      project_id: '',
      type: 1
    }
    data.project_id = this.project_id;
    data.type = this.project_type;

    this.projectsService.downloadZip(data).subscribe(
      resposne => {
        if (resposne) {
          this.file_d = resposne.url;
        }
      }
    );
  }

  // Get Project Language
  getProjectLanguage() {

    this.projectsService.getProjectLanguage(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.ProjectLanguage_data = response.data;
          this.language_pair_count = this.ProjectLanguage_data.length;
          let target_language_obj: any = {
            targetlanguage_id: Number,
            lang_name: String,
            project_lang_id: Number,
            sourcelanguage_id: Number,
            source_language_name: String
          };
          this.ProjectLanguage_data.forEach(element => {
            target_language_obj.targetlanguage_id = element.targetlanguage.id;
            target_language_obj.lang_name = element.targetlanguage.lang_name;
            target_language_obj.source_language_name = element.sourcelanguage.lang_name;
            target_language_obj.sourcelanguage_id = element.sourcelanguage.id;
            target_language_obj.project_lang_id = element.id;
            this.target_language_select.push(target_language_obj);

            target_language_obj = {
              targetlanguage_id: '',
              lang_name: '',
              project_lang_id: '',
              source_language_name: '',
              sourcelanguage_id: ''
            };

          });

          if (response.data) {

            response.data.forEach(element => {
              this.data.project_language_id = element.id;
              if (this.Project_Assigned_obj_compare) {
                this.Project_Assigned_obj_compare.forEach(ele => {
                  if (element.id == ele.project_language_id && ele.status == '5' && ele.project_workflow.workflow_id == 1) {
                    this.vendor_array_match.push(ele);
                  }
                  else if (element.id == ele.project_language_id && ele.status != '3' && ele.status != '7' && ele.project_workflow.workflow_id == 1) {
                    this.vendor_array_match.push(ele);
                  }
                });
              }


              // this.lang1 += element.targetlanguage.lang_name+ ', ' ;
              // this.source_language_value= element.sourcelanguage.lang_name;
              // this.source_lang_name=this.source_language_value;
              // this.source_language_id_value= element.source_language_id;
              // this.data.sourcelanguage.data.lang_name=this.source_language_value;
              // this.data.source_lang_id=this.source_language_id_value;
            });
            // this.target_language_value=this.lang1.slice(0, -2);
          }
        }
      });
  }


  // get Language
  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res: any) => {
        if (res) {
          this.languageData = res.data;

        }
      });
  }

  // select source Name
  getSourceName(evt) {
    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        this.data.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  // select target language
  getTargetName(evt) {
    if (evt.value == "") {
      this.show_search = false;
      this.show_table = false;
      return
    }

    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        this.data.targetlanguage.data.lang_name = element.lang_name;
        this.target_lang_name = element.lang_name;
        this.show_search = true;
        this.show_table = false;
        this.vendor_ids = [];
      }
    });
    this.languageDataArray.forEach(elem => {
      if (elem.targetlanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
        this.data.project_language_id = elem.id;
        this.project_language_id = elem.id;
      }
    });

    this.quote_approve.forEach(elem => {
      if (elem.targetlanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
        let total;
        this.quantity_approve = parseFloat(elem.quantity);
        this.quantity_rate = parseFloat(elem.rate);
        total = this.quantity_approve * this.quantity_rate;
        this.profit_margin = elem.sub_total - this.quantity_rate;
        this.Opp_Amount = this.roundToTwo(total);
      }
    });

  }

  // Get Speciality 

  getSpeciality() {
    this.vendorService.getSpeciality()
      .subscribe((res) => {
        if (res) {
          this.specialityData = res;
        }
      });
  }

  // GetTranslator Languages
  getLanguageData(language_res: any) {
    this.language_resObj = language_res;

    this.project_language_id = language_res.project_lang_id;
    this.target_lang_name = language_res.lang_name;
    this.source_lang_name = language_res.source_language_name;
    this.quote_approve.forEach(elem => {
      if (elem.targetlanguage.data.lang_name == this.target_lang_name) {
        let total;
        this.quantity_approve = parseFloat(elem.quantity);
        this.quantity_rate = parseFloat(elem.rate);
        total = this.quantity_approve * this.quantity_rate;
        this.profit_margin = elem.sub_total - this.quantity_rate;
        this.Opp_Amount = this.roundToTwo(total);
      }
    });

    // let obj:any={
    //   specilization_id:Number,
    //   source_language_id:Number,
    //   target_language_id:Number
    // };

    this.getLanguageDataObj.specilization_id = this.specialization_id;
    this.getLanguageDataObj.source_language_id = language_res.sourcelanguage_id;
    this.getLanguageDataObj.target_language_id = language_res.targetlanguage_id;
    this.getLanguageDataObj.skill_id = 1;
    let query = '';
    this.projectsService.ProjectTranslatorAssignment(this.getLanguageDataObj, query)
      .subscribe((response) => {
        if (response) {
          this.translatorType = '';
          this.native_language = '';
          this.specialization = '';
          if (response.data.length > 0) {
            // test
            let show_available_translator = document.getElementById('show_available_translator');
            if (show_available_translator != null) {
              show_available_translator.scrollIntoView();
              show_available_translator = null;
            }
            //this.toastr.success("Data available!!");
            // test

            response.data.forEach(element => {
              element.preference = false;
            });
            this.dataSource_translator = new MatTableDataSource(response.data);

            this.vendor = [];
            this.vendor_ids = [];
            this.show_table = true;
            this.noData = false;
          }
          else {
            this.show_table = false;
            this.toastr.error("No data available!!");
            this.noData = true;
          }
        }
      });

  }

  selectFinance(evt: any, val) {
    let obj = {
      id: '',
      preference: 0
    }
    var index = this.vendor_ids.findIndex(item => item.id == val.id);
    if (evt.checked && index == -1) {
      this.vendor_first_name = val.first_name;
      this.vendor.push(val);
      obj.id = val.id;
      this.vendor_ids.push(obj);
    }
    else if (!evt.checked && index != -1) {
      let indexVendor = this.vendor.indexOf(val.id);
      this.vendor_ids.splice(index, 1);
      this.vendor.splice(indexVendor, 1);
      this.dataSource_translator.data.forEach(element => {
        if (element.id == val.id) {
          element.preference = false;
        }
      });
    }
    this.getFileDetail();
    this.Form.patchValue({
      vendor_id: this.vendor_ids
    });
  }

  radioChangeHandler(event: any, val: any) {
    if (event.target.checked) {
      this.vendor_ids.map(element => {
        return element.preference = 0;
      });
      this.dataSource_translator.data.forEach((rec) => {
        if (rec.id != val.id) {
          rec.preference = false;
        }
      })
      this.vendor_ids.forEach(element => {
        if (element.id == val.id) {
          element.preference = 1;
        }
      });
    }
  }

  getEmailTemplate() {
    this.projectsService.getEmailTemplateTranslation()
      .subscribe((response) => {
        if (response) {
          this.response_email_data = response.data;
        }
      });
  }

  getWorkflow() {
    this.projectsService.getWorkFlow()
      .subscribe((response) => {
        if (response) {
          this.response_workflow = response.data;
        }
      });
  }

  getWorkFlowAdditionalServices() {
    this.projectsService.getWorkFlowAdditionalServices()
      .subscribe((response) => {
        if (response) {
          this.response_workflow_additional_services = response.data;
        }
      });
  }

  // Submit Assignment project
  submit() {
    if (this.file_id_data.length == 0) {
      this.toastr.error("Please select any files");
      return;
    }
    this.assignment_obj = this.Form.value;
    this.assignment_obj.due_date = this.dateFormat(this.Form.value.due_date);
    this.assignment_obj.project_language_id = this.project_language_id;
    this.assignment_obj.service_id = 1;
    this.assignment_obj.start_date = '';
    this.assignment_obj.completed_percentage = 0;
    this.projectWorkFlowResponse.forEach(element => {
      if (element.workflow_id == 1) {
        this.project_workflow_id = element.id;
      }
    });
    this.assignment_obj.project_workflow_id = this.project_workflow_id;
    //this.assignment_obj.quantity_words = this.quantity_approve;
    this.assignment_obj.quantity_words = this.DetailMainResponse.sprj1_t9n_wordcount_c.value;
    this.assignment_obj.file_id = this.file_id_data;

    this.projectsService.ProjectAssignment(this.assignment_obj)
      .subscribe((response) => {
        if (response) {
          this.show_table = false;
          this.vendor_ids = [];
          this.data.target_lang_id = '';
          this.show_search = false;
          this.vendor = [];
          this.Form = this._formBuilder.group({
            duration: ['30', Validators.compose([Validators.required])],
            duration_unit: ['1'],
            duration_comment: [''],
            email_template_id: ['', Validators.compose([Validators.required])],
            update_status_interval: ['240', Validators.compose([Validators.required])],
            update_status_note: [''],
            instruction: [''],
            vendor_id: [],
            due_date: ['', Validators.compose([Validators.required])],
          });
          this.toastr.success(response.message);
          let top = document.getElementById('top');
          if (top !== null) {
            top.scrollIntoView();
            top = null;
          }
          window.location.href = '/pms/project/details/' + this.project_id;
        }
        // else{
        //   this.toastr.error("Something went wrong");
        // }
      });
  }


  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + '-' + month + '-' + dt + ' 00:00:00';
  }

  // get project assignment
  getProjectAssignment() {
    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;
    let query = 'page=1&per_page=5';
    this.projectsService.getProjectAssignment(query, obj)
      .subscribe((response: any) => {
        if (response) {
          response.response.data.forEach(element => {
            let project_status = '';
            this.ProjectAssignedStatus.forEach(ele => {
              if (ele.id == element.status) {
                project_status = ele.value;
              }
            });
            element.project_status = project_status;
            this.vendor_name = element.vendor.first_name + ' ' + element.vendor.last_name + '';
            let lang1 = '';
            let lang_array = [];
            element.project_language.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
              lang_array.push(lang1);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)
            }
            element.lang_array = lang_array;
            this.lang_pair = lang_array;
            this.vendor_array.push(element);
            response.response.lang_array = lang_array;
          });
          this.Project_Assigned_obj = response.response.data;
          this.dataSource2 = new MatTableDataSource(response.response.data);
          this.paginate = response.response;
        }
        // else{
        //   this.toastr.error("Something went wrong");
        // }
      });
  }


  // get project assignment
  getProjectAssignment_compare() {
    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;
    let query = 'page=1&per_page=20';
    this.projectsService.getProjectAssignment(query, obj)
      .subscribe((response: any) => {
        if (response) {
          this.Project_Assigned_obj_compare = response.response.data;
          if (this.Project_Assigned_obj_compare.length > 0) {
            this.Project_Assigned_obj_compare.forEach(element => {
              if (element.status == "5" && element.project_workflow.workflow.name == "Translation") {
                let obj: any = {
                  workflow_id: '',
                  workflow_name: '',
                  vendor_name: '',
                  assignment_date: ''
                }
                obj.workflow_id = element.project_workflow.workflow.id;
                obj.workflow_name = element.project_workflow.workflow.name;
                obj.vendor_name = element.vendor.first_name + element.vendor.last_name;
                obj.assignment_date = element.project_workflow.updated_at;
                this.completed_workflow_array.push(obj);
              }
            });
            this.completed_workflow_array.sort(function (a, b) {
              return a.workflow_id - b.workflow_id;
            });
          }
        }
        // else{
        //   this.toastr.error("Something went wrong");
        // }
      });
  }

  paginateData(event) {
    let Query = '';
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    Query = 'page=' + this.pageIndex + '&per_page=' + this.pageSize;
    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;
    this.projectsService.getProjectAssignment(Query, obj)
      .subscribe((response) => {
        if (response) {
          response.response.data.forEach(element => {
            let project_status = '';
            this.ProjectAssignedStatus.forEach(ele => {
              if (ele.id == element.status) {
                project_status = ele.value;
              }
            });
            // response.response.data.project_status=project_status;
            element.project_status = project_status;
            let lang1 = '';
            let lang_array = [];
            element.project_language.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name + '-' + elm.targetlanguage.lang_name + ',';
              lang_array.push(lang1);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)
            }
            element.lang_array = lang_array;
            response.response.lang_array = lang_array;
          });
          this.Project_Assigned_obj = response.response.data;
          this.dataSource2 = new MatTableDataSource(response.response.data);
          this.paginate = response.response;
        }
        // else{
        //   this.toastr.error("Something went wrong");
        // }
      });
  }

  roundToTwo(num) {
    return Math.round(num * 100) / 100;
  }


  roundToTwoWithName(num) {
    let checknumber = Math.round(num * 100) / 100;
    if (checknumber < 0) {
      return '-$' + (-checknumber) + ' USD';
    } else {
      return '$' + checknumber + ' USD';
    }

  }

  // Submit WorkFlow
  submitWorkFlow() {
    var index = this.projectWorkFlowResponse.findIndex(item => item.workflow_id == this.workFlowForm.value.workflow_id);
    if (index != -1) {
      this.toastr.error("Process Already Exist!!");
      return;
    }
    let object
    object = this.workFlowForm.value;
    object.project_id = this.project_id;
    object.status = 1;
    this.projectsService.projectWorkFlow(object)
      .subscribe((response) => {
        if (response) {
          this.toastr.success("Process Added!!");
          this.WorkFlowByProjectId();
        }
      });
  }


  // Submit WorkFlow
  submitAdditionalService() {
    var index = this.projectWorkFlowResponse.findIndex(item => item.workflow_id == this.FormAdditionalService.value.workflow_id);
    if (index != -1) {
      this.toastr.error("Process Already Exist!!");
      return;
    }

    let object
    object = this.FormAdditionalService.value;
    object.project_id = this.project_id;
    object.status = 1;
    this.projectsService.projectWorkFlow(object)
      .subscribe((response) => {
        if (response) {
          this.toastr.success("Process Added!!");
          this.WorkFlowByProjectId();
        }
      });
  }

  WorkFlowByProjectId() {
    let obj: any = {
      project_id: ''
    };
    obj.project_id = this.project_id;
    this.projectsService.WorkFlowByProjectId(obj)
      .subscribe((response) => {
        if (response) {
          this.projectWorkFlowResponse = [];
          if (response.response.length > 0) {
            response.response.forEach(element => {
              let project_status;

              this.workflow_data.forEach(ele => {
                if (ele.id == element.status) {
                  project_status = ele.value;
                }

                if (element.status == 3 && element.workflow_id == 1) {
                  this.show_assign_button = false;
                }

                if (element.workflow_id == 1) {
                  this.show_workflow_status = project_status;
                }
              });
              element.project_status = project_status;
            });
            this.projectWorkFlowResponse = response.response;
          }

        }
      });
  }


  deleteWorkFlow(res: any) {
    this.projectsService.deleteWorkFlow(res.id).subscribe(
      (response) => {
        this.toastr.success("Process removed  Successfully!!");
        if (this.projectWorkFlowResponse.indexOf(res.id != -1)) {
          let index = this.projectWorkFlowResponse.indexOf(res.id);
          this.projectWorkFlowResponse.splice(index, 1);
        }

        if (res.workflow_id == 1) {
          this.show_translator = false;
        }
        else if (res.workflow_id == 2) {
          this.show_Proofreading = false;
        }
        else if (res.workflow_id == 3) {
          this.show_Qa = false;
        }
        else if (res.workflow_id == 4) {
          this.show_Editing = false;
        }
        else if (res.workflow_id == 5) {
          this.show_Dtp = false;
        }
        else if (res.workflow_id == 6) {
          this.show_Delivery = false;
        }
        else if (res.workflow_id == 7) {
          this.show_mailing_options = false;
        }
        else if (res.workflow_id == 8) {
          this.show_notarization = false;
        }
        else if (res.workflow_id == 9) {
          this.show_apostile = false;
        }
        this.WorkFlowByProjectId();
      }
    );
  }

  assignProcess(res: any) {
    this.show_table = false;
    this.assignProcessObj = res;
    SharedService.set(SharedService.workflowAssignProcessObj, JSON.stringify(this.assignProcessObj));
    this.project_workflow_id = res.id;
    if (res.workflow_id == 1) {
      let top1 = document.getElementById('top1');
      if (top1 != null) {
        top1.scrollIntoView();
        top1 = null;
      }
      this.show_translator = true;
      this.show_Proofreading = false;
      this.show_Qa = false;
      this.show_Editing = false;
      this.show_Dtp = false;
      this.show_Delivery = false;
    }
    else if (res.workflow_id == 2) {
      let top2 = document.getElementById('top2');
      if (top2 != null) {
        top2.scrollIntoView();
        top2 = null;
      }
      this.show_Proofreading = true;
      this.show_translator = false;
      this.show_Qa = false;
      this.show_Editing = false;
      this.show_Dtp = false;
      this.show_Delivery = false;
    }
    else if (res.workflow_id == 3) {
      let top2 = document.getElementById('top2');
      if (top2 != null) {
        top2.scrollIntoView();
        top2 = null;
      }
      this.show_Qa = true;
      this.show_Proofreading = false;
      this.show_translator = false;
      this.show_Editing = false;
      this.show_Dtp = false;
      this.show_Delivery = false;
    }
    else if (res.workflow_id == 4) {
      let top2 = document.getElementById('top2');
      if (top2 != null) {
        top2.scrollIntoView();
        top2 = null;
      }
      this.show_Editing = true;
      this.show_Qa = false;
      this.show_Proofreading = false;
      this.show_translator = false;
      this.show_Dtp = false;
      this.show_Delivery = false;
    }
    else if (res.workflow_id == 5) {
      let top2 = document.getElementById('top2');
      if (top2 != null) {
        top2.scrollIntoView();
        top2 = null;
      }
      this.show_Dtp = true;
      this.show_Editing = false;
      this.show_Qa = false;
      this.show_Proofreading = false;
      this.show_translator = false;
      this.show_Delivery = false;
    }
    else if (res.workflow_id == 6) {
      let top2 = document.getElementById('top2');
      if (top2 != null) {
        top2.scrollIntoView();
        top2 = null;
      }
      this.show_Delivery = true;
      this.show_Dtp = false;
      this.show_Editing = false;
      this.show_Qa = false;
      this.show_Proofreading = false;
      this.show_translator = false;
    }
    else if (res.workflow_id == 7) {
      let top3 = document.getElementById('top3');
      if (top3 != null) {
        top3.scrollIntoView();
        top3 = null;
      }
      this.show_mailing_options = true;
      this.show_notarization = false;
      this.show_apostile = false;
      this.show_Delivery = false;
      this.show_Dtp = false;
      this.show_Editing = false;
      this.show_Qa = false;
      this.show_Proofreading = false;
      this.show_translator = false;
    }
    else if (res.workflow_id == 8) {
      let top3 = document.getElementById('top3');
      if (top3 != null) {
        top3.scrollIntoView();
        top3 = null;
      }
      this.show_notarization = true;
      this.show_mailing_options = false;
      this.show_apostile = false;
      this.show_Delivery = false;
      this.show_Dtp = false;
      this.show_Editing = false;
      this.show_Qa = false;
      this.show_Proofreading = false;
      this.show_translator = false;
    }
    else if (res.workflow_id == 9) {
      let top3 = document.getElementById('top3');
      if (top3 != null) {
        top3.scrollIntoView();
        top3 = null;
      }
      this.show_apostile = true;
      this.show_notarization = false;
      this.show_mailing_options = false;
      this.show_Delivery = false;
      this.show_Dtp = false;
      this.show_Editing = false;
      this.show_Qa = false;
      this.show_Proofreading = false;
      this.show_translator = false;
    }
  }

  showAssignRow() {
    this.showAssignBlock = true;
  }

  // file section start

  selectFile(evt, res) {
    if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
      this.file_id_data.push(res.id);
    }
    else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
      let index = this.file_id_data.indexOf(res.id);
      this.file_id_data.splice(index, 1);
    }
  }

  selectBulkProgress(evt) {
    this.file_id_data = [];
    let check = false;
    this.file_data.forEach((element, key) => {
      element["check"] = evt.checked;
      if (evt.checked) {
        element.check = true;
        this.file_id_data.push(element.id);
      }
      else {
        this.file_id_data = [];
      }
    });
    if (check) {
      this.bulk_selected_progress = true;
    }
  }

  // Get file data
  getFileDetail() {
    this.projectsService.getProjectDetail(this.project_id)
      .subscribe((response) => {
        if (response) {
          this.file_data = [];
          var fullname = "Customer";
          response.data.projectfile.data.forEach(element => {
            if (element.createdby) {
              fullname = element.createdby.data.fullname;
            }
            if (element.targetlanguage) {
              var lang_name = element.targetlanguage.data.lang_name;
            }

            if (element.filetype) {
              var filetype = element.filetype.data.name;
            }
            element.file.data.forEach(elm => {
              this.project_file_data = this.BaseUrl_file + elm.path;
              elm.type = element.type;
              elm.fullname = fullname;
              //elm.created_at= created_at;
              elm.lang_name = lang_name;
              elm.filetype = filetype;
              this.file_data.push(elm);
            });
          });

        }
      });
  }



  // file section end

  getDuration(evt) {
    let duration_value = parseInt(evt.value);
    if (duration_value > 30) {
      this.customer_duration = true;
    } else {
      this.customer_duration = false;
    }
  }

  getAssignmentUpdate(evt) {
    let duration_value = parseInt(evt.value);
    if (duration_value > 240) {
      this.assignment_update = true;
    } else {
      this.assignment_update = false;
    }
  }

  /**
 * Filter courses by category
 */
  onFilter(evt: any, type): void {

    this.fQuery = '?page=1&per_page=100';
    //  translator type filter
    if ((type == "type" && evt.value != "" && evt.value !== undefined)) {
      this.filterTypeQuery = evt.value;
      this.fQuery += "&translation_type=" + this.filterTypeQuery;
    }
    else if (type == "type" && evt.value == "") {
      this.filterTypeQuery = evt.value;
    }
    else if (this.filterTypeQuery !== "") {
      this.fQuery += "&translation_type=" + this.filterTypeQuery;
    }

    //  native language filter
    if ((type == "native_language" && evt.value != "" && evt.value !== undefined)) {
      this.filterNativeLanguageQuery = evt.value;
      this.fQuery += "&native_language=" + this.filterNativeLanguageQuery;
    }
    else if (type == "native_language" && evt.value == "") {
      this.filterNativeLanguageQuery = evt.value;
    }
    else if (this.filterNativeLanguageQuery !== "") {
      this.fQuery += "&native_language=" + this.filterNativeLanguageQuery;
    }
    //  specialization filter
    if ((type == "speciality" && evt.value != "" && evt.value !== undefined)) {
      this.filterSpecializationQuery = evt.value;
      this.fQuery += "&specialization=" + this.filterSpecializationQuery;
    }
    else if (type == "speciality" && evt.value == "") {
      this.filterSpecializationQuery = evt.value;
    }
    else if (this.filterSpecializationQuery !== "") {
      this.fQuery += "&specialization=" + this.filterSpecializationQuery;
    }

    // project search filter
    if ((type == "search" && evt.value != "" && evt.value !== undefined)) {
      this.filterSearchQuery = encodeURIComponent(evt.value);
      this.fQuery += "&search_text=" + this.filterSearchQuery;
    }
    else if (type == "search" && evt.value == "") {
      this.filterSearchQuery = encodeURIComponent(evt.value);
    }
    else if (this.filterSearchQuery !== "") {
      this.fQuery += "&search_text=" + this.filterSearchQuery;
    }
    this.projectsService.ProjectTranslatorAssignment(this.getLanguageDataObj, this.fQuery)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(element => {
            element.preference = false;
          });

          this.dataSource_translator = new MatTableDataSource(response.data);


        }
      });

  }
  cancelAssignedProject(val: any) {
    let obj: any = {
      assignment_id: ''
    };
    obj.assignment_id = val;
    this.projectsService.cancelAssignProject(obj).subscribe(
      (response) => {
        this.toastr.success("Process removed  Successfully!!");
        this.getProjectAssignment();
      }
    );
  }


  getDetailMain(crmid: any) {
    this.projectsService.getDetailMain(crmid)
      .subscribe((response: any) => {
        if (response) {
          this.DetailMainResponse = response.data;
        };
      });
  }


  openDialog() {
    if (this.file_id_data.length == 0) {
      this.toastr.error("Please select any files");
      return;
    }
    this.assignment_obj = this.Form.value;
    this.assignment_obj.due_date = this.dateFormat(this.Form.value.due_date);
    this.assignment_obj.project_language_id = this.project_language_id;
    this.assignment_obj.service_id = 1;
    this.assignment_obj.start_date = '';
    this.assignment_obj.completed_percentage = 0;
    this.projectWorkFlowResponse.forEach(element => {
      if (element.workflow_id == 1) {
        this.project_workflow_id = element.id;
      }
    });
    this.assignment_obj.project_workflow_id = this.project_workflow_id;
    //this.assignment_obj.quantity_words = this.quantity_approve;
    this.assignment_obj.quantity_words = this.DetailMainResponse.sprj1_t9n_wordcount_c.value;
    this.assignment_obj.file_id = this.file_id_data;
    this.email_temp_id = this.assignment_obj.email_template_id;
    this.projectsService.getEmailTemp(this.email_temp_id)
      .subscribe((response: any) => {
        if (response) {
          this.assignment_obj.emailTemp = response.data;
          this.assignment_obj.project_id = this.project_id;
          const dailogRef = this.dialog.open(EmailTemplateViewComponent, {
            width: '850px',
            data: this.assignment_obj
          });
        };
      });
  }


  openDialogAddTranslator() {
    const dailogRef = this.dialog.open(AddTempTranslatorComponent, {
      width: '1200px',
      height: '600px',
    });

    dailogRef.afterClosed().subscribe((response) => {
        if(response)
        {
          this.getLanguageData(this.language_resObj);
          this.assignProcess(this.assignProcessObj);
        }
        
    });
  }

}