<div class="tHeader">
  <h4>Update Template Master</h4>
</div>

  <form name="Form"  [formGroup]="Form">
  <div mat-dialog-content class="pl-16 pb-2" >
    <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
            <mat-form-field   fxFlex="50">
                <mat-label>To</mat-label>
                <input matInput formControlName="to" readonly>
                <mat-error *ngIf="Form.get('to').hasError('required')">
                    To is required!
                  </mat-error>
                  <mat-error *ngIf="Form.get('to').hasError('email')">
                    Please enter a valid email address
                  </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="50">
                <mat-label>Cc</mat-label>
                <input matInput formControlName="cc"  >
              
                  <mat-error *ngIf="Form.get('cc').hasError('email')">
                    Please enter a valid email address
                  </mat-error>
            </mat-form-field>
        </div> -->
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
            <mat-form-field   fxFlex="32">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title"  >
                <mat-error *ngIf="Form.get('title').hasError('required')">
                    Title is required!
                  </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="32">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject" >
                <mat-error *ngIf="Form.get('subject').hasError('required')">
                    Subject is required!
                  </mat-error>
                 
            </mat-form-field>

            <mat-radio-group aria-label="Select an option " fxFlex="32" formControlName="status">
              <mat-radio-button [value]="1">Active</mat-radio-button>
              <mat-radio-button [value]="0">In Active</mat-radio-button>
            </mat-radio-group>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
          <mat-form-field   fxFlex="32">
              <mat-label>From</mat-label>
              <input matInput formControlName="email_from"  >
          
          </mat-form-field>
          <mat-form-field   fxFlex="32">
              <mat-label>To</mat-label>
              <input matInput formControlName="email_to" >
            
               
          </mat-form-field>
          <div fxFlex="28">
            <mat-form-field fxFlex="100" >
              <mat-label>Type</mat-label>
              <mat-select  formControlName="type" disableOptionCentering class="myPanelClass">         
                <mat-option [value]="''">
                        Select
                    </mat-option>
            <mat-option *ngFor="let res of templateType" [value]="res.type">
                {{res.type}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="Form.get('type').hasError('required')">
            Type is required!
        </mat-error>
          </mat-form-field>
          </div>
        </div>
      <div fxLayout="row" class="mt-2" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <ckeditor formControlName="ckeditorContent" [config]="config" [readonly]="false"
         debounce="500">
        </ckeditor>
      </div>
    
    </div>
  </div>
    
  <div class="actWraper" fxLayout="column"  fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
    <button mat-raised-button color="primary" (click)="Onclose()" >Cancel</button>
  <div></div>
  <button mat-raised-button color="primary" (click)="updateMessage()" [disabled]="Form.invalid">Update</button>

</div>
  </form>