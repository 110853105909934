import { OnInit } from '@angular/core';
import { VendorService } from 'app/main/Services/vendor.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
var AddVendorDocComponent = /** @class */ (function () {
    function AddVendorDocComponent(_formBuilder, toastr, vendorService, dialogRef, data) {
        this._formBuilder = _formBuilder;
        this.toastr = toastr;
        this.vendorService = vendorService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.file_multiple_data = [];
        this.fileError = '';
        this.mainprogressbar = false;
        this.uploaded_files = [];
        this.file_id_array = [];
        this.uploaded_files_array = [];
    }
    AddVendorDocComponent.prototype.ngOnInit = function () {
        this.form = this._formBuilder.group({
            type: ['', Validators.compose([Validators.required])],
            comment: ['']
        });
    };
    // start multiple file upload
    AddVendorDocComponent.prototype.onFileUpload = function () {
        var _this = this;
        this.file_multiple_data = [];
        var fileUpload = document.getElementById('docUpload');
        fileUpload.onchange = function () {
            var filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
            var re = /(?:\.([^.]+))?$/;
            var files = fileUpload.files;
            var file;
            // loop through files
            for (var i = 0; i < files.length; i++) {
                // get item
                file = files.item(i);
                //or
                file = files[i];
                var ext = re.exec(file.name)[1];
                if (file && filecheck.indexOf(ext) != -1) {
                    var size = file.size / 1024 / 1024;
                    if (size > 20) {
                        _this.fileError = "File size should not be more than 20MB.";
                        return;
                    }
                    _this.file_multiple_data.push(file);
                }
                else {
                    _this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
                }
            }
            _this.addDocument(_this.file_multiple_data);
        };
        fileUpload.click();
    };
    // addDocument
    AddVendorDocComponent.prototype.addDocument = function (file) {
        var _this = this;
        this.fileSuccess = '';
        this.mainprogressbar = true;
        var uploadData = new FormData();
        var ins = file.length;
        for (var x = 0; x < ins; x++) {
            uploadData.append("file[]", file[x]);
        }
        uploadData.append("project_reference", this.data.reference_id);
        this.vendorService.uploadFile(uploadData)
            .subscribe(function (response) {
            _this.uploaded_files = response.data;
            _this.uploaded_files_array.push(_this.uploaded_files);
            response.data.forEach(function (element) {
                _this.file_id_array.push(element.id);
            });
            _this.fileSuccess = 'Uploaded successfully!!';
            _this.mainprogressbar = false;
        });
    };
    AddVendorDocComponent.prototype.submit = function () {
        var _this = this;
        var filedata = this.form.value;
        filedata.file_id = this.file_id_array;
        filedata.vendor_id = this.data.vendor_id;
        this.vendorService.vendorDocSave(filedata)
            .subscribe(function (response) {
            _this.toastr.success(response.message);
            _this.dialogRef.close(true);
        });
    };
    return AddVendorDocComponent;
}());
export { AddVendorDocComponent };
