<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div class="pTitle">
                <span class="tText"> <b>Today: </b> {{current_date}} </span> <!-- <h1>{{user_group_name}}</h1> -->
            </div>
            <button mat-flat-button class="ml-32" color="accent" [matMenuTriggerFor]="menu"
                *ngIf="url_string == 'pms'"><i class="material-icons clr-w">
                    add_circle
                </i> Add</button>
            <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item routerLink="/pms/addlead" >Create Lead</button> -->
                <button mat-menu-item routerLink="/pms/customer/addcustomer" >Create
                    Customer</button>
            </mat-menu>
            <button mat-flat-button class="ml-32" color="accent" [matMenuTriggerFor]="hrmenu"
                *ngIf="url_string == 'hr'"><i class="material-icons clr-w">
                    add_circle
                </i> Add</button>
            <mat-menu #hrmenu="matMenu">
                <button mat-menu-item routerLink="hr/translator/add-translator">Add Translator</button>
            </mat-menu>
            <button mat-flat-button class="ml-32" color="accent" [matMenuTriggerFor]="marketingmenu"
                *ngIf="url_string == 'marketing'"><i class="material-icons clr-w">
                    add_circle
                </i> Add</button>
            <mat-menu #marketingmenu="matMenu">
                <button mat-menu-item routerLink="marketing/freelancer/add-freelancer">Add Freelancer</button>
            </mat-menu>


            <div class="apsWraper" *ngIf="url_string == 'pms' ">

            <button mat-button class="user-button belI" [matMenuTriggerFor]="pmNav">
                <span class="material-icons">apps</span>    Tools   </button>
           <mat-menu #pmNav="matMenu" [overlapTrigger]="false">
               <!-- <button mat-menu-item routerLink="/pms/sms"  >
                <span class="material-icons">textsms</span>    SMS
               </button> -->
               <!-- <button mat-menu-item  [routerLink]="'/pms/whatsapp'">
                <img src="https://img.icons8.com/material/24/000000/whatsapp--v1.png"/>
                 </button> -->
               
               <button mat-menu-item routerLink="/pms/tools" >
                <span class="material-icons">chrome_reader_mode</span>   OCR
               </button>
               <button mat-menu-item [matMenuTriggerFor]="payments">
                <span class="material-icons">attach_money</span> Braintree  Payment
               </button>

               <!-- <button mat-menu-item [matMenuTriggerFor]="spayments">
                <span class="material-icons">attach_money</span> Stripe  Payment
               </button> -->

           </mat-menu>

        <mat-menu #payments="matMenu" [overlapTrigger]="false">
            <button mat-menu-item routerLink="/pms/transactions/create" >
                <span class="material-icons">payment</span>   Create Braintree Payment
            </button>
            <button mat-menu-item routerLink="/pms/transactions/search" >
                <span class="material-icons">pageview</span>   Search Braintree Transaction
            </button>
        </mat-menu>

        <mat-menu #spayments="matMenu" [overlapTrigger]="false">
            <button mat-menu-item routerLink="/pms/stripetransactions/create" >
                <span class="material-icons">payment</span>   Create Stripe Payment
            </button>
            <button mat-menu-item routerLink="/pms/stripetransactions/search" >
                <span class="material-icons">pageview</span>   Search Stripe Transaction
            </button>
        </mat-menu>





        </div>

        </div>


       


        <!-- pms top search start -->

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="url_string == 'pms' ">

            
            <div class="toolbar search-wrapper">
                <div class="search" fxlayout="row" fxlayoutalign="start center">
                    <mat-icon class="secondary-text mat-icon notranslate material-icons mat-icon-no-color" role="img"
                        aria-hidden="true">search</mat-icon>
                    <input fxflex="" matInput (keyup)="onFilterPms($event.target.value,'search')"
                        [(ngModel)]="Topsearch.data" placeholder="Search freelancer"
                        class="ng-pristine ng-valid ng-touched" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectSearch($event, 'pms')">
                        <mat-option *ngFor="let option of search_response" [value]="option.id">
                            <ul class="list_items">
                                <!-- <li>
                                    {{option?.reference}} {{option?.customer?.data?.first_name }}
                                    {{option?.customer?.data?.last_name}}
                                </li>
                                <li>
                                    {{option?.customer?.data?.email_primary }}
                                </li> -->
                                <li>
                                    {{option?.first_name}} {{option?.last_name }}
                                </li>
                                <li>
                                    {{option?.email }}
                                </li>
                            </ul>

                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
        <!-- pms top search end -->

                <!-- HR Top search start -->

                <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="url_string == 'hr' ">
                    <div class="toolbar search-wrapper">
                        <div class="search" fxlayout="row" fxlayoutalign="start center">
                            <mat-icon class="secondary-text mat-icon notranslate material-icons mat-icon-no-color" role="img"
                                aria-hidden="true">search</mat-icon>
                            <input fxflex="" matInput (keyup)="onFilterTranslator($event.target.value,'search')"
                                [(ngModel)]="topSearchHr.data" placeholder="Search translators"
                                class="ng-pristine ng-valid ng-touched" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectSearch($event, 'hr')">
                                <mat-option *ngFor="let option of search_response_hr" [value]="option.id">
                                    <ul class="list_items">
                                        <li>
                                            {{option?.first_name}} {{option?.last_name }}
                                        </li>
                                        <li>
                                            {{option?.email }}
                                        </li>
                                    </ul>
        
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
                <!-- HR Top search end -->
                <!-- marketing Top search start -->

                <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="url_string == 'marketing' ">
                    <div class="toolbar search-wrapper">
                        <div class="search" fxlayout="row" fxlayoutalign="start center">
                            <mat-icon class="secondary-text mat-icon notranslate material-icons mat-icon-no-color" role="img"
                                aria-hidden="true">search</mat-icon>
                            <input fxflex="" matInput (keyup)="onFilterMarketingTranslator($event.target.value,'search')"
                                [(ngModel)]="topSearchMarketing.data" placeholder="Search translators"
                                class="ng-pristine ng-valid ng-touched" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectSearch($event, 'marketing')">
                                <mat-option *ngFor="let option of search_response_marketing" [value]="option.id">
                                    <ul class="list_items">
                                        <li>
                                            {{option?.first_name}} {{option?.last_name }}
                                        </li>
                                        <li>
                                            {{option?.email }}
                                        </li>
                                    </ul>
        
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
                <!-- Maketing Top search end -->

        

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button class="user-button belI" *ngIf="url_string == 'pms' " [routerLink]="'/pms/sms'">
                <i [matBadge]="smsTotalCount" matBadgeColor="warn" class="material-icons">sms</i>
            </button>

            <button mat-button class="user-button belI"  *ngIf="url_string == 'pms' " [routerLink]="'/pms/whatsapp'">
                <i [matBadge]="whatsappTotalCount" matBadgeColor="warn"> <img class="i-icon" src="assets/images/whatsapp.png"/> </i>
            </button> 

            <button mat-button [matMenuTriggerFor]="ntfWrap" class="user-button belI" *ngIf="url_string == 'pms' ">
                <i [matBadge]="notification_count" matBadgeColor="warn" class="material-icons">notifications </i>
            </button>

            <mat-menu #ntfWrap="matMenu" [overlapTrigger]="false" class="ntfMenu">
                <div class="notWraper">
                    <div class="ntHeader" *ngIf="notification_response.length>0">
                        <h3> <span [matBadge]="notification_count" matBadgeColor="accent">Recent Notifications</span>
                            <span class="tlink ml-64" (click)="notification_read()">Mark as read</span></h3>
                    </div>

                    <div class="allMsg">
                        <div *ngIf="notification_count>0">
                            <div class="msgRow munRead cBlue" *ngFor="let res of notification_response">
                                <div fxFlex="10">
                                    <p class="mTypeIcon"> <i class="material-icons">info</i></p>
                                </div>
                                <div fxFlex="75">
                                    <p><b>{{res}}</b></p>
                                </div>
                            </div>
                        </div>

                        <div class="ftr">
                            <p class="tlink" routerLink="/pms/notifications" >All Notifications
                            </p>

                        </div>
                    </div>
                </div>
            </mat-menu>
            <button mat-button class="user-button belI qhIcon" [matMenuTriggerFor]="helpWrap">
                <i class="material-icons">help_outlin</i>
            </button>
            <mat-menu #helpWrap="matMenu" [overlapTrigger]="false">
                <button mat-menu-item routerLink="/pms/help" >
                    Help on This Page?
                </button>
                <button mat-menu-item routerLink="/pms/kb" >
                    Knowledge Base
                </button>
                <button mat-menu-item routerLink="/pms/support" >
                    Report Problem
                </button>
            </mat-menu>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row wrap" fxLayoutAlign="left">
                    <div fxFlex>
                        <img class="avatar mr-0" [src]="profile_pic"
                            onerror="this.src='assets/images/avatars/profile.jpg'" width="120">
                    </div>
                    <div fxFlex>
                        <div class="pl-8 pt-4">
                            <div class="username text-left" fxHide fxShow.gt-sm><span matBadgePosition="above after"
                                    matBadge="." matBadgeColor="available">{{user_name}}</span></div>

                            <div class="logTime text-left"> <i class="material-icons">timer</i> 04:25 Hours</div>
                        </div>
                    </div>
                </div>

            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="MyProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>
                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>

            <!-- <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button> -->

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'" width="120">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->
        </div>

    </div>

</mat-toolbar>