import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { UpdateAccountComponent } from './update-account/update-account.component';
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  project_id: string;
  dataSource:any;
  dataSource_customer:any;
  response:any;
  project_detail:any=[];
  total_records:number=0;
  total_customer:number=0;
  displayedColumns: string[] = [ 'id', 'customerName', 'projservices', 'languagePair', 'projStartDate', 'projEndDate', 'projStatus'];
  displayedColumns_customer: string[] = ['id', 'name', 'cust_email', 'savedDate', 'paymentMethod', 'status', 'categories', 'leadSource'];
  constructor(private route: ActivatedRoute,private dialog: MatDialog,
    private projectsService: ProjectsService,private toastr: ToastrService) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getProjects();
  }

  getProjects() {
    this.projectsService.getAccountProjects(this.project_id)
    .subscribe((res:any) => {
        if(res) {
       this.response=res.data;
       this.dataSource_customer = new MatTableDataSource(res.data.accountcustomer.data);
       this.total_customer=res.data.accountcustomer.data.length;
        res.data.accountproject.data.forEach(element => {
          if(element.project){
            let lang1 = '';
            let lang_array = [];
            element.project.data.projectLanguage.data.forEach(elm => {
                lang1 = elm.sourcelanguage.data.lang_name + '-' + elm.targetlanguage.data.lang_name + ',' ;
                lang_array.push(lang1);
            });
    
            if(lang_array.length>0){
                let test;
                test = lang_array[lang_array.length-1];
                lang_array[lang_array.length - 1] =test.slice(0,-1)
                
            }
            element.project.lang_array=lang_array;
            this.project_detail.push(element.project)
          }
         
        });
       this.dataSource = new MatTableDataSource(this.project_detail);
       this.total_records=this.project_detail.length;
        }
    });
  }

    // popup update account
    openDialogAccount() {
      let data:any={
        accountDetail: this.response
      };
    
     const dailogRef = this.dialog.open(UpdateAccountComponent, {
       width: '75%',
       data: data
     });

     dailogRef.afterClosed().subscribe((response) => {
       if(response){
         this.getProjects();
       }
     });
   }

}
