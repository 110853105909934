import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatPaginatorModule } from "@angular/material/paginator";
import {
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    MatProgressBarModule,
    MatButtonModule,
    MatRadioModule,
    MatSliderModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatChipsModule,
} from "@angular/material";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { FuseModule } from "@fuse/fuse.module";
import { fuseConfig } from "app/fuse-config";
import {RatingModule} from "ngx-rating";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { LayoutModule } from "@angular/cdk/layout";
import { PmDashboardComponent } from "./pm-dashboard/pm-dashboard.component";
import { ChartsModule } from "ng2-charts";
import { MatTabsModule } from "@angular/material/tabs";
import { ProjectDetailsComponent } from "./project-details/project-details.component";
import { CreateQuoteComponent } from "./create-quote/create-quote.component";
import { ProjectListComponent } from "./project-list/project-list.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { MatExpansionModule } from "@angular/material/expansion";
import { FileManagementComponent } from "./project-details/file-management/file-management.component";
import { ProjectAssignmentsComponent } from "./project-details/project-assignments/project-assignments.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AddFileManagementComponent } from "./project-details/file-management/add-file-management/add-file-management.component";
import { ProjectReleaseComponent } from "./project-details/project-release/project-release.component";
import { QuotesComponent } from "./quotes/quotes.component";
import { FreelancerComponent } from "./freelancer/freelancer.component";
import { FreelancerDetailsComponent } from "./freelancer/freelancer-details/freelancer-details.component";
import { ProjectEditingComponent } from "./project-details/project-editing/project-editing.component";
import { ProjectProofreadingComponent } from "./project-details/project-proofreading/project-proofreading.component";
import { ProjectQaComponent } from "./project-details/project-qa/project-qa.component";
import { ProjectDeliveryComponent } from "./project-details/project-delivery/project-delivery.component";
import { ProjectDtpComponent } from "./project-details/project-dtp/project-dtp.component";
import { AdditionalServicesComponent } from "./project-details/additional-services/additional-services.component";
import { ProjectHistoryComponent } from "./project-details/project-history/project-history.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { ProjectFinancialsComponent } from "./project-details/project-financials/project-financials.component";
import { DatePipe } from "@angular/common";
import { CKEditorModule } from "ng2-ckeditor";
import { MatDialogModule, MatSlideToggleModule } from "@angular/material";
import { ColorPickerModule } from "ngx-color-picker";
import {
    CalendarModule as AngularCalendarModule,
    DateAdapter,
} from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";

import { FuseConfirmDialogModule } from "@fuse/components";

import { CalendarEventFormDialogComponent } from "app/main/pms/calendar/event-form/event-form.component";
import { CompletedWorkflowDetailComponent } from "./project-details/completed-workflow-detail/completed-workflow-detail.component";
import { AdditionalServicesMailingOptionsComponent } from "./project-details/additional-services-mailing-options/additional-services-mailing-options.component";
import { AdditionalServicesNotarizationComponent } from "./project-details/additional-services-notarization/additional-services-notarization.component";
import { AdditionalServicesApostileComponent } from "./project-details/additional-services-apostile/additional-services-apostile.component";
import { StatusChangeAdditionalServiceComponent } from "./project-details/status-change-additional-service/status-change-additional-service.component";
import { AdditionalInformationMailingComponent } from "./project-details/additional-information-mailing/additional-information-mailing.component";
import { AdditionalInformationNotarizationComponent } from "./project-details/additional-information-notarization/additional-information-notarization.component";
import { AdditionalInformationApostileComponent } from "./project-details/additional-information-apostile/additional-information-apostile.component";
import { AddLeadComponent } from "./add-lead/add-lead.component";
import { HelpComponent } from "./help/help.component";
import { KbComponent } from "./kb/kb.component";
import { SupportComponent } from "./support/support.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { EmailPreviewComponent } from "./create-quote/email-preview/email-preview.component";
import { SpecialityChangeComponent } from "./project-details/speciality-change/speciality-change.component";
import { DeliveryEmailTemplateComponent } from "./project-details/project-delivery/delivery-email-template/delivery-email-template.component";
import { AddAdditionalServicesComponent } from "./project-details/additional-services/add-additional-services/add-additional-services.component";
import { NgxMaskModule } from "ngx-mask";
import { ProjectEmailLogComponent } from "./project-details/project-email-log/project-email-log.component";
import { EmailLogPreviewComponent } from "./project-details/project-email-log/email-log-preview/email-log-preview.component";
import { EscapeHtmlPipe } from "assets/pipes/keep-html.pipe";
import { ConfirmationDialogComponent } from "./project-details/confirmation-dialog/confirmation-dialog.component";
import { PmsGuard } from "app/_guards/pms.guard";
import { LanguageChangeComponent } from "./project-details/language-change/language-change.component";
import { DeadlineChangeComponent } from "./project-details/deadline-change/deadline-change.component";
import { ToolsComponent } from "./tools/tools.component";
import { SmsComponent } from "./sms/sms.component";
import { WhatsappComponent } from "./whatsapp/whatsapp.component";
import { UpdateServiceTypeComponent } from "./project-details/update-service-type/update-service-type.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown-angular7";
import { WhatsappUnreadmessagesComponent } from "./whatsapp-unreadmessages/whatsapp-unreadmessages.component";
import { FilterPipe } from "./filter.pipe";
import { SmsUnreadmessageComponent } from "./sms-conversation/sms-unreadmessage/sms-unreadmessage.component";
import { SmsConversationComponent } from "./sms-conversation/sms-conversation/sms-conversation.component";
import { SendMessageComponent } from "./sms-conversation/sms-conversation/popup/send-message/send-message.component";
import { SendWhatsappMessageComponent } from "./whatsapp/popup/send-whatsapp-message/send-whatsapp-message.component";
import { WhatsappAttachmentComponent } from "./whatsapp-attachment/whatsapp-attachment.component";
import { CRMEmailLogComponent } from "./project-details/crm-email-log/crm-email-log.component";
import { CrmEmailLogPreviewComponent } from "./project-details/crm-email-log/crm-email-log-preview/crm-email-log-preview.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { EmailTemplateViewComponent } from "./project-details/email-template-view/email-template-view.component";
import { AddTempTranslatorComponent } from "./project-details/add-temp-translator/add-temp-translator.component";
import { BulkEmailComponent } from "./freelancer/bulk-email/bulk-email.component";
import { ReportComponent } from "./report/report.component";
import { BlacklistedComponent } from "./blacklisted/blacklisted.component";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};
const routes = [
    {
        path: "pms/dashboard",
        component: PmDashboardComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/details/:id",
        component: ProjectDetailsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/details/create-quote/add-file-management",
        component: AddFileManagementComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/details/create-quote/project-release",
        component: ProjectReleaseComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/projects",
        component: ProjectListComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/addlead",
        component: AddLeadComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/project/:id/create-quote",
        component: CreateQuoteComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/quotes",
        component: QuotesComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/freelancer",
        component: FreelancerComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/freelancer/details/:id",
        component: FreelancerDetailsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/calendar",
        component: CalendarComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/help",
        component: HelpComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/kb",
        component: KbComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/support",
        component: SupportComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/notifications",
        component: NotificationsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/email-log",
        component: ProjectEmailLogComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/tools",
        component: ToolsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/tools/:id",
        component: ToolsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/sms",
        component: SmsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/report",
        component: ReportComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/closed-blacklisted",
        component: BlacklistedComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/sms/detail/:id",
        component: SmsComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/whatsapp",
        component: WhatsappComponent,
        canActivate: [PmsGuard],
    },
    {
        path: "pms/whatsapp/detail/:id",
        component: WhatsappComponent,
        canActivate: [PmsGuard],
    },
];

@NgModule({
    declarations: [
        PmDashboardComponent,
        EscapeHtmlPipe,
        ProjectDetailsComponent,
        CreateQuoteComponent,
        ProjectListComponent,
        FileManagementComponent,
        ProjectAssignmentsComponent,
        AddFileManagementComponent,
        ProjectReleaseComponent,
        QuotesComponent,
        FreelancerComponent,
        FreelancerDetailsComponent,
        ProjectEditingComponent,
        ProjectProofreadingComponent,
        ProjectQaComponent,
        ProjectDeliveryComponent,
        ProjectDtpComponent,
        AdditionalServicesComponent,
        ProjectHistoryComponent,
        CalendarComponent,
        ProjectFinancialsComponent,
        CalendarEventFormDialogComponent,
        CompletedWorkflowDetailComponent,
        AdditionalServicesMailingOptionsComponent,
        AdditionalServicesNotarizationComponent,
        AdditionalServicesApostileComponent,
        StatusChangeAdditionalServiceComponent,
        AdditionalInformationMailingComponent,
        AdditionalInformationNotarizationComponent,
        AdditionalInformationApostileComponent,
        AddLeadComponent,
        HelpComponent,
        KbComponent,
        SupportComponent,
        NotificationsComponent,
        EmailPreviewComponent,
        SpecialityChangeComponent,
        DeliveryEmailTemplateComponent,
        AddAdditionalServicesComponent,
        ProjectEmailLogComponent,
        EmailLogPreviewComponent,
        ConfirmationDialogComponent,
        LanguageChangeComponent,
        DeadlineChangeComponent,
        ToolsComponent,
        SmsComponent,
        WhatsappComponent,
        UpdateServiceTypeComponent,
        WhatsappUnreadmessagesComponent,
        FilterPipe,
        SmsUnreadmessageComponent,
        SmsConversationComponent,
        SendMessageComponent,
        SendWhatsappMessageComponent,
        WhatsappAttachmentComponent,
        CRMEmailLogComponent,
        CrmEmailLogPreviewComponent,
        EmailTemplateViewComponent,
        AddTempTranslatorComponent,
        BulkEmailComponent,
        ReportComponent,
        BlacklistedComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatPaginatorModule,
        TranslateModule.forRoot(),
        CKEditorModule,
        MatAutocompleteModule,
        RatingModule,
        // Material moment date module
        MatMomentDateModule,
        NgxMaskModule.forRoot(),
        // Material
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDatepickerModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        MatTableModule, // App modules
        LayoutModule,
        MatProgressBarModule,
        MatRadioModule,
        MatSliderModule,
        RouterModule.forChild(routes),
        MatBadgeModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        ChartsModule,
        MatTabsModule,
        CdkStepperModule,
        MatStepperModule,
        MatSelectModule,
        MatExpansionModule,
        DragDropModule,
        MatMenuModule,
        MatChipsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        NgMultiSelectDropDownModule.forRoot(),
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        ColorPickerModule,

        FuseConfirmDialogModule,
        PerfectScrollbarModule,
    ],

    entryComponents: [
        CalendarEventFormDialogComponent,
        StatusChangeAdditionalServiceComponent,
        EmailPreviewComponent,
        SpecialityChangeComponent,
        DeliveryEmailTemplateComponent,
        EmailLogPreviewComponent,
        ConfirmationDialogComponent,
        LanguageChangeComponent,
        DeadlineChangeComponent,
        UpdateServiceTypeComponent,
        EmailTemplateViewComponent,
        AddTempTranslatorComponent,
        BulkEmailComponent,
        SendMessageComponent,
        SendWhatsappMessageComponent,
        CrmEmailLogPreviewComponent,
    ],
    providers: [
        DatePipe,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
})
export class PmsModule {}
