import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'app/main/shared.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
     
      if (SharedService.get(SharedService.LOGIN) === null) {
        return true;
      } else if (SharedService.get(SharedService.LOGIN) == 'true' ) {
      return true;
    }
   
  }
}
