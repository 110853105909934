<div class="page-layout pmsDashBoard blank p-24 bg-white" fusePerfectScrollbar>

    <div class="container" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="15px">
  
  
      <div class="item item-1" fxFlex="70%">
  
        <div class="container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="15px">
  
          <div class="item item-1" fxFlex>
  
            <div class="white-box">
              <div class="tHeader" fxLayout>
                <h2 fxFlex>
                  <mat-icon class="ml-24">file_copy</mat-icon> Translations Project                </h2>
                <div class="text-right" fxFlex>
                    

                </div>
  
              </div>
  
              <div class="boxBody">
  
                  <canvas style="width: 100%; height: 200px;" baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                  [options]="pieChartOptions" (chartHover)="chartHovered($event)"
                  (chartClick)="chartClicked($event)"></canvas>
                  
                  <p class="text-center"><b>Today: </b> December, 28 2018 
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1" checked="true">Today</mat-radio-button>
                        <mat-radio-button value="2">Weekly</mat-radio-button>
                        <mat-radio-button value="3">Monthly</mat-radio-button>
                        <mat-radio-button value="4">Custom</mat-radio-button>
                      </mat-radio-group>
                  </p>
                  <div class="boxFooter actWraper">
                     
                  <div class="projStat" fxLayout="row" fxLayoutAlign="center center">

                   

  
                    <div fxFlex>
                      <h3>12</h3>
                      <p>

                        Total Projects
                        </p>
        
                    </div>
        
                    <div fxFlex>
                      <h3>

                        $1641
                        </h3>
                      <p>

                        Total Opp Amount
                        </p>
        
                    </div>
        
                    <div fxFlex>
                      <h3>

                        $673
                        </h3>
                      <p>

                        Total Cost
                        </p>
                    </div>

                    <div fxFlex>
                      <h3> $820              </h3>
                      <p>

                        

Total Profit

                        </p>
                    </div>

                    <div fxFlex>
                      <h3>

                        56.63%
                        </h3>
                      <p>

                       

Total Profit %

                        </p>
                    </div>
        
                  </div>
        
              
                   
  
                </div>
              </div>
            </div>
  
          </div>
  
        </div>
  
  
        <div class="container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="15px">
  
          <div class="item item-1" fxFlex>
  
            <div class="white-box">
              <div class="tHeader" fxLayout>
                <h2 fxFlex>
                  <mat-icon class="ml-24">file_copy</mat-icon> <span matBadge="23" matBadgeOverlap="false"
                    matBadgeColor="accent"> 
                    Project ( Less then 65% - 74.99% )
                     </span>
                </h2>
                
  
              </div>
  
              <div class="boxBody">
  
                <table class="table">
                  <tbody>
                    <tr>
                      <th>Reference</th>
                      <th>Opp Amount</th>
                      <th>Profit</th>
                    </tr>
  
                  </tbody>
  
                  <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                    <td>Khalil- 12201821832</td>
                    <td>451.20</td> <td>282.00 </td>
                  </tr>
                  <tr>
                      <td>Khalil- 12201821832</td>
                    <td>451.20</td> <td>282.00 </td>
                  </tr>
                  <tr>
                      <td>Khalil- 12201821832</td>
                    <td>451.20</td> <td>282.00 </td>
                  </tr>
                  <tr>
                      <td>Khalil- 12201821832</td>
                    <td>451.20</td> <td>282.00 </td>
                  </tr>
                  <tr>
                      <td>Khalil- 12201821832</td>
                    <td>451.20</td> <td>282.00 </td>
                  </tr>
                </table>
                <div class="boxFooter" fxLayout>
  
                  <div class="cnt" fxFlex>
                    <span>Showing <b>5/32</b></span>
                  </div>
  
                  <div class="arWrp text-right" fxFlex>
                    <button mat-button color="primary" class="btn-link">View All</button>
                  </div>
  
  
  
                </div>
              </div>
            </div>
  
          </div>
  
          <div class="item item-2" fxFlex>
  
            <div class="white-box yelowBox">
              <div class="tHeader" fxLayout>
                <h2 fxFlex="80%">
                  <mat-icon class="ml-24">file_copy</mat-icon> <span matBadge="92" matBadgeOverlap="false"
                    matBadgeColor="info">

                    Latest Project ( Less then 50% - 64.99%
                     </span>
                </h2>
                
  
              </div>
  
              <div class="boxBody">
                
                  <p class="text-center">
                      <b>Today: </b> December, 28 2018
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="2">Weekly</mat-radio-button>
                        <mat-radio-button value="3">Monthly</mat-radio-button>
                        
                      </mat-radio-group>
                  </p>
                <table class="table">
                    <tbody>
                      <tr>
                        <th>Reference</th>
                        <th>Opp Amount</th>
                        <th>Profit</th>
                      </tr>
    
                    </tbody>
    
                    <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                      <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                  </table>
                <div class="boxFooter" fxLayout>
  
                  <div class="cnt" fxFlex>
                    <span>Showing <b>5/32</b></span>
                  </div>
  
                  <div class="arWrp text-right" fxFlex>
                    <button mat-button color="primary" class="btn-link">View All</button>
                  </div>
  
  
  
                </div>
              </div>
            </div>
  
          </div>
  
  
  
  
        </div>
  
        <div class="container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="15px">
  
          <div class="item item-1" fxFlex>
  
            <div class="white-box redBox">
              <div class="tHeader" fxLayout>
                <h2 fxFlex>
                  <mat-icon class="ml-24">file_copy</mat-icon> <span matBadge="23" matBadgeOverlap="false"
                    matBadgeColor="accent">
                    

Latest Project ( Less then 50% - 64.99%)

                  </span>
                </h2>
                
              </div>
  
              <div class="boxBody">
  
                <table class="table">
                    <tbody>
                      <tr>
                        <th>Reference</th>
                        <th>Opp Amount</th>
                        <th>Profit</th>
                      </tr>
    
                    </tbody>
    
                    <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                      <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                  </table>
                <div class="boxFooter" fxLayout>
  
                  <div class="cnt" fxFlex>
                    <span>Showing <b>5/32</b></span>
                  </div>
  
                  <div class="arWrp text-right" fxFlex>
                    <button mat-button color="primary" class="btn-link">View All</button>
                  </div>
  
  
  
                </div>
              </div>
            </div>
  
          </div>
  
          <div class="item item-2" fxFlex>
  
            <div class="white-box grnBox">
              <div class="tHeader" fxLayout>
                <h2 fxFlex>
                  <mat-icon class="ml-24">file_copy</mat-icon> <span matBadge="92" matBadgeOverlap="false"
                    matBadgeColor="info">
  
                    

Latest Project ( Less then 100% and up)

                  </span>
                </h2>
                
  
              </div>
  
              <div class="boxBody">
  
                <table class="table">
                    <tbody>
                      <tr>
                        <th>Reference</th>
                        <th>Opp Amount</th>
                        <th>Profit</th>
                      </tr>
    
                    </tbody>
    
                    <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                      <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                  </table>
                <div class="boxFooter" fxLayout>
  
                  <div class="cnt" fxFlex>
                    <span>Showing <b>5/32</b></span>
                  </div>
  
                  <div class="arWrp text-right" fxFlex>
                    <button mat-button color="primary" class="btn-link">View All</button>
                  </div>
  
  
  
                </div>
              </div>
            </div>
  
          </div>
  
        </div>
  
        <div class="container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="15px">
  
          <div class="item item-1" fxFlex>
  
            <div class="white-box">
              <div class="tHeader" fxLayout>
                <h2>
                  <mat-icon class="ml-24">file_copy</mat-icon> <span matBadge="23" matBadgeOverlap="false"
                    matBadgeColor="accent">
                    Latest Project ( Less then 75% - 99.99%)
                  </span>
                </h2>
                
              </div>
  
              <div class="boxBody">
  
                <table class="table">
                    <tbody>
                      <tr>
                        <th>Reference</th>
                        <th>Opp Amount</th>
                        <th>Profit</th>
                      </tr>
    
                    </tbody>
    
                    <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                      <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                  </table>
                <div class="boxFooter" fxLayout>
  
                  <div class="cnt" fxFlex>
                    <span>Showing <b>5/32</b></span>
                  </div>
  
                  <div class="arWrp text-right" fxFlex>
                      <button mat-button color="primary" class="btn-link">View All</button>
                  </div>
  
  
  
                </div>
              </div>
            </div>
  
          </div>
  
          <div class="item item-2" fxFlex>
  
            <div class="white-box">
              <div class="tHeader" fxLayout>
                <h2 fxFlex="80%">
                  <mat-icon class="ml-24">file_copy</mat-icon>
                  Freelancer Invoices
  
                </h2>
                
              </div>
  
              <div class="boxBody">
  
                <table class="table">
                    <tbody>
                      <tr>
                        <th>Reference</th>
                        <th>Opp Amount</th>
                        <th>Profit</th>
                      </tr>
    
                    </tbody>
    
                    <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                      <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                    <tr>
                        <td>Khalil- 12201821832</td>
                      <td>451.20</td> <td>282.00 </td>
                    </tr>
                  </table>
                <div class="boxFooter" fxLayout>
  
                  <div class="cnt" fxFlex>
                    <span>Showing <b>5/32</b></span>
                  </div>
  
                  <div class="arWrp text-right" fxFlex>
                    <button mat-button color="primary" class="btn-link">View All</button>
                  </div>
  
  
  
                </div>
              </div>
            </div>
  
          </div>
  
        </div>
  
  
        
  
        
      </div>
  
  
      <div class="item item-2" fxFlex="30%">
  
        <div class="white-box">
          <div class="tHeader">
        <h2>
            <mat-icon class="ml-24">library_books</mat-icon> <span matBadge="23" matBadgeOverlap="false"
            matBadgeColor="accent">Recent Invoices from Freelancer </span></h2>
  
          </div>
  
          <div class="boxBody">
  
              <table class="table">
                <tbody>
                  <tr>
                    <th>inv#</th>
                    <th>Project</th>
                    <th>Inv Amount/Date</th>
                  </tr>

                </tbody>

                <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                  <td>DAY001</td>
                  <td>Patel-06201929671</td> <td><b>$24.00 USD</b> <span class="tLapse"> (June 31,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY002</td>
                  <td>rtel-06201929671</td> <td><b>$42.80 USD</b> <span class="tLapse"> (May 1,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY003</td>
                  <td>Patel-06201929671</td> <td><b>$32.12 USD</b> <span class="tLapse"> (Feb 3,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY004</td>
                  <td>Patel-06201929671</td> <td><b>$18.48 USD</b><span class="tLapse"> (June 1,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY005</td>
                  <td>Patel-06201929671</td> <td><b>$78.00 USD</b><span class="tLapse"> (June 22,19)</span> </td>
                </tr>
                <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                  <td>DAY001</td>
                  <td>Patel-06201929671</td> <td><b>$24.00 USD</b> <span class="tLapse"> (June 31,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY002</td>
                  <td>rtel-06201929671</td> <td><b>$42.80 USD</b> <span class="tLapse"> (May 1,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY003</td>
                  <td>Patel-06201929671</td> <td><b>$32.12 USD</b> <span class="tLapse"> (Feb 3,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY004</td>
                  <td>Patel-06201929671</td> <td><b>$18.48 USD</b><span class="tLapse"> (June 1,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY005</td>
                  <td>Patel-06201929671</td> <td><b>$78.00 USD</b><span class="tLapse"> (June 22,19)</span> </td>
                </tr>

                <tr title="Click here to View Details" [routerLink]="'/pms/project/details'">
                  <td>DAY001</td>
                  <td>Patel-06201929671</td> <td><b>$24.00 USD</b> <span class="tLapse"> (June 31,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY002</td>
                  <td>rtel-06201929671</td> <td><b>$42.80 USD</b> <span class="tLapse"> (May 1,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY003</td>
                  <td>Patel-06201929671</td> <td><b>$32.12 USD</b> <span class="tLapse"> (Feb 3,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY004</td>
                  <td>Patel-06201929671</td> <td><b>$18.48 USD</b><span class="tLapse"> (June 1,19)</span> </td>
                </tr>
                <tr>
                  <td>DAY005</td>
                  <td>Patel-06201929671</td> <td><b>$78.00 USD</b><span class="tLapse"> (June 22,19)</span> </td>
                </tr>
              </table>
              <div class="boxFooter" fxLayout>

                <div class="cnt" fxFlex>
                  <span>Showing <b>5/32</b></span>
                </div>

                <div class="arWrp text-right" fxFlex>
                  <button mat-button color="primary" class="btn-link">View All</button>
                </div>



              </div>
            </div>
        </div>
  
      </div>
  
    </div>