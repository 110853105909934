import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
var ProjectHistoryComponent = /** @class */ (function () {
    function ProjectHistoryComponent(route, projectsService, toastr) {
        this.route = route;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.activity_response = [];
        this.activity_count = 0;
    }
    ProjectHistoryComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getProjectActivity();
    };
    ProjectHistoryComponent.prototype.getProjectActivity = function () {
        var _this = this;
        this.projectsService.projectActivity(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.activity_response = [];
                if (response.project_activity.length > 0) {
                    _this.activity_response = response.project_activity;
                }
            }
        });
    };
    return ProjectHistoryComponent;
}());
export { ProjectHistoryComponent };
