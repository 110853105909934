import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatColors } from '@fuse/mat-colors';
import { CalendarEventModel } from 'app/main/pms/calendar/event.model';
var CalendarEventFormDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CalendarEventFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    function CalendarEventFormDialogComponent(matDialogRef, _data, _formBuilder) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._formBuilder = _formBuilder;
        this.presetColors = MatColors.presets;
        this.event = _data.event;
        this.action = _data.action;
        if (this.action === 'edit') {
            this.dialogTitle = this.event.title;
        }
        else {
            this.dialogTitle = 'New Event';
            this.event = new CalendarEventModel({
                start: _data.date,
                end: _data.date
            });
        }
        this.eventForm = this.createEventForm();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create the event form
     *
     * @returns {FormGroup}
     */
    CalendarEventFormDialogComponent.prototype.createEventForm = function () {
        return new FormGroup({
            title: new FormControl(this.event.title),
            start: new FormControl(this.event.start),
            end: new FormControl(this.event.end),
            allDay: new FormControl(this.event.allDay),
            color: this._formBuilder.group({
                primary: new FormControl(this.event.color.primary),
                secondary: new FormControl(this.event.color.secondary)
            }),
            meta: this._formBuilder.group({
                location: new FormControl(this.event.meta.location),
                notes: new FormControl(this.event.meta.notes)
            })
        });
    };
    return CalendarEventFormDialogComponent;
}());
export { CalendarEventFormDialogComponent };
