import { Routes } from '@angular/router';
import 'hammerjs';
import { ComingSoonComponent } from './main/coming-soon/coming-soon.component';
import { AdminGuard } from './_guards/admin.guard';
import { HrGuard } from './_guards/hr.guard';
import { SocketIoConfig } from 'ngx-socket-io';
import { MarketingGuard } from './_guards/marketing.guard';
var config = { url: 'http://localhost:3000', options: {} };
var appRoutes = [
    {
        path: "",
        redirectTo: "auth/login",
        pathMatch: "full"
    },
    {
        path: 'coming-soon',
        component: ComingSoonComponent
    },
    {
        path: "admin",
        loadChildren: "./main/admin/admin.module#AdminModule",
        canActivate: [AdminGuard]
    },
    {
        path: "hr",
        loadChildren: "./main/hr/hr.module#HrModule",
        canActivate: [HrGuard]
    },
    {
        path: "marketing",
        loadChildren: "./main/marketing/marketing.module#MarketingModule",
        canActivate: [MarketingGuard]
    },
    {
        path: '',
        loadChildren: './main/pages/pages.module#PagesModule'
    },
    {
        path: '**',
        redirectTo: 'auth/login'
    }
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
