import { OnInit } from '@angular/core';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
var ImportedTranslatorsComponent = /** @class */ (function () {
    function ImportedTranslatorsComponent(vendorService, toastr, router) {
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.router = router;
        this.StageID = 10;
        this.paginate1 = {};
        this.pageIndex = 1;
        this.pageSize = 10;
        this.query = '';
        this.ids_progress = [];
        this.send_email_progress_data = {};
        this.bulk_selected_progress = false;
        this.login_enable = true;
        this.languageResponse = [];
        this.specialityResponse = [];
        this.fQuery = '';
        this.filterSpecialization = '';
        this.filtercategoryLevel = '';
        this.vendorSkill = '';
        this.filter_src_lang = '';
        this.filter_target_lang = '';
        this.filter_native_lang = null;
        this.filter_skill = '';
        this.filter_search = '';
        this.fdata = {
            search: '',
            skill: '',
            category: '',
            Source: '',
            Target: '',
            Native: '',
        };
        this.displayedColumns = [
            'ref',
            'first_name',
            'skill',
            'category_level',
            'native_language',
            'language',
            'email',
            'stage',
        ];
    }
    ImportedTranslatorsComponent.prototype.ngOnInit = function () {
        this.getLanguage();
        this.getSpeciality();
        this.getData();
    };
    ImportedTranslatorsComponent.prototype.getData = function () {
        var _this = this;
        var query = '';
        var imported_category = localStorage.getItem('imported_category');
        imported_category = JSON.parse(imported_category);
        if (imported_category !== '' || imported_category !== null) {
            this.fdata.category = imported_category;
        }
        var imported_source_lang = localStorage.getItem('imported_source_lang');
        var source_langFilter = JSON.parse(imported_source_lang);
        if (source_langFilter !== '' || source_langFilter !== null) {
            this.fdata.Source = source_langFilter;
        }
        var imported_target_lang = localStorage.getItem('imported_target_lang');
        var target_langFilter = JSON.parse(imported_target_lang);
        if (target_langFilter !== '' || target_langFilter !== null) {
            this.fdata.Target = target_langFilter;
        }
        var imported_native_filter = localStorage.getItem('imported_native_filter');
        var nativeFilter = JSON.parse(imported_native_filter);
        if (nativeFilter !== '' || nativeFilter !== null) {
            this.fdata.Native = nativeFilter;
        }
        var importedFilter = localStorage.getItem('importedFilter');
        var filterObject = JSON.parse(importedFilter);
        var pagination = localStorage.getItem('importedPagination');
        var Object = JSON.parse(pagination);
        if (this.StageID == 10) {
            if (importedFilter != null && filterObject) {
                query = filterObject;
            }
            else if (pagination !== null &&
                Object.table_name == 'translatorInImported') {
                query = '?page=' + Object.page + '&per_page=' + Object.per_page + '&order_by=desc';
            }
            else {
                query = '?page=1&per_page=10&order_by=desc';
            }
        }
        this.vendorService.getHrDashboardListing(query, this.StageID).subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ', ';
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    if (elem.vendortranslationlangs.data.length > 0) {
                        _this.vendorSkill = 'Translator';
                        var lang_1 = '';
                        var lang_array_1 = [];
                        var translation_rate_value1_1 = '';
                        elem.vendortranslationlangs.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_1 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_1 += res.translation_rate;
                            }
                            lang_1 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_1.push(lang_1);
                        });
                        elem.check = false;
                        if (lang_array_1.length > 0) {
                            var test = void 0;
                            test = lang_array_1[lang_array_1.length - 1];
                            lang_array_1[lang_array_1.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_1;
                        response.data.lang_array = lang_array_1;
                    }
                    else if (elem.vendorSubtitler.data.length > 0) {
                        _this.vendorSkill = 'AVT';
                        var lang_2 = '';
                        var lang_array_2 = [];
                        var translation_rate_value1_2 = '';
                        elem.vendorSubtitler.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_2 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_2 += res.translation_rate;
                            }
                            lang_2 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_2.push(lang_2);
                        });
                        elem.check = false;
                        if (lang_array_2.length > 0) {
                            var test = void 0;
                            test = lang_array_2[lang_array_2.length - 1];
                            lang_array_2[lang_array_2.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_2;
                        response.data.lang_array = lang_array_2;
                    }
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    ImportedTranslatorsComponent.prototype.paginateData = function (event) {
        var _this = this;
        var aQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize + '&order_by=desc';
        var pagination = {
            table_name: 'translatorInImported',
            page: this.pageIndex,
            per_page: this.pageSize,
            tabIndex: 0,
        };
        SharedService.set(SharedService.importedPagination, JSON.stringify(pagination));
        if (this.filtercategoryLevel !== '') {
            aQuery += '&category_level=' + this.filtercategoryLevel;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (this.fdata.category) {
            aQuery += '&category_level=' + this.fdata.category;
        }
        if (this.filter_src_lang !== '') {
            aQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source) {
            aQuery += '&source_lang=' + this.fdata.Source;
        }
        if (this.filter_target_lang !== '') {
            aQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target) {
            aQuery += '&target_lang=' + this.fdata.Target;
        }
        if (this.filter_native_lang !== null) {
            aQuery += '&native_lang=' + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.fdata.Native) {
            aQuery += '&native_lang=' + this.fdata.Native;
        }
        this.query += aQuery;
        SharedService.set(SharedService.importedFilter, JSON.stringify(this.fQuery));
        this.vendorService
            .getHrDashboardListing(this.query, this.StageID)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ', ';
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    if (elem.vendortranslationlangs.data.length > 0) {
                        _this.vendorSkill = 'Translator';
                        var lang_3 = '';
                        var lang_array_3 = [];
                        var translation_rate_value1_3 = '';
                        elem.vendortranslationlangs.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_3 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_3 += res.translation_rate;
                            }
                            lang_3 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_3.push(lang_3);
                        });
                        elem.check = false;
                        if (lang_array_3.length > 0) {
                            var test = void 0;
                            test = lang_array_3[lang_array_3.length - 1];
                            lang_array_3[lang_array_3.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_3;
                        response.data.lang_array = lang_array_3;
                    }
                    else if (elem.vendorSubtitler.data.length > 0) {
                        _this.vendorSkill = 'AVT';
                        var lang_4 = '';
                        var lang_array_4 = [];
                        var translation_rate_value1_4 = '';
                        elem.vendorSubtitler.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_4 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_4 += res.translation_rate;
                            }
                            lang_4 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_4.push(lang_4);
                        });
                        elem.check = false;
                        if (lang_array_4.length > 0) {
                            var test = void 0;
                            test = lang_array_4[lang_array_4.length - 1];
                            lang_array_4[lang_array_4.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_4;
                        response.data.lang_array = lang_array_4;
                    }
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    ImportedTranslatorsComponent.prototype.editData = function (value) {
        this.router.navigate(['/hr/translator/' + value.id]);
    };
    ImportedTranslatorsComponent.prototype.selectMailProgress = function (evt, val) {
        if (evt.checked && this.ids_progress.indexOf(val.user_id == -1)) {
            this.ids_progress.push(val.user_id);
        }
        else if (!evt.checked &&
            this.ids_progress.indexOf(val.user_id != -1)) {
            var index = this.ids_progress.indexOf(val.user_id);
            this.ids_progress.splice(index, 1);
        }
        if (this.ids_progress.length == 0) {
            this.bulk_selected_progress = false;
        }
        this.send_email_progress_data.ids = this.ids_progress;
    };
    ImportedTranslatorsComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        var check = false;
        this.dataSource1.data.forEach(function (element, key) {
            element['check'] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.login_enable = false;
                _this.ids_progress.push(element.user_id);
            }
            else {
                _this.ids_progress = [];
                _this.login_enable = true;
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
        this.send_email_progress_data.ids = this.ids_progress;
    };
    // get filters
    ImportedTranslatorsComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality().subscribe(function (res) {
            if (res) {
                res.forEach(function (element) {
                    element.name =
                        element.name.charAt(0).toUpperCase() +
                            element.name.slice(1);
                });
                _this.specialityResponse = res;
            }
        });
    };
    ImportedTranslatorsComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages().subscribe(function (res) {
            if (res) {
                _this.languageResponse = res.data;
            }
        });
    };
    // On filters
    ImportedTranslatorsComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10&order_by=desc';
        if (type === 'categoryLevel' && evt.value !== '' && evt.value !== undefined) {
            this.filtercategoryLevel = evt.value;
            this.fQuery += '&category_level=' + this.filtercategoryLevel;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (type === 'categoryLevel' && evt.value === '') {
            this.filtercategoryLevel = evt.value;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (this.filtercategoryLevel !== '') {
            this.fQuery += '&category_level=' + this.filtercategoryLevel;
            var categoryLevel = this.filtercategoryLevel;
            SharedService.set(SharedService.imported_category, JSON.stringify(categoryLevel));
        }
        else if (this.fdata.category) {
            this.fQuery += '&category_level=' + this.fdata.category;
        }
        //debugger;
        if (type === 'skill' && evt.value !== '' && evt.value !== undefined) {
            this.filter_skill = evt.value;
            this.fQuery += '&skill=' + this.filter_skill;
            var vednor_skill = this.filter_skill;
            SharedService.set(SharedService.vednor_skill, JSON.stringify(vednor_skill));
        }
        else if (type === 'skill' && evt.value === '') {
            this.filter_skill = evt.value;
            var vednor_skill = this.filter_skill;
            SharedService.set(SharedService.vednor_skill, JSON.stringify(vednor_skill));
        }
        else if (this.filter_skill !== '') {
            this.fQuery += '&skill=' + this.filter_skill;
            var vednor_skill = this.filter_skill;
            SharedService.set(SharedService.vednor_skill, JSON.stringify(vednor_skill));
        }
        else if (this.fdata.skill) {
            this.fQuery += '&skill=' + this.fdata.skill;
        }
        if (type === 'search_text' && evt !== '' && evt !== undefined) {
            this.filter_search = evt;
            this.fQuery += '&search_text=' + this.filter_search;
            var vednor_search = this.filter_search;
            // SharedService.set(
            //     SharedService.vednor_search,
            //     JSON.stringify(vednor_search)
            // );
        }
        else if (type === 'search_text' && evt === '') {
            this.filter_search = evt;
            var vednor_search = this.filter_search;
            // SharedService.set(
            //     SharedService.vednor_search,
            //     JSON.stringify(vednor_search)
            // );
        }
        else if (this.filter_search !== '') {
            this.fQuery += '&search_text=' + this.filter_search;
            var vednor_search = this.filter_search;
            // SharedService.set(
            //     SharedService.vednor_search,
            //     JSON.stringify(vednor_search)
            // );
        }
        else if (this.fdata.search) {
            this.fQuery += '&search_text=' + this.fdata.search;
        }
        if (type === 's_lang' && evt.value !== '' && evt.value !== undefined) {
            this.filter_src_lang = evt.value;
            this.fQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (type === 's_lang' && evt.value === '') {
            this.filter_src_lang = evt.value;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.filter_src_lang !== '') {
            this.fQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.imported_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source) {
            this.fQuery += '&source_lang=' + this.fdata.Source;
        }
        if (type === 't_lang' && evt.value !== '' && evt.value !== undefined) {
            this.filter_target_lang = evt.value;
            this.fQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (type === 't_lang' && evt.value === '') {
            this.filter_target_lang = evt.value;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.filter_target_lang !== '') {
            this.fQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.imported_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target) {
            this.fQuery += '&target_lang=' + this.fdata.Target;
        }
        if (type === 'native_lang' &&
            evt.value != null &&
            evt.value !== undefined) {
            this.filter_native_lang = evt.value;
            this.fQuery += '&native_lang=' + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (type == 'native_lang' && evt.value == null) {
            this.filter_native_lang = evt.value;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.filter_native_lang != null) {
            this.fQuery += '&native_lang=' + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.imported_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.fdata.Native) {
            this.fQuery += '&native_lang=' + this.fdata.Native;
        }
        SharedService.set(SharedService.importedFilter, JSON.stringify(this.fQuery));
        var query = this.fQuery;
        this.vendorService.getHrDashboardListing(query, this.StageID).subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ', ';
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    if (elem.vendortranslationlangs.data.length > 0) {
                        _this.vendorSkill = 'Translator';
                        var lang_5 = '';
                        var lang_array_5 = [];
                        var translation_rate_value1_5 = '';
                        elem.vendortranslationlangs.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_5 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_5 += res.translation_rate;
                            }
                            lang_5 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_5.push(lang_5);
                        });
                        elem.check = false;
                        if (lang_array_5.length > 0) {
                            var test = void 0;
                            test = lang_array_5[lang_array_5.length - 1];
                            lang_array_5[lang_array_5.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_5;
                        response.data.lang_array = lang_array_5;
                    }
                    else if (elem.vendorSubtitler.data.length > 0) {
                        _this.vendorSkill = 'AVT';
                        var lang_6 = '';
                        var lang_array_6 = [];
                        var translation_rate_value1_6 = '';
                        elem.vendorSubtitler.data.forEach(function (res) {
                            if (res.translation_rate != null) {
                                translation_rate_value1_6 += res.translation_rate;
                            }
                            if (res.bi_direction == 2) {
                                res.bi_direction = '<>';
                            }
                            else {
                                res.bi_direction = '>';
                            }
                            if (res.translation_rate != null) {
                                translation_rate_value1_6 += res.translation_rate;
                            }
                            lang_6 =
                                res.sourcelanguage.data.lang_name +
                                    res.bi_direction +
                                    res.targetlanguage.data.lang_name +
                                    ',';
                            lang_array_6.push(lang_6);
                        });
                        elem.check = false;
                        if (lang_array_6.length > 0) {
                            var test = void 0;
                            test = lang_array_6[lang_array_6.length - 1];
                            lang_array_6[lang_array_6.length - 1] = test.slice(0, -1);
                        }
                        elem.lang_array = lang_array_6;
                        response.data.lang_array = lang_array_6;
                    }
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    ImportedTranslatorsComponent.prototype.Send_mail_single = function (val) {
        var _this = this;
        var progress_single = [];
        var send_email_single_data = {};
        progress_single.push(val.user_id);
        send_email_single_data.ids = progress_single;
        send_email_single_data.resend = true;
        this.vendorService.sendProgressMail(send_email_single_data).subscribe(function (response) {
            if (response) {
                send_email_single_data = {};
                progress_single = [];
                _this.toastr.success('Please check email');
                _this.getData();
                window.location.href = '/hr/hr-dashboard';
            }
        });
    };
    ImportedTranslatorsComponent.prototype.applyFilterProgress = function (filterValue) {
        this.dataSource1.filter = filterValue.trim().toLowerCase();
    };
    ImportedTranslatorsComponent.prototype.Send_mail = function () {
        var _this = this;
        if (Object.keys(this.send_email_progress_data).length === 0) {
            this.toastr.error('Please first select any translator');
            return;
        }
        this.vendorService
            .sendProgressMail(this.send_email_progress_data)
            .subscribe(function (response) {
            if (response) {
                _this.send_email_progress_data = {};
                _this.bulk_selected_progress = false;
                _this.ids_progress = [];
                _this.dataSource1.data.forEach(function (element) {
                    element.check = false;
                });
                _this.toastr.success('Please check emails');
                _this.getData();
            }
        });
    };
    return ImportedTranslatorsComponent;
}());
export { ImportedTranslatorsComponent };
