import { Component, OnInit } from '@angular/core';
import { ChatService } from 'app/main/Services/chat.service';

@Component({
  selector: 'app-kb',
  templateUrl: './kb.component.html',
  styleUrls: ['./kb.component.scss']
})
export class KbComponent implements OnInit {
  newMessage: string;
  messageList:  string[] = [];
  constructor(private chatService: ChatService) { }

  sendMessage() {
    this.chatService.sendMessage(this.newMessage);
    this.newMessage = '';
  }
  ngOnInit() {
    this.chatService
      .getMessages()
      .subscribe((message: string) => {
        this.messageList.push(message);
      });
  }
}
