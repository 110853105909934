import { Component, OnInit, inject } from '@angular/core';
import { SharedService } from 'app/main/shared.service';
import { UserService } from './../../Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatSort,MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material';
@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  dataSource:any;
  sort: MatSort;
  paginate:any = {};
  paginator: MatPaginator;
  pageSize:number = 20;
  pageIndex:number = 1;
  filterStatus: string = '';
  fQuery: string = '';
  filterIssue:string='';
  filterDepartment:string='';
  totalticket:number=0;
  query_quote:string ='';
  displayedColumns: string[] = ['id', 'subject', 'issue_type', 'portal_id','created_at','updated_at', 'status'];
  constructor(private userService:UserService,private toastr:ToastrService) { }

  ngOnInit() {
    this.getTicketDetails();
  }
  getTicketDetails()
  {
    let query='';
    this.userService.getallTickets(query)
    .subscribe((response) => {
        if(response) {
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.sort=this.sort;
        this.paginate = response.meta.pagination;
        this.totalticket=response.meta.pagination.total;
         }
       });
      }
      paginateData(event) {
        let pQuery = ''
        this.pageIndex = event.pageIndex + 1;
        if(this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
       this.query_quote = '?page=' + this.pageIndex + '&per_page='+this.pageSize
       this.query_quote += pQuery;
        this.userService.getallTickets(this.query_quote)
        .subscribe((response) => {
        if(response) 
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
          this.totalticket = response.meta.pagination.total;
         
      });
      }

      onFilter(evt, type )
      {
        this.fQuery = '?page=1&per_page=10'
        if ((type == 'department' && evt.value != '' && evt.value !== undefined)) {
            this.filterDepartment = evt.value;
            this.fQuery += '&department=' + this.filterDepartment;
        }
        else if(type == 'department' && evt.value == ''){
            this.filterDepartment = evt.value;
        }
        else if(this.filterDepartment!==''){
            this.fQuery += '&department=' + this.filterDepartment;
        }
        
        if ((type == 'issue_type' && evt.value != '' && evt.value !== undefined)) {
          this.filterIssue= evt.value;
    
          this.fQuery += '&issue_type=' + this.filterIssue;
      }
      else if(type == 'issue_type' && evt.value == ''){
          this.filterIssue = evt.value;
          
      }  else if(this.filterIssue!==''){
        this.fQuery += '&issue_type=' + this.filterIssue;
    }
    
      if ((type == 'status' && evt.value != '' && evt.value !== undefined)) {
        this.filterStatus= evt.value;
  
        this.fQuery += '&status=' + this.filterStatus;
    }
    else if(type == 'status' && evt.value == ''){
        this.filterStatus = evt.value;

    }
    else if(this.filterStatus!==''){
        this.fQuery += '&status=' + this.filterStatus;
    }
        let query = this.fQuery;
        this.userService.getallTickets(query)
        .subscribe((response) => {
            if(response) {
                this.dataSource = new MatTableDataSource(response.data);
                this.dataSource.sort = this.sort;
                this.paginate = response.meta.pagination;
                this.totalticket = response.meta.pagination.total;
            }
        });

      }
}
