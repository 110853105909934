import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { UserService } from 'app/main/Services/user.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
export interface addServData {
  position: number;
  addServTitle: string;
  addServDesc: string;
}
@Component({
  selector: 'app-workflow-master',
  templateUrl: './workflow-master.component.html',
  styleUrls: ['./workflow-master.component.scss']
})
export class WorkflowMasterComponent implements OnInit {
  workflowData: any = [];
  dataSource: any;
  paginate: any = {};
  pageIndex: number = 1;
  query: string = '';
  pageSize: number = 10;
  displayedColumns: string[] = ['id', 'name', 'description', 'action'];
  //dataSource = ELEMENT_DATA;
  constructor(private userService: UserService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getWorkflow();
  }

  getWorkflow() {
    let query = '?page=1&per_page=5';
    this.userService.getWorkflow(query)
      .subscribe((res: any) => {
        if (res) {
          this.workflowData = res.data;
          this.dataSource = new MatTableDataSource(this.workflowData);
          this.paginate = res.meta.pagination;
        }
      });
  }
  paginateData(event) {
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
    this.userService.getWorkflow(this.query)
      .subscribe((res: any) => {
        if (res) {
          this.workflowData = res.data;
          this.dataSource = new MatTableDataSource(this.workflowData);
          this.paginate = res.meta.pagination;
        }
      });
  }
}
