import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
@Component({
  selector: 'app-bulk-email',
  templateUrl: './bulk-email.component.html',
  styleUrls: ['./bulk-email.component.scss']
})
export class BulkEmailComponent implements OnInit {

  config: any;
  Form: FormGroup;
  emailTemplateData: any = [];
  freelancerArray: any = [];

  //constructor(private projectsService: ProjectsService,) { }
  constructor(private route: ActivatedRoute, private router: Router, private dialogRef: MatDialogRef<BulkEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder, private projectsService: ProjectsService, private toastr: ToastrService) { }

  ngOnInit() {

    this.emailTemplateData = this.data.templateData;
    this.freelancerArray = this.data.freelacner;
    this.config = {
      width: "900px",
      toolbar: [
        { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'] },
        { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
        { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
        { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
        '/',
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
        { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
        '/',
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
        { name: 'about', items: ['About'] }

      ]
    };
    this.Form = this._formBuilder.group({
      subject: ['', Validators.compose([Validators.required])],
      ckeditorContent: [''],
      cc: [''],
    });

    // this.Form.patchValue({
    //   ckeditorContent: this.data.templateData[0].body,
    //   subject: this.data.templateData[0].subject,
    // });


  }

  Onclose() {
    this.dialogRef.close();
  }
  onFilterSpeciality(evt) {
    this.data.templateData.forEach(element => {
      if(element.id == evt.value){
        this.Form.patchValue({
          ckeditorContent: element.body,
          subject: element.subject,
        });
      }
    });

  }

  submit() {
    if(  this.Form.value.subject==''){
      this.toastr.error("Subject never be empty!!");
      return;
    }

    if(  this.Form.value.ckeditorContent==''){
      this.toastr.error("Body never be empty!!");
      return;
    }
    this.data.body = this.Form.value.ckeditorContent;
    this.data.subject = this.Form.value.subject;
    this.data.cc = this.Form.value.cc;
    this.data.freelacner_id = this.freelancerArray;
    this.projectsService.sendVendorRushEmail(this.data)
      .subscribe((response) => {
        if (response) {
            this.toastr.success(response.message);
            this.Onclose();
        }
        else{
          this.toastr.error("Something went wrong");
        }
      });
  }
}
