
<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div fxFlex="100%">
      <div class="white-box">
        <div class="tHeader wMenu">

            <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
                <button color="accent" (click)="back()" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
                  <mat-icon>keyboard_backspace</mat-icon>
                </button>
            
                <span class="title-text h1">
                    Ticket History
                </span>
              </div>

           
          <!-- <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex>
                
              <h2>Ticket History</h2>
            </div>

          </div> -->

        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left"  >
              <div class="item item-1" fxFlex="10" >
                <b><span class="title" >Project ID</span></b>
              </div>
              <div class="item item-1" fxFlex="90">
               <span >{{detail?.second_id}}
                    </span>
              </div>
            </div>
            
            <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
              
              <div class="item item-1" fxFlex="10" >
                  <b><span class="title" >Subject</span></b>
                </div>
                <div class="item item-1" fxFlex="90">
                 <span >{{detail?.subject}}
                      </span>
                </div>
              
            </div>
            <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
              <div class="item item-1" fxFlex="10" >
                  <b><span class="title" >Issue Type</span></b>
                </div>
                <div class="item item-1" fxFlex="90">
                 <span >{{detail?.issue_type}}
                      </span>
                </div>
              </div>
            <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
              <div class="item item-1" fxFlex="10" >
                  <b><span class="title" >Department</span></b>
                </div>
                <div class="item item-1" fxFlex="90">
                    <span *ngIf="detail?.portal_id==1">Admin</span>
                    <span *ngIf="detail?.portal_id==2">HR</span>
                    <span *ngIf="detail?.portal_id==3">PM</span>
                    <span *ngIf="detail?.portal_id==4">Management</span>
                </div>
             
              </div>

              <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
                  <div class="item item-1" fxFlex="10" >
                      <b><span class="title" >Status</span></b>
                    </div>
                    <div class="item item-1" fxFlex="90">
                        <span *ngIf="detail?.status==1">Pending</span>
                        <span *ngIf="detail?.status==2">In Progress</span>
                        <span *ngIf="detail?.status==3">Resolved</span>
                        <span *ngIf="detail?.status==4">Invalid</span>
                    </div>
                 
                  </div>
              <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
              <div class="item item-1" fxFlex="10" >
                  <b><span class="title" >Message</span></b>
                </div>
                <div class="item item-1" fxFlex="90">
                 <span  >{{detail?.message}}
                      </span>
                </div>
              </div>
              <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
                  <div class="item item-1" fxFlex="10" >
                      <b><span class="title" >Attachment</span></b>
                    </div>
                <div class="item item-1" fxFlex="90"  *ngIf="detail?.ticket_attachment?.data.length>0">
                    
                    <p *ngFor="let res of files">
                       
                            <a [href]="res.file_url" target="_blank" download > {{res.file_name}}  </a> 
                       
                    </p>
                </div>
              </div>
            
              <form name="Form" [formGroup]="Form" (ngSubmit)="Submit();" >
              <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
             
                           <mat-form-field   fxFlex="20">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status" >
                                <mat-option [value]="''">Select</mat-option>
                                <mat-option  [value]="'1'">Pending</mat-option>
                                <mat-option  [value]="'2'">In Progress</mat-option>
                                <mat-option  [value]="'3'">Resolved</mat-option>
                                <mat-option  [value]="'4'">Invalid</mat-option>
                             </mat-select>
                             <mat-error *ngIf="Form.get('status').invalid && Form.get('status').touched">
                                <span *ngIf="Form.get('status').errors.required">Status is required</span>
                            </mat-error>
                          </mat-form-field>
                      
              </div>
              <div class="container mb-12" fxLayout fxLayout.xs="column" fxLayoutAlign="left" >
                                
              <mat-form-field   class="d-full-width">
                  <mat-label>Message</mat-label>
                  <textarea matInput placeholder="Leave a comment"
                      formControlName="support_message"></textarea>
                    <mat-error *ngIf="Form.get('support_message').invalid && Form.get('support_message').touched">
                        <span *ngIf="Form.get('support_message').errors.required">Message is required</span>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="actWraper">

                  <button type="submit"  [disabled]="Form.invalid" mat-flat-button color="accent" class="mr-16">Update Status</button>
          
                </div>
              </form>
              </div>
            </div>
          </div>
    </div>

</div>

