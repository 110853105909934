import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-translator-quiz',
  templateUrl: './translator-quiz.component.html',
  styleUrls: ['./translator-quiz.component.scss']
})
export class TranslatorQuizComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
