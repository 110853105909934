import { OnInit } from '@angular/core';
var ELEMENT_DATA = [
    { position: 1, servTitle: 'Translation Services', servDesc: 'Document Translation' },
    { position: 2, servTitle: 'OVer the phone interpretation', servDesc: 'Over the Phone Interpretation' },
];
var ServicesMasterComponent = /** @class */ (function () {
    function ServicesMasterComponent() {
        this.displayedColumns = ['position', 'servTitle', 'servDesc', 'action'];
        this.dataSource = ELEMENT_DATA;
    }
    ServicesMasterComponent.prototype.ngOnInit = function () {
    };
    return ServicesMasterComponent;
}());
export { ServicesMasterComponent };
