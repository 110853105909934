<div class="mopt p-20 " *ngIf="!apostile_show ">
  <p >Not Applied</p>
  </div>

  <div class="mopt p-20" *ngIf="apostile_show  && additonal_service_array.length>0">

    <p> <b> Apostile service required (translation only)</b></p>

    <p *ngIf="shipping_country"> Country name for which  Apostile service is required :<b> {{shipping_country}} </b></p>
    <p *ngIf="shipping_country==null"> Country name for which  Apostile service is required :<b> NA </b></p>

    <p *ngIf="apostile_state"> Apostile service from Illinois: <b>Yes </b></p>
    <p *ngIf="!apostile_state">  Apostile service from Illinois: <b> No</b></p>

    <!-- <p *ngIf="apostile_state"> State for which  Apostile service is required: <b>Yes </b></p>
    <p *ngIf="!apostile_state"> State for which  Apostile service is required: <b> No</b></p> -->

    <p *ngIf="shipping_state">  Apostile service outside Illinois : <b>{{shipping_state}}</b> </p>
    <p *ngIf="shipping_state==null">  Apostile service outside Illinois : <b>No</b> </p>

    <div fxLayoutAlign="space-between end" >
      <div></div>
      <div>
        <button mat-raised-button color="primary" (click)="Edit()"  class="mr-8">Edit</button>
      </div>
     </div>
    </div>