import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
@Component({
  selector: 'app-email-template-view',
  templateUrl: './email-template-view.component.html',
  styleUrls: ['./email-template-view.component.scss']
})
export class EmailTemplateViewComponent implements OnInit {

  config: any;
  project_id: string;
  Form: FormGroup;

  constructor(private route: ActivatedRoute, private router: Router, private dialogRef: MatDialogRef<EmailTemplateViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data :any, private _formBuilder: FormBuilder, private projectsService: ProjectsService, private toastr: ToastrService) { }

  ngOnInit() {
        this.project_id = this.data.project_id;
       this.config = {
       width: "800px",
       toolbar : [
        { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
        { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
        { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
        '/',
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
        { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
        '/',
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        { name: 'about', items: [ 'About' ] }
  
      ]
    };
    this.Form = this._formBuilder.group({ 
        subject: ['', Validators.compose([Validators.required])],
        ckeditorContent: [''],
    });
    this.Form.patchValue({
      ckeditorContent: this.data.emailTemp.body,
      subject: this.data.emailTemp.subject,
    });

  }
  
  Onclose(){
    this.dialogRef.close();
  }

  submit() {
    if(  this.Form.value.subject==''){
      this.toastr.error("Subject never be empty!!");
      return;
    }

    if(  this.Form.value.ckeditorContent==''){
      this.toastr.error("Body never be empty!!");
      return;
    }
    this.data.body = this.Form.value.ckeditorContent;
    this.data.subject = this.Form.value.subject;
    //console.log(this.project_id);
    this.projectsService.ProjectAssignment(this.data)
      .subscribe((response) => {
        if (response) {
        //  console.log(response);
        //   return false;
          // this.show_table = false;
          // this.vendor_ids = [];
          // this.data.target_lang_id = '';
          // this.show_search = false;
          // this.vendor = [];
          // this.Form = this._formBuilder.group({
          //   duration: ['30', Validators.compose([Validators.required])],
          //   duration_unit: ['1'],
          //   duration_comment: [''],
          //   email_template_id: ['', Validators.compose([Validators.required])],
          //   update_status_interval: ['240', Validators.compose([Validators.required])],
          //   update_status_note: [''],
          //   instruction: [''],
          //   vendor_id: [],
          //   due_date: ['', Validators.compose([Validators.required])],
          // });
          // this.toastr.success(response.message);
          // let top = document.getElementById('top');
          // if (top !== null) {
          //   top.scrollIntoView();
          //   top = null;
          // }
           window.location.href = '/pms/project/details/' + this.project_id;
        }
        else{
          this.toastr.error("Something went wrong");
        }
      });
  }

}
