<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" [routerLink]="'/pms/account'">
      <mat-icon>keyboard_backspace</mat-icon>
    </button><span class="title-text h1"><p>{{response?.name}}</p></span>
    <!-- <span class="title-text h1">
      <p *ngIf="response?.reference_id!=null"> {{response?.reference_id}}</p>
      <p *ngIf="response?.reference_id==null"> N/A</p>
      <span class="prfInfo">
        <b>Added:</b> 
        <span class="prfo"><b>Location:</b>
          <span> {{response?.city}} {{response?.country}} </span>
        </span>
      </span>
    </span> -->
  </div>
  <div class="white-box">
    <div class="tHeader">
      <div fxLayout="row wrap">
        <div fxFlex>
          <h2>Account Details </h2>
        </div>
        <div fxFlex class="text-right">
          <button mat-stroked-button (click)="openDialogAccount()">Edit</button>
          <!-- <button mat-stroked-button (click)="deleteCustomer()">Move to Trash</button> -->
        </div>
      </div>
    </div>
    <div class="boxBody">
      <div class="boxBodyInn custInfo">
        <div class="container" fxLayout fxLayoutAlign="left" fxLayoutGap="10px">
          <div class="item item-1" fxFlex>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Name: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b> {{response?.name}} </b></p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Email: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.email}}</b></p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Company: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.company}}</b></p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Office Phone: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b *ngIf="response?.office_phone">{{response?.office_phone}}</b>
              </div>
            </div>
          
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Billing Address: </p>
              </div>
              <div class="item item-1" fxFlex>
                <span *ngFor="let res of response?.accountaddress?.data">
                  <p *ngIf="res.address.data.type== 'Billing'">
                    <b>
                    {{res?.address?.data?.street}} {{res?.address?.data?.city}} 
                    {{res?.address?.data?.state}} {{res?.address?.data?.postal_code}} {{res?.address?.data?.country}}
                  </b>
                  </p>

                </span>
              </div>
            </div>
            
          </div>
          <div class="item item-2" fxFlex>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Account Type: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.account_type}}</b></p>
              </div>
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>SIC code: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p ><b>{{response?.sic_code}}</b></p>
              </div>
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Assigned to: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{response?.assigned_to}}</b></p>
              </div>
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
                <div class="text-right" fxFlex="30">
                  <p>Website: </p>
                </div>
                <div class="item item-1" fxFlex>
                  <p><b>{{response?.website}}</b></p>
                </div>
              </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
                <div class="text-right" fxFlex="30">
                  <p>Shipping Address: </p>
                </div>
                <div class="item item-1" fxFlex>
                  <span *ngFor="let res of response?.accountaddress?.data">
                    <p *ngIf="res.address.data.type== 'Shipping'">
                      <b>
                      {{res?.address?.data?.street}} {{res?.address?.data?.city}} 
                      {{res?.address?.data?.state}} {{res?.address?.data?.postal_code}} {{res?.address?.data?.country}}
                    </b>
                    </p>
  
                  </span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="white-box">
      <div class="tHeader">
        <div fxLayout="row wrap">
          <div fxFlex>
            <h2>Customers</h2>
          </div>
          <div fxFlex class="text-right">
            Total <b>{{total_customer}}</b> Records Available
          </div>
        </div>
      </div>
  
  
      <div class="boxBody">
        <div class="boxInn" >
                <table mat-table [dataSource]="dataSource_customer" >
                    <!-- Name Column -->
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="16"> ID</mat-header-cell>
                            <mat-cell *matCellDef="let product"  fxFlex="16">
                                <p class="text-truncate">{{product?.reference_id}}</p>
                            </mat-cell>
                      </ng-container>
  
                            <!-- Weight Column -->
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex="12"> Name</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxFlex="12">
                          <p class="text-truncate">
                            <a routerLink="/pms/customer/details/{{ product.uuid }}" >{{product.first_name}} {{product.last_name}}</a>
                            
                        </mat-cell>
                    </ng-container>
  
                      <!-- Weight Column -->
                      <ng-container matColumnDef="cust_email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex="20"> Email</mat-header-cell>
                          <mat-cell *matCellDef="let product"   fxFlex="20">
                            <p class="text-truncate">{{product.email_primary}}</p>
                              
                          </mat-cell>
                      </ng-container>
  
                      <!-- Weight Column -->
                      <ng-container matColumnDef="savedDate">
                          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8">Added</mat-header-cell>
                            <mat-cell *matCellDef="let product"   fxFlex="8">
                              <p class="text-truncate">{{product.created_at.date | date: 'MM/dd/yyyy'}}</p>
                              <!-- yyyy-MM-dd -->
                                
                            </mat-cell>
                        </ng-container>
  
                    
  
                          <!-- Weight Column -->
                    <ng-container matColumnDef="paymentMethod">
                      <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex="12"> Payment Method</mat-header-cell>
                        <mat-cell *matCellDef="let product"   fxFlex="12">
                            <p class="text-truncate" *ngIf="product?.payment_method==''"></p>
                            <p class="text-truncate" *ngIf="product?.payment_method==1">NET30</p>
                            <p class="text-truncate" *ngIf="product?.payment_method==2">NET45</p>
                            <p class="text-truncate" *ngIf="product?.payment_method==3">Regular/Upfront</p>
                            <p class="text-truncate" *ngIf="product?.payment_method==4">Credit Available</p>
                            
                        </mat-cell>
                    </ng-container>
  
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="status">
                              <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> Status</mat-header-cell>
                                <mat-cell *matCellDef="let product"   fxFlex="8">
                                
                                  <p class="text-truncate" *ngIf="product?.status==''"></p>
                                  <p class="text-truncate" *ngIf="product?.status==1">Active</p>
                                  <p class="text-truncate" *ngIf="product?.status==2">Inactive</p>
                                  <p class="text-truncate" *ngIf="product?.status==3">Potential</p>
  
                                </mat-cell>
                            </ng-container>
                    <!-- Symbol Column -->
  
                    <!-- <ng-container matColumnDef="addressCountry">
                      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12"> Address</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxFlex="12">
                          <p class="text-truncate">{{product?.customeraddress?.data?.address1}}</p>
                        </mat-cell>
                    </ng-container> -->
  
                                  <!-- Symbol Column -->
  
                                  <ng-container matColumnDef="categories">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12"> Categories</mat-header-cell>
                                      <mat-cell *matCellDef="let product"  fxFlex="12">
                                     
                                        <p class="text-truncate" *ngIf="product?.category==''"></p>
                                        <p class="text-truncate" *ngIf="product?.category==1">DayVIP</p>
                                        <p class="text-truncate" *ngIf="product?.category==2">Company</p>
                                        <p class="text-truncate" *ngIf="product?.category==3">Individual</p>
                                        <p class="text-truncate" *ngIf="product?.category==3">New Client</p>
        
                                
                                      </mat-cell>
                                  </ng-container>
  
                    
                      <!-- Symbol Column -->
  
                      <ng-container matColumnDef="leadSource">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> Lead Source</mat-header-cell>
                          <mat-cell *matCellDef="let product"  fxFlex="8">
                            <p class="text-truncate">{{product?.source?.data?.name}}</p>
                          </mat-cell>
                      </ng-container>
                    
  
                    
                      <mat-header-row *matHeaderRowDef="displayedColumns_customer; sticky:true"></mat-header-row>
              
                      <mat-row *matRowDef="let product; columns: displayedColumns_customer;" class="product fList">
                      </mat-row>
                  </table>
  
        </div>
       <div class="noDataFound" *ngIf="total_customer==0">
          No data available!!
        </div> 
      </div>
    </div>

  <div class="white-box">
    <div class="tHeader">
      <div fxLayout="row wrap">
        <div fxFlex>
          <h2>Projects</h2>
        </div>
        <div fxFlex class="text-right">
          Total <b>{{total_records}}</b> Records Available
          <!-- <button mat-stroked-button>Add Quote</button> -->
          <!-- <button mat-stroked-button routerLink="/pms/addlead">Add Project</button> -->
        </div>
      </div>
    </div>


    <div class="boxBody">
      <div class="boxInn" >
          <table mat-table [dataSource]="dataSource" >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element">
             {{element?.data?.reference}} 
            </td>
          </ng-container>
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef>Customer Name </th>
            <td mat-cell *matCellDef="let element"> 
                <a routerLink="/pms/project/details/{{ element?.data?.uuid }}">{{element?.data?.customer?.data?.first_name}}</a> </td>
          </ng-container>
          <ng-container matColumnDef="projservices">
            <th mat-header-cell *matHeaderCellDef> Services</th>
            <td mat-cell *matCellDef="let element"> {{element?.data?.service?.data?.title}} </td>
          </ng-container>
          <ng-container matColumnDef="languagePair">
            <th mat-header-cell *matHeaderCellDef> Language Pair </th>
            <td mat-cell *matCellDef="let element">
              <span class="price text-truncate">
                <span *ngFor="let res of element.lang_array">
                  <p>{{res}}</p>
                </span></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="projStartDate">
            <th mat-header-cell *matHeaderCellDef> Start Date </th>
            <td mat-cell *matCellDef="let element"> {{element?.data?.created_at?.date | date:'yyyy-MM-dd'}} </td>
          </ng-container>
          <ng-container matColumnDef="projEndDate">
            <th mat-header-cell *matHeaderCellDef> End Date </th>
            <td mat-cell *matCellDef="let element"> {{element?.data?.due_date?.date | date:'yyyy-MM-dd'}} </td>
          </ng-container>
          <ng-container matColumnDef="projStatus">
            <th mat-header-cell *matHeaderCellDef> Current Status </th>
            <td mat-cell *matCellDef="let element">
              {{element?.data?.projectstage?.data?.pm_status}}
            </td>
          </ng-container>
         
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
     <div class="noDataFound" *ngIf="total_records==0">
        No data available!!
      </div> 
    </div>
  </div>

</div>