import { OnInit } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from 'app/main/shared.service';
import { Router, ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material';
import { ProjectReleaseComponent } from './project-release/project-release.component';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { SpecialityChangeComponent } from './speciality-change/speciality-change.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { VendorService } from 'app/main/Services/vendor.service';
import { LanguageChangeComponent } from './language-change/language-change.component';
import { CreateTransactionComponent } from '../customer/transactions/create-transaction/create-transaction.component';
import { DeadlineChangeComponent } from './deadline-change/deadline-change.component';
import { UpdateServiceTypeComponent } from './update-service-type/update-service-type.component';
var ProjectDetailsComponent = /** @class */ (function () {
    function ProjectDetailsComponent(_fuseNavigationService, route, toastr, projectsService, _formBuilder, router, vendorService, dialog, _location) {
        this._fuseNavigationService = _fuseNavigationService;
        this.route = route;
        this.toastr = toastr;
        this.projectsService = projectsService;
        this._formBuilder = _formBuilder;
        this.router = router;
        this.vendorService = vendorService;
        this.dialog = dialog;
        this._location = _location;
        this.linear = true;
        this.showAddAdditionService = false;
        this.additonal_service_array = [];
        this.show = false;
        this.file_id_data = [];
        this.crm_file_data = [];
        this.NewAdditionalServicesTotal = 0;
        this.version = '';
        this.comment_data_update = {};
        this.rush_project_data = {};
        this.important_messages_response = [];
        this.locked_message = '';
        this.is_active_class = '';
        this.languageData = [];
        // project_locked_record: any =[];
        this.quote_approve = [];
        this.DiscoutPromotion = [];
        this.MarkAsStatus = [];
        this.AddonResponse = '';
        this.AutoQuoteInfoResponse = '';
        this.CrmAccountInfoResponse = '';
        this.DetailMainResponse = '';
        this.response_data_quoteArray = [];
        this.activeInternal = false;
        this.data = {
            source_lang_id: '',
            service_id: '',
            project_language_id: '',
            sourcelanguage: {
                data: {
                    lang_name: ''
                }
            },
            targetlanguage: {
                data: {
                    lang_name: ''
                }
            },
            target_lang_id: '',
            quantity: '',
            pricing_method: '',
            rate: '',
            sub_total: ''
        };
        this.project_lang_data = [];
        this.table_data = [];
        this.displayedColumns = ['langPair', 'translationRate', 'proofindRate', 'sub_total', 'actions'];
        this.getValueMailing = "(NA)";
        this.getValueNotarization = "(NA)";
        this.getValueApostile = "(NA)";
        this.project_quote_additional_service = [];
        this.source_id = [];
        this.target_id = [];
        this.unique_source_language = [];
        this.unique_target_language = [];
        this.source_language_array = [];
        this.target_language_array = [];
        this.crmStatusObject = {
            lead_status: '',
            opp_stage: ''
        };
        this.checkCrmStatus = true;
        this.crmdoc_data = [];
        this.BaseUrl_file = environment.baseUrl_file;
        this.DueTime_env = environment.Due_time;
    }
    ProjectDetailsComponent.prototype.ngOnInit = function () {
        this.user_id = SharedService.get('id');
        this.username = SharedService.get('username');
        this.user_group_id = SharedService.get('user_group_id');
        this.getLanguage();
        this.project_id = this.route.snapshot.params['id'];
        this.getAdditionalServices();
        this.getDiscountTypePromotion();
        this.getMarkAsStatus();
        this.getProjectDetail();
        // this.getUniqueProjectLanguage();
        this.getImportantNotes();
        this.Form = this._formBuilder.group({
            total: ['', Validators.compose([Validators.required])],
            discount_amount: [''],
            discount: ['']
        });
        // this.router.events.subscribe(e=>{
        //   if (e instanceof ChildActivationStart) {
        //     console.log(e.snapshot.children[0].params.id)
        //     window.location.href = '/pms/project/details/'+e.snapshot.children[0].params.id;
        //   } 
        // })
    };
    // goToCreateQuote(res:any){
    //   window.location.href = '/pms/project/'+res+'/create-quote/';
    // }
    ProjectDetailsComponent.prototype.getImportantNotes = function () {
        var _this = this;
        this.projectsService.getImportantNotes(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.important_messages_response = response.data;
            }
        });
    };
    ProjectDetailsComponent.prototype.getProjectDetail = function () {
        var _this = this;
        this.commentForm = this._formBuilder.group({
            message: ['', Validators.compose([Validators.required])],
            message_type: ['0', Validators.compose([Validators.required])],
            is_important: [false]
        });
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.getUniqueProjectLanguage();
                if (response.data.crm_opp_id) {
                    _this.getCrmdocuments(response.data.crm_opp_id);
                    _this.getAddonInfo(response.data.crm_opp_id);
                    _this.getAutoQuoteInfo(response.data.crm_opp_id);
                    _this.getCrmAccountInfo(response.data.crm_opp_id);
                    _this.getDetailMain(response.data.crm_opp_id);
                }
                if (response.data.crm_opp_id && response.data.crm_type) {
                    var crm_type = void 0;
                    if (response.data.crm_type == 'Opportunity') {
                        crm_type = 'Opportunities';
                        _this.CrmUrl = environment.crmUrl + '?module=' + crm_type + '&action=DetailView' + '&record=' + response.data.crm_opp_id;
                    }
                    if (response.data.opp_stage == null) {
                        _this.crmStatusObject.opp_stage = '';
                    }
                    if (_this.crmStatusObject.opp_stage != null) {
                        _this.crmStatusObject.opp_stage = response.data.opp_stage; // test
                        _this.checkCrmStatus = false;
                    }
                }
                else if (response.data.crm_lead_id && response.data.crm_type) {
                    var crm_type = void 0;
                    if (response.data.crm_type == 'lead') {
                        crm_type = 'Leads';
                        _this.CrmUrl = environment.crmUrl + '?module=' + crm_type + '&action=DetailView' + '&record=' + response.data.crm_lead_id;
                    }
                    if (response.data.lead_status == null) {
                        _this.crmStatusObject.lead_status = '';
                    }
                    if (_this.crmStatusObject.lead_status != null) {
                        _this.crmStatusObject.lead_status = response.data.lead_status; // test
                        _this.checkCrmStatus = false;
                    }
                }
                _this.DiscoutPromotion.forEach(function (dis_res) {
                    if (response.data.promo_code == dis_res.coupon) {
                        _this.discount_value = dis_res.value;
                    }
                });
                var dt2 = new Date();
                var datetime = moment(response.data.created_at.date);
                var current = moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
                var minutes = current.diff(datetime, 'minutes');
                _this.check_class = minutes;
                if (minutes > 60) {
                    var diff;
                    _this.TimeElapsed = _this.timeConvert(minutes);
                    diff = _this.DueTime_env - minutes;
                    if (diff < 0) {
                        _this.checkdueTime = false;
                        _this.DueTime = _this.timeConvert(diff);
                        _this.DueTime = '-' + _this.DueTime;
                    }
                    else {
                        _this.checkdueTime = true;
                        _this.DueTime = _this.timeConvert(diff);
                    }
                }
                else {
                    _this.TimeElapsed = minutes + ' Mintue(s)';
                    _this.DueTime = _this.DueTime_env - minutes;
                    if (_this.DueTime < 0) {
                        _this.checkdueTime = false;
                        _this.DueTime = _this.DueTime_env - minutes + ' Mintue(s)';
                    }
                    else {
                        _this.checkdueTime = true;
                        _this.DueTime = _this.DueTime_env - minutes + ' Mintue(s)';
                    }
                }
                _this.RequestQueue_response = response.data;
                if (response.data.rush_project == 0) {
                    _this.activeInternal = false;
                }
                else {
                    _this.activeInternal = true;
                }
                _this.project_stage_id = _this.RequestQueue_response.stage_id;
                _this.is_locked = _this.RequestQueue_response.is_locked;
                _this.locked_by = _this.RequestQueue_response.locked_by;
                _this.locked_by_value = _this.locked_by;
                if (_this.user_id == _this.locked_by) {
                    _this.show = true;
                }
                else if (_this.user_id != _this.locked_by) {
                    if (_this.locked_by == null) {
                        _this.show = true;
                    }
                    else {
                        _this.show = false;
                        _this.projectLock();
                    }
                }
                _this.version = _this.RequestQueue_response.version;
                _this.specialization_id = response.data.specilization_id;
                _this.project_type = response.data.service_id;
                if (response.data.projectmanager != null) {
                    _this.project_manager_id = response.data.projectmanager.data.id;
                }
                if (response.data.projectquote.data.length > 0) {
                    response.data.projectquote.data.forEach(function (ele) {
                        ele.projectquoteservice.data.forEach(function (element) {
                            element.language = [];
                            _this.data = {
                                source_lang_id: '',
                                service_id: '',
                                project_language_id: '',
                                sourcelanguage: {
                                    data: {
                                        lang_name: ''
                                    }
                                },
                                targetlanguage: {
                                    data: {
                                        lang_name: ''
                                    }
                                },
                                target_lang_id: '',
                                quantity: '',
                                pricing_method: '',
                                rate: '',
                                sub_total: '',
                            };
                            _this.data.quantity = element.quantity;
                            _this.data.pricing_method = element.pricing_method;
                            _this.data.rate = element.rate;
                            _this.data.sub_total = element.subTotal;
                            element.projectlanguages.data.forEach(function (elem) {
                                _this.data.sourcelanguage.data.lang_name = elem.sourcelanguage.data.lang_name;
                                _this.data.targetlanguage.data.lang_name = elem.targetlanguage.data.lang_name;
                                if (_this.quote_status == 4) {
                                    _this.compare_target_lang = _this.data.targetlanguage.data.lang_name;
                                }
                            });
                            element.language.push(_this.data);
                        });
                    });
                    _this.response_data_quoteArray = response.data.projectquote.data;
                    _this.response_data_quoteArray.forEach(function (res) {
                        var is_active_class = '';
                        if (res.is_active == 0) {
                            is_active_class = 'quote_inactive';
                        }
                        else if (res.is_active == 1) {
                            is_active_class = 'quote_active';
                        }
                        res.is_active_class = is_active_class;
                        _this.quote_status = res.status;
                        if (_this.quote_status == 2 || _this.quote_status == 3) {
                            _this.data =
                                {
                                    source_lang_id: '',
                                    service_id: '',
                                    project_language_id: '',
                                    sourcelanguage: {
                                        data: {
                                            lang_name: ''
                                        }
                                    },
                                    targetlanguage: {
                                        data: {
                                            lang_name: ''
                                        }
                                    },
                                    target_lang_id: '',
                                    quantity: '',
                                    pricing_method: 'Words',
                                    rate: '',
                                    sub_total: ''
                                };
                        }
                    });
                }
            }
        });
    };
    ProjectDetailsComponent.prototype.showAddAdditionalServices = function () {
        this.showAddAdditionService = true;
    };
    ProjectDetailsComponent.prototype.showAddAdditionalServices_click = function () {
        this.index_id = 0;
        this.showAddAdditionService = true;
    };
    //get child component data start
    ProjectDetailsComponent.prototype.getDataMailing = function (value) {
        this.index_id = 0;
        if (value.title == "(Applied)") {
            this.getValueMailing = value.title;
        }
        else {
            this.getValueMailing = "(NA)";
        }
        if (value.click_value == 1) {
            this.showAddAdditionalServices();
            var top_additional_service = document.getElementById('top_additional_service');
            if (top_additional_service != null) {
                top_additional_service.scrollIntoView();
                top_additional_service = null;
            }
        }
    };
    ProjectDetailsComponent.prototype.getDataNotarization = function (value) {
        // this.getValueNotarization = value;
        this.index_id = 1;
        if (value.title == "(Applied)") {
            this.getValueNotarization = value.title;
        }
        else {
            this.getValueNotarization = "(NA)";
        }
        if (value.click_value == 1) {
            this.showAddAdditionalServices();
            var top_additional_service = document.getElementById('top_additional_service');
            if (top_additional_service != null) {
                top_additional_service.scrollIntoView();
                top_additional_service = null;
            }
        }
    };
    ProjectDetailsComponent.prototype.getDataApostile = function (value) {
        this.index_id = 2;
        // this.getValueApostile = value;
        if (value.title == "(Applied)") {
            this.getValueApostile = value.title;
        }
        else {
            this.getValueApostile = "(NA)";
        }
        if (value.click_value == 1) {
            this.showAddAdditionalServices();
            var top_additional_service = document.getElementById('top_additional_service');
            if (top_additional_service != null) {
                top_additional_service.scrollIntoView();
                top_additional_service = null;
            }
        }
    };
    //get child component data end
    ProjectDetailsComponent.prototype.submitComment = function () {
        var _this = this;
        this.comment_data_update.message_type = this.commentForm.value.message_type;
        this.comment_data_update.message = this.commentForm.value.message;
        this.comment_data_update.from_user_id = this.user_id;
        this.comment_data_update.is_important = this.commentForm.value.is_important;
        this.projectsService.updateComment(this.project_id, this.comment_data_update)
            .subscribe(function (response) {
            if (response) {
                if (response.data.mail_response) {
                    _this.toastr.success(response.data.mail_response);
                }
                else {
                    _this.toastr.success("Updated");
                }
                _this.getImportantNotes();
                _this.getProjectDetail();
            }
        });
    };
    ProjectDetailsComponent.prototype.projectLock = function () {
        var _this = this;
        var data = {
            project_id: '',
            is_locked: false
        };
        data.project_id = this.project_id;
        data.is_locked = true;
        this.projectsService.projectLock(data)
            .subscribe(function (response) {
            if (response) {
                if (_this.locked_by != _this.user_id) {
                    if (_this.locked_by_value) {
                        _this.locked_message = response.data;
                    }
                }
            }
        });
    };
    ProjectDetailsComponent.prototype.Total_cost = function () {
        if (this.Form.value.words != '' && this.Form.value.rate != '') {
            var total = 0;
            total = this.Form.value.words * this.Form.value.rate;
            this.Form.patchValue({
                total_cost: total
            });
        }
        else {
            this.Form.patchValue({
                total_cost: ''
            });
        }
    };
    // get unique project language
    ProjectDetailsComponent.prototype.getUniqueProjectLanguage = function () {
        var _this = this;
        var data = {
            project_id: this.project_id
        };
        this.source_language_array = [];
        this.target_language_array = [];
        this.target_language_value = '';
        this.source_language_value = '';
        this.projectsService.getUniqueProjectLanguage(data)
            .subscribe(function (response) {
            if (response) {
                _this.project_lang_data = response.source_language_name;
                _this.source_language_array = response.source_language_name;
                _this.target_language_array = response.target_language_name;
                _this.source_language_value = _this.getLanguageName(_this.source_language_array);
                _this.target_language_value = _this.getLanguageName(_this.target_language_array);
            }
        });
    };
    ProjectDetailsComponent.prototype.getLanguageName = function (res) {
        var lang = '';
        if (res.length > 0) {
            res.forEach(function (element) {
                lang += element.lang_name + ', ';
            });
        }
        lang = lang.slice(0, -2);
        return lang;
    };
    // Get Language
    ProjectDetailsComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res.data;
            }
        });
    };
    // pop-up 
    ProjectDetailsComponent.prototype.projectRelease = function () {
        var dailogRef = this.dialog.open(ProjectReleaseComponent, {
            width: '500px',
            data: { project_id: this.project_id }
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                // this.getProjectDetail();
            }
        });
    };
    // calulate time from minute
    ProjectDetailsComponent.prototype.timeConvert = function (n) {
        if (n < 0) {
            n = -1 * n;
        }
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var days = (hours / 24);
        var rdays = Math.floor(days);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + " hour(s) and " + rminutes + " minute(s)";
    };
    // Discount type promotion
    ProjectDetailsComponent.prototype.getDiscountTypePromotion = function () {
        var _this = this;
        this.projectsService.getDiscountTypePromotion()
            .subscribe(function (res) {
            if (res) {
                _this.DiscoutPromotion = res.data;
            }
        });
    };
    // Get Mark as status
    ProjectDetailsComponent.prototype.getMarkAsStatus = function () {
        var _this = this;
        this.projectsService.getMarkAsStatus()
            .subscribe(function (res) {
            if (res) {
                _this.MarkAsStatus = res.data;
            }
        }, function (error) {
            _this.toastr.error("Something went wrong, Please try later!");
        });
    };
    // update mark as status
    ProjectDetailsComponent.prototype.updateMarkAs = function (res) {
        var _this = this;
        var obj = {
            status_id: ''
        };
        obj.status_id = res;
        this.projectsService.updateMarkAsStatus(this.project_id, obj)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success('Status update successfully');
                _this.getProjectDetail();
            }
            //  else {
            //   this.toastr.error('Something went wrong!!');
            // }
        });
    };
    // popup speciality
    ProjectDetailsComponent.prototype.openDialog = function () {
        var _this = this;
        var data = {
            project_id: this.project_id,
            specilization_id: this.RequestQueue_response.specilization_id
        };
        var dailogRef = this.dialog.open(SpecialityChangeComponent, {
            width: '400px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            _this.getProjectDetail();
        });
    };
    // Delete project
    ProjectDetailsComponent.prototype.openDialogDeleteProject = function () {
        var obj = {
            project_id: this.project_id,
            message: 'Do you confirm the deletion of this project?'
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            height: '30%',
            data: obj
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                // DO SOMETHING
            }
        });
    };
    // Transaction start
    ProjectDetailsComponent.prototype.Transaction = function (id) {
        var _this = this;
        SharedService.set(SharedService.QUOTE_ID, id);
        // let obj = {
        //   quote_id: id,
        //   message: 'Do you confirm the deletion of this project?'
        // }
        var dialogRef = this.dialog.open(CreateTransactionComponent, {
        // data: obj
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // this.getProjectDetail();
            window.location.href = '/pms/project/details/' + _this.project_id;
            // DO SOMETHING
        });
    };
    // Transaction end
    // get Additional Services
    ProjectDetailsComponent.prototype.getAdditionalServices = function () {
        var _this = this;
        this.projectsService.getAdditionalServices(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.additonal_service_array = response.project_addition_service;
                _this.NewAdditionalServicesTotal = 0;
                if (_this.additonal_service_array.length > 0) {
                    _this.project_quote_additional_service = [];
                    _this.additonal_service_array.forEach(function (element) {
                        var obj = {
                            service_id: element.additional_service.service_id,
                            additional_service_id: element.additional_service_id,
                            title: element.additional_service.title,
                            quantity: element.quantity,
                            rate: element.price,
                            sub_total: element.sub_total,
                            additional_info: null
                        };
                        _this.NewAdditionalServicesTotal += parseFloat(element.sub_total);
                        _this.project_quote_additional_service.push(obj);
                    });
                    _this.NewAdditionalServicesTotal = _this.roundToTwo(_this.NewAdditionalServicesTotal);
                }
            }
        });
    };
    ProjectDetailsComponent.prototype.roundToTwo = function (num) {
        return Math.round(num * 100) / 100;
    };
    // Donot follow up
    ProjectDetailsComponent.prototype.doNotFollowUp = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        this.projectsService.updateLeadEnquiryDate(obj)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success(response.data);
            }
        }, function (error) {
            _this.toastr.error(error.error.data);
        });
    };
    // popup Change Language
    ProjectDetailsComponent.prototype.openDialogLanguage = function () {
        var _this = this;
        var dailogRef = this.dialog.open(LanguageChangeComponent, {
            width: '700px',
            data: this.project_id
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getUniqueProjectLanguage();
            }
        });
    };
    // popup Change Deadline
    ProjectDetailsComponent.prototype.openDialogDeadline = function () {
        var _this = this;
        var data = {
            project_id: this.project_id,
            due_date: ''
        };
        if (this.RequestQueue_response.due_date != null) {
            data.due_date = this.RequestQueue_response.due_date.date;
        }
        var dailogRef = this.dialog.open(DeadlineChangeComponent, {
            width: '350px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getProjectDetail();
            }
        });
    };
    ProjectDetailsComponent.prototype.crmStatusChange = function (evt) {
        if (evt.value == '') {
            this.checkCrmStatus = true;
        }
        else {
            this.checkCrmStatus = false;
        }
    };
    // Update Lead status or opportuity stage
    ProjectDetailsComponent.prototype.updateCrmStatus = function () {
        var _this = this;
        var projectCrmData = {
            project_id: '',
            crm_id: '',
            status: ''
        };
        var message;
        if (this.RequestQueue_response.crm_lead_id) {
            projectCrmData.project_id = this.project_id;
            projectCrmData.crm_id = this.RequestQueue_response.crm_lead_id;
            projectCrmData.status = this.crmStatusObject.lead_status;
            message = 'Crm status updated successfully';
        }
        else if (this.RequestQueue_response.crm_opp_id) {
            projectCrmData.project_id = this.project_id;
            projectCrmData.crm_id = this.RequestQueue_response.crm_opp_id;
            projectCrmData.status = this.crmStatusObject.opp_stage;
            message = 'Crm stage updated successfully';
        }
        this.projectsService.updateProjectCrmStatus(projectCrmData)
            .subscribe(function (response) {
            if (response) {
                window.location.href = '/pms/project/details/' + _this.project_id;
                _this.toastr.success(message);
            }
        });
    };
    // popup Update service type
    ProjectDetailsComponent.prototype.openDialogServiceType = function () {
        var _this = this;
        var data = {
            project_id: this.project_id,
            translation_type: ''
        };
        if (this.RequestQueue_response.translation_type) {
            data.translation_type = this.RequestQueue_response.translation_type;
        }
        var dailogRef = this.dialog.open(UpdateServiceTypeComponent, {
            width: '350px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.getProjectDetail();
            }
        });
    };
    ProjectDetailsComponent.prototype.back = function () {
        this._location.back();
    };
    ProjectDetailsComponent.prototype.getCrmdocuments = function (crmid) {
        var _this = this;
        this.projectsService.getCrmdocuments(crmid)
            .subscribe(function (response) {
            if (response) {
                if (response.data.length > 0) {
                    _this.crmdoc_data = response.data;
                    response.data.forEach(function (element) {
                        var obj = {
                            file_name: element.download_link_without_login_c.value,
                            file_path: element.filename.value
                        };
                        _this.crm_file_data.push(obj);
                    });
                }
                else {
                    _this.crmdoc_data = '';
                }
            }
            else {
                _this.crmdoc_data = '';
            }
        });
    };
    ProjectDetailsComponent.prototype.getDetailMain = function (crmid) {
        var _this = this;
        this.projectsService.getDetailMain(crmid)
            .subscribe(function (response) {
            if (response) {
                _this.DetailMainResponse = response.data;
            }
            ;
        });
    };
    ProjectDetailsComponent.prototype.getAutoQuoteInfo = function (crmid) {
        var _this = this;
        this.projectsService.getAutoQuoteInfo(crmid)
            .subscribe(function (response) {
            if (response) {
                _this.AutoQuoteInfoResponse = response.data;
            }
            ;
        });
    };
    ProjectDetailsComponent.prototype.getCrmAccountInfo = function (crmid) {
        var _this = this;
        this.projectsService.getCrmAccountInfo(crmid)
            .subscribe(function (response) {
            if (response) {
                _this.CrmAccountInfoResponse = response.data;
                _this.CrmAccountUrl = environment.crmUrl + '?module=Accounts&action=DetailView&record=' + response.data.id.value;
            }
            ;
        });
    };
    ProjectDetailsComponent.prototype.getAddonInfo = function (crmid) {
        var _this = this;
        this.projectsService.getAddonInfo(crmid)
            .subscribe(function (response) {
            if (response) {
                _this.AddonResponse = response.data;
            }
            ;
        });
    };
    ProjectDetailsComponent.prototype.convertIntoFloat = function (n) {
        var x = parseInt(n);
        var res = x.toFixed(2);
        return res;
    };
    // selectFile(evt, res) {
    //   debugger;
    //   console.log(res[0].id.value);
    //   if (evt.checked && this.crm_file_data.indexOf(res[0].id.value == -1)) {
    //     let obj: any =
    //     {
    //       file_name: res[0].download_link_without_login_c.value,
    //       file_path: res[0].filename.value
    //     }
    //     this.crm_file_data.push(obj);
    //   }
    //   console.log(this.crm_file_data);
    //   // else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
    //   //     let index = this.file_id_data.indexOf(res.id);
    //   //     this.file_id_data.splice(index, 1);
    //   // }
    // }
    // selectBulkProgress(evt) {
    //   debugger;
    //   this.file_id_data = [];
    //   let check = false;
    //   this.crm_file_data.forEach((element) => {
    //     console.log(element);
    //     element["check"] = evt.checked;
    //     if (evt.checked) {
    //       element.check = true;
    //       this.file_id_data.push(element);
    //     }
    //     else {
    //       this.file_id_data = [];
    //     }
    //   });
    //   console.log(this.file_id_data);
    //   // this.DownloadZip();
    //   // if (check) {
    //   //   this.bulk_selected_progress = true;
    //   // }
    // }
    ProjectDetailsComponent.prototype.UncheckedFile = function () {
        // this.bulk_selected_progress = false;
        // this.file_data.forEach((element, key) => {
        //   element["check"] = false;
        //   let index = this.file_id_data.indexOf(element.id);
        //   this.file_id_data.splice(index, 1);
        // });
    };
    ProjectDetailsComponent.prototype.markAsRushProject = function (event) {
        var _this = this;
        this.rush_project_data.project_id = this.project_id;
        this.rush_project_data.rush_project = event.checked;
        this.projectsService.markAsRushProject(this.rush_project_data)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success(response.message);
            }
        });
    };
    return ProjectDetailsComponent;
}());
export { ProjectDetailsComponent };
