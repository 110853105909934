import { PipeTransform } from '@angular/core';
var KeysPipe = /** @class */ (function () {
    function KeysPipe() {
    }
    /**
     * Transform
     *
     * @param value
     * @param {string[]} args
     * @returns {any}
     */
    KeysPipe.prototype.transform = function (value, args) {
        var keys = [];
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                keys.push({
                    key: key,
                    value: value[key]
                });
            }
        }
        return keys;
    };
    return KeysPipe;
}());
export { KeysPipe };
