import { Injectable } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';

import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CrmService {
  BaseUrl: any;

  constructor(public http: HttpClient, public sharedService: SharedService) {
    this.BaseUrl = environment.APIEndpoint;
  }
  getAllGclids(): Observable<any> {
    let url = this.BaseUrl + '/gclidUsers';
    return this.sharedService.getApi(url);

  }

  getAllGclid(query): Observable<any> {
    let url = this.BaseUrl + '/gclidUsers';
    return this.sharedService.getApi(url);

  }
  
}