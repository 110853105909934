import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProjectsService } from 'app/main/Services/projects.service';
import { environment } from 'environments/environment';
import {  MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { VendorService } from 'app/main/Services/vendor.service';
import { AddFileManagementComponent } from './add-file-management/add-file-management.component';
import { ToolsComponent } from '../../tools/tools.component';
import { SharedService } from 'app/main/shared.service';
@Component({
  selector: 'app-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.scss']
})
export class FileManagementComponent implements OnInit {
  file_data:any=[];
  file_table_data:any=[];
  project_file_data:string;
  file_id_data:any=[];
  project_id: string;
  BaseUrl_file: any;
  project_type:number;
  fileError: string = '';
  bulk_selected_progress: boolean = false;
  file_d:string;
  file_multiple_data:any=[];
  fileSuccess: string;
  uploaded_files:any=[];
  file_data_save:any={};
  file_id_array:any=[];
  // mainprogressbar: boolean;
  constructor(private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private vendorService : VendorService,private dialog:MatDialog,
    private toastr: ToastrService) {
    this.BaseUrl_file = environment.baseUrl_file;
   }

  ngOnInit() {
 
    this.project_id = this.route.snapshot.params['id'];
  
    this.getProjectDetail();
  }

  getProjectDetail() {
    this.projectsService.getProjectDetail(this.project_id)
    .subscribe((response) => {
        if(response) {
            this.file_data=[];
            var fullname;
            var id;
           
            this.project_type=response.data.service_id;
     
             response.data.projectfile.data.forEach(element => {
               id=element.id;
                if(element.createdby){
                    fullname =element.createdby.data.fullname;
                }else{
                    fullname='Customer';
                }
     
                    if(element.targetlanguage){
                        var lang_name=element.targetlanguage.data.lang_name;
                   
                    }

                    if( element.filetype){
                        var filetype=element.filetype.data.name;
                   
                    }
                    
               
                    element.file.data.forEach(elm => {
                     
                      //  this.project_file_data=this.BaseUrl_file + elm.path;
                      let file_url;
                      if(elm.path.includes("http")){
                        this.project_file_data= elm.path;
                      }else{
                        this.project_file_data=this.BaseUrl_file + elm.path;
                      }
                       elm.file_url=this.project_file_data;
                       elm.type=element.type;
                        elm.fullname= fullname;
                        //elm.created_at= created_at;
                        elm.lang_name=lang_name;
                        elm.filetype=filetype;
                        elm.id=id;
                        this.file_data.push(elm);
                    });
                    
              
            });
    

           
        }
    });
    }



    DownloadZip(){
     let data={
      project_id:'',
      file_ids: []
     }
      data.project_id=this.project_id;
      data.file_ids=this.file_id_data;
      this.projectsService.downloadZip(data).subscribe(
          resposne => {
              if (resposne) {
                    this.file_d= resposne.url;
              }
          }
      );
  }


  
  selectFile(evt,res){
      if (evt.checked && this.file_id_data.indexOf(res.id== -1)) {
        this.file_id_data.push(res.id);
      }
      else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
          let index = this.file_id_data.indexOf(res.id);
          this.file_id_data.splice(index, 1);
      }
    this.DownloadZip();
  }


  selectBulkProgress(evt) {
      this.file_id_data=[];
    let check = false;
    this.file_data.forEach((element, key) => {
      element["check"] = evt.checked;
        if (evt.checked) {
          element.check = true;
          this.file_id_data.push(element.id);
        }
        else {
            this.file_id_data = [];  
        }
    });
    this.DownloadZip();
    if (check) {
        this.bulk_selected_progress = true;
    } 
}


UncheckedFile(){
    this.bulk_selected_progress = false;
    this.file_data.forEach((element, key) => {
        element["check"] = false;
        let index = this.file_id_data.indexOf(element.id);
        this.file_id_data.splice(index, 1);
      });
}





  // pop-up 

  onCreate()    {
    const dailogRef = this.dialog.open(AddFileManagementComponent, {
        width: '70%',
        data: { project_id: this.project_id}
    })

    dailogRef.afterClosed().subscribe((response) => {
        if(response) {
            this.getProjectDetail();
        }
    });
  }



  ocrTools(): void {
    SharedService.set(SharedService.Project_id, this.project_id);
    const dialogRef = this.dialog.open(ToolsComponent, {
      width: '70%',
      height:'80%',
    });

    dialogRef.afterClosed().subscribe(result => {
        this.getProjectDetail();
        // window.location.href = '/pms/project/details/'+this.project_id;
        // DO SOMETHING
    });
  }
  // Remove Project File
  removeProjectFile(res:any){
    this.projectsService.deleteProjectFile(res.id).subscribe(
      (response) => {
        if(response){
           this.toastr.success(" Delete Project File  Successfully!!");
           this.getProjectDetail();
        }
      }
    );
  }
}
