// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
     production: false,
     hmr       : false,
     serverUrl: '/api',
     defaultLanguage: 'en-US',
     supportedLanguages: ['en-US'],
     APIEndpoint: 'https://tmsapi.daydevelopers.com/api',   
     baseUrl: 'https://tmsapi.daydevelopers.com',
     baseUrl_file: 'https://tmsapi.daydevelopers.com',
     Due_time: 10,
     crmUrl: 'http://dayworkspace.com/admincrm/index.php',
     whisperUrl:'https://swyz1744ck.execute-api.us-east-1.amazonaws.com/prod',
     rate: 0,
     rate_tier2: 2000,
     rate_tier3: 5000,
     rate_tier4: 10000,
     rate_tier5: 20000,
     tokenization_key : 'sandbox_6mf5khhs_rmbxvgtq3dt4grh3',
     BaseNumber : '+17185508412',
     PublishKey :'pk_test_51LJwALCvBixT2WNmkx6sL1PU3FN3bWuI7eo1ZCaEWnFpR8uAc4l1zEviuQlEFW4pEoJff1nHzjG5mDA9pDq6ze4G00hvMp1I4p'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
