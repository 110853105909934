import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatPaginator } from '@angular/material';
import { MatTableDataSource, MatSort,MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { CrmEmailLogPreviewComponent } from './crm-email-log-preview/crm-email-log-preview.component';
@Component({
  selector: 'app-crm-email-log',
  templateUrl: './crm-email-log.component.html',
  styleUrls: ['./crm-email-log.component.scss']
})
export class CRMEmailLogComponent implements OnInit {
  dataSource:any;
  emailLogResponse:any=[];
  paginate:any = {};
  pageSize:number = 10;
  query_projects:string ="";
  paginator: MatPaginator;
  project_id:string;
  pageIndex:number=1;
  allData:any =[];
  total:number=0;
  CrmEmailResponse: any = '';
  constructor(private projectsService: ProjectsService,private toastr: ToastrService, private route: ActivatedRoute, private dialog:MatDialog) { }
  displayedColumns: string[] = [ 'subject', 'sent_on','status'];
  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.getProjectDetail();
  }

  // popup
  openDialogEmail(data:any) {
    const dailogRef = this.dialog.open(CrmEmailLogPreviewComponent, {
      width: '1700px',
      data: data
    });
  }

  getProjectDetail() {
    this.projectsService.getProjectDetail(this.project_id)
    .subscribe((response) => {
    if(response){
      this.getCrmEmailLog(response.data.crm_opp_id);
    }
    });
  }


  getCrmEmailLog(id: any) {
    this.projectsService.getCrmEmailLog(id)
    .subscribe((response) => {
    if(response){
      this.dataSource = new MatTableDataSource(response.data);  
      this.allData = response.data;
    }
    });
  }

}