<div class="dialog-content-wrapper">
  <div class="tHeader">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
  <div fxFlex>
      <h4 class="m0 title dialog-title text-uppercase">Delete Project</h4>
    </div>
    <div fxFlex class="align-right">
        <button mat-icon-button (click)="onNoClick()"  matTooltip="Close" aria-label="Close dialog">
               <mat-icon>close</mat-icon>
        </button>
      </div>
</div>
</div>  

<div class="p-16 pt-4 m-0" mat-dialog-content>
    {{data.message}}
   </div>
   <div mat-dialog-actions>
     <button mat-button color="warn" (click)="onNoClick()">No</button>
     <button mat-button color="primary" (click)="onYesClick()">Yes</button>
     <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button> -->
   </div>
   </div>