import { ProjectsService } from "app/main/Services/projects.service";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource, MatSort, MatDialog } from "@angular/material";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import * as braintree from "braintree-web";
import { AppGlobals } from "app/main/app.global";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';

@Component({
    selector: "app-create-transaction",
    templateUrl: "./create-transaction.component.html",
    styleUrls: ["./create-transaction.component.scss"],
    providers: [AppGlobals],
})
export class CreateTransactionComponent implements OnInit {
    searchCustomerForm: FormGroup;
    paymentForm: FormGroup;
    paymentFormNewUser: FormGroup;
    dataSource: any;

    query_quote: string = "";
    sort: MatSort;
    paginate: any = {};

    displayedColumns: string[] = [
        "id",
        "name",
        "savedDate",
        "paymentMethod",
        "token",
        "action",
    ];
    show_existingUser: boolean = false;
    show_newUser: boolean = false;
    show_existing: boolean = true;
    show_new: boolean = false;
    show_search: boolean = true;
    dataCustomer: any = [];
    dataTransaction: any = [];
    totalCustomerTransaction: number = 0;
    token: any;
    hostedFieldsInstance: braintree.HostedFields;
    cardholdersName: string;
    payloadNonce: any = [];
    customerdetail: any;
    show_success: boolean = false;
    quote_id: any = null;
    quote_detail: any;
    href: string;
    tokenization_key:any;
    constructor(
        private _formBuilder: FormBuilder,
        private toastr: ToastrService,
        private projectsService: ProjectsService,
        private router: Router,
        private route: ActivatedRoute,
        // private dialogRef: MatDialogRef<CreateTransactionComponent>,
        // @Inject(MAT_DIALOG_DATA) public data :any,
        private _global: AppGlobals,
      
    ) {
        this.tokenization_key = environment.tokenization_key;
    }

    ngOnInit() {
        // this.quote_id = this.route.snapshot.params['id'];
        // this.quote_id=this.data.quote_id;
        this.quote_id = null;
        this.dataSource = [];
        this.href = this.router.url;
        if (this.href != "/pms/transactions/create") {
            this.quote_id = localStorage.getItem("quote_id");
        }

        this.getTransactions();
        this.createBraintreeUI();

        this.searchCustomerForm = this._formBuilder.group({
            field_value: ["", Validators.compose([Validators.required])],
        });
        this.paymentForm = this._formBuilder.group({
            transaction_amount: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern("^[+]?[0-9]*.?[0-9]+"),
                ]),
            ],
            order_id: ["", Validators.required],
            transaction_description: [""],
        });
        this.paymentFormNewUser = this._formBuilder.group({
            amount: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern("^[+]?[0-9]*.?[0-9]+"),
                ]),
            ],
            order_id: ["", Validators.required],
            transaction_description: [""],
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            phone: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern("^[0-9+d]{10}$"),
                ]),
            ],
            email: [
                "",
                Validators.compose([Validators.required, Validators.email]),
            ],
            saveToVault: [],
        });
    }

    resetForm(value: any = undefined): void {
        this.paymentFormNewUser.reset(value);
    }
    // first tab start

    check_user(event: any) {
        if (event.source.value == "existing") {
            this.show_existing = true;
            this.show_search = true;
            this.show_new = false;
            this.show_newUser = false;
            this.dataSource = [];
        } else if (event.source.value == "new") {
            this.show_search = false;
            this.show_existing = false;
            this.show_new = true;
        }
    }

    // Existing vault customer start
    searchCustomerTransactions() {
        this.dataSource = [];
        if (this.searchCustomerForm.value.field_value == " ") {
            this.toastr.error("Field value never be empty");
            return false;
        }
        let formValues = this.searchCustomerForm.value;
        this.projectsService.searchcustomerTransaction(formValues).subscribe(
            (response: any) => {
                if (response) {
                    this.dataTransaction = response.data;
                    if (this.dataTransaction.length == 0) {
                        // this.show_existingUser = false;
                        if (this.quote_detail.quote_detail) {
                            this.paymentFormNewUser.patchValue({
                                amount: this.quote_detail.quote_detail.total,
                                order_id: this.quote_detail.project_detail
                                    .reference,
                                firstName: this.quote_detail.customer_detail
                                    .first_name,
                                lastName: this.quote_detail.customer_detail
                                    .last_name,
                                email: this.quote_detail.customer_detail
                                    .email_primary,
                                transaction_description: this.quote_detail
                                    .transaction_description,
                            });
                        }
                    } else {
                        this.show_existingUser = true;

                        if (this.quote_detail.quote_detail) {
                            this.paymentForm.patchValue({
                                transaction_amount: this.quote_detail
                                    .quote_detail.total,
                                order_id: this.quote_detail.project_detail
                                    .reference,
                                transaction_description: this.quote_detail
                                    .transaction_description,
                            });
                        }

                        this.paymentFormNewUser.patchValue({
                            amount: "",
                            order_id: "",
                            firstName: "",
                            lastName: "",
                            email: "",
                        });
                        this.dataSource = new MatTableDataSource(
                            this.dataTransaction
                        );
                    }
                }
            },
            (error) => {
                this.toastr.error(this._global.errorMessage);
            }
        );
    }

    create_payment(type: any, res: any) {
        this.token = res.token;
        if (type == "payment") {
            this.show_newUser = true;
        }
        this.customerdetail = res;
    }

    createPaymentTransactions() {
        let obj = {
            transaction_amount: this.paymentForm.value.transaction_amount,
            token: this.token,
            order_id: this.paymentForm.value.order_id,
            transaction_description: this.paymentForm.value
                .transaction_description,
            quote_id: this.quote_id,
            email: this.customerdetail.email,
            first_name: this.customerdetail.first_name,
            last_name: this.customerdetail.last_name,
            phone: this.customerdetail.phone,
        };
        this.projectsService.customerPayment(obj).subscribe(
            (response) => {
                if (response) {
                    this.searchCustomerForm.patchValue({
                        field_value: [""],
                    });
                    this.paymentForm.patchValue({
                        transaction_amount: [""],
                        order_id: [""],
                        transaction_description: [""],
                    });
                    this.show_newUser = false;
                    this.show_existingUser = false;
                    this.toastr.success(response.status);

                    if (this.href != "/pms/transactions/create") {
                        // this.dialogRef.close(true);
                    }
                }
            },
            (error) => {
                this.toastr.error(this._global.errorMessage);
                if (this.href != "/pms/transactions/create") {
                    // this.dialogRef.close(true);
                }
            }
        );
    }

    // Existing vault customer end

    // New payment with cc info start

    createBraintreeUI() {
        braintree.client
            .create({
                authorization: this.tokenization_key
            })
            .then((clientInstance) => {
                braintree.hostedFields
                    .create({
                        client: clientInstance,
                        styles: {
                            // Override styles for the hosted fields
                        },

                        // The hosted fields that we will be using
                        // NOTE : cardholder's name field is not available in the field options
                        // and a separate input field has to be used incase you need it
                        fields: {
                            number: {
                                selector: "#card-number",
                                placeholder: "1111 1111 1111 1111",
                            },
                            cvv: {
                                selector: "#cvv",
                                placeholder: "111",
                            },
                            expirationDate: {
                                selector: "#expiration-date",
                                placeholder: "MM/YY",
                            },
                        },
                    })
                    .then((hostedFieldsInstance) => {
                        this.hostedFieldsInstance = hostedFieldsInstance;
                        hostedFieldsInstance.on("focus", (event) => {
                            const field = event.fields[event.emittedBy];
                            const label = this.findLabel(field);
                            label.classList.remove("filled"); // added and removed css classes
                            // can add custom code for custom validations here
                        });

                        hostedFieldsInstance.on("blur", (event) => {
                            const field = event.fields[event.emittedBy];
                            const label = this.findLabel(field); // fetched label to apply custom validations
                            // can add custom code for custom validations here
                        });

                        hostedFieldsInstance.on("empty", (event) => {
                            const field = event.fields[event.emittedBy];
                            // can add custom code for custom validations here
                        });

                        hostedFieldsInstance.on("validityChange", (event) => {
                            const field = event.fields[event.emittedBy];
                            const label = this.findLabel(field);
                            if (field.isPotentiallyValid) {
                                // applying custom css and validations
                                label.classList.remove("invalid");
                            } else {
                                label.classList.add("invalid");
                            }
                            // can add custom code for custom validations here
                        });
                    });
            });
    }

    tokenizeUserDetails() {
        if (
            this.paymentFormNewUser.value.amount == "" ||
            this.paymentFormNewUser.value.amount == null
        ) {
            this.toastr.error("transaction amount never be empty!!");
            return;
        }

        if (this.paymentFormNewUser.value.amount < 0) {
            this.toastr.error("transaction amount never be negative!!");
            return;
        }

        if (
            this.paymentFormNewUser.value.order_id == "" ||
            this.paymentFormNewUser.value.order_id == null
        ) {
            this.toastr.error("order id never be empty!!");
            return;
        }

        if (
            this.paymentFormNewUser.value.firstName == "" ||
            this.paymentFormNewUser.value.firstName == null
        ) {
            this.toastr.error("customer first name  never be empty!!");
            return;
        }

        if (
            this.paymentFormNewUser.value.lastName == "" ||
            this.paymentFormNewUser.value.lastName == null
        ) {
            this.toastr.error("customer last name  never be empty!!");
            return;
        }

        if (
            this.paymentFormNewUser.value.phone == "" ||
            this.paymentFormNewUser.value.phone == null
        ) {
            this.toastr.error("phone never be empty!!");
            return;
        }

        if (
            this.paymentFormNewUser.value.email == "" ||
            this.paymentFormNewUser.value.email == null
        ) {
            this.toastr.error("email never be empty!!");
            return;
        }

        this.hostedFieldsInstance
            .tokenize({ cardholderName: this.cardholdersName })
            .then((payload) => {
                let obj = {
                    amount: this.paymentFormNewUser.value.amount,
                    order_id: this.paymentFormNewUser.value.order_id,
                    firstName: this.paymentFormNewUser.value.firstName,
                    lastName: this.paymentFormNewUser.value.lastName,
                    phone: this.paymentFormNewUser.value.phone,
                    email: this.paymentFormNewUser.value.email,
                    payloadNonce: payload.nonce,
                    saveToVault: this.paymentFormNewUser.value.saveToVault,
                    transaction_description: this.paymentFormNewUser.value
                        .transaction_description,
                    quote_id: this.quote_id,
                };

                this.projectsService.newCustomerPayment(obj).subscribe(
                    (response) => {
                        if (response) {
                            this.hostedFieldsInstance.clear("number");
                            this.hostedFieldsInstance.clear("cvv");
                            this.hostedFieldsInstance.clear("expirationDate");
                            this.cardholdersName = "";

                            this.paymentFormNewUser.reset();

                            Object.keys(
                                this.paymentFormNewUser.controls
                            ).forEach((key) => {
                                this.paymentFormNewUser
                                    .get(key)
                                    .setErrors(null);
                            });
                            // this.paymentFormNewUser.reset({});
                            // this.paymentFormNewUser = this._formBuilder.group({
                            //   amount: ['',Validators.compose([Validators.required, Validators.pattern('^[+]?[0-9]*\.?[0-9]+')])],
                            //   order_id: ['', Validators.required],
                            //   transaction_description: [''],
                            //   firstName: ['', Validators.required],
                            //   lastName: ['', Validators.required],
                            //   phone: ['', Validators.compose([Validators.required, Validators.pattern('^\[0-9+\d]{10}$')])],
                            //   email: ['', Validators.compose([Validators.required, Validators.email])],
                            //   saveToVault: []
                            // });
                            // this.paymentFormNewUser.patchValue({
                            //   amount: [''],
                            //   order_id: [''],
                            //   transaction_description: [''],
                            //   firstName: [''],
                            //   lastName: [''],
                            //   phone: [''],
                            //   email: [''],
                            //   saveToVault: [false]
                            // });
                            // this.hostedFieldsInstance.teardown(function (err) {
                            //   if (err) {
                            //     console.error('Could not tear down Hosted Fields!');
                            //   } else {
                            //     console.info('Hosted Fields has been torn down!');
                            //   }
                            // });
                            this.toastr.success(response.status);
                            if (this.href != "/pms/transactions/create") {
                                // this.dialogRef.close(true);
                            }
                        }
                    },
                    (error) => {
                        this.toastr.error(this._global.errorMessage);
                    }
                );
                // submit payload.nonce to the server from here
            })
            .catch((error: any) => {
                this.toastr.error(error.message);
                if (this.href != "/pms/transactions/create") {
                    // this.dialogRef.close(true);
                }
                // perform custom validation here or log errors
            });
    }

    findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
        return document.querySelector(
            '.hosted-field--label[for="' + field.container.id + '"]'
        );
    }

    // New payment with cc info  end

    // first tab end

    // second tab start

    searchTransaction() {
        if (this.quote_id != null) {
            this.router.navigate(["/pms/transactions/search/" + this.quote_id]);
        } else {
            this.router.navigate(["/pms/transactions/search"]);
        }
    }

    CreateTransaction() {
        if (this.quote_id != null) {
            this.router.navigate(["/pms/transactions/create/" + this.quote_id]);
        } else {
            this.router.navigate(["/pms/transactions/create"]);
        }
    }

    getTransactions() {
        this.projectsService.getTransaction(this.quote_id).subscribe(
            (res: any) => {
                if (res) {
                    this.quote_detail = res;
                    this.searchCustomerForm.patchValue({
                        field_value: res.customer_detail.email_primary,
                    });
                    // this.searchCustomerTransactions();
                }
            },
            (error) => {
                this.toastr.error("Something went wrong, Please try later!");
            }
        );
    }
}
