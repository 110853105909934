import { SharedService } from 'app/main/shared.service';
import { ProjectsService } from './../../Services/projects.service';
import { Component, OnInit , Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatSort,MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  // mainprogressbar:boolean = false;
  fileError: string = '';
  file_multiple_data:any=[];
  file_id_array:any=[];
  uploaded_files:any=[];
  uploaded_files_array:any=[];
  fileSuccess: string;
  form: FormGroup;
  project_id: any =[];
  totalticket:number=0;
  dataSource:any;
  query_quote:string ="";
  sort: MatSort;
  paginate:any = {};
  paginator: MatPaginator;
  pageSize:number = 20;
  pageIndex:number=1;
  filterStatus: string = "";
  fQuery: string = "";
  filterIssue:string="";
  displayedColumns: string[] = [ 'second_id','subject', 'issue_type', 'portal_id','created_at','updated_at', 'status'];
  constructor(private toastr: ToastrService,private _formBuilder: FormBuilder, private sharedService: SharedService, private projectsService: ProjectsService,    ) { }
  

  getTicketDetails()
  {
    let query="";
    this.projectsService.getTicketdata(query)
    .subscribe((response) => {
        if(response) {
    
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.sort=this.sort;
        this.paginate = response.meta.pagination;
        this.totalticket=response.meta.pagination.total;
         }
    });
  }
  paginateData(event) {
    let pQuery = ''
    this.pageIndex = event.pageIndex + 1;
    if(this.pageIndex < 0) {
        this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    
   this.query_quote='?page='+this.pageIndex+'&per_page='+this.pageSize;
    
   this.query_quote+=pQuery;
    
  
    this.projectsService.getTicketdata(this.query_quote)
    .subscribe((response) => {
    if(response) {
     
      this.dataSource = new MatTableDataSource(response.data);
      this.paginate = response.meta.pagination;
      this.totalticket=response.meta.pagination.total;
     }
  });
  }
  departmentFilter(evt, type )
  {
   
    this.fQuery = '?page=1&per_page=10'
    if ((type == "department" && evt.value != "" && evt.value !== undefined)) {
        this.filterStatus = evt.value;
        
        this.fQuery += "&department=" + this.filterStatus;
    }
    else if(type == "department" && evt.value == ""){
        this.filterStatus = evt.value;
        
    }
    else if(this.filterStatus!==""){
        this.fQuery += "&department=" + this.filterStatus;
    }
    
    if ((type == "issue_type" && evt.value != "" && evt.value !== undefined)) {
      this.filterIssue= evt.value;

      this.fQuery += '&issue_type=' + this.filterIssue;
  }
  else if(type == "issue_type" && evt.value == ""){
      this.filterIssue = evt.value;
      
  }
  else if(this.filterIssue!==""){
      this.fQuery += '&issue_type=' + this.filterIssue;
  }
    let query = this.fQuery;
    this.projectsService.getTicketdata(query)
    .subscribe((response) => {
    
        if(response) {
        
  
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.sort=this.sort;
            this.paginate = response.meta.pagination;
            this.totalticket = response.meta.pagination.total;
            
        }
    });


  }
  ngOnInit() {
   
    this.getTicketDetails()
    this.form = this._formBuilder.group({
      subject   : ['',Validators.required],
      project : ['',Validators.required],
      portal_id:['',Validators.required],
      message:['',Validators.required],
      issue_type:['',Validators.required]
    });
  }

  onFileUpload() { 
 
    
    this.file_multiple_data=[];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
        let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx','zip','txt'];
        var re = /(?:\.([^.]+))?$/;
        var files = fileUpload.files;
        var file;
          // loop through files
        for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if(file && filecheck.indexOf(ext) != -1) {
            let size = file.size/1024/1024;
            if (size > 20) {
                this.fileError = "File size should not be more than 20MB.";
                return;
            }
          this.file_multiple_data.push(file);
        }
        else {
            this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
 }
 
 this.addDocument(this.file_multiple_data);  
    };
   
    fileUpload.click();
  }

  addDocument(file) {
  
    this.fileSuccess = '';
    // this.mainprogressbar = true;
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append("file[]", file[x]);
     }
     
    this.projectsService.uploadFile(uploadData)
    .subscribe((response) => {
      this.uploaded_files=response.data;
 
      this.uploaded_files_array.push(this.uploaded_files);

      response.data.forEach(element => {
      this.file_id_array.push(element.id);  
      });
        this.fileSuccess ='Uploaded successfully!!';
        // this.mainprogressbar = false;
    });
    }
    submit() {
  
      if(this.form.value.subject==''){
        this.toastr.error("Please select subject !!");
        return false;
      }
      if(this.form.value.project==''){
        this.toastr.error("Please select project!!");
        return false;
      }
      if(this.form.value.portal_id==''){
        this.toastr.error("Please select portal id!!");
        return false;
      }
      if(this.form.value.message==''){
        this.toastr.error("Please select message!!");
        return false;
      }
      if(this.form.value.issue_type==''){
        this.toastr.error("Please select issue type!!");
        return false;
      }

      if(this.file_id_array.length==0){
      this.toastr.error("Please Upload file");
      return false;
            }

      let ticketData = this.form.value;    
      ticketData.file_ids=this.file_id_array;
      ticketData.reference=null;
     
      this.projectsService.addTicket(ticketData)
      .subscribe((response) => {

        if(response){
        //   this.toastr.success("Ticket Created Successfully");
        //   window.location.href = '/pms/support';
        // }
        this.form.patchValue(
        {
          subject   : [''],
          project : [''],
          portal_id:[''],
          message:[''],
          issue_type:['']
        }
        );
        this.file_id_array=[];
        this.uploaded_files_array=[];
        this.fileSuccess=null;
        this.toastr.success("Ticket Created Successfully");
         this.getTicketDetails();
        }
      });
    }
}
