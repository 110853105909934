<!-- <div class="attachmentwrapper">  
  <a target="_blank" href="{{heroconversation.mediaurl}}" *ngFor="let heroconversation of peopleArray">
      <img src="{{heroconversation.mediaurl}}" class="img_test"/>
      <ng-template #nonimage><img src="./assets/preview-unavailable.jpg" height="200" style="display: block;clear: both;"/></ng-template>
  </a>
  </div> -->
  <div class="attachmentwrapper">  
    <div *ngFor="let heroconversation of peopleArray">
      <!-- <img *ngIf="heroconversation.mediacontentType=='image/jpeg'" src="{{heroconversation.mediaurl}}" class="img_test"/> -->
      <a *ngIf="heroconversation.mediacontentType=='image/jpeg'" target="_blank" href="{{heroconversation.mediaurl}}" >
        <img src="{{heroconversation.mediaurl}}" class="img_test"/>
      </a>
      <div *ngIf="heroconversation.mediacontentType=='application/pdf'" class="pdf-media">
        <a target="_blank" href="{{heroconversation.mediaurl}}" ><mat-icon class="s-60">picture_as_pdf</mat-icon></a>
      </div>
      <div class="video" *ngIf="heroconversation.mediacontentType=='video/mp4'">
        <video width="200" controls #videoPlayer>
            <source src="{{heroconversation.mediaurl}}" type="video/mp4" />
            Browser not supported
        </video>
      </div>
      <div class="audio" *ngIf="heroconversation.mediacontentType=='audio/mpeg' || heroconversation.mediacontentType=='audio/ogg'">
        <audio  width="200" controls>
            <source src="{{heroconversation.mediaurl}}" type="audio/mpeg" />
            Browser not supported
        </audio >
      </div>
    </div>
  </div>