import { Component, OnInit ,Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-edit-review-translator',
  templateUrl: './edit-review-translator.component.html',
  styleUrls: ['./edit-review-translator.component.scss']
})
export class EditReviewTranslatorComponent implements OnInit {

  type:string = 'Add';
  reviewForm: FormGroup;
  constructor(private _formBuilder: FormBuilder,private router:Router,
    private route:ActivatedRoute,
    ) { }

  ngOnInit() {
    this.reviewForm = this._formBuilder.group({
      
      firstName : [''],
     
  });
  }

  back(){
this.router.navigate(['approval']);
  }
}
