<div class="page-layout profilePage p-20 blank bg-white" fusePerfectScrollbar>
  <div class="white-box">
    <div class="winn">
      <mat-tab-group class="top-tabs" [(selectedIndex)]="selectIndex" (selectedTabChange)="tabChanged($event)">
        <mat-tab>
          <ng-template matTabLabel >
            <span [matBadge]="new_request_count"  matBadgeOverlap="false" matBadgeColor="accent">New Request</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id == 1" [StageID]="stage_id" [tabId]="0"></app-shared-listing>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span [matBadge]="inprogress_count" matBadgeOverlap="false" matBadgeColor="accent">In Progress</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id==2" [StageID]="stage_id" [tabId]="1"></app-shared-listing>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span [matBadge]="need_approval_count" matBadgeOverlap="false" matBadgeColor="accent">Need Approval</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id==9" [StageID]="stage_id" [tabId]="2"></app-shared-listing>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span [matBadge]="admin_approval_count" matBadgeOverlap="false" matBadgeColor="accent">Pending Admin Approval</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id==7" [StageID]="stage_id" [tabId]="3"></app-shared-listing>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span [matBadge]="archive_count" matBadgeOverlap="false" matBadgeColor="accent">Archive</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id == 0" [StageID]="stage_id" [tabId]="4"></app-shared-listing>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span [matBadge]="non_responsive_count" matBadgeOverlap="false" matBadgeColor="accent">Non responsive</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id==3" [StageID]="stage_id" [tabId]="5"></app-shared-listing>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span [matBadge]="closed_count" matBadgeOverlap="false" matBadgeColor="accent">Closed</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id == 0" [StageID]="stage_id" [tabId]="6"></app-shared-listing>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span [matBadge]="blacklisted_count" matBadgeOverlap="false" matBadgeColor="accent">Blacklisted</span>
          </ng-template>
          <app-shared-listing *ngIf="stage_id == 0" [StageID]="stage_id" [tabId]="7"></app-shared-listing>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>