<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
    <div class="white-box">
        <div class="tHeader">
            <h2>All Notifications</h2>
        </div>
        <div class="boxBody">
            <!-- <div class="p20" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="mr-8"> <i class="material-icons">filter_list</i>   Short by  </div>
                 <mat-form-field   fxFlex="20">
                   <mat-label>Notification Type</mat-label>
                   <mat-select>
                       <mat-option label="New" value="New">Quote Creation</mat-option>
                       <mat-option label="Sample" value="Sample">Project Assignmment</mat-option>
                       <mat-option label="Sample" value="Sample">User Login</mat-option>
                       <mat-option label="Sample" value="Sample">Payments</mat-option>
                       <mat-option label="Sample" value="Sample">Approval</mat-option>
                       <mat-option label="Sample" value="Sample">Promotion</mat-option>
                       <mat-option label="Sample" value="Sample">Subscription</mat-option>
                    </mat-select>
                 </mat-form-field>
       </div> -->
            <div class="notWraper">
                <div class="ntHeader">
                    <h3>
                        <span
                            [matBadge]="notification_count"
                            matBadgeColor="accent"
                            >Recent Notifications</span
                        >
                    </h3>
                </div>
                <div class="allMsg">
                    <div
                        class="msgRow munRead cBlue"
                        *ngFor="let res of notification_response"
                    >
                        <div fxFlex="10">
                            <p class="mTypeIcon">
                                <i class="material-icons">info</i>
                            </p>
                        </div>
                        <div fxFlex="75">
                            <p>
                                <b>{{ res }}</b>
                            </p>
                            <!-- <p>{{res.message}}</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[10,15,20,30,40,50,60,70,80,90,100]" (page)='paginateData($event)'>
        </mat-paginator>
    </div>
</div>
<!-- <div class="msgRow munRead cBlue">
            <div fxFlex="10"> 
                <p class="mTypeIcon"> <i class="material-icons">info</i></p>
            </div>
            <div fxFlex="75">
                <p><b>Translation Approved</b></p>
                <p>Mat Govin, has approved their Translation. Please assign this to translator</p>
            </div>
            <div fxFlex="15" class="text-right">
                <p class="mtime">1 min</p>
                <p class="mIconStat"><i class="material-icons"> check </i></p>
            </div>

        </div> -->
<!-- <div class="msgRow munRead">
            <div fxFlex="10">
                <p class="mTypeIcon"> <i class="material-icons">attach_money
                    </i></p>
            </div>
            <div fxFlex="75">
                <p><b>Translation Approved</b></p>
                <p>Mat Govin, has approved their Translation. Please assign this to translator</p>
            </div>
            <div fxFlex="15" class="text-right">
                <p class="mtime">2 hours</p>
                <p class="mIconStat"><i class="material-icons"> check </i></p>
            </div>

        </div> -->

<!-- <div class="msgRow munRead cYellow">
            <div fxFlex="10">
                <p class="mTypeIcon"> <i class="material-icons">shopping_cart
                    </i></p>
            </div>
            <div fxFlex="75">
                <p><b>New Translation Request</b></p>
                <p>Mat Govin, has approved their Translation. Please assign this to translator</p>
            </div>
            <div fxFlex="15" class="text-right">
                <p class="mtime">Yesterday</p>
                <p class="mIconStat"><i class="material-icons"> check </i></p>
            </div>

        </div> -->

<!-- <div class="msgRow munRead cGreen">
            <div fxFlex="10">
                <p class="mTypeIcon"> <i class="material-icons">file_copy
                    </i></p>
            </div>
            <div fxFlex="75">
                <p><b>Update Submitted</b></p>
                <p>Mat Govin, has approved their Translation. Please assign this to translator</p>
            </div>
            <div fxFlex="15" class="text-right">
                <p class="mtime">16 June</p>
                <p class="mIconStat"><i class="material-icons"> check </i></p>
            </div>

        </div> -->

<!-- <div class="msgRow mRead cRed">
            <div fxFlex="10">
                <p class="mTypeIcon"> <i class="material-icons">info</i></p>
            </div>
            <div fxFlex="75">
                <p><b>Quote Rejected</b></p>
                <p>Mat Govin, has rejected their quote. Please take further action.</p>
            </div>
            <div fxFlex="15" class="text-right">
                <p class="mtime">4 June</p>
                <p class="mIconStat"><i class="material-icons"> check </i></p>
            </div>

        </div> -->
