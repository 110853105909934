import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'app/main/Services/projects.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {
  Form:any;
  portalUsers:any=[];
  accountType:any=[];
  shipping_id:any=null;
  billing_id:any=null;
  accountAddress:any=[];
  constructor(
    private projectsService: ProjectsService,
    private toastr: ToastrService,private router: Router,private route: ActivatedRoute,
    private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getPortalUsers();
    this.getAccountType();
    this.Form = this._formBuilder.group({ 
      name: ['',Validators.compose([Validators.required])],
      email:['',Validators.compose([Validators.required,Validators.email])],
      company:[''],
      website: [''],
      office_phone: [''],
      account_type:['',Validators.compose([Validators.required])],
      sic_code:[''],
      assigned_to: [''],
      billing_street:[''],
      billing_city: [''],
      billing_state: [''],
      billing_country:[''],
      billing_zip_code:[''],
      shipping_street:[''],
      shipping_city: [''],
      shipping_state: [''],
      shipping_country:[''],
      shipping_zip_code:['']
  });

  }

getPortalUsers() {
  let Query = 'page=1&per_page=30&'
  this.projectsService.getPortalUsers(Query)
  .subscribe((res:any) => {
    if(res) {
      this.portalUsers=res.data;
    }
});
}

    // get Account Type
    getAccountType() {
      this.projectsService.getAccountType()
      .subscribe((res:any) => {
        if(res) {
          this.accountType=res.data;
        }
    });
  }

  submit(){

  this.accountAddress=[];
  let billing_address:any={
    id: this.billing_id,
    type: "Billing",
    street: this.Form.value.billing_street,
    city: this.Form.value.billing_city,
    state: this.Form.value.billing_state,
    postal_code: this.Form.value.billing_zip_code,
    country: this.Form.value.billing_country
  }

  let shipping_address:any={
    id: this.shipping_id,
    type: "Shipping",
    street: this.Form.value.shipping_street,
    city: this.Form.value.shipping_city,
    state: this.Form.value.shipping_state,
    postal_code: this.Form.value.shipping_zip_code,
    country: this.Form.value.shipping_country
}

if(this.Form.value.billing_street!='' || this.Form.value.billing_city!='' || this.Form.value.billing_state!=''
|| this.Form.value.billing_zip_code!='' || this.Form.value.billing_country!=''){
  this.accountAddress.push(billing_address);
}

if(this.Form.value.shipping_street!='' || this.Form.value.shipping_city!='' || this.Form.value.shipping_state!=''
|| this.Form.value.shipping_zip_code!='' || this.Form.value.shipping_country!=''){
  this.accountAddress.push(shipping_address);
}

  let final_data:any={
      account_type: this.Form.value.account_type,
      alliance_discount: '',
      name: this.Form.value.name,
      email: this.Form.value.email,
      industry_id: '',
      company: this.Form.value.company,
      company_logo: '',
      office_phone: this.Form.value.office_phone,
      website: this.Form.value.website,
      employees_count:  '',
      annual_revenue: '',
      sic_code: this.Form.value.sic_code,
      rating: '',
      crm_ref_id: '',
      assigned_to: this.Form.value.assigned_to,
      created_by: '',
      account_address: this.accountAddress
  };

this.projectsService.addAccount(final_data)
.subscribe((response) => {
    if(response) {
      this.toastr.success("Account Details added!!");
      this.router.navigate(['/pms/account']);
    }
});
}
}
