import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit {
  //  ckeditorContent: string;
  //  subject: string;
  //  to: string;
  //  cc: string;
   bcc: string;
   config: any;
   project_id: string;
   Form: FormGroup;
  constructor( private dialogRef: MatDialogRef<EmailPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public quote_data :any,private projectsService: ProjectsService,
    private toastr: ToastrService,private router: Router,private route: ActivatedRoute,
    private _formBuilder: FormBuilder) { }

  Onclose(){
    this.dialogRef.close();
}


  ngOnInit() {
    this.project_id = this.route.snapshot.params['id'];
    this.config = {
       width: "1000px",
       height: '338px',
       toolbar : [
        { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
        { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
        { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
        '/',
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
        { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
        '/',
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        { name: 'about', items: [ 'About' ] }
  
      ]
      // toolbar: [
      //     { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
      //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }
      // ]
  };
  this.Form = this._formBuilder.group({ 

    to: ['',Validators.compose([Validators.required, Validators.email])],
    cc:['',Validators.compose([ Validators.email])],
    bcc:['contact@daytranslations.com',Validators.compose([ Validators.email])],
    subject: ['', Validators.compose([Validators.required])],
    ckeditorContent: [''],
});

  this.get_quote_email();
  }

  
 

  updateMessage(){
    if(  this.Form.value.subject==''){
      this.toastr.error("Subject never be empty!!");
      return;
    }

    if(  this.Form.value.ckeditorContent==''){
      this.toastr.error("Body never be empty!!");
      return;
    }
    let data:any={
      mail_response:{}
    };
    

    let mail_response:any={
      to:'',
      cc:'',
       bcc:'',
      message:'',
      subject:'',
     

    }
    mail_response.to =  this.Form.value.to;
    mail_response.cc=  this.Form.value.cc;
    mail_response.bcc=  this.Form.value.bcc;
    mail_response.message=  this.Form.value.ckeditorContent;
    mail_response.subject=  this.Form.value.subject;
    data=this.quote_data;
    data.mail_response=mail_response;

      this.projectsService.sendProjectQuote(data)
          .subscribe((response) => {
              if(response) {
                this.router.navigate(['/pms/project/details/'+this.quote_data.project_id]);
                this.toastr.success("Quote Created");
                this.Onclose();
              }
          }, (error) => {
              this.toastr.error(error.message);
          });
  }

  
  get_quote_email(){    
    this.projectsService.get_quote_email(this.quote_data)
          .subscribe((response) => {
              if(response) {
              //   this.Form.value.ckeditorContent= response.mail_response.message;
              //  this.Form.value.subject=response.mail_response.subject;
              //  this.Form.value.to=response.mail_response.To;
              this.Form.patchValue({
                ckeditorContent: response.mail_response.message,
                subject: response.mail_response.subject,
                to: response.mail_response.To,
              });
          
        
              }
          }, (error) => {
              this.toastr.error(error.message);
           
          });
  }


}
