import { OnInit } from '@angular/core';
var ELEMENT_DATA = [
    { position: 1, addServTitle: 'Notorization', addServDesc: 'Document Translation' },
    { position: 2, addServTitle: ' Apostile', addServDesc: 'Attestation/Apostile of Documents and Implementation of the  Apostile' },
];
var AdditionalServicesMasterComponent = /** @class */ (function () {
    function AdditionalServicesMasterComponent() {
        this.displayedColumns = ['position', 'addServTitle', 'addServDesc', 'action'];
        this.dataSource = ELEMENT_DATA;
    }
    AdditionalServicesMasterComponent.prototype.ngOnInit = function () {
    };
    return AdditionalServicesMasterComponent;
}());
export { AdditionalServicesMasterComponent };
