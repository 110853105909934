<div class="dialog-content-wrapper"  style="position:relative;">
  <div class="tHeader">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
  <div fxFlex>
      <h4 class="m0 title dialog-title text-uppercase">Update Account</h4>
    </div>
    <div fxFlex class="align-right">
        <button mat-icon-button (click)="Onclose()"  matTooltip="Close" aria-label="Close dialog">
               <mat-icon>close</mat-icon>
        </button>
      </div>
      </div>
      </div>

<form  [formGroup]="Form">
<div  class="p-16 pt-4 m-0" >
    
    <div fxLayout="column" fxLayoutAlign="start" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="10px">
            <mat-form-field   fxFlex="24">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
                <mat-error *ngIf="Form.get('name').hasError('required')">
                        name is required!
                </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="28">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" readonly>
            </mat-form-field>
         <mat-form-field   fxFlex="20">
              <mat-label>Company</mat-label>
              <input matInput formControlName="company">
          </mat-form-field> 
          <mat-form-field   fxFlex="24">
            <mat-label>Website</mat-label>
            <input matInput formControlName="website">
        </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">
            <mat-form-field   fxFlex="24">
                <mat-label>Office Phone</mat-label>
                <input matInput formControlName="office_phone">
            </mat-form-field>
            <mat-form-field   fxFlex="24">
                <mat-label>Account Type</mat-label>
                <mat-select formControlName="account_type">
                        <mat-option value="">
                          Select
                        </mat-option>
                        <mat-option *ngFor="let res of accountType" [value]="res?.slug">{{res?.name}}</mat-option>
            
            
                      </mat-select>
            </mat-form-field>
            <mat-form-field   fxFlex="24">
                <mat-label>SIC code</mat-label>
                <input matInput formControlName="sic_code">
            </mat-form-field>
            <mat-form-field   fxFlex="24">
                <mat-label>Assigned to</mat-label>
                <mat-select formControlName="assigned_to">
                        <mat-option value="">
                          Select
                        </mat-option>
                        <mat-option *ngFor="let res of portalUsers" [value]="res?.user_name">{{res?.user_name}}</mat-option>
            
                      </mat-select>
            </mat-form-field>
        </div>

        <!-- Billing address start -->
        <p ><b>Billing Address</b></p>
        <div class="newAdd" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" >
          <mat-form-field   fxFlex="90">
            <mat-label>Street Address </mat-label>
            <input matInput formControlName="billing_street">
          </mat-form-field>

          <mat-form-field  >
            <mat-label>City </mat-label>
            <input matInput formControlName="billing_city">
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>State</mat-label>
            <input matInput formControlName="billing_state">
          </mat-form-field>
          <mat-form-field   fxFlex="20">
            <mat-label>Country </mat-label>
            <input matInput formControlName="billing_country">
          </mat-form-field>

          <mat-form-field   fxFlex="20">
            <mat-label>Zip Code</mat-label>
            <input matInput formControlName="billing_zip_code">
          </mat-form-field>

        </div>
        <!-- Billing address end -->

         <!-- Shipping address start -->
         <p ><b>Shipping Address</b></p>
         <div class="newAdd" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" >
           <mat-form-field   fxFlex="90">
             <mat-label>Street Address </mat-label>
             <input matInput formControlName="shipping_street">
           </mat-form-field>
 
           <mat-form-field  >
             <mat-label>City </mat-label>
             <input matInput formControlName="shipping_city">
           </mat-form-field>
 
           <mat-form-field   fxFlex="20">
             <mat-label>State</mat-label>
             <input matInput formControlName="shipping_state">
           </mat-form-field>
           <mat-form-field   fxFlex="20">
             <mat-label>Country *</mat-label>
             <input matInput formControlName="shipping_country">
           </mat-form-field>
 
           <mat-form-field   fxFlex="20">
             <mat-label>Zip Code</mat-label>
             <input matInput formControlName="shipping_zip_code">
           </mat-form-field>
 
         </div>
         <!-- Shipping address end -->
  
      
      <div fxLayout="column" class="mt-8"  fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxFlex="100" >
        <button mat-stroked-button color="warn" (click)="Onclose()" >Cancel</button>
        <button mat-raised-button color="accent" (click)="update()" [disabled]="Form.invalid">Update</button>
  
      </div>
    </div>

  </div>
</form>
</div>