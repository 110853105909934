import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var LoginGuard = /** @class */ (function () {
    function LoginGuard(router) {
        this.router = router;
    }
    LoginGuard.prototype.canActivate = function (route, state) {
        if (SharedService.get(SharedService.LOGIN) === null) {
            return true;
        }
        else if (SharedService.get(SharedService.LOGIN) == 'true') {
            return true;
        }
    };
    LoginGuard.ngInjectableDef = i0.defineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.inject(i1.Router)); }, token: LoginGuard, providedIn: "root" });
    return LoginGuard;
}());
export { LoginGuard };
