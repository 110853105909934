<div class="page-layout w100 p-24 bg-white" fusePerfectScrollbar>
  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
    <div fxFlex="30">
      <div class="white-box plainBox">
        <div class="tHeader">
          <h2><i class="material-icons">filter_list</i> Sort by</h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <form name="Form" [formGroup]="Form">
              <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">

                <div fxFlex="79">
                  <mat-form-field>
                    <mat-label>Search by ID/Name/Email/Phone</mat-label>
                    <input matInput formControlName="search_value">
                  </mat-form-field>
                </div>
                <div fxFlex="20">
                  <button mat-stroked-button color="primary" (click)="submit()" [disabled]="Form.invalid"><i
                      class="material-icons">search</i></button>
                </div>

              </div>
            </form>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Status is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'status')">
                    <mat-option value="">Any</mat-option>
                    <mat-option value="1">Active</mat-option>
                    <mat-option value="2">Inactive</mat-option>
                    <mat-option value="3">Potential</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Category is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'category')">
                    <mat-option value="">Any</mat-option>
                    <mat-option value="1">DayVIP</mat-option>
                    <mat-option value="2">Company</mat-option>
                    <mat-option value="3">Individual</mat-option>
                    <mat-option value="4">New Client</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Payment Method is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'payment_method')">
                    <mat-option value="">Any</mat-option>
                    <mat-option value="1">NET30</mat-option>
                    <mat-option value="2">NET45</mat-option>
                    <mat-option value="3">Regular/Upfront</mat-option>
                    <mat-option value="4">Credit Available</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Location</b> </div>
              </div>
              <!-- <div fxFlex="30">
                        <mat-form-field  >
                            <mat-select placeholder="City" (selectionChange)="onFilter($event,'city')">
                              <mat-option value="">All</mat-option>
                              <mat-option value="New York">New York</mat-option>
                              <mat-option value="Florida">Florida</mat-option>
                              <mat-option value="Denver">Denver</mat-option>
                              <mat-option value="Basha">Basha</mat-option>
                            </mat-select>
                          </mat-form-field>
                      </div> -->
              <div fxFlex="30">
                <mat-form-field>
                  <input matInput (keyup)="onFilter($event.target.value,'city')">
                </mat-form-field>
              </div>
              <div fxFlex="39">
                <mat-form-field>
                  <mat-select placeholder="Country" (selectionChange)="onFilter($event,'country')">
                    <mat-option value="">All</mat-option>
                    <mat-option value="USA">USA</mat-option>
                    <mat-option value="China">China</mat-option>
                    <mat-option value="Germany">Germany</mat-option>
                    <mat-option value="France">France</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Customer Added</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <input matInput (click)="dp2.open()" [matDatepicker]="dp2"
                    (dateChange)="onFilter($event,'start_date')">
                  <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                  <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>


            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Select</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <input matInput (click)="dp1.open()" [matDatepicker]="dp1" (dateChange)="onFilter($event,'end_date')">
                  <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                  <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>


            </div>



            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Project Manager involved</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <mat-select placeholder="Select Field">
                    <!-- (selectionChange)="onFilter($event,'ProjectManager')" -->
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let res of projectmanager" [value]="res">
                      {{res}}

                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Lead Source</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <mat-select (selectionChange)="onFilter($event,'lead_source')" placeholder="Select Field">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let res of projectLeadsource" [value]="res.id">
                      {{res.name}}

                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Source Language is</b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <mat-select placeholder="Select Field">
                    <!-- (selectionChange)="onFilter($event,'s_lang')"  -->
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="30">
                <div class="text-right"><b>Target Language is </b> </div>
              </div>
              <div fxFlex="69">
                <mat-form-field>
                  <mat-select placeholder="Select Field">
                    <!-- (selectionChange)="onFilter($event,'t_lang')" -->
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>

    <div fxFlex="68">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap">
            <div fxFlex>
              <h2>Browse Customer</h2>
            </div>
            <div fxFlex class="text-right">
              Total <b>{{total_customer}}</b> Records Available
            </div>

          </div>
          <div class="filterTag pt-12">

            <mat-chip-list #chipList aria-label="QuotesChip selection">
              <mat-chip *ngFor="let QuotesChip of fChipsArray" [selectable]="selectable" [removable]="removable">
                {{QuotesChip}}

              </mat-chip>
            </mat-chip-list>


          </div>
        </div>
        <div class="boxBody">
          <div class="boxInn">
            <table mat-table [dataSource]="dataSource" class="table">
              <!-- Name Column -->
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="16"> ID</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="16">
                  <p class="text-truncate">{{product?.reference_id}}</p>
                </mat-cell>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12"> Name</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="12">
                  <p class="text-truncate">
                    <a routerLink="/pms/customer/details/{{ product.uuid }}">{{product.first_name}}
                      {{product.last_name}}</a>

                </mat-cell>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="cust_email">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="16"> Email</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="16">
                  <p class="text-truncate">{{product.email_primary}}</p>

                </mat-cell>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="savedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12">Added</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="12">
                  <p class="text-truncate">{{product.created_at.date | date: 'MM/dd/yyyy'}}</p>
                  <!-- yyyy-MM-dd -->

                </mat-cell>
              </ng-container>



              <!-- Weight Column -->
              <ng-container matColumnDef="paymentMethod">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> Payment Method</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="8">
                  <p class="text-truncate" *ngIf="product?.payment_method==''"></p>
                  <p class="text-truncate" *ngIf="product?.payment_method==1">NET30</p>
                  <p class="text-truncate" *ngIf="product?.payment_method==2">NET45</p>
                  <p class="text-truncate" *ngIf="product?.payment_method==3">Regular/Upfront</p>
                  <p class="text-truncate" *ngIf="product?.payment_method==4">Credit Available</p>

                </mat-cell>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> Status</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="8">

                  <p class="text-truncate" *ngIf="product?.status==''"></p>
                  <p class="text-truncate" *ngIf="product?.status==1">Active</p>
                  <p class="text-truncate" *ngIf="product?.status==2">Inactive</p>
                  <p class="text-truncate" *ngIf="product?.status==3">Potential</p>

                </mat-cell>
              </ng-container>
              <!-- Symbol Column -->

              <ng-container matColumnDef="addressCountry">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> Address Country</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="8">
                  <p class="text-truncate">{{product?.customeraddress?.data?.address1}}</p>
                </mat-cell>
              </ng-container>

              <!-- Symbol Column -->

              <ng-container matColumnDef="categories">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> Categories</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="8">

                  <p class="text-truncate" *ngIf="product?.category==''"></p>
                  <p class="text-truncate" *ngIf="product?.category==1">DayVIP</p>
                  <p class="text-truncate" *ngIf="product?.category==2">Company</p>
                  <p class="text-truncate" *ngIf="product?.category==3">Individual</p>
                  <p class="text-truncate" *ngIf="product?.category==3">New Client</p>


                </mat-cell>
              </ng-container>


              <!-- Symbol Column -->

              <ng-container matColumnDef="leadSource">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> Lead Source</mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="8">
                  <p class="text-truncate">{{product?.source?.data?.name}}</p>
                </mat-cell>
              </ng-container>

              <!-- Symbol Column -->

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8"> </mat-header-cell>
                <mat-cell *matCellDef="let product" fxFlex="8">
                  <!-- <p class="text-truncate">{{product?.deadline_date?.date}}</p> -->
                </mat-cell>
              </ng-container>



              <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

              <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
              </mat-row>
            </table>

            <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
              [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
              [pageSizeOptions]="[5,10,20,30,40,50,60]" (page)='paginateData($event)'>
            </mat-paginator>
          </div>
        </div>
      </div>

    </div>


  </div>



</div>