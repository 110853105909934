import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProjectsService } from 'app/main/Services/projects.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { Router } from '@angular/router';
var ProjectReleaseComponent = /** @class */ (function () {
    function ProjectReleaseComponent(_formBuilder, vendorService, toastr, projectsService, snackBar, dialogRef, router, data) {
        this._formBuilder = _formBuilder;
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.projectsService = projectsService;
        this.snackBar = snackBar;
        this.dialogRef = dialogRef;
        this.router = router;
        this.data = data;
    }
    ProjectReleaseComponent.prototype.ngOnInit = function () {
        this.form = this._formBuilder.group({
            message: ['', Validators.compose([Validators.required])]
        });
    };
    ProjectReleaseComponent.prototype.Onclose = function () {
        this.dialogRef.close();
    };
    // Submit
    ProjectReleaseComponent.prototype.submit = function () {
        var _this = this;
        var ReleaseData = {
            message: '',
            project_id: ''
        };
        ReleaseData = this.form.value;
        ReleaseData.project_id = this.data.project_id;
        this.projectsService.projectRelease(ReleaseData)
            .subscribe(function (response) {
            _this.toastr.success("Project Release Successfully");
            _this.router.navigate(['/pms/dashboard']);
            _this.dialogRef.close(true);
        });
    };
    return ProjectReleaseComponent;
}());
export { ProjectReleaseComponent };
