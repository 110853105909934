import { OnInit } from '@angular/core';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';
var SharedListingComponent = /** @class */ (function () {
    function SharedListingComponent(vendorService, toastr, router) {
        this.vendorService = vendorService;
        this.toastr = toastr;
        this.router = router;
        this.paginate1 = {};
        this.pageIndex = 1;
        this.pageSize = 10;
        this.query = '';
        this.ids_progress = [];
        this.send_email_progress_data = {};
        this.bulk_selected_progress = false;
        this.non_responsive = true;
        this.login_enable = true;
        this.languageResponse = [];
        this.specialityResponse = [];
        this.fQuery = '';
        this.filterSpecialization = '';
        this.filtercategoryLevel = '';
        this.filter_src_lang = '';
        this.filter_target_lang = '';
        this.filter_native_lang = null;
        this.fdata = {
            category: '',
            Source: '',
            Target: '',
            Native: '',
        };
        this.displayedColumns = [
            'ref',
            'first_name',
            'category_level',
            'native_language',
            'language',
            'email',
            'sent_date',
        ];
    }
    SharedListingComponent.prototype.ngOnInit = function () {
        this.getLanguage();
        this.getSpeciality();
        this.getData();
    };
    SharedListingComponent.prototype.getData = function () {
        var _this = this;
        console.log(this.tabId);
        var query = '';
        if (this.StageID == 2) {
            this.StageID = '2,3,4';
            this.in_progress_tab = 1;
            //this.in_progress_tab = 5;
        }
        if (this.StageID == 3) {
            this.StageID = '2,3,4';
            this.in_progress_tab = 5;
        }
        if (this.StageID == 1) {
            var newRequestPagination = localStorage.getItem('newRequestPagination');
            var objectPagination = JSON.parse(newRequestPagination);
            var newRequestFilter = localStorage.getItem('newRequestFilter');
            var newRequestObject = JSON.parse(newRequestFilter);
            if (newRequestFilter != null &&
                newRequestObject.table_name == 'translatorInNewRequest') {
                if (newRequestObject.category !== '') {
                    this.fdata.category = newRequestObject.category;
                }
                if (newRequestObject.source !== '') {
                    this.fdata.Source = newRequestObject.source;
                }
                if (newRequestObject.target !== '') {
                    this.fdata.Target = newRequestObject.target;
                }
                if (newRequestObject.native !== null) {
                    this.fdata.Native = newRequestObject.native;
                }
                query = newRequestObject.query_string;
            }
            else if (objectPagination !== null) {
                query = '?page=' + objectPagination.page + '&per_page=' + objectPagination.per_page;
            }
            else {
                query = '?page=1&per_page=10';
            }
        }
        if (this.in_progress_tab == 1) {
            var inProgressPagination = localStorage.getItem('inProgressPagination');
            var objectPagination = JSON.parse(inProgressPagination);
            var inProgressFilter = localStorage.getItem('inProgressFilter');
            var inProgressObject = JSON.parse(inProgressFilter);
            if (inProgressFilter != null &&
                inProgressObject.table_name == 'translatorInProgress') {
                if (inProgressObject.category !== '') {
                    this.fdata.category = inProgressObject.category;
                }
                if (inProgressObject.source !== '') {
                    this.fdata.Source = inProgressObject.source;
                }
                if (inProgressObject.target !== '') {
                    this.fdata.Target = inProgressObject.target;
                }
                if (inProgressObject.native !== null) {
                    this.fdata.Native = inProgressObject.native;
                }
                query = inProgressObject.query_string;
            }
            else if (objectPagination !== null) {
                query = '?page=' + objectPagination.page + '&per_page=' + objectPagination.per_page;
            }
            else {
                query = '?page=1&per_page=10';
            }
        }
        if (this.StageID == 9) {
            var needApprovalPagination = localStorage.getItem('needApprovalPagination');
            var objectPagination = JSON.parse(needApprovalPagination);
            var needApprovalFilter = localStorage.getItem('needApprovalFilter');
            var needApprovalObject = JSON.parse(needApprovalFilter);
            if (needApprovalFilter != null &&
                needApprovalObject.table_name == 'translatorNeedApproval') {
                if (needApprovalObject.category !== '') {
                    this.fdata.category = needApprovalObject.category;
                }
                if (needApprovalObject.source !== '') {
                    this.fdata.Source = needApprovalObject.source;
                }
                if (needApprovalObject.target !== '') {
                    this.fdata.Target = needApprovalObject.target;
                }
                if (needApprovalObject.native !== null) {
                    this.fdata.Native = needApprovalObject.native;
                }
                query = needApprovalObject.query_string;
            }
            else if (objectPagination !== null) {
                query = '?page=' + objectPagination.page + '&per_page=' + objectPagination.per_page;
            }
            else {
                query = '?page=1&per_page=10';
            }
        }
        if (this.StageID == 7) {
            var pendingAdminApprovalPagination = localStorage.getItem('pendingAdminApprovalPagination');
            var objectPagination = JSON.parse(pendingAdminApprovalPagination);
            var pendingAdminApprovalFilter = localStorage.getItem('pendingAdminApprovalFilter');
            var pendingAdminApprovalObject = JSON.parse(pendingAdminApprovalFilter);
            if (pendingAdminApprovalFilter != null &&
                pendingAdminApprovalObject.table_name ==
                    'translatorPendingAdminApproval') {
                if (pendingAdminApprovalObject.category !== '') {
                    this.fdata.category = pendingAdminApprovalObject.category;
                }
                if (pendingAdminApprovalObject.source !== '') {
                    this.fdata.Source = pendingAdminApprovalObject.source;
                }
                if (pendingAdminApprovalObject.target !== '') {
                    this.fdata.Target = pendingAdminApprovalObject.target;
                }
                if (pendingAdminApprovalObject.native !== null) {
                    this.fdata.Native = pendingAdminApprovalObject.native;
                }
                query = pendingAdminApprovalObject.query_string;
            }
            else if (objectPagination !== null) {
                query = '?page=' + objectPagination.page + '&per_page=' + objectPagination.per_page;
            }
            else {
                query = '?page=1&per_page=10';
            }
        }
        if (this.StageID == 0) {
            var archivePagination = localStorage.getItem('archivePagination');
            var objectPagination = JSON.parse(archivePagination);
            var archiveFilter = localStorage.getItem('archiveFilter');
            var archiveObject = JSON.parse(archiveFilter);
            if (archiveFilter != null && archiveObject.table_name == 'Archive') {
                if (archiveObject.category !== '') {
                    this.fdata.category = archiveObject.category;
                }
                if (archiveObject.source !== '') {
                    this.fdata.Source = archiveObject.source;
                }
                if (archiveObject.target !== '') {
                    this.fdata.Target = archiveObject.target;
                }
                if (archiveObject.native !== null) {
                    this.fdata.Native = archiveObject.native;
                }
                query =
                    archiveObject.query_string +
                        '&archive=Unavailable,Blacklisted,Closed,Leader,Probation,Junior';
            }
            else if (objectPagination !== null) {
                query =
                    '?page=' +
                        objectPagination.page +
                        '&per_page=' +
                        objectPagination.per_page +
                        '&archive=Unavailable,Blacklisted,Closed,Leader,Probation,Junior';
            }
            else {
                query =
                    '?page=1&per_page=10' +
                        '&archive=Unavailable,Blacklisted,Closed,Leader,Probation,Junior';
            }
        }
        if (this.in_progress_tab == 5) {
            var nonResponsivePagination = localStorage.getItem('nonResponsivePagination');
            var objectPagination = JSON.parse(nonResponsivePagination);
            var nonResponsiveFilter = localStorage.getItem('nonResponsiveFilter');
            var nonResponsiveObject = JSON.parse(nonResponsiveFilter);
            if (nonResponsiveFilter != null &&
                nonResponsiveObject.table_name == 'translatorNonResponsive') {
                if (nonResponsiveObject.category !== '') {
                    this.fdata.category = nonResponsiveObject.category;
                }
                if (nonResponsiveObject.source !== '') {
                    this.fdata.Source = nonResponsiveObject.source;
                }
                if (nonResponsiveObject.target !== '') {
                    this.fdata.Target = nonResponsiveObject.target;
                }
                if (nonResponsiveObject.native !== null) {
                    this.fdata.Native = nonResponsiveObject.native;
                }
                query = nonResponsiveObject.query_string;
            }
            else if (objectPagination !== null) {
                query = '?page=' + objectPagination.page + '&per_page=' + objectPagination.per_page + '&non_responsive=' + this.non_responsive;
            }
            else {
                query = '?page=1&per_page=10&non_responsive=' + this.non_responsive;
            }
        }
        if (this.tabId == 6 && this.StageID == 0) {
            var closedPagination = localStorage.getItem('closedPagination');
            var objectPagination = JSON.parse(closedPagination);
            var closedFilter = localStorage.getItem('closedFilter');
            var closedObject = JSON.parse(closedFilter);
            if (closedFilter != null &&
                closedObject.table_name == 'translatorClosed') {
                if (closedObject.category !== '') {
                    this.fdata.category = closedObject.category;
                }
                if (closedObject.source !== '') {
                    this.fdata.Source = closedObject.source;
                }
                if (closedObject.target !== '') {
                    this.fdata.Target = closedObject.target;
                }
                if (closedObject.native !== null) {
                    this.fdata.Native = closedObject.native;
                }
                query = closedObject.query_string;
            }
            else if (objectPagination !== null) {
                query = '?page=' + objectPagination.page + '&per_page=' + objectPagination.per_page + '&archive=Closed';
            }
            else {
                query = '?page=1&per_page=10&archive=Closed';
            }
        }
        if (this.tabId == 7 && this.StageID == 0) {
            var blacklistedPagination = localStorage.getItem('blacklistedPagination');
            var objectPagination = JSON.parse(blacklistedPagination);
            var blacklistedFilter = localStorage.getItem('blacklistedFilter');
            var blacklistedObject = JSON.parse(blacklistedFilter);
            if (blacklistedFilter != null &&
                blacklistedObject.table_name == 'translatorBlacklisted') {
                if (blacklistedObject.category !== '') {
                    this.fdata.category = blacklistedObject.category;
                }
                if (blacklistedObject.source !== '') {
                    this.fdata.Source = blacklistedObject.source;
                }
                if (blacklistedObject.target !== '') {
                    this.fdata.Target = blacklistedObject.target;
                }
                if (blacklistedObject.native !== null) {
                    this.fdata.Native = blacklistedObject.native;
                }
                query = blacklistedObject.query_string;
            }
            else if (objectPagination !== null) {
                query = '?page=' + objectPagination.page + '&per_page=' + objectPagination.per_page + '&archive=Blacklisted';
            }
            else {
                query = '?page=1&per_page=10&archive=Blacklisted';
            }
        }
        this.vendorService.getHrDashboardListing(query, this.StageID, 'marketing').subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ', ';
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value1 = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang =
                            res.sourcelanguage.data.lang_name +
                                res.bi_direction +
                                res.targetlanguage.data.lang_name +
                                ',';
                        lang_array.push(lang);
                    });
                    elem.check = false;
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    SharedListingComponent.prototype.paginateData = function (event) {
        var _this = this;
        var aQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query = '?page=' + this.pageIndex + '&per_page=' + this.pageSize;
        // test
        if (this.in_progress_tab == 1) {
            var pagination_1 = { table_name: 'translatorInProgress', page: this.pageIndex, per_page: this.pageSize, tabIndex: 1 };
            SharedService.set(SharedService.pagination, JSON.stringify(pagination_1));
        }
        if (this.StageID == 9) {
            var pagination_2 = { table_name: 'translatorNeedApproval', page: this.pageIndex, per_page: this.pageSize, tabIndex: 2 };
            SharedService.set(SharedService.pagination, JSON.stringify(pagination_2));
        }
        if (this.StageID == 7) {
            var pagination_3 = { table_name: 'translatorPendingAdminApproval', page: this.pageIndex, per_page: this.pageSize, tabIndex: 3 };
            SharedService.set(SharedService.pagination, JSON.stringify(pagination_3));
        }
        if (this.StageID == 0) {
            var pagination_4 = { table_name: 'Archive', page: this.pageIndex, per_page: this.pageSize, tabIndex: 4 };
            SharedService.set(SharedService.pagination, JSON.stringify(pagination_4));
        }
        if (this.in_progress_tab == 1) {
            var pagination = {
                table_name: 'translatorInProgress',
                page: this.pageIndex,
                per_page: this.pageSize,
                tabIndex: 1,
            };
            SharedService.set(SharedService.inProgressPagination, JSON.stringify(pagination));
        }
        if (this.StageID == 9) {
            var pagination = {
                table_name: 'translatorNeedApproval',
                page: this.pageIndex,
                per_page: this.pageSize,
                tabIndex: 2,
            };
            SharedService.set(SharedService.needApprovalPagination, JSON.stringify(pagination));
        }
        if (this.StageID == 7) {
            var pagination = {
                table_name: 'translatorPendingAdminApproval',
                page: this.pageIndex,
                per_page: this.pageSize,
                tabIndex: 3,
            };
            SharedService.set(SharedService.pendingAdminApprovalPagination, JSON.stringify(pagination));
        }
        if (this.StageID == 0) {
            var pagination = {
                table_name: 'Archive',
                page: this.pageIndex,
                per_page: this.pageSize,
                tabIndex: 4,
            };
            SharedService.set(SharedService.archivePagination, JSON.stringify(pagination));
        }
        if (this.in_progress_tab == 5) {
            var pagination = {
                table_name: 'translatorNonResponsive',
                page: this.pageIndex,
                per_page: this.pageSize,
                tabIndex: 5,
            };
            SharedService.set(SharedService.nonResponsivePagination, JSON.stringify(pagination));
        }
        if (this.tabId == 6) {
            var pagination = {
                table_name: 'translatorClosed',
                page: this.pageIndex,
                per_page: this.pageSize,
                tabIndex: 6,
            };
            SharedService.set(SharedService.closedPagination, JSON.stringify(pagination));
        }
        if (this.tabId == 7) {
            var pagination = {
                table_name: 'translatorBlacklisted',
                page: this.pageIndex,
                per_page: this.pageSize,
                tabIndex: 7,
            };
            SharedService.set(SharedService.blacklistedPagination, JSON.stringify(pagination));
        }
        if (this.filtercategoryLevel !== '') {
            aQuery += '&category_level=' + this.filtercategoryLevel;
        }
        else if (this.fdata.category) {
            aQuery += '&category_level=' + this.fdata.category;
            this.filtercategoryLevel = this.fdata.category;
        }
        if (this.filter_src_lang !== '') {
            aQuery += '&source_lang=' + this.filter_src_lang;
        }
        else if (this.fdata.Source) {
            aQuery += '&source_lang=' + this.fdata.Source;
            this.filter_src_lang = this.fdata.Source;
        }
        if (this.filter_target_lang !== '') {
            aQuery += '&target_lang=' + this.filter_target_lang;
        }
        else if (this.fdata.Target) {
            aQuery += '&target_lang=' + this.fdata.Target;
            this.filter_target_lang = this.fdata.Target;
        }
        if (this.filter_native_lang !== null) {
            aQuery += '&native_lang=' + this.filter_native_lang;
        }
        else if (this.fdata.Native) {
            aQuery += '&native_lang=' + this.fdata.Native;
            this.filter_native_lang = this.fdata.Native;
        }
        var filter;
        this.query += aQuery;
        if (this.StageID == 1) {
            filter = {
                table_name: 'translatorInNewRequest',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.newRequestFilter, JSON.stringify(filter));
        }
        if (this.in_progress_tab == 1) {
            filter = {
                table_name: 'translatorInProgress',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.inProgressFilter, JSON.stringify(filter));
        }
        if (this.StageID == 9) {
            filter = {
                table_name: 'translatorNeedApproval',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.needApprovalFilter, JSON.stringify(filter));
        }
        if (this.StageID == 7) {
            filter = {
                table_name: 'translatorPendingAdminApproval',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.pendingAdminApprovalFilter, JSON.stringify(filter));
        }
        if (this.StageID == 0) {
            filter = {
                table_name: 'Archive',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.archiveFilter, JSON.stringify(filter));
        }
        if (this.in_progress_tab == 5) {
            this.query += '&non_responsive=' + this.non_responsive;
            filter = {
                table_name: 'translatorNonResponsive',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.nonResponsiveFilter, JSON.stringify(filter));
        }
        if (this.tabId == 6 && this.StageID == 0) {
            this.query += '&archive=Closed';
            filter = {
                table_name: 'translatorClosed',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.nonResponsiveFilter, JSON.stringify(filter));
        }
        if (this.tabId == 7 && this.StageID == 0) {
            this.query += '&archive=Blacklisted';
            filter = {
                table_name: 'translatorBlacklisted',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.query,
            };
            SharedService.set(SharedService.nonResponsiveFilter, JSON.stringify(filter));
        }
        if (this.StageID == 2) {
            this.StageID = '2,3,4';
        }
        if (this.StageID == 0 && this.tabId == 4) {
            this.query += '&archive=Unavailable,Blacklisted,Closed,Leader,Probation,Junior';
        }
        this.vendorService
            .getHrDashboardListing(this.query, this.StageID, 'marketing')
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ', ';
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value1 = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 +=
                                res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 +=
                                res.translation_rate;
                        }
                        lang =
                            res.sourcelanguage.data.lang_name +
                                res.bi_direction +
                                res.targetlanguage.data.lang_name +
                                ',';
                        lang_array.push(lang);
                    });
                    elem.check = false;
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    // On filters
    SharedListingComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10';
        if (type === 'categoryLevel' &&
            evt.value !== '' &&
            evt.value !== undefined) {
            this.filtercategoryLevel = evt.value;
            this.fQuery += '&category_level=' + this.filtercategoryLevel;
        }
        else if (type === 'categoryLevel' && evt.value === '') {
            this.filtercategoryLevel = evt.value;
        }
        else if (this.filtercategoryLevel !== '') {
            this.fQuery += '&category_level=' + this.filtercategoryLevel;
        }
        else if (this.fdata.category) {
            this.fQuery += '&category_level=' + this.fdata.category;
        }
        if (type === 's_lang' && evt.value !== '' && evt.value !== undefined) {
            this.filter_src_lang = evt.value;
            this.fQuery += '&source_lang=' + this.filter_src_lang;
        }
        else if (type === 's_lang' && evt.value === '') {
            this.filter_src_lang = evt.value;
        }
        else if (this.filter_src_lang !== '') {
            this.fQuery += '&source_lang=' + this.filter_src_lang;
        }
        else if (this.fdata.Source) {
            this.fQuery += '&source_lang=' + this.fdata.Source;
        }
        if (type === 't_lang' && evt.value !== '' && evt.value !== undefined) {
            this.filter_target_lang = evt.value;
            this.fQuery += '&target_lang=' + this.filter_target_lang;
        }
        else if (type === 't_lang' && evt.value === '') {
            this.filter_target_lang = evt.value;
        }
        else if (this.filter_target_lang !== '') {
            this.fQuery += '&target_lang=' + this.filter_target_lang;
        }
        else if (this.fdata.Target) {
            this.fQuery += '&target_lang=' + this.fdata.Target;
        }
        if (type === 'native_lang' &&
            evt.value != null &&
            evt.value !== undefined) {
            this.filter_native_lang = evt.value;
            this.fQuery += '&native_lang=' + this.filter_native_lang;
        }
        else if (type == 'native_lang' && evt.value == null) {
            this.filter_native_lang = evt.value;
        }
        else if (this.filter_native_lang != null) {
            this.fQuery += '&native_lang=' + this.filter_native_lang;
        }
        else if (this.fdata.Native) {
            this.fQuery += '&native_lang=' + this.fdata.Native;
        }
        // filter start
        var filter;
        if (this.StageID == 1) {
            filter = {
                table_name: 'translatorInNewRequest',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.newRequestFilter, JSON.stringify(filter));
        }
        if (this.in_progress_tab == 1) {
            filter = {
                table_name: 'translatorInProgress',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.inProgressFilter, JSON.stringify(filter));
        }
        if (this.in_progress_tab == 5) {
            this.fQuery += '&non_responsive=' + this.non_responsive;
            filter = {
                table_name: 'translatorNonResponsive',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.nonResponsiveFilter, JSON.stringify(filter));
        }
        if (this.tabId == 6) {
            this.fQuery += '&archive=Closed';
            filter = {
                table_name: 'translatorClosed',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.nonResponsiveFilter, JSON.stringify(filter));
        }
        if (this.tabId == 7) {
            this.fQuery += '&archive=Blacklisted';
            filter = {
                table_name: 'translatorBlacklisted',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.nonResponsiveFilter, JSON.stringify(filter));
        }
        if (this.StageID == 9) {
            filter = {
                table_name: 'translatorNeedApproval',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.needApprovalFilter, JSON.stringify(filter));
        }
        if (this.StageID == 7) {
            filter = {
                table_name: 'translatorPendingAdminApproval',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.pendingAdminApprovalFilter, JSON.stringify(filter));
        }
        if (this.StageID == 0) {
            filter = {
                table_name: 'Archive',
                category: this.filtercategoryLevel,
                source: this.filter_src_lang,
                target: this.filter_target_lang,
                native: this.filter_native_lang,
                query_string: this.fQuery,
            };
            SharedService.set(SharedService.archiveFilter, JSON.stringify(filter));
        }
        // filter end
        var query = this.fQuery;
        if (this.StageID == 2) {
            this.StageID = '2,3,4';
        }
        if (this.StageID == 0 && this.tabId == 4) {
            query += '&archive=Unavailable,Blacklisted,Closed,Leader,Probation,Junior';
        }
        this.vendorService.getHrDashboardListing(query, this.StageID, 'marketing').subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        specs += res.specialities.data.name + ', ';
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value1 = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang =
                            res.sourcelanguage.data.lang_name +
                                res.bi_direction +
                                res.targetlanguage.data.lang_name +
                                ',';
                        lang_array.push(lang);
                    });
                    elem.check = false;
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                    //  elem.translation_rate_value1=translation_rate_value1.slice(0,-2);
                });
                _this.dataSource1 = new MatTableDataSource(response.data);
                _this.paginate1 = response.meta.pagination;
            }
        });
    };
    SharedListingComponent.prototype.editData = function (value) {
        this.router.navigate(['/marketing/freelancer/' + value.id]);
    };
    SharedListingComponent.prototype.selectMailProgress = function (evt, val) {
        if (evt.checked && this.ids_progress.indexOf(val.user_id == -1)) {
            this.ids_progress.push(val.user_id);
        }
        else if (!evt.checked &&
            this.ids_progress.indexOf(val.user_id != -1)) {
            var index = this.ids_progress.indexOf(val.user_id);
            this.ids_progress.splice(index, 1);
        }
        if (this.ids_progress.length == 0) {
            this.bulk_selected_progress = false;
        }
        this.send_email_progress_data.ids = this.ids_progress;
    };
    SharedListingComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        var check = false;
        this.dataSource1.data.forEach(function (element, key) {
            element['check'] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.login_enable = false;
                _this.ids_progress.push(element.user_id);
            }
            else {
                _this.ids_progress = [];
                _this.login_enable = true;
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
        this.send_email_progress_data.ids = this.ids_progress;
    };
    // get filters
    SharedListingComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality().subscribe(function (res) {
            if (res) {
                res.forEach(function (element) {
                    element.name =
                        element.name.charAt(0).toUpperCase() +
                            element.name.slice(1);
                });
                _this.specialityResponse = res;
            }
        });
    };
    SharedListingComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages().subscribe(function (res) {
            if (res) {
                _this.languageResponse = res.data;
            }
        });
    };
    SharedListingComponent.prototype.Send_mail_single = function (val) {
        var _this = this;
        var progress_single = [];
        var send_email_single_data = {};
        progress_single.push(val.user_id);
        send_email_single_data.ids = progress_single;
        send_email_single_data.resend = true;
        this.vendorService.sendProgressMail(send_email_single_data).subscribe(function (response) {
            if (response) {
                send_email_single_data = {};
                progress_single = [];
                _this.toastr.success('Please check email');
                _this.getData();
            }
        });
    };
    SharedListingComponent.prototype.applyFilterProgress = function (filterValue) {
        this.dataSource1.filter = filterValue.trim().toLowerCase();
    };
    SharedListingComponent.prototype.Send_mail = function () {
        var _this = this;
        if (Object.keys(this.send_email_progress_data).length === 0) {
            this.toastr.error('Please first select any translator');
            return;
        }
        this.vendorService
            .sendProgressMail(this.send_email_progress_data)
            .subscribe(function (response) {
            if (response) {
                _this.send_email_progress_data = {};
                _this.bulk_selected_progress = false;
                _this.ids_progress = [];
                _this.dataSource1.data.forEach(function (element) {
                    element.check = false;
                });
                _this.toastr.success('Please check emails');
                _this.getData();
            }
        });
    };
    return SharedListingComponent;
}());
export { SharedListingComponent };
