<div mat-dialog-content class="p-16 pt-4 m-0" >
        <div fxLayout="column" fxLayoutAlign="start">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">  
                        <mat-label>Subject:</mat-label>
                        <p>{{response_email?.name.value}}</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayoutGap="10px">  
                        <mat-label>Status:</mat-label>
                        <p>{{response_email?.status.value}}</p>
                </div>
                <div [innerHTML]="response_email?.description_html.value | keepHtml"></div>
        </div> 
</div>
<div class="actWraper" fxLayout="column"  fxLayout.gt-sm="row" >
        <button mat-raised-button color="primary" (click)="Onclose()" >Cancel</button>
</div>