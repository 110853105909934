<div class="activityLog">
<h4>    Project Activity  </h4>

  <div *ngFor="let res of activity_response">
 
  <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="5px" *ngIf="res.type=='ProjectCreatedNotification'">
    <div class="history-avatar" fxFlex="5">
      <div class="avatar" tooltip="Name">TM</div>
    </div>
    <div class="history-cell" fxFlex>
      <p *ngIf="res?.data?.project" class="jobInfo ml-12">{{res?.data?.project[0]?.customer?.first_name}} <span class="sDate">{{res?.created_at}} </span> </p>
      <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> Ref {{res?.data?.reference}} </p>
    </div>
  </div>   

  <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="5px"  *ngIf="res?.type=='ProjectQuoteCreatedNotification'">
      <div class="history-avatar" fxFlex="5">
        <div class="avatar" tooltip="Name">TK</div>
      </div>
      <div class="history-cell" fxFlex>
        <p class="jobInfo ml-12"> {{res?.created_at}} </p>
        <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> <span *ngIf="res?.data?.language_pair.length>0">for</span> <b *ngFor="let language of res?.data?.language_pair"> {{language}} </b></p>
      </div>
    </div>

    <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='ProjectQuoteApprovedNotification'">
        <div class="history-avatar" fxFlex="5">
          <div class="avatar" tooltip="Name">TK</div>
        </div>
        <div class="history-cell" fxFlex>
          <p class="jobInfo ml-12"> {{res?.created_at}} </p>
          <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> on Job ref {{res?.data?.reference}} </p>
        </div>
      </div>

      <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='ProjectQuoteRejectedNotification'">
          <div class="history-avatar" fxFlex="5">
            <div class="avatar" tooltip="Name">TK</div>
          </div>
          <div class="history-cell" fxFlex>
            <p class="jobInfo ml-12"> {{res?.created_at}} </p>
            <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> on Job ref {{res?.data?.reference}} </p>
          </div>
        </div>

        <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='ProjectQuoteNeedDiscussionNotification'">
            <div class="history-avatar" fxFlex="5">
              <div class="avatar" tooltip="Name">TK</div>
            </div>
            <div class="history-cell" fxFlex>
              <p class="jobInfo ml-12"> {{res?.created_at}} </p>
              <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> on Job ref {{res?.data?.reference}} </p>
            </div>
          </div>

      <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='AssignmentSentNotification'">
          <div class="history-avatar" fxFlex="5">
            <div class="avatar" tooltip="Name">TK</div>
          </div>
          <div class="history-cell" fxFlex>
            <p class="jobInfo ml-12">{{res?.data?.username}} ({{res?.data?.role}}) {{res?.created_at}} </p>
            <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> {{res?.data?.freelancer}} Assigned  <b>{{res?.data?.workflow}}</b> on Job ref {{res?.data?.reference}} </p>
          </div>
        </div>

        <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='AssignmentAcceptNotification'">
            <div class="history-avatar" fxFlex="5">
              <div class="avatar" tooltip="Name">TK</div>
            </div>
            <div class="history-cell" fxFlex>
              <p class="jobInfo ml-12">{{res?.data?.username}} ({{res?.data?.role}}) {{res?.created_at}} </p>
              <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> on Job ref {{res?.data?.reference}} at  <b>{{res?.data?.workflow}}</b> </p>
            </div>
          </div>

          <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='AssignmentDeclineNotification'">
              <div class="history-avatar" fxFlex="5">
                <div class="avatar" tooltip="Name">TK</div>
              </div>
              <div class="history-cell" fxFlex>
                <p class="jobInfo ml-12">{{res?.data?.username}} ({{res?.data?.role}}) {{res?.created_at}} </p>
                <p class="jobInfo ml-12"><b>{{res?.data?.heading}} </b> on Job ref {{res?.data?.reference}} at  <b>{{res?.data?.workflow}}</b> </p>
              </div>
            </div>

            <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='AssignmentUpdateNotification'">
                <div class="history-avatar" fxFlex="5">
                  <div class="avatar" tooltip="Name">TK</div>
                </div>
                <div class="history-cell" fxFlex>
                  <p class="jobInfo ml-12">{{res?.data?.username}} ({{res?.data?.role}}) {{res?.created_at}} </p>
                  <p class="jobInfo ml-12"><b> {{res?.data?.heading}}  {{res?.data?.freelancer}} </b>Status on  <b>{{res?.data?.workflow}}</b> at Job ref {{res?.data?.reference}}   </p>
                </div>
              </div>

              
            <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='AssignmentCompleteNotification'">
                <div class="history-avatar" fxFlex="5">
                  <div class="avatar" tooltip="Name">TK</div>
                </div>
                <div class="history-cell" fxFlex>
                  <p class="jobInfo ml-12"> {{res?.data?.username}} ({{res?.data?.role}}) {{res?.created_at}} </p>
                  <p class="jobInfo ml-12"><b> {{res?.data?.heading}}  {{res?.data?.freelancer}} </b>Status on  <b>{{res?.data?.workflow}}</b> at Job ref {{res?.data?.reference}}   </p>
                </div>
              </div>

              <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='UpdateProjectSpeclizationNotification'">
                  <div class="history-avatar" fxFlex="5">
                    <div class="avatar" tooltip="Name">TK</div>
                  </div>
                  <div class="history-cell" fxFlex>
                    <p class="jobInfo ml-12">{{res?.data?.username}} ({{res?.data?.role}}) {{res?.created_at}} </p>
                    <p class="jobInfo ml-12"><b> {{res?.data?.heading}}   </b>{{res?.data?.specilization}}   </p>
                  </div>
                </div>

                <div class="history-row mb-12" fxLayout="row wrap" fxLayoutGap="10px"  *ngIf="res?.type=='AddProjectAdditionalServiceNotification'">
                    <div class="history-avatar" fxFlex="5">
                      <div class="avatar" tooltip="Name">TK</div>
                    </div>
                    <div class="history-cell" fxFlex>
                      <p class="jobInfo ml-12">{{res?.data?.username}} ({{res?.data?.role}}) {{res?.created_at}} </p>
                      <p class="jobInfo ml-12"><b> {{res?.data?.heading}}   </b>{{res?.data?.service_name}}   </p>
                    </div>
                  </div>
  
</div>

  <div *ngIf="activity_response.length==0" class="noDataFound">
    No data available!!
  
  </div>
</div>