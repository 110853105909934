import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { navigation } from 'app/navigation/navigation';
import { SharedService } from 'app/main/shared.service';
import * as moment from 'moment';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DataService } from 'app/main/Services/data.service';
var ToolbarComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    function ToolbarComponent(_fuseConfigService, _fuseSidebarService, _translateService, router, projectsService, toastr, location, data) {
        var _this = this;
        this._fuseConfigService = _fuseConfigService;
        this._fuseSidebarService = _fuseSidebarService;
        this._translateService = _translateService;
        this.router = router;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.data = data;
        this.fQuery = '';
        this.notArchive = ''; //Unavailable,Closed
        this.search_response = [];
        this.notification_count = 0;
        this.notification_response = [];
        this.welcome = '/welcome';
        this.filterSearchQuery = '';
        this.whatsappTotalCount = 0;
        this.smsTotalCount = 0;
        // pms
        this.Topsearch = {
            data: ''
        };
        // Hr
        this.topSearchHr = {
            data: ''
        };
        this.search_response_hr = [];
        this.hrQuery = '';
        this.filterHrSearchQuery = '';
        // marketing
        this.topSearchMarketing = {
            data: ''
        };
        this.search_response_marketing = [];
        this.marketingQuery = '';
        this.filterMarketingSearchQuery = '';
        router.events.subscribe(function (val) {
            if (location.path() != '') {
                _this.route = location.path();
            }
        });
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];
        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];
        this.navigation = navigation;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.currentMessage.subscribe(function (response) {
            _this.message = response;
            if (_this.message === 'default message') {
                _this.whatsappNotification();
                _this.smsNotification();
            }
            else if (_this.message === 'whatsapp') {
                _this.whatsappNotification();
            }
            else if (_this.message === 'sms') {
                _this.smsNotification();
            }
        });
        var url = this.router.url;
        var x = url.split('/');
        this.url_string = x[1];
        this.today_date = new Date();
        this.getUnreadNotifications();
        // this.whatsappNotification();
        // this.smsNotification();
        this.current_date = moment(this.today_date.toISOString(), 'YYYY-MM-DD HH:mm:ss.SSSS');
        this.profile_pic = SharedService.get('profile_pic');
        this.user_name = SharedService.get('username');
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (settings) {
            _this.horizontalNavbar = settings.layout.navbar.position === 'top';
            _this.rightNavbar = settings.layout.navbar.position === 'right';
            _this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
    };
    /**
     * On destroy
     */
    ToolbarComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    ToolbarComponent.prototype.toggleSidebarOpen = function (key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    };
    /**
     * Search
     *
     * @param value
     */
    ToolbarComponent.prototype.search = function (value) {
        // Do your search here...
    };
    /**
     * Set the language
     *
     * @param lang
     */
    ToolbarComponent.prototype.setLanguage = function (lang) {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        // Use the selected language for translations
        this._translateService.use(lang.id);
    };
    ToolbarComponent.prototype.logout = function () {
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('login');
        // localStorage.removeItem('token');
        localStorage.clear();
        this.clearLocalStorage();
        this.router.navigate(['/auth/login']);
    };
    ToolbarComponent.prototype.clearLocalStorage = function () {
        localStorage.removeItem('pms_freelancer_Filter');
        localStorage.removeItem('pms_freelancer_native_filter');
        localStorage.removeItem('pms_freelancer_specialization_filter');
        localStorage.removeItem('pms_freelancer_source_lang_filter');
        localStorage.removeItem('pms_freelancer_target_lang_filter');
        localStorage.removeItem('pms_freelancer_service_filter');
        localStorage.removeItem('pms_freelancer_search_filter');
        localStorage.removeItem('pms_freelancer_days_available_filter');
        localStorage.removeItem('pms_freelancer_category_level_filter');
        localStorage.removeItem('pms_freelancer_country_filter');
        localStorage.removeItem('pms_freelancer_city_filter');
        localStorage.removeItem('pms_freelancer_startdate_filter');
        localStorage.removeItem('pms_freelancer_enddate_filter');
        localStorage.removeItem('pms_freelancer_pagination');
    };
    ToolbarComponent.prototype.MyProfile = function () {
        window.location.href = '/admin-profile';
    };
    // PMS top search start
    ToolbarComponent.prototype.onFilterPms = function (evt, type) {
        if (evt == '') {
            this.Topsearch.data = '';
            this.search_response = [];
            return;
        }
        if (evt.length > 3) {
            this.fQuery = '';
            // project search filter
            if ((type == 'search' && evt != '' && evt !== undefined)) {
                this.filterSearchQuery = evt;
                this.fQuery += '&per_page=100&&stage=6&search_text=' + this.filterSearchQuery;
            }
            else if (type == 'search' && evt == '') {
                this.filterSearchQuery = evt;
            }
            else if (this.filterSearchQuery !== '') {
                this.fQuery += '&per_page=100&&stage=6&search_text=' + this.filterSearchQuery;
            }
            var query = this.fQuery;
            //this.getTopPmsSearch(query);
            this.getTopPmsSearchFreelancer(query);
        }
        else {
            return;
        }
    };
    ToolbarComponent.prototype.getTopPmsSearchFreelancer = function (query) {
        var _this = this;
        this.projectsService.getTopHrSearch(query)
            .subscribe(function (response) {
            _this.search_response = [];
            if (response.data.length == 0) {
                _this.toastr.error('No data available!!');
                return;
            }
            else {
                _this.search_response = response.data;
            }
        });
    };
    ToolbarComponent.prototype.getTopPmsSearch = function (query) {
        var _this = this;
        this.projectsService.getTopPmsSearch(query)
            .subscribe(function (response) {
            _this.search_response = [];
            if (response.data.length == 0) {
                _this.toastr.error('No data available!!');
                return;
            }
            else {
                _this.search_response = response.data;
            }
        }, function (error) {
            _this.toastr.error(error);
        });
    };
    ToolbarComponent.prototype.onSelectSearch = function (res, type) {
        if (type === 'pms') {
            this.Topsearch.data = '';
            if (res.option.value != null) {
                //this.router.navigate(['/pms/project/details/'+ res.option.value]);
                window.location.href = '/pms/freelancer/details/' + res.option.value;
            }
            else {
                this.toastr.error('Project Id is invalid');
                return;
            }
        }
        else if (type === 'hr') {
            this.topSearchHr.data = '';
            if (res.option.value != null) {
                // this.router.navigate(['/hr/translator/review/' + res.option.value]);
                window.location.href = '/hr/translator/review/' + res.option.value;
            }
            else {
                this.toastr.error('Translator is invalid');
                return;
            }
        }
        else if (type === 'marketing') {
            this.topSearchMarketing.data = '';
            if (res.option.value != null) {
                window.location.href = '/marketing/freelancer/review/' + res.option.value;
            }
            else {
                this.toastr.error('Freelancer is invalid');
                return;
            }
        }
    };
    // PMS top search end
    // Hr top search start
    ToolbarComponent.prototype.onFilterTranslator = function (evt, type) {
        if (evt == '') {
            this.topSearchHr.data = '';
            this.search_response_hr = [];
            return;
        }
        if (evt.length > 3) {
            this.hrQuery = '';
            // project search filter
            if ((type == 'search' && evt != '' && evt !== undefined)) {
                this.filterHrSearchQuery = evt;
                this.hrQuery += '&per_page=100&search_text=' + this.filterHrSearchQuery;
                //+ '&not_archive=' + this.notArchive;
            }
            else if (type == 'search' && evt == '') {
                this.filterHrSearchQuery = evt;
            }
            else if (this.filterHrSearchQuery !== '') {
                this.hrQuery += '&per_page=100&search_text=' + this.filterHrSearchQuery;
                //+ '&not_archive=' + this.notArchive;
            }
            var query = this.hrQuery;
            this.getTopHrSearch(query);
        }
        else {
            return;
        }
    };
    ToolbarComponent.prototype.onFilterMarketingTranslator = function (evt, type) {
        if (evt == '') {
            this.topSearchMarketing.data = '';
            this.search_response_marketing = [];
            return;
        }
        if (evt.length > 3) {
            this.marketingQuery = '';
            // project search filter
            if ((type == 'search' && evt != '' && evt !== undefined)) {
                this.filterMarketingSearchQuery = evt;
                this.marketingQuery += '&per_page=100&search_text=' + this.filterMarketingSearchQuery;
                //+ '&not_archive=' + this.notArchive;
            }
            else if (type == 'search' && evt == '') {
                this.filterMarketingSearchQuery = evt;
            }
            else if (this.filterMarketingSearchQuery !== '') {
                this.marketingQuery += '&per_page=100&search_text=' + this.filterMarketingSearchQuery;
                //+ '&not_archive=' + this.notArchive;
            }
            var query = this.marketingQuery;
            this.getTopMarketingSearch(query);
        }
        else {
            return;
        }
    };
    ToolbarComponent.prototype.getTopHrSearch = function (query) {
        var _this = this;
        this.projectsService.getTopHrSearch(query)
            .subscribe(function (response) {
            _this.search_response_hr = [];
            if (response.data.length == 0) {
                _this.toastr.error('No data available!!');
                return;
            }
            else {
                _this.search_response_hr = response.data;
            }
        });
    };
    ToolbarComponent.prototype.getTopMarketingSearch = function (query) {
        var _this = this;
        this.projectsService.getTopHrSearch(query, 'marketing')
            .subscribe(function (response) {
            _this.search_response_marketing = [];
            if (response.data.length == 0) {
                _this.toastr.error('No data available!!');
                return;
            }
            else {
                _this.search_response_marketing = response.data;
            }
        });
    };
    // Hr top search end
    ToolbarComponent.prototype.getUnreadNotifications = function () {
        var _this = this;
        this.projectsService.unreadNotification()
            .subscribe(function (response) {
            response.data.forEach(function (element) {
                if (element.type == 'App\\Notifications\\ProjectCreatedNotification') {
                    if (element.data.reference) {
                        _this.notification_count = _this.notification_count + 1;
                        var message = ' New ' + element.data.service_type + 'Request for Language ' +
                            element.data.source_language + ' - ' + element.data.target_language + ' Received';
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == 'App\\Notifications\\ProjectQuoteCreatedNotification') {
                    if (element.data.due_date) {
                        _this.notification_count = _this.notification_count + 1;
                        var lang_1 = '';
                        element.data.language_pair.forEach(function (res) {
                            lang_1 += res + ', ';
                        });
                        lang_1 = lang_1.slice(0, -2);
                        var message = lang_1 + ' Translation request due on ' + _this.dateFormat(element.data.due_date);
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == 'App\\Notifications\\AssignmentDeclinedNotification') {
                    if (element.data.reference_number) {
                        _this.notification_count = _this.notification_count + 1;
                        var message = element.data.reference_number + ' assignment was declined by ' + element.data.freelance_name;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == 'App\\Notifications\\AssignmentAcceptNotification') {
                    if (element.data.reference_number) {
                        _this.notification_count = _this.notification_count + 1;
                        var message = element.data.freelance_name + ' accepted ' + element.data.reference_number + ' assignment due on ' + element.data.reference_number;
                        _this.notification_response.push(message);
                    }
                }
                if (element.type == 'App\\Notifications\\ProjectStatusUpdateNotification') {
                    if (element.data.content) {
                        _this.notification_count = _this.notification_count + 1;
                        _this.notification_response.push(element.data.content);
                    }
                }
            });
        });
    };
    ToolbarComponent.prototype.notification_read = function () {
        var _this = this;
        var query = '';
        this.projectsService.notificationRead(query)
            .subscribe(function (response) {
            if (response) {
                _this.toastr.success('Notification read successfully!!');
                _this.notification_count = 0;
                _this.getUnreadNotifications();
            }
        });
    };
    // date format
    ToolbarComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt;
    };
    ToolbarComponent.prototype.whatsappNotification = function () {
        var _this = this;
        this.projectsService.getWhatsappCount()
            .subscribe(function (response) {
            _this.whatsappTotalCount = response.count;
        });
    };
    ToolbarComponent.prototype.smsNotification = function () {
        var _this = this;
        this.projectsService.getSmsCount().subscribe(function (response) {
            _this.smsTotalCount = response.count;
        });
    };
    return ToolbarComponent;
}());
export { ToolbarComponent };
