import { OnInit, EventEmitter } from '@angular/core';
import { ProjectsService } from 'app/main/Services/projects.service';
import { MatTableDataSource } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { StatusChangeAdditionalServiceComponent } from '../status-change-additional-service/status-change-additional-service.component';
var AdditionalServicesApostileComponent = /** @class */ (function () {
    function AdditionalServicesApostileComponent(route, _formBuilder, projectsService, toastr, dialog) {
        this.route = route;
        this._formBuilder = _formBuilder;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.dialog = dialog;
        this.file_data = [];
        this.file_id_data = [];
        this.response_email_data = [];
        this.assignment_obj = {};
        this.vendor_ids = [];
        this.projectWorkFlowResponse = [];
        this.bulk_selected_progress = false;
        this.show_form = false;
        this.apostile_show = false;
        this.additonal_service_array = [];
        this.apostile_state = false;
        this.Project_Assigned_obj_compare = [];
        this.target_language_select = [];
        this.ProjectLanguage_data = [];
        this.completed_workflow_array = [];
        this.project_assignment_response_array = [];
        this.email_variable_obj = {
            mailing_options: '',
            documents_shipping_address: '',
            pickUp_location: '',
            street_address: '',
            phone_number: '',
            shipping_city: '',
            shipping_state: '',
            shipping_country: '',
            shipping_zip_code: '',
            additonal_copy: '',
            second_address: '',
            notarization_of_coa: '',
            notarization_other_state: '',
            additional_notarization: '',
            notarization_affidavit: '',
            apostille_country: '',
            apostille_state: '',
            apostille_outside: '',
        };
        this.displayedColumns = ['position', 'name', 'email', 'location', 'services'];
        this.response = [];
        this.myoutputApostile = new EventEmitter();
        this.BaseUrl_file = environment.baseUrl_file;
    }
    AdditionalServicesApostileComponent.prototype.ngOnInit = function () {
        this.project_id = this.route.snapshot.params['id'];
        this.getAdditionalServices();
        this.getvendorbyskill();
        this.getFileDetail();
        this.getEmailTemplate();
        this.WorkFlowByProjectId();
        this.getProjectAssignment_compare();
        this.getProjectLanguage();
        this.Form = this._formBuilder.group({
            email_template_id: ['', Validators.compose([Validators.required])],
            instruction: [''],
            vendor_id: [''],
            start_date: [''],
            due_date: ['', Validators.compose([Validators.required])],
        });
    };
    AdditionalServicesApostileComponent.prototype.getAdditionalServices = function () {
        var _this = this;
        this.projectsService.getAdditionalServices(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.additonal_service_array = response.project_addition_service;
                if (_this.additonal_service_array.length == 0) {
                    _this.sendNotApply();
                }
                if (response.project_addition_service.length > 0) {
                    response.project_addition_service.forEach(function (element) {
                        if (element.additional_service.code == "apostille_service_Illinois") {
                            _this.apostile_show = true;
                            _this.shipping_country = element.apostile_country_source;
                            _this.apostile_state = true;
                            _this.sendApply();
                        }
                        if (element.additional_service.code == "apostille_service_outside_Illinois") {
                            _this.apostile_show = true;
                            _this.apostile_state = false;
                            _this.shipping_country = element.apostile_country_source;
                            _this.shipping_state = element.apostile_from_state;
                            _this.sendApply();
                        }
                    });
                }
            }
        });
    };
    AdditionalServicesApostileComponent.prototype.sendNotApply = function () {
        this.outputString = "(NA)";
        this.myoutputApostile.emit(this.outputString);
    };
    AdditionalServicesApostileComponent.prototype.sendApply = function () {
        this.outputString = "(Applied)";
        this.myoutputApostile.emit(this.outputString);
    };
    AdditionalServicesApostileComponent.prototype.getvendorbyskill = function () {
        var _this = this;
        var obj = {
            skill_id: Number
        };
        obj.skill_id = 7;
        this.projectsService.getvendorbyskill(obj)
            .subscribe(function (response) {
            if (response) {
                _this.response = response.data;
                _this.dataSource = new MatTableDataSource(_this.response);
            }
        });
    };
    // Get file data
    AdditionalServicesApostileComponent.prototype.getFileDetail = function () {
        var _this = this;
        this.projectsService.getProjectDetail(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.file_data = [];
                var fullname = "Customer";
                response.data.projectfile.data.forEach(function (element) {
                    if (element.createdby) {
                        fullname = element.createdby.data.fullname;
                    }
                    if (element.targetlanguag) {
                        var lang_name = element.targetlanguage.data.lang_name;
                    }
                    if (element.filetype) {
                        var filetype = element.filetype.data.name;
                    }
                    element.file.data.forEach(function (elm) {
                        _this.project_file_data = _this.BaseUrl_file + elm.path;
                        elm.type = element.type;
                        elm.fullname = fullname;
                        //elm.created_at= created_at;
                        elm.lang_name = lang_name;
                        elm.filetype = filetype;
                        _this.file_data.push(elm);
                    });
                });
            }
        });
    };
    AdditionalServicesApostileComponent.prototype.getEmailTemplate = function () {
        var _this = this;
        this.projectsService.getEmailTemplateApostile()
            .subscribe(function (response) {
            if (response) {
                _this.response_email_data = response.data;
            }
        });
    };
    // file section
    AdditionalServicesApostileComponent.prototype.selectFile = function (evt, res) {
        if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
            this.file_id_data.push(res.id);
        }
        else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
            var index = this.file_id_data.indexOf(res.id);
            this.file_id_data.splice(index, 1);
        }
    };
    AdditionalServicesApostileComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        this.file_id_data = [];
        var check = false;
        this.file_data.forEach(function (element, key) {
            element["check"] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.file_id_data.push(element.id);
            }
            else {
                _this.file_id_data = [];
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
    };
    AdditionalServicesApostileComponent.prototype.WorkFlowByProjectId = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        this.projectsService.WorkFlowByProjectId(obj)
            .subscribe(function (response) {
            if (response) {
                if (response.response.length > 0) {
                    _this.projectWorkFlowResponse = response.response;
                    _this.projectWorkFlowResponse.forEach(function (element) {
                        if (element.workflow_id == 9) {
                            _this.project_workflow_id = element.id;
                            _this.getProjectLanguageAssignment(_this.project_workflow_id);
                        }
                    });
                }
            }
        });
    };
    AdditionalServicesApostileComponent.prototype.submit = function () {
        var _this = this;
        if (this.file_id_data.length == 0) {
            this.toastr.error("Please select any files");
            return;
        }
        if (this.Form.value.vendor_id == null) {
            this.toastr.error("Please select any vendor");
            return;
        }
        if (this.Form.value.vendor_id.length > 1) {
            this.toastr.error("Please select only one vendor");
            return;
        }
        this.assignment_obj = this.Form.value;
        var date = new Date();
        this.Form.value.start_date = date;
        this.assignment_obj.start_date = this.dateFormat(this.Form.value.start_date);
        this.assignment_obj.due_date = this.dateFormat(this.Form.value.due_date);
        this.assignment_obj.service_id = 3;
        this.assignment_obj.file_id = this.file_id_data;
        this.assignment_obj.project_workflow_id = this.project_workflow_id;
        var email_variable;
        if (this.apostile_state == true) {
            email_variable = {
                mailing_options: '',
                documents_shipping_address: '',
                pickUp_location: '',
                street_address: '',
                phone_number: '',
                second_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_country: '',
                shipping_zip_code: '',
                additonal_copy: '',
                notarization_of_coa: '',
                notarization_other_state: '',
                additional_notarization: '',
                notarization_affidavit: '',
                apostille_country: this.shipping_country,
                apostille_state: 'Yes',
                apostille_outside: this.shipping_state
            };
        }
        else {
            email_variable = {
                mailing_options: '',
                documents_shipping_address: '',
                pickUp_location: '',
                street_address: '',
                phone_number: '',
                second_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_country: '',
                shipping_zip_code: '',
                additonal_copy: '',
                notarization_of_coa: '',
                notarization_other_state: '',
                additional_notarization: '',
                notarization_affidavit: '',
                apostille_country: this.shipping_country,
                apostille_state: 'No',
                apostille_outside: this.shipping_state
            };
        }
        this.assignment_obj.email_variable = email_variable;
        this.assignment_obj.project_id = this.project_id;
        this.projectsService.ProjectAssignmentAddition_service(this.assignment_obj)
            .subscribe(function (response) {
            if (response) {
                window.location.href = '/pms/project/details/' + _this.project_id;
                //   this.bulk_selected_progress=false;
                //   this.toastr.success("Success!!!");
                //   this.file_id_data=[];
                //   this.Project_Assigned_obj_compare=[];
                //   this.target_language_select=[];
                //   this.ProjectLanguage_data=[];
                //   this.completed_workflow_array=[];
                //   this.show_form=false;
                //   this.Form.patchValue({ 
                //     email_template_id: [''],
                //     instruction:[''],
                //     vendor_id:[],
                //     start_date: [''],
                //     due_date: [''],
                // });
                //  this.ngOnInit();
            }
            // else{
            //   this.toastr.error("Something went wrong");
            // }
        });
    };
    AdditionalServicesApostileComponent.prototype.selectFinance = function (evt, val) {
        if (evt.checked && this.vendor_ids.indexOf(val.id == -1)) {
            this.vendor_ids.push(val.id);
        }
        else if (!evt.checked && this.vendor_ids.indexOf(val.id != -1)) {
            var index = this.vendor_ids.indexOf(val.id);
            this.vendor_ids.splice(index, 1);
        }
        this.Form.patchValue({
            vendor_id: this.vendor_ids
        });
    };
    // date format
    AdditionalServicesApostileComponent.prototype.dateFormat = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt + ' 00:00:00';
    };
    AdditionalServicesApostileComponent.prototype.show_table = function () {
        this.show_form = true;
    };
    // get project assignment
    AdditionalServicesApostileComponent.prototype.getProjectAssignment_compare = function () {
        var _this = this;
        var obj = {
            project_id: ''
        };
        obj.project_id = this.project_id;
        var query = 'page=1&per_page=20';
        this.projectsService.getProjectAssignment(query, obj)
            .subscribe(function (response) {
            if (response) {
                _this.Project_Assigned_obj_compare = response.response.data;
                if (_this.Project_Assigned_obj_compare.length > 0) {
                    _this.Project_Assigned_obj_compare.forEach(function (element) {
                        if (element.status == "5" && (element.project_workflow.workflow.id != 6)) {
                            var obj_1 = {
                                workflow_id: '',
                                workflow_name: '',
                                vendor_name: '',
                                assignment_date: ''
                            };
                            obj_1.workflow_id = element.project_workflow.workflow.id;
                            obj_1.workflow_name = element.project_workflow.workflow.name;
                            obj_1.vendor_name = element.vendor.first_name + element.vendor.last_name;
                            obj_1.assignment_date = element.project_workflow.updated_at;
                            _this.completed_workflow_array.push(obj_1);
                        }
                    });
                    _this.completed_workflow_array.sort(function (a, b) {
                        return a.workflow_id - b.workflow_id;
                    });
                }
            }
            // else{
            //   this.toastr.error("Something went wrong");
            // }
        });
    };
    // Get Project Language
    AdditionalServicesApostileComponent.prototype.getProjectLanguage = function () {
        var _this = this;
        this.projectsService.getProjectLanguage(this.project_id)
            .subscribe(function (response) {
            if (response) {
                _this.ProjectLanguage_data = response.data;
                _this.language_pair_count = _this.ProjectLanguage_data.length;
                var target_language_obj_1 = {
                    id: Number,
                    sourcelang_name: String,
                    targetlang_name: String,
                    project_lang_id: Number
                };
                _this.ProjectLanguage_data.forEach(function (element) {
                    target_language_obj_1.id = element.targetlanguage.id;
                    target_language_obj_1.sourcelang_name = element.sourcelanguage.lang_name;
                    target_language_obj_1.targetlang_name = element.targetlanguage.lang_name;
                    target_language_obj_1.project_lang_id = element.id;
                    _this.target_language_select.push(target_language_obj_1);
                    target_language_obj_1 = {
                        id: '',
                        lang_name: '',
                        project_lang_id: ''
                    };
                });
            }
        });
    };
    // Get Project Language Assignment
    AdditionalServicesApostileComponent.prototype.getProjectLanguageAssignment = function (project_workflow_id) {
        var _this = this;
        this.projectsService.getProjectLanguageAssignment(project_workflow_id)
            .subscribe(function (response) {
            if (response) {
                _this.project_assignment_response_array = response.project_assignment;
            }
        });
    };
    AdditionalServicesApostileComponent.prototype.openDialog = function (project_assignment_id) {
        var _this = this;
        var data = {
            project_assignment_id: '',
            project_id: '',
            email_variable: {}
        };
        data.project_assignment_id = project_assignment_id;
        data.project_id = this.project_id;
        if (this.apostile_state == true) {
            this.email_variable_obj = {
                mailing_options: '',
                documents_shipping_address: '',
                pickUp_location: '',
                street_address: '',
                phone_number: '',
                second_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_country: '',
                shipping_zip_code: '',
                additonal_copy: '',
                notarization_of_coa: '',
                notarization_other_state: '',
                additional_notarization: '',
                notarization_affidavit: '',
                apostille_country: this.shipping_country,
                apostille_state: 'Yes',
                apostille_outside: this.shipping_state
            };
        }
        else {
            this.email_variable_obj = {
                mailing_options: '',
                documents_shipping_address: '',
                pickUp_location: '',
                street_address: '',
                phone_number: '',
                second_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_country: '',
                shipping_zip_code: '',
                additonal_copy: '',
                notarization_of_coa: '',
                notarization_other_state: '',
                additional_notarization: '',
                notarization_affidavit: '',
                apostille_country: this.shipping_country,
                apostille_state: 'No',
                apostille_outside: this.shipping_state
            };
        }
        data.email_variable = this.email_variable_obj;
        var dailogRef = this.dialog.open(StatusChangeAdditionalServiceComponent, {
            width: '400px',
            data: data
        });
        dailogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.WorkFlowByProjectId();
            }
        });
    };
    return AdditionalServicesApostileComponent;
}());
export { AdditionalServicesApostileComponent };
