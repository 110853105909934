import { OnDestroy, OnInit } from "@angular/core";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { Router } from "@angular/router";
import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
var AppComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    function AppComponent(document, _fuseConfigService, _fuseNavigationService, _fuseSidebarService, _fuseSplashScreenService, _fuseTranslationLoaderService, _translateService, _platform, _fuseProgressBarService, router) {
        this.document = document;
        this._fuseConfigService = _fuseConfigService;
        this._fuseNavigationService = _fuseNavigationService;
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseSplashScreenService = _fuseSplashScreenService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translateService = _translateService;
        this._platform = _platform;
        this._fuseProgressBarService = _fuseProgressBarService;
        this.router = router;
        // const url = this.router.url;
        var url = window.location.pathname;
        var x = url.split("/");
        this.url_string = x[1];
        // Get default navigation
        this.navigation = navigation;
        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
        if (this.url_string === "pms") {
            this._fuseNavigationService.updateNavigationItem("dashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("user_management", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketingGCLID", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("approval", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("hrDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketing", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingorientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("translator", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("masterData", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("reports", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tickets", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("imported", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("closedblacklisted", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("subtitler", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("treping", {
                hidden: true,
            });
        }
        else if (this.url_string === "hr") {
            this._fuseNavigationService.updateNavigationItem("pmblacklisted", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketingGCLID", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketing", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingorientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("dashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("user_management", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("approval", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("masterData", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("reports", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("customer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("transactions", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("vault", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("quotes", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("freelancer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("calendar", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tickets", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("account", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tools", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("sms", {
                hidden: true,
            });
        }
        else if (this.url_string === "marketing") {
            this._fuseNavigationService.updateNavigationItem("pmblacklisted", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("subtitler", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("hrDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("translator", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("imported", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("treping", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("dashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("user_management", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("approval", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("masterData", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("reports", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("customer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("transactions", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("vault", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("quotes", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("freelancer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("calendar", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tickets", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("account", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tools", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("sms", {
                hidden: true,
            });
        }
        else if (this.url_string === "admin-dashboard" ||
            this.url_string === "user-management" ||
            this.url_string === "approval" ||
            this.url_string === "reports" ||
            this.url_string === "masterdata" ||
            this.url_string === "tickets") {
            this._fuseNavigationService.updateNavigationItem("maketingGCLID", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketing", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingorientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("subtitler", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("hrDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("translator", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("customer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("transactions", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("vault", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("quotes", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("freelancer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("calendar", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("account", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tools", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("sms", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("imported", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("closedblacklisted", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmblacklisted", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("treping", {
                hidden: true,
            });
        }
        else if (this.url_string === "admin-profile") {
            this._fuseNavigationService.updateNavigationItem("subtitler", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketing", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingorientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("dashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("user_management", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("approval", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("hrDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("translator", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("quotes", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("freelancer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("calendar", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("customer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("masterData", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("reports", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("transactions", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tickets", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("account", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tools", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("sms", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("imported", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("closedblacklisted", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmblacklisted", {
                hidden: true,
            });
        }
        else if (this.url_string === "financial") {
            this._fuseNavigationService.updateNavigationItem("subtitler", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketingGCLID", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("maketing", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("marketingorientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("dashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("reports", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("user_management", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("approval", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("hrDashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("translator", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("orientations", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("quotes", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("freelancer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("calendar", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("masterData", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("quotes", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmprojects", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmdashboard", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("customer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("calendar", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("freelancer", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tickets", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("transactions", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("account", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("tools", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("sms", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("imported", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("closedblacklisted", {
                hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("pmblacklisted", {
                hidden: true,
            });
        }
        // Add languages
        this._translateService.addLangs(["en", "tr"]);
        // Set the default language
        this._translateService.setDefaultLang("en");
        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);
        // Use a language
        this._translateService.use("en");
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */
        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/
        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (config) {
            _this.fuseConfig = config;
            // Boxed
            if (_this.fuseConfig.layout.width === "boxed") {
                _this.document.body.classList.add("boxed");
            }
            else {
                _this.document.body.classList.remove("boxed");
            }
            // Color theme - Use normal for loop for IE11 compatibility
            for (var i = 0; i < _this.document.body.classList.length; i++) {
                var className = _this.document.body.classList[i];
                if (className.startsWith("theme-")) {
                    _this.document.body.classList.remove(className);
                }
            }
            _this.document.body.classList.add(_this.fuseConfig.colorTheme);
        });
    };
    /**
     * On destroy
     */
    AppComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    AppComponent.prototype.toggleSidebarOpen = function (key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    };
    return AppComponent;
}());
export { AppComponent };
